import React, { useContext, useEffect, useState } from 'react' 
import {
    Col, 
    Button,
    Row,
    Label, 
  } from "reactstrap";
import Select from "react-select";
import { DatePicker as AntdDatePicker, Modal as AntModal , Dropdown as AntDropDown } from 'antd';
import { Popover as AntdPopover, DatePicker as AntdDatepicker } from "antd"; 
import './EmailReminder.css'
import dayjs from 'dayjs';
import AwsServerService from '../../common/util/AwsServerService';
import { convertToUSTime } from '../HR/TimeClock/Util';
import swal from 'sweetalert';
import AppContext from '../../Context/AppContext';
import { GlobalRefresh, Socket, setIsReminderPopupOpen, setReminderData } from '../../Context/AppActions';

export default function EmailReminder() {

  const dummyRemOption={
      remindMe:'At specific time',
      sendReminderAs:'Mail',
      remindRecipients:false,
      when:null,
      "Custom date and time":{date:new Date(),time:"00:00"},
      note:''
     }
    const [remOption,setRemOption]=useState(dummyRemOption);  
    const AppData = useContext(AppContext);
    const { dispatch, state} = AppData || {};
    
    let ScheduleArr = [
          {label: "1 hour", value: "1 hour",date:()=>{
            return convertToUSTime(new Date(dayjs(new Date()).add(1,'hours').format("MM/DD/YYYY HH:mm:ss")));
          }},
          {label: "2 hours", value: "2 hour",date:()=>{
            return convertToUSTime(new Date(dayjs(new Date()).add(2,'hours').format("MM/DD/YYYY HH:mm:ss")));
          }},
          {label: "4 hours", value: "4 hours",date:()=>{
            return convertToUSTime(new Date(dayjs(new Date()).add(3,'hours').format("MM/DD/YYYY HH:mm:ss")));
          }},
          {label: "Tomorrow @ 10.00 AM", value: "Tomorrow @ 10.00 AM",date:()=>{
            const tomorrow = dayjs().add(1, 'day');
            const tomorrowTenAM = tomorrow.set('hour', 10).set('minute', 0).set('second', 0).set('millisecond', 0);
            return convertToUSTime(new Date(tomorrowTenAM.format('MM/DD/YYYY HH:mm:ss')));
          }},
          {label: "Custom date and time", value: "Custom date and time",date:(date)=>{
            let str=dayjs(date.date).format("MM/DD/YYYY")+" "+date.time;
            return convertToUSTime(new Date(str));
          }},
        ]
    useEffect(()=>{ 
      setRemOption({...remOption,"Custom date and time":{date:new Date(),time:"00:00"}})
    },[remOption.when])
    useEffect(e=>{
      if(state.reminderData){
        
        const dummyRemOption={
          remindMe: state.reminderData["reminderType"],
          sendReminderAs: state.reminderData["sendAs"],
          remindRecipients: state.reminderData["isReminderNote"]=="true"?true:false,
          when: ScheduleArr.find(r=>r.label==state.reminderData["whenValue"]),
          "Custom date and time":{date:new Date(),time:"00:00"},
          note:state.reminderData["reminderNote"],
          isEdit:state.reminderData["ROWID"],
         }
         setRemOption(dummyRemOption);
      }
    },[state.reminderData])

    const getDatePickerValue = (date) => {
      try {
        let d = date
        if (d) {
          return dayjs(new Date(d));
        } else {
          return null;
        }
      } catch (error) { }
  };

  const getPayload=(data)=>{
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    if(!data || !state.isReminderPopupOpen?.email?.messageId){
      return
    }
    let payload={
      "type":"Notification",
      "attendees": userInfo.email_id,
      "module":"Email",
      "messageId": state.isReminderPopupOpen?.email?.messageId,
      "reminderHeading": state.isReminderPopupOpen?.email?.subject,
      "userEmail": userInfo.email_id,
      "sendAs":data.sendReminderAs,
    };
    if(data.remindMe==="At specific time" || data.remindMe==="If no replies until"){
      payload.reminderType=data.remindMe;
      payload.whenValue=data.when.label;
      if(data.when.label=="Custom date and time"){
        payload.reminderTime=data.when.date(data["Custom date and time"]);
      }else{
        payload.reminderTime=data.when.date();
      }
      if(data.remindRecipients){
        payload.isReminderNote='true';
        payload.reminderNote=data.note;
      }else{
        payload.isReminderNote='false';
      }
    }else{
      payload.reminderType=data.remindMe;
      payload.reminderNote=data.note;
    }
    payload.status="false";
    return payload;
  }
  const onSaveReminder=async (data)=>{
     
    let payLoad=getPayload(data);
    console.log(payLoad);
    if(data.isEdit){
      AwsServerService.UpdateReminders({...payLoad,ROWID:data.isEdit}).then(res=>{
        console.log(res.data);
      }).then(res=>{
        dispatch(setIsReminderPopupOpen({status:false,email:null}));
        swal("Success!", "Reminder updated successfully!.", "success");
        dispatch(Socket({trigger:"Refresh_Reminder_List",data:null}))
      }).catch(e=>{
        swal("Error!", "Something went wrong!", "error");
      })
    }else{
      await AwsServerService.AddReminders(payLoad).then(res=>{
        console.log(res.data);
      }).then(res=>{
        dispatch(setIsReminderPopupOpen({status:false,email:null}));
        // setIsReminderPopupOpen({status:false,email:null});
        swal("Success!", "Reminder added successfully!.", "success");
        dispatch(Socket({trigger:"Refresh_Reminder_List",data:null}))
      }).catch(e=>{
        swal("Error!", "Something went wrong!", "error");
      })
    }
  }
  return (
    <div>
        <AntModal
        open={state.isReminderPopupOpen?.status}
        draggable
        title={"Remind me"}
        maskClosable={false}
        width={300}
        className="Add-absence-modal"
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          dispatch(setIsReminderPopupOpen({status:false,email:null}));
            dispatch(setReminderData(null));
            setRemOption(dummyRemOption)
        }} 
        footer={[
          <div
            className="col-sm-12"
          >
            <Button
              style={{ display: "inline", marginRight: "0.4rem" }}
              onClick={() => {
                dispatch(setIsReminderPopupOpen({status:false,email:null}));
                setRemOption(dummyRemOption)
              }}
              key="Cancel"
              type="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSaveReminder({...remOption})
              }}
              key="Create"
              type="primary"
              color="primary"
            >
             {remOption.isEdit?"Update Reminder":"Add Reminder"}
            </Button>
          </div>,
        ]}
      >
        <Row style={{padding: '3px'}}>
          <Col sm={4}> 
                <input
                  type="radio"
                  name="remOption"
                  value="At specific time"
                  checked={remOption.remindMe=="At specific time"}
                  onChange={(e)=>{
                    setRemOption({...remOption,remindMe:"At specific time"})
                  }}
                />
                <label style={{marginLeft:'.8em'}}>
                At specific time
                </label>
        </Col>
        <Col sm={4}>
                <input
                  type="radio"
                  name="remOption"
                  value="On every reply"
                  checked={remOption.remindMe=="On every reply"}
                  onChange={(e)=>{
                    setRemOption({...remOption,remindMe:"On every reply"})
                  }}
                  />
                <label style={{marginLeft:'1em'}}>
                On every reply
              </label>
              </Col>
        <Col sm={4}>
                <input
                  type="radio"
                  name="remOption"
                  value="If no replies until"
                  checked={remOption.remindMe=="If no replies until"}
                  onChange={(e)=>{
                    setRemOption({...remOption,remindMe:"If no replies until"})
                  }}
                  />
              <label style={{marginLeft:'1em'}}>
                If no replies until
              </label>
              </Col>
              </Row>
       {remOption.remindMe!='On every reply' && <>
              <Label style={{fontWeight:'bold',padding:'3px'}}>
                When
                </Label>
              <Row>
              <Col sm={12}>
              <Select 
                value={remOption.when}
                options={ScheduleArr}
                closeMenuOnSelect={true}  
                onChange={(e) => { 
                  
                  setRemOption({...remOption,when:e})
                }} 
                menuPlacement="auto"
              />
              </Col>  
        </Row>
        {remOption.when?.label=="Custom date and time"&&<><Row className="mt-3">
          <Col sm={3}> 
          <label style={{padding:'1px',position:'absolute'}}> Date and Time:</label>
          </Col> 
          <Col sm={5}> 
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(remOption['Custom date and time'].date)}
              // style={{zIndex:'-1',position:'relative'}}
              placeholderText="Select a date"
              popperClassName="ag-custom-component-popup" 
              className={`form-control form-control-sm `}  
              onChange={(date) =>{
                let dateTime=remOption['Custom date and time'];
                dateTime={...dateTime,'date':new Date(date)}
                setRemOption({...remOption,'Custom date and time':dateTime}) 
              }}  
            /> 
          </Col> 
          <Col sm={4}>
              <input 
              type="time"
              className="form-control form-control-sm"
              format="HH:mm A"
              // style={validation.out=='-1'?{...validationCss}:{}}
              style={{height:'2.2rem'}}  
              onChange={(e) =>{
                let dateTime=remOption['Custom date and time'];
                dateTime={...dateTime,'time':e.currentTarget.value}
                setRemOption({...remOption,'Custom date and time':dateTime}) 
                }}
              value={remOption?.['Custom date and time']?.time}
            />
            </Col>
        </Row>
        </>}
              <Label style={{fontWeight:'bold',padding:'3px',marginTop:'1em'}}>
              Send reminder as a
                </Label>
                 <Row style={{padding: '3px'}}>
                 <Col sm={3}>
                <input
                  type="radio"
                  name="typeOfRem"
                  value="Mail"
                  checked={remOption.sendReminderAs=="Mail"}
                  onChange={(e)=>{
                    setRemOption({...remOption,sendReminderAs:'Mail'})
                  }}
                  />
                <label style={{marginLeft:'1em'}}>
                Mail
              </label>
              </Col>
        <Col sm={4}>
                <input
                  type="radio"
                  name="typeOfRem"
                  value="Notification"
                  checked={remOption.sendReminderAs=='Notification'}
                  onChange={(e)=>{
                    setRemOption({...remOption,sendReminderAs:'Notification'})
                  }}
                  />
              <label style={{marginLeft:'1em'}}>
                Notification
              </label>
              </Col>
                 </Row>
                 <Row style={{padding: '.2rem'}}>
        <Col sm={6}>
                <input
                  type="checkbox"
                  value="Notification"
                  checked={remOption.remindRecipients}
                  onChange={(e)=>{
                    setRemOption({...remOption,remindRecipients:e.currentTarget.checked});
                  }}
                  />
              <label style={{marginLeft:'1em'}}>
                Remind Recipients also
              </label>
              </Col>
                 </Row></>}
              {(remOption.remindRecipients==true || remOption.remindMe=="On every reply") && <Row>
                 <textarea
                 className='resizable-textarea'
                 value={remOption.note}
                //  type="paragraph" 
                 onChange={(e)=>{
                  setRemOption({...remOption,note:e.target.value})
                  console.log('remOptions',remOption);
                 }}
                 />
                 </Row>
              }
        </AntModal>
    </div>
  )
}