import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import "./HR.css";
// import 'antd/dist/reset.css';
import AntdRangePicker from "../../../common/DateRangePicker";
import AttendenceService from "../../../common/util/AttendenceService";
import UserService from "../../../common/util/UserService";
import { GlobalAgGridTable } from "../../Deal/AgGridReactComponent/GlobalAgGridTable";
import AppContext from "../../../Context/AppContext";
import { SelectedIdsForTimeClock, ActionsOfTimeClock as Actions, isDeleteEnable } from "../../../Context/AppActions";
import { getTimeInAMPMFormat, modifyTimesheetData } from "./Util";
import {  Modal as AntModal} from 'antd';
import Select from "react-select";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Draggable from "react-draggable";

const Where = (props) => {

  const userInfo = JSON.parse(localStorage.getItem('userinfo'));

  const getStartDate = () => {
    let startDate = new Date(Date.now());
    startDate.setDate(1);
    startDate.setHours(0, 0, 0);
    return startDate;
  }
  const getEndDate = () => {
    let endDate = new Date(Date.now());
    endDate.setDate(lastDateOFMonth(new Date().getFullYear(), new Date().getMonth()));
    endDate.setHours(0, 0, 0);
    return endDate;
  }
  const [editDeviceIdPopUp,setEditDeviceIdPopUp]=useState({show:false})
  const [refreshTable,setRefreshTable]=useState(false);
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const [deviceIds, setDeviceIds] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [cacheRowData, setCacheRowData] = useState([]);
  const [allusers, setAllUsers] = useState([]);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [gridApi, setGridApi] = useState(null);
  const [listSelectedRows, setlistSelectedRows] = useState([]);
  const [isEditColumnTrue, setIsEditColumnTrue] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [userList, setUserList] = useState([])
  const [modalPosition, setModalPosition] = useState({ x: 0, y: -45 });
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const columnsForRequestViewForUser = [
    {
      path: "UserName",
      label: "Name",
      field: "UserName",
      name: "Name",
      type: "text",
      id: 0,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
    },
    {
      key: "I/O",
      path: "I/O",
      label: "I/O",
      field: "I/O",
      name: "I/O",
      type: "text",
      id: 1,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
      content: (obj, key) => {
        let span = document.createElement("span");
        span.style.fontWeight = "bold";
        if (obj[key] == "IN") {
          span.innerText = "IN";
          span.style.color = "green";
        } else if (obj[key] == "OUT") {
          span.innerText = "OUT";
          span.style.color = "red";
        } else if (obj[key] == "AWAY") {
          span.innerText = "AWAY";
          span.style.color = "#f26c20";
        }
        return span;
      },
    },
    {
      path: "Date",
      label: "Date",
      field: "Date",
      name: "Date",
      type: "datetime",
      id: 2,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
    },
    {
      path: "IP",
      label: "IP",
      field: "IP",
      name: "IP",
      type: "text",
      id: 3,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
    },
    {
      key: "DeviceID",
      path: "DeviceID",
      label: "Device ID",
      field: "DeviceID",
      name: "Device ID",
      type: "text",
      id: 4,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
      content: (obj, key) => {
       
        let id=obj.DeviceROWID;
        let div = document.createElement("div");
        div.classList.add("DeviceId-Wrapper")
        div.innerHTML = `
              <span class="DeviceId" >${obj.DisplayCookie}</span>
              <span class="DeviceId-Hover" id=${id}>Edit</span>
              `
        div.querySelectorAll(".DeviceId-Hover").forEach(e => {
          e.addEventListener("click", (e) => {
               
            let obj=deviceIds?.find(d => d.ROWID == e.currentTarget.id);
            setEditDeviceIdPopUp({ROWID:obj.ROWID,oldId:obj.DisplayCookie,newId:"",show:true});
          })
        })
        if (userInfo && (userInfo?.role_details.role_name == 'App Administrator')) {
          return div;
        } else {
          return id;
        }
      }
    },
    {
      key: "Location",
      path: "Location",
      label: "Location Address",
      field: "Location",
      name: "Location Address",
      type: "text",
      id: 5,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
      content: (obj, key) => {
        let span = document.createElement("span");
        span.innerText = obj[key];
        span.title = obj[key];
        return span
      }
    },
  ];

  const [activeHeaderData, setActiveHeaderData] = useState([...columnsForRequestViewForUser])

  const ActionsOfTimeClock = state.ActionsOfTimeClock;
  const handleDrag = (e, ui) => {
    if(e.target && e.target.closest(".ant-picker-dropdown")){
      e.stopPropagation();
      return
      }
    const { x, y } = ui;
    setModalPosition({ x, y });
  };
  const fetchDeviceIds=()=>{
    AttendenceService.GetDeviceData().then(res => {
       ;
      let arr = res?.data?.map(data => data.DeviceID);
      setDeviceIds(arr);
    }).catch(e => {
      console.log(e);
    })
  }
  useEffect(() => {
    getAllUsers();
    // fetchDeviceIds();
    dispatch(isDeleteEnable(true))
  }, [])

  useEffect(() => {
    if (props.refreshEnable) {
      getAllAttendence(startDate, endDate);
      setEndDate(getEndDate())
      setStartDate(getStartDate())
    }
  }, [props.refreshEnable]);

  useEffect(e => {
    if (cacheRowData && cacheRowData.length > 0 && selectedUser) {
      if (selectedUser.id == "all") {
        setRowData(cacheRowData)
      } else {
        let arr = cacheRowData.filter(e => e.MailId == selectedUser.email);
        setRowData(arr)
      }
    }
  }, [selectedUser, props.refreshEnable, cacheRowData])

  const onChangeUserFilter = (user) => {
    setSelectedUser(user);
  }

  useEffect(() => {
    if (allusers.length > 0) {
      getAllAttendence(startDate, endDate);
    }

    if (allusers) {
      let arrOfUsers = allusers.map((item, i) => {
        return { value: item.id, label: item.full_name };
      });
      // if(userInfo && (userInfo?.role_details.role_name !== 'App User')){
      //   arrOfUsers.unshift({label: "All Users", value: "all"})
      // }
      if (userInfo && (userInfo?.role_details.role_name == 'App User')) {
        let filterUser = allusers.filter(one => one.email == userInfo?.email_id);
        setSelectedUser(filterUser[0]);
      } else {
        let newArr = allusers.splice(0,1)
        setSelectedUser(allusers[0]);
      }
      setUserList(arrOfUsers)
    }

  }, [allusers]);

  useEffect(() => {
    if (ActionsOfTimeClock == "Export") {
      exportListViewData("newExcelSheet.xlsx")
    } else if (ActionsOfTimeClock == "EditColumns") {
      setIsEditColumnTrue(true)
    }
    dispatch(Actions(""))
  }, [ActionsOfTimeClock])

  const exportListViewData = (fileName) => {
    gridApi?.exportDataAsExcel({
      fileName: fileName,
      onlySelected: true,
      processCellCallback: function (params) {
        const rowData = params.node.data;
        const columnField = params.column?.getColDef().field;
        return params.value;
      }
    });
  }
  const updateDeviceId=async ()=>{
     
    let obj=deviceIds?.find(d => d.ROWID == editDeviceIdPopUp.ROWID);
    if(obj){
      let payload={
        ...obj,
        DisplayCookie:editDeviceIdPopUp.newId
      }
       await AttendenceService.UpdateDeviceId(payload).then(res=>{
         ;
        setEditDeviceIdPopUp({show:false})
        // setDeviceIds(arr);
      })
      // fetchDeviceIds();
      getAllAttendence(new Date(startDate), new Date(endDate));
      setRefreshTable(!refreshTable)
    }
  }
  const entryModalPopUp = () => {
    return (
      <AntModal
        open={editDeviceIdPopUp.show}
        draggable
        title={`Edit Device ID`}
        maskClosable={false}
        width={300}
        className="Add-entry-popup"
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setEditDeviceIdPopUp({show:false})
        }}
        modalRender={(modal) => (
          <Draggable
            defaultPosition={modalPosition}
            position={{ x: modalPosition.x, y: modalPosition.y }}
            onStop={handleDrag}
          >
            <div>{modal}</div>
          </Draggable>
        )}
        footer={[
          <div
            className="col-sm-12"
          >
            <Button
              style={{ display: "inline", marginRight: "0.4rem" }}
              onClick={() => {
                setEditDeviceIdPopUp({show:false})
              }}
              key="Cancel"
              type="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => { 
                updateDeviceId();
                }
              }
              key="Save"
              type="primary"
              color="primary"
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Row className="m-1">
          <Col sm={4}>
            <label>Old Device ID:</label>
          </Col>
          <Col sm={8}>
            <input
              type="text"
              className="form-control form-control-sm h-100"
              style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.85)" }}
              onChange={(e) => {

              }}
              value={editDeviceIdPopUp ? editDeviceIdPopUp.oldId : ""}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="m-1">
          <Col sm={4}>
            <label>New Device ID:</label>
          </Col>
          <Col sm={8}>
            <input
              type="text"
              className="form-control form-control-sm h-100"
              style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.85)" }}
              onChange={(e) => {
                setEditDeviceIdPopUp({...editDeviceIdPopUp,newId:e.currentTarget.value})
              }}
              value={editDeviceIdPopUp ? editDeviceIdPopUp.newId : ""}
            // value={editTimeObj ? editTimeObj.in : ""}
            />

          </Col>
        </Row>
      </AntModal>
    );
  }
  const onSelectionChanged = (event) => {
    setGridApi(event.api)
    let listTypeRows = event.api.rowModel?.rowsToDisplay.filter(node => node.isSelected());
    if (listTypeRows.length == event.api.rowModel.rowsToDisplay.length && listSelectedRows.length < listTypeRows.length && listSelectedRows.length != 0) {
      event.api.deselectAll();
    } else {
      let array = [];
      event.api.forEachNode((node) => {
        // allNodes.push(node?.data)
        // const nodeRowId = node?.data?.id;
        // if (nodeRowId) {
        if (node.isSelected()) {
          array.push(node?.data)
        } else {
        }
        // }
      });
      setlistSelectedRows(listTypeRows);
      dispatch(SelectedIdsForTimeClock(array))
    }
  }
  const onFirstDataRendered = (event) => {
    dispatch(SelectedIdsForTimeClock([]))
  }


  const getAllUsers = () => {
    UserService.GetAllUsers()
      .then((result) => {
        result.data.users = result.data.users.filter(one => one.status == 'active');
        if (userInfo && (userInfo?.role_details.role_name == 'App User')) {
          let filterUser = result.data.users.filter(one => one.email == userInfo?.email_id);
          setAllUsers(filterUser);
          // setAllCacheUser(filterUser);
          // setSelectedUser(filterUser[0]);
          // setSelectedUserEmail(filterUser[0].email);
          // setSelectedUserName(filterUser[0].full_name)
          // setEditUsername({ UserName: filterUser[0].full_name });
        } else {
          result.data.users.unshift({ label: "All Users", value: "all", full_name: "All Users", id: "all" })
          setAllUsers(result.data.users);
          // setAllCacheUser(result.data.users);
        }
        // setAllUsers(result.data.users);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  function lastDateOFMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  const [SelectedDateRange, setSelectedDateRange] = useState(
    GetFormattedDate(startDate) + " - " + GetFormattedDate(endDate)
  );

  const changeDateRange = (item,dates) => {
    setStartDate(item.selection.startDate);
    setEndDate(item.selection.endDate);
    // setSelectionRange([item.selection]);
    toggle();
    setSelectedDateRange(
      GetFormattedDate(item.selection.startDate) +
      " - " +
      GetFormattedDate(item.selection.endDate)
    );
    let filterData = [];
    cacheRowData.forEach((one) => {
      if (
        (new Date(item.selection.startDate) <= new Date(one.Date) &&
        new Date(item.selection.endDate) >= new Date(one.Date)) || dates=="Clear"
      ) {
        filterData.push(one);
      }
    });
    // console.log("filterData", filterData);
    setRowData(filterData);
  };

  // console.log("rowData", rowData);

  const toggle = () => setPopoverOpen(!popoverOpen);

  function GetFormattedDate(date) {
    let dateObj = new Date(date);
    let d = dateObj.toString().split(" ");
    let month = d[1]; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return day + "/" + month + "/" + year;
  }

  const [allStart, setAllStart] = useState(new Date());
  const [allEnd, setAllEnd] = useState(new Date());
  const setAllDate = (data) => {
    data.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
    if (data.length > 0) {
      setAllStart(new Date(data[0].date))
      setAllEnd(new Date(data[data.length - 1].date))
    }
  }

  const getAllAttendence = async (startDate, endDate) => {
      
    let start = dayjs(startDate).format("DD/MM/YYYY");
    let end = dayjs(endDate).format("DD/MM/YYYY");
    await AttendenceService.GetDeviceData().then(async res => {
       
      let devices = res?.data?.map(data => data.DeviceID);
      setDeviceIds(devices);
      await AttendenceService.GetAttendenceAllUser(start, end, 0)
      .then(async(result) => {
        let users = [...allusers];
        let arr = [];
        let tempArr=[];
         
        await modifyTimesheetData(result?.data?.data,"whoisin").then(r=>{
          tempArr=r;
        }).catch(err=>{
          console.log(err)
        });
         
        tempArr?.forEach(obj => {
          let d = { ...obj }
          // if (d['checkIn'] == '-' || d['checkIn'] == '') {
          //   d.checkIn = "-"
          // } else {
          //   try {
          //     d.checkIn = getTimeInAMPMFormat(d['checkIn']);
          //   } catch (err) {
          //     d.checkIn = "-"
          //   }
          // }
          // if (d['checkOut'] == '-' || d['checkOut'] == '') {
          //   d.checkOut = "-"
          // } else {
          //   try {
          //     d.checkOut = getTimeInAMPMFormat(d['checkOut']);
          //   } catch (err) {
          //     d.checkOut = "-"
          //   }

          // }
          let checkIn = d['checkIn'];
          let checkOut = d['checkOut'];
          let filterUser = users.filter(one => one.email == d.email);
          let idPresent = devices?.find(k => k.DeviceCookie == d.DeviceId);
          if ((checkIn !== '-') && (checkOut !== '-') && filterUser.length > 0) {
            let obj1 = { UserName: filterUser[0].full_name, MailId: d.email }
            obj1['I/O'] = 'IN';
            obj1.IP = d.DeviceIp || "-";
            obj1.DeviceID = d.DeviceId;
            obj1.DisplayCookie = idPresent?.DisplayCookie;
            obj1.DeviceROWID = idPresent?.ROWID;
            obj1.Location = d.CheckInLocation;
            obj1.Date = d.TimesheetDate;
            let obj2 = { UserName: filterUser[0].full_name, MailId: d.email }
            obj2["I/O"] = 'OUT';
            obj2.IP = d.DeviceIp || "-";
            obj2.DeviceID = d.DeviceId;
            obj2.DisplayCookie = idPresent?.DisplayCookie;
            obj2.DeviceROWID = idPresent?.ROWID;
            obj2.Location = d.CheckOutLocation;
            obj2.Date = d.TimesheetDate;
            arr.push(obj1)
            arr.push(obj2)
          } else if ((checkIn !== '-')  && filterUser.length > 0) {
            let obj1 = { UserName: filterUser[0].full_name, MailId: d.email }
            obj1['I/O'] = 'IN';
            obj1.IP = d.DeviceIp || "-";

            obj1.Location = d.CheckInLocation;
            obj1.DeviceID = d.DeviceId;
            obj1.DisplayCookie = idPresent?.DisplayCookie;
            obj1.DeviceROWID = idPresent?.ROWID;
            obj1.Date = d.TimesheetDate;
            obj1["Live"] = true;
            arr.push(obj1)
          } else if ((checkOut !== '-')  && filterUser.length > 0) {
            let obj2 = { UserName: filterUser[0].full_name, MailId: d.email }
            obj2["I/O"] = 'OUT';
            obj2.IP = d.DeviceIp || "-";

            obj2.DeviceID = d.DeviceId;
            obj2.DisplayCookie = idPresent?.DisplayCookie;
            obj2.DeviceROWID = idPresent?.ROWID;
            obj2.Location = d.CheckOutLocation;
            obj2.Date = d.TimesheetDate;
            arr.push(obj2)
          }

        })
        arr = arr.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        arr.forEach((one) => {
          one.Date = GetFormattedDate1(new Date(one.Date))
        })
        if (userInfo && userInfo?.role_details.role_name == 'App User') {
          arr = arr.filter(one => one.MailId == userInfo?.email_id)
        }
        setRowData(arr);
        setCacheRowData(arr);
      })
      .catch((err) => {
        console.log(err);
      })
      
    }).catch(e => {
      console.log(e);
    })
    
  }
  useEffect(() => {
    getAllAttendence(new Date(startDate), new Date(endDate));
  }, [props.clockisActive])
  function GetFormattedDate1(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return month + "/" + day + "/" + year;
  }



  let date = new Date();
  const [fromFilterTextDate, setFromFilterTextDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [toFilterTextDate, setToFilterTextDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), date.getDate())
  );

  const extraComponentForTable = (e) => {
    return (
      <AntdRangePicker
        onChangeDateRange={onChangeDateRange}
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        allStart={cacheRowData[cacheRowData?.length-1]?.Date} allEnd={cacheRowData[0]?.Date}
      />
    );
  };

  const extraComponentToRight = (e) => {
    return (
      <div className="extra-component-request-toRight">
        <Select
          value={
            selectedUser
              ? { label: selectedUser.full_name, value: selectedUser.id }
              : { label: "All Users", value: "all" }
          }
          options={userList}
          placeholder="Select..."
          closeMenuOnSelect={true}
          onChange={(e) => {
            if (e.value == "all") {
              onChangeUserFilter({ full_name: e.label, id: e.value });
            } else {
              let filterUser = allusers.filter((one) => one.id == e.value);
              if (filterUser.length > 0) {
                onChangeUserFilter(filterUser[0]);
              }
            }
          }}
          // value={selectedUserEmail ? selectedUserEmail :""}
          menuPlacement="auto"
        />
      </div>
    );
  }



  const onChangeDateRange = (dates, datesString) => {
    if (datesString.length > 0) {
      let start = new Date(new Date(datesString[0]))
      let end = new Date(new Date(datesString[1]))
      changeDateRange({ selection: { startDate: start, endDate: end } },dates)
    }

  }

  return (
    <div>
      {entryModalPopUp()}
      <div className="HR-Header-Wrapper">
        <Row className="border-bottom" style={{ justifyContent: "end", height: "56px", alignItems: "center", marginRight: "0px", marginLeft: "0px" }}>
          {/* <Col sm={4}>
                    </Col> */}
          {/* <Col sm={4} style={{display:"flex",justifyContent:"center",height:"2rem"}} className="justify-content-start">
                            <AntdRangePicker onChangeDateRange={onChangeDateRange} defaultStartDate={startDate} defaultEndDate={endDate} allEnd={allEnd} allStart={allStart} />
                    </Col> */}
          <Col sm={3} style={{ display: "flex", justifyContent: "center", height: "52px", alignItems: "center" }} className="ml-auto">
            {/* <div className="text-right">
                            Refresh
                            <span
                                className="refresh-hrlist"
                                onClick={() => getAllAttendence(startDate, endDate)}
                            >
                                <FontAwesomeIcon className="refresh-icon" icon={faSyncAlt} />
                            </span>
                        </div> */}
          </Col>
          <Col sm={3} style={{ display: "flex", justifyContent: "end", paddingRight: "2rem" }}
            className="select-wrap-col"
          >
            {/* <Button
                    className="mr-3 request-btns"
                    onClick={() => {  
                      // setSelectedUserEmail(email);
                      setEditUsername('')
                      setModalPosition({ x: 0, y: -45 })
                      addPopupOpen("", "Add")
                    }}
                  >
                    Add Timesheet
                  </Button> */}

            {/* <Select
                    value={selectedUser ? {label : selectedUser.full_name, value: selectedUser.id} : {label : 'All Users', value: 'all'}}
                    options={userList}
                    placeholder="Select..."
                    closeMenuOnSelect={true}
                    onChange={(e) => { 
                      if (e.value == 'all') {
                        onChangeUserFilter({ full_name: e.label , id : e.value});
                      } else { 
                        let filterUser = allusers.filter(one =>  one.id == e.value);
                        if (filterUser.length > 0) {
                          onChangeUserFilter(filterUser[0]);
                        }
                      }
                    }}
                  // value={selectedUserEmail ? selectedUserEmail :""}
                  menuPlacement="auto"
                /> */}
          </Col>
          <Col sm={6}></Col>
        </Row>
      </div>

      <div className="hr-time-table">
        <div className="ag-theme-alpine" style={{ height: "100%" }}>
          <GlobalAgGridTable
            headers={activeHeaderData}
            onFirstDataRendered={onFirstDataRendered}
            onSelectionChanged={onSelectionChanged}
            setRowData={setRowData}
            cacheRowData={cacheRowData}
            module="Where"
            DropDownFields={["Name", "I/O"]}
            rowData={rowData}
            renderExtraComponent={extraComponentForTable}
            renderExtraComponentToRight={extraComponentToRight}
            refreshTable={refreshTable}
          />

          {/* <Table
                    id="request"
                    sortColumn={{ path: "", order: "asc" }}
                    data={func.sortDataAsc(rowData)}
                    columns={
                        columnsForRequestViewForUser
                    }
                /> */}
        </div>
      </div>
    </div>
  );
};
export default Where;
