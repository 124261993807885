import React, { useState, useEffect, useContext } from "react";
import { useParams, Prompt } from "react-router-dom";
import GetRecordById from "../../common/util/getRecordById";
import CreateClientForm from "./CreateClientForm";
import Config from "../../config.json";
import axios from "axios";
import "./CreateClient.css";
import { Col, Row } from "reactstrap";
import ClientSideNavBar from "./ClientSideNavBar/ClientSideNavBar";
import  {ClientLayoutChanged} from "../../Context/AppActions";
import AppContext from "../../Context/AppContext";
import ClientLayout from "./ClientLayout/ClientLayout";

const CreateClient = (props) => {
  const [color, setColor] = useState("#5b6c97");
  const formInputs = {
    _id: "",
    clientNumber: "",
    clientName: "",
    clientNickname: "",
    parentClient: "",
    clientStatus: true,
    priority: "",
    projectType: "",
    projectVolume: "",
    phone: "",
    website: "",
    billingaddress: "",
    shippingAddress: "",
    billingStreet: "",
    billingCity: "",
    billingHouseNo: "",
    billingState: "",
    billingCode: "",
    // billingCountry: "",
    ShippnigAddDifferent : false,
    shippingStreet: "",
    shippingCity: "",
    shippingHouseNo : "",
    shippingState: "",
    shippingCode: "",
    // shippingCountry: "",
    Color: color,
    contractPrice: "",
    tierNumber : "",
    billingTermsInfo : [],
    serviceAddersInfo : [],
  };
  const [UseremailAdd, setUserEmailAdd] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [clientName, setClientName] = useState("");
  const [formInputsValue, setFormInputsValue] = useState(formInputs);
  const [ActiveChecked, setActiveChecked] = useState(false);
  const [clientNickName, setClientNickName] = useState("");
  const [parentClient, setParentClient] = useState("");
  const [clientStatus, setClientStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [projectType, setProjectType] = useState("");
  const [projectVolume, setProjectVolume] = useState("");
  const [website, setWebsite] = useState("");
  const [billingStreet, setBillingStreet] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCode, setBillingCode] = useState("");
  // const [billingCountry, setBillingCountry] = useState("");
  const [ShippingStreet, setShippingStreet] = useState("");
  const [ShippingCity, setShippingCity] = useState("");
  const [ShippingState, setShippingState] = useState("");
  const [ShippingCode, setShippingCode] = useState("");
  // const [ShippingCountry, setShippingCountry] = useState("");
  const [layoutstate, setLayoutState] = useState("ClientInfo");
  const [updatedSections, setUpdateSectionState] = useState(["ClientInfo"]);
  // const [sidebarCardsStatus, setSidebarCardsStatus] = useState({});
  // const [billingTermsData, setBillingTermsData] = useState({
  //   numberOfTiers : "", 
  //   item : "", 
  //   cost : "",
  //   billingStageTask : "", 
  //   billingStageTaskStatus : "",
  // })
  const location = props.location;
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [clientFullServices, setClientFullServices] = useState([])
  const [billingTermsCost, setBillingTermsCost] = useState({
    // contractPrice : '',
    retainerCost : '',
    approvalCost : '',
    permittingCost : '',
    priorToSignOffCost : '',
    afterSignOffCost : '',
  })
  // const [listState, setListState] = useState("billing");
  // const [billinginfotogglestate, setBillinginfotogglestatee] = useState(false);
  // const [shippinginfotogglestate, setShippinginfotogglestate] = useState(false);
  // const [billingTermstogglestate, setBillingTermstogglestate] = useState(false);
  // const [serviceAdderstogglestate, setServiceAdderstogglestate] = useState(false);

  // const basicinfotoggle = () => {
  //   if(!billinginfotogglestate){
  //     setShippinginfotogglestate(false)
  //   }
  //   setListState("billing")
  //   setBillinginfotogglestatee(!billinginfotogglestate);
  // };
  // const shippinginfotoggle = () => {
  //   if(!shippinginfotogglestate){
  //     setBillinginfotogglestatee(false)
  //   }
  //   setListState("shipping")
  //   setShippinginfotogglestate(!shippinginfotogglestate);
  // };
  // const BillingTermsToggle = () => {
  //   if(!billingTermstogglestate){
  //     setServiceAdderstogglestate(false)
  //   }
  //   setListState("billingTerms")
  //   setBillingTermstogglestate(!billingTermstogglestate);
  // };
  // const ServiceAddersToggle = () => {
  //   if(!serviceAdderstogglestate){
  //     setBillingTermstogglestate(false)
  //   }
  //   setListState("serviceAdders")
  //   setServiceAdderstogglestate(!serviceAdderstogglestate);
  // };
 
  // const AppData = useContext(AppContext);
  // const { state, dispatch } = AppData || {}; 
  // const isSaveClicked = state.SaveClickedForClient;

  // useEffect(e=>{ 
  //   console.log("isSaveClicked : ", isSaveClicked);
  //   let data=JSON.parse(localStorage.getItem("data"));
  //   let obj={};
  //     Object.keys(data).forEach((one)=>{
  //       if(data[one]==''||data[one]==undefined){
  //         obj[one]='-1';
  //       }else{
  //         obj[one]=validation[one];
  //       }
  //     })
  // },[isSaveClicked])
  useEffect(e=>{ 
    console.log("layoutstate : ", layoutstate);
    dispatch(ClientLayoutChanged(layoutstate))
  },[layoutstate])

  const params = useParams();
  useEffect(() => {
    if (props.getParams) {
      props.getParams(params);
    }
  }, [location]);

  const handleChange = (e, type) => {
    if(e.target.name == "ShippnigAddDifferent"){
      setFormInputsValue({
        ...formInputsValue,
        [e.target.name]: e.target.checked,
      });
    }else{
      setFormInputsValue({
        ...formInputsValue,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setData = (dataObj) => {
    let obj = { ...formInputsValue };
    obj.clientName = dataObj.Account_Name;
    obj.parentClient = dataObj.Parent_Account
      ? {
          label: dataObj.Parent_Account?.name,
          value: dataObj.Parent_Account?.name,
        }
      : "";
    obj.phone = dataObj.Phone;
    obj.website = dataObj.Website;
    obj.billingStreet = dataObj.Billing_Street;
    obj.billingCity = dataObj.Billing_City;
    obj.billingHouseNo = dataObj.Billing_House_Number;
    obj.billingState = dataObj.Billing_State;
    obj.billingCode = dataObj.Billing_Code;
    // obj.billingCountry = dataObj.Billing_Country;
    obj.ShippnigAddDifferent = dataObj.Shipping_Address_Different_Than_Billing_Adderss;
    obj.shippingStreet = dataObj.Shipping_Street;
    obj.shippingCity = dataObj.Shipping_City;
    obj.shippingHouseNo = dataObj.Shipping_House_Number
    obj.shippingState = dataObj.Shipping_State;
    obj.shippingCode = dataObj.Shipping_Code;
    // obj.shippingCountry = dataObj.Shipping_Country;
    obj.billingaddress = dataObj.Billing_Address;
    obj.shippingAddress = dataObj.Shipping_Address;
    obj.Color = dataObj.Color;
    obj.contractPrice = dataObj.Contract_Price;
    obj.tierNumber = dataObj.Tier_Number;
    obj.clientNickname = dataObj.Client_Nick_Name;	
    obj.priority = dataObj.Priority	
    ? {
        label: dataObj.Priority,
        value: dataObj.Priority,
      }
    : "";	
    obj.projectVolume = dataObj.Project_Volume	
    obj.projectType = dataObj.Project_Type ? 
      {
        label: dataObj.Project_Type,
        value: dataObj.Project_Type,
      }
    : "";


    let serviceAddersData = [];
    if (dataObj.Service_Adders) {
      dataObj.Service_Adders.map((one) => {
        let obj = {};

        obj.billingStageTask = one.Adder_Billing_Stage;
        obj.billingStageStatus = one.Adder_Billing_Status;
        obj.cost = one.Service_Cost;
        obj.item = one.Service_Name;
        serviceAddersData.push(obj);
      });
    }

    obj.serviceAddersInfo = serviceAddersData

    let billingTermsData = [];
    if (dataObj.Billing_Terms) {
      dataObj.Billing_Terms.map((one) => {
        let obj = {};

        obj.billingStageTask = one.Billing_Stage_Task;
        obj.billingStageStatus = one.Billing_Stage_Task_Status;
        obj.cost = one.Cost;
        obj.item = one.Item;
        billingTermsData.push(obj);
      });
    }

    obj.billingTermsInfo = billingTermsData
    obj.id = dataObj.id;
    setFormInputsValue(obj);
  };

  useEffect(() => {
    ClientModal();
  }, [formInputsValue]);

    const getBillingTermsArrayOfObj = () => {
    let info = formInputsValue.billingTermsInfo ? [...formInputsValue.billingTermsInfo] : [];
    info.forEach((one, index, self) => {
      console.log("oneeeeeeeeeeeeee", one);
      let obj = {};
      obj.Billing_Stage_Task = one.billingStageTask;
      obj.Billing_Stage_Task_Status = one.billingStageStatus;
      obj.Cost = one.cost;
      obj.Item = one.item;
      // obj.PV_System_Id = one.pvSystemId;
      // obj.Number_Of_Modules = parseInt(one.noOfModules);
      // obj.Invertor_Model_Number = one.inverterModelNumber;
      // obj.PV_Module_Model = one.pvmodulemodel;
      self[index] = obj;
    });
    console.log("infoooooooo", info);
    return info;
  };

  const getServiceAddersArrayOfObj = () => {
    let info = formInputsValue.serviceAddersInfo ? [...formInputsValue.serviceAddersInfo] : [];
    info.forEach((one, index, self) => {
      let obj = {};
      obj.Adder_Billing_Stage = one.billingStageTask;
      obj.Adder_Billing_Status = one.billingStageStatus;
      obj.Service_Cost = one.cost;
      obj.Service_Name = one.item;
      // obj.PV_System_Id = one.pvSystemId;
      // obj.Number_Of_Modules = parseInt(one.noOfModules);
      // obj.Invertor_Model_Number = one.inverterModelNumber;
      // obj.PV_Module_Model = one.pvmodulemodel;
      self[index] = obj;
    });
    console.log("infottttt:", info);
    return info;
  };

  const ClientModal = () => {
    let obj = {
      // "Account_Number": clientNumber,
      Account_Name: formInputsValue.clientName,
      Parent_Account:
        typeof formInputsValue.parentClient == "object"
          ? formInputsValue.parentClient?.value
          : formInputsValue.parentClient,
      clientStatus: formInputsValue.clientStatus,
      Client_Nick_Name: formInputsValue.clientNickname,
      Priority: typeof formInputsValue.priority == "object"
      ? formInputsValue.priority?.value
      : formInputsValue.priority,
      Billing_Address: formInputsValue.billingaddress,
      Shipping_Address: formInputsValue.shippingAddress,
      Project_Type: formInputsValue.projectType?.value,
      Project_Volume: formInputsValue.projectVolume,
      Phone: formInputsValue.phone,
      Website: formInputsValue.website,
      Account_Site: formInputsValue.website,
      Billing_Street: formInputsValue.billingStreet,
      Billing_City: formInputsValue.billingCity,
      Billing_House_Number : formInputsValue.billingHouseNo,
      Billing_State: formInputsValue.billingState,
      Billing_Code: formInputsValue.billingCode,
      // Billing_Country: formInputsValue.billingCountry,
      Shipping_Address_Different_Than_Billing_Adderss : formInputsValue.ShippnigAddDifferent,
      Shipping_Street: formInputsValue.shippingStreet,
      Shipping_City: formInputsValue.shippingCity,
      Shipping_House_Number : formInputsValue.shippingHouseNo,
      Shipping_State: formInputsValue.shippingState,
      Shipping_Code: formInputsValue.shippingCode,
      // Shipping_Country: formInputsValue.shippingCountry,
      Color: formInputsValue.Color,
      Contract_Price : formInputsValue.contractPrice,
      Tier_Number : formInputsValue.tierNumber,
      Billing_Terms: getBillingTermsArrayOfObj(),
      Service_Adders : getServiceAddersArrayOfObj(),

    };

    if (formInputsValue.id) {
      obj = { ...obj, id: formInputsValue.id };
    }
    localStorage.setItem("data", JSON.stringify(obj));
  };
  const [services, setServices] = useState([]);
  const getClientServices = async (accountId) => {
    let config = {
      Type: "Get",
      Method: "Get",
      data: {
        Account_Id: accountId,
      },
    };
    await axios
      .post(Config.backend_url + "handleservice", { config: config })
      .then((e) => {
        let data = e.data;
        if (data) {
          setServices(data?.services);
          setClientFullServices(data?.fullService);
          // setBillingTermsCost(data?.billingTerms[0]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getJobData = (module, id) => {
    GetRecordById(module, id)
      .then((data) => { 
        setData(data[0]);
        getClientServices(data[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => { 
    if (params && params.action == "Update") {
      getJobData(params.module, params.id);
    }
  }, [location]);

  // Page refresh Code Start//
  const handleBeforeUnload = (event) => {
    const confirmationMessage = "Are you sure you want to leave this page?";
    event.preventDefault();
    event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage; // Gecko, WebKit, Chrome <34
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Page refresh Code End//

  const getPromtt = () => {
    if (location.state && !props.clientListStatus.ShouldSave) {
      return (
        <Prompt
          when={true}
          message="Are you sure you want to leave without changes save?"
        />
      );
    } else {
      return "";
    }
  };

  let properties = {
    color: color,
    setColor: setColor,
    UseremailAdd: UseremailAdd,
    setUserEmailAdd: setUserEmailAdd,
    phone: phone,
    setPhoneNumber: setPhoneNumber,
    clientNumber: clientNumber,
    setClientNumber: setClientNumber,
    clientName: clientName,
    setClientName: setClientName,
    clientNickName: clientNickName,
    setClientNickName: setClientNickName,
    parentClient: parentClient,
    setParentClient: setParentClient,
    priority: priority,
    setPriority: setPriority,
    ActiveChecked: ActiveChecked,
    setActiveChecked: setActiveChecked,
    projectType: projectType,
    setProjectType: setProjectType,
    projectVolume: projectVolume,
    setProjectVolume: setProjectVolume,
    website: website,
    setWebsite: setWebsite,
    billingStreet: billingStreet,
    setBillingStreet: setBillingStreet,
    billingCity: billingCity,
    setBillingCity: setBillingCity,
    billingState: billingState,
    setBillingState: setBillingState,
    billingCode: billingCode,
    setBillingCode: setBillingCode,
    // billingCountry: billingCountry,
    // setBillingCountry: setBillingCountry,
    ShippingStreet: ShippingStreet,
    setShippingStreet: setShippingStreet,
    ShippingCity: ShippingCity,
    setShippingCity: setShippingCity,
    ShippingState: ShippingState,
    setShippingState: setShippingState,
    ShippingCode: ShippingCode,
    setShippingCode: setShippingCode,
    // ShippingCountry: ShippingCountry,
    // setShippingCountry: setShippingCountry,
    clientStatus: clientStatus,
    setClientStatus: setClientStatus,
  };
  const resetAllFields = () => {
    setClientNumber("");
    setClientName("");
    setClientNickName("");
    setParentClient("");
    setPhoneNumber("");
    setPriority("");
    setActiveChecked(false);
    setProjectType("");
    setProjectVolume("");
    setWebsite("");
    setBillingStreet("");
    setBillingCity("");
    setBillingState("");
    setBillingCode("");
    // setBillingCountry("");
    setShippingStreet("");
    setShippingCity("");
    setShippingState("");
    setShippingCode("");
    // setShippingCountry("");
  };


  return (
    <div
    className="createuser-height"
    >
      {getPromtt()}
      <Row className="mar-15 createdeal-height2 Client-sidebar-wrapper">
        <Col sm={2} className="scrollingSideBar">
          <ClientSideNavBar
            setUpdateSectionState={setUpdateSectionState}
            updatedSections={updatedSections}
            location={location}
            setLayoutState={setLayoutState}
            layoutstate={layoutstate}
            formInputsValue={formInputsValue}
            setFormInputsValue={setFormInputsValue}
            // listState={listState}
            // setListState={setListState}
            // billinginfotogglestate={billinginfotogglestate}
            // setBillinginfotogglestatee={setBillinginfotogglestatee}
            // shippinginfotogglestate={shippinginfotogglestate}
            // setShippinginfotogglestate={setShippinginfotogglestate}
            // shippinginfotoggle={shippinginfotoggle}
            // basicinfotoggle={basicinfotoggle}
            // ServiceAddersToggle={ServiceAddersToggle}
            // BillingTermsToggle={BillingTermsToggle}
            // billingTermstogglestate={billingTermstogglestate}
            // setBillingTermstogglestate={setBillingTermstogglestate}
            // serviceAdderstogglestate={serviceAdderstogglestate}
            // setServiceAdderstogglestate={setServiceAdderstogglestate}
            // billingTermsData={billingTermsData}
            // setBillingTermsData={setBillingTermsData}
          />
        </Col>
        <Col
          sm={10}
          className="pad-l0"
        >
          <ClientLayout
            setUpdateSectionState={setUpdateSectionState}
            updatedSections={updatedSections}
            location={location}
            setLayoutState={setLayoutState}
            layoutstate={layoutstate}
            services={services}
            // billingTermsCost={billingTermsCost}
            // setBillingTermsCost={setBillingTermsCost}
            clientFullServices={clientFullServices}
            setClientFullServices={setClientFullServices}
            {...properties}
            handleChange={handleChange}
            formInputsValue={formInputsValue}
            setFormInputsValue={setFormInputsValue}
            // listState={listState}
            // setListState={setListState}
            // billinginfotogglestate={billinginfotogglestate}
            // setBillinginfotogglestatee={setBillinginfotogglestatee}
            // shippinginfotogglestate={shippinginfotogglestate}
            // setShippinginfotogglestate={setShippinginfotogglestate}
            // shippinginfotoggle={shippinginfotoggle}
            // basicinfotoggle={basicinfotoggle}
            // ServiceAddersToggle={ServiceAddersToggle}
            // BillingTermsToggle={BillingTermsToggle}
            // billingTermstogglestate={billingTermstogglestate}
            // setBillingTermstogglestate={setBillingTermstogglestate}
            // serviceAdderstogglestate={serviceAdderstogglestate}
            // setServiceAdderstogglestate={setServiceAdderstogglestate}
            // billingTermsData={billingTermsData}
            // setBillingTermsData={setBillingTermsData}
          />
        </Col>
      </Row>
      {/* 
      <CreateClientForm
        services={services}
        {...properties}
        handleChange={handleChange}
        formInputsValue={formInputsValue}
        setFormInputsValue={setFormInputsValue}
      /> */}
    </div>
  );
};

export default CreateClient;