import React, { useState,useEffect } from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Button,
  Col,
  FormGroup,
  Label,
  Input,
  CardBody,
  Collapse,
  Card,
} from "reactstrap";
// import { useHistory, withRouter, useLocation, Prompt } from 'react-router-dom';

function NYCProjectButton(props) {
  // const location = useLocation();
  // useEffect(()=>{
  //   // if(location.state.action != 'create'){
  //   //   if(location.state.open&&(location.state.open=='StopWorkTable')){
  //   //     nycProjecttoggle()
  //   //   }
  //   // }
  // },[location])
  const [nycProjectstate, setNycProjectstate] = useState(false);
  const nycProjecttoggle = () =>{
    props.setLayoutState("nycproject")
    props.setListState('nycproperty')
    setNycProjectstate(!nycProjectstate);
   } 
   
   useEffect(() => {
    props.layoutstate == "nycproject"
      ? setNycProjectstate(true)
      : setNycProjectstate(false);
  });
   
    return (
      <div className={props.isNycVisible== 0 || props.sidebarCardsStatus.SkipNycFlag ? "nycProject-hidden" : "nyc-project"}>
      <Button
  className={
    (props.layoutstate == "nycproject" ? "navbarbtn-active" : "navbarbtn-100")+" "
    +(props.sidebarCardsStatus.Preliminary ? "" : "pointer-class-deactive" ) 
  }
  onClick={() =>{
    props.updateTheSidebarState({type:'NavActivetab',status:'NycProject'})
    localStorage.setItem('currentsidebar',"NycProject");
    nycProjecttoggle() 
    }}
>
        NYC Project
      </Button>
      <Collapse isOpen={nycProjectstate}>
        <Card className="sidenav-card createdeal-card">
          <CardBody className="navbar-sublist-card">
          <div className={
          props.listState == "nycproperty"
            ? "sublist-text-active"
            : "sublist-text"
        } onClick={(e) => props.setListState('nycproperty')}>NYC Property Information</div>
        <div className={
          props.listState == "prevsolar"
            ? "sublist-text-active"
            : "sublist-text"
        }  onClick={(e) => props.setListState('prevsolar')}>Previous Solar Application Information</div>
        <div className={
          props.listState == "addnyc"
            ? "sublist-text-active"
            : "sublist-text"
        }  onClick={(e) => props.setListState('addnyc')}>Additional NYC Filling Information</div>
        <div className={
          props?.data?.withoutpermit == "No" ? 
          "disabled-subheader-div" : 
          props.listState == "violation"
            ? "sublist-text-active"
            : "sublist-text"
        }  onClick={(e) => props.setListState('violation')}>WWP Violation Information</div>
        <div className={
          props.listState == "asbestos"
            ? "sublist-text-active"
            : "sublist-text2"
        }  onClick={(e) => props.setListState('asbestos')}>Asbestos Investigation</div>

            
          </CardBody>
          </Card>
          </Collapse>
        </div>
    );
}

export default NYCProjectButton;