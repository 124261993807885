import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import FolderTree, { testData } from 'react-folder-tree';
import { useParams } from "react-router-dom";
import 'react-folder-tree/dist/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFile, faFileCsv, faFileExcel, faFilePdf, faTimes, faExclamation,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, Col, Row } from 'reactstrap';
import './DocumentTree.css';
// import HttpService from '../../../../services/http.service';
import {
    faAlignLeft,
    faAlignJustify,
    faUser,
    faTh,
    faPlus,
    faGrinAlt,
    faSignOutAlt,
    faCloudDownloadAlt, faDatabase, faCog, faUserEdit, faMinus

} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/js/dist/collapse";
import WorkdriveService from '../../common/util/WorkdriveService';
import Loader from '../../common/loader';
import Workdrive from '../Workdrive/workdrive';

const DocumentTree = (props) => {
    const params = useParams();
    const location=props.location
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    const [treeData, setTreeData] = useState([])
    const [loaderState, setLoaderState] = useState(false)
    const rootFolderId = "4snnd72dc47bb20d046428a2770f3cf318468"

    useEffect(() => {
        if (props.getParams) {
            props.getParams(params);
        }
    }, [location])
    useEffect(() => {
        GetSyncAllFiles(rootFolderId)

    }, [])

    const getFiles=(id)=>{
        return new Promise((resolve,reject)=>{
            WorkdriveService.GetFiles(id)
            .then((result) => {
                let arr = []
                result.data.forEach((one) => {
                    let obj = {}
                    obj.id = one.id;
                    obj.name = one.attributes.name;
                    obj.type = one.attributes.icon_class;
                    obj.children = [];
                    arr.push(obj)
                })
                resolve(arr);
            })
            .catch((err) => {
                reject(err);
            })
        })
       
    }


    const GetSyncAllFiles=async (id)=>{
        setLoaderState(true)
        let res=await getFiles(id);
        for(let i=0;i<res.length;i++){
            let res1=await  getFiles(res[i].id);
            res[i].children=res1;
            for(let j=0;j<res1.length;j++){
                let res2=await  getFiles(res1[j].id);
                res1[j].children=res2;
                for(let k=0;k<res2.length;k++){
                    let res3=await  getFiles(res2[k].id);
                    res2[k].children=res3;
                    
                }
                
            }
        }
        setLoaderState(false);
        setTreeData( res);
    }

    const DeleteIcon = ({ onClick: defaultOnClick, nodeData }) => {
        const { path, name, checked, isOpen, url, ...restData } = nodeData;
        const handleClick = () => {
            console.log('icon clicked:', { path, name, url, ...restData });
            defaultOnClick();
        };

        return <FaTimes onClick={handleClick} />;
    };
    const iconComponents = {
        DeleteIcon,
    };

    const onNameClick = ({ defaultOnClick, nodeData }) => {
        const {
          // internal data
          path, name, checked, isOpen,
          // custom data
          url, ...whateverRest
        } = nodeData;
        if (nodeData.type == 'file') {
          let data = JSON.stringify(nodeData)
          localStorage.setItem('file', data);
          window.open('/document/view', '_blank')
        }
    
      };

      const onTreeStateChange=(state, event)=>{
        if(state.isOpen){
            const data=[...treeData];
            WorkdriveService.GetFiles(state.id)
            .then((result) => {
                let arr = []
                result.data.forEach((one) => {
                    let obj = {}
                    obj.isOpen=true
                    obj.id = one.id;
                    obj.name = one.attributes.name;
                    obj.type = one.attributes.icon_class;
                    obj.children = [];
                    arr.push(obj)
                })
                data.forEach((one,index,self)=>{
                    if(one.id==state.id){
                        one.children=arr;
                        self[index]=one
                    }
                })
                setTreeData(data);
            })
            .catch((err) => {
                console.log(err);
            })
        }
        
      }
    return (

        <div className="  hrMenuLeft DocumentTreeContainer mx-3 text-left">
            <Workdrive data={{zohoworkdriveforcrm__Workdrive_Folder_ID:"4snndf1add4f710e14016ba6eb3d69bacb086"}}/>
           {/* {loaderState? <Loader/>:<></>}
            <div className="container m-0 p-3 project-task-card">
                <div id='documents '>
                    {treeData.map((one) => {
                        return <FolderTree
                            initOpenStatus='closed'
                            data={one}
                            onChange={ onTreeStateChange }
                             onNameClick={onNameClick}
                            iconComponent={iconComponents}
                            showCheckbox={false}
                        />
                    })}


                </div>

            </div> */}
        </div>


    );
}

export default DocumentTree;