import React, { useState, useEffect, useRef } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import GoogleMapReact from "google-map-react";
import Marker from "../../preliminary/propertyinformation/Marker";
import Geocode from "react-geocode";
import { color } from "highcharts";
import ReactInputMask from "react-input-mask";
import Config from '../../../../../../config.json'
import axios from "axios";
const OtherOwnerInfo = (props) => {


  
  const wrappNameCss={
    "display": "block",
    "white-space": "nowrap",
    "width": "11em",
    "overflow": "hidden",
    "text-overflow": "ellipsis",
    "cursor":"default"
  }
  const [zipValid,setZipValid]=useState();
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const [buttonState, setbuttonState] = useState(false);
  const placeInputRef = useRef(null);
  const [zoom, setZoom] = useState(16);
  const [mailInvalid, setMailInvalid] = useState();
  const [NumInvalid, setNumInvalid] = useState();
  const [skipOtherOwner, setSkipOtherOwner] = useState(false);
  const [validation,setValidation]=useState({
    othercompnyNme:'0',
    // otherAdd:'0',
    otherstreetName:'0',
    othercityName:'0',
    otherstateName:'0',
    otherzipCode:'0',
    offcrFrstNme:'0',
    offcrLast:'0',
    relatnOwner:'0',
    // otherphoneNumber:'0',
    phoneno:'0',
    otheremailAdd:'0'
  });

  const highliteEmptys=(data)=>{
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
      setValidation(ob)
  }

  const resetValidation=()=>{
    setValidation({
      phoneno:'0',
      othercompnyNme:'0',
      otherAdd:'0',
      otherstreetName:'0',
      othercityName:'0',
      otherstateName:'0',
      otherzipCode:'0',
      offcrFrstNme:'0',
      offcrLast:'0',
      relatnOwner:'0',
      // otherphoneNumber:'0',
      otheremailAdd:'0'
    })
  }
  const onChangePhoneNumber = (value) => {
    var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
    let onlyNo= value1.replace(/\D/g, '');
    if(onlyNo.length>10){
      return
    }
    if(onlyNo.length==10){
      setValidation({...validation,phoneno:'1'})
    }
    props.setOtherPhoneNumber(value);
    props.handleOnChangeDeal({currentTarget:{name:'otherphoneNumber',value:value}})
    
  };

  const onBlurPhoneNumber = (value) => {
    var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
    let onlyNo= value1.replace(/\D/g, '');
    if (onlyNo.length < 10) {
      setNumInvalid(true);
      setValidation({...validation,phoneno:'-1'});
    } else {
      setValidation({...validation,phoneno:'1'});
      setNumInvalid(false);
    }
  };

  let handlemailid = (email) => {
    // Email validation
    let validMail=false;
    let str=email.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
    props.setOtherEmailAdd(email);
    if (validMail) {
      setValidation({...validation,otheremailAdd:'1'})
      setMailInvalid(false);
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.
    } else {
      if (email.length == 0) {
        setValidation({...validation,otheremailAdd:'-1'})
        setMailInvalid(false);
      } else {
        setValidation({...validation,otheremailAdd:'-1'})
        setMailInvalid(true);
      }
    }
  };

  const onChangeEmail = (value) => {
    if(value.target.name=='otheremailAdd'){
      // Email validation
    let validMail=false;
    let str=value.target.value.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!str.startsWith('.')){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
      if (validMail) {
        setValidation({...validation,otheremailAdd:'1'})
      }
    }
    if (value.target.value.length == 0) {
      setMailInvalid(false);
    }
    props.setOtherEmailAdd(value.target.value);
  };

  Geocode.setApiKey("AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw");
  Geocode.setLanguage("en");
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("us");
  Geocode.setLocationType("ROOFTOP");
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();

  const buttontoggle = () => {
     
    props.settoggleState("otherownerinfo");
    props.setListState("otherownerinfo");
    setbuttonState(!buttonState);
  };

  const onChangeOtherAdd = (value) => {
    props.handleOnChangeDeal({currentTarget:{name:'otherAdd',value:value}});
    props.setOtherAdd(value);
  };

  const onChangeApt = (value) => {
    props.setOtherhouseNo(value);
    localStorage.setItem("mailinghouseNo", value);
  };

  const onBlurOtherAdd = (value) => {
    props.setOtherAdd(value);
    localStorage.setItem("otherAddress", value);

    Geocode.fromAddress(value).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;

        var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "";
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                houseNO = response.results[0].address_components[i].long_name;
                break;
              case "route":
                street = houseNO + " " + response.results[0].address_components[i].long_name;
                break;
              case "sublocality_level_1":
                city = response.results[0].address_components[i].long_name;
                break;
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                zipcode = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        if(street!==""){
          setValidation({...validation,otherAdd:'1',otherstreetName:'1',
          othercityName:'1',otherstateName:'1',otherzipCode:'1'})
        }
        let obj={}
        props.setOtherhouseNo("");
        // localStorage.setItem("otherhouseNo", houseNO);
        props.setOtherstreetName(houseNO + " " + street);
        localStorage.setItem("otherstreet", houseNO + " " + street);
        props.setOthercityName(city);
        localStorage.setItem("othercity", city);
        props.setOtherstateName(state);
        localStorage.setItem("otherstate", state);
        props.setOtherzipCode(zipcode);
        localStorage.setItem("otherzipcode", zipcode);
        props.setOtherCenter({ lat: lat, lng: lng });
        props.setOtherltitude(lat);
        localStorage.setItem("otherlat", lat);
        props.setOtherlngitude(lng);
        localStorage.setItem("otherlng", lng);
        
        obj.otherAdd=value;
        obj.otherhouseNo=houseNO;
        obj.otherstateName=state;
        obj.othercityName=city;
        obj.otherstreetName=street;
        obj.othercenter={ lat: lat, lng: lng };
        obj.otherltitude=lat;
        obj.otherlngitude=lng;
        obj.otherzipCode=zipcode;
        props.handleOnChangeDeal({currentTarget:{name:'Merge',value:obj}});
       
      },
      (error) => {
        console.error("Error Finding Place:" + error);
      }
    );
  };

  const setAllStates = () => {
    setbuttonState(true);
    props.setOtherCenter({
      lat: localStorage.getItem("otherlat"),
      lng: localStorage.getItem("otherlng"),
    });
    props.setOtherAdd(localStorage.getItem("otherAddress"));
    props.setOtherhouseNo(localStorage.getItem("otherhouseNo"));
    props.setOtherstreetName(localStorage.getItem("otherstreet"));
    props.setOthercityName(localStorage.getItem("othercity"));
    props.setOtherstateName(localStorage.getItem("otherstate"));
    props.setOtherzipCode(localStorage.getItem("otherzipcode"));
    props.setOtherltitude(localStorage.getItem("otherlat"));
    props.setOtherlngitude(localStorage.getItem("otherlng"));
    initPlaceAPI();
  };

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current
    );
    // new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
    //   let place = autocomplete.getPlace();
    //   setPlace({
    //     address: place.formatted_address,
    //     lat: place.geometry.location.lat(),
    //     lng: place.geometry.location.lng()
    //   });
    // });
  };

  useEffect(() => {
    setValidation({...validation,phoneno:'0'})
    props.toggleState == "otherownerinfo"
      ? setAllStates()
      : setbuttonState(false);
  }, [props.toggleState]);

  useEffect(() => {
    getData()
  },[props.data]);


  const getData=()=>{
    if(props.data!=null){
       props.setDHCR(props.data.dhcr);
       props.setNonProfit(props.data.nonProfit);
       props.setOtherAdd(props.data.otherAdd);
      //  props.setOtherCenter(props.data.MailingAdd);
       props.setOtherstreetName(props.data.otherstreetName);
      //  props.setOtherltitude(props.data.MailingAdd);
       props.setOtherlngitude(props.data.otherlngitude);
       props.setOthercityName(props.data.othercityName);
       props.setOtherstateName(props.data.otherstateName);
       props.setOtherzipCode(props.data.otherzipCode);
       props.setOtherhouseNo(props.data.otherhouseNo);
       props.setOtherPhoneNumber(props.data.otherphoneNumber);
       props.setOtherCompnyNme(props.data.othercompnyNme);
       props.setOffcrFrstNme(props.data.offcrFrstNme);
       props.setOffcrLast(props.data.offcrLast);
       props.setOffcrMiddle(props.data.offcrMiddle);
       props.setRelatnOwner(props.data.relatnOwner);
       props.setOtherEmailAdd(props.data.otheremailAdd);

      }
  }


  const handleOtherOwnerCheck = () => {
    if (skipOtherOwner) {
      props.setOtherComplete.otherownerinfo = true;
      return true;
    }
    if (
      props.data.othercompnyNme !== "" && props.data.othercompnyNme !== null &&
      props.data.othercompnyNme!==undefined && props.data.othercompnyNme!==null &&
      // props.otherAdd !== "" && props.otherAdd !== undefined &&
      props.data.otherstreetName !== "" && props.data.otherstreetName !== undefined && props.data.otherstreetName !== null &&
      props.data.othercityName !== "" && props.data.othercityName !== undefined && props.data.othercityName !== null &&
      props.data.otherstateName !== "" && props.data.otherstateName !== undefined && props.data.otherstateName !== null &&
      props.data.otherzipCode !== "" && props.data.otherzipCode !== undefined && props.data.otherzipCode !== null &&
      props.data.offcrFrstNme !== "" && props.data.offcrFrstNme !== undefined && props.data.offcrFrstNme !== null &&
      // props.offcrMiddle !== "" && props.offcrMiddle !== undefined &&
      props.data.offcrLast !== "" && props.data.offcrLast !== undefined && props.data.offcrLast !== null &&
      props.data.relatnOwner !== "" && props.data.relatnOwner !== undefined && props.data.relatnOwner !== null &&
      props.data.otherphoneNumber !== "" && props.data.otherphoneNumber !== undefined && props.data.otherphoneNumber !== null &&
      props.data.otheremailAdd !== "" && props.data.otheremailAdd !== undefined && props.data.otheremailAdd !== null
    ) {
      let allChecked=true;
      let vKeys=Object.keys(validation);
      for(let i=0;i<vKeys.length;i++){
        if(validation[vKeys[i]]=='-1'){
          allChecked=false ;
          break
        }
      }
      if(allChecked){
        props.setOtherComplete.otherownerinfo = true;
        return true;
      }
    }
    props.setOtherComplete.otherownerinfo = false;
    return false;
  };

  let valid=(emailtext)=>{
    for(let i=1;i<emailtext.length;i++){
      if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
        return false;
      }
    }
    return true;
  }

  const clearFileFromZohoFiles=async(data)=>{
    if(data.fileId){
      let name=data.filename.replace(".PDF",'')+'ARCHIVE.PDF'
      await axios.post(Config.backend_url+'archiveFile',
      {
        file_id:data.fileId,
        file_name:name,
        accessToken:localStorage.getItem('token')
      })
    }
  }
  useEffect(e=>{
    if(props.data.ownerType === "INDIVIDUAL"){
      setValidation({
        othercompnyNme:'0',
        // otherAdd:'0',
        otherstreetName:'0',
        othercityName:'0',
        otherstateName:'0',
        otherzipCode:'0',
        offcrFrstNme:'0',
        offcrLast:'0',
        relatnOwner:'0',
        // otherphoneNumber:'0',
        phoneno:'0',
        otheremailAdd:'0'
      })
    }
  },[props.ownerType])
  
  const obj={
    othercompnyNme:props.data.othercompnyNme,
    otherstreetName:props.data.otherstreetName,
    othercityName:props.data.othercityName,
    otherstateName:props.data.otherstateName,
    otherzipCode:props.data.otherzipCode,
    offcrFrstNme:props.data.offcrFrstNme,
    offcrLast:props.data.offcrLast,
    relatnOwner:props.data.relatnOwner,
    phoneno:props.data.otherphoneNumber,
    otheremailAdd:props.data.otheremailAdd
  }

  return (
    <Col sm={12}>
      <Button
        onClick={buttontoggle}
        className={buttonState ? "btn-100-active" : "btn-100"}
        disabled={props.data.ownerType === "INDIVIDUAL" ? true : false}
      >
        Other Owner Information
        <div className="float-right">
          <Input
            type="checkbox"
            className={
              handleOtherOwnerCheck()
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={props.data.ownerType != "INDIVIDUAL" ? handleOtherOwnerCheck() : true}
            onChange={() =>{ 
               
              setSkipOtherOwner(!skipOtherOwner) 
              // if(zipValid==undefined||zipValid==false){setZipValid(false)}
              // if(mailInvalid==undefined||mailInvalid==false){setMailInvalid(false)}
              // if(NumInvalid==undefined||NumInvalid==false){setNumInvalid(false)}
              let vKeys = Object.keys(obj);
              let ob={};
              for (let i = 0; i < vKeys.length; i++) {
                // if (validation[vKeys[i]] == "-1" || validation[vKeys[i]] == "0" ) {
                //   highliteEmptys({ arr: validation });
                //   return;
                // }
                if (obj[vKeys[i]] == '' || obj[vKeys[i]] == undefined ) {
                  ob[vKeys[i]] = "-1";
                } else {
                  ob[vKeys[i]] = "1";
                }
                if(vKeys[i]=="phoneno" && NumInvalid){
                  ob={...ob,phoneno:'-1'}
                }
                if(vKeys[i]=="otheremailAdd" && mailInvalid){
                  ob={...ob,otheremailAdd:'-1'}
                }
                if(vKeys[i]=="otherzipCode" && zipValid==false){
                  ob={...ob,otherzipCode:'-1'}
                }
              }
              setValidation(ob);
            }}
              disabled={props.data.ownerType === "INDIVIDUAL" ? true : false}
          />
          {buttonState ? (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleDown}
            />
          ) : (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleRight}
            />
          )}
        </div>
      </Button>
      <Collapse isOpen={buttonState}>
        <Card className="createdeal-card">
          <CardBody>
            {/* <FormGroup className="marb-16 ">
              <div className="col-12">
              <Row>
                <Col sm={2} style={{paddingLeft : "0px"}}>
                  <a
                    href="https://a836-acris.nyc.gov/DS/DocumentSearch/BBL"
                    target="_blank"
                  >
                    <Button className="continue-button" style={{width : "6rem"}}>ACRIS</Button>
                  </a>
                </Col>
                <Col sm={10}></Col>
              </Row>
              </div>
              
            </FormGroup> */}

            <Row>
              <Col sm={10}>
                <FormGroup className="marb-16 padtop-15">
                  <Row>
                    <Col sm={3}>
                      <Label className="text-bold" for="non-profit">
                        Non-profit<span className="red-text">*</span>
                      </Label>
                    </Col>
                    <Col sm={2} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input
                        type="radio"
                        name="nonProfit"
                        value="Yes"
                        checked={props.data?props.data.nonProfit === "Yes":false}
                        onChange={(e) => {props.handleOnChangeDeal(e); props.setNonProfit(e.target.value)}}
                      />
                    </Col>

                    <Col sm={2} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input
                        type="radio"
                        name="nonProfit"
                        value="No"
                        checked={props.data?props.data.nonProfit === "No":false}
                        onChange={(e) => {
                          if(props.sidebarCardsStatus.DeedCertificate!=null){
                            if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                              e.preventDefault();
                            }else{
                              if(props.sidebarCardsStatus.DeedCertificate.filename != '' && props.sidebarCardsStatus.DeedCertificate.file==null){    
                                props.updateTheSidebarState({type:"RemoveFilesArray",status: props.sidebarCardsStatus.DeedCertificate.filename})
                              }
                              document.getElementById('deedCertificate').value='';
                              props.updateTheSidebarState({type:'DeedCertificateUpload',status:null})
                              props.handleOnChangeDeal(e);props.setNonProfit(e.target.value)
                            }
                          }else{
                            props.handleOnChangeDeal(e);props.setNonProfit(e.target.value)
                          }
                          }
                          }
                      />
                    </Col>
                    {props.data.nonProfit == "Yes"?
                    <Col sm={3}>
                      <Input type="file" id="deedCertificate" hidden onChange={(e)=>{
                        if(e.target.files[0].type!='application/pdf'){
                          alert('Please select a PDF file!')
                          e.preventDefault();
                          return
                        }
                        let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                        let arr=e.target.value.split('\\');
                        props.updateTheSidebarState({type:'DeedCertificateUpload',status:{
                          filename:arr[arr.length-1],
                          file:e.target.files[0],
                        }})
                      }}/>
                      {
                        props.sidebarCardsStatus.DeedCertificate!=null?
                        <Button className="continue-button"
                      onClick={(e)=>{
                        if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                          e.preventDefault();
                        }else{
                          clearFileFromZohoFiles({
                            ...props.sidebarCardsStatus.DeedCertificate
                          })
                          if(props.sidebarCardsStatus.DeedCertificate.filename != '' && props.sidebarCardsStatus.DeedCertificate.file==null){    
                            props.updateTheSidebarState({type:"RemoveFilesArray",status: props.sidebarCardsStatus.DeedCertificate.filename})
                          }
                          document.getElementById('deedCertificate').value='';
                          props.updateTheSidebarState({type:'DeedCertificateUpload',status:null})
                        }
                      }}
                      >Clear</Button>:
                      <Button className="continue-button"
                      onClick={(e)=>{
                        document.getElementById('deedCertificate').click();
                      }}
                      >Upload Deed</Button>
                      }
                      {props.sidebarCardsStatus.DeedCertificate!=null?<span title={props.sidebarCardsStatus.DeedCertificate.filename} style={{fontSize:'13px',...wrappNameCss}}> {props.sidebarCardsStatus.DeedCertificate.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
                    </Col>:<></>
                    }
                  </Row>
                </FormGroup>
                <FormGroup className="marb-16 padtop-15">
                  <Row>
                    <Col sm={3}>
                      <Label className="text-bold" for="dhcr">
                        DHCR<span className="red-text">*</span>
                      </Label>
                    </Col>
                    <Col sm={2} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input
                        type="radio"
                        name="dhcr"
                        value="Yes"
                        checked={props.data.dhcr === "Yes"}
                        onChange={(e) =>{props.handleOnChangeDeal(e); props.setDHCR(e.target.value)}}
                      />
                    </Col>

                    <Col sm={2} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input
                        type="radio"
                        name="dhcr"
                        value="No"
                        checked={props.data.dhcr === "No"}
                        onChange={(e) =>{
                          if(props.sidebarCardsStatus.DHCRLetter!=null){
                            if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                              e.preventDefault();
                            }else{
                              if(props.sidebarCardsStatus.DHCRLetter.filename != '' && props.sidebarCardsStatus.DHCRLetter.file==null){    
                                props.updateTheSidebarState({type:"RemoveFilesArray",status: props.sidebarCardsStatus.DHCRLetter.filename})
                              }
                              document.getElementById('DHCRLetter').value='';
                              props.updateTheSidebarState({type:'DHCRLetterUpload',status:null})
                              props.handleOnChangeDeal(e); props.setDHCR(e.target.value)
                            }
                          }else{
                            props.handleOnChangeDeal(e); props.setDHCR(e.target.value)
                          }
                        }
                      }
                      />
                    </Col>
                    {props.data.dhcr=="Yes"?
                    <Col sm={3}>
                    <Input type="file" id="DHCRLetter" hidden onChange={(e)=>{
                        if(e.target.files[0].type!='application/pdf'){
                          alert('Please select a PDF file!')
                          e.preventDefault();
                          return
                        }
                        let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                        let arr=e.target.value.split('\\');
                        props.updateTheSidebarState({type:'DHCRLetterUpload',status:{
                          filename:arr[arr.length-1],
                          file:e.target.files[0],
                        }})
                      }}/>
                      {
                        props.sidebarCardsStatus.DHCRLetter!=null?
                      <Button className="continue-button"
                      onClick={(e)=>{
                        if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                          e.preventDefault();
                        }else{
                          clearFileFromZohoFiles({
                            ...props.sidebarCardsStatus.DHCRLetter
                          })
                          if(props.sidebarCardsStatus.DHCRLetter.filename != '' && props.sidebarCardsStatus.DHCRLetter.file==null){    
                            props.updateTheSidebarState({type:"RemoveFilesArray",status: props.sidebarCardsStatus.DHCRLetter.filename})
                          }
                          document.getElementById('DHCRLetter').value='';
                          props.updateTheSidebarState({type:'DHCRLetterUpload',status:null})
                        }
                      }}
                      >
                        Clear
                      </Button>:
                      <Button className="continue-button"
                      onClick={(e)=>{
                        document.getElementById('DHCRLetter').click();
                      }}
                      >
                        Upload DHCR Letter
                      </Button>

                      }
                      {props.sidebarCardsStatus.DHCRLetter!=null? <span title={props.sidebarCardsStatus.DHCRLetter.filename} style={{fontSize:'13px',...wrappNameCss}}>{props.sidebarCardsStatus.DHCRLetter.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
                    </Col>:<></>
                    }
                  </Row>
                </FormGroup>

                <FormGroup className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold " for="projectnumber">
                    Company Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={7}>
                  <Input
                    type="text"
                    name="othercompnyNme"
                    id="projectnumber"
                    className=" createdeal-textfield"
                    style={validation.othercompnyNme=='-1'?{...validationCss}:{}}
                    value={props.data.othercompnyNme}
                    onBlur={(e)=>{
                      if(e.target.value==undefined||e.target.value==''){
                        setValidation({...validation,othercompnyNme:'-1'})
                      }else{
                        setValidation({...validation,othercompnyNme:'1'})
                      }
                    }}
                    onChange={(e) => {
                      e.target.value=e.target.value.trimStart().replace(/[_!#@$%^&*()<>?|}{~:]/,'');
                      if(e.target.value==undefined||e.target.value==''){
                        setValidation({...validation,othercompnyNme:'-1'})
                      }else{
                        setValidation({...validation,othercompnyNme:'1'})
                      }
                      props.handleOnChangeDeal(e);
                      props.setOtherCompnyNme(e.target.value)}}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold " for="projectnumber">
                    Company Address
                  </Label>
                </Col>
                <Col sm={7}>
                  <input
                    type="text"
                    className="address-text2 form-control"
                    ref={placeInputRef}
                    // style={validation.otherAdd=='-1'?{...validationCss}:{}}
                    value={props.data.otherAdd}
                    onChange={(e) =>{ 
                      e.target.value=e.target.value.trimStart();
                      onChangeOtherAdd(e.target.value)}}
                    onBlur={(e) =>{ 
                     
                        // if(e.target.value==undefined||e.target.value==''){
                        //   setValidation({...validation,otherAdd:'-1'})
                        // }else{
                        //   setValidation({...validation,otherAdd:'1'})
                        // }
                    
                      onBlurOtherAdd(e.target.value)}}
                  />
                </Col>
              </Row>
            </FormGroup>

              </Col>
            </Row>

           

            <FormGroup className="marb-16">
              <Row>
                <Col sm={6}>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          Street Address<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.otherstreetName}
                          name="otherstreetName"
                          style={validation.otherstreetName=='-1'?{...validationCss}:{}}
                          id="housenumber"
                          className="createdeal-textfield"
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,otherstreetName:'-1'})
                            }else{
                              setValidation({...validation,otherstreetName:'1'})
                            }
                          }}
                          onChange={(e) =>
                            {  e.target.value=e.target.value.trimStart().replace(/[_!#@$%^&*()<>?|}{~:]/,'');
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,otherstreetName:'-1'})
                            }else{
                              setValidation({...validation,otherstreetName:'1'})
                            }
                            props.handleOnChangeDeal(e)
                            props.setOtherstreetName(e.target.value)}
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          Apt, Suite, Bldg, Unit, etc.
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.otherhouseNum}
                          onChange={(e) =>{ 
                            e.target.value=e.target.value.trimStart().replace(/[_!#@$%^&*()<>?|}{~:]/,'');
                            props.handleOnChangeDeal(e); 
                            onChangeApt(e.target.value)}}
                          name="otherhouseNum"
                          id="housenumber"
                          className="createdeal-textfield"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="projectnumber">
                          City<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.othercityName}
                          style={validation.othercityName=='-1'?{...validationCss}:{}}
                          name="othercityName"
                          id="projectnumber"
                          className="createdeal-textfield"
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,othercityName:'-1'})
                            }else{
                              setValidation({...validation,othercityName:'1'})
                            }
                          }}
                          onChange={(e) =>
                            {
                              e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                              e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                              e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                              if(e.target.value==undefined||e.target.value==''){
                                setValidation({...validation,othercityName:'-1'})
                              }else{
                                setValidation({...validation,othercityName:'1'})
                              }
                            props.handleOnChangeDeal(e);
                              props.setOthercityName(e.target.value)
                            }
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          State<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.otherstateName}
                          name="otherstateName"
                          style={validation.otherstateName=='-1'?{...validationCss}:{}}
                          id="housenumber"
                          className="createdeal-textfield"
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,otherstateName:'-1'})
                            }else{
                              setValidation({...validation,otherstateName:'1'})
                            }
                          }}
                          onChange={(e) =>
                            {
                              e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                              e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                              e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                              if(e.target.value==undefined||e.target.value==''){
                                setValidation({...validation,otherstateName:'-1'})
                              }else{
                                setValidation({...validation,otherstateName:'1'})
                              }
                            props.handleOnChangeDeal(e);
                              props.setOtherstateName(e.target.value)
                            }
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          Zip Code<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.otherzipCode}
                          name="otherzipCode"
                          id="housenumber"
                          className=" createdeal-textfield"
                          style={validation.otherzipCode=='-1'?{...validationCss}:{}}
                          onChange={(e) =>
                           {
                            e.target.value=e.target.value.trimStart();
                            e.target.value=e.target.value.replace(/\D/g, '');
                            if(e.target.value.length>5){
                              e.preventDefault()
                              return
                            }
                            if(e.target.value.length==5){
                              setValidation({...validation,otherzipCode:'1'})
                              setZipValid(true);
                            }
                            props.handleOnChangeDeal(e);
                              props.setOtherzipCode(e.target.value)
                            }
                          }
                          onBlur={(e)=>{
                             
                            if(props.data.otherzipCode?.length<5){
                              setValidation({...validation,otherzipCode:'-1'})
                              setZipValid(false);
                            }
                          }}
                        />
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={7}><span className={validation.otherzipCode=='-1'?"red-text font12":"error-display"}> 5 Digit Zip code required.</span></Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownerfirstname">
                          Officer's First Name
                          <span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          name="offcrFrstNme"
                          id="ownerfirstname"
                          style={validation.offcrFrstNme=='-1'?{...validationCss}:{}}
                          className="createdeal-textfield"
                          value={props.data.offcrFrstNme}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,offcrFrstNme:'-1'})
                            }else{
                              setValidation({...validation,offcrFrstNme:'1'})
                            }
                          }}
                          onChange={(e) =>
                            {
                              e.target.value=e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                              e.target.value=e.target.value.replace(/[^\D]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^\D]/g,'').slice(1)
                              if(e.target.value==undefined||e.target.value==''){
                                setValidation({...validation,offcrFrstNme:'-1'})
                              }else{
                                setValidation({...validation,offcrFrstNme:'1'})
                              }
                            props.handleOnChangeDeal(e);
                            props.setOffcrFrstNme(e.target.value)}
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownermiddlename">
                          Officer's Middle Initial
                          {/* <span className="red-text">*</span> */}
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          name="offcrMiddle"
                          id="ownermiddlename"
                          className="createdeal-textfield"
                          value={props.data.offcrMiddle}
                          onChange={(e) =>{
                            e.target.value=e.target.value.trimStart();
                            e.target.value=e.target.value.replace(/[^a-zA-Z]/g,'').toUpperCase()
                            props.handleOnChangeDeal(e); 
                            props.setOffcrMiddle(e.target.value)}}
                          maxLength={1}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownerlastname">
                          Officer's Last Name<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          name="offcrLast"
                          id="ownerlastname"
                          style={validation.offcrLast=='-1'?{...validationCss}:{}}
                          className="createdeal-textfield"
                          value={props.data.offcrLast}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,offcrLast:'-1'})
                            }else{
                              setValidation({...validation,offcrLast:'1'})
                            }
                          }}
                          onChange={(e) =>{
                            e.target.value=e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.replace(/[^\D]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^\D]/g,'').slice(1)
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,offcrLast:'-1'})
                            }else{
                              setValidation({...validation,offcrLast:'1'})
                            }
                            props.handleOnChangeDeal(e); 
                            props.setOffcrLast(e.target.value)}}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="phoneno">
                          Officer Title
                         <small> (Relationship to Owner)</small> 
                          <span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          name="relatnOwner"
                          id="phoneno"
                          style={validation.relatnOwner=='-1'?{...validationCss}:{}}
                          className="createdeal-textfield"
                          value={props.data.relatnOwner}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,relatnOwner:'-1'})
                            }else{
                              setValidation({...validation,relatnOwner:'1'})
                            }
                          }}
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.replace(/[^\D]/g,'')
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,relatnOwner:'-1'})
                            }else{
                              setValidation({...validation,relatnOwner:'1'})
                            }
                            props.handleOnChangeDeal(e);
                            props.setRelatnOwner(e.target.value)}}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownername">
                          Phone Number<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <ReactInputMask
                          type="text"
                          mask="(999) 999-9999"
                          maskChar='_'
                          className="form-control arrow-hide createdeal-textfield"
                          name="text"
                          id="phoneNumberClass"
                          placeholder="(###) ###-####"
                          value={props.data.otherphoneNumber}
                          style={validation.phoneno=='-1'?{...validationCss}:{}}
    
                          onKeyDown={(evt) =>{
                            ((evt.keyCode > 64 && evt.keyCode < 91) ||
                              evt.key === ".") &&
                            evt.preventDefault()
                          }
                          }
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart();
                            onChangePhoneNumber(e.target.value)
                          }
                          }
                          onBlur={(e) =>{
                              // var v1 = e.target.value.replace(/[-#*$+/?=%() ]/g, "");
                              // let onlyNo= v1.replace(/\D/g, '');
                              // onlyNo.length<10?setValidation({...validation,phoneno:'-1'}):setValidation({...validation,phoneno:'1'})
                              onBlurPhoneNumber(e.target.value)
                            }
                          }
                        />
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={7}><span className={validation.phoneno=='-1'?"red-text font12":"error-display"}> Enter 10 digit Phone Number.</span></Col>
                      
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownername">
                          Email Address<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          placeholder="abc@mail.com"
                          className="createdeal-textfield"
                          value={props.data.otheremailAdd}
                          style={validation.otheremailAdd=='-1'?{...validationCss}:{}}
                          onChange={(evt) => {
                            evt.target.value=evt.target.value.trimStart();
                            props.handleOnChangeDeal(evt); 
                            onChangeEmail(evt)}}
                          onBlur={(e) => handlemailid(e.target.value)}
                          name="otheremailAdd"
                          id="ownername"
                        />
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={7}><span className={validation.otheremailAdd=='-1'?"red-text font12":"error-display"}> Incorrect E-mail Address format.</span></Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col sm={6} className="map-padding">
                  <GoogleMapReact
                    defaultCenter={props.othercenter}
                    center={props.othercenter}
                    defaultZoom={zoom}
                  >
                    <Marker
                      lat={props.othercenter?props.othercenter.lat:''}
                      lng={props.othercenter?props.othercenter.lng:''}
                      name="My Marker"
                      color="blue"
                    />
                  </GoogleMapReact>
                </Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default OtherOwnerInfo;