import httpService from "../../services/http.service";
function handleUpdate(module,data) {
    return new Promise((resolve, reject) => {
        let config = {
            Type: 'Create',
            method: 'POST',
            url: "https://www.zohoapis.com/crm/v2/" + module + "/upsert",
            headers: { "Authorization": localStorage.getItem('token') },
            data: [data]
        }
        httpService.post('workdrive', config)
            .then((result) => {
                if(result.data&&result.data.data){
                    resolve(result.data.data[0]);
                }else{
                    resolve({});
                }
            })
            .catch((err) => {
                reject(err);
            })
    })


}

export default handleUpdate;