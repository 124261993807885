import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Button,
  Input,
  Row,
  ModalHeader,
  ModalFooter,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  PopoverBody,
  Popover,
  UncontrolledPopover,
  UncontrolledTooltip,
  UncontrolledCollapse,
} from "reactstrap";
import { Tabs, Tab, Nav } from "react-bootstrap";
import swal from "sweetalert";
//import "./profileView.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCamera, faChevronLeft, faEye, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import HttpService from "../../services/http.service";
import Geocode from "react-geocode";

const Profile = (props) => {
  const regexMatch = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
  const [selectedTable, setSelectedTable] = useState("MySettings");
  const [key, setKey] = useState("MyProfile");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loginData, setLoginData] = useState({});
  const [newObj, setNewObj] = useState({});
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [error, setError] = useState(null);
  const [passwordView, setPasswordView] = useState({ New: 'password', Confirm: 'password' })

  

  const setFilterStreet = (street) => {
    var str = street.replace(/(\d+)(st|nd|rd|th)/g, "$1");
    return str;
  };

  const onBlurProjectAdd = (value) => {
    Geocode.fromAddress(value).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;
        let obj = {}
        var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "",
          borough = "",
          neighbourhood = "";

        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                houseNO = response.results[0].address_components[i].long_name;
                break;
              case "route":
                street = response.results[0].address_components[i].long_name;
                break;
              case "neighborhood":
                neighbourhood = response.results[0].address_components[i].long_name;
                break;
              case "sublocality_level_1":
                borough = response.results[0].address_components[i].long_name;
                if (
                  borough == "Manhattan" ||
                  borough == "Bronx" ||
                  borough == "Brooklyn" ||
                  borough == "Queens" ||
                  borough == "Staten Island"
                ) {


                  if (borough == "Manhattan") {

                    localStorage.setItem("borough", "1");
                    city = "New York";
                  } else if (borough == "Bronx") {


                    localStorage.setItem("borough", "2");
                    city = borough;
                  } else if (borough == "Brooklyn") {


                    localStorage.setItem("borough", "3");
                    city = borough;
                  } else if (borough == "Queens") {


                    localStorage.setItem("borough", "4");
                    city = neighbourhood;
                  } else if (borough == "Staten Island") {


                    localStorage.setItem("borough", "5");
                    city = borough;
                  } else {

                    localStorage.setItem("borough", "0");
                  }
                } else {

                  localStorage.setItem("nycOption", "0");
                  localStorage.setItem("borough", "");
                  city = response.results[0].address_components[i].long_name;
                }
                break;
              case "locality":
                if (
                  borough != "Manhattan" &&
                  borough != "Bronx" &&
                  borough != "Brooklyn" &&
                  borough != "Queens" &&
                  borough != "Staten Island"
                ) {
                  city = response.results[0].address_components[i].long_name;


                  localStorage.setItem("nycOption", "No");
                  localStorage.setItem("borough", "");
                }

                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                zipcode = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        var str = value;
        var lastIndex = str.lastIndexOf(",");
        var res = str.substring(lastIndex);
        if (lastIndex > 0) {
          if (res.includes("USA")) {
            str = str.substring(0, lastIndex);
            str = str + " " + zipcode;
          }
        }

        obj.Address = str;


        var filterStreet = setFilterStreet(street)

        localStorage.setItem("houseNo", houseNO);


        localStorage.setItem("street", filterStreet);


        localStorage.setItem("city", city);
        obj.City = city;


        localStorage.setItem("state", state);
        obj.State = state;


        obj.Zip = zipcode;

        localStorage.setItem("zipcode", zipcode);



        localStorage.setItem("lat", lat);

        localStorage.setItem("lng", lng);
        console.log(lat, lng);

        props.updateUserData({ currentTarget: { name: 'Address', value: obj } })
      },
      (error) => {
        console.error("Error Finding Place:" + error);
      }
    );
  };

  const onChangePhoneNumber = (e) => {
    let profileData = { ...props.loginData };
    var value1 = e.currentTarget.value.replace(/[-#*$+/?=%() ]/g, "");
    if (value1.length < 10) {

      profileData[e.currentTarget.name] = e.currentTarget.value;
    }
    if (value1.length == 12) {

      profileData[e.currentTarget.name] = "";
    }
    if (value1.length == 10) {
      profileData[e.currentTarget.name] = "(" +
        value1.substring(0, 3) +
        ") " +
        value1.substring(3, 6) +
        "-" +
        value1.substring(6, 10);
    }
    props.setLoginData(profileData);
  }

  const updateUserData = async (e) => {
    let profileData = { ...loginData };
    if (e.currentTarget.name == 'Address') {
      let mergeObj = { ...profileData, ...e.currentTarget.value };
      setLoginData(mergeObj);
    } else {
      profileData[e.currentTarget.name] = e.currentTarget.value;
      setLoginData(profileData);
    }
  }

 

  const placeInputRef1 = useRef(null);
  useEffect(() => {
    //initPlaceAPI()
  },[])

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef1.current
    );
  }

  const [fileNames, setFileNames] = useState("");

  const inputFile = useRef(null)

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  

  return (
    <div className="profile-wrapper">

      <div className=" m-0">

        <div className="card">
          <div className="card-body">
            <div className="row">
              <Col sm={1} className='position-relative h-50'>
                {/* <img
                  src={localStorage.getItem("ProfilePhoto")} 
                  className="userprofile-img "
                /> */}
                  <div
                    className='no-image-user'
                >
                  {/* {props.selectedUser ? props.selectedUser.
                  full_name.charAt(0) : ''} */}
                  </div>
                <input
                  type='file'
                  ref={inputFile}
                  style={{ display: 'none' }}
                  accept=".png, .jpg, .jpeg"
                  id="photo"
                  name={fileNames}
                  onChange={(e) => {
                   // handleFileUpload(e);
                  }}
                />
                <FontAwesomeIcon
                  onClick={onButtonClick}
                  icon={faCamera}
                  className='personal-profile-icon'
                />
              </Col>
              <Col sm={11}>
                <div>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      name="FirstName"
                      type="text"
                      value={props.selectedUser ? props.selectedUser.first_name:""}
                      onChange={(e) => props.updateUserData(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      name="LastName"
                      type="text"
                      value={props.selectedUser ? props.selectedUser.last_name : ""}
                      onChange={(e) => props.updateUserData(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      name="Email"
                      type="email"
                      className="form-control"
                      value={props.selectedUser ? props.selectedUser.email:""}
                      onChange={(e) => props.updateUserData(e)}
                    />
                  </div>
                  {/* <div className="form-group">
                                <label>Password</label>
                                <input
                                  className="form-control"
                                  type="password"
                                  name="Password"
                                  value={loginData.Password}
                                  onChange={(e) => updateUserData(e)}
                                />
                              </div> */}
                  <div className="form-group">
                    <label>Address</label>
                    {/* <InputElement
                                                              placeInputRef1={placeInputRef1}

                                                              isProjectNameEdtbl={hideAddress}
                                                              jobvalue={loginData}
                                                              key="Address"
                                                              e={loginData.Address}
                                                              // editValues={onBlurProjectAdd}
                                                              // setTextVisible={setTextVisible}
                                                              // setProjectNameEdtbl={setProjectNameEdtbl}
                                                              // updateDeals={updateDeals}
                                                              newObj={newObj}
                                                          />  */}

                    <input
                      name='Address'
                      type="text"
                      className="form-control"
                      ref={placeInputRef1}
                      value={props.selectedUser ? props.selectedUser.Adrress : ""}
                      //onChange={(e) => updateUserData(e)}
                      onBlur={(e) => onBlurProjectAdd(e.target.value)}
                    />

                  </div>
                  <div className="form-group">
                    <label>City</label>
                    {/* <InputElement
                                                              // isProjectNameEdtbl={isProjectNameEdtbl}
                                                              jobvalue={loginData}
                                                              e={loginData.City}
                                                              editValues={onBlurProjectAdd}
                                                              // setTextVisible={setTextVisible}
                                                              // setProjectNameEdtbl={setProjectNameEdtbl}
                                                              // updateDeals={updateDeals}
                                                              newObj={newObj}
                                                          />  */}
                    <input
                      type="text"
                      className="form-control"
                      name="City"
                      value={props.selectedUser ? props.selectedUser.city : ""}
                      onChange={(e) => props.updateUserData(e)}
                    />

                  </div>
                  <div className="form-group">
                    <label>State</label>
                    {/* <InputElement
                                                              // isProjectNameEdtbl={isProjectNameEdtbl}
                                                              jobvalue={loginData}
                                                              e={loginData.State}
                                                              // editValues={onBlurProjectAdd}
                                                              // setTextVisible={setTextVisible}
                                                              // setProjectNameEdtbl={setProjectNameEdtbl}
                                                              // updateDeals={updateDeals}
                                                              // newObj={newObj}
                                                          />  */}
                    <input
                      type="text"
                      className="form-control"
                      name="State"
                      value={props.selectedUser ? props.selectedUser.state : ""}
                      onChange={(e) => props.updateUserData(e)}
                    />

                  </div>
                  <div className="form-group">
                    <label>Zip</label>
                    {/* <InputElement
                                                              // isProjectNameEdtbl={isProjectNameEdtbl}
                                                              jobvalue={loginData}
                                                              e={loginData.State}
                                                              // editValues={onBlurProjectAdd}
                                                              // setTextVisible={setTextVisible}
                                                              // setProjectNameEdtbl={setProjectNameEdtbl}
                                                              // updateDeals={updateDeals}
                                                              // newObj={newObj}
                                                          />  */}
                    <input
                      type="text"
                      className="form-control"
                      name="Zip"
                      value={props.selectedUser ? props.selectedUser.zip: ""}
                      onChange={(e) => props.updateUserData(e)}
                    />

                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.selectedUser ? props.selectedUser.phone : ""}
                      name="Phone"
                      onKeyDown={(evt) =>
                        ((evt.keyCode > 64 && evt.keyCode < 91) ||
                          evt.key === ".") &&
                        evt.preventDefault()
                      }
                      onChange={(e) => onChangePhoneNumber(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Role</label>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      name="Role"
                      value={props.selectedUser ? props.selectedUser.role.name : ""}
                      onChange={(e) => props.updateUserData(e)}
                    />
                  </div>
                  <div>
                    <button className="btn btn-primary" onClick={() => {

                      setResetPasswordOpen(!resetPasswordOpen)
                    }}
                    >Reset Password</button>
                  </div>
                  {/* <div className="btn-profile-outer-border">
                                                      <button
                                                          onClick={()=>saveUserData()}
                                                          className="btn-profile-save"
                                                      >Save Changes</button>
                                                      </div>    */}

                </div>
              </Col>
            </div>
          </div>
          <Modal
            className="centered-modal"
            isOpen={resetPasswordOpen}
            toggle={resetPasswordOpen}
          >
            <ModalHeader>Reset Password</ModalHeader>
            <ModalBody>
              <Row className="mb-2">
                <Col sm={4}>
                  <label>
                    New Password
                  </label>
                </Col>
                <Col sm={6}>
                  {/* <InputGroup>
                            <Input placeholder="username" />
                            <InputGroupAddon addonType="prepend"></InputGroupAddon>
                          </InputGroup> */}
                  <div className="pos-relative">
                    <input
                      placeholder="New Password"
                      type={passwordView.New}
                      className="form-control pr-25"
                      name="NewPassword"
                      value={newPassword}
                      onChange={(e) => {
                        if ((newPassword !== '') && (!e.target.value.match(regexMatch))) {
                          let obj = { ...error };
                          obj.New = 'Password must be 8 character one special character and one number'
                          setError(obj)

                        } else {
                          setError(null)
                        }
                        setNewPassword(e.target.value)
                      }}
                    />
                    {error && error.New ? <p className="small text-danger">{error.New}</p> : ""}

                    <FontAwesomeIcon
                      className="view-icons"
                      onClick={() => {
                        const obj = { ...passwordView }
                        if (obj.New == 'text') {
                          obj.New = 'password'
                        } else {
                          obj.New = 'text'
                        }
                        setPasswordView(obj);
                      }} icon={faEye} color='gray' />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <label>
                    Confirm Password
                  </label>
                </Col>
                <Col sm={6}>
                  <div className="pos-relative">
                    <input
                      placeholder="Confirm Password"
                      type={passwordView.Confirm}
                      className="form-control"
                      name="ConfirmPassword"
                      value={confirmPassword}
                      onChange={(e) => {
                        if ((confirmPassword !== '') && (!e.target.value.match(regexMatch))) {
                          let obj = { ...error };
                          obj.Confirm = 'Password must be 8 character one special character and one number'
                          setError(obj)

                        } else {
                          setError(null)
                        }
                        setConfirmPassword(e.target.value)

                      }}
                    />
                    {error && error.Confirm ? <p className="small text-danger">{error.Confirm}</p> : ""}
                    <FontAwesomeIcon
                      className="view-icons"
                      onClick={() => {
                        const obj = { ...passwordView }
                        if (obj.Confirm == 'text') {
                          obj.Confirm = 'password'
                        } else {
                          obj.Confirm = 'text'
                        }
                        setPasswordView(obj);
                      }


                      } icon={faEye} color='gray' />
                  </div>


                </Col>


              </Row>



            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  setResetPasswordOpen(!resetPasswordOpen)
                }}
              >
                {" "}
                Cancel
              </Button>
              <Button

                color="primary"
                onClick={() => {
                //   ResetNewPassword()
                }}
              >
                {" "}
                Update
              </Button>
              {/* <Button color="secondary" onClick={() => setDelPopup(!delPopup)}>
                      {" "}
                                  Cancel
                                   </Button> */}
            </ModalFooter>
          </Modal>
        </div>
      </div>

    </div>
  )
}

export default Profile;