import React, { useState,useEffect } from "react";
import { Button, CardBody, Collapse, Card } from "reactstrap";

function PriliminaryButton(props) {


    const [prilimanarystate, setPrilimanarystate] = useState(true);
    const prilimanarytoggle = () =>{
    props.setLayoutState("preliminary")
    props.setListState('basic')
    setPrilimanarystate(!prilimanarystate);
   } 
   useEffect(() => {
    props.layoutstate == "preliminary"
      ? setPrilimanarystate(true)
      : setPrilimanarystate(false);
  });
    return (
        <div>
            <Button
        className={
          props.layoutstate == "preliminary"
            ? "navbarbtn-top-active"
            : "navbarbtn-top-100"
        }
        onClick={() =>{
          props.updateTheSidebarState({type:'NavActivetab',status:'Preliminary'})
          localStorage.setItem('currentsidebar',"Preliminary");
          prilimanarytoggle()
        }}
      >
        Preliminary
      </Button>
      <Collapse isOpen={prilimanarystate}>
        <Card className="sidenav-card createdeal-card">
          <CardBody className="navbar-sublist-card">
        <div className={
          props.listState == "basic"
            ? "sublist-text-active"
            : "sublist-text"
        }  onClick={(e) => props.setListState('basic')}>Basic Information</div>
        <div className={
          props.listState == "property"
            ? "sublist-text-active"
            : "sublist-text"
        }  onClick={(e) => props.setListState('property')}>Property Location Information</div>
        <div className={
          props.listState == "service"
            ? "sublist-text-active"
            : "sublist-text2"
        }  onClick={(e) => props.setListState('service')}>Services Requested</div>

            
          </CardBody>
          </Card>
          </Collapse>
        </div>
    );
}

export default PriliminaryButton;