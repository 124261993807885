import React, { useState, useEffect } from "react";
import { Button, Row, Col, FormGroup, Label, Input, Modal, ModalBody } from "reactstrap";
import HttpService from "../../../../../../services/http.service";
// import { useHistory, withRouter } from 'react-router-dom';
//import "./createclientform.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faAlignRight, faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faCaretDown, faEnvelope, faTasks, faTimes } from '@fortawesome/free-solid-svg-icons';
import CustumSelect from "../../../../../../common/SelectWithSearch";
import handleCreate from "../../../../../../common/util/createRecord";
import ReactInputMask from 'react-input-mask';

const ClientModal = (props) =>{
    const [phoneNumber, setPhoneNumber] = useState("");
    const [NumInvalid, setNumInvalid] = useState(false);
    const [ActiveChecked, setActiveChecked] = useState(false);
    const [clientNumber, setClientNumber] = useState("");
    const [clientNumberInvalid, setClientNumberInvalid] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientNameInvalid, setClientNameInvaild] = useState();
    const [clientNickName, setClientNickName] = useState("");
    const [clientNickNameInvalid, setClientNickNameInvaild] = useState(false);
    const [parentClient, setParentClient] = useState("");
    const [parentClientInvalid, setParentClientInvalid] = useState(false);
    const [clientStatus, setClientStatus] = useState("");
    const [clientStatusInvalid, setClientStatusInvalid] = useState(false);
    const [priority, setPriority] = useState("");
    const [priorityInvalid, setPriorityInValid] = useState(false);
    const [projectType, setProjectType] = useState("");
    const [projectTypeInvalid, setProjectTypeInvalid] = useState(false);
    const [projectVolume, setProjectVolume] = useState("");
    const [projectVolumeInvalid, setProjectVolumeInvalid] = useState(false);
    const [website, setWebsite] = useState("");
    const [websiteInvalid, setWebsiteInvalid] = useState(false);
    const [billingStreet, setBillingStreet] = useState("");
    const [billingStreetInvalid, setBillingStreetInvalid] = useState(false);
    const [billingCity, setBillingCity] = useState("");
    const [billingCityInvalid, setBillingCityInvalid] = useState(false);
    const [billingState, setBillingState] = useState("");
    const [billingStateInvalid, setBillingStateInvalid] = useState(false);
    const [billingCode, setBillingCode] = useState("");
    const [billingCodeInvalid, setBillingCodeInvalid] = useState(false);
    const [billingCountry, setBillingCountry] = useState("");
    const [billingCountryInvalid, setBillingCountryInvalid] = useState(false);
    const [ShippingStreet, setShippingStreet] = useState("");
    const [shippingStreetInvalid, setShippingStreetInvalid] = useState(false);
    const [ShippingCity, setShippingCity] = useState("");
    const [shippingCityInvalid, setShippingCityInvalid] = useState(false);
    const [ShippingState, setShippingState] = useState("");
    const [shippingStateInvalid, setShippingStateInvalid] = useState(false);
    const [ShippingCode, setShippingCode] = useState("");
    const [shippingCodeInvalid, setShippingCodeInvalid] = useState(false);
    const [ShippingCountry, setShippingCountry] = useState("");
    const [shippingCountryInvalid, setShippingCountryInvalid] = useState(false);
    const [clients, setClients] = useState([]);
    const [mobileError, setMobileError] = useState(false);

    // const history = useHistory();
    const resetAllFields = () => {
        setClientNumber("");
        setClientName("");
        setClientNickName("");
        setParentClient("");
        setPhoneNumber("");
        setPriority("");
        setActiveChecked(false);
        setProjectType("");
        setProjectVolume("");
        setWebsite("");
        setBillingStreet("");
        setBillingCity("");
        setBillingState("");
        setBillingCode("");
        setBillingCountry("");
        setShippingStreet("");
        setShippingCity("");
        setShippingState("");
        setShippingCode("");
        setShippingCountry("");
      }


    const prepareClientData = () => {
      let data = {
        "Account_Name": clientName,
        "parentClient": parentClient,
        "phone": phoneNumber,
        "website": website,
        "billingStreet": billingStreet,
        "billingCity": billingCity,
        "billingState": billingState,
        "billingCode": billingCode,
        "billingCountry": billingCountry,
        "shippingStreet": ShippingStreet,
        "shippingCity": ShippingCity,
        "shippingState": ShippingState,
        "shippingCode": ShippingCode,
        "shippingCountry": ShippingCountry,
      }
      return data;
    }

    const saveClientData=()=>{
        let isvalidData = isAllMandatoriesValid(); 
        if(isvalidData) {
         let clientData = prepareClientData();
         createClientZoho();
        } else {
          alert("Mandatory fields missing ");
        }
    };

    const createClientZoho = async () => {
      let obj = {
        // "Account_Number": clientNumber,
        "Account_Name": clientName,
        "Parent_Account": parentClient,
        // "clientStatus": ActiveChecked,
        // "priority": priority,
        // "Account_Type": projectType,
        //"projectVolume": projectVolume,
        "Phone": phoneNumber,
        "Website": website,
        "Account_Site": website,
        "Billing_Street": billingStreet,
        "Billing_City": billingCity,
        "Billing_State": billingState,
        "Billing_Code": billingCode,
        "Billing_Country": billingCountry,
        "Shipping_Street": ShippingStreet,
        "Shipping_City": ShippingCity,
        "Shipping_State": ShippingState,
        "Shipping_Code": ShippingCode,
        "Shipping_Country": ShippingCountry,
      }
      handleCreate("Accounts",obj)
          .then(function (data) {
            data.data=data.data.data;
             // console.log(data);
              props.handleOnChangeDeal({ currentTarget: { name: 'Client', value:{name:clientName, id:data.data[0].details.id,module:"Accounts"} } })
              resetAllFields();
              props.clientToggle();
               props.getClientsName();
          })
          .catch((err)=>{
              console.log(err);
            // alert(err);
          });
  }

   
    // const updateData = async (data) => {
      
    //     let returnData = await HttpService.CreateUpdate('createclient', data).then(res =>  {
    //     //   console.log(res.data);
    //       if(res.data=="Added Successfully"){
    //         alert("Client "+res.data);
    //         resetAllFields();
    //         props.clientToggle();
    //          props.getClientsName();
    //       }
    //       else{
    //         alert(res.data); 
    //       }
    //     });
    // }
    const validationCss={
      "transition": "width 0.4s ease-in-out",
      "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
    }
    const onChangePhoneNumber = (value) => {
        var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
        if (value1.length < 10) {
            setPhoneNumber(value);
        }
        if (value1.length == 12) {
            setPhoneNumber("");
        }
        if (value1.length == 10) {
            setPhoneNumber(
            "(" +
              value1.substring(0, 3) +
              ") " +
              value1.substring(3, 6) +
              "-" +
              value1.substring(6, 10)
          );
          setNumInvalid(false);
        }
        if (value.length == 0) {
            setNumInvalid(false);
        }
      }

      const checkNumbers = (e) => {
        let value = (e.target.value = e.target.value.trimStart());
        var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
        let onlyNo = value1.replace(/\D/g, "");
        if (onlyNo.length < 10) {
          setPhoneNumber(value);
      }
        if (onlyNo.length > 10) {
          return;
        }
        if (onlyNo.length == 10) {
          setPhoneNumber(onlyNo);
        }
      };

      const validateMobileNmuber = (e) => {
        var value1 =e.target.value.replace(
          /[-#*$+/?=%() ]/g,
          ""
        );
        let onlyNo = value1.replace(/\D/g, "");
        if (onlyNo.length < 10) {
          // setValidation({ ...validation, mobileNumber: "-1" });
          setNumInvalid(true);
        } else {
          // setValidation({ ...validation, mobileNumber: "1" });
          setNumInvalid(false);
        }
      };
    const onBlurPhoneNumber = (value) => {
        var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
        if (value1.length < 10 && value.length != 0) {
            setNumInvalid(true);
        } else {
            setNumInvalid(false);
        }
    }
    
    const isAllMandatoriesValid = ()=> {
      let flag = true;
      
      if(clientName.length ===0 
       ) {
          flag = false;
    }
      return flag;
    }

    function handleErrors(type) {
        console.log("click event");
        if (type === "ClientNumber") {
          if (clientNumber.length === 0) {
            console.log("change ");
            setClientNumberInvalid(true);
          } else {
            console.log("click event : "+clientNumber);
            setClientNumberInvalid(false);
          }
        }
        if (type === "ClientName") {
          if (clientName.length === 0) {
            console.log("change ");
            setClientNameInvaild(true);
          } else {
            setClientNameInvaild(false);
          }
        }
        if (type === "NickName") {
          if (clientNickName.length === 0) {
            setClientNickNameInvaild(true);
          } else {
            setClientNickNameInvaild(false);
          }
        }
        if (type === "ParentClient") {
          if (parentClient.length === 0) {
            setParentClientInvalid(true);
          } else {
            setParentClientInvalid(false);
          }
        }
        if (type === "ClientStatus") {
          if (clientStatus.length === 0) {
            setClientStatusInvalid(true);
          } else {
            setClientStatusInvalid(false);
          }
        }
        if (type === "Priority") {
          if (priority.length === 0) {
            setPriorityInValid(true);
          } else {
            setPriorityInValid(false);
          }
        }
        if (type === "ProjectType") {
            if (projectType.length === 0) {
              setProjectTypeInvalid(true);
            } else {
              setProjectTypeInvalid(false);
            }
          }
          if (type === "ProjectVolume") {
            if (projectVolume.length === 0) {
              setProjectVolumeInvalid(true);
            } else {
              setProjectVolumeInvalid(false);
            }
          }
          if (type === "Website") {
            if (website.length === 0) {
              setWebsiteInvalid(true);
            } else {
              setWebsiteInvalid(false);
            }
          }
          if (type === "BillingStreet") {
            if (billingStreet.length === 0) {
              setBillingStreetInvalid(true);
            } else {
              setBillingStreetInvalid(false);
            }
          }
          if (type === "BillingCity") {
            if (billingCity.length === 0) {
              setBillingCityInvalid(true);
            } else {
              setBillingCityInvalid(false);
            }
          }
          if (type === "BillingState") {
            if (billingState.length === 0) {
              setBillingStateInvalid(true);
            } else {
              setBillingStateInvalid(false);
            }
          }
          if (type === "BillingCode") {
            if (billingCode.length === 0) {
              setBillingCodeInvalid(true);
            } else {
              setBillingCodeInvalid(false);
            }
          }
          if (type === "BillingCountry") {
            if (billingCountry.length === 0) {
              setBillingCountryInvalid(true);
            } else {
              setBillingCountryInvalid(false);
            }
          }
          if (type === "ShippingStreet") {
            if (ShippingStreet.length === 0) {
              setShippingStreetInvalid(true);
            } else {
              setShippingStreetInvalid(false);
            }
          }
          if (type === "ShippingCity") {
            if (ShippingCity.length === 0) {
              setShippingCityInvalid(true);
            } else {
              setShippingCityInvalid(false);
            }
          }
          if (type === "ShippingState") {
            if (ShippingState.length === 0) {
              setShippingStateInvalid(true);
            } else {
              setShippingStateInvalid(false);
            }
          }
          if (type === "ShippingStreet") {
            if (ShippingStreet.length === 0) {
              setShippingStreetInvalid(true);
            } else {
              setShippingStreetInvalid(false);
            }
          }
          if (type === "ShippingCity") {
            if (ShippingCity.length === 0) {
              setShippingCityInvalid(true);
            } else {
              setShippingCityInvalid(false);
            }
          }
          if (type === "ShippingState") {
            if (ShippingState.length === 0) {
              setShippingStateInvalid(true);
            } else {
              setShippingStateInvalid(false);
            }
          }
          if (type === "ShippingCode") {
            if (ShippingCode.length === 0) {
              setShippingCodeInvalid(true);
            } else {
              setShippingCodeInvalid(false);
            }
          }
          if (type === "ShippingCountry") {
            if (ShippingCountry.length === 0) {
              setShippingCountryInvalid(true);
            } else {
              setShippingCountryInvalid(false);
            }
          }
      }

    return (
      <div>
        <Modal
          isOpen={props.clientModal}
          toggle={props.clientToggle}
          backdrop={props.backdrop}
          keyboard={props.keyboard}
        >
          <div className="modal-header-class">
            Create Client
            <div className="note-header-icon">
              {" "}
              <FontAwesomeIcon
                icon={faTimes}
                className="icon-time-color"
                onClick={() => {
                  resetAllFields();
                  setClientNameInvaild(false);
                  props.clientToggle();
                }}
              ></FontAwesomeIcon>
            </div>
          </div>
          <ModalBody className="h500-overflow create-client-modal">
            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Client Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={clientName}
                    style={clientNameInvalid ? { ...validationCss } : {}}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setClientName(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("ClientName")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    clientNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Parent Client
                  </Label>
                </Col>
                <Col sm={6}>
                  <CustumSelect
                    addFlag={false}
                    // placeholder={'Select Client'}
                    value={parentClient ? parentClient.name : ""}
                    onChange={(value) => {
                      let filterClient = props.clients.filter(
                        (one) => one.name == value
                      );
                      setParentClient({
                        name: value,
                        id: filterClient[0].id,
                        module: "Accounts",
                      });
                    }}
                    // addItemLabel={" Add Client"}
                    // addItemAction={clientModalToggle}
                    items={props.clientNames}
                  />
                  {/* <Input
                  type="text"
                  name="text"
                  className="create-user-input"
                  value={parentClient}
                  onChange={(e) => setParentClient(e.target.value)}
                  onBlur={(e) => handleErrors("ParentClient")}
                /> */}
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    parentClientInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  field should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>
            {/* <FormGroup>
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="projectnumber">
                  Client Status<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
              <Label className="padr-15 activelabel">Active</Label>
                <Input 
                  type="checkbox" 
                  defaultChecked={ActiveChecked}
                  onChange={() => setActiveChecked(!ActiveChecked)}
                  className="create-user-checkbox" 
                />
            
                  <Label>Inactive</Label>
                <Input type="checkbox" 
                defaultChecked={ActiveChecked}
        onChange={() => setActiveChecked(!ActiveChecked)}
        className="create-user-checkbox" />
              </Col>
            </Row>
          </FormGroup>   */}

            {/* <FormGroup>
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="projectnumber">
                  Priority<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
                <Input
                  type="text"
                  name="text"
                  className="create-user-input"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  onBlur={(e) => handleErrors("Priority")}
                />
              </Col>
              <Col sm={4}>
                <span
                  className={
                    priorityInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  Field should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup> */}
            {/* <FormGroup>
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="projectnumber">
                  Project Type<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
                <Input
                  type="text"
                  name="text"
                  className="create-user-input"
                  value={projectType}
                  onChange={(e) => setProjectType(e.target.value)}
                  onBlur={(e) => handleErrors("ProjectType")}
                />
              </Col>
              <Col sm={4}>
                <span
                  className={
                    projectTypeInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup> */}
            {/* <FormGroup>
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="projectnumber">
                  Project Volume<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
                <Input
                  type="text"
                  name="text"
                  className="create-user-input"
                  value={projectVolume}
                  onChange={(e) => setProjectVolume(e.target.value)}
                  onBlur={(e) => handleErrors("ProjectVolume")}
                />
              </Col>
              <Col sm={4}>
                <span
                  className={
                    projectVolumeInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup> */}
            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Phone
                  </Label>
                </Col>
                <Col sm={6}>
                  {/* <Input
                  type="text"
                  name="text"
                  placeholder="(###)### ####"
                  // className="create-user-input"
                  className="createdeal-textfield"
                  value={phoneNumber}
                  onKeyDown={(evt) =>
                    ((evt.keyCode >64 && evt.keyCode <91) || evt.key === "."  ) &&
                    evt.preventDefault()
                  }
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart()
                    onChangePhoneNumber(e.target.value)}}
                  onBlur={(e) => onBlurPhoneNumber(e.target.value)}
                /> */}
                  <ReactInputMask
                    type="text"
                    name="text"
                    mask="(999) 999-9999"
                    maskChar="_"
                    className="form-control createdeal-textfield"
                    value={phoneNumber}
                    // style={phoneError || validation.phoneNumber=='-1'?{...validationCss}:{}}
                    onChange={(e) => {
                       checkNumbers(e)
                    }}
                     onBlur={(e) => validateMobileNmuber(e)}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    NumInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Enter 10 digit Phone Number.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Website
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={website}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      setWebsite(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("Website")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    websiteInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Billing Street
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={billingStreet}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setBillingStreet(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("BillingStreet")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    billingStreetInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Billing City
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={billingCity}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setBillingCity(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("BillingCity")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    billingCityInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Billing State
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={billingState}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setBillingState(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("BillingState")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    billingStateInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Billing Code
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={billingCode}
                    onChange={(e) => setBillingCode(e.target.value)}
                    onBlur={(e) => handleErrors("BillingCode")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    billingCodeInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Billing Country
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={billingCountry}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setBillingCountry(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("BillingCountry")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    billingCountryInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Shipping Street
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={ShippingStreet}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setShippingStreet(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("ShippingStreet")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    shippingStreetInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Shipping City
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={ShippingCity}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setShippingCity(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("ShippingCity")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    shippingCityInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Shipping State
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={ShippingState}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setShippingState(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("ShippingState")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    shippingStateInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Shipping Code
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={ShippingCode}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      setShippingCode(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("ShippingCode")}
                  />
                </Col>
                {/* <Col sm={3}>
                <span
                  className={
                    shippingCodeInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm={1}></Col>
                <Col sm={4}>
                  <Label className="text-bold" for="projectnumber">
                    Shipping Country
                  </Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    name="text"
                    // className="create-user-input"
                    className="createdeal-textfield"
                    value={ShippingCountry}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[0-9_!#@$%^&*()<>?|}{~:]/, "");
                      setShippingCountry(e.target.value);
                    }}
                    onBlur={(e) => handleErrors("ShippingCountry")}
                  />
                </Col>
                {/* <Col sm={2}>
                <span
                  className={
                    shippingCountryInvalid == true ? "red-text font12" : "error-display"
                  }
                >
                  {" "}
                  Fields should not be empty.
                </span>
              </Col> */}
              </Row>
            </FormGroup>
            <FormGroup className="marb-16 padtop-15 mt-3">
              <Row>
                <Col sm={6}>
                  <Button
                    className="continue-button"
                    style={{ height: "2rem" }}
                    onClick={props.clientToggle}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    className="continue-button"
                    style={{ height: "2rem" }}
                    onClick={() => {
                      if (clientName == "" || clientName == undefined) {
                        setClientNameInvaild(true);
                      }
                      saveClientData();
                    }}
                  >
                    Save
                  </Button>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </FormGroup>
          </ModalBody>
        </Modal>
      </div>
    );
}
    
export default ClientModal;