import React from 'react'
import { Popover as AntdPopover, Collapse } from "antd";
const Panel = Collapse.Panel;
export const GlobalAntDPopOver = ({ togglePopOver, showPopOver, children, items }) => {
    return (
        <div id="clientAddView">
            <AntdPopover
                placement="bottom"
                onOpenChange={togglePopOver}
                arrow={{
                    pointAtCenter: true,
                }}
                open={showPopOver}
                trigger={"click"}
                content={
                    <>
                        <Collapse defaultActiveKey={['1', '2', '3']}>
                            {items && items.Panels && items.Panels?.map((panel, i) => {
                                return (
                                    <Panel style={{cursor:"pointer"}} header={panel.label} key="1">
                                        {panel && panel.items?.map((l, i) => {
                                            return (
                                                <li onClick={()=>{
                                                    l.action(l)
                                                    togglePopOver()
                                                    }}>
                                                    <span className="pad-right10 fav-star-icon">

                                                    </span>
                                                    <span
                                                    //   onClick={() => {
                                                    //     openView(favtab._id, true);
                                                    //     setShowPopOver(false);
                                                    //   }}
                                                    //   className="fav-tab"
                                                    >
                                                        {l?.label}
                                                    </span>
                                                </li>
                                            );
                                        })
                                        }
                                    </Panel>
                                )
                            })}

                        </Collapse>
                    </>
                }
            >
                {/* <span id="PopoverLegacy" className="line-height36" style={{ textWrap: 'nowrap' }}>
                  +<span className="leftpad-6" onClick={togglePopOver}>Add View</span>
                </span> */}
                {children}
            </AntdPopover>
        </div>
    )
}
