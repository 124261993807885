import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ContractModal from "../ContractorModal/ContractModal";
import ElectricModal from "../ElectricModal/ElectricModal";
// import swal from "sweetalert";
import CustumSelect from "../../../../../../common/SelectWithSearch";
import 'antd/dist/reset.css';


const PermittingInfo = (props) => {
  const [buttonState, setbuttonState] = useState(false);
  const [generalContractermodal, setgeneralContracterModal] = useState(false);
  const [electricianmodal, setelectricianModal] = useState(false);
  const [backdrop, setBackdrop] = useState("static");
  const [keyboard, setKeyboard] = useState(true);
  const[Selected,setSelected]=useState({
    applicantSelected:'0',
    generalSelected:'0',
    electricSelected:'0'
  });
  const [skipPermitting, setSkipPermitting] = useState(false);

  const generalContractorToggle = () => setgeneralContracterModal(!generalContractermodal);

  const applicantToggle = () => {
    console.log('')
  };
  const electricianToggle = () => setelectricianModal(!electricianmodal);
  const buttontoggle = () => {
    props.settoggleState("permittinginfo");
    props.setListState('permittinginfo');
    setbuttonState(!buttonState);
  };

  useEffect(() => {
    props.toggleState == "permittinginfo"
      ? setbuttonState(true)
      : setbuttonState(false);
  },[props.toggleState]);

  // useEffect(() => {
  //   getData()
  // }, [props.data]);


  // const getData = () => {
  //   if (props.data != null) {
  //     props.setApplicant(props.data.applicant);
  //     props.setContractor(props.data.contractor);
  //     props.setElectrician(props.data.electrician);

  //   }
  // }

  const handlePermittingCheck = () => {
    console.log(props);
    if (skipPermitting) {
      props.setPermitComplete.permitInfo = true;
      return true;
    }
    if (props?.data?.applicant !== "Select Applicant" && props?.data?.applicant !== undefined &&
      props?.data?.Contractor?.name !== "Select Contractor" && props?.data?.Contractor?.name !== undefined &&
      props?.data?.Electrician?.name !== "Select Electrician" && props?.data?.Electrician?.name !== undefined) {
      props.setPermitComplete.permitInfo = true;
      return true;
    }
    props.setPermitComplete.permitInfo = false;
    return false;
  }

  const openContractorModal = (value) => {
    console.log("modal is open");
    if (value === "AddGeneralContractor") {
      if (props.isAccess && props.isAccess(props.selectedRole, 'General Contractors', 'Write')) {
        setgeneralContracterModal(!generalContractermodal);
        props.setContractor("");
      } else {
       /// swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
      }

    }
    else {
      props.setContractor(value);
    }
  }

  const openElectricianModal = (value) => {
    if (value === "AddElectrician") {
      if (props.isAccess && props.isAccess(props.selectedRole, 'Liscensed Electrician', 'Write')) {
        electricianToggle();
        props.setElectrician("");
      } else {
        //swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
      }

    }
    else {
      props.setElectrician(value);
    }
  }

 



  return (
    <Col sm={12}>
      <Button
        onClick={buttontoggle}
        className={buttonState ? "btn-100-active" : "btn-100"}
      >
        Permitting Information
        <div className="float-right">
          <Input type="checkbox"
            className={handlePermittingCheck() ? "rightbutton-checkbox-active" : "rightbutton-checkbox"}
            checked={handlePermittingCheck()}
            onChange={() => {
              let obj={}
              if(props.data.Electrician==undefined||props.data.Electrician.name==undefined||props.data.Electrician.name==''){
               obj["electricSelected"]='-1';
              }else{
                obj["electricSelected"]='1'
              }
              if(props.data.Contractor==undefined||props.data.Contractor.name==undefined||props.data.Contractor.name==''){
                obj["generalSelected"]='-1'
              }else{
                obj["generalSelected"]='1'
              }
              if(props.data.applicant==undefined||props.data.applicant==''){
                obj["applicantSelected"]='-1'
              }else{
                obj["applicantSelected"]='1'
              }

               setSelected(obj)             
              setSkipPermitting(!skipPermitting)}}
            />
          {buttonState ? (
            <FontAwesomeIcon className=" font-centered" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
          )}
        </div>
      </Button>
      <Collapse isOpen={buttonState}>
        <Card className="createdeal-card">
          <CardBody>
            <FormGroup className="marb-16 ">
              <Row>
                <Col sm={2}>
                  <Label className="text-bold" for="l2code">
                    Applicant<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4} >
                  <CustumSelect
                  addFlag={false}
                    placeholder={'Select Applicant'}
                    value={props.data ? props.data.applicant : ''}
                    Color={Selected.applicantSelected}
                    onChange={(value) => {
                      if(value != ''){
                        setSelected({...Selected,applicantSelected:'1'})
                      }else{
                        setSelected({...Selected,applicantSelected:'-1'})
                      }
                      props.handleOnChangeDeal({ currentTarget: { name: 'applicant', value: value } })
                      props.setApplicant(value)
                    }}
                    onBlur={e=>{
                      if(props.data?.length!=0){
                        setSelected({...Selected,applicantSelected:'1'})
                      }else{
                        setSelected({...Selected,applicantSelected:'-1'})
                      }     
                    }
                    }
                    addItemLabel={"Add Applicant"}
                    addItemAction={applicantToggle}
                    items={props.applicantList} />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup className="marb-16 ">
              <Row>
                <Col sm={2}>
                  <Label className="text-bold" for="asvestosInspector">
                    General Contractor<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                  <CustumSelect
                   addFlag={true}
                    placeholder={'Select Contractor'}
                    value={props.data.Contractor ? props.data.Contractor.name : undefined}
                    Color={Selected.generalSelected}
                    onChange={(value) => {
                      if(value != ''){
                        setSelected({...Selected,generalSelected:'1'})
                      }else{
                        setSelected({...Selected,generalSelected:'-1'})
                      }
                      let filterData=props.contractors.filter(one=>one.Name==value)
                      props.handleOnChangeDeal({ currentTarget: { name: 'Contractor',value:{ name: value,id:filterData[0].id,module:"General_Contractor"} } })
                      //openContractorModal(value)
                      props.setContractor(value)
                    }}
                    onBlur={e=>{
                      if(props.data.Contractor?.length!=0){
                        setSelected({...Selected,generalSelected:'1'})
                      }else{
                        setSelected({...Selected,generalSelected:'-1'})
                      } 
                    }}
                    addItemLabel={" Add General Contractor"}
                    addItemAction={generalContractorToggle}
                    items={props.contractorList} />
                </Col>
                <Col sm={3}>
                </Col>
                <ContractModal
                  handleOnChangeDeal={props.handleOnChangeDeal}
                  setContractor={props.setContractor}
                  generalContractermodal={generalContractermodal}

                  //generalContractorToggle={generalContractorToggle}
                  setgeneralContracterModal={setgeneralContracterModal}
                  backdrop={backdrop}
                  keyboard={keyboard}
                  getGeneralContractors={props.getGeneralContractors}
                />
                <Col sm={5}></Col>
              </Row>
            </FormGroup>
            <FormGroup className="marb-16">
              <Row>
                <Col sm={2}>
                  <Label className="text-bold" for="asvestosInspector">
                    Electrician<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                  <CustumSelect
                   addFlag={true}
                    placeholder={'Select Electrician'}
                    value={props.data.Electrician ? props.data.Electrician.name : undefined}
                    Color={Selected.electricSelected}
                    onChange={(value) => {
                      if(value != ''){
                        setSelected({...Selected,electricSelected:'1'})
                      }else{
                        setSelected({...Selected,electricSelected:'-1'})
                      }
                      let filterData=props.electricians.filter(one=>one.Name==value)
                      props.handleOnChangeDeal({ currentTarget: { name: 'Electrician',value:{ name: value,id:filterData[0].id,module:"Electricians"} } })
                      props.setElectrician(value)
                     // openElectricianModal(value)
                    }}
                    onBlur={()=>{
                      if(props.data.Electrician?.length!=0){
                        setSelected({...Selected,electricSelected:'1'})
                      }else{
                        setSelected({...Selected,electricSelected:'-1'})
                      } 
                    }}
                    addItemLabel={" Add Electrician"}
                    addItemAction={electricianToggle}
                    items={props.electricianList} />
                  {/* <Input
                    type="select"
                    className="dropdown-text"
                    name="electrician"
                    id="l2code"
                    value={props.data?props.data.electrician:''}
                    onChange={(e)=>{
                      props.handleOnChangeDeal(e);
                                    openElectricianModal(e.target.value)}}
                >
                    <option value="Select Electrician">Select Electrician</option>
                    <option value="AddElectrician"
                    className="create-option-text" >+Add Electrician</option>
                    {
                      props.electricianList.map((name)=>{
                        return <option value={name}>{name}</option>
                         })
                    }
                    
                  </Input> */}
                </Col>

                <Col sm={3}>
                  {/*<Button className="continue-button" onClick={electricianToggle}>Add Electrician</Button>*/}
                </Col>
                <ElectricModal
                  handleOnChangeDeal={props.handleOnChangeDeal}
                  setElectrician={props.setElectrician}
                  electricianmodal={electricianmodal}
                  electricianToggle={electricianToggle}
                  backdrop={backdrop}
                  keyboard={keyboard}
                  getElectricians={props.getElectricians}
                />
                <Col sm={5}></Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default PermittingInfo;
