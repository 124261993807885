import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import { AgGridColumn, AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Switch } from "antd";
import { Coldefs } from "./DealListColDef";
import Config from '../../config.json'
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCircle,
  faEye,
  faGripVertical,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, Prompt, useParams } from "react-router-dom";
import { Dropdown, Modal, ModalBody } from "react-bootstrap";
import { Col, FormGroup, Input, ListGroupItem, ModalHeader, Row } from "reactstrap";
import { Modal as AntdModal } from "antd";
import "./deal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import TabView from "../../common/tabs/tabs";
import FieldService from "../../common/util/FieldService";
import Loader from "../../common/loader";
import ProjectService from "../../common/util/ProjectService";
import DealTableChart from "../../common/DealTableChart/dealTableChart";
import _ from "lodash";
import ModuleService from "../../common/util/ModuleService";
import dayjs from "dayjs";
import Task_Filters from "../../common/task_filters/task_filter";
import TaskService from "../../common/util/TaskService";
import handleUpdate from "../../common/util/updateRecord";
import CustomHeaderComponent from "./CustomHeader/CustomHeaderComponent";
import CustomDateComponent from "../../common/CustomDateComponent/CustomDateComponent";
import CustomEditorComponent from "../../common/CustomDateComponent/CustomEditorComponent";
import CompactView from "./CompactView/CompactView";
import AgGridReactComponent from "./AgGridReactComponent/AgGridReactComponent";
import PdfViewer from "../PdfPreview/PdfViewer";
import { e } from "mathjs";
import { FilterParams } from "./FilterParams";

const DealList = (props) => {
  const history = useHistory();
  const location = props.location;
  useEffect(() => {
    if (props.dealLocation == "Accounts") {
      getDealList();
    }
  }, [props.data]);

  const [showDealTableCharts, setShowDealTableCharts] = useState(false);
  const [dealTableChartObjs, setDealTableChartObjs] = useState({
    data: [],
    headers: [],
  });
  const [isInitialLoad, setInitialLoad] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [cacheHeaderData, setCacheHeaderData] = useState([]);
  const [refreshTab,setRefreshTab]=useState(false);
  const [allServices, setAllServices] = useState([]);
  const [headerData, setheaderData] = useState([]);
  const [activeHeaderData, setActiveHeaderData] = useState([]);
  const [headerDataForCompactView, setheaderDataForCompactView] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [rowDataCompactView, setRowDataCompactView] = useState([]);
  const [filterdData, setFilteredData] = useState([])
  const [cacheData, setcacheData] = useState([]);
  const [from, setFromRow] = useState(1);
  const [activeView, setActiveView] = useState("list")
  const [to, setToRow] = useState(5);
  const [totalRow, setTotalRow] = useState(5);
  const [backActive, setbackActive] = useState(false);
  const [frontActive, setfrontActive] = useState(true);
  const [dotActive, setdotActive] = useState(false);
  const [filterText, setFilterText] = useState(null);
  const [filterState, setFilterState] = useState(false);
  const [firstActive, setFirstActive] = useState(false);
  const [shouldRender, setSouldRender] = useState(true);
  const [querySuccess, setQuerySuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [customFieldFlag, setCustomFieldFlag] = useState(true);
  const [showDealTableChart, setShowDealTableChart] = useState(false);
  const [appliedFiltersForView, setappliedFiltersForView] = useState([]);
  const [preAppliedFiltersForView, setPreAppliedFiltersForView] = useState([]);
  const [filtersmode, setfiltersmode] = useState([]);
  const [AllDealsClicked, setAllDealsClicked] = useState(false);
  const [viewType, setViewType] = useState("List");
  const [activeFiltersOnColumnLevel, setActiveFiltersOnColumnLevel] = useState();
  const [compactViewGridApis, setComactViewGridApis] = useState();
  const [compactViewFilterModal, setCompactViewFilterModal] = useState({});
  const [rowsSelected, setRowsSelected] = useState([]);
  const [compactViewSortState, setCompactViewsortState] = useState([]);
  const [compactViewFiltersOnColumns, setCompactViewFiltersOnColumns] = useState({});
  const [CompactViewFilterTrigger, setCompactViewFilterTrigger] = useState(false);
  const [filtersRowsForCompactView, setFiltersRowsForCompactView] = useState([]);
  const [filterDataBuffer, setFilterDataBuffer] = useState([]);
  const [triggerGridApiEffect, settriggerGridApiEffect] = useState(false);
  const [listSelectedRows, setlistSelectedRows] = useState([]);
  const [isColFilDeleted, setIsColFilDeleted] = useState(false)
  const [groupStatus, setGroupStatus] = useState({
    Pined_Column: false,
    Basic_Information: false,
    Property_Location_Info: false,
    Services_Requested: false,
    NYC_Property_Info: false,
    Previous_Solar_Application_Info: false,
    Additional_Nyc_Filling_Info: false,
    WWP_Violation_Info: false,
    Asbestos_Investigation: false,
    Property_Owner_Info: false,
    Home_Owner_Info: false,
    Homeowner_s_Mailing_Address_Info: false,
    Other_Owner_Information: false,
    Condo_Second_Officer: false,
    Permitting_Info: true,
    Pv_System_Info: false,
    Links: false,
    PV_Interconection: false,
    Project_Cost: false,
    Created_Details: false,
    Modified_Details: false,
  })
  useEffect(e=>{
    console.log("---------- filter state update",appliedFiltersForView)
  },[appliedFiltersForView]);
  const customLinkFields = [
    {
      "field": "bis_link",
      "id": "101",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Bis Link",
      "type": 'link',
    },
    {
      "field": "zola_link",
      "id": "102",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Zola Link",
      "type": 'link',
    },
    {
      "field": "taxmap_link",
      "id": "103",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Tax Map Link",
      "type": 'link',
    },
    {
      "field": "acris_link",
      "id": "104",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Acris Link",
      "type": 'link',
    }
  ]

  useEffect(e => {
    getDealList();
  }, [])
  // useEffect(e=>{

  //   let dealName=[];
  //   rowsSelected.map(r=>{
  //     if(!dealName.includes(r.data.Deal_Name)){
  //       dealName.push(r.data.Deal_Name)
  //     }
  //   })
  //   setRowsSelectedId(dealName)
  // },[rowsSelected])
  useEffect(e => {
    let arr = []
    cacheHeaderData?.forEach(d => {
      let prev = Coldefs['Previous_Solar_Application_Info'].find(t => t.field == d.field);
      let vio = Coldefs['WWP_Violation_Info'].find(t => t.field == d.field);
      let pv = Coldefs['PV_Interconection'].find(t => t.field == d.field);
      if (prev) {
        arr.push({ ...d, filterAttribute: prev.filterAttribute, type: prev.type })
      } else if (vio) {
        arr.push({ ...d, filterAttribute: vio.filterAttribute, type: vio.type })
      } else if (pv) {
        arr.push({ ...d, filterAttribute: pv.filterAttribute, type: pv.type })
      } else {
        arr.push(d)
      }
    })
    let headList = [...arr, ...Coldefs['Previous_Solar_Application_Info'].map(c => {
      return {
        name: c.headerName, field: c.field, ...c, filterAttribute: c.filterAttribute,
        ...(c.type ? { type: c.type } : {}),
      }
    }), ...Coldefs['WWP_Violation_Info'].map(c => {
      return {
        name: c.headerName, field: c.field, ...c, filterAttribute: c.filterAttribute,
        ...(c.type ? { type: c.type } : {})
      }
    }), ...Coldefs['PV_Interconection'].map(c => {
      return {
        name: c.headerName, field: c.field, ...c, filterAttribute: c.filterAttribute,
        ...(c.type ? { type: c.type } : {})
      }
    })];
    headList = _.uniqBy(headList, "field");
    setheaderDataForCompactView(headList)
  }, [cacheHeaderData])

  useEffect(() => {
    // onFirstDataRendered()
  }, [activeFiltersOnColumnLevel]);


  const [dealTableChartObj, setDealTableChartObj] = useState({
    data: [],
    headers: [],
    folderId: null,
  });
  const [dealStatus, setDealStatus] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const [showFilter, setShowFilter] = useState("");

  const [columnsEdit, setColumnsEdit] = useState(false);
  const [editColumnSelectAllFlag, SetEditColumnSelectAllFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const subformApiName = ["Deal_Name", "PV_Interconnection"];
  const moduleApiNameForSubform = ["WWP_Violations", "Previous_Solar_Info"];

  let selectedIndx = -1;
  let selectedValue = null;
  let dropedIndx = -1;
  let dropedValue = null;
  const params = useParams();
  useEffect(() => {
    if (props.getParams) {
      props.getParams(params);
    }
  }, [location]);

  const [filterItemsDeal, setFilterItemsDeal] = useState([
    { name: "Deal Name", type: "string" },
    { name: "Client", type: "string" },
    { name: "Status", type: "string" },
    { name: "Borough", type: "string" },
    { name: "Closing Date", type: "date" },
    { name: "Created By", type: "string" },
  ]);

  useEffect((e) => {
    setAllDealsClicked(AllDealsClicked)
  }, [AllDealsClicked])

  useEffect(() => {
    if (props.refreshEnable) {

      getDealList();
    }
  }, [props.refreshEnable]);

  useEffect(() => {
    if (props.deleteDealFlag == "Deleted") {
      getDealList()
    }
  }, [props.deleteDealFlag])


  useEffect(() => {
    if (headerData.length > 0) {
      let arr = [];
      const items = [...headerData];

      items.forEach((one) => {
        if (!hideArray.includes(one.field) && !(
          pvSystemTableHeaders.find(e => e.value == one.field)
          || wwwViolationHeaders.find(e => e.value == one.field)
          || previousSolarAppHeaders.find(e => e.value == one.field)
        )) {
          if (one.type == "text") {
            one.field == "Occupancy_Classification_Building_2014_2008_Code" ?
              arr.push({ name: one.name, type: "boolean", api_name: one.field }) :
              arr.push({ name: one.name, type: "string", api_name: one.field });
          } else if (one.type == "integer") {
            arr.push({ name: one.name, type: "number", api_name: one.field });
          } else if (one.type == "date") {
            arr.push({ name: one.name, type: "date", api_name: one.field });
          } else if (one.type == "picklist") {
            one.field == "Owner_Type" || one.field == "Active_Work_Without_Permit_Violations"
              || one.field == "Occupancy_Classification_of_Building" ?
              arr.push({ name: one.name, type: "string", api_name: one.field }) :
              arr.push({ name: one.name, type: "boolean", api_name: one.field });
          } else if (one.type == "formula") {
            arr.push({ name: one.name, type: "number", api_name: one.field });
          } else if (one.type == "currency") {
            arr.push({ name: one.name, type: "number", api_name: one.field });
          } else {
            arr.push({ name: one.name, type: "string", api_name: one.field });
          }
        }
      });
      pvSystemTableHeaders.forEach((one) => {
        arr.push({ name: one.label, type: one?.type, api_name: one.value });
      });

      // Extra fields for violation information for main filter
      wwwViolationHeaders.forEach((one) => {
        arr.push({ name: one.label, type: one?.type, api_name: one.value });
      });
      previousSolarAppHeaders.forEach((one) => {
        arr.push({ name: one.label, type: one?.type, api_name: one.value });
      });

      arr = _.uniqBy(arr, "api_name");
      setFilterItemsDeal(arr);
    }
    if (headerDataForCompactView && headerData) {
      if (viewType == "List") {
        setActiveHeaderData([...headerData])
      } else {
        setActiveHeaderData([...headerDataForCompactView])
      }
    }
  }, [headerData]);
  useEffect(e => {
    if (headerDataForCompactView && headerData) {
      if (viewType == "List") {
        setActiveHeaderData([...headerData])
      } else {
        setActiveHeaderData([...headerDataForCompactView])
      }
    }
  }, [headerDataForCompactView])
  // useEffect(e=>{
  //   if(headerDataForCompactView && headerData){
  //     if(viewType=="List"){
  //       setActiveHeaderData([...headerData])
  //     }else{
  //       setActiveHeaderData([...headerDataForCompactView])
  //     }
  //   }
  // },[viewType])

  useEffect(e=>{
    props.setSelectedIds([])
  },[])

  const getPromtt = () => {
    // if(props.warningFlag){
    //     if (location.state && (location.state.action == "create")) {
    //         return <Prompt when={true} message="Are you sure you want to leave without changes save?" />
    //       }else if (location.state) {
    //         return <Prompt when={true} message="Are you sure you want to leave without changes save?" />
    //       }
    //       else {
    //         return ""
    //       }
    // }else{
    //     return ""
    // }
  };

  const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
  };

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  useEffect(() => {
    ModuleService.GetModuleListData('Requested_Service')
      .then((result) => {
        setAllServices(result);
      })
      .catch((err) => {
        console.log(err.message);
      });
    TaskService.GetDealStatus()
      .then((res) => {
        setDealStatus(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // TaskService.GetProjectStatus()
    //     .then((result) => {
    //         let arr = []
    //         result.forEach((one) => {
    //             if (arr.length == 0) {
    //                 arr.push(one)
    //             } else {
    //                 if (arr.filter(obj => obj.status_name == one.status_name).length == 0) {
    //                     arr.push(one)
    //                 }
    //             }
    //         })
    //         arr.forEach((obj) => {
    //             if (obj.status_name == "zp.projstatus.completed") {
    //                 obj.status_name = "Completed"
    //             }
    //         })
    //         arr = arr.filter(one => status.includes(one.status_name))
    //         setDealStatus(arr);
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    getAllClients();
  }, []);

  const getAllClients = () => {
    ModuleService.GetModuleList("Accounts")
      .then((result) => {
        if (result) {
          setAllClients(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //     getFieldHeaders()
  // }, [])

  useEffect(() => {
    if (cacheHeaderData.length > 0 && cacheData.length > 0 && customFieldFlag && props?.checkType?.type == '') {
      (async () => {

        let header = [...cacheHeaderData];
        let data = [...cacheData];
        try {
          setQuerySuccess(true);
          let custom_fields = await getAllTaskInputFields();
          for (let deal = 0; deal < data.length; deal++) {
            if (data[deal].Project_ID) {
              let fields = custom_fields?.filter(
                (field) =>
                  field.Assign &&
                  field.Assign.length > 0 &&
                  field.Assign.filter(
                    (one) => one.DealId == data[deal].Project_ID
                  ).length > 0
              );
              fields?.forEach((field) => {
                data[deal][field.Name] = field.Value
                  ? field.Value.Value
                    ? field.Value.Value
                    : "NA"
                  : "NA";
              });
            }
          }
          custom_fields?.forEach((one) => {
            let obj = {};
            obj.field = one.Name;
            obj.id = one._id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = one.Name;
            obj.type = one.Type;
            // header.push(obj)
          });
          header = [...header, ...customLinkFields]

          setCustomFieldFlag(false);
          setheaderData(header);
          setCacheHeaderData(header);
          setRowData(data);
          setRowDataCompactView(data);
          setcacheData(data);
          setQuerySuccess(false);
        } catch (ex) {
          setQuerySuccess(false);
          console.log(ex);
        }
      })();
    }
  }, [cacheHeaderData, cacheData]);

  const firstArr = [
    "Deal_Name",
    "Closing_Date",
    "Account_Name",
    "Project_Address",
    "Project_Status",
    "House_Number",
    "Street_Name",
    "City",
    "State",
    "Zip_Code",
    "Latitude",
    "Longitude",
    "Is_NYC_Project",
    "Borough",
    "Service_Requested_Name",
    "Block_Number",
    "Lot_Number",
    "Bin_Number",
    "Community_Board_Number",
    "Occupancy_Classification_Building_2014_2008_Code",
    "Occupancy_Classification_of_Building",
    "Construction_Classification_Code_2014_2008_Code",
    "Construction_Classification_Code",
    "Multiple_Dwelling_Classification",
    "Number_of_Dwelling_Units",
    "Building_Height",
    "Number_of_Stories",
    "Zoning_District",
    "Commercial_Overlay",
    "Specials_Districts",
    "Zoning_Map_Number",
    "Tidal_Wetlands",
    "Fresh_Water_Wetland",
    "Flood_Hazard",
    "Coastal_Erosion_Hazard",
    "Special_Fire_District",
    "Landmark_Area",
    "Environmental_Restriction",
    "Conjunction",
    "Conjunction_Application_Number",
    "Stop_Work_Order_Exists_on_Property",
    "Active_Work_Without_Permit_Violations",
    "Asbestos_Required",
    "ACP5_Completed",
    "Asbestos_Inspector_Certificate_Number",
    "ACP5_Control_Number",
    "Certificate_of_Occupancy_Available",
    "NB_Application_Number",
    "Owner_Type",
    "Property_Owner_s_Name",
    "Owner_s_First_Name",
    "Owner_s_Middle_Initial",
    "Owner_s_Last_Name",
    "Owner_Phone_Number",
    "Owner_s_Email_Address",
    "Owner_s_Mailing_Address_Diff_Than_Property_Address",
    "Owner_s_Mailing_Address",
    "Owner_s_Street_Name",
    "Owner_s_House_Number",
    "Owner_s_City",
    "Owner_s_State",
    "Owner_s_Zip_Code",
    "Non_Profit",
    "DHCR",
    "Other_Owner_Company_Name",
    "Other_Owner_Mailing_Address",
    "Other_Owner_Street_Name",
    "Other_Owner_House_Number",
    "Other_Owner_City",
    "Other_Owner_State",
    "Other_Owner_Zip_Code",
    "Other_Owner_Officer_First_Name",
    "Other_Owner_Officer_Middle_Initial",
    "Other_Owner_Officer_Last_Name",
    "Other_Owner_Officer_Title",
    "Other_Owner_Phone_Number",
    "Other_Owner_Email_Address",
    "Condo_Company_Name",
    "Condo_Mailing_Address",
    "Condo_Street_Name",
    "Condo_House_Number",
    "Condo_City",
    "Condo_State_Name",
    "Condo_Zip_Code",
    "Condo_Officer_First_Name",
    "Condo_Officer_Middle_Initial",
    "Condo_Officer_Last_Name",
    "Condo_Officer_Title",
    "Condo_Phone_Number",
    "Condo_Email_Address",
    "Applicant",
    "General_Contractor",
    "Electrician",
    "Number_of_Interconnection",
    "Total_System_Size",
    "Total_Modules",
    "PV_Interconnection",
    "Project_Cost",
    "Owner",
    "Created_By",
    "Created_Time",
    "Modified_By",
    "Modified_Time",
  ];

  const getFieldHeaders = () => {
    setQuerySuccess(true);
    FieldService.GetFields("all", "Deals")
      .then(async (result) => {
        let arr = [];
        firstArr.forEach((one) => {
          let fieldObj = result.filter((object) => object.api_name == one);
          if (fieldObj.length > 0) {
            fieldObj = fieldObj[0];
            fieldObj.field_label = fieldObj.field_label.replace("0", "");
            let obj = {};
            obj.field = fieldObj.api_name;
            obj.id = fieldObj.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            fieldObj.field_label == "Owner's Street Name"
              ? (obj.name = "Owner's Street Address")
              : (obj.name = fieldObj.field_label);
            obj.type = fieldObj.data_type;
            arr.push(obj);
          }
        });
        result.forEach((one) => {
          if (!firstArr.includes(one.api_name)) {
            let obj = {};
            obj.field = one.api_name;
            obj.id = one.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = one.field_label.replace("0", "");
            obj.type = one.data_type == "formula" ? "number" : one.data_type;
            arr.push(obj);
          }
        });
        const notIncludesFileds = ["latitude", "longitude"];
        arr = arr.filter((one) => !notIncludesFileds.includes(one.name));
        moduleApiNameForSubform.forEach((one) => {
          let obj = {};
          obj.field = one;
          obj.id = one;
          obj.isChecked = false;
          obj.isDragOver = false;
          obj.isSearch = true;
          obj.name = one.replaceAll("_", " ");
          obj.type = "text";
          if (one == "Previous_Solar_Info") {
            arr.splice(38, 0, obj);
          }
          // if(one == "WWP_Violations"){
          //     arr.splice(42, 0, obj)
          // }
        });
        arr = _.uniqBy(arr, "name");
        arr = [...arr, ...customLinkFields]
        setfiltersmode([]);
        setappliedFiltersForView([]);
        setheaderData(arr);
        setAllDealsClicked(true);
        setQuerySuccess(false);
        setCacheHeaderData(arr);
      })
      .catch((err) => {
        setQuerySuccess(false);
        console.log(err);
      });
  };
  const updatedHeaderData = (headers) => {
    let data = [...headers]
    if (viewType == "List") {
      data = data.filter(d => {
        if (!Coldefs["Previous_Solar_Application_Info"].find((e, i) => i > 0 && d.field == e.field)
          && !Coldefs["WWP_Violation_Info"].find((e, i) => i > 0 && d.field == e.field)
          && !Coldefs["PV_Interconection"].find((e, i) => i > 0 && d.field == e.field)) {
          return d
        }
      })
      return data
    } else {
      return data;
    }
  };
  const handleColumns = (e, index) => {

    if (!e.currentTarget.checked) {
      SetEditColumnSelectAllFlag(false);
    }
    let newClient = [...activeHeaderData];
    newClient.forEach((obj) => {
      if (obj.id === e.currentTarget.id) {
        obj.isChecked = !e.currentTarget.checked;
      }
    });
    setColumnsEdit(true);
    setActiveHeaderData(updatedHeaderData(newClient));
  };

  const getAllTaskInputFields = (dealId) => {
    const backendUrl =
      Config.backend_url;
    return new Promise((resolve, reject) => {
      let obj = dealId ? { DealId: dealId } : {};
      axios
        .post(backendUrl + "getTaskFields", obj)
        .then((result) => {
          resolve(result.data.Data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  let hideArray = [
    "Last_Activity_Time",
    "Tag",
    "Lead_Conversion_Time",
    "NYC_Project",
    "Sales_Cycle_Duration",
    "Overall_Sales_Duration",
    "Locked__s",
    "Certification",
    "Conjunction_Application_Number",
    "Stage",
    "Reason_For_Loss__s",
    "Amount",
    "Expected_Revenue",
    "Type",
    "Next_Step",
    "Probability",
    "Lead_Source",
    "Contact_Name",
    "Campaign_Source",
    "Client",
    "Project_Date",
    "Partial",
    "Change_Log_Time__s",
    "Description",
    "Record_Image",
    "Block",
    "Address",
    "Total_Interconnections",
    "Project_Status_ID",
    "Service_Requested",
    "Borough_Number",
    "Currency",
    "Exchange_Rate",
    "Previous_Solar_Application",
    "Violation_Information",
    "Tax_Abatement",
    "Electrical_Diagram",
    "Asbestos_Investigation",
    "Pipeline",
    "Asbestos",
    "zohoworkdriveforcrm__Workdrive_Folder_URL",
    "zohoworkdriveforcrm__Workdrive_Folder_ID",
    "Number",
    "Story_Number",
    "Notes_ID",
    "Project_Status_ID",
    "Deal_Status",
    "Project_ID",
    "Structural_Letter",
    "Service_Requested_ID",
  ];
  //hrushikesh Shelke new code start
  const homeOwnerInfoFeilds = {
    Owner_s_Mailing_Address_Diff_Than_Property_Address: "N/A",
    Owner_Phone_Number: "N/A",
    Owner_s_Email_Address: "N/A",
    Owner_s_First_Name: "N/A",
    Owner_s_Middle_Initial: "N/A",
    Owner_s_Last_Name: "N/A",
  };
  const homeOwnerAddInfoFeilds = {
    Owner_s_Mailing_Address: "N/A",
    Owner_s_Street_Name: "N/A",
    Owner_s_City: "N/A",
    Owner_s_State: "N/A",
    Owner_s_Zip_Code: "N/A",
    Owner_s_House_Number: "N/A",
  };

  const otheOwnerInfoFeilds = {
    DHCR: "N/A",
    Non_Profit: "N/A",
    Other_Owner_Mailing_Address: "N/A",
    Other_Owner_Street_Name: "N/A",
    Other_Owner_City: "N/A",
    Other_Owner_State: "N/A",
    Other_Owner_Zip_Code: "N/A",
    Other_Owner_House_Number: "N/A",
    Other_Owner_Phone_Number: "N/A",
    Other_Owner_Company_Name: "N/A",
    Other_Owner_Officer_First_Name: "N/A",
    Other_Owner_Officer_Last_Name: "N/A",
    Other_Owner_Officer_Middle_Initial: "N/A",
    Other_Owner_Officer_Title: "N/A",
    Other_Owner_Email_Address: "N/A",
  };
  const NycProjectNotApplicableFields = {
    Borough: "N/A",
    Block_Number: "N/A",
    Lot_Number: "N/A",
    Bin_Number: "N/A",
    Community_Board_Number: "N/A",
    Occupancy_Classification_Building_2014_2008_Code: "N/A",
    Occupancy_Classification_of_Building: "N/A",
    Construction_Classification_Code_2014_2008_Code: "N/A",
    Construction_Classification_Code: "N/A",
    Multiple_Dwelling_Classification: "N/A",
    Number_of_Dwelling_Units: "N/A",
    Building_Height: "N/A",
    Number_of_Stories: "N/A",
    Zoning_District: "",
    Commercial_Overlay: "",
    Specials_Districts: "",
    Zoning_Map_Number: "N/A",
    Previous_Solar_Application: "N/A",
    Tidal_Wetlands: "N/A",
    Fresh_Water_Wetland: "N/A",
    Flood_Hazard: "N/A",
    Coastal_Erosion_Hazard: "N/A",
    Special_Fire_District: "N/A",
    Landmark_Area: "N/A",
    Environmental_Restriction: "N/A",
    Conjunction: "N/A",
    Stop_Work_Order_Exists_on_Property: "N/A",
    Active_Work_Without_Permit_Violations: "N/A",
    NB_Application_Number: "N/A",
    Asbestos_Required: "N/A",
    Certificate_of_Occupancy_Available: "N/A",
    ACP5_Completed: "N/A",
    Asbestos_Inspector_Certificate_Number: "N/A",
    ACP5_Control_Number: "N/A",
  };
  const CondoInfoFeilds = {
    Condo_Mailing_Address: "N/A",
    Condo_Street_Name: "N/A",
    Condo_City: "N/A",
    Condo_State_Name: "N/A",
    Condo_Zip_Code: "N/A",
    Condo_House_Number: "N/A",
    Condo_Phone_Number: "N/A",
    Condo_Email_Address: "N/A",
    Condo_Company_Name: "N/A",
    Condo_Officer_First_Name: "N/A",
    Condo_Officer_Last_Name: "N/A",
    Condo_Officer_Middle_Initial: "N/A",
    Condo_Officer_Title: "N/A",
  };
  const UnapplicableFromAsbestos = {
    ACP5_Completed: "N/A",
    ACP5_Control_Number: "N/A",
    Asbestos_Inspector_Certificate_Number: "N/A",
  };

  const unApplicableFeildSorting = async (alldata) => {
    alldata = alldata.map((d) => {
      let data = { ...d };
      if (d.Is_NYC_Project == "No") {
        data = { ...data, ...NycProjectNotApplicableFields };
      }
      if (
        d.Asbestos_Required == "No" &&
        d.Certificate_of_Occupancy_Available == "No"
      ) {
        data = { ...data, ...UnapplicableFromAsbestos };
      }
      if (d.Owner_Type == "INDIVIDUAL") {
        if (d.Owner_s_Mailing_Address_Diff_Than_Property_Address == false) {
          data = {
            ...data,
            ...otheOwnerInfoFeilds,
            ...CondoInfoFeilds,
            ...homeOwnerAddInfoFeilds,
          };
        } else {
          data = { ...data, ...otheOwnerInfoFeilds, ...CondoInfoFeilds };
        }
      } else if (d.Owner_Type == "CONDO/CO-OP") {
        data = { ...data, ...homeOwnerAddInfoFeilds, ...homeOwnerInfoFeilds };
      } else if (
        d.Owner_Type == "PARTNERSHIP" ||
        d.Owner_Type == "CORPORATION" ||
        d.Owner_Type == "OTHER GOVERNMENT AGENCY" ||
        d.Owner_Type == "NYC AGENCY" ||
        d.Owner_Type == "NYCHA/HHC"
      ) {
        data = {
          ...data,
          ...homeOwnerAddInfoFeilds,
          ...homeOwnerInfoFeilds,
          ...CondoInfoFeilds,
        };
      }
      return data;
    });
    return alldata;
  };
  //hrushikesh Shelke new code end
  const modifyData = (data) => {

    let newData = []
    data.forEach(d => {
      let arrOfRows = []
      arrOfRows.push({ ...d, Row_Span_Count: false });
      if (d.Previous_Solar_Info?.length > 0) {
        d.Previous_Solar_Info.forEach((v, i) => {
          if (arrOfRows[i]) {
            if (i == 0) {
              arrOfRows[i] = { ...arrOfRows[i], Row_Span_Flag: "Previous_Solar_Application", Solar_Columns: v }
            } else {
              arrOfRows[i] = { ...arrOfRows[i], Row_Span_Count: true, Row_Span_Flag: "Previous_Solar_Application", Solar_Columns: v }
            }
          } else {
            if (i == 0) {
              arrOfRows.push({ ...d, Row_Span_Flag: "Previous_Solar_Application", Solar_Columns: v })
            } else {
              arrOfRows.push({ ...d, Row_Span_Count: true, Row_Span_Flag: "Previous_Solar_Application", Solar_Columns: v })
            }
          }
        })
      }
      if (d.WWP_Violations?.length > 0) {
        d.WWP_Violations.forEach((v, i) => {
          if (arrOfRows[i]) {
            if (i == 0) {
              arrOfRows[i] = { ...arrOfRows[i], Row_Span_Flag: "Violation_Information", Violation_Columns: v }
            } else {
              arrOfRows[i] = { ...arrOfRows[i], Row_Span_Count: true, Row_Span_Flag: "Violation_Information", Violation_Columns: v }
            }
          } else {
            if (i == 0) {
              arrOfRows.push({ ...d, Row_Span_Flag: "Violation_Information", Violation_Columns: v })
            } else {
              arrOfRows.push({ ...d, Row_Span_Count: true, Row_Span_Flag: "Violation_Information", Violation_Columns: v })
            }
          }
        })
      }
      if (d.PV_Interconnection?.length > 0) {
        d.PV_Interconnection.forEach((v, i) => {
          if (arrOfRows[i]) {
            if (i == 0) {
              arrOfRows[i] = { ...arrOfRows[i], Row_Span_Flag: "PV_Interconection", Pv_Columns: v }
            } else {
              arrOfRows[i] = { ...arrOfRows[i], Row_Span_Count: true, Row_Span_Flag: "PV_Interconection", Pv_Columns: v }
            }
          } else {
            if (i == 0) {
              arrOfRows.push({ ...d, Row_Span_Flag: "PV_Interconection", Pv_Columns: v })
            } else {
              arrOfRows.push({ ...d, Row_Span_Count: true, Row_Span_Flag: "PV_Interconection", Pv_Columns: v })
            }
          }
        })
      }
      newData = [...newData, ...arrOfRows];
    })

    return newData
  }
  const getDealList = async () => {
    const backendUrl =
      Config.backend_url + "getAllDealPages";
    var config = {
      token: localStorage.getItem("token"),
    };
    setQuerySuccess(true);
    await axios
      .post(backendUrl, { ...config })
      .then(async function (res) {
        res.data.data = await unApplicableFeildSorting(res.data.data);
        if (props.dealLocation == "Accounts") {
          res.data.data = res.data.data.filter(
            (one) => one.Account_Name && one.Account_Name.id == props.data.id
          );
        }else{

          for (let i = 0; i < subformApiName.length; i++) {
            if (subformApiName[i] !== "Deal_Name") {
              let res1 = await ProjectService.GetSubformData(subformApiName[i]);
              res1.forEach((subform) => {
                res.data.data.forEach((one, dealIdx) => {
                  if (one?.id == subform.Parent_Id?.id) {
                    if (res.data.data[dealIdx][subformApiName[i]]) {
                      res.data.data[dealIdx][subformApiName[i]].push(subform);
                    } else {
                      res.data.data[dealIdx][subformApiName[i]] = [subform];
                    }
                  }
                });
              });
            }
          }
          for (let i = 0; i < moduleApiNameForSubform.length; i++) {
            let res1 = await ModuleService.GetModuleListData(
              moduleApiNameForSubform[i]
            );
            res.data.data.forEach((deal, dealIdx) => {
              let temp = res1.filter(
                (one) => one.Deal_Name && one.Deal_Name.name == deal.Deal_Name
              );
              res.data.data[dealIdx][moduleApiNameForSubform[i]] = temp;
            });
          }
        }
        res.data.data = res.data.data.map((one) => {
          one.Created_By = one.Created_By.name;
          one.Owner = one.Owner.name;
          one.Account_Name = one.Account_Name ? one.Account_Name.name : "";
          return one;
        });

        if (props.setRefreshEnable) {
          props.setRefreshEnable(false);
        }
        let newData = await modifyData(res.data.data);
        setRowData(newData)
        setRowDataCompactView(newData)
        setFilteredData(newData)
        setcacheData(newData);
        setQuerySuccess(false);
        setRefresh(true)
        props?.setDeleteDealFlag('')
      })
      .catch(function (error) {
        setQuerySuccess(false);
        console.log(error);
      });
  };

  const onDragColumns = (e, value, list) => {
    e.preventDefault();
    selectedIndx = list.findIndex((x) => x.name === value.name);
    selectedValue = value;
  };

  const onDropColumns = (e, value, list) => {
    e.preventDefault();
    let newClient = [...headerData];
    if (selectedIndx !== 0 || dropedIndx === 0) {
      dropedIndx = newClient.findIndex((x) => x.name === value.name);
      dropedValue = value;
      newClient.splice(selectedIndx, 1);
      newClient.splice(dropedIndx, 0, selectedValue);
    }

    newClient.forEach((data) => {
      data.isDragOver = false;
    });

    setheaderData(newClient);
  };

  const onDragOverColumns = (e, value, list) => {
    // e.preventDefault();
    // if (isDragOver !== value.name) {
    //   isDragOver = value.name;
    //   let newList = [...headerData];
    //   newList.forEach((data) => {
    //     if (data.name.toUpperCase().includes(value.name.toUpperCase())) {
    //       data.isDragOver = true;
    //     } else {
    //       data.isDragOver = false;
    //     }
    //   });
    //   setheaderData(newList)
    // }
  };

  //new code start
  function formatToUSCurrency(params) {
    let number = params.data.Project_Cost;
    if (typeof number !== "number") {
      return "";
    }
    return "$" + number.toLocaleString("en-US");
  }
  function conjunctionShow(params) {
    if (params.data.Conjunction == "Yes") {
      return params.data.Conjunction_Application_Number;
    } else if (params.data.Conjunction == "N/A") {
      return "N/A";
    } else {
      return "No";
    }
  }
  function acp5DataRender(params) {
    let d = params.data.ACP5_Completed;
    if (d == "Yes") {
      let customDiv = document.createElement('div');
      customDiv.className = "Eye-Icon-Custome"
      customDiv.innerHTML = `<span>${d}</span>
      <svg class="svg-for-prev ACP5_Completed" id=${params.data.zohoworkdriveforcrm__Workdrive_Folder_ID} xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
      `
      let svgComp = customDiv.querySelectorAll(".svg-for-prev")[0];
      svgComp.addEventListener('click', async (e) => {

        setQuerySuccess(true);
        try {
          let file = await getFileByFileType("_ACP5", e.currentTarget.id)
          if (file) {
            await getchFiletDataUrl(file.fileId, file.fileName)
          } else {
            setQuerySuccess(false);
            alert("File not found!")
          }
        } catch (error) {
          setQuerySuccess(false);
        }

      })
      return customDiv
    } else {
      return d
    }
  }
  function prevPdfFile(params) {
  }
  const getFileByFileType = async (type, dealFolderId) => {
    if (dealFolderId != null) {
      let file = await axios
        .post(
          Config.backend_url + "getFilingFolderID",
          {
            id: dealFolderId,
            Authorization: localStorage.getItem("token"),
          }
        )
        .then((r) => {
          let ob = [];
          r.data.all_filing_files.map((e) => {
            if (!e.name.includes("ARCHIVE")) {
              if (e.name.includes(type)) {
                ob.push({
                  fileId: e.id,
                  fileName: e.name
                });
              }
            }
          });
          return ob.length > 0 ? ob[0] : undefined;
        });
      return file
    }
  }
  function DHCRDataRender(params) {

    let d = params.data.DHCR
    if (d == "Yes") {
      let customDiv = document.createElement('div');
      customDiv.className = "Eye-Icon-Custome"
      customDiv.innerHTML = `<span>${d}</span>
      <svg class="svg-for-prev DHCR" id=${params.data.zohoworkdriveforcrm__Workdrive_Folder_ID} xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
      `
      let svgComp = customDiv.querySelectorAll(".svg-for-prev")[0];
      svgComp.addEventListener('click', async (e) => {

        setQuerySuccess(true)
        try {
          let file = await getFileByFileType("_DHCRLETTER", e.currentTarget.id)
          if (file) {
            await getchFiletDataUrl(file.fileId, file.fileName)
          } else {
            setQuerySuccess(false);
            alert("File not found!")
          }
        } catch (error) {
          setQuerySuccess(false)
        }
      })
      return customDiv
    } else {
      return d
    }
  }
  function getBorough(jobData) {
    let borough;
    if (jobData.Borough == "Manhattan" || jobData.Borough == "Manhattann") {
      borough = "1";
    } else if (jobData.Borough == "Bronx") {
      borough = "2";
    } else if (jobData.Borough == "Brooklyn") {
      borough = "3";
    } else if (jobData.Borough == "Queens") {
      borough = "4";
    } else if (jobData.Borough == "Staten Island") {
      borough = "5";
    } else {
      borough = jobData.Borough;
    }
    return borough;
  }
  function renderLinks(params, e) {

    let content;
    if (e.field == "taxmap_link" && params.data.Is_NYC_Project == "Yes") {
      content = document.createElement("a");
      content.target = "_blank"
      content.href = `https://propertyinformationportal.nyc.gov/parcels/parcel/${getBorough(params.data)}${params.data.Block_Number}${params.data.Lot_Number}`;
      content.innerText = "Digital Tax Map"
    } else if (e.field == "bis_link" && params.data.Is_NYC_Project == "Yes") {
      content = document.createElement("a");
      content.target = "_blank"
      content.href = `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=${getBorough(params.data)}&houseno=${params.data.House_Number}&street=${params.data.Street_Name}&go2=+GO+&requestid=2`;
      content.innerText = "Bis"
    } else if (e.field == "zola_link" && params.data.Is_NYC_Project == "Yes") {
      content = document.createElement("a");
      content.target = "_blank"
      content.href = `https://zola.planning.nyc.gov/l/lot/${getBorough(params.data)}/${params.data.Block_Number}/${params.data.Lot_Number}`;
      content.innerText = "Zola"
    } else if (e.field == "acris_link" && params.data.Is_NYC_Project == "Yes") {
      content = document.createElement("a");
      content.target = "_blank"
      content.href = `http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${getBorough(params.data)}&block=${params.data.blockNo}&lot=${params.data.lotNo}`;
      content.innerText = "Acris"
    } else {
      content = "-"
    }
    return content
  }
  function RenderTheNestedCollapsData(params, e) {
    if (e.collapsGroup == "Previous_Solar_Application_Info") {
      try {
        return params.data.Solar_Columns[e.field];
      } catch (error) {
        return '-'
      }
    } else if (e.collapsGroup == "Active_Work_Without_Permit_Violations") {
      try {
        return params.data.Violation_Columns[e.field];
      } catch (error) {
        return '-'
      }
    } else if (e.collapsGroup == "PV_Interconection") {
      try {
        return params.data.Pv_Columns[e.field];
      } catch (error) {
        return '-'
      }
    } else if (e.field == "Deal_Name") {
      return navigateToProject(params, 'fade')
    } else {
      return ''
    }
  }
  function NonProfitDataRender(params) {

    let d = params.data.Non_Profit
    if (d == "Yes") {
      let customDiv = document.createElement('div');
      customDiv.className = "Eye-Icon-Custome"
      customDiv.innerHTML = `<span>${d}</span>
      <svg class="svg-for-prev NonProfit" id=${params.data.zohoworkdriveforcrm__Workdrive_Folder_ID} xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
      `
      let svgComp = customDiv.querySelectorAll(".svg-for-prev")[0];
      svgComp.addEventListener('click', async (e) => {

        setQuerySuccess(true)
        try {
          let file = await getFileByFileType("_DEED", e.currentTarget.id)
          if (file) {
            await getchFiletDataUrl(file.fileId, file.fileName)
          } else {
            setQuerySuccess(false);
            alert("File not found!")
          }
        } catch (error) {
          setQuerySuccess(false)
        }
      })
      return customDiv
    } else {
      return d
    }
  }
  function contractorName(params) {
    let contractor = params.data.General_Contractor;
    if (contractor) {
      return contractor.name;
    } else {
      return "";
    }
  }
  function electricianName(params) {
    let electrician = params.data.Electrician;
    if (electrician) {
      return electrician.name;
    } else {
      return "";
    }
  }
  function stopWorkFunction(params) {

    let stopWork = params.data.Stop_Work_Order_Exists_on_Property;
    if (stopWork == "N/A") {
      return "N/A";
    }
    if (stopWork == "No") {
      return stopWork;
    } else {
      if (params.data.Partial == true) {
        return "Partial SWO Exists";
      } else {
        return "Full SWO Exists";
      }
    }
  }
  function tableTotalSysSize(params) {
    let totalSystemSize = params.data.Total_System_Size;
    if (totalSystemSize || totalSystemSize == 0) {
      return totalSystemSize.toFixed(3);
    } else {
      return "";
    }
  }
  function convertTimeZone(params) {
    let convertTime = new Date(params.data.Created_Time);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/New_York' // Setting timezone to US Eastern Time
  };
    return convertTime.toLocaleString('en-US', options);
  }
  function modifyByName(params) {
    let name1 = params.data.Modified_By.name;
    return name1;
  }
  function modifyTime(params) {
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'America/New_York' // Setting timezone to US Eastern Time
  };
    let ModifyTime = new Date(params.data.Modified_Time);
    return ModifyTime.toLocaleString('en-US', options);
  }

  function getTextColor(params) {
    let conj = params.data.Conjunction;
    if (conj == "No") {
      return { ...staticCellStyle, ...fadeColour };
    } else {
      return staticCellStyle;
    }
  }
  //new code Ends



  //function to get active Column Filters
  const customizeFilterByViewType = (mainFilters, colFilters) => {
    let newColFilters = {};
    // if(mainFilters?.length==0 && location.state.module == "Deals"){
    //   mainFilters=[{ phase: "initial", module: "Deal" }];
    // }
    if (viewType == "List") {
      if (colFilters) {
        colFilters = Object.keys(colFilters).forEach(key => {
          if (!Coldefs["Previous_Solar_Application_Info"].find((e, i) => i > 0 && e.field == key)
            && !Coldefs["WWP_Violation_Info"].find((e, i) => i > 0 && e.field == key)
            && !Coldefs["PV_Interconection"].find((e, i) => i > 0 && e.field == key)) {
            newColFilters[key] = colFilters[key];
          } else {

          }
        })
      }
    } else {
      newColFilters = colFilters;
    }
    return { main: mainFilters, col: newColFilters }
  }
  const setActiveFilterAndGetActiveFilters = async (filters, action, mainFilters) => {
    let api = viewType == "List" ? gridApi : compactViewGridApis && compactViewGridApis["Deal_Name"];
    if (action == "SAVE") {
      if (api) {
        let filtersModal = await api.getFilterModel();
        return filtersModal;
      }
    } else if (action == "SET") {
      let modifiedFilters = customizeFilterByViewType(mainFilters, filters)
      setPreAppliedFiltersForView(modifiedFilters.main)
      setActiveFiltersOnColumnLevel(modifiedFilters.col);
    }
  }

  const onFirstDataRendered = async (e) => {
    let modifiedFilters = customizeFilterByViewType(preAppliedFiltersForView, activeFiltersOnColumnLevel)
    if (viewType == "List" && gridApi) {
      if (rowData && activeFiltersOnColumnLevel != null) {
        let filtersModal = await gridApi?.setFilterModel(modifiedFilters.col);
      } else if (activeFiltersOnColumnLevel == null) {
        let filtersModal = await gridApi?.setFilterModel(null);
      }
    }
  }


  const onGridReady = async (params) => {
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
    // onFirstDataRendered();
    // await getDealList();
    setQuerySuccess(false);
  };

  const paginationAction = (api) => {

    if (api) {
      const currentPage = api.paginationGetCurrentPage();
      const totalPage = api.paginationGetTotalPages();
      const totalRows = api.paginationGetRowCount();
      const pageSize = api.paginationGetPageSize();
      const fromRow = currentPage * pageSize + 1;
      const toRow = Math.min(fromRow + pageSize - 1, totalRows);
      setFromRow(fromRow);
      setToRow(toRow);
      setTotalRow(totalRows);
      fromRow != 1 ? setbackActive(true) : setbackActive(false);
      if (toRow === totalRows) {
        setfrontActive(false);
        setdotActive(true);
      } else {
        setfrontActive(true);
        setdotActive(false);
      }
    }
  }
  const getPagingValues = () => {

    if (viewType == "List" && gridApi != null) {
      paginationAction(gridApi)
    }
  };
  const [currentSortstate, setcurrentSortState] = useState([]);
  const [globalSearchValue, setGlobalSearchValue] = useState("");
  useEffect(e => {
    if (compactViewGridApis) {
      onPageSizeChanged();
      if (from) {
        let pageNo = compactViewGridApis["Deal_Name"].paginationGetCurrentPage();
        if (pageNo) {
          Object.keys(compactViewGridApis).forEach(api => {
            compactViewGridApis[api].paginationGoToPage(pageNo);
          })
        }
      }
      if (viewType != "List") {
        Object.keys(compactViewGridApis).forEach(async api => {
          if (compactViewGridApis["Deal_Name"].getSelectedRows().length == compactViewGridApis["Deal_Name"].getDisplayedRowCount()) {
            if (compactViewGridApis[api].getSelectedRows()?.length !== rowsSelected?.length) {
              await compactViewGridApis[api].selectAll();
            }
          } else if (compactViewGridApis["Deal_Name"].getSelectedRows().length == 0) {
            if (compactViewGridApis[api].getSelectedRows()?.length !== rowsSelected?.length) {
              await compactViewGridApis[api].deselectAll();
            }
          }
          if (globalSearchValue && globalSearchValue.length > 0) {
            compactViewGridApis[api].setQuickFilter(globalSearchValue);
          }
          compactViewGridApis[api].setSortModel(currentSortstate);
          selectTableRows(compactViewGridApis[api])
          if (activeFiltersOnColumnLevel) {
            compactViewGridApis[api].setFilterModel(activeFiltersOnColumnLevel)
          }

          if (rowsSelected) {
            compactViewGridApis[api].forEachNode(node => {
              if (rowsSelected.includes(node.data.Deal_Name)) {
                node.setSelected(true)
              } else {
                node.setSelected(false)
              }
            })
          }
        })
      }
    } else if (viewType == "List") {
      if (gridApi) {
        if (from) {
          let pageNo = gridApi?.paginationGetCurrentPage();
          if (pageNo) {
            gridApi.paginationGoToPage(pageNo)
          }
        }
        if (activeFiltersOnColumnLevel) {
          gridApi.setFilterModel(activeFiltersOnColumnLevel)
        }
      }
    }
  }, [compactViewGridApis, gridApi, triggerGridApiEffect])

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById("page-size").value;
    if (viewType == "List") {
      gridApi.paginationSetPageSize(Number(value));
      let updatedPage = Math.floor(from / value);
      gridApi.paginationGoToPage(updatedPage);
      getPagingValues();
    } else {
      if (compactViewGridApis) {
        Object.keys(compactViewGridApis).forEach(api => {
          compactViewGridApis[api].paginationSetPageSize(Number(value));
          let updatedPage = Math.floor(from / value);
          compactViewGridApis[api].paginationGoToPage(updatedPage);
        })
        paginationAction(compactViewGridApis[Object.keys(compactViewGridApis)[0]]);
      }
    }
  };

  // Nagesh Altekar Code start's

  // Export the selected rows to excel format function //



  const exportListViewData=(fileName)=>{
    gridApi.exportDataAsExcel({
      fileName: fileName,
      onlySelected: true,
      processCellCallback: function(params) {
        const rowData = params.node.data;
        const columnField = params.column?.getColDef().field;
        console.log("columnField", columnField);
        // console.log("rowData", rowData.PV_Interconnection);
        if (columnField === 'Active_Work_Without_Permit_Violations') {
            return rowData.Active_Work_Without_Permit_Violations == "Yes" ?
                   (rowData.WWP_Violations?.length == 0 ? "Required" : "Provided") :
                   rowData.Active_Work_Without_Permit_Violations;
        }
        if (columnField === 'Previous_Solar_Info') {
          return rowData.Previous_Solar_Application == "Yes" ?
                 (rowData.Previous_Solar_Info?.length == 0 ? "Required" : "Provided") :
                 rowData.Previous_Solar_Application;
      }
      if (columnField === 'Project_Status') {
        return rowData.Stage
      }
      if (columnField === 'PV_Interconnection') {
        return (rowData.PV_Interconnection?.length == 0 || rowData.PV_Interconnection == undefined) ? "Required" : "Provided"
      }
      if (columnField === 'General_Contractor') {
        return rowData.General_Contractor?.name
      }
      if (columnField === 'Electrician') {
        return rowData.Electrician?.name
      }
      if (columnField === 'Modified_By') {
        return rowData.Modified_By?.name
      }
       if (columnField === 'Block_Number') {
        return (rowData.Block_Number).toString();
            }
       if (columnField === 'Lot_Number') {
        return (rowData.Lot_Number).toString();
      }
      if (columnField === 'Stop_Work_Order_Exists_on_Property') {
        let stopWork =rowData.Stop_Work_Order_Exists_on_Property;
            if (stopWork == "N/A") {
              return "N/A";
            }
            if (stopWork == "No") {
              return stopWork;
            } else {
              if (rowData.Partial == true) {
                return "Partial SWO Exists";
              } else {
                return "Full SWO Exists";
              }
            }
      }
      if (columnField === 'Certificate_of_Occupancy_Available') {
        if (rowData.Asbestos_Required == "Yes") {
          return "N/A";
        }else{
          return rowData.Certificate_of_Occupancy_Available
        } 
      }
      if (columnField === 'NB_Application_Number') {
        if (
          rowData.Asbestos_Required == "No" &&
          rowData.Certificate_of_Occupancy_Available == "No"
        ) {
          return rowData.NB_Application_Number;
        } else {
          return "N/A";
        }
      }
      if (columnField === 'Conjunction') {
        return rowData.Conjunction == "Yes" ? rowData.Conjunction_Application_Number : rowData.Conjunction == "N/A" ? "N/A" : "No"
      }
      if (columnField === 'Modified_Time') {
        const options = { 
          month: '2-digit', 
          day: '2-digit', 
          year: 'numeric',
          hour: 'numeric', 
          minute: 'numeric', 
          hour12: true,
          timeZone: 'America/New_York' // Setting timezone to US Eastern Time
      };
        return new Date(rowData.Modified_Time).toLocaleString('en-US', options)
      }
      if (columnField === 'Created_Time') {
        const options = { 
          month: '2-digit', 
          day: '2-digit', 
          year: 'numeric',
          hour: 'numeric', 
          minute: 'numeric', 
          hour12: true,
          timeZone: 'America/New_York' // Setting timezone to US Eastern Time
      };
        return new Date(rowData.Created_Time).toLocaleString('en-US', options)
      }
        return params.value;
    }
    });
    
props.dealListTriggers({type:"downloadExcel"})
}

let hideArray2=[
  "Application_Number", 
  "L2_Code",
  "Civil_Penalty_Payment_Required",
  "Paid_Under_Job_Number",
  "Invoice_number",
  "Interconnection_Number",
  "System_Size_KW1",
  "Number_Of_Modules",
  "PV_Module_Model",
  "Invertor_Type",
  "Number_of_Invertors",
  "Invertor_Model_Number",
  "Current_Application_Status",
  "Inspection_Passed_Date",
  "Withdrawal_Required",
  'Installation_Completed',
  "Inspection_Ready_Email_Address",
  "Violatio_Number",
  "Violation_Type",
  "Application_Supersede_Required",
  'Current_Applicant',
  "GC_Supersede_Required",
  "GC_Company_Name",
  "GC_Name",
  "GC_License_Number",
  'Reinstatement_Required',
  "Inspection_Passed"]
  
  const exportCompactViewData = (fileName) => {
    // Check if compactViewGridApis is empty
    if (Object.keys(compactViewGridApis).length === 0) {
      console.error("No grid APIs found. Unable to export.");
      return;
    }
    
    // Collect data from all grid APIs
    // let combinedData = [];
    // Object.keys(compactViewGridApis).forEach(api => {
    //   const currentGridApi = compactViewGridApis[api];
    //   if (currentGridApi && currentGridApi.forEachNode) {
    //       currentGridApi.forEachNode(node => {
    //         console.log("node.data",node.data);
    //           combinedData.push(node.data);
    //       });
    //   }
    // });
    // Check if combinedData is not empty
    // if (combinedData.length > 0) {
      // Export the combined data using exportDataAsExcel for each grid API
      // Object.keys(compactViewGridApis).forEach(api => {
          // const currentGridApi = compactViewGridApis[api];
          //  if (currentGridApi && currentGridApi.exportDataAsExcel) {
              compactViewGridApis["Deal_Name"].exportDataAsExcel({
                  fileName: fileName,
                  // data: combinedData,
                  allColumns:true,
                  onlySelected: true,
                  processHeaderCallback: params => {
                    const columnField = params.column?.getColDef().field;
                    if (hideArray.includes(columnField)||hideArray2.includes(columnField)) {
                      return '';
                    }
                    return columnField;
                  },
                  processCellCallback: function(params) { 
                      const rowData = params.node.data;
                      const columnField = params.column?.getColDef().field;
                      if (hideArray.includes(columnField)||hideArray2.includes(columnField)) {
                        return ''; 
                      }
                      if (columnField === 'Active_Work_Without_Permit_Violations') {
                        return rowData.Active_Work_Without_Permit_Violations == "Yes" ?
                               (rowData.WWP_Violations?.length == 0 ? "Required" : "Provided") :
                               rowData.Active_Work_Without_Permit_Violations;
                    }
                    if (columnField === 'Previous_Solar_Info') {
                      return rowData.Previous_Solar_Application == "Yes" ?
                             (rowData.Previous_Solar_Info?.length == 0 ? "Required" : "Provided") :
                             rowData.Previous_Solar_Application;
                  }
                  if (columnField === 'Project_Status') {
                    return rowData.Stage
                  }
                  if (columnField === 'PV_Interconnection') {
                    return (rowData.PV_Interconnection?.length == 0 || rowData.PV_Interconnection == undefined) ? "Required" : "Provided"
                  }
                  if (columnField === 'General_Contractor') {
                    return rowData.General_Contractor?.name
                  }
                  if (columnField === 'Electrician') {
                    return rowData.Electrician?.name
                  }
                  if (columnField === 'Modified_By') {
                    return rowData.Modified_By?.name
                  }
                   if (columnField === 'Block_Number') {
                    return (rowData.Block_Number).toString();
                        }
                   if (columnField === 'Lot_Number') {
                    return (rowData.Lot_Number).toString();
                  }
                  if (columnField === 'Stop_Work_Order_Exists_on_Property') {
                    let stopWork =rowData.Stop_Work_Order_Exists_on_Property;
                        if (stopWork == "N/A") {
                          return "N/A";
                        }
                        if (stopWork == "No") {
                          return stopWork;
                        } else {
                          if (rowData.Partial == true) {
                            return "Partial SWO Exists";
                          } else {
                            return "Full SWO Exists";
                          }
                        }
                  }
                  if (columnField === 'Certificate_of_Occupancy_Available') {
                    if (rowData.Asbestos_Required == "Yes") {
                      return "N/A";
                    }else{
                      return rowData.Certificate_of_Occupancy_Available
                    } 
                  }
                  if (columnField === 'NB_Application_Number') {
                    if (
                      rowData.Asbestos_Required == "No" &&
                      rowData.Certificate_of_Occupancy_Available == "No"
                    ) {
                      return rowData.NB_Application_Number;
                    } else {
                      return "N/A";
                    }
                  }
                  if (columnField === 'Conjunction') {
                    return rowData.Conjunction == "Yes" ? rowData.Conjunction_Application_Number : rowData.Conjunction == "N/A" ? "N/A" : "No"
                  }
                  if (columnField === 'Modified_Time') {
                    const options = { 
                      month: '2-digit', 
                      day: '2-digit', 
                      year: 'numeric',
                      hour: 'numeric', 
                      minute: 'numeric', 
                      hour12: true,
                      timeZone: 'America/New_York' // Setting timezone to US Eastern Time
                  };
                    return new Date(rowData.Modified_Time).toLocaleString('en-US', options)
                  }
                  if (columnField === 'Created_Time') {
                    const options = { 
                      month: '2-digit', 
                      day: '2-digit', 
                      year: 'numeric',
                      hour: 'numeric', 
                      minute: 'numeric', 
                      hour12: true,
                      timeZone: 'America/New_York' // Setting timezone to US Eastern Time
                  };
                    return new Date(rowData.Created_Time).toLocaleString('en-US', options)
                  }
                      return params.value;
                  }
              });
          // } else {
          //     console.error("exportDataAsExcel is not available on one of the grid APIs. Unable to export.");
          // }
      // });
    // } else {
    //   console.error("Combined data is empty. Unable to export.");
    // }
    }


const onExportBtnClick = (fileName) =>{

  if(viewType && viewType=="List"){
    exportListViewData(fileName)
  }else{
    if(compactViewGridApis){
      exportCompactViewData(fileName)
      // compactViewGridApis["Deal_Name"].exportDataAsExcel(fileName)
    }
  }
  props.dealListTriggers({type:"downloadExcel",status:undefined})
}

  // Export the selected rows to excel format function //

  // Nagesh Altekar Code start's
  let allNodes = [];
  const onSelectionChanged = event => {

    let flag = false;
    let ActualRows = [];
    if (viewType == "Compact") {
      compactViewGridApis["Deal_Name"].getSelectedRows().forEach((element) => {
        if (!ActualRows.includes(element.Deal_Name)) {
          ActualRows.push(element.Deal_Name);
        }
      })
      if (ActualRows.length != rowsSelected.length || ActualRows.length == 0) {
        flag = true;
      }
      if (!flag) {
        setSouldRender(false);
      }
    }
    if (viewType == "Compact" && (shouldRender || flag)) {
      let rows = event.api.rowModel?.rowsToDisplay.filter(node => node.isSelected());

      if (rows.length == event.api.rowModel.rowsToDisplay.length && rowsSelected.length < rows.length && rowsSelected.length != 0) {
        event.api.deselectAll();
      } else {
        let arr = []
        let idsArray = [];
        rows = rows.filter(r => {
          let flag = r.data?.Row_Span_Count;
          if (flag) {
            return false
          } else {
            if (!arr.includes(r.data.Deal_Name)) {
              arr.push(r.data.Deal_Name);
              idsArray.push(r.data.id);
              return true
            }
          }
        })
        props.setSelectedIds(idsArray)
        setRowsSelected(arr);
      }
    } else if (viewType == "List") {
      let listTypeRows = event.api.rowModel?.rowsToDisplay.filter(node => node.isSelected());
      if (listTypeRows.length == event.api.rowModel.rowsToDisplay.length && listSelectedRows.length < listTypeRows.length && listSelectedRows.length != 0) {
        event.api.deselectAll();
      } else {
        let array = [];
        event.api.forEachNode((node) => {
          allNodes.push(node?.data)
          const nodeRowId = node?.data?.id;
          if (nodeRowId) {
            if (node.isSelected()) {
              // code 
              array.push(nodeRowId)
            } else {
              // code
            }
          }
        });
        setlistSelectedRows(listTypeRows);
        props.setSelectedIds(array)
      }
    }
  };
  useEffect(() => {
    if (props.dealListStatus?.ExcelDownload == "download") {
      onExportBtnClick("Selected_RowData.xlsx")
    }
  }, [props.dealListStatus?.ExcelDownload])

  const onBtNext = () => {

    if (viewType && viewType == "List") {
      gridApi.paginationGoToNextPage();
      getPagingValues();
    } else {
      if (compactViewGridApis) {
        Object.keys(compactViewGridApis).forEach(api => {
          compactViewGridApis[api].paginationGoToNextPage();
        })
        paginationAction(compactViewGridApis[Object.keys(compactViewGridApis)[0]]);
      }
    }
  };

  const onBtPrevious = () => {

    if (viewType && viewType == "List") {
      gridApi.paginationGoToPreviousPage();
      getPagingValues();
    } else {
      if (compactViewGridApis) {
        Object.keys(compactViewGridApis).forEach(api => {
          compactViewGridApis[api].paginationGoToPreviousPage();
        })
        paginationAction(compactViewGridApis[Object.keys(compactViewGridApis)[0]]);
      }
    }
  };

  const onBtDoubleNext = () => {

    if (viewType && viewType == "List") {
      gridApi.paginationGoToNextPage();
      gridApi.paginationGoToNextPage();
      getPagingValues();
    } else {
      if (compactViewGridApis) {
        Object.keys(compactViewGridApis).forEach(api => {
          compactViewGridApis[api].paginationGoToNextPage();
          compactViewGridApis[api].paginationGoToNextPage();
        })
        paginationAction(compactViewGridApis[Object.keys(compactViewGridApis)[0]]);
      }
    }
  };

  const onBtDoublePrevious = () => {
    if (viewType && viewType == "List") {
      gridApi.paginationGoToPreviousPage();
      gridApi.paginationGoToPreviousPage();
      getPagingValues();
    } else {
      if (compactViewGridApis) {
        Object.keys(compactViewGridApis).forEach(api => {
          compactViewGridApis[api].paginationGoToPreviousPage();
          compactViewGridApis[api].paginationGoToPreviousPage();
        })
        paginationAction(compactViewGridApis[Object.keys(compactViewGridApis)[0]]);
      }
    }
  };

  const onSearchFilterTexts = (value) => {
    if (viewType != "List" && compactViewGridApis) {
      setGlobalSearchValue(value);
      Object.keys(compactViewGridApis).forEach(Apikey => {
        compactViewGridApis[Apikey].setQuickFilter(value);
      })
    } else {
      gridApi.setQuickFilter(value);
    }
  };

  const sortByAthleteAsc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: "jobNumber",
          sort: "asc",
        },
      ],
      defaultState: { sort: null },
    });
  };

  const sortByAthleteDesc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: "jobNumber",
          sort: "desc",
        },
      ],
      defaultState: { sort: null },
    });
  };

  const onChange = (event) => {
    setFilterText(event.target.value);
  };

  const ColumnFilter = () => {
    return (
      <div style={{ padding: 10, width: 230, backgroundColor: "white" }}>
        <div style={{ marginTop: 10 }} onClick={() => sortByAthleteAsc()}>
          Sort A to Z
        </div>
        <div style={{ marginTop: 10 }} onClick={() => sortByAthleteDesc()}>
          Sort Z to A
        </div>
        <div>
          <input
            style={{ margin: "4 0 4 0" }}
            type="text"
            value={filterText}
            onChange={onChange}
            placeholder="Search"
          />
        </div>
        <div
          style={{
            width: 200,
            height: 150,
            textAlign: "center",
            padding: 10,
            margin: 10,
            border: "1px solid lightgrey",
            backgroundColor: "white",
          }}
        ></div>
      </div>
    );
  };

  const applyFilters = (
    column,
    filterName,
    filterType,
    filterText,
    filterText2,
    duration
  ) => {
    var filtervalue = {
      column: column,
      filterName: filterName,
      filterType: filterType,
      filterText: filterText,
      filterText2: filterText2,
      duration: duration,
    };
    setClearFilterFlag(false);
    gridApi.setFilterModel(null);
    if (pvSystemTableHeaders.some((e) => e.value === filtervalue.column)) {
      custumFilterChange(filtervalue, "PV_Interconnection");
    } else if (
      wwwViolationHeaders.some((e) => e.value === filtervalue.column)
    ) {
      custumFilterChange(filtervalue, "WWP_Violations");
    } else if (
      previousSolarAppHeaders.some((e) => e.value === filtervalue.column)
    ) {
      custumFilterChange(filtervalue, "Previous_Solar_Info");
    } else {
      filterchanged(filtervalue);
    }
  };

  const custumFilterChange = (value, key) => {
    var tableData = cacheData;
    var filtered = [];
    switch (value.filterName) {
      case "clear":
        setRowData(tableData);
        setRowDataCompactView(tableData)
        return true;
      case "contains":
        for (var i = 0; i < tableData.length; i++) {
          if (
            tableData[i][key] &&
            tableData[i][key].some(
              (one) =>
                one[value.column] &&
                one[value.column]
                  .toString()
                  .toLowerCase()
                  .indexOf(value.filterText.toLowerCase()) >= 0
            )
          ) {
            filtered.push(tableData[i]);
          }
        }
        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      case "doesn't contain":
        for (let i = 0; i < tableData.length; i++) {
          if (
            tableData[i][key] &&
            tableData[i][key].some(
              (one) =>
                !one[value.column] ||
                one[value.column]
                  .toString()
                  .toLowerCase()
                  .indexOf(value.filterText.toLowerCase()) === -1
            )
          ) {
            filtered.push(tableData[i]);
          }
        }
        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      case "is":
        try {
          for (let i = 0; i < tableData.length; i++) {
            if (
              tableData[i][key] &&
              tableData[i][key].some(
                (one) =>
                  one[value.column] &&
                  one[value.column].toString().toLowerCase() ==
                  value.filterText.toLowerCase()
              )
            ) {
              filtered.push(tableData[i]);
            }
          }
        } catch (ex) {
          console.log(ex);
        }

        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      case "isn't":
        for (var i = 0; i < tableData.length; i++) {
          if (
            tableData[i][key] &&
            tableData[i][key].some(
              (one) =>
                !one[value.column] ||
                one[value.column].toString().toLowerCase() !==
                value.filterText.toLowerCase()
            )
          ) {
            filtered.push(tableData[i]);
          }
        }
        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      case "starts with":
        for (var i = 0; i < tableData.length; i++) {
          if (
            tableData[i][key] &&
            tableData[i][key].some(
              (one) =>
                one[value.column] &&
                one[value.column]
                  .toString()
                  .toLowerCase()
                  .startsWith(value.filterText.toLowerCase())
            )
          ) {
            filtered.push(tableData[i]);
          }
        }
        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      case "ends with":
        for (var i = 0; i < tableData.length; i++) {
          if (
            tableData[i][key] &&
            tableData[i][key].some(
              (one) =>
                one[value.column] &&
                one[value.column]
                  .toString()
                  .toLowerCase()
                  .endsWith(value.filterText.toLowerCase())
            )
          ) {
            filtered.push(tableData[i]);
          }
        }
        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      case "is empty":
        for (var i = 0; i < tableData.length; i++) {
          if (
            tableData[i][key] &&
            tableData[i][key].some(
              (one) =>
                one[value.column] === "" ||
                one[value.column] == undefined ||
                one[value.column] == null
            )
          ) {
            filtered.push(tableData[i]);
          }
        }
        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      case "is not empty":
        for (var i = 0; i < tableData.length; i++) {
          if (
            tableData[i][key] &&
            tableData[i][key].some((one) => one[value.column])
          ) {
            filtered.push(tableData[i]);
          }
        }
        setRowData(filtered);
        setRowDataCompactView(filtered)
        return true;
      default:
        // should never happen
        console.warn("invalid filter name " + value.filterName);
        return false;
    }
  };

  const filterchanged = (value) => {


    if (
      value.filterType === "text" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      var tableData = cacheData;
      var filtered = [];
      switch (value.filterName) {
        case "contains":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column] &&
              tableData[i][value.column]
                .toLowerCase()
                .indexOf(value.filterText.toLowerCase()) >= 0
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "doesn't contain":
          for (let i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column] &&
              tableData[i][value.column]
                .toLowerCase()
                .indexOf(value.filterText.toLowerCase()) === -1
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "is":
          for (let i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column] &&
              tableData[i][value.column].toLowerCase() ===
              value.filterText.toLowerCase()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "isn't":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column] &&
              tableData[i][value.column].toLowerCase() !==
              value.filterText.toLowerCase()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "starts with":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column] &&
              tableData[i][value.column]
                .toLowerCase()
                .indexOf(value.filterText.toLowerCase()) === 0
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "ends with":
          for (var i = 0; i < tableData.length; i++) {
            var index = tableData[i][value.column]
              .toLowerCase()
              .lastIndexOf(value.filterText.toLowerCase());
            if (
              index >= 0 &&
              index ===
              tableData[i][value.column].length - value.filterText.length
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "is empty":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column] &&
              tableData[i][value.column] === ""
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "is not empty":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column] &&
              tableData[i][value.column] !== ""
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        default:
          // should never happen
          console.warn("invalid filter name " + value.filterName);
          return false;
      }
    } else if (
      value.filterType === "number" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      tableData = cacheData;
      let newData = filterNumberData(
        value.filterName,
        tableData,
        value.column,
        value.filterText,
        value.filterText2
      );
      setRowData(newData);
      setRowDataCompactView(newData)
      return true;
    } else if (
      value.filterType === "radio" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      tableData = cacheData;
      var newData = [];

      switch (value.filterName) {
        case "Selected":
          newData = tableData.filter(
            (e) =>
              (e[value.column]
                ? e[value.column].toLowerCase()
                : e[value.column]) === "yes"
          );
          setRowData(newData);
          setRowDataCompactView(newData)
          return true;
        case "Unselected":
          newData = tableData.filter(
            (e) =>
              (e[value.column]
                ? e[value.column].toLowerCase()
                : e[value.column]) === "no"
          );
          setRowData(newData);
          setRowDataCompactView(newData)
          return true;
      }
    } else if (
      value.filterType === "date" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      tableData = cacheData;
      var filtered = [];
      var todayDate = new Date();
      switch (value.filterName) {
        case "in the last":
          if (value.duration === "days") {
            todayDate.setDate(todayDate.getDate() - value.filterText);

          } else if (value.duration === "weeks") {
            var days = value.filterText * 7;
            todayDate.setDate(todayDate.getDate() - days);

          } else if (value.duration === "months") {
            todayDate.setMonth(todayDate.getMonth() - value.filterText);
          }
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate > todayDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "due in":
          return true;
        case "On":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            if (
              tableDate.getDate() === filterDate.getDate() &&
              tableDate.getMonth() === filterDate.getMonth() &&
              tableDate.getYear() === filterDate.getYear()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "before":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            if (tableDate < filterDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "after":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            if (tableDate > filterDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "between":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            var filterDate2 = new Date(value.filterText2);
            if (tableDate > filterDate && tableDate < filterDate2) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "Today":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (
              tableDate.getDate() === todayDate.getDate() &&
              tableDate.getMonth() === todayDate.getMonth() &&
              tableDate.getYear() === todayDate.getYear()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "Yesterday":
          todayDate.setDate(todayDate.getDate() - 1);
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (
              tableDate.getDate() === todayDate.getDate() &&
              tableDate.getMonth() === todayDate.getMonth() &&
              tableDate.getYear() === todayDate.getYear()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "This Week":
          todayDate.setDate(todayDate.getDate() - 7);
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);

            if (tableDate > todayDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "This Month":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate.getMonth() === todayDate.getMonth()) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "This Month":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate.getYear() === todayDate.getYear()) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "Last Week":
          return true;
        case "Last Month":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate.getMonth() === todayDate.getMonth() - 1) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "is empty":
          for (var i = 0; i < tableData.length; i++) {
            if (tableDate === "") {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        case "is not empty":
          for (var i = 0; i < tableData.length; i++) {
            if (tableDate !== "") {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          setRowDataCompactView(filtered)
          return true;
        default:
          console.warn("invalid filter name " + value.filterName);
          return false;
      }
    } else if ((value.filterName = "clear")) {
      setRowData(cacheData);
      setRowDataCompactView(cacheData)
    }
  };

  const filterNumberData = (
    operation,
    data,
    colText,
    filterText,
    filterText2
  ) => {
    switch (operation) {
      case ">=":
        return data.filter((e) => parseInt(e[colText]) >= parseInt(filterText));
      case "=":
        return data.filter((e) => parseInt(e[colText]) == parseInt(filterText));
      case ">":
        return data.filter((e) => parseInt(e[colText]) > parseInt(filterText));
      case "<":
        return data.filter((e) => parseInt(e[colText]) < parseInt(filterText));
      case "<=":
        return data.filter((e) => parseInt(e[colText]) <= parseInt(filterText));
      case "!=":
        return data.filter(
          (e) => parseInt(e[colText]) !== parseInt(filterText)
        );
      case "between":
        return data.filter(
          (e) =>
            parseInt(e[colText]) > parseInt(filterText) &&
            parseInt(e[colText]) < parseInt(filterText2)
        );
      case "not between":

        let lessArr = data.filter(
          (e) => parseInt(e[colText]) < parseInt(filterText)
        );
        let greatrArr = data.filter(
          (e) => parseInt(e[colText]) > parseInt(filterText2)
        );
        let final = lessArr.concat(greatrArr);
        return final;

      case "is empty":
        let emptyArr = [];
        return emptyArr;
      case "is not empty":
        return data;
    }
  };

  const onSearchColumns = (value) => {
    let newList = [...activeHeaderData];
    if (value == "") {
      newList.forEach((header) => {
        if (header.name) {
          header.isSearch = true;
        }
      });
    } else {
      newList.forEach((header) => {
        if (
          header.name &&
          header.name.toLowerCase().includes(value.toLowerCase())
        ) {
          header.isSearch = true;
        } else {
          header.isSearch = false;
        }
      });
    }
    setSearchValue(value);
    setActiveHeaderData(newList);
  };
  const getNonProfit = (params) => {
    let ownerType = params.data.Owner_Type;
    let feildData = params.data.Non_Profit;
    if (ownerType == "INDIVIDUAL") {
      feildData = "N/A";
    }
    return feildData;
  };
  const getDHCR = (params) => {
    let ownerType = params.data.Owner_Type;
    let feildData = params.data.DHCR;
    if (ownerType == "INDIVIDUAL") {
      feildData = "N/A";
    }
    return feildData;
  };
  const showTilesOfCommercialList = (params) => {
    if (params.data.Commercial_Overlay) {
      let keyData = [...params.data.Commercial_Overlay];
      if (keyData.length == 0) {
        return "N/A";
      }

      const eDiv = document.createElement("div");
      let str = "";
      if (keyData.length > 0) {
        keyData.map((key, i) => {
          (key != "null") ?
            str += `<div class="btn-group deal-status-dropdown-custom deal-status-dropdown-custom-name" style="margin-left:3px">
                <button type="button" style="background-color:#5b6c97"  class="btn btn-sm dropdown-toggle underline-icon" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="visually-hidden">${key}</span>
                 
                </button>
              </div>`
            :
            str = ""
          //str="<h6>Nagesh</h6>"
        });
      }
      eDiv.innerHTML = str;
      return eDiv;
    } else {
      return "";
    }

  };

  const pvSystemTableHeaders = [
    { value: "System_Number", label: "System Number", type: "number" },
    { value: "Interconnection_Number", label: "Interconnection Number", type: "number" },
    { value: "System_Size_KW1", label: "System Size KW", type: "number" },
    { value: "Number_Of_Modules", label: "Number Of Modules", type: "number" },
    { value: "PV_Module_Model", label: "PV Module Model", type: "string" },
    { value: "Invertor_Type", label: "Invertor Type", type: "string" },
    { value: "Number_of_Invertors", label: "Number of Invertors", type: "number" },
    { value: "Invertor_Model_Number", label: "Invertor Model Number", type: "string" },
  ];
  const wwwViolationHeaders = [
    { value: "Violation_Number", label: "Violation Number", type: "string" },
    {
      value: "Violation_Summary",
      label: "Violation Summary",
      extra: "Violation_Number",
      colSpan: 2, type: "string"
    },
    {
      value: "Violation_Copy",
      label: "Violation Copy",
      extra: "Violation_Number",
      colSpan: 2, type: "string"
    },
    { value: "Violation_Type", label: "Violation Type", type: "string" },
    { value: "L2_Code", label: "L2 Code", type: "string" },
    {
      value: "Civil_Penalty_Payment_Required",
      label: "Civil Penalty Payment Required", type: "string"
    },
    { value: "Paid_Under_Job_Number", label: "Paid Under Job Number", type: "string" },
    { value: "Invoice_Number", label: "Invoice Number", type: "string" },
  ];

  const previousSolarAppHeaders = [
    { value: "Application_Number", label: "Application Number", type: "number" },
    {
      value: "Current_Application_Status",
      label: "Current Application Status", type: "string"
    },
    {
      value: "Applicant_Supersede_Required",
      label: "Applicant Supersede Required", type: "string"
    },
    { value: "Current_Applicant", label: "Current Applicant", type: "string" },
    { value: "GC_Supersede_Required", label: "GC Supersede Required", type: "string" },
    { value: "GC_Company_Name", label: "GC Company Name", type: "string" },
    { value: "GC_Name", label: "GC Name", type: "string" },
    { value: "GC_License_Number", label: "GC License Number", type: "number" },
    { value: "Reinstatement_Required", label: "Reinstatement Required", type: "string" },
    { value: "Inspection_Passed", label: "Inspection Passed", type: "string" },
    { value: "Inspection_Passed_Date", label: "Inspection Passed Date", type: "date" },
    { value: "Withdrawal_Required", label: "Withdrawal Required", type: "string" },
    { value: "Installation_Complete", label: "Installation Complete", type: "string" },
    {
      value: "Inspection_Ready_Email_Address",
      label: "Inspection Ready Email Address",
      flag: "Installation_Complete",
      type: "string"
    },
  ];

  const getAntdModalForTableChart = () => {
    return (
      <AntdModal
        open={showDealTableChart}
        title={
          dealTableChartObj.DealName +
          "           >           " +
          dealTableChartObj.headerLabel
        }
        width={900}
        style={{ top: 170, borderRadius: 50 }}
        onCancel={() => setShowDealTableChart(false)}
        footer={null}
      >
        <DealTableChart
          getchFiletDataUrl={getchFiletDataUrl}
          total={dealTableChartObj.total}
          headers={dealTableChartObj.headers}
          data={dealTableChartObj.data}
          folderId={dealTableChartObj.folderId}
          showDealTableChart={showDealTableChart}
        />
      </AntdModal>
    );
  };

  const changeFilterState = () => {
    setFilterState(!filterState);
  };

  const [clearFilterFlag, setClearFilterFlag] = useState(false);
  const clearFilters = () => {
    //gridApi.setFilterModel(null);
    setClearFilterFlag(true);
    setShowFilter("");
  };

  const onColumnPinned = (event) => {
    var allCols = event.columnApi.getAllGridColumns();
    var allFixedCols = allCols.filter(function (col) {
      return col.getColDef().lockPosition;
    });
    var allNonFixedCols = allCols.filter(function (col) {
      return !col.getColDef().lockPosition;
    });
    var pinnedCount = allNonFixedCols.filter(function (col) {
      return col.getPinned() === "left";
    }).length;
    var pinFixed = pinnedCount > 0;
    var columnStates = [];
    allFixedCols.forEach(function (col) {
      if (pinFixed !== col.isPinned()) {
        columnStates.push({
          colId: col.getId(),
          pinned: pinFixed ? "left" : null,
        });
      }
    });
    if (columnStates.length > 0) {
      event.columnApi.applyColumnState({ state: columnStates });
    }
  };
  //-------------------------------------------------------------------------------------------

  const FormatDateData = (params) => {
    getPagingValues();
    let keyData = params.value;
    return keyData ? new Date(keyData).toLocaleDateString() : "";
  };

  const navigateToProject = (params, fade) => {
    let keyData = params.data.Deal_Name;
    if (!keyData) {
      keyData = "N/A";
    }
    const eDiv = document.createElement("div");
    if (fade) {
      eDiv.classList.add("faded-deal")
    }
    eDiv.innerHTML = `<div class="btn-group deal-status-dropdown-custom deal-status-dropdown-custom-name">
        <button type="button" style="background-color:#5b6c97"  class="btn btn-sm dropdown-toggle underline-icon" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="visually-hidden">${keyData}</span>
        </button>
        <ul  class="dropdown-menu">
           <li class="status-deal"><a class="dropdown-item" ></a></li>
        </ul>
      </div><svg id="Deal-${params.data.id}" aria-hidden="true" focusable="false" data-prefix="far" data-icon="folder-open" class="svg-inline--fa fa-folder-open fa-w-18 tablefolder-icon cursor-point" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">' +
      '<path fill="currentColor" d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z">' +
      '</path></svg>`;
    const div = eDiv.querySelectorAll(".underline-icon")[0];
    const folderButton = eDiv.querySelectorAll(".tablefolder-icon")[0];
    folderButton.addEventListener("click", () => {
      if (params.data.zohoworkdriveforcrm__Workdrive_Folder_ID) {
        window.open(
          "https://workdrive.zoho.com/folder/" +
          params.data.zohoworkdriveforcrm__Workdrive_Folder_ID
        );
      }
    });

    div.addEventListener("click", () => {
      history.push({
        pathname: "/d/Deals/DetailView/" + params.data.id,
        state: { data: params.data, action: "DetailView", module: "Deals" },
      });
    });
    return eDiv;
  };

  const showTilesOfSpecialDist = (params) => {
    if (params.data.Specials_Districts) {
      let keyData = [...params.data.Specials_Districts];
      if (keyData.length == 0) {
        return "N/A";
      }
      const eDiv = document.createElement("div");
      let str = "";
      if (keyData.length > 0) {
        keyData.map((key, i) => {
          (key != "null") ?
            str += `<div class="btn-group deal-status-dropdown-custom deal-status-dropdown-custom-name" style="margin-left:3px">
                  <button type="button" style="background-color:#5b6c97"  class="btn btn-sm dropdown-toggle underline-icon" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">${key}</span>
                   
                  </button>
                </div>` :
            str = ""
        });
      }
      eDiv.innerHTML = str;
      return eDiv;
    }
    return "";
  };

  const showTilesOfZoningList = (params) => {
    let keyData = [];
    if (params.data.Zoning_District) {
      keyData = [...params.data.Zoning_District];
      if (keyData.length == 0) {
        return "N/A";
      }
      const eDiv = document.createElement("div");
      let str = "";
      if (keyData.length > 0) {
        keyData.map((key, i) => {
          key != "null" ?
            str += `<div class="btn-group deal-status-dropdown-custom deal-status-dropdown-custom-name" style="margin-left:3px">
                  <button type="button" style="background-color:#5b6c97"  class="btn btn-sm dropdown-toggle underline-icon" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">${key
            }</span>
                  
                  </button>
                </div>`:
            str = ""
        });
      }
      eDiv.innerHTML = str;
      return eDiv;
    }
    return "N/A";
  };
  console.log("allClients", allClients);
  
  const navigateToClient = (params) => {

    let keyData = params.data.Account_Name;
    let clients = [...allClients];
    clients = clients.filter(
      (one) => one.Account_Name == params.data.Account_Name
    );
    let color = "#5b6c97";
    if (clients.length > 0) {
      color = clients[0].Color;
    }
    // let color = clients[0].
    if (keyData) {
      if (typeof keyData === 'object') {
        keyData = keyData.name;
      }
    }
    if (!keyData) {
      return "";
    } else {
      const eDiv = document.createElement("div");

      eDiv.innerHTML = `<div class="btn-group deal-status-dropdown-custom deal-status-dropdown-custom-client">
            <button type="button" style="background-color:${color ? color : "#5b6c97"
        }" class="btn btn-sm dropdown-toggle " data-bs-toggle="dropdown" aria-expanded="false">
              <span class="visually-hidden account-name-tile" title="${keyData}" style="color:${color ? props.invertColor(color, true) : "#fff"
        }" >${keyData}</span>
            </button>
            <ul  class="dropdown-menu">
             <li class="status-deal"><a class="dropdown-item" ></a></li>
            </ul>
          </div>`;

      if (clients.length > 0) {
        clients = clients[0];
        eDiv.addEventListener("click", () => {
          history.push({
            pathname: "/c/Accounts/DetailView/" + clients.id,
            state: { data: clients, action: "Update", module: "Accounts" },
          });
        });
      }

      return eDiv;
    }
  };

  useEffect(() => {

    if (props.checkType?.type == "All_Deals") {
      setRowData(filterdData);
      setRowDataCompactView(filterdData)
    } else if (props.checkType?.type == "NYC_Deals") {
      let d = filterdData.filter(e => e.Is_NYC_Project == "Yes")
      setRowData(d)
      setRowDataCompactView(d)
    } else if (props.checkType?.type == "NON_NYC_Deals") {
      let d = filterdData.filter(e => e.Is_NYC_Project == "No")
      setRowData(d)
      setRowDataCompactView(d)
    }
  }, [props.checkType?.type])

  function updateProjectStatus(key, projectId, statusId) {
    ProjectService.UpdateProject(projectId, key, statusId)
      .then((result) => {
        getDealList();
      })
      .then((result) => {

      })
      .catch((err) => {
        console.log(err);
      });
  }

  let currentlyOpenDropdown = null;

  const renderProjectStatus = (params) => {
    let keyData = params.data.Stage;
    if (!keyData) {
      keyData = "N/A";
    }
    let projectId = params.data.Project_ID;
    let color = dealStatus.filter((one) => one.display_value == keyData);
    const eDiv = document.createElement("div");
    eDiv.innerHTML = `<div class="btn-group deal-status-dropdown-custom render-status">
          <button type="button" style="background-color:${color.length > 0 ? color[0].colour_code : "lightgray"
      }" class="btn btn-sm dropdown-toggle " data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden" style="color:${color.length > 0 ? props.invertColor(color[0].colour_code, true) : "#fff"
      }">${keyData}</span>
          </button>
          <ul id=status-${params.data.id}  class="dropdown-menu">
            ${dealStatus
        .map((one) => {
          return `<li  class="status-deal" id={${projectId}} style="background-color:${one.colour_code}"><a class="dropdown-item" style="color:${one.colour_code ? props.invertColor(one.colour_code, true) : "#fff"
            }">${one.display_value}</a></li>`;
        })
        .join("")}
          </ul>
        </div>`;

    eDiv.addEventListener("click",async (e, ind) => {
      e.stopPropagation();
      // Close the previously open dropdown, if any
      if (currentlyOpenDropdown && currentlyOpenDropdown !== params.data.id) {
        let element = document.getElementById("status-" + currentlyOpenDropdown);
        element.classList.remove("show");
      }

      // Toggle the dropdown of the clicked eDiv
      if (params.data.Stage !== e.target.innerText) {
        if ((e.target.innerText).length < 30) {
         setActiveFilterAndGetActiveFilters({}, "SET", []);
          await handleUpdate("Deals", { id: params.data.id, Stage: e.target.innerText })
            .then((result) => {
              getDealList();
              setRefreshTab(!refreshTab)
            })
            .catch((err) => {
              console.log(err);
            });
          
        }
      }
      let element = document.getElementById("status-" + params.data.id);
      element?.classList.toggle("show");

      // Update the currently open dropdown
      currentlyOpenDropdown = params.data.id;
    });

    document.body.addEventListener("click", (e) => {
      // Check if the clicked target is not within any eDiv
      if (!eDiv.contains(e.target)) {
        let element = document.getElementById("status-" + params.data.id);
        element?.classList.remove("show");
        currentlyOpenDropdown = null; // Reset currently open dropdown
      }
    });

    return eDiv;
  };

  const renderSubformPvSystemData = (params) => {
    let keyData =
      params.data.PV_Interconnection != undefined
        ? params.data.PV_Interconnection
        : [];
    keyData = keyData.sort(function (a, b) {
      return a.System_Number - b.System_Number;
    });
    if (keyData) {
      const eDiv = document.createElement("div");
      if (keyData.length > 0) {
        eDiv.innerHTML = `<a   class="underline-text">` + "Provided" + `</a>`;
        const eButton = eDiv.querySelectorAll(".underline-text")[0];
        const getTotalinterConnNo = () => {
          let array = [];
          if (keyData) {
            keyData.forEach((one, i) => {
              if (!array.includes(parseInt(one.Interconnection_Number))) {
                array.push(parseInt(one.Interconnection_Number));
              }
            });
          }
          return array;
        };
        let totalObj = {
          Number_Of_Modules: 0,
          System_Number: keyData.length,
          System_Size_KW1: 0,
          Interconnection_Number: getTotalinterConnNo().length,
          Number_of_Invertors: 0,
        };
        keyData.forEach((one) => {
          totalObj.Number_Of_Modules =
            one.Number_Of_Modules + totalObj.Number_Of_Modules;
          totalObj.Number_of_Invertors =
            one.Number_of_Invertors + totalObj.Number_of_Invertors;
          totalObj.System_Size_KW1 =
            one.System_Size_KW1 + totalObj.System_Size_KW1;
        });
        eButton.addEventListener("click", () => {
          setShowDealTableChart(true);
          setDealTableChartObj({
            total: totalObj,
            DealName: params.data.Deal_Name,
            headerLabel: "PV Interconnection",
            headers: pvSystemTableHeaders,
            data: keyData,
          });
        });
      } else {
        eDiv.innerHTML = `<a   class="underline-orange">` + "Required" + `</a>`;
        const eButton = eDiv.querySelectorAll(".underline-orange")[0];
        let totalObj = {
          Number_Of_Modules: 0,
          System_Number: keyData.length,
          System_Size_KW1: 0,
          Interconnection_Number: keyData.length,
          Number_of_Invertors: keyData.Number_of_Invertors,
        };
        keyData?.forEach((one) => {
          totalObj.Number_Of_Modules =
            one.Number_Of_Modules + totalObj.Number_Of_Modules;
          totalObj.Number_of_Invertors =
            one.Number_of_Invertors + totalObj.Number_of_Invertors;
          totalObj.System_Size_KW1 =
            one.System_Size_KW1 + totalObj.System_Size_KW1;
        });
        eButton.addEventListener("click", () => {
          setShowDealTableChart(true);
          setDealTableChartObj({
            total: totalObj,
            DealName: params.data.Deal_Name,
            headerLabel: "PV Interconnection",
            headers: pvSystemTableHeaders,
            data: keyData,
          });
        });
      }
      return eDiv;
    }
  };

  const renderSubformViolation = (params) => {
    console.log(
      "params.data.Active_Work_Without_Permit_Violations",
      params.data.Active_Work_Without_Permit_Violations
    );
    let delaFolderId = params.data.zohoworkdriveforcrm__Workdrive_Folder_ID;

    if (params.data.Active_Work_Without_Permit_Violations == "Yes") {
      let keyData = params.data.WWP_Violations;
      if (keyData) {
        const eDiv = document.createElement("div");
        if (keyData.length > 0) {
          eDiv.innerHTML = `<a   class="underline-text">` + "Provided" + `</a>`;
          const eButton = eDiv.querySelectorAll(".underline-text")[0];
          eButton.addEventListener("click", () => {
            setShowDealTableChart(true);
            setDealTableChartObj({
              DealName: params.data.Deal_Name,
              headerLabel: "WWP Violation Information",
              headers: wwwViolationHeaders,
              data: keyData,
              folderId: delaFolderId,
            });
          });
        } else {
          eDiv.innerHTML = `<a   class="underline-orange">` + "Required" + `</a>`;
          const eButton = eDiv.querySelectorAll(".underline-orange")[0];
          eButton.addEventListener("click", () => {
            setShowDealTableChart(true);
            setDealTableChartObj({
              DealName: params.data.Deal_Name,
              headerLabel: "WWP Violation Information",
              headers: wwwViolationHeaders,
              data: keyData,
              folderId: delaFolderId,
            });
          });
        }
        return eDiv;
      }
    } else {
      return params.data.Active_Work_Without_Permit_Violations;
    }
  };

  const renderSubformPreviousSolarApp = (params) => {

    if (params.data.Previous_Solar_Application == "Yes") {
      let keyData = params.data.Previous_Solar_Info;
      if (keyData) {
        const eDiv = document.createElement("div");
        if (keyData.length > 0) {
          eDiv.innerHTML = `<a   class="underline-text">` + "Provided" + `</a>`;
          const eButton = eDiv.querySelectorAll(".underline-text")[0];
          eButton.addEventListener("click", () => {
            setShowDealTableChart(true);
            setDealTableChartObj({
              DealName: params.data.Deal_Name,
              headerLabel: "Previous Solar Application Information",
              headers: previousSolarAppHeaders,
              data: keyData,
            });
          });
        } else {
          eDiv.innerHTML = `<a   class="underline-orange">` + "Required" + `</a>`;
          const eButton = eDiv.querySelectorAll(".underline-orange")[0];
          eButton.addEventListener("click", () => {
            setShowDealTableChart(true);
            setDealTableChartObj({
              DealName: params.data.Deal_Name,
              headerLabel: "Previous Solar Application Information",
              headers: previousSolarAppHeaders,
              data: keyData,
            });
          });
        }
        return eDiv;
      }
    } else {
      return params.data.Previous_Solar_Application;
    }
  };

  const getAntdModalForTableCharts = () => {
    return (
      <AntdModal
        open={showDealTableCharts}
        title={
          dealTableChartObjs.DealName +
          "           >           " +
          "Services Requested"
        }
        width={900}
        style={{ top: 170, borderRadius: 50, marginLeft: 250 }}
        onCancel={() => setShowDealTableCharts(false)}
        footer={null}
      >
        <div className="deal-table-chart">
          <ul>
            {dealTableChartObjs.data
              ? dealTableChartObjs.data.map((d) => {
                return <li>{d}</li>;
              })
              : ""}
          </ul>
        </div>
      </AntdModal>
    );
  };

  const renderServiceRequest = (params) => {
    let keyData = params.data.Service_Requested_Name;
    let arr = keyData?.split(",");
    if (keyData) {
      const eDiv = document.createElement("div");
      if (keyData.length > 0) {
        eDiv.innerHTML = `<a   class="underline-text">` + arr.length + `</a>`;
        const eButton = eDiv.querySelectorAll(".underline-text")[0];
        eButton.addEventListener("click", () => {
          setShowDealTableCharts(true);
          setDealTableChartObjs({ DealName: params.data.Deal_Name, data: arr });
        });
      } else {
        return arr.length;
      }
      return eDiv;
    }
  };

  function ownerMailAddress(params) {
    let d = params.data.Owner_s_Mailing_Address_Diff_Than_Property_Address;
    if (d == true) {
      return "Yes";
    } else if (d == "N/A") {
      return "N/A";
    } else {
      return "No";
    }
  }
  function occCertificateShow(params) {
    if (params.data.Asbestos_Required == "Yes") {
      return "N/A";
    } else {
      if (params.data.Certificate_of_Occupancy_Available == "Yes") {
        let customDiv = document.createElement('div');
        customDiv.className = "Eye-Icon-Custome"
        customDiv.innerHTML = `<span>${params.data.Certificate_of_Occupancy_Available}</span>
          <svg class="svg-for-prev Occupency" id=${params.data.zohoworkdriveforcrm__Workdrive_Folder_ID} xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
          `
        let svgComp = customDiv.querySelectorAll(".svg-for-prev")[0];
        svgComp.addEventListener('click', async (e) => {

          setQuerySuccess(true)
          try {
            let file = await getFileByFileType("_CERTIFICATE OF OCCUPANCY", e.currentTarget.id)
            if (file) {
              await getchFiletDataUrl(file.fileId, file.fileName)
            } else {
              setQuerySuccess(false);
              alert("File not found!")
            }
          } catch (error) {
            setQuerySuccess(false)
          }
        })
        return customDiv
      } else {
        return params.data.Certificate_of_Occupancy_Available
      }
    }
  }
  function nbApplicationShow(params) {
    if (
      params.data.Asbestos_Required == "No" &&
      params.data.Certificate_of_Occupancy_Available == "No"
    ) {
      return params.data.NB_Application_Number;
    } else {
      return "N/A";
    }
  }

  const getDateFormat = (params) => {
    let date = params.data.Closing_Date;
    date = new Date(date);
    date = dayjs(new Date(date)).format("MM-DD-YYYY");
    return date;
  };

  const projectStatusFilterOptions = {

    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Stage) {
              return params.data.Stage;
            }
          },
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Stage) {
              return params.data.Stage;
            }
          },
        },
      },
    ]
  };

  const multiDwellClassificationParams = {

    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {

            if (params.data.Multiple_Dwelling_Classification == "-- Select --") {
              return ''
            } else {
              return params.data.Multiple_Dwelling_Classification
            }
          },
        },
      },
    ]
  };

  const serviceRequestedFilterOptions = {
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            let datas;
            if (typeof params.data.Service_Requested_Name == "string") {
              datas = params.data.Service_Requested_Name?.split(',');
            } else if (params.data.Service_Requested_Name == null) {
              datas = []
            }
            return datas;
          },
        },
      },
    ]
  }


  const excelFilterParams = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          excelMode: "windows",
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        },
      },
    ]
  };
  const excelFilterParams1 = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Modified_By) {
              return params.data.Modified_By.name;
            }
          }
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Modified_By) {
              return params.data.Modified_By.name;
            }
          },
        },
      },
    ]
  };
  const excelFilterParams3 = {
    // can be 'windows' or 'mac'
    filters: [
      // {
      //   excelMode: "windows",
      //   filterParams: {
      //     newRowsAction: 'keep',
      //     buttons: ['reset', 'apply'],
      //     closeOnApply: true,
      //   }
      // },
      {
        title: "Account_Name",
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          excelMode: "windows",
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Account_Name) {
              return params.data.Account_Name;
            }
          },
        },
      },
    ]
  };

  const excelFilterParams2 = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        },
      },
    ]
  };

  const NumberfilterParams = {
    filters: [
      {
        filter: "agNumberColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        },
      },
    ],
  };

  const electricainParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Electrician) {
              return params.data.Electrician.name;
            }
          },
        },
      },
    ],
  };
  const contractorParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.General_Contractor) {
              return params.data.General_Contractor.name;
            }
          },
        },
      },
    ],
  };

  const occCertParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Asbestos_Required == "Yes") {
              return "N/A";
            } else {
              return params.data.Certificate_of_Occupancy_Available;
            }
          },
        },
      },
    ],
  };
  const conjuctionParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Conjunction == "Yes") {
              return params.data.Conjunction_Application_Number;
            } else if (params.data.Conjunction == "N/A") {
              return "N/A";
            } else {
              return "No";
            }
          },
        },
      },
    ],
  };

  const zoningParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            let keyData = [...params.data.Zoning_District];
            let str = "";
            if (keyData.length == 0) {
              return "N/A";
            } else {
              let arr = [];
              keyData.map((key, i) => {
                (key !== "null" && key !== "N/A") ? arr.push(key) : arr.push("")
              })
              return arr
            }
          },
        },
      },
    ],
  };
  const spclParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            let keyData = [...params.data.Specials_Districts];
            let str = "";
            if (keyData.length == 0) {
              return "N/A";
            } else {
              let arr = [];
              keyData.map((key, i) => {
                (key !== "null" && key !== "N/A") ? arr.push(key) : arr.push("")
              })
              return arr
            }
          },
        },
      },
    ],
  };

  const commercialParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            let keyData = [...params.data.Commercial_Overlay];
            let str = "";
            if (keyData.length == 0) {
              return "N/A";
            } else {
              let arr = [];
              keyData.map((key, i) => {
                (key !== "null" && key !== "N/A") ? arr.push(key) : arr.push("")
              })
              return arr
            }
          },
        },
      },
    ],
  };

  const nbParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (
              params.data.Asbestos_Required == "No" &&
              params.data.Certificate_of_Occupancy_Available == "No"
            ) {
              return params.data.NB_Application_Number;
            } else {
              return "N/A";
            }
          },
        },
      },
    ],
  };

  // const previousSolarDataParams=(e)=>{
  //     
  //   if(e.filterAttribute[0]=="Number"){
  //     let filter={...NumberfilterParams};
  //     if(filter.filters){
  //       filter.filters[1].filterParams["valueGetter"]= (params)=>{
  //         if(params.data[e.filterAttribute[1]]){
  //           return params.data[e.filterAttribute[1]][e.field]
  //         }
  //       }
  //     }
  //     return filter;
  //   }else if(e.filterAttribute[0]=="String"){
  //     let filter={...electricainParams};
  //     if(filter.filters){
  //       filter.filters[0].filterParams["valueGetter"]= (params)=>{
  //         if(params.data[e.filterAttribute[1]]){
  //           return params.data[e.filterAttribute[1]][e.field]
  //         }
  //       }
  //     }
  //     return filter;
  //   }
  // }


  const ownerMailParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (
              params.data.Owner_s_Mailing_Address_Diff_Than_Property_Address ==
              true
            ) {
              return "Yes";
            } else if (
              params.data.Owner_s_Mailing_Address_Diff_Than_Property_Address ==
              "N/A"
            ) {
              return "N/A";
            } else {
              return "No";
            }
          },
        },
      },
    ],
  };

  const dateFilterParams = {
    filters: [
      {
        filter: "agDateColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          comparator: function (filterDate, cellValue) {
            if (cellValue == null) return -1;
            return getDate(cellValue) - filterDate;
          },
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            let d = params.data.Closing_Date.split('-');
            return d[1] + '-' + d[2] + '-' + d[0];
          },
          comparator: function (a, b) {
            return getDate(a) - getDate(b);
          },
        },
      },
    ],
  };

  const excelSubFormFilterParams = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.PV_Interconnection?.length > 0) {
              return "Provided";
            } else {
              return "Required";
            }
          },
        },
      },
    ],
  };
  const excelSubFormFilterParams1 = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Active_Work_Without_Permit_Violations == "Yes") {
              let keyData = params.data.WWP_Violations;
              if (keyData?.length > 0) {
                return "Provided";
              } else {
                return "Required";
              }
            } else {
              return params.data.Active_Work_Without_Permit_Violations;
            }
          },
        },
      },
    ],
  };
  const excelSubFormFilterParams2 = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Previous_Solar_Application == "Yes") {
              let keyData = params.data.Previous_Solar_Info;
              if (keyData?.length > 0) {
                return "Provided";
              } else {
                return "Required";
              }
            } else {
              return params.data.Previous_Solar_Application;
            }
          },
        },
      },
    ],
  };
  const excelSubFormFilterParams3 = {
    filters: [
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            return stopWorkFunction(params);
          },
        },
      },
    ],
  };
  function getDate(value) {
    let dateParts = "";
    if (value?.includes("/")) {
      dateParts = value?.split("/");
    } else if (value?.includes("-")) {
      dateParts = value?.split("-");
    }
    return new Date(
      parseInt(dateParts[0]),
      parseInt(dateParts[1]) - 1,
      parseInt(dateParts[2])
    );
  }

  const getViewColumns = (viewData) => {
    // let viewNames =  viewData.map(obj=> {
    //     return obj.fields
    // });
    if (viewData == "All") {
      let columnsData;
      if (viewType == "List") {
        columnsData = [...cacheHeaderData];
      } else {
        columnsData = [...headerDataForCompactView];
      }
      columnsData.forEach((e) => (e.isChecked = false));
      // setheaderData(columnsData);
      setActiveFiltersOnColumnLevel({})
      getFieldHeaders();
    } else {
      let columnsData = [...viewData];
      let viewApiNames = viewData.map((one) => {
        return one.id;
      });
      if (viewType == "List") {
        let filterViewData = [];
        for (let i = 0; i < columnsData.length; i++) {
          if (viewApiNames.includes(columnsData[i].id)) {
            // filterViewData.push(columnsData[i]);
            columnsData[i].isChecked = viewData[i].isChecked;
          }
          else {
            columnsData[i].isChecked = true;
          }
        }
      } else {
        let colsData = [...headerDataForCompactView]
        for (let i = 0; i < columnsData.length; i++) {
          if (viewApiNames.includes(colsData[i]?.id)) {
            // filterViewData.push(columnsData[i]);
            columnsData[i].isChecked = viewData[i].isChecked;
          }
          else {
            columnsData[i].isChecked = true;
          }
        }
      }
      setActiveHeaderData(updatedHeaderData(columnsData));
    }
    setColumnsEdit(false);
  };

  const staticCellStyle = { "text-align": "center" };

  const staticCellStyleDropdown = {
    "text-align": "center",
    overflow: "visible",
  };
  const fadeColour = { color: "#d1d1d1", "font-style": "oblique" };

  const getLeftFilterHeader = () => {
    let headers = [...headerData];
    if (headers.length > 0) {
      let data = [
        ...pvSystemTableHeaders,
        ...wwwViolationHeaders,
        ...previousSolarAppHeaders,
      ];
      let arr = [];
      data.forEach((one) => {
        let obj = {};
        obj.name = one.label;
        obj.type = one.type ? one.type : "text";
        arr.push(obj);
      });
      headers = [...headers, ...arr];
      return headers;
    } else {
      return headers;
    }
  };

  const isGroupPresent = (arr, hideArr) => {

    let isAllPresent = true;
    arr?.some(e => {
      if (hideArr.includes(e.field)) {
        isAllPresent = false;
        return true;
      }
    })
    return isAllPresent;
  }
  const sectionsState = [
    {
      index: 1,
      fieldName: "Preliminary",
      field: "Basic_Information",
      pinnedColumn: "Account_Name",
      headerColor: {
        open: "rgb(1 142 224)",
        closed: "#018EE0",
      },
      expanded: false,
      subSections: [
        {
          fieldName: "Basic Information",
          field: "Basic_Information",
          pinnedColumn: "Account_Name",
        },
        {
          fieldName: "Property Location Info",
          field: "Property_Location_Info",
          pinnedColumn: "Project_Address",
        },
        {
          fieldName: "Services Requested",
          field: "Services_Requested",
          pinnedColumn: "Service_Requested_Name",
        },
        {
          fieldName: "Hide",
          field: "Hide",
          pinnedColumn: "",
          parent: 0
        }
      ]
    },
    {
      index: 2,
      fieldName: "NYC Information",
      field: "NYC_Information",
      pinnedColumn: "Block_Number",
      headerColor: {
        open: "rgb(1 142 224)",
        closed: "rgb(242 127 53)",
      },
      expanded: false,
      subSections: [
        {
          fieldName: "NYC Property Info",
          field: "NYC_Property_Info",
          pinnedColumn: "Block_Number",
        },
        {
          fieldName: "Previous Solar Application Info",
          field: "Previous_Solar_Application_Info",
          pinnedColumn: "Previous_Solar_Info",
        },
        {
          fieldName: "Additional Nyc Filling Info",
          field: "Additional_Nyc_Filling_Info",
          pinnedColumn: "Conjunction",
        },
        {
          fieldName: "WWP Violation Info",
          field: "WWP_Violation_Info",
          pinnedColumn: "Active_Work_Without_Permit_Violations",
        },
        {
          fieldName: "Asbestos_Investigation",
          field: "Asbestos_Investigation",
          pinnedColumn: "Asbestos_Required",
        },
        {
          fieldName: "Links",
          field: "Links",
          pinnedColumn: "zola_link",
        },
        {
          fieldName: "Hide",
          field: "Hide",
          pinnedColumn: "",
          parent: 1
        }
      ]
    },
    {
      index: 3,
      fieldName: "Home Owner",
      field: "Home_Owner",
      expanded: false,
      pinnedColumn: "Property_Owner_s_Name",
      headerColor: {
        open: "rgb(1 142 224)",
        closed: "rgb(56 208 172)",
      },
      subSections: [
        {
          fieldName: "Property Owner Info",
          field: "Property_Owner_Info",
          pinnedColumn: "Owner_Type",
        },
        {
          fieldName: "Home Owner Info",
          field: "Home_Owner_Info",
          pinnedColumn: "Owner_s_First_Name",
        },
        {
          fieldName: "Homeowner's Mailing Address Info",
          field: "Homeowner_s_Mailing_Address_Info",
          pinnedColumn: "Owner_s_Mailing_Address",
        },
        {
          fieldName: "Other Owner Information",
          field: "Other_Owner_Information",
          pinnedColumn: "Non_Profit",
        },
        {
          fieldName: "Condo Second Officer",
          field: "Condo_Second_Officer",
          pinnedColumn: "Condo_Company_Name",
        },
        {
          fieldName: "Hide",
          field: "Hide",
          pinnedColumn: "",
          parent: 2
        }
      ]
    },
    {
      index: 4,
      fieldName: "Permitting",
      field: "Permitting",
      expanded: false,
      pinnedColumn: "Applicant",
      headerColor: {
        open: "rgb(1 142 224)",
        closed: "rgb(1 142 224)",
      },
      subSections: [
        {
          fieldName: "Permitting Info",
          field: "Permitting_Info",
          pinnedColumn: "Applicant",
        },
        {
          fieldName: "Hide",
          field: "Hide",
          pinnedColumn: "",
          parent: 3
        }
      ]
    },
    {
      index: 5,
      fieldName: "Pv System",
      field: "Pv_System",
      expanded: false,
      pinnedColumn: "Number_of_Interconnection",
      headerColor: {
        open: "rgb(1 142 224)",
        closed: "rgb(250 205 24)",
      },
      subSections: [
        {
          fieldName: "Pv System Info",
          field: "Pv_System_Info",
          pinnedColumn: "Number_of_Interconnection",
        },
        {
          fieldName: "PV Interconection",
          field: "PV_Interconection",
          pinnedColumn: "PV_Interconnection",
        },
        {
          fieldName: "Project Cost",
          field: "Project_Cost",
          pinnedColumn: "Project_Cost",
        },
        {
          fieldName: "Hide",
          field: "Hide",
          pinnedColumn: "",
          parent: 4
        }
      ]
    },
    {
      index: 6,
      fieldName: "Actions Details",
      field: "Actions_Details",
      expanded: false,
      pinnedColumn: "Created_By",
      headerColor: {
        open: "rgb(1 142 224)",
        closed: "rgb(250 205 24)",
      },
      subSections: [
        {
          fieldName: "Created Details",
          field: "Created_Details",
          pinnedColumn: "Created_By",
        },
        {
          fieldName: "Modified Details",
          field: "Modified_Details",
          pinnedColumn: "Modified_By",
        },
        {
          fieldName: "Hide",
          field: "Hide",
          pinnedColumn: "",
          parent: 5
        }
      ]
    }
  ]
  const selectTableRows = (api) => {
    if (api) {
      api.forEachNode((node) => {
        if (rowsSelected.includes(node.data.Deal_Name)) {
          node.setSelected(true)
        } else {
          node.setSelected(false);
        }
      })
    }
  }

  useEffect(() => {
    if (viewType !== "List" && compactViewGridApis) {
      Object.keys(compactViewGridApis).map(async (one) => {
        if (compactViewGridApis["Deal_Name"].getSelectedRows().length == compactViewGridApis["Deal_Name"].getDisplayedRowCount()) {
          if (compactViewGridApis[one].getSelectedRows()?.length !== rowsSelected?.length) {
            await compactViewGridApis[one].selectAll();
          }
        } else if (compactViewGridApis["Deal_Name"].getSelectedRows().length == 0) {
          if (compactViewGridApis[one].getSelectedRows()?.length !== rowsSelected?.length) {
            await compactViewGridApis[one].deselectAll();
          }
        } else {
          selectTableRows(compactViewGridApis[one])
        }
      })
    }

  }, [rowsSelected])

  const onExpandOrCollapse = (params) => {
    let oldStatus = { ...groupStatus };
    let currentHeaderName = params.columnGroup.colGroupDef.headerName;
    let key = undefined;
    sectionsState.forEach(s => {
      s.subSections.forEach(sub => {
        if (sub.fieldName == currentHeaderName) {
          key = sub.field;
        }
      })
    })
    if (key) {
      oldStatus[key] = params.columnGroup.expanded;
      oldStatus["Permitting_Info"] = true;
    }
    setGroupStatus(oldStatus);
  }
  useEffect(e => {

    if (viewType != "List") {
      let oldRowData = rowDataCompactView;
      let filterdData = rowDataCompactView.filter(d => {
        let flag = true;
        Object.keys(filtersRowsForCompactView).forEach(key => {

          if (!filtersRowsForCompactView[key].includes(d.id)) {
            flag = false;
          }
        })
        return flag;
      })
      setRowData(filterdData);
    }
  }, [filtersRowsForCompactView])
  useEffect(e => {
    if (viewType != "List" && compactViewGridApis) {
      Object.keys(compactViewGridApis).forEach(key => {
        if (JSON.stringify(activeFiltersOnColumnLevel) != JSON.stringify(compactViewGridApis[key].getFilterModel())) {
          compactViewGridApis[key].setFilterModel(activeFiltersOnColumnLevel);
        }
      })
      setCompactViewFilterTrigger(!CompactViewFilterTrigger)
    } else {
      if (gridApi) {
        let model = gridApi?.getFilterModel();
        if (JSON.stringify(activeFiltersOnColumnLevel) != JSON.stringify(model)) {
          gridApi?.setFilterModel(activeFiltersOnColumnLevel != null ? activeFiltersOnColumnLevel : {});
        }
      }
    }

  }, [activeFiltersOnColumnLevel])

  const updateCompactViewFileters = async ({ params, field }) => {

    let model = await params.api.getFilterModel();
    setActiveFiltersOnColumnLevel(model);
  }

  const cellRenderFunction = (params, e) => {

    if (params.data.Row_Span_Count && viewType != 'List') {
      return RenderTheNestedCollapsData(params, e);
    } else {
      return (
        e.collapsGroup ? RenderTheNestedCollapsData(params, e)
          :
          e.type == 'link'
            ? renderLinks(params, e)
            : e.field == "Deal_Name"
              ? navigateToProject(params)
              : e.field == "Project_Status"
                ? renderProjectStatus(params)
                : e.field == "Account_Name"
                  ? navigateToClient(params)
                  : e.field == "PV_Interconnection"
                    ? renderSubformPvSystemData(params)
                    : e.field ==
                      "Active_Work_Without_Permit_Violations"
                      ? renderSubformViolation(params)
                      : e.field == "Previous_Solar_Info"
                        ? renderSubformPreviousSolarApp(params)
                        : e.field == "Closing_Date"
                          ? getDateFormat(params)
                          : e.field == "Zoning_District"
                            ? showTilesOfZoningList(params)
                            : e.field == "Project_Cost"
                              ? formatToUSCurrency(params)
                              : e.field == "Specials_Districts"
                                ? showTilesOfSpecialDist(params)
                                : e.field == "General_Contractor"
                                  ? contractorName(params)
                                  : e.field == "Electrician"
                                    ? electricianName(params)
                                    : e.field ==
                                      "Stop_Work_Order_Exists_on_Property"
                                      ? stopWorkFunction(params)
                                      : e.field == "Total_System_Size"
                                        ? tableTotalSysSize(params)
                                        : e.field == "Created_Time"
                                          ? convertTimeZone(params)
                                          : e.field == "Modified_By"
                                            ? modifyByName(params)
                                            : e.field == "Modified_Time"
                                              ? modifyTime(params)
                                              : e.field == "Conjunction"
                                                ? conjunctionShow(params)
                                                : e.field == "Service_Requested_Name"
                                                  ? renderServiceRequest(params)
                                                  : e.field ==
                                                    "Owner_s_Mailing_Address_Diff_Than_Property_Address"
                                                    ? ownerMailAddress(params)
                                                    : e.field ==
                                                      "Certificate_of_Occupancy_Available"
                                                      ? occCertificateShow(params)
                                                      : e.field ==
                                                        "ACP5_Completed"
                                                        ? acp5DataRender(params)
                                                        : e.field ==
                                                          "DHCR"
                                                          ? DHCRDataRender(params)
                                                          : e.field ==
                                                            "Non_Profit"
                                                            ? DHCRDataRender(params)
                                                            : e.field == "NB_Application_Number"
                                                              ? nbApplicationShow(params)
                                                              : e.field == "Commercial_Overlay"
                                                                ? showTilesOfCommercialList(params)
                                                                : params.data[e.field])
    }
  }

  const isColumnVisible = (e, temp, section) => {
    return (props.checkType?.type == "NON_NYC_Deals" || props.checkType?.type == "NYC_Deals") ?
      false : hideArray.includes(e.field)
        ? true
        : e.isChecked
          ? true
          : false
  }

  useEffect(e => {

    if (viewType != "List") {
      if (compactViewGridApis) {
        Object.keys(compactViewGridApis).forEach(key => {
          compactViewGridApis[key].setSortModel(currentSortstate);
        })
      }
      setCompactViewFilterTrigger(!CompactViewFilterTrigger)
    }
  }, [compactViewSortState])
  const updateSortingState = (sort) => {

    setcurrentSortState([{ ...sort }]);
    let newState = [...compactViewSortState];
    if (newState.length > 0) {
      let isPresent = newState.find(s => s.colId == sort.colId);
      if (isPresent) {
        newState = newState.map(s => {
          if (s.colId == sort.colId) {
            s.sort = sort.sort;
            return s;
          } else {
            return s;
          }
        })
      } else {
        newState.push(sort)
      }
    } else {
      newState.push(sort)
    }
    setCompactViewsortState(newState);
  }

  const getColumn = (section, pinnedCol, e, temp, headerIdx, shoulHide, isDealName) => {
    return (
      <AgGridColumn
        columnGroupShow={isGroupPresent(Coldefs[section], hideArray) ? (e.field == pinnedCol ? "null" : "open") : "open"}
        pinned={headerIdx == 0 ? "left" : ""}
        hide={shoulHide ? true : isDealName && e.field != "Deal_Name" ? true : isColumnVisible(e, temp, section)}
        headerName={e.name}
        sortable={true}
        filter={"agMultiColumnFilter"}
        headerComponentFramework={(params) => {
          return CustomHeaderComponent({ ...params, filterStateToggel: CompactViewFilterTrigger, updateSortingState: updateSortingState, compactViewSortState: compactViewSortState })
        }}
        filterParams={
          (e.type === "text" &&
            e.field != "NB_Application_Number" &&
            e.field != "Previous_Solar_Info" && e.field !== "Multiple_Dwelling_Classification" && e.field !== "Project_Status") ||
            e.field == 'Owner' ||
            (e.type === "picklist" &&
              e.field !=
              "Certificate_of_Occupancy_Available" &&
              e.field != "Conjunction" &&
              e.field !=
              "Active_Work_Without_Permit_Violations" &&
              e.field !=
              "Stop_Work_Order_Exists_on_Property") ||
            e.type === "email" ||
            (e.type === "subform" && e.field != "PV_Interconnection")
            ? excelFilterParams :
            e.filterAttribute && e.filterAttribute[0] == "Number" ? FilterParams[e.field] :
              e.filterAttribute && e.filterAttribute[0] == "String" ? FilterParams[e.field] :
                e.filterAttribute && e.filterAttribute[0] == "Date" ? FilterParams[e.field]
                  : e.type === "number" ||
                    e.type === "integer" ||
                    e.type === "bigint" ||
                    e.type === "formula" ||
                    e.type === "currency"
                    ? NumberfilterParams
                    : e.field == "Electrician"
                      ? electricainParams
                      : e.field == "General_Contractor"
                        ? contractorParams
                        : e.field ==
                          "Owner_s_Mailing_Address_Diff_Than_Property_Address"
                          ? ownerMailParams
                          : e.field == "NB_Application_Number"
                            ? nbParams
                            : e.field ==
                              "Certificate_of_Occupancy_Available"
                              ? occCertParams
                              : e.field == "Conjunction"
                                ? conjuctionParams
                                : e.field === "PV_Interconnection"
                                  ? excelSubFormFilterParams
                                  : e.field ===
                                    "Active_Work_Without_Permit_Violations"
                                    ? excelSubFormFilterParams1
                                    : e.field === "Previous_Solar_Info"
                                      ? excelSubFormFilterParams2
                                      : e.field ===
                                        "Stop_Work_Order_Exists_on_Property"
                                        ? excelSubFormFilterParams3
                                        :
                                        e.field == "Zoning_District"
                                          ? zoningParams :
                                          e.field == "Specials_Districts"
                                            ? spclParams :
                                            e.field == "Commercial_Overlay"
                                              ? commercialParams :
                                              e.type == "link"
                                                ? excelFilterParams :
                                                e.field ===
                                                  "Modified_By"
                                                  ? excelFilterParams1 :
                                                  e.field ===
                                                    "Account_Name"
                                                    ? excelFilterParams3
                                                    : e.field ===
                                                      "Created_By"
                                                      ? excelFilterParams2 :
                                                      (e.type === "text" && e.field == "Project_Status") ?
                                                        projectStatusFilterOptions :
                                                        e.type === "textarea"
                                                          ? serviceRequestedFilterOptions :
                                                          (e.type === "text" && e.field == "Multiple_Dwelling_Classification") ?
                                                            multiDwellClassificationParams :
                                                            dateFilterParams
        }
        // cellEditor={e.field == "Closing_Date" ? 'customEditor' : ''}
        field={e.field}
        minWidth={200}
        suppressNavigable={
          e.name === "Project Number" ? true : false
        }
        cellStyle={
          e.field === "Project_Status"
            ? staticCellStyleDropdown
            : // e.field === "Conjunction" ? getTextColor :
            staticCellStyle
        }
        headerTooltip={e.name}
        cellRenderer={(params) => {

          if (e.field == "bis_link") {

          }
          return cellRenderFunction(params, e);
        }}
      />
    )
  }
  const getColumnsBySection = (section, pinnedCol, sub) => {

    let avoidColumn = [];
    if (sub) {
      sectionsState[sub.parent]?.subSections?.forEach(s => {
        avoidColumn = [...avoidColumn, ...Coldefs[s.field]];
      })
    }
    return activeHeaderData?.map((e, headerIdx) => {
      // console.log("e.field", e.field, e.type)
      let temp = Coldefs[section]?.find(t => t.field == e.field)
      if ((temp || section == "All") && section != "Hide" && section != "Pined_Column") {
        return getColumn(section, pinnedCol, e, temp, headerIdx, false);
      } else if (section == "Hide") {
        if (sub && avoidColumn) {
          let parentCollaps = sectionsState[sub.parent];
          let hideCol = avoidColumn.find(t => t.field == e.field)
          if (!hideCol) {
            return getColumn(section, pinnedCol, e, hideCol, headerIdx, true);
          }
        }
      } else if (section == "Pined_Column") {
        return getColumn(section, pinnedCol, e, temp, headerIdx, false, true);
      }
    })
  }

  const getTaskFilter = () => {
    return (
      <Task_Filters
        filtersmode={filtersmode}
        setActiveFilterAndGetActiveFilters={setActiveFilterAndGetActiveFilters}
        activeFiltersOnColumnLevel={activeFiltersOnColumnLevel}
        setActiveFiltersOnColumnLevel={setActiveFiltersOnColumnLevel}
        setfiltersmode={setfiltersmode}
        invertColor={props.invertColor}
        location={location}
        setIsColFilDeleted={setIsColFilDeleted}
        AllDealsClicked={AllDealsClicked}
        setAllDealsClicked={setAllDealsClicked}
        setPreAppliedFiltersForView={setPreAppliedFiltersForView}
        preAppliedFiltersForView={preAppliedFiltersForView}
        setappliedFiltersForView={setappliedFiltersForView}
        appliedFiltersForView={appliedFiltersForView}
        taskData={[]}
        dealData={cacheData}
        mode="Deal"
        refresh={refresh}
        getReturnData={(obj) => {
          // get(GroupByFields(obj.deals, groupBy.Deal)
          if (props?.checkType?.type == '' || props?.checkType?.type == 'All_Deals') {
            setRowData(obj.deals);
            setRefresh(false);
          }
        }}
        dealColumns={filterItemsDeal}
        taskColumns={[]}
        allClient={allClients}
        dealStatus={dealStatus}
        Deals={cacheData}
        allServices={allServices}
        categorys={[]}
        pipelines={[]}
        myTasks={[]}
        users={[]}
        status={[]}
      />
    );
  };

  const getModalForEditColumns = () => {
    return <Modal
      className="Edit-Column-Modal"
      show={props.dealListStatus?.EditColumns ? true : false}
      backdrop={true}
      toggle={() => {

      }}
      onHide={(e) => {

        props.dealListTriggers({ type: "EditColumns", status: false })
      }}
    >
      <ModalHeader>
        Manage Columns
      </ModalHeader>
      <ModalBody>
        <FormGroup className="pos-relative editcol-searchpad">
          <Input
            type="text"
            placeholder="Search"
            className="mr-sm-2 pad-fix search-filter"
            value={searchValue}
            onChange={(e) => onSearchColumns(e.target.value)}
          />

          <FontAwesomeIcon
            className="mr-sm-2 search-ico"
            icon={faSearch}
          />

          <div className="select-all-editColumn">
            <input
              type="checkbox"
              // checked={editColumnSelectAllFlag}
              onChange={(e) => {

                const headers = [...activeHeaderData];
                headers.forEach((obj) => {
                  if (obj.field !== "Deal_Name") {
                    obj.isChecked = !e.currentTarget.checked;
                  }
                });
                setActiveHeaderData(headers);
                setColumnsEdit(true);
              }}
            ></input>
            {"  "}
            <span className={"editColumnlist-label "}
              style={{ fontFamily: "Avenir Next LT W02", fontSize: "13px", marginLeft: "-3px" }}
            >
              Select All
            </span>
          </div>
          <hr className="hr-line" />
        </FormGroup>
        <ul className="editColummn-list">
          {activeHeaderData?.map((e, index) =>
            e.name &&
              e.isSearch &&
              !hideArray.includes(e.field) ? (
              <ListGroupItem
                style={{ marginLeft: 8 }}
                className="editColumnlist-text"
                draggable
                onDrag={(ev) => onDragColumns(ev, e, activeHeaderData)}
                onDragOver={(ev) =>
                  onDragOverColumns(ev, e, activeHeaderData)
                }
                onDrop={(ev) => onDropColumns(ev, e, activeHeaderData)}
              >
                <FontAwesomeIcon
                  icon={faGripVertical}
                  className="icon-grip-vertical"
                />
                <input
                  disabled={e.field == "Deal_Name"}
                  id={e.id}
                  type="checkbox"
                  checked={!e.isChecked}
                  onChange={(e) => {
                    handleColumns(e, index);
                  }}
                ></input>
                {"  "}
                <span
                  className={
                    e.isDragOver
                      ? "editColumnlist-label add-line"
                      : "editColumnlist-label"
                  }
                >
                  {e.name}
                </span>
                {e.isDragOver ? <hr className="hr-line" /> : null}
              </ListGroupItem>
            ) : null
          )}
        </ul>
      </ModalBody>
    </Modal>
  }
  const [showPriview, setShowPriview] = useState(false);
  const [filePrvData, setFilePrvData] = useState({
    "DataUrl": "",
    "FileName": "",
  });
  const closePreview = () => {
    setShowPriview(!showPriview);
    //setShowDealTableChart(true)
  }
  const getchFiletDataUrl = async (fileId, name) => {

    setQuerySuccess(true);
    try {
      let data = await props.fetchAuthenticatedUrl({ info: "", type: "", fileId: fileId });
      setFilePrvData({
        "DataUrl": data?.DataUrl,
        "FileName": name,
      })
    } catch (error) {
      setQuerySuccess(false);
      console.log(error)
    }
    //setShowDealTableChart(false)
    setShowPriview(true);
    setQuerySuccess(false);
  }
  useEffect(e => {

  }, [showPriview])
  const onChangeColumnFilters = (params) => {
    if(!props.rendering && (activeFiltersOnColumnLevel!=undefined || activeFiltersOnColumnLevel!=null)){
      props.setRendering(true);
      return
    }
    if (rowData && !isColFilDeleted) {
      let data = params?.api?.getFilterModel();
      if (JSON.stringify(data) !== JSON.stringify(activeFiltersOnColumnLevel)) {
        setActiveFiltersOnColumnLevel(data);
      }
      // if(isInitialLoad){
      //   setInitialLoad(false);
      // }
    } else {
      setIsColFilDeleted(false)
    }
  }
  const getPDFPrevModal = () => {
    return (
      <AntdModal
        open={showPriview}
        title={
          filePrvData?.FileName
        }
        width={900}
        style={{ top: 170, borderRadius: 50 }}
        onCancel={closePreview}
        footer={null}
      >
        {/* <div className="modal-header-class">{filePrvData?.FileName}
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={(e)=>{
                closePreview()
              }}
            ></FontAwesomeIcon>
          </div>
        </div> */}

        <PdfViewer fileData={filePrvData?.DataUrl}></PdfViewer>

      </AntdModal>
    )
  }
  return (
    <div className="deal-list-wrap">
      {getAntdModalForTableChart()}
      {getPDFPrevModal()}
      {getPromtt()}
      {getAntdModalForTableCharts()}
      {getModalForEditColumns()}
      {querySuccess ? <Loader /> : <></>}

      <div className="pad-col">
        <Row className="row-mar-0">
          {/* <div className="col-sm-2 pr-0 pad-r0">
                        {filterState == true ? <Filter setShowFilter={setShowFilter} clearFilterFlag={clearFilterFlag} applyFilters={applyFilters} headerData={getLeftFilterHeader()} /> : <></>}
                    </div> */}
          <div className={"col-sm-12 deal-module"}>
            <Col
              className={
                filterState == true
                  ? "col-8-border outer pos-relative"
                  : "col-8-border active-deal col-sm-12 outer pos-relative"
              }
            >
              {/* {filterState ? firstActive ? (
                                <img
                                    className="filter-expand-lefticon"
                                    onClick={changeFilterState}
                                    src={leftexpandgray}
                                />
                            ) : (
                                <img
                                    className="filter-expand-lefticon"
                                    onClick={changeFilterState}
                                    src={leftexpand}
                                />
                            ) : firstActive ? (
                                <img
                                    className="filter-expand-lefticonactive"
                                    onClick={changeFilterState}
                                    src={leftexpandactivegray}
                                />
                            ) : (
                                <img
                                    className="filter-expand-lefticonactive"
                                    onClick={changeFilterState}
                                    src={leftexpandactive}
                                />
                            )} */}

              <TabView
                setInitialLoad={setInitialLoad}
                setRefreshTab={setRefreshTab}
                refreshTab={refreshTab}
                isInitialLoad={isInitialLoad}
                setPreAppliedFiltersForView={setPreAppliedFiltersForView}
                setActiveFiltersOnColumnLevel={setActiveFiltersOnColumnLevel}
                dealListStatus={props.dealListStatus}
                dealListTriggers={props.dealListTriggers}
                viewType={viewType}
                setActiveFilterAndGetActiveFilters={setActiveFilterAndGetActiveFilters}
                activeFiltersOnColumnLevel={activeFiltersOnColumnLevel}
                preAppliedFiltersForView={preAppliedFiltersForView}
                appliedFiltersForView={appliedFiltersForView}
                setappliedFiltersForView={setappliedFiltersForView}
                checkType={props.checkType}
                setCheckType={props.setCheckType}
                params={props.params}
                location={location}
                setColumnsEdit={setColumnsEdit}
                columnsEdit={columnsEdit}
                getViewColumns={getViewColumns}
                headerData={activeHeaderData}
                module={location.state ? location.state.module : ""}
              />
              <div className="ag-theme-alpine">
                <div className="example-wrapper">
                  <div className="example-header">
                    <Row>
                      <Col
                        sm={filterState ? 5 : 4}
                        className={
                          filterState
                            ? "dealtablesearchdiv-active"
                            : "dealtablesearchdiv"
                        }
                        style={{ display: "flex" }}
                      >
                        <div className="pos-relative" style={{ display: "flex" }}>
                          <div className="input-search-wrapper">
                            <div className="search-icon-wrapper">
                              <FontAwesomeIcon
                                className="mr-sm-2 search-ico"
                                icon={faSearch}
                              />
                            </div>
                            <Input
                              type="text"
                              onChange={(e) =>
                                onSearchFilterTexts(e.target.value)
                              }
                              placeholder="Search"
                              className="mr-sm-2 pad-fix search-filter search-deal"
                              style={{ width: "17rem", borderColor: "#018EE0" }}
                            />

                          </div>
                          <div
                            style={{ minWidth: "max-content", marginTop: "0px" }}
                            className="task_filter_box"
                          // onClick={}
                          >
                            {getTaskFilter()}
                          </div>
                          {/* <div >
                        <Switch
                          // style={{ backgroundColor: "rgb(1, 148, 247)" }}
                          unCheckedChildren={"List"}
                          checkedChildren={"Compact"}
                          onChange={(checked, event) => {
                            if(checked){
                              setViewType("Compact")
                            }else{
                              setViewType("List")
                            }
                          }}
                        />
                        </div> */}
                          <div className="list-compact-view">
                            <div onClick={(e) => { setViewType("List"); setActiveView("list") }} className="list-view" style={activeView == "list" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>List View</div>
                            <div onClick={(e) => { setViewType("Compact"); setActiveView("compact") }} className="compact-view" style={activeView == "compact" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>Compact View</div>
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="deal_column_hide"
                        sm={1}
                      ></Col>
                      {/* <Col sm={filterState ? 2 : 3}></Col> */}
                      <Col>
                        <div className="float-right">
                          <select
                            className="dealtable-dropdown"
                            onChange={() => onPageSizeChanged()}
                            id="page-size"
                          >
                            <option value="5">5 Records Per Page</option>
                            <option value="10">10 Records Per Page</option>
                            <option value="15">15 Records Per Page</option>
                            <option value="25">25 Records Per Page</option>
                            <option value="50" selected={true}>
                              50 Records Per Page
                            </option>
                            <option value="75">75 Records Per Page</option>
                            <option value="100">100 Records Per Page</option>
                          </select>
                          <div className="dot-fix">
                            <FontAwesomeIcon
                              className={
                                dotActive
                                  ? "pad-icon-circle-active"
                                  : "pad-icon-circle"
                              }
                              icon={faCircle}
                            />
                          </div>
                          <div className="dot-fix">
                            <FontAwesomeIcon
                              className={
                                backActive ? "pad-icon-active" : "pad-icon"
                              }
                              onClick={(e) => {
                                onBtDoublePrevious()
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              icon={faAngleDoubleLeft}
                            />
                            <FontAwesomeIcon
                              className={
                                backActive ? "pad-icon-active" : "pad-icon"
                              }
                              onClick={(e) => {
                                onBtPrevious()
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              icon={faAngleLeft}
                            />
                            {/* <div className="rownum-fix"> */}
                            {from} to {to} of {totalRow}
                            {/* </div> */}
                            <FontAwesomeIcon
                              className={
                                frontActive ? "pad-icon-active" : "pad-icon"
                              }
                              onClick={(e) => {
                                onBtNext()
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              icon={faAngleRight}
                            />
                            <FontAwesomeIcon
                              className={
                                frontActive ? "pad-icon-active" : "pad-icon"
                              }
                              onClick={(e) => {
                                onBtDoubleNext()
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              icon={faAngleDoubleRight}
                            />
                          </div>
                        </div>
                        {/* <button className='clear-filter btn btn-sm btn-primary' style={{display : showFilter != "" ? "block" : "none"}} onClick={clearFilters}>Clear Filter</button> */}
                        {/* <div
                          style={{ width: "10%", float: "right" }}
                          className="task_filter_box"
                          // onClick={}
                        >
                          {getTaskFilter()}
                        </div> */}

                      </Col>
                    </Row>
                  </div>
                  <div
                    id="myGrid"
                    style={
                      filterState == true
                        ? {
                          height: "calc(100vh - 277px)",
                          width: "100%",
                          padding: 15,
                          paddingTop: 0,
                        }
                        : {
                          height: "calc(100vh - 248px)",
                          width: "100%",
                          padding: 15,
                          paddingTop: 0,
                        }
                    }
                    className="ag-theme-alpine"
                  >
                    {
                      viewType == "List" ?
                        <AgGridReactComponent
                          onExpandOrCollapse={onExpandOrCollapse}
                          AllCommunityModules={AllCommunityModules}
                          AllModules={AllModules}
                          activeHeaderData={activeHeaderData}
                          ColumnFilter={ColumnFilter}
                          onChangeColumnFilters={onChangeColumnFilters}
                          CustomDateComponent={CustomDateComponent}
                          CustomEditorComponent={CustomEditorComponent}
                          CustomHeaderComponent={CustomHeaderComponent}
                          onFirstDataRendered={onFirstDataRendered}
                          onSelectionChanged={onSelectionChanged}
                          onColumnPinned={onColumnPinned}
                          onGridReady={onGridReady}
                          getPagingValues={getPagingValues}
                          rowData={rowData}
                          staticCellStyle={staticCellStyle}
                          groupStatus={groupStatus}
                          sectionsState={sectionsState}
                          getColumnsBySection={getColumnsBySection}
                          viewType={"List"}
                          compactViewGridApis={compactViewGridApis}
                          updateCompactViewFileters={updateCompactViewFileters}
                          triggerGridApiEffect={triggerGridApiEffect}
                          settriggerGridApiEffect={settriggerGridApiEffect}
                          setGroupStatus={setGroupStatus}
                        />
                        : <AgGridReactComponent
                          onExpandOrCollapse={onExpandOrCollapse}
                          setComactViewGridApis={setComactViewGridApis}
                          compactViewGridApis={compactViewGridApis}
                          activeHeaderData={activeHeaderData}
                          onChangeColumnFilters={onChangeColumnFilters}
                          AllCommunityModules={AllCommunityModules}
                          setSouldRender={setSouldRender}
                          AllModules={AllModules}
                          ColumnFilter={ColumnFilter}
                          CustomDateComponent={CustomDateComponent}
                          CustomEditorComponent={CustomEditorComponent}
                          CustomHeaderComponent={CustomHeaderComponent}
                          onFirstDataRendered={onFirstDataRendered}
                          onSelectionChanged={onSelectionChanged}
                          onColumnPinned={onColumnPinned}
                          onGridReady={onGridReady}
                          getPagingValues={getPagingValues}
                          rowData={rowData}
                          staticCellStyle={staticCellStyle}
                          groupStatus={groupStatus}
                          sectionsState={sectionsState}
                          getColumnsBySection={getColumnsBySection}
                          viewType={"Compact"}
                          triggerGridApiEffect={triggerGridApiEffect}
                          settriggerGridApiEffect={settriggerGridApiEffect}
                          setGroupStatus={setGroupStatus}
                          updateCompactViewFileters={updateCompactViewFileters}
                        />
                      // sample compact view design
                      // <CompactView 
                      //   rowData={rowData}
                      //   ColGroups={Coldefs}
                      //   onExpandOrCollapse={onExpandOrCollapse}
                      //   AllCommunityModules={AllCommunityModules}
                      //   AllModules={AllModules}
                      //   ColumnFilter={ColumnFilter}
                      //   CustomDateComponent={CustomDateComponent}
                      //   CustomEditorComponent={CustomEditorComponent}
                      //   CustomHeaderComponent={CustomHeaderComponent}
                      //   onFirstDataRendered={onFirstDataRendered}
                      //   onRowSelected={onRowSelected}
                      //   onSelectionChanged={onSelectionChanged}
                      //   onColumnPinned={onColumnPinned}
                      //   onGridReady={onGridReady}
                      //   getPagingValues={getPagingValues}
                      //   staticCellStyle={staticCellStyle}
                      //   groupStatus={groupStatus}
                      //   getColumnsBySection={getColumnsBySection}
                      //  />
                    }


                  </div>
                </div>
              </div>
              <div
                className={
                  filterState == true
                    ? "non-tab-div-active d-flex"
                    : "non-tab-div d-flex"
                }
              >
                <div>
                  {" "}
                  {/* <Dropdown className="editColumn">
                    <Dropdown.Toggle
                      id="dropdown-editcolumn"
                      className="float-right edit-colum-button"
                    >
                      Edit Columns
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                    hidden={props.toggleActionBtn}
                    show={props.toggleActionBtn}
                    >
                      <FormGroup className="pos-relative editcol-searchpad">
                        <Input
                          type="text"
                          placeholder="Search"
                          className="mr-sm-2 pad-fix search-filter"
                          value={searchValue}
                          onChange={(e) => onSearchColumns(e.target.value)}
                        />

                        <FontAwesomeIcon
                          className="mr-sm-2 search-ico"
                          icon={faSearch}
                        />

                        <div className="select-all-editColumn">
                          <input
                            type="checkbox"
                            // checked={editColumnSelectAllFlag}
                            onChange={(e) => {
                              const headers = [...headerData];
                              headers.forEach((obj) => {
                                if (obj.field !== "Deal_Name") {
                                  obj.isChecked = !e.currentTarget.checked;
                                }
                              });
                              setheaderData(headers);
                              setColumnsEdit(true);
                            }}
                          ></input>
                          {"  "}
                          <span className={"editColumnlist-label "}>
                            Select All
                          </span>
                        </div>
                        <hr className="hr-line" />
                      </FormGroup>
                      <ul className="editColummn-list">
                        {headerData.map((e, index) =>
                          e.name &&
                          e.isSearch &&
                          !hideArray.includes(e.field) ? (
                            <ListGroupItem
                              style={{ marginLeft: 8 }}
                              className="editColumnlist-text"
                              draggable
                              onDrag={(ev) => onDragColumns(ev, e, headerData)}
                              onDragOver={(ev) =>
                                onDragOverColumns(ev, e, headerData)
                              }
                              onDrop={(ev) => onDropColumns(ev, e, headerData)}
                            >
                              <FontAwesomeIcon
                                icon={faGripVertical}
                                className="icon-grip-vertical"
                              />
                              <input
                                disabled={e.field == "Deal_Name"}
                                id={e.id}
                                type="checkbox"
                                checked={!e.isChecked}
                                onChange={(e) => {
                                  handleColumns(e, index);
                                }}
                              ></input>
                              {"  "}
                              <span
                                className={
                                  e.isDragOver
                                    ? "editColumnlist-label add-line"
                                    : "editColumnlist-label"
                                }
                              >
                                {e.name}
                              </span>
                              {e.isDragOver ? <hr className="hr-line" /> : null}
                            </ListGroupItem>
                          ) : null
                        )}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <div className="clear"></div>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </div>

    </div>
  );
};

// var checkboxSelection = function (params) {
//   return params.columnApi.getRowGroupColumns().length === 0;
// };
// var headerCheckboxSelection = function (params) {
//   return params.columnApi.getRowGroupColumns().length === 0;
// };

export default DealList;
