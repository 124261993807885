import React, { useState , useEffect} from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const HomeownerInfo = (props) => {
  const [buttonState, setbuttonState] = useState(false);
  const [skipHomeOwner, setSkipHomeOwner] = useState(false);
  
  const [validation,setValidation] = useState({
    homeOwnerName:'0',

  })

  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }


  const buttontoggle= () =>{
    props.settoggleState('homeownerinfo');
    props.setListState('homeownerinfo');
    setbuttonState(!buttonState);
  } 

  useEffect(() => {
    props.toggleState =='homeownerinfo'?
    setbuttonState(true)
    
    :setbuttonState(false);

  }, [props.toggleState]); 
  

  useEffect(() => {
    getData()
  },[props.data]);


  const getData=()=>{
    if(props.data!=null){
       props.setHomeOwnerName(props.data.homeOwnerName);
       props.setOwnerType(props.data.ownerType);
      }
  }

  const handleHomeOwnerCheck=()=>{
    if(skipHomeOwner){
      props.setHomeComplete.homeOwnerInfo=true;
      return true;
    }
    if(props.ownerType!=="" && props.ownerType!==undefined && props.homeOwnerName!=="" && props.homeOwnerName!==undefined && props.homeOwnerName!==null){
      props.setHomeComplete.homeOwnerInfo=true;
      return true;
    }
    props.setHomeComplete.homeOwnerInfo=false;
    return false;
  }

  return (
    <Col sm={12}>
      <Button onClick={buttontoggle} className=
       {buttonState ? "btn-100-active":"btn-100"}>
        Property Owner's Information
        <div className="float-right">
           <Input type="checkbox" 
           className={handleHomeOwnerCheck() ? "rightbutton-checkbox-active" : "rightbutton-checkbox"}
           checked={handleHomeOwnerCheck()}
           onChange={()=>
            {
              setSkipHomeOwner(!skipHomeOwner)
              if(props.data.homeOwnerName==''||props.data.homeOwnerName==undefined){
               setValidation({...validation,homeOwnerName:'-1'})
             }
            }
           }
           />
        {buttonState ? (
          <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
        ) : (
          <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
        )}
        </div>
      </Button>
      <Collapse isOpen={buttonState}>
        <Card className="createdeal-card">
          <CardBody>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="ownerType">
                    Owner Type<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="select"
                    className="dropdown-text"
                    name="ownerType"
                    id="ownerType"
                    value={props.data?props.data.ownerType:''}
                    onChange={(e)=>{
                      if(!window.confirm("Changing the owner type will discard any added details.\nDo you want to continue?")){
                        e.preventDefault()
                        return
                        

                      }
                      props.handleOnChangeDeal(e);
                      props.setOwnerType(e.target.value);
                      props.DisablesetownerType(e.target.value);
                    }}
                    style={{height : "35px"}}
                  >
                    <option value="INDIVIDUAL">INDIVIDUAL</option>
                    <option value="CORPORATION">CORPORATION</option>
                    <option value="PARTNERSHIP">PARTNERSHIP</option>
                    <option value="CONDO/CO-OP">CONDO/CO-OP</option>
                    <option value="OTHER GOVERNMENT AGENCY">OTHER GOVERNMENT AGENCY</option>
                    <option value="NYC AGENCY">NYC AGENCY</option>
                    <option value="NYCHA/HHC">NYCHA/HHC</option>
                  </Input>
                </Col>
                <Col sm={7}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="ownername">
                    Property Owner's Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input type="text" name="homeOwnerName" id="ownername"  className="createdeal-textfield"
                  value={props.data?props.data.homeOwnerName:''}
                  style={validation.homeOwnerName=='-1'?{...validationCss}:{}}
                  onBlur={(e)=>{
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,homeOwnerName:"-1"})
                    }else{
                      setValidation({...validation,homeOwnerName:"1"})
                    }
                  }}
                  onChange={(e)=>{
                    e.target.value=e.target.value.trimStart();
                    e.target.value=e.target.value.replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i, '')
                    e.target.value=e.target.value.replace(/[^\D]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^\D]/g,'').slice(1)
                    props.handleOnChangeDeal(e);
                    props.setHomeOwnerName(e.target.value)
                    }}/>
                </Col>
                <Col sm={7}></Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default HomeownerInfo;