import React, { useEffect, useState } from "react";
import { faSearch, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { FormGroup, Input, ListGroupItem } from "reactstrap";
import "./add_column.css";

const AddColumn = ({ columns, onChangeColumn, Label }) => {
  console.log(columns);
  const [cacheHeaderData, setCacheHeaderData] = useState(columns);
  const [headerData, setheaderData] = useState(columns);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setheaderData(columns ? columns : []);
    setCacheHeaderData(columns ? columns : []);
  }, [columns]);
  const onSearchColumns = (value) => {
    let newList = [...headerData];
    if (value == "") {
      newList.forEach((header) => {
        if (header.name) {
          header.isSearch = true;
        }
      });
    } else {
      newList.forEach((header) => {
        if (
          header.name &&
          header.name.toLowerCase().includes(value.toLowerCase())
        ) {
          header.isSearch = true;
        } else {
          header.isSearch = false;
        }
      });
    }
    setSearchValue(value);
    setheaderData(newList);
  };

  const handleColumns = (e, index) => {
    let newClient = [...headerData];
    newClient[index].isVisible = e.currentTarget.checked;
    newClient[index].isChecked = !e.currentTarget.checked;
    setheaderData(newClient);
    onChangeColumn(newClient);
  };
  return (
    <div>
      {" "}
      <Dropdown className="editColumn-custom custom-edit-column">
        <Dropdown.Toggle
          id="dropdown-editcolumn"
          className="float-right edit-colum-button "
        >
          {Label ? Label : "Columns"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <FormGroup className="mb-0 pb-0 pos-relative editcol-searchpad">
            <Input
              type="text"
              placeholder="Search"
              className="mr-sm-2 pad-fix search-filter"
              value={searchValue}
              onChange={(e) => onSearchColumns(e.target.value)}
            />

            <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />

            <div className="select-all-editColumn">
              <input
                type="checkbox"
                checked={
                  headerData.filter((one) => one.isVisible).length ==
                  headerData.length
                }
                onChange={(e) => {
                  const headers = [...headerData];
                  headers.forEach(
                    (obj) => (obj.isVisible = e.currentTarget.checked)
                  );
                  setheaderData(headers);
                  onChangeColumn(headers);
                }}
              ></input>
              {"  "}
              <span className={"editColumnlist-label "}>Select All</span>
            </div>
            <hr className="hr-line" />
          </FormGroup>
          <ul className="editColummn-list">
            {headerData.map((one) => {
              console.log(one);
            })}

            {headerData.map((e, index) =>
              e.name && e.isSearch ? (
                <ListGroupItem className="editColumnlist-text" draggable>
                  <FontAwesomeIcon
                    icon={faGripVertical}
                    className="icon-grip-vertical"
                  />
                  <input
                    id={e.id}
                    type="checkbox"
                    checked={e.isVisible}
                    onChange={(e) => {
                      handleColumns(e, index);
                    }}
                  ></input>
                  {"  "}
                  <span
                    className={
                      e.isDragOver
                        ? "editColumnlist-label add-line"
                        : "editColumnlist-label"
                    }
                  >
                    {e.name}
                  </span>
                  {e.isDragOver ? <hr className="hr-line" /> : null}
                </ListGroupItem>
              ) : null
            )}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
      <div className="clear"></div>
    </div>
  );
};

export default AddColumn;
