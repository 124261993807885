import React, { useEffect, useState } from 'react'
import './sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleLeft, faAngleRight, faFolderMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SideBarUl } from './SideBarUl';
import { ToolBox } from '../../../common/ShortcutsToolbox/ToolBox';
import EmailService from '../../../common/util/EmailService';

export const Sidebar = ({ content, onSelect, selectedFolder, selectedItem, setSelecetdItem, setComposeEmailPopUp, extraItems, addFolderSubFolder ,handleOnClicksForContext, clickedFromBody}) => {
    const [sidebarActive, setSidebarActive] = useState(true);
    const [toolboxposition, setToolboxPosition] = useState({ x: 0, y: 0 });
    const [isToolBox, setIsToolBox] = useState({ state: false, object: null });;

    const sidebarEmailContext = [
        {
          label: "Create Sub Folder",
        //   icon: () => {
        //     return (
        //       <FontAwesomeIcon color='black' icon={faPlus} />
        //     )
        //   },
          module: ['Sidebar'],
          action: (e, data, refrence) => {
            addFolderSubFolder(e,refrence)
          }
        },
        {
            label: "Delete Folder",
            // icon: () => {
            //   return (
            //     <FontAwesomeIcon color='red' icon={faFolderMinus} />
            //   )
            // },
            module: ['Sidebar'],
            Color:"red",
            action: (e, data, refrence) => {
                handleOnClicksForContext(refrence,"DELETE")
            }
          },
        {
          label: "Empty Folder",
        //   icon: () => {
        //     return (
        //       <FontAwesomeIcon color='red' icon={faTrash} />
        //     )
        //   },
          module: ['Sidebar'],
          Color:"red",
          action: (e, data, refrence) => {
            handleOnClicksForContext(refrence,"EMPTY")
          }
        },
       
      ]
    const [contextOptions, setContextOptions] = useState([...sidebarEmailContext])
    useEffect(e => {
        let length = content?.Folders?.length;
        if (!selectedFolder && length > 0) {
            onSelect(content?.Folders[0]);
        }
    }, [content])

    const handleContextMenu = (e, one, module) => { 
          if(one?.folderName == "Drafts" || one?.folderName == "Outbox" || one?.folderName == "Trash" || one?.folderName == "Spam" || one?.folderName == "Templates"){
            e.preventDefault();
            return
          }
        e.preventDefault(); 
        
        setIsToolBox({ state: true, object: one });
        setToolboxPosition({ x: e.clientX, y: e.clientY-50 });
      };
    const getToolBoxForFlag = () => {
        return (
          <ToolBox
            module={"Sidebar"}
            selectedMails={""}
            setOpen={(status) => {
                setIsToolBox(status);
            }}
            isOpen={isToolBox}
            position={toolboxposition}
            content={contextOptions}
          />
        )
      }

    return (<>
    {getToolBoxForFlag()}
        <div className={`Email-Sidebar-Wrapper ${sidebarActive ? "Show" : "Hide"}`}>
            {sidebarActive ? <div className='Sidebar-Collapse-Icon'>
                <FontAwesomeIcon
                    icon={faAngleLeft}
                    onClick={e => setSidebarActive(false)}
                />
            </div> : <div className='Sidebar-Collapse-Icon'>
                <FontAwesomeIcon
                    icon={faAngleRight}
                    onClick={e => { ; setSidebarActive(true)}}
                />
            </div>}
            {sidebarActive ? <div class="List-Wrapper">
                <div className='Compose-Btn p-1'>
                    <button onClick={() => setComposeEmailPopUp(true)} class="btn btn btn-primary btn-block">Compose</button>
                </div>
                <div className={clickedFromBody?`List-Wrapper-scroll`:''}>
                <div className='Section-Header'>Folders</div>
                {/* {getFolderList(content?.Folders)} */}
                <SideBarUl
                    addFolderSubFolder={addFolderSubFolder}
                    selectedItem={selectedItem}
                    collabseId={""}
                    folderArr={content?.Folders}
                    onSelect={onSelect}
                    show={true}
                    handleOnClicksForContext={handleOnClicksForContext}
                    handleContextMenu={handleContextMenu}
                />
                <div className='Section-Header'>Views</div>
                <ul class="List">
                    {/* <li class="compose mb-3"><button onClick={() => setComposeEmailPopUp(true)} class="btn btn-primary btn-block">Compose</button></li> */}
                    {extraItems && extraItems?.map((item) => {
                        return <li
                            onClick={() => {
                                setSelecetdItem(item.label)
                                item.action(item)
                            }}
                            class={selectedItem == item.label ? "active cursor-pointer" : 'cursor-pointer'}>
                            <span style={{minWidth:"13px"}}></span>
                            <a >
                                {item.label}
                            </a>
                        </li>
                    })}
                </ul>
                </div> 
            </div> :
                <div className='Verticle-Folder-Name'>
                    <span style={{ fontWeight: "bold" }}>{selectedItem}</span>
                </div>
            }
        </div>
    </>
    )
}
