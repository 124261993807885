import React, { useState, useEffect } from 'react';
//import StringInverterSubheader from "./stringInverterSubheader"
// import { useHistory, withRouter,useLocation } from 'react-router-dom';
import {
  Col,
  Form,
  Collapse,
  Input, Row, Container, FormGroup, ListGroupItem, Label, ModalBody, Modal, Button
} from 'reactstrap';
import HttpService from '../../../../../../services/http.service';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faAlignRight, faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faCaretDown, faEnvelope, faTasks, faTimes } from '@fortawesome/free-solid-svg-icons';
import handleCreate from '../../../../../../common/util/createRecord';

const formInputs =
{
  Name: "",
  modelNumber: "",
  acOutConSize: "",
  dcInConSize: "",
  ratedACPowerOut: "",
  maxACPowerOut: "",
  maxContOutCurr: "",
  maxDCPowerIn: "",
  maxInputVolt: "",
  nominalDCInputVolt: "",
  maxInputCurr: "",
  maxInputShortCircuitCurr: "",
  cecWeightedEff: "",
  powerFactor: "",
  grounding: "No",
}


const StringInverterModal = (props) => {
  const [formInputsValue, setFormInputsValue] = useState(formInputs);
  // const location = useLocation();
  const [detail, setDetail] = useState([]);
  const [func, setFunc] = useState("create");
  // const history = useHistory();

  const handleChange = (e) => {
     
    if(e.currentTarget.type=='number'){
      if(e.currentTarget.value.includes('-')){
        e.preventDefault()
        return
      }
    }
    console.log("the name is" + e.target.name);
    console.log("the value is" + e.target.value);
    setFormInputsValue({
      ...formInputsValue,
      [e.target.name]: e.target.value
    })
  }

  const changeIntoDecimal = (e) => {
    let tempValidation={...validation};
    e.target.value=e.target.value.trimStart();
    if(e.target.value.trim()=='' || e.target.value==undefined){
      tempValidation[e.currentTarget.name]='-1'
    }else{
      tempValidation[e.currentTarget.name]='1'
    }
    setValidations(tempValidation)
    setFormInputsValue({
      ...formInputsValue,
      [e.target.name]: e.target.value!=''?parseFloat(e.target.value).toFixed(2):''
    })

  }

  const changeGrounding = (e) => {

    if (e.target.value === "Yes") {
      setFormInputsValue({
        ...formInputsValue,
        grounding: "Yes"
      });
    } else {
      setFormInputsValue({
        ...formInputsValue,
        grounding: "No"
      });
    }
  }

  const changePositiveValue = (e) => {
    if (e.target.value < 0) {
      e.target.value = e.target.value * -1;
    }
  }

  //   useEffect(()=>{
  //     if(location.state.action!='create') {
  //       console.log(location.state.detail);
  //       setDetail(location.state.detail);
  //       setFormInputsValue({
  //         ...formInputsValue,
  //         _id: location.state.detail._id,
  //         modelNumber:location.state.detail.modelNumber,
  //         acOutConSize: location.state.detail.acOutConSize,
  //     dcInConSize: location.state.detail.dcInConSize,
  //     ratedACPowerOut: location.state.detail.ratedACPowerOut,
  //     maxACPowerOut: location.state.detail.maxACPowerOut,
  //     maxContOutCurr: location.state.detail.maxContOutCurr,
  //     maxDCPowerIn: location.state.detail.maxDCPowerIn,
  //     maxInputVolt: location.state.detail.maxInputVolt,
  //     nominalDCInputVolt: location.state.detail.nominalDCInputVolt,
  //     maxInputCurr: location.state.detail.maxInputCurr,
  //     maxInputShortCircuitCurr: location.state.detail.maxInputShortCircuitCurr,
  //     cecWeightedEff : location.state.detail.cecWeightedEff,
  //     powerFactor: location.state.detail.powerFactor,
  //     grounding: location.state.detail.grounding,
  //     })
  //     setFunc("update")
  //     }else{
  //       setFunc("create")
  //     }
  // }, [location])

  const resetStringFields = () => {
    setFormInputsValue({
      ...formInputsValue,
      Name:"",
      modelNumber: "",
      acOutConSize: "",
      dcInConSize: "",
      ratedACPowerOut: "",
      maxACPowerOut: "",
      maxContOutCurr: "",
      maxDCPowerIn: "",
      maxInputVolt: "",
      nominalDCInputVolt: "",
      maxInputCurr: "",
      maxInputShortCircuitCurr: "",
      cecWeightedEff: "",
      powerFactor: "",
      grounding: "No",

    })
  }


  const createStringInverter = async () => {
    var route = "";
    route = func == "create" ? 'createstringinverter' : 'updatestringinverterbyid';
    let returnData = await HttpService.CreateUpdate(route, formInputsValue).then(res => {
      //   console.log(res.data);
      if (res.data == "Added Successfully") {
        resetStringFields();
        props.setStringInverterModal(false);
        //props.getInvertersModelNo();
        props.setReloadInverterData(true);
      }
      else if (res.data == "Unsuccessfull") {
        alert("Server Issue: Unable to add PV module.");
      }

    });

  }

  const createStringInverterZOHO = async () => {
     
    let isValid=await isAllValid();
    if(!isValid){
      highliteEmptys()
      alert("Please fill all required fields!")
      return;
    }

    let obj = {
      "Name": formInputsValue.Name,

    }
    handleCreate("String_Invertors",obj)
      .then(function (data) {
        data.data=data.data.data;
        console.log(data);
        props.setinverterModelNumber({ id: data.data[0].details.id, module: "String_Invertors", name: formInputsValue.Name });
        resetStringFields();
        props.setStringInverterModal(false);
        props.getInvertersModelNo(props.invertertype);
        props.setReloadInverterData(true);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  // Hrushikesh shelke validation
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const[validation,setValidations]=useState({
    Name:'0',
    modelNumber:'0',
    acOutConSize:'0',
    ratedACPowerOut:'0',
    dcInConSize:'0',
    maxACPowerOut:'0',
    maxContOutCurr:'0',
    maxDCPowerIn:'0',
    maxInputVolt:'0',
    nominalDCInputVolt:'0',
    maxInputCurr:'0',
    maxInputShortCircuitCurr:'0',
    cecWeightedEff:'0',
    powerFactor:'0',
  });
  const resetValidations=()=>{
     
    let vKeys=Object.keys(validation);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      ob[vKeys[i]]='0'
    }
    setValidations(ob)
  }
  const isAllValid=()=>{  
    let vKeys=Object.keys(validation);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(validation[vKeys[i]]=='0' || validation[vKeys[i]]=='-1'){
        return false;
      }
    }
    return true;
  }
  const highliteEmptys=()=>{     
    let vKeys=Object.keys(validation);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(validation[vKeys[i]]=='0' || validation[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
    setValidations(ob)
  }

  const validateFunction=(e)=>{
     
    let tempValidation={...validation};
    if(e.currentTarget.type=="text"){
      e.target.value=e.target.value.trimStart();
      if(e.currentTarget.value.trim()=='' || e.currentTarget.value==undefined){
        tempValidation[e.currentTarget.name]='-1'
      }else{
        tempValidation[e.currentTarget.name]='1'
      }
    }else if(e.currentTarget.type=="number"){
      e.currentTarget.value=e.currentTarget.value.trimStart();
      e.target.value=e.target.value.trimStart();
      if(e.currentTarget.value.trim()=='' || e.currentTarget.value==undefined){
        tempValidation[e.currentTarget.name]='-1'
      }else{
        tempValidation[e.currentTarget.name]='1'
      }
    }
    setValidations(tempValidation);
  }

  //end

  return (
    <div>
      <Modal
        isOpen={props.stringInverterModal}
        toggle={props.stringInverterModal}

      >
        <div className="modal-header-class">
          String Inverter
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={() =>{ 
                resetValidations()
                resetStringFields();
                props.setStringInverterModal(!props.stringInverterModal)
              }}
            ></FontAwesomeIcon>
          </div>
        </div>
        <ModalBody className="h500-overflow">
        <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="modelNumber">
                 String Inverter Name
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="text"
                  name="Name"
                  className="create-user-input"
                  value={formInputsValue.Name}
                  onChange={(e) =>{ 
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)}}
                  style={validation.Name=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span> */}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="modelNumber">
                  Model Number
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="text"
                  name="modelNumber"
                  className="create-user-input"
                  value={formInputsValue.modelNumber}
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)}}
                  style={validation.modelNumber=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span> */}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="acOutConSize">
                  AC output conduit size

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="acOutConSize"
                  className="create-user-input"
                  value={formInputsValue.acOutConSize}
                  onChange={(e) => handleChange(e)}
                  style={validation.acOutConSize=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={2}>
                {/* <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span> */}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="dcInConSize">
                  DC input conduit size

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="dcInConSize"
                  className="create-user-input"
                  value={formInputsValue.dcInConSize}
                  onChange={(e) => handleChange(e)}
                  style={validation.dcInConSize=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.lastNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  field should not be empty.
                </span> */}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="ratedACPowerOut">
                  Rated AC power output

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="ratedACPowerOut"
                  className="create-user-input"
                  value={formInputsValue.ratedACPowerOut}
                  onChange={(e) => handleChange(e)}
                  style={validation.ratedACPowerOut=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span> */}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxACPowerOut">
                  Maximum AC power output

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                   type="number"
                   min="0.00"
                   step="1.00"
                  name="maxACPowerOut"
                  className="create-user-input"
                  value={formInputsValue.maxACPowerOut}
                  onChange={(e) => handleChange(e)}
                  style={validation.maxACPowerOut=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.lastNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  field should not be empty.
                </span> */}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxContOutCurr">
                  Maximum continuous output current

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                   type="number"
                   min="0.00"
                   step="1.00"
                  name="maxContOutCurr"
                  className="create-user-input"
                  value={formInputsValue.maxContOutCurr}
                  onChange={(e) => handleChange(e)}
                  style={validation.maxContOutCurr=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxDCPowerIn">
                  Maximum DC power input

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxDCPowerIn"
                  className="create-user-input"
                  value={formInputsValue.maxDCPowerIn}
                  onChange={(e) => handleChange(e)}
                  style={validation.maxDCPowerIn=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>

            </Row>
          </FormGroup>

          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxInputVolt">
                  Maximum input voltage

                  <span className="red-text">*</span>

                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxInputVolt"
                  className="create-user-input"
                  value={formInputsValue.maxInputVolt}
                  onChange={(e) => handleChange(e)}
                  style={validation.maxInputVolt=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="nominalDCInputVolt">
                  Nominal DC input voltage

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="nominalDCInputVolt"
                  className="create-user-input"
                  value={formInputsValue.nominalDCInputVolt}
                  onChange={(e) => handleChange(e)}
                  style={validation.nominalDCInputVolt=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxInputCurr">
                  Maximum input current

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                 type="number"
                 min="0.00"
                 step="1.00"
                  name="maxInputCurr"
                  maxLength={9}
                  className="create-user-input"
                  value={formInputsValue.maxInputCurr}
                  onChange={(e) => handleChange(e)}
                  style={validation.maxInputCurr=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxInputShortCircuitCurr">
                  Maximum input short circuit current

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                 type="number"
                 min="0.00"
                 step="1.00"
                  name="maxInputShortCircuitCurr"
                  className="create-user-input"
                  value={formInputsValue.maxInputShortCircuitCurr}
                  onChange={(e) => handleChange(e)}
                  style={validation.maxInputShortCircuitCurr=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="cecWeightedEff">
                  CEC weighted efficiency

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="text"
                  name="cecWeightedEff"
                  className="create-user-input"
                  value={formInputsValue.cecWeightedEff}
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)}}
                  style={validation.cecWeightedEff=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="powerFactor">
                  Power factor

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="text"
                  name="powerFactor"
                  className="create-user-input"
                  value={formInputsValue.powerFactor}
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)}}
                  style={validation.powerFactor=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                {/* <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span> */}
              </Col>
            </Row>
          </FormGroup>


          <FormGroup row className="marb-16  padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="grounding">
                  Grounding

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
                <Label className="text-bold2">Yes</Label>
                <Input
                  type="radio"
                  name="grounding"
                  value="Yes"
                  checked={formInputsValue.grounding === "Yes"}
                  onChange={(e) => changeGrounding(e)}
                />
              </Col>
              <Col sm={2}>
                <Label className="text-bold2">No</Label>
                <Input
                  type="radio"
                  name="grounding"
                  value="No"
                  checked={formInputsValue.grounding === "No"}
                  onChange={(e) => changeGrounding(e)}
                />
              </Col>
              <Col sm={2}></Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <Button
                  className="continue-button"
                  onClick={() => 
                    {
                      resetValidations()
                      resetStringFields();
                      props.setStringInverterModal(false)
                    }
                  }
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={5}>
                <Button className="continue-button"
                  onClick={() => createStringInverterZOHO()}
                >
                  Save
                </Button>
              </Col>
              <Col sm={1}></Col>
            </Row>
          </FormGroup>

        </ModalBody>

      </Modal>

    </div>
  );
}

export default StringInverterModal;