import React, { useState,useRef,useImperativeHandle,forwardRef, useEffect } from 'react';
import PermittingInfo from './permittinginfo/permittinginfo';
import {Button, Row, Col} from "reactstrap";
import HttpService from '../../../../../services/http.service';
import axios from 'axios';
import Config from '../../../../../config.json'

// import GeneralContractor from './generalcontractor/generalcontractor'
// import ElectricianInfo from './electricianinfo/electricianinfo'
const Permitting = forwardRef((props,ref) => {
  const permitObj = {
    permitInfo: false
  }
  const [toggleState, settoggleState] = useState('permittinginfo');
  const [contractorList, setContractorList] = useState([]);
  const [electricianList, setElectricianList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);

  const [contractors, setContractors] = useState([]);
  const [electricians, setElectricians] = useState([]);
  const [applicants, setApplicants] = useState();

  const [applicant, setApplicant] = useState("Select Applicant");
  const [contractor, setContractor] = useState("Select Contractor");
  const [electrician, setElectrician] = useState("Select Electrician");

  let permittingInfoProps = {
    isAccess: props.isAccess,
    selectedRole: props.selectedRole,
    applicant, setApplicant,
    contractor, setContractor,
    electrician, setElectrician,
    toggleState: toggleState, settoggleState: settoggleState, setListState: props.setListState, setPermitComplete: permitObj
  }

  useEffect(() => {
    settoggleState(props.listState);
  }, [props.listState]);

  //hrushikesh shelke new code
  const saveCancelButtonRef = useRef();
  useImperativeHandle(ref, () => ({
    savePermitting() {
      ableNextButton();
    }
  }));
//hrushikesh shelke new code

  const ableNextButton = () => {
    if (permitObj.permitInfo) {
      savePermittingData();
      props.updateTheSidebarState({type:"Permitting",status:true})
      localStorage.setItem('currentsidebar',"PVSystem");
      props.setLayoutState("pvSystem");
      props.setListState("pvsysteminfo");
      return true;
    } else {
      alert("Please check all checkboxes of current Section.(It should be checked for next form.)");
      return false;
    }
  }

  const savePermittingData = () => {
    const data = {
      "applicant": applicant,
      "contractor": contractor,
      "electrician": electrician

    }
    var preliminaryData = JSON.parse(localStorage.getItem("preliminayData"));
    var nycData = JSON.parse(localStorage.getItem("nycData"));
    var homeownerData = JSON.parse(localStorage.getItem("homeownerData"));
    var permittingData = JSON.parse(localStorage.getItem("permittingData"));
    var pvsystemData = JSON.parse(localStorage.getItem("pvSystemData"));

    const mergedObject = {
      ...preliminaryData,
      ...nycData,
      ...homeownerData,
      ...data,
      ...pvsystemData,
    };
    localStorage.setItem("permittingData", JSON.stringify(data));
  }

  const updateData = async (data) => {
    let returnData = await HttpService.CreateUpdate('updatedealbyprojectno', data).then(res => {
      props.refreshDeal(data.ProjectNumber);
      //alert("Deal updated successfully.");
    })
      .catch((err) => {
        // alert("Unable to update deal. Check your Network.");
      });
  }



  // useEffect(() => {
  //   // getGeneralContractors();
  //   // getElectricians();
  // }, []);


  useEffect(() => {
   // window.ZOHO.embeddedApp.on('PageLoad', function (data) {
    getGeneralContractors()
    getElectricians()
    getApplicants()
    //})
   // window.ZOHO.embeddedApp.init();
  }, [])

  const getGeneralContractors = async () => {
    const backendUrl = Config.backend_url+"workdrive";

        var config = {
            Type: "Get",

            url: "https://www.zohoapis.com/crm/v2/General_Contractor",
            method: "GET",
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };
    axios.post(backendUrl, { config: config })
    .then(function (data) {
      data.data=data.data.data;
      let  inverters = [];
      // var newDiv = document.getElementById('Contractor');
      // var selectHTML = "<option>--Select Contractor--</option>";
      for (let i = 0; i < data.data.length; i++) {
        inverters.push(data.data[i].Name);
      }
      setContractorList(inverters);
      setContractors(data.data);
      // newDiv.innerHTML = selectHTML;

    })
    .catch((err) => {
      console.log(err);
      alert(err);
    })

    // var inverters = [];
    // let returnData = await HttpService.getByBoj('getallcontractor', {}).then(res => {

    //   if (res.data.length > 0) {
    //     res.data.map((e) => {
    //       inverters.push(e.companyName);
    //     })
    //     setContractorList(inverters);
    //     setContractors(res.data)
    //   }
    // });

  }

  const getApplicants = async () => {
    const backendUrl = Config.backend_url+"workdrive";

    var config = {
        Type: "Get",

        url: "https://www.zohoapis.com/crm/v2/Applicants",
        method: "GET",
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    };


    axios.post(backendUrl, { config: config })
    .then(function (data) {
      data.data=data.data.data
      // var newDiv = document.getElementById('Electrician');
      let applicants = [];
      var selectHTML = "<option>--Select Electrician--</option>";
      for (let i = 0; i < data.data.length; i++) {
        applicants.push(data.data[i].Name);
        // let obj = { id: data.data[i].id }
        // obj = JSON.stringify(obj)
        // selectHTML += `<option value=${obj}>` + data.data[i].Name + "</option>";
      }
      setApplicantList(applicants);
      setApplicants(data.data);
      // newDiv.innerHTML = selectHTML;

    })
    .catch((err) => {
      console.log(err);
      alert(err);
    })

    // var inverters = [];
    // let returnData = await HttpService.getByBoj('getallelectrician', {}).then(res => {
    //   if (res.data.length > 0) {
    //     res.data.map((e) => {
    //       inverters.push(e.companyName);
    //     })
    //     setElectricians(res.data);
    //     setElectricianList(inverters);
    //   }
    // });

  }

  const getElectricians = async () => {
    const backendUrl = Config.backend_url+"workdrive";

    var config = {
        Type: "Get",

        url: "https://www.zohoapis.com/crm/v2/Electricians",
        method: "GET",
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    };


    axios.post(backendUrl, { config: config })
    .then(function (data) {
      data.data=data.data.data
      // var newDiv = document.getElementById('Electrician');
      let inverters = [];
      var selectHTML = "<option>--Select Electrician--</option>";
      for (let i = 0; i < data.data.length; i++) {
        inverters.push(data.data[i].Name);
        // let obj = { id: data.data[i].id }
        // obj = JSON.stringify(obj)
        // selectHTML += `<option value=${obj}>` + data.data[i].Name + "</option>";
      }
      setElectricianList(inverters);
      setElectricians(data.data);
      // newDiv.innerHTML = selectHTML;

    })
    .catch((err) => {
      console.log(err);
      alert(err);
    })

    // var inverters = [];
    // let returnData = await HttpService.getByBoj('getallelectrician', {}).then(res => {
    //   if (res.data.length > 0) {
    //     res.data.map((e) => {
    //       inverters.push(e.companyName);
    //     })
    //     setElectricians(res.data);
    //     setElectricianList(inverters);
    //   }
    // });

  }

  return (
    <div className="boxplate">
      <div className="boxplate-heading" style={{display: "flex"}}>
        <div className='sectitle'>Permitting</div>
        {/* <div className='zolabisbtn'> */}
          {/* <button className='nxtBtn' onClick={()=>ableNextButton()}>Next</button> */}
        {/* </div> */}
         </div>
      <div className="pad-15 createdeal-custom-scroll">
        <PermittingInfo
          {...permittingInfoProps}
          handleOnChangeDeal={props.handleOnChangeDeal}
          contractorList={contractorList}
          electricianList={electricianList}
          contractors={contractors}
          electricians={electricians}
          applicantList={applicantList}
          applicants={applicants}
          data={props.data}
          getGeneralContractors={getGeneralContractors}
          getElectricians={getElectricians}
          getApplicants={getApplicants}
        />
        {/* <GeneralContractor  toggleState={toggleState} settoggleState={settoggleState}/>
          <ElectricianInfo  toggleState={toggleState} settoggleState={settoggleState}/> */}
        {/* <Row>
          <Col sm={10}></Col>
          <Col sm={2} className="nextbtn-box-pad"> <Button
            className="next-button next-btn3D"
            onClick={() => ableNextButton()}> Next </Button></Col>
        </Row> */}
      </div>
    </div>
  );
})

export default Permitting;