import React, { useEffect, useState } from 'react'
import './Calendar.css'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faArrowCircleUp, faCaretUp, faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
export const EventSearch = ({ children, events, serchInput,openSerachResult}) => {
    const [eventsObj,setEventsObj]=useState({});
    const [isOpen,setIsOpen]=useState(false);
    const [open,setOpen]=useState(false);
    useEffect(e=>{
        if(serchInput && events && events.length>0){
            let obj={};
            events?.map(one=>{
                let key=moment(one.dateandtime.start).format("YYYY-MM-DD");
                if(obj[key]){
                    obj[key]=[...obj[key],one];
                }else{
                    obj[key]=[one];
                }
            })
            setEventsObj(obj);
            setOpen(true);
        }else{
            setEventsObj({});
            setOpen(false);
        }
    },[serchInput,events])
    // useEffect(e=>{
    //     if(isOpen && Object.keys(eventsObj)?.length>0){
    //         setOpen(true);
    //     }else{
    //         setOpen(false);
    //     }
    // },[isOpen,eventsObj])
    return (
        <Dropdown className="Serach-Event-DropDown"
            show={open}
            // onToggle={e=>{
            //     if(serchInput!=""){
            //         setIsOpen(true);
            //     }else{
            //         setIsOpen(false);
            //     }
            // }}
        >
            <Dropdown.Toggle
                id="Serach-Event-Toggle"
                className="Serach-Event-Toggle"
            >{children}
                {/* <Input
                      style={{ padding: "5px 0px 5px 30px", margin: "0px", fontSize: "14px" }}
                      placeholder="Search events..."
                      type="text"
                      className="dropdown-text mr-2 calender-dropdown-height"
                      name="SearchEvent"
                      id="SearchEvent"
                      value={searchEventText}
                      onChange={async (e) => {
                        let text = e.target.value;
                        if (text) {
                          try {
                            setSearchEventText(text);
                            let res = { ...myCalendar };
                            let events = await CalendarAPI.GetSearchEvents(
                              res.uid,
                              text,
                              getDateRange()
                            );
                            setSearchEvents(events);
                          } catch (err) {
                            console.log(err);
                          }
                        } else {
                          setSearchEventText("");
                          setSearchEvents([]);
                        }

                        // goToView(e.currentTarget.value);
                      }}
                    /> */}
            </Dropdown.Toggle>
            <Dropdown.Menu className="Serach-Event-Menu">
                <OtsideClickClosingWrapper
                    close={e=>{
                        if(open){
                            setOpen(!open);
                        }
                    }}
                >
                <div className='Header'>
                    <FontAwesomeIcon color='white' icon={faCalendarAlt} />
                    <span>Event Results</span>
                    <FontAwesomeIcon className='Icon' icon={faCaretUp} />
                </div>
                <div className='Content'>
                    {eventsObj && Object.keys(eventsObj)?.map((key, i) => {
                        return (
                            <Row className="Event-Card" sm={12}>
                                <Col sm={3} className='Column'>
                                    <Row>
                                        <Col sm={5} className='Column' style={{ alignItems: "center", justifyContent: "center", display: "flex" }} >
                                            <div className='Date'>
                                                {moment(key).format("DD")}
                                            </div>
                                        </Col>
                                        <Col sm={7} className='Column' >
                                            <div className='Date-String'>
                                                {moment(key).format("MMM YYYY, ddd")}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={9} className='Column Event-Details'>
                                   {eventsObj[key]?.map((one,i)=>{return (<Row className="Event-Details-Row"
                                    onClick={(e) => {
                                        openSerachResult(e, one);
                                    }}
                                   >
                                        <Col className='Column Event-Type' sm={5} style={{
                                            display: "flex",
                                            // justifyContent:"center",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}>
                                            <Col sm={1}><FontAwesomeIcon color={one.color ? one.color:"#6cc8fd" } icon={faCircle} /></Col>
                                            {one.isallday ? <Col>All Day</Col>:
                                            <Col><span><b>{moment(one.dateandtime.start).format("hh:mm a")+" - "}</b></span><span>{(moment(one.dateandtime.start).format("MM-DD-YYYY")!=moment(one.dateandtime.end).format("MM-DD-YYYY")?<b style={{color:"#018ee0"}}>{moment(one.dateandtime.end).format("MM-DD-YY hh:mm a")}</b>:moment(one.dateandtime.end).format("hh:mm a"))}</span></Col>}
                                        </Col>
                                        <Col className='Column' sm={7} style={{
                                            display: "flex",
                                            // justifyContent:"center",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}>
                                           {one.title}
                                        </Col>
                                    </Row>)})}
                                </Col>
                            </Row>
                        )
                    })}

                </div>
                </OtsideClickClosingWrapper>
            </Dropdown.Menu>
        </Dropdown>
    )
}
