import React, { useContext, useEffect, useState } from "react";
import {
  Modal as AntModal,
  Button as AntButton,
  Dropdown as AntDropdown,
  Select as AntSelect,
  Input,
  DatePicker as AntdDatepicker,
  Popover as AntdPopover,
  Collapse,
  Tabs,
  TimePicker,
  Button,
  Badge,
  Divider,
} from "antd";
import "./viewPipeline.css";
import { Row } from "reactstrap";
import { Col } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faBriefcase,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker } from "react-date-range";
import { CloseOutlined } from "@ant-design/icons";
import TaskListTab from "../ViewPipelinesTabs/TaskListTab";
import IssuesTab from "../ViewPipelinesTabs/IssuesTab";
import JoditTextEditor from "../../../common/JoditEditor/JoditTextEditor";
import { UserPicker } from "../../../common/UserPicker/UserPicker";
import ActivityStreamTab from "../ViewPipelinesTabs/ActivityStreamTab";
import AwsServerService from "../../../common/util/AwsServerService";
import swal from 'sweetalert';

const CustomeTaskField = ({ name, icon, children }) => {
  return (
    <Row
      className={name == "Owner" ? "Custom-Field-Row": "Custom-Field-Pipeline-Row"}
      style={{ opacity: "0.85" }}
      sm={12}
    >
      <Col style={{ padding: "0px" }} sm={4} className="Custom-Field-Col-1">
        <Row sm={12}>
          <Col style={{ padding: "0px 3px", fontWeight: "500" }} sm={12}>
            {name}
          </Col>
        </Row>
      </Col>
      <Col sm={8}>{children}</Col>
    </Row>
  );
};

const GetPipelineFields = () => {
  return (
    <Row sm={12}>
      <Col sm={6}>
        <CustomeTaskField
          name={"Flag"}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          <Input variant="borderless" value={"test"} />
        </CustomeTaskField>
        <CustomeTaskField
          name={"Start Date"}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
            <AntdDatepicker
              portalId="root"
              allowClear
              format="MM/DD/YYYY"
              popperClassName="ag-custom-component-popup"
              className="form-control form-control-sm"
              // value={getDatePickerValueOfGroup(index, groupIdx)}
              value={dayjs(new Date())}
              variant="borderless"
              style={{border : "none"}}
              onChange={(selected) => { 
                // //setDate([...date,{date:selected,index:index}])
                // if (selected) {
                //   let list = [...filters];
                //   list[index].Group[groupIdx].date = [
                //     dayjs(selected).format("MM/DD/YYYY"),
                //   ];
                //   list[index].Group[groupIdx].phase = "completed";
                //   setDatePickerValue(list);
                //   setFilters(list);
                // } else {
                //   let list = [...filters];
                //   list[index].Group[groupIdx].date = [selected];
                //   list[index].Group[groupIdx].phase = "middle";
                //   setDatePickerValue(list);
                //   setFilters(list);
                // }
              }}
            />
        </CustomeTaskField>
        <CustomeTaskField
          name={"Status"}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          <Input variant="borderless" value={"Active"} />
        </CustomeTaskField>
        <CustomeTaskField
          name={"Order"}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          <Input variant="borderless" />
        </CustomeTaskField>
      </Col>
      <Col sm={6}>
        <CustomeTaskField
          name={"Owner"}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          <UserPicker mode={"multiple"} />
        </CustomeTaskField>
        <CustomeTaskField
          name={"End Date"}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          <AntdDatepicker
              portalId="root"
              allowClear
              format="MM/DD/YYYY"
              popperClassName="ag-custom-component-popup"
              className="form-control form-control-sm"
              // value={getDatePickerValueOfGroup(index, groupIdx)}
              value={dayjs(new Date())}
              variant="borderless"
              style={{border : "none"}}
              onChange={(selected) => { 
                // //setDate([...date,{date:selected,index:index}])
                // if (selected) {
                //   let list = [...filters];
                //   list[index].Group[groupIdx].date = [
                //     dayjs(selected).format("MM/DD/YYYY"),
                //   ];
                //   list[index].Group[groupIdx].phase = "completed";
                //   setDatePickerValue(list);
                //   setFilters(list);
                // } else {
                //   let list = [...filters];
                //   list[index].Group[groupIdx].date = [selected];
                //   list[index].Group[groupIdx].phase = "middle";
                //   setDatePickerValue(list);
                //   setFilters(list);
                // }
              }}
            />
        </CustomeTaskField>
        <CustomeTaskField
          name={"Sequence"}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
        >
          <Input variant="borderless" />
        </CustomeTaskField>
      </Col>
    </Row>
  );
};

const ViewPipelines = ({open,setOpen,viewPipelineData,viewTaskFunction, setViewPipelineData, refreshData, setPageRefreshData,
  openTaskModal, setOpenTaskModal, allPipelineData
}) => {
  //   const {AllUsers}= useContext(AppDataRequired);
  // console.log("viewPipelineData:", viewPipelineData);
  const userInfo=JSON.parse(localStorage.getItem("userinfo"));
  const initialPipelineData={}
  const [pipelineData,setPipelineData]=useState(initialPipelineData)    
  const [visible , setVisible] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);


  useEffect(e=>{
    if(viewPipelineData && viewPipelineData?.length > 0){
      setPipelineData(viewPipelineData[0])
    }
  }, [viewPipelineData,refreshData])

  //
  const changeDateRange = async (item) => {
    // let taskObj = { ...taskData };
    let startDate = new Date(item.selection.startDate);
    let endDate = new Date(item.selection.endDate);
    startDate = dayjs(startDate).format("MM-DD-YYYY");
    endDate = dayjs(endDate).format("MM-DD-YYYY");
    setSelectionRange([item.selection]);
  };

  // const DueDateRangePopover = () => {
  //   return (
  //     <AntdPopover
  //       overlayClassName="create-task-datepicker"
  //       content={
  //         <div className="due-date-range">
  //           <DateRangePicker
  //             onChange={changeDateRange}
  //             showSelectionPreview={true}
  //             moveRangeOnFirstSelection={false}
  //             months={1}
  //             ranges={selectionRange}
  //             direction="vertical"
  //             scroll={{ enabled: true }}
  //           />
  //         </div>
  //       }
  //       trigger="click"
  //       placement="bottom"
  //     >
  //       <AntButton
  //         id="setDueDate"
  //         className="SupervisorBtn"
  //         icon={<FontAwesomeIcon icon={faCalendarAlt} />}
  //         style={{ width: "100%" }}
  //       >
  //         Set Due Date
  //       </AntButton>
  //     </AntdPopover>
  //   );
  // };
   // Save Pipeline Api Call // 

   const handleSavePipeline= async () =>{
     let data={...pipelineData}; 
     if (data.PipelineName=='' || data.PipelineName==undefined) {
       swal('Alert','Please fill all the required fields!','warning');
       return;  
     }
       data['MODIFIEDBY']=userInfo?.email_id;  
       data['ROWID']=pipelineData?.PipelineROWID ? pipelineData?.PipelineROWID : pipelineData?.ROWID;
       delete data?.PipelineROWID
       delete data?.Tasks
       AwsServerService.updatePipelineInTemplate(data).then(async res=>{
         // swal('Success','Pipeline updated successfully!','success')
         if(pipelineData?.Tasks?.length > 0){
          await Promise.all(
            pipelineData?.Tasks.map((item) => {
              if (item.TaskROWID) {
                return;
              }
              delete item["id"];
              delete item["flag"];
              item["PipelineROWID"] = pipelineData?.PipelineROWID;
              return AwsServerService.saveTaskInTemplate(item);
            })
          ).then((allRes) => {
            console.log("allRes", allRes);
          });
         }
         setOpen(!open);
         setPageRefreshData(!refreshData)
       }).catch(err=>{
         swal('Failed','Some error occured','warning')
       });
    //  }
   } 

  return (
    <div>
      <AntModal
        open={open}
        wrapClassName={"View-Pipeline-Modal-Wrapper"}
        rootClassName="View-Pipeline-Modal-Root-Wrapper"
        draggable
        title=""
        maskClosable={false}
        width={300}
        className=""
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setPipelineData(initialPipelineData)
          setOpen(false);
        }}
        footer={[<></>]}
      >
        <div className="View-Pipeline-Wrapper">
          <div className="Task-Header">
            <div><h3 style={{ marginBottom: "0px" , opacity : "0.85"}}>Update Pipeline</h3></div>
            <div style={{ display: "flex", gap: "10px" }}>
            <Button
                    type="primary"
                    // icon={<CloseOutlined />}
                    onClick={() => {
                      handleSavePipeline();
                    }}
                >{"Update Pipeline"}</Button>
              <Button
                icon={<CloseOutlined />}
                onClick={() => {setPipelineData(initialPipelineData); setViewPipelineData([]) ;setOpen(false)}}
              ></Button>
            </div>
          </div>
          <div className="View-Pipeline-Modal-Body-Wrapper">
            <div className="View-Pipeline-Modal-Body-Child1">
              <Row className="View-Pipeline-Modal-Body" sm={12}>
                <Col className="View-Pipeline-Info-Wrapper" sm={12}>
                  {/* <Row className="Pipeline-Primary-Data-wrapper"> */}
                  <Row className="PipelineNameWrapper" style={{marginBottom : "10px"}}>
                    <Input
                      className="Pipeline-Name"
                      size="large"
                      placeholder="Pipeline Name"
                      variant="borderless"
                      value={pipelineData?.PipelineName}
                      onChange={(e)=>{
                        e.target.value=e.target.value.trimStart();
                        setPipelineData({...pipelineData,'PipelineName':e.target.value})
                      }}
                    />
                  </Row>
                  <Row>
                    <Tabs
                      defaultActiveKey="1"
                      items={[
                        {
                          label: (
                            <Badge color="blue" count={pipelineData?.Tasks?.length} offset={[10, 0]}>
                              <span>Task List</span>
                            </Badge>
                          ),
                          key: "1",
                          children: <TaskListTab 
                                      groupFilter={""} 
                                      viewTaskFunction={viewTaskFunction}
                                      viewPipelineData={viewPipelineData} 
                                      openTaskModal={openTaskModal}
                                      setOpenTaskModal={setOpenTaskModal}
                                      pipelineData={pipelineData}
                                      setPipelineData={setPipelineData}
                                      refreshData={refreshData}
                                      setPageRefreshData={setPageRefreshData}
                                      allPipelineData={allPipelineData}
                                  />,
                        },
                        // {
                        //   label: (
                        //     <Badge color="blue" count={0} offset={[10, 0]}>
                        //       <span>Issues</span>
                        //     </Badge>
                        //   ),
                        //   key: "2",
                        //   children: <IssuesTab />,
                        // },
                        // {
                        //   label: (
                        //     <Badge color="blue" count={0} offset={[10, 0]}>
                        //       <span>Comments</span>
                        //     </Badge>
                        //   ),
                        //   key: "3",
                        //   children: (
                        //     <JoditTextEditor
                        //       id="Pipelines"
                        //       signature={""}
                        //       isSchedule={""}
                        //       Attachments={[]}
                        //       emailContent={[]}
                        //       preValues={""}
                        //       setIsSchedule={""}
                        //       composeMailObj={[]}
                        //       isDraftAlready={""}
                        //       setEmailContent={""}
                        //       contentShowFlag={""}
                        //       handleUploadFiles={""}
                        //       composeMailToShow={[]}
                        //       openSchedulePopup={""}
                        //       openAttachmentPopUp={""}
                        //       setOpenSchedulePopup={""}
                        //       setOpenAttachmentPopUp={""}
                        //     />
                        //   ),
                        // },
                        // {
                        //   label: (
                        //     <Badge color="blue" count={0} offset={[10, 0]}>
                        //       <span>Fields</span>
                        //     </Badge>
                        //   ),
                        //   key: "4",
                        //   children: <GetPipelineFields />,
                        // },
                        {
                          label: (
                            <Badge color="blue" count={0} offset={[10, 0]}>
                              <span>Status Timeline</span>
                            </Badge>
                          ),
                          key: "5",
                          //   children: <Checklist/>,
                        },
                        {
                          label: (
                            <Badge color="blue" count={0} offset={[10, 0]}>
                              <span>Activity Stream</span>
                            </Badge>
                          ),
                          key: "6",
                            children: <ActivityStreamTab pipelineId={viewPipelineData[0]?.PipelineROWID} module="PIPELINE" />,
                        },
                      ]}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </AntModal>
    </div>
  );
};

export default ViewPipelines;
