import httpService from "../../services/http.service";
import axios from "axios";
import Config from '../../config.json'

class WorkdriveService {

    async DownloadECBViolationFile(value) {
        return new Promise(async (resolve, reject) => {
            await axios.put(Config.backend_url+'downloadECBViolation', {value:value})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })


    }
    async GetFiles(folderId) {
        return new Promise(async (resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + folderId + "/files",
                headers: { "Authorization": localStorage.getItem('token') },
            }
             await httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })


    }


    DeleteMultipleFile (files) {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Delete",
                data: files,
                url: "https://www.zohoapis.com/workdrive/api/v1/files",
                method: "PATCH",
                headers: { "Authorization": localStorage.getItem('token') },
            };
            httpService.post('workdrive', config)
                .then(function (response) {
                    let arr = [];
                    if (response.data) {
                        response.data.data.forEach((file) => {
                            arr.push({
                                attributes: {
                                    status: "61"
                                },
                                id: file.id,
                                type: "files"
                            })
                        })
                        return new WorkdriveService().PermanentDeleteFile(arr);
                    }else{
                        return {data:[]}
                    }
                   
                })
                .then((result) => {
                    resolve(result);
                })
                .catch(function (error) {
                    reject(error);
                });
        })

    }

    PermanentDeleteFile  (files) {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Delete",
                data: files,
                url: "https://www.zohoapis.com/workdrive/api/v1/files",
                method: "PATCH",
                headers: { "Authorization": localStorage.getItem('token') },
            };
            httpService.post('workdrive', config)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        })

    }

    CopyMultipleFile(files, destinationId) {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Copy",
                data: files,
                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + destinationId + "/copy",
                method: "POST",
                headers: { "Authorization": localStorage.getItem('token') },
            };
            httpService.post('workdrive', {config : config})
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        })

    }


    async handleWorkdriveUploadFile(name,file,folderId) {
         
        let p =await new Promise(async(resolve, reject) => {
            var config = {
                Type: "Upload",
                data: {
                    filename: name.toUpperCase(),
                    content: file,
                    parent_id: folderId,
                },
                url: "https://www.zohoapis.com/workdrive/api/v1/upload",
                method: "POST",
                headers: { 
                    "Authorization": localStorage.getItem('token') ,
                },
                maxContentLength: 100000000,
                maxBodyLength: 1000000000
            };
            await axios.post(Config.backend_url+"workdrive", {config:config})
                .then(function (response) {
                   resolve(response)
                })
                .catch(function (error) {
                    reject(error);
                });
        });
        return p

    }
}

export default new WorkdriveService();