import httpService from "../../services/http.service";

class LeaveService{
    
    GetLeaveTypes(userId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://people.zoho.com/people/api/leave/getLeaveTypeDetails?userId="+userId+"",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

   

}

export default new LeaveService();