import React, {
    Component,
    forwardRef,
    useState,
    useRef,
    useEffect,
    useImperativeHandle,
  } from 'react';
  
  import DatePicker from 'react-datepicker';
  import './CustomEditorComponent.css'
  import 'react-datepicker/dist/react-datepicker.css';
  import dayjs from 'dayjs';
  const CustomEditorComponent = forwardRef((props, ref) => {
    const refDatePicker = useRef();
    const [date, setDate] = useState(dayjs(props.value, 'MM DD YYYY').toDate());
    const [editing, setEditing] = useState(true);
  
    useEffect(() => {
      if (!editing) {
        props.api.stopEditing();
      }
    }, [editing]);
  
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return dayjs(date).format('MM/DD/YYYY');
        },
      };
    });
  
    const onChange = (selectedDate) => {
      setDate(selectedDate);
      setEditing(false);
    };
  
    return (
      <div>
        <DatePicker
          ref={refDatePicker}
          portalId="root"
          popperClassName="ag-custom-component-popup"
          selected={date}
          dateFormat="MM/dd/yyyy"
          onChange={onChange}
        />
      </div>
    );
  });
  
  export default CustomEditorComponent;
  