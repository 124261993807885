import React, { useState, useEffect } from "react";
//import PVManufacturerFormSubheader from './pvmanufacturerFormSubheader';
import { Col,
    Form,
    Collapse,
    Input, Row, Container,FormGroup,ListGroupItem, Label, ModalBody, Modal, Button} from 'reactstrap';
// import { useHistory, withRouter,useLocation } from 'react-router-dom';
import HttpService from "../../../../../../services/http.service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import swal from "sweetalert";
import './CustomeDatepicker.css';
import Config from '../../../../../../config.json'
import Loader from "../../../../../../common/loader";
import ReactInputMask from "react-input-mask";


    const formInputs = 
    {
     Name:"",
      manufacturer: "",
      phoneNumber: "",
      faxNumber: "",
      streetAdd: "",
      city:"",
      state: "",
      zipCode: "",
      emailAdd: "",
      website: "",
    }
    
 function ManufacturerModal(props) {

    const [formInputsValue, setFormInputsValue] = useState(formInputs);
    const [inverters, setInverters] = useState([]);
    const [phoneError, setPhoneError] = useState(false);
    const [faxError, setFaxError] = useState(false);
    // const history = useHistory();
    // const location = useLocation();
    const [loader,setLoader]=useState(false);

    const [func, setFunc] = useState("create");
    const [detail, setDetail] = useState([]);

    const handleChange=(e)=>{
      e.target.value=e.target.value.trimStart();
      if(e.target.name == "phoneNumber" || e.target.name == "faxNumber" || e.target.name == "zipCode"){
        e.target.value = e.target.value.replace(/\D/g, '')
      }
        setFormInputsValue({
            ...formInputsValue,
            [e.target.name] : e.target.value
        })
    }

    const checkNumbers=(e)=>{

      if(e.target.name=='faxNumber'){
         
        let FaxNumber= e.target.value.replace(/\D/g,'');
        if(FaxNumber.length<=9){
          setFormInputsValue({
            ...formInputsValue,
            [e.target.name] : FaxNumber
        })
        }else{
          return
        }
      }else{
           
          var value1= e.target.value.replace(/[-#*$+/?=%() ]/g,'')
          let onlyNo= value1.replace(/\D/g, '');
          if(onlyNo.length>10){
            return
          }  
          if(onlyNo.length==10){
            setValidations({...validations,phoneNumber:'1'})
          }  
          setFormInputsValue({
            ...formInputsValue,
            [e.target.name] : e.target.value
        })
      }
    }
  
    function removeFormatter(phoneNumber) {
      phoneNumber= phoneNumber.replace(/\D/g,'');
      if(phoneNumber.length !== 10) {
        if(phoneNumber.includes("(")) {
          phoneNumber = phoneNumber.replace("(","");
          phoneNumber = phoneNumber.replace("-","");
          phoneNumber = phoneNumber.replace(")","");
        }       
      }
      return phoneNumber;
    }

    let valid=(emailtext)=>{
      for(let i=1;i<emailtext.length;i++){
        if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
          return false;
        }
      }
      return true;
    }
  
    const validatePhoneNmuber=(e)=>{
      let value1= e.target.value.replace(/[-#*$+/?=%() ]/g,'')
      let onlyNo= value1.replace(/\D/g, '');
      
      if(onlyNo.length==0){
        setValidations({...validations,phoneNumber:'-1'})
      }  
      
      if (onlyNo.length < 10 && 
        onlyNo.length != 0) {
        setValidations({...validations,phoneNumber:'-1'})
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }
  
    const validateFaxNumber=(e)=>{
      
      if(formInputsValue.faxNumber.trim()==""){
        setValidations({...validations,faxNumber:'-1'})
      }else if(formInputsValue.faxNumber.length < 9 && 
          formInputsValue.faxNumber.length != 0) {
              setValidations({...validations,faxNumber:'-1'})
          setFaxError(true);
      } else {
          setValidations({...validations,faxNumber:'1'})
          setFaxError(false);
      }
    }
  

    const changeIntoDecimal = (e)=>{
      if(e.target.value!=""){
        setFormInputsValue({
          ...formInputsValue,
          [e.target.name] : parseFloat(e.target.value).toFixed(2)
      })
      }
    }
    
  //   useEffect(()=>{
  //     if(location.state&&location.state.action!='create') {
  //       setDetail(location.state.detail);
  //       setFormInputsValue({
  //         ...formInputsValue,
  //         _id: location.state.detail._id,
  //         manufacturer:location.state.detail.manufacturer,
  //         phoneNumber: location.state.detail.phoneNumber,
  //         faxNumber: location.state.detail.faxNumber,
  //         streetAdd: location.state.detail.streetAdd,
  //         city:location.state.detail.city,
  //         state: location.state.detail.state,
  //         zipCode: location.state.detail.zipCode,
  //         emailAdd:location.state.detail.emailAdd,
  //         website: location.state.detail.website,
  //     })
  //     setFunc("update")
  //     }else{
  //       setFunc("create")
  //     }
  // }, [location])

    const changePositiveValue=(e)=>{
       if(e.target.value<0){
           e.target.value= e.target.value* -1;
       } 
    }

   const resetPvManufacturer=()=>{
      setFormInputsValue({
        ...formInputsValue,
        Name: "",
        manufacturer: "",
        phoneNumber: "",
        faxNumber: "",
        streetAdd: "",
        city:"",
        state: "",
        zipCode: "",
        emailAdd: "",
        website: ""
      })
    }

    const createPVManufacturer = async () => {
      let vKeys=Object.keys(validations);
      for(let i=0;i<vKeys.length;i++){
        if(validations[vKeys[i]]=='-1' || validations[vKeys[i]]=='0'){
          alert("Please fill all required fields!")
          highliteEmptys({arr:validations,obType:'validations'})
          return
        }
      }
      setLoader(true)
      if(props.manufacturerName.includes(formInputsValue.Name)){
          swal("Duplicate record")
      }else
      if(formInputsValue.Name && formInputsValue.city && formInputsValue.emailAdd &&
        formInputsValue.phoneNumber && formInputsValue.state && formInputsValue.streetAdd && formInputsValue.zipCode){
        let bacUrl = Config.backend_url
      var config = {
        Type: "Create",
        data: [formInputsValue],
        url: "https://www.zohoapis.com/crm/v2/Photovoltaic_Manufacturer",
        method: "POST",
        headers: {
            'Authorization': localStorage.getItem('token')
        }
      }
      let returnData = await axios.post(bacUrl + "workdrive", { config: config }).then(res =>  {
        console.log(res.data);
        if(res.data){
          setLoader(false)
          resetPvManufacturer();
          props.setManufacturerModal(false);
          props.setReloadManufacturerData(true);
          props.getManufacturerName();
          props.setManufacturer(formInputsValue.Name)
        }
        else {
          setLoader(false)
          swal("Server Issue: Unable to add PV module.");
        }
      }).catch((err)=>{
        setLoader(false)
        console.log(err);
      })
      }else{
        setLoader(false)
        swal("Please fill all mandatory fields!")
      }
      
  }

  const createPVManufacturerZoho = async () => {
    let obj = {
      "Name": formInputsValue.Name,

    }
    window.ZOHO.CRM.API.insertRecord({ Entity: "Photovoltaic_Manufacturer", APIData: obj })
      .then(function (data) {
        console.log(data);
        props.setManufacturer({ id: data.data[0].details.id, module: "Photovoltaic_Manufacturer", name: formInputsValue.Name });
        resetPvManufacturer();
        props.setManufacturerModal(false);
        props.setReloadManufacturerData(true);
        props.getManufacturerName()
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  //Hrushikesh shelke new code starts
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const[validations,setValidations]=useState({
    Name:'0',
    faxNumber:'0',
    phoneNumber:'0',
    streetAdd:'0',
    city:'0',
    state:'0',
    zipCode:'0',
    emailAdd:'0',
    website:'0'
  });
  const resetPvSystemInfoModal=(data)=>{
     
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      ob[vKeys[i]]='0'
    }
    if(data.obType=='validations'){
      setValidations(ob)
    }
  }
  const highliteEmptys=(data)=>{
     
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
    if(data.obType=='validations'){
      setValidations(ob)
    }
  }

  let handlemailid = (email) => {     
    // Email validation
    let validMail=false;
    let str=email.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
      }
    return validMail;
  }
  //Hrushikesh shelke new code ends
  
  return(
    <div className="overflow-hidden">
        <Modal
            className="modalwidth-400 overflow-hidden"
            isOpen={props.manufacturerModal}
            //backdrop={backdrop}
            //toggle={toggleManufacturer}
        >
          <div className="modal-header-class">
            PV Manufacturer Information  
            <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={()=>{
                resetPvSystemInfoModal({arr:validations,obType:'validations'});
                setFormInputsValue(formInputs);
                props.setManufacturerModal(!props.manufacturerModal)
              }}
            ></FontAwesomeIcon>
          </div>  
          </div>
            <ModalBody className="">
            <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownerlastname">
                          Manufacturer Name<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                            type="text"
                            name="Name" 
                            id="ownerlastname"
                            className="createdeal-textfield"
                            value={formInputsValue.Name}
                            onChange={(e) =>{
                              e.target.value=e.target.value.trimStart()
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,Name:'-1'})
                              }else{
                                setValidations({...validations,Name:'1'})
                              }
                              handleChange(e)}}
                            style={validations.Name=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                               
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,Name:'-1'})
                              }else{
                                setValidations({...validations,Name:'1'})
                              }
                            }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
            {/* <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          Manufacturer<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={6}>
                        <Input
                            type="text"
                            name="manufacturer" 
                            id="ownerlastname"
                            className="createdeal-textfield"
                            value={formInputsValue.manufacturer}
                            onChange={(e) => handleChange(e)}
                        />
                      </Col>
                      <Col sm={2}></Col>
                    </Row>
                  </FormGroup> */}
                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          Phone Number<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                      <ReactInputMask
                          type="text"
                          mask="(999) 999-9999"
                          maskChar='_'
                          name="phoneNumber"
                          className="form-control createdeal-textfield"
                            value={formInputsValue.phoneNumber}
                            onChange={(e) => {                    
                              checkNumbers(e)}}
                            style={validations.phoneNumber=='-1'?{...validationCss}:{}}
                            onBlur={(e) =>{
                              validatePhoneNmuber(e)}}
                  
                         />
                         <span className={phoneError == true ? "red-text font12" : "error-display"} >
                            Enter 10 digit Phone number
                         </span>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          Fax Number<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input 
                           type="text"
                           name="faxNumber"
                           className="createdeal-textfield"
                           value={formInputsValue.faxNumber}
                           onChange={(e) =>{
                            e.target.value=e.target.value.trimStart()
                            if(e.target.value.length==9){
                              setValidations({...validations,faxNumber:'1'})
                            }
                            checkNumbers(e)}}
                           style={validations.faxNumber=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                               
                              validateFaxNumber(e)
                            }}
                           maxLength="10"
                        />
                        <span
                         className={faxError == true ? "red-text font12" : "error-display"}>
                             Enter 9 digit Fax Number
                      </span>
                      </Col>
                     
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          Street Address<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input 
                            type="text"
                            name="streetAdd"
                            className="createdeal-textfield"
                            value={formInputsValue.streetAdd}
                            onChange={(e) =>{
                              e.target.value=e.target.value.trimStart()
                              handleChange(e)}}
                            style={validations.streetAdd=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                               
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,streetAdd:'-1'})
                              }else{
                                setValidations({...validations,streetAdd:'1'})
                              }
                            }}
                        />
                      </Col>
                     
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          City<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input
                            type="text"
                            name="city"
                            className="createdeal-textfield"
                            value={formInputsValue.city}
                            onChange={(e) =>{
                              e.target.value=e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                              e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,city:'-1'})
                              }else{
                                setValidations({...validations,city:'1'})
                              }
                              handleChange(e)}}
                            style={validations.city=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                               
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,city:'-1'})
                              }else{
                                setValidations({...validations,city:'1'})
                              }
                            }}
                            //onBlur={(e) => validateFaxNumber(e)}
                        />
                      </Col>
                     
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          State<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input 
                           type="text"
                           name="state"
                           className="createdeal-textfield"
                           value={formInputsValue.state}
                           onChange={(e) =>{
                            e.target.value=e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                            if(e.target.value.trim()=='' || e.target.value==undefined){
                              setValidations({...validations,state:'-1'})
                            }else{
                              setValidations({...validations,state:'1'})
                            }
                            handleChange(e)}
                          }
                           style={validations.state=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                               
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,state:'-1'})
                              }else{
                                setValidations({...validations,state:'1'})
                              }
                            }}
                        />
                      </Col>
                      
                    </Row>
                  </FormGroup>

                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          Zip Code<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input 
                            type="text"
                            name="zipCode"
                            className="createdeal-textfield"
                            value={formInputsValue.zipCode}
                            maxLength={5}
                            onChange={(e) => {
                              if(e.target.value.length<5){
                                  setValidations({...validations,zipCode:'-1'})
                              }else{
                                  setValidations({...validations,zipCode:'1'})      
                              }
                              handleChange(e)}}
                            style={validations.zipCode=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                               
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,zipCode:'-1'})
                              }
                            }}
                        />
                      </Col>
                     
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          Email Address<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input 
                            type="text"
                            name="emailAdd"
                            className="createdeal-textfield"
                            value={formInputsValue.emailAdd}
                            onChange={(e) =>{
                              if(e.target.value.trim()!='' && e.target.value!=undefined){
                                let isMailValid=handlemailid(e.target.value);
                                if(isMailValid){
                                  setValidations({...validations,emailAdd:'1'})
                                }else{
                                  setValidations({...validations,emailAdd:'-1'})
                                }
                              }
                              handleChange(e)}}
                            style={validations.emailAdd=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                              if(e.target.value.trim()!='' && e.target.value!=undefined){
                                let isMailValid=handlemailid(e.target.value);
                                if(isMailValid){
                                  setValidations({...validations,emailAdd:'1'})
                                }else{
                                  setValidations({...validations,emailAdd:'-1'})
                                }
                              }else{
                                setValidations({...validations,emailAdd:'-1'})
                              }
                            }}
                        />
                      </Col>
                     
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16  padtop-15">
                    <Row>
                      <Col sm={4}>
                        <Label className="text-bold" for="ownerlastname">
                          Website<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Input 
                            type="text"
                            name="website"
                            className="createdeal-textfield"
                            value={formInputsValue.website}
                            onChange={(e) => {
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,website:'-1'})
                              }else{
                                setValidations({...validations,website:'1'})
                              }
                              handleChange(e)}}
                            style={validations.website=='-1'?{...validationCss}:{}}
                            onBlur={(e)=>{
                               
                              if(e.target.value.trim()=='' || e.target.value==undefined){
                                setValidations({...validations,website:'-1'})
                              }else{
                                setValidations({...validations,website:'1'})
                              }
                            }}
                        />
                      </Col>
                     
                    </Row>
                  </FormGroup>

                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Button
                          className="continue-button"
                          onClick={()=>{
                            resetPvSystemInfoModal({arr:validations,obType:'validations'});
                            setFormInputsValue(formInputs);
                            props.setManufacturerModal(false)
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          className="continue-button"
                          onClick={()=>createPVManufacturer()}
                        >
                          Save
                        </Button>
                      </Col>
                      
                    </Row>
                  </FormGroup>
            </ModalBody>
        </Modal>
    </div>
  )
}
export default ManufacturerModal;