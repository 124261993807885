import EmailService from "../../common/util/EmailService";
import { modifyTimezoneString } from "../HR/TimeClock/Util";

export function getEmailsFromString(str, allUsers) {

    return new Promise((resolve, reject) => {
        try {
            let arr = [];
            if (str) {
                let rawArr = str.split(",");
                rawArr?.forEach((e,i) => {
                    let temp = e.split(";");
                    if (temp?.length > 0) {
                        let eStr=temp.filter(s=>s.includes("&gt"))
                        let obj={}
                        if(eStr?.length==2){
                            obj = {
                                name: eStr[0].replaceAll("&quot", "").replaceAll("&gt", ""),
                                email: eStr[1].replaceAll("&quot", "").replaceAll("&gt", ""),
                            }
                        }else if(eStr?.length==1){
                            obj = {
                                name: eStr[0].replaceAll("&quot", "").replaceAll("&gt", ""),
                                email: eStr[0].replaceAll("&quot", "").replaceAll("&gt", ""),
                            }
                        }
                        let isPresent = allUsers.find(k => k.email == obj.email);
                        if (isPresent) {
                            obj["status"] = true;
                            obj["id"] = isPresent.id;
                        } else {
                            obj["status"] = false;
                            obj["id"] = i;
                        }
                        arr.push(obj)
                    }
                })
            }
            
            resolve(arr);
        } catch (err) {
            reject(err);
        }
    })
}
export function getVerifiedMAilObject(email, allUsers) {

    return new Promise((resolve, reject) => {
        try {
            let obj = {};
            if (email) {
                let isPresent = allUsers.find(k => k.email == email);
                if (isPresent) {
                    obj["email"]=isPresent.email
                    obj["status"] = true;
                    obj["id"] = isPresent.id;
                } else {
                    obj["email"]=email;
                    obj["status"] = false;
                    obj["id"] = 0;
                }
            }
            
            resolve([obj]);
        } catch (err) {
            reject(err);
        }
    })
}
export function buildFolderHierarchy(data) {
    // Create a map to store folders by their IDs
    const folderMap = new Map();
    // Create a result array to hold the top-level folders
    const result = [];

    // Iterate through each folder
    data.forEach(folder => {
        // Add a subFolders array to each folder to hold its subfolders
        folder.subFolders = [];

        // Add the folder to the map
        folderMap.set(folder.folderId, folder);

        // If the folder has a parentFolderId, add it to the parent folder's subFolders array
        if (folder.parentFolderId) {
            const parentFolder = folderMap.get(folder.parentFolderId);
            if (parentFolder) {
                parentFolder.subFolders.push(folder);
            }
        } else {
            // If the folder has no parentFolderId, add it to the top-level result array
            result.push(folder);
        }
    });

    return result;
}
export function findItemsInEmail(data,AllDataForAssociation) {
    return new Promise((resolve,reject)=>{
        const { email, ...items } = data;
        // Convert email fields to lowercase for case-insensitive comparison
        const emailContent = [
            email?.subject?.toLowerCase(),
            email?.content?.toLowerCase(),
            email?.from?.toLowerCase(),
            email?.to?.toLowerCase()
        ];
        // Helper function to check if any of the email fields contain the term
        function isPresent(term) {
            return emailContent.some(field => field?.toLowerCase()?.includes(term?.toLowerCase()));
        }
        // Iterate over each type of item (deals, accounts, etc.)
        const result = {};
        for (const [key, values] of Object.entries(items)) {
            const uniqueValues = [...new Set(values.filter(value => isPresent(value)))];
            result[key] = uniqueValues;
        }
        resolve(result);
    })
}
export const getHeaderStatement = (data) => {
    if (data.reminderType == "At specific time") {
        return { text: `You have set reminder on an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 1 }
    } else if (data.reminderType == "On every reply") {
        return { text: `You have set reminder on every reply for an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""}`, status: 2 }
    } else if (data.reminderType == "If no replies until") {
        return { text: `You have set reminder on an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} for reply till - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 3 }
    }
}
export const sendEmailNotification= async (reminder,to)=>{
    let obj={ fromAddress: "info@srsolardesign.com", askReceipt: 'yes' };
    obj.toAddress=to;
    obj.mailFormat = "html";
    obj.subject = `Reminder:"${getHeaderStatement(reminder)?.text}" `;
    obj.content = `
        Heyy!
            ${getHeaderStatement(reminder)?.text}
    `
    await EmailService.SendEmail("6534405000000008002", obj).then(res=>{
        
    }).catch(e=>{
        console.log(e);
    })
}


