import React, { useEffect, useState } from 'react'
import './DayView.css';
import { DayRender } from './DayRender';
import AntdRangePicker from "../../../../common/DateRangePicker";
import {
  Col,
  Row
} from "reactstrap";
import { addTimeStrings, getDateFromWeekAndDay } from '../Util';
import moment, { months } from "moment";
import _ from 'lodash';
import dayjs from 'dayjs';
export const DayView = (props) => {
  const newData = {
    "Sunday": [],
    "Monday": [],
    "Tuesday": [],
    "Wednesday": [],
    "Thursday": [],
    "Friday": [],
    "Saturday": [],
  };

  const getStartDate = () => {
    let startDate = new Date(Date.now());
    startDate.setDate(1);
    startDate.setHours(0, 0, 0);
    return startDate;
  }
  const getEndDate = () => {
    let endDate = new Date(Date.now());
    endDate.setDate(lastDateOFMonth(new Date().getFullYear(), new Date().getMonth()));
    endDate.setHours(0, 0, 0);
    return endDate;
  }
  function formatDate(dateObj) {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return dateObj.toLocaleDateString('en-US', options);
  }
  const [userRowData, setUserRowData] = useState({});
  const [cacheUserRowData, setCacheUserRowData] = useState({});
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());

 
  useEffect(e => {
     
    if (props.cacheData && props.selectedUser?.id != "all") {
      let newFilteredData = {};
      Object.keys(props.cacheData).forEach((key, i) => {
        let temp = {};
        Object.keys(props.cacheData[key]).forEach((key2, i2) => {
          let userData = props.cacheData[key][key2].filter(one => one?.email == props.selectedUser?.email)
          temp[key2] = userData;
        })
        if (Object.keys(temp).find(d => temp[d].length > 0)) {
          newFilteredData[key] = temp;
        }
      })
      setCacheUserRowData({...newFilteredData})
    }
    setStartDate(getStartDate())
    setEndDate(getEndDate())
  }, [props.selectedUser,props.cacheData])
  useEffect(e=>{
    if(cacheUserRowData){
      applyFilterOnData(new Date(dayjs().startOf("month")),new Date(dayjs().endOf("month"))).then(data=>{
        setUserRowData(data);
      });
    }
  },[cacheUserRowData])
  const calculateWeedData = (weekNumber, all) => {
     
    let totalHours = "00:00";
    if (all) {
      Object.keys(userRowData)?.map((week, i) => {
        Object.keys(userRowData[week])?.map((day, i) => {
          if (userRowData[week][day]) {
            userRowData[week][day].forEach(d => {
              totalHours = addTimeStrings(totalHours, d.TotalHours)
            })
          }
        })
      })
      return (<div className='Day-Wrapper Table-Footer'>
        <Col className='custome-col' sm={2}>
          Total
        </Col>
        <Col sm={10}>
          <Row className='custome-row' sm={12}>
            <Col className='custome-col' sm={2}></Col>
            <Col className='custome-col' sm={2}></Col>
            <Col className='custome-col' sm={2}></Col>
            <Col className='custome-col' sm={2} style={{ marginLeft: "-0.4rem" }}>{totalHours}</Col>
            <Col className='custome-col' sm={4}></Col>
          </Row>
        </Col>
      </div>)
    } else {
      Object.keys(userRowData[weekNumber])?.map((day, i) => {
        if (userRowData[weekNumber][day]) {
          userRowData[weekNumber][day].forEach(d => {
            let thisDate = getDateFromWeekAndDay(parseInt(weekNumber.split("-")[1]), parseInt(weekNumber.split("-")[0]), day)
            if((dayjs(startDate).isBefore(thisDate) || dayjs(startDate).isSame(thisDate)) && 
            (dayjs(endDate).isAfter(thisDate) || dayjs(endDate).isSame(thisDate))){
              totalHours = addTimeStrings(totalHours, d.TotalHours)
            }
          })
        }
      })
      return (<div className={`Day-Wrapper Total-Wrapper`}>
        <Col className={`custome-col Day`} sm={2}>
          {`Total :`}
        </Col>
        <Col className='Day-Row-Wrapper' sm={10}>
          <Row className='custome-row' sm={12}>
            <Col className='custome-col' sm={2}></Col>
            <Col className='custome-col' sm={2}></Col>
            <Col className='custome-col' sm={2}></Col>
            <Col className='custome-col' sm={2}>{totalHours}</Col>
            <Col className='custome-col' sm={4}></Col>
          </Row>
        </Col>
      </div>)
    }
  }
  function lastDateOFMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }


  
  // Helper function to get the ISO week number for a given date
  function getISOWeek(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const millisecondsInDay = 86400000; // 24  60  60 * 1000
    const dayOfYear = Math.floor((date - firstDayOfYear) / millisecondsInDay);
    const weekNumber = Math.ceil((dayOfYear + firstDayOfYear.getDay() + 1) / 7);
  
    return weekNumber;
  }
  const applyFilterOnData=(startDate,endDate)=>{
     
    return new Promise((resolve,reject)=>{
      try{
        const yearWeekNumbers = {};
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          const year = currentDate.getFullYear();
          const weekNumber = getISOWeek(currentDate);
          let key=year+"-"+weekNumber;
          if(cacheUserRowData[key]){
            yearWeekNumbers[key]=cacheUserRowData[key];
          }else{
            yearWeekNumbers[key]={
              "Sunday":[],
              "Monday":[],
              "Tuesday":[],
              "Wednesday":[],
              "Thursday":[],
              "Friday":[],
              "Saturday":[],
            };
          }
          currentDate.setDate(currentDate.getDate() + 7);
        }
        console.log(yearWeekNumbers);
        resolve(yearWeekNumbers);
      }catch(error){
        reject(error);
      }
    })
  }
  function sumTotalHours(totalHoursArray) {
    let totalMinutes = 0; 
    totalHoursArray.forEach(hoursString => {
      if (typeof hoursString === 'string') {
        const [hours, minutes] = hoursString.split(":").map(Number);
        totalMinutes += hours * 60 + minutes;
    } else {
        console.error("Invalid total hours format:", hoursString);
    }
    }); 
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60; 
    const formattedResult = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
    
    return formattedResult;
}
 
  const onChangeDateRange =async (dates, datesString) => {
    
    if (datesString.length > 0) {
      let start = new Date(new Date(datesString[0]))
      let end = new Date(new Date(datesString[1]))
      let formattedStartDate = formatDate(datesString[0])
      let formattedEndDate = formatDate(datesString[1])
      if (formattedStartDate == formattedEndDate && dates == "Clear") {
        start=new Date(dayjs().startOf("month").format("MM/DD/YYYY"))
        end=new Date(dayjs().endOf("month").format("MM/DD/YYYY"))
      } 
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 0, 0);
      setStartDate(start);
      setEndDate(end);
      let newFilteredData={};
      if (props.cacheData) {
          await applyFilterOnData(start,end).then(data=>{
            newFilteredData=data;
          });
          setUserRowData(newFilteredData);
      }

    }

  }


  return (
    <div className='DayViewWrapper'>
      <div className='User-Header'>
        <Row className="m-1">
          <Col className='custome-col' sm={4} style={{ display: "flex", justifyContent: "start" }}>
            
            <AntdRangePicker
              onChangeDateRange={onChangeDateRange}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              allStart={props.cacheRowData[props.cacheRowData?.length-1]?.TimesheetDate} 
              allEnd={props.cacheRowData[0]?.TimesheetDate}
              style={{ marginLeft: "10px" }}
            />
          </Col>
          
          <Col className='custome-col User-Name-Box' sm={4}>
            <span className="User-Name-Header-Span">{props.selectedUser?.full_name && props.selectedUser.full_name}</span>
          </Col>
          <Col className='custome-col' sm={4} style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
            <div>
              {props.getSelectUserForDayView()}
            </div>
            <div>
              {props.getViewType()}
            </div>
          </Col>
        </Row>
      </div>
      
      <div className='Day-View-Content'>
        {_.isEmpty(userRowData) ? <div className='dataText'>No Data Available</div> : ''}
        {Object.keys(userRowData)?.map((week, k) => {
          let totalHours = "00:00";
          return <>
            <div className='Day-Wrapper Table-Headers'>
              <Col className='custome-col' sm={2}>
                {/* {/ Day /} */}
              </Col>
              <Col sm={10}>
                <Row className='custome-row' sm={12}>
                  <Col className='custome-col' sm={2}>Date</Col>
                  <Col className='custome-col' sm={2}>Check In</Col>
                  <Col className='custome-col' sm={2}>Check Out</Col>
                  <Col className='custome-col' sm={2}>Working Hours</Col>
                  <Col className='custome-col' sm={2}>Total Working Hours</Col>
                  <Col className='custome-col' sm={2}>Action</Col>
                </Row>
              </Col>
            </div>
          {}
            {Object.keys(userRowData[week])?.map((day, i) => { 
             const totalHoursArray = userRowData[week][day].map(entry => entry.TotalHours);
             const totalHoursByDay = sumTotalHours(totalHoursArray);
              let isThisWeekDay = false;
              let thisDate;
              try {
                thisDate = getDateFromWeekAndDay(parseInt(week.split("-")[1]), parseInt(week.split("-")[0]), day)
              } catch (error) {
              }
              if (dayjs(new Date()).format("MM/DD/YYYY") == thisDate) {
                isThisWeekDay = true;
              }
                
              if((dayjs(startDate).isBefore(thisDate) || dayjs(new Date(startDate)).format("MM/DD/YYYY")==thisDate) && 
              (dayjs(endDate).isAfter(thisDate) || dayjs(new Date(endDate)).format("MM/DD/YYYY")==thisDate)){
                // totalHours = addTimeStrings(totalHours, userRowData[week][day]?.TotalHours?userRowData[week][day].TotalHours:"00:00")
                return <DayRender currentDate={thisDate}
                  email={props.selectedUser?.email}
                  actionListCellRenderer={props.actionListCellRenderer}
                  isThisWeekDay={isThisWeekDay} 
                  date={thisDate}
                  incompleteColourFlag={props.selectedUser?.id == "all" ? false : true}
                  colourBand={i % 2 == 0 ? "EvenClass" : "OddClass"} 
                  Day={day}
                  dayData={userRowData[week][day]} 
                  totalHoursByDay={totalHoursByDay}
                  disableDates={props.disableDates}
                  />
              }
            })}
            {calculateWeedData(week,false)}
          </>
        })}

      </div>
      {calculateWeedData(null, true)}
    </div>
  )
}