
export const Coldefs = {
  'Pined_Column': [
    {
      field: 'Deal_Name',
      headerName: 'Deal Name',
      columnGroupShow: 'closed',
    },
  ],
  'Basic_Information': [
    {
      field: "Account_Name",
      headerName: "Account Name",
      columnGroupShow: "closed",
    },
    {
      field: "Closing_Date",
      headerName: "Closing Date",
      columnGroupShow: "closed",
    },],
  "Property_Location_Info": [
    {
      field: "Project_Address",
      headerName: "Project Address",
      columnGroupShow: "closed",
    },
    {
      field: "Project_Status",
      headerName: "Project Status",
      columnGroupShow: "open",
    },
    {
      field: 'House_Number',
      headerName: 'House Number',
      columnGroupShow: "open",
    },
    {
      columnGroupShow: 'open',
      field: 'Street_Name',
      headerName: 'Street Name',
    },
    {
      columnGroupShow: 'open',
      field: 'City',
    },
    {
      columnGroupShow: 'open',
      field: 'State',
    },
    {
      columnGroupShow: 'open',
      field: 'Zip_Code',
      headerName: 'Zip Code',
    },
    {
      columnGroupShow: 'open',
      field: 'Latitude',
    },
    {
      columnGroupShow: 'open',
      field: 'Longitude',
    },
    {
      columnGroupShow: 'open',
      field: 'Is_NYC_Project',
      headerName: 'NYC Project',
    },
    {
      columnGroupShow: 'open',
      field: 'Borough',
    },],
  "Services_Requested": [
    {
      columnGroupShow: 'closed',
      field: 'Service_Requested_Name',
      headerName: 'Service Requested Name',
    },
  ],

  'NYC_Property_Info': [
    {
      columnGroupShow: 'closed',
      field: 'Block_Number',
      headerName: 'Block Number',
    },
    {
      columnGroupShow: 'open',
      field: 'Lot_Number',
      headerName: 'Lot Number',
    },
    {
      columnGroupShow: 'open',
      field: 'Bin_Number',
      headerName: 'Bin Number',
    },
    {
      columnGroupShow: 'open',
      field: 'Community_Board_Number',
      headerName: 'Community Board Number',
    },
    {
      columnGroupShow: 'open',
      field: 'Occupancy_Classification_Building_2014_2008_Code',
      headerName: 'Occupancy Classification Building 2014 2008 Code',
    },
    {
      columnGroupShow: 'open',
      field: 'Occupancy_Classification_of_Building',
      headerName: 'Occupancy Classification of Building',
    },
    {
      columnGroupShow: 'open',
      field: 'Construction_Classification_Code_2014_2008_Code',
      headerName: 'Construction Classification Code 2014 2008 Code',
    },
    {
      columnGroupShow: 'open',
      field: 'Construction_Classification_Code',
      headerName: 'Construction Classification Code',
    },
    {
      columnGroupShow: 'open',
      field: 'Multiple_Dwelling_Classification',
      headerName: 'Multiple Dwelling Classification',
    },
    {
      columnGroupShow: 'open',
      field: 'Number_of_Dwelling_Units',
      headerName: 'Number of Dwelling Units',
    },
    {
      columnGroupShow: 'open',
      field: 'Building_Height',
      headerName: 'Building Height',
    },
    {
      columnGroupShow: 'open',
      field: 'Number_of_Stories',
      headerName: 'Number of Stories',
    },
    {
      columnGroupShow: 'open',
      field: 'Zoning_District',
      headerName: 'Zoning District',
    },
    {
      columnGroupShow: 'open',
      field: 'Commercial_Overlay',
      headerName: 'Commercial Overlay',
    },
    {
      columnGroupShow: 'open',
      field: 'Specials_Districts',
      headerName: 'Specials Districts',
    },
    {
      columnGroupShow: 'open',
      field: 'Zoning_Map_Number',
      headerName: 'Zoning Map Number',
    },
    {
      columnGroupShow: 'open',
      field: 'Tidal_Wetlands',
      headerName: 'Tidal Wetlands',
    },
    {
      columnGroupShow: 'open',
      field: 'Fresh_Water_Wetland',
      headerName: 'Fresh Water Wetland',
    },
    {
      columnGroupShow: 'open',
      field: 'Flood_Hazard',
      headerName: 'Flood Hazard',
    },
    {
      columnGroupShow: 'open',
      field: 'Coastal_Erosion_Hazard',
      headerName: 'Coastal Erosion Hazard',
    },
    {
      columnGroupShow: 'open',
      field: 'Special_Fire_District',
      headerName: 'Special Fire District',
    },
    {
      columnGroupShow: 'open',
      field: 'Landmark_Area',
      headerName: 'Landmark Area',
    },
    {
      columnGroupShow: 'open',
      field: 'Environmental_Restriction',
      headerName: 'Environmental Restriction',
    },],
  "Previous_Solar_Application_Info": [
    {
      columnGroupShow: 'closed',
      field: 'Previous_Solar_Info',
      headerName: 'Previous Solar Application',
      id : "1001",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Application_Number',
      filterAttribute:["Number","Solar_Columns"],
      type : "number",
      headerName: 'Application Number',
      rowGroupField:'Application_Number',
      objectKey:"Solar_Columns",
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1002",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Current_Application_Status',
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      headerName: 'Current Application Status',
      objectKey:"Solar_Columns",
      rowGroupField:'Current_Application_Status',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1003",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Application_Supersede_Required',
      objectKey:"Solar_Columns",
      headerName: 'Application Supersede Required',
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      rowGroupField:'Application_Supersede_Required',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1004",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Current_Applicant',
      headerName: 'Current Applicant',
      filterAttribute:["String","Solar_Columns"],
      objectKey:"Solar_Columns",
      type : "string",
      rowGroupField:'Current_Applicant',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1005",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'GC_Supersede_Required',
      headerName: 'GC Supersede Required',
      objectKey:"Solar_Columns",
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      rowGroupField:'GC_Supersede_Required',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1006",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'GC_Company_Name',
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      headerName: 'GC Company Name',
      objectKey:"Solar_Columns",
      rowGroupField:'GC_Company_Name',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1007",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'GC_Name',
      headerName: 'GC Name',
      filterAttribute:["String","Solar_Columns"],
      objectKey:"Solar_Columns",
      type : "string",
      rowGroupField:'GC_Name',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1008",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'GC_License_Number',
      filterAttribute:["Number","Solar_Columns"],
      type : "number",
      headerName: 'GC License Number',
      rowGroupField:'GC_License_Number',
      objectKey:"Solar_Columns",
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1009",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Reinstatement_Required',
      headerName: 'Reinstatement Required',
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      objectKey:"Solar_Columns",
      rowGroupField:'Reinstatement_Required',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1010",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Inspection_Passed',
      headerName: 'Inspection Passed',
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      rowGroupField:'Inspection_Passed',
      objectKey:"Solar_Columns",
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1011",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Inspection_Passed_Date',
      headerName: 'Inspection Passed Date',
      filterAttribute:["Date","Solar_Columns"],
      type : "date",
      objectKey:"Solar_Columns",
      rowGroupField:'Inspection_Passed_Date',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1012",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Withdrawal_Required',
      headerName: 'Withdrawal Required',
      filterAttribute:["String","Solar_Columns"],
      objectKey:"Solar_Columns",
      type : "string",
      rowGroupField:'Withdrawal_Required',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1013",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Installation_Completed',
      headerName: 'Installation Completed',
      filterAttribute:["String","Solar_Columns"],
      objectKey:"Solar_Columns",
      type : "string",
      rowGroupField:'Installation_Completed',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1014",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Installation_Completed',
      headerName: 'Installation Completed',
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      objectKey:"Solar_Columns",
      rowGroupField:'Installation_Completed',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1015",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Inspection_Ready_Email_Address',
      headerName: 'Inspection Ready Email Address',
      objectKey:"Solar_Columns",
      filterAttribute:["String","Solar_Columns"],
      type : "string",
      rowGroupField:'Inspection_Ready_Email_Address',
      collapsGroup:'Previous_Solar_Application_Info',
      id : "1016",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
  ],
  "Additional_Nyc_Filling_Info": [
    {
      columnGroupShow: 'closed',
      field: 'Conjunction',
    },
    // {
    //   columnGroupShow: 'open',
    //   field: 'Conjunction_Application_Number',
    //   headerName: 'Conjunction Application Number',
    // },
    {
      columnGroupShow: 'open',
      field: 'Stop_Work_Order_Exists_on_Property',
      headerName: 'Stop Work Order Exists on Property',
    },
  ],
  "WWP_Violation_Info": [
    {
      columnGroupShow: 'closed',
      field: 'Active_Work_Without_Permit_Violations',
      headerName: 'Active Work Without Permit Violations',
      id : "1017",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Violation_Number',
      rowGroupField:'Violation_Number',
      type : "string",
      filterAttribute:["String","Violation_Columns"],
      collapsGroup:'Active_Work_Without_Permit_Violations',
      headerName: 'Violation Number',
      id : "1018",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Violation_Type',
      filterAttribute:["String","Violation_Columns"],
      type : "string",
      rowGroupField:'Violation_Type',
      collapsGroup:'Active_Work_Without_Permit_Violations',
      headerName: 'Violation Type',
      id : "1019",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'L2_Code',
      rowGroupField:'L2_Code',
      type : "string",
      filterAttribute:["String","Violation_Columns"],
      collapsGroup:'Active_Work_Without_Permit_Violations',
      headerName: 'L2 Code',
      id : "1020",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Civil_Penalty_Payment_Required',
      rowGroupField:'Civil_Penalty_Payment_Required',
      type : "string",
      filterAttribute:["String","Violation_Columns"],
      collapsGroup:'Active_Work_Without_Permit_Violations',
      headerName: 'Civil Penalty Required',
      id : "1021",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Paid_Under_Job_Number',
      rowGroupField:'Paid_Under_Job_Number',
      type : "string",
      filterAttribute:["String","Violation_Columns"],
      collapsGroup:'Active_Work_Without_Permit_Violations',
      headerName: 'Paid Job Number',
      id : "1022",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Invoice_Number',
      rowGroupField:'Invoice_Number',
      type : "string",
      filterAttribute:["String","Violation_Columns"],
      collapsGroup:'Active_Work_Without_Permit_Violations',
      headerName: 'Invoice Number',
      id : "1023",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    
  ],
  "Asbestos_Investigation": [
    {
      columnGroupShow: 'closed',
      field: 'Asbestos_Required',
      headerName: 'Asbestos Required',
    },
    {
      columnGroupShow: 'open',
      field: 'ACP5_Completed',
      headerName: 'ACP5 Completed',
    },
    {
      columnGroupShow: 'open',
      field: 'Asbestos_Inspector_Certificate_Number',
      headerName: 'Asbestos Inspector Certificate Number',
    },
    {
      columnGroupShow: 'open',
      field: 'ACP5_Control_Number',
      headerName: 'ACP5 Control Number',
    },
    {
      columnGroupShow: 'open',
      field: 'Certificate_of_Occupancy_Available',
      headerName: 'Certificate of Occupancy Available',
    },
    {
      columnGroupShow: 'open',
      field: 'NB_Application_Number',
      headerName: 'NB Application Number',
    },
  ],
  'Property_Owner_Info': [
    {
      field: "Owner_Type",
      headerName: "Owner Type",
      columnGroupShow: "closed",
    }
    , {
      field: "Property_Owner_s_Name",
      headerName: "Property Owner's Name",
      columnGroupShow: "open",
    },],
  "Home_Owner_Info": [
    {
      field: "Owner_s_First_Name",
      headerName: "Owner's First Name",
      columnGroupShow: "closed",
    }
    , {
      field: "Owner_s_Middle_Initial",
      headerName: "Owner's Middle Initial",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_s_Last_Name",
      headerName: "Owner's Last Name",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_Phone_Number",
      headerName: "Owner Phone Number",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_s_Email_Address",
      headerName: "Owner's Email Address",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_s_Mailing_Address_Diff_Than_Property_Address",
      headerName: "Owner's Mailing Address Diff Than Property Address",
      columnGroupShow: "open",
    }],
  "Homeowner_s_Mailing_Address_Info": [
    {
      field: "Owner_s_Mailing_Address",
      headerName: "Owner's Mailing Address",
      columnGroupShow: "closed",
    }
    , {
      field: "Owner_s_Street_Name",
      headerName: "Owner's Street Name",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_s_House_Number",
      headerName: "Owner's House Number",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_s_City",
      headerName: "Owner's City",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_s_State",
      headerName: "Owne'_s State",
      columnGroupShow: "open",
    }
    , {
      field: "Owner_s_Zip_Code",
      headerName: "Owner's Zip Code",
      columnGroupShow: "open",
    }
    ,],
  "Other_Owner_Information": [{
    field: "Non_Profit",
    headerName: "Non Profit",
    columnGroupShow: "closed",
  }
    , {
    field: "DHCR",
    headerName: "DHCR",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Company_Name",
    headerName: "Other Owner Company Name",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Mailing_Address",
    headerName: "Other Owner Mailing Address",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Street_Name",
    headerName: "Other Owner Street Name",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_House_Number",
    headerName: "Other Owner House Number",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_City",
    headerName: "Other Owner City",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_State",
    headerName: "Other Owner State",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Zip_Code",
    headerName: "Other_Owner_Zip_Code",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Officer_First_Name",
    headerName: "Other Owner Officer First Name",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Officer_Middle_Initial",
    headerName: "Other Owner Officer Middle Initial",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Officer_Last_Name",
    headerName: "Other Owner Officer Last Name",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Officer_Title",
    headerName: "Other Owner Officer Title",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Phone_Number",
    headerName: "Other Owner Phone Number",
    columnGroupShow: "open",
  }
    , {
    field: "Other_Owner_Email_Address",
    headerName: "Other Owner Email Address",
    columnGroupShow: "open",
  },],
  "Condo_Second_Officer": [
    {
      field: "Condo_Company_Name",
      headerName: "Condo Company Name",
      columnGroupShow: "closed",
    }
    , {
      field: "Condo_Mailing_Address",
      headerName: "Condo Mailing Address",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Street_Name",
      headerName: "Condo Street Name",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_House_Number",
      headerName: "Condo House Number",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_City",
      headerName: "Condo City",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_State_Name",
      headerName: "Condo State Name",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Zip_Code",
      headerName: "Condo Zip Code",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Officer_First_Name",
      headerName: "Condo Officer First Name",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Officer_Middle_Initial",
      headerName: "Condo Officer Middle Initial",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Officer_Last_Name",
      headerName: "Condo Officer Last Name",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Officer_Title",
      headerName: "Condo Officer Title",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Phone_Number",
      headerName: "Condo Phone Number",
      columnGroupShow: "open",
    }
    , {
      field: "Condo_Email_Address",
      headerName: "Condo Email Address",
      columnGroupShow: "open",
    }
  ],
  'Permitting_Info': [
    {
      field: 'Applicant',
      columnGroupShow: 'closed',
    },
    {
      columnGroupShow: 'open',
      field: 'General_Contractor',
      headerName: 'General Contractor',
    },
    {
      columnGroupShow: 'open',
      field: 'Electrician',

    },
  ],
  'Pv_System_Info': [
    {
      field: 'Number_of_Interconnection',
      headerName: 'Number of Interconnection',
      columnGroupShow: 'closed',
    },
    {
      columnGroupShow: 'open',
      field: 'Total_System_Size',
      headerName: 'Total System Size',

    },
    {
      columnGroupShow: 'open',
      field: 'Total_Modules',
      headerName: 'Total Modules',
    },  
  ],
  "PV_Interconection" :[
    {
      columnGroupShow: 'closed',
      field: 'PV_Interconnection',
      headerName: 'PV Interconnection',
      id : "1024",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Interconnection_Number',
      filterAttribute:["Number","Pv_Columns"],
      type : "number",
      rowGroupField:'Interconnection_Number',
      collapsGroup:'PV_Interconection',
      headerName: 'Interconnection Number',
      id : "1025",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'System_Size_KW1',
      type : "number",
      filterAttribute:["Number","Pv_Columns"],
      rowGroupField:'System_Size_KW1',
      collapsGroup:'PV_Interconection',
      headerName: 'System Size KW',
      id : "1026",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Number_Of_Modules',
      type : "number",
      filterAttribute:["Number","Pv_Columns"],
      rowGroupField:'Number_Of_Modules',
      collapsGroup:'PV_Interconection',
      headerName: 'Number Of Modules',
      id : "1027",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'PV_Module_Model',
      filterAttribute:["String","Pv_Columns"],
      rowGroupField:'PV_Module_Model',
      type : "string",
      collapsGroup:'PV_Interconection',
      headerName: 'PV Module Model',
      id : "1028",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Invertor_Type',
      filterAttribute:["String","Pv_Columns"],
      rowGroupField:'Invertor_Type',
      type : "string",
      collapsGroup:'PV_Interconection',
      headerName: 'Invertor Type',
      id : "1029",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Number_of_Invertors',
      filterAttribute:["Number","Pv_Columns"],
      rowGroupField:'Number_of_Invertors',
      type : "number",
      collapsGroup:'PV_Interconection',
      headerName: 'Number of Invertors',
      id : "1030",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
    {
      columnGroupShow: 'open',
      field: 'Invertor_Model_Number',
      filterAttribute:["String","Pv_Columns"],
      rowGroupField:'Invertor_Model_Number',
      type : "string",
      collapsGroup:'PV_Interconection',
      headerName: 'Invertor Model Number',
      id : "1031",
      isChecked :  false,
      isDragOver : false,
      isSearch : true,
    },
  ],
  "Project_Cost": [
    {
      columnGroupShow: 'closed',
      field: 'Project_Cost',
      headerName: 'Project Cost',
    },
  ],
  "Links": [
    {
      columnGroupShow: 'closed',
      field: 'zola_link',
      headerName: 'Zola',
      type:"link"
    },
    {
      field: 'bis_link',
      headerName: 'Bis',
      type:"link",
      columnGroupShow: 'open',
    },
    {
      field: 'taxmap_link',
      headerName: 'Digital Tax Map',
      type:"link",
      columnGroupShow: 'open',
    },
    {
      field: 'acris_link',
      headerName: 'Acris',
      type:"link",
      columnGroupShow: 'open',
    },
  ],
  'Created_Details': [
    {
      field: 'Created_By',
      headerName: 'Created By',
      columnGroupShow: 'closed',
    },
    {
      field: 'Created_Time',
      headerName: 'Created Time',
      columnGroupShow: 'open',
    },],
  "Modified_Details": [
    {
      columnGroupShow: 'open',
      field: 'Modified_Time',
      headerName: 'Modified Time',
    },
    {
      columnGroupShow: 'closed',
      headerName: 'Modified By',
      field: 'Modified_By',
    },
  ],
  "Hide":[]
}