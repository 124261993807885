import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./tab_workdrive.css";
// import Tasks from "../Task/task";
// import Gantt from "../Gantt/gantt";
// import Dashboard from "../dashboard/dashboard";
// import Milestone from "./milestone/milestone";
import _ from 'lodash';
import Workdrive from "../../components/Workdrive/workdrive";
import Timeline from "../../components/Workdrive/timeline";
const Tab_Workdrive = (props) => {
  const [key, setKey] = useState("workdrive");
  const [DealsId, setDealsId] = useState("");
  useEffect(() => {
    if (props.data) {
      setDealsId(props.data.id);
    }
  }, [props.data]);
    // const getDealObj=(clone)=>{
    //     let dealObj=_.cloneDeep(clone);
    //     delete dealObj.zoningList;
    //     delete dealObj.commercialList;
    //     delete dealObj.specialDistList;
    //     return dealObj
    // }
  return (
    <div>
      <Tabs
        id="progress-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="job-middle-tab job-middle-tabs progress-inner-tabs"
      >
        <Tab eventKey="workdrive" title="Workdrive">
            <Workdrive data={props.data}/>
          {/* <Dashboard eventKey={key} dealsId={DealsId} /> */}
        </Tab>
        <Tab eventKey="activity" title="Activities">
         <Timeline data={props.data} folderId={props.data?props.data.zohoworkdriveforcrm__Workdrive_Folder_ID:''}/>
         {/* <Tasks isView={props.isView} isAccess={props.isAccess} selectedRole={props.selectedRole} deals={props.allDeals} eventKey={key} searchTask={props.searchTask} id='task' dealObj={props.dealObj} dealsId={DealsId} /> */}
       
        </Tab>
      </Tabs>
    </div>
  );
};

export default Tab_Workdrive;
