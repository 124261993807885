import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from 'react';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import CustomHeaderComponent from '../CustomHeader/CustomHeaderComponent';
import { Col, FormGroup, Input, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './GlobalTable.css'
import { Modal as BootStrapModal, ModalBody as BootStrapModalBody } from 'react-bootstrap'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faCircle, faGripVertical, faSearch } from '@fortawesome/free-solid-svg-icons';
import GlobalTaskFilter from '../../../common/task_filters/GlobalTaskFilter';
import { color } from 'highcharts';
import Config from '../../../config.json'
import _ from 'lodash';
import CustomDateComponent from '../../../common/CustomDateComponent/CustomDateComponent';
import CustomEditorComponent from '../../../common/CustomDateComponent/CustomEditorComponent';

import { ListGroupItem } from 'react-bootstrap';
import AppContext from '../../../Context/AppContext';
import { ActionsOfTimeClock, SelectedIdsForTimeClock } from '../../../Context/AppActions';
import swal from 'sweetalert';
import AttendenceService from '../../../common/util/AttendenceService';
import axios from 'axios';

export const GlobalAgGridTable = ({
  onExpandOrCollapse,
  excelStyles,
  gridRef,
  cacheRowData,
  setCacheRowData,
  onColumnPinned,
  rowData,
  module,
  DropDownFields,
  setRowData,
  headers,
  radioButton,
  onChangeRadioButton,
  renderExtraComponent,
  renderExtraComponentToRight,
  mainUrl,
  isDeleteEnable,
  refreshTable,
  setGridApiRef,
  getAppliedFiltersforView,
  filterData,
  setFilterData,
  isTaskFilterHide
}) => {
  const [gridApi, setGridApi] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [headerData, setheaderData] = useState([]);
  const [isEditColumnTrue, setIsEditColumnTrue] = useState(false);
  const [listSelectedRows, setlistSelectedRows] = useState([]);
  const [headersForFilter,setHeadersForFilter] = useState([]);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  let hideFields=["Action","status"];  
  let selectedIndx = -1;
  let selectedValue = null;
  let dropedIndx = -1;
  let dropedValue = null;

  // console.log("rowData in ag tabel", rowData);

  //new methods for manage columns
  
  useEffect(() => {
    let arr = []
    setheaderData(headers);
    headers?.forEach(one=>hideFields.includes(one.field)?'':arr.push(one));
    setHeadersForFilter(arr)
  }, [headers])
  useEffect(e=>{
     
    if(gridApi){
      gridApi.refreshCells();
    }
  },[refreshTable])
  const exportListViewData = (fileName) => {
    gridApi?.exportDataAsExcel({
      fileName: fileName,
      onlySelected: true,
      processCellCallback: function (params) {
        const rowData = params.node.data;
        const columnField = params.column?.getColDef().field;
        if(columnField=="status"){
          if(params.value==1){
            return "Approved"
          }else if(params.value==0){
            return "Pending"
          }else{
            return "Rejected"
          }
        }
        return params.value;
      }
    });
  }
  const onFirstDataRendered=(event)=>{
    dispatch(SelectedIdsForTimeClock([]))
  }
  const onSelectionChanged = (event) => {
    setGridApi(event.api)
    let listTypeRows = event.api.rowModel?.rowsToDisplay.filter(node => node.isSelected());
    if (listTypeRows.length == event.api.rowModel.rowsToDisplay.length && listSelectedRows.length < listTypeRows.length && listSelectedRows.length != 0) {
      event.api.deselectAll();
    } else {
      let array = [];
      event.api.forEachNode((node) => {
        if (node.isSelected()) {
          array.push(node?.data)
        } else {
        }
      });
      setlistSelectedRows(listTypeRows);
      dispatch(SelectedIdsForTimeClock(array))
    }
  }
  useEffect(() => { 
     
    if (state.ActionsOfTimeClock == "Export") {
      exportListViewData("newExcelSheet.xlsx")
    } else if (state.ActionsOfTimeClock == "EditColumns") {
      setIsEditColumnTrue(true)
    } else if (state.ActionsOfTimeClock == "Delete" && gridApi) {
      let array = [];
      gridApi?.forEachNode((node) => {
        if (node.isSelected()) {
          if (node?.data?.ROWID) {
            array.push(node?.data?.ROWID)
          }
        }
      })
      swal({
        title: "Warning!",
        text: "Are you sure you want to delete the record?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: "Proceed",
        },
      }).then((isDelete) => {
        if (isDelete) {
          if(mainUrl){
            axios.post(Config.backend_url+mainUrl,{config:{
              Type:"Delete",
              data:array
            }}).then(res => {
              let filteredData = cacheRowData.filter(e => !array.includes(e.ROWID));
              let filteredData1 = rowData.filter(e => !array.includes(e.ROWID));
              setCacheRowData(filteredData);
              setRowData(filteredData1);
              swal("Success!", "Records deleted successfully!", "success");
              // swal("Record seleted successfully!");
              dispatch(SelectedIdsForTimeClock([]))
            }).catch(e => {
              swal("Error!", "Something went wrong!", "error");
              // swal("Something Went Wrong!")
            })
          }else{
            let array = [];
            console.log("state.SelectedIdsForTimeClock", state.SelectedIdsForTimeClock);
            state.SelectedIdsForTimeClock?.forEach(one => array.push(one.id))
             axios.post(
            `${Config.backend_url}deleteRecords`,
            {
              ids: array,
              module: module,
              token: localStorage.getItem("token"),
            }
          )
          .then((res) => {
            swal("Success!", "Records deleted successfully!", "success");
          })
          .catch((err) => {
            console.log(err);
          });
          }
        }
      })
    }
    dispatch(ActionsOfTimeClock(""))
  }, [state.ActionsOfTimeClock])

  const onDragColumns = (e, value, list) => {
    e.preventDefault();
    selectedIndx = list.findIndex((x) => x.name === value.name);
    selectedValue = value;
  };

  const onDropColumns = (e, value, list) => {
    e.preventDefault();
    let newClient = [...headerData];
    if (selectedIndx !== 0 || dropedIndx === 0) {
      dropedIndx = newClient.findIndex((x) => x.name === value.name);
      dropedValue = value;
      newClient.splice(selectedIndx, 1);
      newClient.splice(dropedIndx, 0, selectedValue);
    }

    newClient.forEach((data) => {
      data.isDragOver = false;
    });
    setheaderData(newClient);
  };

  const handleColumns = (e, index) => {
    let newClient = [...headerData];
    newClient.forEach((obj) => {
      if (obj.id == e.currentTarget.id) {
        obj.isChecked = !e.currentTarget.checked;
      }
    });
    let flag = newClient.every(e => !e.isChecked);
    SetEditColumnSelectAllFlag(flag);
    setheaderData(newClient);
    setHeadersForFilter(newClient)
  };

  const onSearchColumns = (value) => {
    let newList = [...headerData];
    if (value == "") {
      newList.forEach((header) => {
        if (header.name) {
          header.isSearch = true;
        }
      });
    } else {
      newList.forEach((header) => {
        if (
          header.name &&
          header.name.toLowerCase().includes(value.toLowerCase())
        ) {
          header.isSearch = true;
        } else {
          header.isSearch = false;
        }
      });
    }
    setSearchValue(value);
    setheaderData(newList);
  };
  const getModalForEditColumns = () => {

    // console.log(headerData);
    return <BootStrapModal
      className="Edit-Column-Modal"
      show={isEditColumnTrue ? true : false}
      backdrop={true}
      toggle={() => {

      }}
      onHide={(e) => {
        setIsEditColumnTrue(false)
      }}
    >
      <ModalHeader>
        Manage Columns
      </ModalHeader>
      <BootStrapModalBody>
        <FormGroup className="pos-relative editcol-searchpad">
          <Input
            type="text"
            placeholder="Search"
            className="mr-sm-2 pad-fix search-filter"
            value={searchValue}
            onChange={(e) => onSearchColumns(e.target.value)}
          />

          <FontAwesomeIcon
            className="mr-sm-2 search-ico"
            icon={faSearch}
          />

          <div className="select-all-editColumn">
            <input
              type="checkbox"
              checked={editColumnSelectAllFlag}
              onChange={(e) => {
                SetEditColumnSelectAllFlag(!editColumnSelectAllFlag)
                const temp = [...headerData];
                temp.forEach(
                  (obj) => (obj.isChecked = !e.currentTarget.checked)
                );
                setheaderData(temp);
              }}
            ></input>
            {"  "}
            <span className={"editColumnlist-label "}
              style={{ fontFamily: "Avenir Next LT W02", fontSize: "13px", marginLeft: "-3px" }}
            >
              Select All
            </span>
          </div>
          <hr className="hr-line" />
        </FormGroup>
        <ul className="editColummn-list">
          {headerData?.map((e, index) => e.label && e.isSearch && (<ListGroupItem
            style={{ marginLeft: 8 }}
            className="editColumnlist-text"
            draggable
            onDrag={(ev) => onDragColumns(ev, e, headerData)}
            onDrop={(ev) => onDropColumns(ev, e, headerData)}
          // onDrag={(ev) => onDragColumns(ev, e, activeHeaderData)}
          // onDragOver={(ev) =>
          // onDragOverColumns(ev, e, activeHeaderData)
          // }
          // onDrop={(ev) => onDropColumns(ev, e, activeHeaderData)}
          >
            <FontAwesomeIcon
              icon={faGripVertical}
              className="icon-grip-vertical"
            />
            <input
              // disabled={e.name == "Deal_Name"}
              id={index}
              type="checkbox"
              checked={!e.isChecked}
              onChange={(e) => {
                handleColumns(e, index);
              }}
            ></input>
            {"  "}
            <span
              className={
                e.isDragOver
                  ? "editColumnlist-label add-line"
                  : "editColumnlist-label"
              }
            >
              {e.name}
            </span>
            {e.isDragOver ? <hr className="hr-line" /> : null}
          </ListGroupItem>)
          )}
        </ul>
      </BootStrapModalBody>
    </BootStrapModal>
  }
  //
  const [editColumnSelectAllFlag, SetEditColumnSelectAllFlag] = useState(true);
  const types = {
    phone: "string",
    text: "string",
    website: "string",
    ownerlookup: "string",
    picklist: "string",
    lookup: "string",
    textarea: "string",
    integer: "number",
    bigint: "number",
    currency: "number",
    double: "number",
    datetime: "date",
    boolean: "boolean",
  }
  const [to, setToRow] = useState(5);
  const [totalRow, setTotalRow] = useState(5);
  const [from, setFromRow] = useState(1);
  const [backActive, setbackActive] = useState(false);
  const [frontActive, setfrontActive] = useState(true);
  const [dotActive, setdotActive] = useState(false);

  const [isColFilDeleted, setIsColFilDeleted] = useState(false);
  const [activeFiltersOnColumnLevel, setActiveFiltersOnColumnLevel] = useState(
    {}
  );
  const [appliedFiltersForView, setappliedFiltersForView] = useState([]);
  const [preAppliedFiltersForView, setPreAppliedFiltersForView] = useState([]);

  const [FilterTrigger, setFilterTrigger] = useState(false);
  const [SortState, setSortState] = useState([]);
  const staticCellStyle = { "text-align": "center" };
  //filter params
  function getDate(value) {
    let dateParts = "";
    if (value?.includes("/")) {
      dateParts = value?.split("/");
    } else if (value?.includes("-")) {
      dateParts = value?.split("-");
    }
    if(module == "TimeSheet" || module == "Request" ||module == "ShiftMapping" ||module == "Where" ||module == "WhoIsIn"){
      return new Date(value)
    }else{
      return new Date(
        parseInt(dateParts[0]),
        parseInt(dateParts[1]) - 1,
        parseInt(dateParts[2])
      );
    }
  }

  const excelFilterParams = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          excelMode: "windows",
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        },
      },
    ]
  };
  const excelFilterForRequestType = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true, 
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            let d=params.data.requestType.split("_")[0];
            return d;
          },
        },
      },
    ]
  };
  const excelFilterForStatus = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true, 
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          valueGetter: (params) => {
            let d=params.data.status
              return d==0?"Pending":d==1?"Approved":"Rejected";
          },
        },
      },
    ]
  };
  const NumberfilterParams = {
    filters: [
      {
        filter: "agNumberColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        }
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          excelMode: "windows",
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
        },
      },
    ],
  };
  const dateFilterParams = {
    filters: [
      {
        filter: "agDateColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          comparator: function (filterDate, cellValue) {
            if (cellValue == null) return -1;
            return getDate(cellValue) - filterDate;
          },
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: 'keep',
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          //   valueGetter: (params) => {
          //     let d = params.data.Closing_Date.split('-');
          //     return d[1] + '-' + d[2] + '-' + d[0];
          //   },
          comparator: function (a, b) {
            return getDate(a) - getDate(b);
          },
        },
      },
    ],
  };
  // end---
  const updateSortingState = (sort) => {
    setSortState([{ ...sort }]);
    let newState = [...SortState];
    if (newState.length > 0) {
      let isPresent = newState.find(s => s.colId == sort.colId);
      if (isPresent) {
        newState = newState.map(s => {
          if (s.colId == sort.colId) {
            s.sort = sort.sort;
            return s;
          } else {
            return s;
          }
        })
      } else {
        newState.push(sort)
      }
    } else {
      newState.push(sort)
    }
    setSortState(newState);
  }

  useEffect(e=>{
    if(module=="Vendors"){
      getAppliedFiltersforView(appliedFiltersForView)
    }
  },[appliedFiltersForView])

  useEffect(e => {

    console.log("headers--------",headers)
    // console.log("data-",rowData)
    // console.log("data-",cacheRowData)
  }, [headers])
  function getFilterParam(e) {
    if (!e.key) {
      return e.field=="status"?excelFilterForStatus:e.field=="requestType"?excelFilterForRequestType:types[e.type] == "string" ? excelFilterParams :
        types[e.type] == "number" ? NumberfilterParams :
          types[e.type] == "date" ? dateFilterParams : excelFilterParams
    } else if (e.key) {
      let filter = e.field=="status"?excelFilterForStatus:e.field=="requestType"?excelFilterForRequestType:types[e.type] == "string" ? excelFilterParams :
        types[e.type] == "number" ? NumberfilterParams :
          types[e.type] == "date" ? dateFilterParams : excelFilterParams;
      filter["valueGetter"] = (params) => {}
      return filter;
    }
  }
  function getColumnsBySection(cols) {
    return cols.map(e => {
      if (e.field) {
        let filter = getFilterParam(e)
        return (
          <AgGridColumn
            headerName={e.name}
            hide={e.isChecked}
            sortable={true}
            pinned={e.pinned}
            filter={"agMultiColumnFilter"}
            headerComponentFramework={(params) => {
              return CustomHeaderComponent({ ...params, filterStateToggel: FilterTrigger, updateSortingState: updateSortingState, compactViewSortState: SortState })
            }}
            field={e.field}
            filterParams={
              filter
            }
            minWidth={200}
            cellStyle={staticCellStyle}
            headerTooltip={e.name}
            cellRenderer={(params) => {
              if (e.key) {
                return e.content(params.data, e.key );
              } else {
                return params.data[e.field];
              }
            }}
          />
        )
      }
    });
  }
  const onChangeColumnFilters = (params) => {
    if (rowData && !isColFilDeleted) {
      let data = params?.api?.getFilterModel();
      if (JSON.stringify(data) !== JSON.stringify(activeFiltersOnColumnLevel)) {
        setActiveFiltersOnColumnLevel(data);
      }
    } else {
      setIsColFilDeleted(false);
    }
  };

  //grid header methods
  const onBtDoublePrevious = () => {
    gridApi.paginationGoToPreviousPage();
    gridApi.paginationGoToPreviousPage();
    getPagingValues();
  };
  const onBtPrevious = () => {
    gridApi.paginationGoToPreviousPage();
    getPagingValues();
  };
  const onBtNext = () => {
    gridApi.paginationGoToNextPage();
    getPagingValues();
  };
  const onBtDoubleNext = () => {
    gridApi.paginationGoToNextPage();
    gridApi.paginationGoToNextPage();
    getPagingValues();
  };
  const paginationAction = (api) => {
    if (api) {
      const currentPage = api.paginationGetCurrentPage();
      const totalPage = api.paginationGetTotalPages();
      const totalRows = api.paginationGetRowCount();
      const pageSize = api.paginationGetPageSize();
      const fromRow = currentPage * pageSize + 1;
      const toRow = Math.min(fromRow + pageSize - 1, totalRows);
      setFromRow(fromRow);
      setToRow(toRow);
      setTotalRow(totalRows);
      fromRow != 1 ? setbackActive(true) : setbackActive(false);
      if (toRow === totalRows) {
        setfrontActive(false);
        setdotActive(true);
      } else {
        setfrontActive(true);
        setdotActive(false);
      }
    }
  }
  const getPagingValues = () => {
    paginationAction(gridApi)
  };
  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById("page-size").value;
    gridApi.paginationSetPageSize(Number(value));
    let updatedPage = Math.floor(from / value);
    gridApi.paginationGoToPage(updatedPage);
    getPagingValues();

  };

  /////////New Code/////////////

  useEffect(() => {
    if (gridApi) {
      let model = gridApi?.getFilterModel();
      if (JSON.stringify(activeFiltersOnColumnLevel) != JSON.stringify(model)) {
        gridApi?.setFilterModel(
          activeFiltersOnColumnLevel != null ? activeFiltersOnColumnLevel : {}
        );
      }
    }
  }, [activeFiltersOnColumnLevel]);

  const setActiveFilterAndGetActiveFilters = async (
    filters,
    action,
    mainFilters
  ) => {
    let api = gridApi;
    if (action == "SAVE") {
      if (api) {
        let filtersModal = await api.getFilterModel();
        return filtersModal;
      }
    } else if (action == "SET") {
      let filterMain = mainFilters;
      if (!_.isArray(mainFilters) && !_.isEmpty(mainFilters)) {
        do {
          filterMain = JSON.parse(filterMain);
        } while (!_.isArray(filterMain));
      }
      let filter = filters;
      if (!_.isObject(filters) && !_.isEmpty(filters)) {
        do {
          filter = JSON.parse(filter);
        } while (!_.isObject(filter));
      }
      setPreAppliedFiltersForView(filterMain);
      setTimeout(() => {
        setActiveFiltersOnColumnLevel(filter);
      }, 1000);
    }
  };

  /////////New Code/////////////

  const onSearchFilterTexts = (value) => {

    gridApi.setQuickFilter(value);
  };
  //end
  // body --- start
  return (
    <>
      {getModalForEditColumns()}
      <div style={{ height: "100%",width:"100%" }}>
        <div className='Globaltable-Header-Wrapper'>
          <Row className='m-2'>
            <Col
              sm={7}
              className={"dealtablesearchdiv"}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="pos-relative" style={{ display: "flex", alignItems : "center"}}>
                <div className="input-search-wrapper">
                  <div className="search-icon-wrapper">
                    <FontAwesomeIcon
                      className="mr-sm-2 search-ico"
                      icon={faSearch}
                    />
                  </div>
                  <Input
                    type="text"
                    onChange={(e) => {

                      onSearchFilterTexts(e.target.value)
                    }
                    }
                    placeholder="Search"
                    className="mr-sm-2 pad-fix search-filter search-deal"
                    style={{ width: "17rem", borderColor: "#018EE0" }}
                  />

                </div>
                {!isTaskFilterHide && <div
                  style={{ minWidth: "max-content", marginTop: "0px" , display: module == "WhoIsIn" ? "none" : ""}}
                  className="task_filter_box"
                // onClick={}
                >
                  <GlobalTaskFilter
                    activeFiltersOnColumnLevel={activeFiltersOnColumnLevel}
                    setActiveFiltersOnColumnLevel={setActiveFiltersOnColumnLevel}
                    setActiveFilterAndGetActiveFilters={setActiveFilterAndGetActiveFilters}
                    setappliedFiltersForView={setappliedFiltersForView}
                    preAppliedFiltersForView={preAppliedFiltersForView}
                    setIsColFilDeleted={setIsColFilDeleted}
                    setFilterTrigger={setFilterTrigger}
                    FilterTrigger={FilterTrigger}
                    Columns={headersForFilter}
                    setHeadersForFilter={setHeadersForFilter}
                    rowData={rowData}
                    cacheData={cacheRowData}
                    setRowData={setRowData}
                    module={module}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    dropdownFields={DropDownFields}
                  />
                </div>}
                {radioButton?.active && <div className="list-compact-view">
                  <div onClick={(e) => onChangeRadioButton(true)} className="list-view" style={radioButton.state ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>{radioButton.displayName1}</div>
                  <div onClick={(e) => onChangeRadioButton(false)} className="compact-view" style={!radioButton.state ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>{radioButton.displayName2}</div>
                </div>}
              </div>
              {module !== "WhoIsIn" &&  renderExtraComponent ? renderExtraComponent() : <></>}
            </Col>
            <Col sm={5} style={{display : "flex", justifyContent : "end", padding : "0px"}}>
              <div className="float-right" style={{display : (module == "Where" || module == "TimeSheet" || module == "Request" || module == "WhoIsIn" || module == "ShiftMapping") ? "none" : ""}}>
                <select
                  className="dealtable-dropdown"
                  onChange={() => onPageSizeChanged()}
                  id="page-size"
                >
                  <option value="5">5 Records Per Page</option>
                  <option value="10">10 Records Per Page</option>
                  <option value="15">15 Records Per Page</option>
                  <option value="25">25 Records Per Page</option>
                  <option value="50" selected={true}>
                    50 Records Per Page
                  </option>
                  <option value="75">75 Records Per Page</option>
                  <option value="100">100 Records Per Page</option>
                </select>
                <div className="dot-fix">
                  <FontAwesomeIcon
                    className={
                      dotActive
                        ? "pad-icon-circle-active"
                        : "pad-icon-circle"
                    }
                    icon={faCircle}
                  />
                </div>
                <div className="dot-fix">
                  <FontAwesomeIcon
                    className={
                      backActive ? "pad-icon-active" : "pad-icon"
                    }
                    onClick={(e) => {
                      onBtDoublePrevious()
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    icon={faAngleDoubleLeft}
                  />
                  <FontAwesomeIcon
                    className={
                      backActive ? "pad-icon-active" : "pad-icon"
                    }
                    onClick={(e) => {
                      onBtPrevious()
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    icon={faAngleLeft}
                  />
                  {from} to {to} of {totalRow}
                  <FontAwesomeIcon
                    className={
                      frontActive ? "pad-icon-active" : "pad-icon"
                    }
                    onClick={(e) => {
                      onBtNext()
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    icon={faAngleRight}
                  />
                  <FontAwesomeIcon
                    className={
                      frontActive ? "pad-icon-active" : "pad-icon"
                    }
                    onClick={(e) => {
                      onBtDoubleNext()
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    icon={faAngleDoubleRight}
                  />
                </div>
              </div>
            {renderExtraComponentToRight ? renderExtraComponentToRight() : <></>}
           </Col>
          </Row>
        </div>
        {headerData && rowData && <AgGridReact
          suppressLoadingOverlay={true}
          onFilterChanged={(e) => {
            setFilterTrigger(!FilterTrigger)
            onChangeColumnFilters(e)
          }}
          onColumnGroupOpened={onExpandOrCollapse}
          excelStyles={excelStyles}
          ref={gridRef}
          modules={(AllCommunityModules, AllModules)}
          paginationNumberFormatter={function (params) {
            return params.value.toLocaleString();
          }}
          defaultColDef={{
            sortable: true,
            resizable: true,
            flex: 1,
            minWidth: 140,
          }}
          frameworkComponents={{
            // columnFilter: ColumnFilter,
            agDateInput: CustomDateComponent,
            customEditor: CustomEditorComponent,
          }}
          onFirstDataRendered={onFirstDataRendered}
          onSelectionChanged={onSelectionChanged}
          cacheQuickFilter={true}
          suppressDragLeaveHidesColumns={true}
          suppressPaginationPanel={true}
          onColumnPinned={onColumnPinned}
          rowSelection={"multiple"}
          groupSelectsChildren={true}
          suppressRowClickSelection={true}
          // enableRangeSelection={true}
          // pagination={true}
          // paginationPageSize={50}
          onGridReady={(grid) => {
            if(module=="Vendors"){
              setGridApiRef(grid?.api);
            }
              setGridApi(grid?.api)
          }}
          // onPaginationChanged={getPagingValues}
          rowData={rowData}
        >
          <AgGridColumn
            pinned="left"
            field=""
            maxWidth={55}
            lockPosition={true}
            // pinned='left'
            cellClass="locked-col"
            checkboxSelection={true}
            headerCheckboxSelection={true}
            cellStyle={staticCellStyle}
            suppressNavigable={true}
          ></AgGridColumn>
          {getColumnsBySection(headerData)}

        </AgGridReact>}
      </div>
    </>
  )
}
