import React, { useState, useEffect, useContext } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Loader from "../../../../../../common/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import plus from "../../../../../../assets/images/plus.png";
import minus from "../../../../../../assets/images/minus.png";
import axios from "axios";
import { v4 as uuid } from "uuid";
import Config from "../../../../../../config.json";
import CustomSelectComponent from "../../../../../../common/Select";
import AppContext from "../../../../../../Context/AppContext";
import { CreateDealData } from "../../../../../../Context/AppActions";

// import swal from "sweetalert";
function NycProperty(props) {
  const backendUrl1 = Config.backend_url;
  const [nycpropertystate, setNycpropertytoggle] = useState(false);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const editDealData = state.EditDealData || {};
  const createDealData = state.CreateDealData || {};
  const nycApiData = state.NycApiData || {};
  const generateInitialState = (numberOfSelects) =>
    numberOfSelects.map((item) => null);
  const [zoningDist, setZoningDist] = useState(() =>
    // increase the array elements to add more select fields
    generateInitialState([{}])
  );
  const [BinInvalid, setBinInvalid] = useState(false);
  const [CommInvalid, setCommInvalid] = useState(false);
  const [skipNycProperty, setSkipNycProperty] = useState(false);
  const [zolaEnable, setzolaEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zoningDistOptions, setzoningDistOptions] = useState();
  const [specialDistOptions, setspecialDistOptions] = useState();
  const [commercialList, setCommercialList] = useState();

  const [validation, setValidation] = useState({
    blockNo: "0",
    lotNo: "0",
    binNo: "0",
    community: "0",
    dwellingUnits: "0",
    zoiningMap: "0",
  });

  const validationCss = {
    transition: "width 0.4s ease-in-out",
    "box-shadow": "rgb(255 98 98) 0px 0px 7px 1px",
    "border-color": "#ff0000",
  };

  const pdfByURL = async (url, fileType) => {
    let config = {
      url: Config.backend_url + "downloadPdfByURL",
      method: "post",
      data: {
        url: url,
        fileType: fileType,
      },
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    };
    let file = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return file;
  };

  const [specialDist, setSpecialDist] = useState(() =>
    generateInitialState([{}])
  );
  const [commercialOverlay, setcommercialOverlay] = useState(() =>
    generateInitialState([{}])
  );

  let location = props.location;

  const customstyle = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      background: "#fff",
      borderColor: "#ccc",
      minHeight: "33px",
      height: "33px",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {},
    }),
  };

  const NycAPIs = async () => {
    setInfoField(nycApiData.nycData.myData);
  };

  useEffect(() => {
    if (nycApiData.nycData != undefined) {
      hideLoader();
    } else {
      showLoader();
    }
  }, [nycApiData]);

  useEffect(() => {
    if (nycApiData.nycData != undefined) {
      NycAPIs();
    }
  }, [props.addressValChange, nycApiData]);
  useEffect(() => {
    if (props.data.Borough && props.data.blockNo && props.data.lotNo) {
      setzolaEnable(true);
      props.setzolaEnable(true);
    }
  }, [props.data]);
  const handleNycPropertyCheck = () => {
    if (skipNycProperty) {
      props.setNycPropertyComplete.nycProperty = true;
      return true;
    }
    if (
      props.blockNo !== "" &&
      props.binNo !== "" &&
      props.lotNo !== "" &&
      props.community !== "" &&
      props.dwellingUnits !== "" &&
      props.dwellingUnits !== undefined &&
      props.dwellingUnits !== null &&
      props.zoningList !== "N/A" &&
      props.commercialList !== "N/A" &&
      props.specialDistList !== "N/A" &&
      props.zoiningMap !== "" &&
      props.tidalWetland != null &&
      props.freshWater != null &&
      props.coastal != null
    ) {
      props.setNycPropertyComplete.nycProperty = true;
      return true;
    }
    props.setNycPropertyComplete.nycProperty = false;
    return false;
  };
  //Zoning Districts //

  useEffect(() => {
    const zoneDist = [];
    const spclDist = [];
    const commercialOverlay = [];
    axios
      .get(`${backendUrl1}/getOptionsList/ZoningDistrictsList`)
      .then((response) => {
        response.data.data.map((item, ind) => {
          zoneDist.push({
            label: item.ZoningDistrictsList.ZoningDistrict,
            value: item.ZoningDistrictsList.ZoningDistrict,
          });
        });
        setzoningDistOptions(zoneDist);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${backendUrl1}/getOptionsList/SpecialDistrictsList`)
      .then((response) => {
        response.data.data.map((item, ind) => {
          spclDist.push({
            label: item.SpecialDistrictsList.SpecialDistrict,
            value: item.SpecialDistrictsList.SpecialDistrict,
          });
        });
        setspecialDistOptions(spclDist);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${backendUrl1}/getOptionsList/CommercialOverlaysList`)
      .then((response) => {
        response.data.data.map((item, ind) => {
          commercialOverlay.push({
            label: item.CommercialOverlaysList.CommercialOverlay,
            value: item.CommercialOverlaysList.CommercialOverlay,
          });
        });
        setCommercialList(commercialOverlay);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  ////////////////////
  const handlespecialDistListChange = (value, index) => {
    const clonedSelectState = JSON.parse(JSON.stringify(specialDist));
    clonedSelectState[index] = value;
    setSpecialDist(clonedSelectState);
    const list = [...props.specialDistList];
    list[index].specialDist = value.value;
    props.handleOnChangeDeal({
      currentTarget: { name: "specialDistList", value: list },
    });
    dispatch(CreateDealData({ ...createDealData, specialDistList: list }));
    props.setspecialDistList(list);
  };
  ////////////////////
  const handlespecialDistListRemove = (index) => {
    const list = [...props.specialDistList];
    const list1 = [...specialDist];
    list.splice(index, 1);
    list1.splice(index, 1);
    setSpecialDist(list1);
    props.handleOnChangeDeal({
      currentTarget: { name: "specialDistList", value: list },
    });
    props.setspecialDistList(list);
    dispatch(CreateDealData({ ...createDealData, specialDistList: list }));
  };

  // handle click event of the Add button
  const handleAddspecialDistList = () => {
    setSpecialDist([...specialDist, { label: "N/A", value: "N/A" }]);
    props.handleOnChangeDeal({
      currentTarget: {
        name: "specialDistList",
        value: [...props.data.specialDistList, { specialDist: "N/A" }],
      },
    });
    props.setspecialDistList([
      ...props.data.specialDistList,
      { specialDist: "N/A" },
    ]);
    dispatch(
      CreateDealData({
        ...createDealData,
        specialDistList: [
          ...createDealData.specialDistList,
          { specialDist: "N/A" },
        ],
      })
    );
  };
  function hideLoader() {
    setLoading(false);
  }

  function showLoader() {
    setLoading(true);
  }
  //////////////commercial////////////////
  const handleCommercialChange = (value, index) => {
    const clonedSelectState = JSON.parse(JSON.stringify(commercialOverlay));
    clonedSelectState[index] = value;
    setcommercialOverlay(clonedSelectState);
    const list = [...props.commercialList];
    list[index].commercialoverlay = value.value;
    props.handleOnChangeDeal({
      currentTarget: { name: "commercialList", value: list },
    });
    props.setCommercialList(list);
    dispatch(
      CreateDealData({
        ...createDealData,
        commercialList: [...createDealData.commercialList, list],
      })
    );
  };

  const handleCommercialRemove = (index) => {
    const list = [...props.commercialList];
    const list1 = [...commercialOverlay];
    list.splice(index, 1);
    list1.splice(index, 1);
    setcommercialOverlay(list1);
    props.handleOnChangeDeal({
      currentTarget: { name: "commercialList", value: list },
    });
    props.setCommercialList(list);
    props.setCommercialList(list);
    dispatch(
      CreateDealData({
        ...createDealData,
        commercialList: [...createDealData.commercialList, list],
      })
    );
  };

  // handle click event of the Add button
  const handleAddCommercial = () => {
    setcommercialOverlay([
      ...commercialOverlay,
      { label: "N/A", value: "N/A" },
    ]);
    props.handleOnChangeDeal({
      currentTarget: {
        name: "commercialList",
        value: [...props.data.commercialList, { commercialoverlay: "N/A" }],
      },
    });
    props.setCommercialList([
      ...props.data.commercialList,
      { commercialoverlay: "N/A" },
    ]);
    dispatch(
      CreateDealData({
        ...createDealData,
        commercialList: [
          ...createDealData.commercialList,
          { commercialoverlay: "N/A" },
        ],
      })
    );
  };
  const getOptionsToRender = (allSelectedOptions, zoningDistOptions) => {
    let optionsToRender;
    if (zoningDistOptions != undefined) {
      const filteredOptions = allSelectedOptions.flatMap((options) => options);
      optionsToRender =
        filteredOptions?.length > 0
          ? zoningDistOptions.filter(
              (option) =>
                !filteredOptions.some(
                  (selectOption) =>
                    option &&
                    selectOption &&
                    option.value === selectOption.value
                )
            )
          : zoningDistOptions;
    }

    return optionsToRender;
  };

  const getOptionsToRenderSpeclDist = (
    allSelectedOptions,
    specialDistOptions
  ) => {
    let optionsToRender;
    if (specialDistOptions != undefined) {
      const filteredOptions = allSelectedOptions.flatMap((options) => options);
      optionsToRender =
        filteredOptions?.length > 0
          ? specialDistOptions.filter(
              (option) =>
                !filteredOptions.some(
                  (selectOption) =>
                    option &&
                    selectOption &&
                    option.value === selectOption.value
                )
            )
          : specialDistOptions;
    }

    return optionsToRender;
  };
  const getOptionsCommercial = (allSelectedOptions, commercialList) => {
    let optionsToRender;
    if (commercialList != undefined) {
      const filteredOptions = allSelectedOptions.flatMap((options) => options);
      optionsToRender =
        filteredOptions?.length > 0
          ? commercialList.filter(
              (option) =>
                !filteredOptions.some(
                  (selectOption) =>
                    option &&
                    selectOption &&
                    option.value === selectOption.value
                )
            )
          : commercialList;
    }

    return optionsToRender;
  };

  const handleZoningChange = (value, index) => {
    // clone state
    const clonedSelectState = JSON.parse(JSON.stringify(zoningDist));
    clonedSelectState[index] = value;
    setZoningDist(clonedSelectState);
    const list = [...props.zoningList];
    list[index].zoningdistrict = value.value;
    props.handleOnChangeDeal({
      currentTarget: { name: "zoningList", value: list },
    });
    props.setZoningList(list);
    dispatch(
      CreateDealData({
        ...createDealData,
        zoningList: [...createDealData.zoningList, list],
      })
    );
  };

  const handleZoningRemove = (index) => {
    const list = [...props.zoningList];
    const list1 = [...zoningDist];
    list.splice(index, 1);
    list1.splice(index, 1);
    setZoningDist(list1);
    props.handleOnChangeDeal({
      currentTarget: { name: "zoningList", value: list },
    });
    props.setZoningList(list);
    dispatch(
      CreateDealData({
        ...createDealData,
        zoningList: [...createDealData.zoningList, list],
      })
    );
  };

  // handle click event of the Add button
  const handleAddZoning = () => {
    setZoningDist([...zoningDist, { label: "N/A", value: "N/A" }]);
    props.handleOnChangeDeal({
      currentTarget: {
        name: "zoningList",
        value: [...props.data.zoningList, { zoningdistrict: "N/A" }],
      },
    });
    props.setZoningList([...props.data.zoningList, { zoningdistrict: "N/A" }]);
    dispatch(
      CreateDealData({
        ...createDealData,
        zoningList: [...createDealData.zoningList, { zoningdistrict: "N/A" }],
      })
    );
  };

  const occupancyYesOption = (
    <Input
      type="select"
      style={{ height: "35px" }}
      value={props.data.occupancyval}
      onChange={(e) => {
        props.handleOnChangeDeal(e);
        props.setOccupancyVal(e.target.value);
      }}
      className="dropdown-text align-center-class"
      name="occupancyval"
      id="yesoccupancy"
    >
      <option>Please Select</option>
      <option>ASSEMBLY: THEATERS, CONCERT HALLS</option>
      <option>ASSEMBLY: THEATERS, CONCERT HALLS</option>
      <option>ASSEMBLY: EATING & DRINKING</option>
      <option>ASSEMBLY: OTHER</option>
      <option>ASSEMBLY: INDOOR SPORTS</option>
      <option>ASSEMBLY: OUTDOORS</option>
      <option>BUSINESS</option>
      <option>EDUCATIONAL</option>
      <option>FACTORY/INDUSTRIAL: MODERATE HAZARD</option>
      <option>FACTORY/INDUSTRIAL: LOW HAZARD</option>
      <option>HIGH HAZARD: EXPLOSIVE DETONATION</option>
      <option>HIGH HAZARD: ACCELERATED BURNING</option>
      <option>HIGH HAZARD: SUPPORTS COMBUSTION</option>
      <option>HIGH HAZARD: HEALTH</option>
      <option>HIGH HAZARD: SEMICONDUCTORS</option>
      <option>INSTITUTION: ASSISTED LIVING</option>
      <option>INSTITUTION: INCAPACITATED</option>
      <option>INSTITUTION: RESTRAINED</option>
      <option>INSTITUTION: DAY CARE</option>
      <option>MERCANTILE</option>
      <option>RESIDENTIAL: HOTELS, DORMITORIES</option>
      <option>RESIDENTIAL: APARTMENT HOUSES</option>
      <option>ESIDENTIAL: 1 & 2 FAMILY HOUSES</option>
      <option>STORAGE: MODERATE HAZARD</option>
      <option>STORAGE: LOW HAZARD</option>
      <option>UTILITY / MISCELLANEOUS</option>
    </Input>
  );

  const occupancyNoOption = (
    <Input
      type="select"
      className="dropdown-text align-center-class"
      value={props.data.occupancyval}
      onChange={(e) => {
        props.handleOnChangeDeal(e);
        props.setOccupancyVal(e.target.value);
      }}
      name="occupancyval"
      id="nooccupancy"
      style={{ height: "35px" }}
    >
      <option>Please Select</option>
      <option>HIGH HAZARD</option>
      <option>STORAGE (MODERATE HAZARD)</option>
      <option>STORAGE (LOW HAZARD)</option>
      <option>MERCANTILE</option>
      <option>INDUSTRIAL (MODERATE HAZARD)</option>
      <option>INDUSTRIAL (LOW HAZARD)</option>
      <option>BUSINESS</option>
      <option>ASSEMBLY (THEATERS)</option>
      <option>ASSEMBLY (CHURCHES, CONCERT HALLS)</option>
      <option>ASSEMBLY (OUTDOORS)</option>
      <option>ASSEMBLY (MUSEUMS)</option>
      <option>ASSEMBLY (RESTAURANTS)</option>
      <option>EDUCATION</option>
      <option>INSTITUTIONAL (RESTRAINED)</option>
      <option>INSTITUTIONAL (INCAPACITATED)</option>
      <option>RESIDENTIAL (HOTELS)</option>
      <option>RESIDENTIAL APT HOUSE</option>
      <option>RESIDENTIAL 1-2 FAMILY HOUSES</option>
      <option>MISCELLANEOUS</option>
      <option>COMMERCIAL BUILDINGS - OLD CODE</option>
      <option>PUBLIC BLDG - OLD CODE</option>
      <option>RESID. BLDG - OLD CODE</option>
    </Input>
  );

  const constructionYesOption = (
    <Input
      type="select"
      className="dropdown-text align-center-class"
      value={props.data.constructionval}
      onChange={(e) => {
        props.handleOnChangeDeal(e);
        props.setConstructionVal(e.target.value);
      }}
      name="constructionval"
      id="yesconstruction"
      style={{ height: "35px" }}
    >
      <option>I-A: 3 HOUR PROTECTED - NON-COMBUSTIBLE</option>
      <option>I-B: 2 HOUR PROTECTED - NON-COMBUSTIBLE</option>
      <option>II-A: 1 HOUR PROTECTED - NON-COMBUSTIBLE</option>
      <option>II-B: UNPROTECTED - NON-COMBUSTIBLE</option>
      <option>III-A: PROTECTED WOOD JOIST - COMBUSTIBLE</option>
      <option>III-B: UNPROTECTED WOOD JOIST - COMBUSTIBLE</option>
      <option>IV-HT: HEAVY TIMBER - COMBUSTIBLE</option>
      <option>V-A: PROTECTED WOOD FRAME - COMBUSTIBLE</option>
      <option>V-B: UNPROTECTED WOOD FRAME - COMBUSTIBLE</option>
    </Input>
  );

  const constructionNoOption = (
    <Input
      style={{ height: "35px" }}
      type="select"
      className="dropdown-text align-center-class"
      value={props.data.constructionval}
      onChange={(e) => {
        props.handleOnChangeDeal(e);
        props.setConstructionVal(e.target.value);
      }}
      name="constructionval"
      id="noconstruction"
    >
      <option>Please Select</option>
      <option>1: FIREPROOF STRUCTURES</option>
      <option>I-A: 4 HOUR PROTECTED</option>
      <option>I-B: 3 HOUR PROTECTED</option>
      <option>I-C: 2 HOUR PROTECTED</option>
      <option>I-D: 1 HOUR PROTECTED</option>
      <option>I-E: UNPROTECTED</option>
      <option>2: FIRE-PROTECTED STRUCTURES</option>
      <option>II-A: HEAVY TIMBER</option>
      <option>II-B: PROTECTED WOOD JOIST</option>
      <option>II-C: UNPROTECTED WOOD JOIST</option>
      <option>II-D: PROTECTED WOOD FRAME</option>
      <option>II-E: UNPROTECTED WOOD FRAME</option>
      <option>3: NON-FIREPROOF STRUCTURES</option>
      <option>4: WOOD FRAME STRUCTURES</option>
      <option>5: METAL STRUCTURES</option>
      <option>6: HEAVY TIMBER STRUCTURES</option>{" "}
    </Input>
  );

  const multipledwelling = (
    <Input
      type="select"
      style={{ height: "35px" }}
      className="dropdown-text"
      name="multipleDwellingCallsification"
      id="multipledwelling"
      value={props.data.multipleDwellingCallsification}
      onChange={(e) => props.handleOnChangeDeal(e)}
    >
      <option>-- Select --</option>
      <option>COMMERCIAL ALTERED "A"</option>
      <option>COMMERCIAL ALTERED "B"</option>
      <option>CONVERTED NEW LAW</option>
      <option>CONVERTED OLD LAW</option>
      <option>HEREAFTER CONVERTED CLASS A</option>
      <option>HERETOFORE CONVERTED CLASS B</option>
      <option>HEREAFTER ERECTED CLASS A</option>
      <option>HEREAFTER ERECTED CLASS B (HOTELS)</option>
      <option>HERETOFORE CONVERTED CLASS A</option>
      <option>HERETOFORE CONVERTED CLASS B</option>
      <option>HERETOFORE ERECTED EXISTING CLASS A (HOTELS)</option>
      <option>HERETOFORE ERECTED EXISTING CLASS B (HOTELS)</option>
      <option>JOINT ARTIST IN RESIDENCE</option>
      <option>LODGING HOUSE</option>
      <option>NEW LAW TENEMENTS</option>
      <option>NEW LAW SINGLE ROOM</option>
      <option>OLD LAW TENEMENTS</option>
      <option>OLD LAW SINGLE ROOM</option>
      <option>Y-TYPE BUILDING (CLASS B)</option>
    </Input>
  );

  const onChangeBlockNo = (value) => {
    if (value?.length < 6) {
      setValidation({ ...validation, blockNo: "-1" });
      props.setBlockNo(value);
    }
    if (value?.length == 5) {
      setValidation({ ...validation, blockNo: "1" });
    }
  };
  const onBlurBlockNo = (value) => {
    if (value?.length < 5 && value?.length != 0) {
      var addZero = "";
      const remain = 5 - value?.length;
      for (var i = 0; i < remain; i++) {
        addZero = addZero + "0";
      }
      props.setBlockNo(addZero + value);
      return addZero + value;
    } else {
      props.setBlockNo(value);
      return value;
    }
  };

  const onChangeLotNo = (value) => {
    if (value?.length < 5) {
      setValidation({ ...validation, lotNo: "-1" });
      props.setLotNo(value);
    }
    if (value?.length == 4) {
      setValidation({ ...validation, lotNo: "1" });
    }
  };
  const onBlurLotNo = (value) => {
    if (value?.length < 4 && value?.length != 0) {
      var addZero = "";
      const remain = 4 - value?.length;
      for (var i = 0; i < remain; i++) {
        addZero = addZero + "0";
      }
      props.setLotNo(addZero + value);
      return addZero + value;
    } else {
      props.setLotNo(value);
      return value;
    }
  };

  const onChangeBinNo = (value) => {
    if (value?.length < 8) {
      setValidation({ ...validation, binNo: "-1" });
      props.setBinNo(value);
    }
    if (value?.length == 7) {
      setValidation({ ...validation, binNo: "1" });
      setBinInvalid(false);
    }
  };

  const onBlurBinNo = (value) => {
    if (value?.length < 7) {
      setValidation({ ...validation, binNo: "-1" });
      setBinInvalid(true);
    } else {
      setValidation({ ...validation, binNo: "1" });
      setBinInvalid(false);
    }
  };

  const onChangeCommunity = (value) => {
    if (value?.length < 4) {
      setValidation({ ...validation, community: "-1" });
      props.setCommNo(value);
    }
    if (value?.length == 3) {
      setValidation({ ...validation, community: "1" });
      setCommInvalid(false);
    }
  };

  const onBlurCommNo = (value) => {
    if (value?.length < 3) {
      setValidation({ ...validation, community: "-1" });
      setCommInvalid(true);
    } else {
      setValidation({ ...validation, community: "1" });
      setCommInvalid(false);
    }
  };

  const setInfoField = async (data) => {
    let mapNumberPdf = null;
    if (data.data?.length > 0) {
      let obj = {};
      obj.blockNo = onBlurBlockNo(data.data[0].block);
      obj.lotNo = onBlurLotNo(data.data[0].lot);
      props.setBinNo(nycApiData.nycData.binNum);
      props.setCommNo(data.data[0].cd);
      dispatch(
        CreateDealData({
          ...createDealData,
          landmark: nycApiData.nycData.landStatus,
        })
      );
      props.setCoastal(
        nycApiData.nycData.coastal !== undefined
          ? nycApiData.nycData.coastal
          : "No"
      );
      props.setEnvRestrictions(
        nycApiData.nycData.env_WebScrape == "Yes" ? "Yes" : "No"
      );
      props.setFloodHazard(nycApiData.nycData.floodZone);
      props.setFreshWater(nycApiData.nycData.freshWaters ? "Yes" : "No");
      props.setTidalWetland(
        nycApiData.nycData.tidal_WebScrape == "Yes" ? "Yes" : "No"
      );
      props.setZoiningMap(data.data[0].zonemap);
      obj.binNo = nycApiData.nycData.binNum;
      obj.bblNo = nycApiData.nycData.bblNum;
      obj.community = data.data[0].cd;
      obj.landmark =
        nycApiData.nycData.landmark_WebScrape == "No" ? "No" : "Yes";
      obj.coastal =
        nycApiData.nycData.coastal !== undefined
          ? nycApiData.nycData.coastal
          : "No";
      obj.envRestrictions =
        nycApiData.nycData.env_WebScrape == "No" ? "No" : "Yes";
      obj.floodHazard = nycApiData.nycData.floodZone;
      obj.freshWater = nycApiData.nycData.freshWaters == "Yes" ? "Yes" : "No";
      obj.tidalWetland =
        nycApiData.nycData.tidal_WebScrape == "Yes" ? "Yes" : "No";
      obj.zoiningMap = data.data[0].zonemap;

      props.setzolaEnable(true);
      setzolaEnable(true);
      const url = `${backendUrl1}getPLUTOInfo?bbl=${nycApiData.nycData.bblNum}`;
      var returnValue = axios
        .get(url)
        .then((res) => {
          res?.data?.data?.map(async (content) => {
            mapNumberPdf = {
              MapNumber: await pdfByURL(
                `https://s-media.nyc.gov/agencies/dcp/assets/files/pdf/zoning/zoning-maps/map${content?.zonemap}.pdf`,
                "application/pdf"
              ),
            };
            obj = { ...obj, ...setAnotherFields(content, obj) };
            props.updateTheSidebarState({
              type: "mapNumberDownload",
              mapNumberPdf: mapNumberPdf,
            });
          });
          return GetPropertyVoilationData(nycApiData.nycData.binNum, obj);
        })
        .then((result) => {
          return GetPropertyComplaintData(nycApiData.nycData.binNum, result);
        })
        .then((result) => {
          hideLoader();
          props.handleOnChangeDeal({
            currentTarget: { name: "Merge", value: result },
          });
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      hideLoader();
    }
  };

  const GetPropertyComplaintData = (bin, obj1) => {
    const backendUrl = Config.backend_url + "getPropertycomplaintData?bin=";
    return new Promise((resolve, reject) => {
      axios
        .get(backendUrl + bin)
        .then((result) => {
          if (result.data?.length > 0) {
            if (result.data[0].disposition_code == "A3") {
              props.setStopWork("Yes");
              let obj = {};
              obj.StopWork = "Yes";
              obj1 = { ...obj1, ...obj };
              resolve(obj1);
            } else if (
              result.data[0].disposition_code == "L1" ||
              result.data[0].disposition_code == "L3"
            ) {
              let obj = {};
              obj.partial = true;
              obj.StopWork = "Yes";
              obj1 = { ...obj1, ...obj };
              props.setPartial(true);
              props.setStopWork("Yes");
              resolve(obj1);
            } else {
              let obj = {};
              obj.partial = false;
              obj.StopWork = "No";
              obj1 = { ...obj1, ...obj };
              props.setPartial(false);
              props.setStopWork("No");
              resolve(obj1);
            }
          } else {
            resolve(obj1);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getAndRemoveString = (str) => {
    if (str.startsWith("VW")) {
      return str.replace("VW", "");
    } else if (str.startsWith("VPW")) {
      return str.replace("VPW", "");
    } else {
      return str;
    }
  };

  const getPropertyViolation = async (req) => {
    try {
      const urlECBVoilation =
        "https://data.cityofnewyork.us/resource/6bgk-3dad.json?bin=" + req.bin;
      let res_ecb_violation = await axios.get(urlECBVoilation);
      res_ecb_violation = res_ecb_violation.data.filter((one) => {
        if (
          one.ecb_violation_status == "ACTIVE" &&
          (one.section_law_description1.includes("27-147") ||
            one.section_law_description1.includes("28-105.1") ||
            one.section_law_description1.includes("27-3018(B)"))
        ) {
          one.type = "ECB";
          return one;
        }
      });
      const urlDOBVoilation =
        "https://data.cityofnewyork.us/resource/3h2n-5cm9.json?bin=" + req.bin;
      let res_dob_violation = await axios.get(urlDOBVoilation);
      res_dob_violation = res_dob_violation.data.filter((one) => {
        if (
          one.violation_category.includes("ACTIVE") &&
          (one.violation_category.includes("VW-") ||
            one.violation_category.includes("VPW-"))
        ) {
          one.type = "DOB";
          return one;
        }
      });

      let data = [...res_dob_violation, ...res_ecb_violation];
      let returnArr = [];

      data.forEach(async (one) => {
        let obj = {};
        if (one.type == "ECB") {
          obj.violationNum = one.ecb_violation_number;
          obj.ltwocode = "";
          obj.Civilpenalty = "";
          obj.jobNumber = "";
          obj.invoiceNumber = "";
          obj.violationType = one.type;
        }
        if (one.type == "DOB") {
          obj.violationNum = getAndRemoveString(one.number);
          obj.ltwocode = "";
          obj.Civilpenalty = "";
          obj.jobNumber = "";
          obj.invoiceNumber = "";
          obj.isnNumber = one.isn_dob_bis_viol;
          obj.violationType = one.type;
        }
        returnArr.push(obj);
      });
      // returnArr = await this.updatedgetSaveDocumentAndGetPath(returnArr, req.bin);
      // returnArr = await this.getECBViolationPdf(returnArr);
      return returnArr;
    } catch (err) {
      return err;
    }
  };

  const GetPropertyVoilationData = (bin, obj1) => {
    return new Promise((resolve, reject) => {
      getPropertyViolation({ bin: bin })
        .then(async (data) => {
          if (
            location.state.action == "Update" ||
            props.sidebarCardsStatus.duplicateDeal.dealName != null
          ) {
            if (props.data.wwpViolation.length > 0) {
              props.updateTheSidebarState({
                type: "ViolationTabStatus",
                status: true,
              });
            } else {
              props.updateTheSidebarState({
                type: "ViolationTabStatus",
                status: false,
              });
            }
            props.setWithoutpermit(props.data.withoutpermit);
            props.setWWPViolation(props.data.wwpViolation);
          } else if (props.data.wwpViolation.length > 0) {
            props.setWWPViolation(props.data.wwpViolation);
            props.setWithoutpermit("Yes");
          } else {
            if (data?.length > 0) {
              let ob = props.sidebarCardsStatus.EditDocuments;
              let oldSidebarOb = { ...props.sidebarCardsStatus };
              let fileExtention = props.data.StreetName;
              let violationsDoc = [];
              let violationSummaryDocs = [];
              let invoiceDoc = [];
              let paidUnderJobDoc = [];
              let arr = [];
              let obj = {};
              data.forEach((one) => {
                one = { ...one };
                var unique = uuid();
                one.violationId = unique;
                arr.push(one);
                if (one.violationNum) {
                  violationsDoc.push({
                    filename:
                      fileExtention +
                      "_" +
                      one.violationType +
                      " VIOLATION COPY " +
                      one.violationNum +
                      ".PDF",
                    fill: null,
                    id: one.violationNum,
                  });
                  violationSummaryDocs.push({
                    filename:
                      fileExtention +
                      "_" +
                      one.violationType +
                      " VIOLATION SUMMARY " +
                      one.violationNum +
                      ".PDF",
                    fill: null,
                    id: one.violationNum,
                  });
                }
                if (one.invoiceNumber) {
                  let fileNameForInvoice =
                    fileExtention +
                    "_Violation Paid Invoice " +
                    one.invoiceNumber +
                    ".pdf";
                  invoiceDoc.push({
                    filename: fileNameForInvoice,
                    fill: null,
                    id: one.invoiceNumber,
                  });
                }
                if (one.jobNumber) {
                  let fileNameForJob =
                    fileExtention +
                    "_Violation Paid Application " +
                    one.jobNumber +
                    ".pdf";
                  paidUnderJobDoc.push({
                    filename: fileNameForJob,
                    fill: null,
                    id: one.jobNumber,
                  });
                }
              });
              oldSidebarOb = {
                ...oldSidebarOb,
                EditDocuments: {
                  ...ob,
                  violationDocs: violationsDoc,
                  violationSummaryDocs: violationSummaryDocs,
                  paidUnderJobDocs: paidUnderJobDoc,
                  invoiceDocs: invoiceDoc,
                },
              };
              props.updateTheSidebarState({
                type: "updateObject",
                status: oldSidebarOb,
                ViolationTabStatus: true,
              });
              obj.wwpViolation = arr;
              //props.updateTheSidebarState({type : "ViolationTabStatus", status : true})
              obj.withoutpermit = "Yes";
              obj1 = { ...obj1, ...obj };
              props.setWWPViolation(arr);
              props.setWithoutpermit("Yes");
              resolve(obj1);
            } else {
              let obj = {};
              obj.wwpViolation = data;
              obj.withoutpermit = "No";
              obj1 = { ...obj1, ...obj };
              props.updateTheSidebarState({
                type: "ViolationTabStatus",
                status: false,
              });
              props.setWWPViolation(data);
              props.setWithoutpermit("No");
              resolve(obj1);
            }
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const setAnotherFields = (content, obj1) => {
    let contentBlock;
    let contentLot;
    let obj = {};
    if (content.block?.length < 5 && content.block?.length != 0) {
      var addZero = "";
      const remain = 5 - content.block?.length;
      for (var i = 0; i < remain; i++) {
        addZero = addZero + "0";
      }
      contentBlock = addZero + content.block;
    } else {
      contentBlock = content.block;
    }

    if (content.lot?.length < 4 && content.lot?.length != 0) {
      var addZero = "";
      const remain = 4 - content.lot?.length;
      for (var i = 0; i < remain; i++) {
        addZero = addZero + "0";
      }
      contentLot = addZero + content.lot;
    } else {
      contentLot = content.block;
    }
    if (editDealData.Borough == "Manhattan") {
      editDealData.Borough = "1";
    } else if (editDealData.Borough == "Bronx") {
      editDealData.Borough = "2";
    } else if (editDealData.Borough == "Brooklyn") {
      editDealData.Borough = "3";
    } else if (editDealData.Borough == "Queens") {
      editDealData.Borough = "4";
    } else {
      editDealData.Borough = "5";
    }
    if (
      location.state.action == "Update" &&
      content.borocode == editDealData.Borough &&
      contentBlock == editDealData.blockNo &&
      contentLot == editDealData.lotNo
    ) {
      let zoningarr1 = [];
      let zoningarr2 = [];
      editDealData.zoningList.map((item, i) => {
        zoningarr1.push({
          label: item.zoningdistrict,
          value: item.zoningdistrict,
        });
        zoningarr2.push({ zoningdistrict: item.zoningdistrict });
        props.setZoningList(zoningarr2);
        dispatch(
          CreateDealData({
            ...createDealData,
            zoningList: [...createDealData.zoningList, zoningarr2],
          })
        );
        obj.zoningList = zoningarr2;
        props.handleOnChangeDeal({
          currentTarget: { name: "zoningList", value: zoningarr2 },
        });
      });
      setZoningDist(zoningarr1);

      // Special District

      let spclDistarr = [];
      let spclDistarr1 = [];
      editDealData.specialDistList.map((item, i) => {
        spclDistarr.push({ label: item.specialDist, value: item.specialDist });
        spclDistarr1.push({ specialDist: item.specialDist });
        props.setspecialDistList(spclDistarr1);
        dispatch(
          CreateDealData({
            ...createDealData,
            specialDistList: [...createDealData.specialDistList, spclDistarr1],
          })
        );
        obj.specialDistList = spclDistarr1;
        props.handleOnChangeDeal({
          currentTarget: { name: "specialDistList", value: spclDistarr1 },
        });
      });
      setSpecialDist(spclDistarr);

      let commercialarr = [];
      let commercialarr1 = [];
      editDealData.commercialList.map((item, i) => {
        commercialarr.push({
          label: item.commercialoverlay,
          value: item.commercialoverlay,
        });
        commercialarr1.push({ commercialoverlay: item.commercialoverlay });
        props.setCommercialList(commercialarr1);
        dispatch(
          CreateDealData({
            ...createDealData,
            commercialList: [...createDealData.commercialList, commercialarr1],
          })
        );
        obj.commercialList = commercialarr1;
        props.handleOnChangeDeal({
          currentTarget: { name: "commercialList", value: commercialarr1 },
        });
      });
      setcommercialOverlay(commercialarr);
      props.setLandmark(editDealData.landmark);
      dispatch(
        CreateDealData({ ...createDealData, landmark: editDealData.landmark })
      );
      obj.landmark = editDealData.landmark;

      props.setEnvRestrictions(editDealData.envRestrictions);
      props.setFloodHazard(editDealData.floodHazard);
      props.setCoastal(
        editDealData.coastal !== undefined &&
          editDealData.coastal !== "undefined"
      );
      props.setFreshWater(editDealData.freshWater);
      obj.floodHazard = editDealData.floodHazard;
      obj.freshWater = editDealData.freshWater;
      obj.coastal =
        editDealData.coastal !== undefined &&
        editDealData.coastal !== "undefined"
          ? editDealData.coastal
          : "No";
      obj.envRestrictions = editDealData.envRestrictions;
      dispatch(
        CreateDealData({
          ...createDealData,
          envRestrictions: editDealData.envRestrictions,
        })
      );

      props.setTidalWetland(editDealData.tidalWetland);
      obj.tidalWetland = editDealData.tidalWetland;
      dispatch(
        CreateDealData({
          ...createDealData,
          tidalWetland: editDealData.tidalWetland,
        })
      );
    } else {
      if (content.zonemap != undefined) {
        obj.zoiningMap = content.zonemap;
        props.setZoiningMap(content.zonemap);
      }

      if (
        content.zonedist1 != undefined &&
        content.zonedist2 != undefined &&
        content.zonedist3 != undefined &&
        content.zonedist4 != undefined
      ) {
        obj.zoningList = [
          { zoningdistrict: content.zonedist1 },
          { zoningdistrict: content.zonedist2 },
          { zoningdistrict: content.zonedist3 },
          { zoningdistrict: content.zonedist4 },
        ];
        props.setZoningList([
          { zoningdistrict: content.zonedist1 },
          { zoningdistrict: content.zonedist2 },
          { zoningdistrict: content.zonedist3 },
          { zoningdistrict: content.zonedist4 },
        ]);
        dispatch(
          CreateDealData({
            ...createDealData,
            zoningList: [
              ...createDealData.zoningList,
              { zoningdistrict: content.zonedist1 },
              { zoningdistrict: content.zonedist2 },
              { zoningdistrict: content.zonedist3 },
              { zoningdistrict: content.zonedist4 },
            ],
          })
        );
        setZoningDist([
          { label: content.zonedist1, value: content.zonedist1 },
          { label: content.zonedist2, value: content.zonedist2 },
          { label: content.zonedist3, value: content.zonedist3 },
          { label: content.zonedist4, value: content.zonedist4 },
        ]);
      } else if (
        content.zonedist1 != undefined &&
        content.zonedist2 != undefined &&
        content.zonedist3 != undefined
      ) {
        obj.zoningList = [
          { zoningdistrict: content.zonedist1 },
          { zoningdistrict: content.zonedist2 },
          { zoningdistrict: content.zonedist3 },
        ];

        props.setZoningList([
          { zoningdistrict: content.zonedist1 },
          { zoningdistrict: content.zonedist2 },
          { zoningdistrict: content.zonedist3 },
        ]);
        dispatch(
          CreateDealData({
            ...createDealData,
            zoningList: [
              ...createDealData.zoningList,
              { zoningdistrict: content.zonedist1 },
              { zoningdistrict: content.zonedist2 },
              { zoningdistrict: content.zonedist3 },
            ],
          })
        );
        setZoningDist([
          { label: content.zonedist1, value: content.zonedist1 },
          { label: content.zonedist2, value: content.zonedist2 },
          { label: content.zonedist3, value: content.zonedist3 },
        ]);
      } else if (
        content.zonedist1 != undefined &&
        content.zonedist2 != undefined
      ) {
        obj.zoningList = [
          { zoningdistrict: content.zonedist1 },
          { zoningdistrict: content.zonedist2 },
        ];

        props.setZoningList([
          { zoningdistrict: content.zonedist1 },
          { zoningdistrict: content.zonedist2 },
        ]);
        dispatch(
          CreateDealData({
            ...createDealData,
            zoningList: [
              ...createDealData.zoningList,
              { zoningdistrict: content.zonedist1 },
              { zoningdistrict: content.zonedist2 },
            ],
          })
        );
        setZoningDist([
          { label: content.zonedist1, value: content.zonedist1 },
          { label: content.zonedist2, value: content.zonedist2 },
        ]);
      } else if (content.zonedist1 != undefined) {
        obj.zoningList = [{ zoningdistrict: content.zonedist1 }];

        props.setZoningList([{ zoningdistrict: content.zonedist1 }]);
        dispatch(
          CreateDealData({
            ...createDealData,
            zoningList: [
              ...createDealData.zoningList,
              { zoningdistrict: content.zonedist1 },
            ],
          })
        );
        setZoningDist([{ label: content.zonedist1, value: content.zonedist1 }]);
      } else {
        obj.zoningList = [{ zoningdistrict: "N/A" }];

        props.setZoningList([{ zoningdistrict: "N/A" }]);
        dispatch(
          CreateDealData({
            ...createDealData,
            zoningList: [
              ...createDealData.zoningList,
              { zoningdistrict: "N/A" },
            ],
          })
        );
        setZoningDist([{ label: "N/A", value: "N/A" }]);
      }

      if (
        content.spdist1 != undefined &&
        content.spdist2 != undefined &&
        content.spdist3 != undefined
      ) {
        obj.specialDistList = [
          { specialDist: content.spdist1 },
          { specialDist: content.spdist2 },
          { specialDist: content.spdist3 },
        ];
        props.setspecialDistList([
          { specialDist: content.spdist1 },
          { specialDist: content.spdist2 },
          { specialDist: content.spdist3 },
        ]);
        dispatch(
          CreateDealData({
            ...createDealData,
            specialDistList: [
              ...createDealData.specialDistList,
              { specialDist: content.spdist1 },
              { specialDist: content.spdist2 },
              { specialDist: content.spdist3 },
            ],
          })
        );
        setSpecialDist([
          { label: content.spdist1, value: content.spdist1 },
          { label: content.spdist2, value: content.spdist2 },
          { label: content.spdist3, value: content.spdist3 },
        ]);
      } else if (content.spdist1 != undefined && content.spdist2 != undefined) {
        obj.specialDistList = [
          { specialDist: content.spdist1 },
          { specialDist: content.spdist2 },
        ];
        props.setspecialDistList([
          { specialDist: content.spdist1 },
          { specialDist: content.spdist2 },
        ]);
        dispatch(
          CreateDealData({
            ...createDealData,
            specialDistList: [
              ...createDealData.specialDistList,
              { specialDist: content.spdist1 },
              { specialDist: content.spdist2 },
            ],
          })
        );
        setSpecialDist([
          { label: content.spdist1, value: content.spdist1 },
          { label: content.spdist2, value: content.spdist2 },
        ]);
      } else if (content.spdist1 != undefined) {
        obj.specialDistList = [{ specialDist: content.spdist1 }];
        props.setspecialDistList([{ specialDist: content.spdist1 }]);
        dispatch(
          CreateDealData({
            ...createDealData,
            specialDistList: [
              ...createDealData.specialDistList,
              { specialDist: content.spdist1 },
            ],
          })
        );
        setSpecialDist([{ label: content.spdist1, value: content.spdist1 }]);
      } else {
        obj.specialDistList = [{ specialDist: "N/A" }];
        props.setspecialDistList([{ specialDist: "N/A" }]);
        dispatch(
          CreateDealData({
            ...createDealData,
            specialDistList: [
              ...createDealData.specialDistList,
              { specialDist: "N/A" },
            ],
          })
        );
        setSpecialDist([{ label: "N/A", value: "N/A" }]);
      }

      if (content.overlay2 != undefined) {
        obj.commercialList = [
          { commercialoverlay: content.overlay1 },
          { commercialoverlay: content.overlay2 },
        ];
        props.setCommercialList([
          { commercialoverlay: content.overlay1 },
          { commercialoverlay: content.overlay2 },
        ]);
        dispatch(
          CreateDealData({
            ...createDealData,
            commercialList: [
              ...createDealData.commercialList,
              { commercialoverlay: content.overlay1 },
              { commercialoverlay: content.overlay2 },
            ],
          })
        );
        setcommercialOverlay([
          { label: content.overlay1, value: content.overlay1 },
          { label: content.overlay2, value: content.overlay2 },
        ]);
      } else if (content.overlay1 != undefined) {
        obj.commercialList = [{ commercialoverlay: content.overlay1 }];
        props.setCommercialList([{ commercialoverlay: content.overlay1 }]);
        dispatch(
          CreateDealData({
            ...createDealData,
            commercialList: [
              ...createDealData.commercialList,
              { commercialoverlay: content.overlay1 },
            ],
          })
        );
        setcommercialOverlay([
          { label: content.overlay1, value: content.overlay1 },
        ]);
      } else {
        obj.commercialList = [{ commercialoverlay: "N/A" }];
        props.setCommercialList([{ commercialoverlay: "N/A" }]);
        dispatch(
          CreateDealData({
            ...createDealData,
            commercialList: [
              ...createDealData.commercialList,
              { commercialoverlay: "N/A" },
            ],
          })
        );
        setcommercialOverlay([{ label: "N/A", value: "N/A" }]);
      }
    }
    obj1 = { ...obj1, ...obj };
    return obj1;
  };

  const getSelectedValue = (btnClassValue) => {
    if (
      undefined === btnClassValue ||
      "" === btnClassValue ||
      "N/A" === btnClassValue ||
      "UNKNOWN" === btnClassValue
    ) {
      return "plus-img-size"; //"plus-hidden";
    } else {
      return "plus-img-size";
    }
  };

  const nycpropertytoggle = () => {
    props.settoggleState("nycproperty");
    props.setListState("nycproperty");
    setNycpropertytoggle(!nycpropertystate);
  };

  useEffect(() => {
    props.toggleState == "nycproperty"
      ? setNycpropertytoggle(true)
      : setNycpropertytoggle(false);
  }, [props.toggleState]);

  useEffect(() => {
    getData();
  }, [props.data]);

  const getData = () => {
    if (props.data != null) {
      props.setBlockNo(props.data.blockNo);
      props.setLotNo(props.data.lotNo);
      props.setBinNo(props.data.binNo);
      props.setCommNo(props.data.community);
      props.setDwellingUnits(props.data.dwellingUnits);
      props.setOccupancy(props.data.Occupancy);
      props.setTidalWetland(props.data.tidalWetland);
      props.setFreshWater(props.data.freshWater);
      props.setCoastal(props.data.coastal);
      props.setFiredistrict(props.data.firedistrict);
      props.setFloodHazard(props.data.floodHazard);
      props.setLandmark(props.data.landmark);
      dispatch(
        CreateDealData({
          ...createDealData,
          landmark: nycApiData?.nycData?.landStatus,
        })
      );
      props.setEnvRestrictions(props.data.envRestrictions);
      props.setConstruction(props.data.Construction);
      props.setZoiningMap(props.data.zoiningMap);
    }
  };

  return (
    <Col sm={12}>
      <Button
        onClick={nycpropertytoggle}
        className={nycpropertystate ? "btn-100-active" : "btn-100"}
      >
        NYC Property Information
        <div className="float-right">
          <Input
            type="checkbox"
            className={
              handleNycPropertyCheck()
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={handleNycPropertyCheck()}
            onChange={() => {
              if (
                props.data.dwellingUnits == "" ||
                props.data.dwellingUnits == undefined
              ) {
                setValidation({ ...validation, dwellingUnits: "-1" });
              }
              setSkipNycProperty(!skipNycProperty);
            }}
          />
          {nycpropertystate ? (
            <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
          )}
        </div>
      </Button>
      <Collapse isOpen={nycpropertystate}>
        <Card className="createdeal-card">
          <CardBody>
            {loading ? <Loader /> : null}
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="projectnumber">
                    Block<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="number"
                    name="blockNo"
                    className="arrow-hide createdeal-textfield form-control"
                    id="projectnumber"
                    placeholder="*****"
                    style={
                      validation.blockNo == "-1" ? { ...validationCss } : {}
                    }
                    value={props.data ? props.data.blockNo : ""}
                    onChange={(e) => {
                      if (e.target.value.length > 5) {
                        e.preventDefault();
                      } else {
                        props.handleOnChangeDeal(e);
                        onChangeBlockNo(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value == undefined || e.target.value == "") {
                        setValidation({ ...validation, blockNo: "-1" });
                      }
                      onBlurBlockNo(e.target.value);
                    }}
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === ".") &&
                      evt.preventDefault()
                    }
                  />
                </Col>
                <Col sm={4}>
                  <span
                    className={
                      validation.blockNo == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    Enter 5 digit Block number.
                  </span>
                </Col>
                <Col sm={6}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="projectdate">
                    Lot<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="number"
                    name="lotNo"
                    className="arrow-hide createdeal-textfield"
                    id="projectnumber"
                    placeholder="****"
                    style={validation.lotNo == "-1" ? { ...validationCss } : {}}
                    value={props.data ? props.data.lotNo : ""}
                    onChange={(e) => {
                      if (e.target.value.length > 4) {
                        e.preventDefault();
                      } else {
                        props.handleOnChangeDeal(e);
                        onChangeLotNo(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value == undefined || e.target.value == "") {
                        setValidation({ ...validation, lotNo: "-1" });
                      }
                      onBlurLotNo(e.target.value);
                    }}
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === ".") &&
                      evt.preventDefault()
                    }
                  />
                </Col>
                <Col sm={4}>
                  <span
                    className={
                      validation.lotNo == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    Enter 4 digit Lot number.
                  </span>
                </Col>
                <Col sm={6}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="projectdate">
                    BIN<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="number"
                    name="binNo"
                    className="arrow-hide createdeal-textfield"
                    id="projectnumber"
                    style={validation.binNo == "-1" ? { ...validationCss } : {}}
                    placeholder="*******"
                    value={props.data ? props.data.binNo : ""}
                    onChange={(e) => {
                      if (e.target.value.length > 7) {
                        e.preventDefault();
                      } else {
                        props.handleOnChangeDeal(e);
                        onChangeBinNo(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value == undefined || e.target.value == "") {
                        setValidation({ ...validation, binNo: "-1" });
                      } else {
                        setValidation({ ...validation, binNo: "1" });
                      }
                      onBlurBinNo(e.target.value);
                    }}
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === ".") &&
                      evt.preventDefault()
                    }
                  />
                </Col>
                <Col sm={4}>
                  <span
                    className={
                      validation.binNo == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    Enter 7 digit BIN number.
                  </span>
                </Col>
                <Col sm={2}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="communityboard">
                    Community Board<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="number"
                    name="community"
                    className="arrow-hide createdeal-textfield"
                    id="communityboard"
                    style={
                      validation.community == "-1" ? { ...validationCss } : {}
                    }
                    placeholder="***"
                    value={props.data ? props.data.community : ""}
                    onChange={(e) => {
                      if (e.target.value.length > 3) {
                        e.preventDefault();
                      } else {
                        props.handleOnChangeDeal(e);
                        onChangeCommunity(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value == undefined || e.target.value == "") {
                        setValidation({ ...validation, community: "-1" });
                      } else {
                        setValidation({ ...validation, community: "1" });
                      }
                      onBlurCommNo(e.target.value);
                    }}
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === ".") &&
                      evt.preventDefault()
                    }
                  />
                </Col>
                <Col sm={4}>
                  <span
                    className={
                      validation.community == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    Enter 3 digit Board number.
                  </span>
                </Col>

                <Col sm={3}></Col>
              </Row>
            </FormGroup>

            <div className="row padtop-15">
              <Col sm={4}></Col>
              <Label className="text-bold code-border" for="2008code" sm={2}>
                <b>2014/2008 Code</b>
              </Label>
              <Col sm={6}></Col>
            </div>

            <Form className="code-border2 mb-3">
              <Row>
                <Col sm={4}>
                  <Label className="text-bold code-pad" for="occupancy">
                    Occupancy Classification of Building
                  </Label>
                </Col>
                {/* <Col sm={1}>
                   
                  </Col> */}
                <Col sm={2} className="border-left-right code-pad">
                  <div className="col-2 d-flex ms-2 occupancy">
                    <Label className="text-bold2-left text-align-center">
                      Yes
                    </Label>
                    <Input
                      type="radio"
                      name="Occupancy"
                      value="Yes"
                      checked={
                        props.data ? props.data.Occupancy === "Yes" : false
                      }
                      onChange={(e) => {
                        props.handleOnChangeDeal(e);
                        props.setOccupancy(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-2 d-flex occupancy">
                    <Label className="text-bold2-left">No</Label>
                    <Input
                      type="radio"
                      name="Occupancy"
                      value="No"
                      checked={
                        props.data ? props.data.Occupancy === "No" : false
                      }
                      onChange={(e) => {
                        props.handleOnChangeDeal(e);
                        props.setOccupancy(e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col sm={1}></Col>
                <Col sm={4} className="code-pad">
                  {(props.data ? props.data.Occupancy == "Yes" : false)
                    ? occupancyYesOption
                    : occupancyNoOption}
                </Col>
                <Col sm={1}></Col>
              </Row>

              <Row>
                <Col sm={4}>
                  <Label className="text-bold code-pad" for="construction">
                    Construction Classification Code
                  </Label>
                </Col>
                <Col sm={2} className="border-left-right code-pad">
                  <div className="col-2 d-flex construction">
                    <Label className="text-bold2-left">Yes</Label>
                    <Input
                      type="radio"
                      name="Construction"
                      value="Yes"
                      checked={
                        props.data ? props.data.Construction === "Yes" : false
                      }
                      onChange={(e) => {
                        props.handleOnChangeDeal(e);
                        props.setConstruction(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-2 d-flex construction">
                    <Label className="text-bold2-left">No</Label>
                    <Input
                      type="radio"
                      name="Construction"
                      value="No"
                      checked={
                        props.data ? props.data.Construction === "No" : false
                      }
                      onChange={(e) => {
                        props.handleOnChangeDeal(e);
                        props.setConstruction(e.target.value);
                      }}
                    />
                  </div>
                </Col>

                <Col sm={1}></Col>
                <Col sm={4} className="code-pad">
                  {(props.data ? props.data.Construction == "Yes" : false)
                    ? constructionYesOption
                    : constructionNoOption}
                </Col>
                <Col sm={1}></Col>
              </Row>
            </Form>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="multipledwelling">
                    Multiple Dwelling Classification
                  </Label>
                </Col>
                <Col sm={5}>{multipledwelling}</Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="dwellingunits">
                    Number of Dwelling Units<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="number"
                    name="dwellingUnits"
                    id="dwellingunits"
                    className="createdeal-textfield"
                    style={
                      validation.dwellingUnits == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    value={props.data ? props.data.dwellingUnits : ""}
                    onChange={(e) => {
                      // e.target.value = e.target.value < 0 ? '' :  e.target.value;
                      if (e.target.value >= 0) {
                        setValidation({ ...validation, dwellingUnits: "1" });
                        props.handleOnChangeDeal(e);
                        props.setDwellingUnits(e.target.value);
                      } else {
                        e.target.value = "";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value == undefined || e.target.value == "") {
                        setValidation({ ...validation, dwellingUnits: "-1" });
                      } else {
                        setValidation({ ...validation, dwellingUnits: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={4}>
                  <span
                    className={
                      validation.dwellingUnits == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    Please select Dwelling Units.
                  </span>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="buildingheight">
                    Building Height (ft.)
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="number"
                    // defaultValue={props.data.buildingheight}
                    value={props.data ? props.data.buildingheight : ""}
                    name="buildingheight"
                    onChange={(e) =>
                      e.target.value < 0 ? "" : props.handleOnChangeDeal(e)
                    }
                    id="buildingheight"
                    className="createdeal-textfield"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty">
                  <Label className="text-bold" for="storynumber">
                    Number of Stories
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    type="number"
                    // defaultValue={props.data.storynumber}
                    value={props.data ? props.data.storynumber : ""}
                    onChange={(e) =>
                      e.target.value < 0 ? "" : props.handleOnChangeDeal(e)
                    }
                    name="storynumber"
                    id="storynumber"
                    className="createdeal-textfield"
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup row className=" marb-16 padtop-15">
              <Row>
                <Col sm={2} className="width20-nycproperty my-2">
                  <Label className="text-bold" for="zoning">
                    Zoning District(s)<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                  {zoningDist
                    ? zoningDist.map((x, i) => {
                        const options = getOptionsToRender(
                          zoningDist,
                          zoningDistOptions
                        );
                        return (
                          // <Row className="marb-16">
                          // <FormGroup row>
                          <Row className="my-2">
                            <Col sm={9} className="pad-right-38">
                              <CustomSelectComponent
                                value={{ label: x?.value }}
                                options={options}
                                onSelect={(value) =>
                                  handleZoningChange(value, i)
                                }
                                key={i}
                                styles={customstyle}
                              />
                            </Col>

                            {zoningDist
                              ? zoningDist?.length !== 1 && (
                                  <Col sm={1} className="p-0">
                                    {
                                      <img
                                        src={minus}
                                        onClick={() => handleZoningRemove(i)}
                                        className="minus-img-size"
                                      />
                                    }
                                  </Col>
                                )
                              : ""}
                            {zoningDist
                              ? zoningDist?.length - 1 === i &&
                                zoningDist?.length < 4 &&
                                zoningDist[i] &&
                                zoningDist[i].value !== "N/A" && (
                                  <Col sm={1} className="p-0">
                                    {
                                      <img
                                        src={plus}
                                        onClick={() => handleAddZoning(i)}
                                        className={getSelectedValue({
                                          label: zoningDist[i],
                                          value: zoningDist[i],
                                        })}
                                      />
                                    }
                                  </Col>
                                )
                              : ""}
                          </Row>
                        );
                      })
                    : ""}
                </Col>
              </Row>
            </FormGroup>

            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty my-2">
                  <Label className="text-bold" for="overlay">
                    Commercial Overlay(s)<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                  {commercialOverlay
                    ? commercialOverlay.map((x, i) => {
                        const options = getOptionsCommercial(
                          commercialOverlay,
                          commercialList
                        );
                        return (
                          <Row className="my-2">
                            <Col sm={9} className="pad-right-38">
                              <CustomSelectComponent
                                value={{ label: x?.value }}
                                options={options}
                                onSelect={(value) =>
                                  handleCommercialChange(value, i)
                                }
                                key={i}
                                styles={customstyle}
                              />
                            </Col>

                            {commercialOverlay
                              ? commercialOverlay?.length !== 1 && (
                                  <Col sm={1} className="p-0">
                                    {
                                      <img
                                        src={minus}
                                        onClick={() =>
                                          handleCommercialRemove(i)
                                        }
                                        className="minus-img-size"
                                      />
                                    }
                                  </Col>
                                )
                              : ""}
                            {commercialOverlay
                              ? commercialOverlay?.length - 1 === i &&
                                commercialOverlay?.length < 2 &&
                                commercialOverlay[i] &&
                                commercialOverlay[i].value !== "N/A" && (
                                  <Col sm={1} className="p-0">
                                    {
                                      <img
                                        src={plus}
                                        onClick={handleAddCommercial}
                                        className={getSelectedValue({
                                          label: commercialOverlay[i],
                                          value: commercialOverlay[i],
                                        })}
                                      />
                                    }
                                  </Col>
                                )
                              : ""}
                          </Row>
                        );
                      })
                    : ""}
                </Col>
              </Row>
            </FormGroup>

            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3} className="width20-nycproperty my-2">
                  <Label className="text-bold" for="district">
                    Special District(s)<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                  {specialDist
                    ? specialDist.map((x, i) => {
                        const options = getOptionsToRenderSpeclDist(
                          specialDist,
                          specialDistOptions
                        );
                        return (
                          <Row className="my-2">
                            <Col sm={9} className="pad-right-38">
                              <CustomSelectComponent
                                value={{ label: x?.value }}
                                options={options}
                                onSelect={(value) =>
                                  handlespecialDistListChange(value, i)
                                }
                                key={i}
                                styles={customstyle}
                              />
                            </Col>

                            {specialDist
                              ? specialDist?.length !== 1 && (
                                  <Col sm={1} className="p-0">
                                    {
                                      <img
                                        src={minus}
                                        onClick={() =>
                                          handlespecialDistListRemove(i)
                                        }
                                        className="minus-img-size"
                                      />
                                    }
                                  </Col>
                                )
                              : ""}
                            {specialDist
                              ? specialDist?.length - 1 === i &&
                                specialDist?.length < 3 &&
                                specialDist[i] &&
                                specialDist[i].value !== "N/A" && (
                                  <Col sm={1} className="p-0">
                                    {
                                      <img
                                        src={plus}
                                        onClick={handleAddspecialDistList}
                                        className={getSelectedValue({
                                          label: specialDist[i],
                                          value: specialDist[i],
                                        })}
                                      />
                                    }
                                  </Col>
                                )
                              : ""}
                          </Row>
                        );
                      })
                    : ""}
                </Col>
              </Row>
            </FormGroup>

            <FormGroup row className="marb-16 padtop-15 ">
              <Row>
                <Label
                  className="text-bold width20-nycproperty"
                  for="zoningmapno"
                  sm={2}
                >
                  Zoning Map Number<span className="red-text">*</span>
                </Label>
                <Col sm={3}>
                  <Input
                    type="text"
                    name="zoiningMap"
                    id="zoningmapno"
                    className="createdeal-textfield"
                    style={
                      validation.zoiningMap == "-1" ? { ...validationCss } : {}
                    }
                    value={
                      props?.data ? props?.data?.zoiningMap?.toUpperCase() : ""
                    }
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      props.handleOnChangeDeal(e);
                      props.setZoiningMap(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (e.target.value == undefined || e.target.value == "") {
                        setValidation({ ...validation, zoiningMap: "-1" });
                      } else {
                        setValidation({ ...validation, zoiningMap: "1" });
                      }
                    }}
                  />
                </Col>

                <Col sm={7}></Col>
              </Row>
            </FormGroup>

            <FormGroup className="marb-16 padtop-15">
              <Row>
                <Col sm={6}>
                  <FormGroup row className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Label className="text-bold " for="tidalwetlands">
                          Tidal Wetlands<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">Yes</Label>
                        <Input
                          type="radio"
                          name="tidalWetland"
                          value="Yes"
                          checked={
                            props.data
                              ? props.data.tidalWetland === "Yes"
                              : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setTidalWetland(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">No</Label>
                        <Input
                          type="radio"
                          name="tidalWetland"
                          value="No"
                          checked={
                            props.data
                              ? props.data.tidalWetland === "No"
                              : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setTidalWetland(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Label className="text-bold" for="freshwaterwetlands">
                          Fresh Water Wetlands
                          <span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">Yes</Label>
                        <Input
                          type="radio"
                          name="freshWater"
                          value="Yes"
                          checked={
                            props.data ? props.data.freshWater === "Yes" : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setFreshWater(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">No</Label>
                        <Input
                          type="radio"
                          name="freshWater"
                          value="No"
                          checked={
                            props.data ? props.data.freshWater === "No" : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setFreshWater(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Label className="text-bold" for="coastal">
                          Coastal Erosion Hazard Area
                          <span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">Yes</Label>
                        <Input
                          type="radio"
                          name="coastal"
                          value="Yes"
                          checked={
                            props.data ? props.data.coastal === "Yes" : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setCoastal(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">No</Label>
                        <Input
                          type="radio"
                          name="coastal"
                          value="No"
                          checked={
                            props.data ? props.data.coastal === "No" : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setCoastal(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Label className="text-bold" for="firedistrict">
                          Special Fire District
                          <span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">Yes</Label>
                        <Input
                          type="radio"
                          name="firedistrict"
                          value="Yes"
                          checked={
                            props.data
                              ? props.data.firedistrict === "Yes"
                              : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setFiredistrict(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">No</Label>
                        <Input
                          type="radio"
                          name="firedistrict"
                          value="No"
                          checked={
                            props.data
                              ? props.data.firedistrict === "No"
                              : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setFiredistrict(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup row className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Label className="text-bold" for="floodhazard">
                          Flood Hazard Area<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">Yes</Label>
                        <Input
                          type="radio"
                          name="floodHazard"
                          value="Yes"
                          checked={
                            props.data
                              ? props.data.floodHazard === "Yes"
                              : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setFloodHazard(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">No</Label>
                        <Input
                          type="radio"
                          name="floodHazard"
                          value="No"
                          checked={
                            props.data ? props.data.floodHazard === "No" : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setFloodHazard(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Label className="text-bold" for="landmark">
                          Landmark<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">Yes</Label>
                        <Input
                          type="radio"
                          name="landmark"
                          value="Yes"
                          checked={
                            props.data ? props.data.landmark === "Yes" : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setLandmark(e.target.value);
                            dispatch(
                              CreateDealData({
                                ...createDealData,
                                landmark: e.target.value,
                              })
                            );
                          }}
                        />
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">No</Label>
                        <Input
                          type="radio"
                          name="landmark"
                          value="No"
                          checked={
                            props.data ? props.data.landmark === "No" : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setLandmark(e.target.value);
                            dispatch(
                              CreateDealData({
                                ...createDealData,
                                landmark: e.target.value,
                              })
                            );
                          }}
                        />
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16 padtop-15">
                    <Row>
                      <Col sm={6}>
                        <Label className="text-bold" for="envrestrictions">
                          Environmental Restrictions
                          <span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">Yes</Label>
                        <Input
                          type="radio"
                          name="envRestrictions"
                          value="Yes"
                          checked={
                            props.data
                              ? props.data.envRestrictions === "Yes"
                              : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setEnvRestrictions(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={2} className="radioStyle">
                        <Label className="text-bold2">No</Label>
                        <Input
                          type="radio"
                          name="envRestrictions"
                          value="No"
                          checked={
                            props.data
                              ? props.data.envRestrictions === "No"
                              : false
                          }
                          onChange={(e) => {
                            props.handleOnChangeDeal(e);
                            props.setEnvRestrictions(e.target.value);
                          }}
                        />
                      </Col>
                      <Col sm={3}></Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
}

export default NycProperty;
