import moment from "moment";
import httpService from "../../services/http.service";
import axios from "axios";
import Config from '../../config.json'
import { getSystemTimezone } from "../../components/HR/TimeClock/Util";

class CalendarAPI {

    GetAllCalendars() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://calendar.zoho.com/api/v1/calendars",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    if(result.data&&(result.data.calendars.length>0)){
                        resolve(result.data.calendars[0]);
                    }else{
                        resolve({});
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    CreateEvent(calenderId,eventData) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'post',
                url: "https://calendar.zoho.com/api/v1/calendars/"+calenderId+"/events",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {key:'eventdata',content:JSON.stringify(eventData.eventdata)}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.events);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    UpdateEvent(calenderId,uid,eventData) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'put',
                url: "https://calendar.zoho.com/api/v1/calendars/"+calenderId+"/events/"+uid,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {eventdata:JSON.stringify(eventData)}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.events);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


    DeleteEvent(calenderId,uid,etag,reccurenceId,editType) {
        return new Promise((resolve, reject) => {
            let url="https://calendar.zoho.com/api/v1/calendars/"+calenderId+"/events/"+uid
            if(reccurenceId){
                const eventdata = {
                    "uid": uid,
                    "recurrenceid": reccurenceId,
                    "etag": etag,
                    "recurrence_edittype": editType ? editType : "only"
                };
                const encodedEventData = encodeURIComponent(JSON.stringify(eventdata));
                if(editType){
                    url= `${url}?eventdata=${encodedEventData}`
                }else{
                    url=`${url}?eventdata=${encodedEventData}`
                }
            }
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: url,
                headers: editType ? { "Authorization": localStorage.getItem('token') } :  { "Authorization": localStorage.getItem('token'),etag:etag },
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.events);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


    GetEventDetail(calenderId,uid) {
        return new Promise((resolve, reject) => {
            let url="https://calendar.zoho.com/api/v1/calendars/"+calenderId+"/events/"+uid;
            let config = {
                Type: 'Get',
                method: 'GET',
                url: url,
                headers: { "Authorization": localStorage.getItem('token')},
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data.events);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetSearchEvents(calenderId,text, getDates) {
        let startdate = moment(getDates.start).utc().format('YYYYMMDDTHHmmss[Z]');
        let enddate = moment(getDates.end).utc().format('YYYYMMDDTHHmmss[Z]');
        return new Promise((resolve, reject) => {
            // let url="http://calendar.zoho.com/api/v1/calendars/"+calenderId+"/search?searchtext="+text;
            let url=`http://calendar.zoho.com/api/v1/calendars/${calenderId}/search?searchtext=${text}&start=${startdate}&end=${enddate}`;
            let config = {
                Type: 'Get',
                method: 'GET',
                url: url,
                headers: { "Authorization": localStorage.getItem('token')},
            }
            httpService.post('task', config)
                .then((result) => {
                    if(result.data.search.filter(one=>one.message=='No events found.').length>0){
                        resolve([]);
                    }else{
                        resolve(result.data.search);
                    }
                   
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


    GetAllEvents(calenderId,rangeObj) {
        const params = {
            range: rangeObj
        };
        let timezone=getSystemTimezone();
        const encodedParams = new URLSearchParams(params).toString();
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: `https://calendar.zoho.com/api/v1/calendars/${calenderId}/events?${encodedParams}&byinstance=${true}&timezone=${timezone?timezone:"America/New_York"}`,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data.events);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    GetAllEventsWithoutTime(calenderId) {
        let timezone=getSystemTimezone();
        // const encodedParams = new URLSearchParams(params).toString();
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: `https://calendar.zoho.com/api/v1/calendars/${calenderId}/events?byinstance=${true}&timezone=${timezone?timezone:"America/New_York"}`,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data.events);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    UploadCalendarAttachement(file) {
        const formData = new FormData();
        // file.forEach((e) => {
            formData.append("files", file);
        // });
        return new Promise((resolve, reject) => {
            axios.post(Config.backend_url+`uploadMultipartFileForCalendar`,formData,
            {headers: { 
                'content-type': 'multipart/form-data',
                "token": localStorage.getItem('token') 
            }})
            .then((result) => {
                 
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    } 

    AttachmentDownload(calenderId,uid,fileId,mode) {
        return new Promise((resolve, reject) => {
            // let url="https://calendar.zoho.com/api/v1/calendars/"+calenderId+"/events/"+uid;
            let url=`https://calendar.zoho.com/api/v1/calendars/${calenderId}/events/${uid}/attachment/${fileId}?apimode=${mode}`;
            let config = {
                Type: 'Get',
                method: 'GET',
                url: url,
                headers: { "Authorization": localStorage.getItem('token'),
                    "Content-Type": "application/json" 
                },
            }
            httpService.post('downloadPdfByURL', config)
                .then((result) => {
                    resolve(result.data?.split("base64,")[1]);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    DeletedAttachment(calenderId,uid,eventData) {
        const encodedEventData = encodeURIComponent(JSON.stringify(eventData));

        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'put',
                // url: "https://calendar.zoho.com/api/v1/calendars/"+calenderId+"/events/"+uid,
                url: `https://calendar.zoho.com/api/v1/calendars/${calenderId}/events/${uid}?eventdata=${encodedEventData}`,
                headers: { "Authorization": localStorage.getItem('token') },
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.events);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
}

export default new CalendarAPI();