import { faEye, faImage, faUser } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown, faArrowRight, faGreaterThan, faLink, faMinusCircle, faSquare, faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './activity.css'
import TaskService from '../../common/util/TaskService';
import UserService from '../../common/util/UserService';

const ActvityCard = ({ activityFor, obj, dealObj, history }) => {
    const priorityValues = [
        { name: "None", color: "lightgray" },
        { name: "High", color: "#e50000" },
        { name: "Medium", color: "#f7c700" },
        { name: "Low", color: "blue" },
    ];
    const [taskLayout, setTaskLayout] = useState({
        status_details: [
            // {
            //   is_start: true,
            //   color: "#f56b62",
            //   name: "Pending",
            //   id: "1716273000002029049",
            //   is_default: false,
            //   type: "open",
            // },
            {
                is_start: false,
                color: "#a7bcee",
                name: "Not Started",
                id: "1716273000002029045",
                is_default: false,
                type: "open",
            },
            {
                is_start: false,
                color: "#fbc11e",
                name: "To Do",
                id: "1716273000002029041",
                is_default: false,
                type: "open",
            },
            {
                is_start: false,
                color: "#08aeea",
                name: "In Progress",
                id: "1716273000000031001",
                is_default: true,
                type: "open",
            },
            {
                is_start: false,
                color: "#98ca3c",
                name: "Completed",
                id: "1716273000002029047",
                is_default: false,
                type: "closed",
            },
            {
                is_start: false,
                color: "#a9b2c0",
                name: "Not Required",
                id: "1716273000002029043",
                is_default: false,
                type: "closed",
            },
        ]
    })
    // const getActivityStatement = (activity) => {
    //     // if (activity.state == 'bulkupdatetask') {
    //     if (activity.change_info) {
    //         if (activity.change_info.status) {
    //             return <>
    //                 <div class="status-badge">
    //                     CHANGED STATUS
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div class="start-badge status-badge">
    //                         {activity.change_info.status ? activity.change_info.status.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div class="complete-badge status-badge start-badge ">
    //                         {activity.change_info.status ? activity.change_info.status.new : ""}
    //                     </div>
    //                 </div>

    //             </>
    //         }
    //         else if (activity.change_info.stdate) {
    //             return <>
    //              <div class="status-badge">
    //                     UPDATED START DATE
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div>
    //                         {activity.change_info.stdate ? activity.change_info.stdate.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div>
    //                         {activity.change_info.stdate ? activity.change_info.stdate.new : ""}
    //                     </div>
    //                 </div>
    //             </>


    //           }
    //           else if (activity.change_info.enddate) {
    //             return <>
    //              <div class="status-badge">
    //                     UPDATED END DATE
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div>
    //                         {activity.change_info.enddate ? activity.change_info.enddate.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div >
    //                         {activity.change_info.enddate ? activity.change_info.enddate.new : ""}
    //                     </div>
    //                 </div>
    //           </>

    //           }
    //         else if(activity.change_info.priority){
    //            return <>
    //             <div class="status-badge">
    //                     CHANGED PRIORITY
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div class="start-badge status-badge">
    //                         {activity.change_info.priority ? activity.change_info.priority.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div class="complete-badge status-badge start-badge ">
    //                         {activity.change_info.priority ? activity.change_info.priority.new : ""}
    //                     </div>
    //                 </div>
    //            </> 
    //         }
    //         else if (activity.change_info.duration) {
    //             return <>
    //              <div class="status-badge">
    //                     CHANGED DAYS
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div>
    //                         {activity.change_info.duration ? activity.change_info.duration.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div >
    //                         {activity.change_info.duration ? activity.change_info.duration.new : ""}
    //                     </div>
    //                 </div>
    //           </>

    //           }
    //         // else {
    //         //     return <></>
    //         // }

    //     }


    //     // }
    //     else if(activity.activity_for=="task_comment"){
    //         return <>
    //         <div class="status-badge">
    //            COMMENTED
    //         </div>
    //         <div class="d-flex align-items-center px-3">
    //             {activity.name}
    //         </div>
    //     </> 
    //     }

    //     else if (activity.state == 'new') {
    //         return <>
    //             <div class="status-badge">
    //                 CREATED TASK
    //             </div>
    //             <div class="d-flex align-items-center px-3">
    //                 {activity.name}
    //             </div>
    //         </>
    //     } 

    //     else{
    //         return <>
    //         <div class="status-badge">
    //             ADDED COMMENT
    //         </div>
    //         <div>
    //             {activity.name ?activity.name : "Task"}
    //         </div>
    //         </>
    //     }



    // }

    // }

    const GetStatusColor = (value) => {
        if (taskLayout && taskLayout.status_details) {
            let values = [...taskLayout.status_details]
            values = values.filter(one => one.name == value);
            if (values.length > 0) {
                return values[0].color
            } else {
                return 'gray'
            }
        }

    }

    const GetPriorityColor = (value) => {
        let values = [...priorityValues]
        values = values.filter(one => one.name == value);
        if (values.length > 0) {
            return values[0].color
        } else {
            return 'gray'
        }
    }
    const getStatementForTask = (one) => {
        //  return <span>You change {one.state } from {one.new_value}  to  {one.old_value}</span>
        let returnValue = <></>
        if (one.state === "moveto") {
            returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a>  updated tasklist from {one.old_value.tasklist.name}&nbsp;to&nbsp;{one.new_value.tasklist.name} <p></p></span>
        }
        if (one.state === "Comment add") {
            returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> added Comment {one.name} </span>
        }
        else if (one.state === "Description updated") {
            returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a>  {one.state} from {one.old_value}&nbsp;to&nbsp;{one.new_value} <p></p></span>
        }
        else if (one.state === "Priority") {
            returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated {one.state} from {one.old_value} <FontAwesomeIcon
                color={GetPriorityColor(one.old_value)} icon={faFlagSolid} />&nbsp;to&nbsp;{one.new_value} <FontAwesomeIcon color={GetPriorityColor(one.new_value)} icon={faFlagSolid} /> <p></p></span>
        }
        else if (one.state === "Task Title") {
            returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated {one.state} from {one.old_value}&nbsp;to&nbsp;{one.new_value} <p></p></span>
        }
        else if (one.state === "new") {
            returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> added {one.name}  <p>{one.affected_msg}</p></span>
        }
        else if (one.old_value && one.old_value.tasklist && one.old_value.tasklist.name) {
            returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a>  updated tasklist from {one.old_value.tasklist.name}&nbsp;to&nbsp;{one.new_value.tasklist.name} <p></p></span>
        }
        else if (one.change_info) {
            if (one.change_info.priority) {

                returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated Priority from {one.change_info.priority.old} <FontAwesomeIcon color={GetPriorityColor(one.change_info.priority.old)} icon={faFlagSolid} />&nbsp;to&nbsp;{one.change_info.priority.new} <FontAwesomeIcon color={GetPriorityColor(one.change_info.priority.new)} icon={faFlagSolid} /> <p></p></span>
            }
            else if (one.change_info.status) {
                returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated Status from
                    <span className="name-tasklist-label" style={{ borderRadius: 5, padding: 3, backgroundColor: GetStatusColor(one.change_info.status.old), color: 'white', marginLeft: 10 }}>{one.change_info.status.old}</span>&nbsp;to&nbsp;<div><span className="name-tasklist-label" style={{ borderRadius: 5, padding: 3, backgroundColor: GetStatusColor(one.change_info.status.new), color: 'white' }}>{one.change_info.status.new}</span></div> <p></p></span>
            }
            else if (one.change_info.customfield) {
                returnValue = one.change_info.customfield.map((obj) => {
                    return <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated {obj.fieldName} from {obj.old}&nbsp;to&nbsp;{obj.new} <p></p></span>
                })
            }
            else if (one.change_info.stdate) {
                return <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated Start Date from {one.change_info.stdate.old}&nbsp;to&nbsp;{one.change_info.stdate.new} </span>

            }
            else if (one.change_info.enddate) {
                return <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated End Date from {one.change_info.enddate.old}&nbsp;to&nbsp;{one.change_info.enddate.new} </span>

            }
            else if (one.change_info.owner) {
                return <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated Assignee from {one.change_info.owner.old}&nbsp;to&nbsp;{one.change_info.owner.new} </span>

            }
            else {
                returnValue = <span><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> added {one.name}  <p></p></span>
            }
        }
        else {
            returnValue = <span style={{ lineBreak: 'anywhere' }}><a onClick={() => history.push({ pathname: '/app/setting/users/4695950000000296001', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> updated {one.state} from {one.old_value}&nbsp;to&nbsp;{one.new_value} <p></p></span>
        }
        return returnValue;

    }


    // const getActivityStatement = (activity) => {
    //     // if (activity.state == 'bulkupdatetask') {
    //     if (activity.activity_for == 'Workdrive') {
    //         if (activity.change_info && activity.change_info.action_type == 'Upload') {
    //             return <>

    //                 <div class="d-flex align-items-center px-3">
    //                     Upload a file
    //                     &nbsp;
    //                     <FontAwesomeIcon icon={faImage} />
    //                     &nbsp;
    //                     <div class="start-badge status-badge">
    //                         {activity.change_info.data.name}
    //                     </div>
    //                     &nbsp;
    //                     in
    //                     &nbsp;
    //                     <div style={{cursor:'pointer'}} onClick={() => {
    //                         if (activity.change_info && activity.change_info.data.pathId) {
    //                             window.open("https://workdrive.zoho.com/folder/" + activity.change_info.data.pathId)
    //                         }
    //                     }} class="complete-badge status-badge start-badge ">
    //                         {activity.change_info.data.path}
    //                     </div>
    //                 </div>

    //             </>
    //         }
    //         else if (activity.change_info.stdate) {
    //             return <>
    //                 <div class="status-badge">
    //                     UPDATED START DATE
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div>
    //                         {activity.change_info.stdate ? activity.change_info.stdate.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div>
    //                         {activity.change_info.stdate ? activity.change_info.stdate.new : ""}
    //                     </div>
    //                 </div>
    //             </>


    //         }
    //         else if (activity.change_info.enddate) {
    //             return <>
    //                 <div class="status-badge">
    //                     UPDATED END DATE
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div>
    //                         {activity.change_info.enddate ? activity.change_info.enddate.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div >
    //                         {activity.change_info.enddate ? activity.change_info.enddate.new : ""}
    //                     </div>
    //                 </div>
    //             </>

    //         }
    //         else if (activity.change_info.priority) {
    //             return <>
    //                 <div class="status-badge">
    //                     CHANGED PRIORITY
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div class="start-badge status-badge">
    //                         {activity.change_info.priority ? activity.change_info.priority.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div class="complete-badge status-badge start-badge ">
    //                         {activity.change_info.priority ? activity.change_info.priority.new : ""}
    //                     </div>
    //                 </div>
    //             </>
    //         }
    //         else if (activity.change_info.duration) {
    //             return <>
    //                 <div class="status-badge">
    //                     CHANGED DAYS
    //                 </div>
    //                 <div class="d-flex align-items-center px-3">
    //                     <div>
    //                         {activity.change_info.duration ? activity.change_info.duration.old : ""}
    //                     </div>
    //                     <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
    //                     {/* <i class="fa-solid px-3 fa-arrow-right"></i> */}
    //                     <div >
    //                         {activity.change_info.duration ? activity.change_info.duration.new : ""}
    //                     </div>
    //                 </div>
    //             </>

    //         }
    //         // else {
    //         //     return <></>
    //         // }

    //     }



    // }

    const getActvityYear = (date) => {
        date = new Date(date);
        let nowDate = new Date();
        if (date.getFullYear() !== nowDate.getFullYear()) {
            let year = ', ' + date.getFullYear();
            return year;
        }
        return '';
    }

    const getTime = (date) => {
        let Time = new Date(date);
        var hours = Time.getHours();
        var minutes = Time.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;

    };

    const getDate = (date) => {
        date = new Date(date);
        let str = '';
        str = str + date.toLocaleString('en-us', { month: 'short' });
        str = str + " " + date.getDate();

        return str;
    }


    const getWorkdriveActivity = () => {
        return obj.map((one) => {
            return <div class="card custom_card ">
                <a href="#" class="parent_link"></a>
                <div class="sub-card-heder-section bg-white">
                    <div class="sub_card_header_name">
                        <div class="sub_card_name_title">
                            <div class="sub_card_breadcrumb">
                                <a class="breadcrumb_tabs">
                                    {dealObj.Deal_Name}

                                </a>
                                <div class="breadcrumb_icon icon">
                                    <FontAwesomeIcon icon={faGreaterThan} style={{ fontSize: '7px' }} />
                                    {/* <i class="fa-solid fa-greater-than"  style={{fontSize:'7px'}}></i> */}
                                </div>
                                <a class="breadcrumb_tabs">
                                    {dealObj.Account_Name ? dealObj.Account_Name : ''}

                                </a>
                                {/* {obj.length > 0 ?
                                obj[0].activity_for == "task_comment" ? "" :
                                    <div class="breadcrumb_icon icon" style={{ fontSize: '7px' }}>
                                        <FontAwesomeIcon icon={faGreaterThan} style={{ fontSize: '7px' }} />
                                    </div>
                                : ""}
                            <a class="breadcrumb_tabs">
                                {obj.length > 0 ? (obj[0].activity_for === "task_comment" ? "" : obj[0].name) : ""}
                            </a> */}
                                {/* <div class="breadcrumb_icon icon" style={{ fontSize: '7px' }}>
                                <FontAwesomeIcon icon={faGreaterThan} style={{ fontSize: '7px' }} />
                            </div>
                            <a class="breadcrumb_tabs">
                                M22-317_84-15 118 STREET
                            </a> */}
                            </div>
                        </div>
                        <a href="#" class="d-flex align-items-center justify-content-between" style={{ textDecoration: 'none' }}>
                            <div class="d-flex align-items-center">
                                <FontAwesomeIcon icon={faSquare} />
                                {/* <i class="fa-solid fa-square mt-1 custom-box-icon"></i> */}
                                {/* <i class="fa-solid fa-link mt-1 px-2" style={{fontSize:'12px'}}></i> */}
                                <FontAwesomeIcon icon={faLink} style={{ fontSize: '12px', margin: '0 10px' }} />
                                <h6 class="custom_task_name mb-0">
                                    {one.change_info && one.change_info.action_type ? one.change_info.action_type.toUpperCase() : ""}
                                    {/* {obj.length > 0 ? (obj[0].activity_for ? obj[0].activity_for.toUpperCase() : "") : ""} */}
                                    {/* {obj.length>0 ? (obj[0].name ? 
                                obj[0].name.toUpperCase() :"" ):""} */}
                                    {/* {obj.length>0 ? obj[0].name : ""} */}
                                </h6>
                                {/* <h6 class="custom_task_name mb-0">Recieve Hydro Flow Test Payment</h6> */}
                                <FontAwesomeIcon icon={faMinusCircle} />
                                {/* <i class="fa-solid mt-1 fa-circle-minus custom_minus_icon"></i> */}
                            </div>
                            <div class="d-none align-items-center task_name_group_icons">
                                <FontAwesomeIcon icon={faUser} style={{ color: '#a8a8a8', margin: '0 10px' }} />
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: '#a8a8a8', margin: '0 10px' }} />
                                <FontAwesomeIcon icon={faEye} style={{ color: '#08c7e0', margin: '0 10px' }} />
                                {/* <i class="fa-regular fa-user px-2" style={{color:'#a8a8a8'}} ></i> */}
                                {/* <i class="fa-solid fa-arrow-down px-2" style={{color:'#a8a8a8'}}></i>
                        <i class="fa-regular fa-eye px-2" style={{color:'#08c7e0'}} ></i> */}
                            </div>

                        </a>
                    </div>
                </div>
                <div class="sub-card-body">
                    <div class="sub_card_body_item d-flex justify-content-between align-items-center p-3">
                        <div class="d-flex align-items-center ">
                            <span class="avatar" style={{ background: 'rgb(4, 169, 244)' }}>{one.user_name ? one.user_name.charAt(0) : <></>}</span>
                            <p class="mb-0 px-2" style={{ fontSize: '13px', fontWeight: 400, cursor: 'pointer' }} >{one.user_name}
                            </p>
                            {/* <>
                                <div class="status-badge">
                                    CHANGED STATUS
                                </div>
                                <div class="d-flex align-items-center px-3">
                                    <div class="start-badge status-badge">
                                        {activity.change_info.status ? activity.change_info.status.old : ""}
                                    </div>
                                    <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
                                    <div class="complete-badge status-badge start-badge ">
                                        {activity.change_info.status ? activity.change_info.status.new : ""}
                                    </div>
                                </div>

                            </> */}
                            {getStatementForTask(one)}
                        </div>
                        <div class="d-flex aling-items-center" style={{ fontSize: '12px', fontWeight: 400, color: '#a8a8a8' }}>
                            {getDate(new Date(one.createdAt))}{getActvityYear(new Date(one.createdAt))} at {getTime(new Date(one.createdAt))}
                        </div>
                    </div>


                    {/* <div class="sub_card_body_item d-flex justify-content-between align-items-center p-3">
                <div class="d-flex align-items-center">
                    <span class="avatar" style={{background:'rgb(4, 169, 244)'}} >AW</span>
                    <p class="mb-0 px-2" style={{fontSize:'13px',fontWeight:400,color:'#a8a8a8'}} >Analisa Washington
                    </p>
                    <div class="status-badge">
                        CHANGED STATUS
                    </div>
                    <div class="d-flex align-items-center px-3">
                        <div class="start-badge status-badge">
                            NOT STARTED
                        </div>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <div class="complete-badge status-badge start-badge ">
                            COMPLETED
                        </div>
                    </div>
                </div>
                <div class="d-flex aling-items-center" style={{fontSize:'12px',fontWeight:400,color:'#a8a8a8'}} >
                    Sep 2 2022 at 4:56 pm
                </div>
            </div> */}

                </div>

            </div>
        })

    }


    const getDealActivity = () => {
        return <div class="card custom_card ">
            <a href="#" class="parent_link"></a>
            <div class="sub-card-heder-section bg-white">
                <div class="sub_card_header_name d-flex justify-content-between">
                    <div className='d-flex'>
                        <div class="sub_card_name_title">
                            <div class="sub_card_breadcrumb">
                                <a class="breadcrumb_tabs">
                                    {dealObj.Account_Name ? dealObj.Account_Name.name : ''}

                                </a>
                                <div class="breadcrumb_icon icon">
                                    <FontAwesomeIcon icon={faGreaterThan} style={{ fontSize: '7px' }} />
                                    {/* <i class="fa-solid fa-greater-than"  style={{fontSize:'7px'}}></i> */}
                                </div>
                                <a class="breadcrumb_tabs">
                                    {dealObj.Deal_Name}

                                </a>
                                {obj.length > 0 ?
                                    obj[0].activity_for == "task_comment" ? "" :
                                        <div class="breadcrumb_icon icon" style={{ fontSize: '7px' }}>
                                            <FontAwesomeIcon icon={faGreaterThan} style={{ fontSize: '7px' }} />
                                        </div>
                                    : ""}
                                <a class="breadcrumb_tabs">
                                    {obj.length > 0 ? (obj[0].activity_for === "task_comment" ? "" : obj[0].name) : ""}
                                </a>
                                {/* <div class="breadcrumb_icon icon" style={{ fontSize: '7px' }}>
                                <FontAwesomeIcon icon={faGreaterThan} style={{ fontSize: '7px' }} />
                            </div>
                            <a class="breadcrumb_tabs">
                                M22-317_84-15 118 STREET
                            </a> */}
                            </div>
                        </div>
                        {/* <a href="#" class="d-flex align-items-center justify-content-between" style={{ textDecoration: 'none' }}>
                            <div class="d-flex align-items-center">
                                <FontAwesomeIcon icon={faSquare} />
                                <FontAwesomeIcon icon={faLink} style={{ fontSize: '12px', margin: '0 10px' }} />
                                <h6 class="custom_task_name mb-0">
                                    {obj.length > 0 ? (obj[0].activity_for ? obj[0].activity_for.toUpperCase() : "") : ""}
                                 
                                </h6>
                                <FontAwesomeIcon icon={faMinusCircle} />
                            </div>


                        </a> */}

                    </div>
                    {/* <div class="d-none align-items-center task_name_group_icons">
                        <FontAwesomeIcon icon={faUser} style={{ color: '#a8a8a8', margin: '0 10px' }} />
                        <FontAwesomeIcon icon={faArrowDown} style={{ color: '#a8a8a8', margin: '0 10px' }} />
                        <FontAwesomeIcon icon={faEye} style={{ color: '#08c7e0', margin: '0 10px' }} />
                       
                    </div> */}
                </div>
            </div>
            <div class="sub-card-body">
                {obj.map((one) => {
                    return <div class="sub_card_body_item d-flex justify-content-between align-items-center p-3">
                        <div class="d-flex align-items-center activity_card_content col pl-0">
                            {one.zuid ?
                                <span class="avatar" ><img style={{ borderRadius: 'inherit' }} src={UserService.GetUserPhotoUrl(one.zuid)} /></span>
                                :
                                <span class="avatar" style={{ background: 'rgb(4, 169, 244)' }}>{one.activity_by ? one.activity_by.charAt(0) : <></>}</span>
                            }

                            {/* <p class="mb-0 px-2" style={{ fontSize: '13px', fontWeight: 400, cursor: 'pointer' }} >{one.activity_by}
                            </p> */}
                            {/* <>
                                <div class="status-badge">
                                    CHANGED STATUS
                                </div>
                                <div class="d-flex align-items-center px-3">
                                    <div class="start-badge status-badge">
                                        {activity.change_info.status ? activity.change_info.status.old : ""}
                                    </div>
                                    <FontAwesomeIcon icon={faArrowRight} style={{ margin: '0 10px' }} />
                                    <div class="complete-badge status-badge start-badge ">
                                        {activity.change_info.status ? activity.change_info.status.new : ""}
                                    </div>
                                </div>

                            </> */}
                            {getStatementForTask(one)}
                        </div>
                        <div class="d-flex aling-items-center" style={{ fontSize: '12px', fontWeight: 400, color: '#a8a8a8' }}>
                            {getDate(new Date(one.time_long))}{getActvityYear(new Date(one.time_long))} at {getTime(new Date(one.time_long))}
                        </div>
                    </div>
                })}


                {/* <div class="sub_card_body_item d-flex justify-content-between align-items-center p-3">
                <div class="d-flex align-items-center">
                    <span class="avatar" style={{background:'rgb(4, 169, 244)'}} >AW</span>
                    <p class="mb-0 px-2" style={{fontSize:'13px',fontWeight:400,color:'#a8a8a8'}} >Analisa Washington
                    </p>
                    <div class="status-badge">
                        CHANGED STATUS
                    </div>
                    <div class="d-flex align-items-center px-3">
                        <div class="start-badge status-badge">
                            NOT STARTED
                        </div>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <div class="complete-badge status-badge start-badge ">
                            COMPLETED
                        </div>
                    </div>
                </div>
                <div class="d-flex aling-items-center" style={{fontSize:'12px',fontWeight:400,color:'#a8a8a8'}} >
                    Sep 2 2022 at 4:56 pm
                </div>
            </div> */}

            </div>

        </div>
    }

    const getActivity = () => {
        switch (activityFor) {
            case "Workdrive":
                return getWorkdriveActivity();
            case "Deal":
                return getDealActivity();
            default:
                return <></>
        }
    }
    return (
        <>{getActivity()}</>
    );
}

export default ActvityCard;