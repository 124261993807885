import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
} from "react";
import { Col, Row, Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideNavbar from "./sidenavbar/sidenavbar";
import Layout from "./layout/layout";
import HttpService from "../../../services/http.service";
import axios from "axios";
import "./createdeal.css";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Prompt, useParams } from "react-router-dom";
import GetRecordById from "../../../common/util/getRecordById";
import swal from "sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import Config from '../../../config.json'
import FieldService from "../../../common/util/FieldService";
import TaskService from "../../../common/util/TaskService";
import ChatService from "../../../common/util/ChatService";
import ModuleService from "../../../common/util/ModuleService";
import AppContext from "../../../Context/AppContext";
import {
  AllDeals,
  CreateDealData,
  EditDealData,
  NycApiData,
} from "../../../Context/AppActions";

const Createdeal = forwardRef((props, ref) => {
  const backendUrl1 = Config.backend_url;
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const nycApiData = state.NycApiData || {};
  const [activityDetails, setActivityDetails] = useState([]);
  const [oldProjectAdd, setOldProjectAdd] = useState(null);
  const [addressChangeFlag, setAddressChangeFlag] = useState(false);
  const [onChangeAddress, setOnChangeAddress] = useState(false);
  const [dealData, setDealData] = useState([]);
  const moduleApiNameForSubform = ["WWP_Violations", "Previous_Solar_Info"];
  const [cacheObj, setCacheObj] = useState();
  const [func, setFunc] = useState("create");
  const [mailAddressDisable, setMailAddressDisable] = useState(false);
  let ShowLoader = false;
  // code to refresh the access token
  useLayoutEffect((e) => {
    (async () => {
      try {
        localStorage.removeItem("isDealCreated");
        await props.getToken();
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);
  // code to refresh the access token

  const location = props.location;
  const params = useParams();
  useEffect(() => {
    if (props.getParams) {
      props.getParams(params);
    }
  }, [location]);

  const [layoutstate, setLayoutState] = useState("preliminary");
  const [listState, setListState] = useState("basic");
  const [isNycVisible, setNycVisible] = useState(
    localStorage.getItem("nycOption")
  );
  const [ownerType, setownerType] = useState("INDIVIDUAL");
  // const [editDealDetails, setEditDealDetails] = useState(0);
  const [buttonPosition, setButtonPosition] = useState("");
  const [entityId, setEntityId] = useState("");
  const [allServicesName, setAllServicesName] = useState([]);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [taskLayout, setTaskLayout] = useState({ status_details: [] });
  const [showExitPrompt, setShowExitPrompt] = useState(props.showExitPrompt);

  const defaultData = {
    dhcr: "No",
    nonProfit: "No",
    Occupancy: "No",
    Construction: "No",
    tidalWetland: "No",
    coastal: "No",
    freshWater: "No",
    firedistrict: "No",
    floodHazard: "No",
    Services: [],
    landmark: "No",
    envRestrictions: "No",
    installation: "No",
    Withdrawal: "No",
    InspectionPass: "No",
    Reinstatement: "No",
    GeneralContractor: "No",
    AppSupersede: "No",
    PreviousSolar: "No",
    certificateoccupancy: "No",
    existingACP5: "No",
    asbestosReq: "No",
    withoutpermit: "No",
    StopWork: "No",
    partial: false,
    Conjunction: "No",
    zoningList: [{ zoningdistrict: "N/A" }],
    commercialList: [{ commercialoverlay: "N/A" }],
    specialDistList: [{ specialDist: "N/A" }],
    wwpViolation: [],
    pvSystemInfo: [],
    ownerType: "INDIVIDUAL",
    ProjectDate: new Date(Date.now()),
    PreliminaryResearch: "No",
    PreliminaryLayout: "No",
    SiteAssessment: "No",
    Design: "No",
    Asbestos: "No",
    Expediting: "No",
    Permitting: "No",
    Certification: "No",
    ElectricalDiagram: "No",
    SpecialInspection: "No",
    ProgressInspection: "No",
    EnergyInspection: "No",
    Interconnection: "No",
    StructuralLetter: "No",
    PropertySurvey: "No",
    NYSERDAFiling: "No",
    PropertyTaxAbatement: "No",
    Latitude: 40.705121,
    projectCost: 0,
    Longitude: -73.82738499999999,
    mailAddDifferent: false,
  };

  const [allService, setAllService] = useState([]);
  const [data, setData] = useState(defaultData);
  const [projectAddress, setProjectAddress] = useState(null);
  const [duplicateDealObj, setDuplicateDealObj] = useState([]);
  const [availableDuplicateDeals, setAvailabaleDuplicateDeals] = useState([]);
  const [dealValue, setDealValue] = useState("");
  const [duplicateModalPopUp, setDuplicateDealPopUp] = useState(false);
  const [selectDealPopup, setSelectDealPopup] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);
  const [dealdropdownModal, setDealDropdownModal] = useState(false);
  const [selectedDealFields, setSelectedDealFields] = useState([]);
  const [dealCopyItems, setDealCopyItems] = useState({
    Tasks: false,
    Notes: false,
    Workdrive: false,
    Email: false,
    Accounting: false,
    Milestone: false,
  });
  let myData;

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const dealdropdownModalToggle = () => {
    setDealDropdownModal(!dealdropdownModal);
  };

  const duplicateModalPopUpToogle = () =>
    setDuplicateDealPopUp(!duplicateModalPopUp);

  const showduplicateModalPopUpToogle = () => {
    setSelectDealPopup(!selectDealPopup);
  };

  useEffect(() => {
    getDealList();
    setItemsForCopyDeal();
  }, []);

  const setItemsForCopyDeal = () => {
    let items = { ...dealCopyItems };
    let arr = [];
    Object.keys(items).forEach((key) => {
      let obj = { label: key, value: key };
      arr.push(obj);
    });
    setFieldsData(arr);
  };

  const getFieldHeaders = () => {
    FieldService.GetFields("all", "Deals")
      .then((result) => {
        let arr = [{ label: "All", value: "all" }];
        result.forEach((one) => {
          if (one.api_name !== "Deal_Name") {
            let obj = {
              label: one.field_label,
              value: one.id,
              field: one.api_name,
            };
            arr.push(obj);
          }
        });
        setFieldsData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectDealFields = (values) => {
    const items = { ...dealCopyItems };
    values.forEach((one) => {
      items[one.value] = true;
    });
    updateTheSidebarState({
      type: "DuplicateDeal",
      status: values,
      flag: "Modules",
    });
    setDealCopyItems(items);
    setSelectedDealFields(values);
  };

  const handleCreateDuplicateDeal = (dataa) => {
    let dataArr = [...dataa];
    if (dataArr.length > 1) {
      setSelectDealPopup(!selectDealPopup);
    } else {
      let text = "You want to copy clone this deal " + dataa[0].Deal_Name + " ";
      swal({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then(async function (isConfirm) {
        if (isConfirm) {
          let dealObject = { ...dataArr[0] };
          props.setLoader(true);
          await getDealData(location.state.module, dealObject.id, true);
          setDuplicateModal(false);
          setSelectDealPopup(false);
          setDealDropdownModal(false);
        } else {
          setSidebarCardsStatus({
            ...sidebarCardsStatus,
            duplicateDeal: {
              dealName: null,
              modules: [],
            },
          });
        }
      });
    }
  };

  const handleRecursiveCopySubtask = async (
    copyDealId,
    taskId,
    copyTaskId,
    dealObj
  ) => {
    try {
      let res = await TaskService.GetAllSubTaskOfTask(copyDealId, taskId);
      if (res) {
        for (let i = 0; i < res.length; i++) {
          await copySubtask(copyDealId, copyTaskId, res[i], dealObj);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addCommentsAndReply = async (chatId, id, message, replies) => {
    return new Promise((resolve, reject) => {
      let messageVal = message;
      ChatService.ReplyToUser(chatId, id, messageVal)
        .then((result) => {
          let doArr = [];
          let id = result.data.message_id;
          id = id.split("%");
          let firstId = id[0];
          let secondId = id[1];
          secondId = secondId.replace("20", "");
          id = firstId + "_" + secondId;
          replies.forEach((reply) => {
            doArr.push(addReply(chatId, id, reply.content.text));
          });
          return Promise.all(doArr);
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const addReply = async (chatId, id, message) => {
    try {
      let messageVal = message;
      await ChatService.ReplyToUser(chatId, id, messageVal);
    } catch (ex) {
      console.log(ex);
    }
  };

  const addNotes = async (chatId, content) => {
    try {
      let res = await ChatService.SendMessageToUser(chatId, content);
      return res;
    } catch (ex) {
      console.log(ex);
    }
  };

  const saveNotesCommentReply = (chatId, note, comments, replies) => {
    return new Promise((resolve, reject) => {
      addNotes(chatId, note)
        .then((result) => {
          let id = result.data.message_id;
          id = id.split("%");
          let firstId = id[0];
          let secondId = id[1];
          secondId = secondId.replace("20", "");
          id = firstId + "_" + secondId;
          let doArr = [];
          comments.forEach((one) => {
            doArr.push(
              addCommentsAndReply(chatId, id, one.content.text, replies)
            );
          });
          return Promise.all(doArr);
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const updateTask = async (projectId, taskId, dataKey, value) => {
    TaskService.UpdateTask(projectId, taskId, dataKey, value)
      .then(async (result) => {
        if (result) {
        
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copySubtask = async (copyDealId, taskId, subtask, dealObj) => {
    subtask = { ...subtask };
    let data = {};
    data.person_responsible = "";
    data.name = subtask.name;
    data.priority = subtask.priority;
    subtask.details.owners.forEach((one) => {
      if (one.id) {
        data.person_responsible = data.person_responsible + one.id + ",";
      }
    });
    if (subtask.start_date) {
      data.start_date = subtask.start_date;
    }
    if (subtask.end_date) {
      data.end_date = subtask.end_date;
    }
    data.description = subtask.description;
    TaskService.CreateSubtask(dealObj.Project_ID, taskId, data)
      .then(async (result) => {
        if (result) {
          let obj = {};
          subtask.custom_fields.forEach((one) => {
            if (
              one.column_name == "UDF_CHAR1" ||
              one.column_name == "UDF_CHAR3"
            ) {
              obj[one.column_name] = one.value;
            }
          });
          updateTask(
            dealObj.Project_ID,
            result.id_string,
            "custom_fields",
            JSON.stringify(obj)
          );
          handleCopyComments(copyDealId, subtask.id_string, result.id_string);
          let id = taskLayout.status_details.filter(
            (one) => one.name == subtask.status.name
          );
          if (id.length > 0) {
            updateTask(
              dealObj.Project_ID,
              result.id_string,
              "custom_status",
              id[0].id
            );
          }
          await handleRecursiveCopySubtask(
            copyDealId,
            subtask.id_string,
            result.id_string,
            dealObj
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopyComments = (copyDealId, pid, tid, copyTaskId) => {
    TaskService.GetTaskComment(copyDealId, tid)
      .then((notes) => {
        if (notes) {
          let doArr = [];
          notes.forEach((note) => {
            doArr.push(
              TaskService.AddTaskComment(
                pid,
                copyTaskId,
                "content",
                note.content
              )
            );
          });
          return Promise.all(doArr);
        }
      })
      .then((result) => {
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showDuplicateItemsPopup = () => {
    return (
      <Modal
        isOpen={selectDealPopup}
        backdrop={"static"}
        size="md"
        toggle={showduplicateModalPopUpToogle}
        className="taskComplete-warning duplicate-items"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Available duplicate Deals</h5>
              <ul className="bulletlist">
                {availableDuplicateDeals.map((e) => (
                  <li>
                    <input
                      type="radio"
                      name="deal_fields"
                      value={e.Deal_Name}
                      className="mr-10"
                      onClick={(e) => {
                        setDealValue(e.target.value);
                      }}
                    />
                    {e.Deal_Name}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-primary w-100 mt-40"
                onClick={() => {
                  let arr = [...availableDuplicateDeals];
                  let filterObj = arr.filter(
                    (one) => one.Deal_Name === dealValue
                  );
                  updateTheSidebarState({
                    type: "DuplicateDeal",
                    status: {
                      id: dealValue,
                      dealFolderId:
                        filterObj[0].zohoworkdriveforcrm__Workdrive_Folder_ID,
                    },
                    flag: "dealName",
                  });
                  setDealDropdownModal(true);
                  setDuplicateDealObj(filterObj);
                }}
              >
                Duplicate
              </button>
              <button
                className="btn btn-primary w-100 mb-20"
                onClick={() => {
                  updateTheSidebarState({
                    type: "DuplicateDeal",
                    status: null,
                    flag: "dealName",
                  });
                  setSelectDealPopup(!selectDealPopup);
                }}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const showDropdownDealFields = () => {
    return (
      <Modal
        isOpen={dealdropdownModal}
        backdrop={"static"}
        size="md"
        toggle={dealdropdownModalToggle}
        className="taskComplete-warning duplicate-items"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">
                Which Deals Module you want to copy from deal name{" "}
                {duplicateDealObj.length > 0
                  ? duplicateDealObj[0].Deal_Name
                  : "NA"}
              </h5>
              Fields:
              <Select
                options={fieldsData}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(values) => handleSelectDealFields(values)}
                allowSelectAll={true}
                value={selectedDealFields}
                menuPlacement="auto"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                onClick={() => {
                  handleCreateDuplicateDeal(duplicateDealObj);        
                }}
                className="btn btn-success w-100 mt-2"
              >
                Duplicate
              </button>
              <button
                onClick={() => {
                  updateTheSidebarState({
                    type: "DuplicateDeal",
                    status: null,
                    flag: "dealName",
                  });
                  setDealDropdownModal(!dealdropdownModal);
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const duplicateDealModal = () => {
    return (
      <Modal
        isOpen={duplicateModal}
        backdrop={"static"}
        size="md"
        toggle={duplicateModalPopUpToogle}
        className="taskComplete-warning duplicate-items"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Duplicate Deal Warning</h5>
              <p className="taskComplete-warning-subtitle">
                Same Project Address Exist On Another Deal{" "}
                {duplicateDealObj ? duplicateDealObj.ProjectNumber : ""}{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  setSidebarCardsStatus({
                    ...sidebarCardsStatus,
                    duplicateDeal: {
                      dealName: null,
                      modules: [],
                    },
                  });
                  setDuplicateModal(!duplicateModal);
                }}
              >
                Continue
              </button>
              <button
                onClick={() => {
                  if (availableDuplicateDeals.length > 1) {
                    setSelectDealPopup(true);
                  } else {
                    setDealDropdownModal(!dealdropdownModal);
                  }
                }}
                className="btn btn-success w-100 mt-2"
              >
                Duplicate
              </button>
              <button
                onClick={() => {
                  setDuplicateModal(!duplicateModal);
                  setSidebarCardsStatus({
                    ...sidebarCardsStatus,
                    duplicateDeal: {
                      dealName: null,
                      modules: [],
                    },
                  });
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    alert("akskldashdlkasjdkla");
  };

  useEffect(() => {
    let timer2;
    if (location.state && location.state.action == "create") {
      timer2 = setTimeout(() => {
      }, 3000);
    }
    getPromptCode();
    const timer = setTimeout(
      () => {
        localStorage.setItem("hidePrompt", true);
      },
      location.state.action == "create" ? 3950 : 4950
    );
    return () => clearTimeout(timer, timer2);
  }, []);

  window.onbeforeunload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const getPromtt = () => {
    if (location.state && location.state.action == "create") {
      localStorage.setItem("isUpdateDeal", false);
      if (
        !sidebarCardsStatus.ShouldSave ||
        localStorage.getItem("showWarning") == "true"
      ) {
        localStorage.removeItem("showWarning");
        return (
          <Prompt
            when={true}
            message="Are you sure you want to leave without changes save?"
          />
        );
      } else {
        return "";
      }
    } else if (location.state && location.state.action == "Update") {
      localStorage.setItem("isUpdateDeal", true);
      if (
        !sidebarCardsStatus.ShouldSave ||
        localStorage.getItem("showWarning") == "true"
      ) {
        localStorage.removeItem("showWarning");

        return (
          <Prompt
            when={true}
            message="Are you sure you want to leave without changes save?"
          />
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const initBeforeUnLoad = (showExitPrompt) => {
    if (showExitPrompt) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
        dispatch(EditDealData({}));
        dispatch(CreateDealData({}));
      };
    }
  };

  window.addEventListener("popstate", initBeforeUnLoad);
  window.addEventListener("hashchange", initBeforeUnLoad);
  window.onload = () => {
    initBeforeUnLoad(showExitPrompt);
    dispatch(EditDealData({}));
    dispatch(CreateDealData({}));
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    if (localStorage.getItem("hidePrompt")) {
    } else {
      initBeforeUnLoad(showExitPrompt);
    }
  }, [showExitPrompt]);

  const refreshDeal = async (requiredJob) => {
    let returnData = await HttpService.getByBoj("getdealbyjobnumber", {
      ProjectNumber: requiredJob,
    }).then((res) => {
      if (res.data.length > 0 && res.data != null) {
      }
    });
  };

  const getDealData = async (module, id, duplicateFlag) => {
    await GetRecordById(module, id)
      .then(async (data) => {
        for (let i = 0; i < moduleApiNameForSubform.length; i++) {
          let res1 = await ModuleService.GetModuleListData(
            moduleApiNameForSubform[i]
          );
          data.forEach((deal, dealIdx) => {
            res1 = res1.filter(
              (one) => one.Deal_Name && one.Deal_Name.name == deal.Deal_Name
            );
            data[dealIdx][moduleApiNameForSubform[i]] = res1;
          });
        }
        data.forEach((one, oneIdx) => {
          one.Service_Requested = one.Service_Requested.map((ser, serIdx) => {
            return {
              name: ser.Service_Requested.name,
              id: ser.Service_Requested.id,
            };
          });
        });
        let folderId = data[0].zohoworkdriveforcrm__Workdrive_Folder_ID;
        let fillingFolderData = null;
        let ob = {
          ...sidebarCardsStatus,
          Preliminary: true,
          NycProject: true,
          Homeowner: true,
          Permitting: true,
          PVSystem: true,
          SkipNycFlag: data[0].Is_NYC_Project == "No" ? true : false,
          NavActivetab: "Preliminary",
          AsbestosCertificate: null,
          DeedCertificate: null,
          DHCRLetter: null,
          ACP5: null,
          DealFilingFolderId: "",
          DealFolderId: folderId,
          Violation: [],
          PaidunderJob: [],
          Invoice: [],
          ACP5: null,
          RemoveDocumentsArray: [],
          FilesExtention: null,
          EditDocuments: null,
        };
        let violationsDoc = [];
        let paidUnderJobDoc = [];
        let invoiceDoc = [];
        let violationSummaryDocs = [];

        if (data[0].Violation_Information?.length > 0) {
          data[0].Violation_Information.forEach((e) => {
            let t = {
              JobNo: e.Paid_Under_Job_Number,
              invoiceNo: e.Invoice_Number,
              violationNo: e.Violation_Number,
            };
            ob.Violation.push(t);
          });
        }
        if (folderId != null) {
          fillingFolderData = await axios
            .post(
              Config.backend_url+"getFilingFolderID",
              {
                id: folderId,
                Authorization: localStorage.getItem("token"),
              }
            )
            .then((r) => {
              ob.DealFilingFolderId = r.data.filing_folder_id;
              r.data.all_filing_files.map((e) => {
                if (!e.name.includes("ARCHIVE")) {
                  if (e.name.includes("_DEED")) {
                    ob = {
                      ...ob,
                      DeedCertificate: {
                        filename: e.name,
                        file: null,
                        fileId: e.id,
                      },
                    };
                  }
                  if (e.name.includes("_ACP5")) {
                    ob = {
                      ...ob,
                      ACP5: {
                        filename: e.name,
                        file: null,
                        fileId: e.id,
                      },
                    };
                  }
                  if (e.name.includes("_DHCRLETTER")) {
                    ob = {
                      ...ob,
                      DHCRLetter: {
                        filename: e.name,
                        file: null,
                        fileId: e.id,
                      },
                    };
                  }
                  if (e.name.includes("_CERTIFICATE OF OCCUPANCY")) {
                    ob = {
                      ...ob,
                      AsbestosCertificate: {
                        filename: e.name,
                        file: null,
                        fileId: e.id,
                      },
                    };
                  }

                  if (
                    e.name.includes("_ECB VIOLATION COPY") ||
                    e.name.includes("_DOB VIOLATION COPY")
                  ) {
                    let id = e.name.split(" VIOLATION COPY ")[1].split(".PDF")[0];
                    violationsDoc = [
                      ...violationsDoc,
                      {
                        filename: e.name,
                        id: id,
                        fileId: e.id,
                      },
                    ];
                  }
                  if (
                    e.name.includes("_ECB VIOLATION SUMMARY") ||
                    e.name.includes("_DOB VIOLATION SUMMARY")
                  ) {
                    let id = e.name
                      .split(" VIOLATION SUMMARY ")[1]
                      .split(".PDF")[0];
                    violationSummaryDocs = [
                      ...violationSummaryDocs,
                      {
                        filename: e.name,
                        id: id,
                        fileId: e.id,
                      },
                    ];
                  }

                  if (e.name.includes("_VIOLATION PAID APPLICATION")) {
                    let id = e.name
                      .split("_VIOLATION PAID APPLICATION ")[1]
                      .split(".PDF")[0];
                    paidUnderJobDoc = [
                      ...paidUnderJobDoc,
                      {
                        filename: e.name,
                        id: id,
                        fileId: e.id,
                      },
                    ];
                  }
                  if (e.name.includes("_VIOLATION PAID INVOICE")) {
                    let id = e.name
                      .split("_VIOLATION PAID INVOICE ")[1]
                      .split(".PDF")[0];
                    invoiceDoc = [
                      ...invoiceDoc,
                      {
                        filename: e.name,
                        id: id,
                        fileId: e.id,
                      },
                    ];
                  }
                }
              });
              return r.data;
            });
        }
        ob["EditDocuments"] = {
          violationDocs: violationsDoc,
          invoiceDocs: invoiceDoc,
          paidUnderJobDocs: paidUnderJobDoc,
          violationSummaryDocs: violationSummaryDocs,
        };
        let borough;
        if (data[0].Borough == "Manhattan") {
          borough = "1";
        } else if (data[0].Borough == "Bronx") {
          borough = "2";
        } else if (data[0].Borough == "Brooklyn") {
          borough = "3";
        } else if (data[0].Borough == "Queens") {
          borough = "4";
        } else {
          borough = "5";
        }

        await axios
          .get(
            `${backendUrl1}getPLUTOInfo?bbl=${
              borough + data[0].Block_Number + data[0].Lot_Number
            }`
          )
          .then((res) => {
            myData = res.data;
          })
          .catch((err) => {
            console.log(err);
          });

        setDataObj(data[0], duplicateFlag);

        setSidebarCardsStatus(ob);
      })
      .catch((err) => {
        console.log(err);
      });
    props.setLoader(false);
  };
  useEffect(() => {
    (async () => {
      await ModuleService.GetModuleListData("Requested_Service")
        .then((result) => {
          setAllService(result);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  useEffect(() => {
    (async (e) => {
      if (location.state && location.state.action == "Update") {
        props.setLoader(true);
        await getDealData(location.state.module, location.state.id);
      }
    })();
  }, [location, allService]);

  const getDealList = () => {
    const backendUrl =
      Config.backend_url+"getAllDealPages";
    var config = {
      token: localStorage.getItem("token"),
    };
    axios
      .post(backendUrl, { ...config })
      .then(function (res) {
        setDealData(res.data.data);
        dispatch(AllDeals(res.data.data))
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const wwwViolationHeaders = [
    { value: "Name", label: "Violation Index", oldKey: "violationIndex" },
    {
      value: "Violation_Number",
      label: "Violation Number",
      oldKey: "violationNum",
    },
    {
      value: "Violation_Type",
      label: "Violation Type",
      oldKey: "violationType",
    },
    { value: "L2_Code", label: "L2 Code", oldKey: "ltwocode" },
    {
      value: "Civil_Penalty_Payment_Required",
      label: "Civil Penalty Payment Required",
      oldKey: "Civilpenalty",
    },
    {
      value: "Paid_Under_Job_Number",
      label: "Paid Under Job Number",
      oldKey: "jobNo",
    },
    { value: "Invoice_Number", label: "Invoice Number", oldKey: "invoiceNo" },
  ];

  const convertRawDealData = async (deal) => {

    let obj = { ...data };
    obj.projectCost = deal.Project_Cost;
    obj.ProjectNumber = deal.Deal_Name;
    obj.interconnNo = deal.Interconnection;
    obj.ProjectDate = deal.Closing_Date;
    obj.Client = deal.Account_Name;
    obj.Latitude = deal.Latitude;
    obj.Longitude = deal.Longitude;
    obj.ProjectAddress = deal.Project_Address;
    obj.StreetName = deal.Street_Name;
    obj.City = deal.City;
    obj.State = deal.State;
    obj.ZipCode = deal.Zip_Code;
    obj.Borough = deal.Borough;
    obj.binNo = deal.Bin_Number;
    obj.blockNo = deal.Block_Number;
    obj.lotNo = deal.Lot_Number;
    obj.HouseNumber = deal.House_Number;
    obj.Previous_Solar_App = deal.Previous_Solar_App;
    obj.Previous_Solar_Info = deal.Previous_Solar_Info;
    obj.asbestosReq = deal.Asbestos_Required;
    obj.nbApplication = deal.NB_Application_Number;
    obj.certificateoccupancy = deal.Certificate_of_Occupancy_Available;
    obj.existingACP5 = deal.ACP5_Completed;
    obj.existingACP5ControlNumber = deal.ACP5_Control_Number;
    obj.existingACP5InspCertNumber = deal.Asbestos_Inspector_Certificate_Number;
    obj.lblConjunction = deal.Conjunction_Application_Number;
    obj.Conjunction = deal.Conjunction;
    // home onwner state //
    obj.ownerType = deal.Owner_Type;
    obj.homeOwnerName = deal.Property_Owner_s_Name;
    obj.OwnerFrstNme = deal.Owner_s_First_Name;
    obj.ownerMiddle = deal.Owner_s_Middle_Initial;
    obj.ownerlastname = deal.Owner_s_Last_Name;
    obj.phoneNumber = deal.Owner_Phone_Number;
    obj.emailAdd = deal.Owner_s_Email_Address;
    obj.MailingAdd = deal.Owner_s_Mailing_Address;
    obj.streetName = deal.Owner_s_Street_Name;
    obj.houseNum = deal.Owner_s_House_Number;
    obj.cityName = deal.Owner_s_City;
    obj.stateName = deal.Owner_s_State;
    obj.zipCode = deal.Owner_s_Zip_Code;
    obj.mailAddDifferent = deal.Owner_s_Mailing_Address_Diff_Than_Property_Address;
    obj.otherzipCode = deal.Other_Owner_Zip_Code;
    obj.otherstreetName = deal.Other_Owner_Street_Name;
    obj.otherstateName = deal.Other_Owner_State;
    obj.otherhouseNum = deal.Other_Owner_House_Number;
    obj.relatnOwner = deal.Other_Owner_Officer_Title;
    obj.offcrFrstNme = deal.Other_Owner_Officer_First_Name;
    obj.offcrMiddle = deal.Other_Owner_Officer_Middle_Initial;
    obj.offcrLast = deal.Other_Owner_Officer_Last_Name;
    obj.otheremailAdd = deal.Other_Owner_Email_Address;
    obj.othercompnyNme = deal.Other_Owner_Company_Name;
    obj.othercityName = deal.Other_Owner_City;
    obj.otherAdd = deal.Other_Owner_Mailing_Address;
    obj.otherphoneNumber = deal.Other_Owner_Phone_Number;
    obj.nonProfit = deal.Non_Profit;
    obj.dhcr = deal.DHCR;
    obj.condocompnyNme = deal.Condo_Company_Name;
    obj.condoAdd = deal.Condo_Mailing_Address;
    obj.condostreetName = deal.Condo_Street_Name;
    obj.condohouseNum = deal.Condo_House_Number;
    obj.condocityName = deal.Condo_City;
    obj.condostateName = deal.Condo_State_Name;
    obj.condozipCode = deal.Condo_Zip_Code;
    obj.condooffcrFrstNme = deal.Condo_Officer_First_Name;
    obj.condooffcrMiddle = deal.Condo_Officer_Middle_Initial;
    obj.condooffcrLast = deal.Condo_Officer_Last_Name;
    obj.condorelatnOwner = deal.Condo_Officer_Title;
    obj.condophoneNumber = deal.Condo_Phone_Number;
    obj.condoemailAdd = deal.Condo_Email_Address;
    obj.Occupancy = deal.Occupancy_Classification_Building_2014_2008_Code;
    obj.Construction = deal.Construction_Classification_Code_2014_2008_Code;
    obj.constructionval = deal.Construction_Classification_Code;
    obj.occupancyval = deal.Occupancy_Classification_of_Building;
    obj.buildingheight = deal.Building_Height;
    obj.multipleDwellingCallsification = deal.Multiple_Dwelling_Classification;
    obj.dwellingUnits = deal.Number_of_Dwelling_Units;
    obj.storynumber = deal.Number_of_Stories;
    obj.community = deal.Community_Board_Number;
    obj.applicant = deal.Applicant;
    // home onwner state //
    obj.Services = [];
    let serviceRequestedName = deal.Service_Requested_Name
      ? deal.Service_Requested_Name
      : "";
    serviceRequestedName = serviceRequestedName.split(",");
    if (serviceRequestedName) {
      let name = [];
      serviceRequestedName.forEach((one) => {
        if (one) {
          let filterService = allService.filter((ser) => ser.Name == one);
          if (filterService.length > 0) {
            name.push({ name: filterService[0].Name, id: filterService[0].id });
          }
        }
      });
      obj.Services = name;
    }

    let pvSystemInfo = [];
    if (deal.PV_Interconnection) {
      deal.PV_Interconnection.map((one) => {
        let obj = {};

        obj.pvSystemId = one.id;
        obj.interConnectionNum = one.Interconnection_Number;
        obj.systemSize = one.System_Size_KW1.toFixed(3);
        obj.noOfModules = one.Number_Of_Modules;
        obj.pvmodulemodel = one.PV_Module_Model;
        obj.numberOfInvertors = one.Number_of_Invertors;
        obj.invertertype = one.Invertor_Type;
        obj.inverterModelNumber = one.Invertor_Model_Number;
        pvSystemInfo.push(obj);
      });
    }
    obj.pvSystemInfo = pvSystemInfo;

    let wwpViolation = [];
    if (deal.WWP_Violations) {
      deal.WWP_Violations.forEach((one) => {
        let obj = { id: one.id };
        wwwViolationHeaders.forEach((header) => {
          obj[header.oldKey] = one[header.value];
        });
        wwpViolation.push(obj);
      });
    }

    let zoningDistList = [];
    if (deal.Zoning_District) {
      deal.Zoning_District.forEach((one) => {
        let obj = { "zoningdistrict" : one};
        zoningDistList.push(obj);
      });
    }
    let specialDists = [];
    if (deal.Specials_Districts) {
      deal.Specials_Districts.forEach((one) => {
        let obj = { "specialDist" : one};
        specialDists.push(obj);
      });
    }
    let commericialOverList = [];
    if (deal.Commercial_Overlay) { 
      deal.Commercial_Overlay.forEach((one) => {
        let obj = { "commercialoverlay" : one};
        commericialOverList.push(obj);
      });
    }
    
    obj.wwpViolation = wwpViolation;
    obj.commercialList = commericialOverList;
    obj.specialDistList = specialDists;
    obj.zoningList = zoningDistList;
    obj.interconnNo = deal.Number_of_Interconnection;
    obj.tidalWetland = deal.Tidal_Wetlands;
    obj.coastal = deal.Coastal_Erosion_Hazard;
    obj.floodHazard = deal.Flood_Hazard != null ? deal.Flood_Hazard : "No";
    obj.zoiningMap = deal.Zoning_Map_Number;
    obj.landmark = deal.Landmark_Area;
    obj.freshWater = deal.Fresh_Water_Wetland;

    obj.envRestrictions = deal.Environmental_Restriction;
    obj.firedistrict = deal.Special_Fire_District;
    obj.StopWork = deal.Stop_Work_Order_Exists_on_Property;
    obj.withoutpermit = deal.Active_Work_Without_Permit_Violations;
    obj.partial = deal.Partial;
    obj.PreliminaryResearch = deal.Preliminary_Research;
    obj.PreliminaryLayout = deal.Preliminary_Layout;
    obj.SiteAssessment = deal.Site_Assessment;
    obj.Design = deal.Design;
    obj.Asbestos = deal.Asbestos;
    obj.Expediting = deal.Expediting;
    obj.Permitting = deal.Permitting;
    obj.Certification = deal.Certification;
    obj.PreviousSolar = deal.Previous_Solar_Application;

    obj.ElectricalDiagram = deal.Electrica_lDiagram;
    obj.SpecialInspection = deal.Special_Inspection;
    obj.ProgressInspection = deal.Progress_Inspection;
    obj.EnergyInspection = deal.Energy_Inspection;
    obj.Interconnection = deal.Interconnection;
    obj.StructuralLetter = deal.Structural_Letter;
    obj.PropertySurvey = deal.Property_Survey;
    obj.NYSERDAFiling = deal.NYSERDA_Filing;
    obj.PropertyTaxAbatement = deal.Property_Tax_Abatement;
    obj.Contractor = deal.General_Contractor;
    obj.Electrician = deal.Electrician;
    obj.NYCProject = deal.Is_NYC_Project;
    obj.id = deal.id;
    return obj;
  };

  const setDataObj = async (deal, duplicateFlag) => {
    let obj = await convertRawDealData(deal);

    let oldData = data;

    if (duplicateFlag) {
      let new_Previous_Solar_App = obj.Previous_Solar_Info?.map((e) => {
        delete e.id;
        delete e.Name;
        return e;
      });
      let new_wwpViolation = obj.wwpViolation?.map((e) => {
        delete e.id;
        delete e.Name;
        delete e.violationIndex;
        return e;
      });
      obj["Previous_Solar_Info"] = new_Previous_Solar_App;
      obj["wwpViolation"] = new_wwpViolation;
      delete obj.id;
      obj = {
        ...obj,
        ProjectNumber: oldData.ProjectNumber,
        Client: oldData.Client,
        ProjectDate: oldData.ProjectDate,
      };
    }
    setData(obj);
    const {
      Borough,
      blockNo,
      lotNo,
      zoningList,
      binNo,
      specialDistList,
      commercialList,
      landmark,
      envRestrictions,
      tidalWetland,
      coastal,
      floodHazard,
      projectCost,
      ProjectNumber,
      freshWater,
    } = obj;
    dispatch(
      EditDealData({
        Borough,
        blockNo,
        lotNo,
        zoningList,
        specialDistList,
        commercialList,
        landmark,
        envRestrictions,
        tidalWetland,
        coastal,
        floodHazard,
        projectCost,
        ProjectNumber,
        freshWater,
      })
    );
    let borough;
    if (Borough == "Manhattan") {
      borough = "1";
    } else if (Borough == "Bronx") {
      borough = "2";
    } else if (Borough == "Brooklyn") {
      borough = "3";
    } else if (Borough == "Queens") {
      borough = "4";
    } else {
      borough = "5";
    }

    let nycData = {
      tidalWetlands: tidalWetland,
      landStatus: landmark,
      envRes: envRestrictions,
      binNum: binNo,
      bblNum: borough + blockNo + lotNo,
      myData: myData,
    };
    dispatch(NycApiData({ ...nycApiData, nycData }));
  };

  // hrushikesh shelke
  const homeOwnerInfoFeilds = {
    mailAddDifferent: "",
    phoneNumber: "",
    emailAdd: "",
    OwnerFrstNme: "",
    ownerMiddle: "",
    ownerlastname: "",
  };
  const homeOwnerAddInfoFeilds = {
    MailingAdd: "",
    center: "",
    streetName: "",
    cityName: "",
    stateName: "",
    zipCode: "",
    houseNum: "",
    streetAdd: "",
  };
  const otheOwnerInfoFeilds = {
    dhcr: "",
    nonProfit: "",
    otherAdd: "",
    othercenter: "",
    otherstreetName: "",
    otherltitude: "",
    otherlngitude: "",
    othercityName: "",
    otherstateName: "",
    otherzipCode: "",
    otherhouseNum: "",
    otherphoneNumber: "",
    othercompnyNme: "",
    offcrFrstNme: "",
    offcrLast: "",
    offcrMiddle: "",
    relatnOwner: "",
    otheremailAdd: "",
  };
  const CondoInfoFeilds = {
    condoAdd: "",
    condocenter: "",
    condostreetName: "",
    condoltitude: "",
    condolngitude: "",
    condocityName: "",
    condostateName: "",
    condozipCode: "",
    condohouseNum: "",
    condophoneNumber: "",
    condoemailAdd: "",
    condocompnyNme: "",
    condooffcrFrstNme: "",
    condooffcrLast: "",
    condooffcrMiddle: "",
    condorelatnOwner: "",
  };
  //

  const handleOnChangeDeal = async (e) => {
    let obj = { ...data };
    // setEditDealDetails(1);
    if (e.currentTarget.name == "Client") {
      if (func === "create") {
        obj.Client = e.currentTarget.value;
   

        if (e.currentTarget.services) {
          let cloneSrvice = [...allServicesName];
         
          cloneSrvice.forEach((one, index) => {
            if (
              e.currentTarget.services.filter((obj) => obj.id == one.id)
                .length > 0
            ) {
              obj[one.key] = "Yes";
              cloneSrvice[index].value = "Yes";
            }
          });
         
          setAllServicesName(cloneSrvice);
          obj["Services"] = e.currentTarget.services;
        } else {
          obj["Services"] = [];
        }
        setData(obj);
        // setOnChangeAddress(false);
      } else {
        const activityArr = [...activityDetails];
        let activityObj = {};
        activityObj.PrevState = {
          Value: cacheObj[e.currentTarget.name],
          Name: e.currentTarget.name,
        };
        activityObj.UpdatedState = {
          Value: e.target.options[e.target.selectedIndex].text,
          Name: e.currentTarget.name,
        };
        obj[e.currentTarget.name] =
          e.target.options[e.target.selectedIndex].text;
        obj["ClientId"] = e.target.value;
        activityArr.push(activityObj);
        setActivityDetails(activityArr);
        setData(obj);
        // setOnChangeAddress(false);
      }
    }
    if (e.currentTarget.name == "Contractor") {
      if (func === "create") {
        obj.Contractor = e.currentTarget.value;

        setData(obj);
        // setOnChangeAddress(false);
      } else {
        const activityArr = [...activityDetails];
        let activityObj = {};
        activityObj.PrevState = {
          Value: cacheObj[e.currentTarget.name],
          Name: e.currentTarget.name,
        };
        activityObj.UpdatedState = {
          Value: e.target.options[e.target.selectedIndex].text,
          Name: e.currentTarget.name,
        };
        obj[e.currentTarget.name] =
          e.target.options[e.target.selectedIndex].text;
        obj["ClientId"] = e.target.value;
        activityArr.push(activityObj);
        setActivityDetails(activityArr);
        setData(obj);
        // setOnChangeAddress(false);
      }
    }
    if (e.currentTarget.name == "Electrician") {
      if (func === "create") {
        obj.Electrician = e.currentTarget.value;
        setData(obj);
        // setOnChangeAddress(false);
      } else {
        const activityArr = [...activityDetails];
        let activityObj = {};
        activityObj.PrevState = {
          Value: cacheObj[e.currentTarget.name],
          Name: e.currentTarget.name,
        };
        activityObj.UpdatedState = {
          Value: e.target.options[e.target.selectedIndex].text,
          Name: e.currentTarget.name,
        };
        obj[e.currentTarget.name] =
          e.target.options[e.target.selectedIndex].text;
        obj["ClientId"] = e.target.value;
        activityArr.push(activityObj);
        setActivityDetails(activityArr);
        setData(obj);
        // setOnChangeAddress(false);
      }
    } else if (e.currentTarget.name == "Address") {
      setDuplicateDealObj([]);
      if (e.currentTarget.already) {
        setAddressChangeFlag(true);
        // setOnChangeAddress(false);
        let dataDeal = [...dealData];
        let filterDeal = dataDeal.filter(
          (one) => one.Project_Address === e.currentTarget.value.ProjectAddress
        );
        if (filterDeal.length > 0) {
          ShowLoader = false;
          setDuplicateModal(!duplicateModal);
          setDuplicateDealObj(filterDeal);
          setAvailabaleDuplicateDeals(filterDeal);
        }
        setProjectAddress(e.currentTarget.value);
      } else {
        if (func === "create") {
          let mergeObj = { ...obj, ...e.currentTarget.value };
          let dataDeal = [...dealData];
          let filterDeal = dataDeal.filter(
            (one) =>
              one.Project_Address === e.currentTarget.value.ProjectAddress
          );
          if (filterDeal.length > 0) {
            setDuplicateModal(!duplicateModal);
            setDuplicateDealObj(filterDeal);
            setAvailabaleDuplicateDeals(filterDeal);
          }
          setOldProjectAdd(e.currentTarget.value);
          setData(mergeObj);
        } else {
          const activityArr = [...activityDetails];
          let mergeObj = { ...obj, ...e.currentTarget.value };
          setOldProjectAdd(e.currentTarget.value);
          let activityObj = { ...e.currentTarget.activity };
          activityObj.PrevState = {
            Value: cacheObj.ProjectAddress,
            Name: "Address",
          };
          activityArr.push(activityObj);
          setActivityDetails(activityArr);
          setData(mergeObj);
        }
        // setOnChangeAddress(true);
      }
    } else if (e.currentTarget.name == "InsertInfo") {
      // setOnChangeAddress(false);
      let mergeObj1 = { ...obj, ...e.currentTarget.value };
      setData(mergeObj1);
      // setOnChangeAddress(false);
    } else if (e.currentTarget.name == "Merge") {
      // setOnChangeAddress(false);
      let mergeObj2 = { ...obj, ...e.currentTarget.value };
      setData(mergeObj2);
      // setOnChangeAddress(false);
    } else if (e.currentTarget.name == "Services") {
      if (e.currentTarget.action == "Yes") {
        // setOnChangeAddress(false);
        obj.Services.push(e.currentTarget.value);
        setData(obj);
        // setOnChangeAddress(false);
      } else {
        // setOnChangeAddress(false);
        obj.Services = obj.Services.filter(
          (one) => one.value !== e.currentTarget.value.value
        );
        setData(obj);
        // setOnChangeAddress(false);
      }
    } else if (e.currentTarget.name == "ServiceMerge") {
      // setOnChangeAddress(false);
      obj.Services = e.currentTarget.value;
      setData(obj);
      // setOnChangeAddress(false);
    } else {
      if (e.currentTarget.name == "withoutpermit") {
        if (e.currentTarget.value == "No") {
          obj.wwpViolation = [];
          setSidebarCardsStatus({
            ...sidebarCardsStatus,
            EditDocuments: {
              violationDocs: [],
              paidUnderJobDocs: [],
              invoiceDocs: [],
            },
          });
        }
      }
      if (e.currentTarget.name == "PreviousSolar") {
        if (e.currentTarget.value == "No") {
          obj["Previous_Solar_App"] = [];
          obj["Previous_Solar_Info"] = [];
        }
      }
      if (e.currentTarget.name == "Conjunction") {
        if (e.currentTarget.value == "No") {
          obj["lblConjunction"] = "";
        }
      }
      if (e.currentTarget.name == "Construction") {
        obj["constructionval"] = "";
      }
      if (e.currentTarget.name == "Occupancy") {
        obj["occupancyval"] = "";
      }
      if (e.currentTarget.name == "ownerType") {
        let fileRemove = { ...sidebarCardsStatus };
        let archiveFiles= [...sidebarCardsStatus.archiveDocumentArray]
        let data = {};
        let type1 = e.currentTarget.value;
        if (type1?.toUpperCase() == "INDIVIDUAL") {
          if(fileRemove.DHCRLetter){
            archiveFiles.push(fileRemove.DHCRLetter);
          }
          if(fileRemove.DeedCertificate){
            archiveFiles.push(fileRemove.DeedCertificate);
          }
          fileRemove = { ...fileRemove, DHCRLetter: null };
          fileRemove = { ...fileRemove, DeedCertificate: null };
          data = { ...data, ...otheOwnerInfoFeilds, ...CondoInfoFeilds };
          if (obj.mailAddDifferent == false) {
            data = { ...data, ...homeOwnerAddInfoFeilds };
          }
        } else if (type1?.toUpperCase() == "CONDO/CO-OP") {
          data = { ...data, ...homeOwnerAddInfoFeilds, ...homeOwnerInfoFeilds };
        } else if (
          type1?.toUpperCase() == "PARTNERSHIP" ||
          type1?.toUpperCase() == "CORPORATION" ||
          type1?.toUpperCase() == "OTHER GOVERNMENT AGENCY" ||
          type1?.toUpperCase() == "NYC AGENCY" ||
          type1?.toUpperCase() == "NYCHA/HHC"
        ) {
          data = {
            ...data,
            ...homeOwnerAddInfoFeilds,
            ...homeOwnerInfoFeilds,
            ...CondoInfoFeilds,
          };
        }
        setSidebarCardsStatus({ ...fileRemove ,archiveDocumentArray: archiveFiles});
        obj = { ...obj, ...data };
      }
      if (
        e.currentTarget.name == "mailAddDifferent" &&
        !e.currentTarget.checked
      ) {
        obj = { ...obj, ...homeOwnerAddInfoFeilds };
      }
      if (
        e.currentTarget.name == "asbestosReq" &&
        e.currentTarget.value == "No"
      ) {
        let archiveFiles= [...sidebarCardsStatus.archiveDocumentArray]
        if(sidebarCardsStatus.ACP5){
          archiveFiles.push(sidebarCardsStatus.ACP5);
        }
        let fileRemove = { ...sidebarCardsStatus, ACP5: null };
        obj["existingACP5ControlNumber"] = "";
        obj["existingACP5InspCertNumber"] = "";
        setSidebarCardsStatus({ ...fileRemove,archiveDocumentArray: archiveFiles });
      } else if (
        e.currentTarget.name == "asbestosReq" &&
        e.currentTarget.value == "Yes"
      ) {
        let archiveFiles= [...sidebarCardsStatus.archiveDocumentArray]
        if(sidebarCardsStatus.AsbestosCertificate){
          archiveFiles.push(sidebarCardsStatus.AsbestosCertificate);
        }
        let fileRemove = { ...sidebarCardsStatus, AsbestosCertificate: null };
        obj["nbApplication"] = "";
        setSidebarCardsStatus({ ...fileRemove ,archiveDocumentArray: archiveFiles});
      }
      if (
        e.currentTarget.name == "certificateoccupancy" &&
        e.currentTarget.value == "Yes"
      ) {
        obj["nbApplication"] = "";
      }
      if (
        e.currentTarget.name == "existingACP5" &&
        e.currentTarget.value == "No"
      ) {
        obj["existingACP5ControlNumber"] = "";
        obj["existingACP5InspCertNumber"] = "";
      }
      if (func == "create") {
        if (e.currentTarget.type == "checkbox") {
          obj[e.currentTarget.name] = e.currentTarget.checked;
        } else {
          obj[e.currentTarget.name] = e.currentTarget.value;
        }
        setData(obj);
        // setOnChangeAddress(false);
      } else {
        const activityArr = [...activityDetails];
        let activityObj = {};
        activityObj.PrevState = {
          Value: cacheObj[e.currentTarget.name],
          Name: e.currentTarget.name,
        };
        activityObj.UpdatedState = {
          Value: e.currentTarget.value,
          Name: e.currentTarget.name,
        };
        obj[e.currentTarget.name] = e.currentTarget.value;

        activityArr.push(activityObj);
        setActivityDetails(activityArr);
        setData(obj);
        // setOnChangeAddress(false);
      }
    }
  };

  useEffect(() => {
    parseDealModel(data);
    dispatch(CreateDealData(data));
  }, [data]);

  const getDate = (date) => {
    let date1 = new Date(date);
    return (
      date1.getFullYear() +
      "-" +
      (date1.getMonth() + 1 < 10 ? "0" : "") +
      (date1.getMonth() + 1) +
      "-" +
      (date1.getDate() < 10 ? "0" : "") +
      date1.getDate()
    );
  };

  const getPvSystemInfoArrayOfObj = (obj) => {
    let info = obj.pvSystemInfo ? [...obj.pvSystemInfo] : [];
    info.forEach((one, index, self) => {
      let obj = {};
      obj.System_Number = index + 1;
      obj.System_Size_KW1 = one.systemSize;
      obj.Interconnection_Number = parseInt(one.interConnectionNum);
      obj.Invertor_Type = one.invertertype;
      obj.Number_of_Invertors = one.numberOfInvertors;
      obj.PV_System_Id = one.pvSystemId;
      obj.Number_Of_Modules = parseInt(one.noOfModules);
      obj.Invertor_Model_Number = one.inverterModelNumber;
      obj.PV_Module_Model = one.pvmodulemodel;
      self[index] = obj;
    });
    return info;
  };

  const parseDealModel = (obj) => {
    obj = { ...obj };
    let specialDistArr = [];
    if (obj.specialDistList?.length > 0) {
      let specialDist = obj.specialDistList;
      specialDist.forEach((one) => {
        if (one.specialDist == "UNKNOWN") {
          specialDistArr.push("N/A");
        } else {
          specialDistArr.push(one.specialDist);
        }
      });
    } else {
      specialDistArr.push("N/A");
    }

    let zoningListArr = [];
    if (obj.zoningList?.length > 0) {
      let zoningList = obj.zoningList;
      zoningList.forEach((one) => {
        if (one.zoningdistrict == "UNKNOWN") {
          zoningListArr.push("N/A");
        } else {
            zoningListArr.push(one.zoningdistrict);
        }
      });
    } else {
      zoningListArr.push("N/A");
    }

    let commercialDistArr = [];
    if (obj.commercialList?.length > 0) {
      let commercialList = obj.commercialList;
      commercialList.forEach((one) => {
        if (one.commercialoverlay == "UNKNOWN") {
          commercialDistArr.push("N/A");
        } else {
          commercialDistArr.push(one.commercialoverlay);
        }
      });
    } else {
      commercialDistArr.push("N/A");
    }

    let apiData = {
      Service_Requested: obj.Services,
      wwpViolation: obj.wwpViolation,
      PV_Interconnection: getPvSystemInfoArrayOfObj(obj),
      Owner_Type: obj.ownerType,
      Occupancy_Classification_Building_2014_2008_Code: obj.Occupancy,
      Construction_Classification_Code_2014_2008_Code: obj.Construction,
      Occupancy_Classification_of_Building: obj.occupancyval,
      Construction_Classification_Code: obj.constructionval,
      ACP5_Completed: obj.existingACP5,
      Asbestos_Required: obj.asbestosReq,
      Building_Height: obj.buildingheight,
      NB_Application_Number: obj.nbApplication,
      Multiple_Dwelling_Classification: obj.multipleDwellingCallsification,
      Certificate_of_Occupancy_Available: obj.certificateoccupancy,
      Number_of_Dwelling_Units: obj.dwellingUnits,
      Number_of_Stories: obj.storynumber,
      Amount: obj.projectCost,
      Applicant: obj.applicant,
      Project_Cost: obj.projectCost,
      Number_of_Interconnection: obj.interconnNo,
      Closing_Date: getDate(new Date(obj.ProjectDate)),
      Account_Name: obj.Client,
      Client: obj.Client,
      Deal_Name: obj.ProjectNumber,
      Client: obj.Client,
      Project_Date: getDate(new Date(obj.ProjectDate)),
      Latitude: `${obj.Latitude}`,
      Longitude: `${obj.Longitude}`,
      Project_Address: obj.ProjectAddress,
      Street_Name: obj.StreetName,
      Zip_Code: obj.ZipCode,
      City: obj.City,
      State: obj.State,
      House_Number: obj.HouseNumber,
      Borough: obj.Borough,
      Bin_Number: obj.binNo,
      Block_Number: obj.blockNo,
      Lot_Number: obj.lotNo,
      Community_Board_Number: obj.community,
      Specials_Districts: specialDistArr,
      Tidal_Wetlands: obj.tidalWetland,
      Previous_Solar_Application: obj.PreviousSolar,
      Zoning_District: zoningListArr,
      Commercial_Overlay: commercialDistArr,
      Coastal_Erosion_Hazard: obj.coastal,
      Flood_Hazard: obj.floodHazard != null ? obj.floodHazard : "No",
      Zoning_Map_Number: (obj.zoiningMap ? obj.zoiningMap.toUpperCase() : "" ),
      Landmark_Area: obj.landmark,
      Fresh_Water_Wetland: obj.freshWater,
      Environmental_Restriction: obj.envRestrictions,
      Special_Fire_District: obj.firedistrict,
      Stop_Work_Order_Exists_on_Property: obj.StopWork,
      Active_Work_Without_Permit_Violations: obj.withoutpermit,
      Partial: obj.partial,
      General_Contractor: obj.Contractor,
      Electrician: obj.Electrician,
      Is_NYC_Project: obj.NYCProject,
      NYC_Project: obj.NYCProject,
      Previous_Solar_App: obj.Previous_Solar_App,
      Previous_Solar_Info: obj.Previous_Solar_Info,
      ACP5_Control_Number: obj.existingACP5ControlNumber,
      Asbestos_Inspector_Certificate_Number: obj.existingACP5InspCertNumber,
      Conjunction_Application_Number: obj.lblConjunction,
      Conjunction: obj.Conjunction,
      // Individual owner info //

      Property_Owner_s_Name: obj.homeOwnerName,
      Owner_s_First_Name: obj.OwnerFrstNme,
      Owner_s_Middle_Initial: obj.ownerMiddle,
      Owner_s_Last_Name: obj.ownerlastname,
      Owner_s_City: obj.cityName,
      Owner_Phone_Number: obj.phoneNumber,
      Owner_s_Email_Address: obj.emailAdd,
      Owner_s_Mailing_Address_Diff_Than_Property_Address: obj.mailAddDifferent,
      Owner_s_Mailing_Address: obj.MailingAdd,
      Owner_s_Street_Name: obj.streetName,
      Owner_s_State: obj.stateName,
      Owner_s_Zip_Code: obj.zipCode,
      Owner_s_House_Number: obj.houseNum,

      // Other owner info //
      Non_Profit: obj.nonProfit,
      DHCR: obj.dhcr,
      Other_Owner_City: obj.othercityName,
      Other_Owner_Email_Address: obj.otheremailAdd,
      Other_Owner_Company_Name: obj.othercompnyNme,
      Other_Owner_House_Number: obj.otherhouseNum,
      Other_Owner_Mailing_Address: obj.otherAdd,
      Other_Owner_Officer_First_Name: obj.offcrFrstNme,
      Other_Owner_Officer_Last_Name: obj.offcrLast,
      Other_Owner_Officer_Middle_Initial: obj.offcrMiddle,
      Other_Owner_Officer_Title: obj.relatnOwner,
      Other_Owner_Phone_Number: obj.otherphoneNumber,
      Other_Owner_State: obj.otherstateName,
      Other_Owner_Street_Name: obj.otherstreetName,
      Other_Owner_Zip_Code: obj.otherzipCode,

      // Condo info //

      Condo_Company_Name: obj.condocompnyNme,
      Condo_Mailing_Address: obj.condoAdd,
      Condo_Street_Name: obj.condostreetName,
      Condo_House_Number: obj.condohouseNum,
      Condo_City: obj.condocityName,
      Condo_State_Name: obj.condostateName,
      Condo_Zip_Code: obj.condozipCode,
      Condo_Officer_First_Name: obj.condooffcrFrstNme,
      Condo_Officer_Middle_Initial: obj.condooffcrMiddle,
      Condo_Officer_Last_Name: obj.condooffcrLast,
      Condo_Officer_Title: obj.condorelatnOwner,
      Condo_Phone_Number: obj.condophoneNumber,
      Condo_Email_Address: obj.condoemailAdd,
    };
    localStorage.setItem("data", JSON.stringify(apiData));
  };

  const getProjectNumber = (projectNumber) => {
    const obj = { ...data };
    obj["ProjectNumber"] = projectNumber;
    setData(obj);
  };

  const addressChangeFlagToogle = () =>
    setAddressChangeFlag(!addressChangeFlag);
  const dealResetProjectAddressWarningModal = () => {
    return (
      <Modal
        isOpen={
          addressChangeFlag &&
          sidebarCardsStatus.duplicateDeal?.dealName == null
        }
        backdrop={"static"}
        size="md"
        toggle={addressChangeFlagToogle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Deal Reset Warning</h5>
              <p className="taskComplete-warning-subtitle">
                Are you sure want to reset deal data?
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  const obj = { ...defaultData };
                  obj.Client = data.Client;
                  obj.ClientId = data.ClientId;
                  obj.ProjectDate = data.ProjectDate;
                  obj.ProjectNumber = data.ProjectNumber;
                  if (projectAddress) {
                    let mergeObj = { ...obj, ...projectAddress };
                    setData(mergeObj);
                    setOldProjectAdd(projectAddress);
                  }
                  // setOnChangeAddress(true);
                  setProjectAddress(null);
                  setAddressChangeFlag(false);
                }}
              >
                OK
              </button>

              <button
                onClick={() => {
                  setAddressChangeFlag(false);
                  const obj = { ...data };
                  if (projectAddress) {
                    let mergeObj = { ...obj };
                    if (oldProjectAdd) {
                      mergeObj = { ...mergeObj, ...oldProjectAdd };
                    }
                    setData(mergeObj);
                    setProjectAddress(null);
                  }
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  //hrushikesh shelke new code start
  const saveCancelButtonRef = useRef();
  useImperativeHandle(ref, () => ({
    saveButtonClick() {
      saveCancelButtonRef.current.saveButtonClick();
    },
    toggelActiveBar(d) {
      saveCancelButtonRef.current.toggelActiveBar(d);
    },
  }));
  const [sidebarCardsStatus, setSidebarCardsStatus] = useState({
    Preliminary: false,
    NycProject: false,
    Homeowner: false,
    Permitting: false,
    PVSystem: false,
    SkipNycFlag: false,
    NavActivetab: "Preliminary",
    isViolationActive: true,
    ShouldSave: false,
    AsbestosCertificate: null,
    DeedCertificate: null,
    DHCRLetter: null,
    Violation: [],
    PaidunderJob: [],
    RenameFilesArray: [],
    violationSummaryDocs: [],
    Invoice: [],
    ACP5: null,
    RemoveDocumentsArray: [],
    FilesExtention: null,
    EditDocuments: {
      violationDocs: [],
      paidUnderJobDocs: [],
      invoiceDocs: [],
      violationSummaryDocs: [],
    },
    duplicateDeal: {
      dealName: null,
      modules: [],
    },
    LoadingModalStatus: false,
    webScrapeMonitor: {},
    archiveDocumentArray:[],
    tidalImage : {
      image : null,
      name : null
    },
    floodMapDocs : null,
    mapNumberPdf : null
  });
  const updateTheSidebarState = async (data) => {
    if (data.type == "Preliminary" && data.status) {
      if (data.skipNycFlag) {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          Preliminary: data.status,
          NycProject: data.skipNycFlag,
          NavActivetab: "Homeowner",
          SkipNycFlag: data.skipNycFlag,
        });
      } else {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          Preliminary: data.status,
          NavActivetab: "NycProject",
          SkipNycFlag: data.skipNycFlag,
        });
      }
    } else if (data.type == "NycProject" && data.status) {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        NycProject: data.status,
        NavActivetab: "Homeowner",
      });
    } else if (data.type == "Homeowner" && data.status) {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        Homeowner: data.status,
        NavActivetab: "Permitting",
      });
    } else if (data.type == "Permitting" && data.status) {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        Permitting: data.status,
        NavActivetab: "PVSystem",
      });
    } else if (data.type == "PVSystem" && data.status) {
      setSidebarCardsStatus({ ...sidebarCardsStatus, PVSystem: data.status });
    } else if (data.type == "NavActivetab") {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        NavActivetab: data.status,
      });
    } else if (data.type == "SkipNycFlag") {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        SkipNycFlag: data.status,
      });
    } else if (data.type == "ShouldSave") {
      setSidebarCardsStatus({ ...sidebarCardsStatus, ShouldSave: data.status });
    } else if (data.type == "AsbestosCertificateUpload") {
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          AsbestosCertificate: {
            ...data.status,
            type: "pdf",
          },
        });
      } else {
        if(sidebarCardsStatus.AsbestosCertificate){
          archiveArr=[...archiveArr,sidebarCardsStatus.AsbestosCertificate]
        }
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          AsbestosCertificate: null,
          archiveDocumentArray: archiveArr
        });
      }
    } else if (data.type == "DeedCertificateUpload") {
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          DeedCertificate: {
            ...data.status,
            type: "pdf",
          },
        });
      } else {
        if(sidebarCardsStatus.DeedCertificate){
          archiveArr=[...archiveArr,sidebarCardsStatus.DeedCertificate]
        }
        setSidebarCardsStatus({ ...sidebarCardsStatus, DeedCertificate: null ,archiveDocumentArray: archiveArr});
      }
    } else if (data.type == "DHCRLetterUpload") {
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          DHCRLetter: {
            ...data.status,
            type: "pdf",
          },
        });
      } else {
        if(sidebarCardsStatus.DHCRLetter){
          archiveArr=[...archiveArr,sidebarCardsStatus.DHCRLetter]
        }
        setSidebarCardsStatus({ ...sidebarCardsStatus, DHCRLetter: null, archiveDocumentArray: archiveArr });
      }
    } else if (data.type == "ACP5Upload") {
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          ACP5: {
            ...data.status,
            type: "pdf",
          },
        });
      } else {
        if(sidebarCardsStatus.ACP5){
          archiveArr=[...archiveArr,sidebarCardsStatus.ACP5]
        }
        setSidebarCardsStatus({ ...sidebarCardsStatus, ACP5: null ,archiveDocumentArray: archiveArr });
      }
    } else if (data.type == "VoilationUpload") {
      let renameArray = [];
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        let oldV = sidebarCardsStatus.Violation;
        let oldEditDoc = sidebarCardsStatus.EditDocuments;
        if (data.action == "clear") {
          if(data.Archive){
            archiveArr=[...archiveArr,...data.Archive]
          }
          let rData = oldV?.find(
            (e) =>
              e.violationNo.toUpperCase() ==
              data.status.violationNo.toUpperCase()
          );
          oldV = oldV?.filter(
            (e) =>
              e.violationNo.toUpperCase() !=
              data.status.violationNo.toUpperCase()
          );
          if (rData?.violationNo) {
            let vtemp = oldEditDoc.violationDocs?.filter(
              (e) => e.id.toUpperCase() != rData.violationNo.toUpperCase()
            );
            oldEditDoc["violationDocs"] = vtemp;
          }
          if (rData?.invoiceNo) {
            let vtemp = oldEditDoc.invoiceDocs?.filter(
              (e) => e.id.toUpperCase() != rData.invoiceNo.toUpperCase()
            );
            oldEditDoc["invoiceDocs"] = vtemp;
          }
          if (rData?.jobNo) {
            let vtemp = oldEditDoc.paidUnderJobDocs?.filter(
              (e) => e.id.toUpperCase() != rData.jobNo.toUpperCase()
            );
            oldEditDoc["paidUnderJobDocs"] = vtemp;
          }
          if (rData?.violationNo) {
            let vtemp1 = oldEditDoc.violationSummaryDocs?.filter(
              (e) => e.id.toUpperCase() != rData.violationNo.toUpperCase()
            );
            oldEditDoc["violationSummaryDocs"] = vtemp1;
          }
        } else if (data.action == "update") {
          if(data.Archive){
            archiveArr=[...archiveArr,...data.Archive]
          }
          if (data.renameFileArr) {
            renameArray = [...data.renameFileArr];
          }
          let rData = oldV?.find(
            (e) =>
              e.violationNo.toUpperCase() ==
              data.oldObj.violationNum.toUpperCase()
          );
          oldV = oldV?.filter((e) => e.violationNo != data.oldObj.violationNum);
          if (data.docs && data.oldObj) {
            if (data.docs.violationDoc) {
              let vtemp = oldEditDoc.violationDocs?.filter(
                (e) =>
                  e.id.toUpperCase() != data.oldObj.violationNum.toUpperCase()
              );
              vtemp.push(data.docs.violationDoc);
              oldEditDoc["violationDocs"] = vtemp;
            }
            if (data.docs.violationSummaryDocs) {
              let vtemp = oldEditDoc.violationSummaryDocs?.filter(
                (e) =>
                  e.id.toUpperCase() != data.oldObj.violationNum.toUpperCase()
              );
              vtemp.push(data.docs.violationSummaryDocs);
              oldEditDoc["violationSummaryDocs"] = vtemp;
            }
            if (data.docs.invoiceDoc) {
              let vtemp = oldEditDoc.invoiceDocs?.filter(
                (e) => e.id.toUpperCase() != data.oldObj.invoiceNo.toUpperCase()
              );
              vtemp.push(data.docs.invoiceDoc);
              oldEditDoc["invoiceDocs"] = vtemp;
            }
            if (data.docs.paidUnderJobDoc) {
              let vtemp = oldEditDoc.paidUnderJobDocs?.filter(
                (e) => e.id.toUpperCase() != data.oldObj.jobNo.toUpperCase()
              );
              vtemp.push(data.docs.paidUnderJobDoc);
              oldEditDoc["paidUnderJobDocs"] = vtemp;
            }
          }
          oldV.push(data.status);
        } else {
          if (data.docs) {
            if (data.docs.violationDoc) {
              oldEditDoc.violationDocs.push(data.docs.violationDoc);
            }
            if (data.docs.violationSummaryDocs) {
              oldEditDoc.violationSummaryDocs.push(
                data.docs.violationSummaryDocs
              );
            }
            if (data.docs.invoiceDoc) {
              oldEditDoc.invoiceDocs.push(data.docs.invoiceDoc);
            }
            if (data.docs.paidUnderJobDoc) {
              oldEditDoc.paidUnderJobDocs.push(data.docs.paidUnderJobDoc);
            }
          }
          oldV.push(data.status);
        }
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          Violation: oldV,
          EditDocuments: oldEditDoc,
          RenameFilesArray: renameArray,
          archiveDocumentArray: archiveArr
        });
      } else {
        setSidebarCardsStatus({ ...sidebarCardsStatus, Violation: null });
      }
    } else if (data.type == "violationSummaryUpload") {
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        let oldV = sidebarCardsStatus.violationSummary;
        if (data.action == "clear") {
          if(data.Archive){
            archiveArr=[...archiveArr,...data.Archive]
          }
          oldV = oldV?.filter((e) => e.violationNo != data.status.violationNo);
        } else if (data.action == "update") {
          oldV = oldV?.filter((e) => e.violationNo != data.status.violationNo);
          oldV.push(data.status);
        } else {
          oldV.push(data.status);
        }
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          violationSummary: oldV,
          archiveDocumentArray: archiveArr
        });
      } else {
        setSidebarCardsStatus({ ...sidebarCardsStatus, violationSummary: [] });
      }
    } else if (data.type == "PaidunderJobUpload") {
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        let oldV = sidebarCardsStatus.PaidunderJob;
        if (data.action == "clear") {
          if(data.Archive){
            archiveArr=[...archiveArr,...data.Archive]
          }
          oldV = oldV?.filter(
            (e) => e.PaidunderJobNo != data.status.PaidunderJobNo
          );
        } else if (data.action == "update") {
          oldV = oldV?.filter(
            (e) => e.PaidunderJobNo != data.status.PaidunderJobNo
          );
          oldV.push(data.status);
        } else {
          oldV.push(data.status);
        }
        setSidebarCardsStatus({ ...sidebarCardsStatus, PaidunderJob: oldV,archiveDocumentArray: archiveArr });
      } else {
        setSidebarCardsStatus({ ...sidebarCardsStatus, PaidunderJob: null });
      }
    } else if (data.type == "InvoiceUpload") {
      let archiveArr=sidebarCardsStatus.archiveDocumentArray;
      if (data.status != null) {
        let oldV = sidebarCardsStatus.Invoice;
        if (data.action == "clear") {
          if(data.Archive){
            archiveArr=[...archiveArr,...data.Archive]
          }
          oldV = oldV?.filter((e) => e.invoiceNo != data.status.invoiceNo);
        } else if (data.action == "update") {
          oldV = oldV?.filter((e) => e.invoiceNo != data.status.invoiceNo);
          oldV.push(data.status);
        } else {
          oldV.push(data.status);
        }
        setSidebarCardsStatus({ ...sidebarCardsStatus, Invoice: oldV,archiveDocumentArray: archiveArr });
      } else {
        setSidebarCardsStatus({ ...sidebarCardsStatus, Invoice: null });
      }
    } else if (data.type == "RemoveFilesArray") {
      let temp = sidebarCardsStatus.RemoveDocumentsArray;
      temp.push(data.status);
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        RemoveDocumentsArray: temp,
      });
    } else if (data.type == "FilesExtention") {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        FilesExtention: data.status,
        SkipNycFlag: data.SkipNycFlag,
      });
    } else if (data.type == "LoadingModalStatus") {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        LoadingModalStatus: data.status,
      });
    } else if (data.type == "EmailValidationCheck") {
      var validRegex = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
      if (data.status.match(validRegex)) {
        return true;
      } else {
        return false;
      }
    } else if (data.type == "updateObject") {
      if (data.status) {
        if (data.ViolationTabStatus) {
          setSidebarCardsStatus({
            ...sidebarCardsStatus,
            EditDocuments: data.status.EditDocuments,
            isViolationActive: true,
          });
        } else {
          setSidebarCardsStatus({
            ...sidebarCardsStatus,
            EditDocuments: data.status.EditDocuments,
          });
        }
      }
    } else if (data.type == "DuplicateDeal") {
      if (data.flag == "Modules") {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          duplicateDeal: {
            ...sidebarCardsStatus.duplicateDeal,
            modules: data.status,
          },
        });
      }
      if (data.flag == "dealName") {
        setSidebarCardsStatus({
          ...sidebarCardsStatus,
          duplicateDeal: { dealName: data.status, modules: [] },
        });
      }
    } else if (data.type == "ViolationTabStatus") {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        isViolationActive: data.status,
      });
    } else if (data.type == "removeDuplicateUrl") {
      if (window.history && window.history.replaceState) {
        var currentURL = window.location.href;
        // Check if the previous entry in the history is the same as the current URL
        if (window.history.state && window.history.state.path === currentURL) {
          window.history.replaceState(null, "", currentURL);
        }
      }
    } else if (data.type == "webScrapingApi") {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        tidalImage : data.tidalData? data.tidalData : null,
        floodMapDocs : data.floodMapDocs ? data.floodMapDocs : null,
        webScrapeMonitor: { ...data.status },
      });
    }else if (data.type == "mapNumberDownload") {
      setSidebarCardsStatus({
        ...sidebarCardsStatus,
        mapNumberPdf : data.mapNumberPdf ? data.mapNumberPdf : null,
      });
    }
    
    else if(data.type=="Archive"){
      let arr=sidebarCardsStatus.archiveDocumentArray;
      if(arr){
        arr=[...arr,data.status];
      }else{
        arr=[...data.status];
      }
      setSidebarCardsStatus({...sidebarCardsStatus,archiveDocumentArray:arr})
    }else if(data.type == "TidalUpload"){
      setSidebarCardsStatus({...sidebarCardsStatus,tidalImage:{image : data.status.image , name : data.status.name}})
    }else if(data.type == "FetchPrevDataUrl"){
        return await props.fetchAuthenticatedUrl(data.status);
    }
  };

  useEffect(() => {
    // console.log(
    //   "state refreshed................................",
    //   sidebarCardsStatus
    // );
    props.setSidebarCardsStatus({ ...sidebarCardsStatus });
  }, [sidebarCardsStatus]);
  //hrushikesh shelke new code end
  const waitCode = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [showPrompCode, setShowPromptCode] = useState(false);
  async function getPromptCode() {
    if (location.state.action === "Update") {
      await waitCode(5000);
    } else {
      await waitCode(4000);
    }
    setShowPromptCode(true);
  }
  return (
    <div className="createdeal-height">
      {duplicateDealModal()}
      {showDuplicateItemsPopup()}
      {showDropdownDealFields()}
      {getPromtt()}
      {dealResetProjectAddressWarningModal()}
      {/* <CreateDealSubheader isAccess={props.isAccess} selectedRole={props.selectedRole} onChangeAddress={onChangeAddress} resetDealData={resetDealData} activityDetails={activityDetails} setEditDealDetails={setEditDealDetails} ClickSaveSave={ClickSaveSave} data={data} func={func} /> */}
      <Row className="mar-15 createdeal-height2">
        <Col sm={2} className="scrollingSideBar">
          <SideNavbar
            setLayoutState={setLayoutState}
            layoutstate={layoutstate}
            sidebarCardsStatus={sidebarCardsStatus}
            toggelActiveBar={props.toggelActiveBar}
            updateTheSidebarState={updateTheSidebarState}
            listState={listState}
            setListState={setListState}
            setNycVisible={setNycVisible}
            isNycVisible={isNycVisible}
            ownerType={ownerType}
            setownerType={setownerType}
            mailAddressDisable={mailAddressDisable}
            data={data}
          />
        </Col>
        <Col
          sm={10}
          className="pad-l0"
        >
          <Layout
            ref={saveCancelButtonRef}
            location={location}
            getDealData={getDealData}
            setAllServicesName={setAllServicesName}
            allServicesName={allServicesName}
            allDeals={props.allDeals}
            isAccess={props.isAccess}
            selectedRole={props.selectedRole}
            setEntityId={setEntityId}
            updateTheSidebarState={updateTheSidebarState}
            sidebarCardsStatus={sidebarCardsStatus}
            entityId={entityId}
            setButtonPosition={setButtonPosition}
            buttonPosition={buttonPosition}
            setSidebarCardsStatus={props.setSidebarCardsStatus}
            setLoader={props.setLoader}
            refreshDeal={refreshDeal}
            func={func}
            getProjectNumber={getProjectNumber}
            handleOnChangeDeal={handleOnChangeDeal}
            data={data}
            setData={setData}
            layoutstate={layoutstate}
            setLayoutState={setLayoutState}
            listState={listState}
            setListState={setListState}
            setNycVisible={setNycVisible}
            isNycVisible={isNycVisible}
            setownerType={setownerType}
            ownerType={ownerType}
            setMailAddressDisable={setMailAddressDisable}
            mailAddressDisable={mailAddressDisable}
          />
        </Col>
      </Row>
    </div>
  );
});

export default Createdeal;
