import React, { useState, useEffect, useContext, useMemo } from "react";
import HttpService from "../../services/http.service";
import "./ProjectTasks.css";
import CreatePipeline from "../../tabs/Task/Pipelines/CreatePipeline";
// import Taskcard from "../job/jobbody/tasks/Taskcard";
import { useHistory, useLocation, useParams } from "react-router-dom";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { subDays } from "date-fns/esm";
import { Tabs, Tab, Nav } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Config from "../../config.json";
import _ from "lodash";
import {
  Col,
  FormGroup,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import ModuleService from "../../common/util/ModuleService";
import UserService from "../../common/util/UserService";
import Loader from "../../common/loader";
import dayjs from "dayjs";
import { Popover as AntdPopover, Button, Switch, Tooltip } from "antd";
import Task_Filters from "../../common/task_filters/task_filter";
import AddColumn from "../../common/AddColumn/add_column";
import { faFlag, faUser } from "@fortawesome/free-regular-svg-icons";
import dateTimeObject from "../../common/util/date";
import ProjectService from "../../common/util/ProjectService";
import FieldService from "../../common/util/FieldService";
import StatusDropdown from "../../common/StatusDropdown/StatusDropdown";
import CustomDropdown from "../../common/custom_dropdown/CustomDropdown";
import refreshICON from "../../assets/images/refresh-icon.png";
import AppContext from "../../Context/AppContext";
import { GlobalSearch } from "../GlobalSearch/GlobalSearch";
import GetElementsGroupBy from "./GetElementsGroupBy";
import CreateTaskModal from "../../tabs/Task/CreateTaskModal"
import Pipelines from "../../tabs/Task/Pipelines/Pipelines";
import ViewPipelines from "../../tabs/Task/Pipelines/ViewPipelines";
import { RenderAllTasks } from "./RenderAllTasks";
import AwsServerService from "../../common/util/AwsServerService";
import { RenderAllPipelineTemplates } from "./RenderAllPipelineTemplates";
import { AppDataRequired } from "../../App";
import {  faCaretDown, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import GanttView from "./GanttView";
import { ViewSwitcher } from "./viewSwitcher";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ViewMode } from "gantt-task-react";
import moment from "moment";
let dealsResult = [];

const ProjectTasks = (props) => {
  const location = props.location;
  const history = props.history;
  const params = useParams();
  useEffect(() => {
    if (props.getParams) {
      props.getParams(params);
    }
  }, [location]);
  const [dummyDealObj, setDummyDealObj] = useState({
    id: "Miscellaneous",
    Account_Name: { name: "Miscellaneous", id: "Miscellaneous" },
    Deal_Name: "Miscellaneous",
  });
  const [Deals, setDeals] = useState([]);
  const [dealStatus,setDealStatus] = useState([]);
  const [dealList, setDealList] = useState([]);
  const [DropDownTasks, setDropDownTasks] = useState([]);
  const [taskModifiedData, setTaskModifiedData] = useState({
    my_task: [],
    task_to_pass: [],
  });
  const [groupFilter, setGroupFilter] = useState({
    group1: { name: "Deal Name", value: "Deal_Name", extraKey: "Account_Name" },
    group2: { name: "Priority", value: "Priority" },
  })
  const [AllClients, setAllClients] = useState("");
  const [isSubTaskView,setIsSubTaskView] = useState(false)
  const [cacheDealData, setCacheDealData] = useState([]);

  const [DropDownDeals, setDropDownDeals] = useState([]);
  const [viewTaskData,setViewTaskData] = useState(null);
  const [DealSearchResult, setDealSearchResult] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [allTimeLogs, setAllTimeLogs] = useState([]);
  const [refreshData, setPageRefreshData] = useState(false)
  const [loader, setLoader] = useState(false);
  const [createTaskModal, setCreateTaskModal] = useState(false);
  const [taskModalState, setTaskModalState] = useState({
    module:"TASK",action:"create"
  });
  const [createPiplineModal, setCreatePiplineModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("create");
  const [dataToEdit, setDataToEdit] = useState({});
  const [viewPipelineData, setViewPipelineData] = useState([]);
  const [cacheGroupBy, setCacheGroupBy] = useState({
    Task: "Pipeline",
    Deal: "Deal Name",
  });
  const [view, setView] = useState(ViewMode.Day); 
  const [isChecked, setIsChecked] = useState(true);
  const [viewData, setViewData] = useState([]);
  const priorityValues = [
    { name: "None", color: "lightgray" },
    { name: "High", color: "#e50000" },
    { name: "Medium", color: "#f7c700" },
    { name: "Low", color: "blue" },
  ];
  const AppData = useContext(AppContext);
  const AppRequireData = useContext(AppDataRequired);
  const { dispatch, state } = AppData || {};
  const [allPipeline, setAllPipeline] = useState([]);
  const [activeView, setActiveView] = useState('List');
  const [searchText,setSearchText] = useState("");
  const [searchPipelines,setSearchPipelines] = useState("");
  const [searchSettings,setSearchSettings] = useState({
    TaskName:true,
    Description:false
  })
  const [taskFilterSetting, setTaskFilterSetting] = useState({
    TaskLocation: false,
    SubtaskParntNames: false,
    ExpandDeals: true,
    ExpandGroups: true,
    ExpandTasks: false,
    ViewSeparateTask: false,
  });

  const [allPipelineTemplates, setAllPipelineTemplates] = useState([]);
  const [cacheAllPipelineTemplates, setCacheAllPipelineTemplates] = useState([]);
  const [allDependeniesData, setAllDependeniesData] = useState([]);
  const [allBlueprintsData,setAllBlueprintData]=useState([]);
  useEffect(()=>{
    AwsServerService.getAllTaskFlows().then(res=>{
      setAllBlueprintData(res);
    }).catch(err=>{
      console.log(err);
    })
  },[])
  useEffect(()=>{ 
    AwsServerService.getAllDependencies().then(r=>{ 
      let data=r.data[0]
      setAllDependeniesData(data)
    }
    )
  },[])

  const [allTasksAndSubtasks,setAllTasksAndSubtasks]=useState({Tasks:[],SubTasks:[]});
  const [showDependencies, setShowDependencies] = useState({});
  const [dropdownTasksDataCache,setDropdownTasksDataCache]=useState([]);

  const getTasksAndSubtasks = (data) => {
    const result = []; 
    const taskMap = new Map();
    const subtaskMap = new Map(); 
    data?.forEach(pipeline => {
      pipeline.Tasks?.forEach(task => { 
        taskMap.set(task.TaskROWID, task);
        result.push({
          label: task.TaskName,
          value: `Tasks_${task.TaskROWID}`
        }); 
        if (task.SubTasks) {
          task.SubTasks?.forEach(subtask => {
            subtaskMap.set(subtask.SubTaskROWID,subtask)
            result.push({
              label: subtask.TaskName,
              value: `SubTasks_${subtask.SubTaskROWID}`
            });
          });
        }
      });
    }); 
    setAllTasksAndSubtasks({Tasks:taskMap,SubTasks:subtaskMap})
    return result; 
  };
  useEffect(()=>{
    if(allPipeline.length>0){
      let data=getTasksAndSubtasks(allPipeline) 
      // setDropdownTasksData(data);
      setDropdownTasksDataCache(data);
    }
  },[allPipeline]) 
  const fetchAllPiplineTasks = async () => {
    await AwsServerService.getAllPipeline().then(res => {
      // console.log("------p", res);
      setAllPipeline(res);
    })
  }
  const fetchAllPiplineTemplateTasks = async () => {
    await AwsServerService.getPipelineTemplatesData().then(res => {
      // console.log("------p template", res);
      setAllPipelineTemplates(res);
      setCacheAllPipelineTemplates(res)
    })
  }
  useEffect(e=>{
    if(cacheAllPipelineTemplates){
      if(searchPipelines){
        let temp = cacheAllPipelineTemplates.filter(data => data?.PipelineName?.toLowerCase().includes(searchPipelines?.toLowerCase()))
        setCacheAllPipelineTemplates(temp)
      }else{
        setCacheAllPipelineTemplates(allPipelineTemplates)
      }
    }
  },[searchPipelines])
  useEffect(e=>{
    setDealStatus(AppRequireData.DealStatus);
  },[AppRequireData.DealStatus])
  useEffect(e => {
    fetchAllPiplineTasks();
    fetchAllPiplineTemplateTasks();
  }, [refreshData])
  useEffect(
    (e) => {
      setDropDownDeals(state.AllDeals);
    },
    [state.AllDeals]
  );
  const [taskLayout, setTaskLayout] = useState({
    status_details: [
      // {
      //     is_start: true,
      //     color: "#f56b62",
      //     name: "Pending",
      //     id: "1716273000002029049",
      //     is_default: false,
      //     type: "open",
      // },
      {
        is_start: false,
        color: "#a9b2c0",
        name: "Not Required",
        id: "1716273000002029043",
        is_default: false,
        type: "closed",
      },
      {
        is_start: false,
        color: "#a7bcee",
        name: "Not Started",
        id: "1716273000002029045",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#fbc11e",
        name: "To Do",
        id: "1716273000002029041",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#08aeea",
        name: "In Progress",
        id: "1716273000000031001",
        is_default: true,
        type: "open",
      },
      {
        is_start: false,
        color: "#98ca3c",
        name: "Completed",
        id: "1716273000002029047",
        is_default: false,
        type: "closed",
      },
    ],
  });

  const [addTaskColumns, setAddTaskColumns] = useState([
    {
      name: "TIME TRACKING",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "TIME_TRACKING",
      field: "TIME_TRACKING",
      type: "text",
    },
    {
      name: "SUPERVISER",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "SUPERVISER",
      field: "SUPERVISER",
      type: "text",
    },
    {
      name: "ASSIGNEE",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "ASSIGNEE",
      field: "ASSIGNEE",
      type: "text",
    },
    {
      name: "DUE DATE",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "DUE_DATE",
      field: "DUE_DATE",
      type: "text",
    },
    {
      name: "PRIORITY",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "PRIORITY",
      field: "PRIORITY",
      type: "text",
    },
    {
      name: "TIME ESTIMATE",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "TIME_ESTIMATE",
      field: "TIME_ESTIMATE",
      type: "text",
    },
    {
      name: "PROGRESS",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "PROGRESS",
      field: "PROGRESS",
      type: "text",
    },
    {
      name: "STATUS",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "STATUS",
      field: "STATUS",
      type: "text",
    },
    {
      name: "PIPELINE",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "PIPELINE",
      field: "PIPELINE",
      type: "text",
    },
    {
      name: "CATEGORY",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "CATEGORY",
      field: "CATEGORY",
      type: "text",
    },
    {
      name: "MILESTONE",
      isChecked: false,
      isVisible: true,
      isSearch: true,
      isDragOver: false,
      id: "MILESTONE",
      field: "MILESTONE",
      type: "text",
    },
  ]);

  const [filterItems, setFilterItems] = useState([
    { name: "Status", type: "string" },
    { name: "Task Name", type: "string" },
    { name: "Pipeline", type: "string" },
    { name: "Category", type: "string" },
    { name: "Due Date", type: "date" },
    { name: "Priority", type: "string" },
    { name: "Milestone", type: "string" },
    { name: "Assignee", type: "string" },
    { name: "Created By", type: "string" },
    { name: "Start Date", type: "date" },
  ]);

  const [filterItemsDeal, setFilterItemsDeal] = useState([
    { name: "Deal Name", type: "string" },
    { name: "Client", type: "string" },
    // { name: 'Project Address', type: 'string' },
    { name: "Status", type: "string" },
    { name: "Borough", type: "string" },
    // { name: "Bin Number", type: 'string' },
    // { name: "Lot Number", type: 'string' },
    // { name: "Block Number", type: 'string' },
    { name: "Closing Date", type: "date" },
    { name: "Created By", type: "string" },
  ]);
  const pvSystemTableHeaders = [
    { value: "Interconnection_Number", label: "Interconnection Number" },
    { value: "Invertor_Model_Number", label: "Invertor Model Number" },
    { value: "Invertor_Type", label: "Invertor Type" },
    { value: "Number_Of_Modules", label: "Number Of Modules" },
    { value: "PV_Module_Model", label: "PV Module Model" },
    { value: "System_Number", label: "System Number" },
    { value: "System_Size_KW1", label: "System Size KW" },
  ];
  const wwwViolationHeaders = [
    {
      value: "Civil_Penalty_Payment_Required",
      label: "Civil Penalty Payment Required",
    },
    { value: "Invoice_Number", label: "Invoice Number" },
    { value: "ISN_Number", label: "ISN Number" },
    { value: "L2_Code", label: "L2 Code" },
    { value: "Paid_Under_Job_Number", label: "Paid Under Job Number" },
    { value: "Violation_Number", label: "Violation Number" },
    { value: "Violation_Type", label: "Violation Type" },
  ];
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));

const goToNextMonth = () => { 
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
};

const goToPreviousMonth = () => { 
  setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
};

  const [columnsEdit, setColumnsEdit] = useState(false);
  // const [dealStatus, setDealStatus] = useState([]);

  const [selectedTable, setSelectedTable] = useState("tasks");
  const [filterState, setFilterState] = useState(true);
  const viewTaskFunction=(task,module)=>{
    // console.log("1111111111111111111111");    
      if(module == "SUBTASK"){
        setIsSubTaskView(true)
      }
      setCreateTaskModal(true);
      setViewTaskData(task)
      setTaskModalState({module:module,action:"edit"})
  }

// console.log("createTaskModal", createTaskModal);

  const status = [
    "On Hold",
    "Proposal",
    "Cancelled",
    "Completed",
    "In Progress",
  ];


  useEffect(() => {
    if (allJobs.length > 0) {
      getAllUsers();
      // getAllTasks();
      getFieldHeaders();
    }
  }, [allJobs, allTimeLogs, state.AllAccounts]);

  useEffect(() => {
    if (allJobs.length > 0 && AllClients.length > 0) {
      getAllDeals({ data: { data: state.AllDeals } });
    }
  }, [allJobs, AllClients, state.AllDeals]);

  const [allPipelines, setAllPipline] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const subformApiName = ["Deal_Name", "PV_Interconnection"];
  const moduleApiNameForSubform = ["WWP_Violations", "Previous_Solar_Info"];
  useEffect(() => {
    setSelectedTable(state.taskActiveKey?.tab)
  }, [state.taskActiveKey])
  const firstArr = [
    "Deal_Name",
    "Closing_Date",
    "Account_Name",
    "Project_Address",
    "Project_Status",
    "House_Number",
    "Street_Name",
    "City",
    "State",
    "Zip_Code",
    "Latitude",
    "Longitude",
    "Is_NYC_Project",
    "Borough",
    "Service_Requested_Name",
    "Block_Number",
    "Lot_Number",
    "Bin_Number",
    "Community_Board_Number",
    "Occupancy_Classification_Building_2014_2008_Code",
    "Occupancy_Classification_of_Building",
    "Construction_Classification_Code_2014_2008_Code",
    "Construction_Classification_Code",
    "Multiple_Dwelling_Classification",
    "Number_of_Dwelling_Units",
    "Building_Height",
    "Number_of_Stories",
    "Zoning_District",
    "Commercial_Overlay",
    "Specials_Districts",
    "Zoning_Map_Number",
    "Tidal_Wetlands",
    "Fresh_Water_Wetland",
    "Flood_Hazard",
    "Coastal_Erosion_Hazard",
    "Special_Fire_District",
    "Landmark_Area",
    "Environmental_Restriction",
    "Conjunction",
    "Conjunction_Application_Number",
    "Stop_Work_Order_Exists_on_Property",
    "Active_Work_Without_Permit_Violations",
    "Asbestos_Required",
    "ACP5_Completed",
    "Asbestos_Inspector_Certificate_Number",
    "ACP5_Control_Number",
    "Certificate_of_Occupancy_Available",
    "NB_Application_Number",
    "Owner_Type",
    "Property_Owner_s_Name",
    "Owner_s_First_Name",
    "Owner_s_Middle_Initial",
    "Owner_s_Last_Name",
    "Owner_Phone_Number",
    "Owner_s_Email_Address",
    "Owner_s_Mailing_Address_Diff_Than_Property_Address",
    "Owner_s_Mailing_Address",
    "Owner_s_Street_Name",
    "Owner_s_House_Number",
    "Owner_s_City",
    "Owner_s_State",
    "Owner_s_Zip_Code",
    "Non_Profit",
    "DHCR",
    "Other_Owner_Company_Name",
    "Other_Owner_Mailing_Address",
    "Other_Owner_Street_Name",
    "Other_Owner_House_Number",
    "Other_Owner_City",
    "Other_Owner_State",
    "Other_Owner_Zip_Code",
    "Other_Owner_Officer_First_Name",
    "Other_Owner_Officer_Middle_Initial",
    "Other_Owner_Officer_Last_Name",
    "Other_Owner_Officer_Title",
    "Other_Owner_Phone_Number",
    "Other_Owner_Email_Address",
    "Condo_Company_Name",
    "Condo_Mailing_Address",
    "Condo_Street_Name",
    "Condo_House_Number",
    "Condo_City",
    "Condo_State_Name",
    "Condo_Zip_Code",
    "Condo_Officer_First_Name",
    "Condo_Officer_Middle_Initial",
    "Condo_Officer_Last_Name",
    "Condo_Officer_Title",
    "Condo_Phone_Number",
    "Condo_Email_Address",
    "Applicant",
    "General_Contractor",
    "Electrician",
    "Number_of_Interconnection",
    "Total_System_Size",
    "Total_Modules",
    "PV_Interconnection",
    "Project_Cost",
    "Owner",
    "Created_By",
    "Created_Time",
    "Modified_By",
    "Modified_Time",
  ];
  const [dealHeaders, setDealHeaders] = useState([]);
  const getFieldHeaders = () => {
    // if(headerData.length===0){
    setLoader(true);
    FieldService.GetFields("all", "Deals")
      .then(async (result) => {
        let arr = [];
        // result = result.filter((one) => one.api_name == 'PV_Interconnection')
        firstArr.forEach((one) => {
          let fieldObj = result.filter((object) => object.api_name == one);
          if (fieldObj.length > 0) {
            fieldObj = fieldObj[0];
            fieldObj.field_label = fieldObj.field_label.replace("0", "");
            let obj = {};
            obj.field = fieldObj.api_name;
            obj.id = fieldObj.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = fieldObj.field_label;
            obj.type = fieldObj.data_type;
            arr.push(obj);
          }
        });
        result.forEach((one) => {
          if (!firstArr.includes(one.api_name)) {
            let obj = {};
            obj.field = one.api_name;
            obj.id = one.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = one.field_label.replace("0", "");
            obj.type = one.data_type == "formula" ? "number" : one.data_type;
            arr.push(obj);
          }
        });
        const notIncludesFileds = ["latitude", "longitude"];
        arr = arr.filter((one) => !notIncludesFileds.includes(one.name));
        moduleApiNameForSubform.forEach((one) => {
          let obj = {};
          obj.field = one;
          obj.id = one;
          obj.isChecked = false;
          obj.isDragOver = false;
          obj.isSearch = true;
          obj.name = one.replaceAll("_", " ");
          obj.type = "text";
          arr.push(obj);
        });
        arr = _.uniqBy(arr, "name");
        setDealHeaders(arr);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  
  useEffect(() => {
    if (
      viewData.length > 0 &&
      DropDownTasks.length > 0 &&
      DropDownDeals.length > 0
    ) {
      // if (viewData == 'All') {
      //     resetViewFilters()
      // } else {
      //     setViewFilters(viewData)
      // }
    }
  }, [DropDownTasks, viewData, DropDownDeals]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropdownOpen((prevState) => !prevState);
    // setSearchedTask("");
  };

  const [searchedTask, setSearchedTask] = useState("");
  const [searchedAssinee, setSearchAssinee] = useState("");
  const [TaskSearchResult, setTaskSearchResult] = useState([]);
  const [assineeSearchResult, setAssineeSearchResult] = useState([]);


  const getAllDeals = async (res) => {
    const backendUrl = Config.backend_url + "getAllDealPages";
    var config = {
      token: localStorage.getItem("token"),
    };
    setLoader(true);
   
    try {
      for (let i = 0; i < subformApiName.length; i++) {
        if (subformApiName[i] !== "Deal_Name") {
          let res1 = await ProjectService.GetSubformData(subformApiName[i]);
          res1.forEach((subform) => {
            res.data.data.forEach((one, dealIdx) => {
              if (one?.id == subform.Parent_Id?.id) {
                if (res.data.data[dealIdx][subformApiName[i]]) {
                  res.data.data[dealIdx][subformApiName[i]].push(subform);
                } else {
                  res.data.data[dealIdx][subformApiName[i]] = [subform];
                }
              }
            });
          });
        }
      }
      for (let i = 0; i < moduleApiNameForSubform.length; i++) {
        let res1 = await ModuleService.GetModuleListData(
          moduleApiNameForSubform[i]
        );
        res.data.data.forEach((deal, dealIdx) => {
          res1 = res1.filter(
            (one) => one.Deal_Name && one.Deal_Name.name == deal.Deal_Name
          );
          res.data.data[dealIdx][moduleApiNameForSubform[i]] = res1;
        });
      }
      // res.data.data=res.data.data.filter((one)=>one.Deal_Name=='S23-0108')
      AllClients.forEach((one, index) => {
        if (one.Project_ID) {
          res.data.data.push({
            Deal_Name: "Miscellaneous" + index,
            id: "Miscellaneous" + index,
            Account_Name: { id: one.id, name: one.Account_Name },
            Project_ID: one.Project_ID,
          });
        }
      });
      res.data.data.push({
        Deal_Name: "Misc ",
        id: "Misc",
        Account_Name: { id: "Misc", name: "Misc" },
        Project_ID: "1716273000002687005",
      });

      setDeals(res.data.data);
      let data = res.data.data.filter((e) => e.hasOwnProperty("Created_Time"));
      setCacheDealData(data);
      setDealList(res.data.data);
      // res.data.data = res.data.data.map((one) => {
      //     one.Created_By = one.Created_By.name;
      //     one.Owner = one.Owner.name;
      //     one.Account_Name = one.Account_Name ? one.Account_Name.name : "";
      //     return one;
      // })
      // DealIcons(res.data.data);

      let arr = [];
      let originalData = [...res.data.data];
      res.data.data.forEach((deal, i) => {
        deal.isCheck = true;
        let obj = { DealId: deal.id, isOpen: true };
        arr.push(obj);
        // if (deal.Project_ID) {
        //     arr.push({ _id: deal.Project_ID, id: deal.id, isCheck: true, ProjectNumber: deal.Deal_Name })

        // } else {
        //     arr.push({ _id: "NA", id: deal.id, isCheck: true, ProjectNumber: deal.Deal_Name })

        // }
      });
      // if (res.data.data.length > 0) {
      //     res.data.data.unshift({ _id: 'allDeal', id: 'allDeal', Deal_Name: 'All', isCheck: true })
      // }
      // setGroupExpandFlag(arr);
      setDropDownDeals(res.data.data);
      setDealSearchResult(res.data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };


  const [filterDueDatePopup, setFilterDueDatePopup] = useState(false);
  const filterDueDateToggle = () => setFilterDueDatePopup(!filterDueDatePopup);

  const changeGroupBy = (e, name) => {
    const group = { ...cacheGroupBy };
    const deals = [...DropDownDeals];
    group[name] = e;
    setCacheGroupBy(group);
  };
  const [SearchThisTask, setSearchThisTask] = useState("");
  
  const [selectedTab, setSelectedTab] = useState("tasks");
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const getAllUsers = async () => {
    setLoader(true);
    UserService.GetAllUsers()
      .then((result) => {
        console.log(result.data.users);
        setUsers(result.data.users);
        let users = [...result.data.users];

        setAllUsers(users);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const [allTasks, setAllTasks] = useState([]);


  const conditions = {
    "<": (x, y) => (x < y ? true : false),
    "<=": (x, y) => (x <= y ? true : false),
    ">": (x, y) => (x > y ? true : false),
    ">=": (x, y) => (x >= y ? true : false),
    "=": (x, y) => (x == y ? true : false),
  };

 
  const [taskDeleteWarningToggle, setTaskDeleteWarningToggle] = useState(false);


  const groupByTaskItem = [
    { name: "None", value: "None" },
    { name: "Pipeline", value: "PipelineROWID" },
    { name: "Status", value: "Status" },
    { name: "Assignee", value: "Assignee" },
    { name: "Priority", value: "Priority" },
    { name: "Category", value: "Category" },
    { name: "Milestone", value: "Milestone" },
    { name: "Supervisor", value: "Supervisor" },
    { name: "Due Date", value: "DueDate" },
  ];
  const groupByDealItem = [
    { name: "None", value: "None" },
    { name: "Status", value: "Stage" },
    { name: "Deal Name", value: "Deal_Name" },
    { name: "Client", isNested:true ,value: "Client" ,getValue:(data)=>{
      if(data?.Client?.id){
        return data["Client"]["id"]
      }else{
        return "Unknown"
      }
    }},
    { name: "Closing Date", value: "Closing_Date" },
    { name: "NYC Project", value: " Is_NYC_Project" },
    { name: "Borough", value: "Borough" },
    // { name: "Block Number", value: 'Block_Number0' },
    // { name: "Bin Number", value: 'Bin_Number' },
    // { name: "Lot Number", value: 'Lot_Number0' }
  ];

  const [itemsTaskGroup, setItemTaskGroup] = useState(groupByTaskItem);
  const [itemDealGroup, setItemDealGroup] = useState(groupByDealItem);

  const getTaskFilter = () => {
    return (
      <></>
      // <Task_Filters
      //   refresh={refreshData}
      //                       dealData={cacheDealData}
      //                       taskData={TaskSearchResult}
      //                       mode="Both"
      //                       getReturnData={(obj) => {
      //                         getGroupByFields(obj.deals, groupBy.Deal);
      //                         setDropDownDeals(obj.deals);
      //                         setDropDownTasks(obj.tasks);
      //                         setRefreshData(false);
      //                       }}
      //                       dealColumns={filterItemsDeal}
      //                       taskColumns={filterItems}
      //                       allClient={ClientSearchResult}
      //                       dealStatus={dealStatus}
      //                       Deals={cacheDealData}
      //                       categorys={allCategory}
      //                       pipelines={allPipelines}
      //                       myTasks={taskModifiedData?.my_task}
      //                       clearFilter={clearFilter}
      //                       users={users}
      //                       status={
      //                         taskLayoutDetail?.status_details
      //                           ? taskLayoutDetail?.status_details
      //                           : []
      //                       }
      //                       open={taskFilterOpen}
      //                       handleClose={() => setTaskFilterOpen(false)}
      //   invertColor={() => {}}
      //   />
    );
  };
  const onSearch=(text,tasks)=>{

  }
  const getTaskFilterSetting = () => {
    return (
      <>
       <UncontrolledTooltip placement="top" target="filter-setting">
          Group Setting
        </UncontrolledTooltip>
        <AntdPopover
          placement="bottom"
          trigger="click"
          // isOpen={employeeNotePopup}
          overlayClassName="add-columns-tasks"
          content={
            <div>

              {/* <hr className="hrcls"></hr> */}
              <div className="row task-filter-hovr">
                <div className="col-sm-9">
                  <a onClick={() => {}}>Task Location</a>
                </div>
                <div className="col-sm-3">
                  <Switch
                    onChange={(checked, event) => {
                      const obj = { ...taskFilterSetting };
                      obj["TaskLocation"] = checked;
                      setTaskFilterSetting(obj);
                    }}
                    size="small"
                    checked={taskFilterSetting.TaskLocation}
                  />
                </div>
              </div>

              <hr className="hrcls"></hr>
              <div className="row task-filter-hovr">
                <div className="col-sm-9">
                  <a onClick={() => {}}>Expand All Deals</a>
                </div>
                <div className="col-sm-3">
                  <Switch
                    onChange={(checked, event) => {
                      const obj = { ...taskFilterSetting };
                      obj["ExpandDeals"] = checked;
                      setTaskFilterSetting(obj);
                    }}
                    size="small"
                    checked={taskFilterSetting.ExpandDeals}
                  />
                </div>
              </div>

              <hr className="hrcls"></hr>
              <div className="row task-filter-hovr">
                <div className="col-sm-9">
                  <a>Expand All Groups</a>
                </div>
                <div className="col-sm-3">
                  <Switch
                    onChange={(checked, event) => {
                      const obj = { ...taskFilterSetting };
                      obj["ExpandGroups"] = checked;
                      setTaskFilterSetting(obj);
                    }}
                    size="small"
                    checked={taskFilterSetting.ExpandGroups}
                  />
                </div>
              </div>

              <hr className="hrcls"></hr>
              <div className="row task-filter-hovr">
                <div className="col-sm-9">
                  <a onClick={() => {}}>Expand All Tasks</a>
                </div>
                <div className="col-sm-3">
                  <Switch
                    onChange={(checked, event) => {
                      const obj = { ...taskFilterSetting };
                      obj["ExpandTasks"] = checked;
                      setTaskFilterSetting(obj);
                    }}
                    size="small"
                    checked={taskFilterSetting.ExpandTasks}
                  />
                </div>
              </div>

              
            </div>
          }
        >
          <span className="ml-3 mr-3">
            <FontAwesomeIcon icon={faEllipsisV} id="filter-setting" />
          </span>
        </AntdPopover>
      </>
    );
  };
  const getTaskSearchSetting = () => {
    return (
      <>
        <UncontrolledTooltip placement="top" target="search-setting">
          Search setting
        </UncontrolledTooltip>

        <AntdPopover
          placement="bottom"
          trigger="click"
          // isOpen={employeeNotePopup}
          overlayClassName="search-settings-popover"
          content={
            <div>
              <div className="row task-filter-hovr">
                <div className="col-sm-9">
                  <a onClick={() => { }}>Task Name</a>
                </div>
                <div className="col-sm-3">
                  <Switch
                    onChange={(checked, event) => {
                      let temp = { ...searchSettings };
                      temp["TaskName"] = checked;
                      setSearchSettings(temp);
                    }}
                    size="small"
                    checked={searchSettings.TaskName}
                  />
                </div>
              </div>
              {/* <a onClick={() => {}}>
                Task Name{" "}
                <Switch
                  onChange={(checked, event) => {
                    let temp={...searchSettings};
                    temp["TaskName"]=checked;
                    setSearchSettings(temp);
                  }}
                  style={{ marginLeft: 35 }}
                  size="small"
                  checked={searchSettings.TaskName}
                />
              </a> */}
              {/* <br></br> */}
              <hr className="hrcls"></hr>
              {/* <a onClick={() => {}}>
                Task Description{" "}
                <Switch
                  onChange={(checked, event) => {
                    let temp={...searchSettings};
                    temp["Description"]=checked;
                    setSearchSettings(temp);
                  }}
                  size="small"
                  checked={searchSettings.Description}
                />
              </a> */}
              <div className="row task-filter-hovr">
                <div className="col-sm-9">
                  <a onClick={() => { }}>Task Description</a>
                </div>
                <div className="col-sm-3">
                  <Switch
                    onChange={(checked, event) => {
                      let temp = { ...searchSettings };
                      temp["Description"] = checked;
                      setSearchSettings(temp);
                    }}
                    size="small"
                    checked={searchSettings.Description}
                  />
                </div>
              </div>
            </div>
          }
        >
          <span className="ml-3">
            <FontAwesomeIcon icon={faEllipsisV} id="search-setting" />
          </span>
        </AntdPopover>
      </>
    );
  };
  
  return (
    <div className="project-tasks-wrapper ag-theme-alpine">
      {loader ? <Loader /> : <></>}
      <CreateTaskModal 
      isSubTaskView={isSubTaskView}
      setIsSubTaskView={setIsSubTaskView}
      taskModalState={taskModalState} viewTaskData={viewTaskData} open={createTaskModal} setOpen={(state) => {
        setCreateTaskModal(state);
        if(!state){
          setViewTaskData(null);
          setTaskModalState({
            module:"TASK",action:"create"
          })
        }
      }} 
      viewPipelineData={viewPipelineData}
      setViewPipelineData={setViewPipelineData} 
      viewTaskFunction={viewTaskFunction}
      refreshData={refreshData}
      setPageRefreshData={setPageRefreshData}
      createPiplineModalState={createPiplineModal}
      selectedTable={selectedTable}
      allPipelineData={selectedTable == "tasks" ? allPipeline : allPipelineTemplates}
      showDependencies={showDependencies}
      allBlueprintsData={allBlueprintsData}
      setShowDependencies={setShowDependencies}
      dropdownTasksDataCache={dropdownTasksDataCache}
      allTasksAndSubtasks={allTasksAndSubtasks}
      />
      <ViewPipelines open={createPiplineModal} 
      setOpen={(state) => {
        setCreatePiplineModal(state);
      }}
      viewPipelineData={viewPipelineData}
      setViewPipelineData={setViewPipelineData} 
      allPipelineData={allPipelineTemplates}
      refreshData={refreshData}
      setPageRefreshData={setPageRefreshData}
      viewTaskFunction={viewTaskFunction}
      openTaskModal={createTaskModal} 
      setOpenTaskModal={(state) => {
        setCreateTaskModal(state);
        if(!state){
          setViewTaskData(null);
          setTaskModalState({
            module:"TASK",action:"create"
          })
        }
      }}
      />
      {selectedTable == "tasks" ?
        <Col className="Tasks-Tab-Wrapper" sm={12}>
          <Row className="Header">
            <Col style={{display:"flex",alignItems:"center",flexDirection:"row",gap: "5px"}} sm={3}>
            {activeView=='List' ? <GlobalSearch
                text={searchText}
                onSearchFilterTexts={(text) =>
                  setSearchText(text)
                }
              />
            : 
              (<><ViewSwitcher
                onViewModeChange={(viewMode) => setView(viewMode)}
                viewMode={view}
                onViewListChange={setIsChecked}
                isChecked={isChecked}
              /> 
              {/* <div className="buttonsForDateChange"> */}
                <Button className={'btnclass'} onClick={()=>goToPreviousMonth()} type="primary" ghost icon={<LeftOutlined />} iconPosition="start">  Prev </Button>
                <Button className={'btnclass'} onClick={()=>goToNextMonth()} type="primary" icon={<RightOutlined />} iconPosition="end" ghost>Next</Button>
                </>)
            }
              {activeView=='List' && getTaskSearchSetting()}
            </Col>
            {/* {activeView !='List' && <Col sm={1}></Col>} */}
            <Col style={{display:"flex",alignItems:"center"}} sm={2}>
            {activeView=='List' && <div>
                <GetElementsGroupBy
                    // groupBy={groupBy}
                    itemsTaskGroup={itemsTaskGroup}
                    itemDealGroup={itemDealGroup}
                    groupByFilter={groupFilter}
                    setGroupFilter={(data)=>setGroupFilter(data)}
                  // cacheGroupBy={cacheGroupBy}
                  // getGroupByFields={getGroupByFields}
                  />
              </div>}
            </Col>
            <Col sm={3}>
            </Col>
            <Col style={{ display: "flex", justifyContent: "end" ,alignItems:"center",flexDirection:"row", gap:"5px" }} sm={4}>
            <div className="list-compact-view">
              <div onClick={(e) => { setActiveView("List") }} className="list-view" style={activeView == "List" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>List View</div>
              <div onClick={(e) => { setActiveView("Gantt") }} className="compact-view" style={activeView == "Gantt" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>Gantt View</div>
            </div> 
              {activeView=='List' ? getTaskFilterSetting() : <></>}
              <Button type="primary" onClick={e => {
                setCreateTaskModal(true);
                setViewTaskData(null);
              }}>Add Task</Button>
              
            </Col>
          </Row>
          {activeView=='List' ? <RenderAllTasks 
            selectedTable={selectedTable}
            searchText={searchText}
            searchSettings={searchSettings}
            isSubTaskView={isSubTaskView}
            setIsSubTaskView={setIsSubTaskView} 
            taskFilterSetting={taskFilterSetting} 
            DealStatus={dealStatus} 
            viewTaskFunction={viewTaskFunction} 
            group={groupFilter} 
            allPipelineData={allPipeline} 
            refreshData={refreshData}
            setPageRefreshData={setPageRefreshData}
            setShowDependencies={setShowDependencies}
            dropdownTasksDataCache={dropdownTasksDataCache}
            allTasksAndSubtasks={allTasksAndSubtasks}
            allDependeniesData={allDependeniesData}
          /> : <GanttView 
              view={view}
              isChecked={isChecked}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
          /> 
        } 
        </Col> :
        <Col className="Pipline-Tab-Wrapper" sm={12}>
          <Row className="Header">
            <Col sm={10}>
            <GlobalSearch
                text={searchPipelines}
                onSearchFilterTexts={(text) =>
                  setSearchPipelines(text)
                }
              />
            </Col>
            <Col style={{ display: "flex", justifyContent: "end", gap: "10px" }} sm={2}>
              <Button type="primary" onClick={e => {
                setIsOpen(true);
              }}>Add Pipeline</Button>
              {
                <CreatePipeline
                  data={dataToEdit}
                  mode={mode}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  open={createPiplineModal}
                  setOpen={(state) => {
                    setCreatePiplineModal(state);
                  }}
                  allPipelineData={allPipelineTemplates}
                  viewPipelineData={viewPipelineData}
                  setViewPipelineData={setViewPipelineData}
                />
              }
            </Col>
          </Row>
          {/* <Pipelines
            open={createPiplineModal}
            setOpen={(state) => {
              setCreatePiplineModal(state);
            }}
            viewPipelineData={viewPipelineData}
            setViewPipelineData={setViewPipelineData}
          /> */}
          <RenderAllPipelineTemplates 
            selectedTable={selectedTable}
            isSubTaskView={isSubTaskView}
            searchPipelines={searchPipelines}
            setIsSubTaskView={setIsSubTaskView}
            setOpen={(state) => {
              setCreatePiplineModal(state);
            }} 
            viewTaskFunction={viewTaskFunction} 
            group={groupFilter} 
            allPipelineData={cacheAllPipelineTemplates} 
            setViewPipelineData={(pipline)=>setViewPipelineData([pipline])} 
            refreshData={refreshData}
            setPageRefreshData={setPageRefreshData}
            />
        </Col>
      }
    </div>
  );
};

export default ProjectTasks;
