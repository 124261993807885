const { convertToUSTime } = require("../components/HR/TimeClock/Util");


function getWorkingDaysInMonth(month, year) {
    month--; // lets fix the month once, here and be done with it
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {

        // Exclude weekends
        var tmpDate = new Date(date);
        var weekDay = tmpDate.getDay(); // week day
        var day = tmpDate.getDate(); // day

        if (weekDay % 6) { // exclude 0=Sunday and 6=Saturday
            days.push(day);
        }

        date.setDate(date.getDate() + 1);
    }

    return days;
}

function toIsoString(date) {
     
    date=new Date(convertToUSTime(date,"America/New_York"));
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };
        // if(allDay){
        // return date.getFullYear() +
        // '' + pad(date.getMonth() + 1) +
        // '' + pad(date.getDate())
        // }else{
            return date.getFullYear() +
            '' + pad(date.getMonth() + 1) +
            '' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            '' + pad(date.getMinutes()) +
            '' + pad(date.getSeconds()) +
            dif + pad(Math.floor(Math.abs(tzo) / 60)) +
            '' + pad(Math.abs(tzo) % 60);
        // }
  
    // return date.getFullYear() +
    //     '-' + pad(date.getMonth() + 1) +
    //     '-' + pad(date.getDate()) +
    //     'T' + pad(date.getHours()) +
    //     ':' + pad(date.getMinutes()) +
    //     ':' + pad(date.getSeconds()) +
    //     dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    //     ':' + pad(Math.abs(tzo) % 60);
  }

const getDiffInHourAndMiniutes = (start, end) => {
    var diffMs = (end - start); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return { hours: diffHrs, minutes: diffMins }
}

// function workingHoursBetweenDatesWithLunchTime(startDate, endDate, user, includeWeekends) {
//     var minutesWorked = 0;
//     if (user.UserId == 'all') {
//         return minutesWorked;
//     }
//     try {
//         let arr = []
//         let start = user.InTime.split(':');
//         let end = user.OutTime.split(':');
//         let lunchStart = user.LunchStartTime.split(':');
//         let lunchEnd = user.LunchEndTime.split(':');
//         let firstObj = { start: parseInt(start[0]), end: parseInt(lunchStart[0]) };
//         let secondObj = { start: parseInt(lunchEnd[0]), end: parseInt(end[0]) }
//         arr.push(firstObj)
//         arr.push(secondObj)
//         arr.forEach((obj) => {
//             minutesWorked = minutesWorked + workingHoursBetweenDates(new Date(startDate), new Date(endDate), obj, includeWeekends)
//         })
//         return minutesWorked;
//     }
//     catch (ex) {
//         return ex
//     }


// }

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function workingHoursBetweenDatesWithLunchTime(startDate, endDate, user, includeWeekends) {
    // Store minutes worked
    var minutesWorked = 0;

    // Validate input
    if (endDate < startDate) { return 0; }

    // Loop from your Start to End dates (by hour)
    var current = new Date(startDate);

    // Define work range
    // var workHoursStart = workHour.start;
    // var workHoursEnd = workHour.end;

    let workDays = user.WorkingDays;

    // workHoursStart = new Date(date);
    // let inTime = user.InTime
    // inTime = inTime.split(':');
    // workHoursStart.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);


    // workHoursEnd = new Date(date);
    // let outTime = user.OutTime;
    // outTime = outTime.split(':');
    // workHoursEnd.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0);

    // Loop while currentDate is less than end Date (by minutes)
    while (current <= endDate) {
        let daysShift = workDays.filter(day => day.Day == days[current.getDay()]);
        if ((daysShift.length > 0) && (daysShift[0].isWorking)) {
            daysShift = daysShift[0]
            let breakStart = daysShift.LunchStartTime.split(':');
            let breakEnd = daysShift.LunchEndTime.split(':')
            let inTime = daysShift.InTime.split(':');
            let outTime = daysShift.OutTime.split(':');


            let start1 = new Date(current)
            let end1 = new Date(current)

            start1.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0)
            end1.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0)

            let lunchStart = new Date(current)
            let lunchEnd = new Date(current)

            lunchStart.setHours(parseInt(breakStart[0]), parseInt(breakStart[1]), 0, 0);
            lunchEnd.setHours(parseInt(breakEnd[0]), parseInt(breakEnd[1]), 0, 0);

            let total = end1.getTime() - start1.getTime();
            let breakTotal = lunchEnd.getTime() - lunchStart.getTime()
            let diff = total - breakTotal;
            diff = Math.floor(diff / (1000 * 60))
            minutesWorked = diff + minutesWorked;


        }
        current.setDate(current.getDate() + 1);
    }

    // Return the number of hours
    return minutesWorked //(minutesWorked / 60).toFixed(2);
}


function workingHoursBetweenDates(startDate, endDate, workHour, includeWeekends) {
    // Store minutes worked
    var minutesWorked = 0;

    // Validate input
    if (endDate < startDate) { return 0; }

    // Loop from your Start to End dates (by hour)
    var current = new Date(startDate);

    // Define work range
    var workHoursStart = workHour.start;
    var workHoursEnd = workHour.end;

    // workHoursStart = new Date(date);
    // let inTime = user.InTime
    // inTime = inTime.split(':');
    // workHoursStart.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);


    // workHoursEnd = new Date(date);
    // let outTime = user.OutTime;
    // outTime = outTime.split(':');
    // workHoursEnd.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0);

    // Loop while currentDate is less than end Date (by minutes)
    while (current <= endDate) {


        // Store the current time (with minutes adjusted)
        var currentTime = current.getHours() + (current.getMinutes() / 60);

        // Is the current time within a work day (and if it occurs on a weekend or not)          
        if (currentTime >= workHoursStart && currentTime < workHoursEnd &&
            (includeWeekends ? current.getDay() !== 0 && current.getDay() !== 6 : true)) {
            minutesWorked++;
        }

        // Increment current time
        current.setTime(current.getTime() + 1000 * 60);
    }

    // Return the number of hours
    return minutesWorked //(minutesWorked / 60).toFixed(2);
}

function getMonthStartEnd(date) {
    var date = new Date(date), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    return { start: firstDay, end: lastDay }
}

function getWeekStartEnd(date) {
    var curr = new Date(date); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last));
    return { start: firstday, end: lastday };
}

function isWeekend(date) {
    let isWeekend = false;
    var today = new Date(date);
    if (today.getDay() == 6 || today.getDay() == 0) {
        isWeekend = true;
    }
    return isWeekend;
}

function getDateDuration(date) {
    let star = new Date(date);
    star.setHours(0, 0, 0, 0);
    let end = new Date(date);
    end.setHours(23, 59, 59, 999);
    return { start: star, end: end }
}

function getDateOfWeek(week, year, m, day) {
    // year=2021;
    // Create a date for 1 Jan in required year
    var d = new Date(year, m);
    // Get day of week number, sun = 0, mon = 1, etc.
    var dayNum = d.getDay();
    // Get days to add
    var requiredDate = --week * 7;

    // For ISO week numbering
    // If 1 Jan is Friday to Sunday, go to next week 
    if (dayNum != 0 || dayNum > 4) {
        requiredDate += 7;
    }

    // Add required number of days
    requiredDate = requiredDate - day;
    d.setDate(1 - d.getDay() + ++requiredDate);
    return d;
}

// function isHolidayFestival(dateTime, holidayList) {
//     let holiday = false;
//     let date = new Date(dateTime);
//     let list = [...holidayList];
//     for (let h = 0; h < list.length; h++) {
//         if (list[h].DateFormat == 'DayOfWeek') {
//             if (((date.getMonth() + 1) == parseInt(list[h].Month)) &&
//                 (date.getDay() == parseInt(list[h].Day)) &&
//                 (date.getWeek() == parseInt(list[h].Week))) {
//                 if (list[h].Recurring) {
//                     holiday = true;
//                     break;
//                 } else {
//                     if (date >= new Date()) {
//                         holiday = true;
//                         break;
//                     }
//                 }
//             }
//         }
//         else if (list[h].DateFormat == 'DayOfMonth') {
//             if (list[h].DateFormat == 'DayOfWeek') {
//                 if (((date.getMonth() + 1) == parseInt(list[h].Month)) &&
//                     (date.getDate() == parseInt(list[h].Day))) {
//                     if (list[h].Recurring) {
//                         holiday = true;
//                         break;
//                     } else {
//                         if (date >= new Date()) {
//                             holiday = true;
//                             break;
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return holiday;
// }


// function getDateOfWeek(w, y,m) {
//     var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
//     let date=new Date(y, m, d)
//     return date;
// }

function getWeekOfMonth(date) {
    let adjustedDate = date.getDate() + date.getDay();
    let prefixes = ['0', '1', '2', '3', '4', '5'];
    return (parseInt(prefixes[0 | adjustedDate / 7]));
}

function getLastDayInMonth(year,month,day) {
    var now = new Date(year, month);
    var daysAfterLastThursday = (-7 + day) - now.getDay(); // 7 = number of days in week, 4 = the thursdayIndex (0= sunday)
    var currentMs = now.getTime();
    var lastThursday = new Date(currentMs + (daysAfterLastThursday * 24 * 60 * 60 * 1000));
    return lastThursday;
}



module.exports = {
   
    getLastDayInMonth,
    getWeekOfMonth,
    getDateOfWeek,
    getDateDuration,
    isWeekend,
    getWeekStartEnd,
    getMonthStartEnd,
    getDiffInHourAndMiniutes,
    getWorkingDaysInMonth,
    workingHoursBetweenDates,
    workingHoursBetweenDatesWithLunchTime,
    toIsoString,
    //isHolidayFestival,

}