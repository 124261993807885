import React, { useContext, useEffect, useState } from "react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import { Popover as AntdPopover, DatePicker as AntdDatepicker } from "antd";
import { DatePicker as AntdDatePicker, Modal as AntModal , Dropdown as AntDropDown} from 'antd';
import Select from "react-select";
import {
  Col,
  Modal,
  ModalBody,
  Button,
  Row,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import validate from "../../../common/util/validation";
import "antd/dist/reset.css";
import AntdRangePicker from "../../../common/DateRangePicker";
import swal from "sweetalert";
import AttendenceService from "../../../common/util/AttendenceService";
import leaveService from "../../../common/util/leaveService";
import UserService from "../../../common/util/UserService";
import Loader from "../../../common/loader";
import Config from '../../../config.json'
import axios from "axios";
import { GlobalAgGridTable } from "../../Deal/AgGridReactComponent/GlobalAgGridTable";
import AppContext from "../../../Context/AppContext";
import { getTimeInAMPMFormat, getWorkingHoursAndMinutes,updateWorkingHoursIfExistForRequest, updateWorkingHoursIfExist ,getDateFormate,formateNoInDecimalString, getWorkingHours, disabledDate, convertToUSTime, modifyTimezoneString, convertToUSDate, getDeviceId, checkDeviceIdCookie, modifyTimesheetData, getModifiedEntries, UpdateTimesheetWithBreak, combineTimesheetRecords, joinAttendanceWithBreakInBetween} from './Util'
import { SelectedIdsForTimeClock, ActionsOfTimeClock as Actions, CustomeRefreshButton ,isDeleteEnable, addTimeSheetClicked } from "../../../Context/AppActions";
import Draggable from "react-draggable";
import './Common.css'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faCircle, faGripVertical, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ListGroupItem } from 'react-bootstrap';
import { Modal as BootStrapModal, ModalBody as BootStrapModalBody } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ShiftServices from "../../../common/util/ShiftServices";

const RequestTable = ({refreshEnable}) => {
  const [userList, setUserList] = useState([])
  const [requestData, setRequestData] = useState(null);
  const [breakRequestData, setBreakRequestData] = useState(null);
  const [absenseData, setAbsenseData] = useState({ DateWise: true, Day: '1.0' });
  const [rowData, setRowData] = useState([]);
  const [cacheData, setcacheData] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [deleteAll, setDeleteAll] = useState({ isDelete: false, value: "All" });
  const [totalHourVal, setTotalHourVal] = useState(null);
  const [managerCommentModal, setManagerCommentModal] = useState(null);
  const [timesheetData, setTimesheetData] = useState([]);
  const [allusers, setAllUsers] = useState([]);
  const [loaderState, setLoaderState] = useState(false)
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [absentDates,setAbsentDates] = useState([])
  const [disableDates,setDisableDates] = useState([])
  const [listSelectedRows, setlistSelectedRows] = useState([]);
  const [isEditColumnTrue, setIsEditColumnTrue] = useState(false);
  const [entryReqModal, setEntryReqModal] = useState(false);
  const [breakReqModal, setBreakReqModal] = useState(false);
  const [ptoCode, setPtoCode] = useState([]);
  const [ptoCodeUnit, setPtoCodeUnit] = useState("");
  const [entryAbsenceModal, setEntryAbsenceRequest] = useState(false);
  const [ptoCodeOptions, setPtoCodeOptions] = useState([])
  const [dayOptions, setDayOptions] = useState([]);
  const daysArr = [{ name: "Full Day", val: "1" }, { name: "Half Day", val: "0.5" }, { name: "Quarter Day", val: "0.25" }]
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  const [isReadMore, setIsReadMore] = useState(true);
  const [noteObj, setNoteObj] = useState({});
  const [employeeNotePopup, setEmployeeNotePopup] = useState(false);
  const length = 10;
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 35 });
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showInDatePicker, setShowInDatePicker] = useState(false)
  const [showOutDatePicker, setShowOutDatePicker] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [LeaveDayData, setLeaveDayData] = useState({ label: "", value: "" ,leaveType:""}); 
  const [selectedOption, setSelectedOption] = useState('fullDay');
  const [shiftData, setShiftData] = useState([]);
  const columnsForRequestView = [
    {
      pinned:true,
      path: "userName",
      label: "User Name",
      field: "userName",
      name: "User Name",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    },
    {
      pinned:true,
      path: "requestDate",
      label: "Request Date",
      field: "requestDate",
      name: "Request Date",
      type: "datetime",
      id : 1,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      // content: (obj) => {
      //   return <label>{obj.date}</label>;
      // },
    },
    {
      pinned:true,
      path: "TimesheetDate",
      label: "Timesheet Date",
      field: "TimesheetDate",
      name: "Timesheet Date",
      type: "datetime",
      id : 2,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      // content: (obj) => {
      //   return <label>{obj.date}</label>;
      // },
    },
    {
      // key: "checkIn",
      id : 3,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      path: "checkIn",
      label: "Check In",
      field: "checkIn",
      name: "Check In",
      type: "text",
      // content: function (obj, key) {
      //   return obj[key] ? getTime(obj[key], obj) : "NA"
      // },
    },
    {
      // key: "checkOut",
      id : 4,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      path: "checkOut",
      label: "Check Out",
      field: "checkOut",
      name: "Check Out",
      type: "text",
      // content: function (obj, key) {
      //   return obj[key] ? getTime(obj[key], obj) : "NA"
      // },
    },
    {
      id : 5,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      path: "hours",
      label: "Hours",
      field: "hours",
      name: "Hours",
      type: "text",
    },
    // {
    //   id : 6,
    //   isChecked : false,
    //   isDragOver : false,
    //   isSearch : true,
    //   path: "Day",
    //   label: "Day",
    //   field: "Day",
    //   name: "Day",
    //   type: "text",
      
    // },
    // {
    //   id : 7,
    //   isChecked : false,
    //   isDragOver : false,
    //   isSearch : true,
    //   key: "ptoCode",
    //   path: "ptoCode",
    //   label: "Absense",
    //   field: "ptoCode",
    //   name: "Absense",
    //   type: "text",
    //   content: (obj, key) => (
    //     obj[key] !== "undefined" && obj[key] !== ""
    //       ? obj[key]
    //       : "NA"
    //   ),
    // },
    {
      id : 6,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      path: "note",
      label: "Employee Note",
      field: "note",
      name: "Employee Note",
      type: "text"
    },
    {
      id : 7,
      key :"requestType",
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      path: "requestType",
      label: "Request Type",
      field: "requestType",
      name: "Request Type",
      type: "text",
      content:(obj,key)=>{
        return obj[key]?.split("_")[0];
      }
    },
    {
      id : 8,
      key :"reason",
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      path: "reason",
      label: "Reason",
      field: "reason",
      name: "Reason",
      type: "text",
      content:(obj,key)=>{
        return obj[key];
      }
    },
    {
      id : 9,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      key: "status",
      label: "Status",
      field: "status",
      name: "Status",
      type: "text",
      content: (obj, key) => {
        return renderStatusPopOver(obj, key)
      },
    },
    
    {
      id : 10,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      key: "email",
      name: "Action",
      type: "text",
      label: "Action",
      field: "Action",
      content: function (obj, key) { return actionListCellRenderer(obj, obj[key]) },
    },
  ];
  const [activeHeaderData, setActiveHeaderData] = useState([...columnsForRequestView])
  const absenceObjTemp={
    in: "00:00", 
    out: "00:00", 
    DateWise: true, 
    Date: new Date(),
    requestType:"Full Day" ,
    reason:null,
  }
  const valudationObjForAbsense={
    "Full Day":{
      "Sick Leave":["FromDate","TillDate"],
      "Vacation":["FromDate","TillDate"],
      "Personal Leave":["FromDate","TillDate"],
    },
    "Partial Day":{
      "Early Leave":["out","Date"],
      "Late Arrival":["in",'Date'],
      "Midday":["in","out",'Date']
    }
  }
  const [partialAbsenseData, setPartialAbsenseData] = useState({ 
   ...absenceObjTemp
  });
  const fullDayOptions = [{value:"Personal Leave",label:"Personal Leave"},{value:"Sick Leave",label:"Sick Leave"},{value:"Vacation",label:"Vacation"}];
  const halfDayOptions = [{value:"Early Leave",label:"Early Leave"},{value:"Late Arrival",label:"Late Arrival"},{value:"Midday",label:"Midday"}];
  const tempValidation={
    reason:'0',
    TillDate:'0',
    FromDate:'0',
    Date:'0',
    in:'0',
    out:'0',
  }
  const [validation,setValidation]=useState({...tempValidation})
  const [showWarnFooterForAbsenceModal,setshowWarnFooterForAbsenceModal]=useState(false)
  const validationCss = {
    transition: "width 0.4s ease-in-out",
    "box-shadow": "rgb(255 98 98) 0px 0px 7px 1px",
    "border-color": "#ff0000",
  };

  useEffect(e => {
    if(showWarnFooterForAbsenceModal){
      setshowWarnFooterForAbsenceModal(false)
    }
  }, [partialAbsenseData])

  useEffect(e => {
    let date=dayjs(partialAbsenseData.Date).format('YYYY-MM-DD');;
    let id=userInfo; 
    getShiftDetailsOfUser(userInfo.email_id, date, date);
  }, [partialAbsenseData.Date])

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setLeaveDayData({ label: "", value: "" ,requestType:selectedOption})
  };
  const handleDrag = (e, ui) => {
    if(e.target && e.target.closest(".ant-picker-dropdown")){
    e.stopPropagation();
    return
    }
    const { x, y } = ui;
    setModalPosition({ x, y });
  };
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [requestFilter, setRequestFilterData] = useState({
    0: true,
    1: true,
    2: true,
  });
  const [errors, setErrors] = useState(null);
  // const [selectionRange, setSelectionRange] = useState([
  //   {
  //     startDate: startDate,
  //     endDate: endDate,
  //     key: "selection",
  //   },
  // ]);
  const [updateRequestStatus, setUpdateRequestStatus] = useState(null);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [allStart, setAllStart] = useState(new Date());
  const [allEnd, setAllEnd] = useState(new Date());
  const [radioButtonTable,setRadioButtonTable] = useState({
    state:true,
    active:true,
    displayName1:"Accepted",
    displayName2:"Rejected",
  })


  const addClicked=state.addTimeSheetClicked;
  // console.log("addClicked", addClicked);

  useEffect(e=>{
    if(addClicked && addClicked?.module == "request"){
      if(addClicked?.button == "Entry Request"){
              let date;
              // let obj = { From: new Date(), Till: new Date(), date: GetFormattedDate(new Date())}
              date = new Date(dayjs(new Date()).subtract(1,"day").format("MM/DD/YYYY"));
              const editObj = { ...requestData};
              editObj.date = date;
              let inTime = new Date(date);
              let out = new Date(date);
              inTime.setHours(9, 0, 0, 0);
              out.setHours(17, 0, 0, 0);
              let obj = { date: date, in: inTime, out: out };
              setEntryReqModal(!entryReqModal);
              setModalPosition({ x: 0, y: 35 })
              setRequestData(obj);
              setErrors(null);
      }else if(addClicked?.button == "Absence Request"){
        // if (isAccess && isAccess(selectedRole, 'Request', 'Write')) {
          let date = new Date();
          let obj = { in: "00:00", out: "23:59", DateWise: true, FromDate : new Date(), TillDate: new Date() };
          // getTotalHrsBetweenTwoDate(obj.in, obj.out, "absenceModal");
          getTotalHrsBetweenTwoDate(dayjs(obj.TillDate).format("MM/DD/YYYY") + " " + obj.out, dayjs(obj.FromDate).format("MM/DD/YYYY")+ " " + obj.in, "absenceModal");

          // obj.date=GetFormattedDate(date);
          // date.setHours(0,0,0,0,1);
          // obj.in=date
          // date.setHours(0,0,0,0,1);
          // date.setHours(23,59,59,999)
          setModalPosition({ x: 0, y: 35 })
          setEntryAbsenceRequest(!entryAbsenceModal);
          setAbsenseData(obj);
          setErrors(null);
          // } else {
          //   swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
          // }

          // setTotalHourVal(hours);
        }
    }
  },[addClicked, addClicked?.button])

  const setAllDate = (data) => {
    data.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
    if (data.length > 0) {
      setAllStart(new Date(data[0].date))
      setAllEnd(new Date(data[data.length - 1].date))
    }
  }

  function lastDateOFMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }
  const getStartDate = () => {
    let startDate = new Date(Date.now());
    startDate.setDate(1);
    startDate.setHours(0, 0, 0);
    return startDate;
  }
  const getEndDate = () => {
    let endDate = new Date(Date.now());
    endDate.setDate(lastDateOFMonth(new Date().getFullYear(), new Date().getMonth()));
    endDate.setHours(0, 0, 0);
    return endDate;
  }
  const ActionsOfTimeClock = state.ActionsOfTimeClock;

  // useEffect(() => {
  //   if (ActionsOfTimeClock == "Export") {
  //     exportListViewData("newExcelSheet.xlsx")
  //   } else if (ActionsOfTimeClock == "EditColumns") {
  //     setIsEditColumnTrue(true)
  //   }else if(ActionsOfTimeClock=="Delete" && gridApi){
  //     let array=[];
  //     gridApi?.forEachNode((node) => {
  //           if (node.isSelected()) {
  //             if(node?.data?.ROWID){
  //               array.push(node?.data?.ROWID)
  //             }
  //           }
  //     })
  //     swal({
  //       title: "Warning!",
  //       text: "Are you sure you want to delete the record(s)?",
  //       icon: "warning",
  //       buttons: {
  //         cancel: "Cancel",
  //         confirm: "Proceed",
  //       },
  //     }).then((isDelete)=>{
  //       if(isDelete){
  //         AttendenceService.DeleteRequest(array)
  //         .then(res=>{
  //           getAllTimesheet();
  //           swal("Success!", "Record deleted successfully!", "success");
  //         }).catch(e=>{
  //           swal("Error!", "Something went wrong!", "error");
  //         })
  //       }
  //     })
  //   }
  //   dispatch(Actions(""))
  // }, [ActionsOfTimeClock])

  // const exportListViewData = (fileName) => {
  //   gridApi?.exportDataAsExcel({
  //     fileName: fileName,
  //     onlySelected: true,
  //     processCellCallback: function (params) {
  //       const rowData = params.node.data;
  //       const columnField = params.column?.getColDef().field;
  //       return params.value;
  //     }
  //   });
  // }
  // const onSelectionChanged = (event) => {
  //   setGridApi(event.api)
  //   let listTypeRows = event.api.rowModel?.rowsToDisplay.filter(node => node.isSelected());
  //   if (listTypeRows.length == event.api.rowModel.rowsToDisplay.length && listSelectedRows.length < listTypeRows.length && listSelectedRows.length != 0) {
  //     event.api.deselectAll();
  //   } else {
  //     let array = [];
  //     event.api.forEachNode((node) => {
  //       // allNodes.push(node?.data)
  //       // const nodeRowId = node?.data?.id;
  //       // if (nodeRowId) {
  //       if (node.isSelected()) {
  //         array.push(node?.data)
  //       } else {
  //       }
  //       // }
  //     });
  //     setlistSelectedRows(listTypeRows);
  //     dispatch(SelectedIdsForTimeClock(array))
  //   }
  // }
  // const onFirstDataRendered = (event) => {
  //   dispatch(SelectedIdsForTimeClock([]))
  // }

  useEffect(() => {
    if (allusers.length > 0) {

      let arrOfUsers = allusers.map((item, i) => {
        return { value: item.id, label: item.full_name };
      });
      if(userInfo && (userInfo?.role_details.role_name !== 'App User')){
        arrOfUsers.unshift({label: "All Users", value: "all"})
      }
      setUserList(arrOfUsers)
    }

  }, [allusers, selectedUser])

  const onChangeDateRange = (dates, datesString) => { 
    if (datesString.length > 0) {
      let start = new Date(new Date(datesString[0]))
      let end = new Date(new Date(datesString[1]))
      changeDateRange({ selection: { startDate: start, endDate: end } },dates);
    }

  }
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const [SelectedDateRange, setSelectedDateRange] = useState(
    GetFormattedDate(startDate) + " - " + GetFormattedDate(endDate)
  );
  const [updateRequestData, setUpdateRequestData] = useState(null);
  const [delPopup, setDelPopup] = useState(false);
  useEffect(e=>{
    getAllUsers()
    getAllTimesheet();
    dispatch(isDeleteEnable(false))
  },[])
  useEffect(() => {
    if(refreshEnable){
      getAllUsers()
      getAllTimesheet(true);
      setEndDate(getEndDate())
      setStartDate(getStartDate())
    }
  }, [refreshEnable])

  const getAllUsers = () => {
    setLoaderState(true)
    UserService.GetAllUsers()
      .then((result) => {
        result.data.users = result.data.users.filter(one => one.status == 'active');
        if (userInfo && (userInfo?.role_details.role_name == 'App User')) {
          let filterUser = result.data.users.filter(one => one.email == userInfo?.email_id);
          setAllUsers(filterUser);
          setSelectedUser(filterUser[0]);
        } else {
          setAllUsers(result.data.users);
        }
        setLoaderState(false)
      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
      })
  }
  useEffect(e=>{
    if(userInfo?.email_id){
       
      let arr=[];
      absentDates.map(a=>{
      if(a.email==userInfo?.email_id){
        arr.push(a.date);
      }});
      setDisableDates(arr)
    }else{
      setDisableDates([])
    }
  },[absentDates])
  const getModalForStatus = () => {
    return <BootStrapModal
      className="Edit-Column-Modal2"
      show={isEditColumnTrue}
      backdrop={true}
      toggle={() => {
      onclick(()=>{
        setIsEditColumnTrue(!isEditColumnTrue)
      })
      }}
      onHide={(e) => {
          setIsEditColumnTrue(!isEditColumnTrue)
      }}
    >
      <BootStrapModalBody>
        <ul className="editColummn-list">
          {status?.map((e, index) => e.field && (<ListGroupItem
            style={{ marginLeft: 8 }}
            className="editColumnlist-text"
            draggable 
          >
            <FontAwesomeIcon
              icon={faGripVertical}
              className="icon-grip-vertical"
            />
            <input
              // disabled={e.name == "Deal_Name"}
              id={index}
              type="checkbox"
              checked={e.isChecked}
              onChange={(event) => {
                let data=[...status]
                data[index].isChecked=!e.isChecked;
                setStatus(data);
              }}
            ></input>
            {"  "}
            <span
              className={
                e.field
                  ? "editColumnlist-label add-line"
                  : "editColumnlist-label"
              }
            >
              {e.field}
            </span>
          </ListGroupItem>)
          )}
        </ul>
      </BootStrapModalBody>
    </BootStrapModal>
  }

  function disabledDateLocal(current) {
    // Disable dates from today onwards
    const today = moment().startOf('day');
  
    // Check if the current date is today or in the future
    if (current && current.valueOf() >= today.valueOf()) {
      return true;
    }
  
    // Check if the current date is in the array of blocked dates
    return disableDates.some(date => current && current.isSame(date, 'day'));
  }

  function disabledDateLocalMidDay(current) {
    // Disable dates from today onwards
    const today = moment().startOf('day');
     // Create a set of dates for which timesheets are filled
     const timesheetFilledDates = new Set(timesheetData.map(e => moment(e.TimesheetDate).startOf('day').valueOf()));

     // Check if the current date is today or in the future
     // or if the timesheet is not filled for the current date
     if (current && (current.valueOf() >= today.valueOf() || !timesheetFilledDates.has(current.startOf('day').valueOf()))) {
         return true;
     }
  
    // Check if the current date is in the array of blocked dates
    return disableDates.some(date => current && current.isSame(date, 'day'));
  }

  useEffect(() => {
    // getZohoRequestData();
    getAttendenceDetail();
    getLeaveTypeData();
  }, [])
  useEffect(e => {
    let arr = daysArr?.map((item) => {
      return { value: item.name, label: item.name };
    })
    setDayOptions(arr)
  }, [])

  useEffect(() => {
    if (ptoCode.length > 0) {
      let arr = ptoCode?.map((item, i) => {
        return { value: item.Name, label: item.Name };
      });
      setPtoCodeOptions(arr)
    }

  }, [ptoCode])

  const getLeaveTypeData = () => {
    return new Promise((resolve, reject) => {
      leaveService.GetLeaveTypes("616894000000190005")
        .then((res) => {
          // result.data.Date = date;
          setPtoCode(res.response.result)
          resolve(res);


        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  const GetFramesAccordingToCalender = (from, till) => {
    let frames = []
    while (from <= till) {
      let formattedDate = dayjs(new Date(from)).format("MM-DD-YYYY");
      from.setDate(from.getDate() + 1)
      frames.push(formattedDate);
    }
    return frames;
  }


  const getAttendenceDetail = async () => {

    // return new Promise((resolve, reject) => {
    //   let doArr = []
    //   let frames = GetFramesAccordingToCalender(new Date(startDate), new Date(endDate));
    //   frames.forEach((frame) => {
    //     doArr.push(getDetailsAttendence(frame))
    //   })
    //   Promise.all(doArr)
    //     .then((result) => {
    //       //  result=SplitDataToInOneDayForAnotherDay(result);
    //       setZohoRequestData(result);
    //       resolve(result)
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     })

    // })
    await AttendenceService.GetAttendenceAllUser("", "", 0)
      .then(async(result) => {
        let arr = [];
        
        // result?.data?.data?.forEach(obj => {
        //   let d = { ...obj?.Attendance }
        //   d.TimesheetDate=modifyTimezoneString(d.TimesheetDate,"MM/DD/YYYY");
        //   if (!d['checkIn'] || d['checkIn'] != '-' || d['checkIn'] != '') {
        //     try {
        //       d.checkIn = getTimeInAMPMFormat(d['checkIn']);
        //     } catch (err) {
        //       d.checkIn = "-"
        //     }
        //   }
        //   if (!d['checkOut'] || d['checkOut'] != '-' || d['checkOut'] != '') {
        //     try {
        //       d.checkOut = getTimeInAMPMFormat(d['checkOut']);
        //     } catch (err) {
        //       d.checkOut = "-"
        //     }
        //   }
        //   arr.push(d);
        // })
        await modifyTimesheetData(result?.data?.data).then(r=>{
          arr=r;
        }).catch(err=>{
          console.log(err)
        });
        if (userInfo && userInfo?.role_details.role_name == 'App User') {
          arr = arr.filter(one => one.email == userInfo.email_id && (one.checkIn !== '-'));
        } else {
          arr = arr.filter(one => (one.checkIn !== '-'));
        }

        try {
          arr = arr.sort((a, b) => new Date(b.TimesheetDate+" "+b.checkIn) - new Date(a.TimesheetDate+" "+a.checkIn));
        } catch (e) {
        }
         
        setTimesheetData(arr);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const getDetailsAttendence = (date) => {
    return new Promise((resolve, reject) => {
      AttendenceService.GetSplitAttendence(date, userInfo?.email_id)
        .then((result) => {
          // result.data.Date = date;
          resolve(result.data);

        })
        .catch((err) => {
          reject(err);
        })
    })
  }
  const getTime = (date, obj) => {
    if(obj && obj.requestType == "Absent"){
      return "-"
    }else{
      let Time = new Date(date);
      var hours = Time.getHours();
      var minutes = Time.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }

  };

  const { RangePicker } = AntdDatePicker;


  // const ShowAlert = (message, flag) => {
  //   return <div>
  //     <Modal
  //       className="exportmodal-width"
  //       isOpen={flag}
  //       toggle={flag}
  //     >
  //       <ModalHeader>Alert</ModalHeader>
  //       <ModalBody>
  //         <span className="text-bold" for="l2code">
  //           {message}
  //         </span>
  //       </ModalBody>
  //       <ModalFooter>
  //         <Button
  //           color="primary"
  //           onClick={() => {
  //             setShowAlert(!showAlert);
  //             setAlertMessage('');
  //             setAlertTitle('');
  //           }}
  //         >
  //           {" "}
  //           OK
  //         </Button>
  //         {/* <Button color="secondary" onClick={() => setDelPopup(!delPopup)}>
  //         {" "}
  //         Cancel
  //       </Button> */}
  //       </ModalFooter>
  //     </Modal>
  //   </div>
  // }

  const changeDateRange = (item,dates) => { 
    setStartDate(item.selection.startDate);
    setEndDate(item.selection.endDate);
    // setSelectionRange([item.selection]);
    toggle();
    setSelectedDateRange(
      GetFormattedDate(item.selection.startDate) +
      " - " +
      GetFormattedDate(item.selection.endDate)
    );
    let filterData = [];
    cacheData.forEach((one) => {
      if (
       ( new Date(item.selection.startDate) <= new Date(one.TimesheetDate) &&
        new Date(item.selection.endDate) >= new Date(one.TimesheetDate)) || dates=="Clear"
      ) {
        filterData.push(one);
      }
    });
    setRowData(filterData);

    //  
    // getDashBoardData(
    //   allTasks,
    //   item.selection.startDate,
    //   item.selection.endDate
    // );
  };

  const [status,setStatus] =useState(
    [{id:"0",field:"Pending",isChecked:true},
      {id:"1", field:"Approved",isChecked:true}, 
      {id:"2",field:"Rejected",isChecked:true}]
    );

   useEffect(()=>{
    let arr=[];
    let checks=[]
    status.filter(one=>{
      if(one.isChecked==true){
        checks.push(one.id)
    }})
    
    filterData?.forEach(one=>{
      if(checks.includes(one.status)){
        arr.push(one);
      }
    });
    setRowData(arr);
   },[status,filterData])

  const toggle = () => setPopoverOpen(!popoverOpen);

  const getTimeForTimePicker = (date) => {
    let Time = new Date(date);
    return (
      (Time.getHours() < 10 ? "0" : "") +
      Time.getHours() +
      ":" +
      (Time.getMinutes() < 10 ? "0" : "") +
      Time.getMinutes()
    );
  };

  // const filterDataOnStatus=(e)=>{
      
  //   if(e?.currentTarget?.classList?.contains("Active")){
  //     e?.currentTarget?.classList?.remove("Active")
  //   }else{
  //     e?.currentTarget?.classList?.add("Active")
  //   }
  //   let arr=[];
  //   let parent=e.currentTarget.parentElement.parentElement.querySelectorAll(".Active").forEach(e=>{
  //     arr.push(e.id);
  //   })
  //   if(arr.length>0){
  //     let data=cacheData.filter(e=>arr.includes(e.status));
  //     setRowData(data);
  //   }else{
  //     setRowData(cacheData);
  //   }
  // }
  const extraComponentForTable=(e)=>{
    return (
      <div className="extra-component-request">
        <div>
          <AntdRangePicker
            className="req-range-picker"
            onChangeDateRange={onChangeDateRange}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            allStart={cacheData[cacheData?.length-1]?.TimesheetDate} allEnd={cacheData[0]?.TimesheetDate}
          />
        </div>
        {/* <div className="btn-group deal-status-dropdown-custom3 render-status">
          <div className="wrapper">
            <span
              className="visually-hidden status-btn Pending Action-Checkbox"
              style={{ padding: "0px" }}
            >
              <input id="0" type="checkbox" onClick={filterDataOnStatus} />
              Pending
            </span>
            <span
              className="visually-hidden status-btn Approved Action-Checkbox"
              style={{ padding: "0px" }}
            >
              <input id="1" type="checkbox" onClick={filterDataOnStatus} />
              Approved
            </span>
            <span
              className="visually-hidden status-btn Rejected Action-Checkbox"
              style={{ padding: "0px" }}
            >
              <input id="2" type="checkbox" onClick={filterDataOnStatus} />{" "}
              Rejected
            </span>
          </div>
        </div> */}
         {getModalForStatus()}
        <Button
        className="bg-primary"
        onClick={()=>{
          setIsEditColumnTrue(!isEditColumnTrue)
        }}
        style={{ fontWeight: "500" , height: "1.8rem", lineHeight: "16px"}}
        >
        Status
        </Button>
        
        {/* <div>
          <Select
               value={selectedUser ? {label : selectedUser.full_name, value: selectedUser.id} : {label : 'All Users', value: 'all'}}
              options={userList}
              className="select-user"
              closeMenuOnSelect={true}
              onChange={(e) => { 
                if (e.value == 'all') {
                  onChangeUserFilter({ full_name: e.label , id : e.value});
                } else { 
                  let filterUser = allusers.filter(one =>  one.id == e.value);
                  if (filterUser.length > 0) {
                    onChangeUserFilter(filterUser[0]);
                  }
                }
              }}
              // value={selectedUserEmail ? selectedUserEmail :""}
              menuPlacement="auto"
            />
        </div> */}
      </div>
    );
  }

  const extraComponentToRight=(e)=>{
    return (<div className="extra-component-request-toRight">
          {getActionsForRequest()}
         
        <div>
          <Select
               value={selectedUser ? {label : selectedUser.full_name, value: selectedUser.id} : {label : 'All Users', value: 'all'}}
              options={userList}
              className="select-user"
              closeMenuOnSelect={true}
              onChange={(e) => { 
                if (e.value == 'all') {
                  onChangeUserFilter({ full_name: e.label , id : e.value});
                } else { 
                  let filterUser = allusers.filter(one =>  one.id == e.value);
                  if (filterUser.length > 0) {
                    onChangeUserFilter(filterUser[0]);
                  }
                }
              }}
              // value={selectedUserEmail ? selectedUserEmail :""}
              menuPlacement="auto"
            />
        </div>
    </div>
    );
  }

  const onChangeDelete = (name, value, checked) => {
    const ids = [...deletedIds];
    const data = [...rowData];
    const allObj = { ...deleteAll };
    if (value == "All") {
      if (checked) {
        data.forEach((one) => {
          one.isDelete = checked;
          if (!ids.includes(one._id)) {
            ids.push(one._id);
          }
        });
        allObj.isDelete = checked;
        setDeleteAll(allObj);
        setDeletedIds(ids);
        setRowData(data);
      } else {
        data.forEach((one) => {
          one.isDelete = false;
        });
        allObj.isDelete = checked;
        setDeleteAll(allObj);
        setRowData(data);
        setDeletedIds([]);
      }
    } else {
      if (ids.includes(value)) {
        let filterIds = ids.filter((id) => id !== value);
        data.filter((one) => one._id == value)[0].isDelete = false;
        allObj.isDelete = false;
        setDeleteAll(allObj);
        setDeletedIds(filterIds);
        setRowData(data);
      } else {
        data.filter((one) => one._id == value)[0].isDelete = true;
        ids.push(value);
        allObj.isDelete = false;
        setDeleteAll(allObj);
        setDeletedIds(ids);
        setRowData(data);
      }
    }
  };

  const getStyleForButton = (obj) => {
    if (parseInt(obj.status) == 0) {
      return { backgroundColor: "#0230e8" };
    }
    if (parseInt(obj.status) == 1) {
      return { backgroundColor: "green" };
    }
    if (parseInt(obj.status) == 2) {
      return { backgroundColor: "#e50000" };
    }
  };

  const openEmployeeNotePopup = () => {
    return <Modal
      size="md"
      isOpen={employeeNotePopup}
      toggle={employeeNotePopup}
    >
      <ModalHeader>Employee Note</ModalHeader>
      <ModalBody>
        <Row className="mt-2">
          <Col sm={12}>
            <label>Note:</label>
            <textarea
              value={noteObj["note"]}
              // onChange={(e) => onChange("managerComm", e.currentTarget.value)}
              style={{ width: "100%" }}
              rows="3"
              name="note"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => setEmployeeNotePopup(false)}
        >
          Cancel
        </Button>

      </ModalFooter>
    </Modal>
  }
  function escapeHTML(html) {
    return html.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
  }

  const convertTime12to24 = (time12h) => { 

    if (time12h) {
      time12h = getTime(time12h);
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes] = time.split(':');

      if (hours === '12') {
        hours = '00';
      }

      if (modifier?.toUpperCase() === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      hours = (hours.length == 1) ? '0' + hours : hours;
      return `${hours}:${minutes}`;
    }
    return time12h;

  }

  const getShiftDetailsOfUser=async (email,fromDate,toDate)=>{
    let arr=[]
   await ShiftServices.GetShiftConfiguration(fromDate,toDate,email)
            .then((result)=>{ 
                let shiftData = result.data.userShiftDetails;
               shiftData.shiftList?.forEach(e=>{
                    let obj = {
                        employeeName : shiftData.employeeName,
                        emailId : shiftData.emailId,
                        shiftStartTime : e.shiftStartTime,
                        shiftEndTime : e.shiftEndTime,
                        shiftName : e.shiftName,
                        date : e.date,
                    }
                    arr.push(obj)
                })
                setShiftData(arr) 
  }).catch(err=>{ 
    console.log("err", err);
  })
}

  const updateRequestAndInsertTime = async (data, status) => {
     
    let obj = {...data};
    //let approvedUser=allusers.filter(one=>one.zuid==localStorage.getItem("ZUID"));
    //const appRovedUserEmail=approvedUser[0].email
     
    let zohoData = [...timesheetData];
    if (obj.requestType.includes("Timesheet Request")) {
      let payLoad={}
      payLoad.inTime = data.inTime;
      payLoad.ROWID = data._id;
      payLoad.outTime = data.outTime;
      payLoad.status = parseInt(status)
      payLoad.managerComm = ""
      if (requestData) {
        obj.managerComm = requestData.managerComm;
      }
      if (status == 2) {
        updateTimeRequest(payLoad)
        swal("Success!", "Request Rejected Successfully!", "success");
        return
      } else if (status == 1) {
         
        let checkIn = dayjs(data.inTime).format("DD/MM/YYYY HH:mm:ss");
        let checkOut = dayjs(data.outTime).format("DD/MM/YYYY HH:mm:ss");
        let arr = [];
        let checkEntryExist = zohoData?.filter(one => {     
          if(one.email==data.email){
            let dObj = new Date(one.rawcheckIn);
            let dOutObj = new Date(one.rawcheckOut);
            let cIn = new Date(data.rawcheckIn);
            let cOut = new Date(data.rawcheckOut);
            cIn?.setSeconds(0);
            cOut?.setSeconds(0);
            if (
              !((cIn < new Date(dObj) && (cOut < new Date(dObj) || getDateFormate(cOut) === getDateFormate(dObj)) ) 
            || ((cIn > new Date(dOutObj) || getDateFormate(cIn)  === getDateFormate(dOutObj)) && cOut > new Date(dOutObj) ))
            ){
              return one
            }
          }
        })

        if (checkEntryExist.length > 0) {
          swal("Alert!",   "Please make sure entry does not overlap with existing hours", "warning");
        }else {
           
          let attendanceObj = {};
          attendanceObj.TimesheetDate = data.inTime;
          attendanceObj.UserName = data.userName;
          attendanceObj.checkIn = data.inTime;
          attendanceObj.checkOut = data.outTime;
          attendanceObj.CheckInLocation = data.CheckInLocation;
          attendanceObj.CheckOutLocation = data.CheckOutLocation;
          attendanceObj.DeviceId = data.DeviceId;
          attendanceObj.email = data.email;
          //let timeObj = getWorkingHoursAndMinutes(new Date(data?.inTime), new Date(data?.outTime));
         // attendanceObj.TotalHours = formateNoInDecimalString(timeObj.hours) + ":" + formateNoInDecimalString(timeObj.minutes);
          attendanceObj.TotalHours=data.hours;
         //let updatedValues = updateWorkingHoursIfExist(attendanceObj, zohoRequestData);
         let updatedValues ={data:attendanceObj} ;
          if (updatedValues.isEdit) {
            await AttendenceService.UpdateAttendence(updatedValues.data.ROWID, updatedValues.data)
              .then((result) => {
                // console.log(result)
                getAttendenceDetail()
                updateTimeRequest({ROWID:obj.ROWID,status:status})
                swal("Success!", "Request Approved Successfully!", "success");
              })
              .catch((err) => {
                swal("Error!", "Something went wrong!", "error");
                // console.log(err);
              })
          } else {
            AttendenceService.CreateAttendence(checkIn, checkOut, "", attendanceObj)
              .then((result) => {
                console.log(result);
                if (result?.data?.status == 'success') {
                  // tempObj["EntryType"] = [status]
                  getAttendenceDetail()
                  updateTimeRequest({ROWID:obj.ROWID,status:status})
                  swal("Success!", "Request Approved Successfully!", "success");
                } else {
                  swal("Alert!",   "Please make sure entry does not overlap with existing hours", "warning");
                  //setShowAlert(true);
                }
              })
              .then((result) => {
                // console.log(result);
              })
              .catch((err) => {
                swal("Error!", "Something went wrong!", "error");
                // console.log(err);
              })
          }
        }

      }else if (status == 0) {
        updateTimeRequest(payLoad)
        swal("Success!", "Request Status Changed Successfully!", "success");
        return
      } 
    }else if (obj.requestType.includes("Edit Timesheet Request")) {
      let payLoad={}
      payLoad.inTime = data.inTime;
      payLoad.ROWID = data._id;
      payLoad.outTime = data.outTime;
      payLoad.status = parseInt(status)
      payLoad.managerComm = ""
      if (requestData) {
        obj.managerComm = requestData.managerComm;
      }
      if (status == 2) {
        updateTimeRequest(payLoad)
        swal("Success!", "Request Rejected Successfully!", "success");
        return
      } else if (status == 1) {         
          let attendanceObj = {};
          attendanceObj.TimesheetDate = data.inTime;
          attendanceObj.UserName = data.userName;
          attendanceObj.ROWID = data?.requestType?.split("_")[1];
          attendanceObj.checkIn = data.inTime;
          attendanceObj.checkOut = data.outTime;
          attendanceObj.CheckInLocation = "-";
          attendanceObj.CheckOutLocation = "-";
          attendanceObj.email = data.email;
          //let timeObj = getWorkingHoursAndMinutes(new Date(data?.inTime), new Date(data?.outTime));
         // attendanceObj.TotalHours = formateNoInDecimalString(timeObj.hours) + ":" + formateNoInDecimalString(timeObj.minutes);
          attendanceObj.TotalHours=data.hours;
         //let updatedValues = updateWorkingHoursIfExist(attendanceObj, zohoRequestData);
      
            await AttendenceService.UpdateAttendence(attendanceObj.ROWID, attendanceObj)
              .then((result) => {
                // console.log(result)
                getAttendenceDetail()
                updateTimeRequest({ROWID:obj.ROWID,status:status})
                swal("Success!", "Request Approved Successfully!", "success");
              })
              .catch((err) => {
                swal("Error!", "Something went wrong!", "error");
                // console.log(err);
              })
      }else if (status == 0) {
        updateTimeRequest(payLoad)
        swal("Success!", "Request Status Changed Successfully!", "success");
        return
      } 
    }
    else if ((obj.requestType.includes("Full Day") || obj.requestType.includes("Partial Day")) && !obj.reason.includes("Midday")) {
      let payLoad={}
      payLoad.inTime = data.inTime;
      payLoad.ROWID = data._id;
      payLoad.outTime = data.outTime;
      payLoad.status = parseInt(status);

      let datediff = Math.round((obj.TillDate - obj.FromDate) / (1000 * 60 * 60 * 24));
      obj.status = parseInt(status)
      obj.managerComm = ""
      if (requestData) {
        obj.managerComm = requestData.managerComm;
      }
      if (obj.status == 2) {
        updateTimeRequest(payLoad)
        swal("Success!", "Request Rejected Successfully!", "success");
        return
      }else if ((status) == 1) {
        let payLoad={ROWID:data.ROWID,status:status}
        updateTimeRequest(payLoad)
        swal("Success!", "Request Approved Successfully!", "success");
        return

        // let ptoId = ptoCode.filter(one => one.Name == obj.reason)[0];
        // // console.log(ptoId);
        // let leaveData = {
        //   Employee_ID: "616894000000190005",
        //   Leavetype: ptoId.Id,
        //   From: dayjs(obj.TimesheetDate).format("MM-DD-YYYY"),
        //   To: dayjs(obj.TimesheetDate).format("MM-DD-YYYY"),

        // }

        // if (ptoCodeUnit == 'Days') {
        //   leaveData.days = {
        //     [leaveData.From]: {
        //       LeaveCount: obj.Day,
        //     },
        //   }
        // }
        // updateTimeRequest(payLoad)
        // swal("Success!", "Request Approved Successfully!", "success");
        // AttendenceService.CreateLeave(leaveData)
        //   .then((result) => {
        //     if (result.response.message == 'Data added successfully') {
        //       return
        //     } else {
        //       // setAlertMessage("Zoho server error for updating request");
        //       swal("Error!", "Zoho server error for updating request", "error");
        //       // setShowAlert(true);
        //     }
        //     // console.log(result);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })

      }else{
        let payLoad={ROWID:data.ROWID,status:status}
        updateTimeRequest(payLoad)
        swal("Success!", "Request Status Changed Successfully!", "success");
        return
      }

    }else if(data.requestType?.includes("Delete Timesheet Request")){
      let objRoeId=data.requestType.split("_")[1];
      if(objRoeId && status == 1){
        await AttendenceService.DeleteAttendance([objRoeId]).then(res=>{
          let newPayLoad={ROWID:data.ROWID,status:status }
          updateTimeRequest(newPayLoad)
          swal("Success!", "Records deleted successfully!", "success");
        }).catch(e=>{

        })
      }else if(objRoeId && status == 2){
        let newPayLoad={ROWID:obj.ROWID,status:status }
        updateTimeRequest(newPayLoad)
      }else if(objRoeId && status == 0){
        let newPayLoad={ROWID:obj.ROWID,status:status }
        updateTimeRequest(newPayLoad)
      }
    }else if((obj.requestType.includes("Full Day") || obj.requestType.includes("Partial Day")) && obj.reason.includes("Midday")){
      
      if (status == 2) {
        let payLoad={ROWID:data.ROWID,status:status}
        updateTimeRequest(payLoad)
        swal("Success!", "Request Rejected Successfully!", "success");
        return
      } else if (status == 1) {
        let arr = combineTimesheetRecords(zohoData)?.filter(d=>d.TimesheetDate==data.TimesheetDate && d.email==data.email);
        
        let newArr=[];
        if(arr && arr?.length>0){
          newArr = getModifiedEntries(arr,data);
        }
        if(newArr?.length>0){
          await UpdateTimesheetWithBreak(newArr).then(res=>{
            updateTimeRequest({ROWID:obj.ROWID,status:status})
            setBreakRequestData(null);
            swal("Success!", "Request Approved Successfully!", "success").then(k=>{
              getAttendenceDetail();
            })
          }).catch(err=>{
            swal("Error!", "Something went wrong!", "error");
          })
        }
        
      }else if (status == 0) {
        let payLoad={ROWID:data.ROWID,status:status}
        updateTimeRequest(payLoad)
        swal("Success!", "Request Status Changed Successfully!", "success");
        return
      }
    }
  };
  const onUpdateStatusOfRequest=async(data, status,attendanceData, editFlag)=>{
    if(editFlag){
      data = cacheData?.find(k => k?.ROWID == data?.ROWID)
    }
    let zohoData = [...attendanceData];
    let arr = combineTimesheetRecords(zohoData)?.filter(d=>d.TimesheetDate==data.TimesheetDate && d.email==data.email);
    await joinAttendanceWithBreakInBetween(arr,data).then(async res=>{
      if(res.UpdateObject){
        await AttendenceService.UpdateAttendence(res.UpdateObject?.ROWID,res.UpdateObject).then(async r=>{
          await AttendenceService.DeleteAttendance([res.DeleteObject.ROWID]).then(rr=>{
            
          });
        }).then(res=>{
          if(editFlag){
            updateTimeRequest({ROWID:data.ROWID,status:0,inTime:partialAbsenseData.in,outTime:partialAbsenseData.out,reason:partialAbsenseData.reason})
          }else{
            updateTimeRequest({ROWID:data.ROWID,status:status})
          }
          swal("Success!", "Request Status Changed Successfully!", "success").then(k=>{
            getAttendenceDetail();
          })
        })
      }
    }).catch(err=>{
      swal("Error!", "Something went wrong!", "error");
    })
    console.log(arr);
  }
  const onUpdateRequestStatus = (val, data, editFlag) => {
    setIsStatusChanged(!isStatusChanged);
    setUpdateRequestStatus(val);
    setUpdateRequestData(data);
    
    if(data.status!=0 && val!=1 && data?.reason?.includes("Midday") || (data?.reason?.includes("Midday") && editFlag)){
      onUpdateStatusOfRequest(data, val, timesheetData, editFlag);
    }else{
      updateRequestAndInsertTime(data, val);
    }
  };

  const renderStatusPopOver = (obj, key) => {
    let st={
      0:"Pending",
      1:"Approved",
      2:"Reject"
    }
    const eDiv = document.createElement("div");
    if (userInfo && userInfo?.role_details.role_name != 'App Administrator'){
      eDiv.innerHTML = `<div class="btn-group deal-status-dropdown-custom1 render-status">
            <button status="${obj[key]}" type="button" id=${"button-"+obj.ROWID}  class="btn btn-sm dropdown-toggle StatusChangeButton ${obj[key]==1?"AprovedColor":obj[key]==2?"RejectedColor":""}" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="visually-hidden main-span" style="color: "#fff"}">${st[obj[key]]}</span>
            </button>
      </div>`;
    }else if(userInfo && userInfo?.role_details.role_name == 'App Administrator' && obj[key]=="0"){
      eDiv.innerHTML = `<div class="btn-group deal-status-dropdown-custom2 render-status">
            <button status="${obj[key]}" type="button" id=${"button-"+obj.ROWID} style="background-color:}" class="btn btn-sm dropdown-toggle StatusChangeButton ${obj[key]==1?"AprovedColor":obj[key]==2?"RejectedColor":""}" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="visually-hidden main-span" style="color: "#fff"}">${st[obj[key]]}</span>
              <div class="overlayBtn-wrapper">
              <span id=${"1-"+obj.ROWID} value="1" class="visually-hidden AprovedColor Action-Buttons" style="color: "#fff"}">Approve</span>
              <span id=${"2-"+obj.ROWID} value="2" class="visually-hidden RejectedColor Action-Buttons" style="color: "#fff"}">Reject</span>
              </div>
            </button>
      </div>`;
    }else if(userInfo && userInfo?.role_details.role_name == 'App Administrator' && obj[key]!="0"){
      eDiv.innerHTML = `<div class="btn-group deal-status-dropdown-custom2 render-status">
            <button status="${obj[key]}" type="button" id=${"button-"+obj.ROWID} style="background-color:}" class="btn btn-sm dropdown-toggle StatusChangeButton ${obj[key]==1?"AprovedColor":obj[key]==2?"RejectedColor":""}" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="visually-hidden main-span" style="color: "#fff"}">${st[obj[key]]}</span>
              <div class="overlayBtn-wrapper">
              <span id=${"0-"+obj.ROWID} value="0" class="visually-hidden PendingColour Action-Buttons" style="color: "#fff"}">Pending</span>
              <span id=${obj[key]==1?"2":"1"+"-"+obj.ROWID} value=${obj[key]} class="visually-hidden Action-Buttons ${obj[key]==1?"RejectedColor":obj[key]==2?"AprovedColor":""}" style="color: "#fff"}">${obj[key]==1?"Reject":"Approve"}</span>
              </div>
            </button>
      </div>`;
    }
    eDiv.querySelectorAll(".Action-Buttons").forEach(btn=>{
      btn.addEventListener("click",(e)=>{
        
        let val = e.currentTarget.id;
        swal({
          title: "Warning!",
          text: "Are you sure you want to change the status?",
          icon: "warning",
          buttons: {
            cancel: "Cancel",
            confirm: "Proceed",
          },
        }).then(async(isDelete)=>{
          if(isDelete){
            // let val = e.currentTarget.id;
            let arr=val.split("-");
            let status = arr[0]?parseInt(arr[0]):0;
            let id=arr[1];
            console.log(rowData)
            let data = rowData.find(d => d.ROWID == id);
            if (obj) {
              onUpdateRequestStatus(status, obj)
            }
          }
        })
         
      })
    })

    return eDiv;
  
  }


  const onChangeUserFilter = (user) => {
    setSelectedUser(user);
  }

  function GetFormattedDate1(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return month + "/" + day + "/" + year;
  }

  const getTime24Format = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    minutes = minutes < 9 ? "0" + minutes : minutes;
    hours = hours < 9 ? "0" + hours : hours;
    return hours + ":" + minutes;
  }

  function actionListCellRenderer(params, email) {
    let reqType= params?.requestType?.split("_")[0] ;
    if(reqType=="Delete Request" && params?.status!=0){
    return;
    }
    // console.log(email);
    // console.log(params);
    let btnDiv = document.createElement("div");
    btnDiv.classList.add("hrlist-action-btn");
    // let addBtn=document.createElement('button');
    // addBtn.classList.add("add")
    // addBtn.value=params.Date !== "" ? params.Date : "";
    // addBtn.innerText="Add"
    // addBtn.addEventListener('click',(e)=>{
    //   console.log(email)
    //   // setSelectedUserEmail(email);
    //   // addPopupOpen(e.target.value, "Add")
    // })
    let editBtn = document.createElement('button');
    editBtn.classList.add("add")
    editBtn.innerText = "Edit"
    editBtn.value = params.Date !== "" ? params.Date : "";
    editBtn.addEventListener('click', (e) => {
       
      // console.log(email)
      if (/Request/.test(params.requestType) || params.requestType == "Partial Day" || params.requestType == "Full Day") { 
        if((params.requestType != "Absent Request") && (params.requestType != "Partial Day") && (params.requestType != "Full Day")){
          setEntryReqModal(!entryReqModal)
          dispatch(addTimeSheetClicked({module : "", button : ""}))
          setEditFlag(true)
          let obj = { ...params };
          let inTime = params.checkIn != "-" ? new Date((params.TimesheetDate+" "+params.checkIn)) : params.checkIn;
          obj.ROWID = params.ROWID
          obj.in = inTime;
          let outTime = params.checkOut != "-" ? new Date((params.TimesheetDate+" "+params.checkOut)) : params.checkOut;
          obj.out = outTime
          obj.date = new Date(params.TimesheetDate);
          setRequestData(obj)
        }else{
          setEntryAbsenceRequest(!entryAbsenceModal)
          dispatch(addTimeSheetClicked({module : "", button : ""}))
          setEditFlag(true)
          let obj = { ...params };
          let newObj = {};
          newObj.DateWise = obj.DateWise
          newObj.Day  = obj.Day
          if(params.requestType != "Partial Day"){
            newObj.FromDate = new Date(obj.TimesheetDate)
            newObj.TillDate = new Date(obj.TimesheetDate)
          }else{
            newObj.Date = new Date(obj.TimesheetDate)
          }
          newObj.in = obj.inTime
          newObj.out = obj.outTime
          newObj.requestType = obj.requestType
          newObj.reason = obj.reason
          newObj.ptoCode = obj.ptoCode
          newObj.status = obj.status
          if(obj.ptoCode == "Personal Leave (Clone)" || obj.ptoCode == "Personal Leave (test)"){
            setPtoCodeUnit("Hours")
          }else{
            setPtoCodeUnit("Days")
          }
          newObj.TimesheetDate = obj.TimesheetDate
          newObj.email = obj.email
          newObj.note = obj.note
          newObj.ROWID = obj.ROWID
          setPartialAbsenseData(newObj)
        }
      }
     
    })
    let deleteBtn = document.createElement('button');
    deleteBtn.classList.add("add")
    deleteBtn.style.color = "red"
    deleteBtn.innerText = "Delete"
    deleteBtn.value = params._id !== "" ? params._id : "";
    deleteBtn.addEventListener('click', (e) => {
      // console.log(email)
      // setSelectedUserEmail(email);
      // setDelPopup(!delPopup)
      // addPopupOpen(e.target.value, "Delete",params)
      swal({
        title: "Warning!",
        text: "Are you sure you want to delete the record?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: "Proceed",
        },
      }).then((isDelete)=>{
        if(isDelete){
          deleteSelectedItems(params._id);
        }
      })
    })
    // btnDiv.appendChild(addBtn);
    if(params.status==0 || (userInfo && userInfo?.role_details.role_name == 'App Administrator')){
      btnDiv.appendChild(editBtn);
      btnDiv.appendChild(deleteBtn);
    }else{
      return "-";
    }
    return btnDiv;
  }


  


  // const updateRequestAndInsertTime = (data, status) => {
  //   let obj = { ...data };
  //   if(data.requestType==="Request"){

  //     obj.in = data.checkIn;
  //     obj.out = data.checkOut;
  //     obj.status = parseInt(status)
  //     obj.managerComm = ""
  //     if (requestData) {
  //       obj.managerComm = requestData.managerComm;
  //     }

  //     let checkIn = dayjs(data.checkIn).format("DD/MM/YYYY HH:mm:ss");
  //     let checkOut = dayjs(data.checkOut).format("DD/MM/YYYY HH:mm:ss");
  //     AttendenceService.CreateAttendence(checkIn, checkOut, "david@srsolardesign.com")
  //       .then((result) => {
  //         console.log(result);
  //         if (result.data.length > 0) {
  //           if (result.data[0].response == 'success') {
  //             return updateTimeRequest(obj)
  //           } else {
  //             setAlertMessage("Duplicate timesheet entry was overlap. This entry can not be approved");
  //             setShowAlert(true);
  //           }
  //         }
  //         // getLastAttendece();

  //       })
  //       .then((result) => {
  //         console.log(result);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })

  //   }
  //   else{

  //     let datediff = Math.round((obj.TillDate - obj.FromDate) / (1000 *60 *60 * 24));
  //     obj.status = parseInt(status)
  //     obj.managerComm = ""
  //     if (requestData) {
  //       obj.managerComm = requestData.managerComm;
  //     }

  // //   // function parseDate(str) {
  // //   //     var mdy = str.split('/');
  // //   //     return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  // //   // }

  // //   // alert(datediff(parseDate(first.value), parseDate(second.value)));
  // //   // 'Employee_ID':'3000000020481','Leavetype':'3000000046003','From':07-Jan-2021,'To':07-Jan-2021,'days':{'07-Jan-2020':{'LeaveCount':0.5, 'Session':2}}}
  //   let d1 = dayjs().format("DD-MMM-YYYY");
  //   let d2 = dayjs().format("DD-MMM-YYYY");
  //   let leaveData = {
  //     Employee_ID: "616894000000190005",
  //     Leavetype: "616894000000190071",
  //     From: dayjs(obj.date).format("MM-DD-YYYY"),
  //     To: dayjs(obj.date).format("MM-DD-YYYY"),
  //     // days: {
  //     //   "12-07-2022 ": {
  //     //     LeaveCount: "01.00",
  //     //     Session: "2"
  //     //   }, "12-07-2022": {
  //     //     LeaveCount: "01.00",
  //     //     Session: "2"
  //     //   }
  //     // }
  //   }

  //   AttendenceService.CreateLeave(leaveData)
  //     .then((result) => {

  //         if (result.response.message == 'Data added successfully') {
  //           return updateTimeRequest(obj)
  //         } else {
  //           // setAlertMessage("Duplicate timesheet entry was overlap. This entry can not be approved");
  //           setShowAlert(true);
  //         }

  //       console.log(result);
  //       // getLastAttendece();

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })

  //   }

  // };

  const updateTimeRequest = async (obj) => {

    try {
      await axios.post(Config.backend_url + "requestTime", {
        config: {
          Type: "Update",
          data: obj
        }
      }).then(result => {
        if (result) {
          getAllTimesheet()
          // alert("Update Status Success");
          setManagerCommentModal(false)
          return 'Done';
        } else {
          return "Some Internal Error ";
        }
      })
    }
    catch (err) {
      return err
    }


  }

  

  // const onUpdate = (obj) => {
  //   obj = [ ...obj ];
  //   if (obj[0].checkIn && obj[0].checkOut) {
  //     if (obj[0].checkIn) {
  //       obj[0].in = getTimeForTimePicker(new Date(obj[0].checkIn));
  //       delete obj[0].checkIn;
  //     }
  //     if (obj[0].checkOut) {
  //       obj[0].out = getTimeForTimePicker(new Date(obj[0].checkOut));
  //       delete obj[0].checkOut;
  //     }
  //     setEditFlag(true);
  //     setEntryReqModal(!entryReqModal);
  //     setRequestData(obj);
  //   } else {
  //     setEditFlag(true);
  //     setEntryAbsenceRequest(!entryAbsenceModal);
  //     obj.FromDate = new Date(obj.date);
  //     obj.TillDate = new Date(obj.date);
  //     setAbsenseData(obj);
  //   }
  // };
  function convertToDay(str) {
    let dayMap = new Map();
    dayMap.set(0, "SUN");
    dayMap.set(1, "MON");
    dayMap.set(2, "TUE");
    dayMap.set(3, "WED");
    dayMap.set(4, "THU");
    dayMap.set(5, "FRI");
    dayMap.set(6, "SAT");

    let mydate = new Date(str);
    let dayNumber = mydate.getDay();
    // console.log("dayNumber is : " + dayNumber);
    let dayName = dayMap.get(dayNumber);
    // console.log("day is : " + dayName + " for str : " + str);
    return dayName;
  }

  // const onApproveAbsenceEntry = () =>{
  //   let datediff = Math.round((obj.TillDate - obj.FromDate) / (1000 *60 *60 * 24));


  //   // function parseDate(str) {
  //   //     var mdy = str.split('/');
  //   //     return new Date(mdy[2], mdy[0] - 1, mdy[1]);
  //   // }

  //   // alert(datediff(parseDate(first.value), parseDate(second.value)));
  //   // 'Employee_ID':'3000000020481','Leavetype':'3000000046003','From':07-Jan-2021,'To':07-Jan-2021,'days':{'07-Jan-2020':{'LeaveCount':0.5, 'Session':2}}}
  //   let d1 = dayjs().format("DD-MMM-YYYY");
  //   let d2 = dayjs().format("DD-MMM-YYYY");
  //   let leaveData = {
  //     Employee_ID: "616894000000190005",
  //     Leavetype: "616894000000190071",
  //     From: dayjs(obj.FromDate).format("MM-DD-YYYY"),
  //     To: dayjs(obj.TillDate).format("MM-DD-YYYY"),
  //     // days: {
  //     //   "12-07-2022 ": {
  //     //     LeaveCount: "01.00",
  //     //     Session: "2"
  //     //   }, "12-07-2022": {
  //     //     LeaveCount: "01.00",
  //     //     Session: "2"
  //     //   }
  //     // }
  //   }

  //   AttendenceService.CreateLeave(leaveData)
  //     .then((result) => {
  //       console.log(result);
  //       // getLastAttendece();

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })

  // }

  const postAbsenceData = (dataArr, editFlag) =>{ 
    return new Promise((resolve,reject)=>{
      dataArr.forEach(data =>{
        axios.post(Config.backend_url + "requestTime", { config:{
          data:data,
          Type: editFlag ? "Update" : "Post",
          } })
          .then(function (response) {
            resolve(response.data.data)
        })
        .catch(function (error) {
            reject(error);
        });
      })
    })
  }

  function checkValidationForAbsence(obj){
    let isValid=false;
    let tempObj={...tempValidation}
    if(!obj.reason){
      Object.keys(tempObj).map(one=>{
        tempObj[one]='-1';
        isValid=true;
      })
    }else{
      let valFields=valudationObjForAbsense[obj.requestType][obj.reason];
      valFields?.forEach(key=>{
      if((key=='out'||key=='in')&&obj[key]=='00:00'){
        tempObj[key]="-1";
        isValid=true;
      }
      if(!obj[key]){
        tempObj[key]="-1";
        isValid=true;
      }
    })
  }
    setValidation(tempObj);
    return isValid;
  }

  const handleOnsaveAbsenseData=async (obj)=>{

    let shiftStart = new Date(obj.Date); 
    let time = shiftData[0]?.shiftStartTime.split(':')
    shiftStart.setHours(parseInt(time[0]), parseInt(time[1]))

    let shiftEnd = new Date(obj.Date); 
    let endTime = shiftData[0]?.shiftEndTime.split(':')
    shiftEnd.setHours(parseInt(endTime[0]), parseInt(endTime[1]))

    if(checkValidationForAbsence(obj)){
      setshowWarnFooterForAbsenceModal(true)
      return
    }
    obj.userId = userInfo?.email_id;
    let userName=allusers.filter((one)=>one.email==userInfo?.email_id)[0]
    obj.userName = userName.full_name;
    let dataArr = [];
    if(obj.requestType=="Partial Day"){
      if(editFlag && obj.reason == "Midday"){
        onUpdateRequestStatus("0", obj , editFlag);
        setEntryAbsenceRequest(!entryAbsenceModal)
        return
      }
      let finalObj = { ...obj };
      finalObj.status = "0"
      finalObj.requestDate = convertToUSTime(new Date());
      finalObj.TimesheetDate = convertToUSTime(obj.Date);
      finalObj.inTime = finalObj.reason=="Early Leave"?shiftStart:finalObj.in;
      finalObj.outTime = finalObj.reason=="Late Arrival"?shiftEnd:finalObj.out;
      // obj.FromDate.setDate(obj.FromDate.getDate() + 1);
      delete finalObj.FromDate;
      delete finalObj.TillDate;
      delete finalObj.in;
      delete finalObj.out;
      delete finalObj.Date;
      dataArr.push(finalObj);
    }else{
    while (obj.FromDate <= obj.TillDate) {
      let finalObj = { ...obj };
      finalObj.status = "0"
      finalObj.requestDate = convertToUSTime(new Date());
      finalObj.TimesheetDate = convertToUSTime(obj.FromDate);
      finalObj.inTime = "-";
      finalObj.outTime = "-";
      obj.FromDate.setDate(obj.FromDate.getDate() + 1);
      delete finalObj.FromDate;
      delete finalObj.TillDate;
      delete finalObj.in;
      delete finalObj.out;
      delete finalObj.Date;
      dataArr.push(finalObj);
    }
  }
    await postAbsenceData(dataArr, editFlag)
    .then(()=>{ 
      setEditFlag(false);
      setEntryAbsenceRequest(!entryAbsenceModal);
      dispatch(addTimeSheetClicked({module : "", button : ""}))
      setPartialAbsenseData({...absenceObjTemp})
      getAllTimesheet()
      if (editFlag) {
        swal("Success!", "Record updated successfully!", "success");
      } else {
        swal("Success!", "Record added successfully!", "success");
      }
    })
    .catch((err) => {
      swal("Error!", "Something went wrong!", "error");
    });
    
  }

  const onCreateAbsenseData = async(obj) => {  
    let err = validate.validate(obj, schemaForAbsense, errors);
    if (err) {
      setErrors(err);
      return;
    }

    obj.userId = userInfo?.email_id;
    let userName=allusers.filter((one)=>one.email==userInfo?.email_id)[0]
    obj.userName = userName.full_name;
    obj.requestType = "Absent Request"

    // if(editFlag){ 
    //   obj.ROWID = obj.ROWID
    // }

    let dataArr = [];
    while (obj.FromDate <= obj.TillDate) {
      let finalObj = { ...obj };
      finalObj.status = "0"
      finalObj.requestDate = convertToUSTime(new Date());
      finalObj.TimesheetDate = convertToUSTime(obj.FromDate);
      // let inArr = finalObj.in.split(":");
      // let outArr = finalObj.out.split(":");
      // let from = new Date(finalObj.date);
      // let till = new Date(finalObj.date);
      // from.setHours(parseInt(inArr[0]), parseInt(inArr[1]));
      // till.setHours(parseInt(outArr[0]), parseInt(outArr[1]));
      finalObj.inTime = "-";
      finalObj.outTime = "-";
      finalObj.timeId = uuidv4()
      obj.FromDate.setDate(obj.FromDate.getDate() + 1);
      delete finalObj.FromDate;
      delete finalObj.TillDate;
      delete finalObj.in;
      delete finalObj.out;
      dataArr.push(finalObj);
    }
    let doArr = [];
    let url = editFlag ? "updateRequestTime" : "addRequestTime";
    // dataArr.forEach((one) => {
    //   delete one.isDelete;
    //   //one.in.setMilliseconds(1)
    //   doArr.push(HttpService.getByBoj(url, one));
    // });


    // HttpService.getByBoj(url, dataArr)
    await postAbsenceData(dataArr, editFlag)
    .then(()=>{ 
      setEditFlag(false);
      setEntryAbsenceRequest(!entryAbsenceModal);
      dispatch(addTimeSheetClicked({module : "", button : ""}))
      getAllTimesheet()
      if (editFlag) {
        swal("Success!", "Record updated successfully!", "success");
      } else {
        swal("Success!", "Record added successfully!", "success");
      }
    })
    .catch((err) => {
      swal("Error!", "Something went wrong!", "error");
    });
    
  };
  // const onCreateAbsenseData = (obj) => {
  //   let err = validate.validate(obj, schemaForAbsense, errors);
  //   if (err) {
  //     setErrors(err);
  //     return;
  //   }

  //   obj.userId = localStorage.getItem("UserId");
  //   obj.userName = localStorage.getItem("UserName");
  //   obj.requestType = "Absent"

  //   let dataArr = [];
  //   while (obj.FromDate <= obj.TillDate) {
  //     let finalObj = { ...obj };
  //     finalObj.EntryType = [2]
  //     finalObj.date = GetFormattedDate(new Date(obj.FromDate));
  //     let inArr = finalObj.in.split(":");
  //     let outArr = finalObj.out.split(":");
  //     let from = new Date(finalObj.date);
  //     let till = new Date(finalObj.date);
  //     from.setHours(parseInt(inArr[0]), parseInt(inArr[1]));
  //     till.setHours(parseInt(outArr[0]), parseInt(outArr[1]));
  //     finalObj.in = from;
  //     finalObj.out = till;
  //     finalObj.timeId = uuidv4()
  //     obj.FromDate.setDate(obj.FromDate.getDate() + 1);
  //     delete finalObj.FromDate;
  //     delete finalObj.TillDate;
  //     dataArr.push(finalObj);
  //   }
  //   let doArr = [];
  //   let url = editFlag ? "updateRequestTimeData" : "addRequestTime";
  //   // dataArr.forEach((one) => {
  //   //   delete one.isDelete;
  //   //   //one.in.setMilliseconds(1)
  //   //   doArr.push(HttpService.getByBoj(url, one));
  //   // });


  //   HttpService.getByBoj(url, dataArr)
  //     .then((result) => {
  //       if (editFlag) {
  //         alert("Update Success");
  //       } else {
  //         alert("Added Success");
  //       }
  //      // updateData();
  //       setEditFlag(false);
  //       setEntryAbsenceRequest(!entryAbsenceModal);
  //     })
  //     .catch((err) => {
  //       alert(`${err}`);
  //     });
  // };

  const schema = [
    { date: "date fields are required" },
    { in: "time range checkin fields are required" },
    { out: "time range  checkout fields are required" },
  ];

  const getTimeFrame = (start, end, action, data) => {
    let arr = []
    if (action == 'edit') {
      if (start <= end) {
        let obj = { in: getDateFormate(start), out: getDateFormate(end), id: data.tid };
        arr.push(obj);
      } else {
        let inDate = new Date(start);
        let out = new Date(inDate);
        out.setHours(23, 59, 59, 999)
        let obj1 = { in: inDate, out: out, id: data.tid }
        let inDate1 = new Date(out);
        inDate1.setDate(inDate1.getDate() + 1);
        inDate1.setHours(0, 0, 0, 0);
        let outDate1 = new Date(end);
        outDate1.setDate(outDate1.getDate() + 1);
        let obj2 = { in: inDate1, out: outDate1, id: uuidv4() };
        arr.push(obj1);
        arr.push(obj2)
      }
    } else {
      if (start <= end) {
        let obj = { in: getDateFormate(start), out: getDateFormate(end), id: uuidv4() };
        arr.push(obj);
      } else {
        let inDate = new Date(start);
        let out = new Date(inDate);
        out.setHours(23, 59, 59, 999)
        let obj1 = { in: inDate, out: out, id: uuidv4() }
        let inDate1 = new Date(out);
        inDate1.setDate(inDate1.getDate() + 1);
        inDate1.setHours(0, 0, 0, 0);
        let outDate1 = new Date(end);
        outDate1.setDate(outDate1.getDate() + 1);
        let obj2 = { in: inDate1, out: outDate1, id: uuidv4() };
        arr.push(obj1);
        arr.push(obj2)
      }
    }

    return arr;
  }

  const checkAndValidateEntry = (data) => {
     
    let obj = { ...data };
    let zohoData = [...cacheData];

    let checkEntryExist = zohoData.filter(one => {
      // let tempdate=dayjs(new Date(data.TimesheetDate)).format("MM/DD/YYYY");
      if(one.email==data.userId){
        // let dt = dayjs(one.inTime).format("HH:mm:ss");
        // let dateTimeInVal = new Date(one.TimesheetDate);
        // let dateStr = dateTimeInVal.toString();
        // let dObj = dateStr.replace("00:00:00", dt)
  
        // let odt = dayjs(one.outTime).format("HH:mm:ss");
        // let dateTimeOutVal = new Date(one.TimesheetDate);
        // let dateOutStr = dateTimeOutVal.toString();
        // let dOutObj = dateOutStr.replace("00:00:00", odt);
        if(one.rawcheckIn == "-" || one.rawcheckOut == "-"){
          return
        }
        let dObj = new Date(one.rawcheckIn);
        let dOutObj = new Date(one.rawcheckOut);
  
        let cIn=new Date(data.inTime);
        let cOut=new Date(data.outTime);
        cIn?.setSeconds(0);
        cOut?.setSeconds(0);
  
        if (
          !((cIn < new Date(dObj) && (cOut < new Date(dObj) || getDateFormate(cOut) === getDateFormate(dObj)) ) 
        || ((cIn > new Date(dOutObj) || getDateFormate(cIn)  === getDateFormate(dOutObj)) && cOut > new Date(dOutObj) ))
        ){
          return one
        }
      }
    })

    if (checkEntryExist.length > 0) {
      return true
    }else {
      return false
    }




  };


  const addRequestEntry = async(obj,BreakReq) =>{ 
     
    let payload={};
    let requestArr = [];
    const err = validate.validate(obj, schema, errors);
    if (err) {
      setErrors(err);
      return;
    }
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    if (userInfo) {
      payload.userId = userInfo?.email_id;
    }
    if(editFlag){ 
      payload.ROWID = obj.ROWID
    }
    let userName = allusers.filter((one) => one.email == userInfo?.email_id)[0];
    payload.userName = userName?.full_name;
    payload.note = !BreakReq? requestData.note != "null" ? requestData.note : "":breakRequestData.note != "null" ? breakRequestData.note:"";
    payload.requestType = !BreakReq?"Timesheet Request":"Missing Break Request";
    payload.requestDate = convertToUSTime(new Date());
    payload.TimesheetDate = convertToUSTime(obj?.in);
    // let url;
    if (obj.in) {
      if (typeof obj.in === typeof new Date()) {
        payload.inTime = convertToUSTime(obj.in);
      }
    }
    if (obj.out) {
      if (typeof obj.out === typeof new Date()) {
        payload.outTime = convertToUSTime(obj.out);
      }
    }
    
    if (!checkAndValidateEntry(payload) || editFlag || BreakReq) {
      await getDeviceId(navigator).then(add=>{
        payload.CheckInLocation = add;
        payload.CheckOutLocation = add;
        payload.DeviceId = checkDeviceIdCookie();
      }).catch(e=>{
        console.log(e);
      })
      if(obj.in != "-" && obj.out != "-"){
        let HObject = getWorkingHoursAndMinutes(new Date(obj.in), new Date(obj.out));
        payload.hours = formateNoInDecimalString(HObject.hours) + ":" + formateNoInDecimalString(HObject.minutes);
      }
      requestArr.push(obj);
      await axios.post(Config.backend_url + "requestTime", { config:{
        data:payload,
        Type: editFlag ? "Update" : "Post",
      } })
        .then((result) => {
            if(result.data.status=="success"){
              getAllTimesheet()
              swal("Success!", editFlag ? "Request updated successfully" : "Request added successfully", "success")
              if(BreakReq){
                setBreakRequestData(null)
                setEditFlag(false);
                setBreakReqModal(!breakReqModal);
              }else{
                setRequestData(null)
                setEditFlag(false);
                setEntryReqModal(!entryReqModal);
              }
              dispatch(addTimeSheetClicked({module : "", button : ""}))
            }else{
              swal("Error!", "Something went wrong!", "error");
            }
        })
        .catch((err) => {
          swal("Error!", "Something went wrong!", "error");
        });
    } else {
      swal("Alert!", "Please make sure entry does not overlap with existing hours", "warning");
    }
  }

  const onSave = async(obj) => {  
     
    try{
      if(obj.in>obj.out){
        obj.out.setDate(obj.out.getDate() + 1);
        // let result=getWorkingHours(obj.in,obj.out)
        // // console.log(result);
        // if(result?.length>0){
        //   result.forEach(async o=>{
        //     await addRequestEntry(o,true)
        //   })
        //   getAllTimesheet()
        //   if(editFlag){
        //     swal("Success!",result?.length + " Request updated successfully" , "success");
        //   }else{
        //     swal("Success!",result?.length + " Request added successfully" , "success");
        //   }
        // }
      }
      await addRequestEntry(obj)
      
    }catch(err){
      swal("Error!",err.message, "error");
    }
  };
  const onSaveBreakEntry = async(obj) => {  
    
    try{
      if(obj.in>obj.out){
        obj.out.setDate(obj.out.getDate() + 1);
        return
      }
      await addRequestEntry(obj,true)
      
    }catch(err){
      swal("Error!",err.message, "error");
    }
  };

  const onChangeTime = (name,time,breakModalflag, partial) => { 
    let requestObj = { ...requestData };
    if(breakModalflag){
      requestObj={...breakRequestData}
    } 
    let dateTime;
    if(partial=="partial"){
      dateTime = new Date(partialAbsenseData.Date);
    }else{
     dateTime = new Date(requestObj.date);
    }
    if (name == 'in' || name == 'out') {
      time = time.split(':')
      dateTime.setHours(parseInt(time[0]), parseInt(time[1]))
      requestObj[name] = dateTime;
    }else if (name == 'date') {
      requestObj.date = time;
      if (requestObj.in) {
        let newIn = new Date(requestObj.date);
        newIn.setHours(requestObj.in.getHours(), requestObj.in.getMinutes())
        requestObj['in'] = newIn;
      }
      if (requestObj.out) {
        let newout = new Date(requestObj.date);
        newout.setHours(requestObj.out.getHours(), requestObj.out.getMinutes())
        requestObj['out'] = newout;
      }
      setShowDatePicker(false);
    }
    if(breakModalflag){
      setBreakRequestData(requestObj);
    }else{
      setRequestData(requestObj);
    } 
  };

  const getActionsForRequest = () => {
    const items = [
      {
        label: <a onClick={() => {
          let date;
           
          // let obj = { From: new Date(), Till: new Date(), date: GetFormattedDate(new Date())}
          date = new Date(dayjs(new Date()).subtract(1,"day").format("MM/DD/YYYY"));
          const editObj = { ...requestData};
          editObj.date = date;
          let inTime = new Date(date);
          let out = new Date(date);
          inTime.setHours(9, 0, 0, 0);
          out.setHours(17, 0, 0, 0);
          let obj = { date: date, in: inTime, out: out , note: ""};
          setEntryReqModal(!entryReqModal);
          setModalPosition({ x: 0, y: 35 })
          setRequestData(obj);
          setErrors(null);
        }}
        >Entry Request</a>,
        key: "0",
      },
      {
        label: <a onClick={() => {
          // if (isAccess && isAccess(selectedRole, 'Request', 'Write')) {
          let date = new Date();
          let obj = { in: "00:00", out: "23:59", DateWise: true, FromDate : new Date(), TillDate: new Date() };
          // getTotalHrsBetweenTwoDate(obj.in, obj.out, "absenceModal");
          getTotalHrsBetweenTwoDate(dayjs(obj.TillDate).format("MM/DD/YYYY") + " " + obj.out, dayjs(obj.FromDate).format("MM/DD/YYYY")+ " " + obj.in, "absenceModal");
          // obj.date=GetFormattedDate(date);
          // date.setHours(0,0,0,0,1);
          // obj.in=date
          // date.setHours(0,0,0,0,1);
          // date.setHours(23,59,59,999)
          setModalPosition({ x: 0, y: 35 })
          setEntryAbsenceRequest(!entryAbsenceModal);
          setAbsenseData(obj);
          setErrors(null);
          // } else {
          //   swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
          // }

          // setTotalHourVal(hours);
        }}
        >Absence Request</a>,
        key: "1",
      },
    ];
    return (
      <div className="job-action-box">
        <AntDropDown
          style={{ color: "none" }}
          className=" action"
          menu={{
            items,
          }}
          arrow={{
            pointAtCenter: false,
          }}
          trigger={["click"]}
        >
          <Button
            className="Action-Btn-Ant"
            variant="outline-primary"
            style={{background:"#007bff",color:"white"}}
            onClick={(e) => e.preventDefault()}
          >
            Add Request
            {/* <FontAwesomeIcon
              className="Action-Btn-Ant-Dropdown"
              icon={faAngleDown}
            /> */}
          </Button>
        </AntDropDown>
      </div>
    );
  };

  const onChange = (name, value,breakModalflag) => {
    let requestObj = { ...requestData };
    if(breakModalflag){
      requestObj={...breakRequestData}
    } 
    // const requestObj = {};
    if(requestObj[name] == "date"){
      requestObj[name] = value;
      setShowDatePicker(false);
    }else{
      requestObj[name] = value;
    }
      // requestArr.push(requestObj);
    // }
    // requestObj["in"] = inTimevalue;
    // requestObj["out"] = outTimevalue;
    let err = validate.validateProperty(name, value, errors);
    setErrors(err);
    if(breakModalflag){
      setBreakRequestData(requestObj);
    }else{
      setRequestData(requestObj);
    } 
  };

  // const onDateChange = (time, timeString) => { 
  //   // setValue(time);
  //   const requestObj = { ...requestData };
  //   requestObj["in"] =  new Date(requestObj.date+", "+timeString);
  //   setRequestData(requestObj)
  //   setInTimeValue(time)
  // }
  // const onOutDateChange = (time, timeString) => { 
  //   // setValue(time);
  //   const requestObj = { ...requestData };
  //   requestObj["out"] = new Date(requestObj.date+", "+timeString);
  //   setRequestData(requestObj)
  //   setOutTimeValue(time)
  // }

  const schemaForAbsense = [
    { ptoCode: "PTO Code required" },
    { FromDate: "In date range from date required" },
    { TillDate: "In date range till date required" },
    // { in: "check in time required" },
    // { out: "check out time required" },
  ];
  // const ptoCode = ["Holiday", "Other", "Personal", "Sick", "Vacation", "Time Adjustment"];


  const onChangeAbsence = (name, value) => {  
    const absenseObj = { ...absenseData };
    absenseObj[name] = value;
    if (name == 'DateWise') {
      absenseObj['TimeWise'] = false;
    }
    if (name == 'TimeWise') {
      absenseObj['DateWise'] = false;
    }
    if (name == 'TillDate') {
      setShowOutDatePicker(false)
    }
    if (name == 'FromDate') {
      setShowInDatePicker(false)
    }
    let err = validate.validateProperty(name, value, errors);
    setErrors(err);
    setAbsenseData(absenseObj);
    if (name == "out") {
      getTotalHrsBetweenTwoDate(dayjs(absenseObj.TillDate).format("MM/DD/YYYY") + " " + absenseObj.out, dayjs(absenseObj.FromDate).format("MM/DD/YYYY")+ " " + absenseObj.in, "absenceModal");
    }
    if (name == "in") {
      getTotalHrsBetweenTwoDate(dayjs(absenseObj.TillDate).format("MM/DD/YYYY") + " " + absenseObj.out, dayjs(absenseObj.FromDate).format("MM/DD/YYYY")+ " " + absenseObj.in, "absenceModal");
    }

    if (name == 'ptoCode') {
      let code = ptoCode.filter(one => one.Name == value)
      if (code.length > 0) {
        setPtoCodeUnit(code[0].Unit)
      }
    }
  };

  const SetTime = () => {
    let hrs = new Date().getHours();
    hrs = hrs < 10 ? "0" + hrs : hrs;
    let mins = new Date().getMinutes();
    mins = mins < 10 ? "0" + mins : mins;
    return hrs + ":" + mins;
  };

  function GetFormattedDate(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return month + "/" + day + "/" + year;
  }
  const getTotalHrsBetweenTwoDate = (time1, time2, type) => {
    let dateTime = "";
    if (type === "absenceModal" && time1 && time2) {
      // let hour = 0;
      // let minute = 0;
      // //let second=0;

      // let splitTime1 = time1.split(":");
      // let splitTime2 = time2.split(":");

      // hour = parseInt(splitTime1[0]) - parseInt(splitTime2[0]);
      // minute = parseInt(splitTime2[1]) - parseInt(splitTime1[1]);
      // hour = hour + (minute < 60 ? 0 : minute / 60);
      // minute = minute % 60;

      // let total =
      //   Math.trunc(Math.abs(hour)) + ":" + Math.trunc(minute) + " hrs";
      const date1 = dayjs(time2);
      const date2 = dayjs(time1);
      // const total = date2.diff(date1, 'hour');
      // let totalHrs = dayjs(total).format("HH:MM") + " hrs"

      const differenceInMilliseconds = date2.diff(date1);

        // Convert milliseconds to hours and minutes
        const totalMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

            // Format the result as HH:MM
      const formattedDifference = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} hrs`;
      
      console.log("total", formattedDifference);

      setTotalHourVal(formattedDifference);
    }
    // else if( time1==="" && time2===""){
    //   setTotalHourVal("");
    // }

    if (time1 && time2) {
      time1 = new Date(time1);
      time2 = new Date(time2);
      // let timeDiff=new Date(Math.abs((time1 - time2)));
      let timeDiff = new Object();
      timeDiff.milliseconds = Math.abs(time1 - time2);
      timeDiff.seconds = Math.floor(timeDiff.milliseconds / 1000);
      timeDiff.minutes = Math.floor(timeDiff.seconds / 60);
      timeDiff.hours = Math.floor(timeDiff.minutes / 60);
      timeDiff.days = Math.floor(timeDiff.hours / 24);
      timeDiff.weeks = Math.floor(timeDiff.days / 7);
      if (timeDiff.seconds >= 60) {
        timeDiff.seconds = timeDiff.seconds % 60;
      }
      if (timeDiff.minutes >= 60) {
        timeDiff.minutes = timeDiff.minutes % 60;
      }

      dateTime = dateTime + (timeDiff.hours < 10 ? "0" : "") + timeDiff.hours;
      dateTime =
        dateTime + ":" + (timeDiff.minutes < 10 ? "0" : "") + timeDiff.minutes;
      // dateTime =
      // dateTime + ":" + (timeDiff.seconds < 10 ? "0" : "") + timeDiff.seconds;

      return dateTime + " Hrs";
    } else {
      return "NA";
    }
  };

  useEffect(e=>{ 
    if(cacheData?.length > 0){ 
      if(selectedUser){
        if(selectedUser.id == 'all'){
          setRowData(cacheData)
          setFilterData(cacheData)
        }else{
          let temData = cacheData?.filter(one => one.email == selectedUser.email);
          setRowData(temData)
          setFilterData(temData)
        }
      }
    }
  },[selectedUser, isStatusChanged, refreshEnable, cacheData])

  // useEffect(()=>{
  //     updateData();
  // }, [])

  // const getAllTimesheet = async () => {
  //   // let from = new Date();
  //   // from.setMonth(from.getMonth()-1)
  //   // //from.setHours(obj.in.getHours(), obj.in.getMinutes(), obj.in.getSeconds());
  //   // let till = new Date();
  //   // //till.setHours(obj.out.getHours(), obj.out.getMinutes(), obj.out.getSeconds());
  //   // from = dayjs(from).format("MM-DD-YYYY");
  //   // till = dayjs(till).format("MM-DD-YYYY");
  //   // TimeSheetService.GetAllTimeSheet(from, till, 'aditya@srsolardesign.com')
  //   //   .then((result) => {
  //   //     console.log(result.data);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   })
  //   let body = { EntryType: [1, 2] };
  //   let returnData = await HttpService.getByBoj("getrequestTime", body).then((res) => {
  //     console.log("see when admin not loggedin " + JSON.stringify(res.data));
  //     let arr = [];
  //     // let users = []
  //     // if (localStorage.getItem("Role") === "admin") {
  //     //     users.push("All Users")
  //     // }
  //     if (res.data != "unsuccesfull") {

  //       res.data.forEach((e, index) => {
  //         // users.push(e.userName);
  //         let columnsData = {
  //           _id: e._id,
  //           EntryType: e.EntryType,
  //           timeId: e.timeId,
  //           userName: e.userName,
  //           date: e.date,
  //           userId: e.userId,
  //           checkIn: e.in,
  //           checkOut: e.out,
  //           hours:
  //             e.in && e.out
  //               ? getTotalHrsBetweenTwoDate(e.in, e.out)
  //               : e.hours
  //                 ? e.hours
  //                 : "NA",
  //           status: e.status,
  //           note: e.note,
  //           ptoCode: e.ptoCode,
  //           isDelete: false,
  //           managerComm: e.managerComm,
  //         };
  //         arr.push(columnsData);
  //       });
  //     }
  //     let data = [];
  //     arr.forEach((one) => {
  //       if (requestFilter[parseInt(one.status)]) {
  //         data.push(one);
  //       }
  //     });
  //     setAllDate(res.data)
  //     setRowData(data);
  //     setcacheData(arr);

  //     //setAllUsers([...new Set(users)])
  //   });
  // };


  const getAllTimesheet = async (refreshFlag) => {
    // let from = new Date();
    // from.setMonth(from.getMonth()-1)
    // //from.setHours(obj.in.getHours(), obj.in.getMinutes(), obj.in.getSeconds());
    // let till = new Date();
    // //till.setHours(obj.out.getHours(), obj.out.getMinutes(), obj.out.getSeconds());
    // from = dayjs(from).format("MM-DD-YYYY");
    // till = dayjs(till).format("MM-DD-YYYY");
    // TimeSheetService.GetAllTimeSheet(from, till, 'aditya@srsolardesign.com')
    //   .then((result) => {
    //     console.log(result.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    let body = { EntryType: [1, 2] };
    // let returnData = await HttpService.getByBoj("getrequestTime", body).then((res) => {
    let returnData = await axios.post(Config.backend_url + "requestTime", {
      config: {
        Type: "Get"
      }
    }).then((res) => {
       
      // console.log("see when admin not loggedin " + JSON.stringify(res?.data?.data));
      let arr = [];
      let absentDatesarr=[]
      // let users = []
      // if (localStorage.getItem("Role") === "admin") {
      //     users.push("All Users")
      // }
      if (res?.data?.status == "success") {
        if (userInfo && userInfo?.role_details.role_name == 'App User') {
          res.data.data = res?.data?.data?.filter(one => one.RequestDataSchema.userId == userInfo?.email_id)
        }

        res.data.data.forEach((e, index) => {
          // users.push(e.userName);
          if(e.RequestDataSchema.status=="1" && e.RequestDataSchema.requestType=="Absent Request"){
            absentDatesarr.push({email:e.RequestDataSchema.userId,date:modifyTimezoneString(e.RequestDataSchema.TimesheetDate,"MM/DD/YYYY")})
          }
          let columnsData = {
            ...e.RequestDataSchema,
            _id: e.RequestDataSchema.ROWID,
            requestDate:modifyTimezoneString(e.RequestDataSchema.requestDate,"MM/DD/YYYY"),
            TimesheetDate:modifyTimezoneString(e.RequestDataSchema.TimesheetDate,"MM/DD/YYYY"),
            checkIn: e.RequestDataSchema.inTime=="-"?"-": getTimeInAMPMFormat(e.RequestDataSchema.inTime),
            checkOut: e.RequestDataSchema.outTime=="-"?"-":  getTimeInAMPMFormat(e.RequestDataSchema.outTime),
            rawcheckIn: e.RequestDataSchema.inTime=="-"?"-": e.RequestDataSchema.inTime,
            rawcheckOut: e.RequestDataSchema.outTime=="-"?"-": e.RequestDataSchema.outTime,
            email: e.RequestDataSchema.userId,
            isDelete: false,
          };
          arr.push(columnsData);
        });
         
        setAbsentDates(absentDatesarr?absentDatesarr:[])
      }
      let data = [];
      arr.forEach((one) => {
        if (requestFilter[parseInt(one.status)]) {
          data.push(one);
        }
      });
      data=data.sort((a,b)=>new Date(b.requestDate)-new Date(a.requestDate))
      setAllDate(data)
      // setDataObj(res.data)
      setRowData(data);
      setcacheData(data);
      setFilterData(data)
      //setAllUsers([...new Set(users)])
    }).finally(e=>{
      if(refreshFlag){
        dispatch(CustomeRefreshButton(false));
      }
    });
  };
  const deleteSelectedItems = (ROWID) => {
    // console.log("ROWID", ROWID);
    axios.delete(`${Config.backend_url}deleteRequestTimeDataById?ROWID=${ROWID}`)
      .then(async (res) => {
        await getAllTimesheet()
        swal("Success!", "Record deleted successfully!", "success");
      })
      .catch((e) => {
        swal("Error!", "Something went wrong!", "error");
      })

    // const deleteIds = [...deletedIds];
    // let doArr = [];
    // Promise.all(doArr)
    //   .then(async (result) => {
    //     // updateData();
    //     await getAllTimesheet()
    //     setDelPopup(false);
    //     setDeletedIds([]);
    //     swal("Success!", "Record deleted successfully!", "success");
    //   })
    //   .catch((err) => {
    //     alert(`${err}`);
    //   });
  };
  const getDatePickerValue = (date) => {
      try {
        let d = date
        if (d) {
          return dayjs(new Date(d));
        } else {
          return null;
        }
      } catch (error) { }
  };
  return (
    <div className="">
      {loaderState ? <Loader /> : <></>}
      {openEmployeeNotePopup()}
      {/* {ShowAlert(alertMessage, showAlert)} */}
      <Draggable
        defaultPosition={modalPosition} position={{ x: modalPosition.x, y: modalPosition.y }} onStop={handleDrag}
      >
        <Modal
          size="md"
          isOpen={managerCommentModal}
          toggle={managerCommentModal}
        >
          <ModalHeader>Manager Comment Entry</ModalHeader>
          <ModalBody>
            <Row className="mt-2">
              <Col sm={12}>
                <label>Comment:</label>
                <textarea
                  value={requestData ? requestData.managerComm : ""}
                  onChange={(e) => onChange("managerComm", e.currentTarget.value)}
                  style={{ width: "100%" , borderRadius: "5px"}}
                  rows="3"
                  name="note"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => setManagerCommentModal(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() =>
                updateRequestAndInsertTime(updateRequestData, updateRequestStatus)
              }
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
      {/* Add Request Entry Modal */}

      <AntModal
        open={entryReqModal}
        draggable
        title={editFlag ? "Edit Working Entry" : "Add Working Entry"}
        maskClosable={false}
        width={300}
        className="Add-request-modal"
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setEditFlag(false)
          setEntryReqModal(false);
          dispatch(addTimeSheetClicked({module : "", button : ""}))
        }}
        modalRender={(modal) => (
          <Draggable
            defaultPosition={modalPosition}
            position={{ x: modalPosition.x, y: modalPosition.y }}
            onStop={handleDrag}
          >
            <div>{modal}</div>
          </Draggable>
        )}
        footer={[
          <div
            className="col-sm-12"
          >
            <Button
              style={{ display: "inline", marginRight: "0.4rem" }}
              onClick={() => {
                setEntryReqModal(false)
                setEditFlag(false)
                dispatch(addTimeSheetClicked({module : "", button : ""}))
              }}
              key="Cancel"
              type="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => onSave(requestData)}
              key="Save"
              type="primary"
              color="primary"
            >
              {editFlag ? "Update" : "Create"}
            </Button>
          </div>,
        ]}
      >
        <Row className="m-1">
          <Col sm={2}>
            <label>Date:</label>
          </Col>
          <Col sm={10}>
            {/* <DatePicker
          selected={requestData ? requestData.date ? new Date(requestData.date) : "" : ""}
          dateFormat="MM/dd/yyyy"
          placeholderText="mm/dd/yyyy"
          onChange={(date) => onChange("date", new Date(date))}
          popperProps={{
            popperPlacement: "top-end",
            positionFixed: true, // use this to make the popper position: fixed
          }}
        /> */}
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(requestData?.date)}
              disabledDate={disabledDateLocal}              
              placeholderText="Select a date"
              popperClassName="ag-custom-component-popup"
              className="form-control form-control-sm"
              onChange={(date) => onChangeTime("date", new Date(date))}
              open={showDatePicker}
              onOpenChange={open => setShowDatePicker(open)}
            // popperProps={{
            //   popperPlacement: "top-end",
            //   positionFixed: true, // use this to make the popper position: fixed
            // }}
            />
            {errors ? (
              errors.date ? (
                <p className="text-danger small">{errors.date}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
        </Row>

        {/* <Row>
      <Col sm={1}>
        <label>From Date:</label>
      </Col>
      <Col sm={10}>
        <DatePicker
          selected={requestData ? new Date(requestData.From) : new Date()}
          dateFormat="MM/dd/yyyy"
          placeholderText="mm/dd/yyyy"
          onChange={(date) => onChange("From", new Date(date))}
          popperProps={{
            popperPlacement: "top-end",
            positionFixed: true, // use this to make the popper position: fixed
          }}
        />
        {errors ? (
          errors.From ? (
            <p className="text-danger small">{errors.From}</p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Col>
    </Row> */}
        {/* <Row>
      <Col sm={1}>
        <label>To Date:</label>
      </Col>
      <Col sm={10}>
        <DatePicker
          selected={requestData ? new Date(requestData.Till) : new Date()}
          dateFormat="MM/dd/yyyy"
          placeholderText="mm/dd/yyyy"
          onChange={(date) => onChange("Till", new Date(date))}
          popperProps={{
            popperPlacement: "top-end",
            positionFixed: true, // use this to make the popper position: fixed
          }}
        />
        {errors ? (
          errors.Till ? (
            <p className="text-danger small">{errors.Till}</p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Col>
    </Row> */}

        <Row className="m-1">
          <Col sm={2}>
            <label>Time:</label>
          </Col>
          <Col sm={5}>
            <input
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a"
              style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
              // clearIcon={null}
              // disableClock={true}
              value={requestData ? convertTime12to24(requestData.in) : ""}
              onChange={(e) => onChangeTime("in", e.currentTarget.value)}
            />
            {/* <TimePicker
              use12Hours
              format="h:mm A"
              // onChange={(e) => onChange("in", e)}
              onChange={onDateChange}
              value={inTimevalue}
              /> */}
            {errors ? (
              errors.in ? (
                <p className="text-danger small">{errors.in}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
          <Col sm={5}>
            <input
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a"
              style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
              // clearIcon={null}
              // disableClock={true}
              onChange={(e) => onChangeTime("out", e.currentTarget.value)}
              value={requestData ? convertTime12to24(requestData.out) : ""}
            />
            {/* <TimePicker
              use12Hours
              format="h:mm A"
              value={outTimevalue}
              onChange={onOutDateChange}
              /> */}
            {errors ? (
              errors.out ? (
                <p className="text-danger small">{errors.out}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="m-1">
          <Col sm={2}>
            <label>Note:</label>
          </Col>
          <Col sm={10}>
            <textarea
              value={requestData ? requestData.note : ""}
              onChange={(e) => onChange("note", e.currentTarget.value)}
              style={{ width: "100%" }}
              rows="3"
              name="note"
            />
          </Col>
        </Row>
      </AntModal>
      {/* Add Absence Modal */}
      {/* Add Break Entries Modal */}
      {/* <AntModal
        open={breakReqModal}
        draggable
        title={"Missing Break Request"}
        maskClosable={false}
        width={300}
        className="Add-request-modal"
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setBreakReqModal(false);
        }}
        modalRender={(modal) => (
          <Draggable
            defaultPosition={modalPosition}
            position={{ x: modalPosition.x, y: modalPosition.y }}
            onStop={handleDrag}
          >
            <div>{modal}</div>
          </Draggable>
        )}
        footer={[
          <div
            className="col-sm-12"
          >
            <Button
              style={{ display: "inline", marginRight: "0.4rem" }}
              onClick={() => {
                setBreakReqModal(false)
                
              }}
              key="Cancel"
              type="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => onSaveBreakEntry(breakRequestData)}
              key="Save"
              type="primary"
              color="primary"
            >
              Create
            </Button>
          </div>,
        ]}
      >
        <Row className="m-1">
          <Col sm={2}>
            <label>Date:</label>
          </Col>
          <Col sm={10}>
           
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(breakRequestData?.date)}
              disabledDate={disabledDateLocal}              
              placeholderText="Select a date"
              popperClassName="ag-custom-component-popup"
              className="form-control form-control-sm"
              onChange={(date) => onChangeTime("date", new Date(date),true)}
              open={showDatePicker}
              onOpenChange={open => setShowDatePicker(open)}
           
            />
            {errors ? (
              errors.date ? (
                <p className="text-danger small">{errors.date}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row className="m-1">
          <Col sm={2}>
            <label>Time:</label>
          </Col>
          <Col sm={5}>
            <input
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a"
              style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
              // clearIcon={null}
              // disableClock={true}
              value={breakRequestData ? convertTime12to24(breakRequestData.in) : ""}
              onChange={(e) => onChangeTime("in", e.currentTarget.value,true)}
            />
            {errors ? (
              errors.in ? (
                <p className="text-danger small">{errors.in}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
          <Col sm={5}>
            <input
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a"
              style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
              // clearIcon={null}
              // disableClock={true}
              onChange={(e) => onChangeTime("out", e.currentTarget.value,true)}
              value={breakRequestData ? convertTime12to24(breakRequestData.out) : ""}
            />
            {errors ? (
              errors.out ? (
                <p className="text-danger small">{errors.out}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="m-1">
          <Col sm={2}>
            <label>Note:</label>
          </Col>
          <Col sm={10}>
            <textarea
              value={breakRequestData ? breakRequestData.note : ""}
              onChange={(e) => onChange("note", e.currentTarget.value,true)}
              style={{ width: "100%" }}
              rows="3"
              name="note"
            />
          </Col>
        </Row>
      </AntModal> */}
      {/* Add Break Entries Modal */}

      <AntModal
        open={entryAbsenceModal}
        draggable
        title={editFlag ? "Edit Absence Request" : "Add Absence Request"}
        maskClosable={false}
        width={300}
        className="Add-absence-modal"
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setEditFlag(false)
          setEntryAbsenceRequest(false)
          setPartialAbsenseData({...absenceObjTemp})
          dispatch(addTimeSheetClicked({module : "", button : ""}))
        }}
        modalRender={(modal) => (
          <Draggable
            position={{ x: modalPosition.x, y: modalPosition.y }}
            onStop={handleDrag}
          >
            <div>{modal}</div>
          </Draggable>
        )}
        footer={[
          <div
            className="col-sm-12"
          >
            <small style={{color:'red',position:"absolute",left:'1rem'}}>{showWarnFooterForAbsenceModal?'Please Fill All The Required Data':''}</small>
            <Button
              style={{ display: "inline", marginRight: "0.4rem" }}
              onClick={() => {
                setEditFlag(false)
                setPartialAbsenseData({...absenceObjTemp})
                setEntryAbsenceRequest(false);
                dispatch(addTimeSheetClicked({module : "", button : ""}))
              }}
              key="Cancel"
              type="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleOnsaveAbsenseData(partialAbsenseData)}
              key="Create"
              type="primary"
              color="primary"
            >
              {editFlag ? "Update" : "Create"}
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col sm={3}>
            <label>Leave Type:</label>
          </Col>
          <Col sm={9}> 
              <label  style={{padding: ".5rem",
                  marginleft: "10px"}}>
                <input
                 style={{    marginRight: ".5rem"}}
                  type="radio"
                  name="dayType"
                  value="Full Day"
                  checked={partialAbsenseData?.requestType === 'Full Day'}
                  onChange={(e)=>{
                    // handleOptionChange(e)
                    setValidation(tempValidation)
                    setPartialAbsenseData({...absenceObjTemp,
                      "requestType":e.target.value});
                  }}
                />
                Full Day
              </label>
              <label  style={{padding: ".5rem",
                  marginleft: "10px"}}>
                <input
                 style={{ marginRight: ".7rem"}}
                  type="radio"
                  name="dayType"
                  value="Partial Day"
                  checked={partialAbsenseData.requestType === 'Partial Day'}
                  onChange={(e)=>{
                    // handleOptionChange(e)
                    setValidation(tempValidation)
                    setPartialAbsenseData({...absenceObjTemp,
                      "requestType":e.target.value});
                  }}
                />
                Partial Day
              </label>
              </Col>
              </Row>
              <Row>
               
              <Col sm={3}>
            <label>Reason:</label>
          </Col>
          <Col sm={9}>
              <Select
                className={
                  validation.reason == "-1"
                    ? "addclss"
                    : ""
                }
                value={partialAbsenseData.reason? { label: partialAbsenseData.reason, value: partialAbsenseData.reason } : null}
                options={partialAbsenseData?.requestType=="Full Day"?fullDayOptions:halfDayOptions}
                closeMenuOnSelect={true}  
                onChange={(e) => { 
                  // setLeaveDayData({label: e.label, value: e.value})
                  let temp={...absenceObjTemp,requestType:partialAbsenseData.requestType}
                  setPartialAbsenseData({...temp,"reason":e.value});
                }} 
                menuPlacement="auto"
              />
              </Col> 
            <Col>
            {errors ? (
              errors.ptoCode ? (
                <p className="text-danger small">{errors.ptoCode}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
        </Row>

        {partialAbsenseData?.requestType=="Full Day"?<Row className="mt-2">
          <Col sm={3}>
            <label>Date:</label>
          </Col>
           <Col sm={9}>
            <Row>
          <Col sm={6}> 
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(partialAbsenseData.FromDate)}
              placeholderText="Select a date"
              // disabledDate={disabledDateLocal}
              popperClassName="ag-custom-component-popup"
              className={`form-control form-control-sm ${
                validation.FromDate == "-1"
                  ? "addclss"
                  : ""
              }`}
              onChange={(date) => {  
                  if(date==null){
                    setValidation({...validation,'FromDate':'-1'})
                  }else{
                    setValidation({...validation,'FromDate':'1'})
                  }
                setPartialAbsenseData({...partialAbsenseData,"FromDate":new Date(date)});
                // onChangeAbsence("FromDate", new Date(date))
              }}
              open={showInDatePicker}
              onOpenChange={open => setShowInDatePicker(open)} 
            />
            {errors ? (
              errors.FromDate ? (
                <p className="text-danger small">{errors.FromDate}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
          <Col sm={6}> 
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(partialAbsenseData.TillDate)}
              placeholderText="Select a date"
              popperClassName="ag-custom-component-popup"
              // disabledDate={disabledDateLocal}
              className={`form-control form-control-sm ${
                validation.TillDate == "-1"
                  ? "addclss"
                  : ""
              }`}
              onChange={(date) => { 
                  if(date==null){
                    setValidation({...validation,'TillDate':'-1'})
                  }else{
                    setValidation({...validation,'TillDate':'1'})
                  } 
                setPartialAbsenseData({...partialAbsenseData,"TillDate":new Date(date)});
                // onChangeAbsence("TillDate", new Date(date))
              }}
              open={showOutDatePicker}
              onOpenChange={open => setShowOutDatePicker(open)} 
            />
            {errors ? (
              errors.TillDate ? (
                <p className="text-danger small">{errors.TillDate}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
            </Row>
          </Col>
        </Row>:<><Row className="mt-2">
          <Col sm={3}>
            <label>Date:</label>
          </Col> 
          <Col sm={9}> 
            <AntdDatepicker
              disabledDate={partialAbsenseData.reason == "Midday" ? disabledDateLocalMidDay : ""}
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(partialAbsenseData.Date)}
              placeholderText="Select a date"
              popperClassName="ag-custom-component-popup" 
              className={`form-control form-control-sm ${
                validation.Date == "-1"
                  ? "addclss"
                  : ""
              }`}
              onChange={(date) =>{
                  if(date==null){
                    setValidation({...validation,'Date':'-1'})
                  }else{
                    setValidation({...validation,'Date':'1'})
                  } 
                setPartialAbsenseData({...partialAbsenseData,"Date": new Date(date)})}}
              open={showOutDatePicker}
              onOpenChange={open => setShowOutDatePicker(open)} 
            /> 
          </Col>
        </Row>
        <Row style={{marginTop:'1em'}}>
          <Col sm={3}>
          <label>In Time : </label>
          </Col>
          <Col sm={5}>
        <input
              disabled={(partialAbsenseData?.reason=="Early Leave")}
              style={validation.in=='-1'?{...validationCss}:{}}
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a" 
              value={partialAbsenseData?.reason=="Early Leave"?shiftData[0]?.shiftStartTime:partialAbsenseData.in ? convertTime12to24(partialAbsenseData.in) : "00:00"}
              onBlur={(e)=>{
                if(e.currentTarget.value==''){
                  setValidation({...validation,'in':'-1'})
                }else{
                  setValidation({...validation,'in':'1'})
                }
              }}
              onChange={(e) => {
                 let dateTime = new Date(partialAbsenseData.Date); 
                 let time = e.currentTarget.value.split(':')
                  dateTime.setHours(parseInt(time[0]), parseInt(time[1]))
                  setPartialAbsenseData({...partialAbsenseData,"in":dateTime})
                // onChangeTime("in", e.currentTarget.value,undefined,"partial")
              }}
            />
            </Col>
        </Row>
        <Row style={{marginTop:'1em'}}>
        <Col sm={3}>
          <label>Out Time : </label>
          </Col>
          <Col sm={5}>
        <input
        disabled={partialAbsenseData?.reason=="Late Arrival"}
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a"
              style={validation.out=='-1'?{...validationCss}:{}}
              // clearIcon={null}
              // disableClock={true}
              onBlur={(e)=>{
                if(e.currentTarget.value==''){
                  setValidation({...validation,'out':'-1'})
                }else{
                  setValidation({...validation,'out':'1'})
                }
              }}
              onChange={(e) =>{
                let dateTime = new Date(partialAbsenseData.Date); 
                let time = e.currentTarget.value.split(':')
                 dateTime.setHours(parseInt(time[0]), parseInt(time[1]))
                 setPartialAbsenseData({...partialAbsenseData,"out":dateTime})
                 onChangeTime("out", e.currentTarget.value,undefined,"partial")
                }}
                value={partialAbsenseData?.reason=="Late Arrival"?shiftData[0]?.shiftEndTime:partialAbsenseData.out ? convertTime12to24(partialAbsenseData.out) : "00:00"}
            />
            </Col>
        </Row>
        </>}
         <Row className="mt-2">
            <Col sm={3}>
            </Col>
            <Col sm={9}>
            </Col>
          </Row>
        
        <Row className="mt-2">
          <Col sm={3}>
            <label>Note:</label>
          </Col>
          <Col sm={9}>
            <textarea
              value={
                partialAbsenseData.note ? (partialAbsenseData.note ? partialAbsenseData.note : "") : ""
              }
              onChange={(e) =>{
                setPartialAbsenseData({...partialAbsenseData,"note":e.currentTarget.value});
                // onChangeAbsence("note", e.currentTarget.value)
                // console.log("partialAbsenseData : ",partialAbsenseData);
                }}
              style={{ width: "100%" }}
              rows="3"
              name="note"
            />
          </Col>
        </Row>
        </AntModal>

      <AntModal
        open={false}
        draggable
        title={editFlag ? "Edit Absence Request" : "Add Absence Request"}
        maskClosable={false}
        width={300}
        className="Add-absence-modal"
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setEditFlag(false)
          setEntryAbsenceRequest(false)
      dispatch(addTimeSheetClicked({module : "", button : ""}))
        }}
        modalRender={(modal) => (
          <Draggable
            position={{ x: modalPosition.x, y: modalPosition.y }}
            onStop={handleDrag}
          >
            <div>{modal}</div>
          </Draggable>
        )}
        footer={[
          <div
            className="col-sm-12"
          >
            <Button
              style={{ display: "inline", marginRight: "0.4rem" }}
              onClick={() => {
                setEditFlag(false)
                setEntryAbsenceRequest(false);
                dispatch(addTimeSheetClicked({module : "", button : ""}))
              }}
              key="Cancel"
              type="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => onCreateAbsenseData(absenseData)}
              key="Create"
              type="primary"
              color="primary"
            >
              {editFlag ? "Update" : "Create"}
            </Button>
          </div>,
        ]}
      >
        <Row>
          <Col sm={3}>
            <label>Leave Type:</label>
          </Col>
          <Col sm={9}>
            {/* <select
          className="form form-control form-control-sm"
          value={
            absenseData
              ? absenseData.ptoCode !== undefined
                ? absenseData.ptoCode
                : ""
              : ""
          }
          onChange={(e) =>
            onChangeAbsence("ptoCode", e.currentTarget.value)
          }
        >
          <option>---select leave type---</option>
          {ptoCode&&ptoCode.length > 0 ? ptoCode.map((code, index) => {
            return <option value={code.Name}>{code.Name}</option>;
          }) : ""}
        </select> */}
              {/* <Select
                value={absenseData && absenseData.Day != undefined ? { label: absenseData.Day, value: absenseData.Day } : null}
                options={dayOptions}
                closeMenuOnSelect={true}
                onChange={(e) => {
                  onChangeAbsence('Day', e.value)
                }}
                // value={selectedUserEmail ? selectedUserEmail :""}
                menuPlacement="auto"
              /> */}
              <label  style={{padding: ".5rem",
                  marginleft: "10px"}}>
                <input
                 style={{    marginRight: ".5rem"}}
                  type="radio"
                  name="dayType"
                  value="fullDay"
                  checked={selectedOption === 'fullDay'}
                  onChange={handleOptionChange}
                />
                Full Day
              </label>
              <label  style={{padding: ".5rem",
                  marginleft: "10px"}}>
                <input
                 style={{    marginRight: ".7rem"}}
                  type="radio"
                  name="dayType"
                  value="partialDay"
                  checked={selectedOption === 'partialDay'}
                  onChange={handleOptionChange}
                />
                Partial Day
              </label>
              </Col>
              </Row>
              <Row>
               
              <Col sm={3}>
            <label>Reason:</label>
          </Col>
          <Col sm={9}>
              <Select
                value={LeaveDayData.value!==''? { label: LeaveDayData.label, value: LeaveDayData.value } : null}
                options={selectedOption=="fullDay"?fullDayOptions:halfDayOptions}
                closeMenuOnSelect={true}  
                onChange={(e) => { 
                  setLeaveDayData({label:e.label,value:e.value,leaveType:selectedOption}) 
                }} 
                menuPlacement="auto"
              />
              </Col>
            {/* <Select
              value={absenseData && absenseData?.ptoCode != undefined ? { label: absenseData.ptoCode, value: absenseData.ptoCode } : null}
              options={ptoCodeOptions}
              // isClearable={true} // Allows clearing the selection
              onChange={(selectedOption) => {
                onChangeAbsence("ptoCode", selectedOption ? selectedOption.value : null)
              }}
              menuPlacement="auto"
            /> */}
            <Col>
            {errors ? (
              errors.ptoCode ? (
                <p className="text-danger small">{errors.ptoCode}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
        </Row>

        {selectedOption==="fullDay"?<Row className="mt-2">
          <Col sm={3}>
            <label>Date:</label>
          </Col>
           <Col sm={9}>
            <Row>
          <Col sm={6}>
            {/* <DatePicker
          selected={
            absenseData
              ? absenseData.FromDate
                ? new Date(absenseData.FromDate)
                : ""
              : ""
          }
          dateFormat="MM/dd/yyyy"
          placeholderText="mm/dd/yyyy"
          onChange={(date) => onChangeAbsence("FromDate", new Date(date))}
          popperProps={{
            popperPlacement: "top-end",
            positionFixed: true, // use this to make the popper position: fixed
          }}
        /> */}
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(absenseData.FromDate)}
              placeholderText="Select a date"
              // disabledDate={disabledDateLocal}
              popperClassName="ag-custom-component-popup"
              className="form-control form-control-sm"
              onChange={(date) => { ; onChangeAbsence("FromDate", new Date(date))}}
              open={showInDatePicker}
              onOpenChange={open => setShowInDatePicker(open)}
            // popperProps={{
            //   popperPlacement: "top-end",
            //   positionFixed: true, // use this to make the popper position: fixed
            // }}
            />
            {errors ? (
              errors.FromDate ? (
                <p className="text-danger small">{errors.FromDate}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
          <Col sm={6}>
            {/* <DatePicker
          selected={
            absenseData
              ? absenseData.TillDate
                ? new Date(absenseData.TillDate)
                : ""
              : ""
          }
          dateFormat="MM/dd/yyyy"
          placeholderText="mm/dd/yyyy"
          onChange={(date) => onChangeAbsence("TillDate", new Date(date))}
          popperProps={{
            popperPlacement: "top-end",
            positionFixed: true, // use this to make the popper position: fixed
          }}
        /> */}
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(absenseData.TillDate)}
              placeholderText="Select a date"
              popperClassName="ag-custom-component-popup"
              // disabledDate={disabledDateLocal}
              className="form-control form-control-sm"
              onChange={(date) => onChangeAbsence("TillDate", new Date(date))}
              open={showOutDatePicker}
              onOpenChange={open => setShowOutDatePicker(open)}
            // popperProps={{
            //   popperPlacement: "top-end",
            //   positionFixed: true, // use this to make the popper position: fixed
            // }}
            />
            {errors ? (
              errors.TillDate ? (
                <p className="text-danger small">{errors.TillDate}</p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
            </Row>
          </Col>
        </Row>:<><Row className="mt-2">
          <Col sm={3}>
            <label>Date:</label>
          </Col> 
          <Col sm={9}> 
            <AntdDatepicker
              allowClear
              portalId="root"
              format="MM/DD/YYYY"
              value={getDatePickerValue(partialAbsenseData.Date)}
              placeholderText="Select a date"
              popperClassName="ag-custom-component-popup" 
              className="form-control form-control-sm"
              onChange={(date) => setPartialAbsenseData({...partialAbsenseData,"Date": new Date(date)})}
              open={showOutDatePicker}
              onOpenChange={open => setShowOutDatePicker(open)} 
            /> 
          </Col>
        </Row>
        {(LeaveDayData.value=="Early Leave" ||LeaveDayData.value=="Midday")&& <Row style={{marginTop:'1em'}}>
          <Col sm={3}>
          <label>In Time : </label>
          </Col>
          <Col sm={5}>
        <input
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a"
              // style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
              // clearIcon={null}
              // disableClock={true}
              value={requestData ? convertTime12to24(requestData.in) : ""}
              onChange={(e) => onChangeTime("in", e.currentTarget.value,undefined,"partial")}
            />
            </Col>
        </Row>}
        {(LeaveDayData.value=="Late Arrival"||LeaveDayData.value=="Midday") && 
        <Row style={{marginTop:'1em'}}>
        <Col sm={3}>
          <label>Out Time : </label>
          </Col>
          <Col sm={5}>
        <input
              type="time"
              className="form-control form-control-sm h-100"
              format="hh:mm a"
              // style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
              // clearIcon={null}
              // disableClock={true}
              onChange={(e) => onChangeTime("out", e.currentTarget.value,undefined,"partial")}
              value={requestData ? convertTime12to24(requestData.out) : ""}
            />
            </Col>
        </Row>}
        </>}

        {/* <Row>
      <Col sm={2}>
      </Col>
      <Col sm={4}>
        <input
          name="TimeType"
          type="radio"
          checked={absenseData.TimeWise}
          onChange={(e) => onChangeAbsence("TimeWise", e.currentTarget.checked)}
        />&nbsp;&nbsp;
        <label>Start Or End Time</label>
      </Col>

      <Col sm={4}>
        <input
          name="TimeType"
          type="radio"
          checked={absenseData.DateWise}
          onChange={(e) => onChangeAbsence("DateWise", e.currentTarget.checked)}
        />&nbsp;&nbsp;
        <label>Start Or End Date</label>
      </Col>

    </Row> */}

        {
        // ptoCodeUnit == 'Hours' ?
        //   <><Row className="mt-2">
        //     <Col sm={2}>
        //       <label>Time</label>
        //     </Col>
        //     <Col sm={5}>
        //       <input format="hh:mm a"
        //         type="time"
        //         className="form-control form-control-sm height24"
        //         clearIcon={null}
        //         defaultTime="12:59"
        //         disableClock={true}
        //         onChange={(e) => onChangeAbsence("in", e.currentTarget.value)}
        //         value={absenseData ? absenseData.in : ""}
        //       />
        //       {errors ? (
        //         errors.in ? (
        //           <p className="text-danger small">{errors.in}</p>
        //         ) : (
        //           ""
        //         )
        //       ) : (
        //         ""
        //       )}
        //     </Col>
        //     <Col sm={5}>
        //       <input format="hh:mm a"
        //         type="time"
        //         className="form-control form-control-sm height24"
        //         clearIcon={null}
        //         disableClock={true}
        //         defaultTime="1:00"
        //         onChange={(e) => onChangeAbsence("out", e.currentTarget.value)}
        //         value={absenseData ? absenseData.out : ""}
        //       // onKeyDown={(e) =>
        //       //    countTotalHours()
        //       //  }
        //       />
        //       {errors ? (
        //         errors.out ? (
        //           <p className="text-danger small">{errors.out}</p>
        //         ) : (
        //           ""
        //         )
        //       ) : (
        //         ""
        //       )}
        //     </Col>
        //   </Row>

        //     <Row className="mt-2">
        //       <Col sm={2}>
        //         <label>Hours:</label>
        //       </Col>
        //       <Col sm={10}>
        //         <input

        //           className="form form-control form-control-sm"
        //           type="text"
        //           clearIcon={null}
        //           disableClock={true}
        //           // onChange={(e) =>
        //           //   onChangeAbsence("hours", e.currentTarget.value)
        //           // }
        //           // value={
        //           //   absenseData
        //           //     ? absenseData.hours
        //           //       ? absenseData.hours
        //           //       : ""
        //           //     : ""
        //           // }
        //           value={totalHourVal}
        //         />
        //       </Col>
        //     </Row></>
        //   :
         <Row className="mt-2">
            <Col sm={3}>
              {/* <label>Note:</label> */}
            </Col>
            <Col sm={9}>
              {/* <select name="Day" onChange={(e) => onChangeAbsence('Day', e.currentTarget.value)} className="form-control form-control-sm">
            <option value="1.0">full day</option>
            <option value="0.5">half day</option>
            <option value="0.25">quarter day</option>
          </select> */}
              {/* <Select
                value={absenseData && absenseData.Day != undefined ? { label: absenseData.Day, value: absenseData.Day } : null}
                options={dayOptions}
                closeMenuOnSelect={true}
                onChange={(e) => {
                  onChangeAbsence('Day', e.value)
                }}
                // value={selectedUserEmail ? selectedUserEmail :""}
                menuPlacement="auto"
              /> */}
            </Col>
          </Row>
        }
        <Row className="mt-2">
          <Col sm={3}>
            <label>Note:</label>
          </Col>
          <Col sm={9}>
            <textarea
              value={
                absenseData ? (absenseData.note ? absenseData.note : "") : ""
              }
              onChange={(e) => onChangeAbsence("note", e.currentTarget.value)}
              style={{ width: "100%" }}
              rows="3"
              name="note"
            />
          </Col>
        </Row>
        </AntModal>

      <Row className="border-bottom" style={{ justifyContent: "end", height: "56px", alignItems: "center" }}>
        {/* <Col sm={3}></Col> */}
        {/* <Col sm={4} style={{ display: "flex", justifyContent: "center", height: "2rem" }} className="hr-request-data-range-picker float-left ml-5">
          <AntdRangePicker onChangeDateRange={onChangeDateRange} defaultStartDate={startDate} defaultEndDate={endDate} allEnd={allEnd} allStart={allStart} />
        </Col> */}
        <Col sm={4}></Col>
        <Col sm={4} style={{ display: "flex", justifyContent: "center", height: "52px", alignItems: "center" }} className="hrRequestBtns">
          {/* <Dropdown className="request-btns">
            <Dropdown.Toggle >
              <FontAwesomeIcon icon={faFilter} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <ListGroupItem
                id={'all'}
                style={{ height: '5vh' }}
              //  style={i==0 ? {backgroundColor:'blue'} : i==1 ? {backgroundColor:'green'} : i==2 ?{backgroundColor:'red'}:''}
              // onClick={(e) =>
              //   fetchDataOnBasisOfStatus()
              // }
              >
                <input
                  type="checkbox"
                  checked={requestFilter[0] && requestFilter[1] && requestFilter[2] ? true : false}
                  onChange={(e) => {
                    const filterData = { ...requestFilter };
                    filterData[0] = e.currentTarget.checked;
                    filterData[1] = e.currentTarget.checked;
                    filterData[2] = e.currentTarget.checked;
                    let data = [];
                    cacheData.forEach((one) => {
                      if (filterData[parseInt(one.status)]) {
                        data.push(one);
                      }
                    });
                    setRequestFilterData(filterData);
                    setRowData(data);
                  }}
                />
                <span className="pl-2">Select All</span>
              </ListGroupItem>
              <Dropdown.Divider style={{ borderTop: '1.5px solid lightgray' }} className="p-0 m-0" />
              {status.map((value, i) => {
                // if (i == 0) {
                //   return;
                // }
                return (

                  <ListGroupItem
                    style={{ height: '5vh' }}
                    id={i}
                  //  style={i==0 ? {backgroundColor:'blue'} : i==1 ? {backgroundColor:'green'} : i==2 ?{backgroundColor:'red'}:''}
                  // onClick={(e) =>
                  //   fetchDataOnBasisOfStatus()
                  // }
                  >
                    <input
                      type="checkbox"
                      checked={requestFilter[i]}
                      onChange={(e) => {
                        const filterData = { ...requestFilter };
                        filterData[i] = e.currentTarget.checked;
                        let data = [];
                        cacheData.forEach((one) => {
                          if (filterData[parseInt(one.status)]) {
                            data.push(one);
                          }
                        });
                        setRequestFilterData(filterData);
                        setRowData(data);
                      }}
                    />
                    <span className="pl-2">{value}</span>
                  </ListGroupItem>
                );
              })}
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Button
            className="ml-3 request-btns"
            onClick={() => {
              let date;
               
              // let obj = { From: new Date(), Till: new Date(), date: GetFormattedDate(new Date())}
              date = new Date(dayjs(new Date()).subtract(1,"day").format("MM/DD/YYYY"));
              const editObj = { ...requestData};
              editObj.date = date;
              let inTime = new Date(date);
              let out = new Date(date);
              inTime.setHours(9, 0, 0, 0);
              out.setHours(17, 0, 0, 0);
              let obj = { date: date, in: inTime, out: out };
              setEntryReqModal(!entryReqModal);
              setModalPosition({ x: 0, y: -45 })
              setRequestData(obj);
              setErrors(null);
            }}
          >
            Add Entry Request
          </Button>

          <Button
            className="ml-3 request-btns"
            onClick={() => {
              // if (isAccess && isAccess(selectedRole, 'Request', 'Write')) {
              let date = new Date();
              let obj = { in: "00:00", out: "23:59", DateWise: true, FromDate : new Date(), TillDate: new Date() };
              getTotalHrsBetweenTwoDate(obj.in, obj.out, "absenceModal");
              // obj.date=GetFormattedDate(date);
              // date.setHours(0,0,0,0,1);
              // obj.in=date
              // date.setHours(0,0,0,0,1);
              // date.setHours(23,59,59,999)
              setModalPosition({ x: 0, y: -45 })
              setEntryAbsenceRequest(!entryAbsenceModal);
              setAbsenseData(obj);
              setErrors(null);
              // } else {
              //   swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
              // }

              // setTotalHourVal(hours);
            }}
          >
            Add Absence Request
          </Button> */}
          {/* <Select
               value={selectedUser ? {label : selectedUser.full_name, value: selectedUser.id} : {label : 'All Users', value: 'all'}}
              options={userList}
              className="select-user"
              closeMenuOnSelect={true}
              onChange={(e) => { 
                if (e.value == 'all') {
                  onChangeUserFilter({ full_name: e.label , id : e.value});
                } else { 
                  let filterUser = allusers.filter(one =>  one.id == e.value);
                  if (filterUser.length > 0) {
                    onChangeUserFilter(filterUser[0]);
                  }
                }
              }}
              // value={selectedUserEmail ? selectedUserEmail :""}
              menuPlacement="auto"
            /> */}
          {/* <Button
            className="ml-3 request-btns"
            // onClick={deleteSelectedItems}
            onClick={() => { 
              // setDelPopup(!delPopup)
              console.log(`${Config.backend_url}deleteRequestTimeDataById?ROWID=14952000000862032`);
              axios.post(`${Config.backend_url}deleteRequestTimeDataById?ROWID=14952000000862032`)
              .then((res)=>{console.log(res);}).catch((e) => {console.log(e);})
            }}
          >
            Delete
          </Button> */}
          <div>
            <Modal
              className="exportmodal-width"
              isOpen={delPopup}
              toggle={delPopup}
            >
              <ModalHeader>Delete Entry</ModalHeader>
              <ModalBody>
                <span className="text-bold" for="l2code">
                  Are you sure to delete this entry?
                </span>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => {
                    // deleteSelectedItems();
                    setDelPopup(false);
                  }}
                >

                  Delete
                </Button>
                <Button color="secondary" onClick={() => setDelPopup(!delPopup)}>
                  {" "}
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Col>
        <Col sm={4}></Col>
      </Row>
      <div className="hr-time-table">
        <div className="ag-theme-alpine" style={{ height: "100%" }}>
          {/* <Table
            className="request-data-table"
            id="request"
            // data={requestList}
            sortColumn={{ path: "", order: "asc" }}
            data={func.sortDataAsc(rowData)}
            columns={columnsForRequestView}
          /> */}
          <GlobalAgGridTable
            headers={activeHeaderData}
            // onFirstDataRendered={onFirstDataRendered}
            // onSelectionChanged={onSelectionChanged}
            setRowData={setRowData}
            cacheRowData={cacheData}
            setCacheRowData={setcacheData}
            module="Request"
            mainUrl="requestTime"
            DropDownFields={["User Name","Check In", "Check Out",  "Request Type","Absense","Day"]}
            rowData={rowData}
            filterData={filterData}
            setFilterData={setFilterData}
            // radioButton={radioButtonTable}
            // onChangeRadioButton={(state)=>{
            //    
            //   setRadioButtonTable({...radioButtonTable,state:state})
            // }}
            renderExtraComponent={extraComponentForTable}
            renderExtraComponentToRight={extraComponentToRight}
          />
        </div>
      </div>
    </div>
  );
};
export default RequestTable;
