import { faCheckCircle, faFile, faFileAlt, faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faHome, faSearchDollar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { Card, CardBody, CardFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Button } from 'reactstrap';
import 'antd/dist/reset.css';
import { Popover as AntdPopover } from "antd";
import './workdrive.css'
import swal from "sweetalert"
import Loading from '../../common/loader';
import icon from '../../assets/images/zoho-Workdrive-tr.png';
import httpService from '../../services/http.service';
import TaskService from '../../common/util/TaskService';
import ActivitieService from '../../common/util/ActivitieService';
import ActvityCard from '../../tabs/Activity/activity';

//

const Timeline = (props) => {
    const [timelineActivity, setActivityTimeline] = useState([]);
    const [workdriveFolderId, setWorkdriveFolderId] = useState('')

    useEffect(() => {
        if (props.folderId) {
            // getFolder(props.folderId)
            // setWorkdriveFolderId(props.folderId)
            getActivityTime(props.folderId)

        }
    }, [props.folderId])

    const getActivityTime=(id)=>{
        ActivitieService.GetFolderActivity(id)
        .then((result)=>{
            setActivityTimeline([])
        })
        .catch((err)=>{
            console.log(err);
        })
    }
    const getFolder = (id) => {
        var config = {
            Type: "Get",

            url: "https://www.zohoapis.com/workdrive/api/v1/files/" + id+'/files' ,
            method: "GET",
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };

        httpService.post("workdrive", config)
            .then(function (response) {
                return getTimeline(response.data.data.relationships.timeline.links.related)
            })
            .then((result)=>{
                console.log(result);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getTimeline=(link)=>{
        return new Promise((resolve,reject)=>{
            var config = {
                Type: "Get",
    
                url: link ,
                method: "GET",
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            };
    
            httpService.post("workdrive", config)
                .then(function (response) {
                    resolve(response.data.data)
                })
                .catch(function (error) {
                    reject(error);
                });
        })
    }


    return (
        <div className="timelineCards-wrapper">
       <ActvityCard activityFor='Workdrive' dealObj={props.data} obj={timelineActivity}/>

      </div>
       
    );
}

export default Timeline;