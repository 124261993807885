import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import HttpService from "../../../services/http.service";
import VendorForm from "./vendorForm";
import GetRecordById from "../../../common/util/getRecordById";
import VendorLayout from "./CreateVendorLayout/VendorLayout";
import { Col, Row } from "reactstrap";
import VendorSideBar from "./CreateVendorSidebar/VendorSideBar";
import AppContext from "../../../Context/AppContext";
import "./CreateVendor.css";

const CreateVendor = (props)=> {
  
  const [UsermailInvalid, setUserMailInvalid] = useState(false);
  const [formInputsValue, setFormInputsValue] = useState();
  
    const [data, setData] = useState({
      Active: true,
      Vendor_Name:"",
      Email:"",
      Company_Name:"",
      Company_Address:"",
      City:"",
      State:"",
      Zip_Code:"",
      GL_Account:""
    });
    const [func, setFunc] = useState("create");
    const [layoutstate, setLayoutState] = useState("Vendor Information");
  const [updatedSections, setUpdateSectionState] = useState(["Vendor Information"]);
  // const [sidebarCardsStatus, setSidebarCardsStatus] = useState({});
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  
    // const [clients, setClients] = useState([]);
    const history = useHistory();
    const location = props.location;
    const params=useParams();
    useEffect(()=>{
        if(props.getParams){
            props.getParams(params);
        }
    },[location])
    useEffect(()=>{
      localStorage.setItem('data',JSON.stringify(data));
    },[data])
  
    const handleChange = (e) => {
      const vendor = { ...data } 
       
      if(e.currentTarget.name=='Active'){
        vendor[e.currentTarget.name] = e.currentTarget.checked;
        setData(vendor);
      }else if(e.currentTarget.name == "Company_Address"){
        let mergeAddress={};
        if(typeof (e.currentTarget.value)==="string"){
          mergeAddress = { ...data, "Company_Address":e.currentTarget.value };
        }else{
          mergeAddress = { ...data, ...e.currentTarget.value };
        }
        setData(mergeAddress);
      }
      else{
        vendor[e.currentTarget.name] = e.currentTarget.value;
        setData(vendor);
      } 
       
      // localStorage.setItem('data',JSON.stringify(vendor));
    }

    const getData = (module, id) =>{
        GetRecordById(module, id)
        .then((data) => {
            setData(data[0]);
           
        })
        .catch(err => {
            console.log(err);
        })
     }
     const updateTheSidebarState = async (data) => {
      console.log(data);
    }
  
    useEffect(()=>{
        if(params && params.action==="Update") {
          // setDetail(location.state.detail);
          getData(params.module, params.id);
        setFunc("update")
        }else{
          setFunc("create")
        }
    }, [location])


    
      // const isAllMandatoriesValid= () =>{
      //   let flag = true;
      //   if(formInputsValue.clientNumber.length === 0 || formInputsValue.clientName.length === 0 ||
      //      formInputsValue.clientNickname.length === 0 || formInputsValue.parentClient.length === 0 || 
      //      formInputsValue.priority.length === 0 || formInputsValue.projectType.length === 0 ||
      //      formInputsValue.projectVolume.length === 0 ||  formInputsValue.phone.length === 0 || 
      //      formInputsValue.website.length === 0 || formInputsValue.billingStreet.length === 0 ||
      //      formInputsValue.billingCity.length === 0 || formInputsValue.billingState.length === 0 ||
      //      formInputsValue.billingCode.length === 0 ||  formInputsValue.shippingCountry.length === 0 ||
      //      formInputsValue.shippingState.length === 0 || formInputsValue.shippingCity.length === 0){
      //       flag = false;
      //   }
      //   return flag;
      // }
    
    //   const updateData = async (data) => {
    //     let route = "";
    //     route = func == "create" ? 'createVendor' : 'updateVendor'
  
    //       let returnData = await HttpService.CreateUpdate(route, data).then(res =>  {
    //       //   console.log(res.data);
    //         if(res.data.ack === 1 || res.data.Ack === 1){
    //           route = func == "create" ? history.push("/contacts/vendorlist") : alert("Updated Successfully");
    //           // resetAllFields();
    //           // route = func == "create" ?   history.push('/contacts/clientlist') : refreshClient(formInputsValue._id)
             
    //         }
    //         else{
    //           alert("Network/Server Issue: Unable to Add/Update Vendor Data. Try Again");
    //         }
    //       });
      
    //   }

      const refreshVendor=async(id)=>{
 
        let returnData = await HttpService.getByBoj("getvendorbyid", {_id:id}).then(
          (res) => {
            if(res.data.length>0 && res.data!=null){
      
              history.push({
                pathname: '/app/contacts/vendorlist',
                //search: '?query=abc',
                state: { detail: res.data[0] }
            });
              // props.setJob(res.data[0])
            }else{
                 history.push('/app/contacts/vendorlist');
            }
            
          //   loadTabData();
          });
      
      }

   
      // const updateData = async (data) => {
      //   // let headers = {
      //   //   'fileName':UseremailAdd
      //   // } 
      //   let returnData = await HttpService.CreateUpdate('createVendor', data).then(res => {
      //     //   console.log(res.data);
      //     if (res.data.ack == 1) {
      //       swal("Vendor " + res.data.msg);
      //       // resetAllFields();
      //       history.push('/contacts/vendorlist');
      //     } else if (res.data.ack == 0) {
      //       alert("Vendor already registered with same Email-ID.");
      //     }
    
      //   });
    
      // }

    //   const saveVendorData=(data)=>{
    //     updateData(data)
    //   }
    
    return (
        <div className="createuser-height">
            {/* <VendorSubheader  
            isAccess={props.isAccess}
            selectedRole={props.selectedRole}
                func = {func} 
                data={data}
                saveVendorData={saveVendorData} 
            /> */}
             <Row className="mar-15 createdeal-height2 Client-sidebar-wrapper">
        <Col sm={2} className="scrollingSideBar">
          <VendorSideBar
            setUpdateSectionState={setUpdateSectionState}
            updatedSections={updatedSections}
            location={location}
            setLayoutState={setLayoutState}
            layoutstate={layoutstate}
            // sidebarCardsStatus={sidebarCardsStatus}
            updateTheSidebarState={updateTheSidebarState}
          />
        </Col>
        <Col
          sm={10}
          className="pad-l0"
        >
          <VendorLayout
            setUpdateSectionState={setUpdateSectionState}
            updatedSections={updatedSections}
            location={location}
            setLayoutState={setLayoutState}
            layoutstate={layoutstate}
            // sidebarCardsStatus={sidebarCardsStatus}
            updateTheSidebarState={updateTheSidebarState}
            data={data}
            setData={setData}
            handleChange={handleChange} 
            func={func}
            UsermailInvalid={UsermailInvalid} 
            setUserMailInvalid={setUserMailInvalid}
          />
        </Col>
      </Row>
           {/*             
            <VendorForm 
                data={data}
                setData={setData}
                handleChange={handleChange} 
                func={func}
                UsermailInvalid={UsermailInvalid} 
                setUserMailInvalid={setUserMailInvalid}
            /> */}

        </div>
    );
}

export default CreateVendor;