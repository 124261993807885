import React, { useState, useRef,useImperativeHandle,forwardRef, useEffect, useContext } from 'react';
import NycProperty from './nycproperty/nycproperty'
import PreviouSolar from './previousolar/previousolar'
import AddNYCFilling from './addnycfilling/addnycfilling'
import Violation from './violation/violation'
import Asbestos from './asbestos/asbestos';
import { Button } from "reactstrap"
const NycProject= forwardRef((props,ref)=> {
  const nycObj={
    nycProperty : false,
    previousSolar : false,
    additionalNyc : false,
    wwpViolation: false,
    asbestos : false,
  }
  const [toggleState, settoggleState] = useState('nycproperty');

  //---------------------------------------------nyc Property -----------------------------
  const [zolaEnable, setzolaEnable] = useState(false);
  const [blockNo, setBlockNo] = useState("");
  const [lotNo, setLotNo] = useState("");
  const [binNo, setBinNo] = useState("");
  const [community, setCommNo] = useState("");
  const [dwellingUnits, setDwellingUnits] = useState("");
  const [Occupancy, setOccupancy] = useState("Yes");
  const [OccupancyVal, setOccupancyVal] = useState("");
  const [tidalWetland, setTidalWetland] = useState("No");
  const [freshWater, setFreshWater] = useState("No");
  const [coastal, setCoastal] = useState("No");
  const [firedistrict, setFiredistrict] = useState("No");
  const [floodHazard, setFloodHazard] = useState("No");
  const [landmark, setLandmark] = useState("No");
  const [envRestrictions, setEnvRestrictions] = useState("No");
  const [Construction, setConstruction] = useState("No");
  const [ConstructionVal, setConstructionVal] = useState("No");
  const [zoningList, setZoningList] = useState([{ zoningdistrict: "" }]);
  const [commercialList, setCommercialList] = useState([
    { commercialoverlay: "" },
  ]);
  const [specialDistList, setspecialDistList] = useState([{ specialDist: "" }]);
  const [zoiningMap, setZoiningMap] = useState("");
  let nycPropertyProps ={
    blockNo:blockNo, setBlockNo:setBlockNo,
    zolaEnable : zolaEnable, setzolaEnable : setzolaEnable,
    location:props.location,
    lotNo:lotNo, setLotNo:setLotNo,
    binNo:binNo, setBinNo:setBinNo,
    community:community, setCommNo:setCommNo,
    dwellingUnits:dwellingUnits, setDwellingUnits:setDwellingUnits,
    Occupancy:Occupancy, setOccupancy:setOccupancy,
    tidalWetland:tidalWetland, setTidalWetland:setTidalWetland,
    freshWater:freshWater, setFreshWater:setFreshWater,
    coastal:coastal, setCoastal:setCoastal,
    firedistrict:firedistrict, setFiredistrict:setFiredistrict,
    floodHazard:floodHazard, setFloodHazard:setFloodHazard,
    landmark:landmark, setLandmark:setLandmark,
    envRestrictions:envRestrictions, setEnvRestrictions:setEnvRestrictions,
    Construction:Construction, setConstruction:setConstruction,
    ConstructionVal : ConstructionVal, setConstructionVal : setConstructionVal,
    OccupancyVal : OccupancyVal , setOccupancyVal : setOccupancyVal,
    zoningList:zoningList, setZoningList:setZoningList,
    commercialList:commercialList, setCommercialList:setCommercialList,
    specialDistList:specialDistList, setspecialDistList:setspecialDistList,
    zoiningMap:zoiningMap, setZoiningMap:setZoiningMap,
    toggleState:toggleState, settoggleState:settoggleState, setListState:props.setListState,
    setNycPropertyComplete:nycObj
  }

  //--------------------------------------privious Solar State---------------------------
  const [PreviousSolar, setPreviousSOlar] = useState("No");
  const [AppSupersede, setAppSupersede] = useState("No");
  const [installation, setinstallation] = useState("No");
  const [GeneralContractor, setGeneralContractor] = useState("No");
  const [InspectionPass, setInspectionPass] = useState("No");
  const [Reinstatement, setReinstatement] = useState("");
  const [Withdrawal, setWithdrawal] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [emailAdd, setEmailAdd]=useState("");
  const [appnumber, setAppNumber] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  let previouSolarProps={
    setData:props.setData,
    location:props.location,
    getDealData:props.getDealData,
  PreviousSolar:PreviousSolar, setPreviousSOlar:setPreviousSOlar,
  AppSupersede:AppSupersede, setAppSupersede:setAppSupersede,
  installation:installation, setinstallation:setinstallation,
  GeneralContractor:GeneralContractor, setGeneralContractor:setGeneralContractor,
  InspectionPass:InspectionPass, setInspectionPass:setInspectionPass,
  Reinstatement:Reinstatement, setReinstatement:setReinstatement,
  Withdrawal:Withdrawal, setWithdrawal:setWithdrawal,
  startDate:startDate, setStartDate:setStartDate,
  emailAdd:emailAdd, setEmailAdd:setEmailAdd,
  appnumber:appnumber, setAppNumber:setAppNumber,
  currentStatus:currentStatus, setCurrentStatus:setCurrentStatus,
  toggleState:toggleState, settoggleState:settoggleState, setListState:props.setListState,
  setPreviousComplete:nycObj
        
  }

  //--------------------------------------Additional NYC Filling State--------------------------
  const [Conjunction, setConjunction] = useState("No");
  const [StopWork, setStopWork] = useState("No");
  const [withoutpermit, setWithoutpermit] = useState("No");
  const [partial, setPartial] = useState(false);
  const[lblConjunction, setLblConjunction]= useState("");
  
  let additionalNYCProps={
    ownerType:props.ownerType, 
    setNycFilingDisable:props.setNycFilingDisable,
    Conjunction:Conjunction, setConjunction:setConjunction,
    StopWork:StopWork, setStopWork:setStopWork,
    withoutpermit:withoutpermit, setWithoutpermit:setWithoutpermit,
    partial:partial, setPartial:setPartial,
    lblConjunction:lblConjunction, setLblConjunction:setLblConjunction,
    toggleState:toggleState, settoggleState:settoggleState, setListState:props.setListState,
    setAddFillingComplete:nycObj
  }
  
  //------------------------------------------WWP Violation Info ---------------------------------
  const [wwpViolation, setWWPViolation] = useState([]);
  const [violationNum, setViolationNum] = useState("");
  const [violationType, setViolationType] = useState("");
  const [dobViolationNum, setDOBViolationNum] = useState("");
  const [ltwocode, setltwocode] = useState("NRV");
  const [Civilpenalty, setCivilpenalty] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

 
  let ViolationProps={
    setData:props.setData,
    ownerType:props.ownerType,
    nycFilingDisable:props.nycFilingDisable,
    wwpViolation:wwpViolation, setWWPViolation:setWWPViolation,
   violationNum:violationNum, setViolationNum:setViolationNum,
   violationType:violationType, setViolationType:setViolationType,
   dobViolationNum:dobViolationNum,setDOBViolationNum,setDOBViolationNum,
   ltwocode:ltwocode, setltwocode:setltwocode,
  Civilpenalty:Civilpenalty, setCivilpenalty:setCivilpenalty,
   jobNumber:jobNumber, setJobNumber:setJobNumber,
   invoiceNumber:invoiceNumber, setInvoiceNumber:setInvoiceNumber,
   toggleState:toggleState, settoggleState:settoggleState, setListState:props.setListState,
        setViolationComplete:nycObj
     
  }
  //------------------------------------------Asbestos State---------------------------------
  const [asbestosReq, setAsbestosReq] = useState("");
  const [acpcompleted, setACPcompleted] = useState("No");
  const [certificateoccupancy, setCertificateoccupancy] = useState("No");
  const [nbApplication, setNbApplication] = useState("");
  const [asCertificateNum, setCertificateNum] = useState("");
  const [AcpControl, setAcpControl] = useState("");


let asbestosProps={
  asbestosReq:asbestosReq, setAsbestosReq:setAsbestosReq,
  acpcompleted:acpcompleted, setACPcompleted:setACPcompleted,
  certificateoccupancy:certificateoccupancy, setCertificateoccupancy:setCertificateoccupancy,
  nbApplication:nbApplication, setNbApplication:setNbApplication,
  asCertificateNum:asCertificateNum, setCertificateNum:setCertificateNum,
  AcpControl:AcpControl, setAcpControl:setAcpControl,
  toggleState:toggleState, settoggleState:settoggleState, setListState:props.setListState,
  setAsbestosComplete:nycObj
}

  useEffect(() => {
        settoggleState(props.listState);
      },[props.listState]);

       //hrushikesh shelke new code
      const saveCancelButtonRef = useRef();
      useImperativeHandle(ref, () => ({
        saveNycProject() {
          ableNextButton();
        }
      }));
       //hrushikesh shelke new code
      const ableNextButton=()=>{
        if(wwpViolation.length == 0){
          nycObj.wwpViolation = true
        }
        if(nycObj.nycProperty && nycObj.previousSolar && nycObj.additionalNyc && nycObj.wwpViolation && 
          nycObj.asbestos)
          {
          saveNYCProjectData();
          props.updateTheSidebarState({type:"NycProject",status:true})
          localStorage.setItem('currentsidebar',"Homeowner");
          props.setLayoutState("homeowner");
          props.setListState("homeownerinfo");
          return true;
        } else {
          alert("Please check all checkboxes of current Section.(It should be checked for next form.)");
          return false;
        }
      }
      const saveNYCProjectData = () => {
        const data={
          "blockNo":blockNo,
          "lotNo":lotNo,
          "binNo":binNo,
          "community":community,
          "dwellingUnits":dwellingUnits,
          "Occupancy":Occupancy,
          "tidalWetland":tidalWetland,
          "freshWater":freshWater,
          "coastal":coastal,
          "firedistrict":firedistrict,
          "floodHazard":floodHazard,
          "landmark":landmark,
          "envRestrictions":envRestrictions,
          "Construction":Construction,
          "zoningList":zoningList,
          "commercialList":commercialList,
          "specialDistList":specialDistList,
          "zoiningMap":zoiningMap,
          "PreviousSolar":PreviousSolar,
          "AppSupersede":AppSupersede,
          "installation":installation, 
          "GeneralContractor":GeneralContractor,
          "InspectionPass":InspectionPass,
          "Reinstatement":Reinstatement,
          "Withdrawal":Withdrawal,
          "startDate":startDate,
          "inspectionEmailAdd":emailAdd,
          "appnumber":appnumber,
          "currentStatus":currentStatus,
          "Conjunction":Conjunction,
          "StopWork":StopWork,
          "withoutpermit":withoutpermit,
          "partial": partial,
          "lblConjunction":lblConjunction,
          "wwpViolation":wwpViolation,
          "ConstructionVal" : ConstructionVal,
          "OccupancyVal" : OccupancyVal,
          "asbestosReq": asbestosReq,
          "acpcompleted": acpcompleted,
          "certificateoccupancy": certificateoccupancy,
          "nbApplication": nbApplication,
          "asCertificateNum": asCertificateNum,
          "AcpControl": AcpControl
        }
        var preliminaryData = JSON.parse(localStorage.getItem("preliminayData"));
        var nycData = JSON.parse(localStorage.getItem("nycData"));
        var homeownerData = JSON.parse(localStorage.getItem("homeownerData"));
        var permittingData = JSON.parse(localStorage.getItem("permittingData"));
        var pvsystemData = JSON.parse(localStorage.getItem("pvSystemData"));
    
     const mergedObject = {
          ...preliminaryData,
          ...data,
          ...homeownerData,
          ...permittingData,
          ...pvsystemData,
        };
        localStorage.setItem("nycData", JSON.stringify(data));
      }

      let borough;
      if(props.data.Borough == "Manhattan" || props.data.Borough == "Bronx" || props.data.Borough == "Brooklyn"|| props.data.Borough == "Queens"|| props.data.Borough == "Staten Island"){
        if(props.data.Borough == "Manhattan"){
          borough = "1"
        }else if(props.data.Borough == "Bronx"){
          borough = "2"
        }else if(props.data.Borough == "Brooklyn"){
          borough = "3"
        }else if(props.data.Borough == "Queens"){
          borough = "4"
        }else{
          borough = "5"
        }
      }else{
        borough = props.data.Borough
      }

      
      var href = "http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=" + ((borough)) + "&houseno=" + (props.data.HouseNumber) + "&street=" + (props.data.StreetName) + "&go2=+GO+&requestid=2"
      var zolalink = "https://zola.planning.nyc.gov/l/lot/" + (borough) + "/" + (props.data.blockNo) + "/" + (props.data.lotNo);
      let digitalTaxMapLink = `https://propertyinformationportal.nyc.gov/parcels/parcel/${borough}${props.data.blockNo}${props.data.lotNo}`
      
    return (
        <div className="boxplate">
        <div className="boxplate-heading" style={{display: "flex"}}>
          <div className='sectitle'>NYC Project</div> 
        <div className='zolabisbtn'>
          {zolaEnable ? 
           <a href={zolalink} target="_blank" rel="noopener noreferrer" className='zola-link'> <Button color="primary" className='zolaBtn'>Zola</Button></a> : <Button className='zolaBtn' disabled>Zola</Button>}
           <a href={href} target="_blank" rel="noopener noreferrer" className='bis-link'> <Button color="primary" className='zolaBtn'>BIS</Button></a>
           <a href={digitalTaxMapLink} target="_blank" rel="noopener noreferrer"> <Button color="primary" className='zolaBtn'>Digital Tax Map</Button></a>
           </div>
        
        </div>
        <div className="pad-15 createdeal-custom-scroll">
        <NycProperty {...nycPropertyProps} sidebarCardsStatus={props.sidebarCardsStatus}
          setSidebarCardsStatus={props.setSidebarCardsStatus} 
          updateTheSidebarState={props.updateTheSidebarState} 
          setAddressValChange={props.setAddressValChange} addressValChange={props.addressValChange}  handleOnChangeDeal={props.handleOnChangeDeal} setWWPViolation={setWWPViolation} setWithoutpermit={setWithoutpermit} setStopWork={setStopWork} setPartial={setPartial} data={props.data}/>
        <PreviouSolar {...previouSolarProps}  handleOnChangeDeal={props.handleOnChangeDeal} data={props.data}/>  
        <AddNYCFilling {...additionalNYCProps}  handleOnChangeDeal={props.handleOnChangeDeal} data={props.data} sidebarCardsStatus={props.sidebarCardsStatus}
          setSidebarCardsStatus={props.setSidebarCardsStatus} 
          updateTheSidebarState={props.updateTheSidebarState}
        />
        <Violation {...ViolationProps} bin={binNo} 
        setLoader={props.setLoader}
        sidebarCardsStatus={props.sidebarCardsStatus} 
        updateTheSidebarState={props.updateTheSidebarState} 
        handleOnChangeDeal={props.handleOnChangeDeal} data={props.data}
        />
        <Asbestos sidebarCardsStatus={props.sidebarCardsStatus} 
          updateTheSidebarState={props.updateTheSidebarState}
         {...asbestosProps}  handleOnChangeDeal={props.handleOnChangeDeal} data={props.data}/>
        </div>
        </div>
    );
})

export default NycProject;