import { faLink, fas, faSquare, faFlag, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import {
    Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText
} from 'reactstrap';
import swal from 'sweetalert';
import TableCollapse from '../../common/CollapseTable/collapse_table';
import HttpService from "../../services/http.service";
import _ from 'lodash';
import './milestone.css';
import ProjectService from '../../common/util/ProjectService';

const Milestone = ({ eventKey, projectId,allMilstones,dealObj }) => {
    const [taskList, setTaskList] = useState([])
    const [renderList, setRederList] = useState([])
    const [tableData, setTableData] = useState([]);
    const [tabelCacheData, setTableCacheData] = useState([])

    useEffect(() => {
        if(projectId){
            // getAllTaskDocs()
            getAllPipelinewithAllStatus()
        }
        


    }, [eventKey,projectId])

    useEffect(()=>{
        setTableData(allMilstones);
        setTableCacheData(allMilstones);
    },[allMilstones])


    const getAllPipelinewithAllStatus = () => {
        // ProjectService.GetProjectMilestone(projectId)
        // .then(async (result)=>{
        //     setTableData(result);
        //     setTableCacheData(result);
        // })
        // .catch((err)=>{
        //     console.log(err);
        // })
        // HttpService.getByBoj('getAllPipeline', {})
        //     .then((result) => {
        //         let doArr = []
        //         result.data.forEach((one) => {
        //             doArr.push(getPipelineStatusFromPipeline(one))
        //         })
        //         return Promise.all(doArr)
        //     })
        //     .then((result) => {
        //         result=result.filter((one)=>one.Task.length>0);
        //         setTableData(result);
        //          let arr=_.cloneDeep(result);
        //         setTableCacheData(arr);
        //         // let dataArr=[]
        //         // result.forEach((oneArr)=>{
        //         //     dataArr=[...oneArr,...dataArr];
        //         // })
        //         // dataArr=dataArr.filter((one)=>{
        //         //     if((one.Status.name=='COMPLETED')&&(one.isMilestone)){
        //         //         return one;
        //         //     }
        //         // })
        //         // dataArr.sort(function(x,y){
        //         //     if(x.isMilestone){
                        
        //         //        return -1
        //         //     }
        //         //     else if(y.isMilestone){
                        
        //         //         return -1
        //         //      }
        //         //      else if(x.isMilestone&&y.isMilestone){
        //         //          if(new Date(x.Milestone.DateTime)<new Date(y.Milestone.DateTime)){
        //         //              return -1
        //         //          }else{
        //         //              return 1;
        //         //          }
        //         //      }else{
        //         //         return 1
        //         //     }
        //         // });
        //         // let arr=_.cloneDeep(dataArr);
        //         // setTableCacheData(arr);
               
        //     })
        //     .catch((err) => {
        //         //swal(`${err}`);
        //     })
    }

const getTableData=(data)=>{
    data=[...data];

}
    const data = [
        { name: 'one', LatestStaus: 'test', LatestDate: 'Test', PrevStatus: [{ Status: 'Test', Date: 'Test' }, { Status: 'Test', Date: 'Test' }] },
        { name: 'two', LatestStaus: 'test', LatestDate: 'Test', PrevStatus: [{ Status: 'Test', Date: 'Test' }, { Status: 'Test', Date: 'Test' }] },

    ]
    const getPipelineStatusFromPipeline = (pipeline) => {
        return new Promise((resolve, reject) => {
            HttpService.CreateUpdate('getTaskDocsFromPipeline', {DealsId:projectId, Pipeline: pipeline.PipelineName })
                .then((result) => {
                    //let arr = []
                    // result.data.forEach((one) => {
                    //     if (one) {
                    //         if (one.Status == 'Not Started') {
                    //             let obj = { name: pipeline.PipelineName, LatestStaus: one.Status, LatestDate: one.DateTime, PrevStatus: [] }
                    //             arr.push(obj);
                    //         } else {
                    //             let obj = { name: pipeline.PipelineName, LatestStaus: one.Status, LatestDate: new Date(one.DateTime).toLocaleDateString(), PrevStatus: [{ Status: 'Not Started', Date: 'NA' }] }
                    //             arr.push(obj);
                    //         }

                    //     } else {
                    //         let obj = { name: pipeline.PipelineName, LatestStaus: 'NA', LatestDate: 'NA', PrevStatus: [] }
                    //         arr.push(obj);
                    //     }
                    // })
                    // result.data=result.data.filter((one)=>{
                    //     if((one.Status.name=='COMPLETED')&&(one.isMilestone)){
                    //         return one;
                    //     }
                    // })
                    let obj={PipelineCreateDate:new Date(pipeline.createdAt),Pipeline:pipeline.PipelineName,Task:result.data}
                    resolve(obj);

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    const getAllTaskDocs = async () => {
        let returnData = await HttpService.getByBoj("gettaskdocs", { DealsId: projectId }).then(
            (res) => {
                setRederList(res.data.Data)

            })
            .catch((err) => {
                alert(`${err}`);
            })
    }

    // const getTime = (date) => {
    //     let Time = new Date(date);
    //     var hours = Time.getHours();
    //     var minutes = Time.getMinutes();
    //     var ampm = hours >= 12 ? 'pm' : 'am';
    //     hours = hours % 12;
    //     hours = hours ? hours : 12; // the hour '0' should be '12'
    //     minutes = minutes < 10 ? '0' + minutes : minutes;
    //     var strTime = hours + ':' + minutes + ' ' + ampm;
    //     return strTime;

    // };

    // const getDate = (date) => {
    //     date = new Date(date);
    //     let str = '';
    //     str = str + date.toLocaleString('en-us', { month: 'short' });
    //     str = str + " " + date.getDate();

    //     return str;
    // }

    // const getStatementForTask = (one) => {
    //     // activityList.map(e=>{
    //     if (one.Action.DataType === "Milestone") {
    //         return (
    //             <span><small>You</small> {one.Action.Data.UpdatedState ? 'mark' : 'unmark'} this task as a milestone</span>
    //         )
    //     }
    //     else if (one.Action.DataType === "TASK") {
    //         return (
    //             <span>You {one.Action.ActionType.toLowerCase()} {one.TaskName}</span>
    //         )
    //     }
    //     // })

    //     else if (one.Action.DataType === "Assignee") {
    //         return (
    //             <span>You {one.Action.ActionType.toLowerCase()} {one.Action.DataType} {one.Action.Data.userName}</span>
    //         )
    //     }
    //     else if (one.Action.DataType === "DueDate") {
    //         return (
    //             <span>You set {one.Action.DataType.toLowerCase()} {one.Action.Data.PrevDate} to {one.Action.Data.UpdatedDate}</span>
    //         )
    //     }
    //     else if (one.Action.DataType === "Status") {
    //         return (
    //             <span>You set {one.Action.DataType.toLowerCase()}      <span style={{ backgroundColor: one.Action.Data.PrevStatus.color, color: 'white', borderRadius: 17, padding: 4 }}>{one.Action.Data.PrevStatus.name} </span>       to     <span style={{ borderRadius: 17, padding: 4, backgroundColor: one.Action.Data.UpdatedState.color, color: 'white' }}>{one.Action.Data.UpdatedState.name} </span> </span>
    //         )
    //     }
    //     else if (one.Action.DataType === "Priority") {

    //         if (one.Action.Data.PrevState.name) {
    //             return (
    //                 <span>You set {one.Action.DataType.toLowerCase()}    {one.Action.Data.PrevState.name ? one.Action.Data.PrevState.name : 'Unknown'}     {<FontAwesomeIcon color={one.Action.Data.PrevState.color} icon={faFlag} />}    to    {one.Action.Data.UpdatedState.name}  {<FontAwesomeIcon color={one.Action.Data.UpdatedState.color} icon={faFlag} />} </span>
    //             )
    //         } else {
    //             return <span>You set {one.Action.DataType.toLowerCase()}    to    {one.Action.Data.UpdatedState.name}  {<FontAwesomeIcon color={one.Action.Data.UpdatedState.color} icon={faFlag} />} </span>
    //         }

    //     }
    //     else if (one.Action.DataType === "Category") {
    //         return (
    //             <span>You set {one.Action.DataType.toLowerCase()}    {one.Action.Data.PrevState ? one.Action.Data.PrevState : 'Unknown'}        to    {one.Action.Data.UpdatedState}   </span>
    //         )
    //     }
    //     else if (one.Action.DataType === "CheckList") {
    //         if (one.Action.Data.PrevCheckList) {
    //             return (
    //                 <span>You {one.Action.ActionType.toLowerCase()}  {one.Action.DataType.toLowerCase()}    {one.Action.Data.PrevCheckList.Label ? one.Action.Data.PrevCheckList.Label : 'Unknown'}        to    {one.Action.Data.UpdatedCheckList.Label}   </span>
    //             )
    //         } else {
    //             return (
    //                 <span>You set  {one.Action.DataType.toLowerCase()}           to    {one.Action.Data.UpdatedCheckList.Label}   </span>
    //             )
    //         }

    //     }
    //     else {
    //         return (
    //             <span>You set to {one.Action.DataType}</span>
    //         )
    //     }

    // }

    const getString = (task) => {
        // if(task.Status.name=='COMPLETED'){
        if (task.isMilestone) {
            return <p className='small card-para'>{task.TaskName} is {task.Milestone.Category} statuses {task.Milestone.Status} </p>
        }

        // }
    }

    const getFilterTask = (list) => {
        return list.filter(task => {
            //if(task.Status.name=='COMPLETED'){
            if (task.isMilestone) {
                return task
            }

            //}
        })
    }

    const handleChangeSearch=(e)=>{
        let data=_.cloneDeep(tabelCacheData);
        if(e.target.value==''){
            setTableData(data);
        }else{
            data=data.filter((task)=>{
                if(task.Pipeline.toLowerCase().includes(e.target.value.toLowerCase())){
                    return  task;
                }
            })
            setTableData(data);
        }
       
    }



    return (
        <div className="activityContent">
            <div className="MilstoneSearch-bar">
                <FontAwesomeIcon icon={faSearch} />
                <input placeholder='Search pipeline...' name='Search' onChange={handleChangeSearch}  className="form-control from-control-sm" />
            </div>
            <TableCollapse data={tableData} removeString={dealObj?dealObj.Deal_Name:""} />
        </div>
    );
};

{/* <style>
.card-para {
    padding: 15px;
    margin-bottom: 0 !important;
    font-size: 14px;
  }
    </style> */}

export default Milestone;