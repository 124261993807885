import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  faFlag as faFlagSolid,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import Config from "../../config.json";
import _ from "lodash";
import { UncontrolledCollapse } from "reactstrap";
import axios from "axios";
import TaskService from "../../common/util/TaskService";
import ModuleService from "../../common/util/ModuleService";
import UserService from "../../common/util/UserService";
import Loader from "../../common/loader";
import dayjs from "dayjs";
import ProjectService from "../../common/util/ProjectService";
import FieldService from "../../common/util/FieldService";
import TaskList from "./TaskList";

const TaskSearchComponent = (props) => {
  const location = props.location;
  const history = props.history;
  const params = useParams();
  useEffect(() => {
    if (props.getParams) {
      props.getParams(params);
    }
  }, [location]);
  const [dummyDealObj, setDummyDealObj] = useState({
    id: "Miscellaneous",
    Account_Name: { name: "Miscellaneous", id: "Miscellaneous" },
    Deal_Name: "Miscellaneous",
  });
  const [Deals, setDeals] = useState([]);
  const [dealList, setDealList] = useState([]);
  const [allProjects, setAllProjects] = useState([]);

  const [openTaskModal, setOpenTaskModel] = useState(false);
  const [AllClients, setAllClients] = useState("");
  const [DropDownClient, setDropDownClient] = useState([]);
  const [ClientSearchResult, setClientSearchResult] = useState([]);
  const [DropdownStatus, setDropdownStatus] = useState([]);
  const [DropDownDeals, setDropDownDeals] = useState([]);
  const [DealSearchResult, setDealSearchResult] = useState([]);
  const [assignedMeVisible, setAssignedMeVisible] = useState(false);
  const [CollapseDeal, setCollapseDeal] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [allTimeLogs, setAllTimeLogs] = useState([]);
  const [loader, setLoader] = useState(false);
  const [taskLayoutDetail, setTaskLayoutDetail] = useState({});
  const [groupExpandFlag, setGroupExpandFlag] = useState([]);
  const [taskSearchSetting, setTaskSearchSetting] = useState({
    TaskName: true,
    Description: false,
    Custom_Fields: false,
  });
  const [dealGroupBy, setDealGroupBy] = useState([]);
  const [groupBy, setGroupBy] = useState({
    Task: "Pipeline",
    Deal: "Deal Name",
  });
  const [cacheGroupBy, setCacheGroupBy] = useState({
    Task: "Pipeline",
    Deal: "Deal Name",
  });
  const [taskFilterSetting, setTaskFilterSetting] = useState({
    TaskLocation: false,
    SubtaskParntNames: false,
    ExpandDeals: true,
    ExpandGroups: true,
    ExpandTasks: false,
    ViewSeparateTask: false,
  });
  const [taskLocationShow, setTaskLocationShow] = useState(false);
  const [showParentNames, setShowParentNames] = useState(false);
  const [dependencyList, setDependencyList] = useState([]);
  const [tasklist, setTaskList] = useState([]);
  const groupByTaskItem = [
    { name: "None", value: "None" },
    { name: "Pipeline", value: "Pipeline" },
    { name: "Status", value: "Status" },
    { name: "Assignee", value: "Assignee" },
    { name: "Priority", value: "Priority" },
    { name: "Category", value: "Category" },
    { name: "Milestone", value: "Milestone" },
    { name: "Superviser", value: "Superviser" },
    { name: "Due Date", value: "Due Date" },
  ];
  const groupByDealItem = [
    { name: "None", value: "None" },
    { name: "Status", value: "Status" },
    { name: "Deal Name", value: "Deal_Name" },
    { name: "Client", value: "Client" },
    { name: "Closing Date", value: "Closing Date" },
    { name: "NYC Project", value: " Is_NYC_Project" },
    { name: "Borough", value: "Borough" },
    // { name: "Block Number", value: 'Block_Number0' },
    // { name: "Bin Number", value: 'Bin_Number' },
    // { name: "Lot Number", value: 'Lot_Number0' }
  ];

  const [itemsTaskGroup, setItemTaskGroup] = useState(groupByTaskItem);
  const [itemDealGroup, setItemDealGroup] = useState(groupByDealItem);

  const [viewData, setViewData] = useState([]);
  const priorityValues = [
    { name: "None", color: "lightgray" },
    { name: "High", color: "#e50000" },
    { name: "Medium", color: "#f7c700" },
    { name: "Low", color: "blue" },
  ];

  const [taskLayout, setTaskLayout] = useState({
    status_details: [
      // {
      //     is_start: true,
      //     color: "#f56b62",
      //     name: "Pending",
      //     id: "1716273000002029049",
      //     is_default: false,
      //     type: "open",
      // },
      {
        is_start: false,
        color: "#a9b2c0",
        name: "Not Required",
        id: "1716273000002029043",
        is_default: false,
        type: "closed",
      },
      {
        is_start: false,
        color: "#a7bcee",
        name: "Not Started",
        id: "1716273000002029045",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#fbc11e",
        name: "To Do",
        id: "1716273000002029041",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#08aeea",
        name: "In Progress",
        id: "1716273000000031001",
        is_default: true,
        type: "open",
      },
      {
        is_start: false,
        color: "#98ca3c",
        name: "Completed",
        id: "1716273000002029047",
        is_default: false,
        type: "closed",
      },
    ],
  });

  const [addTaskColumns, setAddTaskColumns] = useState([
    {
      name: "TIME TRACKING",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "TIME_TRACKING",
      field: "TIME_TRACKING",
      type: "text",
    },
    {
      name: "SUPERVISER",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "SUPERVISER",
      field: "SUPERVISER",
      type: "text",
    },
    {
      name: "ASSIGNEE",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "ASSIGNEE",
      field: "ASSIGNEE",
      type: "text",
    },
    {
      name: "DUE DATE",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "DUE_DATE",
      field: "DUE_DATE",
      type: "text",
    },
    {
      name: "PRIORITY",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "PRIORITY",
      field: "PRIORITY",
      type: "text",
    },
    {
      name: "TIME ESTIMATE",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "TIME_ESTIMATE",
      field: "TIME_ESTIMATE",
      type: "text",
    },
    {
      name: "PROGRESS",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "PROGRESS",
      field: "PROGRESS",
      type: "text",
    },
    {
      name: "STATUS",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "STATUS",
      field: "STATUS",
      type: "text",
    },
    {
      name: "PIPELINE",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "PIPELINE",
      field: "PIPELINE",
      type: "text",
    },
    {
      name: "CATEGORY",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "CATEGORY",
      field: "CATEGORY",
      type: "text",
    },
    {
      name: "MILESTONE",
      isChecked: false,
      isVisible: false,
      isSearch: true,
      isDragOver: false,
      id: "MILESTONE",
      field: "MILESTONE",
      type: "text",
    },
  ]);

  const [filterItems, setFilterItems] = useState([
    { name: "Status", type: "string" },
    { name: "Task Name", type: "string" },
    { name: "Pipeline", type: "string" },
    { name: "Category", type: "string" },
    { name: "Due Date", type: "date" },
    { name: "Priority", type: "string" },
    { name: "Milestone", type: "string" },
    { name: "Assignee", type: "string" },
    { name: "Created By", type: "string" },
    { name: "Start Date", type: "date" },
  ]);

  const [filterItemsDeal, setFilterItemsDeal] = useState([
    { name: "Deal Name", type: "string" },
    { name: "Client", type: "string" },
    // { name: 'Project Address', type: 'string' },
    { name: "Status", type: "string" },
    { name: "Borough", type: "string" },
    // { name: "Bin Number", type: 'string' },
    // { name: "Lot Number", type: 'string' },
    // { name: "Block Number", type: 'string' },
    { name: "Closing Date", type: "date" },
    { name: "Created By", type: "string" },
  ]);
  const pvSystemTableHeaders = [
    { value: "Interconnection_Number", label: "Interconnection Number" },
    { value: "Invertor_Model_Number", label: "Invertor Model Number" },
    { value: "Invertor_Type", label: "Invertor Type" },
    { value: "Number_Of_Modules", label: "Number Of Modules" },
    { value: "PV_Module_Model", label: "PV Module Model" },
    { value: "System_Number", label: "System Number" },
    { value: "System_Size_KW1", label: "System Size KW" },
  ];
  const wwwViolationHeaders = [
    {
      value: "Civil_Penalty_Payment_Required",
      label: "Civil Penalty Payment Required",
    },
    { value: "Invoice_Number", label: "Invoice Number" },
    { value: "ISN_Number", label: "ISN Number" },
    { value: "L2_Code", label: "L2 Code" },
    { value: "Paid_Under_Job_Number", label: "Paid Under Job Number" },
    { value: "Violation_Number", label: "Violation Number" },
    { value: "Violation_Type", label: "Violation Type" },
  ];

  useEffect(() => {
    const items = [...filterItemsDeal];
    pvSystemTableHeaders.forEach((one) => {
      items.push({ name: one.label, type: "string", api_name: one.value });
    });
    wwwViolationHeaders.forEach((one) => {
      items.push({ name: one.label, type: "string", api_name: one.value });
    });
    setFilterItemsDeal(items);
    getTaskDependencyData();
  }, []);

  const getTaskDependencyData = () => {
    ModuleService.GetModuleListData("Task_Dependency")
      .then((result) => {
        setDependencyList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [dealStatus, setDealStatus] = useState([]);
  const [filterState, setFilterState] = useState(true);
  const changeFilterState = () => setFilterState(!filterState);
  useEffect(() => {
    getTaskList();
    getJobsByProjectId("1716273000002205035");
    getAllTimeLogs();
    TaskService.GetTaskLayout("1716273000002205035")
      .then((result) => {
        setTaskLayoutDetail(result);
        let status = [...result.status_details];
        status.forEach((one) => {
          one.isCheck = true;
        });
        status.unshift({ name: "All", id: "all", isCheck: true });
        setDropdownStatus(status);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getAllTimeLogs = () => {
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    let startDate = new Date();
    startDate.setDate(1);
    startDate = dayjs(startDate).format("YYYY-MM-DD");
    let endDate = new Date();
    endDate.setDate(31);
    endDate = dayjs(endDate).format("YYYY-MM-DD");
    TaskService.GetAllTimeTrack(userInfo?.email_id, startDate, endDate, "all")
      .then((result) => {
        setAllTimeLogs(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const status = [
    "On Hold",
    "Proposal",
    "Cancelled",
    "Completed",
    "In Progress",
  ];

  useEffect(() => {
    TaskService.GetProjectStatus()
      .then((result) => {
        let arr = [];
        result.forEach((one) => {
          if (arr.length == 0) {
            arr.push(one);
          } else {
            if (
              arr.filter((obj) => obj.status_name == one.status_name).length ==
              0
            ) {
              arr.push(one);
            }
          }
        });
        arr = arr.filter((one) => status.includes(one.status_name));
        setDealStatus(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // if (allJobs.length > 0) {
    getAllUsers();
    getAllTasks();
    getAllClients();
    getFieldHeaders();
    // }
  }, [allJobs, allTimeLogs]);

  useEffect(() => {
    if (AllClients.length > 0) {
      getAllDeals();
    }
  }, [allJobs, AllClients]);

  const [allPipelines, setAllPipline] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  useEffect(() => {
    let clients = [...AllClients];
    let alldeals = [...Deals];
    let tasks = [...allTasks];
    if (clients.length > 0 && alldeals.length > 0 && tasks.length > 0) {
      const obj = { ...taskFilterSetting };
      if (props.searchText) {
        tasks.forEach((one) => {
          one.Deals = alldeals.filter((deal) => {
            if (deal.Project_ID) {
              let ids = deal.Project_ID.split(",");
              ids = ids.filter((id) => id);
              if (ids.includes(one.project_id)) {
                return deal;
              }
            }
          });
        });
        tasks = tasks.filter((task) => {
          if (
            task.name &&
            task.name.toLowerCase().includes(props.searchText.toLowerCase())
          ) {
            return task;
          } else if (
            task.Deals.filter(
              (oneDeal) =>
                (oneDeal.Deal_Name &&
                  oneDeal.Deal_Name.toLowerCase().includes(
                    props.searchText.toLowerCase()
                  )) ||
                (oneDeal.Account_Name &&
                  oneDeal.Account_Name.name
                    .toLowerCase()
                    .includes(props.searchText.toLowerCase()))
            ).length > 0
          ) {
            return task;
          }
        });
        let searchDeals = [];
        tasks.forEach((searchTask) => {
          searchDeals = [...searchDeals, ...searchTask.Deals];
          delete searchTask.Deals;
        });
        alldeals = alldeals.map((item) => {
          if (searchDeals.some((searchItem) => searchItem.id == item.id)) {
            return item;
          }
        });
        alldeals = alldeals.filter((obj) => obj);
        getGroupByFields(alldeals, groupBy.Deal);
        setDropDownDeals(alldeals);
        setDropDownTasks(tasks);

        // if (props.searchBy == "Client") {
        //     alldeals = alldeals.filter(one =>one.Account_Name&& one.Account_Name.name.toLowerCase().includes(props.searchText.toLowerCase()));
        //     getGroupByFields(alldeals,groupBy.Deal)
        //     setDropDownDeals(alldeals);
        // }
        // if (props.searchBy == "Deal") {
        //     alldeals = alldeals.filter(one => one.Deal_Name.toLowerCase().includes(props.searchText.toLowerCase()));
        //     getGroupByFields(alldeals,groupBy.Deal)
        //     setDropDownDeals(alldeals);
        // }
        // if (props.searchBy == "Task") {
        //     tasks = tasks.filter(one => one.name && one.name.toLowerCase().includes(props.searchText.toLowerCase()));
        //     setDropDownTasks(tasks);
        // }

        obj.ExpandTasks = true;
      } else {
        getGroupByFields(alldeals, groupBy.Deal);
        setDropDownDeals(alldeals);
        setDropDownTasks(tasks);
        obj.ExpandTasks = false;
      }
      setTaskFilterSetting(obj);
    }
  }, [props.searchText]);

  useEffect(() => {
    // getAllPipelines();
    getAllCategorys();
  }, []);
  const subformApiName = ["Deal_Name", "PV_Interconnection"];
  const moduleApiNameForSubform = ["WWP_Violations", "Previous_Solar_Info"];
  const firstArr = [
    "Deal_Name",
    "Closing_Date",
    "Account_Name",
    "Project_Address",
    "Project_Status",
    "House_Number",
    "Street_Name",
    "City",
    "State",
    "Zip_Code",
    "Latitude",
    "Longitude",
    "Is_NYC_Project",
    "Borough",
    "Service_Requested_Name",
    "Block_Number",
    "Lot_Number",
    "Bin_Number",
    "Community_Board_Number",
    "Occupancy_Classification_Building_2014_2008_Code",
    "Occupancy_Classification_of_Building",
    "Construction_Classification_Code_2014_2008_Code",
    "Construction_Classification_Code",
    "Multiple_Dwelling_Classification",
    "Number_of_Dwelling_Units",
    "Building_Height",
    "Number_of_Stories",
    "Zoning_District",
    "Commercial_Overlay",
    "Specials_Districts",
    "Zoning_Map_Number",
    "Tidal_Wetlands",
    "Fresh_Water_Wetland",
    "Flood_Hazard",
    "Coastal_Erosion_Hazard",
    "Special_Fire_District",
    "Landmark_Area",
    "Environmental_Restriction",
    "Conjunction",
    "Conjunction_Application_Number",
    "Stop_Work_Order_Exists_on_Property",
    "Active_Work_Without_Permit_Violations",
    "Asbestos_Required",
    "ACP5_Completed",
    "Asbestos_Inspector_Certificate_Number",
    "ACP5_Control_Number",
    "Certificate_of_Occupancy_Available",
    "NB_Application_Number",
    "Owner_Type",
    "Property_Owner_s_Name",
    "Owner_s_First_Name",
    "Owner_s_Middle_Initial",
    "Owner_s_Last_Name",
    "Owner_Phone_Number",
    "Owner_s_Email_Address",
    "Owner_s_Mailing_Address_Diff_Than_Property_Address",
    "Owner_s_Mailing_Address",
    "Owner_s_Street_Name",
    "Owner_s_House_Number",
    "Owner_s_City",
    "Owner_s_State",
    "Owner_s_Zip_Code",
    "Non_Profit",
    "DHCR",
    "Other_Owner_Company_Name",
    "Other_Owner_Mailing_Address",
    "Other_Owner_Street_Name",
    "Other_Owner_House_Number",
    "Other_Owner_City",
    "Other_Owner_State",
    "Other_Owner_Zip_Code",
    "Other_Owner_Officer_First_Name",
    "Other_Owner_Officer_Middle_Initial",
    "Other_Owner_Officer_Last_Name",
    "Other_Owner_Officer_Title",
    "Other_Owner_Phone_Number",
    "Other_Owner_Email_Address",
    "Condo_Company_Name",
    "Condo_Mailing_Address",
    "Condo_Street_Name",
    "Condo_House_Number",
    "Condo_City",
    "Condo_State_Name",
    "Condo_Zip_Code",
    "Condo_Officer_First_Name",
    "Condo_Officer_Middle_Initial",
    "Condo_Officer_Last_Name",
    "Condo_Officer_Title",
    "Condo_Phone_Number",
    "Condo_Email_Address",
    "Applicant",
    "General_Contractor",
    "Electrician",
    "Number_of_Interconnection",
    "Total_System_Size",
    "Total_Modules",
    "PV_Interconnection",
    "Project_Cost",
    "Owner",
    "Created_By",
    "Created_Time",
    "Modified_By",
    "Modified_Time",
  ];
  const [dealHeaders, setDealHeaders] = useState([]);
  const getFieldHeaders = () => {
    // if(headerData.length===0){
    setLoader(true);
    FieldService.GetFields("all", "Deals")
      .then(async (result) => {
        let arr = [];
        // result = result.filter((one) => one.api_name == 'PV_Interconnection')
        firstArr.forEach((one) => {
          let fieldObj = result.filter((object) => object.api_name == one);
          if (fieldObj.length > 0) {
            fieldObj = fieldObj[0];
            fieldObj.field_label = fieldObj.field_label.replace("0", "");
            let obj = {};
            obj.field = fieldObj.api_name;
            obj.id = fieldObj.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = fieldObj.field_label;
            obj.type = fieldObj.data_type;
            arr.push(obj);
          }
        });
        result.forEach((one) => {
          if (!firstArr.includes(one.api_name)) {
            let obj = {};
            obj.field = one.api_name;
            obj.id = one.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = one.field_label.replace("0", "");
            obj.type = one.data_type == "formula" ? "number" : one.data_type;
            arr.push(obj);
          }
        });
        const notIncludesFileds = ["latitude", "longitude"];
        arr = arr.filter((one) => !notIncludesFileds.includes(one.name));
        moduleApiNameForSubform.forEach((one) => {
          let obj = {};
          obj.field = one;
          obj.id = one;
          obj.isChecked = false;
          obj.isDragOver = false;
          obj.isSearch = true;
          obj.name = one.replaceAll("_", " ");
          obj.type = "text";
          arr.push(obj);
        });
        arr = _.uniqBy(arr, "name");
        setDealHeaders(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (dealHeaders.length > 0) {
      let arr = [];
      const items = [...dealHeaders];
      items.forEach((one) => {
        if (one.type == "text") {
          arr.push({ name: one.name, type: "string", api_name: one.field });
        } else if (one.type == "integer") {
          arr.push({ name: one.name, type: "number", api_name: one.field });
        } else if (one.type == "date") {
          arr.push({ name: one.name, type: "date", api_name: one.field });
        } else if (one.type == "picklist") {
          arr.push({ name: one.name, type: "boolean", api_name: one.field });
        } else {
          arr.push({ name: one.name, type: "string", api_name: one.field });
        }
      });
      pvSystemTableHeaders.forEach((one) => {
        arr.push({ name: one.label, type: "string", api_name: one.value });
      });
      wwwViolationHeaders.forEach((one) => {
        arr.push({ name: one.label, type: "string", api_name: one.value });
      });
      setFilterItemsDeal(arr);
    }
  }, [dealHeaders]);

  const getAllCategorys = () => {
    const backendUrl = Config.backend_url;
    setLoader(true);
    axios
      .get(backendUrl + "getAllCategories")
      .then((result) => {
        setLoader(false);
        setAllCategory(result.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const getTaskList = () => {
    TaskService.GetAllProjects()
      .then(async (result) => {
        let doarr = [];
        if (result.data.projects) {
          // result.data.projects.forEach(one => {
          //     doarr.push(TaskService.GetTaskList(one.id_string))
          // })
          // let tasklist=await Promise.all(doarr);
          // tasklist = validation.RemoveNestedArray(tasklist);
          // tasklist=tasklist.filter(tlist=>tlist.name!=="General");
          // setTaskList(tasklist);
          setAllProjects(result.data.projects);
        } else {
          setTaskList([]);
          setAllProjects([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobsByProjectId = (pid) => {
    setLoader(true);
    TaskService.GetJobsByProjectId(pid)
      .then((result) => {
        if (result) {
          setAllJobs(result);
        } else {
          setAllJobs([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const getGroupByFields = (deals, groupBy) => {
    var unique = [];
    if (groupBy == "Client") {
      unique = [
        ...new Set(
          deals.map((deal) => {
            if (deal.Account_Name && deal.Account_Name.name) {
              return deal.Account_Name.name;
            } else {
              return "None";
            }
          })
        ),
      ];
    } else if (groupBy == "Status") {
      unique = [
        ...new Set(
          deals.map((deal) => {
            if (deal.Project_Status) {
              return deal.Project_Status;
            } else {
              return "None";
            }
          })
        ),
      ];
    } else {
      let value = groupByDealItem.filter((one) => one.name == groupBy)[0];
      if (value) {
        value = value.value;
        unique = [
          ...new Set(
            deals.map((deal) => {
              if (deal[value]) {
                return deal[value];
              } else {
                return "None";
              }
            })
          ),
        ];
      }
    }
    let arr = [];
    unique.forEach((one) => {
      arr.push({ DealId: one, isOpen: true });
    });
    setGroupExpandFlag(arr);
    setDealGroupBy(unique);
    DealIcons(unique);
  };

  // console.log("dealGroupBy :                         ", dealGroupBy);

  const getAllClients = () => {
    ModuleService.GetModuleList("Accounts")
      .then((result) => {
        if (result) {
          let all = [...result.data];
          setAllClients(all);
          result.data.forEach((client, i) => {
            client.isCheck = true;
          });
          if (result.data.length > 0) {
            result.data.unshift({
              Account_Name: "All",
              isCheck: true,
              id: "allClient",
            });
          }
          setDropDownClient(result.data);
          setClientSearchResult(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [ClientdropdownOpen, setClientdropdownOpen] = useState(false);
  const toggleClientDropDown = () => {
    setClientdropdownOpen(!ClientdropdownOpen);
  };

  const [DropDownPriority, setDropDownPriority] = useState([
    { name: "All", _id: "all", isCheck: true },
    { name: "None", _id: "none", isCheck: true },
    { name: "High", _id: "high", isCheck: true },
    { name: "Medium", _id: "medium", isCheck: true },
    { name: "Low", _id: "low", isCheck: true },
  ]);
  const [DropDownSearchPriorityResult, setDropDownSearchPriorityResult] =
    useState();

  const [DropDownTasks, setDropDownTasks] = useState([]);

  useEffect(() => {
    if (
      viewData.length > 0 &&
      DropDownTasks.length > 0 &&
      DropDownDeals.length > 0
    ) {
      if (viewData == "All") {
        if (viewData !== "All") {
          resetViewFilters();
        }
      } else {
        setViewFilters(viewData);
      }
    }
  }, [DropDownTasks, viewData, DropDownDeals]);

  useEffect(() => {
    if (
      viewData.length > 0 &&
      DropDownTasks.length > 0 &&
      DropDownDeals.length > 0
    ) {
      // if (viewData == 'All') {
      //     resetViewFilters()
      // } else {
      //     setViewFilters(viewData)
      // }
    }
  }, [DropDownTasks, viewData, DropDownDeals]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => {
    setDropdownOpen((prevState) => !prevState);
    // setSearchedTask("");
  };

  const [DropDownAssinee, setDropDownAssinee] = useState([]);

  const getAllDeals = async () => {
    const backendUrl = Config.backend_url + "getAllDealPages";
    var config = {
      token: localStorage.getItem("token"),
    };
    setLoader(true);
    axios
      .post(backendUrl, { ...config })
      // axios.post(backendUrl, { config: config })
      .then(async function (res) {
        for (let i = 0; i < subformApiName.length; i++) {
          if (subformApiName[i] !== "Deal_Name") {
            let res1 = await ProjectService.GetSubformData(subformApiName[i]);
            res1.forEach((subform) => {
              res.data.data.forEach((one, dealIdx) => {
                if (one.id == subform?.Parent_Id?.id) {
                  if (res.data.data[dealIdx][subformApiName[i]]) {
                    res.data.data[dealIdx][subformApiName[i]]?.push(subform);
                  } else {
                    res.data.data[dealIdx][subformApiName[i]] = [subform];
                  }
                }
              });
            });
          }
        }
        for (let i = 0; i < moduleApiNameForSubform.length; i++) {
          let res1 = await ModuleService.GetModuleListData(
            moduleApiNameForSubform[i]
          );
          res.data.data.forEach((deal, dealIdx) => {
            res1 = res1.filter(
              (one) => one.Deal_Name && one.Deal_Name.name == deal.Deal_Name
            );
            res.data.data[dealIdx][moduleApiNameForSubform[i]] = res1;
          });
        }
        //res.data.data=res.data.data.filter((one)=>one.Deal_Name=='S23-0117')
        AllClients.forEach((one, index) => {
          if (one.Project_ID) {
            res.data.data.push({
              Deal_Name: "Miscellaneous" + index,
              id: "Miscellaneous" + index,
              Account_Name: { id: one.id, name: one.Account_Name },
              Project_ID: one.Project_ID,
            });
          }
        });
        res.data.data.push({
          Deal_Name: "Misc ",
          id: "Misc",
          Account_Name: { id: "Misc", name: "Misc" },
          Project_ID: "1716273000002687005",
        });

        setDeals(res.data.data);
        setDealList(res.data.data);
        // res.data.data = res.data.data.map((one) => {
        //     one.Created_By = one.Created_By.name;
        //     one.Owner = one.Owner.name;
        //     one.Account_Name = one.Account_Name ? one.Account_Name.name : "";
        //     return one;
        // })
        // DealIcons(res.data.data);

        let arr = [];
        let originalData = [...res.data.data];
        res.data.data.forEach((deal, i) => {
          deal.isCheck = true;
          let obj = { DealId: deal.id, isOpen: true };
          arr.push(obj);
          // if (deal.Project_ID) {
          //     arr.push({ _id: deal.Project_ID, id: deal.id, isCheck: true, ProjectNumber: deal.Deal_Name })

          // } else {
          //     arr.push({ _id: "NA", id: deal.id, isCheck: true, ProjectNumber: deal.Deal_Name })

          // }
        });
        // if (res.data.data.length > 0) {
        //     res.data.data.unshift({ _id: 'allDeal', id: 'allDeal', Deal_Name: 'All', isCheck: true })
        // }
        // setGroupExpandFlag(arr);
        setDropDownDeals(res.data.data);
        setDealSearchResult(res.data.data);
        getGroupByFields(originalData, groupBy.Deal);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
      });
  };

  const DealIcons = (Deals) => {
    let dc = [];
    Deals.forEach((e, i) => {
      dc.push({
        ID: e,
        isOpen: true,
      });
    });
    setCollapseDeal(dc);
  };

  const toggleDealCollapse = (dealsId) => {
    let dc = [...CollapseDeal];
    CollapseDeal.forEach((e, i) => {
      if (e.ID == dealsId) {
        dc[i] = {
          ID: dealsId,
          isOpen: !e.isOpen,
        };
      }
    });
    setCollapseDeal(dc);
  };
  const getDealCollapse = (dealsId) => {
    let obj = CollapseDeal.find((e) => e.ID == dealsId);
    if (obj != undefined) {
      return obj.isOpen;
    } else {
      return true;
    }
  };

  const [SearchResult, setSearchResult] = useState([]); ///
  const [SelectedDeal, setSelectedDeal] = useState(null); // <--  Search Deal Const
  const [SearchedItem, setSearchedItem] = useState(""); ///

  const [filterTaskPopup, setFilterTaskPopup] = useState(false);

  const filtertasktoggle = () => {
    setFilterTaskPopup(!filterTaskPopup);
  };

  const [filterDueDatePopup, setFilterDueDatePopup] = useState(false);
  const filterDueDateToggle = () => setFilterDueDatePopup(!filterDueDatePopup);

  const [selectedTab, setSelectedTab] = useState("tasks");
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const getAllUsers = async () => {
    setLoader(true);
    UserService.GetAllUsers()
      .then((result) => {
        console.log(result.data.users);
        setUsers(result.data.users);
        let users = [...result.data.users];

        users.forEach((user, i) => {
          user.isCheck = true;
        });
        users.unshift({ id: "allUser", full_name: "All", isCheck: true });
        users.push({ id: "noUser", full_name: "No Assignee", isCheck: true });

        setDropDownAssinee(users);
        // setAssineeSearchResult(users);
        setAllUsers(users);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const [allTasks, setAllTasks] = useState([]);

  const getJobId = (taskId) => {
    let jobs = [...allJobs];
    let filterJob = jobs.filter((one) => one.zProjectsJobRefId == taskId);
    if (filterJob.length > 0) {
      return filterJob[0].jobId;
    } else {
      return "";
    }
  };

  const getTimeTrackLogs = (taskId) => {
    // let result = [...allTimeLogs];
    // result = result?.filter((one) => one.jobId == taskId)
    // console.log(result)
    // let totalTime = 0;
    // result.forEach((one) => {
    //     totalTime = totalTime + one.totaltime;
    // })
    // let obj = { TotalTime: totalTime, TimeTracks: result }
    // return obj;
  };
  const getAllTasks = async () => {
    setLoader(true);
    TaskService.GetMyTasks()
      .then(async (result) => {
        for (let i = 0; i < result.length; i++) {
          let timeTracksObj = getTimeTrackLogs(getJobId(result[i].id_string));
          let milestone = {}; //await getTaskMilestones(result[i].milestone_id);
          result[i].Milestone = milestone;
          result[i].TimeTrack = timeTracksObj;
          result[i].JobId = getJobId(result[i].id_string);
        }
        setAllTasks(result);
        result.forEach((task, i) => {
          task.TaskName = task.name;
          task._id = task.id_string;
          task.isCheck = true;
        });
        if (result.length > 0) {
          result.unshift({ TaskName: "All", _id: "allTask", isCheck: true });
        }
        setDropDownTasks(result);
        // setTaskSearchResult(result);
        setLoader(false);
      })
      .catch((ex) => {
        setLoader(false);
        console.log(ex);
      });
  };
  const [addColumns, setAddColumns] = useState([
    { headerName: "ASSIGNEE", isVisible: false },
    { headerName: "DUE DATE", isVisible: false },
    { headerName: "PRIORITY", isVisible: false },
    { headerName: "TIME ESTIMATE", isVisible: false },
    { headerName: "PROGRESS", isVisible: false },
    { headerName: "STATUS", isVisible: false },
    { headerName: "CATEGORY", isVisible: false },
  ]);

  // useEffect(() => {
  //   if (location.state.detail.action === "taskModalOpen") {
  //     setOpenModalDefault(location.state.detail.TaskUniqueId);
  //   }
  // }, [location])

  // const setOpenModalDefault = (taskId) => {
  //   let intervalTask = setInterval(() => {
  //     let element = document.getElementById('OpenUrlModal' + taskId);
  //     if (element) {
  //       element.click();
  //       clearInterval(intervalTask);
  //     }
  //   }, 100)

  // }

  const getDealByGroupName = (group, groupIdx) => {
    let deals = [...DropDownDeals];
    let tasks = [...DropDownTasks];
    // let filterResult = getFilterResult(filters, tasks, deals);
    // // getGroupByFields(filterResult.deals,groupBy.Deal)
    // deals = filterResult.deals;
    // tasks = filterResult.tasks;
    let value = groupByDealItem.filter((one) => one.name == groupBy.Deal)[0];
    value = value.value;
    if (groupBy.Deal == "Client") {
      deals = deals.filter((deal) => {
        if (deal.Account_Name && deal.Account_Name.name == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    } else if (groupBy.Deal == "Status") {
      deals = deals.filter((deal) => {
        if (deal.Project_Status && deal.Project_Status == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    } else if (groupBy.Deal == "Closing Date") {
      deals = deals.filter((deal) => {
        if (deal[value] && deal[value] == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    } else if (groupBy.Deal == "Block Number") {
      // let value=groupByDealItem.filter((one)=>one.name==groupBy.Deal)[0];
      // value=value.value
      deals = deals.filter((deal) => {
        if (deal[value] && deal[value] == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    } else if (groupBy.Deal == "Lot Number") {
      deals = deals.filter((deal) => {
        if (deal[value] && deal[value] == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    } else if (groupBy.Deal == "Bin Number") {
      deals = deals.filter((deal) => {
        if (deal[value] && deal[value] == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    } else if (groupBy.Deal == "Deal Name") {
      deals = deals.filter((deal) => {
        if (deal[value] && deal[value] == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    } else {
      deals = deals.filter((deal) => {
        if (deal[groupBy.Deal] && deal[groupBy.Deal] == group) {
          return deal;
        } else {
          if (group == "None") {
            return deal;
          }
        }
      });
    }
    let myTask = [];
    let taskToPass = [];
    deals.forEach((deal) => {
      if (deal.Project_ID) {
        let ids = deal.Project_ID;
        ids = ids.split(",");
        ids = ids.filter((id) => id);
        let task = tasks.filter(
          (task) => task.project && ids.includes(task.project.id_string)
        );
        let tasksPass = tasks.filter(
          (task) =>
            !task.parent_task_id &&
            task.project &&
            ids.includes(task.project.id_string)
        );
        task.forEach((one) => {
          one.Deal_Name = deal.Deal_Name;
          one.DealId = deal.id;
        });
        tasksPass.forEach((one) => {
          one.Deal_Name = deal.Deal_Name;
          one.DealId = deal.id;
        });
        myTask = [...task, ...myTask];
        taskToPass = [...taskToPass, ...tasksPass];
      }
    });
    if (assignedMeVisible) {
      let info = JSON.parse(localStorage.getItem("userinfo"));
      let name = info.first_name + " " + info.last_name;
      myTask = myTask.filter(
        (task) =>
          task.details.owners.length > 0 &&
          task.details.owners.filter((ow) => {
            if (name == ow.first_name + " " + ow.last_name) {
              return ow;
            }
          }).length > 0
      );
      taskToPass = taskToPass.filter(
        (task) =>
          task.details.owners.length > 0 &&
          task.details.owners.filter((ow) => {
            if (name == ow.first_name + " " + ow.last_name) {
              return ow;
            }
          }).length > 0
      );
    }
    if (taskToPass.length > 0) {
      let obj = { Deal_Name: "", DealId: "", ProjectId: [] };
      taskToPass.forEach((one) => {
        obj.Deal_Name = one.Deal_Name;
        obj.DealId = one.DealId;
        if (!obj.ProjectId.includes(one.project_id))
          obj.ProjectId.push(one.project_id);
      });
      let dealObj = deals.filter((one) => one.id == obj.DealId);
      dealObj = dealObj.length > 0 ? dealObj[0] : {};
      return React.cloneElement(
        <TaskList />,
        {
          taskFor: "Search",
          onSelect: props.onSelect,
          startstopClockTimeTrack: props.startstopClockTimeTrack,
          setTimerTimeTrack: props.setTimerTimeTrack,
          timerTimeTrack: props.timerTimeTrack,
          timerObj: props.timerObj,
          timeTrackFlag: props.timeTrackFlag,
          initializeTimeTrack: props.initializeTimeTrack,
          getAllTasks: getAllTasks,
          dependencyList: dependencyList,
          taskLocation: "Tasks",
          // onChangeRadio: onChangeRadio,
          AllClients: AllClients,
          dealStatus: dealStatus,
          history: history,
          dealGroupExpand: groupExpandFlag,
          Deals: Deals,
          dealGroup: group,
          taskLocationShow: taskLocationShow,
          showParentNames: showParentNames,
          columns: addTaskColumns,
          taskFilterSetting: taskFilterSetting,
          taskSearchSetting: taskSearchSetting,
          setOpenTaskModel: setOpenTaskModel,
          openTaskModal: openTaskModal,
          groupIdx: groupIdx,
          isView: props.isVisible,
          isAccess: props.isAccess,
          selectedRole: props.selectedRole,
          // handleOnChangeAddColumn: handleOnChangeAddColumn,
          dealObj: dealObj,
          dealInfo: obj,
          addColumns: addColumns,
          DropDownDeals: DropDownDeals,
          allUsers: allUsers,
          allJobs: allJobs,
          groupBy: groupBy.Task,
          allTasks: taskToPass,
          myTasks: myTask,
          setAllTasks: setAllTasks,
          id: "tasklist",
          dealsId: "none",
          groupExpandFlag: groupExpandFlag,
        },
        null
      );
    }
  };

  const getTaskCardbyId = (deal, tasksToPass) => {
    // let dealObj = Deals.filter(obj => obj.id == deal.id);
    let dealObj = deal;
    let myTasks = tasksToPass.filter(
      (task) => task.project && task.project.id_string == deal.Project_ID
    );
    tasksToPass = tasksToPass.filter(
      (task) =>
        !task.parent_task_id &&
        task.project &&
        task.project.id_string == deal.Project_ID
    );

    if (tasksToPass.length > 0 && allUsers.length > 0) {
      // tasksToPass = getFilterResult(filters, tasksToPass).tasks;
      return (
        // <></>
        React.cloneElement(
          <TaskList />,
          {
            taskFor: "Search",
            onSelect: props.onSelect,
            startstopClockTimeTrack: props.startstopClockTimeTrack,
            setTimerTimeTrack: props.setTimerTimeTrack,
            timerTimeTrack: props.timerTimeTrack,
            timerObj: props.timerObj,
            timeTrackFlag: props.timeTrackFlag,
            initializeTimeTrack: props.initializeTimeTrack,
            getAllTasks: getAllTasks,
            taskLocation: "Tasks",
            dependencyList: dependencyList,
            AllClients: AllClients,
            dealStatus: dealStatus,
            Deals: Deals,
            history: history,
            taskLocationShow: taskLocationShow,
            showParentNames: showParentNames,
            columns: addTaskColumns,
            taskFilterSetting: taskFilterSetting,
            taskSearchSetting: taskSearchSetting,
            setOpenTaskModel: setOpenTaskModel,
            openTaskModal: openTaskModal,
            isView: props.isVisible,
            isAccess: props.isAccess,
            selectedRole: props.selectedRole,
            // handleOnChangeAddColumn: handleOnChangeAddColumn,
            dealObj: dealObj,
            addColumns: addColumns,
            DropDownDeals: DropDownDeals,
            allUsers: allUsers,
            allJobs: allJobs,
            groupBy: groupBy.Task,
            allTasks: tasksToPass,
            myTasks: myTasks,
            setAllTasks: setAllTasks,
            id: "tasklist",
            dealsId: deal._id,
            groupExpandFlag: groupExpandFlag,
          },
          null
        )
      );
    } else {
      return <></>;
    }
  };

  const removeNumberWhenMisc = (group) => {
    if (group.startsWith("Misc")) {
      group = group.replace(/[0-9]/g, "");
    }
    return group;
  };

  const Test = (group) => {
    console.log(group);
  };

  const getDealNameWithClientName = (group) => {
    if (groupBy.Deal == "Deal Name") {
      let deals = [...DropDownDeals];
      deals = deals.filter((one) => one.Deal_Name == group);
      let colorObj = { background: "#5b6c97", color: "#fff" };
      let clientColorObj = { background: "#5b6c97", color: "#fff" };
      let filterClient = {};
      if (deals.length > 0) {
        deals = deals[0];
        if (deals.Project_Status_ID) {
          let color_code = dealStatus.filter(
            (one) => one.status_id == deals.Project_Status_ID
          );
          if (color_code.length > 0) {
            color_code = color_code[0];
            colorObj = {
              background: color_code.status_color_hexcode,
              color: "#fff",
            };
          }
        }

        if (AllClients.length > 0) {
          filterClient = AllClients.filter(
            (one) => deals.Account_Name && one.id == deals.Account_Name.id
          );
          if (filterClient.length > 0) {
            filterClient = filterClient[0];
            if (filterClient.Color) {
              clientColorObj.background = filterClient.Color;
            }
          }
        }
      }

      /* background: #5b6c97;
 color: #fff; */
      return (
        <>
          <a
            onClick={() => {
              history?.push({
                pathname: "/c/Accounts/DetailView/" + filterClient.id,
                state: {
                  data: filterClient,
                  action: "Update",
                  module: "Accounts",
                },
              });
            }}
            style={clientColorObj}
          >
            {" "}
            {deals.Account_Name ? deals.Account_Name.name : ""}
          </a>
          <span>{"/"}</span>
          <a
            style={colorObj}
            onClick={() => {
              deals.Created_By = deals.Created_By.name;
              deals.Owner = deals.Owner.name;
              deals.Account_Name = deals.Account_Name
                ? deals.Account_Name.name
                : "";
              history?.push({
                pathname: "/d/Deals/DetailView/" + deals.id,
                state: { data: deals, action: "DetailView", module: "Deals" },
              });
            }}
          >
            {removeNumberWhenMisc(group)}
          </a>
        </>
      );
    } else {
      let defaultColor = { background: "#5b6c97", color: "#fff" };
      if (groupBy.Deal == "Status") {
        let filterResult = dealStatus.filter((one) => one.status_name == group);
        if (filterResult.length > 0) {
          filterResult = filterResult[0];
          defaultColor = {
            background: filterResult.status_color_hexcode,
            color: "#fff",
          };
        }
      }

      return (
        <>
          <a style={defaultColor}>{group}</a>
        </>
      );
    }
  };

  const resetViewFilters = () => {
    let allheaderData = [...addTaskColumns];
    const deals = [...DropDownDeals];
    let searchObj = { ...taskFilterSetting };
    allheaderData.forEach((one) => {
      one.isChecked = false;
      one.isVisible = true;
    });
    setAddTaskColumns(allheaderData);
    getGroupByFields(deals, "Deal Name");
    setGroupBy({ Task: "Pipeline", Deal: "Deal Name" });
    setCacheGroupBy({ Task: "Pipeline", Deal: "Deal Name" });
    setAssignedMeVisible(false);
    searchObj.ViewSeparateTask = false;
    searchObj.TaskLocation = false;
    searchObj.SubtaskParntNames = false;
    setTaskFilterSetting(searchObj);
  };

  const setViewFilters = (viewData) => {
    if (viewData.length > 0) {
      const deals = [...DropDownDeals];
      let searchObj = { ...taskFilterSetting };
      viewData = viewData[0];

      setCacheGroupBy(viewData.group_by);
      setAssignedMeVisible(viewData.is_me_selected);
      setAddTaskColumns(viewData.columns);
      setGroupBy(viewData.group_by);
      getGroupByFields(deals, viewData.group_by.Deal);
      searchObj.TaskLocation = viewData.is_task_location;
      searchObj.SubtaskParntNames = viewData.is_parent_name;
      searchObj.ViewSeparateTask = viewData.is_seprate_task;
      searchObj.ExpandDeals = viewData.is_expand_deal;
      searchObj.ExpandGroups = viewData.is_expand_group;
      searchObj.ExpandTasks = viewData.is_expand_task;
      setTaskFilterSetting(searchObj);
    }
  };

  return (
    <div className="project-tasks-wrapper">
      {loader ? <Loader /> : <></>}
      <div className="task-tab pr-0 row">
        <div className="project-deals-height">
          {dealGroupBy.map((group, groupIdx) => {
            return (
              <div className="mb-2">
                {SelectedDeal == null ? (
                  <>
                    <h5 className="deal-num d-flex align-items-center mb-2">
                      <span
                        id={"toggle-collapse" + group.replace(/[- )(]/g, "")}
                        onClick={() => toggleDealCollapse(group)}
                      >
                        <i className="deal-toggle-down">
                          <FontAwesomeIcon
                            style={{ marginBottom: "1px" }}
                            id={"collapse-" + group.replace(/[- )(]/g, "")}
                            className="task-toggle-down-alt"
                            icon={
                              getDealCollapse(group)
                                ? faChevronDown
                                : faChevronRight
                            }
                          />
                        </i>
                      </span>

                      <span
                        id={"toggle-collapse" + group.replace(/[- )(]/g, "")}
                        onClick={() => Test(group)}
                      >
                        {getDealNameWithClientName(group)}
                      </span>
                    </h5>
                    <UncontrolledCollapse
                      toggler={"toggle-collapse" + group.replace(/[- )(]/g, "")}
                      isOpen={getDealCollapse(group)}
                    >
                      {getDealByGroupName(group, groupIdx)}
                    </UncontrolledCollapse>
                  </>
                ) : (
                  <>
                    <h5 className="deal-num mb-2">
                      <span>{SelectedDeal.ProjectNumber}</span>
                    </h5>
                    {getTaskCardbyId(
                      SelectedDeal._id,
                      DropDownTasks.filter((task) => task.isCheck == true)
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TaskSearchComponent;
