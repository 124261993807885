import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./ClientProgress.css";
import Tasks from "../Task/task";
// import Gantt from "../Gantt/gantt";
// import Dashboard from "../dashboard/dashboard";
import Milestone from "../Milestone/milestone";
import _ from 'lodash';
import TaskListWithDeal from "../../common/List/TaskListWithDeal";
import DealList from "../../components/Deal/DealList";
const ClientProgress = (props) => {
    const [key, setKey] = useState("deals");
    const [DealsId, setDealsId] = useState("");
    // useEffect(() => {
    //     if (props.dealObj.Project_ID) {
    //         setDealsId(props.dealObj.Project_ID);
    //     }
    // }, [props.dealObj]);
    // const getDealObj=(clone)=>{
    //     let dealObj=_.cloneDeep(clone);
    //     delete dealObj.zoningList;
    //     delete dealObj.commercialList;
    //     delete dealObj.specialDistList;
    //     return dealObj
    // }
    return (
        <div>
            <Tabs
                style={key == 'milestones' ? { marginBottom: '12px' } : {}}
                id="progress-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="job-middle-tab job-middle-tabs progress-inner-tabs"
            >
                <Tab eventKey="deals" title="Deals">
                    <DealList
                        setCheckType={props.setCheckType}
                        checkType={props.checkType}
                        selectedIds={props.selectedIds}
                        invertColor={props.invertColor}
                        setSelectedIds={props.setSelectedIds}
                        deleteDealFlag={props.deleteDealFlag}
                        setDeleteDealFlag={props.setDeleteDealFlag}
                        refreshEnable={props.refreshEnable}
                        setRefreshEnable={props.setRefreshEnable}
                        dealLocation={"Accounts"}
                        data={props.data}
                        params={props.params}
                        history={props.history}
                        location={props.location} />
                </Tab>
                <Tab className={"client-body-task"} eventKey="tasks" title="Tasks">
                    <TaskListWithDeal
                        history={props.history}
                        invertColor={props.invertColor}
                        refreshEnable={props.refreshEnable}
                        setRefreshEnable={props.setRefreshEnable}
                        setTimerPrevTimeTrack={props.setTimerPrevTimeTrack}
                        startstopClockTimeTrack={props.startstopClockTimeTrack}
                        setTimerTimeTrack={props.setTimerTimeTrack}
                        timerTimeTrack={props.timerTimeTrack}
                        timerObj={props.timerObj}
                        timeTrackFlag={props.timeTrackFlag}
                        initializeTimeTrack={props.initializeTimeTrack}
                        taskLoaction={"Accounts"} data={props.data} />
                </Tab>
                {/* <Tab eventKey="gantt" title="Gantt & Reports">
            Gantt Reports
        </Tab>
        <Tab eventKey="milestones" title="Milestones">
          <Milestone allMilstones={props.allMilstones} dealObj={props.dealObj} eventKey={key} projectId={DealsId} />
       
        </Tab> */}
                {/* <Tab eventKey="timesheet" title="Timesheet">
       
          Timesheet
        </Tab>
        <Tab eventKey="issues" title="Issues">
          Issues
        </Tab>
        <Tab eventKey="activity" title="Activities">
          All Task Activities
        </Tab> */}
            </Tabs>
        </div>
    );
};

export default ClientProgress;
