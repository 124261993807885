import React, { useState, useEffect, useRef } from "react";
import "./filter.css";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Collapse,
  Row,
  Input,
  FormText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { OverlayTrigger, NavDropdown, Dropdown } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";

import {
  faAngleDown,
  faAngleRight,
  faCaretDown,
  faCaretRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";

const systemNames = [{
  name : "AssignedTasks"
},
  {
    name : "Test 2"
  },
  {
    name : "Test 3"
  }
  ]

const Filter = (props) => {
  const [systemToggle, setSystemToggle] = useState(true);
  const [filterToggle, setFilterToggle] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  // const [filterFields, setFilterFields] = useState(["Job Number"," Client","Project Date","Project Address","Close Date","Deal Status","Administrative Status"]);
  const [searchedFields, setSearchedFields] = useState([]);
  const [filterchecked, setfilterchecked] = useState("");
  const [filterName, setFilterName] = useState("is");
  const [filterType, setFilterType] = useState("");
  const [filterText, setFilterText] = useState("");
  const [filterText2, setFilterText2] = useState("");
  const [duration, setduration] = useState("days");
  const [systemDefine, setSystemDefine] = useState(systemNames);
  const [dateError, setDateError] = useState("");
  const [filterTextDate, setFilterTextD] = useState("");
  const [filterTextDate2, setFilterTextD2] = useState("");
  const showFilterBtn = useRef("")
  useEffect(() => {
    setSearchedFields(props.headerData)
   },[props.headerData]);
 

  const applyFilter2=()=>{
    if(filterType=="date" && (filterName=="On" || filterName== "after"||  filterName== "before" ||  filterName== "between" )){

      let dateRegex = /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;
     
        props.applyFilters(filterchecked, filterName, filterType, filterText, filterText2, duration, filterTextDate)
     
    }
    else{
      props.applyFilters(filterchecked, filterName, filterType, filterText, filterText2, duration, filterTextDate, filterTextDate2)
    }
    
  }


  const onChangeFilterText = (value) => {
    setFilterText(value);
    if(value===""){
      setDateError("");
    }
  };
  const onChangeFilterText2 = (value) => {
    setFilterText2(value);
    if(value===""){
      setDateError("");
    }
  };

  const onChangeFilterDeal = (value) => {

    setFilterToggle(true);
    setSystemToggle(true);
    var fields = [];
    props.headerData.map((field, index) =>
      field.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        ? fields.push(field)
        : console.log("")
    );
    if(undefined !== value || ""!==value) {
      let newData = systemNames.filter(e=> e.name.toLowerCase().includes(value.toLowerCase()))
      //console.log("new list ..."+JSON.stringify(newData));
      setSystemDefine(newData);
    }
    

    setSearchedFields(fields);//tbl data
    setSearchValue(value);//search
  };

  const setfiltercheckedField = (fields) => {
    if (fields.field != filterchecked) {
      setfilterchecked(fields.field);
      setFilterType(fields.type);
      setFilterText2("")
      setFilterText("");
      showFilterBtn.current = fields.field
    } else {
      setfilterchecked("");
      showFilterBtn.current = ""
    }

    if (fields.type == "number") {
      setFilterName("=");
    } else if (fields.type == "date") {
      setFilterName("in the last");
    } else if (fields.type == "radio") {
      setFilterName("Selected");
    }else {
      setFilterName("is");
    }
  };

  useEffect(()=>{
    if(props.clearFilterFlag){
      clearFilters();
      showFilterBtn.current = ""
    }
    props.setShowFilter(showFilterBtn.current)
  },[props.clearFilterFlag,showFilterBtn.current])

  const clearFilters = () => {
    setSearchValue("");
    setFilterText("");
    setfilterchecked("");
    setSearchedFields(props.headerData);
    props.applyFilters(filterchecked, "clear", "");
    showFilterBtn.current = ''
  };

  return (
    <Col className="col-2-border outer2">
      <div className="filter-box">
        <b>Filter Deals :</b>{" "}
      </div>
      <FormGroup className="pos-relative">
        <Input
          type="text"
          placeholder="Search"
          className="mr-sm-2 pad-fix search-filter"
          value={searchValue}
          onChange={(e) => onChangeFilterDeal(e.target.value)}
        />

        <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
      </FormGroup>

      <div className="filter-overflow">
        <div
          class="filter-list-style font-14"
          onClick={() => {
            setSystemToggle(!systemToggle);
            setFilterToggle(filterToggle);
          }}
        >
          {systemToggle ? (
            <FontAwesomeIcon className="mar-right-5" icon={faCaretDown} />
          ) : (
            <FontAwesomeIcon className="mar-right-5" icon={faCaretRight} />
          )}
          System Defined Filters
        </div>
        <Collapse isOpen={systemToggle}>
          {systemDefine.length > 0 ? systemDefine.map(e=>
            <ListGroupItem className="filter-list-item pad-9">
            <Input className="form-check-input2" type="checkbox" />
            {e.name}
          </ListGroupItem>
          ): (<div className="filter-list-item pad-9">No Records Found</div>)
        }
          
          {/*<ListGroupItem className="filter-list-item pad-9">
            <Input className="form-check-input2" type="checkbox"
            
            />
            Test 2
          </ListGroupItem>
          <ListGroupItem className="filter-list-item pad-9">
            <Input className="form-check-input2" type="checkbox" />
            Test 3
          </ListGroupItem>*/}
        </Collapse>

        <div
          class="filter-list-style font-14"
          onClick={() => {
            setFilterToggle(!filterToggle);
          }}
        >
          {filterToggle ? (
            <FontAwesomeIcon className="mar-right-5" icon={faCaretDown} />
          ) : (
            <FontAwesomeIcon className="mar-right-5" icon={faCaretRight} />
          )}
          Filter By Fields
        </div>
        <Collapse isOpen={filterToggle}>
          {searchedFields.length > 0 ? searchedFields.map((fields, index) => (
            !fields.isChecked ?(
            <ListGroupItem className="filter-list-item pad-9">
              <Input
                className="form-check-input2"
                type="checkbox"
                checked={filterchecked == fields.field}
                onClick={() => {setfiltercheckedField(fields); }}
              />
              {fields.name}
              <Collapse isOpen={filterchecked == fields.field}>
                <Dropdown className="filterType-dropdown" drop='down'>
                  <Dropdown.Toggle
                    id="dropdown-filter"
                    className="filterType-toggle"
                    
                  >
                    <span className="pad-r5">{filterName}</span>
                  </Dropdown.Toggle>

                  {fields.type == "number" ? (
                    <Dropdown.Menu className="filterTypeMenu">
                      <Dropdown.Item onClick={() => setFilterName("=")}>
                        {"="}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("!=")}>
                        {"!="}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("<")}>
                        {"<"}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("<=")}>
                        {"<="}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName(">")}>
                        {">"}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName(">=")}>
                        {">="}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("between")}>
                        between
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("not between")}
                      >
                        not between
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("is empty")}>
                        is empty
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("is not empty")}
                      >
                        is not empty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  ) : fields.type == "date" ? (
                    <Dropdown.Menu className="filterTypeMenu">
                      <Dropdown.Item
                        onClick={() => setFilterName("in the last")}
                      >
                        in the last
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("due in")}>
                        due in
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("On")}>
                        On
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("before")}>
                        before
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("after")}>
                        after
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("between")}>
                        between
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("Today")}>
                        Today
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("Yesterday")}>
                        Yesterday
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("This Week")}>
                        This Week
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("This Month")}
                      >
                        This Month
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("This Year")}>
                        This Year
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("Last Week")}>
                        Last Week
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("Last Month")}
                      >
                        Last Month
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("is empty")}>
                        is empty
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("is not empty")}
                      >
                        is not empty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  ) :fields.type == "radio" ? (
                    <Dropdown.Menu className="filterTypeMenu">
                      <Dropdown.Item onClick={() => setFilterName("Selected")}>
                        Selected
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("Unselected")}>
                        Unselected
                      </Dropdown.Item>
                    
                    </Dropdown.Menu>
                  ) : (
                    <Dropdown.Menu className="filterTypeMenu">
                      <Dropdown.Item onClick={() => setFilterName("is")}>
                        is
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("isn't")}>
                        isn't
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("contains")}>
                        contains
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("doesn't contain")}
                      >
                        doesn't contain
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("starts with")}
                      >
                        starts with
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("ends with")}>
                        ends with
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterName("is empty")}>
                        is empty
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setFilterName("is not empty")}
                      >
                        is not empty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                {((fields.type == "text")&&(filterName !="is empty") &&(filterName !="is not empty")) ||
                ((fields.type == "number")&&(filterName !="is empty") &&(filterName !="is not empty")
                &&(filterName !="between") &&(filterName !="not between"))? (
                <Input
                  type="text"
                  placeholder="Type Here"
                  className="mr-sm-2 pad-fix search-filter1"
                  value={filterText}
                  onChange={(e) => onChangeFilterText(e.target.value)}
                />):
                (fields.type == "date")&&((filterName =="On")||(filterName =="before")||(filterName =="after")) ?
                <>
                
                 <DatePicker
                  selected={filterTextDate}
                   dateFormat="MM/dd/yyyy"
                   placeholderText="mm/dd/yyyy"
                    onChange={(date) =>
                    setFilterTextD(date)
                  }
                  popperProps={{
                    popperPlacement:"top-end",
                    positionFixed: true // use this to make the popper position: fixed
                  }}
                 /> 
                <span style={{color:"red"}}>{dateError}</span>
                
                </>
                :
                (fields.type == "date")&&((filterName =="in the last")||(filterName =="due in")) ?
               <div>
                 <Input
                  type="text"
                  className="mr-sm-2 pad-fix search-filter3"
                  value={filterText}
                  onChange={(e) => onChangeFilterText(e.target.value)}
                />
               <Dropdown className="filterType-dropdown">
                <Dropdown.Toggle
                  id="dropdown-filter"
                  className="filterType-toggle"
                >
                  <span className="pad-r5">{duration}</span>
                </Dropdown.Toggle>
                  <Dropdown.Menu className="filterTypeMenu">
                    <Dropdown.Item onClick={() => setduration("days")}>
                      days
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setduration("weeks")}>
                      weeks
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setduration("months")}>
                      months
                    </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                    </div>
               :((fields.type == "date")&&(filterName =="between"))?
               <>
                
                 <DatePicker
                  selected={filterTextDate}
                 // calendarContainer={}
                  popperPlacement="top-end"
                  //popperClassName="mr-sm-2 pad-fix search-filter1"
                  dateFormat="MM/dd/yyyy"
                  
                  onChange={(date) =>
                    setFilterTextD(date)
                  }
                 />- 
                 <DatePicker
                  selected={filterTextDate2}
                 // calendarContainer={}
                  popperPlacement="top-end"
                  //popperClassName="mr-sm-2 pad-fix search-filter1"
                  dateFormat="MM/dd/yyyy"
                  
                  onChange={(date) =>
                    setFilterTextD2(date)
                  }
                 />
                
                </>
               :
               ((fields.type == "number")&&((filterName =="between")||(filterName =="not between")))  ?
               <div>
                 <Row>
                   <Col sm={5} className="pad-00">
               <Input
                type="text"
                placeholder="From"
                className="mr-sm-2 pad-fix search-filter2"
                value={filterText}
                onChange={(e) => onChangeFilterText(e.target.value)}
              /></Col>
              -
              
               <Col sm={5} className="pad-00">
               <Input
                type="text"
                placeholder="To"
                className="mr-sm-2 pad-fix search-filter2"
                value={filterText2}
                onChange={(e) => onChangeFilterText2(e.target.value)}
              />
              </Col>
              <span style={{color:"red"}}>{dateError}</span>
              </Row>
              </div>
               : (<></>)}
              </Collapse>
            </ListGroupItem>
          ) : null
          )
          
          )
          : (<div className="filter-list-item pad-9">No Records Found</div>)
          }
        </Collapse>
      </div>
      <div className="filterbtn-box">
        <Button
          className="applyfilter-btn blue-background3D-signfilter"
          onClick={() => applyFilter2()
           // props.applyFilters(filterchecked, filterName, filterType, filterText, filterText2, duration)

          }
        >
          Apply Filter
        </Button>
        <Button className="clear-btn" onClick={clearFilters}>
          Clear
        </Button>
      </div>
    </Col>
  );
};

export default Filter;
