import httpService from "../../services/http.service";

class AttendenceService{
    CreateTimeSheet(fromDate,toDate,emailid) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://people.zoho.com/people/api/timetracker/createtimesheet?user="+emailid+"&timesheetName=Daily Timesheet&description=Worked on System Analysis&dateFormat=dd/MM/yyyy&fromDate="+fromDate+"&toDate="+toDate+"&billableStatus=all&jobId=all&projectId=all&clientId=all&sendforApproval=true",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config :config})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }


    GetAllTimeSheet(fromdate,todate,emailid) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://people.zoho.com/people/api/attendance/getRegularizationRecords?fromdate="+fromdate+"&todate="+todate+"&employeeId=001&startIndex=0",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

  

}

export default new AttendenceService();