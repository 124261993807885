import React, { useState } from 'react'
import swal from 'sweetalert';
import './ReminderShortcutPopOver.css'
import AwsServerService from '../../common/util/AwsServerService';
import { convertToUSTime, modifyTimezoneString } from '../HR/TimeClock/Util';
import dayjs from 'dayjs';
import { Button, Col, Row } from 'reactstrap';
import { getHeaderStatement } from '../EmailSection/emailUtil';
import { Popover as AntdPopover } from "antd";
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ReminderSvg from '../../../src/assets/images/svg/AlarmReminderBlack.svg';
export const ReminderShortCutPopOver = ({reminders,onOpenReminder}) => {
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    const [openSnooze,setOpenSnooze] = useState({id:null,open:false});
    const [snoozeTime,setSnoozeTime] = useState(null);
    const handleSnooze=(data,time,custome)=>{
         
        let payLoad={
            ROWID:data.ROWID,
            triggered:"false",
        };
        if(!custome){
            payLoad.reminderTime=convertToUSTime(dayjs(modifyTimezoneString(data.reminderTime,"MM/DD/YYYY HH:mm")).add(time,"minutes"));
        }else{
            if(!time){
                time=dayjs().add(10, "minutes").format("HH:mm");
            }
            let tempDateStr=modifyTimezoneString(data.reminderTime,"MM/DD/YYYY")+" "+time;
            payLoad.reminderTime=convertToUSTime(tempDateStr);
        }
        AwsServerService.UpdateReminders(payLoad).then(res=>{
             
            console.log(res);
            setSnoozeTime(null);
        }).catch(e=>{
            console.log(e);
        })
        setOpenSnooze({id:null,open:false});
    }
    const handleCompleted=(data)=>{
         
        let payLoad={
            ROWID:data.ROWID,
            triggered:"false",
        };
        payLoad.status="true";
        payLoad.triggered="false";
        AwsServerService.UpdateReminders(payLoad).then(res=>{
             
            console.log(res);
        }).catch(e=>{
            console.log(e);
        })
    }
    const handleDelete=(data)=>{
        swal({
            title: "Are you sure?",
            text: "You want to delete the reminder!",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
                AwsServerService.DeleteReminders([data.messageId]).then(res=>{
                     
                    console.log(res);
                }).catch(e=>{
                    console.log(e);
                })
            }
          });
    }
    const getSnoozePopover=(data)=>{
        return ( <div className="Snooze-Content">
            <Row sm={12}>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                    handleSnooze(data,10)
                }} >10 min</Col>
                <Col className='Preset-Cards' sm={3}onClick={e=>{
                    handleSnooze(data,20)
                }}>20 min</Col>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                    handleSnooze(data,30)
                }}>30 min</Col>
            </Row>
            <div className='Custome-Snooze-Time'><input
                type="time"
                className="form-control form-control-sm"
                format="hh:mm a"
                // style={validation.out=='-1'?{...validationCss}:{}}
                style={{ height: '2.2rem' }}
                onChange={(e) => {
                    setSnoozeTime(e.currentTarget.value)
                }}
                value={snoozeTime ? snoozeTime : dayjs().add(10, "minutes").format("HH:mm")}
            />
                <Button style={{"line-height":"0.3rem"}} className='btn btn-primary' onClick={e=>{
                    handleSnooze(data,snoozeTime,true)
                }}>Set</Button>
            </div>
        </div>)
    }
    const getReminderSection=(data)=>{
        return ( <div className="Reminder-Content-Wrapper">
          <Row className='Reminder-Popup-Heading'>Reminders</Row>
          {data?.filter(r => r.userEmail == userinfo.email_id && r.status != "true")?.map((r, i) => {
                                        return <>
                                            <Row className='Card' onClick={e=>{
                                                e.stopPropagation();
                                                onOpenReminder(r);
                                            }}>
                                                {/* <Row style={{
                                                    "padding": "0px 8px",
                                                    "font-weight": "500",
                                                }}>{getHeaderStatement(r)?.text}
                                                </Row> */}
                                                <Row>
                                                    {/* <Col sm={1}>{i + 1}</Col> */}
                                                    <Col className='Head-Text-Link' sm={10} style={{
                                                        "padding": "0px 8px",
                                                        "font-weight": "500",
                                                    }}>{getHeaderStatement(r)?.text}</Col>
                                                    <Col className='Head-Text-Link' style={{
                                                        "display": "flex",
                                                        "align-items": "center",
                                                        "justify-content": "end",
                                                    }} sm={2}><FontAwesomeIcon icon={faExpandAlt} /></Col>
                                                    {/* <Col sm={4} className='Option-Wrapper'>
                                                        <OtsideClickClosingWrapper className={"Options"}
                                                            close={e=>{
                                                                setOpenSnooze({id:null,open:false})
                                                            }}
                                                        >                                             
                                                            {getHeaderStatement(r)?.status != 2 &&
                                                                <AntdPopover
                                                                    open={openSnooze.open && r.ROWID+"AA"==openSnooze.id}
                                                                    overlayClassName={`Snooze-Popover Do-Not-Close ROWID ROWID-${r.ROWID}`}
                                                                    style={{ zIndex: 9999 ,"width":"max-content"}}
                                                                    trigger="click"
                                                                    placement="bottomLeft"
                                                                    content={()=>getSnoozePopover(r)}
                                                                    onOpenChange={e=>{
                                                                         
                                                                        setOpenSnooze({id:r.ROWID+"AA",open:true});
                                                                    }}
                                                                    arrow={true}
                                                                >
                                                                    <span>Snooze</span>
                                                                </AntdPopover>
                                                            }
                                                            <span onClick={e=>{
                                                                handleCompleted(r)
                                                            }}>Complete</span>
                                                            <span onClick={e=>{
                                                                handleDelete(r)
                                                            }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                                       
                                                        </OtsideClickClosingWrapper>
                                                        
                                                    </Col> */}
                                                </Row>
                                            </Row>
                                        </>
                                    })}
        </div>)
    }
  return (
    <div>
        <AntdPopover
        //   open={true}
          overlayClassName={`Snooze-Popover Do-Not-Close`}
          style={{ zIndex: 9999, "width": "max-content" }}
          trigger="hover"
          placement="topLeft"
          content={() => getReminderSection(reminders)}
          arrow={true}
        >
          <div
            style={{
              "display": "flex",
              "height": "1.1rem",
              "margin-left": "1rem"
            }}
          >
            <img src={ReminderSvg} />
          </div>
        </AntdPopover> 
    </div>
  )
}
