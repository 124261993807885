import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from 'react'
import CompactView from '../CompactView/CompactView';

export default function AgGridReactComponent({
    activeHeaderData,
    onExpandOrCollapse, AllCommunityModules, AllModules, ColumnFilter, CustomDateComponent, CustomEditorComponent,
    CustomHeaderComponent, onFirstDataRendered, onSelectionChanged, onColumnPinned, onGridReady,setSouldRender,
    getPagingValues, rowData, staticCellStyle, groupStatus, getColumnsBySection,viewType,ref,triggerGridApiEffect,settriggerGridApiEffect,
    onChangeColumnFilters,sectionsState,setComactViewGridApis,updateCompactViewFileters,compactViewGridApis,setGroupStatus, excelStyles
}) {
    const[uniqueDataForListView,setUniqueDataForListView]=useState([]);
    const gridRef = useRef(null);
    useEffect(e=>{      
        let arr=[];
        let d=rowData.filter(r=>{
            if(!arr.includes(r.Deal_Name)){
                arr.push(r.Deal_Name);
                return r;
            }
        })
        setUniqueDataForListView(d);
    },[rowData])

    const getTableOnType=(typeOfTable)=>{
        if(viewType=="List"){
            return (<AgGridReact
                onFilterChanged={onChangeColumnFilters}
                onColumnGroupOpened={onExpandOrCollapse}
                excelStyles={excelStyles}
                ref={gridRef}
                //onColumnGr={onExpandOrCollapse}
                modules={(AllCommunityModules, AllModules)}
                paginationNumberFormatter={function (params) {
                    return params.value.toLocaleString();
                }}
                defaultColDef={{
                    sortable: true,
                    resizable: true,
                    flex: 1,
                    minWidth: 140,
                }}
                frameworkComponents={{
                    columnFilter: ColumnFilter,
                    agDateInput: CustomDateComponent,
                    customEditor: CustomEditorComponent,
                    CustomHeaderComponent: CustomHeaderComponent
                }}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
                cacheQuickFilter={true}
                suppressDragLeaveHidesColumns={true}
                suppressPaginationPanel={true}
                onColumnPinned={onColumnPinned}
                // debug={true}
                rowSelection={"multiple"}
                groupSelectsChildren={true}
                suppressRowClickSelection={true}
                enableRangeSelection={true}
                pagination={true}
                paginationPageSize={50}
                onGridReady={onGridReady}
                onPaginationChanged={getPagingValues}
                rowData={uniqueDataForListView}
            >
                <AgGridColumn
                    pinned="left"
                    field=""
                    maxWidth={55}
                    lockPosition={true}
                    // pinned='left'
                    cellClass="locked-col"
                    checkboxSelection={true}
                    headerCheckboxSelection={true}
                    cellStyle={staticCellStyle}
                    suppressNavigable={true}
                ></AgGridColumn>
              
                {getColumnsBySection("All", "")}
              
            </AgGridReact>)
        }else{
           return (<CompactView
                activeHeaderData={activeHeaderData}
                onExpandOrCollapse={onExpandOrCollapse}
                setComactViewGridApis={setComactViewGridApis}
                compactViewGridApis={compactViewGridApis}
                onChangeColumnFilters={onChangeColumnFilters}
                setSouldRender={setSouldRender}
                AllCommunityModules={AllCommunityModules}
                AllModules={AllModules}
                ColumnFilter={ColumnFilter}
                CustomDateComponent={CustomDateComponent}
                CustomEditorComponent={CustomEditorComponent}
                CustomHeaderComponent={CustomHeaderComponent}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
                onColumnPinned={onColumnPinned}
                onGridReady={onGridReady}
                getPagingValues={getPagingValues}
                rowData={rowData}
                staticCellStyle={staticCellStyle}
                groupStatus={groupStatus}
                sectionsState={sectionsState}
                getColumnsBySection={getColumnsBySection}
                unExpandedRowData={uniqueDataForListView}
                triggerGridApiEffect={triggerGridApiEffect}
                settriggerGridApiEffect={settriggerGridApiEffect}
                setGroupStatus={setGroupStatus}
                updateCompactViewFileters={updateCompactViewFileters}
            />)
        }
    }

    return getTableOnType(viewType);
}
