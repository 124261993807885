import { getHeaderStatement } from "../../components/EmailSection/emailUtil";
import httpService from "../../services/http.service";
import NotificationAPI from "./NotificationAPI";
import ProjectService from "./ProjectService";
import GetRecordById from "./getRecordById";
import validation from "./validation";
import swal from 'sweetalert'
import Config from '../../config.json'
class TaskService {
    GetAllProjectActivity() {
        return new Promise((resolve, reject) => {
            this.GetAllProjects()
                .then((result) => {
                    let doArr = [];
                    result.data.projects.forEach((one) => {
                        doArr.push(this.GetProjectActivity(one.link.activity.url))
                    })
                    return Promise.all(doArr);
                })
                .then((result) => {
                    resolve(result)
                })
                .catch((err) => {
                    reject(err);
                })

        })
    }

    GetProjectActivity(link) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: link,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetAllProjects() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config: {...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetProjectTask(projectId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config:config})
                .then(async (result) => {
                    let res = await ProjectService.GetProjectDetail(projectId)
                    if (res) {
                        if (result.data && result.data.tasks) {
                            result.data.tasks.forEach((one) => {
                                one.project_id = projectId;
                                one.project_name = res.name;
                                if (one.custom_fields) {
                                    one.custom_fields.push({ label_name: 'Pipeline', value: res.name })
                                } else {
                                    one.custom_fields = [{ label_name: 'Pipeline', value: res.name }]
                                }
                            })
                            resolve(result.data.tasks);
                        } else {
                            resolve([]);
                        }

                    } else {
                        resolve([]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetAllTaskOfProject(projectId) {
        return new Promise((resolve, reject) => {
            projectId = projectId.split(',');
            projectId = projectId.filter(id => id);
            let doArr = []
            projectId.forEach((id) => {
                doArr.push(this.GetProjectTask(id))
            })
            Promise.all(doArr)
                .then((result) => {
                    result = validation.RemoveNestedArray(result);

                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })

    }

    GetAllSubTaskOfTask(projectId, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/subtasks/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config:config})
                .then(async (result) => {
                    let res = await ProjectService.GetProjectDetail(projectId)
                    if (res && result.data) {
                        result.data.tasks.forEach((one) => {
                            one.project_id = projectId;
                            one.project_name = res.name;
                            if (one.custom_fields) {
                                one.custom_fields.push({ label_name: 'Pipeline', value: res.name })
                            } else {
                                one.custom_fields = [{ label_name: 'Pipeline', value: res.name }]
                            }
                        })
                        resolve(result.data.tasks);
                    } else {
                        resolve([]);
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetTaskActivity(projectId, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/activities/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config:config})
                .then((result) => {
                    if (result.data) {
                        resolve(result.data.activities);
                    } else {
                        resolve([]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    StartTimeTrack(email, workDate, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "http://people.zoho.com/people/api/timetracker/timer?user=" + email + "&jobId=" + taskId + "&workDate=" + workDate + "&timer=start&billingStatus=Billable",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    ResumeAndPauseTimeTrack(timeLogId, timer) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "http://people.zoho.com/people/api/timetracker/timer?timeLogId=" + timeLogId + "&timer=" + timer,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetAllTimeTrack(email, startDate, endDate, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://people.zoho.com/people/api/timetracker/gettimelogs?user=" + email + "&jobId=" + taskId + "&fromDate=" + startDate + "&toDate=" + endDate + "&billingStatus=all",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    if (result.data && result.data.response) {
                        resolve(result.data.response.result);
                    } else {
                        resolve([]);
                    }

                })
                .catch((err) => {
                    resolve([]);
                })
        })
    }

    GetCurrentlyRunningTimer() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://people.zoho.com/people/api/timetracker/getcurrentlyrunningtimer?",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data.response.result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    DeleteTimeTrack(timeLogId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://people.zoho.com/people/api/timetracker/deletetimelog?timeLogId=" + timeLogId,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data.response.result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    addTimeTrack(jobId, jobName, workDate, email, fromTime, toTime) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "http://people.zoho.com/people/api/timetracker/addtimelog?user=" + email + "&jobId=" + jobId + "&workDate=" + workDate + "&billingStatus=Billable&fromTime=" + fromTime + "&toTime=" + toTime,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data.response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    UpdateTask(projectId, taskId, key, data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: { key: key, content: data }
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    if (result.data.error) {
                        if (result.data.error.details)
                            var span = document.createElement("span");
                        span.innerHTML = result.data.error.details.message;
                        swal({
                            title: "" + "Warning" + "",
                            content: span,
                            confirmButtonText: "OK",
                            allowOutsideClick: "false"

                        });
                        resolve(null);
                    } else {
                        resolve(result.data.tasks[0]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    UpdateTaskMultipleFields(projectId, taskId, data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: data
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    if (result.data.error) {
                        resolve({});
                    } else {
                        resolve(result.data.tasks[0]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    AddTaskComment(projectId, taskId, key, data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/comments/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: { key: key, content: data }
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    UpdateTaskComment(projectId, taskId, key, data, commentId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/comments/" + commentId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: { key: key, content: data }
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    DeleteTaskComment(projectId, taskId, commentId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/comments/" + commentId + "/",
                headers: { "Authorization": localStorage.getItem('token') },

            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetTaskAttachements(projectId, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/attachments/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config:config})
                .then((result) => {
                    if (!result.data) {
                        result.data = []
                    }
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    DeleteTaskAttachements(projectId, taskId, attachementId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/attachments/" + attachementId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config:config})
                .then((result) => {
                    resolve(result.data.response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    AddTaskAttachements(projectId, taskId, fileObj) {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Upload",
                data: {
                    filename: fileObj.fileName,
                    content: fileObj.file,
                },
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/",
                method: "POST",
                headers: { "Authorization": localStorage.getItem('token') },
            };

            httpService.post('chat', {config:config})
                .then((result) => {
                    resolve(result.data.response);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    getDealNameFormPipeline(groupValue) {
        if (groupValue) {
            groupValue = groupValue.split('-');
            groupValue = groupValue.slice(0, groupValue.length - 1);
            return groupValue.join('-');
        }

    }   

    GetDealColor(stage){
        return new Promise((resolve,reject)=>{
            const status = ["On Hold", 'Proposal', 'In Progress','Cancelled', 'Completed']
            this.GetProjectStatus()
            .then((result) => {
                let arr = []
                result.forEach((one) => {
                    if (arr.length == 0) {
                        arr.push(one)
                    } else {
                        if (arr.filter(obj => obj.status_name == one.status_name).length == 0) {
                            arr.push(one)
                        }
                    }
                })
                arr.forEach((obj)=>{
                    if(obj.status_name=="zp.projstatus.completed"){
                        obj.status_name="Completed"
                    }
                })
                arr = arr.filter(one => status.includes(one.status_name))
                let filterColor = arr.filter(one => one.status_name == stage)
                if (filterColor.length > 0) {
                    resolve(filterColor[0].status_color_hexcode)
                } else {
                    resolve("#5b6c97");
                }
               
            })
            .catch((err) => {
               reject(err);
            })
        })
    }

    GetClientColor(id){
        return new Promise((resolve,reject)=>{
            GetRecordById("Accounts", id)
            .then((data) => {
                data = { ...data[0] };
                if(data.Color){
                    resolve(data.Color);
                }else{
                    resolve("#5b6c97");
                }
            })
            .catch(err => {
               reject(err);
            })
        })
    }

    SendAssignedNotification(zuid, allUsers, taskData, dealObj,dataFor,text) {
        return new Promise(async (resolve, reject) => {
            let userInfo = JSON.parse(localStorage.getItem('userinfo'))
            let users = [...allUsers];
            users = users.filter((one) => zuid == one.zuid);
            let dataArr = [];
            let doArr = []
            let clientColor=await this.GetClientColor(dealObj.Account_Name.id);
            let dealColor=await this.GetDealColor(dealObj.Stage);
        
            users.forEach((user) => {
                let obj = {IsArchive:false,Data_For:dataFor, IsRead: false, Sender_Email: userInfo?.email_id, Sender_Name: userInfo?.first_name + " " + userInfo?.last_name, Reciever_Email: user.email, Reciever_Name: user.full_name, Type: "Assignee", Title: "Assignee", Text: `<a style="color:#40a9ff" id="User-${userInfo?.email_id}">${userInfo?.first_name+" "+userInfo?.last_name}</a> <span>${text?text:" assigned you in a task "}</span> <a style="color:#40a9ff" id="Task-${taskData.id_string}">${taskData.name}</a>  <a style="background-color:${clientColor}" id="Client-${dealObj.Account_Name.id}">${dealObj.Account_Name ? dealObj.Account_Name.name : ""}</a>  <a style="background:${dealColor}" id="Deal-${dealObj.id}">${this.getDealNameFormPipeline(taskData.project_name)}</a> ` };
                dataArr.push(obj);
            })
            dataArr.forEach((obj) => {
                doArr.push(NotificationAPI.Trigger(obj))
            })
            Promise.all(doArr)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })

    }
    SendReminderNotification(message,allUsers,DataFor,reminder){
        let text = `<a style="color:#40a9ff !important" id="User-${reminder.userEmail}">${getHeaderStatement(reminder)}`;
        return new Promise((resolve, reject) => {
            let userInfo = JSON.parse(localStorage.getItem('userinfo'));
            let users = [...allUsers];
            users = users.filter((one) => message.includes(one.zuid));
            let dataArr = [];
            let doArr = []
            // users.forEach((user) => {
              let obj = {IsArchive:false, IsRead:false,Data_For:DataFor, Sender_Email: "david@srsolardesign.com", Sender_Name: "David" , Reciever_Email: reminder.userEmail, Reciever_Name: "" , Type: `Reminder`, Title: `Reminder`,Text:text };
            //   dataArr.push(obj);
            // })
            dataArr.forEach((obj) => {
              doArr.push(NotificationAPI.Trigger(obj))
            })
            Promise.all(doArr)
              .then((result) => {
                resolve(result);
              })
              .catch((err) => {
                reject(err);
              })
        })
    }
    SendMentionNotification(message, allUsers, text,DataFor,recipients_summary,NotificationType) {
         
        if(!recipients_summary){
            return new Promise((resolve, reject) => {
                let userInfo = JSON.parse(localStorage.getItem('userinfo'))
                let users = [...allUsers];
                users = users.filter((one) => message.includes(one.zuid));
                let dataArr = [];
                let doArr = []
                users.forEach((user) => {
                  let obj = {IsArchive:false, IsRead:false,Data_For:DataFor, Sender_Email: userInfo?.email_id, Sender_Name: userInfo?.first_name + " " + userInfo?.last_name, Reciever_Email: user.email, Reciever_Name: user.full_name, Type: `${NotificationType?NotificationType:"Mention"}`, Title: `${NotificationType?NotificationType:"Mention"}`,Text:text };
                  dataArr.push(obj);
                })
                dataArr.forEach((obj) => {
                  doArr.push(NotificationAPI.Trigger(obj))
                })
                Promise.all(doArr)
                  .then((result) => {
                    resolve(result);
                  })
                  .catch((err) => {
                    reject(err);
                  })
            })
        }else if(recipients_summary){
             
            return new Promise((resolve, reject) => {
                let userInfo = JSON.parse(localStorage.getItem('userinfo'))
                let users = [...allUsers];
                users = users.filter((one) => {
                    if(recipients_summary?.find(e=>e.user_id==one.zuid)){
                        return one
                    }
                });
                let dataArr = [];
                let doArr = []
                users.forEach((user) => {
                  let obj = {IsArchive:false, IsRead:false,Data_For:DataFor, Sender_Email: userInfo?.email_id, Sender_Name: userInfo?.first_name + " " + userInfo?.last_name, Reciever_Email: user.email, Reciever_Name: user.full_name, Type: `${NotificationType?NotificationType:"Mention"}`, Title: `${NotificationType?NotificationType:"Mention"}`,Text:text };
                  dataArr.push(obj);
                })
                dataArr.forEach((obj) => {
                  doArr.push(NotificationAPI.Trigger(obj))
                })
                Promise.all(doArr)
                  .then((result) => {
                    resolve(result);
                  })
                  .catch((err) => {
                    reject(err);
                  })
            })
        }

    }

    GetTaskLayout(projectId) {
        return new Promise(async (resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId.replace(",",'') + "/tasklayouts",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            await httpService.post('task', {config : config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetAllTaskLayout() {
        return new Promise(async (resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/tasklayouts",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config : config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetTaskDetail(projectId, projectName, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    if (result.data) {
                        let obj = result.data.tasks[0]
                        obj.project_id = projectId;
                        obj.project_name = projectName;
                        if (obj.custom_fields) {
                            obj.custom_fields.push({ label_name: 'Pipeline', value: projectName });
                        } else {
                            obj.custom_fields = [{ label_name: 'Pipeline', value: projectName }];
                        }
                        resolve(obj);
                    } else {
                        resolve({});
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetTaskComment(projectId, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/comments/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.comments);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    // GetTaskLayout(projectId) {
    //     return new Promise((resolve, reject) => {
    //         let config = {
    //             Type: 'Get',
    //             method: 'GET',
    //             url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId.replace(",",'') + "/tasklayouts",
    //             headers: { "Authorization": localStorage.getItem('token') },
    //             data: {}
    //         }
    //         httpService.post('task', {config : config})
    //             .then((result) => {
    //                 resolve(result.data);
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             })
    //     })
    // }

    SetTaskDependency(projectId, obj) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/taskdependency/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: obj
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    RemoveTaskDependency(projectId, obj) {
        return new Promise((resolve, reject) => {
            obj.toupdate = "removedependency";
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/taskdependency/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: obj
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    CreateTask(projectId, obj) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: obj
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.tasks[0]);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    MoveTask(projectId, taskId, fromTaskListId, toTaskListId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/move",
                headers: { "Authorization": localStorage.getItem('token') },
                data: { from_todolist: fromTaskListId, to_todolist: toTaskListId }
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    CreateSubtask(projectId, taskId, obj) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/subtasks/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: obj
            }
            httpService.post('task', {config:config})
                .then(async (result) => {
                    if (result.data.error) {
                        resolve({});
                    } else {
                        resolve(result.data.tasks[0]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    DeleteTask(projectId, taskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetMyTasks() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/mytasks/?range=1000&owner=all&status=all&time=all",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', config)
                .then((result) => {
                    if (result.data.tasks) {
                        result.data.tasks.forEach((one) => {
                            if (one.project) {
                                one.project_id = one.project.id_string;
                                one.project_name = one.project.name;
                            }
                            if (one.custom_fields) {
                                one.custom_fields.push({ label_name: 'Pipeline', value: one.project.name })
                            } else {
                                one.custom_fields = [{ label_name: 'Pipeline', value: one.project.name }]
                            }

                        })
                        resolve(result.data.tasks);
                    } else {
                        resolve([]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    async GetJobsByProjectId(projectId) {
        return new Promise(async (resolve, reject) => {
            // let config = {
            //     Type: 'Get',
            //     method: 'GET',
            //     url: Config.backend_aws_server_url+"getAllTaskPages",
            //     // headers: { "Authorization": localStorage.getItem('token') },
            //     data: { "token": localStorage.getItem('token') }
            // }

            await httpService.post(Config.backend_aws_server_url+"getAllTaskPages", { "token": localStorage.getItem('token') })
                .then(async (result) => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        resolve({});
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetJobsByProjectId1(startIndex) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://people.zoho.com/people/api/timetracker/getjobs?assignedTo=all&sIndex=" + startIndex,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }

            httpService.post('task', {config : config})
                .then(async (result) => {
                    if (result.data.response) {
                        resolve(result.data.response);
                    } else {
                        resolve({});
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    RemoveAttachement(projectId, taskId, fileId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/tasks/" + taskId + "/attachments/" + fileId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    SaerchInProject(pid, module, text, range, index) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'get',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + pid + "/search?search_term=" + text + "&module=" + module + "&index=" + index + "&range=" + range,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.tasks);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetMilestone(projectId, milestoneId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + "/milestones/" + milestoneId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    if (result.data && result.data.milestones) {
                        resolve(result.data.milestones[0]);
                    } else {
                        resolve({});
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetProjectLayout() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/module/projects/layouts/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetProjectStatus() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/module/projects/status/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetDealStatus() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/crm/v2/settings/fields?module=Deals",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', config)
                .then((result) => {
                    let ob=result.data?.fields?.find(e=>e.field_label=="Stage")
                    if(ob){
                        result.data=ob.pick_list_values;
                    }else{
                        result.data=[]
                    }
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetTaskDetailById(ProjectId, TaskId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + ProjectId + "/tasks/" + TaskId + "/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data.tasks[0]);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetTaskList(ProjectId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + ProjectId + "/tasklists/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    if (result.data.tasklists) {
                        result.data.tasklists.forEach((one) => {
                            if (one.milestone) {
                                if (!one.milestone.project) {
                                    one.milestone.project = { id: ProjectId };
                                }
                            } else {
                                one.milestone = { project: { id: ProjectId } }
                            }
                        })
                        resolve(result.data.tasklists);
                    } else {
                        resolve([]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetSubtaskOfTask(pid, tid) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + pid + "/tasks/" + tid + "/subtasks/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    if (result.data) {
                        resolve(result.data.subtasks);
                    } else {
                        resolve([]);
                    }
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
}

export default new TaskService();