import { createSlice, createSelector } from "@reduxjs/toolkit";


const initialStateValues = {
  timer: 0,
}
const timerSlice = createSlice({
  name: "Timer",
  initialState: initialStateValues,
  reducers: {
    addTimer: (state, action) => {
      state.timer=state.timer+action.payload;
    },
  },
});

export const getTimerSelector = createSelector(
  (state) => state.timer,
  (state) => state
);

export const { addTimer } = timerSlice.actions;

export default timerSlice.reducer;