import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './email.css';
import './newemail.css';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArchive, faArrowDown, faArrowUp, faCog, faDownload, faEnvelope, faEnvelopeOpen, faFile, faFlag, faPaperclip, faQuestion, faTags, faTimes } from '@fortawesome/free-solid-svg-icons';
import TaskService from '../../common/util/TaskService';
import Loader from '../../common/loader';
import EmailService from '../../common/util/EmailService';
import validation from '../../common/util/validation';
import { Input, Label, Button } from 'reactstrap';
import { FormGroup, Row, Col, Tabs, Tab } from "react-bootstrap";
import {Modal as AntDModal , Button as AntButton, Dropdown as AntDropdown, Menu, Space} from 'antd'
import swal from 'sweetalert';
import { faEye, faFileExcel, faFileImage, faFilePdf, faTrashAlt, faHourglass } from '@fortawesome/free-regular-svg-icons';
import { Sidebar } from './EmailSidebar/Sidebar';
import { MultiSelectInpute } from './MultiSelectInpute/MultiSelectInput';
import { findItemsInEmail, getEmailsFromString, getHeaderStatement, getVerifiedMAilObject } from './emailUtil';
import { ToolBox } from '../../common/ShortcutsToolbox/ToolBox';
import { GlobalAntDPopOver } from '../../common/util/AntPopover/GlobalAntDPopOver';
import { convertToUSTime, getRanges, modifyTimezoneString, sortArrayOfObjects } from '../HR/TimeClock/Util';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { DocumentPreview } from './DocumentPreview/DocumentPreview';
import { DatePicker as AntdDatepicker } from "antd";
import AssociateMail from './AssociateMail';
import AppContext from '../../Context/AppContext';
import Select from "react-select";
import JoditTextEditor from '../../common/JoditEditor/JoditTextEditor';
import EmailReminder from './EmailReminder';
import ModuleService from '../../common/util/ModuleService';
import { DownOutlined } from '@ant-design/icons';
import AwsServerService from '../../common/util/AwsServerService';
import { Socket, forwardObject, setIsReminderPopupOpen, setReminderData } from '../../Context/AppActions';
import ReminderSvg from '../../../src/assets/images/svg/AlarmReminderBlack.svg';
import { Popover as AntdPopover } from "antd";
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper';
import { ReminderShortCutPopOver } from '../ReminderPopUp/ReminderShortCutPopOver';

const Email = ({ allUsers, handleNotificationHyperlink, cacheDeals, allTask, refreshEnable, setRefreshEnable, location, getParams, clickedFromBody, dealObj}) => {
  const params = useParams();
  let userinfo = JSON.parse(localStorage.getItem('userinfo'));
  const [isOpen, setIsOpen] = useState({ state: false, object: null });
  const [AllEmailsFromAllFolders,setAllEmailsFromAllFolders]=useState([]);
  const [errorShow ,setErrorShow] = useState(false)
  const [allAssociatedEmails,setAllAssociatedEmails] = useState([]);
  const [openAssociatePopUp,setOpenAssociatePopUp]=useState({
    status:false,
    email:null
  });
  const [selectedItoms, setSelectedItoms] = useState({});
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [isHoverOnTag,setIsHoverOnTag] = useState(false);
  const [possibleAssociationData,setPossibleAssociationData] = useState({
    isAlready:false,
    data:null
  });
  const getAllMailData=async(folders,accountId,isArchive)=>{
    let allData=[];
    return new Promise(async(resolve,reject)=>{
      if(isArchive){
        const results = await Promise.all(folders.map(folderId => EmailService.GetAllEmailMessages(accountId,folderId.folderId,isArchive)));
        allData = results.reduce((acc, result) => acc.concat(result.data), []);
      }else{
        const results = await Promise.all(folders.map(folderId => EmailService.GetAllEmailMessages(accountId,folderId.folderId)));
        allData=results.reduce((acc, result) => acc.concat(result.data), []);
      }
       
      resolve(allData);
    })
  } 
  const [selectedMails, setSelectedMails] = useState([]); 
  const [isOpenFlag, setIsOpenFlag] = useState({ state: false, object: null });
  const [isOpenModuleContext, setIsOpenModuleContext] = useState({ state: false, object: null });
  const [toolboxposition, setToolboxPosition] = useState({ x: 0, y: 0 });
  const [showSortPopOver, setShowSortPopOver] = useState(false);
  const [clearFields, setClearFields] = useState(false);
  const [emails, setAllEmails] = useState([]);
  const [TagList, setTagList] = useState([]);
  const [contentShowFlag, setContentShowFlag] = useState("");
  const [isDraftAlready, setIsDraftAlready] = useState(false);
  const [openAttachmentPopUp,setOpenAttachmentPopUp] = useState(false);
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [attachments,setAttachments] = useState([]);
  const [sidebarActive, setSidebarActive] = useState(true);
  const [dataForSearch,setDataForSearch] = useState([]);
  const [reloadAssociations, setreloadAssociations] = useState([]);
  const [activeTabinPopup, setActiveTabinPopup] = useState({
    pinned:true,
    path: "Deals",
    label: "Deals",
    field: "Deals",
    name: "Deals",
    type: "text",
    id : 0,
    isChecked : false,
    isDragOver : false,
    isSearch : true,
  });
  const openSubFolderTemplate={
    status:false,
    folderId:null,
  }
  const [openSubFolderPopup, setOpenSubFolderPopup] = useState({
    status:false,
    folderId:null
  });
  const [subFolderName, setSubFolderName] = useState("");
  const [searchText, setSearchText] = useState('');
  const [cacheEmails, setCacheEmails] = useState([])
  const [viewEmail, setViewEmail] = useState(null)
  const [replyEmail, setReplyEmail] = useState(false)
  const [signature, setSignature] = useState("");
  const [loader, setLoader] = useState(false);
  const [currentAccounts, setCurrentAccounts] = useState(null);
  const [composeEmailPopup, setComposeEmailPopUp] = useState(false);
  const [composeObj, setComposeObj] = useState({ fromAddress: userinfo?.email_id, askReceipt: 'yes' })
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedItem, setSelecetdItem] = useState("");
  const [showHeaderOptions,setShowHeaderOptions] = useState(false);
  const [draftEmailContent, setDraftEmailContent] = useState("");
  const [draftId, setDraftId] = useState("");
  const [cacheUnreadData,setCacheUnreadData] = useState([]);
  const [cacheAllEmailData,setCacheAllEmailData] = useState([]);
  const [emailAttachmentInfo,setEmailAttachmentInfo]=useState([]);
  const [selectedView, setSelectedView] = useState(null);
  const [scheduleId, setScheduleId] = useState("1");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [emailContent, setEmailContent] = useState('')
  const scheduleObject = {
    isSchedule : "",
    scheduleType : "", 
    timeZone : null, 
    scheduleDate : dayjs().format("MM/DD/YYYY"),
    time : dayjs().add(1, 'hour').format('HH:mm'),
  }
  const [isSchedule, setIsSchedule] = useState(false);
  const [scheduleObj, setScheduleObj] = useState({...scheduleObject});

  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [dataForPopup, setDataForPopup] = useState([]);
  const [voilationData, setVoilationData] = useState([]);
  const [prevSolarData, setPrevSolarData] = useState([]);
  useEffect(e=>{
    ModuleService.GetModuleListData("WWP_Violations").then(res=>{
      setVoilationData(res);
    }).catch(e=>{
      console.log(e);
    })
    ModuleService.GetModuleListData("Previous_Solar_Info").then(res=>{
      setPrevSolarData(res);
    }).catch(e=>{
      console.log(e);
    })
  },[])
  useEffect(e=>{
    if(isSchedule){
      let obj = {...scheduleObj};
      obj.isSchedule = isSchedule;
      obj.scheduleType = scheduleId;
      setScheduleObj(obj);
    }
  }, [isSchedule, scheduleId])
  const [associateModuleData,setAssociateModuleData] = useState({});
  useEffect(e=>{
    if(state.AllDeals){
      let Deals=state?.AllDeals?.map(d => d.Deal_Name);
      let Accounts=state?.AllDeals?.map(d => d.Account_Name?.name);
      let Client=state?.AllDeals?.map(d => d.Client?.name);
      let Owner=state?.AllDeals?.map(d => d.Owner?.name);
      setAssociateModuleData({
        Deals:Deals && Deals.length>0?Deals:[],
        Accounts:Accounts && Accounts.length>0?Accounts:[],
        Owner:Owner && Owner.length>0?Owner:[],
        Client:Client && Client.length>0?Client:[],
      })
    }
  },[state.AllDeals])

  useEffect(e=>{
     
    if(state?.forwardObject?.module=="Email" && state?.forwardObject?.state=="create"){
      dispatch(forwardObject({module:"",data:"",state:""}))
    }else if(state?.forwardObject?.module=="Email" && state?.forwardObject?.state=="view" && state?.forwardObject?.data){
       
      let data=AllEmailsFromAllFolders?.find(k=>k.messageId==state?.forwardObject?.data?.messageId);
      if (data && currentAccounts.Folders) {
        //onClickEvent(data);
        let folder = currentAccounts.Folders.find(f => f.folderId == data.folderId);
        if (folder) {
          EmailService.GetEmailContent(
            data.accountId,
            data.folderId,
            data.messageId
          )
            .then(async (result) => {
              data.content = result.content;
              setViewEmail(data)
              setContentShowFlag("viewemail");
              setSelectedFolder(folder);
              resetSelections();
              setSelectedView(null)
              dispatch(forwardObject({module:"",data:"",state:""}))
            })
            .catch((err) => {
              console.log(err);
            });

        }
      }
    }
  },[state?.forwardObject,AllEmailsFromAllFolders])

  const AssingUnreadEmailAutomatically = async (emaildata) => {
    
    if (emaildata) {
      let tempData=allAssociatedEmails.find(d=>d?.messageId==emaildata?.messageId);
      if(!tempData){
        let allDeals = state?.AllDeals?.map(d => d.Deal_Name);
        let obj = {
          email: {
            from: emaildata.fromAddress,
            to: emaildata.toAddress,
            subject: emaildata.subject,
            content: emaildata.content,
          },
          ...associateModuleData
        }
        await findItemsInEmail(obj,dataForPopup).then(async res => {
          
          if (res && Object.keys(res)?.length>0) {
            setPossibleAssociationData({isAlready:false,data:res});
            // await EmailService.AssociateEmailWithRecords({
            //   messageId: emaildata.messageId,
            //   AssociationType: "Auto",
            //   AssociationObj: res,
            // }, "Post").then(res => {
            //   
            //   let arr=[...allAssociatedEmails]
            //   if(res.data){
            //     arr.push(res.data[0])
            //   }
            //   setAllAssociatedEmails(arr);
            //   swal("Success!", "Email associated successfully!", "success");
            // }).catch(er => {
            //   console.log(er);
            // })
          }
        })
      }else{
        setPossibleAssociationData({isAlready:true,data:tempData?.AssociationObj});
      }
    }
  }
  function invertColor(hex, bw) {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    } 
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    } 
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16); 
    return "#" + padZero(r) + padZero(g) + padZero(b);
  }
  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  }
  useEffect(()=>{
    let arr=[];
    if(activeTabinPopup?.name=="Deals"){
      let deals=state?.AllDeals;
      setDataForSearch(deals);
     deals.forEach((one,index)=>{
      let clients=state?.AllAccounts?.filter(r=>one?.Account_Name?.id==r.id).map(e=>e.Account_Name); 
        arr.push({name:one.Deal_Name,id:one.id,color:one.Color?one.Color:"#20adff",TagExtraItem:{
          Accounts:clients[0],
          Owner:one?.Owner?.name
        }}) 
     })
    }
    else if(activeTabinPopup?.name=="Accounts"){
      let accounts=state?.AllAccounts; 
      setDataForSearch(accounts);
      accounts.forEach((one,index)=>{ 
        if(one?.Account_Name){
          // let isDeal=state?.AllDeals?.find(r=>r?.Account_Name?.id==one.id);
          // if(isDeal){
          //   arr.push({name:one.Account_Name,id:one.id,color:one.Color? one.Color  :"#20adff",TagExtraItem:{
          //     Deals:isDeal?.Deal_Name
          //   }}) 
          // }else{
            arr.push({name:one.Account_Name,id:one.id,color:one.Color? one.Color  :"#20adff"}) 
          // }
        }
     })
    }
    else if(activeTabinPopup?.name=="Application_Number"){
      let accounts=[...prevSolarData]; 
      setDataForSearch(accounts);
      accounts.forEach((one,index)=>{ 
        if(one?.Application_Number?.trim()){
          if(one?.Deal_Name?.name){
            arr.push({name:one.Application_Number,id:one.id,color:one.Color? one.Color  :"#20adff",TagExtraItem:{
              Deals:one.Deal_Name.name
            }}) 
          }else{
            arr.push({name:one.Application_Number,id:one.id,color:one.Color? one.Color  :"#20adff"}) 
          }
        }
     })
    }
    else if(activeTabinPopup?.name=="Violation_Number"){
      let accounts=[...voilationData]; 
      setDataForSearch(accounts);
      accounts.forEach((one,index)=>{ 
        let number=one?.Violation_Number?.trim();
        let data= accounts.filter(e=>e?.Violation_Number==number);
        let deals= data?.map(e=>e.Deal_Name?.name); 
        if(one?.Violation_Number?.trim()){
          if(one?.Deal_Name?.name){
            arr.push({name:one.Violation_Number,id:one.id,color:one.Color? one.Color  :"#20adff",TagExtraItem:{
              Deals:deals
            }}) 
          }else{
            arr.push({name:one.Violation_Number,id:one.id,color:one.Color? one.Color  :"#20adff"}) 
          }
        }
     })
    }
    // else if(activeTabinPopup=="Client"){
    //   let accounts=state?.AllDeals; 
    //  accounts.forEach((one,index)=>{ 
    //   if(one?.Client?.name){
    //     arr.push({name:one?.Client?.name,id:one?.Client?.id,color:one.Color? one.Color  :"#20adff"}) 
    //   }
    //  })
    // }
    else if(activeTabinPopup?.name=="Owner"){
      let accounts=state?.AllDeals; 
      setDataForSearch(accounts);
     accounts.forEach((one,index)=>{ 
        if(one?.Owner?.name){
          arr.push({name:one?.Owner?.name,id:one?.Owner?.id,color:one.Color? one.Color  :"#20adff" ,TagExtraItem:{
            Deals: one.Deal_Name
          }}) 
        }
     })
    }else if(activeTabinPopup?.name=="Property_Owner's_Name"){
      let accounts=state?.AllDeals; 
      setDataForSearch(accounts);
     accounts.forEach((one,index)=>{ 
        if(one?.Property_Owner_s_Name){
          arr.push({name:one?.Property_Owner_s_Name,id:one?.id,color:one.Color? one.Color  :"#20adff" ,TagExtraItem:{
            Deals: one.Deal_Name
          }}) 
        }
     })
    }else if(activeTabinPopup?.name=="Must be filed in Conjunction"){
      let accounts=state?.AllDeals; 
      setDataForSearch(accounts);
     accounts.forEach((one,index)=>{ 
        if(one?.Conjunction=='Yes' && one?.Conjunction_Application_Number){
          arr.push({name:one?.Conjunction_Application_Number,id:one?.id,color:one.Color? one.Color  :"#20adff" ,TagExtraItem:{
            Deals: one.Deal_Name
          }}) 
        }
     })
    }
    setDataForPopup(arr);
  },[activeTabinPopup])


  useEffect(() => {
    // Get the list of time zones using moment-timezone
    const tz = moment.tz.names();
    const tzWithOffsets = tz.map((timeZone) => {
      const offset = moment.tz(timeZone).utcOffset();
      const hours = Math.floor(Math.abs(offset) / 60);
      const minutes = Math.abs(offset) % 60;
      const sign = offset >= 0 ? '+' : '-';
      const formattedOffset = `UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
      return { timeZone, formattedOffset };
    });
    let array = tzWithOffsets?.map((item,i)=>{
      return {value : item?.timeZone , label: item?.timeZone }
    })
    setTimeZones(array);
    EmailService.GetAllAssociateEmailWithRecords().then(res=>{
      
      setAllAssociatedEmails(res.data);
    }).catch(err=>{
      console.log(err);
    })
  }, [reloadAssociations])

  const downloadFile = (base64String,fileName) => {
     

    // Fix the base64 string
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the URL
    URL.revokeObjectURL(url);
  };

  const tabsForEmailAssociation =[ 
    {
      pinned:true,
      path: "Deals",
      label: "Deals",
      field: "Deals",
      name: "Deals",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    },
    {
      pinned:true,
      path: "Accounts",
      label: "Accounts",
      field: "Accounts",
      name: "Accounts",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    },
    {
      pinned:true,
      path: "Owner",
      label: "Owner",
      field: "Owner",
      name: "Owner",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    },
    {
      pinned:true,
      path: "Application_Number",
      label: "Application_Number",
      field: "Application_Number",
      name: "Application_Number",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    },
    {
      pinned:true,
      path: "Violation_Number",
      label: "Violation_Number",
      field: "Violation_Number",
      name: "Violation_Number",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    },
    {
      pinned:true,
      path: "Property_Owner_s_Name",
      label: "Property_Owner_s_Name",
      field: "Property_Owner_s_Name",
      name: "Property_Owner's_Name",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    },
    {
      pinned:true,
      path: "Conjunction_Application_Number",
      label: "Conjunction_Application_Number",
      field: "Conjunction_Application_Number",
      name: "Must be filed in Conjunction",
      type: "text",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
    }
  ]

  let ScheduleArr = [
    {name: "1 hour", id: "1"},
    {name: "2 hours", id: "2"},
    {name: "4 hours", id: "3"},
    {name: "Tomorrow @ 10.00 AM", id: "4"},
    {name: "Custom date", id: "6"},
  ]

  const sidebarEmailContext = [
    {
      label: "Preview",
      icon: () => {
        return (
          <FontAwesomeIcon color='007bff' icon={faEye} />
        )
      },
      module: ['Attachment'],
      action: (e, data, refrence) => {
        getchFiletDataUrl(refrence)
      }
    },
    {
      label: "Download",
      module: ['Attachment'],
      icon: () => {
        return (
          <FontAwesomeIcon color='007bff' icon={faDownload} />
        )
      },
      action:async (e, data, refrence) => {
        await EmailService.GetAttachmentsContent(viewEmail?.accountId, viewEmail?.folderId, viewEmail?.messageId, refrence.attachmentId)
          .then(response => {
             
            downloadFile(response,refrence.attachmentName)
          })
          .catch(error => {
            console.error('Error downloading the image:', error);
          });
      }
    },
    {
      label: "Archive",
      module: ["inbox", 'sent'],
      action: (e, data, refrence) => {

        if (refrence) {
          archiveMail(refrence, "archiveMails");
        }
      }
    },
    {
      label: "Clear-Flag",
      module: ["flag"],
      icon: () => {
        return (
          <FontAwesomeIcon color='gray' icon={faFlag} />
        )
      },
      action: async (e, data, refrence) => { handleTags(e, data, refrence, "Flag") }
    },
    {
      label: "Delete",
      module: ["trash"],
      action: (e, data, refrence) => {
        if (refrence) {
          handleHardDeleteMail(refrence);
        }
      }
    },
    {
      label: "Follow-Up",
      module: ["flag"],
      icon: () => {
        return (
          <FontAwesomeIcon color='green' icon={faFlag} />
        )
      },
      action: async (e, data, refrence) => { handleTags(e, data, refrence, "Flag") }
    },
    {
      label: "Important",
      module: ["flag"],
      icon: () => {
        return (
          <FontAwesomeIcon color='red' icon={faFlag} />
        )
      },
      action: async (e, data, refrence) => { handleTags(e, data, refrence, "Flag") }
    },
    {
      label: "Info",
      module: ["flag"],
      icon: () => {
        return (
          <FontAwesomeIcon color='blue' icon={faFlag} />
        )
      },
      action: async (e, data, refrence) => { handleTags(e, data, refrence, "Flag") }
    },
    {
      label: "Mark as read",
      module: ["inbox","unread"],
      action: (e, data, refrence) => {

        if (refrence) {
          archiveMail(refrence, "markAsRead");
        }
      }
    },
    {
      label: "Mark as Unread",
      module: ["inbox"],
      action: (e, data, refrence) => {

        if (refrence) {
          archiveMail(refrence, "markAsUnread");
        }
      }
    },
    {
      label: "Mark as spam",
      module: ["inbox"],
      action: (e, data, refrence) => {
        if (refrence) {
          archiveMail(refrence, "moveToSpam");
        }
      }
    },
    {
      label: "Mark as not spam",
      module: ["spam"],
      action: (e, data, refrence) => {
        if (refrence) {
          archiveMail(refrence, "markNotSpam");
        }
      }
    },
    {
      label: "Move to Trash",
      module: ["inbox", 'sent', "archived", "drafts"],
      action: (e, data, refrence) => {

        if (refrence) {
          handleDeleteMail(refrence);
        }
      }
    },
    {
      label: "Reply",
      module: ["inbox", 'sent', "archived"],
      key: "replyemail",
      action: async(e, data, refrence) => { 

        if (refrence && data.key) {
          setContentShowFlag(data.key)
          getViewEmailData(refrence, "replyemail");
          await EmailService.GetEmailContent(
            refrence.accountId,
            refrence.folderId,
            refrence.messageId
          )
            .then(async(result) => {
              refrence.content = result.content;
              setViewEmail(refrence)
            })
            .catch((err) => {
              console.log(err);
            });
          setIsOpen({ state: false, object: null });
        }
      }
    },
    {
      label: "Reply all",
      key: "replyallemail",
      module: ["inbox", 'sent', "archived"],
      action: async(e, data, refrence) => { 

        if (refrence && data.key) {
          setContentShowFlag(data.key)
          getViewEmailData(refrence, "replyallemail");
          await EmailService.GetEmailContent(
            refrence.accountId,
            refrence.folderId,
            refrence.messageId
          )
            .then(async(result) => {
              refrence.content = result.content;
              setViewEmail(refrence)
            })
            .catch((err) => {
              console.log(err);
            });
          setIsOpen({ state: false, object: null });
        }
      }
    },
    {
      label: "Restore",
      module: ["trash"],
      action: (e, data, refrence) => {

        if (refrence) {

          EmailService.GetTrashDataById(refrence.messageId)
          .then(async(response)=>{
            let destFolderId;
            let ROWID;
            if(response?.data[0]?.TrashRestore){
              destFolderId = response?.data[0]?.TrashRestore?.folderId;
              ROWID = response?.data[0]?.TrashRestore?.ROWID;
            }else{
              destFolderId = "6534405000000008014";
              ROWID = "";
            }
            setLoader(true)
           await EmailService.RestoreDeletedMail(refrence?.accountId, destFolderId ,[refrence.messageId], "moveMessage")
              .then(async(res)=>{
                if(ROWID){
                  await EmailService.DeleteTrashRecord([ROWID])
                  .then((re)=>{
                  }).catch((e)=>{
                    console.log("ee", e);
                  })
                }
                getAllEmails(currentAccounts?.accountId, selectedFolder?.folderId)
                resetSelections()
                setLoader(false);
              }).catch((e)=>{
                setLoader(false)
              console.log(e);
              })
          }).catch((e)=>{
            console.log("e", e);
          })
        }
      }
    },
    {
      label: "Unarchive",
      module: ["archived"],
      action: (e, data, refrence) => {

        if (refrence) {
          archiveMail(refrence, "unArchiveMails");
        }
      }
    },
    {
      label: "Associate Email",
      module: ["All"],
      action: (e, data, refrence) => {
        setOpenAssociatePopUp({
          status:true,
          email:refrence,
          context:true
        });
      }
    },
    {
      label: "Set Reminder",
      module: ["All"],
      action: (e, data, refrence) => {
        dispatch(setIsReminderPopupOpen({status:true,email:refrence}));      
       }
    },     
  ]
  const [contextOptions, setContextOptions] = useState([...sidebarEmailContext])
  const [sortEmailObj, setSortEmailObj] = useState({
    type: "Date",
    order: "desc",
  })
  const [composeMailObj, setComposeMailObj] = useState({
    from: "",
    to: [],
    cc: [],
    bcc: [],
    subject: "",
  })
  const [composeMailToShow, setComposeMailToShow] = useState({
    from: "",
    to: [],
    cc: [],
    bcc: [],
    subject: "",
  })
  const tabsName = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "unread",
      name: "Unread",
    }
  ];

  const [showPriview, setShowPriview] = useState(false);
  const closePreview = () => {
    setShowPriview(!showPriview);
    //setShowDealTableChart(true)
  }
  const getPDFPrevModal = () => {
    return (
      <AntDModal
        open={showPriview}
        title={
          filePrvData?.FileName
        }
        width={900}
        style={{ top: 170, borderRadius: 50 }}
        onCancel={closePreview}
        footer={null}
      >
        {/* <div className="modal-header-class">{filePrvData?.FileName}
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={(e)=>{
                closePreview()
              }}
            ></FontAwesomeIcon>
          </div>
        </div> */}

{/* <PdfViewer type={"Image"} fileData={filePrvData?.DataUrl}></PdfViewer> */}
        <DocumentPreview base64String={filePrvData?.DataUrl} />
      </AntDModal>
    )
  }

  // New Buttons code

  const handleSendButton = (message, isReply) =>{
      const obj = { ...composeObj };
      obj.toAddress = composeMailObj?.to
      ?.map((e) => e.email)
      ?.join(",");
      obj.ccAddress = composeMailObj?.cc
      ?.map((e) => e.email)
      ?.join(",");
      obj.bccAddress = composeMailObj?.bcc
        ?.map((e) => e.email)
        ?.join(",");
        obj.content = message;
        obj.mailFormat = "html";
      if(isReply){
        obj.fromAddress = composeMailObj.from;
        handleReplyEmail(obj);
      }else{
        obj.subject = composeMailObj?.subject;
        handleSendEmail(obj);
      }
  }

  const handleSaveDraft = async(message) =>{
      const obj = { ...composeObj };
      await UploadAttachments(attachments).then(res=>{
        if(res.length>0){
          obj["attachments"]=res.map(k=>{delete k.url; return k});
        }
      }).catch(e=>{
        console.log(e);
      })
      obj.content = message;
      obj.mode = "draft";
      if (draftId) {
        await DeleteDraftMsg(currentAccounts?.accountId, selectedFolder?.folderId, draftId)
      }
      obj.subject = composeMailObj?.subject;
      obj.toAddress = composeMailObj?.to
        ?.map((e) => e.email)
        ?.join(",");
      obj.ccAddress = composeMailObj?.cc
        ?.map((e) => e.email)
        ?.join(",");
      obj.bccAddress = composeMailObj?.bcc
        ?.map((e) => e.email)
        ?.join(",");
      obj.mailFormat = "html";
      handleSendEmail(obj);
  }

  const getCCOptions = () =>{
    const items = [
      {
        label: <a
          onClick={() => {
            setShowCC(!showCC)
          }}
        >
          {showCC ? "Hide CC" : "Show CC"}
          </a>,
        key: "0",
      },
      {
        label: <a
        onClick={() => {
          setShowBCC(!showBCC)
        }}
      >
        {showBCC ? "Hide BCC" : "Show BCC"}
      </a>,
        key: "1",
      },
    
    ];
    return (
      <AntDropdown
        className="email-setting-icon"
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["click"]}
      >
        <Button
          className="Action-Btn-Ant"
          variant="outline-primary"
          onClick={(e) => e.preventDefault()}
        >
          <FontAwesomeIcon
            className="Action-Btn-Ant-Dropdown"
            icon={faCog}
          />
        </Button>
      </AntDropdown>
    )
  }

  useEffect(e=>{
    if(composeMailObj?.cc?.length > 0){
      setShowCC(true)
    }
    if(composeMailObj?.bcc?.length > 0){
      setShowBCC(true)
    }
  },[composeMailObj])

  // New Buttons code

  const [filePrvData, setFilePrvData] = useState({
    "DataUrl": "",
    "FileName": "",
  });
  const getchFiletDataUrl = async (refrence) => {

    setLoader(true);
    try {
      await EmailService.GetAttachmentsContent(viewEmail?.accountId, viewEmail?.folderId, viewEmail?.messageId, refrence.attachmentId)
      .then(response => {
        setFilePrvData({
          "DataUrl": response,
          "FileName": refrence?.attachmentName?refrence?.attachmentName:"File",
        })
      })
      .catch(error => {
        console.error('Error downloading the image:', error);
      });
      
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
    //setShowDealTableChart(false)
    setShowPriview(true);
    setLoader(false);
  }

  useEffect(e=>{
    getAllEmailMessages(currentAccounts?.accountId, selectedFolder?.folderId, "status=unread")
  },[selectedFolder?.folderId])


  const [allTabsName, setAllTabsName] = useState(tabsName);
  const sortPopOverItems = {
    Panels: [
      {
        label: "Arranged By",
        items: [
          {
            label: "Size",
            action: (data) => {
              setSortEmailObj({
                type: data.label,
                order: "asc"
              })
            }
          },
          {
            label: "From",
            action: (data) => {
              setSortEmailObj({
                type: data.label,
                order: "asc"
              })
            }
          },
          {
            label: "To",
            action: (data) => {
              setSortEmailObj({
                type: data.label,
                order: "asc"
              })
            }
          },
          {
            label: "Subject",
            action: (data) => {
              setSortEmailObj({
                type: data.label,
                order: "asc"
              })
            }
          },
          {
            label: "Date",
            action: (data) => {
              setSortEmailObj({
                type: data.label,
                order: "desc"
              })
            }
          },
        ]
      },
    ],
  }
  const [key, setKey] = useState("");

  const getDatePickerValue = (date) => {
    try {
      let d = date
      if (d) {
        return dayjs(new Date(d));
      } else {
        return dayjs(new Date()).subtract(1,"day");
      }
    } catch (error) {}
  };


  const DeleteDraftMsg = async(accId, folderId, msgId) =>{
    await EmailService.DeleteEmail(accId, folderId, msgId)
    .then((result) => {
      setDraftId("")
    })
    .catch((err) => {
      console.log(err);
    })
  }


  useEffect(e => {
    if (selectedFolder) {
      setKey("all")
      setSelecetdItem(selectedFolder?.folderName);
    }
    if(selectedFolder?.folderName != "Drafts"){
      setIsDraftAlready(false);
    }
  }, [selectedFolder])
  useEffect(e => {
    EmailService.GetAllTags().then(res => {
       
      setTagList(res?.data?.length > 0 ? res?.data?.map(e => e?.EmailsAndTags) : []);
    }).catch(e => {
      console.log(e)
    })
  }, [])

  function isHeadersVisible(){
    let arr=document.querySelectorAll("input.Single-Check-Box:checked");
    setShowHeaderOptions(arr?.length>0?true:false);
  }

  const handleCheckBoxClick=(e,one)=>{
    if(e.currentTarget.checked){
      let obj={};
      obj["messageId"]=one.messageId;
      obj["folderId"]=one.folderId;
      obj["accountId"]=one.accountId;
      setSelectedMails([...selectedMails,obj]);
      e.currentTarget.parentElement.parentElement.classList.add("Email-Selected");
    }else{
      let arr=selectedMails.filter(k=>k.messageId!=one.messageId);
      setSelectedMails(arr);
      e.currentTarget.parentElement.parentElement.classList.remove("Email-Selected");
    }
    let arr=document.querySelectorAll("input.Single-Check-Box:not(:checked)");
    let selectAllCheck=document.getElementById("Select-All-Checkbox")
    if(arr?.length==0){
      selectAllCheck.checked=true;
    }else{
      selectAllCheck.checked=false;
    }
    isHeadersVisible()
  }

  function resetSelections() {
    document.querySelectorAll(".Email-Recepient-Sidebar .First-Child input")
             ?.forEach((c) => {
               c.checked = false;
               c.parentElement.parentElement.classList.remove("Email-Selected")
             });
            let selectAllCheck=document.getElementById("Select-All-Checkbox")
            selectAllCheck.checked=false;
            setSelectedMails([]);
  }
 

  //contextMenues
  const extraSidebarOptions = [
    {
      label: "Unread",
      action: () => {
        setSelectedFolder(null);
        setSelectedView("Unread")
        getAllEmails(currentAccounts.accountId, null, "status=unread");
        setContentShowFlag("");
        resetSelections();
      }
    },
    {
      label: "Archived",
      action: (d) => {
        setSelectedFolder(null);
        setSelectedView("Archived")
        getAllEmails(currentAccounts.accountId, null, "includearchive=true", true);
        setContentShowFlag("");
        resetSelections();
      }
    },
    {
      label: "Flagged",
      action: () => {
        setSelectedFolder(null);
        setSelectedView("Flagged")
        setContentShowFlag("");
        let tempArr=[...AllEmailsFromAllFolders];
        let filteredArr=tempArr.filter(d=>TagList?.find(t => t.EmailRowId == `${d.folderId}_${d.messageId}` && t.Tag == "Flag"))
        setCacheAllEmailData(filteredArr);
        resetSelections();
      }
    },
  ]

  const handleClearMultilpeFlags = async ( refrence ) => {
   setLoader(true);
   try { 
   let ROWIDs=[];
     refrence?.forEach((one)=>{
       const tag = TagList.find(t => t.EmailRowId === `${one.folderId}_${one.messageId}`);
       if (tag) {
         ROWIDs.push(tag.ROWID);
       }}) 
         await EmailService.DeleteTags(ROWIDs).then(res => {
             let temp = TagList.filter(t => (!ROWIDs.includes(t.ROWID)));
             setTagList(temp);
             swal("Success!", "Flags cleared successfully.", "success");
         }).catch(e => {
           console.log(e);
         })
   } catch (e) {
     setLoader(false);
   } finally {
     setLoader(false);
   }

 }

  const handleTags = async (e, data, refrence, Tag, skipAndUpdateToClear) => {
     
    setLoader(true);
    try {

      let isPresent = TagList?.find(t => t.EmailRowId == `${refrence.folderId}_${refrence.messageId}` && t.Tag == Tag)
      if (!isPresent) {
        let payload = {}
        payload.EmailRowId = `${refrence.folderId}_${refrence.messageId}`;
        payload.Tag = Tag;
        payload.TagValue = skipAndUpdateToClear ? "Important" : data?.label;
        await EmailService.AddTags(payload).then(res => {
           
          if (res?.data[0]?.EmailsAndTags) {
            let temp = [...TagList, res?.data[0]?.EmailsAndTags];
            setTagList(temp);
          }
        }).catch(e => {
          console.log(e);
        })
      } else {
        let payload = { ...isPresent, TagValue: skipAndUpdateToClear ? isPresent?.TagValue == "Clear-Flag" ? "Important" : "Clear-Flag" : data.label }
        if(data?.label=="Clear-Flag" || (skipAndUpdateToClear && isPresent?.TagValue != "Clear-Flag")){
          await EmailService.DeleteTags([payload.ROWID]).then(res => {
              let temp = TagList.filter(t => (t.ROWID != isPresent?.ROWID));
              setTagList(temp);
          }).catch(e => {
            console.log(e);
          })
          return
        }
        await EmailService.UpdateTags(payload).then(res => {
          if (res?.data[0]?.EmailsAndTags) {
            let temp = TagList.map(t => {
              if (t.EmailRowId == res?.data[0]?.EmailsAndTags?.EmailRowId) {
                t.TagValue = payload.TagValue
              }
              return t;
            })
            setTagList(temp);
          }
        }).catch(e => {
          console.log(e);
        })
      }
    } catch (e) {
      setLoader(false);
    } finally {
      setLoader(false);
    }

  }

  //

  const isUnreadEmail = (one) =>{
      let data = cacheUnreadData.find(e=> e?.messageId == one?.messageId);
      return data;
  }

  useEffect(() => {
    if (key == "all") {
        setAllEmails(cacheAllEmailData);
        setCacheEmails(cacheAllEmailData)
    } else if (key == "unread") {
      setAllEmails(cacheUnreadData);
      setCacheEmails(cacheUnreadData)
    }
  }, [key, cacheAllEmailData, cacheUnreadData])

  useEffect(e => {
    EmailService.getSignature()
      .then((result) => {
        // console.log("result[0].content", result.data[0].content);
        setSignature(result.data[0].content)
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])
  useEffect(() => {
    if (getParams) {
      getParams(params);
      setLoader(true)
      EmailService.GetCurrentEmailAccount(userinfo?.email_id)
        .then((result) => {
          setCurrentAccounts(result.data[0]);
          setLoader(false)
        })
        .catch((err) => {
          console.log(err)
          setLoader(false)
        })
    }
  }, [location])

  useEffect(() => {
    if (dealObj) {
      getAllEmails(currentAccounts?.accountId, selectedFolder?.folderId)
    }
  }, [dealObj, allAssociatedEmails])

 


  useEffect(e=>{
    if(currentAccounts?.Folders){
      getAllMailData(currentAccounts?.Folders,currentAccounts?.accountId).then(res=>{
        let arr=[];
        if(clickedFromBody){
          let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
          .map(email => email.messageId);
          res=res.filter(item =>
            messageIds.includes(item.messageId)
          );
        }
        setAllEmailsFromAllFolders(res);
      })
    }
  },[currentAccounts])
  useEffect(() => {
    if ((cacheDeals.length > 0) && (allTask.length > 0) && (currentAccounts && currentAccounts.length > 0)) {
      // getAllEmails()
    }

  }, [cacheDeals, allTask, currentAccounts])

  useEffect(() => {

    if (currentAccounts && selectedFolder) {
      getAllEmails(currentAccounts?.accountId, selectedFolder?.folderId)
    }
  }, [currentAccounts, selectedFolder])

  useEffect(e=>{
    setShowHeaderOptions(false);
  },[emails,selectedFolder])

  useEffect(() => {
    if (refreshEnable && currentAccounts && selectedFolder) {
      getAllEmails(currentAccounts?.accountId, selectedFolder?.folderId)
    }
  }, [refreshEnable])
  useEffect(e => {
     
    if (sortEmailObj && cacheEmails?.length > 0) {
      if (sortEmailObj.type != "default") {
        let sortedArray = [...cacheEmails];
        if (sortEmailObj.type == "Size" && sortEmailObj.order == "asc") {
          sortedArray = sortedArray.sort((a, b) => parseInt(a.size) - parseInt(b.size));
        } else if (sortEmailObj.type == "Size" && sortEmailObj.order == "desc") {
          sortedArray = sortedArray.sort((a, b) => parseInt(b.size) - parseInt(a.size));
        }
        if (sortEmailObj.type == "Date" && sortEmailObj.order == "asc") {
          sortedArray = sortedArray.sort((a, b) => new Date(parseInt(a.receivedTime)) - new Date(parseInt(b.receivedTime)));
        } else if (sortEmailObj.type == "Size" && sortEmailObj.order == "desc") {
          sortedArray = sortedArray.sort((a, b) => new Date(parseInt(b.receivedTime)) - new Date(parseInt(a.receivedTime)));
        }
        if (sortEmailObj.type == "From") {
          sortedArray = sortArrayOfObjects(sortedArray, "sender", sortEmailObj.order);
        }
        if (sortEmailObj.type == "To") {
          sortedArray = sortArrayOfObjects(sortedArray, "toAddress", sortEmailObj.order);
        }
        if (sortEmailObj.type == "Subject") {
          sortedArray = sortArrayOfObjects(sortedArray, "subject", sortEmailObj.order);
        }
        setAllEmails(sortedArray);
      } else {
        setAllEmails(cacheEmails)
      }
    }
  }, [sortEmailObj])
  function replaceFirstOccurrence(obj, newFrom, oldFrom) { 
    const foundIndex = obj["to"].findIndex(item => item.email == oldFrom.email);
    if (foundIndex !== -1) {
      obj["to"][foundIndex] = oldFrom;
    } else {
      obj["to"] = [oldFrom, ...obj["to"]];
    }
    obj["cc"] = obj["cc"]?.filter(item => item.email != newFrom);
    obj["to"] = obj["to"]?.filter(item => item.email != newFrom);
    obj["bcc"] = obj["bcc"]?.filter(item => item.email != newFrom);
    return obj;
  }
  function onToggleCollapse(e) {
     
    let parent = e.currentTarget.parentElement;
    let range = parent?.id;
    let shouldShow = true;
    if (range) {
      parent.querySelectorAll(".arrowicon").forEach(icon => {
        if (icon.classList.contains("Display-Visible")) {
          icon.classList.remove("Display-Visible");
          icon.classList.add("Display-Hidden");
        } else if (icon.classList.contains("Display-Hidden")) {
          icon.classList.remove("Display-Hidden");
          icon.classList.add("Display-Visible");
        }
      })
      parent.parentElement.parentElement.querySelectorAll(`.${range}`).forEach(card => {
        if (card.classList.contains("Display-Visible")) {
          card.classList.remove("Display-Visible");
          card.classList.add("Display-Hidden");
        } else if (card.classList.contains("Display-Hidden")) {
          card.classList.remove("Display-Hidden");
          card.classList.add("Display-Visible");
        }
      })

    }
  }

  async function getViewEmailData(viewEmailData, flag) { 
     
    if (viewEmailData) {
      let obj = {
        ...composeMailObj
      }
      // obj["from"] = userinfo.email_id;
      if(flag != "forward"){
        await getEmailsFromString(viewEmailData.toAddress, allUsers, obj).then(data => {
          obj["to"] = data?.filter(d => d.email);
        });
        await getEmailsFromString(viewEmailData.ccAddress, allUsers, obj).then(data => {
          obj["cc"] = data?.filter(d => d.email);
        });
        await getVerifiedMAilObject(viewEmailData.fromAddress, allUsers, obj).then(data => {
          if(selectedFolder?.folderName != "Drafts"){
           obj = replaceFirstOccurrence(obj, userinfo.email_id, data[0])
          }
          obj["from"] = userinfo.email_id;
          obj["subject"] = viewEmailData.subject;
        })
      }else{
        obj["to"] = [];
        obj["cc"] = [];
        obj["from"] = userinfo.email_id;
        obj["subject"] = "Fwd: " + viewEmailData.subject;
      }
      
      if(selectedFolder?.folderName == "Drafts" || flag == "forward"){ 
        setDraftEmailContent(viewEmailData.content)
        setDraftId(viewEmailData.messageId);
      }
      setComposeMailObj(obj);
      setReplyEmail(true);
      setContentShowFlag(flag);
    } else {
      setComposeMailObj({
        from: "",
        to: [],
        cc: [],
        bcc: [],
        subject: "",
      });
    }
  }


  async function getAddressToShow(viewEmailData) { 
     
    if (viewEmailData) {
      let obj = {
        ...composeMailToShow
      }
      // obj["from"] = userinfo.email_id;
      await getEmailsFromString(viewEmailData.toAddress, allUsers, obj).then(data => {
        obj["to"] = data?.filter(d => d.email);
      });
      await getEmailsFromString(viewEmailData.ccAddress, allUsers, obj).then(data => {
        obj["cc"] = data?.filter(d => d.email);
      });
      await getEmailsFromString(viewEmailData.bccAddress, allUsers, obj).then(data => {
        obj["bcc"] = data?.filter(d => d.email);
      });
      await getVerifiedMAilObject(viewEmailData.fromAddress, allUsers, obj).then(data => {
        obj["from"] = data[0].email;
      })
      obj["subject"] = viewEmailData.subject;
     
      setComposeMailToShow(obj);
    } else {
      setComposeMailToShow({
        from: "",
        to: [],
        cc: [],
        bcc: [],
        subject: "",
      });
    }
  }

  useEffect(e => {
    if (contentShowFlag == "compose" || contentShowFlag == "") {
      setDraftEmailContent("")
      if(selectedFolder?.folderName != "Drafts"){ 
        setAttachments([])
      }
      setComposeMailObj({
        from: "",
        to: [],
        cc: [],
        bcc: [],
        subject: "",
      })
    }
  }, [contentShowFlag])
  const convertImage = (attachement) => {
    var b64encoded = btoa(String.fromCharCode.apply(null, attachement.content.data));
    var datajpg = "data:image/jpg;base64," + b64encoded;
    return datajpg;
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (ev) => {
        const array = new Uint8Array(ev.target.result);
        const fileByteArray = [];
        for (let i = 0; i < array.length; i++) {
          fileByteArray.push(array[i]);
        }
        resolve(array);  // successful
        // resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleUploadFiles = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'uploadFiles');
    link.setAttribute('multiple', "multiple");
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      // let formData = new FormData();
      // formData.append('uploaddoc', e.currentTarget.files[0])
      // updateZohoTask(formData)
      handleFileAttachments(e.currentTarget.files);
    }

    document.body.removeChild(link);
  }

  const handleMultipleDeleteMail = (mailsToDelete) => {
    if (!mailsToDelete || !Array.isArray(mailsToDelete) || mailsToDelete.length === 0) {
      return;
    }
    swal({
      title: "Are you sure?",
      text: "You want to delete selected emails!",
      icon: "warning",
      buttons: ['No', 'Yes'],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        let messageIds=mailsToDelete?.map((one)=>one.messageId)
        setLoader(true);
        const deletePromises = mailsToDelete.map(mail => {
          return EmailService.DeleteEmail(mail.accountId, mail.folderId, mail.messageId);
        });
        Promise.all(deletePromises)
          .then(async results => { 
            mailsToDelete.map(async mail => {
              let obj = {
                "messageId" : mail.messageId,
                "folderId" : mail.folderId
              }
             await EmailService.AddTrashRecord(obj)
              .then((res)=>{
                console.log("res", res);
              }).catch((e)=>{
                console.log("e", e);
              })
            });
            if(selectedItem=="Archived"){
              await EmailService.ArchiveEmails(currentAccounts.accountId, messageIds,"unArchiveMails").then(rr=>{
                console.log("-------------",rr)
              }).catch(er=>{
                console.log("-------------",er)
              })
            }
            setLoader(false);
            setViewEmail(null);
            let updatedEmails = emails.filter(e => !mailsToDelete.some(del => del.messageId === e.messageId));
            if(clickedFromBody){
              let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
              .map(email => email.messageId);
              updatedEmails=updatedEmails?.filter(item =>
                messageIds.includes(item.messageId)
              );
            }
            setAllEmails(updatedEmails);
            setCacheEmails(updatedEmails);
            setContentShowFlag("");
            resetSelections();
            isHeadersVisible();
            swal("Success!", "Emails moved to trash successfully.", "success");
          })
          .catch(err => {
            setLoader(false);
            console.error("Error deleting emails:", err);
          })
          .finally(()=>{
            setLoader(false);
          })
      }
    });
  }

  const handleHardDeleteSelectedMails = (mailsToDelete) => {
    if (!mailsToDelete || !Array.isArray(mailsToDelete) || mailsToDelete.length === 0) {
      return;
    }
    swal({
      title: "Are you sure?",
      text: "You want to delete selected emails!",
      icon: "warning",
      buttons: ['No', 'Yes'],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        setLoader(true);
        const deletePromises = mailsToDelete.map(mail => {
          return EmailService.HardDeleteEmail(mail.accountId, mail.folderId, mail.messageId)
        });
        Promise.all(deletePromises)
          .then(results => { 
            setLoader(false);
            setViewEmail(null);
            let updatedEmails = emails.filter(e => !mailsToDelete.some(del => del.messageId === e.messageId));
            if(clickedFromBody){
              let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
              .map(email => email.messageId);
              updatedEmails=updatedEmails?.filter(item =>
                messageIds.includes(item.messageId)
              );
            }
            setAllEmails(updatedEmails);
            setCacheEmails(updatedEmails);
            setContentShowFlag("");
            resetSelections();
            console.log("Messages deleted permanentaly");
            isHeadersVisible();
            swal("Success!", "Emails deleted successfully.", "success");
          })
          .catch(err => {
            setLoader(false);
            console.error("Error deleting emails:", err);
          })
          .finally(()=>{
            setLoader(false);
          })
      }
    });
  }

  const archiveAllMail = async (mails, mode) => {
    if (!mails || !Array.isArray(mails) || mails.length === 0) {
      return;
    } 
    setLoader(true); 
      const messageIds = mails.map(mail => mail.messageId);
      const accountId = mails[0].accountId;
    await EmailService.ArchiveEmails(accountId, messageIds, mode).then((result)=>{
        setViewEmail(null); 
        if (mode === "markAsUnread" || mode === "markAsRead") {
        if (mode === "markAsUnread") {
          let arrayData = [...cacheUnreadData];
          arrayData.push(...mails);
          if(clickedFromBody){
            let messageIds2=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
            .map(email => email.messageId);
            arrayData=arrayData?.filter(item =>
              messageIds2.includes(item.messageId)
            );
          }
          setCacheUnreadData(arrayData);
        } else if (mode === "markAsRead") {
          getAllEmails(currentAccounts.accountId, null, "status=unread");
          resetSelections();
          let filtereddata = cacheUnreadData.filter(e => !mails.some(m => m.messageId === e.messageId));
          if(clickedFromBody){
            let messageIds2=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
            .map(email => email.messageId);
            filtereddata=filtereddata?.filter(item =>
              messageIds2.includes(item.messageId)
            );
          }
          setCacheUnreadData(filtereddata);
        }
      } else {
        let updatedEmails = emails.filter(e => !mails.some(m => m.messageId === e.messageId));
        if(clickedFromBody){
          let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
          .map(email => email.messageId);
          updatedEmails=updatedEmails?.filter(item =>
            messageIds.includes(item.messageId)
          );
        }
        setAllEmails(updatedEmails);
        setCacheEmails(updatedEmails);
        resetSelections();
        isHeadersVisible();
        if(mode=="unArchiveMails"){
          swal("Success!", "Emails Unarchived Successfully.", "success");
        }else if(mode=="markNotSpam"){
          swal("Success!", "Emails marked as not spam successfully.", "success");
        }else{
          swal("Success!", "Emails archived successfully.", "success");
        }
      } 

      setContentShowFlag("");
      setLoader(false);
    })
     .catch( (error)=>{
      setLoader(false);
      console.error("Error archiving emails:", error);
    })
    .finally(()=>{
      setLoader(false);
    })
  };  

  const handleDeleteMail = (data,multiple) => {
    if (!data) {
      return;
    }
    swal({
      title: "Are you sure?",
      text: selectedItem !="Trash" ? "you want to trash this email?" : "you want to delete this email?",
      icon: "warning",
      buttons: [
        'No',
        'Yes'
      ],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        await EmailService.DeleteEmail(data.accountId, data.folderId, data.messageId)
          .then(async(result) => {
            let obj = {
              "messageId" : data.messageId,
              "folderId" : data.folderId
            }
           await EmailService.AddTrashRecord(obj)
            .then((res)=>{
              console.log("res", res);
            }).catch((e)=>{
              console.log("e", e);
            })
            if(selectedItem=="Archived"){
              await EmailService.ArchiveEmails(data.accountId, [data.messageId],"unArchiveMails").then(rr=>{
                console.log("-------------",rr)
              }).catch(er=>{
                console.log("-------------",er)
              })
            }
            setViewEmail(null);
            let filteredArray = emails?.filter(e => e.messageId != data.messageId);
            if(clickedFromBody){
              let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
              .map(email => email.messageId);
              filteredArray=filteredArray?.filter(item =>
                messageIds.includes(item.messageId)
              );
            }
            setAllEmails(filteredArray);
            setCacheEmails(filteredArray);
            setContentShowFlag("");
            if(selectedFolder?.folderName !="Trash"){
              swal("Success!", "Email  moved to trash successfully.", "success");
            }else{
              swal("Success!", "Email deleted successfully.", "success");
            }
          })
          .catch((err) => {
            console.log(err);
          })
      }
    })
  }

  const handleHardDeleteMail = (data,multiple) => {
    if (!data) {
      return;
    }
    swal({
      title: "Are you sure?",
      text: "you want to delete this email?",
      icon: "warning",
      buttons: [
        'No',
        'Yes'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        setLoader(true)
        EmailService.HardDeleteEmail(data.accountId, data.folderId, data.messageId)
          .then(async(result) => {
            setViewEmail(null);
            let filteredArray = emails?.filter(e => e.messageId != data.messageId);
            if(clickedFromBody){
              let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
              .map(email => email.messageId);
              filteredArray=filteredArray?.filter(item =>
                messageIds.includes(item.messageId)
              );
            }
            setAllEmails(filteredArray);
            setCacheEmails(filteredArray);
            setContentShowFlag("");
            setLoader(false);
            resetSelections();
            isHeadersVisible();
            swal("Success!", "Emails deleted successfully.", "success");
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
          })
      }
    })
  }

  const getRowIdsAndFolderIds=async (mailsToRestore)=>{
    const results= await Promise.all(mailsToRestore.map(one => EmailService.GetTrashDataById(one.messageId)))
    
   let allData=results.reduce((acc, result) => acc.concat(result.data), []);
   return allData;
  }
  const restoreMultipleEmails=async (mailsToRestore)=>{
    let ROWID=[];
    let obj={}; 
   let res= await getRowIdsAndFolderIds(mailsToRestore)
      if(res.length>0){
        res.forEach((response)=>{
          if(response?.TrashRestore){
                      let folderId=response?.TrashRestore?.folderId;
                      if(obj[folderId]){
                        obj[folderId]=[...obj[folderId],response.TrashRestore.messageId]
                      }else{
                        obj[folderId]=[];
                      }
                       ROWID.push(response?.TrashRestore?.ROWID);
        }
        })
      }
    let flag=false;
    setLoader(true);
Object.keys(obj)?.forEach(async one=>{
           await EmailService.RestoreDeletedMail(mailsToRestore[0]?.accountId, one ,obj[one], "moveMessage")
              .then(async(res)=>{
                setLoader(false);
                getAllEmails(currentAccounts?.accountId, selectedFolder?.folderId)
                resetSelections();
                flag=true;
                swal("Success!", "Emails restored successfully.", "success");
              // console.log(res);
              }).catch((e)=>{
              setLoader(false);
              console.log(e);
              })
          })
          if(flag){
            if(ROWID.length>0){
              await EmailService.DeleteTrashRecord([ROWID])
              .then((re)=>{
                console.log("moved from trash folder successfully");
                // swal("Success!", "Emails moved to trash successfully.", "success");
              }).catch((e)=>{
                console.log("ee", e);
              })
            }
          }
}

  const archiveMail = async (data, mode) => {
    if (!data) {
      return;
    }
    setLoader(true);
    await EmailService.ArchiveEmails(data.accountId, [data.messageId], mode)
      .then((result) => {
        setViewEmail(null);
        if(mode == "markAsUnread" || mode == "markAsRead"){
            if(mode == "markAsUnread"){
              let arrayData = [...cacheUnreadData];
              arrayData.push(data);
              if(clickedFromBody){
                let messageIds2=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
                .map(email => email.messageId);
                arrayData=arrayData?.filter(item =>
                  messageIds2.includes(item.messageId)
                );
              }
              setCacheUnreadData(arrayData)
            }else if(mode == "markAsRead"){
              // getAllEmails(currentAccounts.accountId, null, "status=unread");
              resetSelections();
              isHeadersVisible();
              let arrayData = [...cacheUnreadData];
              let filtereddata = arrayData.filter(e=> e.messageId != data.messageId);
              if(clickedFromBody){
                let messageIds2=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
                .map(email => email.messageId);
                filtereddata=filtereddata?.filter(item =>
                  messageIds2.includes(item.messageId)
                );
              }
              setCacheUnreadData(filtereddata)
            }
        }else{
          let filteredArray = emails?.filter(e => e.messageId != data.messageId);
          if(clickedFromBody){
            let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
            .map(email => email.messageId);
            filteredArray=filteredArray?.filter(item =>
              messageIds.includes(item.messageId)
            );
          }
          setAllEmails(filteredArray);
          setCacheEmails(filteredArray);
        }
        setContentShowFlag("")
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        console.log(err);
      })
  }

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...attachments];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      let base64 = await convertToBase64(fileList[index]);
      tempFileCollection.push({
        attachmentId:uuid(),
        attachmentName:fileName,
        file: fileList[index],
      });
      index = index + 1;
    }
    setAttachments(tempFileCollection);
    // let obj = {}
    // tempFileCollection.forEach((temp) => {
    //   obj.fileName = temp.fileName
    //   obj.file = temp.file
    // })
    //new logic
    // let temAttachmen=[...attachments];
    // fileList?.forEach(at=>{
    //   let att={
    //     attachmentId:"",
    //     attachmentName:at.name,
    //     file:at,
    //   }
    //   temAttachmen.push(att);
    // })
    // setLoader(true);
    if (fileList) {
      // await EmailService.UploadEmailAttachement(currentAccounts.accountId, fileList)
      //   .then((result) => {
           
      //     let arr=[...attachments];
      //     setAttachments([...arr,result.data[0]])
      //     console.log(result);
      //     setLoader(false);
      //   }).catch((err) => {
      //     setLoader(false);
      //     console.log(err);
      //   })
    }

    // setFileNames(tempFileCollection);
  };

  async function associateEmail(e){ 
    await EmailService.AssociateEmailWithRecords({
      messageId:e?.messageId,
      AssociationType:"Manual",
      AssociationObj:{Deals:[dealObj?.Deal_Name]}
    },"Post").then(res=>{ 
      swal("Success!", "Email associated successfully!", "success");
    }).catch(e=>{ 
      swal("Error!", "Something went wrong!", "error");
    }) 
}

  const handleOnChangeMessage = (e) => {
    const obj = { ...composeObj };
    obj[e.currentTarget.name] = e.currentTarget.value;
    setComposeObj(obj);
  }

  const UploadAttachments=async(files)=>{
    return new Promise(async(resolve,reject)=>{
      const results = await Promise.all(files.map(file => EmailService.UploadEmailAttachement(currentAccounts.accountId, file.file)));
      const allData = results.reduce((acc, result) => acc.concat(result.data), []);
       
      resolve(allData);
    })
  }
  const handleSendEmail = async (obj) => {
    if (currentAccounts) {
      setLoader(true)
      let alreadyUploadedAttachments = attachments.filter(e=> !e.file);
      alreadyUploadedAttachments=alreadyUploadedAttachments.map(k=>{delete k.attachmentSize;
        k["storeName"] = localStorage.getItem("ZUID")
         return k})
      let attachmentsToBeUploaded = attachments.filter(e=> e.file);
      let attachmentArr=[];
      if(attachmentsToBeUploaded && attachmentsToBeUploaded.length>0){
        await UploadAttachments(attachmentsToBeUploaded).then(res=>{
          if(res.length>0){
            attachmentArr=res.map(k=>{delete k.url; return k});
            }
        }).catch(e=>{
          console.log(e);
        })
      }
      if(alreadyUploadedAttachments && alreadyUploadedAttachments.length > 0){
        if(attachmentArr && attachmentArr.length>0){
          attachmentArr = [...attachmentArr, ...alreadyUploadedAttachments]
        }else{
          attachmentArr=alreadyUploadedAttachments;
        }
      }
      let attachmentsToBeSend = attachmentArr.filter(e=> e.attachmentPath);
      obj["attachments"] = attachmentsToBeSend;
      await EmailService.SendEmail(currentAccounts.accountId, obj)
        .then(async(result) => {
          setLoader(false)
          if(clickedFromBody){
            setreloadAssociations(result?.data)
            associateEmail(result?.data)
          }
          setComposeEmailPopUp(false);
          if(isSchedule){
            setOpenSchedulePopup(false);
            setScheduleObj({
              timeZone : null, 
              scheduleDate : dayjs(new Date()).format("MM/DD/YYYY"),
              time : dayjs().add(1, 'hour').format('HH:mm'),
            })
            setIsSchedule(false);
          }
          setContentShowFlag("")
          setAttachments([])
          setClearFields(!clearFields);
          if(obj?.mode=="draft"){
            await getAllEmailsWithoutLoader(currentAccounts?.accountId, selectedFolder?.folderId)
            setDraftId("")
            swal("Success!", "Email successfully saved in Drafts.", "success");
          }else{
            swal("Success!", "Email Send Successfully.", "success");
            if (draftId) {
              await DeleteDraftMsg(currentAccounts?.accountId, selectedFolder?.folderId, draftId)
            }
            await getAllEmailsWithoutLoader(currentAccounts?.accountId, selectedFolder?.folderId)
          }
        })
        .catch((err) => {
          setLoader(false)
          setAttachments([])
          swal("Error!", "Something went wrong!", "error");
          console.log(err);
        })
    }
  }

  const handleReplyEmail = async(obj) => {
    await UploadAttachments(attachments).then(res=>{
      if(res.length>0){
        obj["attachments"]=res.map(k=>{delete k.url; return k});
      }
    }).catch(e=>{
      console.log(e);
    })
    if (currentAccounts) {
      obj.action = 'reply'
      EmailService.ReplyToEmail(currentAccounts.accountId, viewEmail?.messageId, obj)
        .then((result) => {
          dispatch(Socket({trigger:"Check_Reply_Reminder",data:viewEmail?.messageId}))
          setComposeEmailPopUp(false)
          setViewEmail(null)
          setContentShowFlag("")
          setReplyEmail(false)
          setContentShowFlag("")
          setAttachments([])
          swal("Success!", "Email Reply Successfully!", "success");
        })
        .catch((err) => {
          setAttachments([])
          console.log(err);
        })
    }
  }

  const getIconByType=(name)=>{
    if(name.includes("pdf")){
      return <FontAwesomeIcon color='#007bff' icon={faFilePdf} />
    }else if(name.includes("xlx")){
      return <FontAwesomeIcon icon={faFileExcel} />
    }else if(name.includes("png") || name.includes("jpeg")){
      return <FontAwesomeIcon color='#007bff' icon={faFileImage} />
    }else{
      return <FontAwesomeIcon color='#007bff' icon={faFile} />
    }
  }

  const handleSelectAccounts = (values) => {
    console.log("values", values);
    setScheduleObj({...scheduleObj, timeZone : values.value})
  };
  
  const handleScheduleChange = (e) =>{
    let id = e.target.id;
    let obj = {...composeObj};
    setScheduleId(id);
    if(id == "6"){
      obj.scheduleTime = scheduleObj.scheduleDate + " " + scheduleObj.time + ":00"
      obj.scheduleDate = dayjs(obj.scheduleTime).format('MM/DD/YYYY')
    }else if(id == "1"){
      obj.scheduleTime = dayjs().add(1, 'hour').format('MM/DD/YYYY HH:mm:ss');
      obj.scheduleDate = dayjs(obj.scheduleTime).format('MM/DD/YYYY')
    }else if(id == "2"){
      obj.scheduleTime = dayjs().add(2, 'hour').format('MM/DD/YYYY HH:mm:ss');
      obj.scheduleDate = dayjs(obj.scheduleTime).format('MM/DD/YYYY')
    }else if(id == "3"){
      obj.scheduleTime = dayjs().add(4, 'hour').format('MM/DD/YYYY HH:mm:ss');
      obj.scheduleDate = dayjs(obj.scheduleTime).format('MM/DD/YYYY')
    }else if(id == "4"){
      obj.scheduleTime = (dayjs().add(1, 'day').hour(10).minute(0).second(0)).format('MM/DD/YYYY HH:mm:ss');
      obj.scheduleDate = dayjs(obj.scheduleTime).format('MM/DD/YYYY')
    }
    setScheduleObj({...scheduleObj, 
      time : dayjs(obj.scheduleTime).format('HH:MM'),
      scheduleDate : obj.scheduleDate,
      timeZone : null
    })
  }

  const getTime = (date) => {
    // let Time =  new Date(date);
    // Time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    if ((date == 'N/A') || (date == '')) {
      return date
    } else {
      try {
        var hours = date.getHours();
        var minutes = date.getMinutes();


        // if(seconds>=30){
        //   minutes=minutes+1;
        // }
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }
      catch (ex) {
      }
    }
  };

  const convertTime12to24 = (time12h) => {

    if (time12h) {
      time12h = getTime(time12h);
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes] = time.split(':');

      if (hours === '12') {
        hours = '00';
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      hours = (hours.length == 1) ? '0' + hours : hours;
      return `${hours}:${minutes}`;
    }
    return time12h;

  }


  const onChangeTime = (name, e) => {
    console.log("time", e.currentTarget.value);
    let time = e.currentTarget.value;
    // let obj = {...scheduleObj}
    if (moment(scheduleObj.scheduleDate + " " + e.currentTarget.value + ":00").isSameOrBefore(moment(new Date()))) {
      setErrorShow(true);
    }else{
      setErrorShow(false)
    }
      setScheduleObj({...scheduleObj, time : time})
  };

  const handleScheduleCancel= () =>{
    setIsSchedule(!isSchedule)
    setOpenSchedulePopup(!openSchedulePopup);
    setScheduleId("1");
    setScheduleObj({...scheduleObject})
  }
  const handleCloseScheduleModal= () =>{
    setOpenSchedulePopup(!openSchedulePopup); setIsSchedule(!isSchedule);
      setScheduleId("1");
      setScheduleObj({...scheduleObject})
  }

  const handleSaveSchedule= () =>{
    if(!errorShow){
      let obj = {...composeObj};
      obj.content = emailContent.toString("html");
      obj.subject = composeMailObj?.subject;
      obj.toAddress = composeMailObj?.to
        ?.map((e) => e.email)
        ?.join(",");
      obj.ccAddress = composeMailObj?.cc
        ?.map((e) => e.email)
        ?.join(",");
      obj.bccAddress = composeMailObj?.bcc
        ?.map((e) => e.email)
        ?.join(",");
      obj.mailFormat = "html";
      obj.isSchedule = scheduleObj.isSchedule
      obj.scheduleType = scheduleObj.scheduleType
      obj.timeZone = scheduleObj.timeZone == null ? "America/New_York" : scheduleObj.timeZone;
      obj.scheduleTime = scheduleObj.scheduleDate + " " + scheduleObj.time + ":00";
      // obj.scheduleDate = dayjs(scheduleObj.scheduleDate).format("MM/DD/YYYY");  
      handleSendEmail(obj)
    }
  }

  function disabledDateLocal(current) {
    // Get the start of today
    const today = moment().startOf('day');
  
    // Check if the current date is before today
    if (current && current.isBefore(today, 'day')) {
      return true;
    }
  
    // If you have an array of blocked dates, you can uncomment and use the following code:
    // Check if the current date is in the array of blocked dates
    // return disableDates.some(date => current && current.isSame(date, 'day'));
    
    return false; // Return false to enable dates that are today or in the future
  }
  
  const AttachmentPopUp = () => {
    return <AntDModal 
    title="Attachments" 
    className='Attachment-PopUp'
    open={openAttachmentPopUp}
    onOk={e=>setOpenAttachmentPopUp(!openAttachmentPopUp)}
    onCancel={e=>setOpenAttachmentPopUp(!openAttachmentPopUp)}
    >
    {attachments?.map(att=>{
      return (<>
      <Row sm={12}>
        <Col sm={2}>{getIconByType(att?.attachmentName)}</Col>
        <Col sm={8}>{att?.attachmentName}</Col>
        <Col sm={2} className='Attach-Delete-Icon'><FontAwesomeIcon onClick={e=>{
          let arr=attachments.filter(a=>a.attachmentId!=att.attachmentId);
          setAttachments(arr);
          if(arr.length == 0){
            setOpenAttachmentPopUp(false)
          }
        }} icon={faTrashAlt}/></Col>
      </Row>
      </>) 
    })}
  </AntDModal>
  }

  const SchedulePopUp = () => {
    return <AntDModal 
    title="Schedule" 
    className='Attachment-PopUp Schedule-PopUp'
    open={openSchedulePopup}
    // onOk={e=>setOpenSchedulePopup(!openSchedulePopup)}
    onCancel={e=>{handleCloseScheduleModal(e)}}
    footer={[
      <AntButton key="back" onClick={(e) => handleScheduleCancel()}>
        Cancel
      </AntButton>,
      <AntButton key="submit" type="primary" onClick={(e) => handleSaveSchedule()}>
        Schedule and send
      </AntButton>,
    ]}
    >
      {
        ScheduleArr && ScheduleArr.map((one, index)=>{
          return (
            <FormGroup className='schedule-input-wrapper schedule-formGroup' key={index}>
              <Row>
              <Col sm={1}></Col>
                <Col sm={8}>
                <div className="radioStyle">
                  <Input
                  style={{marginTop:".7rem"}}
                    type="radio"
                    name={"Schedule"}
                    id={one.id}
                    value={one.name}
                    checked={one.id == scheduleId}
                    onChange={(e) => {handleScheduleChange(e)}}
                  />
                </div>
                  <Label
                    className="text-bold schedule-option"
                    for="preliminaryresearch"
                  >
                    {one.name}
                  </Label>
                </Col>
              </Row>
            </FormGroup>
          )
        })
      }
    {scheduleId == "6" && 
          <FormGroup className='schedule-date-wrapper schedule-formGroup'>
          <Row>
            {/* <Col sm={3}></Col> */}
              <Col sm={6}>
              <Label
                  className="text-bold schedule-option"
                  for="preliminaryresearch"
                >
                Select Date
                </Label>
                <AntdDatepicker
                  disabledDate={disabledDateLocal}
                  allowClear
                  portalId="root"
                  format="MM/DD/YYYY"
                  value={getDatePickerValue(scheduleObj.scheduleDate)}
                  placeholderText="Select a date"
                  popperClassName="ag-custom-component-popup"
                  className="form-control form-control-sm"
                  onChange={(date) => {
                    let editObj = { ...scheduleObj };
                    editObj["scheduleDate"] = dayjs(new Date(date)).format("MM/DD/YYYY");
                    setScheduleObj(editObj);
                  }}
                  open={showDatePicker}
                  onOpenChange={open => setShowDatePicker(open)}
                />
                
              </Col>
              <Col sm={6}>
              <Label
                  className="text-bold schedule-option"
                  for="preliminaryresearch"
                >
                Select Time
                </Label>
                <input
                  type="time"
                  className="form-control form-control-sm"
                  format="HH:mm a"
                  style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
                  onChange={(e) => {
                    onChangeTime("time", e);
                  }}
                  // value={"01:00"}
                  value={scheduleObj ? scheduleObj.time : ""}
                />
                {
                  errorShow && <span style={{color : "red"}}>The Time cannot be in past!</span>
                }
              </Col>
          </Row>
          <Row>
            {/* <Col sm={3}></Col> */}
              <Col sm={6}>
              <Label
                  className="text-bold schedule-option"
                  for="preliminaryresearch"
                >
                Select Timezone
                </Label>
                <Select
                      options={timeZones}
                      closeMenuOnSelect={true}
                      onChange={(values) => handleSelectAccounts(values)}
                      value={
                        scheduleObj
                          ? {label : scheduleObj.timeZone , value : scheduleObj.timeZone}
                          : null
                      }
                      menuPlacement="auto"
                    />
                
              </Col>
          </Row>
          </FormGroup>
          
          }
      
        {/* <FormGroup className='schedule-input-wrapper'>
              <Row>
              <Col sm={3}></Col>
                <Col sm={8}>
                <div className="radioStyle">
                  <Input
                    type="radio"
                    name={"Schedule"}
                    checked={false}
                    onChange={(e) => {
                      
                    }}
                  />
                </div>
                  <Label
                    className="text-bold schedule-option"
                    for="preliminaryresearch"
                  >
                    10 Minutes
                  </Label>
                </Col>
              </Row>
              <Row>
              <Col sm={3}></Col>
                <Col sm={8}>
                <div className="radioStyle">
                  <Input
                    type="radio"
                    name={"Schedule"}
                    checked={false}
                    onChange={(e) => {
                      
                    }}
                  />
                </div>
                  <Label
                    className="text-bold schedule-option"
                    for="preliminaryresearch"
                  >
                    1 hour
                  </Label>
                </Col>
              </Row>
              <Row>
              <Col sm={3}></Col>
                <Col sm={8}>
                <div className="radioStyle">
                  <Input
                    type="radio"
                    name={"Schedule"}
                    checked={false}
                    onChange={(e) => {
                      
                    }}
                  />
                </div>
                  <Label
                    className="text-bold schedule-option"
                    for="preliminaryresearch"
                  >
                    Tomorrow @ 10.00 AM
                  </Label>
                </Col>
              </Row>
        </FormGroup> */}
  </AntDModal>
  }

  const createSubFolder=async()=>{
    if(subFolderName && openSubFolderPopup?.folderId){
      let payLoad={
        parentFolderId:openSubFolderPopup?.folderId?.folderId,
        folderName:subFolderName
      }
      setLoader(true);
      await EmailService.CreateSubFolders(currentAccounts?.accountId,payLoad).then(async res=>{
        setOpenSubFolderPopup(openSubFolderTemplate);
        setSubFolderName("")
        await EmailService.GetCurrentEmailAccount(userinfo?.email_id).then((result) => {
          setCurrentAccounts(result.data[0]);
        }).catch((err) => {
          console.log(err)
        })
        setLoader(false);
      }).catch(err=>{
        console.log(err)
        setLoader(false);
      }).finally(e=>{
        setLoader(false);
      })
    }
  }
  const SubFolderPopUp = () => {
    return <AntDModal 
    title="Create Sub Folder" 
    className='Attachment-PopUp Schedule-PopUp'
    open={openSubFolderPopup.status}
    onCancel={e=>{setOpenSubFolderPopup(!openSubFolderPopup?.status)}}
    footer={[
      <AntButton key="back" onClick={(e) =>{setOpenSubFolderPopup(!openSubFolderPopup?.status)}}>
        Cancel
      </AntButton>,
      <AntButton key="submit" type="primary" onClick={(e) => {
        createSubFolder();
      }}>
        Create Sub-folder
      </AntButton>,
    ]}
    >
      <FormGroup className='schedule-formGroup'>
        <Row>
          <Col sm={1}></Col>
          <Col sm={3}>
            <Label
                    className="text-bold schedule-option"
                    for="preliminaryresearch"
                  >
            Folder Name :
            </Label>
          </Col>
          <Col sm={7}>
            <input 
              className="form-control form-control-sm"
              type='text'
              value={subFolderName}
              onChange={e=>{
                setSubFolderName(e.currentTarget.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
          
  </AntDModal>
  }

  async function handleOnClicksForContext(data,type){
    if(type=="DELETE"){
      setLoader(true);
      await EmailService.DeleteSubFolder(localStorage.getItem("AccountID"),data.folderId).then(async res=>{
        await EmailService.GetCurrentEmailAccount(userinfo?.email_id).then((result) => {
          setCurrentAccounts(result.data[0]);
        }).catch((err) => {
          console.log(err)
        })
        setLoader(false);
      }).catch(err=>{
        setLoader(false);
      })
    }else if(type=="EMPTY"){
      setLoader(true);
      await EmailService.EmptyFolder(localStorage.getItem("AccountID"),data.folderId).then(async res=>{
        await EmailService.GetCurrentEmailAccount(userinfo?.email_id).then((result) => {
          setCurrentAccounts(result.data[0]);
        }).catch((err) => {
          console.log(err)
        })
        setLoader(false);
      }).catch(err=>{
        setLoader(false);
      })
    }else if(type=="MOVEFOLDER"){
      setLoader(true);
      await EmailService.MoveToFolder(localStorage.getItem("AccountID"),data.folderId,data.messageId).then(async res=>{
        await EmailService.GetCurrentEmailAccount(userinfo?.email_id).then((result) => {
          setCurrentAccounts(result.data[0]);
        }).catch((err) => {
          console.log(err)
        })
        setLoader(false);
      }).catch(err=>{
        setLoader(false);
      })
    }
  }

  const getText = (text, taskid) => {
    let type = ['email', 'popup', 'notification'];
    text = text.replaceAll(`#$taskid:${taskid}$#`, '')
    text = text.replaceAll(`<span>`, '');
    text = text.replaceAll(`</span>`, '');
    type.forEach((one) => {
      text = text.replaceAll(`#$type:${one}$#`, '')
    })
    return text.trim();
  }

  const convertReminderEmailSubject = async (subject) => {

    const deals = [...cacheDeals]
    const tasks = [...allTask]
    if (subject.startsWith("Event reminder")) {
      subject = getText(subject)
      for (let t = 0; t < tasks.length; t++) {
        if (subject.includes(`taskid:${tasks[t].id_string}`)) {
          let deal = deals.filter(d => d.Project_ID && d.Project_ID.includes(tasks[t].project_id))
          if (deal.length > 0) {
            deal = deal[0];
            let clientColor = await TaskService.GetClientColor(deal.Account_Name.id);
            let dealColor = await TaskService.GetDealColor(deal.Stage);
            subject = `${getText(subject, tasks[t].id_string)} <a style="color:#40a9ff" id="Task-${tasks[t].id_string}">${tasks[t].name}</a>  <a style="background-color:${clientColor}" id="Client-${deal.Account_Name ? deal.Account_Name.id : ''}">${deal.Account_Name ? deal.Account_Name.name : ''}</a>  <a style="background-color:${dealColor}" id="Deal-${deal.id}">${deal.Deal_Name}</a> `
          }
        }
      }
    }
    return subject;
  }

  // const getAllEmails = () => {
  //     setLoader(true)
  //     axios.get('http://localhost:4000/api/v1/emails')
  //         .then(async (result) => {
  //             for(let i=0;i<result.data.length;i++){
  //                 result.data[i].subject=await convertReminderEmailSubject(result.data[i].subject)
  //             }
  //             result.data.sort((a, b) => new Date(b.date) - new Date(a.date))

  //             setAllEmails(result.data);
  //             setCacheEmails(result.data);
  //             if (setRefreshEnable) {
  //                 setRefreshEnable(false)
  //             }
  //             setLoader(false)
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //             setLoader(false)
  //         })
  // }


  const getAllEmailMessages = async (accountId, folderId, isArchive) => {
    setLoader(true)
    await EmailService.GetAllEmailMessages(accountId, folderId, isArchive)
      .then(async (result) => {
        result = validation.RemoveNestedArray(result.data);
        let data = [...result]
        data.sort((a, b) => new Date(parseInt(b.receivedTime)) - new Date(parseInt(a.receivedTime)))
        for (let i = 0; i < data.length; i++) {
          data[i].subject = await convertReminderEmailSubject(data[i].subject)
        }
        if(clickedFromBody){
          let messageIds2=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
          .map(email => email.messageId);
          data=data?.filter(item =>
            messageIds2.includes(item.messageId)
          );
        }
        setCacheUnreadData(data);
        if (setRefreshEnable) {
          setRefreshEnable(false)
        }
        setLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false)
      })
  }

  const getAllEmails = async (accountId, folderId, isArchive,flag) => {
    setLoader(true)
    if(flag){
      let result=[]
      await getAllMailData(currentAccounts.Folders,accountId,isArchive).then((res)=>{
        result=res
      });
      let data = []
      if(result?.length>0){
        data = [...result]
          data.sort((a, b) => new Date(parseInt(b.receivedTime)) - new Date(parseInt(a.receivedTime)))
          for (let i = 0; i < data.length; i++) {
            data[i].subject = await convertReminderEmailSubject(data[i].subject)
          }
          if (setRefreshEnable) {
            setRefreshEnable(false)
          }
        }
        if(clickedFromBody){
          let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
          .map(email => email.messageId);
          data=data?.filter(item =>
            messageIds.includes(item.messageId)
          );
        }
        setLoader(false)
        setCacheAllEmailData(data);
    }else{
      await EmailService.GetAllEmails(accountId, folderId, isArchive)
        .then(async (result) => {
          let data=[];
          if(result?.data?.length>0){
          result = validation.RemoveNestedArray(result.data);
          data = [...result]
          data.sort((a, b) => new Date(parseInt(b.receivedTime)) - new Date(parseInt(a.receivedTime)))
          for (let i = 0; i < data.length; i++) {
            data[i].subject = await convertReminderEmailSubject(data[i].subject)
          }
        }
        if(clickedFromBody){
          let messageIds=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
          .map(email => email.messageId);
          data=data?.filter(item =>{
            if(messageIds.includes(item.messageId)){ 
             return item
            }
           });
        }
        setLoader(false)
          setCacheAllEmailData(data);
          if (setRefreshEnable) {
            setRefreshEnable(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setLoader(false)
        })
    }
  }

  const getAllEmailsWithoutLoader = async (accountId, folderId, isArchive,flag) => {
    // setLoader(true)
    if(flag){
      let result=[]
      await getAllMailData(currentAccounts.Folders,accountId,isArchive).then((res)=>{
        result=res
      });
      let data = []
      if(result?.length>0){
        data = [...result]
          data.sort((a, b) => new Date(parseInt(b.receivedTime)) - new Date(parseInt(a.receivedTime)))
          for (let i = 0; i < data.length; i++) {
            data[i].subject = await convertReminderEmailSubject(data[i].subject)
          }
          if (setRefreshEnable) {
            setRefreshEnable(false)
          }
        }
        if(clickedFromBody){
          let messageIds2=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
          .map(email => email.messageId);
          data=data?.filter(item =>
            messageIds2.includes(item.messageId)
          );
        }
        setCacheAllEmailData(data);
        // setLoader(false)
    }else{
      await EmailService.GetAllEmails(accountId, folderId, isArchive)
        .then(async (result) => {
          let data=[];
          if(result?.data?.length>0){
          result = validation.RemoveNestedArray(result.data);
          data = [...result]
          data.sort((a, b) => new Date(parseInt(b.receivedTime)) - new Date(parseInt(a.receivedTime)))
          for (let i = 0; i < data.length; i++) {
            data[i].subject = await convertReminderEmailSubject(data[i].subject)
          }
        }
        if(clickedFromBody){
          let messageIds2=allAssociatedEmails?.filter(email => email.AssociationObj?.Deals?.includes(dealObj?.Deal_Name))
          .map(email => email.messageId);
          data=data?.filter(item =>
            messageIds2.includes(item.messageId)
          );
        }
          setCacheAllEmailData(data);
          if (setRefreshEnable) {
            setRefreshEnable(false)
          }
          // setLoader(false)
        })
        .catch((err) => {
          console.log(err);
          // setLoader(false)
        })
    }
  }

  const items = [
    {
      label: <a
      onClick={(e)=>{
          if(composeMailObj?.to?.length == 0){
            e.preventDefault();
            swal("Alert!", `No recipients added in "To" field`, "warning");
          }else{
            setOpenSchedulePopup(!openSchedulePopup);
            setIsSchedule(true);
          }
      }}
      >
          Send Later
      </a>,
      key: '1',
    }
  ]

  const composeEmailComponent = (isReply, isForward) => {

    return (
      <>
        <Col sm={12} className="Compose-Email-Wrapper">
          <Row sm={12}>
            <Col sm={11} className="email-buttons">
              <AntDropdown.Button 
                menu={{
                  items,
                }} 
                className='compose-btns' 
                trigger={"click"}
                icon={<DownOutlined />}
                onClick={()=>handleSendButton(emailContent, isReply)}
              >
                  Send
              </AntDropdown.Button>
			        {clickedFromBody!=true && <><Button
                onClick={(e) => {
                handleSaveDraft(emailContent);
                setEmailContent("");
                }}
                  className="compose-btns"
                >
                  Save Draft
              </Button>
				      <Button
                title='Upload attachments'
                onClick={() => {
                handleUploadFiles();
                }}
                className="compose-btns"
                style={{position: "relative"}}
                >
                  <FontAwesomeIcon icon={faPaperclip} />
              </Button>
              
              <Button
                onClick={() => {
                  // handleUploadFiles();
                  setOpenAssociatePopUp({
                    status: true,
                    possibleAssociationFlag: false,
                    email: null,
                    context: false
                  })
                }}
                className="compose-btns"
                >
                  <FontAwesomeIcon icon={faTags} />
              </Button></>}
              {/* <Button
                onClick={() => {
                  setShowCC(!showCC)
                }}
                className="compose-btns"
                >
                  {showCC ? "Show CC" : "Hide CC"}
              </Button>
              <Button
                onClick={() => {
                  setShowBCC(!showBCC)
                }}
                className="compose-btns"
                >
                  {showBCC ? "Show BCC" : "Hide BCC"}
              </Button> */}
			        <Button
                onClick={() => {
                  setEmailContent("");
                  setViewEmail(null);
                  setReplyEmail(false);
                  setContentShowFlag("");
                  setShowCC(false);
                  setShowBCC(false);
                }}
                className="compose-btns"
              >
                Cancel
              </Button>
              {attachments && attachments.length>0 && <span onClick={e=>{
                setOpenAttachmentPopUp(!openAttachmentPopUp)
              }} className="Attachmnets-Btn">Attachments ({attachments?.length})</span>}                 
            </Col>
            <Col sm={1} className="mb-2 mt-1 right">
              <button
                onClick={() => {
                  swal({
                    //  text: 'Are you sure?',
                    title: "Save this email as Draft?",
                    icon: "warning",
                    buttons: ["Discard", "Save Draft"],
                    dangerMode: true,
                  }).then(async(save) => {
                    if (save) {
                      const obj = { ...composeObj };
                      await UploadAttachments(attachments).then(res=>{
                        if(res.length>0){
                          obj["attachments"]=res.map(k=>{delete k.url; return k});
                        }
                      }).catch(e=>{
                        console.log(e);
                      })

                      setIsDraftAlready(false);
                      if (emailContent) {
                        let msg = emailContent.toString("html");
                        msg = msg.replace("<p>", "");
                        msg = msg.replace("</p>", "");
                        msg = msg.replace("<br>", "");
                        msg = msg.replaceAll("&nbsp;", "");
                        msg = msg
                          .replace("<p>", "")
                          .replace("</p>", "")
                          .replace("<br>", "")
                          .replace("<h1>", "")
                          .replace("</h1>", "")
                          .replace("<h2>", "")
                          .replace("</h2>", "")
                          .replace("<h3>", "")
                          .replace("</h3>", "")
                          .replace("<em>", "")
                          .replace("</em>", "")
                          .replace("<h1></h1>", "")
                          .replace("<h2></h2>", "")
                          .replace("<h3></h3>", "")
                          .replace("<pre><code></code></pre>", "")
                          .replace("<blockquote></blockquote>", "")
                          .replace("<ul>↵ <li></li>↵</ul>", "")
                          .replace("<ol>↵ <li></li>↵</ol>", "");
                        obj.content = emailContent;
                        obj.mode = "draft";
                        if (draftId) {
                          await DeleteDraftMsg(currentAccounts?.accountId, selectedFolder?.folderId, draftId)
                        }
                        obj.subject = composeMailObj?.subject;
                        obj.toAddress = composeMailObj?.to
                          ?.map((e) => e.email)
                          ?.join(",");
                        obj.ccAddress = composeMailObj?.cc
                          ?.map((e) => e.email)
                          ?.join(",");
                        obj.bccAddress = composeMailObj?.bcc
                          ?.map((e) => e.email)
                          ?.join(",");
                        obj.mailFormat = "html";
                        handleSendEmail(obj);
                        if (msg) {
                          setComposeEmailPopUp(false);
                          setContentShowFlag("");
                          setComposeMailObj({
                            from: "",
                            to: [],
                            cc: [],
                            bcc: [],
                            subject: "",
                          });
                        }
                      }
                      swal("Email saved to Drafts!", {
                        icon: "success",
                      });
                    }else{
                      setAttachments([])
                      setShowCC(false);
                      setShowBCC(false);
                    }
                    if (
                      contentShowFlag == "replyemail" ||
                      contentShowFlag == "replyallemail"
                    ) {
                      setContentShowFlag("viewemail");
                      return;
                    }
                    setViewEmail(null);
                    setContentShowFlag("");
                  });
                }}
                type="button"
                class="btn btn-sm btn-outline-secondary"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </Col>
          </Row>
          <Row sm={12} className="Compose-Email-Header">
            <Col sm={11} className="left">
              <MultiSelectInpute
                lable={"From"}
                isMultiSelect={false}
                disabled={true}
                dropdownDisplayKey={"email"}
                values={allUsers}
                clear={clearFields}
                preInputeValue={
                  contentShowFlag == "compose"
                    ? userinfo?.email_id
                    : composeMailObj.from
                }
                onChange={(data) => {}}
                dropdownValueKey={"id"}
              />
              <MultiSelectInpute
                lable={"To"}
                isMultiSelect={true}
                dropdownDisplayKey={"email"}
                values={allUsers}
                preValues={composeMailObj?.to}
                onChange={(data) => {
                  setComposeMailObj({ ...composeMailObj, to: data });
                }}
                dropdownValueKey={"id"}
              />
              {((isReply && contentShowFlag == "replyallemail") || (isForward && contentShowFlag == "forward") ||
                contentShowFlag == "compose") && (
                <>
                  <MultiSelectInpute
                    showCC={showCC}
                    lable={"Cc"}
                    isMultiSelect={true}
                    dropdownDisplayKey={"email"}
                    preValues={composeMailObj.cc}
                    onChange={(data) => {
                      setComposeMailObj({ ...composeMailObj, cc: data });
                    }}
                    values={allUsers}
                    dropdownValueKey={"id"}
                  />
                  <MultiSelectInpute
                    showBCC={showBCC}
                    lable={"Bcc"}
                    isMultiSelect={true}
                    dropdownDisplayKey={"email"}
                    onChange={(data) => {
                      setComposeMailObj({ ...composeMailObj, bcc: data });
                    }}
                    values={allUsers}
                    dropdownValueKey={"id"}
                  />
                </>
              )}
              {!isReply && (
                <MultiSelectInpute
                  lable={"Subject"}
                  isMultiSelect={false}
                  disabled={contentShowFlag == "compose" || contentShowFlag == "forward" ? false : true}
                  preInputeValue={composeMailObj.subject}
                  onChange={(data) => {
                    setComposeMailObj({ ...composeMailObj, subject: data });
                  }}
                  // dropdownDisplayKey={"email"}
                  values={allUsers}
                  // dropdownValueKey={"id"}
                />
              )}
            </Col>
            <Col sm={1} className='mb-2 mt-1 right'>
              {getCCOptions()}
            </Col>
          </Row>
          <Row
            sm={12}
            style={{
              height: contentShowFlag == "replyallemail" ? "72%" : "88%",
            }}
            onDrop={e=>{
              let file=e.dataTransfer.files;
              if(file){
                handleFileAttachments(file);
              }
            }}
          >
            {isReply ? (
              <div class="message-content reply-content">
                <p
                  onClick={(e) => handleNotificationHyperlink(e)}
                  className="notifications-hyperlinks my-1"
                  dangerouslySetInnerHTML={{
                    __html: "Re : " + viewEmail?.subject,
                  }}
                ></p>
                <JoditTextEditor 
                id="email"
                signature={signature}
                isSchedule={isSchedule}
                Attachments={attachments}
                emailContent={emailContent}
                preValues={draftEmailContent}
                setIsSchedule= {setIsSchedule}
                composeMailObj={composeMailObj}
                isDraftAlready={isDraftAlready}
                setEmailContent={setEmailContent}
                contentShowFlag={contentShowFlag}
                handleUploadFiles={handleUploadFiles}
                composeMailToShow={composeMailToShow}
                openSchedulePopup={openSchedulePopup}
                openAttachmentPopUp={openAttachmentPopUp}
                setOpenSchedulePopup={setOpenSchedulePopup}
                setOpenAttachmentPopUp={setOpenAttachmentPopUp}
                />
                <div className="message_text_head_div">
                  <h6 class="message_text">
                    ----{" "}
                    {dayjs(new Date(parseInt(viewEmail?.receivedTime))).format(
                      "MMMM ,DD  YYYY HH:mm"
                    )}
                    &nbsp;&nbsp;{viewEmail?.sender}--- wrote
                  </h6>

                  <div
                    dangerouslySetInnerHTML={{ __html: viewEmail?.content }}
                  ></div>
                </div>
              </div>
            ) : (
              <JoditTextEditor 
              id="email"
              signature={signature}
              isSchedule={isSchedule}
              Attachments={attachments}
              emailContent={emailContent}
              preValues={draftEmailContent}
              setIsSchedule= {setIsSchedule}
              composeMailObj={composeMailObj}
              isDraftAlready={isDraftAlready}
              contentShowFlag={contentShowFlag}
              setEmailContent={setEmailContent}
              handleUploadFiles={handleUploadFiles}
              composeMailToShow={composeMailToShow}
              openSchedulePopup={openSchedulePopup}
              openAttachmentPopUp={openAttachmentPopUp}
              setOpenSchedulePopup={setOpenSchedulePopup}
              setOpenAttachmentPopUp={setOpenAttachmentPopUp}
              // onChange={(data) => {
              //   setEditorValue(data);
              // }}
              />
            )}
          </Row>
        </Col>
      </>
    );

    // return <><div class="message-body reply-message-body col-sm-12">
    //     <div className='custom_email_header'>
    //         <div class="row">
    //             <div class="col-md-11 ">
    //                 <div class="input-group">
    //                     <div class="input-group-prepend">
    //                         <div class="input-group-text" id="btnGroupAddon">From</div>
    //                     </div>
    //                     <input value={composeMailObj.from} type="text" class="form-control" placeholder="From" aria-label="Input group example" aria-describedby="btnGroupAddon" 
    //                         onChange={e=>setComposeMailObj({...composeMailObj,from:e.currentTarget.value})}
    //                     />
    //                 </div>
    //             </div>
    //             <div class="col-md-11 ">
    //                 <div class="input-group">
    //                     <div class="input-group-prepend">
    //                         <div class="input-group-text" id="btnGroupAddon">To</div>
    //                     </div>
    //                     <input type="text" value={composeMailObj.to} class="form-control" placeholder="To" aria-label="Input group example" aria-describedby="btnGroupAddon" 
    //                         onChange={e=>setComposeMailObj({...composeMailObj,to:e.currentTarget.value})}
    //                     />
    //                 </div>
    //             </div>
    //             <div class="col-md-11 ">
    //                 <div class="input-group">
    //                     <div class="input-group-prepend">
    //                         <div class="input-group-text" id="btnGroupAddon">Cc</div>
    //                     </div>
    //                     <input value={composeMailObj.cc} type="text" class="form-control" placeholder="Cc" aria-label="Input group example" aria-describedby="btnGroupAddon" 
    //                          onChange={e=>setComposeMailObj({...composeMailObj,cc:e.currentTarget.value})}
    //                     />
    //                 </div>
    //             </div>
    //             <div class="col-md-1  mt-4">
    //                 <button onClick={() => {
    //                        ;
    //                     console.log(composeMailObj);
    //                     setComposeEmailPopUp(false);
    //                     setComposeMailObj({
    //                         from:"",
    //                         to:"",
    //                         cc:"",
    //                         bcc:"",
    //                         subject:"",
    //                     });
    //                 }} type="button" class="btn btn-sm btn-outline-secondary cross-btn"><FontAwesomeIcon icon={faTimes} /></button>
    //             </div>
    //         </div>

    //     </div>



    //     <div class="message-content reply-content">
    //         {/* <p onClick={(e) => handleNotificationHyperlink(e)} className='notifications-hyperlinks my-1' dangerouslySetInnerHTML={{ __html: 'Re : ' + viewEmail?.subject }}></p> */}
    //         <Comment
    //             handleUploadFiles={handleUploadFiles}
    //             alltasks={[]}
    //             placeholder="Write message here..."
    //             onSave={(message) => {
    //                    
    //                 console.log(message)
    //                 console.log(composeMailObj)
    //                 // const obj = { ...composeObj };
    //                 // obj.fromAddress = reciverEmail;
    //                 // obj.toAddress = viewEmail?.fromAddress;
    //                 // obj.content = message;
    //                 // obj.mailFormat = "html";
    //                 // handleReplyEmail(obj)
    //                 // handleSendMessage(message)
    //                 // console.log(message);
    //             }}

    //             id='email'
    //             onClose={() => { setComposeEmailPopUp(false);
    //                 setComposeMailObj({
    //                     from:"",
    //                     to:"",
    //                     cc:"",
    //                     bcc:"",
    //                     subject:"",
    //                 });}}
    //         />
    //         {/* <p onClick={(e) => handleNotificationHyperlink(e)} dangerouslySetInnerHTML={{ __html: viewEmail?.subject }} class="sender-name notifications-hyperlinks my-1"></p> */}
    //         {/* <h6 class="message_text">---- {dayjs(new Date(parseInt(viewEmail?.receivedTime))).format('MMMM ,DD  YYYY HH:mm')}&nbsp;&nbsp;{viewEmail?.sender}--- wrote</h6>

    //         <div dangerouslySetInnerHTML={{ __html: viewEmail?.content }}></div> */}

    //         {/* <div class="attachments-sections">
    //         </div> */}

    //     </div>

    // </div> </>
  }
  const handleContextMenu = (e, one, module) => { 
    let arr = [...sidebarEmailContext];
    if(e.currentTarget.classList.contains("unread_mail")){
      arr = arr.filter(k=> k.label != "Mark as Unread");
    }else if(e.currentTarget.classList.contains("read_mail")){
      arr = arr.filter(k=> k.label != "Mark as read");
    }
    // if(module=="Attachment"){
    //   arr = arr.filter(k=> k?.module?.includes("Attachment"));
    // }
    e.preventDefault(); // Prevent default context menu
    if (module == "flag") {
      setIsOpenFlag({ state: true, object: one })
    } else if (module == "Other"){
      setIsOpenModuleContext({ state: true, object: one })
    }else{
      setIsOpen({ state: true, object: one });
    }
    setContextOptions(arr);
    setToolboxPosition({ x: e.clientX, y: e.clientY });
  };
  const getToolBox = () => {
    return (
      <ToolBox
        module={selectedItem ? selectedItem : ""}
        selectedMails={selectedMails}
        setOpen={(status) => {
          setIsOpen(status);
        }}
        isOpen={isOpen}
        position={toolboxposition}
        content={contextOptions}
      />
    )
  }
  const getToolBoxForModule=(module)=>{
    return (
      <ToolBox
        module={module}
        selectedMails={selectedMails}
        setOpen={(status) => {
          setIsOpenModuleContext(status);
        }}
        isOpen={isOpenModuleContext}
        position={toolboxposition}
        content={contextOptions}
      />
    )
  }
  const getToolBoxForFlag = () => {
    return (
      <ToolBox
        module={"flag"}
        selectedMails={selectedMails}
        setOpen={(status) => {
          setIsOpenFlag(status);
        }}
        isOpen={isOpenFlag}
        position={toolboxposition}
        content={contextOptions}
      />
    )
  }
  const getFlagColour = {
    "Important": "red",
    "Clear-Flag": "gray",
    "Follow-Up": "green",
    "Info": "blue"

  }

  const showTimeTitle = (one) =>{
    // const timestamp = 1717089035000;
    // const normalDate = dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
    // return normalDate 
  }
  const getFirstexistingTag=(data,falg)=>{
    if(falg){
      let firstTag="";
      Object.keys(data).forEach(e=>{
        if(data[e] && data[e]?.length>0 && !firstTag){
          firstTag=data[e][0];
        }
      })
      return firstTag
    }else{
      let count=0;
      return <>
     { Object.keys(data).map((e,index)=>{
        if(data[e] && data[e]?.length>0){
          return <>{data[e].map((k,i)=>{
            count++;
            if(count<4){
              return <span className="Tag-Card">{k}</span>
            }
          })}</>
        }
      })}
      </>
    }
  }
  return (
    <>
      {getToolBox()}
      {getToolBoxForModule("Attachment")}
      {AttachmentPopUp()}
      {SchedulePopUp()}
      {SubFolderPopUp()}
      {getToolBoxForFlag()}
      {getPDFPrevModal()}
      {/* {<EmailReminder 
            //isReminderPopupOpen={isReminderPopupOpen} 
            //setIsReminderPopupOpen={setIsReminderPopupOpen}
            setReminderData={setReminderData}
            reminderData={reminderData}
            />} */}
      {loader ? <Loader /> : <></>}
      <div className="Email-Body-Wrapper" style={clickedFromBody?{position: 'relative'}:{position: 'relative',top: '-4.5rem'}}>
        {/* {composeEmailPopup ? composeEmail() : <></>} */}
        <Sidebar
          setSelecetdItem={setSelecetdItem}
          selectedItem={selectedItem}
          clickedFromBody={clickedFromBody}
          onSelect={(data) => {
            setViewEmail(null)
            setContentShowFlag("");
            setSelectedFolder(data);
            resetSelections();
            setSelectedView(null)
          }}
          addFolderSubFolder={(e,folder)=>{
            setOpenSubFolderPopup({status:true,folderId:folder})
          }}
          handleOnClicksForContext={handleOnClicksForContext}
          setComposeEmailPopUp={() => setContentShowFlag("compose")}
          selectedFolder={selectedFolder}
          content={currentAccounts}
          extraItems={extraSidebarOptions}
        />
        <div className="Email-Body-Content-Wrapper">
          <Row className="Email-Main-Header" lg={12}>
            <Col sm={3} className="p-0">
              <div className="Content">
                <div className="User-Card">
                  <div class="First-Child">
                    <input
                      type="checkbox"
                      id="Select-All-Checkbox"
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          document
                            .querySelectorAll(
                              ".Email-Recepient-Sidebar .First-Child input"
                            )
                            ?.forEach((c) => {
                              c.checked = true;
                              c.parentElement.parentElement.classList.add("Email-Selected")
                            });
                            let arr=[];
                            emails.map((one, i) => {
                              let obj={};
                              obj["messageId"]=one.messageId;
                              obj["folderId"]=one.folderId;
                              obj["accountId"]=one.accountId;
                              arr.push(obj)
                            })
                            setSelectedMails(arr);
                        } else {
                          document
                            .querySelectorAll(
                              ".Email-Recepient-Sidebar .First-Child input"
                            )
                            ?.forEach((c) => {
                              c.checked = false;
                              c.parentElement.parentElement.classList.remove("Email-Selected")
                            });
                            setSelectedMails([])
                        }
                        isHeadersVisible()
                      }}
                    />
                  </div>
                  <div className="Second-Child">
                    <input
                      value={searchText}
                      onChange={(e) => {
                        let list = [...cacheEmails];
                        if (e.currentTarget.value) {
                          list = list.filter((one) =>
                            one.subject
                              .toLowerCase()
                              .includes(e.currentTarget.value.toLowerCase())
                          );
                        }
                        setAllEmails(list);
                        setSearchText(e.currentTarget.value);
                      }}
                      class="form-control w-100 email-global-search"
                      type="search"
                      placeholder="Search mail"
                      id="Mail-rearch"
                    />
                    <FontAwesomeIcon
                      style={{ display: searchText ? "block" : "none" }}
                      className="email_cancel_icon"
                      onClick={() => {
                        let list = [...cacheEmails];
                        setSearchText("");
                        setAllEmails(list);
                      }}
                      icon={faTimes}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={9}>
             {showHeaderOptions && <Row sm={12}>
              <div className='header-options'>
              {selectedFolder?.folderName=="Trash"?<div
              onClick={e=>{
                handleHardDeleteSelectedMails(selectedMails)                    
              }}
              ><FontAwesomeIcon className='mr-2' icon={faTrashAlt}  />Delete</div>
                  :<div
                  onClick={e=>{
                    // handleDeleteMail()
                    handleMultipleDeleteMail(selectedMails);
                  }}
                  ><FontAwesomeIcon className='mr-2' icon={faTrashAlt}  />Move To Trash</div>}
                  {
                    selectedFolder?.folderName=="Trash"?<div onClick={()=>{restoreMultipleEmails(selectedMails)}}><FontAwesomeIcon className='mr-2' icon={faArchive} />Restore</div>:
                    selectedFolder?.folderName=="Spam"?<div onClick={()=>{archiveAllMail(selectedMails,"markNotSpam")}}><FontAwesomeIcon className='mr-2' icon={faArchive} />Mark As Not Spam</div>:
                    selectedView==null?<div onClick={()=>{archiveAllMail(selectedMails,"archiveMails")}}><FontAwesomeIcon className='mr-2' icon={faArchive} />Archive</div>
                  :selectedView=="Archived"?<div onClick={()=>{archiveAllMail(selectedMails,"unArchiveMails")}}><FontAwesomeIcon className='mr-2' icon={faArchive} />Unarchive</div>
                  :selectedView=="Unread"?<div onClick={()=>{archiveAllMail(selectedMails,"markAsRead")}}><FontAwesomeIcon className='mr-2' icon={faArchive} />Mark As Read</div>
                  :<div onClick={()=>{handleClearMultilpeFlags(selectedMails)}}><FontAwesomeIcon className='mr-2' icon={faArchive} />Clear Flag</div>
                }
              </div>
              </Row>}
            </Col>
          </Row>
          {/* <Row className="Email-Main-Header" lg={12}>
                <div>
                <Tabs
                  className={"emailTabs text-colr"}
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => {
                    setKey(k);
                  }}
                >
                  {allTabsName.map((e) => {
                    return <Tab eventKey={e.id} title={e.name}></Tab>;
                  })}
                </Tabs>
                <span className='sort-by'>
                    Sort By
                </span>
                </div>
            </Row> */}
          <Row className="Email-Recepien-Row" lg={12}>
            <Col
              className="Email-Recepient-Sidebar" 
              style={clickedFromBody?{height:'77%'}:{height:'100%'}}
              lg={contentShowFlag ? 3 : 12}
            >
              {/* <div className='Search-Wrapper'>
                            <div class="border-bottom custom_container ">
                                <div class="form-group">
                                    <div className='position-relative sidebar-header'>
                                        <h4>{selectedFolder?.folderName}</h4>
                                    </div>
                                </div>
                            </div>
                        </div> */}
              {/* <Row sm={12} className='Search-Wrapper'>
                            <Col sm={1}></Col>
                            <Col sm={11} class="border-bottom custom_container ">
                                <div class="form-group">
                                    <div className='position-relative'>
                                        <input value={searchText} onChange={(e) => {
                                            let list = [...cacheEmails];
                                            if (e.currentTarget.value) {
                                                list = list.filter(one => one.subject.toLowerCase().includes(e.currentTarget.value.toLowerCase()));
                                            }
                                            setAllEmails(list);
                                            setSearchText(e.currentTarget.value);

                                        }} class="form-control w-100" type="search" placeholder="Search mail" id="Mail-rearch" />
                                        {searchText ? <FontAwesomeIcon className='email_cancel_icon' onClick={() => {
                                            let list = [...cacheEmails];
                                            setSearchText('')
                                            setAllEmails(list);
                                        }} icon={faTimes} /> : ""}
                                    </div>

                                </div>
                            </Col>
                        </Row> */}
              {emails?.length == 0 && (
                <div className="No-Content-Class">No mails found!</div>
              )}
              <div className="Content">
                <div
                  className="Email-sort"
                >
                  {
                    (selectedFolder == null || selectedFolder?.folderName == "Sent") ? <div className="emailTabs"></div> : 
                      <Tabs
                        className={"emailTabs text-colr"}
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => {
                          setKey(k);
                        }}
                      >
                        {allTabsName.map((e) => {
                          return <Tab eventKey={e.id} title={e.name}></Tab>;
                        })}
                      </Tabs>
                }

                  <div style={{
                    display: "flex",
                    "align-items": "center",
                    "cursor": "pointer",
                    "flex-direction": "row",
                    "gap": "6px",
                    "padding-right": "10px"
                  }}>
                    <GlobalAntDPopOver
                      items={sortPopOverItems}
                      togglePopOver={() => {
                        setShowSortPopOver(!showSortPopOver)
                      }}
                      showPopOver={showSortPopOver}
                    >
                      <div style={{
                        display: "flex",
                        "align-items": "center",
                        "cursor": "pointer",
                        "flex-direction": "row",
                        "gap": "6px",
                        "padding-right": "10px"
                      }}
                      >

                        <span style={{ marginRight: "0px" }} className='sort-by'>Sort By</span>
                        <span className='sort-by-icon'>
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faAngleDown}
                          // onClick={(e) => {
                          //     setDownclicked({ state: true, time: e.timeStamp });
                          //     setUpclicked({ state: false, time: e.timeStamp });
                          // }}
                          ></FontAwesomeIcon>
                        </span>
                      </div>
                    </GlobalAntDPopOver>
                    <span className='sort-by-icon'>
                      {sortEmailObj && sortEmailObj.order == "asc" &&
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          className='Prevent-Open'
                          icon={faArrowUp}
                          onClick={(e) => {
                            setSortEmailObj({ ...sortEmailObj, order: "desc" })
                          }}
                        ></FontAwesomeIcon>
                      }
                      {sortEmailObj && sortEmailObj.order == "desc" &&
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faArrowDown}
                          className='Prevent-Open'
                          onClick={(e) => {
                            setSortEmailObj({ ...sortEmailObj, order: "asc" })
                          }}
                        ></FontAwesomeIcon>
                      }

                    </span>
                  </div>
                </div>
                {(cacheUnreadData || !cacheUnreadData) && sortEmailObj && getRanges(sortEmailObj)?.map((range, i) => {
                  let noSubSection = false;
                  if (i == 0 && range == "No-Sub-Cat") {
                    noSubSection = true;
                  }
                  return (<>
                    {!noSubSection && (
                      <div id={range.label?.replaceAll(" ", '')} className={`${range.label?.replaceAll(" ", '')} Date-Band-Email`} onClick={(e) => { }}>
                        <FontAwesomeIcon className='Display-Visible arrowicon' onClick={onToggleCollapse} icon={faAngleDown} />
                        <FontAwesomeIcon className='Display-Hidden arrowicon' onClick={onToggleCollapse} icon={faAngleUp} />
                        {range.label}
                      </div>
                    )}
                    {emails.map((one, i) => {
                      let isEmailHasReminder=state?.cacheReminderData?.filter(e=>e.messageId==one.messageId && e.status!="true");
                      // if(isEmailHasReminder && isEmailHasReminder?.length>0){
                      //   
                      // }
                      let isFlagPresent = TagList?.find(t => t.EmailRowId == `${one.folderId}_${one.messageId}` && t.Tag == "Flag")
                      let render = false;
                      let date = dayjs(
                        new Date(parseInt(one.receivedTime))
                      ).format("MMMM DD, YYYY hh:mm A");
                      let mailDate = dayjs(
                        new Date(parseInt(one.receivedTime))
                      ).format("MM/DD/YYYY");
                      // if(sortEmailObj.type=="Date"){
                      //   if (i == 0) {
                      //     tempVeriable = mailDate;
                      //     render = true;
                      //   } else if (mailDate == tempVeriable) {
                      //     render = false;
                      //   } else {
                      //     tempVeriable = mailDate;
                      //     render = true;
                      //   }
                      // }
                      const emailDate = moment(mailDate)
                      if (sortEmailObj.type == "Date" && ((range.label == "Old" && emailDate?.isSameOrBefore(range.range[0])) || (emailDate?.isSameOrBefore(range.range[0]) && emailDate?.isAfter(range.range[1])))) {
                        render = true;
                      } else if (sortEmailObj.type == "Size" && ((range.label == "Greater than 25MB" && Number(one.size) / 1024 >= range.range[0]) || (range.label != "Greater than 25MB" && Number(one.size) / 1024 >= range.range[0] && Number(one.size) / 1024 < range.range[1]))) {
                        render = true;
                      }
                      if (render || noSubSection) {
                        return (
                          <>

                            <div
                              className={`${range.label?.replaceAll(" ", '')} Display-Visible User-Card ${viewEmail && viewEmail?.messageId == one.messageId
                                  ? "selected-email-card"
                                  : ""
                                }`}
                                draggable
                                onDragStart={e=>{
                                  e.dataTransfer.setData("messageId",one.messageId);
                                }}
                            >
                              <div class="First-Child">
                              <input id={one.messageId} className='Single-Check-Box' type="checkbox" 
                                  onChange={(e)=>{handleCheckBoxClick(e, one)}}
                                />
                                <FontAwesomeIcon
                                  onClick={e => {
                                     
                                    handleTags(e, null, one, "Flag", true)
                                  }}
                                  style={
                                    isFlagPresent && isFlagPresent?.TagValue && isFlagPresent?.TagValue != "Clear-Flag"
                                      ? {
                                        display: "block",
                                        color: getFlagColour[isFlagPresent.TagValue],
                                      }
                                      : {}
                                  }
                                  className="Flag"
                                  icon={faFlag}
                                  onContextMenu={(e) => {
                                    handleContextMenu(e, one, "flag");
                                  }}
                                />
                              </div>
                              <div
                                className={isUnreadEmail(one) ? "unread_mail Second-Child" : "read_mail Second-Child"}
                                onContextMenu={(e) => {
                                  handleContextMenu(e, one);
                                }}
                                onClick={async(e) => { 
                                  setPossibleAssociationData({
                                    isAlready:false,
                                    data:null
                                  });
                                  let isUnread=e.currentTarget.classList.contains("unread_mail");
                                   await EmailService.GetEmailContent(
                                    one.accountId,
                                    one.folderId,
                                    one.messageId
                                  ).then(async(result) => {
                                     
                                      await EmailService.GetAttachmentsInfo( one.accountId,one.folderId,one.messageId).then(r=>{
                  
                                        setEmailAttachmentInfo(r?.data?.attachments);
                                        setAttachments(r?.data?.attachments);
                                      })
                                      await archiveMail(one, "markAsRead");
                                      one.content = result.content;
                                      setViewEmail(one);
                                     
                                      if(isUnread || true){
                                        AssingUnreadEmailAutomatically(one);
                                      }
                                      if(selectedFolder?.folderName == "Drafts"){ 
                                        // console.log("one", one);
                                        setContentShowFlag("compose");
                                        getViewEmailData(one, "compose")
                                        setIsDraftAlready(true);
                                        // setComposeMailObj()
                                      }else{
                                        setContentShowFlag("viewemail");
                                        getAddressToShow(one)
                                      }
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                              >
                                <div className="Header Child-Rows">
                                { isUnreadEmail(one) ? 
                                  <FontAwesomeIcon
                                    color="#0069d9"
                                    icon={faEnvelope}
                                  />
                                  :
                                  <FontAwesomeIcon
                                    color="#8ac1fc"
                                    icon={faEnvelopeOpen}
                                  />
                                }
                                  <h6 style={{display:"flex",alignItems:"center"}} class={isUnreadEmail(one) ? "unread_message_text" : "message_text"} title={showTimeTitle(one)}>{one.sender}
                                  {selectedFolder?.folderName == "Outbox" && <FontAwesomeIcon
                                    color="#8ac1fc"
                                    icon={faHourglass}
                                    style={{marginLeft: "1rem"}}
                                  />}
                                  {isEmailHasReminder && isEmailHasReminder?.length>0 ? 
                                      // <div className='All-Reminder-Modal'>
                                        // <AntdPopover
                                        //   open={true}
                                        //   overlayClassName={`Snooze-Popover Do-Not-Close`}
                                        //   style={{ zIndex: 9999, "width": "max-content" }}
                                        //   trigger="hover"
                                        //   placement="topLeft"
                                        //   content={() => getReminderSection(isEmailHasReminder)}
                                        //   arrow={true}
                                        // >
                                        //   <div
                                        //     style={{
                                        //       "display": "flex",
                                        //       "height": "1.1rem",
                                        //       "margin-left": "1rem"
                                        //     }}
                                        //   >
                                        //     <img src={ReminderSvg} />
                                        //   </div>
                                        // </AntdPopover> 
                                        <ReminderShortCutPopOver 
                                        onOpenReminder={(data)=>{
                                          if(data){
                                            dispatch(setReminderData(data));
                                            dispatch(setIsReminderPopupOpen({status:true,email:one}))
                                          }
                                        }} reminders={isEmailHasReminder} 
                                        />
                                      // </div>
                                     :<></>}
                                  </h6>
                                  {/* {isEmailHasReminder && isEmailHasReminder?.length>0 ? <div
                                    style={{
                                      "display": "flex",
                                      "height": "1.1rem",
                                      "margin-left": "1rem"
                                    }}  
                                  >
                                    <img src={ReminderSvg} />
                                  </div>:<></>}
                                  </h6> */}
                                  <FontAwesomeIcon
                                    className="Delete-Icon"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteMail(one);
                                    }}
                                    icon={faTrashAlt}
                                  />
                                </div>
                                <div className="Subject Child-Rows">
                                  <p
                                    onClick={(e) => handleNotificationHyperlink(e)}
                                    dangerouslySetInnerHTML={{
                                      __html: one.subject?.trim()?contentShowFlag?one.subject?.substring(0, 30)+"...":one.subject?.substring(0, 150)+"...":"(No Subject!)",
                                    }}
                                    className={isUnreadEmail(one) ? "unread_message_text" :"sender-name notifications-hyperlinks my-1"}
                                  ></p>
                                  
                                </div>
                                <div className="Subject Child-Rows">
                                  <p
                                      onClick={(e) => handleNotificationHyperlink(e)}
                                      dangerouslySetInnerHTML={{
                                        __html: one.summary?contentShowFlag?one.summary?.substring(0, 30)+"...":one.summary?.substring(0, 150)+"...":"",
                                      }}
                                      className={isUnreadEmail(one) ? "unread_message_text" :"sender-name notifications-hyperlinks my-1"}
                                    ></p>
                                </div>
                                <div
                                  className={`Date Child-Rows ${!contentShowFlag ? "Closed-Content-Css" : ""
                                    }`}
                                >
                                  {viewEmail ? (
                                    <p>{date}</p>
                                  ) : (
                                    <p>{date}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                  </>)
                })}
              </div>
            </Col>
            <Col
              className="Email-Recepient-Content"
              lg={contentShowFlag ? 9 : 0}
            >
              {contentShowFlag == "compose" ? (
                composeEmailComponent()
              ) : viewEmail && contentShowFlag == "viewemail" ? (
                <div class="mail-view bg-white">
                  {
                    viewEmail ? (
                      <div class="message-body">
                        <div className="custom_email_header">
                          {/* <div class="row"> */}
                            {/* <div class="col-md-11 mb-2 mt-1 reply-header-left">
                              <div class="btn-toolbar">
                                <div class="btn-group">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      getViewEmailData(viewEmail, "replyemail");

                                    }}
                                    class="btn btn-sm btn-outline-secondary"
                                  >
                                    <i class="mdi mdi-reply text-primary mr-1"></i>{" "}
                                    Reply
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      getViewEmailData(viewEmail, "replyallemail");
                                     
                                    }}
                                    class="btn btn-sm btn-outline-secondary"
                                  >
                                    <i class="mdi mdi-reply-all text-primary mr-1"></i>
                                    Reply All
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-outline-secondary"
                                    onClick={() => {
                                      getViewEmailData(viewEmail, "forward");
                                    }}
                                  >
                                    <i class="mdi mdi-share text-primary mr-1"></i>
                                    Forward
                                  </button>
                                </div>
                                <div class="btn-group">
                                 
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if(selectedFolder?.folderName !="Trash"){
                                        handleDeleteMail(viewEmail);
                                      }else{
                                        handleHardDeleteMail(viewEmail)
                                      }
                                    }}
                                    class="btn btn-sm btn-outline-secondary"
                                  >
                                    <i class="mdi mdi-delete text-primary mr-1"></i>
                                    {selectedFolder?.folderName !="Trash" ? "Trash" : "Delete"}
                                  </button>
                                </div>
                              </div>
                            </div> */}
                            {/* <div class="col-md-1 mb-2 mt-1 reply-header-right"> */}
                                <Row sm={12} style={{
                                  "margin": "3px 0px !important",
                                  "display": "flex",
                                  "align-items": "center"
                                }}>
                                <Col sm={6}>
                                  <p
                                    style={{
                                      "font-size": "1.4rem",
                                      "font-weight": 500,
                                      "color": "#5b6c97",
                                    }}
                                    onClick={(e) => handleNotificationHyperlink(e)}
                                    className="notifications-hyperlinks my-1"
                                    dangerouslySetInnerHTML={{
                                      __html: viewEmail?.subject,
                                    }}
                                  ></p>
                                </Col>
                                  <Col className='RemovePaddingAndJustifyEnd' sm={5}>
                                    <div class="row">   
                                        <div class="btn-toolbar"  style={{position:"relative"}}>
                                        {clickedFromBody != true && possibleAssociationData?.data != null && Object.keys(possibleAssociationData?.data)?.length > 0 && <div class="btn-group">
                                            
                                            <div className='Shortcut-Tags-Section' onMouseOver={e=>{
                                                setIsHoverOnTag(true)
                                              }} onMouseLeave={e=>{
                                                setIsHoverOnTag(false)
                                            }}>
                                              {!isHoverOnTag && <span className="Tag-Card">{getFirstexistingTag(possibleAssociationData?.data,true)}</span>}
                                              {isHoverOnTag?getFirstexistingTag(possibleAssociationData?.data,false):<span className="Tag-Card"></span>}
                                              
                                            </div>
                                            <button
                                              type="button"
                                              class="btn btn-sm btn-outline-secondary"
                                            >
                                              
                                                <span className='Association-Question-Icon'
                                                  title='Check possible associations.'
                                                 
                                                  onClick={e => {
                                                    setOpenAssociatePopUp({
                                                      status: true,
                                                      possibleAssociationFlag: true,
                                                      email: viewEmail,
                                                      context: false
                                                    });
                                                  }}
                                                >
                                                 
                                                  {/* <img src={possibleAssociationData.isAlready?AssociationIcon:ConformAssociationIcon} alt="" /> */}
                                                  <FontAwesomeIcon icon={possibleAssociationData.isAlready ? faTags : faTags} />
                                                </span>
                                            </button>
                                          </div>}
                                          {clickedFromBody!=true && <><div class="btn-group">
                                            <button
                                              type="button"
                                              onClick={() => {
                                                getViewEmailData(viewEmail, "replyemail");

                                                // setContentShowFlag("replyemail");
                                              }}
                                              class="btn btn-sm btn-outline-secondary"
                                            >
                                              <i class="mdi mdi-reply text-primary mr-1"></i>{" "}
                                              Reply
                                            </button>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                getViewEmailData(viewEmail, "replyallemail");
                                                // setReplyEmail(true);
                                                // setContentShowFlag("replyallemail");
                                              }}
                                              class="btn btn-sm btn-outline-secondary"
                                            >
                                              <i class="mdi mdi-reply-all text-primary mr-1"></i>
                                              Reply All
                                            </button>
                                            <button
                                              type="button"
                                              class="btn btn-sm btn-outline-secondary"
                                              onClick={() => {
                                                getViewEmailData(viewEmail, "forward");
                                              }}
                                            >
                                              <i class="mdi mdi-share text-primary mr-1"></i>
                                              Forward
                                            </button>
                                          </div>
                                          <div class="btn-group">
                                            
                                            <button
                                              type="button"
                                              onClick={() => {
                                                if (selectedFolder?.folderName != "Trash") {
                                                  handleDeleteMail(viewEmail);
                                                } else {
                                                  handleHardDeleteMail(viewEmail)
                                                }
                                              }}
                                              class="btn btn-sm btn-outline-secondary"
                                            >
                                              <i class="mdi mdi-delete text-primary mr-1"></i>
                                              {selectedFolder?.folderName != "Trash" ? "Trash" : "Delete"}
                                            </button>
                                          </div></>}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col className='RemovePaddingAndJustifyEnd' sm={1}>
                                    <button
                                      onClick={() => {
                                        setViewEmail(null);
                                        setContentShowFlag("");
                                      }}
                                      type="button"
                                      class="btn btn-sm btn-outline-secondary"
                                    >
                                      <FontAwesomeIcon onClick={()=>setAttachments([])} icon={faTimes} />
                                    </button>
                                  </Col>
                                </Row>

                            {/* </div>
                          </div> */}
                          <div class="sender-details pt-0">
                            <span class="no-image-icon img-sm rounded-circle mr-3">
                              {viewEmail && viewEmail?.sender
                                ? viewEmail?.sender.charAt(0).toUpperCase()
                                : ""}
                            </span>
                            <div class="details">
                            
                                <p class="sender-email mb-0" style={{position:"relative"}}>
                                  <a href="#" onClick={(e) => e.preventDefault()} style={{ fontSize: "1.2rem" }}>{composeMailToShow.from}</a>
                                  
                                </p>
                              {(composeMailToShow.to).length > 0 &&
                              <p class="sender-email mb-0">To: &nbsp;&nbsp;
                                <a href="#" onClick={(e) => e.preventDefault()}>{(composeMailToShow.to).map((item)=>{
                                 return item.email + "; "
                                })}</a>
                                &nbsp;
                                {/* <i class="mdi mdi-account-multiple-plus"></i> */}
                              </p>
                              }
                              {(composeMailToShow.cc).length > 0 && 
                              <p class="sender-email mb-0">Cc: &nbsp;&nbsp;
                                <a href="#" onClick={(e) => e.preventDefault()}>{(composeMailToShow.cc).map((item)=>{
                                 return item.email + "; "
                                })}</a>
                                &nbsp;
                                {/* <i class="mdi mdi-account-multiple-plus"></i> */}
                              </p>
                              }
                              <p class="msg-subject mb-0" style={{
                                "font-size": "small",
                                "font-style": "italic",
                              }}>
                                {dayjs(
                                  new Date(parseInt(viewEmail?.receivedTime))
                                ).format("MMMM DD, YYYY hh:mm A")}
                              </p>
                               
                            </div>
                          </div>
                            {emailAttachmentInfo && <Row sm={12}> {emailAttachmentInfo?.map(att => {
                              return (
                                <Col sm={2} title={att?.attachmentName} className='Attachment-Card'
                                  onContextMenu={e=>{
                                    handleContextMenu(e, att, "Other");
                                  }}
                                >
                                  <span>{getIconByType(att?.attachmentName)}</span>
                                  <span className='Name'>{att?.attachmentName}</span>
                                </Col>
                              )
                            })}</Row>}
                        </div>

                        <div
                          class="message-content"
                          style={{ height: "77%" }}
                        >
                         
                          <div
                            dangerouslySetInnerHTML={{
                              __html: viewEmail?.content,
                            }}
                          ></div>
                          {/* <p>Hi Emily,</p>
                                <
                                <p>This week has been a great week and the team is right on schedule with the set deadline. The team has made great progress and achievements this week. At the current rate we will be able to deliver the product right on time and meet the quality that is expected of us. Attached are the seminar report held this week by our team and the final product design that needs your approval at the earliest.</p>
                                <p>For the coming week the highest priority is given to the development for <a href="http://www.urbanui.com/" target="_blank">http://www.urbanui.com/</a> once the design is approved and necessary improvements are made.</p>
                                <p><br /><br />Regards,<br />Sarah Graves</p> */}
                          <div class="attachments-sections">
                            {/* <ul>
                                        {viewEmail?.attachments.map(one => {
                                            return <li>
                                                <div class="thumb"><img src={convertImage(one)} /></div>
                                                <div class="details">
                                                    <p class="file-name">{one.filename}</p>
                                                    <div class="buttons">
                                                        <p class="file-size">{(one.size / 1024).toFixed(1)}Kb</p>
                                                        <a href="#" class="view">View</a>
                                                        <a href="#" class="download">Download</a>
                                                    </div>
                                                </div>
                                            </li>
                                        })}

                                    </ul> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                    // <div class="message-body">
                    //     <div class="sender-details">
                    //         <img class="img-sm rounded-circle mr-3" src="http://www.urbanui.com/dashflat/template/images/faces/face11.jpg" alt="" />
                    //         <div class="details">
                    //             <p class="msg-subject">
                    //                 Weekly Update - Week 19 (May 8, 2017 - May 14, 2017)
                    //             </p>
                    //             <p class="sender-email">
                    //                 Sarah Graves
                    //                 <a href="#">itsmesarah268@gmail.com</a>
                    //                 &nbsp;<i class="mdi mdi-account-multiple-plus"></i>
                    //             </p>
                    //         </div>
                    //     </div>
                    //     <div class="message-content">
                    //         <p>Hi Emily,</p>
                    //         <p>This week has been a great week and the team is right on schedule with the set deadline. The team has made great progress and achievements this week. At the current rate we will be able to deliver the product right on time and meet the quality that is expected of us. Attached are the seminar report held this week by our team and the final product design that needs your approval at the earliest.</p>
                    //         <p>For the coming week the highest priority is given to the development for <a href="http://www.urbanui.com/" target="_blank">http://www.urbanui.com/</a> once the design is approved and necessary improvements are made.</p>
                    //         <p><br /><br />Regards,<br />Sarah Graves</p>
                    //         <div class="attachments-sections">
                    //             <ul>
                    //                 <li>
                    //                     <div class="thumb"><i class="mdi mdi-file-pdf"></i></div>
                    //                     <div class="details">
                    //                         <p class="file-name">Seminar Reports.pdf</p>
                    //                         <div class="buttons">
                    //                             <p class="file-size">678Kb</p>
                    //                             <a href="#" class="view">View</a>
                    //                             <a href="#" class="download">Download</a>
                    //                         </div>
                    //                     </div>
                    //                 </li>
                    //                 <li>
                    //                     <div class="thumb"><i class="mdi mdi-file-image"></i></div>
                    //                     <div class="details">
                    //                         <p class="file-name">Product Design.jpg</p>
                    //                         <div class="buttons">
                    //                             <p class="file-size">1.96Mb</p>
                    //                             <a href="#" class="view">View</a>
                    //                             <a href="#" class="download">Download</a>
                    //                         </div>
                    //                     </div>
                    //                 </li>
                    //             </ul>
                    //         </div>

                    //     </div>
                    // </div>
                  }
                </div>
              ) : contentShowFlag == "replyemail" ||
                contentShowFlag == "replyallemail" ? (
                composeEmailComponent(true, false)
              ) : contentShowFlag == "forward" ? 
                  composeEmailComponent(false, true)
              : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </div>
      {
        <AssociateMail
        dataForSearch={dataForSearch}
        isOpen={openAssociatePopUp}
        title={possibleAssociationData?.isAlready?"Associate Email":"Possible Associations With Email"}
        allAssociatedEmails={allAssociatedEmails}
        setAllAssociatedEmails={setAllAssociatedEmails}
        onClose={e=>{
          setOpenAssociatePopUp({
            status:false,
            email:null,
          });
        }}
        tabs={tabsForEmailAssociation}
        setActiveTab={setActiveTabinPopup}
        activeTab={activeTabinPopup}
        data={dataForPopup}
        invertColor={invertColor}
        setSelectedItoms={setSelectedItoms}
        selectedItems={possibleAssociationData.data}
        setPossibleAssociationData={setPossibleAssociationData}
       />} 
    </>
  );
}


export default Email;