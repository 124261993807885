import { Button, Input } from 'antd'
import JoditEditor from 'jodit-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col,Row } from 'reactstrap'
import './common.css';
import { SendOutlined } from '@ant-design/icons';
import ActivityStreamTab from '../ViewPipelinesTabs/ActivityStreamTab';

export const ActivitySection = ({taskId,module}) => {
	console.log("task id",taskId);
	const [id,setId]=useState();
	useEffect(e=>{
		setId(taskId);
	},[taskId])
	const [content, setContent] = useState('');
	const editorRef = useRef(null);
	const config = {
		readonly: false, // Set to true to make the editor read-only
		toolbar: true, // Toolbar will be visible on focus only
		height: 400, // Set the height of the editor
		buttons: [
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'|',
			'font',
			'fontsize',
		],
		removeButtons: [
			'brush',
			'copyformat',
			'video',
			'superscript',
			'subscript',
			'file',
			'indent',
			'outdent',
			'formatPainter',
			'link',
			'unlink',
			'source',
			'code',
			'symbol',
			'selectall',
			'cutformat',
			'copy',
			'cut',
			'paste',
			'copyhtml',
			'cuthtml',
			'insertImage',
			'filebrowser',
			'about',
			"breakline",
			'eraser',
			'align',
			'ul',
			'ol',
			'hr',
			'paragraph',
			'table',
			'speechRecognize',
			'spellcheck',
			'undo',
			'redo',
			'print'
		],
		uploader: {
			insertImageAsBase64URI: true // Insert images as base64 URIs
		},
	};

	return (
		<Col sm={12}>
			<Row style={{height:"90%"}}>
				<ActivityStreamTab pipelineId={id} module={module} />
			</Row>
			<Row className='Activity-Comment-Editor' sm={12}>
				{/* <Row sm={12} className='Comment-Section'>
						<Input style={{width:"93%"}} placeholder='Write a comment.' />
						<Button type="primary" shape="circle" icon={<SendOutlined />}>
						</Button>
					
				</Row> */}
			</Row>
		</Col>
	)
}
