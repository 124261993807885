import React, { useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core'; 
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; 
//import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import './pdf.css'
import { Image } from 'antd';
const PdfViewer = (props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  if(!props.location){
    return (
      <div className='pdf-container'>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer 
                plugins={[defaultLayoutPluginInstance]} 
                fileUrl={props.fileData?props.fileData:""}
              />
        </Worker>
      </div>
    );
  }else if(props.location && props.location == "chats" ){
    return ( <div className='pdf-container'>
        {props.type=="pdf" ?<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer 
                plugins={[defaultLayoutPluginInstance]} 
                fileUrl={props.fileData?props.fileData:""}
              />
        </Worker>:<>
          <Image
            preview={false}
            src={props.fileData?props.fileData:""}
            alt="Your Image"
          />
        </>}
    </div>)
  }
};
 
export default PdfViewer;