import { CCollapse } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react'
import './collapse.css'
import '../chat.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faEllipsisV, faFileAlt, faReply, faThumbtack, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { OtsideClickClosingWrapper } from '../../EmojiReactionPicker/OtsideClickClosingWrapper';
import AppContext from '../../../Context/AppContext';

export const MentionsCollapse = ({ showMentions, setshowMentions, chatMessageInpute, setchatMessageInpute, allUsers, charToCompare, attachments , setAttachments
  , setchatMessageToSubmit, chatMessageToSubmit
}) => {
  const [visible, setVisible] = useState(false);
  const [sortedUsers, setSortedUsers] = useState([])
  const [allDealsUsers, setallDealsUsers] = useState([])
  const [allDealsSortedUsers, setallDealsSortedUsers] = useState([])
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const allDealsAndUsers = state.AllDeals || [];
  const allAccounts = state.AllAccounts || []
  useEffect(e => {
    console.log("Users", allUsers)
    if (allUsers) {
      setSortedUsers(allUsers);
    }
  }, [allUsers])
  useEffect(e => {
    if (showMentions) {
      setVisible(true)
    } else {
      setVisible(false)
    }
    console.log("Attachment Object", attachments)
  }, [showMentions])
  useEffect(e => {
    if (allDealsAndUsers) {
      let arr = [];
      allDealsAndUsers?.forEach(e => {
        let account = allAccounts?.find(k => k.Account_Name == e.Account_Name?.name)
        let obj = {
          id: e.id,
          Deal_Name: e.Deal_Name,
          Account_Name: e.Account_Name?.name,
          AccountColor: account ? account.Color : "#5b6c97",
          DealColor: "#5b6c97",
        }
        arr.push(obj);
      })
      setallDealsUsers(arr);
      setallDealsSortedUsers(arr)
    }
  }, [])
  useEffect(e => {
    console.log("Modified Accounts And Deals", allDealsUsers)
  }, [allDealsUsers])
  useEffect(e => {
    if (chatMessageInpute && charToCompare == "@") {
      let typedName = chatMessageInpute.split(charToCompare)
      typedName = typedName[typedName.length - 1]?.split(' ');
      if (typedName) {
        let filteredArray = allUsers.filter(e => e.full_name?.includes(typedName[0]));
        setSortedUsers(filteredArray);
      } else {
        setSortedUsers(allUsers);
      }
    } else if (chatMessageInpute && charToCompare == "#") {
      let typedName = chatMessageInpute.split(charToCompare)
      typedName = typedName[typedName.length - 1]?.split(' ');
      if (typedName) {
        let filteredArray = allDealsUsers.filter(e => e.Account_Name?.includes(typedName[0]) || e.Deal_Name?.includes(typedName[0]));
        setallDealsSortedUsers(filteredArray);
      } else {
        setallDealsSortedUsers(allDealsUsers);
      }
    } else {
      setSortedUsers(allUsers);
      setallDealsSortedUsers(allDealsUsers)
    }
  }, [chatMessageInpute])
  const selectUser = (e) => {
    if (e) {
      let newInpute = "";
      let name = e.currentTarget?.attributes?.userName?.value;
      if (name) {
        let arr = chatMessageInpute.split(charToCompare);
        arr.forEach((element, i) => {
          if (i == arr.length - 1) {
            newInpute = newInpute + (i == 0 ? name : charToCompare + name)
          } else {
            newInpute = newInpute + (i == 0 ? element : charToCompare + element)
          }
        });
        console.log(newInpute)
        setchatMessageInpute(newInpute)
      }
    }
  }
  if (charToCompare == '@') {
    return (
      <OtsideClickClosingWrapper className={`Mentions-Collapse ${visible && "Bordered-Box"}`} close={(e) => {
        if (visible) {
          setVisible(false)
        }
      }}>
        <CCollapse
          id="collapseWidthExample" verticle visible={visible}>
          <div className='User-Mention-Header'>Mention Users</div>
          {sortedUsers && sortedUsers.map(user => {
            return (
              <div userName={user.full_name} className='user-card' onClick={selectUser}>
                <span>{user.full_name}</span>
              </div>
            )
          })
          }
        </CCollapse>

      </OtsideClickClosingWrapper>
    )
  } else if (charToCompare == '#') {
    return (
      <OtsideClickClosingWrapper className={`Mentions-Collapse ${visible && "Bordered-Box"}`} close={(e) => {
        if (visible) {
          setVisible(false)
        }
      }}>
        <CCollapse
          id="collapseWidthExample" verticle visible={visible}>
          <div className='User-Mention-Header'>Accounts And Deals</div>
          {allDealsSortedUsers && allDealsSortedUsers.map(user => {
            return (
              <div className='Deal-Account-Wrapper'>
                <div userName={user.Account_Name} style={{ backgroundColor: user.AccountColor, borderColor: user.AccountColor }} className='user-card' onClick={selectUser}>
                  <span>{user.Account_Name}</span>
                </div>

                <div userName={user.Deal_Name} style={{ backgroundColor: user.DealColor, borderColor: user.DealColor }} className='user-card' onClick={selectUser}>
                  <span>{user.Deal_Name}</span>
                </div>
              </div>
            )
          })
          }
        </CCollapse>

      </OtsideClickClosingWrapper>
    )
  } else if (charToCompare == "Attachments") {
    return (
      <OtsideClickClosingWrapper className={`Mentions-Collapse ${visible && "Bordered-Box"}`} close={(e) => {
        if (visible) {
          setshowMentions(false)
        }
      }}>
        <CCollapse
          id="collapseWidthExample" verticle visible={visible}>
          <div className='User-Mention-Header'>Attachments</div>
          {attachments
            && attachments.map(a => {
              return (
                <div className='Attachment-Main'>
                  <div className='Deal-Account-Wrapper Attachment-Wrapper'>
                    <FontAwesomeIcon
                      color='grey'
                      icon={faFileAlt}
                    />
                    <span>{a?.name}</span>
                  </div>
                  <FontAwesomeIcon
                      className='Delete-Icon'
                      icon={faTimes}
                      onClick={e=>{
                        let arr = attachments?.filter(aa=>aa.name!=a.name);
                        setAttachments(arr);
                        if(arr?.length==0){
                          setshowMentions(false)
                        }
                      }}
                    />
                </div>
              )
            })
          }
        </CCollapse>

      </OtsideClickClosingWrapper>
    )
  }
}
