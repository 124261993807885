import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ModuleService from "../../../../../../common/util/ModuleService";

const ServiceRequested = (props) => {
  const [servicereqtogglestate, setServicereqtoggle] = useState(false);
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    ModuleService.GetModuleListData("Requested_Service")
      .then((result) => {
        setAllServices(result);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  const servicereqtoggle = () => {
    setServicereqtoggle(!servicereqtogglestate);
    props.setListState("service");
    props.settoggleState("service");
  };

  const [skipService, setSkipService] = useState(false);
  // console.log(props.data.Services);

  const handleServiceCheck = () => {
    if (skipService) {
      props.setServicesComplete.servicesComplete = true;
      return true;
    }
    if (props.data.Services.length > 0) {
      props.setServicesComplete.servicesComplete = true;
      return true;
    }
    props.setServicesComplete.servicesComplete = false;
    return false;
  };

  const handleResetClick = () => {
    let checkbox = document.getElementById("serviceCheckBox");
    if (checkbox.checked) {
      checkbox.click();
    }
    props.handleOnChangeDeal({
      currentTarget: { name: "ServiceMerge", value: [] },
    });
    setServicereqtoggle(true);
  };
  const handleFullClick = () => {
    let allService = [...allServices];
    let arr = [];
    allService.forEach((one) => {
      let obj = { name: one.Name, id: one.id };
      arr.push(obj);
    });
    props.handleOnChangeDeal({
      currentTarget: { name: "ServiceMerge", value: arr },
    });
    let checkbox = document.getElementById("serviceCheckBox");
    if (!checkbox.checked) {
      checkbox.click();
    }
    setServicereqtoggle(true);
  };

  useEffect(() => {
    props.toggleState == "service"
      ? setServicereqtoggle(true)
      : setServicereqtoggle(false);
  }, [props.toggleState]);

  useEffect(() => {
    getData();
  }, [props.data]);

  const getData = () => {
    if (props.data != null) {
      props.setResearchOption(props.data.PreliminaryResearch);
      props.setLayoutOption(props.data.PreliminaryLayout);
      props.setSiteOption(props.data.SiteAssessment);
      props.setDesignOption(props.data.Design);
      props.setAsbestosOption(props.data.Asbestos);
      props.setExpeditingOption(props.data.Expediting);
      props.setPermittingOption(props.data.Permitting);
      props.setCertificationOption(props.data.Certification);
      props.setElectricalOption(props.data.ElectricalDiagram);
      props.setSpecialOption(props.data.SpecialInspection);
      props.setProgressOption(props.data.ProgressInspection);
      props.setEnergyOption(props.data.EnergyInspection);
      props.setInterconnectionOption(props.data.Interconnection);
      props.setStructuralOption(props.data.StructuralLetter);
      props.setPropertyOption(props.data.PropertySurvey);
      props.setNYSERDAOption(props.data.NYSERDAFiling);
      props.setPropertyTaxOption(props.data.PropertyTaxAbatement);
    }
  };

  return (
    <Col sm={12}>
      <Button
        onClick={servicereqtoggle}
        className={servicereqtogglestate ? "btn-100-active" : "btn-100"}
      >
        Services Requested
        <div className="float-right">
          <Input
            type="checkbox"
            id="serviceCheckBox"
            className={
              handleServiceCheck()
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={handleServiceCheck()}
            onChange={() => {
              //setSkipService(!skipService)
              //props.setServicesComplete.servicesComplete = true;
            }}
          />
          {servicereqtogglestate ? (
            <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
          )}
        </div>
      </Button>
      <Collapse isOpen={servicereqtogglestate}>
        <Card className="createdeal-card">
          <CardBody>
            <FormGroup className="marb-16">
              <Row>
                <Col sm={12}>
                  <FormGroup row className="marb-16">
                    <Row>
                      <Col sm={2} style={{ marginLeft: "1.8rem" }}>
                        <Button
                          className="continue-button"
                          onClick={handleResetClick}
                          style={{ width: "7rem" }}
                        >
                          Reset
                        </Button>
                      </Col>
                      <Col sm={3}>
                        <Button
                          className="continue-button"
                          onClick={handleFullClick}
                          style={{ width: "8rem" }}
                        >
                          Full Service
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row className="marb-16 padtop-15">
                    {allServices.map((one, index) => {
                      return (
                        <Col sm={6} key={index}>
                          <Row>
                            <Col sm={4}>
                              <Label
                                className="text-bold"
                                for="preliminaryresearch"
                              >
                                {one.Name}
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={2} className="radioStyle">
                              <Label className="text-bold2">Yes</Label>
                              <Input
                                type="radio"
                                name={one.Name}
                                id={one.id}
                                value={one.Name}
                                checked={
                                  props.data.Services &&
                                  props.data.Services.filter(
                                    (ser) => ser.id == one.id
                                  ).length > 0
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let obj = { ...props.data };
                                  if (obj.Services) {
                                    let service = [...obj.Services];
                                    service.push({
                                      id: one.id,
                                      name: one.Name,
                                      module: "CustomModule14",
                                    });
                                    obj.Services = service;
                                  } else {
                                    let service = [];
                                    service.push({
                                      id: one.id,
                                      name: one.Name,
                                      module: "CustomModule14",
                                    });
                                    obj.Services = service;
                                  }
                                  props.setData(obj);
                                }}
                              />
                            </Col>

                            <Col sm={2} className="radioStyle">
                              <Label className="text-bold2">No</Label>
                              <Input
                                type="radio"
                                name={one.key}
                                id={one.key}
                                value="No"
                                checked={
                                  props.data.Services &&
                                  !(
                                    props.data.Services.filter(
                                      (ser) => ser.id == one.id
                                    ).length > 0
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  let obj = { ...props.data };
                                  if (obj.Services) {
                                    let service = [...obj.Services];
                                    service = service.filter(
                                      (ser) => ser.id !== one.id
                                    );
                                    obj.Services = service;
                                  }
                                  props.setData(obj);
                                }}
                              />
                            </Col>
                            <Col sm={4}></Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default ServiceRequested;
