import httpService from "../../services/http.service";

class FieldService{
    GetFields(type,module) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/crm/v3/settings/fields?module="+module+"&type="+type,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    let arr=[...result.data.fields];
                    let firstDisplayArr=arr.filter(one=>one.quick_sequence_number);
                    let secondDisplayArr=arr.filter(one=>(!one.quick_sequence_number));
                    firstDisplayArr=firstDisplayArr.sort((a,b)=>parseInt(a.quick_sequence_number)-parseInt(b.quick_sequence_number));
                    arr=[...firstDisplayArr,...secondDisplayArr]
                    resolve(arr);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

}

export default new FieldService();