import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultUser from "../../assets/images/defaultUser.png";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ChatService from "../../common/util/ChatService";
import Comment from "../../common/Comment/comment";
import TaskService from "../../common/util/TaskService";

const CreateNoteModal = (props) => {
  const convertZohoClickToCRM = (message) => {
    const users = [...props.allusers];
    users.forEach((one) => {
      let fromStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.full_name}</span>`;
      let toStr = `<span uid="${one.zuid}" elemtype="contact" contenteditable="false" class="selusr sel" nodetype="@" data-title="@${one.first_name}" title="@Aditya">@${one.first_name}</span>`;
      message = message?.replaceAll(fromStr, "{@" + one.zuid + "}");
    });
    return message;
  };

  const saveNote = async (message) => {
    message = message.toString("html");
    message = message
      .replace("<p>", "")
      .replace("</p>", "")
      .replace("<br>", "")
      .replace("<h1>", "")
      .replace("</h1>", "")
      .replace("<h2>", "")
      .replace("</h2>", "")
      .replace("<h3>", "")
      .replace("</h3>", "")
      .replace("<em>", "")
      .replace("</em>", "")
      .replace("<h1></h1>", "")
      .replace("<h2></h2>", "")
      .replace("<h3></h3>", "")
      .replace("<pre><code></code></pre>", "")
      .replace("<blockquote></blockquote>", "")
      .replace(
        /<ol>\s*<li>\s*<\/li>\s*<\/ol>|<ul>\s*<li>\s*<\/li>\s*<\/ul>/g,
        ""
      );
    props.allusers?.forEach((e, i) => {
      message = message.replaceAll(`@${e.full_name}`, `{@${e.zuid}}`);
    });
    if (message?.includes('src=""')) {
      message = message.replace('<img src=""/>', "");
    }
    message = convertZohoClickToCRM(message);
    let messageVal = message;
    messageVal = messageVal.replaceAll("&nbsp;", "");
    if (messageVal?.trim()) {
      ChatService.SendMessageToUser(props.notesId, messageVal)
        .then(async function (data) {
          if (data.data.code) {
            alert(data.data.message);
          }
          props.setModalOpen(!props.modalOpen);
          props.getAllChatMessage();
          let clientColor = await TaskService.GetClientColor(
            props.job.Account_Name.id
          );
          let dealColor = await TaskService.GetDealColor(props.job.Stage);
          let userInfo = JSON.parse(localStorage.getItem("userinfo"));
          let text = `<a style="color:#40a9ff" id="User-${
            userInfo?.email_id
          }">${
            userInfo?.first_name + " " + userInfo?.last_name
          }</a>  <span>mentioned you in a </span>  <a style="color:#40a9ff" id="Note-${
            data.data.message_id
          }">Note</a>  <a style="background-color:${clientColor}" id="Client-${
            props.job.Account_Name.id
          }">${
            props.job.Account_Name ? props.job.Account_Name.name : ""
          }</a>  <a style="background-color:${dealColor}" id="Deal-${
            props.job.id
          }">${props.job.Deal_Name}</a>  `;
          return TaskService.SendMentionNotification(
            messageVal,
            props.allusers,
            text,
            "Notes"
          );
        })
        .then((result) => {
          if (props.getNotification) {
            props.getNotification();
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    } else {
      props.setModalOpen(true);
      alert("Enter something to create a Note!");
    }
  };

  return (
    <div>
      <Modal
        className="modal-notes-body"
        isOpen={props.modalOpen}
        toggle={props.toggle}
      >
        <div className="modal-header-class">
          Note{" "}
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={() => {
                props.toggle();
                // setSizeOfScreen(false)
              }}
            ></FontAwesomeIcon>
          </div>
        </div>

        <ModalBody>
          <div className="note-ckeditor">
            <Comment onSave={saveNote} id="notes" onClose={props.toggle} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateNoteModal;
