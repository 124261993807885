import { Checkbox, Table } from 'antd';
import React from 'react';

export const Checklist = () => {
    const columns = [
        {
            title: 'SubtaskID',
            dataIndex: 'name',
            key: 'name',
            render:(data)=>{
                return <span style={{display:"flex",gap:"10px", alignItems:"center"}}>
                    <Checkbox/>
                    <span>{data}</span>
                </span>
            }
        },
    ];
    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York Park',
        },
        {
            key: '2',
            name: 'Jim Green',
            age: 40,
            address: 'London Park',
        },
        {
            key: '3',
            name: 'Jim Green',
            age: 40,
            address: 'London Park',
        },
        {
            key: '4',
            name: 'Jim Green',
            age: 40,
            address: 'London Park',
        },
    ];
    return (
        <Table
            columns={columns}
            dataSource={data}
            scroll={{
                x: 1200,
            }}
        />
    )
}
