import React, { useEffect, useRef, useState } from 'react'
import { checkDeviceIdCookie, convertToUSTime, formateNoInDecimalString, getDeviceId, getWorkingHoursAndMinutes, modifyTimezoneString } from './components/HR/TimeClock/Util';
import attendance from "./common/util/AttendenceService";
import moment from 'moment';
function ClockInTime(props) {
    const [timer, setTimer] = useState(0);
    const [lastEntry, setLastEntry] = useState(null);
    const clockInterval = useRef(null);
    const entrytwo = useRef(null);
    const entry = useRef(null);
    const setTimeclockOnRefresh=async ()=>{ 
      let arr=[]
        await attendance.GetAttendenceAllUser(0, 0, 0)
         .then((result) => {
           result?.data?.data?.forEach(obj=>{
             if((obj?.Attendance?.checkIn!="-"||obj?.Attendance?.checkIn!="")
             && (obj?.Attendance?.checkOut=="-"||obj?.Attendance?.checkOut=="") && obj?.Attendance?.email == props.userInfo?.email_id  ){
               let object = {
                 in: obj.Attendance.checkIn,
                 ROWID: obj.Attendance.ROWID
               };
               arr.push(object);
              
             }
           })
           if(arr.length>0){
            arr=arr.sort((a,b)=>new Date(b.in)-new Date(a.in));
          if(arr[0]){
            entry.current="timeClockRefreshed";
            setLastEntry(arr[0]);
            const lastEntryIn = new Date(arr[0].in);
            const now = new Date();
            const startingTime = moment(lastEntryIn, "HH:mm:ss");
            const timeDifferenceInSeconds = Math.round((now - startingTime) / 1000);
            setTimer((timer)=>timer + timeDifferenceInSeconds);
            props.setClockIsActive(true);
            clockInterval.current = setInterval(() => {
            setTimer((timer) => timer + 1);
          }, 1000);
          }
           }
           })
           .catch((err)=>{
             console.log(err);
           })
         }
    useEffect(()=>{
    if(entrytwo.current!="loaded" && props.userInfo!=null){
      setTimeclockOnRefresh();
      entrytwo.current="loaded";
    }
    },[props.userInfo])


    const startStopClock = async () => {  
        entry.current=null;
        if (!props.clockisActive) {
          let checkOut =  convertToUSTime(new Date()) 
          let TimesheetDate=convertToUSTime(new Date(lastEntry?.in));
          // checkOut=TimesheetDate+", "+moment(checkOut, ["h:mm A"]).format("HH:mm:ss");
          let checkIn= convertToUSTime(new Date(lastEntry.in));
          let timeObj=getWorkingHoursAndMinutes(new Date(checkIn), new Date(checkOut));
          let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
          let payload={ 
            checkOut:checkOut,
            TotalHours:TotalHours,
            ROWID:lastEntry.ROWID
          }
          await getDeviceId(navigator).then(add=>{
            payload.CheckOutLocation = add;
             
            payload.DeviceId = checkDeviceIdCookie();
            // attendanceObj.CheckOutLocation = add;
          }).catch(e=>{
             
            console.log(e);
          })
          await attendance
            .UpdateAttendence(lastEntry.ROWID, payload )
            .then((result) => {
              console.log(result);
              // getLastAttendece();
              setLastEntry(null);
              props.setRefreshTimeclock(!props.refreshTimeclock)
            })
            .catch((err) => {
                 
              console.log(err);
            });
        } else {
          let checkIn =  convertToUSTime(new Date());
          let TimesheetDate= checkIn;
          let UserName =props.userInfo.first_name+" "+props.userInfo.last_name;
          let payload={
            UserName:UserName,
            TimesheetDate:TimesheetDate,
            checkIn:checkIn,
            checkOut:'-',
            email:props.userInfo.email_id
          }
          await getDeviceId(navigator).then(add=>{
            payload.CheckInLocation = add;
            payload.DeviceId = checkDeviceIdCookie();
          }).catch(e=>{
            console.log(e);
          })
          await attendance
            .CreateAttendence(checkIn, "", props.userInfo.email_id,payload)
            .then((result) => { 
              if(result.data.data.length>0){
                let obj = {
                  in:  modifyTimezoneString(result.data.data[0].Attendance.checkIn,"MM/DD/YYYY HH:mm:ss"),
                  out:  modifyTimezoneString(result.data.data[0].Attendance.checkOut,"MM/DD/YYYY HH:mm:ss"),
                  ROWID: result.data.data[0].Attendance.ROWID
                }; 
                setLastEntry(obj);
              } else {
                 
                setLastEntry(null);
              }
               props.setRefreshTimeclock(!props.refreshTimeclock);
            // getLastAttendece();
            console.log(result);
            })
            .catch((err) => {
                 
              console.log(err);
            });
        }
      };
      useEffect(() => { 
        if (lastEntry ) {
          if(entry.current=="timeClockRefreshed"){
            return
          }
          let seconds = 1;
          // new Date(Date.now()).getTime() - new Date(moment(lastEntry.in, 'DD/MM/YYYY HH:mm:ss')).getTime();
          seconds = Math.round(seconds / 1000);
          setTimer((timer) => timer + seconds);
        //   props.setClockIsActive(true);
          clockInterval.current = setInterval(() => {
            setTimer((timer) => timer + 1);
          }, 1000);
        } else {
          clearInterval(clockInterval.current);
          setTimer(0);
        //   props.setClockIsActive(false);
        }
      }, [lastEntry]);
      useEffect(() => { 
      if(props.appRefreshed){
      }else{
        startStopClock();
      } 
      }, [props.clockisActive])
      const formatTime = (timer) => {
        const getSeconds = `0${timer % 60}`.slice(-2);
        const minutes = `${Math.floor(timer / 60)}`;
        const getMinutes = `0${minutes % 60}`.slice(-2);
        const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    
        return `${getHours}:${getMinutes}:${getSeconds} Hrs`;
      };
  return (
    <div>{timer==0?"00:00:00":formatTime(timer)}</div>
  )
}
export default React.memo(ClockInTime);