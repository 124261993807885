import React, { useState, useEffect, useRef } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Marker from '../../preliminary/propertyinformation/Marker';
import Geocode from "react-geocode";
import ReactInputMask from "react-input-mask";
const CondoSecondOfficer = (props) => {
  const[zipValid,setZipValid]=useState();
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const [buttonState, setbuttonState] = useState(false);
  const placeInputRef = useRef(null);
  const [zoom, setZoom] = useState(16);
  const [mailInvalid, setMailInvalid] = useState();
  const [NumInvalid, setNumInvalid] = useState();

  const [skipCondo, setSkipCondo] = useState(false);
  const [validation,setValidation]=useState({
    // phoneno:'0',
    condocompnyNme:'0',
    condozipCode:'0',
    // condoAdd:'0',
    condostreetName:'0',
    condocityName:'0',
    condostateName:'0',
    condooffcrFrstNme:'0',
    condooffcrLast:'0',
    condorelatnOwner:'0',
    condoemailAdd:'0',
    condophoneNumber:'0'

  });

  let valid=(emailtext)=>{
    for(let i=1;i<emailtext.length;i++){
      if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
        return false;
      }
    }
    return true;
  }

  const highliteEmptys=(data)=>{
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
      setValidation(ob)
  }

  const resetValidation=()=>{
    setValidation({
      // phoneno:'0',
      condocompnyNme:'0',
      // condoAdd:'0',
      condostreetName:'0',
      condocityName:'0',
      condostateName:'0',
      condooffcrFrstNme:'0',
      condooffcrLast:'0',
      condorelatnOwner:'0',
      condoemailAdd:'0',
      condophoneNumber:'0'
    })
  }

  const onChangePhoneNumber = (value) => {

    var value1= value.replace(/[-#*$+/?=%() ]/g,'')
    let onlyNo= value1.replace(/\D/g, '');
    if(onlyNo.length>10){
      return
    }
    if(onlyNo.length==10){
      setValidation({...validation,condophoneNumber:'1'})
    }
    props.handleOnChangeDeal({ currentTarget: { name: 'condophoneNumber', value: value } });
    props.setCondoPhoneNumber(value);
  };

  const onBlurPhoneNumber = (value) => {
    let value1 = value.replace(/[-#*$+/?=%() ]/g, '')
    let onlyNo= value1.replace(/\D/g, '');
    if (onlyNo.length < 10) {
      setValidation({...validation,condophoneNumber:'-1'})
      setNumInvalid(true);
    } else {
      setValidation({...validation,condophoneNumber:'1'})
      setNumInvalid(false);
    }
  };

  let handlemailid = (email) => {
    // Email validation
    let validMail=false;
    let str=email.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str) && valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
    props.setCondoEmailAdd(email);

    if (validMail) {
      setValidation({...validation,condoemailAdd:"1"})
      setMailInvalid(false);
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.
    }
    else {

      if (email.length == 0) {
        setValidation({...validation,condoemailAdd:"-1"})
        setMailInvalid(false);
      } else {
        setValidation({...validation,condoemailAdd:"-1"})
        setMailInvalid(true);
      }
      // invalid email, maybe show an error to the user.
    }

  }

  const onChangeEmail = (value) => {
    if(value.target.name=='condoemailAdd'){
      // Email validation
    let validMail=false;
    let str=value.target.value.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
              setValidation({...validation,condoemailAdd:'1'})
            }
        }
    }
    // use validMail(true/false) for valoidation
    }
    if (value.target.value.length == 0) {
      setMailInvalid(false);
    }
    props.setCondoEmailAdd(value)
  }

  const onChangeApt = (value) => {
    props.setCondohouseNo(value);
    localStorage.setItem("mailinghouseNo", value);
  };


  Geocode.setApiKey("AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw");
  Geocode.setLanguage("en");
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("us");
  Geocode.setLocationType("ROOFTOP");
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();


  const buttontoggle = () => {
    props.settoggleState("condosecondo");
    props.setListState('condosecondo');
    setbuttonState(!buttonState);
  };


  const onChangeCondoAdd = (value) => {
    props.handleOnChangeDeal({ currentTarget: { name: 'condoAdd', value: value } });
    props.setCondoAdd(value);
  };


  const onBlurCondoAdd = (value) => {
    props.setCondoAdd(value);
    localStorage.setItem("condoAddress", value);

    Geocode.fromAddress(value).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;

        var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "";
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                houseNO = response.results[0].address_components[i].long_name;
                break;
              case "route":
                street = houseNO + " " + response.results[0].address_components[i].long_name;
                break;
              case "sublocality_level_1":
                city = response.results[0].address_components[i].long_name;
                break;
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                zipcode = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        if(street!==""){
          setValidation({...validation,condoAdd:'1',condostreetName:'1',
          condocityName:'1',condostateName:'1',condostreetName:'1'})
        }
        
        let obj = {}
        props.setCondohouseNo("");
        //   localStorage.setItem("condohouseNo", houseNO);
        props.setCondostreetName(houseNO + " " + street);
        localStorage.setItem("condostreet", houseNO + " " + street);
        props.setCondocityName(city);
        localStorage.setItem("condocity", city);
        props.setCondostateName(state);
        localStorage.setItem("condostate", state);
        props.setCondozipCode(zipcode);
        localStorage.setItem("condozipcode", zipcode);
        props.setCondoCenter({ lat: lat, lng: lng });
        props.setCondoltitude(lat);
        localStorage.setItem("condolat", lat);
        props.setCondolngitude(lng);
        localStorage.setItem("condolng", lng);
        obj.condoAdd = value;
        obj.condocenter = { lat: lat, lng: lng }
        obj.condostreetName = street;
        obj.condoltitude = lat;
        obj.condolngitude = lng;
        obj.condocityName = city;
        obj.condostateName = state;
        obj.condozipCode = zipcode;
        obj.condohouseNo = houseNO;
        props.handleOnChangeDeal({ currentTarget: { name: 'Merge', value: obj } })
      },
      (error) => {
        console.error("Error Finding Place:" + error);
      }
    );
  };

  const setAllStates = () => {
    setbuttonState(true);
    props.setCondoCenter({
      lat: localStorage.getItem("condolat"),
      lng: localStorage.getItem("condolng"),
    });
    props.setCondoAdd(localStorage.getItem("condoAddress"));
    props.setCondohouseNo(localStorage.getItem("condohouseNo"));
    props.setCondostreetName(localStorage.getItem("condostreet"));
    props.setCondocityName(localStorage.getItem("condocity"));
    props.setCondostateName(localStorage.getItem("condostate"));
    props.setCondozipCode(localStorage.getItem("condozipcode"));
    props.setCondoltitude(localStorage.getItem("condolat"));
    props.setCondolngitude(localStorage.getItem("condolng"));
    initPlaceAPI();
  };

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current);

  };

  useEffect(() => {
    props.toggleState == "condosecondo"
      ? setAllStates()
      : setbuttonState(false);
  }, [props.toggleState]);


  useEffect(() => {
    getData()
  }, [props.data]);


  const getData = () => {
    if (props.data != null) {

      props.setCondoAdd(props.data.condoAdd);
      //  props.setCondoCenter(props.data.dhcr);
      props.setCondostreetName(props.data.condostreetName);
      props.setCondoltitude(props.data.condoltitude);
      props.setCondolngitude(props.data.condolngitude);
      props.setCondocityName(props.data.condocityName);
      props.setCondostateName(props.data.condostateName);
      props.setCondozipCode(props.data.condozipCode);
      props.setCondohouseNo(props.data.condohouseNo);
      props.setCondoPhoneNumber(props.data.condophoneNumber);
      props.setCondoEmailAdd(props.data.condoemailAdd);
      props.setCondoCompnyNme(props.data.condocompnyNme);
      props.setCondoOffcrFrstNme(props.data.condooffcrFrstNme);
      props.setCondoOffcrLast(props.data.condooffcrLast);
      props.setCondoOffcrMiddle(props.data.condooffcrMiddle);
      props.setCondoRelatnOwner(props.data.condorelatnOwner);
    }
  }


  const handleCondoCheck = () => {
    if (skipCondo) {
      props.setCondoComplete.condoSecond = true;
      return true;
    }
    if (props.data.condocompnyNme !== "" && props.data.condocompnyNme !== undefined && props.data.condocompnyNme !== null
    // && props.condoAdd !== "" && props.condoAdd !== undefined
     && props.data.condostreetName !== "" && props.data.condostreetName !== undefined && props.data.condostreetName !== null &&
      props.data.condocityName !== "" && props.data.condocityName !== undefined && props.data.condocityName !== null &&
      props.data.condostateName !== "" && props.data.condostateName !== undefined && props.data.condostateName !== null &&
      props.data.condozipCode !== "" && props.data.condozipCode !== undefined && props.data.condozipCode !== null &&
      props.data.condooffcrFrstNme !== "" && props.data.condooffcrFrstNme !== undefined && props.data.condooffcrFrstNme !== null &&
      props.data.condooffcrLast !== "" && props.data.condooffcrLast !== undefined && props.data.condooffcrLast !== null &&
      props.data.condorelatnOwner !== "" && props.data.condorelatnOwner !== undefined && props.data.condorelatnOwner !== null &&
      props.data.condophoneNumber !== "" && props.data.condophoneNumber !== undefined && props.data.condophoneNumber !== null &&
      props.data.condoemailAdd !== "" && props.data.condoemailAdd !== undefined &&props.data.condoemailAdd !== null) {
      let allChecked=true;
      let vKeys=Object.keys(validation);
      for(let i=0;i<vKeys.length;i++){
        if(validation[vKeys[i]]=='-1'){
          allChecked=false ;
          break
        }
      }
      if(allChecked){
        props.setCondoComplete.condoSecond = true;
        return true;
      }
    }
    props.setCondoComplete.condoSecond = false;
    return false;
  }
  useEffect(e=>{
    if(props.data.ownerType != "CONDO/CO-OP"){
      setValidation({
        condocompnyNme:'0',
        condozipCode:'0',
        condoAdd:'0',
        condostreetName:'0',
        condocityName:'0',
        condostateName:'0',
        condooffcrFrstNme:'0',
        condooffcrLast:'0',
        condorelatnOwner:'0',
        condoemailAdd:'0',
        condophoneNumber:'0'
    
      })
    }
  },[props.ownerType])

  const obj={
    condocompnyNme : props.data.condocompnyNme,
    condozipCode : props.data.condozipCode,
    condoAdd : props.data.condoAdd,
    condostreetName : props.data.condostreetName,
    condocityName : props.data.condocityName,
    condostateName : props.data.condostateName,
    condooffcrFrstNme : props.data.condooffcrFrstNme,
    condooffcrLast : props.data.condooffcrLast,
    condorelatnOwner : props.data.condorelatnOwner,
    condoemailAdd : props.data.condoemailAdd,
    condophoneNumber : props.data.condophoneNumber
  }

  return (
    <Col sm={12}>
      <Button
        onClick={buttontoggle}
        className={buttonState ? "btn-100-active" : "btn-100"}
        disabled={props.data.ownerType != "CONDO/CO-OP" ? true : false}
      >
        CONDO/CO-OP Second Officer
        <div className="float-right">
          <Input type="checkbox"
            className={handleCondoCheck() ? "rightbutton-checkbox-active" : "rightbutton-checkbox"}
            checked={props.data.ownerType == "CONDO/CO-OP" ?handleCondoCheck() : true}
            onChange={() =>{
              setSkipCondo(!skipCondo)
              // if(zipValid==undefined||zipValid==false){setZipValid(false)}
              // if(mailInvalid==undefined||mailInvalid==false){setMailInvalid(false)}
              // if(NumInvalid==undefined||NumInvalid==false){setNumInvalid(false)}
              let vKeys = Object.keys(validation);
              let ob={};
              for (let i = 0; i < vKeys.length; i++) {
                // if (validation[vKeys[i]] == "-1" || validation[vKeys[i]] == "0" ) {
                //   highliteEmptys({ arr: validation });
                //   return;
                // }
                if (obj[vKeys[i]] == '' || obj[vKeys[i]] == undefined ) {
                  ob[vKeys[i]] = "-1";
                } else {
                  ob[vKeys[i]] = "1";
                }
                if(vKeys[i]=="condophoneNumber" && NumInvalid){
                  ob={...ob,condophoneNumber:'-1'}
                }
                if(vKeys[i]=="condoemailAdd" && mailInvalid){
                  ob={...ob,condoemailAdd:'-1'}
                }
                if(vKeys[i]=="condozipCode" && zipValid==false){
                  ob={...ob,condozipCode:'-1'}
                }
              }
              setValidation(ob);
             } }
            disabled={props.data.ownerType != "CONDO/CO-OP" ? true : false}
        />
          {buttonState ? (
            <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
          )}
        </div>
      </Button>
      <Collapse isOpen={buttonState}>
        <Card className="createdeal-card">
          <CardBody>

            <Row>
              <Col sm={10}>
                <FormGroup className="marb-16 padtop-15">

                  <Row>
                    <Col sm={3}>
                      <Label className="text-bold " for="projectnumber">
                        Company Name<span className="red-text">*</span>
                      </Label>
                    </Col>
                    <Col sm={7} >
                      <Input type="text" name="condocompnyNme" id="projectnumber" className="createdeal-textfield"
                        value={props.data.condocompnyNme}
                        style={validation.condocompnyNme=='-1'?{...validationCss}:{}}
                        onBlur={(e)=>{
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condocompnyNme:'-1'})
                          }else{
                            setValidation({...validation,condocompnyNme:'1'})
                          }
                        }}
                        onChange={(e) => { 
                          e.target.value=e.target.value.trimStart();
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condocompnyNme:'-1'})
                          }else{
                            setValidation({...validation,condocompnyNme:'1'})
                          }
                          props.handleOnChangeDeal(e); props.setCondoCompnyNme(e.target.value) }} />
                    </Col>
                  </Row>

                </FormGroup>

                <FormGroup className="marb-16 padtop-15">

                  <Row>
                    <Col sm={3}>
                      <Label className="text-bold " for="condoadd">
                        Company Address
                      </Label>
                    </Col>
                    <Col sm={7}>
                      <input type="text"
                        className="address-text2 form-control"
                        ref={placeInputRef}
                        value={props.data.condoAdd}
                        // style={validation.condoAdd=='-1'?{...validationCss}:{}}
                        onBlur={(e)=>{
                          // if(e.target.value==undefined||e.target.value==''){
                          //   setValidation({...validation,condoAdd:'-1'})
                          // }else{
                          //   setValidation({...validation,condoAdd:'1'})
                          // }
                          onBlurCondoAdd(e.target.value)
                        }}
                        onChange={(e) =>{ 
                          e.target.value=e.target.value.trimStart().replace(/[_!#$%^&*()<>?|}{~:]/,'');
                          // if(e.target.value==undefined||e.target.value==''){
                          //   setValidation({...validation,condoAdd:'-1'})
                          // }else{
                          //   setValidation({...validation,condoAdd:'1'})
                          // }
                          onChangeCondoAdd(e.target.value)}}
                      />


                    </Col>
                  </Row>
                </FormGroup>

              </Col></Row>

            <FormGroup className="marb-16">

              <Row>
                <Col sm={6}>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          Street Address<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" value={props.data.condostreetName} name="condostreetName" id="housenumber" className="createdeal-textfield"
                        style={validation.condostreetName=='-1'?{...validationCss}:{}}
                        onBlur={(e)=>{
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condostreetName:'-1'})
                          }else{
                            setValidation({...validation,condostreetName:'1'})
                          }
                        }}
                        onChange={(e) => { 
                          e.target.value=e.target.value.trimStart();
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condostreetName:'-1'})
                          }else{
                            setValidation({...validation,condostreetName:'1'})
                          }
                            props.handleOnChangeDeal(e); props.setCondostreetName(e.target.value) }} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          Apt, Suite, Bldg, Unit, etc.

                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" value={props.data.condohouseNum}
                          onChange={(e) => { 
                            e.target.value=e.target.value.trimStart();
                            props.handleOnChangeDeal(e); onChangeApt(e.target.value) }}
                          name="condohouseNum" id="housenumber" className=" createdeal-textfield" />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="projectnumber">
                          City<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" value={props.data.condocityName} name="condocityName" id="projectnumber" className="createdeal-textfield"
                          style={validation.condocityName=='-1'?{...validationCss}:{}}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,condocityName:'-1'})
                            }else{
                              setValidation({...validation,condocityName:'1'})
                            }
                          }}
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.trimStart().replace(/[0-9]/,'');
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,condocityName:'-1'})
                            }else{
                              setValidation({...validation,condocityName:'1'})
                            }
                            props.handleOnChangeDeal(e); props.setCondocityName(e.target.value) }} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          State<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" value={props.data.condostateName} name="condostateName" id="housenumber" className="createdeal-textfield"
                          style={validation.condostateName=='-1'?{...validationCss}:{}}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,condostateName:'-1'})
                            }else{
                              setValidation({...validation,condostateName:'1'})
                            }
                          }}
                          onChange={(e) => { 
                            e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,condostateName:'-1'})
                            }else{
                              setValidation({...validation,condostateName:'1'})
                            }
                            props.handleOnChangeDeal(e); props.setCondostateName(e.target.value) }} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          Zip Code<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" 
                        value={props.data.condozipCode} 
                        style={validation.condozipCode=='-1'?{...validationCss}:{}}
                        name="condozipCode" id="housenumber" className="createdeal-textfield"
                          onChange={(e) => { 
                            e.target.value=e.target.value.trimStart();
                            e.target.value=e.target.value.replace(/\D/g, '');
                            if(e.target.value.length>5){
                              e.preventDefault()
                              return
                            }
                            if(e.target.value.length==5){
                              setValidation({...validation,condozipCode:'1'})
                              setZipValid(true);
                            }
                            props.handleOnChangeDeal(e); 
                            props.setCondozipCode(e.target.value) 
                          }}
                          onBlur={(e)=>{
                             
                            if(props.data.condozipCode?.length<5){
                              setValidation({...validation,condozipCode:'-1'})
                              setZipValid(false);
                            }else{
                              setValidation({...validation,condozipCode:'1'})
                              setZipValid(true);
                            }
                          }}
                          />
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={7}><span className={validation.condozipCode=='-1'?"red-text font12":"error-display"}> 5 Digit Zip code required.</span></Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownerfirstname">
                          Officer's First Name<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" name="condooffcrFrstNme" id="ownerfirstname" className="createdeal-textfield"
                          value={props.data.condooffcrFrstNme}
                          style={validation.condooffcrFrstNme=='-1'?{...validationCss}:{}}
                        onBlur={(e)=>{
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condooffcrFrstNme:'-1'})
                          }else{
                            setValidation({...validation,condooffcrFrstNme:'1'})
                          }
                        }}
                        onChange={(e) => { 
                          e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                          e.target.value=e.target.value.replace(/[^\D]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^\D]/g,'').slice(1)
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condooffcrFrstNme:'-1'})
                          }else{
                            setValidation({...validation,condooffcrFrstNme:'1'})
                          }
                            props.handleOnChangeDeal(e); 
                            props.setCondoOffcrFrstNme(e.target.value) }} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownermiddlename">
                          Officer's Middle Initial
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" name="condooffcrMiddle" id="ownermiddlename" className="createdeal-textfield"
                          value={props.data.condooffcrMiddle}
                          onChange={(e) => { 
                            e.target.value=e.target.value.trimStart();
                            e.target.value=e.target.value.replace(/[^a-zA-Z]/g,'').toUpperCase()
                            props.handleOnChangeDeal(e); 
                            props.setCondoOffcrMiddle(e.target.value) }} 
                          maxLength={1}
                          />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownerlastname">
                          Officer's Last Name<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" name="condooffcrLast" id="ownerlastname" className="createdeal-textfield"
                          value={props.data.condooffcrLast}
                          style={validation.condooffcrLast=='-1'?{...validationCss}:{}}
                        onBlur={(e)=>{
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condooffcrLast:'-1'})
                          }else{
                            setValidation({...validation,condooffcrLast:'1'})
                          }
                        }}
                        onChange={(e) => { 
                          e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                          e.target.value=e.target.value.replace(/[^\D]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^\D]/g,'').slice(1)
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condooffcrLast:'-1'})
                          }else{
                            setValidation({...validation,condooffcrLast:'1'})
                          }
                            props.handleOnChangeDeal(e); 
                            props.setCondoOffcrLast(e.target.value) }} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="phoneno">
                          Officer Title
                          <small> (Relationship to Owner)</small>
                          <span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text" name="condorelatnOwner" id="phoneno" className="createdeal-textfield"
                          value={props.data.condorelatnOwner}
                          style={validation.condorelatnOwner=='-1'?{...validationCss}:{}}
                        onBlur={(e)=>{
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condorelatnOwner:'-1'})
                          }else{
                            setValidation({...validation,condorelatnOwner:'1'})
                          }
                        }}
                        onChange={(e) => { 
                          e.target.value=e.target.value.trimStart();
                          e.target.value=e.target.value.replace(/[^\D]/g,'')
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,condorelatnOwner:'-1'})
                          }else{
                            setValidation({...validation,condorelatnOwner:'1'})
                          }
                            props.handleOnChangeDeal(e); 
                            props.setCondoRelatnOwner(e.target.value) }} />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownername">
                          Phone Number<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                      <ReactInputMask
                          type="text"
                          mask="(999) 999-9999"
                          maskChar='_'
                          className="form-control createdeal-textfield" 
                          name="condophoneNumber" id="phoneno"
                          placeholder="(###) ###-####"
                          value={props.data.condophoneNumber}
                          style={validation.condophoneNumber=='-1'?{...validationCss}:{}}
                          onKeyDown={(evt) =>
                            ((evt.keyCode > 64 && evt.keyCode < 91) || evt.key === ".") &&
                            evt.preventDefault()
                          }
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart();
                            onChangePhoneNumber(e.target.value)
                          }}
                          onBlur={(e) => onBlurPhoneNumber(e.target.value)}

                        />
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={7}><span className={validation.condophoneNumber=='-1'?"red-text font12":"error-display"}> Enter 10 digit Phone Number.</span></Col>
                
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16  padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownername">
                          Email Address<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input type="text"
                          className="createdeal-textfield"
                          placeholder="abc@mail.com"
                          style={validation.condoemailAdd=='-1'?{...validationCss}:{}}
                          value={props.data.condoemailAdd} 
                          onChange={(evt) => { 
                            evt.target.value=evt.target.value.trimStart(); 
                            props.handleOnChangeDeal(evt); onChangeEmail(evt) }}
                          onBlur={(e) => handlemailid(e.target.value)}
                          name="condoemailAdd" id="owneremail" />
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={7}><span className={validation.condoemailAdd=='-1'?"red-text font12":"error-display"}> Incorrect E-mail Address format.</span></Col>
                    </Row>
                  </FormGroup>

                </Col>
                <Col sm={6} className="map-padding">
                  <GoogleMapReact

                    defaultCenter={props.condocenter}
                    center={props.condocenter}
                    defaultZoom={zoom}
                  >
                    <Marker
                      lat={props.condocenter ? props.condocenter.lat : ''}
                      lng={props.condocenter ? props.condocenter.lng : ''}
                      name="My Marker"
                      color="blue"
                    />

                  </GoogleMapReact>
                </Col>
              </Row>
            </FormGroup>


          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default CondoSecondOfficer;