import React, { useContext, useEffect, useRef, useState } from "react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import { AgGridReact, AgGridColumn } from "@ag-grid-community/react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import leftexpand from "../../../assets/images/svg/left.svg";
import leftexpandactive from "../../../assets/images/svg/leftactive.svg";
import leftexpandgray from "../../../assets/images/svg/left-gray.svg";
import { DatePicker as AntdDatepicker , Modal as AntModal} from "antd";
import {getWorkingHours,getTimeInAMPMFormat,getDeviceId,addTimeStrings,updateWorkingHoursIfExist,getDateFormate,formateNoInDecimalString, disabledDate, reverseObjectKeys, convertToUSDate, convertToUSTime, modifyTimezoneString, getYaerWeekStr, checkDeviceIdCookie, splitOvelappingDays, modifyTimesheetData} from './Util';
import leftexpandactivegray from "../../../assets/images/svg/leftactive-gray.svg";
import Config from '../../../config.json'
import axios from "axios";
import {
  Col,
  Button,
  Input,
  Modal ,ModalBody,
  Row,
  ModalHeader,
  ModalFooter,
  ListGroupItem,
  FormGroup,
} from "reactstrap";
import {Modal as BootStrapModal ,ModalBody as BootStrapModalBody} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faSearch ,faTimes} from "@fortawesome/free-solid-svg-icons";
import {CacheDataOfTimesheet,SelectedIdsForTimeClock,ActionsOfTimeClock as Actions ,isDeleteEnable, addTimeSheetClicked, hrActiveKey} from "../../../Context/AppActions";
import { Tabs, Tab, Nav } from "react-bootstrap";
// import { AllModules } from '@ag-grid-community/all-modules';
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import './HR.css';
import {getWorkingHoursAndMinutes} from './Util'
import Table from '../../../common/table/table';

import { i, label, object, param } from "react-dom-factories";
import dayjs from "dayjs";
import TimePicker from "react-time-picker/dist/entry.nostyle";
import Draggable from 'react-draggable';
// Import Draggable
// import "../projects/job/jobbody/tasks/TimePicker.css";
import { v4 as uuidv4 } from "uuid";
// import Table from "../../common/table/table";
// import RequestTable from "./request";
import { isArray } from "highcharts";
// import Where from "./where";
import { DateRangePicker } from "react-date-range";
import { addDays, parse } from "date-fns";
import { subDays } from "date-fns/esm";
import validate from "../../../common/util/validation";
import 'antd/dist/reset.css';
import swal from "sweetalert";
import HRTabs from "./HRTabs";
import AntdRangePicker from "../../../common/DateRangePicker";
import AttendenceService from "../../../common/util/AttendenceService";
import UserService from "../../../common/util/UserService";
import RequestTable from "./Request";
import TimeSheetService from "../../../common/util/TimeSheetService";
import Where from "./Where";
import Loader from "../../../common/loader";
import ShiftMapping from "./ShiftMapping";
import HttpService from "../../../services/http.service";
import { useParams } from "react-router-dom";
import { GlobalAgGridTable } from "../../Deal/AgGridReactComponent/GlobalAgGridTable";
import AppContext from "../../../Context/AppContext";
import Select from "react-select";
import WhoIsIn from "./WhoIsIn";
import { DayView } from "./DayView/DayView";
import moment from "moment";
// import func from '../../common/utill/date';
const DAY_MAP = new Map([
  [0, "SUN"],
  [1, "MON"],
  [2, "TUE"],
  [3, "WED"],
  [4, "THU"],
  [5, "FRI"],
  [6, "SAT"],
]);
const schemaForAbsense = [
  { mail: "user required" },
  { date: "Timesheet date required" },
  { in: "check in time required" },
  { out: "check out time required" },
];
const schemaForUserTimesheet = [
  { date: "Timesheet date required" },
  { in: "check in time required" },
  { out: "check out time required" },
];
const EMPTY_STRING = "";
const NULL_VALUE = null;
const DAY_WISE_ROW_DATA = "DAY_WISE_ROW_DATA";
const CALCULATE_WEEKLY_TOTAL_HRS = "CALCULATE_WEEKLY_TOTAL_HRS";
const INCOMPLTED_WEEK_TOTAL_HRS = "INCOMPLTED_WEEK_TOTAL_HRS";
const LAST_ROW_TOTAL_HRS = "LAST_ROW_TOTAL_HRS";
const LAST_ENTRY = "lastEntry";
const LAST_DAY = "SAT";
let weeklyTotalHr = "00:00";
let totalHr = "00:00";
let totalDailyHours = "00:00";

function HRTimeClock(props) {
  const [gridApi, setGridApi] = useState(null);
  const [validationScema,setValidationScema]=useState([...schemaForUserTimesheet]);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [cacheData, setcacheData] = useState([]);
  const [rowDataForDay, setRowDataForDay] = useState([]);
  const [overlapError,setOverlapError] = useState("");
  const [absentDates,setAbsentDates] = useState([])
  const [disableDates,setDisableDates] = useState([])
  const [cacheDataForDay, setCacheDataForDay] = useState([]);
  const [cacheRowData, setCacheRowData] = useState([]);
  // const [from, setFromRow] = useState(1);
  // const [to, setToRow] = useState(100);
  // const [totalRow, setTotalRow] = useState(100);
  // const [backActive, setbackActive] = useState(false);
  // const [frontActive, setfrontActive] = useState(true);
  // const [dotActive, setdotActive] = useState(false);
  // const [filterText, setFilterText] = useState(null);
  const [allusers, setAllUsers] = useState([]);
  const [allCacheUser, setAllCacheUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState();
  const [selectedUserName, setSelectedUserName] = useState("David Rastegar");
  const [actionId, setActionId] = useState("");
  const [errors, setErrors] = useState(null);
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');
  const [filterState, setFilterState] = useState(true);
  const [firstActive, setFirstActive] = useState(false);
  const [selectedTable, setSelectedTable] = useState('timeSheet');
  const [selectedTbl, setSelectedTbl] = useState('listView');
  const [listSelectedRows, setlistSelectedRows] = useState([]);
  const [timeEntryObj, setTimeEntryObj] = useState({});
  const [editUsername, setEditUsername] = useState('');
  const [timeEntryPopUp, setTimeEntryPopUp] = useState(false);
  // const [allStart, setAllStart] = useState(new Date());
  // const [allEnd, setAllEnd] = useState(new Date());
  // console.log("timeEntryPopUp", timeEntryPopUp);
  const [loaderState, setLoaderState] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [isEditColumnTrue, setIsEditColumnTrue] = useState();
  const [selectedUserIdForEntry, setSelectedUserIdForEntry] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 35 });
  const [manualRefresh, setmanualRefresh] = useState(false);
  const [userList, setUserList] = useState([]);
  const columnForZoho = [
    {
      field: "UserName",
      name: "User Name",
      path: "UserName",
      type:"text",
      label: "User Name",
      id : 0,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      content: (obj) => (
        <label hidden={selectedTbl === "dayView"}>{obj.userName}</label>
      ),
    },
    {
      key:"TimesheetDate",
      name: "Date",
      field: "TimesheetDate",
      type:"datetime",
      path: "Date",
      label: "Date",
      id : 1,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      content: function(obj,key){return GetFormattedDate(new Date(obj[key]))}
    },
    { 
      path: "checkIn",
      label: "Check In",
      field: "checkIn",
      type:"text",
      name: "Check In",
      id : 2,
      isChecked : false,
      isDragOver : false,
      isSearch : true,  
    },
    { 
      field: "checkOut", 
      name: "Check Out" ,
      path: "checkOut", 
      type:"text",
      label: "Check Out",
      id : 3,
      isChecked : false,
      isDragOver : false,
      isSearch : true, 
    },
    // { path: "CheckInLocation", label: "Check In Location" },
    // { path: "CheckOutLocation", label: "Check Out Location" },
    { 
      path: "TotalHours", 
      label: "Total Hours" ,
      field: "TotalHours", 
      type: "text",
      name: "Total Hours",
      id : 4,
      isChecked : false,
      isDragOver : false,
      isSearch : true, 
    },
    // { path: "WorkingHours", label: "Working Hours" },
    {
      key: "email",
      name: "Action",
      type:"text",
      label: "Action",
      field: "Action",
      id : 5,
      isChecked : false,
      isDragOver : false,
      isSearch : true,
      content: function(obj,key){return obj.TimesheetDate == formattedDateToday ? "" : actionListCellRenderer(obj, obj[key])},
    },
  ];
  const [activeHeaderData, setActiveHeaderData] = useState([...columnForZoho])
  const handleDrag = (e, ui) => {
    if(e.target && e.target.closest(".ant-picker-dropdown")){
      e.stopPropagation();
      return
      }
    const { x, y } = ui;
    setModalPosition({ x, y });
  };
  const todayDate = new Date();
  const month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
  const day = todayDate.getDate().toString().padStart(2, '0');
  const year = todayDate.getFullYear();
  const formattedDateToday = `${month}/${day}/${year}`;
  const history = props.history;
  const location = props.location;
  const params=useParams();
  useEffect(e=>{
    if(userInfo && (userInfo?.role_details.role_name == 'App Administrator')){
     setValidationScema([...schemaForAbsense])
    }
  },[])
  useEffect(()=>{
      if(props.getParams){
          props.getParams( { module: 'TimeClock', action: 'listview' } )
      }
  },[location])
  // useEffect(e=>{
  //   if(rowData && rowData.length>0){
  //     modifyDataForDayView(rowData)
  //   }
  // },[rowData])
  useEffect(()=>{
    
    getAllAttendence(new Date(startDate), new Date(endDate));
    if(selectedTable=="request"||selectedTable=="timeSheet"){
      dispatch(isDeleteEnable(false))
    }else{
      dispatch(isDeleteEnable(true))
    }
  },[props.clockisActive,selectedTable])
  const getStartDate = () => {
    let startDate = new Date(Date.now());
    startDate.setDate(1);
    startDate.setHours(0, 0, 0);
    return startDate;
  }
  function lastDateOFMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }
  const getEndDate = () => {
    let endDate = new Date(Date.now());
    endDate.setDate(lastDateOFMonth(new Date().getFullYear(), new Date().getMonth()));
    endDate.setHours(0, 0, 0);
    return endDate;
  }
  const [startDate, setStartDate] = useState(getStartDate());
  const [endDate, setEndDate] = useState(getEndDate());


  const [zohoRequestData, setZohoRequestData] = useState([])
  const ActionsOfTimeClock=state.ActionsOfTimeClock;
  const addClicked=state.addTimeSheetClicked;
  const activeKey=state.hrActiveKey;


  // console.log("addClicked", addClicked);

  useEffect(e=>{
    if(addClicked && addClicked?.module == "timeSheet"){
      setEditUsername('')
      setModalPosition({ x: 0, y: 35 })
      addPopupOpen("", "Add")
    }
  },[addClicked])
  useEffect(e=>{
    if(selectedUserEmail){
       
      let arr=[];
      absentDates.map(a=>{
      if(a.email==selectedUserEmail){
        arr.push(a.date);
      }});
      setDisableDates(arr)
    }else{
      setDisableDates([])
    }
  },[selectedUserEmail,absentDates])

  useEffect(e=>{
   setSelectedTable(activeKey?.tab);
  },[activeKey])

  // useEffect(() => {
  //   if(ActionsOfTimeClock=="Export"){
  //     exportListViewData("newExcelSheet.xlsx")
  //   }else if(ActionsOfTimeClock=="EditColumns"){
  //     setIsEditColumnTrue(true)
  //   }else if(ActionsOfTimeClock=="Delete" && gridApi){
  //     let array=[];
  //     gridApi?.forEachNode((node) => {
         
  //           if (node.isSelected()) {
  //             if(node?.data?.ROWID){
  //               array.push(node?.data?.ROWID)
  //             }
  //           }
  //     })
  //     swal({
  //       title: "Warning!",
  //       text: "Are you sure you want to delete the record?",
  //       icon: "warning",
  //       buttons: {
  //         cancel: "Cancel",
  //         confirm: "Proceed",
  //       },
  //     }).then((isDelete)=>{
  //       if(isDelete){
  //         AttendenceService.DeleteAttendance(array).then(res=>{
  //           let filteredData=cacheRowData.filter(e=>!array.includes(e.ROWID));
  //           let filteredData1=rowData.filter(e=>!array.includes(e.ROWID));
  //           setCacheRowData(filteredData);
  //           setRowData(filteredData1);
  //           swal("Success!", "Records deleted successfully!", "success");
  //          // swal("Record seleted successfully!");
  //         }).catch(e=>{
  //            swal("Error!", "Something went wrong!", "error");
  //          // swal("Something Went Wrong!")
  //         })
  //       }
  //     })
  //   }
  //   dispatch(Actions(""))
  //   }, [ActionsOfTimeClock])
   
    // const onFirstDataRendered=(event)=>{
    //   dispatch(SelectedIdsForTimeClock([]))
    // }

  // const exportListViewData=(fileName)=>{
  //   gridApi?.exportDataAsExcel({
  //     fileName: fileName,
  //     onlySelected: true,
  //     processCellCallback: function(params) {
  //       const rowData = params.node.data;
  //       const columnField = params.column?.getColDef().field;
  //       return params.value;
  //     }
  //   });
// }
  
  useEffect(() => { 
    getAllUsers()
  }, [selectedTbl])
  
  useEffect(()=>{
    if(props.refreshEnable){
    getAllAttendence(startDate,endDate);
    setStartDate(getStartDate());
    setEndDate(getEndDate());
    setTimeout(() => {
      props.setRefreshEnable(false);
    }, 3000);
  }
    },[props.refreshEnable])
  // let allNodes = [];
  // const onSelectionChanged=(event)=>{
  //   setGridApi(event.api)
  //   let listTypeRows = event.api.rowModel?.rowsToDisplay.filter(node => node.isSelected());
  //   if (listTypeRows.length == event.api.rowModel.rowsToDisplay.length && listSelectedRows.length < listTypeRows.length && listSelectedRows.length != 0) {
  //     event.api.deselectAll();
  //   } else {
  //     let array = [];  
  //     event.api.forEachNode((node) => {
  //       // allNodes.push(node?.data)
  //       // const nodeRowId = node?.data?.id;
  //       // if (nodeRowId) {
  //         if (node.isSelected()) {
  //           array.push(node?.data)
  //         } else {
  //         }
  //       // }
  //     });
  //     setlistSelectedRows(listTypeRows);
  //     dispatch(SelectedIdsForTimeClock(array))
  //   }
  // }
  
  const extraComponentForTable=(e)=>{
    return (selectedUser && selectedTbl=="dayView" ? <span className="User-Name-Header-Span">{selectedUser?.full_name && selectedUser.full_name}</span> 
    : 
    <AntdRangePicker onChangeDateRange={onChangeDateRange} allStart={cacheRowData[cacheRowData?.length-1]?.TimesheetDate} allEnd={cacheRowData[0]?.TimesheetDate} defaultStartDate={startDate} defaultEndDate={endDate} />
  )
}
function disabledDateLocal(current) {
  // Disable dates from today onwards
  const today = moment().startOf('day');

  // Check if the current date is today or in the future
  if (current && current.valueOf() >= today.valueOf()) {
    return true;
  }

  // Check if the current date is in the array of blocked dates
  return disableDates.some(date => current && current.isSame(date, 'day'));
}
  // const getModalForEditColumns = () => {
  //   return <BootStrapModal
  //     className="Edit-Column-Modal"
  //     show={isEditColumnTrue?true:false}
  //     backdrop={true}
  //     toggle={() => {

  //     }}
  //     onHide={(e) => {
  //       setIsEditColumnTrue(false)
  //       // props.dealListTriggers({ type: "EditColumns", status: false })
  //     }}
  //   >
  //     <ModalHeader>
  //       Manage Columns
  //     </ModalHeader>
  //     <BootStrapModalBody>
  //       <FormGroup className="pos-relative editcol-searchpad">
  //         <Input
  //           type="text"
  //           placeholder="Search"
  //           className="mr-sm-2 pad-fix search-filter"
  //           value={"searchValue"}
  //           // onChange={(e) => onSearchColumns(e.target.value)}
  //         />

  //         <FontAwesomeIcon
  //           className="mr-sm-2 search-ico"
  //           icon={faSearch}
  //         />

  //         <div className="select-all-editColumn">
  //           <input
  //             type="checkbox"
  //             // checked={editColumnSelectAllFlag}
  //             onChange={(e) => {
  //               const headers = [...activeHeaderData];
  //               headers.forEach((obj) => {
  //                 if (obj.field !== "Deal_Name") {
  //                   obj.isChecked = !e.currentTarget.checked;
  //                 }
  //               });
  //               // setActiveHeaderData(headers);
  //               // setColumnsEdit(true);
  //             }}
  //           ></input>
  //           {"  "}
  //           <span className={"editColumnlist-label "}
  //             style={{ fontFamily: "Avenir Next LT W02", fontSize: "13px", marginLeft: "-3px" }}
  //           >
  //             Select All
  //           </span>
  //         </div>
  //         <hr className="hr-line" />
  //       </FormGroup>
  //       <ul className="editColummn-list">
  //         {activeHeaderData?.map((e, index) =>{ 
  //           e.name &&
  //             // e.isSearch &&
               
  //             <ListGroupItem
  //               style={{ marginLeft: 8 }}
  //               className="editColumnlist-text"
  //               draggable
  //               // onDrag={(ev) => onDragColumns(ev, e, activeHeaderData)}
  //               // onDragOver={(ev) =>
  //                 // onDragOverColumns(ev, e, activeHeaderData)
  //               // }
  //               // onDrop={(ev) => onDropColumns(ev, e, activeHeaderData)}
  //             >
  //               <FontAwesomeIcon
  //                 icon={faGripVertical}
  //                 className="icon-grip-vertical"
  //               />
  //               <input
  //                 disabled={e.name == "Deal_Name"}
  //                 id={index}
  //                 type="checkbox"
  //                 checked={!e.isChecked}
  //                 onChange={(e) => {
  //                   // handleColumns(e, index);
  //                 }}
  //               ></input>
  //               {"  "}
  //               <span
  //                 className={
  //                   e.isDragOver
  //                     ? "editColumnlist-label add-line"
  //                     : "editColumnlist-label"
  //                 }
  //               >
  //                 {e.name}
  //               </span>
  //               {e.isDragOver ? <hr className="hr-line" /> : null}
  //             </ListGroupItem>
             
  //               })}
  //       </ul>
  //     </BootStrapModalBody>
  //   </BootStrapModal>
  // }

  const [allUsersOptions,setAllUsersOptions]=useState([]);

  useEffect(e=>{
    if(cacheRowData && cacheRowData.length > 0 && selectedUser){
      if(selectedUser.id == "all"){
        setRowData(cacheRowData)
      }else{
        let arr = cacheRowData.filter(e => e.email == selectedUser.email);
        setRowData(arr)
      }
    }else{
      setRowData(cacheRowData)
    }
  },[selectedUser, props.refreshEnable, cacheRowData, activeKey?.view])
  
  useEffect(() => { 
    if (allusers.length > 0 && selectedTable === "timeSheet") {
      // getAllAttendence(new Date(startDate), new Date(endDate));
     // if (selectedTbl == 'listView') {
     //   getAllAttendence(new Date(startDate), new Date(endDate));
     // }
     // else if (selectedTbl == 'dayView') {
     //   getDayViewAttendence(selectedUser);
     // }
     let arr = allusers.map((item, i) => {
       return { value: item.id, label: item.full_name };
     });
     let arrOfUsers = allusers.map((item, i) => {
       return { value: item.id, label: item.full_name };
     });
    //  if(userInfo && (userInfo?.role_details.role_name !== 'App User') && (selectedTbl && (selectedTbl != "dayView"))){
    //    arrOfUsers.unshift({label: "All Users", value: "all"})
    //  }
     if (userInfo && (userInfo?.role_details.role_name == 'App User')) {
        let filterUser = allusers.filter(one => one.email == userInfo?.email_id);
        setSelectedUser(filterUser[0]);
        setSelectedUserEmail(filterUser[0].email);
        setSelectedUserName(filterUser[0].full_name)
      } else {
        // if(selectedTbl && (selectedTbl == "dayView")){
          setSelectedUser(allusers[0]); 
        // }
        setSelectedUserEmail(allusers[0].email);
        setSelectedUserName(allusers[0].full_name)
      }
     setAllUsersOptions(arr)
     setUserList(arrOfUsers)
   }
  }, [selectedTbl,selectedTable, allusers])

  const checkAndValidateEntry = (data) => {
     
    let obj = { ...data };
    let zohoData = [...cacheRowData];
    let thisUserMail = selectedUserEmail;

    let checkEntryExist = zohoData.filter(one => {
      let tempdate=dayjs(new Date(data.date)).format("MM/DD/YYYY");
      if(one.email==thisUserMail){
        // let dt = dayjs( one.checkIn, ["h:mm A"]).format("HH:mm:ss");
        // let dateTimeInVal = new Date(one.TimesheetDate);
        // let dateStr = dateTimeInVal.toString();
        // let dObj = dateStr.replace("00:00:00", dt)
  
        // let odt = dayjs(one.checkOut, ["h:mm A"]).format("HH:mm:ss");
        // let dateTimeOutVal = new Date(one.TimesheetDate);
        // let dateOutStr = dateTimeOutVal.toString();
        // let dOutObj = dateOutStr.replace("00:00:00", odt);
  
        let dObj = new Date(one.rawcheckIn);
        let dOutObj = new Date(one.rawcheckOut);
        data?.in?.setSeconds(0);
        data.out?.setSeconds(0);
        let cIn=data.in;
        let cOut=data.out;
  
        if (
          !((cIn < new Date(dObj) && (cOut < new Date(dObj) || getDateFormate(cOut) === getDateFormate(dObj)) ) 
        || ((cIn > new Date(dOutObj) || getDateFormate(cIn)  === getDateFormate(dOutObj)) && cOut > new Date(dOutObj) ))
        ){
          return one
        }
      }
    })

    if (checkEntryExist.length > 0) {
      return true
    }else {
      return false
    }
  };

  const getSelectUserForDayView = () =>{
  return  (<div className="extra-component-request-toRight">
    <div className="dayview-select">
    <Select
      value={
        selectedUser
          ? { label: selectedUser.full_name, value: selectedUser.id }
          : { label: "All Users", value: "all" }
      }
      options={userList}
      placeholder="Select..."
      closeMenuOnSelect={true}
      onChange={(e) => {
        if (e.value == "all") {
          onChangeUserFilter({ full_name: e.label, id: e.value });
        } else {
          let filterUser = allusers.filter((one) => one.id == e.value);
          if (filterUser.length > 0) {
            onChangeUserFilter(filterUser[0]);
          }
        }
      }}
      // value={selectedUserEmail ? selectedUserEmail :""}
      menuPlacement="auto"
    />
    </div>
  </div>)
  }

  const extraComponentToRight=(e)=>{
    return (
      <div className="extra-component-request-toRight">
        <div className="job-action-box">
          <Button
            className="mr-3 request-btns"
            onClick={() => {
              // setSelectedUserEmail(email);
              setEditUsername("");
              setModalPosition({ x: 0, y: 35 });
              addPopupOpen("", "Add");
            }}
          >
            Add Timesheet
          </Button>
        </div>
        <div>
          <Select
            value={
              selectedUser
                ? { label: selectedUser.full_name, value: selectedUser.id }
                : { label: "All Users", value: "all" }
            }
            options={userList}
            placeholder="Select..."
            closeMenuOnSelect={true}
            onChange={(e) => {
              if (e.value == "all") {
                onChangeUserFilter({ full_name: e.label, id: e.value });
              } else {
                let filterUser = allusers.filter((one) => one.id == e.value);
                if (filterUser.length > 0) {
                  onChangeUserFilter(filterUser[0]);
                }
              }
            }}
            // value={selectedUserEmail ? selectedUserEmail :""}
            menuPlacement="auto"
          />
        </div>
        <div>
        {getViewType()}
        </div>
      </div>
    );
  }

  const onChangeTime = (name, time) => {
     
    const timeObj = { ...timeEntryObj };

    if(editUsername.UserName==undefined||editUsername.UserName==''){
      let err = validate.validate(timeObj, validationScema, errors);
      setErrors(err);
    }
    let dateTime = new Date(timeObj.date);
    if (name == 'in' || name == 'out') {
      time = time.split(':')
      let tempDate=new Date(dayjs(dateTime).format("MM/DD/YYYY"));
      dateTime.setHours(parseInt(time[0]), parseInt(time[1]))
      if(timeEntryObj.block=="checkIn" && dateTime<=tempDate.setHours(0,0)) {
        setOverlapError({state:"out",text:"check out time should be greater than check in time"})
        return
      }else if(timeEntryObj.block=="checkOut" && dateTime>=tempDate.setHours(23,59)){
        setOverlapError({state:"in",text:"check In time should be samller than check out time"})
        return
      }else{
        setOverlapError(null)
      }
    }
    if (name == 'date') {
      if (timeObj.in) {
        let newIn = new Date(timeObj.date);
        newIn.setHours(timeObj.in.getHours(), timeObj.in.getMinutes())
        timeObj['in'] = newIn;
      }
      if (timeObj.out) {
        let newout = new Date(timeObj.date);
        newout.setHours(timeObj.out.getHours(), timeObj.out.getMinutes())
        timeObj['in'] = newout;
      }
    }
    timeObj[name] = dateTime;
    
    setTimeEntryObj(timeObj);
  };


  const getAllUsers = () => { 
    UserService.GetAllUsers()
      .then((result) => {
        result.data.users = result.data.users.filter(one => one.status == 'active');
        if (userInfo && (userInfo?.role_details.role_name == 'App User')) {
          let filterUser = result.data.users.filter(one => one.email == userInfo?.email_id);
          setAllUsers(filterUser);
          setAllCacheUser(filterUser);
        } else if(userInfo && (userInfo?.role_details.role_name !== 'App User') && (selectedTbl && (selectedTbl != "dayView"))){
          result.data.users.unshift({label: "All Users", value: "all", full_name: "All Users", id: "all"})
          setAllUsers(result.data.users);
          setAllCacheUser(result.data.users);
        }else{
          setAllUsers(result.data.users);
          setAllCacheUser(result.data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  
  const modifyDataForDayView=async (data)=>{
      
    // let weeksArray=[];
    const newData={
      "Sunday":[],
      "Monday":[],
      "Tuesday":[],
      "Wednesday":[],
      "Thursday":[],
      "Friday":[],
      "Saturday":[],
    };
  
      const result = {};
    
      data.forEach((obj) => {
        const date = new Date(obj.TimesheetDate);
        const week = getYaerWeekStr(date);  // Get week number
        const dayOfWeek = dayNames[date.getDay()]; // 0 (Sunday) through 6 (Saturday)
    
        // Initialize array for the week if it doesn't exist
        if (!result[week]) {
          result[week] = {
            "Sunday":[],
            "Monday":[],
            "Tuesday":[],
            "Wednesday":[],
            "Thursday":[],
            "Friday":[],
            "Saturday":[],
          };
        }
    
        // Initialize array for the day if it doesn't exist
        if (!result[week][dayOfWeek]) {
          result[week][dayOfWeek] = [];
        }
    
        // Add object to the array for the day
        result[week][dayOfWeek].push(obj);
      });
      setRowDataForDay(result);
      setCacheDataForDay(result);
    // Function to get the week number of a date
    // await reverseObjectKeys(result).then(data=>{
    //   setRowDataForDay(data);
    //   setCacheDataForDay(data);
    // }).catch(e=>{
    //   console.log(e);
    // });
    
  }
  const getAllAttendence =async (startDate, endDate) => {
    let start = dayjs(startDate).format("DD/MM/YYYY");
    let end = dayjs(endDate).format("DD/MM/YYYY");
   // setLoaderState(true)
    await AttendenceService.GetApsenceRecords().then(res=>{
      let data = res?.data?.map(req=>{
         
        let obj={
          email:req.RequestDataSchema?.userId,
          date:modifyTimezoneString(req?.RequestDataSchema?.TimesheetDate, "MM/DD/YYYY")
        }
        return obj;
      })
       
      if(data){
        setAbsentDates(data);
      }
    })
    await AttendenceService.GetAttendenceAllUser(start, end, 0)
      .then(async (result) => {
        const users = [...allusers];
        let arr = [];
         
        // result?.data?.data?.forEach(async obj=>{
        //   let d={...obj.Attendance}
        //   d.TimesheetDate=modifyTimezoneString(d.TimesheetDate,"MM/DD/YYYY");
        //   if(!(d['checkIn'] == '-' || d['checkIn'] == '') && !(d['checkOut'] == '-' || d['checkOut'] == '')){
        //     await splitOvelappingDays(modifyTimezoneString(d['checkIn'],"MM/DD/YYYY HH:mm:ss"),modifyTimezoneString(d['checkOut'],"MM/DD/YYYY HH:mm:ss"),d,true).then(res=>{
        //        
        //       res.forEach(ob=>{
        //         arr.push(ob);
        //       })
        //     })
        //   }else{
        //     await splitOvelappingDays(modifyTimezoneString(d['checkIn'],"MM/DD/YYYY HH:mm:ss"),modifyTimezoneString(d['checkOut'],"MM/DD/YYYY HH:mm:ss"),d).then(res=>{
        //        
        //       res.forEach(ob=>{
        //         arr.push(ob);
        //       })
        //     })
        //   }
        // })
        await modifyTimesheetData(result?.data?.data).then(r=>{
          arr=r;
        }).catch(err=>{
          console.log(err)
        });
         
        if(userInfo && userInfo?.role_details.role_name == 'App User'){
          arr=arr.filter(one => one.email == userInfo.email_id && (one.checkIn !== '-'));
        }else{
          arr=arr.filter(one =>(one.checkIn !== '-'));
        }
        arr = arr.sort((a, b) => new Date(b.rawcheckIn) - new Date(a.rawcheckIn));
        modifyDataForDayView(arr)
        setCacheRowData(arr)
        // if (selectedUser) {
        //   try{
        //     arr = arr.sort((a, b) => new Date(a.TimesheetDate) - new Date(b.TimesheetDate));
        //     modifyDataForDayView(arr)
        //     setCacheRowData(arr)
        //   }catch(e){
        //   }
        //   if (selectedUser.id == 'all') {
        //     setRowData(arr);
        //     dispatch(CacheDataOfTimesheet(arr))
        //   } else {
        //     arr = arr.filter(one => one.email == selectedUser.email);
        //     setRowData(arr);
        //     dispatch(CacheDataOfTimesheet(arr))
        //   }
        // } else {
        //   try{
        //     arr = arr.sort((a, b) => new Date(a.TimesheetDate) - new Date(b.TimesheetDate));
        //   }catch(e){
        //   }
        //   setRowData(arr);
        //   setCacheRowData(arr)
        //   modifyDataForDayView(arr)
        //   dispatch(CacheDataOfTimesheet(arr))
        // }

        setLoaderState(false)
       // getZohoAttendence(allusers);

      })
      .catch((err) => {
        setLoaderState(false)
        console.log(err);
      })
  }

  const getZohoAttendence = (users) => {
    let allUsers = [...users];
    let doArr = []
    allUsers=allUsers.filter(one=>one.email==selectedUserEmail);
    allUsers.forEach(user => {
      if (user) {
        doArr.push(getAttendenceDetail(user))
      }
    })
    // setLoaderState(true)
    Promise.all(doArr)
      .then((result) => {
        let arr = []
        result.forEach((one) => {
          one.Entries.forEach(obj => {
            arr = [...arr, ...obj.entries];
          })
        })
        setZohoRequestData(arr);
        setLoaderState(false);
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
      })

  }

  function GetFormattedDate(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return month + "/" + day + "/" + year;
  } 

  const addTimesheetEntry=async (obj,flag)=>{
     
    let requestArr = [];
    obj = { ...obj };
    if (!checkAndValidateEntry(obj) || actionId=="Edit") {
         
        obj.status = 0;
        let from = new Date(obj.in);
        //from.setHours(obj.in.getHours(), obj.in.getMinutes(), obj.in.getSeconds());
        let till = new Date(obj.out);
       // till.setHours(obj.out.getHours(), obj.out.getMinutes(), obj.out.getSeconds());
        let checkIn = dayjs(new Date(from)).format("DD/MM/YYYY HH:mm:ss");
        let checkOut = dayjs(new Date(till)).format("DD/MM/YYYY HH:mm:ss");
        //let user=allusers.filter((one)=>one.email==userInfo?.email_id)[0]
        let userName=allusers.filter((one)=>one.email==selectedUserEmail)[0]
        obj.userId = userName._id;
        obj.userName = userName.full_name;
        obj.requestType = "Timesheet Request"
        obj.date = GetFormattedDate(obj.date);
        requestArr.push(obj);
        if (userInfo && userInfo?.role_details.role_name == 'App User') {
           
          let payload={};
          payload.userId = userName.email;
          payload.userName = userName.full_name;
          payload.requestType =actionId=="Edit"?"Edit Timesheet Request_"+editUsername.ROWID:"Timesheet Request";
          payload.status = 0 ;
          payload.inTime = convertToUSTime(obj?.in);
          payload.outTime = convertToUSTime(obj?.out);
          payload.requestDate = convertToUSTime(new Date());
          payload.TimesheetDate = convertToUSTime(obj?.in);
          await getDeviceId(navigator).then(add=>{
            payload.CheckInLocation = add;
            payload.CheckOutLocation = add;
            payload.DeviceId = checkDeviceIdCookie();
          }).catch(e=>{
            console.log(e);
          })
          let HObject = getWorkingHoursAndMinutes(new Date(obj?.in),new Date(obj?.out));
          payload.hours= formateNoInDecimalString(HObject.hours)+":"+formateNoInDecimalString(HObject.minutes);
          await HttpService.getByBoj("requestTime",{
              config:{
                Type:"Post",
                data:payload
              }
            })
            .then((result) => {
              if (result.data == 'Duplicate') {
                swal("Alert!", "Please make sure entry does not overlap with existing hours", "warning");
              } else {
                setEditUsername("");
                setTimeEntryPopUp(false);
                swal("Success!", "Timesheet request sent successfully!", "success");
              }
            })
            .catch((err) => {
              swal("Error!", "Something went wrong!", "error");
            });
        } else {
          let attendanceObj={};
          attendanceObj.TimesheetDate= convertToUSTime(obj?.in);
          attendanceObj.UserName = obj.userName;
          if(obj?.block=="checkOut"){
            attendanceObj.checkIn = convertToUSTime(obj?.in);
          }else if(obj?.block=="checkIn"){
            attendanceObj.checkOut = convertToUSTime(obj?.out);
          }else{
            attendanceObj.checkIn = convertToUSTime(obj?.in);
            attendanceObj.checkOut = convertToUSTime(obj?.out);
          }
          await getDeviceId(navigator).then(add=>{
            attendanceObj.CheckInLocation = add;
            attendanceObj.CheckOutLocation = add;
            attendanceObj.DeviceId = checkDeviceIdCookie();
          }).catch(e=>{
            console.log(e);
          })
          attendanceObj.email = selectedUserEmail;
          let timeObj=getWorkingHoursAndMinutes(obj.in,obj.out);
          attendanceObj.TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
          let updatedValues={data:attendanceObj};
          //let updatedValues=updateWorkingHoursIfExist(attendanceObj,cacheRowData);
          // if(updatedValues.isEdit){
            if(actionId=="Edit"){
              attendanceObj.ROWID = editUsername.ROWID;
              let timeObj1=getWorkingHoursAndMinutes(new Date(updatedValues.data.checkIn),new Date(updatedValues.data.checkOut));
              updatedValues.data.TotalHours = formateNoInDecimalString(timeObj1.hours)+":"+formateNoInDecimalString(timeObj1.minutes);
              await AttendenceService.UpdateAttendence(updatedValues.data.ROWID,updatedValues.data)
                .then((result) => {
                //  console.log(result)
                  getAllAttendence(new Date(startDate), new Date(endDate));
                 swal("Success!", "Timesheet record updated successfully!", "success");
                })
                .catch((err) => {
                  console.log(err);
                  swal("Error!", "Something went wrong!", "error");
                  setSelectedUserEmail(null);
                })
          }else{
          await AttendenceService.CreateAttendence(checkIn, checkOut, selectedUserEmail,updatedValues.data)
            .then((result) => { 
            //  console.log(result)
            getAllAttendence(new Date(startDate), new Date(endDate));
             swal("Success!", "Timesheet added successfully!", "success");
            })
            .catch((err) => {
              console.log(err);
              swal("Error!", "Something went wrong!", "error");
              setSelectedUserEmail(null);
            })
          }
        }


    }else {
      swal("Alert!", "Please make sure entry does not overlap with existing hours", "warning");
    }
  }
  const handleSaveTimeEntry = async(obj, emaild) => {
     
    if (actionId == "Add") {
      let err = validate.validate(obj, validationScema, errors);
      if (err) {
        setErrors(err);
        return;
      }
    }
    
    
    let requestArr = [];
    obj = { ...obj };
    if(obj.in>obj.out){//intercecting two days
      obj.out.setDate(obj.out.getDate() + 1);
    }
    // let oldData=cacheRowData.find(d=>d.id)
    if (!checkAndValidateEntry(obj) || actionId=="Edit") {
        
      // if(obj.in>obj.out){//intercecting two days
      //   obj.out.setDate(obj.out.getDate() + 1);
      //   // let result=getWorkingHours(obj.in,obj.out)
      //   // // console.log(result);
      //   // if(result?.length>0){
      //   //   result.forEach(async o=>{
      //   //     await addTimesheetEntry(o,true)
      //   //   })
      //   //   setEditUsername("");
      //   //   setTimeEntryPopUp(false);
      //   //   dispatch(addTimeSheetClicked({module : ""}))
      //   //   // if (selectedTbl == 'listView') {
      //   //   getAllAttendence(new Date(startDate), new Date(endDate));
      //   //  // }
      //   //   // else if (selectedTbl == 'dayView') {
      //   //   //   getDayViewAttendence(selectedUser);
      //   //   // }
          
      //   // }
      // }
      await addTimesheetEntry(obj)
      dispatch(addTimeSheetClicked({module : ""}))
      setEditUsername("");
      setTimeEntryPopUp(false);
      //if (selectedTbl == 'listView') {
      getAllAttendence(new Date(startDate), new Date(endDate));
      //}
      // else if (selectedTbl == 'dayView') {
      //   getDayViewAttendence(selectedUser);
      // }
      setSelectedUserEmail(null);

    }else {
      swal("Alert!", "Please make sure entry does not overlap with existing hours", "warning");
    }

  }

  
  const getDatePickerValue = (date) => {
    try {
      let d = date
      if (d) {
        return dayjs(new Date(d));
      } else {
        return dayjs(new Date()).subtract(1,"day");
      }
    } catch (error) {}
  };

  const entryModalPopUp = () => {
    return (
      <AntModal
        open={timeEntryPopUp}
        draggable
        title={`${actionId} Working Entry`}
        maskClosable={false}
        width={300}
        className="Add-entry-popup"
        style={{ top: 170, borderRadius: 50 , right: 50}}
        onCancel={() => {
          dispatch(addTimeSheetClicked({module : ""}))
          setTimeEntryPopUp(false)
        }}
        modalRender={(modal) => (
          <Draggable
            defaultPosition={modalPosition}
            position={{x:modalPosition.x, y: modalPosition.y}}
            onStop={handleDrag}
          >
            <div>{modal}</div>
          </Draggable>
        )}
        footer={[
          <div
            className="col-sm-12"
          >
            <Button
              style={{ display: "inline", marginRight: "0.4rem" }}
              onClick={() => {
                dispatch(addTimeSheetClicked({module : ""}))
                setEditUsername("");
                setTimeEntryPopUp(false);
              }}
              key="Cancel"
              type="primary"
            >
              Cancel
            </Button>
            <Button
             onClick={() =>
              handleSaveTimeEntry(timeEntryObj, selectedUserEmail)
            }
              key="Save"
              type="primary"
              color="primary"
            >
            Save
            </Button>
          </div>,
        ]}
      >
        {
                    userInfo && (userInfo?.role_details.role_name == 'App Administrator') && 
                  <Row className="m-1">
                    <Col sm={2}>
                      <label>User:</label>
                    </Col>
                    <Col sm={10}>
                      <Select
                        value={
                          editUsername.UserName == "" ||
                          editUsername.UserName == undefined  
                            ? ""
                            : {
                                label: editUsername.UserName,
                                value: editUsername.UserName,
                              }
                        }
                        isDisabled={actionId == "Edit"}
                        options={allUsersOptions}
                        closeMenuOnSelect={true}
                        onChange={(values) => {
                          let ob = { ...timeEntryObj };
                          let user = allusers.find((u) => u.id == values.value);
                          setEditUsername({ UserName: user.full_name });
                          if (user && user.email) {
                            setSelectedUserEmail(user.email);
                            ob["mail"] = user.email;
                            setTimeEntryObj(ob);
                          }
                          let err = validate.validate(ob, schemaForAbsense, errors);
                          setErrors(err);
                        }}
                        // value={selectedUserEmail ? selectedUserEmail :""}
                        menuPlacement="auto"
                      />
                      {errors ? (
                        errors.mail ? (
                          <p className="text-danger small">{errors.mail}</p>
                        ) : (
                          ""
                        )
                      ) : ""}
                    </Col>
                  </Row>
                  }
            <Row className="m-1">
              <Col sm={2}>
                <label>Date:</label>
              </Col>
              <Col sm={10}>
                <AntdDatepicker
                  disabledDate={disabledDateLocal}
                  allowClear
                  disabled={actionId=="Edit" || (actionId!="Edit" && !editUsername?.UserName) ?true:false}
                  portalId="root"
                  format="MM/DD/YYYY"
                  value={getDatePickerValue(timeEntryObj.date)}
                  placeholderText="Select a date"
                  popperClassName="ag-custom-component-popup"
                  className="form-control form-control-sm"
                  onChange={(date) => {
                    let editObj = { ...timeEntryObj };
                    editObj["date"] = new Date(date);
                    let err = validate.validateProperty(
                      "date",
                      new Date(date),
                      errors
                    );
                    if (editObj.in) {
                      let newIn = new Date(editObj.date);
                      newIn.setHours(
                        editObj.in.getHours(),
                        editObj.in.getMinutes()
                      );
                      editObj["in"] = newIn;
                    }
                    if (editObj.out) {
                      let newout = new Date(editObj.date);
                      newout.setHours(
                        editObj.out.getHours(),
                        editObj.out.getMinutes()
                      );
                      editObj["out"] = newout;
                    }
                  
                    setTimeEntryObj(editObj);
                    setShowDatePicker(false)
                    //setFilterTextD(new Date(date));
                    setErrors(err);

                  }}
                  open={showDatePicker}
                  onOpenChange={open => setShowDatePicker(open)}
                />

                {errors ? (
                  errors.date ? (
                    <p className="text-danger small">{errors.date}</p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row className="m-1">
              <Col sm={2}>
                <label>Time:</label>
              </Col>
              <Col sm={5}>

                <input
                  type="time"
                  readOnly={timeEntryObj.block=="checkIn"?true:false}
                  className="form-control form-control-sm h-100"
                  format="HH:mm a"
                  style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
                  // clearIcon={null}
                  // disableClock={true}
                  onChange={(e) => {
                    onChangeTime("in", e.currentTarget.value);
                  }}
                  value={timeEntryObj ? convertTime12to24(timeEntryObj.in) : ""}
                  // value={editTimeObj ? editTimeObj.in : ""}
                />
                {/* <input
            type="time"
            name="in"
            // defaultValue="::00"
            onChange={(e) => onChangeTime(e.target.name, e.target.value)}
            value={editTimeObj ? editTimeObj.in : ""}
          /> */}
                {errors ? (
                  errors.in ? (
                    <p className="text-danger small">{errors.in}</p>
                  ) : (
                    ""
                  )
                ) :overlapError && overlapError?.state=="in"? 
                <p className="text-danger small">{overlapError?.text}</p>
              :""}
              </Col>
              <Col sm={5}>
                <input
                  type="time"
                  readOnly={timeEntryObj.block=="checkOut"?true:false}
                  className="form-control form-control-sm h-100"
                  format="hh:mm a"
                  style={{fontWeight : "bold", color : "rgba(0, 0, 0, 0.85)"}}
                  // clearIcon={null}
                  // disableClock={true}
                  onChange={(e) => onChangeTime("out", e.currentTarget.value)}
                  value={
                    timeEntryObj ? convertTime12to24(timeEntryObj.out) : ""
                  }
                />
                {errors ? (
                  errors.out ? (
                    <p className="text-danger small">{errors.out}</p>
                  ) : (
                    ""
                  )
                ) : overlapError && overlapError?.state=="out"? 
                <p className="text-danger small">{overlapError?.text}</p>
              :""}
               </Col>
              {/* <Col sm={3}>
            <input
              type="checkbox"
              name="working"
              checked={timeEntryObj ? timeEntryObj.working : false}
              onChange={(e) => {
                const timeObj = { ...timeEntryObj };
                timeObj[e.currentTarget.name] = e.currentTarget.checked;
                setTimeEntryObj(timeObj);
              }}
            />{" "}
            Working
          </Col> */}
            </Row>
      </AntModal>
    );
  }

  const SplitDataToInOneDayForAnotherDay = (result) => {
    try {
      result.forEach((one, index, self) => {
        if ((one.firstIn !== '-') && (one.lastOut !== '-')) {
          let fromDate = one.firstIn.split(' ');
          fromDate = new Date(fromDate[0]);
          let toDate = one.lastOut.split(' ');
          toDate = new Date(toDate[0]);
          let arr = []
          while (fromDate < toDate) {
            fromDate.setDate(fromDate.getDate() + 1);
            let chkIn = dayjs(fromDate).format('DD-MM-YYYY') + " - 00:00 AM";
            let chkOut = dayjs(fromDate).format('DD-MM-YYYY') + " - " + convertTime24To12("23:59");
            let obj = { checkIn: chkIn, checkOut: chkOut, checkIn_Location: one.firstIn_Location, checkOut_Location: one.lastOut_Location }
            arr.push(obj);
          }
          if (arr.length > 0) {
            arr[arr.length - 1].checkOut = dayjs(toDate).format('DD-MM-YYYY') + " - " + convertTime24To12(one.lastOut.split(' ')[1])
            let firstIn = arr[0].checkIn;
            firstIn = firstIn.split('-')
            let date1 = firstIn[0] + '/' + firstIn[1] + '/' + firstIn[2];
            firstIn = firstIn[firstIn.length - 1].replaceAll('AM', '');
            firstIn = firstIn.replaceAll('PM', '');
            firstIn = firstIn.split(':')
            firstIn = new Date().setHours(parseInt(firstIn[0]), parseInt(firstIn[1]))
            let firstInTime = convertTime12to24(new Date(firstIn))
            firstInTime = date1 + " " + firstInTime

            let lastOut = arr[arr.length - 1].checkOut;
            lastOut = lastOut.split('-')
            let date2 = lastOut[0];
            firstIn = lastOut[lastOut.length - 1].replaceAll('AM', '');
            lastOut = lastOut.replaceAll('PM', '');
            lastOut = lastOut.split(':')
            lastOut = new Date().setHours(parseInt(lastOut[0]), parseInt(lastOut[1]))
            let lastOutTime = convertTime12to24(new Date(lastOut))
            lastOutTime = date2 + " " + lastOutTime
            let newObj = { firstIn: firstInTime, firstIn_Location: one.firstIn_Location, lastOut: lastOutTime, lastOut_Location: one.lastOut_Location }
            newObj.entries = arr;

            if (index < self.length - 1) {
              if ((result[index + 1].firstIn !== '-') && (result[index + 1].lastOut !== '-')) {
                result[index + 1].entries = [...result[index + 1].entries, ...arr];
              } else {
                result[index + 1] = newObj
                ///mew Obj assign
              }
            }
          }

        }
      })
      return result;
    }
    catch (ex) {
      console.log(ex);
    }
  }


  const getAttendenceDetail = (user) => {
    return new Promise((resolve, reject) => {
      let doArr = []
      let frames = GetFramesAccordingToCalender(new Date(startDate), new Date(endDate));
      frames.forEach((frame) => {
        doArr.push(getDetailsAttendence(user, frame))
      })
      Promise.all(doArr)
        .then((result) => {
          //  result=SplitDataToInOneDayForAnotherDay(result);
          let totalHrs = '00:00'
          let monthlyHrs = "00:00"
          result.forEach((one, index, self) => {
            totalHrs = sumOfTotalHrs(totalHrs, one.totalHrs)
            if ((getDay(one.Date) == 'SAT') || (index == result.length - 1)) {
              one.weeklyHrs = totalHrs
              monthlyHrs = sumOfTotalHrs(monthlyHrs, totalHrs)
              if (index == result.length - 1) {
                one.monthlyHrs = monthlyHrs
              }

              totalHrs = "00:00"
            }
          })
          let obj = {}
          obj.UserName = user.full_name;
          obj.Email = user.email;
          obj.Entries = result;
          resolve(obj)
        })
        .catch((err) => {
          reject(err);
        })

    })
  }

  const getDetailsAttendence = async (user, date) => {
    return new Promise(async(resolve, reject) => {
      await AttendenceService.GetSplitAttendence(date, user.email)
        .then((result) => {
          result.data.Date = date;
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }


  const GetFramesAccordingToCalender = (from, till) => {
    let frames = []
    while (from <= till) {
      let formattedDate = dayjs(new Date(from)).format("MM-DD-YYYY");
      from.setDate(from.getDate() + 1)
      frames.push(formattedDate);
    }
    return frames;
  }


  const convertTime24To12 = (time) => {
    if (time) {
      time = time.split(':')
      let date = new Date()
      date.setHours(parseInt(time[0]), parseInt(time[1]));
      return getTime(date);
    } else {
      return "";
    }

  }

  const renderButton = (one, index) => {
    let obj = { ...one };
    if (index !== undefined) {
      obj.tid = [obj.tid[index]];
    }
    return actionListCellRenderer({ data: obj });
  };

  const getSelectedButton = (val, id) => {
    if (val !== undefined && val !== "" && val !== "undefined") {
      return id;
    }
  };

  const deletePopupOpen = (tid) => {
    // setSelectedTimerId(tid.split(","));
    setDelPopup(!delPopup);
  };

  function actionListCellRenderer(params, email,dayView) {  
      
    console.log(email);
    console.log(params);
    let btnDiv=document.createElement("div");
    btnDiv.classList.add("hrlist-action-btn");
    let addBtn=document.createElement('button');
    addBtn.classList.add("add")
    addBtn.value=params.TimesheetDate !== "" ? params.TimesheetDate : "";
    addBtn.innerText="Add"
    addBtn.addEventListener('click',(e)=>{
      console.log(email)
        
      setSelectedUserEmail(email);
      if(dayView && dayView?.isEmpty=="dayView"){
        addPopupOpen(e.currentTarget.parentElement.parentElement.id,"Add")
      }else{
        addPopupOpen(e.currentTarget.value, "Add")
      }
    })
    let editBtn=document.createElement('button');
    editBtn.classList.add("add")
    editBtn.innerText="Edit"
    editBtn.value=params.TimesheetDate !== "" ? params.TimesheetDate : "";
    editBtn.addEventListener('click',(e)=>{  
       
      console.log(email)
      console.log(params)
      setSelectedUserEmail(email);
      addPopupOpen(e.currentTarget.value, "Edit",params)
    })
    let deleteBtn=document.createElement('button');
    deleteBtn.classList.add("add")
    deleteBtn.style.color="red";
    deleteBtn.innerText="Delete";
    deleteBtn.value=params.Date !== "" ? params.Date : "";
    deleteBtn.addEventListener('click',(e)=>{ 
      // console.log(email)
      swal({
        title: "Warning!",
        text: "Are you sure you want to delete the record?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: "Proceed",
        },
      }).then(async(isDelete)=>{
        if(isDelete){
          if(userInfo && (userInfo?.role_details.role_name=='App User')){
            let payload = {};
            if (userInfo) {
              payload.userId = userInfo?.email_id;
            }
            let userName = allusers.filter((one) => one.email == userInfo?.email_id)[0]
             
            payload.userName = params?.UserName;
            payload.requestType = "Delete Timesheet Request_"+ params.ROWID;
            payload.requestDate = convertToUSTime(new Date());
            payload.TimesheetDate = convertToUSTime(new Date(params.TimesheetDate+" "+params.checkIn));
            payload.outTime = convertToUSTime(new Date(params.TimesheetDate+" "+params.checkOut));
            payload.inTime = convertToUSTime(new Date(params.TimesheetDate+" "+params.checkIn));
            payload.hours = params.TotalHours
            payload.status = 0;

            await axios.post(Config.backend_url + "requestTime", { config:{
              data:payload,
              Type: "Post",
            } })
              .then((result) => {
                  if(result.data.status=="success"){
                    swal("Success!", "Request added successfully", "success")
                  }else{
                    swal("Error!", "Something went wrong!", "error");
                  }
              })
              .catch((err) => {
                swal("Error!", "Something went wrong!", "error");
              });
    
          }else{
            AttendenceService.DeleteAttendance([params.ROWID]).then(res=>{
              let filteredData=cacheRowData.filter(e=>e.ROWID!=params.ROWID);
              let filteredData1=rowData.filter(e=>e.ROWID!=params.ROWID);
              setCacheRowData(filteredData);
              setRowData(filteredData1);
              modifyDataForDayView(filteredData)
              swal("Success!", "Record deleted successfully!", "success");
             // swal("Record seleted successfully!");
            }).catch(e=>{
               swal("Error!", "Something went wrong!", "error");
             // swal("Something Went Wrong!")
            })
          }
          }
      })})
    if(dayView && dayView.isEmpty){
      btnDiv.appendChild(addBtn);
    }else{
      btnDiv.appendChild(addBtn);
      btnDiv.appendChild(editBtn);
      btnDiv.appendChild(deleteBtn);
    }
    
    return btnDiv;
  }

  function isEmptyOrNull(str) {
    let flag = false;
    if (
      str == '-' ||
      str === undefined ||
      str === NULL_VALUE ||
      str === EMPTY_STRING ||
      str.length === 0
    ) {
      flag = true;
    }
    return flag;
  }

  const getTotalHrsBetweenTwoDate = (time1, time2) => {
    let dateTime = "";

    if (time1 && time2) {
      time1 = new Date(time1);
      time2 = new Date(time2);
      // let timeDiff=new Date(Math.abs((time1 - time2)));
      let timeDiff = new Object();
      timeDiff.milliseconds = Math.abs(time1 - time2);
      timeDiff.seconds = Math.ceil(timeDiff.milliseconds / 1000);
      timeDiff.minutes = Math.floor(timeDiff.seconds / 60);
      timeDiff.hours = Math.floor(timeDiff.minutes / 60);
      timeDiff.days = Math.floor(timeDiff.hours / 24);
      timeDiff.weeks = Math.floor(timeDiff.days / 7);
      if (timeDiff.seconds >= 60) {
        timeDiff.seconds = timeDiff.seconds % 60;
      }
      if (timeDiff.minutes >= 60) {
        timeDiff.minutes = timeDiff.minutes % 60;
      }
      if (timeDiff.seconds >= 30) {
        timeDiff.minutes = timeDiff.minutes + 1;
      }
      dateTime = dateTime + (timeDiff.hours < 10 ? "0" : "") + timeDiff.hours;
      dateTime =
        dateTime + ":" + (timeDiff.minutes < 10 ? "0" : "") + timeDiff.minutes;

      // dateTime =
      // dateTime + ":" + (timeDiff.seconds < 10 ? "0" : "") + timeDiff.seconds;
      return dateTime + " Hrs";
    } else {
      return "N/A";
    }
  };

  const getTime = (date) => {
    // let Time =  new Date(date);
    // Time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    if ((date == 'N/A') || (date == '')) {
      return date
    } else {
      try {
        var hours = date.getHours();
        var minutes = date.getMinutes();


        // if(seconds>=30){
        //   minutes=minutes+1;
        // }
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }
      catch (ex) {

      }


    }


  };

  const convertTime12to24String = (time12h) => {

    if (time12h) {
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes] = time.split(':');

      if (hours === '12') {
        hours = '00';
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      hours = (hours.length == 1) ? '0' + hours : hours;
      return `${hours}:${minutes}`;
    }
    return time12h;

  }

  const convertTime12to24 = (time12h) => {

    if (time12h) {
      time12h = getTime(time12h);
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes] = time.split(':');

      if (hours === '12') {
        hours = '00';
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }
      hours = (hours.length == 1) ? '0' + hours : hours;
      return `${hours}:${minutes}`;
    }
    return time12h;

  }



  const diffOfTotalHours = (time1, time2) => {
    let from = new Date();
    let till = new Date();
    if ((time2 !== '-') && (time1 !== '-')) {
      time2=convertTime12to24String(time2.trim())
      time1=convertTime12to24String(time1.trim())
      // time1 = time1.replaceAll('AM', '').trim();
      // time1 = time1.replaceAll('PM', '').trim();
      // time2 = time2.replaceAll('AM', '').trim();
      // time2 = time2.replaceAll('PM', '').trim();
      time1 = time1.split(":");
      time2 = time2.split(":");
      from.setHours(parseInt(time1[0]), parseInt(time1[1]),0,0); //, parseInt(time1[2]));
      till.setHours(parseInt(time2[0]), parseInt(time2[1]),0,0); //, parseInt(time2[2]));
      let diff = getTotalHrsBetweenTwoDate(from, till);
      let hours = diff.replace("Hrs", "").trim();
      totalDailyHours = sumOfTotalHrs(totalDailyHours, hours);
      return diff;
    } else {
      return 'N/A';
    }

  };

  function sumOfTotalHrs(preTimeTotalTime, newTime) {
    if (newTime == "N/A") {
      newTime = "00:00";
    }
    let hour = 0;
    let minute = 0;
    //let second = 0;

    let splitTime1 = preTimeTotalTime.split(":");
    let splitTime2 = newTime.split(":");

    hour = parseInt(splitTime1[0]) + parseInt(splitTime2[0]);
    minute = parseInt(splitTime1[1]) + parseInt(splitTime2[1]);
    hour = hour + minute / 60;
    minute = minute % 60;
    // second = parseInt(splitTime1[2]) + parseInt(splitTime2[2]);
    //minute = minute + second / 60;

    // minute = hour*60;
    //second = second % 60;

    //remove decimal places
    hour = Math.trunc(hour);
    minute = Math.trunc(minute);
    //second = Math.trunc(second);

    hour = (hour < 10 ? "0" : "") + hour;
    minute = (minute < 10 ? "0" : "") + minute;
    //  second = (second < 10 ? "0" : "") + second;

    let total = hour + ":" + minute; //+ ":" + second;

    //+":"+Math.trunc(second)
    //let total = hour.toFixed(0)+':'+minute.toFixed(0)+':'+second.toFixed(0)
    // console.log("total : " + total);

    return total;
  }
  const getDateFromTime=(time1,date1)=> {
    // Split the time string into hours, minutes, and AM/PM
    let timeComponents = time1.split(' ');
    let time = timeComponents[0].split(':');
    let hours = parseInt(time[0], 10);
    let minutes = parseInt(time[1], 10);
    let isPM = timeComponents[1] === 'PM';
    if (isPM && hours < 12) {
        hours += 12;
    }
    if (!isPM && hours === 12) {
        hours = 0;
    }
    let date = new Date(date1);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}
  const addPopupOpen = (date, actionId,data) => {   
     
    if(date){
      date = new Date(date);
    }else{
      date = new Date(dayjs(new Date()).subtract(1,"day").format("MM/DD/YYYY"));
      // date = new Date();
    }
    const editObj = { ...timeEntryObj };
    editObj.date = date;
    let inTime = new Date(date);
    let out = new Date(date);
    if(actionId == "Edit" && data){ 
      setEditUsername({UserName:data.UserName,ROWID:data.ROWID});
      inTime=getDateFromTime(data.checkIn,date);
      out=getDateFromTime(data.checkOut,date);
    }else{
      if(userInfo.role_details.role_name=="App User"){
        setEditUsername({UserName:userInfo?.email_id});
      }
      inTime.setHours(9, 0, 0, 0);
      out.setHours(17, 0, 0, 0);
    }
    let obj = { date: date, in: inTime, out: out ,block:data?.blockEdit };
    // setTimeEntryObj(editObj);
    // setFilterTextD(new Date(date));
    setTimeEntryObj(obj);
    setTimeEntryPopUp(!timeEntryPopUp);
    setErrors(null);
    setActionId(actionId);
  };

  const getDay = (date) => {
    date = new Date(date);
    let day = DAY_MAP.get(date.getDay());
    return day;
  }

  const getLastRow = (date, obj, color) => {
    if (getDay(date) == 'SAT') {
      return <tr
        style={{ color: "white", backgroundColor: color }
        }
      >
        <td>Total Hours</td>
        <td></td>
        <td></td>
        <td></td>
        <td>{obj.weeklyHrs}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {/* <td></td>
        <td></td> */}
      </tr>
    } else {
      return <></>
    }
  }

  const getLastMonthRow = (obj, color) => {
    if (getDay(obj.Date) !== 'SAT') {
      return <>
        <tr
          style={{ color: "white", backgroundColor: 'blue' }
          }
        >
          <td>Total Hours</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{obj.weeklyHrs}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {/* <td></td>
        <td></td> */}
        </tr>

        <tr
          style={{ color: "white", backgroundColor: color }
          }
        >
          <td>Total Hours</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{obj.monthlyHrs}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr></>
    } else {
      return <tr
        style={{ color: "white", backgroundColor: color }
        }
      >
        <td>Total Hours</td>
        <td></td>
        <td></td>
        <td></td>
        <td>{obj.monthlyHrs}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    }

  }


  const TableHrList = (data) => {
    console.log("-----------------------------------------------------");
    console.log(data);
    console.log("-----------------------------------------------------");

    if (selectedTbl == 'dayView') {
      data.forEach((oneUser) => {
        if (oneUser) {
          oneUser.Entries.forEach((one) => {
            one.entries = one.entries.filter((obj) => (obj.checkIn !== '-') && (obj.checkOut !== '-'))
          })
        }

      })
    }


    if (selectedTbl == "listView") {
      return "listView";
    }
    return (

      <table className="table table-sm table-striped table-hover border timesheet-table ">
        <thead style={{ position: "sticky", top: "-1px" }}>
          <tr>
            {/* <th style={{ width: "135px" }}>User Name</th> */}
            <th>Day</th>
            <th>Date</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Total Hours</th>
            <th>Total Daily Hours</th>
            <th>Unpaid</th>
            <th>Job/Absense</th>
            <th style={{ width: "96px" }}></th>
          </tr>
        </thead>
        <tbody>
          {data.map((oneUser) => {
            return oneUser.Entries && oneUser.Entries.map((one, idx) => {
              let date = one.firstIn ? one.firstIn.split(' ')[0] : ""
              if (date !== "") {
                let flag = ((!isEmptyOrNull(one.firstIn)) && Array.isArray(one.entries))
                if (flag) {
                  return one.entries.map((oneCheck, index) => {
                    let checkIn = one.entries[index].checkIn
                    if (checkIn !== '-') {
                      checkIn = checkIn.split('-');
                      checkIn = checkIn[checkIn.length - 1];
                    }
                    let checkOut = one.entries[index].checkOut
                    if (checkOut !== '-') {
                      checkOut = checkOut.split('-');
                      checkOut = checkOut[checkOut.length - 1];
                    }

                    return <> <tr>
                      {/* <td>{oneUser.UserName}</td> */}
                      <td>{index == 0 ? getDay(new Date(one.Date)) : ""}</td>
                      <td>{index == 0 ? one.Date : ""}</td>
                      <td>{checkIn}</td>
                      <td>{checkOut}</td>
                      <td>
                        {diffOfTotalHours(checkIn, checkOut)}
                      </td>
                      <td>{index == 0 ? one.totalHrs : ""}</td>
                      <td></td>
                      <td></td>
                      <td>{actionListCellRenderer(one, one.MailId)}</td>
                    </tr>
                      {index == one.entries.length - 1 ? getLastRow(one.Date, one, "blue") : <></>}
                      {idx == oneUser.Entries.length - 1 ? getLastMonthRow(one, "red") : <></>}
                    </>


                  })
                } else {
                  return <> <tr>
                    <td>{getDay(new Date(one.Date))}</td>
                    <td>{one.Date}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{actionListCellRenderer(one, oneUser.Email)}</td>
                  </tr>
                    {getLastRow(one.Date, one, 'blue')}
                    {idx == oneUser.Entries.length - 1 ? getLastMonthRow(one, "red") : <></>}
                  </>
                }


              } else {
                return (
                  <tr
                    style={
                      one.day == "lastEntry"
                        ? { backgroundColor: "red", color: "white" }
                        : { color: "white", backgroundColor: "blue" }
                    }
                  >
                    <td>Total Hours</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{one.totalHours}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td></td>
                    <td></td> */}
                  </tr>
                );
              }
            })
          })}
        </tbody>
      </table>
    );
  };

  const changeFilterState = () => {
    setFilterState(!filterState);
  };


  const getNavImageIconStyle = () => {

    if (filterState) {
      return <img
        className=" hr-table-icon"
        onClick={changeFilterState}
        src={leftexpand}
      />
    } else {
      return <img
        className=" hr-table-icon"
        onClick={changeFilterState}
        src={leftexpandactive}
      />
    }
    // if ((selectedTbl == 'listView') && (selectedTable == 'timeSheet')) {
    //   return <img
    //     className=" hr-table-icon"
    //     onClick={changeFilterState}
    //     src={leftexpand}
    //   />
    // } else {
    //   return <img
    //     className=" hr-table-icon"
    //     onClick={changeFilterState}
    //     src={((selectedTable == 'shiftMapping') || (selectedTable == 'request') || (selectedTable == 'where')) ? leftexpandactive : leftexpandgray}
    //   />
    // }
  }
  function formatDate(dateObj) {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return dateObj.toLocaleDateString('en-US', options);
  }
  const onChangeDateRange = (dates, datesString) => {
    if (datesString.length > 0) {
      let start = new Date(new Date(datesString[0]))
      let end = new Date(new Date(datesString[1]))
      start.setHours(0, 0, 0, 0);
      end.setHours(23,59, 0, 0);
      setStartDate(start);
      setEndDate(end);
      let formattedStartDate=formatDate(datesString[0])
      let formattedEndDate=formatDate(datesString[1])
      if(cacheRowData){
        let arr=cacheRowData.filter(r=>{
          if(formattedStartDate==formattedEndDate && dates=="Clear"){
            return r;
          } else if(new Date(r.TimesheetDate)>=start && new Date(r.TimesheetDate)<=end){
            return r;
          }
      })
        setRowData(arr);
      }
      // if(cacheDataForDay){
      //   let arr=Object.keys(cacheDataForDay).filter(week=>{
      //       Object.keys(cacheDataForDay[week]).filter(day=>{
      //         if(formattedStartDate==formattedEndDate && dates=="Clear"){
      //           return r;
      //         } else if(new Date(r.TimesheetDate)>=start && new Date(r.TimesheetDate)<=end){
      //           return r;
      //         }
      //       })
      //   })
      //   setRowData(arr);
      // }
      
    }

  }

  const onChangeUserFilter = (user) => {
    setSelectedUser(user);
  }

  const getViewType = ()=>{
   return (selectedTable=="timeSheet" && <div className="list-compact-view" style={{height: "1.8rem"}}>
    <div onClick={(e) => {setSelectedTbl("listView"); dispatch(hrActiveKey({tab: selectedTable, view: "listView"}))}} className="list-view" style={selectedTbl=="listView" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>List View</div>
    <div onClick={(e) => {setSelectedTbl("dayView"); dispatch(hrActiveKey({tab: selectedTable, view: "dayView"}))}} className="compact-view" style={selectedTbl=="dayView" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>Day View</div>
 </div>)
  }

  const updateCacheDataAfterApproved = (obj) => {
    let columnsData = {
      userName: obj.userName,
      date: obj.date,
      checkIn: getTime(new Date(obj.in)),
      checkOut: obj.out ? getTime(new Date(obj.out)) : "N/A",
      totalHours: getTotalHrsBetweenTwoDate(obj.in, obj.out),
      day: convertToDay(obj.date),
      tid: obj.timeId,
      note: obj.note,
    };
    cacheData.push(columnsData);
    setcacheData(cacheData);
  };

  function convertToDay(str) {
    let mydate = new Date(str);
    let dayNumber = mydate.getDay();
    console.log("dayNumber is : " + dayNumber);
    let dayName = DAY_MAP.get(dayNumber);
    console.log("day is : " + dayName + " for str : " + str);
    return dayName;
  }

  return (
    <div className="hr-main-wrapper">
      {/* {executeScroll()} */}
      {/* {ShowAlert(alertMessage, showAlert)} */}
      {loaderState ? <Loader /> : <></>}
      {entryModalPopUp()}
      {/* {getModalForEditColumns()} */}
      <div className="pad-col">
      <div className="row m-0">

        <div className={"col-sm-12 hr-sub-wrap"}>
          <div className="hrMenuRight">
            {/* {getNavImageIconStyle()} */}
            {selectedTable === "timeSheet" ? (
              <>
                <Row className="border-bottom align-items-center" style={{height:"56px"}}>
                  <Col sm={4} style={{display:"flex",alignItems:"center"}}>
                    {/* <HRTabs
                      setRowData={setRowData}
                      setActionId={setActionId}
                      selectedTbl={selectedTbl}
                      setSelectedTbl={setSelectedTbl}
                    /> */}
                    {/* <div className="list-compact-view" style={{marginLeft:"55px"}}>
                      <div onClick={(e) => {
                        setSelectedTbl("listView")
                        setActionId("");
                      }} className="list-view" style={selectedTbl=="listView" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>List View</div>
                      <div onClick={(e) => {
                        setSelectedTbl("dayView")
                        setActionId("");
                      }} className="compact-view" style={selectedTbl=="dayView" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>Day View</div>
                    </div> */}
                  </Col>
                  {/* <Col sm={3} style={{ display:"flex",justifyContent:"center",height: '2rem' }}>
                    {selectedUser && selectedTbl=="dayView" && false? <span className="User-Name-Header-Span">{selectedUser?.full_name && selectedUser.full_name}</span> : <AntdRangePicker onChangeDateRange={onChangeDateRange} defaultStartDate={startDate} defaultEndDate={endDate} />}
                  </Col> */}
                  <Col sm={4} style={{display:"flex",justifyContent:"center",paddingRight:"0px" ,gap:"5px"}}
                  className="select-wrap-col"
                  >
                  {/* <Button
                    className="mr-3 request-btns"
                    onClick={() => {  
                      // setSelectedUserEmail(email);
                      setEditUsername('')
                      setModalPosition({ x: 0, y: -45 })
                      addPopupOpen("", "Add")
                    }}
                  >
                    Add Timesheet
                  </Button> */}
                    {/* {selectedTable=="timeSheet" && <div className="list-compact-view">
                      <div onClick={(e) => {setSelectedTbl("listView"); dispatch(hrActiveKey({tab: selectedTable, view: "listView"}))}} className="list-view" style={selectedTbl=="listView" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>List View</div>
                      <div onClick={(e) => {setSelectedTbl("dayView"); dispatch(hrActiveKey({tab: selectedTable, view: "dayView"}))}} className="compact-view" style={selectedTbl=="dayView" ? { background: "#018EE0", color: "#fff" } : { background: "#fff" }}>Day View</div>
                   </div>} */}
                    {/* <Select
                  
                    value={selectedUser ? {label : selectedUser.full_name, value: selectedUser.id} : {label : 'All Users', value: 'all'}}
                    options={userList}
                    placeholder="Select..."
                    closeMenuOnSelect={true}
                    onChange={(e) => { 
                      if (e.value == 'all') {
                        onChangeUserFilter({ full_name: e.label , id : e.value});
                      } else { 
                        let filterUser = allusers.filter(one =>  one.id == e.value);
                        if (filterUser.length > 0) {
                          onChangeUserFilter(filterUser[0]);
                        }
                      }
                    }}
                  // value={selectedUserEmail ? selectedUserEmail :""}
                  menuPlacement="auto"
                /> */}
                  </Col>
                  <Col sm={2}></Col>

                </Row>


                {selectedTbl=="listView" ? <div className="ag-theme-alpine">
                  <div className="example-wrapper">
                    {/* <div className="example-header">
                      <Row className="justify-content-center">
                        <Col sm={12} className="head-txtv text-center pt-0">
                          <strong>{selectedUser ? selectedUser.full_name : ""}</strong>
                        </Col>
                      </Row>
                    </div> */}
                    <div className="hr-time-table">
                      <div
                        id="myGrid"
                        className="ag-theme-alpine"
                      >    
                        <GlobalAgGridTable
                        // onSelectionChanged={onSelectionChanged}
                      //  onFirstDataRendered={onFirstDataRendered}
                        headers={activeHeaderData}
                        setRowData={setRowData}
                        cacheRowData={cacheRowData}
                        setCacheRowData={setCacheRowData}
                        module="TimeSheet"
                        mainUrl="attendance"
                        DropDownFields={["User Name", "Check In", "Check Out"]}
                        rowData={rowData}
                        renderExtraComponent={extraComponentForTable}
                        renderExtraComponentToRight={extraComponentToRight}
                      />
                      </div>
                    </div>
                  </div>
                  </div>:<DayView getViewType={getViewType} cacheRowData={cacheRowData} getSelectUserForDayView={getSelectUserForDayView} actionListCellRenderer={actionListCellRenderer} selectedUser={selectedUser} rowData={rowDataForDay} cacheData={cacheDataForDay} setRowData={setRowDataForDay} disableDates={disableDates}/>}
              </>
            ) : selectedTable === "request" ? (
              <RequestTable
                refreshEnable={props.refreshEnable}
                updateCacheDataAfterApproved={updateCacheDataAfterApproved}
              />
            ) : selectedTable === "where" ?

              <Where clockisActive={props.clockisActive} refreshEnable={props.refreshEnable} UserId={localStorage.getItem("UserId")} />
              : selectedTable === "whoIsIn" ?

              <WhoIsIn refreshEnable={props.refreshEnable} clockisActive={props.clockisActive} UserId={localStorage.getItem("UserId")} />

              : <ShiftMapping refreshEnable={props.refreshEnable} users={allusers} />

            }
          </div>
        </div>
      </div>
      </div>

    </div>
  );
}

export default HRTimeClock;