import React from "react";
import { Button, CardBody, Collapse, Card } from "reactstrap";

function BillingInfoButton(props) {
  
  return (
    <div>
      <Button
        className={
          ((props.layoutstate == "BillingDetails" || props.layoutstate == "ShippingDetails")
            ? "navbarbtn-active"
            : "navbarbtn-100")
        }
        style={(props.layoutstate == "BillingDetails" || props.layoutstate == "ShippingDetails")? 
        {color : "aliceblue", marginTop: "0px"} : {color : "#018ee0", marginTop: "0px"}}
        onClick={(e) => {
          if(props.location.state.action=="create" && !props.updatedSections.includes("BillingDetails")){
            e.preventDefault()
            return
          }else{
          localStorage.setItem('currentsidenavbar',"BillingDetails");
          props.setLayoutState("BillingDetails");
          } 
        }}
      >
        Address Information
      </Button>
      <Collapse isOpen={(props.layoutstate == "BillingDetails" || props.layoutstate == "ShippingDetails")}>
        <Card className="sidenav-card createdeal-card">
          <CardBody className="navbar-sublist-card">
            <div
              className={
                props.layoutstate == "BillingDetails"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              onClick={(e) => {
                props.setLayoutState("BillingDetails")
                localStorage.setItem('currentsidenavbar',"BillingDetails");
              }}
            >
              Billing Details
            </div>
            <div
              className={
                 props.layoutstate == "ShippingDetails"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              style={props.formInputsValue.ShippnigAddDifferent == false ? {
                color: "#fff",
                backgroundColor: "#6c757d",
                borderColor: "#6c757d",
                opacity: "0.6",
                pointerEvents: "none"
              } : {}}
              onClick={(e) =>{
                props.setLayoutState("ShippingDetails")
                localStorage.setItem('currentsidenavbar',"ShippingDetails");
            }}
            >
              Shipping Details
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default BillingInfoButton;
