import React, { useEffect, useState } from 'react';
import './CustomHeaderComponent.css';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomHeaderComponent = ({ column, api, filterStateToggel, updateSortingState, compactViewSortState }) => {

  const [sortDirection, setSortDirection] = useState('');
  const [isFilterActive, setIsFilterActive] = useState(false);

  const showFilterMenu = (e) => {
    api.showColumnMenuAfterButtonClick(column.getColId(), e.target);
  };

  useEffect(() => {
    if (column) {
      setIsFilterActive(column.isFilterActive());
    }
    if (compactViewSortState) {
      let thisColumnState = compactViewSortState.find(c => c.colId === column.getColId())
      if (thisColumnState) {
        setSortDirection(thisColumnState.sort)
      }
    }
  }, [filterStateToggel])

  const clearFilter = () => {
    api.destroyFilter(column.getColId()); // Clear filter for the specific column
    api.onFilterChanged();
  };

  const toggleSort = () => {
    const newSortDirection = getNextSortDirection(sortDirection);
    api.setSortModel([{ colId: column.getColId(), sort: newSortDirection }]);
    updateSortingState({ colId: column.getColId(), sort: newSortDirection });
    setSortDirection(newSortDirection);
  };

  const getNextSortDirection = (currentSortDirection) => {
    if (currentSortDirection === '') {
      return 'asc';
    } else if (currentSortDirection === 'asc') {
      return 'desc';
    } else {
      return ''
    }
  };

  return (
    <div className='mainContaner'>
      <div className='headerContainer'>
        <div className='d-flex' style={{ "overflow": "hidden", "width": "100%", "textAlign": "center", "justifyContent": "center" }} onClick={toggleSort}>
          <span style={{
            "width": "84%",
            "overflow": "hidden",
            "textOverflow": "ellipsis",
            "display": "block"
          }}>{column.getColDef().headerName}</span>

          {column.getColDef().sortable && (
            <div style={{ cursor: 'pointer' }}>
              {sortDirection === '' && ''}
              {sortDirection === 'desc' && <i className="ag-icon ag-icon-desc"></i>}
              {sortDirection === 'asc' && <i className="ag-icon ag-icon-asc"></i>}
            </div>
          )}
        </div>
        <div className='showMenu'>
          {
            column.getColDef().filter &&
            <div style={{ display: "flex" }}>
              <span className="ag-filter-icon" onClick={showFilterMenu}>
                <i className={`ag-icon ag-icon-menu showMenuicon`}></i>
              </span>
              <span>
                {
                  isFilterActive &&
                  <button onClick={clearFilter} className='clearBtn' style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                    {/* <i className="ag-icon ag-icon-cancel showClearMenuicon"></i> */}
                    <FontAwesomeIcon className='showClearMenuicon' icon={faTimesCircle} size="sm"/>
                  </button>
                }
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CustomHeaderComponent;
