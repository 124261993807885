import httpService from "../../services/http.service";

class PipelineService{
    GetAllPipeline() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/crm/v3/settings/pipeline?layout_id=4695950000000091023",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

    CreatePipeline(data) {
        return new Promise((resolve, reject) => {
           
            httpService.CreateUpdate("createPipeline", data)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    }

    UpdatePipeline(data,ID) {
        return new Promise((resolve, reject) => {
            httpService.CreateUpdate("updatePipeline", data)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

   

}

export default new PipelineService();