import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import * as serviceWorker from './serviceWorker';
import AppStore from './Context/AppStore';

ReactDOM.render(
  <AppStore>
  <React.StrictMode>
    <HashRouter  >
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  </React.StrictMode>
  </AppStore>,
  document.getElementById('root')
);
serviceWorker.register()

reportWebVitals();
