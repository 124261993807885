import React,{useEffect, useState} from 'react';
import  {  Divider, Input, Select, Space, Typography  } from 'antd';
import { color } from 'highcharts';
import './customeselect.css'

let index = 0;


const CustumSelect = ({placeholder,Color,value,onBlur,onChange,addItemLabel,items,addItemAction,addFlag,mode,onClick,disabled}) => {
    const { Option } = Select;
  const[color,setColor]=useState({});

  useEffect(()=>{
    if(Color=='-1'){
      setColor({ "transition": "width 0.4s ease-in-out",
      "border": "1px solid red",
      "box-shadow" : "rgb(242 13 13) 0px 0px 7px 1px","border-color":"#ff0000",});
    }else{
      setColor({});
    }
  },[onBlur])

  useEffect(()=>{
    if(Color=='-1'){
      setColor({ "transition": "width 0.4s ease-in-out",
      "border": "1px solid red",
      "box-shadow" : "rgb(242 13 13) 0px 0px 7px 1px","border-color":"#ff0000",});
    }else{
      setColor({});
    }
  },[Color])

  return (
    <Select
    mode={mode}
    disabled={disabled}
    onClick={onClick}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    showSearch
    style={{
        width:'100%',
        height:'33.8px',
        borderRadius:'5px',
        marginBottom:'2%',
        ...color
    }}
      placeholder={placeholder}
      dropdownRender={(menu) => (

        <>
          {menu}
          {addFlag==true?
          <>
           <Divider
           style={{
             margin: '8px 0',
           }}
         />
         <Space
           align="center"
           style={{
             padding: '0 8px 4px',
           }}
         >
          
           <Typography.Link
             onClick={addItemAction}
             style={{
               whiteSpace: 'nowrap',
             }}
           >
              {addItemLabel}
           </Typography.Link>
         </Space>
         </>
        
        :<></>}
         
        </>
      )}
    >
      {items&&items.map((item,key) => (
        <Option key={key} value={item} >{item}</Option>
      ))}
    </Select>
  );
};

export default CustumSelect;
