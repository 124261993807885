import React, { useEffect, useState } from 'react'
import { Popover as AntdPopover } from "antd";
import dayjs from 'dayjs';
import { Button, Col, Row } from 'reactstrap';

export default function CustomTimePicker({eventData,setEventData,idx}) {
    const [ Open, setOpen] = useState(false); 
    const [ time, setTime] = useState({hours:'0',minutes:'0'});
    useEffect(()=>{ 
        if(eventData?.action[idx].customTime){ 
            setOpen(true); 
        }else {
            setOpen(false);
        }
    },[eventData?.action])
    function timeToMinutes(time) { 
        return parseInt(time.hours) * 60 + parseInt(time.minutes);
    }
    const getTimePicker=(data,idx)=>{
        return ( <div className="Snooze-Content">
            <Row sm={12}>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                let data= eventData?.action?.map((option,index)=> {
                  if(idx==index){
                    let obj=  {...option,
                      NotificationTime: "10"
                    }
                    delete obj.customTime
                    return obj;
                  }
                  return option
                });
                delete eventData.action[idx]?.customTime
                setEventData({...eventData,"action":data}) 
                }} >10 min</Col>
                <Col className='Preset-Cards' sm={3}onClick={e=>{
                    let data= eventData?.action?.map((option,index)=> {
                      if(idx==index){
                        let obj=  {...option,
                          NotificationTime: "20"
                        }
                        delete obj.customTime
                        return obj;
                      }
                      return option
                    });
                    delete eventData.action[idx]?.customTime
                    setEventData({...eventData,"action":data}) 
                }}>20 min</Col>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                    let data= eventData?.action.map((option,index)=> {
                      if(idx==index){
                        let obj=  {...option,
                          NotificationTime: "30"
                        }
                        delete obj.customTime
                        return obj;
                      }
                      return option
                    });
                    delete eventData.action[idx]?.customTime
                    setEventData({...eventData,"action":data}) 
                }}>30 min</Col>
            </Row>
            <div className='Custome-Snooze-Time' style={{alignItems:'center'}}>H <input
                type="number"
                name='hours'
                className="form-control form-control-sm" 
                style={{ height: '2.2rem' }}
                onChange={(e) => {
                    if(e.target.value<0){
                        return
                    }
                   setTime({...time,hours:e.target.value}) 
                }}
                value={time.hours}
            />
            M <input
                type="number"
                name='minutes'
                className="form-control form-control-sm" 
                style={{ height: '2.2rem' }}
                onChange={(e) => {
                    if(e.target.value<0){
                        return
                    }
                    setTime({...time,minutes:e.target.value}) 
                }}
                value={time.minutes}
            />
                <Button style={{"height": "100%"}} className='btn btn-primary' onClick={e=>{
                    let timeToInsert= timeToMinutes(time)
                    let data= eventData?.action.map((option,index)=> {
                        if(idx==index){
                          let obj =  {...option,
                            NotificationTime: timeToInsert
                          }
                          delete obj.customTime
                          return obj;
                        }
                        return option
                      }); 
                      delete eventData.action[idx]?.customTime
                      setEventData({...eventData,"action":data})
                }}>Set</Button>
            </div>
        </div>)
    }
  return (
    <div><AntdPopover
    open={Open}
    overlayClassName={`Snooze-Popover Do-Not-Close `}
    style={{ zIndex: 9999, "width": "max-content" }}
    trigger="click"
    placement="bottomLeft"
    content={() => getTimePicker(eventData.action[idx],idx)}
    onOpenChange={e => {

        // setOpenSnooze({ id: message?.ROWID + "TT", open: true });
    }}
    arrow={true}
/> </div>
  )
}