import axios from 'axios';
import swal from 'sweetalert';
import Config from '../config.json'

const axiosinstance=axios.create({
    // baseURL: "http://localhost:4000/api/v1/",
    // baseURL: "https://crm.srsolardesign.com:3006/api/v1/",
    baseURL: Config.backend_url,
    headers: {
        'Content-Type': 'application/json',
        "Role": localStorage.getItem('Role'),
        "UserId": localStorage.getItem('UserId'),
        "RoleId": localStorage.getItem('RoleId')
    }
})

axiosinstance.interceptors.response.use(null, async error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500;
    if (expectedError) {
        if (error.response.status == 401) {
           // swal(error.response.data.error, { dangerMode: true });

        }
    }
    console.log(expectedError);
    if (!expectedError) {
        // console.log("logging the error", error);
        // alert("An unexpected error occurred.");
       // job(`${error}`)
        //logger.log(error);
        //toast.error("An unexpected error occurred.");


    }
    return Promise.reject(error);
});
export default axiosinstance;


