import React, { useState, useEffect} from 'react';
import {
    Collapse,
    Button,
    CardBody,
    Card,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
// import { useHistory, withRouter,useLocation } from 'react-router-dom';
import HttpService from '../../../../../../services/http.service';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faAlignRight, faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faCaretDown, faEnvelope, faTasks, faTimes } from '@fortawesome/free-solid-svg-icons';
import handleCreate from '../../../../../../common/util/createRecord';
import ReactInputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const formInputs = 
    {
       GeneralElectricianName:"",
      companyName: "",
      firstName: "",
      //middleInitial: "",
      lastName: "",
      phoneNumber: "",
      faxNumber: "",
      streetAdd: "",
      city: "",
      state: "",
      zipCode: "",
      emailAdd: "",
      elecFirmNumber: "",
      elecLicenseNumber: "",
      expirationDate: "",
    }
    

export default function ElectricModal(props) {
    
    const [formInputsValue, setFormInputsValue] = useState(formInputs);
    const [inverters, setInverters] = useState([]);
    const [phoneError, setPhoneError] = useState(false);
    const [faxError, setFaxError] = useState(false);
    // const location = useLocation();
    const [detail, setDetail] = useState([]);
    const [func, setFunc] = useState("create");
    // const history = useHistory();

    let valid=(emailtext)=>{
      for(let i=1;i<emailtext.length;i++){
        if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
          return false;
        }
      }
      return true;
    }
    const handleChange=(e)=>{
      if(e.currentTarget.name=='emailAdd'){
        // Email validation
      let validMail=false;
      let str=e.target.value.toString()
      const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
      const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
      if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){          if(!regforSpec.test(str)){
              if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
                validMail=true;
              }
          }
      }
      // use validMail(true/false) for valoidation
        if ( validMail ) {
          setValidation({...validation,emailAdd:"1"})
          setMailInvalid(false);
        }
      } 
        
        setFormInputsValue({
            ...formInputsValue,
            [e.target.name] : e.target.value
        })
    }

    const changeIntoDecimal = (e)=>{
      if(e.target.value!=""){
        setFormInputsValue({
          ...formInputsValue,
          [e.target.name] : parseFloat(e.target.value).toFixed(2)
      })
      }
    }

    const changeBuiltInAc=(e)=>{
        if(e.target.value === "true") {
            setFormInputsValue({
                ...formInputsValue, 
                mppVoltage: "N/A",
                mppCurrent: "N/A", 
                openCircuitVolt: "N/A",
                builtinAC: "true",
                associatedmicroType:""
            });
        } else {
            setFormInputsValue({
                ...formInputsValue,
                mppVoltage: "",
                mppCurrent: "", 
                openCircuitVolt: "",
                builtinAC: "false"
                
            });
        }
        
    }
    
//     useEffect(()=>{
//       if(location.state.action!='create') {
//         setDetail(location.state.detail);
//         setFormInputsValue({
//           ...formInputsValue,
//           _id: location.state.detail._id,
//           companyName: location.state.detail.companyName,
//           firstName: location.state.detail.firstName,
//           middleInitial: location.state.detail.middleInitial,
//           lastName: location.state.detail.lastName,
//           phoneNumber: location.state.detail.phoneNumber,
//           faxNumber:location.state.detail.faxNumber,
//           streetAdd: location.state.detail.streetAdd,
//           city: location.state.detail.city,
//           state: location.state.detail.state,
//           zipCode: location.state.detail.zipCode,
//           emailAdd: location.state.detail.emailAdd,
//           elecFirmNumber: location.state.detail.elecFirmNumber,
//           elecLicenseNumber: location.state.detail.elecLicenseNumber,
//           expirationDate: location.state.detail.expirationDate,
//       })
//       setFunc("update")
//       }else{
//         setFunc("create")
//       }
//   }, [location])


    const changePositiveValue=(e)=>{
       if(e.target.value<0){
           e.target.value= e.target.value* -1;
       } 
    }

    const isAllMandatoriesValid= () =>{
         
        let flag = true;
         
        let vKeys=Object.keys(validation);
        let ob={};
        for(let i=0;i<vKeys.length;i++){
          if(formInputsValue[vKeys[i]]=='' || formInputsValue[vKeys[i]]==undefined ||mailInvalid ||faxError|| phoneError|| zipValid==false){
            flag=false;
            break
          }
        }

        return flag;
      }
    
      const saveElectrician =()=>{
        let isvalidData = isAllMandatoriesValid();
        if(isvalidData){
            createElectricianZoho();
        }
        else{
            highliteEmptys()
            alert("Mandatory fields are missing");
        }
      }
      

      const createElectricianZoho = async () => {
        let obj = {
            "Name": formInputsValue.GeneralElectricianName,
            "Company_Name": formInputsValue.companyName,
            "Street_Name": formInputsValue.streetAdd,
            "Fax_Number": formInputsValue.faxNumber,
            "Email_Address": formInputsValue.emailAdd,
            "Phone_Number": formInputsValue.phoneNumber,
            "State": formInputsValue.state,
           
            "Expiration_Date": formInputsValue.expirationDate,
        }
        
        
        await handleCreate("Electricians",obj)
            .then(function (data) {
                data.data=data.data.data;
                props.handleOnChangeDeal({ currentTarget: { name: 'Electrician', value:{name:formInputsValue.GeneralElectricianName,id:data.data[0].details.id,module:"Electricians"} } })
                props.setElectrician(formInputsValue.companyName)
                props.getElectricians();
                props.electricianToggle();
                resetValidations();
            })
            .catch((err)=>{
                console.log(err);
               alert(err);
            });
    }

    const changeDate = (date, name) => {
      // props.setStartDate(date)
      // const today = Date.now();
      if(date==null
        ||date==undefined
        ||date==''){
        setValidation({ ...validation, expirationDate: "-1" });
      } else {
        setValidation({ ...validation, expirationDate: "1" });
      }
      const formattedDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)
      // props.setProjectDate(formattedDate)
      setFormInputsValue({
        ...formInputsValue,
        [name] : date
    })
  
    }

    
    const addElectrician = async () => {
      let returnData = await HttpService.CreateUpdate('addelectrician', formInputsValue).then(res =>  {
        if(res.data === "Added Successfully"){
            props.handleOnChangeDeal({ currentTarget: { name: 'electrician', value: formInputsValue.companyName } })
            props.setElectrician(formInputsValue.companyName)
          //history.push('/licensedElectrician/list');
          props.electricianToggle();
          props.getElectricians();
        }
        else {
          alert("Server Issue: Unable to add PV module.");
        }
       
      });
  
  }

  const checkNumbers=(e)=>{
    if(e.target.name=='phoneNumber' || e.target.name=='mobileNumber'){       
        let value= e.target.value=e.target.value.trimStart();    
        var value1= value.replace(/[-#*$+/?=%() ]/g,'')
        let onlyNo= value1.replace(/\D/g, '');
        if(onlyNo.length>10){
            return
        }
        setFormInputsValue({
             ...formInputsValue,
             [e.target.name]: value
        })

    }else{
        setFormInputsValue({
            ...formInputsValue,
            [e.target.name]: e.target.value
     })
    }
  }

  const validatePhoneNmuber = (e) => {
    console.log("line 124 :  " + formInputsValue.phoneNumber.length);
    var value1= formInputsValue.phoneNumber?.replace(/[-#*$+/?=%() ]/g,'')
    let onlyNo= value1.replace(/\D/g, '');
    if (onlyNo?.length < 10) {
        setValidation({...validation,phoneNumber:'-1'})
        setPhoneError(true);
    } else {
        setValidation({...validation,phoneNumber:'1'})
        setPhoneError(false);
    }
}

  const validateFaxNumber=(e)=>{
    if (formInputsValue.faxNumber.length < 9 && 
      formInputsValue.faxNumber.length != 0) {
      setFaxError(true);
  } else {
      setFaxError(false);
  }
  }

  // hrushikesh shelke new code
  const [validation,setValidation] = useState({
    GeneralElectricianName:"0",
    companyName:'0',
    firstName:'0',
    // middleInitial:'0',
    lastName:'0',
    phoneNumber:'0',
    streetAdd:'0',
    city:'0',
    zipCode:'0',
    state:'0',
    emailAdd:'0',
    elecFirmNumber:'0',
    elecLicenseNumber:'0',
    expirationDate:'0',
    faxNumber:'0'
})
const resetValidations=(data)=>{
 
    let vKeys=Object.keys(validation);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      ob[vKeys[i]]='0'
    }
   setFormInputsValue(formInputs);
   setValidation(ob)
  }
  const highliteEmptys=()=>{
     
    let vKeys=Object.keys(validation);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(formInputsValue[vKeys[i]]=='' || formInputsValue[vKeys[i]]==undefined){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
      if(vKeys[i]=='zipCode'&&zipValid==false){
        ob={...ob,zipCode:'-1'}
      }
    }
    setValidation(ob)
  }
const [mailInvalid, setMailInvalid] = useState(false);
let handlemailid = (email) => {
    // Email validation
    let validMail=false;
    let str=email.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
    if (validMail) {
      setMailInvalid(false);
      setValidation({...validation,emailAdd:'1'})
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.
    } else {
      setValidation({...validation,emailAdd:'-1'})
      if (email.length == 0) {
        setMailInvalid(false);
      } else {
        setMailInvalid(true);
      }
    }
  };
const[zipValid,setZipValid]=useState(true);
const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }

//end

  function removeFormatter(phoneNumber) {
    if(phoneNumber.length !== 10) {
      if(phoneNumber.includes("(")) {
        phoneNumber = phoneNumber.replace("(","");
        phoneNumber = phoneNumber.replace("-","");
        phoneNumber = phoneNumber.replace(")","");
      }
      
    }
    return phoneNumber;
  }

    return (
      <div>
        <Modal
          isOpen={props.electricianmodal}
          toggle={props.electricianToggle}
          backdrop={props.backdrop}
          keyboard={props.keyboard}
        >
          <div className="modal-header-class">
            Electrician Information
            <div className="note-header-icon">
              {" "}
              <FontAwesomeIcon
                icon={faTimes}
                className="icon-time-color"
                onClick={() => {
                  props.electricianToggle();
                  setPhoneError(false)
                  setZipValid(true);
                  setMailInvalid(false);
                  resetValidations();
                }}
              ></FontAwesomeIcon>
            </div>
          </div>
          <ModalBody className="h500-overflow">
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    General Electrician Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="GeneralElectricianName"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.GeneralElectricianName}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      handleChange(e);
                    }}
                    style={
                      validation.GeneralElectricianName == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.GeneralElectricianName?.trim().length ==
                          0 ||
                        formInputsValue.GeneralElectricianName == undefined
                      ) {
                        setValidation({
                          ...validation,
                          GeneralElectricianName: "-1",
                        });
                      } else {
                        setValidation({
                          ...validation,
                          GeneralElectricianName: "1",
                        });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Company Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="companyName"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.companyName}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      handleChange(e);
                    }}
                    style={
                      validation.companyName == "-1" ? { ...validationCss } : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.companyName?.trim().length == 0 ||
                        formInputsValue.companyName == undefined
                      ) {
                        setValidation({ ...validation, companyName: "-1" });
                      } else {
                        setValidation({ ...validation, companyName: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    First Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="firstName"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.firstName}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                      e.target.value =
                        e.target.value
                          .replace(/[^\D]/g, "")
                          .charAt(0)
                          .toUpperCase() +
                        e.target.value.replace(/[^\D]/g, "").slice(1);
                      handleChange(e);
                    }}
                    style={
                      validation.firstName == "-1" ? { ...validationCss } : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.firstName?.trim().length == 0 ||
                        formInputsValue.firstName == undefined
                      ) {
                        setValidation({ ...validation, firstName: "-1" });
                      } else {
                        setValidation({ ...validation, firstName: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Middle Initial
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="middleInitial"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.middleInitial}
                    maxLength={1}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      e.target.value = e.target.value
                        .replace(/[^A-Za-z]/g, "")
                        .toUpperCase();
                      handleChange(e);
                    }}
                    // style={validation.middleInitial == "-1"? { ...validationCss }:{}}
                    onBlur={() => {
                      // if (
                      //   formInputsValue.middleInitial?.trim().length == 0 ||
                      //   formInputsValue.middleInitial == undefined
                      // ) {
                      //   setValidation({ ...validation, middleInitial: "-1" });
                      // } else {
                      //   setValidation({ ...validation, middleInitial: "1" });
                      // }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Last Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="lastName"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.lastName}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                      e.target.value =
                        e.target.value
                          .replace(/[^\D]/g, "")
                          .charAt(0)
                          .toUpperCase() +
                        e.target.value.replace(/[^\D]/g, "").slice(1);
                      handleChange(e);
                    }}
                    style={
                      validation.lastName == "-1" ? { ...validationCss } : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.lastName?.trim().length == 0 ||
                        formInputsValue.lastName == undefined
                      ) {
                        setValidation({ ...validation, lastName: "-1" });
                      } else {
                        setValidation({ ...validation, lastName: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="electricianphoneno">
                    Phone Number<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <ReactInputMask
                    type="text"
                    mask="(999) 999-9999"
                    maskChar="_"
                    className="form-control createdeal-textfield"
                    name="phoneNumber"
                    id="ownerlastname"
                    value={formInputsValue.phoneNumber}
                    style={
                      phoneError || validation.phoneNumber == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    onChange={(e) => checkNumbers(e)}
                    onBlur={(e) => validatePhoneNmuber(e)}
                  />
                </Col>

                <Col sm={1}></Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <span
                    className={
                      phoneError == true || validation.phoneNumber == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    Enter 10 digit Phone Number
                  </span>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="elecfaxnumber">
                    Fax Number<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="faxNumber"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.faxNumber}
                    style={
                      faxError || validation.faxNumber == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                      if (e.target.value.length > 9) {
                        e.preventDefault();
                        return;
                      }
                      checkNumbers(e);
                    }}
                    onBlur={(e) => {
                      if (
                        formInputsValue.faxNumber?.trim().length == 0 ||
                        formInputsValue.faxNumber == undefined
                      ) {
                        setValidation({ ...validation, faxNumber: "-1" });
                      } else {
                        setValidation({ ...validation, faxNumber: "1" });
                      }
                      validateFaxNumber(e);
                    }}
                    maxLength="9"
                  />
                </Col>

                <Col sm={1}></Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <span
                    className={
                      faxError == true || validation.faxNumber == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    Enter 9 digit fax Number
                  </span>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Street Address<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="streetAdd"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.streetAdd}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();
                      handleChange(e);
                    }}
                    style={
                      validation.streetAdd == "-1" ? { ...validationCss } : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.streetAdd?.trim().length == 0 ||
                        formInputsValue.streetAdd == undefined
                      ) {
                        setValidation({ ...validation, streetAdd: "-1" });
                      } else {
                        setValidation({ ...validation, streetAdd: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    City<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="city"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.city}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart().replace(/[0-9]/,'');
                      e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                      handleChange(e);
                    }}
                    style={validation.city == "-1" ? { ...validationCss } : {}}
                    onBlur={() => {
                      if (
                        formInputsValue.city?.trim().length == 0 ||
                        formInputsValue.city == undefined
                      ) {
                        setValidation({ ...validation, city: "-1" });
                      } else {
                        setValidation({ ...validation, city: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    State<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="state"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.state}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart().replace(/[0-9]/,'');
                      e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                      handleChange(e);
                    }}
                    style={validation.state == "-1" ? { ...validationCss } : {}}
                    onBlur={() => {
                      if (
                        formInputsValue.state?.trim().length == 0 ||
                        formInputsValue.state == undefined
                      ) {
                        setValidation({ ...validation, state: "-1" });
                      } else {
                        setValidation({ ...validation, state: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Zip Code<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="zipCode"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.zipCode}
                    style={
                      validation.zipCode == "-1" ? { ...validationCss } : {}
                    }
                    maxLength={5}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");

                      if (e.target.value.length == 5) {
                        setValidation({ ...validation, zipCode: "1" });
                        setZipValid(true);
                      }
                      if (e.target.value.length < 5) {
                        setValidation({ ...validation, zipCode: "-1" });
                      }
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      if (e.target.value == undefined || e.target.value == "") {
                        setValidation({ ...validation, zipCode: "-1" });
                      }
                      if (
                        formInputsValue.zipCode.length < 5 &&
                        formInputsValue.zipCode != undefined &&
                        formInputsValue.zipCode?.length != 0
                      ) {
                        setZipValid(false);
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <span
                    className={
                      validation.zipCode=='-1'? "red-text font12" : "error-display"
                    }
                  >
                    5 Digit Zip code required.
                  </span>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="electricianemail">
                    Email Address<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="emailAdd"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.emailAdd}
                    onChange={(e) =>{
                      e.target.value=e.target.value.trimStart()
                      handleChange(e)}}
                    style={
                      mailInvalid || validation.emailAdd == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    onBlur={(e) => handlemailid(e.target.value)}
                  />
                </Col>

                <Col sm={1}></Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <span
                    className={
                      mailInvalid == true || validation.emailAdd == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    Incorrect E-mail Address format.
                  </span>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Electrical Firm Number<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="elecFirmNumber"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.elecFirmNumber}
                    onChange={(e) =>{
                      e.target.value=e.target.value.trimStart()
                     //e.target.value=e.target.value.replace(/[^a-zA-Z0-9]/,'') 
                      handleChange(e)}}
                    style={
                      validation.elecFirmNumber == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.elecFirmNumber?.trim().length == 0 ||
                        formInputsValue.elecFirmNumber == undefined
                      ) {
                        setValidation({ ...validation, elecFirmNumber: "-1" });
                      } else {
                        setValidation({ ...validation, elecFirmNumber: "1" });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Electrical License Number<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                  <Input
                    type="text"
                    name="elecLicenseNumber"
                    id="ownerlastname"
                    className="createdeal-textfield"
                    value={formInputsValue.elecLicenseNumber}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[\D]/, "");
                      handleChange(e);
                    }}
                    style={
                      validation.elecLicenseNumber == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.elecLicenseNumber?.trim().length == 0 ||
                        formInputsValue.elecLicenseNumber == undefined
                      ) {
                        setValidation({
                          ...validation,
                          elecLicenseNumber: "-1",
                        });
                      } else {
                        setValidation({
                          ...validation,
                          elecLicenseNumber: "1",
                        });
                      }
                    }}
                  />
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>

            <FormGroup row className="marb-16  padtop-15">
              <Row>
                {" "}
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Label className="text-bold" for="ownerlastname">
                    Expiration Date<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={5}>
                <DatePicker
                    className={(validation.expirationDate == '0' || validation.expirationDate == '1') ? "valid-date" : "invalid-date"}
                    name="expirationDate"
                    placeholderText='mm/dd/yyyy'
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    selected={
                      formInputsValue.expirationDate ? formInputsValue.expirationDate : ''
                    }
                    // id="ProjectDate"
                    onChange={(e) => changeDate(e, "expirationDate")}
                    dateFormat="MM/dd/yyyy"
                    // style={
                    //   validation.expirationDate == "-1"
                    //     ? { ...validationCss }
                    //     : {}
                    // }
                    onBlur={(e) => {
                      if(e.currentTarget.value==null
                        ||e.currentTarget.value==undefined
                        ||e.currentTarget.value==''){
                        setValidation({ ...validation, expirationDate: "-1" });
                      } else {
                        setValidation({ ...validation, expirationDate: "1" });
                      }
                    }}
                  />
                  {/* <Input
                    type="date"
                    name="expirationDate"
                    className="create-user-input"
                    value={formInputsValue.expirationDate}
                    onChange={(e) => handleChange(e)}
                    style={
                      validation.expirationDate == "-1"
                        ? { ...validationCss }
                        : {}
                    }
                    onBlur={() => {
                      if (
                        formInputsValue.expirationDate?.trim().length == 0 ||
                        formInputsValue.expirationDate == undefined
                      ) {
                        setValidation({ ...validation, expirationDate: "-1" });
                      } else {
                        setValidation({ ...validation, expirationDate: "1" });
                      }
                    }}
                  /> */}
                  {/* <Input
                  type="date"
                  name="date"
                  id="projectdate"
                  defaultValue={date}
                /> */}
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>

            <FormGroup className="marb-16 padtop-15">
              <Row>
                <Col sm={1}></Col>
                <Col sm={5}>
                  <Button
                    className="continue-button"
                    onClick={() => {
                      setZipValid(true);
                      setMailInvalid(false);
                      setPhoneError(false)
                      resetValidations();
                      props.electricianToggle();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col sm={5}>
                  <Button
                    className="continue-button"
                    onClick={() => saveElectrician()}
                  >
                    Save
                  </Button>
                </Col>
                <Col sm={1}></Col>
              </Row>
            </FormGroup>
          </ModalBody>
        </Modal>
      </div>
    );
}