import React, { useContext, useEffect, useState } from 'react';
import {Modal as AntDModal} from 'antd';
import { Button, Col, Row } from 'reactstrap';
import "./AssociateMail.css"; 
import "./../../components/Deal/AgGridReactComponent/GlobalTable.css"; 
import EmailService from '../../common/util/EmailService';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import GlobalTaskFilter from '../../common/task_filters/GlobalTaskFilter';
import AppContext from '../../Context/AppContext';
import ModuleService from '../../common/util/ModuleService';
import _ from "lodash";

export default function AssociateMail(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [cacheData, setCacheData] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [autoTaggEnable, setAutoTaggEnable] = useState(false);
  const [searchValue2, setSearchValue2] = useState('');
  const [selectedItoms, setSelectedItoms] = useState({});
  const [cacheSelectedItoms, setCacheSelectedItoms] = useState({});
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [preAppliedFiltersForView, setpreAppliedFiltersForView] = useState([]);
  const [sColFilDeleted, setIsColFilDeleted] = useState(false);
  const [preAppliedFiltersFor, setappliedFiltersForView] = useState([]);
  const [activeFiltersOnColumnLevel, setActiveFiltersOnColumnLevel] = useState([]);
  const [filterTrigger, setFilterTrigger] = useState(true);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [voilationData, setVoilationData] = useState([]);
  const [prevSolarData, setPrevSolarData] = useState([]);
  const [flag, setFlag] = useState(true) 
  useEffect(e=>{
    ModuleService.GetModuleListData("WWP_Violations").then(res=>{ 
      setVoilationData(res);
    }).catch(e=>{
      console.log(e);
    })
    ModuleService.GetModuleListData("Previous_Solar_Info").then(res=>{ 
      setPrevSolarData(res);
    }).catch(e=>{
      console.log(e);
    })
  },[])
  
  function removeDuplicates(filteredData) {
    const seenNames = new Set();
    const uniqueData = filteredData.filter(item => {
      if (seenNames.has(item.name)) {
        return false;
      } else {
        seenNames.add(item.name);
        return true;
      }
    });
    return uniqueData;
  }

  function removeDuplicatesforSelected(filteredData) {
    const seenNames = new Set();
    const uniqueData = filteredData.filter(item => {
      if (seenNames.has(item)) {
        return false;
      } else {
        seenNames.add(item);
        return true;
      }
    });
    return uniqueData;
  }

  useEffect(()=>{
      let dataa=[...rowData];
      let Name= props.tabs[activeTab].name  
      let arr=[]
      if(Name=="Deals"){
        let deals=dataa; 
       deals.forEach((one,index)=>{ 
          arr.push({name:one.Deal_Name,id:one.id,color:one.Color?one.Color:"#20adff",TagExtraItem:{
            Accounts:one?.Client?.name,
            Owner:one?.Owner?.name
          }}) 
       })
      }
      else if(Name=="Accounts"){
        let accounts=dataa;  
        accounts.forEach((one,index)=>{ 
          if(one?.Account_Name){
            let isDeal=state?.AllDeals?.find(r=>r?.Account_Name?.id==one.id);
            if(isDeal){
              arr.push({name:one.Account_Name,id:one.id,color:one.Color? one.Color  :"#20adff",TagExtraItem:{
                Deals:isDeal?.Deal_Name
              }}) 
            }else{
              arr.push({name:one.Account_Name,id:one.id,color:one.Color? one.Color  :"#20adff"}) 
            }
          }
       })
      }
      else if(Name=="Application_Number"){
        let accounts=dataa;  
        accounts.forEach((one,index)=>{ 
          if(one?.Application_Number?.trim()){
            if(one?.Deal_Name?.name){
              arr.push({name:one.Application_Number,id:one.id,color:one.Color? one.Color  :"#20adff",TagExtraItem:{
                Deals:one.Deal_Name.name
              }}) 
            }else{
              arr.push({name:one.Application_Number,id:one.id,color:one.Color? one.Color  :"#20adff"}) 
            }
          }
       })
      }
      else if(Name=="Violation_Number"){
        let accounts=dataa;  
        accounts.forEach((one,index)=>{ 
        let number=one?.Violation_Number?.trim();
        let data= accounts.filter(e=>e?.Violation_Number==number);
        let deals= data?.map(e=>e.Deal_Name?.name);
          if(one?.Violation_Number?.trim()){
            if(one?.Deal_Name?.name){
              arr.push({name:one.Violation_Number,id:one.id,color:one.Color? one.Color  :"#20adff",TagExtraItem:{
                Deals:deals
              }}) 
            }else{
              arr.push({name:one.Violation_Number,id:one.id,color:one.Color? one.Color  :"#20adff"}) 
            }
          }
       })
      } 
      else if(Name=="Owner"){
        let accounts=dataa;  
       accounts.forEach((one,index)=>{ 
          if(one?.Owner?.name){
            arr.push({name:one?.Owner?.name,id:one?.Owner?.id,color:one.Color? one.Color  :"#20adff" ,TagExtraItem:{
              Deals: one.Deal_Name
            }}) 
          }
       })
      }else if(Name=="Property_Owner's_Name"){
        // let accounts=state?.AllDeals;  
        let accounts=dataa;  
       accounts.forEach((one,index)=>{ 
          if(one?.Property_Owner_s_Name){
            arr.push({name:one?.Property_Owner_s_Name,id:one?.id,color:one.Color? one.Color  :"#20adff" ,TagExtraItem:{
              Deals: one.Deal_Name
            }}) 
          }
       })
      }else if(Name=="Must be filed in Conjunction"){
        // let accounts=state?.AllDeals;  
        let accounts=dataa;  
       accounts.forEach((one,index)=>{  
          if(one?.Conjunction=='Yes' && one?.Conjunction_Application_Number){
            arr.push({name:one?.Conjunction_Application_Number,id:one?.id,color:one.Color? one.Color  :"#20adff" ,TagExtraItem:{
              Deals: one.Deal_Name
            }}) 
          }
       })
      }
      let filteredData=arr.filter(one=>!checkIfPresent(selectedItoms[props.tabs[activeTab].name],one.name));
      let uniqueData = removeDuplicates(filteredData);
      setData(uniqueData);
      setCacheData(uniqueData);
    },[rowData])
  const setActiveFilterAndGetActiveFilters=()=>{
  }

  let hideArray=[
    "Application_Number", 
    "L2_Code",
    "Civil_Penalty_Payment_Required",
    "Paid_Under_Job_Number",
    "Invoice_number",
    // "Interconnection_Number",
    "System_Size_KW1",
    "Number_Of_Modules",
    // "PV_Module_Model",
    "Invertor_Type",
    "Number_of_Invertors",
    "Invertor_Model_Number",
    "Current_Application_Status",
    "Inspection_Passed_Date",
    "Withdrawal_Required",
    'Installation_Completed',
    "Inspection_Ready_Email_Address",
    "Violatio_Number",
    "Violation_Type",
    "Application_Supersede_Required",
    'Current_Applicant',
    "GC_Supersede_Required",
    "GC_Company_Name",
    "GC_Name",
    "GC_License_Number",
    'Reinstatement_Required',
    "Inspection_Passed","Last_Activity_Time",
    "Tag",
    "Lead_Conversion_Time",
    "NYC_Project",
    "Sales_Cycle_Duration",
    "Overall_Sales_Duration",
    "Locked__s",
    "Certification",
    // "Conjunction_Application_Number",
    "Stage",
    "Reason_For_Loss__s",
    "Amount",
    "Expected_Revenue",
    "Type",
    "Next_Step",
    "Probability",
    "Lead_Source",
    "Contact_Name",
    "Campaign_Source",
    "Client",
    "Project_Date",
    "Partial",
    "Change_Log_Time__s",
    "Description",
    "Record_Image",
    "Block",
    "Address",
    "Total_Interconnections",
    "Project_Status_ID",
    "Service_Requested",
    "Borough_Number",
    "Currency",
    "Exchange_Rate",
    // "Previous_Solar_Application",
    "Violation_Information",
    "Tax_Abatement",
    "Electrical_Diagram",
    "Asbestos_Investigation",
    "Pipeline",
    "Asbestos",
    "zohoworkdriveforcrm__Workdrive_Folder_URL",
    "zohoworkdriveforcrm__Workdrive_Folder_ID",
    "Number",
    "Story_Number",
    "Notes_ID",
    "Project_Status_ID",
    "Deal_Status",
    "Project_ID",
    "Structural_Letter",
    "Service_Requested_ID",
  // "Non_Profit",
  "id",
  "zohocontracts__Contracts_Counterparty_ID",
  "Annual_Revenue",
  "Tier_Number",
  "Contract_Price",
  "Employees",
  "Ticker_Symbol",
  "Contract_Price",
  "Rating",
  ]
  useEffect(e=>{
  if(props.dataForSearch.length>0){
   let arr= Object.keys(props.dataForSearch[0]).filter(e=>!hideArray.includes(e)&&!e.includes('$'));
   let arr2=[];
   arr.map((e,index)=>{
   let type= typeof props.dataForSearch[0][e];
   type=(type=='string'||type=='object'||type=='boolean'||type=='number')&&e!='Closing_Date'?'text':'datetime'; 
    arr2.push({"pinned":true,
    "path": e,
    "label": e?.replaceAll("_",' '),
    "field": e,
    "name": e?.replaceAll("_",' '),
    "type":type,
    "id" : index,
    "isChecked" : false,
    "isDragOver" : false,
    "isSearch" : true})
  })
   setColumns(arr2);
  }
  },[props.dataForSearch])
  useEffect(e=>{
    
    if(props?.isOpen?.status && props?.isOpen?.email?.messageId && props?.allAssociatedEmails){
      
      let tempData=props.allAssociatedEmails.find(d=>d.messageId===props.isOpen.email.messageId);
      if(tempData && tempData.AssociationObj){
        setSelectedItoms(tempData.AssociationObj)
        setCacheSelectedItoms(tempData.AssociationObj)
      }else if(props.selectedItems && !props.isOpen.context){
        setSelectedItoms(props.selectedItems)
        setCacheSelectedItoms(props.selectedItems)
      }else{
        setSelectedItoms({})
        setCacheSelectedItoms({})
      }
    }else{
      setSelectedItoms({})
      setCacheSelectedItoms({})
    }
  },[props.isOpen])

  function resetFields(){
    setSelectedItoms({})
    setCacheSelectedItoms({})
    setSearchValue2('')
    setSearchValue('')
  }  
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  function handleChangeInput(value){
    setSearchValue(value);
  }
  function handleChangeInput2(value){
    setSearchValue2(value);
  }
  useEffect(()=>{
   props.setActiveTab(props.tabs[activeTab]);
   setSearchValue2('')
   setSearchValue('')
  },[activeTab])

  useEffect(e=>{
    if(props.selectedItems && Object.keys(props?.selectedItems)?.length>0){
      let uniqueData = removeDuplicates(Object.values(props.selectedItems));
      setSelectedItoms(uniqueData)
    }
  },[props.selectedItems, flag])

  // useEffect(() => {
  //   if (selectedItoms && Object.keys(selectedItoms)?.length > 0) {
  //     const updatedSelectedItoms = { ...selectedItoms };

  //     props.tabs.forEach((tab) => {
  //       const tabName = tab.name;
  //       if (updatedSelectedItoms[tabName] !== undefined) {
  //         const uniqueData = removeDuplicatesforSelected(updatedSelectedItoms[tabName]);
  //         updatedSelectedItoms[tabName] = uniqueData;
  //       }
  //     });

  //     setSelectedItoms(updatedSelectedItoms);
  //   }
  // }, [flag, activeTab]);

  useEffect(()=>{
    let filteredData=props.data.filter(one=>!checkIfPresent(selectedItoms[props.tabs[activeTab].name],one.name));
    let uniqueData = removeDuplicates(filteredData);
    setData(uniqueData);
    setCacheData(uniqueData);
  },[props.data, activeTab])

  useEffect(()=>{
    let filteredData=[];
    if(preAppliedFiltersFor[0]?.phase=="initial"||preAppliedFiltersFor.length==0){
      filteredData=props.data.filter(one=>!checkIfPresent(selectedItoms[props.tabs[activeTab].name],one.name));
    }else{
      filteredData=cacheData?.filter(one=>!checkIfPresent(selectedItoms[props.tabs[activeTab].name],one.name));
    }    
    let uniqueData = removeDuplicates(filteredData);
    setData(uniqueData); 
  },[cacheSelectedItoms])
  
  useEffect(() => {
    if (searchValue == '') {
      let tempd =[]
      if(preAppliedFiltersFor[0]?.phase=="initial"||preAppliedFiltersFor.length==0){
        tempd=props.data.filter(one=>!checkIfPresent(cacheSelectedItoms[props.tabs[activeTab].name],one.name));
      }else{
        tempd = cacheData?.filter((one) => !checkIfPresent(cacheSelectedItoms[props.tabs[activeTab].name], one.name))
      }
      let uniquedata = removeDuplicates(tempd);
      setData(uniquedata)
      return
    }
    let filteredData=[];
    if(preAppliedFiltersFor[0]?.phase=="initial"||preAppliedFiltersFor.length==0){
     filteredData = props.data?.filter((one) => one.name?.toLowerCase().includes(searchValue?.toLowerCase()) && !checkIfPresent(cacheSelectedItoms[props.tabs[activeTab].name], one.name))
    }else{
      filteredData = cacheData?.filter((one) => one.name?.toLowerCase().includes(searchValue?.toLowerCase()) && !checkIfPresent(cacheSelectedItoms[props.tabs[activeTab].name], one.name))
    }
    let uniqueData = removeDuplicates(filteredData);
    setData(uniqueData)
  }, [searchValue, selectedItoms])
    useEffect(()=>{
      if(searchValue2==''){
          setSelectedItoms({...cacheSelectedItoms})
          return
      }
      let tempObj={...selectedItoms};
      tempObj[props.tabs[activeTab].name]=cacheSelectedItoms[props.tabs[activeTab].name]?.filter((one)=>one?.toLowerCase().includes(searchValue2?.toLowerCase()))
      setSelectedItoms({...tempObj})
    },[searchValue2,cacheSelectedItoms])
  function checkIfPresent(data,element){
    if(data?.length==0){
      return false;
    }
    let flag=false;
    data?.filter(one=>{
      if(one==element){
        flag=true;
      }
    })
    return flag;
  }
  async function associateEmail(e){
    
    let tempData=props.allAssociatedEmails.find(d=>d?.messageId==props?.isOpen?.email?.messageId);
    if(tempData){
      await EmailService.AssociateEmailWithRecords({
        messageId:props.isOpen?.email?.messageId,
        AssociationType:"Manual",
        AssociationObj:selectedItoms,
        ROWID:tempData.ROWID
      },"Update").then(res=>{
        let newData = props.allAssociatedEmails.map(d=>{
          if(d?.messageId==props?.isOpen?.email?.messageId){
            d.AssociationObj=selectedItoms;
          }
          return d;
        });
        props.setPossibleAssociationData({isAlready:true,data:{...selectedItoms}})
        props.setAllAssociatedEmails(newData);
        props.onClose();
        setAutoTaggEnable(false);
        swal("Success!", "Email associated successfully!", "success");
      }).catch(e=>{
        console.log(e)
        swal("Error!", "Something went wrong!", "error");
      })
    }else{
      await EmailService.AssociateEmailWithRecords({
        messageId:props.isOpen?.email?.messageId,
        AssociationType:"Manual",
        AssociationObj:selectedItoms
      },"Post").then(res=>{
        
        let arr=[...props.allAssociatedEmails]
        if(res.data){
          arr.push(res.data[0])
        }
        props.setPossibleAssociationData({isAlready:true,data:{...selectedItoms}})
        props.setAllAssociatedEmails(arr);
        props.onClose();
        swal("Success!", "Email associated successfully!", "success");
      }).catch(e=>{
        console.log(e)
        swal("Error!", "Something went wrong!", "error");
      })
    }
    console.log(selectedItoms)
  }
  function checkIncludes(data,element){
  let arr=[];
  data?.filter(one=>{
    if(one!=element){
      arr.push(one);
    }
  })
  return arr;
  }
        return (
          <AntDModal
          className='Associate-Email-Modal'
            open={props.isOpen?.status}
            title={<Row>
              <Col sm={5}><span style={{fontWeight:"bold",color:"#2d2c2c",fontSize:"large"}}>{props.title}</span> </Col>
              <Col sm={4}>
              <div className='task_filter_box' style={{"width": "max-content"}}>
              <GlobalTaskFilter
                    activeFiltersOnColumnLevel={activeFiltersOnColumnLevel}
                    setActiveFiltersOnColumnLevel={setActiveFiltersOnColumnLevel}
                    setActiveFilterAndGetActiveFilters={setActiveFilterAndGetActiveFilters}
                    setappliedFiltersForView={setappliedFiltersForView}
                    preAppliedFiltersForView={preAppliedFiltersForView}
                    setIsColFilDeleted={setIsColFilDeleted}
                    setFilterTrigger={setFilterTrigger}
                    FilterTrigger={filterTrigger}
                    Columns={columns}
                    rowData={data}
                    cacheData={props.dataForSearch}
                    setRowData={setRowData}
                    module={activeTab}
                    dropdownFields={["State","City", "Project Status", "Account Name","Is NYC Project","Conjunction","Conjunction Application Number"]}
                  /></div></Col>
            </Row>}
            width={900}
            style={{ top: 170, borderRadius: 50,width:"25rem" }}
            onCancel={()=>{
              props.onClose();
              setAutoTaggEnable(false);
              resetFields();
            }}
            footer={  <Button className='btn-info' onClick={associateEmail}>Associate</Button> }
          >  
          <div className='main-div'> 
             <div className="tabs-container">
              <div className="individual-tabs">
                    {props.tabs?.map((tab, index) => (
                        <div
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index)}
                      >
                        {tab.name?.replaceAll("_"," ")}{selectedItoms[tab.name]?.length?" ("+selectedItoms[tab.name]?.length+")":""}
                        </div>
                    ))}
              </div>
              <div className='AutoTaggingCheckBox'>
                      Auto Tagging : <input type='checkbox' value={autoTaggEnable} checked={autoTaggEnable} onChange={e=>{
                        setAutoTaggEnable(e.currentTarget.checked);
                      }} />
                    </div>
             </div>    
             <Row className='Associate-Row'>
              <Col className='Associate-Col' sm={6}>
                <h6 style={{fontWeight:"700",fontSize:"normal",color: '#757070',
                  "border-left": "3px solid orange",
                  "padding-left": "4px",
                }}>Select {props.tabs[activeTab].name?.replaceAll("_"," ")} </h6>
                <input
                type="text"
                className="search"
                placeholder="Search..."
                value={searchValue}
                onChange={(e)=>{
                    e.target.value=e.target.value.trimStart();
                    handleChangeInput(e.target.value)}}
                />
              <div className="data-container">
                    {data?.map((one, index) => (
                        <div
                        key={index}
                        style={{background:one.color,color:props.invertColor(one.color,true)}}
                        className={`tab2`}
                        onClick={()=>{
                          setFlag(!flag)
                          let tempObj={...cacheSelectedItoms};
                          if(tempObj[props.tabs[activeTab].name] ){
                            if(!checkIfPresent(tempObj[props.tabs[activeTab].name],one.name)){
                              tempObj[props.tabs[activeTab].name]=[...tempObj[props.tabs[activeTab].name],one.name];
                            } 
                          }else{
                            tempObj[props.tabs[activeTab].name]=[one.name];
                          }
                          if(one?.TagExtraItem && autoTaggEnable){
                            Object.keys(one?.TagExtraItem)?.forEach(key=>{
                              try{
  
                                if(key && one.TagExtraItem[key]){
                                  if(_.isArray(one?.TagExtraItem[key])){
                                    if(tempObj[key]){ 
                                      const set1 = new Set(tempObj[key]); 
                                      const differingElements = [...new Set(one?.TagExtraItem[key])]?.filter(item => !set1.has(item) && item!=undefined); 
                                        tempObj[key]=[...tempObj[key],...differingElements]; 
                                    }else{
                                      const differingElements = [...new Set(one?.TagExtraItem[key])]?.filter(item =>item!=undefined);
                                      tempObj[key]=[...differingElements];
                                    }
                                  }else{
                                    if(tempObj[key]){ 
                                      if(!tempObj[key].includes(one.TagExtraItem[key])){
                                        tempObj[key]=[...tempObj[key],one.TagExtraItem[key]];
                                      }
                                    }else{
                                      tempObj[key]=[one.TagExtraItem[key]];
                                    }
                                  }
                                }
                              }catch(e){
                                
                              }
                            });
                          }
                            setSelectedItoms({...tempObj})
                            setCacheSelectedItoms({...tempObj})
                        }}
                        >
                        {one.name}
                        </div>
                    ))} 
             </div>
              </Col>
             <Col className='Associate-Col' sm={6}>
             <h6 style={{fontWeight:"700",fontSize:"normal",color: '#757070',
              "border-left": "3px solid #018ee0",
              "padding-left": "4px",
             }}>Selected {props.tabs[activeTab].name?.replaceAll("_"," ")}</h6>
                <input
                type="text"
                className="search"
                placeholder="Search..."
                value={searchValue2}
                onChange={(e)=>{
                    e.target.value=e.target.value.trimStart();
                    handleChangeInput2(e.target.value)}}
                />
             <div className="data-container">
             {selectedItoms[props.tabs[activeTab].name]?.map((temp, index)=>{
                    let tab=props.data?.find(s=>s.name==temp);
                    if(tab){
                        return (
                        <div className='tab2 selected-tiles'
                        style={{background:tab.color,color:props.invertColor(tab.color,true)}}
                        ><span>
                            {temp}
                        </span>
                        <span className='Delete-Icon' onClick={()=>{
                          setFlag(!flag)
                          let tempObj={...cacheSelectedItoms};
                          if(checkIfPresent(tempObj[props.tabs[activeTab].name],tab.name)){
                            tempObj[props.tabs[activeTab].name] = checkIncludes(tempObj[props.tabs[activeTab].name],tab.name);
                              setSelectedItoms({...tempObj})
                              setCacheSelectedItoms({...tempObj})
                              setFlag(!flag) 
                          }
                        }}><FontAwesomeIcon icon={faTimes} /></span>
                        </div>
                        )
                      }
                })}
             </div>
             </Col>
             </Row>
             </div>
          </AntDModal>
        )
}