import React from 'react'
import './Tabs.css';
import { Row } from 'reactstrap';
export const CustomeTabs = ({ onSelectTab, tabs , value}) => {
    return (
        <Row>
            <div className='Tabs-Wrapper'>
                {tabs.map((r,i)=> {
                    return <div className={`Tab-Card ${(value?.id == r.id || (!value && i==0)) && "Active-Tab"}`}
                        onClick={e => {
                            onSelectTab(r)
                        }}
                    >
                        {r.icon && <span>{r.icon}</span>}
                        <span>{r.label}</span>
                        {r.count() && r.count()?.count > 0 && <span className='Count-Span' 
                        style={r.count()?.color?{backgroundColor:r.count()?.color}:{}}>
                            {r.count()?.count}
                        </span>}
                    </div>
                })}
            </div>
        </Row>
    )
}
