import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper';
import { CompactPicker } from 'react-color';
import { HexColorRenges } from '../HR/TimeClock/Util';
import './CustomColorPicker.css';

export const CustomColorPicker = ({value,onChange}) => {
    const [color,setColor] = useState("#8cbf40");
    const [open,setopen] = useState(false);
    useEffect(e=>{
        setColor(value)
    },[value])
  return (
    <div className='Custom-Color-Picker-Wrapper'>
              <div
                style={{ position: 'relative' }}
                onClick={() => {
                    setopen(true)
                }}
              >
                <div className="color-picker">
                  <span
                    style={ { backgroundColor: color }}
                    className="circle iconPosition"
                  ></span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>
              <OtsideClickClosingWrapper
                forceClose={true}
                close={() => { setopen(false) }}
              >
                {open  && <div className="compactColorPicker">
                  <CompactPicker
                    color={color}
                    onChange={(value) => {
                      setColor(value.hex)
                      setopen(false)
                      onChange(value.hex)
                    }}
                    colors={HexColorRenges}
                  />
                </div>}
              </OtsideClickClosingWrapper>
    </div>
  )
}
