import React, { useState, useEffect , useImperativeHandle,forwardRef} from 'react';
import PVSystemInformation from "./pvsysteminformation/pvsysteminformation";
import Projectcost from './projectcost/Projectcost';
import { Row, Col } from "reactstrap";
const PVSystem = forwardRef((props,ref) => {

  const formObj = {
    pvsysteminfocomplete: false,
    projectcost: false,
  };

  const [toggleState, settoggleState] = useState('pvsysteminfo');
  const [pvSystemInfo, setPVSystemInfo] = useState([]);
  const [interconnNo, setinterconnNo] = useState(0);
  const [interConnectionNum, setInterConnectionNum] = useState("1");
  const [projectCost, setprojectCost] = useState(0);
  const [systemSize, setSystemSize] = useState("");
  const [noOfModules, setNoOfModules] = useState("");
  const [pvmodulemodel, setpvmodulemodel] = useState("");
  const [invertertype, setinvertertype] = useState("");
  const [inverterModelNumber, setinverterModelNumber] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [lowerCostInvalid, setlowerCostInvalid] = useState(false);
  const [upperCostInvalid, setupperCostInvalid] = useState(false);
  const [numberOfInvertors, setNumberOfInverters] = useState("");
  const [totalSysSize, setTotalSysSize] = useState();
  const [activeTab, setActiveTab] = useState("pvsysteminfo");
  let pvSystemProps = {
    numberOfInvertors: numberOfInvertors, setNumberOfInverters: setNumberOfInverters,
    pvSystemInfo: pvSystemInfo, setPVSystemInfo: setPVSystemInfo,
    interconnNo: interconnNo, setinterconnNo: setinterconnNo,
    interConnectionNum: interConnectionNum, setInterConnectionNum: setInterConnectionNum,
    // projectCost: projectCost, setprojectCost: setprojectCost,
    toggleState: toggleState, settoggleState: settoggleState,
    setListState: props.setListState, setSystemSize: setSystemSize, systemSize: systemSize,
    noOfModules: noOfModules, setNoOfModules: setNoOfModules,
    pvmodulemodel: pvmodulemodel, setpvmodulemodel: setpvmodulemodel,
    invertertype: invertertype, setinvertertype: setinvertertype,
    inverterModelNumber: inverterModelNumber, setinverterModelNumber: setinverterModelNumber,
    manufacturer: manufacturer, setManufacturer: setManufacturer,
    lowerCostInvalid: lowerCostInvalid, setlowerCostInvalid: setlowerCostInvalid,
    upperCostInvalid: upperCostInvalid, setupperCostInvalid: setupperCostInvalid,
    totalSysSize : totalSysSize, setTotalSysSize : setTotalSysSize,
    setpvsysteminfocomplete:formObj,
    activeTab:activeTab, setActiveTab:setActiveTab
  }

  let projectCostProps = {
    pvSystemInfo: pvSystemInfo, setPVSystemInfo: setPVSystemInfo,
    projectCost: projectCost, setprojectCost: setprojectCost,
    toggleState: toggleState, settoggleState: settoggleState,
    setListState: props.setListState, setSystemSize: setSystemSize, systemSize: systemSize,
    lowerCostInvalid: lowerCostInvalid, setlowerCostInvalid: setlowerCostInvalid,
    upperCostInvalid: upperCostInvalid, setupperCostInvalid: setupperCostInvalid,
    totalSysSize : totalSysSize, setprojectcostcomplete:formObj,
    activeTab:activeTab, setActiveTab:setActiveTab
  }

  useEffect(() => {
    settoggleState(props.listState);
    setPVSystemInfo(props.data.pvSystemInfo)
  }, [props.listState]);

  //Hrushikesh Shelke new code start
  
  useImperativeHandle(ref, () => ({
    toggelActiveBar(tab) {
      setActiveTab(tab);
    }
  }));

  const[moduleCheckStatus,setModuleCheckStatus]=useState({
    Projectcost:props.sidebarCardsStatus.PVSystem || localStorage.getItem('allitemchecked'),
    PVSystemInformation:props.sidebarCardsStatus.PVSystem || localStorage.getItem('allitemchecked'),
  })
  useEffect(()=>{
    if(formObj.pvsysteminfocomplete && formObj.projectcost){
      localStorage.setItem('allitemchecked',true);
      props.updateTheSidebarState({type:'ShouldSave', status:true})
    }else{
      localStorage.setItem('allitemchecked',false);
      props.updateTheSidebarState({type:'ShouldSave', status:false})
    }
  },[moduleCheckStatus]);
  //Hrushikesh Shelke new code end

  return (
    <div className="boxplate">
      <div className="boxplate-heading" style={{display: "flex"}}>
          <div className='sectitle'>PV System</div>
           {/* <div className='zolabisbtn'> */}
            {/* <button className='nxtBtn' onClick={()=>ableNextButton()}>Next</button> */}
           {/* </div> */}
          </div>
      <div className="pad-15 createdeal-custom-scroll">
        <PVSystemInformation setModuleCheckStatus={setModuleCheckStatus} moduleCheckStatus={moduleCheckStatus} isAccess={props.isAccess} selectedRole={props.selectedRole} {...pvSystemProps} data={props.data} handleOnChangeDeal={props.handleOnChangeDeal} />
        <Projectcost setModuleCheckStatus={setModuleCheckStatus} location={props.location} moduleCheckStatus={moduleCheckStatus} data={props.data} handleOnChangeDeal={props.handleOnChangeDeal} {...projectCostProps}/>
        <Row>
          <Col sm={10}></Col>
        </Row>
      </div>
    </div>
  );
})

export default PVSystem;