
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import React, { useState } from 'react'
import './emojiNotePicker.css';
import AddReactionIcon from '../../common//EmojiReactionPicker/smile-plus.png'
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper'
export const EmojiPickerNote = ({setEditorValue, editorValue, handleChange}) => {

  const [isOpen, setIsOpen] = useState(false)

    const emojiChanged = (e) =>{
        const newValue = editorValue.toString('html') + e.native;
        const regex = /(<\/?p>|<br>|\\n)/g;
        const cleanedValue = newValue.replace(regex, '');
        handleChange(cleanedValue.toString('html'));
        // setIsOpen(false);
    }

    return (<div className='Emoji-Wrapper-Note'>
    <div className='Selected-Reaction-Wrapper-Note'>
      <div className='Selected-Reaction-Header-Note'>
        <div className='Add-Emoji-Icon'>
          <img onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen)
          }} 
          src={AddReactionIcon} />
        </div>
      </div>

      {isOpen ?
        <OtsideClickClosingWrapper className={'Emoji-Picker-Wrapper Emoji-Picker-Wrapper-Note'} close={(e) => {
          if (isOpen) {
            setIsOpen(false)
          }
        }}>
          <Picker data={data} onEmojiSelect={emojiChanged}
          />
        </OtsideClickClosingWrapper> :
        <></>
      }
    </div>

  </div>
  )

}
