import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import GoogleMapReact from "google-map-react";
import Config from "../../../../../../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Geocode from "react-geocode";
import Marker from "./Marker";
import axios from "axios";
import { NycApiData } from "../../../../../../Context/AppActions";
import AppContext from "../../../../../../Context/AppContext";
import Loader from "../../../../../../common/loader";

const PropertyInformation = (props) => {
  const backendUrl1 = Config.backend_url;
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const nycApiData = state.NycApiData || {};
  const [zoom, setZoom] = useState(17);
  const placeInputRef = useRef(null);
  const [propinfotogglestate, setPropinfotoggle] = useState(false);
  const [Continue, setContinue] = useState(false);
  const [duplicateModalPopUp, setDuplicateDealPopup] = useState(false);
  const [alreadyExistDeal, setAlreadyExistDeal] = useState(null);
  const [selectedDealId, setSelectedDealId] = useState("");
  const [loader, setLoader] = useState(false);

  const [validation, setValidation] = useState("0");
  const validationCss = {
    transition: "width 0.4s ease-in-out",
    "box-shadow": "rgb(255 98 98) 0px 0px 7px 1px",
    "border-color": "#ff0000",
  };
  const propinfotoggle = () => {
    setPropinfotoggle(!propinfotogglestate);
    props.setListState("property");
    props.settoggleState("property");
  };
  useEffect(
    (e) => {
      props.updateTheSidebarState({
        type: "LoadingModalStatus",
        status: loader,
      });
    },
    [loader]
  );

  const [skipPropertyinfo, setSkipPropertyinfo] = useState(false);
  let NycObj = {
    effectiveFldZone: "",
    preliminaryFldZone: "",
    FreshwaterCheckzone: "",
    FreshwaterWetlands: "",
    preliminaryFirmPanelNo: "",
    effectiveFirmPanelNo: "",
  };
  let tidal_WebScrape;
  let landmark_WebScrape;
  let env_WebScrape;
  var binNum;
  let bblNum;
  let coordinates;
  var landStatus;
  var envRes;
  let tidalWetlands;
  let floodZoneApi;
  let freshWatersApi;
  let floodZone;
  let coastal;
  let freshWaters;
  let compareObj;
  let mapIndex;
  let imageUrl;
  let categoryArr = [];
  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey("AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw");
  // set response language. Defaults to english.
  Geocode.setLanguage("en");
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("us");
  Geocode.setLocationType("ROOFTOP");
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();

  const onChangeProjectAdd = async (value) => {
    props.handleOnChangeDeal({
      currentTarget: { name: "ProjectAddress", value: value },
    });
    props.setProjectAdd(value);
    if (skipPropertyinfo == true && value.trim() == "") {
      setSkipPropertyinfo(!skipPropertyinfo);
    }
    props.setAddressValChange(!props.addressValChange);
  };

  const setFilterStreet = (street) => {
    var str = street.replace(/(\d+)(st|nd|rd|th)/g, "$1");
    return str;
  };

  if (
    props.data.Borough == "1" ||
    props.data.Borough == "2" ||
    props.data.Borough == "3" ||
    props.data.Borough == "4" ||
    props.data.Borough == "5"
  ) {
    if (props.data.Borough == "1") {
      props.data.Borough = "Manhattan";
    } else if (props.data.Borough == "2") {
      props.data.Borough = "Bronx";
    } else if (props.data.Borough == "3") {
      props.data.Borough = "Brooklyn";
    } else if (props.data.Borough == "4") {
      props.data.Borough = "Queens";
    } else {
      props.data.Borough = "Staten Island";
    }
  }

  const pdfByURL = async (url, fileType) => {
    let config = {
      url: Config.backend_url + "downloadPdfByURL",
      method: "post",
      data: {
        url: url,
        fileType: fileType,
      },
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
    };
    let file = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return file;
  };

  let nycData;
  const NycInfoApi = async () => {
    await axios
      .get(`${backendUrl1}getLandmarkInfo?bbl=${bblNum}`)
      .then((resp) => {
        // console.log("LandMark api", resp.data);
        landStatus = resp?.data?.data?.length !== 0 ? "Yes" : "No";
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${backendUrl1}getEnvResInfo?bbl=${bblNum}`)
      .then((response) => {
        console.log("env api", response.data);
        envRes = response?.data?.data?.length !== 0 ? "Yes" : "No";
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${backendUrl1}getCoordinates?bbl=${bblNum}`)
      .then((result) => {
        // console.log("result", result.data);
        coordinates = result.data.data.Coordinates;
      })
      .catch((err) => {
        console.log(err);
      });

    let obj = {
      coordinates: `{'rings':${coordinates}}`,
    };
    let tidalData = null;

    await axios
      .post(`${backendUrl1}getTidalWetlandsInfo`, obj)
      .then(async (result1) => {
        // console.log("tidal wetlands", result1.data);
        if (result1?.data?.success) {
          tidalWetlands = result1?.data?.data?.length !== 0 ? "Yes" : "No";
          mapIndex = result1?.data?.MAPINDEX;
          imageUrl = result1?.data?.IMAGEURL;
          if (result1?.data?.IMAGEURL) {
            await axios
              .post(Config.backend_url + "download", {
                url: result1?.data?.IMAGEURL,
              })
              .then((res) => {
                // console.log("res Image", res);
                tidalData = {
                  image: res.data,
                  name:
                    props.sidebarCardsStatus.FilesExtention +
                    " TIDAL WETLAND IMAGE",
                };
              })
              .catch((err) => {
                console.log("error");
              });
          }
          if (result1?.data?.data?.length !== 0) {
            result1?.data?.data?.map((item, i) => {
              categoryArr.push(item?.attributes?.TWCAT74);
            });
          }
        } else {
          alert("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .post(`${backendUrl1}getFloodZoneInfo`, obj)
      .then((result) => {
        // console.log("result of flood zone", result.data);
        if (result.data.data.EffectiveFloodZone) {
          if (
            result.data.data.EffectiveFloodZone.ZONE_SUBTY !=
            "AREA OF MINIMAL FLOOD HAZARD"
          ) {
            NycObj.effectiveFldZone = "Yes";
          } else {
            NycObj.effectiveFldZone = "No";
          }
        }
        if (result.data.data.PreliminaryFloodZone) {
          NycObj.preliminaryFldZone = "Yes";
        } else {
          NycObj.preliminaryFldZone = "No";
        }
        if (
          result.data.data.EffectiveFloodZone.ZONE_SUBTY !=
            "AREA OF MINIMAL FLOOD HAZARD" ||
          result.data.data.PreliminaryFloodZone
        ) {
          floodZoneApi = "Yes";
        } else {
          floodZoneApi = "No";
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .post(`${backendUrl1}getFreshwaterWetlandsInfo`, obj)
      .then((result) => {
        // console.log("result of fresh water wetland", result.data);
        if (result.data.data.FreshwaterCheckzone !== null) {
          NycObj.FreshwaterCheckzone = "Yes";
        } else {
          NycObj.FreshwaterCheckzone = "No";
        }
        if (result.data.data.FreshwaterWetlands !== null) {
          NycObj.FreshwaterWetlands = "Yes";
        } else {
          NycObj.FreshwaterWetlands = "No";
        }
        if (
          result.data.data.FreshwaterCheckzone ||
          result.data.data.FreshwaterWetlands
        ) {
          freshWatersApi = "Yes";
        } else {
          freshWatersApi = "No";
        }
      })
      .catch((err) => {
        console.log(err);
      });

    let floodMapDocs = null;

    await axios
      .post(`${backendUrl1}getFirmPanelInfo`, obj)
      .then(async (result) => {
        // console.log("result of firm panel", result.data);
        NycObj.preliminaryFirmPanelNo = result.data.data.PreliminaryFirmPanelNo;
        NycObj.effectiveFirmPanelNo = result.data.data.EffectiveFirmPanelNo;
        if (floodZoneApi == "Yes") {
          floodMapDocs = {
            effictive: await pdfByURL(
              `https://msc.fema.gov/portal/downloadProduct?productTypeID=FINAL_PRODUCT&productSubTypeID=FIRM_PANEL&productID=${result.data.data.EffectiveFirmPanelNo}`,
              "application/zip"
            ),
            preliminary: await pdfByURL(
              `https://msc.fema.gov/portal/downloadProduct?productTypeID=PRELIM_PRODUCT&productSubTypeID=PRELIM_FIRM&productID=${result.data.data.PreliminaryFirmPanelNo}`,
              "application/pdf"
            ),
          };
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${backendUrl1}getNYCProjectDetails?bin=${binNum}`)
      .then((result) => {
        // console.log("scraping" , result);
        floodZone = result.data.data.Flood_Hazard_Zone;
        coastal =
          result.data.data.Coastal_Erosion_Hazard !== undefined &&
          result.data.data.Coastal_Erosion_Hazard !== ""
            ? result.data.data.Coastal_Erosion_Hazard
            : "No";
        freshWaters = result.data.data.Fresh_Water_Wetlands;
        tidal_WebScrape = result.data.data.Tidal_Wetlands;
        landmark_WebScrape = result.data.data.Landmark;
        env_WebScrape =
          result.data.data.Environmental_Restrictions != "" &&
          result.data.data.Environmental_Restrictions != undefined &&
          result.data.data.Environmental_Restrictions != "N/A"
            ? result.data.data.Environmental_Restrictions
            : "No";

        compareObj = {
          BBL_Number: bblNum,
          Tidal_Wetlands_API: tidalWetlands,
          Tidal_Wetlands_WebScraping: result.data.data.Tidal_Wetlands,
          Tidal_Wetlands_Map_Index_Number: mapIndex != null ? mapIndex : "No",
          Tidal_Wetlands_Category: "",
          Tidal_Wetlands_Image_URL: imageUrl != undefined ? imageUrl : "No",
          Effective_Flood_Hazard_Checkzones: NycObj.effectiveFldZone,
          Effective_BFE: "",
          Preliminary_Flood_Hazard_Checkzones: NycObj.preliminaryFldZone,
          Preliminary_BFE: "No",
          Collective_Result_Flood_Hazard_Checkzones: "No",
          // "Freshwater_Api" : freshWaterApi,
          Freshwater_Wetlands_WebScraping:
            result.data.data.Fresh_Water_Wetlands,
          // "FloodHazard_Api" : "N/A",
          Effective_Firm_Panel_Number: NycObj.effectiveFirmPanelNo,
          Preliminary_Firm_Panel_Number: NycObj.preliminaryFirmPanelNo,
          State_Regulated_Freshwater_Wetlands: NycObj.FreshwaterWetlands,
          State_Regulated_Freshwater_Wetlands_Checkzone:
            NycObj.FreshwaterCheckzone,
          Flood_Hazard_Zones_WebScarping_API:
            result.data.data.Flood_Hazard_Zone,
          Coastal_Erosion_Hazard_Area_API: "N/A",
          Coastal_Erosion_Hazard_Area_WebScraping:
            result.data.data.Coastal_Erosion_Hazard,
          Environmental_Restrictions_API: envRes,
          Environmental_Restrictions_WebScraping:
            result.data.data.Environmental_Restrictions != "N/A"
              ? result.data.data.Environmental_Restrictions
              : "No",
          Landmark_API: landStatus,
          Landmark_WebScraping: result.data.data.Landmark,
          Deals_ID: "",
        };
      })
      .catch((err) => {
        console.log(err);
      });

    ////// Get PLUTO Info Api ////

    let myData;

    await axios
      .get(`${backendUrl1}getPLUTOInfo?bbl=${bblNum}`)
      .then((res) => {
        myData = res.data;
      })
      .catch((err) => {
        console.log(err);
      });

    nycData = {
      ...nycData,
      bblNum: bblNum,
      binNum: binNum,
      tidal_WebScrape: tidal_WebScrape,
      landmark_WebScrape: landmark_WebScrape,
      env_WebScrape: env_WebScrape,
      myData: myData,
      mapIndex: mapIndex,
      imageUrl: imageUrl,
      coastal: coastal,
      floodZone: floodZone,
      floodZoneApi: floodZoneApi,
      tidalWetlands: tidalWetlands,
      freshWatersApi: freshWatersApi,
      freshWaters: freshWaters,
      landStatus: landStatus,
      envRes: envRes,
    };
    dispatch(NycApiData({ ...nycApiData, nycData, NycObj }));
    props.updateTheSidebarState({
      type: "webScrapingApi",
      status: compareObj,
      tidalData: tidalData,
      floodMapDocs: floodMapDocs,
    });
  };

  const handlePropertyCheck = () => {
    if (skipPropertyinfo) {
      props.setPropertyInfoComplete.propertInformationComplete = true;
      return true;
    }
    if (
      props.projectAdd !== "" &&
      props.projectAdd != null &&
      props.houseNo !== "" &&
      props.streetName !== "" &&
      props.cityName !== "" &&
      props.stateName !== "" &&
      props.zipCode !== "" &&
      props.NYCOption != null
    ) {
      props.setPropertyInfoComplete.propertInformationComplete = true;
      return true;
    }
    return false;
  };

  const onBlurProjectAdd = async (value) => {
    if (value != "" && props.data.ProjectAddress != value) {
      setLoader(true);
      dispatch(NycApiData({}));
      if (value == "" && props.sidebarCardsStatus.FilesExtention == null) {
        return;
      }
      Geocode.fromAddress(value).then(
        async (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          let obj = {
            NYCProject: "No",
            Borough: "",
            ProjectAddress: "",
            HouseNumber: "",
            StreetName: "",
            City: "",
            State: "",
            ZipCode: "",
            Longitude: "",
            Latitude: "",
          };
          var houseNO = "",
            street = "",
            city = "",
            state = "",
            zipcode = "",
            borough = "",
            neighbourhood = "";
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "street_number":
                  houseNO = response.results[0].address_components[i].long_name;
                  break;
                case "route":
                  street = response.results[0].address_components[i].long_name;
                  break;
                case "neighborhood":
                  neighbourhood =
                    response.results[0].address_components[i].long_name;
                  break;
                case "sublocality_level_1":
                  borough = response.results[0].address_components[i].long_name;
                  if (
                    borough == "Manhattan" ||
                    borough == "Bronx" ||
                    borough == "The Bronx" ||
                    borough == "Brooklyn" ||
                    borough == "Queens" ||
                    borough == "Staten Island"
                  ) {
                    obj.NYCProject = "Yes";
                    props.setNYCOption("Yes");
                    props.setNycVisible("1");
                    props.setSkipNycFlag(false);
                    localStorage.setItem("nycOption", "Yes");

                    if (borough == "Manhattan") {
                      props.setBorough("Manhattan");
                      obj.Borough = "1";
                      localStorage.setItem("borough", "1");
                      city = "New York";
                    } else if (borough == "Bronx" || borough == "The Bronx") {
                      props.setBorough("Bronx");
                      obj.Borough = "2";
                      localStorage.setItem("borough", "2");
                      city = borough;
                    } else if (borough == "Brooklyn") {
                      props.setBorough("Brooklyn");
                      obj.Borough = "3";
                      localStorage.setItem("borough", "3");
                      city = borough;
                    } else if (borough == "Queens") {
                      props.setBorough("Queens");
                      obj.Borough = "4";
                      localStorage.setItem("borough", "4");
                      city = neighbourhood;
                    } else if (borough == "Staten Island") {
                      props.setBorough("StatenIsland");
                      obj.Borough = "5";
                      localStorage.setItem("borough", "5");
                      city = borough;
                    } else {
                      obj.Borough = "0";
                      localStorage.setItem("borough", "0");
                    }
                  } else {
                    obj.NYCProject = "No";
                    props.setNYCOption("No");
                    props.setNycVisible("0");
                    props.setSkipNycFlag(true);

                    localStorage.setItem("nycOption", "0");
                    localStorage.setItem("borough", "");
                    city = response.results[0].address_components[i].long_name;
                  }
                  break;
                case "locality":
                  if (
                    borough != "Manhattan" &&
                    borough != "Bronx" &&
                    borough != "Brooklyn" &&
                    borough != "Queens" &&
                    borough != "Staten Island"
                  ) {
                    city = response.results[0].address_components[i].long_name;
                    obj.NYCProject = "No";
                    props.setNYCOption("No");
                    props.setNycVisible("0");
                    props.setSkipNycFlag(true);

                    localStorage.setItem("nycOption", "No");
                    localStorage.setItem("borough", "");
                  }

                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].short_name;
                  break;
                case "postal_code":
                  zipcode = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }

          var str = value;
          var lastIndex = str.lastIndexOf(",");
          var res = str.substring(lastIndex);
          if (lastIndex > 0) {
            if (res.includes("USA")) {
              str = str.substring(0, lastIndex);
              str = str + " " + zipcode;
            }
          }
          props.updateTheSidebarState({
            type: "FilesExtention",
            status: {
              street: street,
              houseNo: houseNO,
            },
            NycProject: obj.NYCProject == "Yes" ? false : true,
            SkipNycFlag: obj.NYCProject == "No" ? true : false,
          });

          obj.ProjectAddress = str;
          props.setProjectAdd(str);
          localStorage.setItem("projectAddress", str);
          var filterStreet = setFilterStreet(street);
          props.sethouseNo(houseNO);
          localStorage.setItem("houseNo", houseNO);
          obj.HouseNumber = houseNO;
          props.setstreetName(filterStreet);
          localStorage.setItem("street", filterStreet);
          obj.StreetName = filterStreet;
          props.setcityName(city);
          localStorage.setItem("city", city);
          obj.City = city;
          props.setstateName(state);
          localStorage.setItem("state", state);
          obj.State = state;
          props.setzipCode(zipcode);
          obj.ZipCode = zipcode;
          localStorage.setItem("zipcode", zipcode);
          props.setCenter({ lat: lat, lng: lng });
          obj.Longitude = lng;
          obj.Latitude = lat;
          props.setltitude(lat);
          localStorage.setItem("lat", lat);
          props.setlngitude(lng);
          localStorage.setItem("lng", lng);
          let filterDeal = props.allDeals.filter(
            (one) => one.Project_Address == str
          );
          if (filterDeal.length > 0) {
            //  console.log("line 256",filterDeal);
            setAlreadyExistDeal(filterDeal);
            setDuplicateDealPopup(true);
          }
          if (props.func === "create") {
            let flag = false;
            if (props.data.StreetName) {
              flag = true;
            }
            props.handleOnChangeDeal({
              currentTarget: { name: "Address", value: obj, already: flag },
            });
          } else {
            let flag = false;
            if (props.data.StreetName) {
              flag = true;
            }
            props.handleOnChangeDeal({
              currentTarget: {
                name: "Address",
                value: obj,
                already: flag,
                activity: {
                  UpdatedState: { Value: obj.ProjectAddress, Name: "Address" },
                },
              },
            });
          }

          const data = {
            boro: obj.Borough,
            houseNo: obj.HouseNumber,
            street: obj.StreetName,
          };
          if (obj.NYCProject == "Yes") {
            let backendUrl12 = Config.backend_url;
            await axios
              .get(
                `${backendUrl12}getNYCPropertyInfo?Borough=${data.boro}&AddressNo=${data.houseNo}&StreetName=${data.street}`
              )
              .then((res) => {
                binNum = res.data.bin;
                bblNum = res.data.bbl;
              })
              .catch((err) => {
                console.log("Error", err.message);
              });
            await NycInfoApi();
          }
          setLoader(false);
        },
        (error) => {
          let obj = {};
          obj.NYCProject = "No";
          obj.Borough = "";
          obj.ProjectAddress = value;
          props.setProjectAdd("");
          localStorage.removeItem("projectAddress");
          props.sethouseNo("");
          localStorage.removeItem("houseNo");
          obj.HouseNumber = "";
          props.setstreetName("");
          localStorage.removeItem("street");
          obj.StreetName = "";
          props.setcityName("");
          localStorage.removeItem("city");
          obj.City = "";
          props.setstateName("");
          localStorage.removeItem("state", "");
          obj.State = "";
          props.setzipCode("");
          obj.ZipCode = "";
          localStorage.removeItem("zipcode");
          props.setCenter({ lat: "", lng: "" });
          obj.Longitude = "";
          obj.Latitude = "";
          props.setltitude("");
          localStorage.removeItem("lat");
          props.setlngitude("");
          localStorage.removeItem("lng");
          props.updateTheSidebarState({
            type: "FilesExtention",
            status: null,
            NYCProject: obj.NYCProject == "Yes" ? true : false,
            SkipNycFlag: obj.NYCProject == "No" ? false : true,
          });
          props.handleOnChangeDeal({
            currentTarget: {
              name: "Address",
              value: obj,
              already: true,
              activity: {
                UpdatedState: { Value: obj.ProjectAddress, Name: "Address" },
              },
            },
          });
          console.error("Error Finding Place:" + error);
          props.updateTheSidebarState({
            type: "LoadingModalStatus",
            status: !props.sidebarCardsStatus.LoadingModalStatus,
          });
          setLoader(false);
        }
      );
    }
  };

  const handleChangeDealSelect = (e) => {
    setSelectedDealId(e.currentTarget.id);
  };

  const duplicateDealModal = () => {
    return (
      <Modal
        isOpen={duplicateModalPopUp}
        backdrop={"static"}
        size="md"
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12} className="text-center">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Duplicate Deal Warning pppp</h5>
              <p className="taskComplete-warning-subtitle">
                Same Project Address Exist On Another Deals? if you want to
                create duplicate select which one.
              </p>
              <table className="mb-3">
                {alreadyExistDeal &&
                  alreadyExistDeal.map((one) => {
                    return (
                      <tr style={{ textAlign: "left" }}>
                        <td className="d-flex">
                          <input
                            onChange={handleChangeDealSelect}
                            id={one.id}
                            name="Deal"
                            type="radio"
                          ></input>
                          &nbsp; &nbsp;
                        </td>
                        <td>
                          <span style={{ fontWeight: 500 }}>Client :</span>
                          &nbsp;
                        </td>
                        <td>
                          <span>{one.Account_Name.name}</span>&nbsp;&nbsp;
                        </td>
                        <td>
                          <span style={{ fontWeight: 500 }}>Deal :</span>&nbsp;
                        </td>
                        <td>
                          <span>{one.Deal_Name}</span>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  setContinue(true);
                  setDuplicateDealPopup(false);
                }}
              >
                Continue
              </button>
              <button
                onClick={() => {
                  let filterDeal = [];
                  if (alreadyExistDeal && alreadyExistDeal.length > 0) {
                    filterDeal = alreadyExistDeal.filter(
                      (one) => one.id == selectedDealId
                    );
                    // console.log("alreadyExistdeal..", alreadyExistDeal);
                    // console.log("selected deal id is..", selectedDealId);
                  }
                  //  console.log("the filter deal", filterDeal);
                  filterDeal = filterDeal[0];
                  if (filterDeal) {
                    delete filterDeal.id;
                    filterDeal.Deal_Name = props.data.ProjectNumber;
                    filterDeal.Account_Name = props.data.Client;
                    filterDeal.Client = props.data.Client;
                    if (
                      props.buttonPosition == "CreateOrCloneView" ||
                      props.buttonPosition == "EditView"
                    ) {
                      window.ZOHO.CRM.UI.Record.populate(filterDeal).then(
                        async function (data) {
                          await window.ZOHO.CRM.UI.Popup.closeReload();
                        }
                      );
                    } else if (
                      props.buttonPosition == "ListViewWithoutRecord"
                    ) {
                      window.ZOHO.CRM.API.insertRecord({
                        Entity: "Potentials",
                        APIData: filterDeal,
                      }).then(async function (data) {
                        await window.ZOHO.CRM.UI.Popup.closeReload();
                      });
                    }
                  }
                  setDuplicateDealPopup(false);
                }}
                className="btn btn-success w-100 mt-2"
              >
                Duplicate
              </button>
              <button
                onClick={() => {
                  const obj = { ...props.data };
                  obj.ProjectAddress = "";
                  obj.State = "";
                  obj.ZipCode = "";
                  obj.City = "";
                  obj.StreetName = "";
                  obj.HouseNumber = "";
                  props.setData(obj);
                  setDuplicateDealPopup(false);
                  setContinue(false);
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const initPlaceAPI = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
    }
  };

  const setAllStates = () => {
    setPropinfotoggle(true);
    props.setCenter({
      lat: localStorage.getItem("lat"),
      lng: localStorage.getItem("lng"),
    });
    props.setProjectAdd(localStorage.getItem("projectAddress"));
    props.sethouseNo(localStorage.getItem("houseNo"));
    props.setstreetName(localStorage.getItem("street"));
    props.setcityName(localStorage.getItem("city"));
    props.setstateName(localStorage.getItem("state"));
    props.setzipCode(localStorage.getItem("zipcode"));
    props.setltitude(localStorage.getItem("lat"));
    props.setlngitude(localStorage.getItem("lng"));
    props.setNYCOption(localStorage.getItem("nycOption"));
    props.setBorough(localStorage.getItem("borough"));
    initPlaceAPI();
  };

  useEffect(() => {
    if (props.data) {
      initPlaceAPI();
    }
    props.toggleState == "property"
      ? props.data == null
        ? setAllStates()
        : setPropinfotoggle(true)
      : setPropinfotoggle(false);
  }, [props.toggleState]);

  const formAbleNext = (props) => {
    props.setPropertyInfoComplete(true);
  };

  useEffect(() => {
    getData();
  }, [props.data]);

  const getData = () => {
    if (props.data != null) {
      props.setNYCOption(props.data.NYCProject);
      props.setProjectAdd(props.data.ProjectAddress);
      props.sethouseNo(props.data.HouseNumber);
      props.setstreetName(props.data.StreetName);
      props.setcityName(props.data.City);
      props.setstateName(props.data.State);
      props.setzipCode(props.data.ZipCode);
      props.setltitude(props.data.Latitude);
      props.setlngitude(props.data.Longitude);
      props.setBorough(props.data.Borough);
    }
  };

  return (
    <>
      {duplicateDealModal()}
      {props.sidebarCardsStatus.LoadingModalStatus ? <Loader /> : <></>}
      <Col sm={12}>
        <Button
          onClick={propinfotoggle}
          className={propinfotogglestate ? "btn-100-active" : "btn-100"}
        >
          Property Location Information
          <div className="float-right">
            <Input
              type="checkbox"
              className={
                handlePropertyCheck()
                  ? "rightbutton-checkbox-active"
                  : "rightbutton-checkbox"
              }
              checked={handlePropertyCheck() && props.projectAdd.length > 0}
              onChange={(e) => {
                if (
                  props.data.ProjectAddress == "" ||
                  props.data.ProjectAddress == undefined
                ) {
                  setValidation("-1");
                }
                let pAd = document
                  .getElementById("ProjectAddress")
                  .value.trim();
                if (e.target.checked && !pAd.length > 0) {
                  e.preventDefault();
                  return;
                }
                setSkipPropertyinfo(!skipPropertyinfo);
              }}
            />
            {propinfotogglestate ? (
              <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
            ) : (
              <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
            )}
          </div>
        </Button>
        <Collapse isOpen={propinfotogglestate}>
          <Card className="createdeal-card">
            <CardBody>
              <FormGroup className="marb-16 ">
                <Row>
                  <FormGroup className="marb-16 ">
                    <Col sm={6}>
                      <Row>
                        <Col sm={4} style={{ flexBasis: "32%" }}>
                          <Label className="text-bold" for="projectaddress">
                            Project Address<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={8} className="">
                          <input
                            type="text"
                            className="address-text form-control"
                            ref={placeInputRef}
                            id="ProjectAddress"
                            value={
                              props.data
                                ? props.data.ProjectAddress
                                  ? props.data.ProjectAddress
                                  : ""
                                : ""
                            }
                            style={
                              validation == "-1" ? { ...validationCss } : {}
                            }
                            onChange={(e) => {
                              e.target.value = e.target.value.trimStart();
                              if (
                                e.target.value == "" ||
                                e.target.value == undefined
                              ) {
                                setValidation("-1");
                              } else {
                                setValidation("1");
                              }
                              if (props.location.state.action == "Update") {
                                e.preventDefault();
                              } else {
                                onChangeProjectAdd(e.target.value);
                              }
                            }}
                            onBlur={(e) => {
                              if (props.location.state.action == "Update") {
                                e.preventDefault();
                              } else {
                                onBlurProjectAdd(e.target.value);
                              }
                            }}
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6}></Col>
                  </FormGroup>
                </Row>
                <Row>
                  <Col sm={4}>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            House Number<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            className="createdeal-textfield"
                            type="text"
                            name="text"
                            id="HouseNumber"
                            value={
                              props.data
                                ? props.data.HouseNumber
                                  ? props.data.HouseNumber
                                  : ""
                                : ""
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            Street Name<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            className="createdeal-textfield"
                            type="text"
                            name="text"
                            id="Street"
                            value={
                              props.data
                                ? props.data.StreetName
                                  ? props.data.StreetName
                                  : ""
                                : ""
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="projectnumber">
                            City<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            className="createdeal-textfield"
                            type="text"
                            name="text"
                            id="City"
                            value={
                              props.data
                                ? props.data.City
                                  ? props.data.City
                                  : ""
                                : ""
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            State<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            className="createdeal-textfield"
                            type="text"
                            name="text"
                            id="State"
                            value={
                              props.data
                                ? props.data.State
                                  ? props.data.State
                                  : ""
                                : ""
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            Zip Code<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            className="createdeal-textfield"
                            type="text"
                            name="text"
                            id="ZipCode"
                            value={
                              props.data
                                ? props.data.ZipCode
                                  ? props.data.ZipCode
                                  : ""
                                : ""
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            Latitude
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            className="createdeal-textfield"
                            type="text"
                            name="text"
                            id="Latitude"
                            value={
                              props.data
                                ? props.data.ProjectAddress
                                  ? props.data.Latitude
                                  : ""
                                : ""
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            Longitude
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            className="createdeal-textfield"
                            type="text"
                            name="text"
                            id="Longitude"
                            value={
                              props.data
                                ? props.data.ProjectAddress
                                  ? props.data.Longitude
                                  : ""
                                : ""
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            NYC Project<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={3}>
                          <Label className="text-bold2">Yes</Label>
                          <Input
                            type="radio"
                            name="NYCProject"
                            value="Yes"
                            checked={
                              props.data
                                ? props.data.NYCProject === "Yes"
                                : false
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col sm={3}>
                          <Label className="text-bold2">No</Label>
                          <Input
                            type="radio"
                            name="NYCProject"
                            value="No"
                            checked={
                              props.data
                                ? props.data.NYCProject === "No"
                                : false
                            }
                            disabled={
                              (props.location.state.action == "Update") == true
                                ? true
                                : false
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup
                      row
                      className={
                        (props.data ? props.data.NYCProject === "Yes" : false)
                          ? "marb-16 padtop-15"
                          : "nycOption-none"
                      }
                    >
                      <Row>
                        <Col sm={6}>
                          <Label className="text-bold" for="housenumber">
                            Borough<span className="red-text">*</span>
                          </Label>
                        </Col>
                        <Col sm={6}>
                          <Input
                            type="text"
                            className="createdeal-textfield"
                            name="text"
                            id="Borough"
                            value={props.data ? props.data.Borough : ""}
                            disabled="true"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col sm={8} className="map-padding text-center">
                    {props.data.Longitude && props.data.Latitude ? (
                      <GoogleMapReact
                        // bootstrapURLKeys={{ key: "AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw"}}
                        defaultCenter={{
                          lng: parseFloat(props.data.Longitude),
                          lat: parseFloat(props.data.Latitude),
                        }}
                        center={{
                          lng: parseFloat(props.data.Longitude),
                          lat: parseFloat(props.data.Latitude),
                        }}
                        defaultZoom={zoom}
                      >
                        <Marker
                          lat={parseFloat(props.data.Latitude)}
                          lng={parseFloat(props.data.Longitude)}
                          name="My Marker"
                          color="blue"
                        />
                      </GoogleMapReact>
                    ) : (
                      <p>Map Not Available</p>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </CardBody>
          </Card>
        </Collapse>
      </Col>
    </>
  );
};

export default PropertyInformation;
