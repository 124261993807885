import React, { useState, useEffect, useRef } from "react";
import { UncontrolledCollapse, Input, UncontrolledTooltip } from "reactstrap";
import TaskService from "../util/TaskService";
import UserService from "../util/UserService";
import "./comment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faPaperPlane,
  faPaperclip,
  faTags,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import NotificationAPI from "../util/NotificationAPI";
import _ from "lodash";
import RichTextEditor from "react-rte";
import MentionUserNotes from "../../components/Notes/MentionUserNotes";
import { EmojiPickerNote } from "../../components/Notes/EmojiPickerNote";

const Comment = (props) => {
  const { id, allusers, handleUploadFiles, preValues , isDraftAlready, contentShowFlag , composeMailToShow,  composeMailObj,
    setIsSchedule, isSchedule
  } = props;
  const [openCommentEditor, setOpenCommentEditor] = useState(false);
  const [allTask, setAllTask] = useState([]);
  const initialContent = `<p>Your Signature Here</p>`;
  // const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());
  const [editorValue, setEditorValue] = useState(RichTextEditor.createValueFromString(initialContent, "html"));
  const [showDropdown, setShowDropdown] = useState(false);
  const [charToCompare, setcharToCompare] = useState("");
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [attachments,setAttachmenets]=useState([]);
  let forwardContent;

  if(props.id == "email"){
    forwardContent = `
    &nbsp;&nbsp;&nbsp;_______________________________________________________________________________________________________________________________________________
    <br><br>
      ============ Forwarded message ============<br>
      From: ${composeMailToShow?.from}<br>
      To: ${(composeMailToShow.to).map((item) => item.email).join("; ")}<br>
      ${composeMailToShow.cc.length > 0 ? `Cc: ${(composeMailToShow.cc).map((item) => item.email).join("; ")}<br>` : ""}
      ${composeMailToShow.bcc.length > 0 ? `Bcc: ${(composeMailToShow.bcc).map((item) => item.email).join("; ")}<br>` : ""}
      Subject: ${composeMailToShow.subject}<br>
      ============ Forwarded message ============<br>
  `
  .trim()
  .split("\n")
  .filter(line => line.trim().length > 0)
  .join("\n");

  }
  
  useEffect(e=>{
    setAttachmenets(props?.Attachments)
  },[props?.Attachments])
  const editorRef = useRef(null);

  useEffect(() => {
    if (document.activeElement.classList.contains("public-DraftEditor-content")) {
      setCursorPositionToEnd();
    }
  }, [editorValue]); // Run this effect whenever editorState changes

  const setCursorPositionToEnd = () => {
    const editor = editorRef.current;
    if (editor) {
      const length = editorValue.toString('html').length; // Get the length of the content as HTML string
        editor.editor?.focus(length, length);
    }
  };

  const handleChange = async (value) => {
    let text = value.toString("html");
    let arr = text.split(" ");
    if (arr[arr.length - 1].includes("@")) { 
      setcharToCompare("@");
      setShowDropdown(true);
    } else if (arr[arr.length - 1].includes("#")) {
      setcharToCompare("#");
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
    if (typeof value == "string") {
      if(props.id!="email"){
        setEditorValue(RichTextEditor.createValueFromString(value, "html"));
      }else{
        props.emailOnTagSelect(value);
      }
      setShowDropdown(false);
    } else {
      setEditorValue(value);
      if(props.id == "email"){
        props.onChange(value);
      }
    }
  };
  

  useEffect((e) => {
    setEditorValue(RichTextEditor.createEmptyValue());
    getUsers();
  }, []);

  const getUsers = async () => {
    let users = await UserService.GetAllUsers();
    users = users.data.users;

    // Populate suggestions array with all users
    const suggestions = users.map((user) => ({
      name: user.full_name,
      id: user.zuid,
      image: UserService.GetUserPhotoUrl(user.zuid),
    }));
    setMentionSuggestions(suggestions);
  };

  useEffect(() => {
    if (props.alltasks && props.alltasks.length > 0) {
      setAllTask(props.alltasks);
    }
  }, [props.alltasks]);

  useEffect(() => {
    if (props.action == "Edit" && allTask.length > 0) {
      let value = convertZohoToCRM(props.value);
      setEditorValue(RichTextEditor.createValueFromString(value, "html"));
    }
  }, [props.action, allTask]);

  useEffect(() => {
    if (props.taskId) {
      setEditorValue(RichTextEditor.createEmptyValue());
      setOpenCommentEditor(false);
    }
  }, [props.taskId]);

  const sendNotification = (message) => {
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    let users = [...props.allusers];
    users = users.filter((one) => message.includes(one.zuid));
    let dataArr = [];
    let doArr = [];
    users.forEach((user) => {
      let obj = {
        IsRead: false,
        Sender_Email: userInfo?.email_id,
        Sender_Name: userInfo?.first_name + " " + userInfo?.last_name,
        Reciever_Email: user.email,
        Reciever_Name: user.full_name,
        Type: "Mention",
        Title: "Mention",
        Text: "mention you in task " + props.taskname,
      };
      dataArr.push(obj);
    });
    dataArr.forEach((obj) => {
      doArr.push(NotificationAPI.Trigger(obj));
    });
    Promise.all(doArr)
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleKeyPress = async (e) => {
    // add task

    if (editorValue != "") {
      let convertedMessage = convertZohoMentionToCRM(editorValue);
      if (props.action == "Edit") {
        TaskService.UpdateTaskComment(
          props.projectId,
          props.taskId,
          "content",
          convertedMessage,
          props.commentId
        )
          .then(function (data) {
            props.onClose();
            props.getAllTaskMessage(props.projectId, props.taskId);
          })
          .then((result) => {})
          .catch((err) => {
            console.log(err);
            alert(err);
          });
      } else {
        TaskService.AddTaskComment(
          props.projectId,
          props.taskId,
          "content",
          convertedMessage
        )
          .then(async function (data) {
            props.getAllTaskMessage(props.projectId, props.taskId);
            let clientColor = await TaskService.GetClientColor(
              props.dealObj.Account_Name.id
            );
            let dealColor = await TaskService.GetDealColor(props.dealObj.Stage);
            let userInfo = JSON.parse(localStorage.getItem("userinfo"));
            let text = `<a style="color:#40a9ff" id="User-${
              userInfo?.email_id
            }">${
              userInfo?.first_name + " " + userInfo?.last_name
            }</a> <span>mentioned you in a </span><a style="color:#40a9ff" id="TaskComment-${
              props.task.id_string
            }-${
              data.comments[0].id_string
            }">comment</a> <span>under task</span> <a style="color:#40a9ff" id="Task-${
              props.task.id_string
            }">${
              props.task.name
            }</a>  <a style="background-color:${clientColor}" id="Client-${
              props.dealObj.Account_Name.id
            }">${
              props.dealObj.Account_Name ? props.dealObj.Account_Name.name : ""
            }</a>  <a style="background-color:${dealColor}" id="Deal-${
              props.dealObj.id
            }">${TaskService.getDealNameFormPipeline(
              props.task.project_name
            )}</a> `;
            await TaskService.SendMentionNotification(
              convertedMessage,
              allusers,
              text,
              "Task"
            );
            if (props.getNotification) {
              props.getNotification();
            }
          })
          .then((result) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const convertZohoMentionToCRM = (message) => {
    const users = [...props.allusers];
    users.forEach((one) => {
      let replaceStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.full_name}</span>`;
      message = message.toString("html");
      message = message.replaceAll(
        replaceStr,
        `zp[@zpuser#${one.zuid}#${one.full_name}]zp`
      );
    });
    return message;
  };

  const convertZohoToCRM = (message) => {
    const users = [...props.allusers];
    const tasks = [...allTask];
    users.forEach((one) => {
      let replaceStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.full_name}</span>`;
      message = message.toString("html");
      message = message.replaceAll(
        `zp[@zpuser#${one.zuid}#${one.full_name}]zp`,
        replaceStr
      );
      message = message.replaceAll(
        `zp[@zpuser#${one.zuid}#${one.first_name}]zp`,
        replaceStr
      );

      let fromStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.first_name}</span>`;
      if (message) {
        message = message.toString("html");
        message = message.replaceAll("{@" + one.zuid + "}", fromStr);
      } else {
        message = "";
      }
    });

    tasks.forEach((task) => {
      let replaceStr = `<span class="mymention" data-mention-id=${task.id_string} id=Task-${task.id_string}-${task.project_id} name="Task" >#${task.name}</span>`;
      message = message.toString("html");
      message = message.replaceAll(
        `<span id="Task-${task.id_string}-${task.project_id}">#${task.name}</span>`,
        replaceStr
      );
    });

    return message;
  };


  //  Signature Code //

  useEffect(() => {
    if(props.id == "email"){
      let sign;
      if(contentShowFlag == "forward"){
        sign = "<br><br>" + props.signature.replaceAll("</div>", "").replaceAll("<div>", "") + forwardContent + preValues;
      }else{
      sign = props.signature.replaceAll("</div>", "").replaceAll("<div>", "");
      }
      let signatureWithBreaks;
      if(!isDraftAlready){
        if(preValues){
          if(contentShowFlag == "forward"){
            signatureWithBreaks =`${sign}`;
          }else{
            signatureWithBreaks = `${preValues}<br><br>${sign}`;
          }
        }else{
          signatureWithBreaks = `<br><br>${sign}`;
        }
        
        const newContent = RichTextEditor.createValueFromString(signatureWithBreaks, 'html');
        setEditorValue(newContent);
      }else{
        if(preValues){
            signatureWithBreaks =`${preValues}`;
          }else{
          signatureWithBreaks = `<br><br>${sign}`;
        }
        const newContent = RichTextEditor.createValueFromString(signatureWithBreaks, 'html');
        setEditorValue(newContent);
      }
    } 
    // Set the signature text upon component mount
  }, [props?.signature, preValues]);

  return (
    <div className={`comment-here-box ${props.EmailSection?"Custome-Height":""}`}>
      {props.id !== "email" && !openCommentEditor && props.action !== "Edit" ? (
        <Input
          type="text"
          placeholder={
            props.placeholder ? props.placeholder : "Comment Here ..."
          }
          onClick={() => {
            setOpenCommentEditor(!openCommentEditor);
          }}
          className="opencommenteditor"
          id="opencommenteditor"
        />
      ) : (
        <>
          {props.id == "notes" || props.id == "email" ? (
            <p id="opencommenteditor"></p>
          ) : (
            <></>
          )}
        </>
      )}
      <UncontrolledCollapse
        isOpen={
          props.action == "Edit" || openCommentEditor || props.id == "email"
        }
        toggler="opencommenteditor"
      >
        <div className="callLog-desc-editor">
          <RichTextEditor
            value={editorValue}
            autoFocus={true}
            onChange={handleChange}
            ref={editorRef}
            placeholder={props.id == "email" ? "Draft mail here..." : "Write Notes Here..."}
            editorStyle={{
              minHeight: 150,
              fontFamily: "Avenir Next LT W02",
              fontSize: "12",
            }}
            customStyleMap={{
              MONOSPACE: {
                color: "#5b6c97", // or specify the color you desire
              },
            }}
            className="custom-rich-text-editor"
          />

          <div className="mention-note-wrapper">
            <MentionUserNotes
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              charToCompare={charToCompare}
              editorValue={editorValue}
              allusers={mentionSuggestions}
              handleChange={handleChange}
            />
          </div>

          {
            props.id != "email" && 
            <>
              <UncontrolledTooltip placement="top" target={"add-mention-user"}>
                Add Mention
              </UncontrolledTooltip>
              <span
                onClick={(e) => { 
                  setShowDropdown(true);
                  setcharToCompare("@");
                  let editVal = editorValue.toString("html");
                  editVal = editVal.replaceAll("<p>", "");
                  editVal = editVal.replaceAll("</p>", "");
                  editVal = editVal.replaceAll("<br>", "");
                  let newValue;
                  if(props.id == "email"){
                    let neeSign = (props.signature).replaceAll("<div>", "").replaceAll("</div>", "").replaceAll("</p>", "").replaceAll("<p>", "").replaceAll("<br />", "");
                    let contentToCompare =  editVal.replaceAll("<div>", "").replaceAll("</div>", "").replaceAll("</p>", "").replaceAll("<p>", "").replaceAll("<br />", "");
                    let lines1 = neeSign.split('\n');
                    let lines2 = contentToCompare.split('\n');
            
                    // Find the differing portion
                    let differingLineIndex = 0;
                    while (lines1[differingLineIndex] === lines2[differingLineIndex]) {
                        differingLineIndex++;
                    }
                    // Extract the differing portion
                    let differingPortion = lines2[differingLineIndex];
                    newValue = differingPortion + "@" + props.signature;
                  }else{
                    newValue = editVal + "@";
                  }

                  setEditorValue(
                    RichTextEditor.createValueFromString(newValue, "html")
                  );
                }}
                className="comment-bottom-mention-icon"
              >
                <FontAwesomeIcon id="add-mention-user" icon={faAt} />
              </span>
          </>
          }

          <UncontrolledTooltip placement="top" target={"add-task-tag"}>
            Add Tags
          </UncontrolledTooltip>

          <span
            onClick={(e) => {
              setShowDropdown(true);
              setcharToCompare("#");
              let editVal = editorValue.toString("html");
              editVal = editVal.replaceAll("<p>", "");
              editVal = editVal.replaceAll("</p>", "");
              editVal = editVal.replaceAll("<br>", "");
              const newValue = editVal + "#";
              if(props.id!="email"){
                setEditorValue(
                  RichTextEditor.createValueFromString(newValue, "html")
                );
              }
            }}
            className="comment-bottom-mention-icon"
          >
            <FontAwesomeIcon id="add-task-tag" icon={faTags} />
          </span>

          <span className="comment-bottom-mention-icon last-span">
            <EmojiPickerNote
              handleChange={handleChange}
              editorValue={editorValue}
              setEditorValue={setEditorValue}
            />
          </span>

          {props.id == "chat" ? (
            <>
              <button
                onClick={() => {
                  setEditorValue(RichTextEditor.createEmptyValue());
                  setOpenCommentEditor(false);

                  if (props.onClose) {
                    props.onClose();
                  }
                }}
                className="continue-button small-3d-button font-13 logactivity float-right"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <button
                onClick={() => {
                  // if (editorRef && editorRef.current) {
                  //   editorRef.current.setClipboard('')
                  // }
                  // setComms("")
                  // setOpenCommentEditor(false)
                  // if (props.onClose) {
                  //   props.onClose()
                  // }
                }}
                className="continue-button small-3d-button font-13 logactivity float-right"
              >
                <FontAwesomeIcon icon={faPaperclip} />
              </button>
              <button
                className="continue-button font-13 logactivity float-right"
                onClick={(e) => {
                  setEditorValue(RichTextEditor.createEmptyValue());
                  setOpenCommentEditor(false);
                  if (props.onSave) {
                    props.onSave(editorValue);
                    props.onClose();
                  } else {
                    handleKeyPress(e);
                  }
                }}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </>
          ) : (
            <div className="comment-cancel-button">
               {attachments && attachments.length>0 && <span onClick={e=>{
                props?.setOpenAttachmentPopUp(!props?.openAttachmentPopUp)
              }} className=" Attachmnets-Btn">Attachments({attachments?.length})</span>}
               <button
                onClick={() => {
                  props.setOpenSchedulePopup(!props.openSchedulePopup);
                  setIsSchedule(true)
                }}
                className={composeMailObj?.to?.length == 0 ? "continue-button font-13 logactivity float-right disabled" : "continue-button small-3d-button font-13 logactivity float-right"}
                disabled={composeMailObj?.to?.length == 0 ? true : false }
              >
                Schedule
              </button>
              <button
                className="continue-button small-3d-button font-13 logactivity float-right"
                onClick={(e) => {
                  setEditorValue(RichTextEditor.createEmptyValue());
                  setOpenCommentEditor(false);
                  if (props.onSave) {
                    let msg = editorValue.toString("html");
                    msg = msg.replace("<p>", "");
                    msg = msg.replace("</p>", "");
                    msg = msg.replace("<br>", "");
                    msg = msg.replaceAll("&nbsp;", "");
                    msg = msg
                      .replace("<p>", "")
                      .replace("</p>", "")
                      .replace("<br>", "")
                      .replace("<h1>", "")
                      .replace("</h1>", "")
                      .replace("<h2>", "")
                      .replace("</h2>", "")
                      .replace("<h3>", "")
                      .replace("</h3>", "")
                      .replace("<em>", "")
                      .replace("</em>", "")
                      .replace("<h1></h1>", "")
                      .replace("<h2></h2>", "")
                      .replace("<h3></h3>", "")
                      .replace("<pre><code></code></pre>", "")
                      .replace("<blockquote></blockquote>", "")
                      .replace("<ul>↵ <li></li>↵</ul>", "")
                      .replace("<ol>↵ <li></li>↵</ol>", "");
                    props.onSave(editorValue);
                    if (msg) {
                      props.onClose();
                    } else {
                      setOpenCommentEditor(true);
                    }
                  } else {
                    handleKeyPress(e);
                  }
                }}
              >
                {props.action == "Edit"
                  ? "Update"
                  : props.id == "email"
                  ? "Send"
                  : "Comment"}
              </button>
              <button
                onClick={() => {
                  setEditorValue(RichTextEditor.createEmptyValue());
                  setOpenCommentEditor(false);
                  if (props.onClose) {
                    props.onClose();
                  }
                }}
                className="continue-button small-3d-button font-13 logactivity float-right"
              >
                Cancel
              </button>
              {id == "email" ? (
                <button
                  onClick={() => {
                    handleUploadFiles();
                  }}
                  className="continue-button small-3d-button font-13 logactivity float-right"
                >
                  Upload Files
                </button>
              ) : (
                <></>
              )}

              {id == "email" ? (
                <button
                onClick={(e) => {
                  setEditorValue(RichTextEditor.createEmptyValue());
                  setOpenCommentEditor(false);
                  if (props.onDraft) {
                    let msg = editorValue.toString("html");
                    msg = msg.replace("<p>", "");
                    msg = msg.replace("</p>", "");
                    msg = msg.replace("<br>", "");
                    msg = msg.replaceAll("&nbsp;", "");
                    msg = msg
                      .replace("<p>", "")
                      .replace("</p>", "")
                      .replace("<br>", "")
                      .replace("<h1>", "")
                      .replace("</h1>", "")
                      .replace("<h2>", "")
                      .replace("</h2>", "")
                      .replace("<h3>", "")
                      .replace("</h3>", "")
                      .replace("<em>", "")
                      .replace("</em>", "")
                      .replace("<h1></h1>", "")
                      .replace("<h2></h2>", "")
                      .replace("<h3></h3>", "")
                      .replace("<pre><code></code></pre>", "")
                      .replace("<blockquote></blockquote>", "")
                      .replace("<ul>↵ <li></li>↵</ul>", "")
                      .replace("<ol>↵ <li></li>↵</ol>", "");
                      props.onDraft(editorValue);
                    if (msg) {
                      props.onClose();
                    } else {
                      setOpenCommentEditor(true);
                    }
                  } else {
                    handleKeyPress(e);
                  }
                }}
                  className="continue-button small-3d-button font-13 logactivity float-right"
                >
                  Save Draft
                </button>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </UncontrolledCollapse>
    </div>
  );
};

export default Comment;
