import { Button, Divider, Popover, Segmented } from 'antd';
import { useMemo, useState } from 'react';
import UserService from '../util/UserService';
import './CustomDropdown.css';

const CustomDropdown = ({ selectedValue, onSelect, items, toggelButton,isUser }) => {
  const [showArrow, setShowArrow] = useState(true);
  const [arrowAtCenter, setArrowAtCenter] = useState(false);
  const mergedArrow = useMemo(() => {
    if (arrowAtCenter)
      return {
        pointAtCenter: true,
      };
    return showArrow;
  }, [showArrow, arrowAtCenter]);

  const text = <span>Title</span>;
  const buttonWidth = 70;

  const content = (
    <div className='custum-dropdown-status-popover'>
    
      {items.map((one) => {
        if(isUser){
            let elem=one.zuid && one.zuid !== '' ?
            <img
              src={UserService.GetUserPhotoUrl(one.zuid)}
              //alt={avtar}
              className="mr-1 rounded-circle width25"
            />
            : <div className='no-image-user'>{one.full_name.charAt(0)}</div>
         
            return <p onClick={()=>onSelect(one)} style={{ display:'flex', color:'black',backgroundColor:'white',border:'solid 0.5px lightgray' }}>{elem}{one.full_name}</p>
        }else{
            return <p onClick={()=>onSelect(one)} style={{ color:'black',backgroundColor:'white',border:'solid 0.5px lightgray' }}>{one.name}</p>
        }
      })}
    </div>
  );
  return (
    <div >
      <Popover overlayClassName='antd-custum-dropdown-popover' style={{zIndex:9999}}  trigger='click' placement="bottomLeft" content={content} arrow={false}>
        {toggelButton}
      </Popover>
    </div>

  );
}

export default CustomDropdown;