import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./Progress.css";
import Tasks from "../Task/task";
// import Gantt from "../Gantt/gantt";
// import Dashboard from "../dashboard/dashboard";
import Milestone from "../Milestone/milestone";
import _ from 'lodash';
import Activities from "../../components/Activity/Activity";
const Progress = (props) => {
  const [key, setKey] = useState("tasks");
  const [DealsId, setDealsId] = useState("");
  useEffect(() => {
    if (props.dealObj.Project_ID) {
      setDealsId(props.dealObj.Project_ID);
    }
  }, [props.dealObj]);
  // const getDealObj=(clone)=>{
  //     let dealObj=_.cloneDeep(clone);
  //     delete dealObj.zoningList;
  //     delete dealObj.commercialList;
  //     delete dealObj.specialDistList;
  //     return dealObj
  // }
  return (
    <div>
      <Tabs
        style={key == 'milestones' ? { marginBottom: '12px' } : {}}
        id="progress-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="job-middle-tab job-middle-tabs progress-inner-tabs"
      >
        <Tab eventKey="dashboard" title="Dashboard">
          DashBoard
          {/* <Dashboard eventKey={key} dealsId={DealsId} /> */}
        </Tab>
        <Tab eventKey="tasks" title="Tasks">

          <Tasks
           invertColor={props.invertColor}
          dealColor={props.dealColor}
           getNotification={props.getNotification}
          taskLayout={props.taskLayout}
          refreshEnable={props.refreshEnable}
          setRefreshEnable={props.setRefreshEnable}
          history={props.history}
          setTimerPrevTimeTrack={props.setTimerPrevTimeTrack}
            timerObj={props.timerObj}
            timerTimeTrack={props.timerTimeTrack}
            startstopClockTimeTrack={props.startstopClockTimeTrack}
            setTimerTimeTrack={props.setTimerTimeTrack}
            initializeTimeTrack={props.initializeTimeTrack}
            timeTrackFlag={props.timeTrackFlag}
            allMilestones={props.allMilstones}
            getAllMilestone={props.getAllMilestone}
            isView={props.isView} isAccess={props.isAccess} selectedRole={props.selectedRole} deals={props.allDeals} eventKey={key} searchTask={props.searchTask} id='task' dealObj={props.dealObj} dealsId={DealsId} />

        </Tab>
        <Tab eventKey="gantt" title="Gantt & Reports">
          Gantt Reports
          {/* <Gantt eventKey={key} dealsId={DealsId} /> */}

        </Tab>
        {/* <Tab eventKey="tasks" title="Tasks">
              <Tasks jobNo={props.job.ProjectNumber}/>
              </Tab> */}
        <Tab eventKey="milestones" title="Milestones">
          {/* Milestone */}
          <Milestone allMilstones={props.allMilstones} dealObj={props.dealObj} eventKey={key} projectId={DealsId} />

        </Tab>
        <Tab eventKey="timesheet" title="Timesheet">

          Timesheet
        </Tab>
        <Tab eventKey="issues" title="Issues">
          Issues
        </Tab>
        <Tab eventKey="activity" title="Activities">
          <Activities data={props.jobData} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Progress;
