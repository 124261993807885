import { faPen, faChevronRight, faLink, faSquare, faFlag as faFlagSolid, faSearch, faCaretDown, faChevronDown, faExclamationTriangle, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import {
  Card, Button, CardHeader, CardFooter, CardBody,
  CardTitle, CardText
} from 'reactstrap';
import HttpService from "../../services/http.service";
import './Activity.css';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-all";
import { UncontrolledCollapse, UncontrolledPopover, PopoverBody, PopoverHeader, Popover, Modal, ModalBody, Row, Col } from 'reactstrap';
// import Select from '../../common/Select/select';
import { DateRangePicker } from "react-date-range";
import { DatePicker, Space } from 'antd';
import 'antd/dist/reset.css';
import ActvityCard from '../../tabs/Activity/activity';

import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import defaultUser from '../../assets/images/defaultUser.png';
import ActivitieService from '../../common/util/ActivitieService';
import UserService from '../../common/util/UserService';
import validation from '../../common/util/validation';

const Activities = (props) => {

  const defaultFilterObj = [
    {
      name: "Assignee",
      id: "Owner",
      isCheck: true,
    },
    {
      name: "DueDate",
      id: "dueDate",
      isCheck: true,
    },
    {
      name: "StartDate",
      id: "startDate",
      isCheck: true,
    },
    {
      name: "Priority",
      id: "priority",
      isCheck: true,
    },
    {
      name: "Status",
      id: "status",
      isCheck: true,
    },



    // Assignee: true, DueDate: true, StartDate: true, Priority: true, Status: true,
    // Note: true, Meeting: true,
    // Call: true, Event: true, TaskComment: true, All: true
  ]

  const { RangePicker } = DatePicker;
  const [filterObj, setFilterObj] = useState(defaultFilterObj);
  const [allActivities, setAllActivities] = useState([]);
  const [renderList, setRenderList] = useState([])
  const [filterActvityPop, setFilterActivityPop] = useState(false);
  const [filterActvityPopUser, setFilterActivityPopUser] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [cacheUsers, setCacheUsers] = useState([]);

  const toggleFilterActvity = () => setFilterActivityPop(!filterActvityPop);
  const toggleFilterActvitUser = () => setFilterActivityPopUser(!filterActvityPopUser);

  const handleOnChangeFilterActvity = (e) => {
    let activityList = [...allActivities];
    let obj = [...filterObj];
    let filteractivity = [];
    obj.forEach((one, i) => {
      if (one.id == e.target.id) {
        obj[i].isCheck = !obj[i].isCheck;

      }

    });
    obj.forEach((one) => {
      if (one.isCheck) {
        let filterActivityList = activityList.filter(e => e.state == one.id);
        filteractivity = [...filteractivity, ...filterActivityList]
      }

    })

    setRenderList(filteractivity)
    // users.filter(one => one.id == e.currentTarget.id)[0].isCheck = e.currentTarget.checked;

    setFilterObj(obj)




    // let list = [...allActivities];
    // const obj = { ...filterObj };
    // if (e.currentTarget.name == 'All') {
    //   Object.keys(obj).forEach((key) => {
    //     obj[key] = e.currentTarget.checked
    //   })
    // }
    // // else if(e.currentTarget.name==="TaskComment"){
    // //   let list = [...allActivities];
    // //   let filterList = list.filter(one=>)
    // // }
    // else {
    //   obj['All'] = false;
    //   obj[e.currentTarget.name] = e.currentTarget.checked;
    //   // if(e.currentTarget.checked){
    //   //   let filterList = list.filter(one=>)
    //   // }
    // }
    // setFilterObj(obj);
  }

  useEffect(() => {
    if (props.data && props.data.Project_ID) {
      let doArr = []
      let ids = props.data.Project_ID.split(',');
      ids = ids.filter(id => id);
      ids.forEach((id) => {
        doArr.push(ActivitieService.GetAllProjectActivity(id))
      })
      Promise.all(doArr)
        .then((result) => {
          result = validation.RemoveNestedArray(result)
          let activities = [];
          // result.forEach((one) => {
          //   activities = [...one.activities, ...activities]
          // })
          result = result.filter((one) => {
            if ((one && (one.state == "custom_function_execution")) || (one.old_value && one.old_value.includes('#')) || (one.state == "businessrule")) {
    
            } else {
              return one;
            }
          })
          setAllActivities(result);
          setRenderList(result);
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }, [props.data])

  const handleOnChangeSearchTask = (e) => {
    let list = [...allActivities];
    let filterActivityList = list.filter((one) => {
      if (one.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        one.activity_by.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return one;
      }
    })
    setRenderList(filterActivityList);

  }


  const getDiffDays = (dateTime1, dateTime2) => {
    const date1 = new Date(dateTime1);
    const date2 = new Date(dateTime2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffTime + " milliseconds");
    console.log(diffDays + " days");
    return diffDays;
  }


  const getLableTime = (diffDays) => {
    switch (diffDays) {
      case 1:
        return 'Today';
      case 2:
        return 'Yesterday';
      default:
        return (diffDays - 1) + ' Days ago';

    }
  }

  const getActvityYear = (date) => {
    date = new Date(date);
    let nowDate = new Date();
    if (date.getFullYear() !== nowDate.getFullYear()) {
      let year = ', ' + date.getFullYear();
      return year;
    }
    return '';
  }

  const getTime = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;

  };

  const getDate = (date) => {
    date = new Date(date);
    let str = '';
    str = str + date.toLocaleString('en-us', { month: 'short' });
    str = str + " " + date.getDate();

    return str;
  }

  // const getFilterListByUser = (list) => {
  //   const userList = [...users];
  //   list = [...list];
  //   list = list.filter((one) => {
  //     let filterUser = userList.filter(user => user.UserId == one.UserId);
  //     if ((filterUser.length > 0) && (filterUser[0].isCheck)) {
  //       return one;
  //     }
  //   })
  //   return list;
  // }

  // const getFilteredActvityList = (list) => {
  //   const filterObj1 = { ...{} };

  //   list = [...list]
  //  list = getFilterListByUser(list);
  //   list = list.filter(one => {
  //     if (one.DocumentType) {
  //       if (filterObj1[one.DocumentType]) {
  //         return one
  //       }
  //     } else {
  //       if (taskActivity.includes(one.Action.DataType) && filterObj1['Task']) {
  //         if (one.Action.ActionType == 'ADD') {
  //           if (filterObj1['Add']) {
  //             return one;
  //           }
  //         } else {
  //           if (filterObj1[one.Action.ActionType]) {
  //             return one
  //           }
  //         }
  //       }
  //       else {
  //         if (filterObj1[one.Action.DataType]) {
  //           if (one.Action.ActionType == 'ADD') {
  //             if (filterObj1['Add']) {
  //               return one;
  //             }
  //           } else {
  //             if (filterObj1[one.Action.ActionType]) {
  //               return one
  //             }
  //           }
  //         }

  //       }
  //     }

  //   })
  //   return list;
  // }

  const getActivityList = (list) => {
    list = [...list]
    // list = list.filter(one => one.TaskName !== '');
    // list = getFilteredActvityList(list)
    list.sort((a, b) => {
      return new Date(b.time_long) - new Date(a.time_long)
    })
    let returnArr = [];
    list.forEach((one) => {
      if (returnArr.length == 0) {
        let obj = {}
        obj.Date = new Date(one.time_long).toLocaleDateString();
        obj.time_long = one.time_long;
        obj.Activity = []
        obj.Activity.push(one);
        returnArr.push(obj);
      } else {
        let filterDate = returnArr.filter(date => date.Date == new Date(one.time_long).toLocaleDateString());
        if (filterDate.length > 0) {
          filterDate[0].Activity.push(one);
        } else {
          let obj = {}
          obj.time_long = one.time_long;
          obj.Date = new Date(one.time_long).toLocaleDateString();
          obj.Activity = []
          obj.Activity.push(one);
          returnArr.push(obj);
        }

      }

    })
    return returnArr;
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  const getAllUsers = async () => {
    UserService.GetAllUsers()
      .then((result) => {
        result.data.users.forEach((one) => {
          one.isCheck = true
        })
        setAllUsers(result.data.users);
        setCacheUsers(result.data.users);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const handleChangeFilterUser = (val) => {
    let users = [...allUsers];
    let filterUsers = users.filter(one => {
      if (one.full_name.includes(val)) {
        return one
      }
    })
    setCacheUsers(filterUsers);
  }



  const handleFilterUser = (e) => {
    let activityList = [...allActivities];
    let users = [...allUsers];
    let filteractivity = [];
    users.forEach((one, i) => {
      if (one.id == e.target.id) {
        users[i].isCheck = !users[i].isCheck;

      }
      // filteractivity = activityList.filter(obj=>obj.full_name==one.full_name && one.isCheck==false);

    });
    users.forEach((one) => {
      if (one.isCheck) {
        let filterActivityList = activityList.filter(obj => obj.activity_by == one.full_name);
        filteractivity = [...filteractivity, ...filterActivityList]
      }

    })

    setRenderList(filteractivity)
    // users.filter(one => one.id == e.currentTarget.id)[0].isCheck = e.currentTarget.checked;

    setCacheUsers(users);

  }

  const getActivityList1 = (list) => {
    list = [...list]
    list.sort((a, b) => {
      return new Date(b.time_long) - new Date(a.time_long)
    })

    let returnArr = []
    for (let i = 0; i < list.length; i++) {
      let arr = []
      arr.push(list[i]);
      for (let j = i + 1; j < list.length; j++) {
        if (list[j].activity_for == list[i].activity_for) {
          arr.push(list[j]);
          list.splice(j, 1);
          j--;
        }
      }
      // arr.sort((a, b) => {
      //     return new Date(a.createdAt) - new Date(b.createdAt)
      // })
      //let filterArr=arr.filter(one=>one.TaskName!=='');
      returnArr.push(arr);
    }
    return returnArr;
  }

  return (
    <div className="activityContent">
      <div className='activity-fixed-top pt-0'>
        <div className="activitySearch-bar activity_custom_search">
          <FontAwesomeIcon icon={faSearch} />
          <input
            placeholder='Search task...'
            name='Search'
            className="form-control from-control-sm"
            onChange={handleOnChangeSearchTask}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <div className='activity-filters-title'>
            {/* <label className='m-0'>Filter by:</label> */}
            <span className='btn btn-sm btn-primary activity-filter-icon' onClick={() => {
              //   setFilterActivityPopUser(false);
              setFilterActivityPop(true);
            }} id="filter-activity-pop" >
              {/* Activities */}
              <FontAwesomeIcon icon={faFilter} />
            </span>
            {/* <Button onClick={() => {
             
            }} id="filter-activity-pop-user" >
              Users
              <FontAwesomeIcon icon={faCaretDown} />
            </Button> */}

            <div className="justify-content-start">
              <Space direction="vertical" size={12}>
                <RangePicker onChange={(item) => {
                  //   changeDateRange(item);
                }} renderExtraFooter={() => <div><span>Select All</span><input onChange={(e) => {
                  //   setDateRangeIsAll(e.currentTarget.checked)
                }} name='SelectAll' type='checkbox' /></div>} />

              </Space>
            </div>
          </div>
          {/* <UncontrolledPopover trigger="legacy" placement="bottom" toggle={toggleFilterActvitUser} isOpen={filterActvityPopUser} target="filter-activity-pop-user" >
            <PopoverHeader>
              <input
                type='text'
                name='SearchActivityUser'
                onChange={(e) => handleChangeFilterUser(e.target.value)}
                placeholder='search user...'
                className='form-control form-control-sm'
              />
            </PopoverHeader>
            <PopoverBody
              className='activity-filters'
            >
              {cacheUsers.map((user) => {
                return <div className='row m-0'>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <input
                        type='checkbox'
                        checked={user.isCheck}
                        id={user.id}
                        name={user.full_name}
                        onChange={(e) => handleFilterUser(e)}
                      />
                    </div>
                    <div className='col-sm-9 filterName call-log-contacts-text'>
                      {user.full_name}
                    </div>
                  </div>
                </div>
              })}
            </PopoverBody>
          </UncontrolledPopover> */}
          <UncontrolledPopover
            trigger="legacy"
            placement="bottom"
            toggle={toggleFilterActvity}
            isOpen={filterActvityPop}
            target="filter-activity-pop"
          >
            {/* <PopoverHeader>
              <input
                type='checkbox'
                id={one.id}
                onChange={handleOnChangeFilterActvity}
                checked={filterObj['All']}
                name='All'
              />{' '}Select All
            </PopoverHeader> */}
            <PopoverBody
              className='activity-filters'
            >
              <div className='row m-0'>

                <div className='row'>
                  {filterObj.map(one => {
                    return <>
                      <div className='col-sm-3'>


                        <input
                          checked={one.isCheck}
                          name={one.name}
                          id={one.id}
                          onChange={handleOnChangeFilterActvity}
                          type='checkbox'
                        />
                      </div>
                      <div className='col-sm-9 filterName'>
                        {one.name}
                      </div>
                    </>
                  }

                  )}

                </div>


              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      </div>

      <div className="timelineCards-wrapper">
        {getActivityList(renderList).map((obj) => {
          return <> <div className="time-label mt-3">
            <span>{getLableTime(getDiffDays(new Date(Date.now()), new Date(obj.time_long)))}</span>
          </div>
            {getActivityList1(obj.Activity).map((e) => {
              return <><ActvityCard activityFor={"Deal"} dealObj={props.data} obj={e} />

                {/* <Card className="activityCard">
                  <CardHeader>
                    <span className="taskName">{e.activity_for}</span>
                  </CardHeader>
                  <CardBody className="p-0">
                    <ul className="activity-detail">
                     {e.map((one)=>{
                      return <li>
                        <div className="activity-info">
                          <span className="userProfile-link">{one.activity_by}</span>  has {one.state} {one.name}
                        </div>
                        <div className="activity-time">
                          <span>{getDate(new Date(one.time_long))}{getActvityYear(new Date(one.time_long))}</span>
                          at
                          <span>{getTime(new Date(one.time_long))}</span>
                        </div>
                      </li>
                     }) }
                    </ul>
                  </CardBody>
                </Card> */}
              </>
            })}
          </>
        })}

      </div>
    </div>
  );
};

export default Activities;