import { faFlag, faHourglass, faClock, faStopCircle, faPlayCircle, faEdit, faTrashAlt, faEye, faCalendar, faCalendarCheck, faCaretSquareDown, faCheckSquare, faFile, faDotCircle, faCircle, faPauseCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle, faFlag as faFlagSolid, faUpload, faSquare, faFunnelDollar, faAngleRight, faExpandAlt, faReply, faEnvelope, faImage, faSortNumericDown, faPercentage, faMoneyBillAlt, faFun, faPhone, faSearchPlus, faPlus, faTimes, faPlusCircle, faHashtag, faTags, faItalic, faTextHeight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trueDependencies } from "mathjs";
import React, { Component, useEffect, useState } from 'react';
import {
    Col, Container, Row, Button, Form, Modal, ModalBody, ModalHeader, UncontrolledDropdown, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Popover,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover,
    UncontrolledTooltip,
    UncontrolledCollapse,
    Tooltip,
    Card, CardTitle, CardText,
    Collapse, FormGroup,
    CardBody,
} from "reactstrap";
import swal from "sweetalert";
import Config from '../../config.json'
import httpService from "../../services/http.service";
import FieldService from "../util/FieldService";
import axios from "axios";

const LookUpFields = (props) => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'));
    const backdrop = 'static'
    const [openModal, setOpenModal] = useState(false);
    const [addEditField, setAddEditFiels] = useState(false);
    const [modalState, setModalState] = useState(false);
    const modalStateToogle = () => setModalState(!modalState);
    const openModalToogle = () => setOpenModal(!openModal);
    const [elementType, setElementType] = useState('')
    const [taskFieldObj, setTaskFieldObj] = useState(null);
    const [taskFieldConfigObj, setTaskFieldConfigObj] = useState(null);
    const [formulaTypeObj, setFormulaTypeObj] = useState({})
    const [functionSelect, setFunctionSelect] = useState('')
    const [openFormulaPopup, setFormulaPopUp] = useState(false);
    const [fieldSelect, setFieldSelect] = useState('')
    const [operatorSelect, setOperatorSelect] = useState('')
    const [formulaExpression, setFormulaExpression] = useState('')
    const [syntaxError, setSyntaxError] = useState(null);
    const [createFieldPopUp, setCreateFieldPopUp] = useState(false);
    const [taskFieldAction, setTaskFieldAction] = useState('');
    const [isTaskFieldExist, setTaskFieldExist] = useState(false);
    const [taskCustumeFieldList, setTaskCustumFieldList] = useState([])
    const [showLookupItems, setShowLookupItems] = useState(false);

    const [lookUpSearch, setLookUpSearch] = useState(null);

    const [dealFields,setDealFields]=useState([])

    const addEditFieldToggle = () => setAddEditFiels(!addEditField);


    const [options, setOptions] = useState([1]);
    const handleAddOption = () => {
        const opt = [...options]
        let value = opt[opt.length - 1];
        value = parseInt(value) + 1
        opt.push(value);
        setOptions(opt);
    };
    useEffect(()=>{
        getFieldHeaders()
    },[])

    const getFieldHeaders = () => {
        // if(headerData.length===0){
        FieldService.GetFields('all', 'Deals')
            .then((result) => {
            
                setDealFields(result);
            })
            .catch((err) => {
                console.log(err);
            })
        // }
    }

    const addLookUpFields = (label, name, value) => {
      let bacurl = Config.backend_url;
        const parentObj = { ...props.data }
        const obj = {};
        obj.Type = 'LookUp';
        obj.Name = name;
        obj.Label = label;
        obj.Value = { Value: value };
        obj.TaskId = parentObj.id;
        obj.TaskName = parentObj.TaskName;
        obj.DealId = props.dealObj.id;
        obj.UserName = localStorage.getItem('UserName');
        obj.UserId = localStorage.getItem('UserId');
        obj.Config = props.taskFieldConfigObj;
        obj.Assign = [{ DealId: props.dealObj.id, TaskName: parentObj.TaskName, TaskId: parentObj.id }];
        // httpService.CreateUpdate('createTaskField', obj)
        axios.post(bacurl +'createTaskField', obj)
          .then((result) => {
            props.GetAllTaskCustumeFieldList(parentObj.id)
            props.setTaskFieldConfigObj(null);
            setTaskFieldObj(null)
            // addLookUpFieldsToggle();
            // setAddLookupFieldsPopUp(true);
            setOpenModal(true)
            //swal('Create Success');
          })
          .catch((err) => {
            swal(`${err}`, { dangerMode: true });
          })
      }
   

    useEffect(() => {
        if (props.modal) {
            setOpenModal(props.modal);
            setTaskFieldAction(props.taskFieldAction)
        }
    }, [props.modal])

    const handleOnChangeSearchLookUpFields = (e) => {
        if (e.target.value == '') {
          setLookUpSearch(null);
        } else {
          setLookUpSearch(e.target.value.toLowerCase());
        }
    
      }

      const getFullDate = (date) => {
        let date1 = new Date(date);
        return (
          (date1.getMonth() + 1 < 10 ? "0" : "") +
          (date1.getMonth() + 1) +
          "/" +
          (date1.getDate() < 10 ? "0" : "") +
          date1.getDate() +
          "/" +
          date1.getFullYear()
        );
      };

    const toggleHandleLookUp=()=>{
        setOpenModal(!openModal);
        props.onClose()
    }

    const renderAddLookUpFieldsModal = () => {
        return <Modal
            isOpen={openModal}
            backdrop={backdrop}
            // toggle={addLookUpFieldsToggle} 
            size='sm'
            className="customFieldModal deal-table"
            id="manageFieldModal"
        >
            <ModalHeader className="text-center" 
             toggle={toggleHandleLookUp}
            >

                <Row>
                    <Col sm={12}>
                        LOOKUP FIELDS
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        {/* <button onClick={() => handleOpenCreateField('Formula')} className="btn btn-sm btn-primary">Fx Formula</button> */}
                    </Col>
                </Row>
            </ModalHeader>



            <div className="fieldSearch w-100">
                <input name='LookUpSearch' onChange={handleOnChangeSearchLookUpFields} className="form-control" type="text" placeholder="Search..." />
                <FontAwesomeIcon icon={faSearch} />
            </div>
            <ModalBody style={{ overflowY: "auto", maxHeight: "465px" }}>
                {/* <h6 className="addCustomField-title">LOOKUP FIELDS</h6> */}

                <table className='table table-sm table-hover addCustomField-table addLookupFieldTbl'>
                    {dealFields.map(field => {
                        let dataType=field.data_type;
                        let jsonType=field.json_type;
                        if (jsonType!=='string' ) {
                            return;
                        }
                        if (lookUpSearch) {
                            if (field.field_label.toLowerCase().includes(lookUpSearch)) {
                                return <tr>
                                    <td>
                                        <FontAwesomeIcon icon={faEye} className="field-icon" />
                                        {field.field_label}
                                    </td>
                                    <td>
                                        NA
                                        {/* {field.field_label == 'Closing Date' ? getFullDate(new Date(props.dealObj[field.api_name])) : props.dealObj[field.api_name]} */}
                                    </td>
                                    <td 
                                    onClick={() => addLookUpFields(field.field_label, field.field_label,  props.dealObj[field.api_name])} 
                                    
                                    style={{ cursor: 'pointer' }} className="labels">
                                        <span  ><FontAwesomeIcon icon={faPlusCircle} color='red' /> Add</span>
                                    </td>
                                </tr>
                            }
                        }
                        if (!lookUpSearch) {
                            return <tr>
                                <td>
                                    <FontAwesomeIcon icon={faEye} className="field-icon" />
                                    {field.field_label}
                                    {/* {getSpaceBetweenCamelString(key)} */}
                                </td>
                                <td>
                                  
                                    {field.field_label == 'Closing Date' ? getFullDate(new Date(props.dealObj[field.api_name])) : props.dealObj[field.api_name]}
                                </td>
                                <td style={{ cursor: 'pointer' }} 
                                onClick={() => addLookUpFields(field.field_label, field.field_label,  props.dealObj[field.api_name])} 
                                
                                className="labels">
                                    <span><FontAwesomeIcon icon={faPlusCircle} color='red' /> Add</span>
                                </td>
                            </tr>
                        }

                    })}


                </table>


            </ModalBody>
        </Modal>
    }

    


    return (
        <>
            {renderAddLookUpFieldsModal()}
        </>

    );
}

export default LookUpFields;