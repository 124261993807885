import {
  faCommentDots,
  faListAlt,
  faThumbsUp,
} from "@fortawesome/free-regular-svg-icons";
import {
  faEdit,
  faReply,
  faSearchDollar,
  faTrash,
  faThumbsUp as faThumbsUpSolid,
  faThumbtack,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";
import "antd/dist/reset.css";
import { Dropdown as AntDropDown } from "antd";
import swal from "sweetalert";
import CreateNoteModal from "./createNotemodal";
import defaultUser from "../../assets/images/defaultUser.png";
import dayjs from "dayjs";
import Config from "../../config.json";
import "./notes.css";
import ChatService from "../../common/util/ChatService";
import UserService from "../../common/util/UserService";
import Comment from "../../common/Comment/comment";
import TaskService from "../../common/util/TaskService";
import { EmojiReactionPicker } from "../../common/EmojiReactionPicker/EmojiReactionPicker";
import { AllPinnedMessageCollapse } from "../../common/chat/CollapseOptions/AllPinedMessageCollapse";
import PinChatIcon from "../../common/chat/PinChatIcon.png";
const Notes = (props) => {
  const history = props.history;
  const [modalOpen, setModalOpen] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [titleVal, setTitleVal] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [allData, setAllData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [actionOnNoteData, setActionOnNoteData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [pinnedNotes, setPinnedNotes] = useState(null);
  const [allTask, setAllTask] = useState([]);
  const [isPinnedExpandOpen, setIsPinnedExpandOpen] = useState(false);
  const [allPinnedNotes, setAllPinnedNotes] = useState([]);
  const [myProfile, setMyProfile] = useState({});
  const [section, setSection] = useState("Notes");
  useEffect(() => {
    TaskService.GetMyTasks()
      .then((result) => {
        setAllTask(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getTime = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;

    /// date
    if (
      new Date().toLocaleDateString() !== new Date(date).toLocaleDateString()
    ) {
      let dateTime = new Date(date);
      let str = "";
      str = str + dateTime.toLocaleString("en-us", { month: "short" });
      str = str + " " + dateTime.getDate();
      /// year
      if (new Date().getFullYear() !== new Date(date).getFullYear()) {
        strTime = str + " , " + dateTime.getFullYear();
      }
    }
    return strTime;
  };

  useEffect((e) => {
    let userString = localStorage.getItem("userinfo");
    if (userString) {
      userString = JSON.parse(userString);
      userString["name"] = userString.first_name + " " + userString.last_name;
      setMyProfile(userString);
    }
  }, []);

  //Hrushi new methods and code
  const AddReactionToMessage = async (messageId, reaction) => {
    let payLoad = {
      Type: "Reaction",
      method: "POST",
      url:
        "https://cliq.zoho.com/api/v2/chats/" +
        chatId +
        "/messages/" +
        messageId +
        "/reactions",
      headers: { Authorization: localStorage.getItem("token") },
      data: { emoji_code: reaction },
    };
    await axios
      .post(Config.backend_url + "chat", { config: payLoad })
      .then((res) => {
        let newArr = notesData?.map((e) => {
          if (e.id && e.id == messageId) {
            if (reaction != "👍") {
              e["my_reactions"]?.push({
                emoji: reaction,
                user: localStorage.getItem("ZUID"),
              });
            } else {
              e["my_like"] = { emoji: reaction, user: "" };
            }
          }
          return e;
        });
        setNotesData(newArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const RemoveReactionToMessage = async (messageId, reaction) => {
    let payLoad = {
      Type: "Reaction",
      method: "DELETE",
      url:
        "https://cliq.zoho.com/api/v2/chats/" +
        chatId +
        "/messages/" +
        messageId +
        "/reactions",
      headers: { Authorization: localStorage.getItem("token") },
      data: { emoji_code: reaction },
    };
    axios
      .post(Config.backend_url + "chat", { config: payLoad })
      .then((res) => {
        let newArr = notesData?.map((e) => {
          if (e.id && e.id == messageId) {
            if (reaction != "👍") {
              e["my_reactions"] = e["my_reactions"]?.map(
                (k) => k.emoji != e.emoji
              );
            } else {
              e["my_like"] = null;
            }
          }
          return e;
        });
        setNotesData(newArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(
    (e) => {
      if (chatId) {
        ChatService.GetAllPinnedChatsFromZoho(chatId, myProfile)
          .then(function (data) {
            if (data?.data?.data) {
              setAllPinnedNotes(data.data.data);
            } else {
              setAllPinnedNotes([]);
            }
          })
          .catch((err) => {
            setAllPinnedNotes([]);
            console.log(err);
          });
      } else {
        setAllPinnedNotes([]);
      }
      setIsPinnedExpandOpen(false);
    },
    [chatId]
  );

  useEffect(
    (e) => {
      allPinnedNotes?.forEach((e) => {
        focuseThePinnedNote(e?.id);
      });
    },
    [pinnedNotes]
  );

  useEffect(
    (e) => {
      if (allPinnedNotes.length > 0) {
        setPinnedNotes(allPinnedNotes.find((e) => e?.primary == true));
      } else {
        setPinnedNotes(null);
      }
    },
    [allPinnedNotes]
  );

  const handlePinNotes = async (one) => {
    let replacePinned = pinnedNotes != null ? true : false;
    await ChatService.PinMessagesInZoho(chatId, one.id, replacePinned, "");
    ChatService.GetAllPinnedChatsFromZoho(chatId, myProfile)
      .then((e) => {
        if (e?.data?.data) {
          setAllPinnedNotes(e?.data?.data);
        } else {
          setAllPinnedNotes([]);
        }
      })
      .catch((e) => {
        setAllPinnedNotes([]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function focuseThePinnedNote(id) {
    if (pinnedNotes) {
      let classId = `notes-body${id?.replaceAll("_", "")}`;
      if (classId) {
        let dom = document.querySelector(`#${classId}`);
        if (dom) {
          dom.classList.add("Pinned-Note-Class");
        }
      }
    }
  }

  function FocusIntoView(id) {
    focuseThePinnedNote(id);
    let classId = `Highlight${id?.replaceAll("_", "")}`;
    if (classId) {
      let dom = document.getElementById(classId);
      if (dom) {
        dom.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  function getReactionStatus(noteId) {
    let temp = [...notesData];
    let noteReaction = temp?.find((n) => n.id == noteId);
    if (noteReaction) {
      if (noteReaction.my_like) {
        return true;
      }
    }
    return false;
  }
  //Hrushi new methods end

  const toggle = () => {
    setEditorData("");
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if (props.job) {
      setChatId(props.job.Notes_ID);
    }
  }, [props.job]);

  useEffect(() => {
    if (chatId) {
      getAllChatMessage();
    }
  }, [chatId]);

  const getAllUsers = async () => {
    UserService.GetAllUsers()
      .then((result) => {
        setAllUsers(result.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isUserPerformAction = (userId) => {
    let isAction = false;
    let all = [...allUsers];
    all = all.filter((user) => user.zuid == userId);
    if (all.length > 0) {
      all = all[0];
      if (all.role.name == "CEO") {
        isAction = true;
      }
    }
    return isAction;
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (props.refreshEnable) {
      getAllChatMessage();
    }
  }, [props.refreshEnable]);

  const modifySingleChat = (chat, UID) => {
    let myReactions = [];
    let othersReactions = [];
    chat["my_like"] = null;
    if (chat.reaction) {
      Object.keys(chat.reaction).forEach((key, i) => {
        let isFound = false;
        chat.reaction[key]?.forEach((d) => {
          if (d) {
            if (d.includes(UID)) {
              if (key == "👍") {
                chat["my_like"] = { emoji: key, user: d };
              } else {
                myReactions.push({ emoji: key, user: d });
              }
            } else if (!d.includes(UID)) {
              othersReactions.push({ emoji: key, user: d });
            }
          }
        });
      });
    }
    chat["my_reactions"] = myReactions;
    chat["others_reactions"] = othersReactions;
    return chat;
  };

  const modifyChatData = (oldData) => {
    let UID = localStorage.getItem("ZUID");
    let newData = [];
    if (oldData) {
      oldData.forEach((chat) => {
        let newChat = modifySingleChat(chat, UID);
        if (newChat.replied_to) {
          newChat["parent_id"] = newChat.replied_to?.id;
        }
        newData.push(newChat);
      });
    }
    return newData;
  };
  const getAllChatMessage = () => {
    let newChatData = [];
    ChatService.GetChatMessage(chatId)
      .then((notes) => {
        notes.data.data = notes.data.data.filter((one) => one.type == "text");
        if (notes.data.data) {
          let all = [...notesData];
          notes.data.data.forEach((one) => {
            let filterObj = all.filter((oneObj) => oneObj.id == one.id);
            if (filterObj.length > 0) {
              filterObj = filterObj[0];
              one.OpenComment = filterObj.OpenComment;
              one.OpenReply = filterObj.OpenReply;
            }
          });
          newChatData = modifyChatData(notes.data.data);
          setNotesData(newChatData);
        }
        setAllData(newChatData);
        if (props.setRefreshEnable) {
          props.setRefreshEnable(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNoteActions = (one) => {
    const items = [
      {
        label: (
          <span
            onClick={() => {
              one.sender.id == localStorage.getItem("ZUID")
                ? setActionOnNoteData({
                    action: "Edit",
                    note: one,
                  })
                : alert("You don't have access!");
            }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              style={{ fontSize: "12px", opacity: "0.3" }}
            />{" "}
            Edit
          </span>
        ),
        key: "0",
      },
      {
        label: (
          <span
            onClick={() => {
              one.sender.id == localStorage.getItem("ZUID")
                ? swal({
                    title: "Are you sure want to delete this note?",
                    text: "",
                    icon: "warning",
                    buttons: ["No", "Yes"],
                    dangerMode: true,
                  }).then(function (isConfirm) {
                    if (isConfirm) {
                      ChatService.DeleteUserChat(chatId, one.id)
                        .then((result) => {
                          getAllChatMessage();
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  })
                : alert("You don't have access!");
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ fontSize: "12px", opacity: "0.3" }}
            />{" "}
            Delete
          </span>
        ),
        key: "1",
      },
      {
        label: (
          <span
            onClick={() => {
              setActionOnNoteData({
                action: "Pin",
                note: one,
              });
              handlePinNotes(one);
            }}
          >
            <FontAwesomeIcon
              icon={faThumbtack}
              style={{ fontSize: "12px", opacity: "0.3" }}
            />{" "}
            Pin Note
          </span>
        ),
        key: "2",
      },
    ];
    return (
      <div className="job-action-box">
        <AntDropDown
          style={{ color: "none" }}
          className=" action"
          menu={{
            items,
          }}
          arrow={{
            pointAtCenter: true,
          }}
          trigger={["click"]}
        >
          <span style={{ cursor: "pointer" }}>
            <span>Action</span>
            <FontAwesomeIcon
              className="Action-Btn-Ant-Dropdown"
              icon={faCaretDown}
              style={{ margin: "0px 6px 0px 2px" }}
            />
          </span>
        </AntDropDown>
      </div>
    );
  };

  const getCommentsData = (noteID) => {
    let filterComments = allData.filter((one) => {
      if (one.replied_to) {
        return one;
      }
    });
    let commentsPerId = filterComments.filter((one) => {
      let id = one.replied_to.id;
      id = id.split("%");
      let firstId = id[0];
      let secondId = id[1];
      secondId = secondId.replace("20", "");
      noteID = noteID.replace("_", "");
      if (firstId + secondId == noteID) {
        return one;
      }
    });
    return commentsPerId;
  };

  const getReplyData = (noteID) => {
    let filterComments = allData.filter((one) => {
      if (one.replied_to) {
        return one;
      }
    });
    let commentsPerId = filterComments.filter((one) => {
      let id = one.replied_to.id;
      id = id.split("%");
      let firstId = id[0];
      let secondId = id[1];
      secondId = secondId.replace("20", "");
      noteID = noteID.replace("_", "");
      if (firstId + secondId == noteID) {
        return one;
      }
    });
    return commentsPerId;
  };

  const convertZohoClickToCRM = (message) => {
    const users = [...allUsers];
    users.forEach((one) => {
      let fromStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.full_name}</span>`;
      let toStr = `<span uid="${one.zuid}" elemtype="contact" contenteditable="false" class="selusr sel" nodetype="@" data-title="@${one.first_name}" title="@Aditya">@${one.first_name}</span>`;
      message = message.toString("html");
      message = message?.replaceAll(fromStr, "{@" + one.zuid + "}");
      let fromFullNameStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.first_name}</span>`;
      message = message?.replaceAll(fromFullNameStr, "{@" + one.zuid + "}");
    });
    return message;
  };

  const sendNotification = (message) => {
    // const users = [...allUsers];
    // users.forEach((one) => {
    //     if (message.includes("{@" + one.zuid + "}")) {
    //         one.isMention = true;
    //     }
    // })
    // if (users.filter(one => one.isMention).length > 0) {
    //     let obj = {
    //         "signal_namespace": "zohocliqmention_zohocliqmentionuser",
    //         "email": "david@srsolardesign.com",
    //         "subject": "Mention David",
    //         "message": `In Deal ${props.job.Deal_Name} Notes Mention You`,
    //         "actions": [
    //             {
    //                 "type": "link",
    //                 "open_in": "popup",
    //                 "display_name": "View Notes",
    //                 "url": "www.google.com"
    //             }
    //         ]
    //     }
    //     NotificationAPI.Trigger(obj)
    //         .then((result) => {
    //             console.log(result);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }
  };
  const addComments = async (message, id) => {
    message = message.toString("html");
    allUsers?.forEach((e, i) => {
      message = message?.replaceAll(`@${e.full_name}`, `{@${e.zuid}}`);
    });
    let content = convertZohoClickToCRM(message);
    content = content
      .replace("<p>", "")
      .replace("</p>", "")
      .replace("<br>", "")
      .replace("<h1>", "")
      .replace("</h1>", "")
      .replace("<h2>", "")
      .replace("</h2>", "")
      .replace("<h3>", "")
      .replace("</h3>", "")
      .replace("<em>", "")
      .replace("</em>", "")
      .replace("<h1></h1>", "")
      .replace("<h2></h2>", "")
      .replace("<h3></h3>", "")
      .replace("<pre><code></code></pre>", "")
      .replace("<blockquote></blockquote>", "")
      .replace(
        /<ol>\s*<li>\s*<\/li>\s*<\/ol>|<ul>\s*<li>\s*<\/li>\s*<\/ul>/g,
        ""
      );
    if (content?.includes('src=""')) {
      content = content.replace('<img src=""/>', "");
    }
    let messageVal = content;

    messageVal = messageVal?.replaceAll("&nbsp;", "");
    if (actionOnNoteData.action == "Edit" && actionOnNoteData.note) {
      if (messageVal?.trim()) {
        ChatService.UpdateUserChat(chatId, id, messageVal)
          .then(function (data) {
            setActionOnNoteData({});
          })
          .then((result) => {
            getAllChatMessage();
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          });
      } else {
        alert("Enter something to create a note!");
      }
    } else {
      if (messageVal?.trim()) {
        ChatService.ReplyToUser(chatId, id, messageVal)
          .then(async function (data) {
            sendNotification(messageVal);
            getAllChatMessage();
            let clientColor = await TaskService.GetClientColor(
              props.job.Account_Name.id
            );
            let dealColor = await TaskService.GetDealColor(props.job.Stage);
            let userInfo = JSON.parse(localStorage.getItem("userinfo"));
            let text = `<a style="color:#40a9ff" id="User-${
              userInfo?.email_id
            }">${
              userInfo?.first_name + " " + userInfo?.last_name
            }</a>  <span>mentioned you in a </span>  <a style="color:#40a9ff" id="Comment-${id.replace(
              "_",
              "%20"
            )}">Note</a> <a style="background-color:${clientColor}" id="Client-${
              props.job.Account_Name.id
            }">${
              props.job.Account_Name ? props.job.Account_Name.name : ""
            }</a>  <a style="background-color:${dealColor}" id="Deal-${
              props.job.id
            }">${props.job.Deal_Name}</a>  `;
            return TaskService.SendMentionNotification(
              messageVal,
              allUsers,
              text,
              "Notes"
            );
          })
          .then((result) => {
            if (props.getNotification) {
              props.getNotification();
            }
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          });
      } else {
        alert("Enter something to create a note!");
      }
    }
  };

  const addReply = async (message, id, noteId) => {
    message = message.toString("html");
    allUsers?.forEach((e, i) => {
      message = message?.replaceAll(`@${e.full_name}`, `{@${e.zuid}}`);
    });
    let content = convertZohoClickToCRM(message);
    content = content
      .replace("<p>", "")
      .replace("</p>", "")
      .replace("<br>", "")
      .replace("<h1>", "")
      .replace("</h1>", "")
      .replace("<h2>", "")
      .replace("</h2>", "")
      .replace("<h3>", "")
      .replace("</h3>", "")
      .replace("<em>", "")
      .replace("</em>", "")
      .replace("<h1></h1>", "")
      .replace("<h2></h2>", "")
      .replace("<h3></h3>", "")
      .replace("<pre><code></code></pre>", "")
      .replace("<blockquote></blockquote>", "")
      .replace(
        /<ol>\s*<li>\s*<\/li>\s*<\/ol>|<ul>\s*<li>\s*<\/li>\s*<\/ul>/g,
        ""
      );
    if (content?.includes('src=""')) {
      content = content.replace('<img src=""/>', "");
    }
    let messageVal = content;
    messageVal = messageVal?.replaceAll("&nbsp;", "");
    if (actionOnNoteData.action == "Edit" && actionOnNoteData.note) {
      if (messageVal?.trim()) {
        ChatService.UpdateUserChat(chatId, id, messageVal)
          .then(function (data) {
            setActionOnNoteData({});
            sendNotification(messageVal);
          })
          .then((result) => {
            getAllChatMessage();
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          });
      } else {
        alert("Enter sonmething to comment!");
      }
    } else {
      if (messageVal?.trim()) {
        ChatService.ReplyToUser(chatId, id, messageVal)
          .then(async function (data) {
            console.log(data);
            let clientColor = await TaskService.GetClientColor(
              props.job.Account_Name.id
            );
            let dealColor = await TaskService.GetDealColor(props.job.Stage);
            let userInfo = JSON.parse(localStorage.getItem("userinfo"));
            let text = `<a style="color:#40a9ff" id="User-${
              userInfo?.email_id
            }">${
              userInfo?.first_name + " " + userInfo?.last_name
            }</a> <span>mentioned you in a </span> <a style="color:#40a9ff" id="Reply-${noteId.replace(
              "_",
              "%20"
            )}-${id.replace(
              "_",
              "%20"
            )}">Note</a>  <a style="background-color:${clientColor}" id="Client-${
              props.job.Account_Name.id
            }">${
              props.job.Account_Name ? props.job.Account_Name.name : ""
            }</a>   <a style="background-color:${dealColor}" id="Deal-${
              props.job.id
            }">${props.job.Deal_Name}</a>  `;
            return TaskService.SendMentionNotification(
              messageVal,
              allUsers,
              text,
              "Notes"
            );
          })
          .then((result) => {
            if (props.getNotification) {
              props.getNotification();
            }
            getAllChatMessage();
          })
          .catch((err) => {
            console.log(err);
            alert(err);
          });
      } else {
        alert("Enter sonmething to comment!");
      }
    }
  };

  const handleClickMention = async (e) => {
    if (e.target.id.startsWith("User") || e.target.id.startsWith("Task")) {
      let span = e.target;
      if (span) {
        let text = span.innerText;
        if (text.startsWith("@")) {
          let all = [...allUsers];
          let id = span.id.replace("User-", "");
          all = all.filter((one) => one.zuid == id);
          if (all.length > 0) {
            all = all[0];
            history.push({
              pathname: `/setting/users/${all.id}/Users`,
              state: { tab: "Users" },
            });
          }
        } else if (text.startsWith("#")) {
          //   let id=span.id.replace("Task-",'')
          //   id=id.split('-');
          //   id=id.filter(one=>one);
          //   let res=await ProjectService.GetProjectDetail(id[1])
          //   TaskService.GetTaskDetail(res.id_string,res.name,id[0])
          //   .then((result)=>{
          //     const arr = [...openTaskArray]
          //     arr.push(result);
          //     setOpenTaskArr(arr);
          //     setTaskData(result)
          //   })
          //   .catch((err)=>{
          //     console.log(err);
          //   })
        }
      }
    }
  };

  const getNotesData = (noteData, placeholder, style) => {
    if (
      actionOnNoteData &&
      actionOnNoteData.action == "Edit" &&
      actionOnNoteData.note &&
      actionOnNoteData.note.id == noteData.id
    ) {
      return (
        <Comment
          alltasks={allTask}
          allusers={allUsers}
          action="Edit"
          value={noteData.content.text}
          commentId={noteData.id}
          placeholder={placeholder}
          onSave={(message) => addComments(message, noteData.id)}
          id="notes"
          onClose={() => setActionOnNoteData({})}
        />
      );
    } else {
      return (
        <div
          className="note-container-header"
          onClick={(e) => handleClickMention(e)}
          dangerouslySetInnerHTML={{
            __html: convertZohoClickMessageToCRM1(noteData.content.text),
          }}
        ></div>
      );
    }
  };
  const convertZohoClickMessageToCRM1 = (message) => {
    const users = [...allUsers];
    users.forEach((one) => {
      let fromStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.first_name}</span>`;
      if (message) {
        message = message.toString("html");
        message = message?.replaceAll("{@" + one.zuid + "}", fromStr);
      } else {
        message = "";
      }
    });
    return `<p>${message}</p>`;
  };

  const returnNOtes = () => {
    let allNotes = getSearchData(searchText);
    allNotes.sort((a, b) => new Date(b.time) - new Date(a.time));

    return allNotes
      ? allNotes?.map((one, noteIndex) => {
          let isPinned = allPinnedNotes?.find((e) => e.id == one.id);
          if (one.replied_to) {
            return <></>;
          }

          var firstDated = dayjs(one.time).format("LLL");

          return (
            <>
              <div
                className="media note-comment-box"
                id={`Highlight${one.id?.replaceAll("_", "")}`}
              >
                <div className="notes_card_profile">
                  <img
                    class="card-img-top"
                    src={UserService.GetUserPhotoUrl(one.sender.id)}
                    alt="Card image cap"
                  />
                </div>

                <div className="flex-grow-1">
                  <div
                    id={"notes-body" + one.id.replace("_", "")}
                    className={`media-body py-2 new-class ${
                      isPinned && "Pinned-Note-Class"
                    }`}
                  >
                    <Row className="note-container-header">
                      <Col
                        sm={4}
                        className="note-caret-pointer"
                        id={"comment" + one.id.replace("_", "")}
                      >
                        <strong> Note </strong>&nbsp;by {one.sender.name}
                        {isPinned && (
                          <div className="Pin-Icon">
                            <img src={PinChatIcon}></img>
                          </div>
                        )}
                      </Col>
                      <Col sm={4}></Col>
                      <Col sm={4}>
                        <div className="comment-action">
                          {getNoteActions(one)} <span>{firstDated}</span>
                        </div>
                      </Col>
                    </Row>
                    {getNotesData(one, "Comment Here...")}
                    <UncontrolledCollapse
                      className="px-3 pb-2 comment-body"
                      toggler={"comment" + one.id.replace("_", "")}
                      defaultOpen={true}
                    >
                      {getCommentsData(one.id).length > 0 ? (
                        <>
                          <div className="comment-text-hyperlink">
                            <div className="reaction-comment-wrapper">
                              {getReactionStatus(one.id) ? (
                                <FontAwesomeIcon
                                  icon={faThumbsUpSolid}
                                  onClick={(e) => {
                                    console.log(one);
                                    e.stopPropagation();
                                    RemoveReactionToMessage(one.id, "👍");
                                  }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faThumbsUp}
                                  onClick={(e) => {
                                    console.log(one);
                                    e.stopPropagation();
                                    AddReactionToMessage(one.id, "👍");
                                  }}
                                />
                              )}
                              <EmojiReactionPicker
                                setNotesData={setNotesData}
                                chatId={chatId}
                                notesReactions={notesData}
                                messageId={one.id}
                              />
                              {"  "}|{"  "}
                              <span
                                id={"UniqueComment" + one.id.replace("_", "")}
                                onClick={() => {
                                  let all = [...notesData];
                                  all.forEach((noteObj) => {
                                    if (noteObj.id == one.id) {
                                      noteObj.OpenComment =
                                        !noteObj.OpenComment;
                                    }
                                  });
                                  setNotesData(all);
                                  setAllData(all);
                                }}
                              >
                                <FontAwesomeIcon icon={faCommentDots} />
                                {"     "}{" "}
                                <span>
                                  {one.OpenComment
                                    ? "Hide Comments (" +
                                      getCommentsData(one.id).length +
                                      ")"
                                    : "Add Comments (" +
                                      getCommentsData(one.id).length +
                                      ")"}
                                </span>
                              </span>
                            </div>
                          </div>

                          <UncontrolledCollapse
                            isOpen={one.OpenComment}
                            toggler={"UniqueComment" + one.id.replace("_", "")}
                          >
                            <div className="media note-comment-box">
                              <div className="flex-grow-1">
                                <div
                                  id={"notes-body" + one.id.replace("_", "")}
                                  className="media-body py-2 note-comment-body"
                                >
                                  {getCommentsData(one.id)?.map(
                                    (comment, i) => {
                                      var dated = dayjs(comment.time).format(
                                        "LLL"
                                      );
                                      return (
                                        <>
                                          <Row className="comment-headername">
                                            <span
                                              style={{
                                                width: "25px",
                                                marginLeft: "16px",
                                              }}
                                            >
                                              <img
                                                class="card-img-top"
                                                src={UserService.GetUserPhotoUrl(
                                                  comment.sender.id
                                                )}
                                                alt="Card image cap"
                                              />
                                            </span>
                                            <Col sm={4} className="pl-2">
                                              <strong>Comment </strong> by{" "}
                                              {comment.sender.name}
                                            </Col>
                                            <Col
                                              sm={6}
                                              className="comment-action ml-auto"
                                            >
                                              <UncontrolledDropdown className="note-action-drop">
                                                <DropdownToggle caret>
                                                  Action
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  {comment.sender.id ==
                                                  localStorage.getItem(
                                                    "ZUID"
                                                  ) ? (
                                                    <DropdownItem
                                                      className="cursor-pointer"
                                                      onClick={() => {
                                                        setActionOnNoteData({
                                                          action: "Edit",
                                                          note: comment,
                                                        });
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faEdit}
                                                      />{" "}
                                                      Edit
                                                    </DropdownItem>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  {comment.sender.id ==
                                                    localStorage.getItem(
                                                      "ZUID"
                                                    ) ||
                                                  isUserPerformAction(
                                                    localStorage.getItem("ZUID")
                                                  ) ? (
                                                    <DropdownItem
                                                      className="cursor-pointer"
                                                      onClick={() => {
                                                        swal({
                                                          title:
                                                            "Are you sure? want to delete this comment",
                                                          text: "",
                                                          icon: "warning",
                                                          buttons: [
                                                            "No",
                                                            "Yes",
                                                          ],
                                                          dangerMode: true,
                                                        }).then(function (
                                                          isConfirm
                                                        ) {
                                                          if (isConfirm) {
                                                            ChatService.DeleteUserChat(
                                                              chatId,
                                                              comment.id
                                                            )
                                                              .then(
                                                                (result) => {
                                                                  getAllChatMessage();
                                                                }
                                                              )
                                                              .catch((err) => {
                                                                console.log(
                                                                  err
                                                                );
                                                              });
                                                          }
                                                        });
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faTrash}
                                                      />{" "}
                                                      Delete
                                                    </DropdownItem>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                              <span>{dated}</span>
                                            </Col>
                                          </Row>
                                          {getNotesData(
                                            comment,
                                            "Comment Here...",
                                            { marginLeft: "35px" }
                                          )}
                                          <Row className="reply-row">
                                            {getReplyData(comment.id).length >
                                            0 ? (
                                              <>
                                                <div className="comment-text-hyperlink">
                                                  <div className="reaction-comment-wrapper">
                                                    {getReactionStatus(
                                                      comment.id
                                                    ) ? (
                                                      <FontAwesomeIcon
                                                        icon={faThumbsUpSolid}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          console.log(
                                                            comment.id
                                                          );
                                                          RemoveReactionToMessage(
                                                            comment.id,
                                                            "👍"
                                                          );
                                                        }}
                                                      />
                                                    ) : (
                                                      <FontAwesomeIcon
                                                        icon={faThumbsUp}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          console.log(one);
                                                          AddReactionToMessage(
                                                            comment.id,
                                                            "👍"
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                    <EmojiReactionPicker
                                                      setNotesData={
                                                        setNotesData
                                                      }
                                                      chatId={chatId}
                                                      notesReactions={notesData}
                                                      messageId={comment.id}
                                                    />
                                                    {"  "}|{"  "}
                                                    <span
                                                      id={
                                                        "UniqueReply" +
                                                        comment.id.replace(
                                                          "_",
                                                          ""
                                                        )
                                                      }
                                                      onClick={() => {
                                                        let all = [
                                                          ...notesData,
                                                        ];
                                                        all.forEach(
                                                          (noteObj) => {
                                                            if (
                                                              noteObj.id ==
                                                              comment.id
                                                            ) {
                                                              noteObj.OpenReply =
                                                                !noteObj.OpenReply;
                                                            }
                                                          }
                                                        );
                                                        setNotesData(all);
                                                        setAllData(all);
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faReply}
                                                      />
                                                      {"     "}{" "}
                                                      <span>
                                                        {comment.OpenReply
                                                          ? "Hide Reply (" +
                                                            getReplyData(
                                                              comment.id
                                                            ).length +
                                                            ")"
                                                          : "Add Reply (" +
                                                            getReplyData(
                                                              comment.id
                                                            ).length +
                                                            ")"}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>

                                                <UncontrolledCollapse
                                                  isOpen={comment.OpenReply}
                                                  toggler={
                                                    "UniqueReply" +
                                                    comment.id.replace("_", "")
                                                  }
                                                >
                                                  <div className="media note-comment-box note-reply-box">
                                                    <div className="flex-grow-1">
                                                      <div
                                                        id={
                                                          "notes-body" +
                                                          comment.id.replace(
                                                            "_",
                                                            ""
                                                          )
                                                        }
                                                        className="media-body py-2 note-reply-body"
                                                      >
                                                        {getReplyData(
                                                          comment.id
                                                        )?.map((reply, i) => {
                                                          var dated = dayjs(
                                                            reply.time
                                                          ).format("LLL");
                                                          return (
                                                            <>
                                                              <Row className="comment-headername">
                                                                <span
                                                                  style={{
                                                                    width:
                                                                      "25px",
                                                                    marginLeft:
                                                                      "16px",
                                                                  }}
                                                                >
                                                                  <img
                                                                    class="card-img-top"
                                                                    src={UserService.GetUserPhotoUrl(
                                                                      comment
                                                                        .sender
                                                                        .id
                                                                    )}
                                                                    alt="Card image cap"
                                                                  />
                                                                </span>
                                                                <Col
                                                                  sm={4}
                                                                  className="pl-2"
                                                                >
                                                                  <strong>
                                                                    Reply{" "}
                                                                  </strong>{" "}
                                                                  by{" "}
                                                                  {
                                                                    comment
                                                                      .sender
                                                                      .name
                                                                  }
                                                                </Col>
                                                                <Col
                                                                  sm={6}
                                                                  className="comment-action ml-auto"
                                                                >
                                                                  <UncontrolledDropdown className="note-action-drop">
                                                                    <DropdownToggle
                                                                      caret
                                                                    >
                                                                      Action
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                      {reply
                                                                        .sender
                                                                        .id ==
                                                                      localStorage.getItem(
                                                                        "ZUID"
                                                                      ) ? (
                                                                        <DropdownItem
                                                                          className="cursor-pointer"
                                                                          onClick={() => {
                                                                            setActionOnNoteData(
                                                                              {
                                                                                action:
                                                                                  "Edit",
                                                                                note: reply,
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            icon={
                                                                              faEdit
                                                                            }
                                                                          />{" "}
                                                                          Edit
                                                                        </DropdownItem>
                                                                      ) : (
                                                                        <></>
                                                                      )}

                                                                      {reply
                                                                        .sender
                                                                        .id ==
                                                                        localStorage.getItem(
                                                                          "ZUID"
                                                                        ) ||
                                                                      isUserPerformAction(
                                                                        localStorage.getItem(
                                                                          "ZUID"
                                                                        )
                                                                      ) ? (
                                                                        <DropdownItem
                                                                          className="cursor-pointer"
                                                                          onClick={() => {
                                                                            swal(
                                                                              {
                                                                                title:
                                                                                  "Are you sure want to delete this reply?",
                                                                                text: "",
                                                                                icon: "warning",
                                                                                buttons:
                                                                                  [
                                                                                    "No",
                                                                                    "Yes",
                                                                                  ],
                                                                                dangerMode: true,
                                                                              }
                                                                            ).then(
                                                                              function (
                                                                                isConfirm
                                                                              ) {
                                                                                if (
                                                                                  isConfirm
                                                                                ) {
                                                                                  ChatService.DeleteUserChat(
                                                                                    chatId,
                                                                                    reply.id
                                                                                  )
                                                                                    .then(
                                                                                      (
                                                                                        result
                                                                                      ) => {
                                                                                        getAllChatMessage();
                                                                                      }
                                                                                    )
                                                                                    .catch(
                                                                                      (
                                                                                        err
                                                                                      ) => {
                                                                                        console.log(
                                                                                          err
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                }
                                                                              }
                                                                            );
                                                                          }}
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            icon={
                                                                              faTrash
                                                                            }
                                                                          />{" "}
                                                                          Delete
                                                                        </DropdownItem>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </DropdownMenu>
                                                                  </UncontrolledDropdown>
                                                                  <span>
                                                                    {dated}
                                                                  </span>
                                                                </Col>
                                                              </Row>
                                                              {getNotesData(
                                                                reply,
                                                                "Reply Here...",
                                                                {
                                                                  marginLeft:
                                                                    "40px",
                                                                }
                                                              )}
                                                            </>
                                                          );
                                                        })}
                                                        <div>
                                                          <Comment
                                                            alltasks={allTask}
                                                            placeholder="Reply Here..."
                                                            onSave={(message) =>
                                                              addReply(
                                                                message,
                                                                comment.id,
                                                                one.id
                                                              )
                                                            }
                                                            id="notes"
                                                            onClose={() => {}}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </UncontrolledCollapse>
                                              </>
                                            ) : (
                                              <>
                                                <div className="comment-text-hyperlink">
                                                  <div className="reaction-comment-wrapper">
                                                    {getReactionStatus(
                                                      comment.id
                                                    ) ? (
                                                      <FontAwesomeIcon
                                                        icon={faThumbsUpSolid}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          RemoveReactionToMessage(
                                                            comment.id,
                                                            "👍"
                                                          );
                                                        }}
                                                      />
                                                    ) : (
                                                      <FontAwesomeIcon
                                                        icon={faThumbsUp}
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                          AddReactionToMessage(
                                                            comment.id,
                                                            "👍"
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                    <EmojiReactionPicker
                                                      setNotesData={
                                                        setNotesData
                                                      }
                                                      chatId={chatId}
                                                      notesReactions={notesData}
                                                      messageId={comment.id}
                                                    />
                                                    {"  "}|{"  "}
                                                    <span
                                                      id={
                                                        "AddReply" +
                                                        comment.id.replace(
                                                          "_",
                                                          ""
                                                        )
                                                      }
                                                      onClick={() => {
                                                        let all = [
                                                          ...notesData,
                                                        ];
                                                        all.forEach(
                                                          (noteObj) => {
                                                            if (
                                                              noteObj.id ==
                                                              comment.id
                                                            ) {
                                                              noteObj.OpenReply =
                                                                !noteObj.OpenReply;
                                                            }
                                                          }
                                                        );
                                                        setNotesData(all);
                                                        setAllData(all);
                                                      }}
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faReply}
                                                      />
                                                      {"     "}
                                                      <span>
                                                        {comment.OpenReply
                                                          ? "Hide Reply"
                                                          : "Add Reply"}
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>

                                                <UncontrolledCollapse
                                                  isOpen={comment.OpenReply}
                                                  toggler={
                                                    "AddReply" +
                                                    comment.id.replace("_", "")
                                                  }
                                                >
                                                  <div className="media note-comment-box note-reply-box">
                                                    <div className="flex-grow-1">
                                                      <div className="media-body py-2">
                                                        <div>
                                                          <Comment
                                                            alltasks={allTask}
                                                            placeholder="Reply Here..."
                                                            onSave={(message) =>
                                                              addReply(
                                                                message,
                                                                comment.id,
                                                                one.id
                                                              )
                                                            }
                                                            id="notes"
                                                            onClose={() => {}}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </UncontrolledCollapse>
                                              </>
                                            )}
                                          </Row>
                                        </>
                                      );
                                    }
                                  )}
                                  <div>
                                    <Comment
                                      alltasks={allTask}
                                      onSave={(message) =>
                                        addComments(message, one.id)
                                      }
                                      id="notes"
                                      onClose={() => {}}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </UncontrolledCollapse>
                        </>
                      ) : (
                        <>
                          <div className="comment-text-hyperlink">
                            <div className="reaction-comment-wrapper">
                              {getReactionStatus(one.id) ? (
                                <FontAwesomeIcon
                                  icon={faThumbsUpSolid}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    console.log(one);
                                    RemoveReactionToMessage(one.id, "👍");
                                  }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faThumbsUp}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    console.log(one);
                                    AddReactionToMessage(one.id, "👍");
                                  }}
                                />
                              )}
                              <EmojiReactionPicker
                                setNotesData={setNotesData}
                                chatId={chatId}
                                notesReactions={notesData}
                                messageId={one.id}
                              />
                              {"  "}|{"  "}
                              <span
                                id={"AddComment" + one.id.replace("_", "")}
                                onClick={() => {
                                  let all = [...notesData];
                                  all.forEach((noteObj) => {
                                    if (noteObj.id == one.id) {
                                      noteObj.OpenComment =
                                        !noteObj.OpenComment;
                                    }
                                  });
                                  setNotesData(all);
                                  setAllData(all);
                                }}
                                style={{ cursor: "pointer !important" }}
                              >
                                <FontAwesomeIcon icon={faCommentDots} />
                                {"     "}{" "}
                                <span>
                                  {one.OpenComment
                                    ? "Hide Comments"
                                    : "Add Comments"}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="Comments-section-wrapper">
                            <UncontrolledCollapse
                              isOpen={one.OpenComment}
                              toggler={"AddComment" + one.id.replace("_", "")}
                            >
                              <div className="media note-comment-box">
                                <div className="flex-grow-1">
                                  <div className="media-body py-2">
                                    <div>
                                      <Comment
                                        alltasks={allTask}
                                        onSave={(message) =>
                                          addComments(message, one.id)
                                        }
                                        id="notes"
                                        onClose={() => {}}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </UncontrolledCollapse>
                          </div>
                        </>
                      )}
                    </UncontrolledCollapse>
                  </div>
                </div>
              </div>
            </>
          );
        })
      : "";
  };

  const getSearchData = (text) => {
    let all = [...allData];
    if (text !== "") {
      text = text.toLowerCase();
      all = all.filter((one) => {
        let mentions = [];
        if (one.mentions) {
          let keys = Object.keys(one.mentions);
          keys.forEach((key) => {
            mentions.push({
              name: one.mentions[key].name,
              mention_name: one.mentions[key].dname,
            });
          });
        }
        let men = mentions.some(
          (a) =>
            a.mention_name.toLowerCase().includes(text) ||
            a.name.toLowerCase().includes(text)
        );
        if (
          one.sender.name.toLowerCase().includes(text.toLowerCase()) ||
          one.content.text.toLowerCase().includes(text.toLowerCase()) ||
          men
        ) {
          return one;
        }
      });
    }
    return all;
  };

  return (
    <>
      <div className="notes-section">
        <Row className="mar-15 note-header ">
          <Col sm={9} className="pl-0">
            <div class="form-group position-relative has-search  notes-searchbar">
              <input
                type="text"
                placeholder="Search notes..."
                className="form-control form-control-sm workdrive-searchbar"
                value={searchText ? searchText : ""}
                onChange={(e) => {
                  setSearchText(e.currentTarget.value);
                }}
              />
              <FontAwesomeIcon
                className=" note-searh-icon"
                icon={faSearchDollar}
              />
            </div>
          </Col>
          <Col sm={3} className="create-note-box">
            <span
              style={{ borderRadius: "25px", marginTop: "5px" }}
              onClick={toggle}
              className="btn btn-sm btn-primary"
            >
              Create Notes
            </span>
          </Col>
        </Row>

        <div
          className="pin-note-container"
          style={{ display: pinnedNotes !== null ? "" : "none" }}
        >
          <div className="pinned-messages-wrapper">
            <span
              className="pinned-messages"
              dangerouslySetInnerHTML={{
                __html: convertZohoClickMessageToCRM1(
                  pinnedNotes?.content?.text
                ),
              }}
              onClick={(e) => {
                FocusIntoView(pinnedNotes.id);
              }}
            ></span>
            <span
              title="Pinned Notes"
              className="remove-note list-icon"
              onClick={(e) => {
                setIsPinnedExpandOpen(!isPinnedExpandOpen);
              }}
            >
              <FontAwesomeIcon
                color="#018cdc"
                icon={faListAlt}
              ></FontAwesomeIcon>
              <AllPinnedMessageCollapse
                FocusIntoView={FocusIntoView}
                section={section}
                allUsers={allUsers}
                myProfile={myProfile}
                convertZohoClickMessageToCRM1={convertZohoClickMessageToCRM1}
                getTime={getTime}
                isPinnedExpandOpen={isPinnedExpandOpen}
                setIsPinnedExpandOpen={setIsPinnedExpandOpen}
                allPinnedMessageInChat={allPinnedNotes}
                setAllPinnedMessageInChat={setAllPinnedNotes}
                selectUser={[]}
              />
            </span>
          </div>
        </div>

        <div
          className={
            pinnedNotes != null ? "notes-scroller" : "notes-scroller-unpinned"
          }
          onScroll={() => {
            document.querySelectorAll(".dropdown-menu")?.forEach((e) => {
              if (e.classList.contains("show")) {
                e.classList.remove("show");
              }
            });
          }}
        >
          {returnNOtes()}
        </div>
        <CreateNoteModal
          getNotification={props.getNotification}
          allusers={allUsers}
          getAllChatMessage={getAllChatMessage}
          setNotesData={setNotesData}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          job={props.job}
          toggle={toggle}
          module={props.module}
          editorData={editorData}
          setEditorData={setEditorData}
          titleVal={titleVal}
          setTitleVal={setTitleVal}
          notesId={chatId}
        />
      </div>
    </>
  );
};

export default Notes;
