import React from 'react';
import { Button, ListGroup } from 'reactstrap';
import "../CreateVendor.css";

export default function VendorSideBar(props) {

  return (
    <ListGroup className="client-list-sticky">
     <div className='listdiv'>
      <Button
        className={"navbarbtn-top-100"}
        style={props.layoutstate == "Vendor Information"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={() =>{
        props.setLayoutState("Vendor Information");
        localStorage.setItem('currentsidenavbar',"Vendor Information");
        }}
      >
        Vendor Information
      </Button>
      </div>
      {/* <div className='listdiv'>
      <Button
        className={`navbarbtn-top-100 ${props.layoutstate == "BillingDetails"?"Active":""}`}
        style={props.layoutstate == "BillingDetails"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("BillingDetails")){
            e.preventDefault()
            return
          }else{
          localStorage.setItem('currentsidenavbar',"BillingDetails");
          props.setLayoutState("BillingDetails");
          }
        }}
      >
        Billing Details
      </Button>
      </div>
      <div className='listdiv'> 
      <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "ShippingDetails"?"Active":""}`}
         style={props.layoutstate == "ShippingDetails"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("ShippingDetails")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"ShippingDetails");
          props.setLayoutState("ShippingDetails");
        }}
      >
       Shipping Details
      </Button>
      </div>
      <div className='listdiv'>
      <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "FullServices"?"Active":""}`}
         style={props.layoutstate == "FullServices"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("FullServices")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"FullServices");
          props.setLayoutState("FullServices");
        }}
      >
        Full Services
      </Button>
      </div>
      <div className='listdiv'>
      <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "BillingTerms"?"Active":""}`}
         style={props.layoutstate == "BillingTerms"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("BillingTerms")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"BillingTerms");
          props.setLayoutState("BillingTerms");
        }}
      >
        Billing Terms
      </Button>
      </div>
      <div className='listdiv'>
      <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "Services"?"Active":""}`}
         style={props.layoutstate == "Services"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("Services")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"Services");
          props.setLayoutState("Services");
        }}
      >
        Service Adders
      </Button>
      </div> */}
    </ListGroup>
  )
}