import { CCollapse } from '@coreui/react';
import React, { useState } from 'react'
import './collapse.css'
import '../chat.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faEllipsisV, faReply, faSmile, faThumbtack, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { OtsideClickClosingWrapper } from '../../EmojiReactionPicker/OtsideClickClosingWrapper';

export const HorizontalCollapseOptions = ({showByDefault,currentMessage,onClickReply,closeIcon,callBack,onPinnedMessage,massageId,
  onClickReaction}) => {
  const [visible, setVisible] = useState(false);
  const onReply=()=>{
    onClickReply(currentMessage);
  }
  return (
    <OtsideClickClosingWrapper className='Options-Icon' close={(e) => {
      if (visible) {
        setVisible(false)
      }
    }}>
       <CCollapse
        dir=''
        id="collapseWidthExample" horizontal visible={visible || showByDefault}>
          <FontAwesomeIcon
            title='Reply'
            style={{cursor: "pointer"}}
            icon={faReply}
            color='#018cdc'
            onClick={e=>{
              onReply();
            }}
          />
          <FontAwesomeIcon
            title='Pin Chat'
            color='#018cdc'
            style={{cursor: "pointer"}}
            icon={faThumbtack}
            onClick={e=>{
              onPinnedMessage(currentMessage)
            }}
          />
          <FontAwesomeIcon
            title='Pin Chat'
            color='#018cdc'
            style={{cursor: "pointer"}}
            icon={faSmile}
            onClick={e=>{
              // onPinnedMessage(currentMessage)
              onClickReaction(massageId)
            }}
          />
        </CCollapse>
        {closeIcon?
          <FontAwesomeIcon
          className='Three-Dot-Icon'
          icon={faTimes}
          color='red'
          onClick={e=>{
            callBack()
          }}/>
          :
          <></>
          // <FontAwesomeIcon
          // className='Three-Dot-Icon'
          // icon={faEllipsisV}
          // onClick={e=>{
          //   setVisible(!visible)
          // }}
        ///>
        }
       
    </OtsideClickClosingWrapper>
  )
}
