import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import dayjs from "dayjs";
import "./HR.css";
import "antd/dist/reset.css";
import AntdRangePicker from "../../../common/DateRangePicker";
import AttendenceService from "../../../common/util/AttendenceService";
import UserService from "../../../common/util/UserService";
import { GlobalAgGridTable } from "../../Deal/AgGridReactComponent/GlobalAgGridTable";
import AppContext from "../../../Context/AppContext";
import {
  SelectedIdsForTimeClock,
  ActionsOfTimeClock as Actions,
  isDeleteEnable,
} from "../../../Context/AppActions";
import { getTimeInAMPMFormat, modifyTimesheetData } from "./Util";

const WhoIsIn = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [rowData, setRowData] = useState([]);
  const [cacheRowData, setCacheRowData] = useState([]);
  const [allusers, setAllUsers] = useState([]);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [gridApi, setGridApi] = useState(null);
  const [listSelectedRows, setlistSelectedRows] = useState([]);
  const [isEditColumnTrue, setIsEditColumnTrue] = useState();

  useEffect(() => {
    getAllUsers();
    dispatch(isDeleteEnable(true));
  }, []);

  useEffect(() => {
    if (allusers.length > 0) {
      getAllAttendence(startDate, endDate);
    }
  }, [allusers]);

  useEffect(() => {
    if (props.refreshEnable) {
      getAllAttendence(startDate, endDate);
    }
  }, [props.refreshEnable]);

  // const ActionsOfTimeClock = state.ActionsOfTimeClock;

  // useEffect(() => {
  //     if (ActionsOfTimeClock == "Export") {
  //         exportListViewData("newExcelSheet.xlsx")
  //     } else if (ActionsOfTimeClock == "EditColumns") {
  //         setIsEditColumnTrue(true)
  //     }
  //     dispatch(Actions(""))
  // }, [ActionsOfTimeClock])

  // const exportListViewData = (fileName) => {
  //     gridApi?.exportDataAsExcel({
  //         fileName: fileName,
  //         onlySelected: true,
  //         processCellCallback: function (params) {
  //             return params.value;
  //         }
  //     });
  // }

  const onSelectionChanged = (event) => {
    setGridApi(event.api);
    let listTypeRows = event.api.rowModel?.rowsToDisplay.filter((node) =>
      node.isSelected()
    );
    if (
      listTypeRows.length == event.api.rowModel.rowsToDisplay.length &&
      listSelectedRows.length < listTypeRows.length &&
      listSelectedRows.length != 0
    ) {
      event.api.deselectAll();
    } else {
      let array = [];
      event.api.forEachNode((node) => {
        if (node.isSelected()) {
          array.push(node?.data);
        } else {
        }
        // }
      });
      setlistSelectedRows(listTypeRows);
      dispatch(SelectedIdsForTimeClock(array));
    }
  };
  const onFirstDataRendered = (event) => {
    dispatch(SelectedIdsForTimeClock([]));
  };

  const getAllUsers = () => {
    UserService.GetAllUsers()
      .then((result) => {
        result.data.users = result.data.users.filter(
          (one) => one.status == "active"
        );
        setAllUsers(result.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columnsForRequestViewForUser = [
    {
      path: "UserName",
      label: "Name",
      field: "UserName",
      name: "Name",
      type: "text",
      id: 0,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
    },
    {
      key: "I/O",
      path: "I/O",
      label: "I/O",
      field: "I/O",
      name: "I/O",
      type: "text",
      content: (obj, key) => {
        let span = document.createElement("span");
        span.style.fontWeight = "bold";
        if (obj[key] == "IN") {
          span.innerText = "IN";
          span.style.color = "green";
        }else if(obj[key] == "OUT") {
          span.innerText = "OUT";
          span.style.color = "red";
        } else if(obj[key] == "AWAY") {
          span.innerText = "AWAY";
          span.style.color = "#f26c20";
        }
        return span;
      },
      id: 1,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
    },
    {
      path: "Date",
      label: "Date",
      field: "TimesheetDate",
      name: "Date",
      type: "datetime",
      id: 2,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
    },
    {
      path: "Time",
      label: "Time",
      field: "Time",
      name: "Time",
      type: "text",
      id: 3,
      isChecked: false,
      isDragOver: false,
      isSearch: true,
    },
  ];
  const [activeHeaderData, setActiveHeaderData] = useState([...columnsForRequestViewForUser])
  const [allStart, setAllStart] = useState(new Date());
  const [allEnd, setAllEnd] = useState(new Date());

  useEffect(() => {
    getAllAttendence(new Date(startDate), new Date(endDate));
  }, [props.clockisActive]);
  const getAllAttendence = (startDate, endDate) => {
    let start = dayjs(startDate).format("DD/MM/YYYY");
    let end = dayjs(endDate).format("DD/MM/YYYY");
    AttendenceService.GetAttendenceAllUser(start, end, 0)
      .then(async(result) => {
        const users = [...allusers];
        let arr = [];
        let tempArr=[];
         
        await modifyTimesheetData(result?.data?.data,"whoisin").then(r=>{
           
          tempArr=r;
        }).catch(err=>{
          console.log(err)
        });
        tempArr.forEach(async(obj) => {
          let d = { ...obj };
          d.TimesheetDate = GetFormattedDate1(new Date(d.TimesheetDate));
          // if (d["checkIn"] == "-" || d["checkIn"] == "") {
          //   d.checkIn = "-";
          // } else {
          //   try {
          //     d.checkIn = getTimeInAMPMFormat(d["checkIn"]);
          //   } catch (err) {
          //     d.checkIn = "-";
          //   }
          // }
          // if (d["checkOut"] == "-" || d["checkOut"] == "") {
          //   d.checkOut = "-";
          // } else {
          //   try {
          //     d.checkOut = getTimeInAMPMFormat(d["checkOut"]);
          //   } catch (err) {
          //     d.checkOut = "-";
          //   }
          // }
        
          let checkIn = d["checkIn"];
          let checkOut = d["checkOut"];
          let obj1 = { ...d };
          if (checkIn !== "-" && checkOut == "-") {
            obj1["I/O"] = "IN";
            obj1.Time = checkIn;
            obj1.rawTime = d?.rawcheckIn
            arr.push(obj1);
          } else if (checkIn !== "-" && checkOut !== "-") {
            obj1["I/O"] = "OUT";
            obj1.Time = checkOut;
            obj1.rawTime = d?.rawcheckOut
            arr.push(obj1);
          }
        });
        // arr.forEach((one) => {
        //   one.TimesheetDate = GetFormattedDate1(new Date(one.TimesheetDate));
        // });

        let emails = {};
        let date = dayjs(new Date()).format("MM-DD-YYYY");
        arr.forEach((one) => {
          // if (
          //   new Date(one.TimesheetDate).getTime() == new Date(date).getTime()
          // ) {
            // if(dayjs().format("MM/DD/YYYY")==dayjs(one.TimesheetDate).format("MM/DD/YYYY")){
              let key = one.email;
              if (!emails[key]) {
                emails[key] = one;
              } else {
                if (
                  emails[key] &&
                  new Date(one.rawTime) > new Date(emails[key].rawTime)
                ) {
                  emails[key] = one;
                }
              }
           // }
          // }
        });

        // arr.forEach((one) => {
        //   let key = one.email;
        //   if (!emails[key]) {
        //     emails[key] = one;
        //   } else {
        //     if (
        //       emails[key] &&
        //       new Date(emails[key].TimesheetDate).getTime() !=
        //         new Date(date).getTime()
        //     ) {
        //       if (
        //         new Date(one.TimesheetDate) >=
        //         new Date(emails[key].TimesheetDate)
        //       ) {
        //         if (
        //           new Date(one.MODIFIEDTIME) >=
        //           new Date(emails[key].MODIFIEDTIME)
        //         ) {
        //           // one.Time="-";
        //           emails[key] = one;
        //         }
        //       }
        //     }
        //   }
        // });
        let finalArr=[]
        allusers.forEach(user=>{
          if(emails[user.email]){
            finalArr.push(emails[user.email]);
          }else{
            finalArr.push({
              "Time":"00:00",
              "UserName":user.full_name,
              "I/O":"OUT",
              "TimesheetDate":"-",
            });
          }
        })
        // arr = Object.values(emails).map((one) => one);
        finalArr = finalArr.sort(
          (a, b) =>
            new Date(
              dayjs(b.TimesheetDate).format("MM/DD/YYYY") + " " + b.Time
            ) -
            new Date(
              dayjs(a.TimesheetDate).format("MM/DD/YYYY") + " " + a.Time
            )
        );
        setCacheRowData(finalArr);
        setRowData(finalArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function GetFormattedDate1(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return month + "/" + day + "/" + year;
  }

  function formatDate(dateObj) {
    const options = { month: "long", day: "numeric", year: "numeric" };
    return dateObj.toLocaleDateString("en-US", options);
  }
  const extraComponentForTable = (e) => {
    return (
      <AntdRangePicker
        onChangeDateRange={onChangeDateRange}
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        allEnd={allEnd}
        allStart={allStart}
      />
    );
  };

  const onChangeDateRange = (dates, datesString) => {
    // if (datesString.length > 0) {
    //     let start = new Date(new Date(datesString[0]))
    //     let end = new Date(new Date(datesString[1]))
    //     if(start && end){
    //         let filterData=cacheRowData.filter(e=>start<=new Date(e.TimesheetDate) && new Date(e.TimesheetDate)<=end)
    //         setRowData(filterData)
    //     }
    //     changeDateRange({ selection: { startDate: start, endDate: end } })
    // }

    if (datesString.length > 0) {
      let start = new Date(new Date(datesString[0]));
      let end = new Date(new Date(datesString[1]));
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 0, 0);
      setStartDate(start);
      setEndDate(end);
      let formattedStartDate = formatDate(datesString[0]);
      let formattedEndDate = formatDate(datesString[1]);
      if (cacheRowData) {
        let arr = cacheRowData.filter((r) => {
          if (formattedStartDate == formattedEndDate && dates == "Clear") {
            return r;
          } else if (
            new Date(r.TimesheetDate) >= start &&
            new Date(r.TimesheetDate) <= end
          ) {
            return r;
          }
        });
        setRowData(arr);
      }
    }
  };

  return (
    <div>
      <div className="HR-Header-Wrapper">
        <Row
          className="border-bottom"
          style={{
            justifyContent: "end",
            height: "56px",
            alignItems: "center",
            marginRight: "0px",
            marginLeft: "0px",
          }}
        >
          {/* <Col sm={4}>
                    </Col> */}
          {/* <Col sm={4} style={{ display: "flex", justifyContent: "center", height: "2rem" }} className="justify-content-start">
                        <AntdRangePicker onChangeDateRange={onChangeDateRange} defaultStartDate={startDate} defaultEndDate={endDate} allEnd={allEnd} allStart={allStart} />
                    </Col> */}
          <Col
            sm={4}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "52px",
              alignItems: "center",
            }}
            className="ml-auto"
          >
            {/* <div className="text-right">
                            Refresh
                            <span
                                className="refresh-hrlist"
                                onClick={() => getAllAttendence(startDate, endDate)}
                            >
                                <FontAwesomeIcon className="refresh-icon" icon={faSyncAlt} />
                            </span>
                        </div> */}
          </Col>
        </Row>
      </div>

      <div className="hr-time-table">
        <div className="ag-theme-alpine" style={{ height: "100%" }}>
          <GlobalAgGridTable
            headers={activeHeaderData}
            onFirstDataRendered={onFirstDataRendered}
            onSelectionChanged={onSelectionChanged}
            setRowData={setRowData}
            cacheRowData={cacheRowData}
            module="WhoIsIn"
            DropDownFields={["Name", "I/O"]}
            rowData={rowData}
            renderExtraComponent={extraComponentForTable}
          />
        </div>
      </div>
    </div>
  );
};
export default WhoIsIn;
