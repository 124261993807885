import { faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Table,} from "reactstrap";
import './dealTableChart.css';
import axios from "axios";
import Config from '../../config.json'

const DealTableChart = ({ total, headers, data, addOnClick, editOnClick, onDelete, folderId ,getchFiletDataUrl}) => {
    const[wwpDocuments,setWwpDocuments]=useState({
        violationDocs: [],
        invoiceDocs: [],
        paidUnderJobDocs: [],
        violationSummaryDocs: [],
    });

    const wrappNameCss={
        // "display": "block",
        "white-space": "nowrap",
        // "width": "11em",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "cursor":"default"
      }
  
    useEffect(e=>{
        (async(d)=>{
            if(folderId){
                    let violationsDoc = [];
                    let paidUnderJobDoc = [];
                    let invoiceDoc = [];
                    let violationSummaryDocs = [];
                    let fillingFolderData = null;
                    if (folderId != null) {
                        fillingFolderData = await axios
                          .post(
                            Config.backend_url+"getFilingFolderID",
                            {
                              id: folderId,
                              Authorization: localStorage.getItem("token"),
                            }
                          )
                          .then((r) => {
                            r.data.all_filing_files.map((e) => {
                              if (!e.name.includes("ARCHIVE")) {
                                if (
                                  e.name.includes("_ECB VIOLATION COPY") ||
                                  e.name.includes("_DOB VIOLATION COPY")
                                ) {
                                  let id = e.name.split(" VIOLATION COPY ")[1].split(".PDF")[0];
                                  violationsDoc = [
                                    ...violationsDoc,
                                    {
                                      filename: e.name,
                                      id: id,
                                      fileId: e.id,
                                    },
                                  ];
                                }
                                if (
                                  e.name.includes("_ECB VIOLATION SUMMARY") ||
                                  e.name.includes("_DOB VIOLATION SUMMARY")
                                ) {
                                  let id = e.name
                                    .split(" VIOLATION SUMMARY ")[1]
                                    .split(".PDF")[0];
                                  violationSummaryDocs = [
                                    ...violationSummaryDocs,
                                    {
                                      filename: e.name,
                                      id: id,
                                      fileId: e.id,
                                    },
                                  ];
                                }
              
                                if (e.name.includes("_VIOLATION PAID APPLICATION")) {
                                  let id = e.name
                                    .split("_VIOLATION PAID APPLICATION ")[1]
                                    .split(".PDF")[0];
                                  paidUnderJobDoc = [
                                    ...paidUnderJobDoc,
                                    {
                                      filename: e.name,
                                      id: id,
                                      fileId: e.id,
                                    },
                                  ];
                                }
                                if (e.name.includes("_VIOLATION PAID INVOICE")) {
                                  let id = e.name
                                    .split("_VIOLATION PAID INVOICE ")[1]
                                    .split(".PDF")[0];
                                  invoiceDoc = [
                                    ...invoiceDoc,
                                    {
                                      filename: e.name,
                                      id: id,
                                      fileId: e.id,
                                    },
                                  ];
                                }
                              }
                            });
                            return r.data;
                          });
                      }
                      setWwpDocuments({
                        violationDocs: violationsDoc,
                        invoiceDocs: invoiceDoc,
                        paidUnderJobDocs: paidUnderJobDoc,
                        violationSummaryDocs: violationSummaryDocs,
                      })
                }
                //
        })();
    },[data])

    function getTd(k,info,header){
      if(k== 0 && k!= 13){
        return  <td style={{position : 'sticky', left : '53px', background: "#dbeef9" }} className={info[header.value] != undefined && info[header.value] != "" ? "fixed-side grey-bg sticky-column" : "backcolor"}>
                  <a
                      href={`https://a810-bisweb.nyc.gov/bisweb/JobsQueryByNumberServlet?passjobnumber=${info[header.value]}&passdocnumber=&go10=+GO+&requestid=0`}
                      target="_blank"
                      style={{cursor :"pointer"}}
                  >
                      {info[header.value]}
                  </a>
                </td>
      }else if(k!==0 && k==13){
        return <td className={(info[header.flag] !== "Yes") ? "backcolor" : "fixed-side grey-bg sticky-column"}>
        {info[header.value]}</td>
      } else{
        return<> <td className={(info[header.value] !== undefined && info[header.value] !== "" && info[header.value] !== null) 
        || (header.value=='Violation_Summary' || header.value=='Violation_Copy') ? "fixed-side grey-bg sticky-column" : "backcolor"}  style={{...wrappNameCss}}>
          {header.value=='Violation_Copy'?fetchFileName(info[header.extra],"vc")
        :(header.value=='Violation_Summary'?fetchFileName(info[header.extra],"vs")
        :info[header.value])}</td>
         {header.value=='Violation_Copy'?<td className="width-36">{ getFileDataUrl(info[header.extra],"vc")}</td>
        :(header.value=='Violation_Summary'? <td className="width-36">{getFileDataUrl(info[header.extra],"vs")}</td>
        :"" )}
        </>
      }
    }

    useEffect(e=>{
     
    },[wwpDocuments]);
    const fetchFileName=(id,type)=>{
        let name=""
        let fileId
        if(type=="vs"){
            let vsdata=wwpDocuments.violationSummaryDocs?.find(d=>d.id?.toUpperCase()==id?.toUpperCase());
            if(vsdata){
                name=vsdata.filename?vsdata.filename:"";
                fileId=vsdata
            }
        }
        else if(type=="vc"){
            let vcdata=wwpDocuments.violationDocs?.find(d=>d.id?.toUpperCase()==id?.toUpperCase());
            if(vcdata){
                name=vcdata.filename?vcdata.filename:"";
                fileId=vcdata
            }
        }
        return (<span>{name}</span>) 
    }
    const getFileDataUrl=(id,type)=>{
      let name=""
        let fileId
        if(type=="vs"){
            let vsdata=wwpDocuments.violationSummaryDocs?.find(d=>d.id?.toUpperCase()==id?.toUpperCase());
            if(vsdata){
                name=vsdata.filename?vsdata.filename:"";
                fileId=vsdata
            }
        }
        else if(type=="vc"){
            let vcdata=wwpDocuments.violationDocs?.find(d=>d.id?.toUpperCase()==id?.toUpperCase());
            if(vcdata){
                name=vcdata.filename?vcdata.filename:"";
                fileId=vcdata
            }
        }
        return (
          <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getchFiletDataUrl(fileId?.fileId,name)
                }
                download
              >
              <FontAwesomeIcon icon={faEye} />
              </a>
        )
    }
    return (
        <div className='deal-table-chart'>
            <Table striped bordered className="table-pad margin-top-18">
                <thead className="bg-header">
                    <tr>
                        <th className="width-64 left-th" style={{position : 'sticky', left : '0',background:'#018ee0'}}>Action</th>
                        {headers.map((one,k) => {
                            return (
                            k == 0 ? <th className=" width-75 middle-th" colSpan={one.colSpan?"2":"1"} style={{position : 'sticky', left : '53px',background:'#018ee0'}}>{one.label}</th>
                            :
                            <th className=" width-75 middle-th" colSpan={one.colSpan?"2":"1"} >{one.label}</th>)
                        })}
                    </tr>
                </thead>
                <tbody className="font12">
                    {data && data.map((info, i) => {
                        if(info.System_Size_KW1){
                            info.System_Size_KW1=parseFloat(info.System_Size_KW1).toFixed(3)
                        }
                        return (
                            <tr>
                                <td className="pad0" scope="row" style={{position : 'sticky', left : '0', background: "#dbeef9"}}>
                                    <Button className="btn btn-info btn-info2" style={{margin : '3px 3px 3px 0'}}
                                    >
                                        <FontAwesomeIcon
                                            className="float-right"
                                            icon={faEdit}
                                            onClick={() => {
                                                editOnClick(info, i);

                                            }
                                            }

                                        />
                                    </Button>
                                    <Button className="btn btn-danger btn-info2"
                                    >
                                        <FontAwesomeIcon
                                            className="float-right"
                                            icon={faTrashAlt}
                                            onClick={() => onDelete(info,i)}

                                        />
                                    </Button>
                                </td>
                                {headers.map((header, k) => {
                                    return getTd(k,info,header)
                                })}
                            </tr>
                        );

                    })}

                    <tr>
                        <td className="pad0" scope="row" style={{position : 'sticky', left : '0',background:'#dbeef9'}}>
                            <Button className="btn-wwp-plus"
                                onClick={addOnClick}

                            >
                                +
                            </Button>
                        </td>
                        {headers.map((one, i) => {
                            return (i== 0 ?
                            <td style={{position : 'sticky', left : '53px',background :'#dbeef9', display : "none"}}></td>
                            // : i ==1 ? 
                            // <td style={{position : 'sticky', left : '160px',background :'#dbeef9', display : "none"}}></td>
                            : 
                            <td style={{display : "none"}}></td>
                            )
                        })}
                    </tr>
                    {total ? <tr>
                        <td>Total</td>
                        {headers.map((one) => {
                            if(one.value !== "System_Size_KW1"){
                                if (total[one.value]) {
                                    return <td>{total[one.value]}</td>
                                } else {
                                    return <td></td>
                                }
                            }else{
        
                                if (total[one.value]) {
                                    let t=Number(total[one.value])
                                    console.log("t:        ", t);
                                    return <td>{t.toFixed(3)}</td>
                                } else {
                                    return <td></td>
                                }
                            }

                        })}


                    </tr> : <></>}
                </tbody>
            </Table>
        </div>

    );
}

export default DealTableChart;