import React from "react";
import { ListGroup } from "reactstrap";
import "./sidenavbar.css";
import PVSystemButton from "./navbuttons/pvsystembutton"
import PermittingButton from "./navbuttons/permittingbutton"
import PriliminaryButton from "./navbuttons/priliminarybutton"
import HomeownerButton from "./navbuttons/homeownerbutton"
import NYCProjectButton from "./navbuttons/nycprojectbutton"
const SideNavbar = (props) => {
  return (
    <ListGroup className="deal-list-sticky">
      <PriliminaryButton 
      sidebarCardsStatus={props.sidebarCardsStatus}
      updateTheSidebarState={props.updateTheSidebarState}
      setLayoutState={props.setLayoutState} layoutstate={props.layoutstate} listState={props.listState} setListState={props.setListState}/>
      <NYCProjectButton
       updateTheSidebarState={props.updateTheSidebarState} 
        sidebarCardsStatus={props.sidebarCardsStatus}
        setLayoutState={props.setLayoutState} 
        layoutstate={props.layoutstate}  
        listState={props.listState}
        setListState={props.setListState}
        setNycVisible={props.setNycVisible}
        isNycVisible= {props.isNycVisible}
        data={props.data}
      />
       <HomeownerButton 
      updateTheSidebarState={props.updateTheSidebarState}
       sidebarCardsStatus={props.sidebarCardsStatus}
       setLayoutState={props.setLayoutState} layoutstate={props.layoutstate}
       listState={props.listState} mailAddressDisable={props.mailAddressDisable}
      setListState={props.setListState} ownerType={props.ownerType} setownerType={props.setownerType}
      data={props.data}/>
     
     
       <PermittingButton 
      updateTheSidebarState={props.updateTheSidebarState}
       sidebarCardsStatus={props.sidebarCardsStatus}
       setLayoutState={props.setLayoutState} layoutstate={props.layoutstate}  listState={props.listState} setListState={props.setListState}/>
     
      
      <PVSystemButton 
      updateTheSidebarState={props.updateTheSidebarState}
      toggelActiveBar={props.toggelActiveBar}
      sidebarCardsStatus={props.sidebarCardsStatus}
      setLayoutState={props.setLayoutState} layoutstate={props.layoutstate}  listState={props.listState} setListState={props.setListState}/>  
      
    </ListGroup>
  );
};

export default SideNavbar;
