import axios from "axios";
import httpService from "../../services/http.service";
import Config from '../../config.json'

class ProjectService {
    GetProjectLayout() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/module/projects/layouts/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    GetProjectLayout() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/module/projects/layouts/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    UpdateProject(projectId, key, data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + '/',
                headers: { "Authorization": localStorage.getItem('token') },
                data: { key: key, content: data }
            }
            httpService.post('task', {config : config} )
                .then((result) => {
                    if (result.data.error) {
                        resolve({});
                    } else {
                        resolve(result.data.projects[0]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


    GetTemplateProjects(name) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/?status=template",
                headers: { "Authorization": localStorage.getItem('token') },

            }
            httpService.post('task', {config : config})
                .then((result) => {
                    if (result.data.error) {
                        resolve({});
                    } else {
                        result.data.projects=result.data.projects.filter(one=>one.name==name);
                        if(result.data.projects.length>0){
                            resolve(result.data.projects[0]);
                        }else{
                            resolve({});
                        }
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetProjectDetail(projectId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/" + projectId + '/',
                headers: { "Authorization": localStorage.getItem('token') },

            }
            httpService.post('task', {config : config})
                .then((result) => {
                    if (result.data.error) {
                        resolve({});
                    } else {
                        resolve(result.data.projects[0]);
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetSubformData(api_name) {
        if(api_name=="PV_Interconnection"){
            return new Promise((resolve, reject) => {
                axios.post(Config.backend_url+'getAllPagesOfModule', {
                    token:  localStorage.getItem('token'),
                    module: api_name
                })
                    .then((result) => {
                        if (result.data.error) {
                            resolve({});
                        } else {
                            if(result.data){
                                resolve(result.data.data);
                            }else{
                                resolve([]);
                            }
                           
                        }
    
                    })
                    .catch((err) => {
                        reject(err);
                    })
            })
        }else{
            return new Promise((resolve, reject) => {
                let config = {
                    Type: 'Get',
                    method: 'GET',
                    url: `https://www.zohoapis.com/crm/v2/${api_name}`,
                    headers: { "Authorization": localStorage.getItem('token') },
    
                }
                httpService.post('task', config)
                    .then((result) => {
                        if (result.data.error) {
                            resolve({});
                        } else {
                            if(result.data){
                                resolve(result.data.data);
                            }else{
                                resolve([]);
                            }
                           
                        }
    
                    })
                    .catch((err) => {
                        reject(err);
                    })
            })
        }
    }

    ExecuteZohoFlow(jsonData,url) {
        // let backendUrl = "https://crm.srsolardesign.com:3006/api/v1/"
        return new Promise((resolve, reject) => {
            // axios.post(backendUrl+'executeZohoFlow',{config: {url:url,data:jsonData}})
            httpService.CreateUpdate('executeZohoFlow',{config: {url:url,data:jsonData}})
                .then((result) => {
                    resolve(result)
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetProjectMilestone(ProjectId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/"+ProjectId+"/milestones/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', {config: config})
                .then((result) => {
                    if(result.data){
                        resolve(result.data.milestones);
                    }else{
                        resolve([]);
                    }
                   
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


    CreateProject(data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: data
            }
            httpService.post('task', config)
                .then((result) => {
                    if(result.data){
                        resolve(result.data.projects[0]);
                    }else{
                        resolve({});
                    }
                   
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


   





    UpdateDeal(obj) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://www.zohoapis.com/crm/v2/" + "Deals" + "/upsert",
                headers: { "Authorization": localStorage.getItem('token') },
                data: [obj]
            }
            httpService.post('workdrive', config)
                .then((result) => {
                  resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }










}

export default new ProjectService();