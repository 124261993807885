import React, { useEffect, useState } from 'react'
import { OtsideClickClosingWrapper } from '../EmojiReactionPicker/OtsideClickClosingWrapper'
import './ToolBox.css';

export const ToolBox = ({position,isOpen,setOpen,content,module, selectedMails}) => {
    const { x, y } = position;
    const ignoreModule=["Attachment","flag"]
    function includesIgnoreCase(arr, str) {
        return arr.some(item => item.toLowerCase() === str.toLowerCase() || (item.toLowerCase() === "all" && !ignoreModule.includes(module)));
    }
    const toolboxStyle = {
      position: 'absolute',
      left: `${x}px`,
      top: `${y}px`,
      border: '1px solid #ccc',
      backgroundColor: '#fff',
      padding: '4px',
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
      zIndex: '99999',
      "border-radius": "5px"
    };

  return <>
        {isOpen && isOpen?.state && <OtsideClickClosingWrapper
        close={e=>{
            setOpen(false)
        }}
        >
            <div className='Toolbox-wrapper' style={{...toolboxStyle}}>
                <ul>
                    {content && content.map(e=>{
                         if(selectedMails?.length>1 && (e.label=="Reply"||e.label=="Reply all")){
                            return
                        }
                        if(includesIgnoreCase(e.module,module)){
                            return <li style={e.Color && {color:e.Color}} onClick={(k)=>{
                                e.action(k,e,isOpen?.object)
                                setOpen(false)
                                }}>
                                {e.icon && e.icon()}
                                {e.label}
                            </li>
                        }
                    })}
                </ul>
            </div>
        </OtsideClickClosingWrapper>}
    </>
  
}
