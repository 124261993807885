import {
  faFlag as faFlagSolid,
  faSearch,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import TaskService from "../util/TaskService";
import UserService from "../util/UserService";
import "./custom_multiselect.css";
import { values } from "lodash";
import { color } from "highcharts";

const CustomMultiSelect = ({
  options,
  icon,
  onChange,
  values,
  id,
  opendDropdowns,
  setOpendDropDowns,
  invertColor,
  removeTile,
  filterIndex,
  GroupIndex,
}) => {
  const [expanded, setExpand] = useState();
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const newRef = useRef();

  //new code
  useEffect(
    (e) => {
      if (id == opendDropdowns) {
        setExpand(true);
      } else {
        setExpand(false);
      }
      //getOptionByIcon()
    },
    [opendDropdowns]
  );

  useEffect(
    (e) => {
  
    },
    [expanded]
  );

  const openDeopDown = (e) => {
    if (!expanded) {
      setOpendDropDowns(id);
    } else {
      setOpendDropDowns("");
    }
    //setExpand(!expanded);
  };
  //new code end

  const getFilterValues = () => {
    let items = [...options];
    if (searchText) {
      items = items.filter((one) => {
        if (
          one.name.toString().toLowerCase().includes(searchText.toLowerCase())
        ) {
          return one;
        }
      });
    }
    return items;
  };
  const isChecked = (one) => {
    if (values && values.filter((val) => val.name == one.name).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getOptionByIcon = () => {
    options = getFilterValues();
    const ids = options.map(({ id }) => id);
    const filtered = options.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    if (icon == "Number of Interconnection") {
      filtered.sort(function (a, b) {
        return parseInt(a.id) - parseInt(b.id);
      });
    }
    options = filtered;

    if (icon == "Assignee" || icon == "Created By" || icon == "Supervisor") {
      return (
        options &&
        options.map((one, index) => {
          return (
            <label for={"option" + index}>
              <input
                checked={isChecked(one)}
                onChange={(e) => onChange(e.currentTarget.checked, one, index)}
                type="checkbox"
                id="three"
              />
              <span className="cust-drop-icon">
                <img src={UserService.GetUserPhotoUrl(one.id)} />
              </span>
              <span className="cust-drop-text">{one.name}</span>
            </label>
          );
        })
      );
    } else if (
      icon == "Account Name" ||
      icon == "Status" ||
      icon == "Project Status"
    ) {
      return (
        options &&
        options.map((one, index) => {
          return (
            <label for={"option" + index}>
              <input
                checked={isChecked(one)}
                onChange={(e) => onChange(e.currentTarget.checked, one, index)}
                type="checkbox"
                id="three"
              />
              <span className="cust-drop-icon">
                <span style={{ backgroundColor: one.color }}></span>
              </span>
              <span style={{ left: 22 }} className="cust-drop-text">
                {one.name}
              </span>
            </label>
          );
        })
      );
    } else if (icon == "Priority") {
      return (
        options &&
        options.map((one, index) => {
          return (
            <label for={"option" + index}>
              <input
                checked={isChecked(one)}
                onChange={(e) => onChange(e.currentTarget.checked, one, index)}
                type="checkbox"
                id="three"
              />
              <span className="cust-drop-icon">
                <FontAwesomeIcon color={one.color} icon={faFlagSolid} />
              </span>
              <span className="cust-drop-text">{one.name}</span>
            </label>
          );
        })
      );
    } else {
      return (
        options &&
        options.map((one, index) => {
          return (
            <label for={"option" + index}>
              <input
                checked={isChecked(one)}
                onChange={(e) => onChange(e.currentTarget.checked, one, index)}
                type="checkbox"
                id="three"
              />
              <span className="cust-drop-icon"></span>
              <span className="cust-drop-text">{one.name.includes("_")?one.name.split("_")[0]:one.name}</span>
            </label>
          );
        })
      );
    }
  };

  function ApplyColor(bgcolor, one) {
    return { background: bgcolor, color: invertColor(bgcolor, true), display : one.name != "Select All" ? "" : "none" };
  }
  return (
    <div id="custom_dropdown" class="multiselect">
      <div
        onClick={(e) => {
          openDeopDown(e);
        }}
        className="custom-tiles text-truncate overSelect"
      >
        {values && values.length > 0 ? <></> : "Select Option"}
        {values &&
          values.map((one, i) => {
            return (
              <span
                className="tile-span"
                key={i}
                style={
                   one.color ? ApplyColor(one.color, one) : { background: "rgb(91, 108, 151)", display : one.name != "Select All" ? "" : "none" } 
                }
                id={one.id}
              >
                {one.name.includes("_")?one.name.split("_")[0]:one.name}
                <FontAwesomeIcon
                  onClick={(e) =>
                    removeTile(one.id, filterIndex, GroupIndex, e)
                  }
                  className="remove-tile"
                  icon={faTimesCircle}
                />
              </span>
            );
          })}
      </div>
      <div
        class="selectBox"
        onClick={(e) => {
          openDeopDown(e);
        }}
      >
        <select>
          <option>
            <span style={{ padding: "5px", backgroundColor: "yellow" }}>
              ksafgjadsgfdsgjas
            </span>
          </option>
        </select>
        <div class="overSelect"></div>
      </div>
      <div
        className="custom-dropdown-box checkboxes"
        style={expanded ? { display: "block" } : { display: "none" }}
        id={id}
      >
        <div className="form-group form-group-sm filter-search-box">
          <input
            type="text"
            placeholder="Search"
            className="mr-sm-2 pad-fix search-filter"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
        </div>
        <div className="filter-drop-list">
          {expanded ? getOptionByIcon() : <></>}
        </div>
      </div>
    </div>
  );
};

export default CustomMultiSelect;
