import { Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { TaskGroupCardForTemplate } from '../../../components/ProjectTasks/TaskGroupCardForTemplate';
import './common.css';
import AddInlineData from '../../../components/ProjectTasks/AddInlineData';
import AwsServerService from '../../../common/util/AwsServerService';
export default function SubTaskTab({taskData,allPipelineData,subtasks,module,viewTaskFunction,groupFilter,selectedTable,subTaskData,setSubTaskData, isSubTaskView, setIsSubTaskView}) {
    let user=JSON.parse(localStorage.getItem("userinfo"));
    const [isAdding, setIsAdding] = useState(false);
    const [idToDelete, setIdToDelete] = useState('')
    const [subEditData, setSubEditData] = useState({
        id: Date.now(),
        Assignee: "",
        Priority: "",
        CREATEDBY:user.email_id,
        Supervisor: "",
        TaskDescription: "",
        TaskName : '',
        TaskROWID: '',
        TimeEstimate: "",
    })
    useEffect(e=>{
        if(subtasks?.length > 0){
            setSubTaskData(subtasks)
        }
    },[subtasks]);

    useEffect(e=>{
        if(taskData){         
            setSubEditData({...subEditData, TaskROWID : taskData?.TaskROWID})
        }
    },[taskData])
    
    const handleAddRow = (e, taskData) => {
        setIsAdding(true)
        setSubTaskData([...subTaskData, subEditData]);
    };

    // Delete the last row
    // const handleDeleteRow = () => {
    //     if (subTaskData.length > 0) {
    //         setSubTaskData(subTaskData.slice(0, -1));
    //     }
    //     setIsAdding(false)
    // };

useEffect(e=> {
    if (idToDelete) {
      let updatedTasks = [...subTaskData];
      let fileterdData = updatedTasks?.filter(item => item?.id != idToDelete);
      setSubTaskData(fileterdData);
      setIdToDelete('');
      setIsAdding(false);
    }
  },[idToDelete]);

    // Save the data (simulate API call)
    const handleSave = () => {
        // Find the index of the object with the same id in subTaskData
        subEditData["flag"] = true;
        const existingIndex = subTaskData.findIndex(item => item.id === subEditData.id);
      
        // Update the object if it exists, otherwise add subEditData as a new object
        if (existingIndex !== -1) {
          // Update the existing object with subEditData
          const updatedTaskData = [...subTaskData];
          updatedTaskData[existingIndex] = { ...subTaskData[existingIndex], ...subEditData };
          setSubTaskData(updatedTaskData);
        } else {
          // Add new object if no matching id is found
          setSubTaskData([...subTaskData, subEditData]);
        }
        // Reset subEditData to default values
        setSubEditData({
          id: Date.now(),
          Assignee: "",
          Priority: "",
          CREATEDBY: user.email_id,
          Supervisor: "",
          TaskDescription: "",
          TaskName: '',
          TimeEstimate: "",
        });
      
        setIsAdding(false);
      };

    return (<div className='Subtask-Table'>
        <AddInlineData
            onAdd={(e)=>{handleAddRow(e, taskData)}}
            // onDelete={(e)=>{handleDeleteRow()}}
            onSave={(e)=>{handleSave()}}
            isAdding={isAdding}
        />
        <TaskGroupCardForTemplate editData={subEditData} setEditData={setSubEditData} module={module} taskData={taskData} viewTaskFunction={viewTaskFunction} groupFilter={groupFilter} allPipelineData={allPipelineData} 
        data={subTaskData} header={"Task Name"} selectedTable={selectedTable} isSubTaskView={isSubTaskView} setIdToDelete={setIdToDelete} setIsSubTaskView={setIsSubTaskView} />
    </div>
        // <TaskGroupCard viewTaskFunction={viewTaskFunction} groupFilter={groupFilter} allPipelineData={allPipelineData} data={group1Data[key][key2]} header={key2}/>
    )
}
