import React, { useState, useEffect } from "react";
//import MicroInverterSubheader from "./microInverterSubheader"
// import { useHistory, withRouter,useLocation } from 'react-router-dom';
import { Col,
    Form,
    Collapse,
    Input, Row, Container,FormGroup,ListGroupItem, Label, Button, Modal, ModalBody} from 'reactstrap';
import HttpService from "../../../../../../services/http.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faAlignRight, faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faCaretDown, faEnvelope, faTasks, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import Config from '../../../../../../config.json'


const formInputs = 
    {
     Name:"",
      modelNumber:"",
      builtinAC: "false",
      assPVModuleType: "",
      minDCInputWatt: "",
      maxDCInputWatt: "",
      moduleCellCompat:"",
      maxInputDCVolt: "",
      minOperVoltRange: "",
      maxOperVoltRange: "",
      maxDCShortCircuitCurr: "",
      maxDCCurrPerBranch: "",
      peakOutPower: "",
      maxContOutPower: "",
      maxContOutCurr208: "",
      maxContOutCurr240: "",
      maxUnitsPerString208: "",
      maxUnitsPerString240: "",
      cecWeightedEff: "",
      powerFactor: "",
      grounding: "false",
      quantity: "",
    }
    
    
function MicroInverterModal(props) {

    const [formInputsValue, setFormInputsValue] = useState(formInputs);
    const [inverters, setInverters] = useState([]);
   // const history = useHistory();
   // const location = useLocation();
    const [detail, setDetail] = useState([]);
    const [func, setFunc] = useState("create");
    const [isOK,setisOK]=useState('');

    const validationCss={
      "transition": "width 0.4s ease-in-out",
      "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
    }
    const [validation,setValidations] = useState({
      Name:'0',
      modelNumber:'0',
      minDCInputWatt:'0',
      maxDCInputWatt:'0',
      moduleCellCompat:'0',
      maxInputDCVolt:'0',
      minOperVoltRange:'0',
      maxOperVoltRange:'0',
      maxDCShortCircuitCurr:'0',
      maxDCCurrPerBranch:'0',
      peakOutPower:'0',
      maxContOutPower:'0',
      maxContOutCurr208:'0',
      maxContOutCurr240:'0',
      maxUnitsPerString208:'0',
      maxUnitsPerString240:'0',
      cecWeightedEff:'0',
      powerFactor:'0',
      quantity:'0'
    });
    const resetValidations=()=>{
       
      let vKeys=Object.keys(validation);
      let ob={};
      for(let i=0;i<vKeys.length;i++){
        ob[vKeys[i]]='0'
      }
      setValidations(ob)
    }
    const isAllValid=()=>{  
      let vKeys=Object.keys(validation);
      let ob={};
      for(let i=0;i<vKeys.length;i++){
        if(validation[vKeys[i]]=='0' || validation[vKeys[i]]=='-1'){
          return false;
        }
      }
      return true;
    }
    const highliteEmptys=()=>{     
      let vKeys=Object.keys(validation);
      let ob={};
      for(let i=0;i<vKeys.length;i++){
        if(validation[vKeys[i]]=='0' || validation[vKeys[i]]=='-1'){
          ob[vKeys[i]]='-1'
        }else{
          ob[vKeys[i]]='1'
        }
      }
      setValidations(ob)
    }
  
    const validateFunction=(e)=>{
       
      let tempValidation={...validation};
      if(e.currentTarget.type=="text"){
        e.target.value=e.target.value.trimStart();
        if(e.currentTarget.value.trim()=='' || e.currentTarget.value==undefined){
          tempValidation[e.currentTarget.name]='-1'
        }else{
          tempValidation[e.currentTarget.name]='1'
        }
      }else if(e.currentTarget.type=="number"){
        e.currentTarget.value=e.currentTarget.value.trimStart();
        e.target.value=e.target.value.trimStart();
        if(e.currentTarget.value.trim()=='' || e.currentTarget.value==undefined){
          tempValidation[e.currentTarget.name]='-1'
        }else{
          tempValidation[e.currentTarget.name]='1'
        }
      }
      setValidations(tempValidation);
    }

    const resetStringFields = () => {
      setFormInputsValue({
        ...formInputsValue,
        Name:"",
      modelNumber:"",
      builtinAC: "false",
      assPVModuleType: "",
      minDCInputWatt: "",
      maxDCInputWatt: "",
      moduleCellCompat:"",
      maxInputDCVolt: "",
      minOperVoltRange: "",
      maxOperVoltRange: "",
      maxDCShortCircuitCurr: "",
      maxDCCurrPerBranch: "",
      peakOutPower: "",
      maxContOutPower: "",
      maxContOutCurr208: "",
      maxContOutCurr240: "",
      maxUnitsPerString208: "",
      maxUnitsPerString240: "",
      cecWeightedEff: "",
      powerFactor: "",
      grounding: "false",
      quantity: "",
  
      })
    }

    const handleChange=(e)=>{
        console.log("the name is"+e.target.name);
        console.log("the value is"+e.target.value);
        setFormInputsValue({
            ...formInputsValue,
            [e.target.name] : e.target.value
        })
    }

    const changeIntoDecimal = (e)=>{
        setFormInputsValue({
            ...formInputsValue,
            [e.target.name] : parseFloat(e.target.value).toFixed(2)
        })

    }

    const changeBuiltInAc=(e)=>{
        if(e.target.value === "true") {
            setFormInputsValue({
                ...formInputsValue, 
                builtinAC: "true"
            });
        } else {
            setFormInputsValue({
                ...formInputsValue,
                builtinAC: "false"
            });
        }
    }

     const changeGrounding=(e)=>{
        if(e.target.value === "true") {
            setFormInputsValue({
                ...formInputsValue, 
                grounding: "true"
            });
        } else {
            setFormInputsValue({
                ...formInputsValue,
                grounding: "false"
            });
        }
    }
    
    const changePositiveValue=(e)=>{
       if(e.target.value<0){
           e.target.value= e.target.value* -1;
       } 
    }

    const createMicroInverter = async () => {
      var route="";
      route=func=="create"?'createmicroinverter':'updatemicroinverterbyid';

      let returnData = await HttpService.CreateUpdate(route, formInputsValue).then(res =>  {
      if(res.data=="Added Successfully"){
          //history.push('/microInverter/list');
          props.setMicroInverterModal(false);
          props.setReloadInverterData(true);
          //props.getInvertersModelNo();
        }
        else if(res.data=="Unsuccessfull"){
          alert("Server Issue: Unable to add PV module.");
        }
      });
  
  }

  const createMicroInverterZOHO = async () => {
     
    if(formInputsValue.builtinAC=='true' && formInputsValue.assPVModuleType==''){
     setisOK(true);
     return;
    }
    if(formInputsValue.builtinAC=='true'){
    setValidations({...validation,maxOperVoltRange:'1',minOperVoltRange:'1',maxInputDCVolt:'1'})
    }
     
    let isValid=await isAllValid();
    if(!isValid){
      highliteEmptys()
      alert("Please fill all required fields!")
      return;
    }

    let obj = {
      "Built_In_AC": formInputsValue.builtinAC,
      "Maximum_DC_Input_Wattage": formInputsValue.maxDCInputWatt,
      "Name": formInputsValue.Name,
      "Minimum_DC_Input_Wattage": formInputsValue.minDCInputWatt,
      "Maximum_Operating_Voltage_Range": formInputsValue.maxOperVoltRange,
      "Peak_Output_Power": formInputsValue.peakOutPower,
      "Grounding": formInputsValue.grounding,
      "Maximum_Continuous_Output_Current208": formInputsValue.maxContOutCurr208,
      "Maximum_Continuous_Output_Current240": formInputsValue.maxContOutCurr240,
      "Model_Number": formInputsValue.modelNumber,
      "Maximum_Continuous_Output_Power": formInputsValue.maxContOutPower,
      "Maximum_Input_DC_Voltage": formInputsValue.maxInputDCVolt,
      "Maximum_Units_Per_String208": formInputsValue.maxUnitsPerString208,
      "Maximum_Units_Per_String240": formInputsValue.maxUnitsPerString240,
      "Minimum_Operating_Voltage_Range": formInputsValue.minOperVoltRange,
      "Maximum_DC_Current_Per_Branch": formInputsValue.maxDCCurrPerBranch,
      "Maximum_DC_Short_Circuit_Current": formInputsValue.maxDCShortCircuitCurr,
      "Quantity": formInputsValue.quantity,
      "Power_Factor": formInputsValue.powerFactor,
      "CEC_Weighted_Efficiency" : formInputsValue.cecWeightedEff,
      "Module_Cell_Compatibility":formInputsValue.moduleCellCompat
    }
    let bacUrl = Config.backend_url
    var config = {
        Type: "Create",
        data: [obj],
        url: "https://www.zohoapis.com/crm/v2/Micro_Invertors",
        method: "POST",
        headers: {
            'Authorization': localStorage.getItem('token')
      }
    }
    //window.ZOHO.CRM.API.insertRecord({ Entity: "Micro_Invertors", APIData: obj })
    await axios.post(bacUrl+'workdrive',config)
      .then(function (data) {
        console.log(data);
        data=data.data;
        props.setinverterModelNumber({ id: data.data[0].details.id, module: "Micro_Invertors", name: formInputsValue.Name });
        resetStringFields();
        props.setMicroInverterModal(false);
        props.setReloadInverterData(true);
        props.getInvertersModelNo(props.invertertype);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

 useEffect(() => {
    getInverters();
  }, []);

  const getInverters = async() => {
    const backendUrl =Config.backend_url;
    var inverters=[];
    let returnData = await axios.get(backendUrl+'getallpvmodule').then(res =>  {
      if(res.data.length>0 && res.data!='unsuccesfull'){
        res.data.map((invertor,i)=>{
          inverters.push(invertor.modelNumber);
        })
        setInverters(inverters);
      }
    });

  }

//   useEffect(()=>{
//     if(location.state.action!='create') {
//       console.log(location.state.detail);
//       setDetail(location.state.detail);
//       setFormInputsValue({
//         ...formInputsValue,
//         _id: location.state.detail._id,
//         modelNumber:location.state.detail.modelNumber,
//         builtinAC: location.state.detail.builtinAC,
//         assPVModuleType: location.state.detail.assPVModuleType,
//         minDCInputWatt: location.state.detail.minDCInputWatt,
//         maxDCInputWatt: location.state.detail.maxDCInputWatt,
//         moduleCellCompat:location.state.detail.moduleCellCompat,
//         maxInputDCVolt: location.state.detail.maxInputDCVolt,
//         minOperVoltRange: location.state.detail.minOperVoltRange,
//         maxOperVoltRange: location.state.detail.maxOperVoltRange,
//         maxDCShortCircuitCurr: location.state.detail.maxDCShortCircuitCurr,
//         maxDCCurrPerBranch: location.state.detail.maxDCCurrPerBranch,
//         peakOutPower: location.state.detail.peakOutPower,
//         maxContOutPower: location.state.detail.maxContOutPower,
//         maxContOutCurr208: location.state.detail.maxContOutCurr208,
//         maxContOutCurr240: location.state.detail.maxContOutCurr240,
//         maxUnitsPerString208: location.state.detail.maxUnitsPerString208,
//         maxUnitsPerString240: location.state.detail.maxUnitsPerString240,
//         cecWeightedEff: location.state.detail.cecWeightedEff,
//         powerFactor: location.state.detail.powerFactor,
//         grounding: location.state.detail.grounding,
//         quantity: location.state.detail.quantity,
//     })
//     setFunc("update")
//     }else{
//       setFunc("create")
//     }
// }, [location])


    return (
        <div>
        <Modal  
            isOpen={props.microInverterModal}
            toggle={props.microInverterModal}

        >
            <div className="modal-header-class">
                Micro Inverter
                <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={()=>{
                props.setMicroInverterModal(!props.microInverterModal)
                resetStringFields();
                resetValidations();
              }}
            ></FontAwesomeIcon>
          </div> 
            </div>
            <ModalBody className="h500-overflow">
            <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="modelNumber">
               Micro Inverter Name
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="text"
                  name="Name"
                  className="create-user-input"
                  value={formInputsValue.Name}
                  onChange={(e) => {
                    e.target.value=e.target.value.trimStart()
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.Name=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                  //onBlur={(e) => handleErrors("FirstName")}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup>
            <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="modelNumber">
                Model Number
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="text"
                  name="modelNumber"
                  className="create-user-input"
                  value={formInputsValue.modelNumber}
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart()
                    validateFunction(e)
                    handleChange(e)}}
                   style={validation.modelNumber=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                  //onBlur={(e) => handleErrors("FirstName")}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup>

        <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="builtinAC">
                   Built-In AC
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
                 <Label className="text-bold2">Yes</Label>
                    <Input 
                        type="radio" 
                        name="builtinAC"
                        value="true"
                        checked={formInputsValue.builtinAC==="true"}
                        onChange={(e) => changeBuiltInAc(e)}
                    />
              </Col>
              <Col sm={2}>
                 <Label className="text-bold2">No</Label>
                    <Input 
                        type="radio" 
                        name="builtinAC"
                        value= "false"
                        checked={formInputsValue.builtinAC==="false"}
                        onChange={(e) =>  changeBuiltInAc(e)}
                    />
              </Col>
              <Col sm={2}></Col>
            </Row>
          </FormGroup>
         {formInputsValue.builtinAC==="true"?<>
         <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="assPVModuleType">
                Associated Micro-Inverter Type
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="select"
                  name="assPVModuleType"
                  className="create-user-input"
                  style={isOK?{...validationCss}:{}}
                  value={formInputsValue.assPVModuleType}
                  onChange={(e) => {
                    setisOK(false)
                    handleChange(e)}}
                  // onBlur={(e) => changePositiveValue(e)}
                >
                  <option value="">Select Inverter</option>
                  {
                   inverters.map((name)=>{
                   return <option value={name}>{name}</option>
                    })
                  }
                  
                  </Input>
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
         </>:null}   
        <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="minDCInputWatt">
                Minimum DC input wattage

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="minDCInputWatt"
                  className="create-user-input"
                  value={formInputsValue.minDCInputWatt}
                  onChange={(e) =>{validateFunction(e)
                     handleChange(e)}}
                  style={validation.minDCInputWatt=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                  //onBlur={(e) => handleErrors("FirstName")}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxDCInputWatt">
                Maximum DC input wattage

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxDCInputWatt"
                  className="create-user-input"
                  value={formInputsValue.maxDCInputWatt}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.maxDCInputWatt=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                  //onBlur={(e) => handleErrors("LastName")}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.lastNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  field should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup>
        <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="moduleCellCompat">
                Module cell compatibility
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="moduleCellCompat"
                  className="create-user-input"
                  value={formInputsValue.moduleCellCompat}
                  onChange={(e) => {
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.moduleCellCompat=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                  //onBlur={(e) => handleErrors("FirstName")}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.frstNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Field should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup>
          {formInputsValue.builtinAC==="true"?null:   
          <>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxInputDCVolt">
                Maximum input DC voltage
                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxInputDCVolt"
                  className="create-user-input"
                  value={formInputsValue.maxInputDCVolt}
                  onChange={(e) =>{
                    validateFunction(e);
                     handleChange(e)}}
                  style={validation.maxInputDCVolt=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                  }}
                  //onBlur={(e) => handleErrors("LastName")}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.lastNameInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  field should not be empty.
                </span>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="minOperVoltRange">
                Minimum. operating voltage range

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                 type="number"
                 min="0.00"
                 step="1.00"
                  name="minOperVoltRange"
                  className="create-user-input"
                  value={formInputsValue.minOperVoltRange}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.minOperVoltRange=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changePositiveValue(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxOperVoltRange">
                Maximum. operating voltage range

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxOperVoltRange"
                  className="create-user-input"
                  value={formInputsValue.maxOperVoltRange}
                  onChange={(e) =>{
                    validateFunction(e);
                    handleChange(e)}}
                  style={validation.maxOperVoltRange=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changePositiveValue(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
              
            </Row>
          </FormGroup>
          </>
          }
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxDCShortCircuitCurr">
                Maximum DC short circuit current

                  <span className="red-text">*</span> 

                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxDCShortCircuitCurr"
                  className="create-user-input"
                  value={formInputsValue.maxDCShortCircuitCurr}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.maxDCShortCircuitCurr=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changePositiveValue(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          
          <FormGroup className="marb-16 padtop-15"> 
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxDCCurrPerBranch">
                Maximum DC current per branch

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxDCCurrPerBranch"
                  className="create-user-input"
                  value={formInputsValue.maxDCCurrPerBranch}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.maxDCCurrPerBranch=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changePositiveValue(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="peakOutPower">
                Peak output power

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="peakOutPower"
                  maxLength={9}
                  className="create-user-input"
                  value={formInputsValue.peakOutPower}
                  onChange={(e) => {
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.peakOutPower=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxContOutPower">
                Maximum continuous output power

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxContOutPower"
                  className="create-user-input"
                  value={formInputsValue.maxContOutPower}
                  onChange={(e) =>{
                    validateFunction(e)
                     handleChange(e)}}
                  style={validation.maxContOutPower=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxContOutCurr208">
                Maximum continuous output current (208 V)

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxContOutCurr208"
                  className="create-user-input"
                  value={formInputsValue.maxContOutCurr208}
                  onChange={(e) =>{
                    validateFunction(e)
                     handleChange(e)}}
                  style={validation.maxContOutCurr208=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxContOutCurr240">
                Maximum continuous output current (240 V)

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxContOutCurr240"
                  className="create-user-input"
                  value={formInputsValue.maxContOutCurr240}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.maxContOutCurr240=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxUnitsPerString208">
                Maximum units per string (208 V)

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxUnitsPerString208"
                  className="create-user-input"
                  value={formInputsValue.maxUnitsPerString208}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  //onBlur={(e) => handleUsermailid(e.target.value)}
                  style={validation.maxUnitsPerString208=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="maxUnitsPerString240">
                Maximum units per string (240 V)

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="maxUnitsPerString240"
                  className="create-user-input"
                  value={formInputsValue.maxUnitsPerString240}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.maxUnitsPerString240=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="cecWeightedEff">
                CEC weighted efficiency

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="cecWeightedEff"
                  className="create-user-input"
                  value={formInputsValue.cecWeightedEff}
                  onChange={(e) => {
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.cecWeightedEff=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changeIntoDecimal(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="powerFactor">
                Power factor

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="number"
                  min="0.00"
                  step="1.00"
                  name="powerFactor"
                  className="create-user-input"
                  value={formInputsValue.powerFactor}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.powerFactor=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changePositiveValue(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="grounding">
                Grounding

                  <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
                 <Label className="text-bold2">Yes</Label>
                    <Input 
                        type="radio" 
                        name="grounding"
                        value="true"
                         checked={formInputsValue.grounding==="true"}
                        onChange={(e) => changeGrounding(e)}
                    />
              </Col>
              <Col sm={2}>
                 <Label className="text-bold2">No</Label>
                    <Input 
                        type="radio" 
                        name="grounding"
                        value= "false"
                        checked={formInputsValue.grounding==="false"}
                        onChange={(e) =>  changeGrounding(e)}
                    />
              </Col>
              <Col sm={2}></Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
              <Col sm={5}>
                <Label className="text-bold" for="quantity">
                Quantity
               <span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                 type="number"
                 min="0.00"
                 step="1.00"
                  name="quantity"
                  className="create-user-input"
                  value={formInputsValue.quantity}
                  onChange={(e) =>{
                    validateFunction(e)
                    handleChange(e)}}
                  style={validation.quantity=='-1'?{...validationCss}:{}}
                  onBlur={(e) =>{
                    validateFunction(e)
                    changePositiveValue(e)
                  }}
                />
              </Col>
              <Col sm={1}>
                <span
                  className={
                    props.UsermailInvalid == true
                      ? "red-text font12"
                      : "error-display"
                  }
                >
                  {" "}
                  Incorrect E-mail Address format.
                </span>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
                <Col sm={1}></Col>
                <Col sm={5}>
                    <Button
                        className="continue-button" 
                        onClick={()=>{
                          props.setMicroInverterModal(false)
                          resetStringFields();
                          resetValidations();
                        }
                        }
                    >
                        Cancel
                    </Button>
                </Col>
                <Col sm={5}>
                    <Button className="continue-button"
                        onClick={()=>createMicroInverterZOHO()}
                    >
                        Save
                    </Button>
                </Col>
                <Col sm={1}></Col>
            </Row>  
        </FormGroup>

            </ModalBody>
            </Modal>
            </div>
    );
}

export default MicroInverterModal;