import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Button,
  Input,
  Row,
  Collapse,

} from "reactstrap";
import { Tabs, Tab, Nav } from "react-bootstrap";
import swal from "sweetalert";
import "./Users.css";
import { useHistory, withRouter, useLocation, Prompt, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronLeft, faEye, faSignOutAlt,faSearch, faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import HttpService from "../../services/http.service";
import Geocode from "react-geocode";
import Profile from "./Profile";
import UsersTable from "./UserTable";
import UserService from "../../common/util/UserService";

const Users = (props) => {
  const regexMatch = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
  const [selectedTable, setSelectedTable] = useState("Users");
  const [key, setKey] = useState("MyProfile");
  const [generalCollapse, setGeneralCollapse] = useState(true);
  const [usersCollapse, setUsersCollapse] = useState(true);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loginData, setLoginData] = useState({});
  const [newObj, setNewObj] = useState({});
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [error, setError] = useState(null);
  const [passwordView, setPasswordView] = useState({ New: 'password', Confirm: 'password' })
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null)

  const location = props.location;
  const history=useHistory()
  const params=useParams()

  useEffect(() => {
    if (params && params.tab) {
      setSelectedTable(params.tab);
    }

  }, [params])

  useEffect(() => {
    if (props.getParams) {
        props.getParams(params);
    }
}, [location])



  const getAllUsers = async () => {
   UserService.GetAllUsers()
   .then((result)=>{
    let loginuser=result.data.users.filter(one=>one.id==props.match.params.id);
    setAllUsers(result.data.users);
    setSelectedUser(loginuser[0]);
   })
   .catch((err)=>{
    console.log(err);
   })
  };

  useEffect(() => {
    getAllUsers()
  }, [])

  const setFilterStreet = (street) => {
    var str = street.replace(/(\d+)(st|nd|rd|th)/g, "$1");
    return str;
  };

  const onBlurProjectAdd = (value) => {

    Geocode.fromAddress(value).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;
        let obj = {}
        var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "",
          borough = "",
          neighbourhood = "";

        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                houseNO = response.results[0].address_components[i].long_name;
                break;
              case "route":
                street = response.results[0].address_components[i].long_name;
                break;

                break;
              case "sublocality_level_1":
                borough = response.results[0].address_components[i].long_name;
                if (
                  borough == "Manhattan" ||
                  borough == "Bronx" ||
                  borough == "Brooklyn" ||
                  borough == "Queens" ||
                  borough == "Staten Island"
                ) {


                  if (borough == "Manhattan") {


                    localStorage.setItem("borough", "1");
                    city = "New York";
                  } else if (borough == "Bronx") {


                    localStorage.setItem("borough", "2");
                    city = borough;
                  } else if (borough == "Brooklyn") {


                    localStorage.setItem("borough", "3");
                    city = borough;
                  } else if (borough == "Queens") {


                    localStorage.setItem("borough", "4");
                    city = neighbourhood;
                  } else if (borough == "Staten Island") {


                    localStorage.setItem("borough", "5");
                    city = borough;
                  } else {

                    localStorage.setItem("borough", "0");
                  }
                } else {


                  localStorage.setItem("nycOption", "0");
                  localStorage.setItem("borough", "");
                  city = response.results[0].address_components[i].long_name;
                }
                break;
              case "locality":
                if (
                  borough != "Manhattan" &&
                  borough != "Bronx" &&
                  borough != "Brooklyn" &&
                  borough != "Queens" &&
                  borough != "Staten Island"
                ) {
                  city = response.results[0].address_components[i].long_name;


                  localStorage.setItem("nycOption", "No");
                  localStorage.setItem("borough", "");
                }

                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                zipcode = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        var str = value;
        var lastIndex = str.lastIndexOf(",");
        var res = str.substring(lastIndex);
        if (lastIndex > 0) {
          if (res.includes("USA")) {
            str = str.substring(0, lastIndex);
            str = str + " " + zipcode;
          }
        }

        obj.Address = str;


        var filterStreet = setFilterStreet(street)

        localStorage.setItem("houseNo", houseNO);


        localStorage.setItem("street", filterStreet);


        localStorage.setItem("city", city);
        obj.City = city;


        localStorage.setItem("state", state);
        obj.State = state;


        obj.Zip = zipcode;

        localStorage.setItem("zipcode", zipcode);



        localStorage.setItem("lat", lat);

        localStorage.setItem("lng", lng);
        console.log(lat, lng);

        updateUserData({ currentTarget: { name: 'Address', value: obj } })
      },
      (error) => {
        console.error("Error Finding Place:" + error);
      }
    );
  };

  const updateUserData = async (e) => {
    let profileData = { ...loginData };
    if (e.currentTarget.name == 'Address') {
      let mergeObj = { ...profileData, ...e.currentTarget.value };
      setLoginData(mergeObj);
    } else {
      profileData[e.currentTarget.name] = e.currentTarget.value;
      setLoginData(profileData);
    }
  }

  const updateUser = async (data) => {
    let formData = new FormData();
    //formData.append("file", file ); 
    formData.append("data", JSON.stringify(data));
    let returnData = await HttpService.CreateUpdate('updateuser', formData).then(res => {
      if (res.data.Ack == 1) {
        alert("Details Updated Successfully");
      } else if (res.data == "Already Exist.") {
        alert("User already registered with same Email-ID.");
      }
    })
  }

  const saveUserData = () => {
    alert("click fire");
    updateUser(loginData);
  }

  const ResetNewPassword = () => {
    if (newPassword.match(regexMatch) && confirmPassword.match(regexMatch)) {
      if (newPassword === confirmPassword) {
        HttpService.getByBoj("ResetPassword", { Email: loginData.Email, Password: newPassword })
          .then((result) => {
            if (result.data.ack == 1) {
              swal({
                title: "Success",
                text: result.data.msg,
                icon: "success",
                buttons: true,
                dangerMode: false,
              })
                .then((willDelete) => {
                  if (willDelete) {
                    setResetPasswordOpen(!resetPasswordOpen)
                  } else {
                    // swal("Your imaginary file is safe!");
                  }
                });
            } else {
              swal(`${result.data.msg}`, { dangerMode: true });
            }
          })
          .catch((err) => {
            swal(`${err}`);
          })

      } else {
        swal('confirm password and new password are not same', { dangerMode: true });
      }
      setError(null)
    } else {
      const obj = { ...error };
      if (!newPassword.match(regexMatch)) {
        obj.New = 'Password must be 8 character one special character and one number'
      }
      if (!confirmPassword.match(regexMatch)) {
        obj.Confirm = 'Password must be 8 character one special character and one number'
      }
      setError(obj);
    }

  }

  const placeInputRef1 = useRef(null);
  useEffect(() => {
    // initPlaceAPI()
  },[])

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef1.current
    );
  }

  const getRightPage = (tab) => {
    if (tab == 'Users') {
      return <>
        <Tabs  defaultActiveKey="first">
          <Tab  eventKey="first" title="Users">
            <UsersTable selectedUser={selectedUser} getAllUsers={getAllUsers}  setSelectedUser={setSelectedUser} users={allUsers} />
          </Tab>
          {/* <Tab eventKey="second" title="Activate Users">
            Activate Users
          </Tab> */}
        </Tabs>



      </>
    } else if (tab == 'Personal Setting') {
      return  <Profile selectedUser={selectedUser} setSelectedUser={setSelectedUser} updateUserData={updateUserData} />
    } else {
      return <></>
    }
  }


  return (
    <div className="profile-wrapper">
      <FormGroup className="createdeal-headpadding">
        <Row className="row-mar-0">
          <Col sm={3} className="heading-text-padding">
            <div className="jobhead-box">
              <FontAwesomeIcon className="job-back-icon" icon={faArrowLeft}
                onClick={() => { window.history.go(-1) }}
              />
              <span className="jobnumber-heding-text"><strong>Settings</strong></span>
            </div>
          </Col>
          <Col sm={5}></Col>
          <Col sm={4}>
            <div className="save-cancelbox">
              <div className="create-dealbox2">
                <Button className="top-botton-pad blue-background3D"
                  onClick={() => { window.history.go(-1) }}
                >Cancel</Button>
              </div>
              <div className="create-dealbox3">
                <Button className="top-botton-pad blue-background3D"
                  onClick={() => saveUserData()}
                >Save Changes</Button>
              </div>
            </div>
          </Col>
        </Row>
      </FormGroup>
      <div className="row m-0">

        <div className="col-sm-2 pr-0">
          <div className="hrMenuLeft text-left">

            <div className="setting-txt">
              Settings
            </div>
            <div className="search-box">
              <FontAwesomeIcon color="#018ee0" icon={faSearch} />
              <input type="text"
                placeholder="Search here"
              />

            </div>
            <div className="project-task-card">

              <div className="project-deals-height sidebar mt-4">
                {/* <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item"> */}
                    {/* <h2 class="accordion-header" id="flush-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        General
                      </button>
                    </h2> */}
                    <div 
                        className="d-flex align-items-center mb-2"
                        onClick={()=>setGeneralCollapse(!generalCollapse)}
                    >
                    <FontAwesomeIcon 
                        icon={generalCollapse ? faCaretDown : faCaretRight}
                        className={generalCollapse ? "icon-down-width cursor-pointer" : "icon-right-width cursor-pointer"}
                    />
                    <h6 className="m-0 fw-bold cursor-pointer">General</h6>
                    </div>
                    <Collapse
                        isOpen={generalCollapse}
                    >
                       <ul className="menu-listbox">
                          <li 
                              className={selectedTable=="Personal Setting" ? "cursor-pointer setting-child menuleft-active" : "cursor-pointer setting-child" } 
                              onClick={() => setSelectedTable('Personal Setting')} 
                          >Personal Setting
                          </li>
                       </ul>
                    </Collapse>
                    {/* <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"> */}
                       
                      {/* </div>
                    </div> */}
                    <div 
                        onClick={()=>setUsersCollapse(!usersCollapse)}
                        className="d-flex align-items-center mb-2"
                      >
                      <FontAwesomeIcon 
                         icon={usersCollapse ? faCaretDown : faCaretRight}
                         className={usersCollapse ? "icon-down-width cursor-pointer" : "icon-right-width cursor-pointer"}
                      />
                      <h6 className="m-0 fw-bold cursor-pointer">Users and Control</h6>
                    </div>
                    <Collapse
                        isOpen={usersCollapse}
                    >
                      <ul className="menu-listbox">
                          <li 
                          style={props.isVisible && props.isVisible(props.selectedRole,'Users')?{}:{color:'gray'}} 
                          onClick={()=>{
                            if(props.isVisible && props.isVisible(props.selectedRole,'Users')){
                              setSelectedTable('Users')
                            }
                          }} 
                          className={selectedTable=="Users" ? "cursor-pointer setting-child menuleft-active" : "cursor-pointer setting-child" } 
                          >Users</li>
                          <li 
                           className={selectedTable=="User Role" ? "cursor-pointer setting-child menuleft-active" : "cursor-pointer setting-child" } 
                          style={props.isVisible&&props.isVisible(props.selectedRole,'User Role')?{}:{color:'gray'}}
                           onClick={()=>{
                            if(props.isVisible&&props.isVisible(props.selectedRole,
                              'User Role')){
                              history.push("/app/usersroles/list")
                            }
                           
                           }}>User Role</li>
                        </ul>
                    </Collapse>
                  </div>
                  {/* <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Users And Control
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <ul className="menu-listbox">
                          <li  style={props.isVisible&&props.isVisible(props.selectedRole,'Users')?{}:{color:'gray'}} onClick={()=>{
                            if(props.isVisible&&props.isVisible(props.selectedRole,'Users')){
                              setSelectedTable('Users')
                            }
                          }} className=" cursor-pointer setting-child">Users</li>
                          <li className="cursor-pointer setting-child" style={props.isVisible&&props.isVisible(props.selectedRole,'User Role')?{}:{color:'gray'}}
                           onClick={()=>{
                            if(props.isVisible&&props.isVisible(props.selectedRole,'User Role')){
                              history.push("/app/usersroles/list")
                            }
                           
                           }}>User Role</li>
                        </ul>
                      </div>
                    </div>
                  </div> */}

                </div>

                {/* <Tabs
      // activeKey={key}
      // onSelect={(k) => setKey(k)}
      className="flex-column hr-tabs-left border-0"
      activeKey={selectedTable}
      onSelect={(k) => {
        setKey(k);
        setSelectedTable(k);
      }}
    //activeKey={key}
    // onSelect={(key, e)=> handleTabSelection(key, e)}
    // defaultActiveKey="alldeals1"
    >
      <Tab eventKey="MySettings" title="My Settings"></Tab>

      { localStorage.getItem("Role")==="admin" ?  <Tab eventKey="RoleManagement" title="Role Management"></Tab> : "" }
      <Tab eventKey="TaskTemplate" title="Task Template"></Tab>
    </Tabs> */}

                {/* <a
      class="nav-item nav-link logout-link"
      onClick={() => {
        localStorage.clear();
        window.location = '/login'
      }}
    >
      Logout
    </a> */}

              {/* </div> */}
            {/* </div> */}
          </div>
        </div>

        <div className="col-sm-10">
          <div className="hrMenuRight p-3">

            {getRightPage(selectedTable)}


          </div>
        </div>
      </div>

    </div >
  )
}

export default Users;