export function getNextStepOfFlow(nodes, edges, activeNodeId, isCompleted) {

    const edgesMap = edges.reduce((acc, edge) => {
        if (!acc[edge.source]) acc[edge.source] = [];
        acc[edge.source].push(edge);
        return acc;
    }, {});

    const activeNodeEdges = edgesMap[activeNodeId] || [];
    
    for (const edge of activeNodeEdges) {
        if (isCompleted && edge.sourceHandle === 'destination') {
            return edge.target;
        } else if (!isCompleted && edge.sourceHandle === 'reject') {
            return edge.target;
        }
    }
    return null;
}