import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import { AgGridColumn, AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import {
  Col,
  FormGroup,
  Input,
  ListGroupItem,
  ModalHeader,
  Row,
} from "reactstrap";
import doticon from "../../assets/images/doticon.png";
import Config from "../../config.json";
import leftexpandgray from "../../assets/images/svg/left-gray.svg";
import leftexpand from "../../assets/images/svg/left.svg";
import leftexpandactivegray from "../../assets/images/svg/leftactive-gray.svg";
import leftexpandactive from "../../assets/images/svg/leftactive.svg";
import "../Deal/deal.css";
import _ from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import TabView from "../../common/tabs/tabs";
import Filter from "../../common/filter/filter";
import FieldService from "../../common/util/FieldService";
import AppContext from "../../Context/AppContext";
import GlobalTaskFilter from "../../common/task_filters/GlobalTaskFilter";
import CustomHeaderComponent from "../Deal/CustomHeader/CustomHeaderComponent";
import CustomDateComponent from "../../common/CustomDateComponent/CustomDateComponent";
import CustomEditorComponent from "../../common/CustomDateComponent/CustomEditorComponent";

const ClientList = (props) => {
  const history = useHistory();
  const location = props.location;
  const AppData = useContext(AppContext);
  const { state } = AppData || {};
  const allAccounts = state.AllAccounts || [];

  const params = useParams();
  useEffect(() => {
    if (props.getParams) {
      props.getParams(params);
    }
  }, [location]);
  const [showFilter, setShowFilter] = useState("");
  const [cacheHeaderData, setCacheHeaderData] = useState([]);
  const [headerData, setheaderData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [cacheData, setcacheData] = useState([]);
  const [from, setFromRow] = useState(1);
  const [to, setToRow] = useState(5);
  const [totalRow, setTotalRow] = useState(5);
  const [backActive, setbackActive] = useState(false);
  const [frontActive, setfrontActive] = useState(true);
  const [dotActive, setdotActive] = useState(false);
  const [filterText, setFilterText] = useState(null);
  const [filterState, setFilterState] = useState(false);
  const [firstActive, setFirstActive] = useState(false);
  const [columnsEdit, setColumnsEdit] = useState(false);
  const [CompactViewFilterTrigger, setCompactViewFilterTrigger] =
    useState(false);
  const [compactViewSortState, setCompactViewsortState] = useState([]);
  const [activeFiltersOnColumnLevel, setActiveFiltersOnColumnLevel] = useState(
    {}
  );
  const [appliedFiltersForView, setappliedFiltersForView] = useState([]);
  const [preAppliedFiltersForView, setPreAppliedFiltersForView] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isColFilDeleted, setIsColFilDeleted] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [editColumnSelectAllFlag, SetEditColumnSelectAllFlag] = useState(true);
  let selectedIndx = -1;
  let selectedValue = null;
  let dropedIndx = -1;
  let dropedValue = null;

  const setActiveFilterAndGetActiveFilters = async (
    filters,
    action,
    mainFilters
  ) => {
    let api = gridApi;
    if (action == "SAVE") {
      if (api) {
        let filtersModal = await api.getFilterModel();
        return filtersModal;
      }
    } else if (action == "SET") {
      let filterMain = mainFilters;
      if (!_.isArray(mainFilters) && !_.isEmpty(mainFilters)) {
        do {
          filterMain = JSON.parse(filterMain);
        } while (!_.isArray(filterMain));
      }
      let filter = filters;
      if (!_.isObject(filters) && !_.isEmpty(filters)) {
        do {
          filter = JSON.parse(filter);
        } while (!_.isObject(filter));
      }
      setPreAppliedFiltersForView(filterMain);
      setTimeout(() => {
        setActiveFiltersOnColumnLevel(filter);
      }, 1000);
    }
  };

  const onChangeColumnFilters = (params) => {
    if (rowData && !isColFilDeleted) {
      let data = params?.api?.getFilterModel();
      if (JSON.stringify(data) !== JSON.stringify(activeFiltersOnColumnLevel)) {
        setActiveFiltersOnColumnLevel(data);
      }
    } else {
      setIsColFilDeleted(false);
    }
  };

  useEffect(() => {
    if (gridApi) {
      let model = gridApi?.getFilterModel();
      if (JSON.stringify(activeFiltersOnColumnLevel) != JSON.stringify(model)) {
        gridApi?.setFilterModel(
          activeFiltersOnColumnLevel != null ? activeFiltersOnColumnLevel : {}
        );
      }
    }
  }, [activeFiltersOnColumnLevel]);

  useEffect(() => {
    if (!_.isEmpty(activeFiltersOnColumnLevel)) {
      gridApi?.setFilterModel(activeFiltersOnColumnLevel);
    }
  }, [gridApi]);

  const updateSortingState = (sort) => {};
  const dropdownFields = [
    "Account Name",
    "Phone",
    "Website",
    "Account Owner",
    "Account Site",
    "Parent Account",
    "Account Number",
    "Account Type",
    "Created By",
    "Modified By",
    "Currency",
    "Account Name",
    "Shipping Address",
    "Billing Street",
    "Shipping Street",
    "Billing City",
    "Shipping City",
    "Billing State",
    "Shipping State",
    "Billing Code",
    "Shipping Code",
    "Billing Country",
    "Shipping Country",
  ];
  const getTaskFilter = () => {
    return (
      <GlobalTaskFilter
        setActiveFilterAndGetActiveFilters={setActiveFilterAndGetActiveFilters}
        activeFiltersOnColumnLevel={activeFiltersOnColumnLevel}
        setActiveFiltersOnColumnLevel={setActiveFiltersOnColumnLevel}
        setappliedFiltersForView={setappliedFiltersForView}
        preAppliedFiltersForView={preAppliedFiltersForView}
        setIsColFilDeleted={setIsColFilDeleted}
        Columns={cacheHeaderData}
        rowData={rowData}
        cacheData={cacheData}
        setRowData={setRowData}
        module={"Client"}
        dropdownFields={dropdownFields}
      />
    );
  };

  useEffect(() => {
    getFieldHeaders();
  }, []);

  const getModalForEditColumns = () => {
    return (
      <Modal
        className="Edit-Column-Modal"
        show={props.clientListStatus?.EditColumns ? true : false}
        backdrop={true}
        toggle={() => {}}
        onHide={(e) => {
          props.clientListTriggers({ type: "EditColumns", status: false });
        }}
      >
        <ModalHeader>Manage Columns</ModalHeader>
        <ModalBody>
          <FormGroup className="pos-relative editcol-searchpad">
            <Input
              type="text"
              placeholder="Search"
              className="mr-sm-2 pad-fix search-filter"
              value={searchValue}
              onChange={(e) => onSearchColumns(e.target.value)}
            />

            <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />

            <div className="select-all-editColumn">
              <input
                type="checkbox"
                checked={editColumnSelectAllFlag}
                onChange={(e) => {
                  SetEditColumnSelectAllFlag(!editColumnSelectAllFlag)
                  const headers = [...headerData];
                  headers.forEach(
                    (obj) => (obj.isChecked = !e.currentTarget.checked)
                  );
                  setheaderData(headers);
                }}
              ></input>
              {"  "}
              <span
                className={"editColumnlist-label "}
                style={{
                  fontFamily: "Avenir Next LT W02",
                  fontSize: "13px",
                  marginLeft: "-3px",
                }}
              >
                Select All
              </span>
            </div>

            <hr className="hr-line" />
          </FormGroup>
          <ul className="editColummn-list">
            {headerData.map((e, index) =>
              e.name && e.isSearch ? (
                <ListGroupItem
                  style={{ marginLeft: 8 }}
                  className="editColumnlist-text"
                  draggable
                  onDrag={(ev) => onDragColumns(ev, e, headerData)}
                  onDrop={(ev) => onDropColumns(ev, e, headerData)}
                >
                  <img className="icon-grip-vertical" src={doticon} />
                  <input
                    type="checkbox"
                    checked={!e.isChecked}
                    id={e.id}
                    onChange={(e) => handleColumns(e, index)}
                  ></input>
                  {"  "}
                  <span
                    className={
                      e.isDragOver
                        ? "editColumnlist-label add-line"
                        : "editColumnlist-label"
                    }
                  >
                    {e.name}
                  </span>
                  {e.isDragOver ? <hr className="hr-line" /> : null}
                </ListGroupItem>
              ) : null
            )}
          </ul>
        </ModalBody>
      </Modal>
    );
  };

  let firstArr = [
    "Account_Name",
    "Parent_Account",
    "Account_Type",
    "Phone",
    "Website",
    "Billing_Address",
    "Billing_Street",
    "Billing_City",
    "Billing_State",
    "BillingCode",
    "Billing_Country",
    "Shipping_Address",
    "Shipping_Street",
    "Shipping_City",
    "Shipping_State",
    "Shipping_Code",
    "Shipping_Country",
    "Color",
    "Created_By",
    "Modified_By",
    "Created_Time",
    "Modified_Time",
  ];

  const getFieldHeaders = () => {
    FieldService.GetFields("all", "Accounts")
      .then((result) => {
        let arr = [];
        firstArr.forEach((one) => {
          let fieldObj = result.filter((object) => object.api_name == one);
          if (fieldObj.length > 0) {
            fieldObj = fieldObj[0];
            fieldObj.field_label = fieldObj.field_label.replace("0", "");
            let obj = {};
            obj.field = fieldObj.api_name;
            obj.id = fieldObj.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = fieldObj.field_label;
            obj.type = fieldObj.data_type;
            arr.push(obj);
          }
        });
        setheaderData(arr);
        setCacheHeaderData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect((e) => {
    props.setSelectedIds([]);
  }, []);

  const handleColumns = (e, index) => {
    let newClient = [...headerData];
    newClient.forEach((obj) => {
      if (obj.id === e.currentTarget.id) {
        obj.isChecked = !e.currentTarget.checked;
      }
    });
    let flag = newClient.every(e => !e.isChecked);
    SetEditColumnSelectAllFlag(flag);
    setColumnsEdit(true);
    setheaderData(newClient);
  };
  useEffect(() => {
    if (props.refreshEnable) {
      getClientList();
    }
  }, [props.refreshEnable]);

  useEffect(() => {
    if (props.deleteDealFlag == "Deleted") {
      getClientList();
    }
  }, [props.deleteDealFlag]);

  const getClientList = () => {
    const backendUrl = Config.backend_url + "workdrive";

    var config = {
      Type: "Get",

      url: "https://www.zohoapis.com/crm/v2/Accounts",
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };

    axios
      .post(backendUrl, { config: { ...config } })
      .then(function (res) {
        res.data.data = res.data.data.map((one) => {
          one.Created_By = one.Created_By.name;
          one.Owner = one.Owner.name;
          return one;
        });
        setRowData(res.data.data);
        setcacheData(res.data.data);
        props?.setDeleteDealFlag("");
        if (props.setRefreshEnable) {
          props.setRefreshEnable(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onDragColumns = (e, value, list) => {
    e.preventDefault();
    selectedIndx = list.findIndex((x) => x.name === value.name);
    selectedValue = value;
  };

  const onDropColumns = (e, value, list) => {
    e.preventDefault();
    let newClient = [...headerData];
    if (selectedIndx !== 0 || dropedIndx === 0) {
      dropedIndx = newClient.findIndex((x) => x.name === value.name);
      dropedValue = value;
      newClient.splice(selectedIndx, 1);
      newClient.splice(dropedIndx, 0, selectedValue);
    }

    newClient.forEach((data) => {
      data.isDragOver = false;
    });

    setheaderData(newClient);
  };

  const updatedHeaderData = () => {
    let headList = headerData;
    if (gridColumnApi !== null) {
      let orderedList = headList.map((data) => data.field);
      gridColumnApi.moveColumns(orderedList, 1); //moveColumn(colKey, toIndex)
    }
    return headList;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    getClientList();
  };

  useEffect(
    (e) => {
      if (gridApi) {
        if (from) {
          let pageNo = gridApi?.paginationGetCurrentPage();
          if (pageNo) {
            gridApi.paginationGoToPage(pageNo);
          }
        }
        if (activeFiltersOnColumnLevel) {
          gridApi.setFilterModel(activeFiltersOnColumnLevel);
        }
      }
    },
    [gridApi]
  );

  const getPagingValues = () => {
    if (gridApi != null) {
      const currentPage = gridApi.paginationGetCurrentPage();
      const totalPage = gridApi.paginationGetTotalPages();
      const totalRows = gridApi.paginationGetRowCount();
      const pageSize = gridApi.paginationGetPageSize();
      const fromRow = currentPage * pageSize + 1;
      const toRow = Math.min(fromRow + pageSize - 1, totalRows);

      setFromRow(fromRow);
      setToRow(toRow);
      setTotalRow(totalRows);
      fromRow != 1 ? setbackActive(true) : setbackActive(false);
      if (toRow === totalRows) {
        setfrontActive(false);
        setdotActive(true);
      } else {
        setfrontActive(true);
        setdotActive(false);
      }
    }
  };

  const onPageSizeChanged = () => {
    var value = document.getElementById("page-size").value;
    gridApi.paginationSetPageSize(Number(value));
    let updatedPage = Math.floor(from / value);
    gridApi.paginationGoToPage(updatedPage);
    getPagingValues();
  };

  const onBtNext = () => {
    gridApi.paginationGoToNextPage();
    getPagingValues();
  };

  const onBtPrevious = () => {
    gridApi.paginationGoToPreviousPage();
    getPagingValues();
  };

  const onBtDoubleNext = () => {
    gridApi.paginationGoToNextPage();
    gridApi.paginationGoToNextPage();
    getPagingValues();
  };

  const onBtDoublePrevious = () => {
    gridApi.paginationGoToPreviousPage();
    gridApi.paginationGoToPreviousPage();
    getPagingValues();
  };

  const onSearchFilterTexts = (value) => {
    gridApi.setQuickFilter(value);
  };

  const sortByAthleteAsc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: "jobNumber",
          sort: "asc",
        },
      ],
      defaultState: { sort: null },
    });
  };

  const sortByAthleteDesc = () => {
    gridColumnApi.applyColumnState({
      state: [
        {
          colId: "jobNumber",
          sort: "desc",
        },
      ],
      defaultState: { sort: null },
    });
  };

  const onChange = (event) => {
    setFilterText(event.target.value);
  };

  // Excel Download functionality Start//

  useEffect(() => {
    if (props.clientListStatus?.ExcelDownload == "download") {
      onExportBtnClick("Selected_RowData.xlsx");
    }
  }, [props.clientListStatus?.ExcelDownload]);

  const onExportBtnClick = (fileName) => {
    gridApi.exportDataAsExcel({
      fileName: fileName,
      onlySelected: true,
      processCellCallback: function (params) {
        const rowData = params.node.data;
        const columnField = params.column?.getColDef().field;
        if (columnField === "Parent_Account") {
          return rowData.Parent_Account?.name;
        }
        if (columnField === "Modified_By") {
          return rowData.Modified_By?.name;
        }

        if (columnField === "Modified_Time") {
          const options = {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "America/New_York", // Setting timezone to US Eastern Time
          };
          return new Date(rowData.Modified_Time).toLocaleString(
            "en-US",
            options
          );
        }
        if (columnField === "Created_Time") {
          const options = {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: "America/New_York", // Setting timezone to US Eastern Time
          };
          return new Date(rowData.Created_Time).toLocaleString(
            "en-US",
            options
          );
        }
        return params.value;
      },
    });

    props.clientListTriggers({ type: "downloadExcel", status: undefined });
  };

  // Excel Download functionality End //

  // Page refresh Code Start//
  const initBeforeUnLoad = () => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    };
  };

  window.onload = function () {
    initBeforeUnLoad();
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad();
  }, []);

  // Page refresh Code End//

  const ColumnFilter = () => {
    return (
      <div style={{ padding: 10, width: 230, backgroundColor: "white" }}>
        <div style={{ marginTop: 10 }} onClick={() => sortByAthleteAsc()}>
          Sort A to Z
        </div>
        <div style={{ marginTop: 10 }} onClick={() => sortByAthleteDesc()}>
          Sort Z to A
        </div>
        <div>
          <input
            style={{ margin: "4 0 4 0" }}
            type="text"
            value={filterText}
            onChange={onChange}
            placeholder="Search"
          />
        </div>
        <div
          style={{
            width: 200,
            height: 150,
            textAlign: "center",
            padding: 10,
            margin: 10,
            border: "1px solid lightgrey",
            backgroundColor: "white",
          }}
        ></div>
      </div>
    );
  };

  const applyFilters = (
    column,
    filterName,
    filterType,
    filterText,
    filterText2,
    duration
  ) => {
    var filtervalue = {
      column: column,
      filterName: filterName,
      filterType: filterType,
      filterText: filterText,
      filterText2: filterText2,
      duration: duration,
    };
    setClearFilterFlag(false);
    gridApi.setFilterModel(null);
    filterchanged(filtervalue);
  };

  let allNodes = [];
  const onSelectionChanged = (event) => {
    let listTypeRows = event.api.rowModel?.rowsToDisplay.filter((node) =>
      node.isSelected()
    );
    if (
      listTypeRows.length == event.api.rowModel.rowsToDisplay.length &&
      selectedRows.length < listTypeRows.length &&
      selectedRows.length != 0
    ) {
      event.api.deselectAll();
    } else {
      let array = [];
      event.api.forEachNode((node) => {
        allNodes.push(node?.data);
        const nodeRowId = node?.data?.id;
        if (nodeRowId) {
          if (node.isSelected()) {
            array.push(nodeRowId);
          } else {
          }
        }
      });
      setSelectedRows(listTypeRows);
      props.setSelectedIds(array);
    }
  };

  const filterchanged = (value) => {
    if (
      value.filterType === "text" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      var tableData = cacheData;
      var filtered = [];
      switch (value.filterName) {
        case "contains":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column]
                .toLowerCase()
                .indexOf(value.filterText.toLowerCase()) >= 0
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "doesn't contain":
          for (let i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column]
                .toLowerCase()
                .indexOf(value.filterText.toLowerCase()) === -1
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "is":
          for (let i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column].toLowerCase() ===
              value.filterText.toLowerCase()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "isn't":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column].toLowerCase() !==
              value.filterText.toLowerCase()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "starts with":
          for (var i = 0; i < tableData.length; i++) {
            if (
              tableData[i][value.column]
                .toLowerCase()
                .indexOf(value.filterText.toLowerCase()) === 0
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "ends with":
          for (var i = 0; i < tableData.length; i++) {
            var index = tableData[i][value.column]
              .toLowerCase()
              .lastIndexOf(value.filterText.toLowerCase());
            if (
              index >= 0 &&
              index ===
                tableData[i][value.column].length - value.filterText.length
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "is empty":
          for (var i = 0; i < tableData.length; i++) {
            if (tableData[i][value.column] === "") {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "is not empty":
          for (var i = 0; i < tableData.length; i++) {
            if (tableData[i][value.column] !== "") {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        default:
          // should never happen
          // console.warn("invalid filter name " + value.filterName);
          return false;
      }
    } else if (
      value.filterType === "number" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      tableData = cacheData;
      let newData = filterNumberData(
        value.filterName,
        tableData,
        value.column,
        value.filterText,
        value.filterText2
      );
      setRowData(newData);
      return true;
    } else if (
      value.filterType === "radio" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      tableData = cacheData;
      var newData = [];

      switch (value.filterName) {
        case "Selected":
          newData = tableData.filter(
            (e) =>
              (e[value.column]
                ? e[value.column].toLowerCase()
                : e[value.column]) === "yes"
          );
          setRowData(newData);
          return true;
        case "Unselected":
          newData = tableData.filter(
            (e) =>
              (e[value.column]
                ? e[value.column].toLowerCase()
                : e[value.column]) === "no"
          );
          setRowData(newData);
          return true;
      }
    } else if (
      value.filterType === "date" &&
      value.filterName !== "clear" &&
      value.filterName !== "" &&
      value.filterName !== undefined
    ) {
      tableData = cacheData;
      var filtered = [];
      var todayDate = new Date();
      switch (value.filterName) {
        case "in the last":
          if (value.duration === "days") {
            todayDate.setDate(todayDate.getDate() - value.filterText);
          } else if (value.duration === "weeks") {
            var days = value.filterText * 7;
            todayDate.setDate(todayDate.getDate() - days);
          } else if (value.duration === "months") {
            todayDate.setMonth(todayDate.getMonth() - value.filterText);
          }
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate > todayDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "due in":
          return true;
        case "On":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            if (
              tableDate.getDate() === filterDate.getDate() &&
              tableDate.getMonth() === filterDate.getMonth() &&
              tableDate.getYear() === filterDate.getYear()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "before":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            if (tableDate < filterDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "after":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            if (tableDate > filterDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "between":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            var filterDate = new Date(value.filterText);
            var filterDate2 = new Date(value.filterText2);
            if (tableDate > filterDate && tableDate < filterDate2) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "Today":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (
              tableDate.getDate() === todayDate.getDate() &&
              tableDate.getMonth() === todayDate.getMonth() &&
              tableDate.getYear() === todayDate.getYear()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "Yesterday":
          todayDate.setDate(todayDate.getDate() - 1);
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (
              tableDate.getDate() === todayDate.getDate() &&
              tableDate.getMonth() === todayDate.getMonth() &&
              tableDate.getYear() === todayDate.getYear()
            ) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "This Week":
          todayDate.setDate(todayDate.getDate() - 7);
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);

            if (tableDate > todayDate) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "This Month":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate.getMonth() === todayDate.getMonth()) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "This Month":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate.getYear() === todayDate.getYear()) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "Last Week":
          return true;
        case "Last Month":
          for (var i = 0; i < tableData.length; i++) {
            var tableDate = new Date(tableData[i][value.column]);
            if (tableDate.getMonth() === todayDate.getMonth() - 1) {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "is empty":
          for (var i = 0; i < tableData.length; i++) {
            if (tableDate === "") {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        case "is not empty":
          for (var i = 0; i < tableData.length; i++) {
            if (tableDate !== "") {
              filtered.push(tableData[i]);
            }
          }
          setRowData(filtered);
          return true;
        default:
          console.warn("invalid filter name " + value.filterName);
          return false;
      }
    } else if ((value.filterName = "clear")) {
      setRowData(cacheData);
    }
  };

  const filterNumberData = (
    operation,
    data,
    colText,
    filterText,
    filterText2
  ) => {
    switch (operation) {
      case ">=":
        return data.filter((e) => parseInt(e[colText]) >= parseInt(filterText));
      case "=":
        return data.filter((e) => parseInt(e[colText]) == parseInt(filterText));
      case ">":
        return data.filter((e) => parseInt(e[colText]) > parseInt(filterText));
      case "<":
        return data.filter((e) => parseInt(e[colText]) < parseInt(filterText));
      case "<=":
        return data.filter((e) => parseInt(e[colText]) <= parseInt(filterText));
      case "!=":
        return data.filter(
          (e) => parseInt(e[colText]) !== parseInt(filterText)
        );
      case "between":
        return data.filter(
          (e) =>
            parseInt(e[colText]) > parseInt(filterText) &&
            parseInt(e[colText]) < parseInt(filterText2)
        );
      case "not between":
        let lessArr = data.filter(
          (e) => parseInt(e[colText]) < parseInt(filterText)
        );
        let greatrArr = data.filter(
          (e) => parseInt(e[colText]) > parseInt(filterText2)
        );
        let final = lessArr.concat(greatrArr);
        return final;

      case "is empty":
        let emptyArr = [];
        return emptyArr;
      case "is not empty":
        return data;
    }
  };

  const onSearchColumns = (value) => {
    let newList = [...headerData];
    if (value == "") {
      newList.forEach((header) => {
        if (header.name) {
          header.isSearch = true;
        }
      });
    } else {
      newList.forEach((header) => {
        if (
          header.name &&
          header.name.toLowerCase().includes(value.toLowerCase())
        ) {
          header.isSearch = true;
        } else {
          header.isSearch = false;
        }
      });
    }
    setSearchValue(value);
    setheaderData(newList);
  };

  const changeFilterState = () => {
    setFilterState(!filterState);
  };

  const [clearFilterFlag, setClearFilterFlag] = useState(false);
  const clearFilters = () => {
    gridApi.setFilterModel(null);
    setClearFilterFlag(true);
    setShowFilter("");
  };

  const onColumnPinned = (event) => {
    var allCols = event.columnApi.getAllGridColumns();
    var allFixedCols = allCols.filter(function (col) {
      return col.getColDef().lockPosition;
    });
    var allNonFixedCols = allCols.filter(function (col) {
      return !col.getColDef().lockPosition;
    });
    var pinnedCount = allNonFixedCols.filter(function (col) {
      return col.getPinned() === "left";
    }).length;
    var pinFixed = pinnedCount > 0;
    var columnStates = [];
    allFixedCols.forEach(function (col) {
      if (pinFixed !== col.isPinned()) {
        columnStates.push({
          colId: col.getId(),
          pinned: pinFixed ? "left" : null,
        });
      }
    });
    if (columnStates.length > 0) {
      event.columnApi.applyColumnState({ state: columnStates });
    }
  };
  //-------------------------------------------------------------------------------------------

  const navigateToClient = (params) => {
    let keyData = params.data.Account_Name;

    let clients = [...allAccounts];
    clients = clients.filter(
      (one) => one.Account_Name == params.data.Account_Name
    );
    let color = params.data.Color;
    // if (clients.length > 0) {
    //   color = clients[0].Color;
    // }

    if (!keyData) {
      keyData = "N/A";
    }
    const eDiv = document.createElement("div");
    eDiv.innerHTML = `<div class="btn-group deal-status-dropdown-custom deal-status-dropdown-custom-name">
        <button type="button" style="background-color:${
          color ? color : "#5b6c97"
        }" class="btn btn-sm dropdown-toggle underline-icon" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="visually-hidden account-name-tile" title="${keyData}" style="color:${
      color ? props.invertColor(color, true) : "#fff"
    }">${keyData}</span>
         
        </button>
        <ul  class="dropdown-menu">
           <li class="status-deal"><a class="dropdown-item" ></a></li>
        </ul>
      </div><svg id="Deal-${
        params.data.id
      }" aria-hidden="true" focusable="false" data-prefix="far" data-icon="folder-open" class="svg-inline--fa fa-folder-open fa-w-18 tablefolder-icon cursor-point" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">' +
      '<path fill="currentColor" d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z">' +
      '</path></svg>`;
    const div = eDiv.querySelectorAll(".underline-icon")[0];
    const folderButton = eDiv.querySelectorAll(".tablefolder-icon")[0];
    folderButton.addEventListener("click", () => {
      if (params.data.zohoworkdriveforcrm__Workdrive_Folder_ID) {
        window.open(
          "https://workdrive.zoho.com/folder/" +
            params.data.zohoworkdriveforcrm__Workdrive_Folder_ID
        );
      }
    });

    div.addEventListener("click", () => {
      history.push({
        pathname: "/c/Accounts/DetailView/" + params.data.id,
        state: { data: params.data, action: "DetailView", module: "Accounts" },
      });
    });
    return eDiv;
  };

  const renderParentAccount = (params) => {
    return params.data.Parent_Account != null
      ? params?.data?.Parent_Account?.name
      : "";
  };
  function modifyByName(params) {
    let name1 = params.data?.Modified_By?.name;
    return name1;
  }

  function convertTimeZone(params) {
    let convertTime = new Date(params?.data?.Created_Time);
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/New_York", // Setting timezone to US Eastern Time
    };
    return convertTime.toLocaleString("en-US", options);
  }

  function modifyTime(params) {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "America/New_York", // Setting timezone to US Eastern Time
    };
    let ModifyTime = new Date(params?.data?.Modified_Time);
    return ModifyTime.toLocaleString("en-US", options);
  }

  const excelFilterParams = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          excelMode: "windows",
          buttons: ["reset", "apply"],
          closeOnApply: true,
        },
      },
    ],
  };
  const excelFilterParamsForModifiedBy = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          excelMode: "windows",
          buttons: ["reset", "apply"],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Modified_By) {
              return params.data.Modified_By.name;
            }
          },
        },
      },
    ],
  };
  const excelFilterParamsForParentAccount = {
    // can be 'windows' or 'mac'
    filters: [
      {
        excelMode: "windows",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          excelMode: "windows",
          buttons: ["reset", "apply"],
          closeOnApply: true,
          valueGetter: (params) => {
            if (params.data.Parent_Account) {
              return params.data.Parent_Account.name;
            }
          },
        },
      },
    ],
  };
  const NumberfilterParams = {
    filters: [
      {
        filter: "agNumberColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
        },
      },
    ],
  };

  const dateFilterParams = {
    filters: [
      {
        filter: "agDateColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
          comparator: function (filterDate, cellValue) {
            if (cellValue == null) return -1;

            return getDate(cellValue) - filterDate;
          },
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
          valueGetter: (params) => {
            let d = params.data.Modified_Time.substr(0, 10).split("-");
            return (
              d[1] +
              "-" +
              d[2] +
              "-" +
              d[0] +
              params.data.Modified_Time.substr(10)
            );
          },
          comparator: function (a, b) {
            return getDate(a) - getDate(b);
          },
        },
      },
    ],
  };
  const dateFilterParamsforCreatedTime = {
    filters: [
      {
        filter: "agDateColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
          comparator: function (filterDate, cellValue) {
            if (cellValue == null) return -1;
            return getDate(cellValue) - filterDate;
          },
        },
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          newRowsAction: "keep",
          buttons: ["reset", "apply"],
          closeOnApply: true,
          valueGetter: (params) => {
            let d = params.data.Created_Time.substr(0, 10).split("-");
            return d[1] + "-" + d[2] + "-" + d[0];
          },
          comparator: function (a, b) {
            return getDate(a) - getDate(b);
          },
        },
      },
    ],
  };
  function getDate(value) {
    let dateParts = "";
    if (value?.includes("/")) {
      dateParts = value?.split("/");
    } else if (value?.includes("-")) {
      dateParts = value?.split("-");
    }
    return new Date(
      parseInt(dateParts[0]),
      parseInt(dateParts[1]) - 1,
      parseInt(dateParts[2])
    );
  }

  const getViewColumns = (viewData) => {
    if (viewData == "All") {
      let columnsData = [...cacheHeaderData];
      columnsData.forEach((e) => (e.isChecked = false));
      // setheaderData(columnsData);
      setappliedFiltersForView([]);
      setActiveFiltersOnColumnLevel({});
      getFieldHeaders();
    } else {
      let viewApiNames = viewData.map((one) => {
        return one.id;
      });
      let columnsData = [...viewData];
      for (let i = 0; i < columnsData.length; i++) {
        if (viewApiNames.includes(columnsData[i].id)) {
          columnsData[i].isChecked = viewData[i].isChecked;
        } else {
          columnsData[i].isChecked = true;
        }
      }
      setheaderData(columnsData);
    }
  };

  const staticCellStyle = { "text-align": "center" };
  return (
    <div className="client-list-wrap">
      {getModalForEditColumns()}
      <div className="pad-col">
        <Row className="row-mar-0">
          <div className="col-sm-2 pr-0 pad-r0">
            {filterState == true ? (
              <Filter
                setShowFilter={setShowFilter}
                clearFilterFlag={clearFilterFlag}
                applyFilters={applyFilters}
                headerData={headerData}
              />
            ) : (
              <></>
            )}
          </div>
          <div className={"col-sm-12 client-module"} style={{ padding: "0px" }}>
            <Col
              className={
                filterState == true
                  ? "col-8-border outer pos-relative"
                  : "col-8-border active-deal col-sm-12 outer pos-relative"
              }
            >
              {/* {filterState ? firstActive ? (
                                <img
                                    className="filter-expand-lefticon"
                                    onClick={changeFilterState}
                                    src={leftexpandgray}
                                />
                            ) : (
                                <img
                                    className="filter-expand-lefticon"
                                    onClick={changeFilterState}
                                    src={leftexpand}
                                />
                            ) : firstActive ? (
                                <img
                                    className="filter-expand-lefticonactive"
                                    onClick={changeFilterState}
                                    src={leftexpandactivegray}
                                />
                            ) : (
                                <img
                                    className="filter-expand-lefticonactive"
                                    onClick={changeFilterState}
                                    src={leftexpandactive}
                                />
                            )} */}

              <TabView
                location={location}
                getViewColumns={getViewColumns}
                headerData={headerData}
                setPreAppliedFiltersForView={setPreAppliedFiltersForView}
                setActiveFiltersOnColumnLevel={setActiveFiltersOnColumnLevel}
                setActiveFilterAndGetActiveFilters={
                  setActiveFilterAndGetActiveFilters
                }
                activeFiltersOnColumnLevel={activeFiltersOnColumnLevel}
                preAppliedFiltersForView={preAppliedFiltersForView}
                appliedFiltersForView={appliedFiltersForView}
                setappliedFiltersForView={setappliedFiltersForView}
              />
              {
                <div className="ag-theme-alpine">
                  <div className="example-wrapper">
                    <div className="example-header">
                      <Row>
                        <Col
                          sm={filterState ? 4 : 3}
                          className={
                            filterState
                              ? "dealtablesearchdiv-active"
                              : "dealtablesearchdiv"
                          }
                          style={{ display: "flex" }}
                        >
                          <div
                            className="pos-relative"
                            style={{ display: "flex" }}
                          >
                            <div className="input-search-wrapper">
                              <div className="search-icon-wrapper">
                                <FontAwesomeIcon
                                  className="mr-sm-2 search-ico"
                                  icon={faSearch}
                                />
                              </div>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  onSearchFilterTexts(e.target.value)
                                }
                                placeholder="Search"
                                className="mr-sm-2 pad-fix search-filter search-deal"
                                style={{
                                  width: "17rem",
                                  borderColor: "#018EE0",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                minWidth: "max-content",
                                marginTop: "0px",
                              }}
                              className="task_filter_box"
                            >
                              {getTaskFilter()}
                            </div>
                          </div>
                        </Col>
                        <Col sm={filterState ? 1 : 2}></Col>
                        <Col sm={7}>
                          <div className="float-right">
                            <select
                              className="dealtable-dropdown"
                              onChange={() => onPageSizeChanged()}
                              id="page-size"
                            >
                              <option value="5">5 Records Per Page</option>
                              <option value="10">10 Records Per Page</option>
                              <option value="15">15 Records Per Page</option>
                              <option value="25">25 Records Per Page</option>
                              <option value="50" selected={true}>
                                50 Records Per Page
                              </option>
                              <option value="75">75 Records Per Page</option>
                              <option value="100">100 Records Per Page</option>
                            </select>
                            <div className="dot-fix">
                              <FontAwesomeIcon
                                className={
                                  dotActive
                                    ? "pad-icon-circle-active"
                                    : "pad-icon-circle"
                                }
                                icon={faCircle}
                              />
                            </div>
                            <div className="dot-fix">
                              <FontAwesomeIcon
                                className={
                                  backActive ? "pad-icon-active" : "pad-icon"
                                }
                                onClick={() => onBtDoublePrevious()}
                                icon={faAngleDoubleLeft}
                              />
                              <FontAwesomeIcon
                                className={
                                  backActive ? "pad-icon-active" : "pad-icon"
                                }
                                onClick={() => onBtPrevious()}
                                icon={faAngleLeft}
                              />
                              {from} to {to} of {totalRow}
                              <FontAwesomeIcon
                                className={
                                  frontActive ? "pad-icon-active" : "pad-icon"
                                }
                                onClick={() => onBtNext()}
                                icon={faAngleRight}
                              />
                              <FontAwesomeIcon
                                className={
                                  frontActive ? "pad-icon-active" : "pad-icon"
                                }
                                onClick={() => onBtDoubleNext()}
                                icon={faAngleDoubleRight}
                              />
                            </div>
                          </div>
                          <button
                            className="clear-filter btn btn-sm btn-primary"
                            style={{
                              display: showFilter != "" ? "block" : "none",
                            }}
                            onClick={clearFilters}
                          >
                            Clear Filter
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <div
                      id="myGrid"
                      style={
                        props.filterState
                          ? {
                              height: "calc(100vh - 248px)",
                              width: "100%",
                              padding: 15,
                              paddingTop: 0,
                            }
                          : {
                              height: "calc(100vh - 248px)",
                              width: "100%",
                              padding: 15,
                              paddingTop: 0,
                            }
                      }
                      className="ag-theme-alpine"
                    >
                      <AgGridReact
                        modules={(AllCommunityModules, AllModules)}
                        paginationNumberFormatter={function (params) {
                          return params.value.toLocaleString();
                        }}
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          flex: 1,
                          minWidth: 140,
                        }}
                        frameworkComponents={{
                          columnFilter: ColumnFilter,
                          agDateInput: CustomDateComponent,
                          customEditor: CustomEditorComponent,
                        }}
                        onFilterChanged={onChangeColumnFilters}
                        cacheQuickFilter={true}
                        suppressDragLeaveHidesColumns={true}
                        suppressPaginationPanel={true}
                        onColumnPinned={onColumnPinned}
                        onSelectionChanged={onSelectionChanged}
                        rowSelection={"multiple"}
                        groupSelectsChildren={true}
                        suppressRowClickSelection={true}
                        enableRangeSelection={true}
                        pagination={true}
                        paginationPageSize={50}
                        onGridReady={onGridReady}
                        onPaginationChanged={getPagingValues}
                        rowData={rowData}
                      >
                        <AgGridColumn
                          field=""
                          maxWidth={55}
                          lockPosition={true}
                          pinned="left"
                          cellClass="locked-col"
                          checkboxSelection={checkboxSelection}
                          headerCheckboxSelection={headerCheckboxSelection}
                          cellStyle={staticCellStyle}
                          suppressNavigable={true}
                        ></AgGridColumn>
                        {updatedHeaderData().map((e, headerIdx) => (
                          // console.log("e.field", e.field),
                          <AgGridColumn
                            pinned={headerIdx == 0 ? "left" : ""}
                            //  hide={e.name === "Job Number" ? false : e.isChecked}
                            hide={e.isChecked}
                            headerName={e.name}
                            filter={"agMultiColumnFilter"}
                            headerComponentFramework={(params) => {
                              return CustomHeaderComponent({
                                ...params,
                                filterStateToggel: CompactViewFilterTrigger,
                                updateSortingState: updateSortingState,
                                compactViewSortState: compactViewSortState,
                              });
                            }}
                            filterParams={
                              e.type === "text"
                                ? excelFilterParams
                                : e.field === "Modified_By"
                                ? excelFilterParamsForModifiedBy
                                : e.type === "number"
                                ? NumberfilterParams
                                : e.field === "Parent_Account"
                                ? excelFilterParamsForParentAccount
                                : e.field === "Modified_Time"
                                ? dateFilterParams
                                : e.field === "Created_Time"
                                ? dateFilterParamsforCreatedTime
                                : excelFilterParams
                            }
                            field={e.field}
                            minWidth={150}
                            suppressNavigable={
                              e.name === "Project Number" ? true : false
                            }
                            cellStyle={staticCellStyle}
                            headerTooltip={e.name}
                            cellRenderer={
                              e.field === "Account_Name"
                                ? navigateToClient
                                : e.field === "Parent_Account"
                                ? renderParentAccount
                                : e.field == "Modified_By"
                                ? modifyByName
                                : e.field == "Modified_Time"
                                ? modifyTime
                                : e.field == "Created_Time"
                                ? convertTimeZone
                                : e.name
                            }
                          />
                        ))}
                      </AgGridReact>
                    </div>
                  </div>
                </div>
              }
              <div
                className={
                  filterState == true ? "non-tab-div-active" : "non-tab-div"
                }
              >
                <div>
                  <div className="clear"></div>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </div>
  );
};

var checkboxSelection = function (params) {
  return params.columnApi.getRowGroupColumns().length === 0;
};
var headerCheckboxSelection = function (params) {
  return params.columnApi.getRowGroupColumns().length === 0;
};

export default ClientList;
