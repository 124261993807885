import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Collapse, Row } from "reactstrap";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ClientBody.css";
import { Popover as AntdPopover } from "antd";
import { faCaretDown, faChevronCircleDown, faChevronCircleRight, faEllipsisV, faLock, faPen, faSearchDollar, faStar as filledstar } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import rightexpand from "../../assets/images/svg/right.svg"
import leftexpand from "../../assets/images/svg/left.svg"
import rightexpandactive from "../../assets/images/svg/rightactive.svg"
import leftexpandactive from "../../assets/images/svg/leftactive.svg"
import DealList from '../Deal/DealList';
import GetRecordById from "../../common/util/getRecordById";
import { typeOf } from "mathjs";
import TaskListWithDeal from "../../common/List/TaskListWithDeal";
import Workdrive from "../Workdrive/workdrive";
import Tab_Workdrive from "../../tabs/Workdrive/tab_workdrive";
import leftexpandactivegray from "../../assets/images/svg/left-active-grayunderline.svg";
import blurLeftPng from '../../assets/images/left-blur-1.png'
import ClientProgress from "../../tabs/ClientProgress/ClientProgress";

const ClientBody = (props) => {
    const history = props.history;
    const params = useParams();
    const location = props.location;
    location.state = params;
    useEffect(() => {
        if (props.getParams) {
            props.getParams(params);
        }
    }, [location])
    const [data, setData] = useState({})
    const [settingToggle, setSettingToggle] = useState(false);
    const [contactToggle, setContactToggle] = useState(false);
    const [documentToggle, setDocumentToggle] = useState(false);
    const [serviceToggle, setServiceToggle] = useState(false);
    const [accountingToggle, setAccountingToggle] = useState(true);
    const [aboutToggle, setAboutToggle] = useState(true);
    const [rightState, setRightState] = useState(false);
    const [leftState, setLeftState] = useState(false);
    const [key, setKey] = useState("progress");
    const [clientkey, setClientKey] = useState([]);
    const [clientvalue, setClientValue] = useState([]);
    const [textVisib, setTextVisible] = useState('');
    const [SearchedItem, setSearchedItem] = useState("");
    const [textKey, setTextKey] = useState('');
    const [dealTopBoxCollapse, setDealTopBoxCollapse] = useState(true)
    const [tabArr, setTabArr] = useState(["Progress", "Emails", "Notes", "Tasks", "Activities","Accounting"]);
    const [remaingTab, setRemaingTab] = useState(["Workdrive"]);

    const changeRightExpand = () => {
        setRightState(!rightState);
    }

    const changeLeftExpand = () => {
        setLeftState(!leftState);
    }

    useEffect(e=>{
        // console.log("params", params);
        let arr = [];
        arr.push(params.id)
        props.setSelectedIds(arr)
      },[])

    const getStyleExpandIco = () => {
        if (rightState) {
            if (leftState) {
                return { paddingLeft: '12px', paddingRight: '12px' }
            } else {
                return { paddingRight: '12px' }
            }

        }
        else if (leftState) {
            if (rightState) {
                return { paddingLeft: '12px', paddingRight: '12px' }
            } else {
                return { paddingLeft: '12px' }
            }
        }
        else if (leftState && rightState) {
            return { paddingLeft: '12px', paddingRight: '12px' }
        }
        else {
            return {}
        }

    }



    useEffect(() => {
        if (params) {
            GetRecordById(params.module, params.id)
                .then((data) => {
                    if(data){
                        data = { ...data[0] };
                        delete data.Subform_2;
                        // delete data.Owner;
                        // delete data.Modified_By;
                        // delete data.Created_By;
                        // delete data.Tag;
                        setData(data);
                    }else{
                        props?.setDeleteDealFlag('')
                        history.push("/clientlist/Accounts/listview")
                      }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [params])

    useEffect(() => {
        if (data) {
            let keys = Object.keys(data)
            keys = keys.filter(key => key && (!key.includes('$')));
            setClientKey(keys);
            let arrValue = []
            keys.forEach((key) => {
                arrValue.push(data[key]);
            })
            setClientValue(arrValue)


        }
    }, [data])

    function GetFormattedDate() {
        let dateObj = new Date();
        let month = dateObj.getUTCMonth() + 1; //months from 1-12
        let day = dateObj.getUTCDate();
        let year = dateObj.getUTCFullYear();
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        return day + "/" + month + "/" + year;
    };

    const tilesColumn = ['Deal_Name', 'Account_Name', 'Client', "Project_Status"];
    const getViewTabs = (tab) => {

        switch (tab) {
            case "Progress":
                return <Tab className="client_body_deals" eventKey="progress" title="Progress">
                    <ClientProgress
                        invertColor={props.invertColor}
                        selectedIds={props.selectedIds}
                        setSelectedIds={props.setSelectedIds}
                        setCheckType={props.setCheckType}
                        checkType={props.checkType}
                        refreshEnable={props.refreshEnable}
                        setRefreshEnable={props.setRefreshEnable}
                        setTimerPrevTimeTrack={props.setTimerPrevTimeTrack}
                        startstopClockTimeTrack={ props.startstopClockTimeTrack}
                        setTimerTimeTrack={ props.setTimerTimeTrack}
                        timerTimeTrack={props.timerTimeTrack}
                        timerObj={props.timerObj}
                        deleteDealFlag={props.deleteDealFlag}
                        setDeleteDealFlag={props.setDeleteDealFlag}
                        timeTrackFlag={props.timeTrackFlag}
                        initializeTimeTrack={props.initializeTimeTrack}
                        data={data}
                        params={{ module: 'Deals', action: 'listview' }}
                        history={history}
                        location={location}
                        
                    />
                   
                </Tab>
            case "Emails":
                return <Tab eventKey="emails" title="Emails">
                    Emails
                </Tab>
            case "Notes":
                return <Tab eventKey="notes" title="Notes">
                    Notes
                </Tab>
            // case "Tasks":
            //     return <Tab className={"client-body-task"} eventKey="tasks" title="Tasks">
            //         <TaskListWithDeal data={data} />

            //     </Tab>
            case "Activities":
                return <Tab eventKey="activities" title="Activities">
                    Activities
                </Tab>
            case "Accounting":
                return <Tab eventKey="accounting" title="Accounting">
                    Accounting
                </Tab>
            case "Workdrive":
                return <Tab eventKey="workdrive" title="Workdrive">
                    <Tab_Workdrive  refreshEnable={props.refreshEnable}
                    setRefreshEnable={props.setRefreshEnable} data={data} />
                </Tab>
            default:
                return <></>
        }
    }

    const getTilesStatusDropdown = (data, key, dataType) => {
        if (dataType == 'string') {
            if (tilesColumn.includes(key)) {
                if (key == 'Project_Status') {
                    // return  <StatusDropdown toggelButton={
                    //     <span style={projectData.custom_status_color ? { backgroundColor: projectData.custom_status_color } : { backgroundColor: 'lightgray' }} className="project_status_custom">{projectData.custom_status_name ? projectData.custom_status_name : "N/A"} <FontAwesomeIcon style={{ marginLeft: '30px' }} icon={faCaretDown} /></span>

                    // } items={getDealStatus()} onSelect={(value) => {
                    //     updateProject("custom_status", value.id, value.name)
                    //     // updateTask(data, "custom_status", value.id)
                    // }} />
                } else {
                    // return <StatusDropdown toggelButton={
                    //     <sapn style={{ backgroundColor: '#5b6c97' }} className="project_status_custom">{data[key] ? data[key] : "N/A"}</sapn>

                    // } items={[]} onSelect={(value) => {
                    //     // updateProject("custom_status", value.id, value.name)
                    //     // updateTask(data, "custom_status", value.id)
                    // }} />
                }

            } else {
                return data[key]
            }
        }
        if (dataType == 'Object') {
            if (tilesColumn.includes(key)) {
                // return <StatusDropdown toggelButton={
                //     <sapn style={getClientColor(data.Account_Name ? data.Account_Name.id : '')} className="project_status_custom">{data[key] ? data[key].name : "N/A"} </sapn>

                // } items={[]} onSelect={(value) => {
                //     // updateProject("custom_status", value.id, value.name)
                //     // updateTask(data, "custom_status", value.id)
                // }} />
            } else {
                return data[key].name
            }
        }



    }


    const doSearch = (name) => {
        const fields = { ...data };
        let arr = Object.keys(fields)
        if (name) {
            arr = []
            Object.keys(fields).forEach((key) => {
                let originalKey = key.replaceAll('sandeepext', '');
                originalKey = originalKey.replaceAll('_', ' ');
                originalKey = originalKey.replaceAll('__', ' ');
                if (originalKey.toLowerCase().includes(name.toLowerCase())) {
                    arr.push(key)
                }
            })
        }
        setClientKey(arr);
        setSearchedItem(name)
    };

    return (
       
        <div className="pad-col ">
            <Row className="row-mar-0">
                <div className={leftState ? "display-none" : "widthper-21"}>

                    <div className="col-3-border outer-border-3">
                        <div className="collapse-body-tiles" onClick={() => setDealTopBoxCollapse(!dealTopBoxCollapse)}>
                            {dealTopBoxCollapse ? (
                                <FontAwesomeIcon
                                    className="mar-right-5"
                                    icon={faChevronCircleDown}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="mar-right-5"
                                    icon={faChevronCircleRight}
                                />
                            )}
                            Information
                        </div>
                        <div className="collapse-body-tiles-border"></div>
                        <Collapse className="contact-roles" isOpen={dealTopBoxCollapse}>

                            <div className="deal-details-topbox">
                                {/* <p>
                                    <strong className="custom-label-client-head">Client:</strong> <span className="padl-14 client-text-style" >{data.Account_Name}</span>
                                </p> */}
                                <p>
                                    <strong className="custom-label-client-head">Client Number:</strong>  <span className="padl-10">{data.Account_Number}</span>
                                </p>
                                <p>
                                    <strong className="custom-label-client-head">Start Date:</strong>  <span className="padl-10">{GetFormattedDate()}</span>
                                </p>
                                <p>
                                    <strong className="custom-label-client-head">Priority: </strong> <span className="padl-10 star-color" >
                                        <FontAwesomeIcon icon={filledstar} />
                                        <FontAwesomeIcon icon={filledstar} />
                                        <FontAwesomeIcon icon={filledstar} />
                                        <FontAwesomeIcon icon={faStar} />
                                        <FontAwesomeIcon icon={faStar} />
                                    </span>
                                </p>
                                <p>
                                    <strong className="custom-label-client-head">Client Status: </strong> <span className="padl-14 inprogress-text">Active  <FontAwesomeIcon
                                        className="mar-Inprogess-icon"
                                        icon={faCaretDown}
                                    /></span>
                                </p>
                                <p>
                                    <strong className="custom-label-client-head">Phone Number:</strong>  <span className="padl-10">{data.Phone}</span>
                                </p>

                            </div>

                        </Collapse>
                        <div className="collapse-body-tiles" onClick={() => {
                            setAboutToggle(!aboutToggle);
                        }}>
                            {aboutToggle ? (
                                <FontAwesomeIcon
                                    className="mar-right-5"
                                    icon={faChevronCircleDown}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="mar-right-5"
                                    icon={faChevronCircleRight}
                                />
                            )}
                            About This Client
                        </div>
                        <div className="collapse-body-tiles-border"></div>



                        <div className=" ">
                            <div className="deal-details-bottumbox">
                                <div
                                    class="head-list-style about-deals-collapse font-14"

                                >

                                    <Collapse isOpen={aboutToggle}>

                                        <div className="position-relative about-deal-search">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="pad-fix search-filter-deals"
                                                value={SearchedItem}
                                                onChange={(e) => doSearch(e.target.value)}
                                            />
                                            <FontAwesomeIcon className="search-ico" icon={faSearchDollar} />
                                        </div>
                                        <div style={dealTopBoxCollapse ? { height: 'calc(100vh - 520px)' } : { minHeight: 'calc(100vh - 260px)' }} className="about-this-account-content">
                                            {
                                                clientkey?.map((key, e) => {
                                                    let originalKey = key.replaceAll('sandeepext', '');
                                                    originalKey = originalKey.replaceAll('_', ' ');
                                                    originalKey = originalKey.replaceAll('__', ' ');
                                                    let value = data[key];
                                                    let valType = typeOf(value);
                                                    // console.log("line 205" + valType);
                                                    // console.log("line 205" + key);
                                                    // console.log("line 205" + data[key]);
                                                    return (
                                                        <>
                                                            {originalKey.includes("$") ? "" : <><p
                                                                className="abt-deal-field"
                                                            >
                                                                <strong
                                                                    className="custom-label-job-head"
                                                                >{originalKey}:
                                                                </strong>
                                                                {textKey === originalKey && (data[key] != "Yes" &&
                                                                    data[key] != "No") ? <input
                                                                    type="text"
                                                                    defaultValue={data[key]}
                                                                    onChange={(event) => {

                                                                    }}

                                                                />
                                                                    : textKey == originalKey && (data[key] == "Yes" ||
                                                                        data[key] == "No") ?
                                                                        <select
                                                                            className='select-client'
                                                                            name={key}

                                                                            defaultValue={data[key]}
                                                                        >
                                                                            <option>Yes</option>
                                                                            <option>No</option>
                                                                        </select> :
                                                                        <span
                                                                        >
                                                                            {
                                                                                valType == "Object" ? getTilesStatusDropdown(data, key, 'Object') : getTilesStatusDropdown(data, key, 'String')

                                                                            }
                                                                        </span>


                                                                }
                                                                {
                                                                    (originalKey === "Owner") || (originalKey == "Deal Name") || (originalKey == "Client") ?
                                                                        <FontAwesomeIcon
                                                                            icon={faLock}
                                                                        />
                                                                        :
                                                                        <FontAwesomeIcon
                                                                            icon={faPen}
                                                                            id={originalKey}
                                                                            onClick={() => {

                                                                            }}
                                                                        />

                                                                }

                                                            </p>

                                                            </>
                                                            }




                                                        </>
                                                    )
                                                })

                                            }
                                        </div>




                                    </Collapse>

                                </div>


                            </div>
                        </div>
                    </div>
                    {/* <div style={{paddingBottom:1}} className="col-3-border outer-border-3">

                        <div className="deal-details-topbox">
                            <p>
                                <strong className="custom-label-client-head">Client:</strong> <span className="padl-14 client-text-style" >{data.Account_Name}</span>
                            </p>
                            <p>
                                <strong className="custom-label-client-head">Client Number:</strong>  <span className="padl-10">{data.Account_Number}</span>
                            </p>
                            <p>
                                <strong className="custom-label-client-head">Start Date:</strong>  <span className="padl-10">{GetFormattedDate()}</span>
                            </p>
                            <p>
                                <strong className="custom-label-client-head">Priority: </strong> <span className="padl-10 star-color" >
                                    <FontAwesomeIcon icon={filledstar} />
                                    <FontAwesomeIcon icon={filledstar} />
                                    <FontAwesomeIcon icon={filledstar} />
                                    <FontAwesomeIcon icon={faStar} />
                                    <FontAwesomeIcon icon={faStar} />
                                </span>
                            </p>
                            <p>
                                <strong className="custom-label-client-head">Client Status: </strong> <span className="padl-14 inprogress-text">Active  <FontAwesomeIcon
                                    className="mar-Inprogess-icon"
                                    icon={faCaretDown}
                                /></span>
                            </p>
                            <p>
                                <strong className="custom-label-client-head">Phone Number:</strong>  <span className="padl-10">{data.Phone}</span>
                            </p>
                        </div>

                        <div className="about-overflow">
                            <div className="deal-details-bottumbox">
                                <div
                                style={{marginLeft:'-12px'}}
                                    class="head-list-style font-14"
                                    onClick={() => {
                                        setAboutToggle(!aboutToggle);
                                    }}
                                >
                                    {aboutToggle ? (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faCaretDown}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faCaretRight}
                                        />
                                    )}
                                    About This Client
                                </div>
                                <Collapse isOpen={aboutToggle}>
                                    {
                                        clientkey.length > 0 ?
                                            clientkey.map((key, e) => {
                                                let originalKey = key.replaceAll('sandeepext', '');
                                                originalKey = originalKey.replaceAll('_', ' ');
                                                originalKey = originalKey.replaceAll('__', ' ');
                                                let value = data[key];
                                                let valType = typeOf(value);
                                                console.log("line 205" + valType);
                                                return (
                                                    <>
                                                        {originalKey.includes("$") ? "" : <><p
                                                            className="abt-deal-field"
                                                        >
                                                            <strong
                                                                className="custom-label-job-head"
                                                            >{originalKey}:
                                                            </strong>
                                                            {textKey === originalKey && (data[key] != "Yes" &&
                                                                data[key] != "No") ? <input
                                                                type="text"
                                                                // type={getTagType(key)}
                                                                defaultValue={data[key]}
                                                                onChange={(event) => {
                                                                    // editValues(event, jobData[key], key);
                                                                }}

                                                            />
                                                                : textKey == originalKey && (data[key] == "Yes" ||
                                                                data[key] == "No") ?
                                                                    <select
                                                                        className='select-client'
                                                                        name={key}
                                                                        // onChange={handleOnChange}
                                                                        defaultValue={data[key]}
                                                                    >
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select> :
                                                                    <span
                                                                    >
                                                                        {
                                                                            valType == "Object" ? data[key].name:data[key]

                                                                        }
                                                                    </span>


                                                            }
                                                            {
                                                                (originalKey === "Owner") || (originalKey == "Deal Name") || (originalKey == "Client") ?
                                                                    <FontAwesomeIcon
                                                                        icon={faLock}
                                                                    />
                                                                    :
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        id={originalKey}
                                                                        onClick={() => {
                                                                            // spanClickHandler(jobData[key], originalKey)
                                                                        }}
                                                                    />

                                                            }

                                                        </p>

                                                        </>
                                                        }



                                                       
                                                    </>
                                                )

                                            })
                                            : <></>
                                    }



                                </Collapse>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div style={getStyleExpandIco()} className={
                    (rightState && leftState) ? "widthper-100 pos-relative" :
                        rightState || leftState ? (rightState ? "pad-l0 widthper-79 pos-relative" : "pad-r0 widthper-79 pos-relative") : "pad-l0 pad-r0 widthper-58 pos-relative"}>
                    {rightState ? <img src={rightexpandactive} className="mid-right-icon-active" onClick={changeRightExpand} /> :
                        <img src={rightexpand} className="mid-right-icon" onClick={changeRightExpand} />}
                    {leftState ?
                        key != "progress" ?

                            <img src={leftexpandactivegray}
                                className="mid-left-icon-active" onClick={changeLeftExpand} />
                            : <img src={leftexpandactive} className="mid-left-icon-active" onClick={changeLeftExpand} /> :
                        key != "progress" ? <img src={blurLeftPng} className="mid-left-icon" onClick={changeLeftExpand} /> :
                            <img src={leftexpand} className="mid-left-icon" onClick={changeLeftExpand} />}



                    <div className="outer-border-6 task-group-tabs">
                        <Tabs

                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="job-parent-tabs"
                        // className={
                        //     (rightState && leftState) ? "job-parent-tabs widthper-100 job-middle-tab job-middle-tabs clientmiddle-tab-padding-doubleActive line-height-36" :
                        //         rightState || leftState ? "job-parent-tabs job-middle-tab job-middle-tabs clientmiddle-tab-padding-active line-height-33" :
                        //             "job-parent-tabs job-middle-tab job-middle-tabs clientmiddle-tab-padding line-height-33"}
                        >
                            {tabArr.map(tab => getViewTabs(tab))}
                            {/* <Tab className="client_body_deals" eventKey="progress" title="Deals & Quotes">
                                <DealList
                                    dealLocation={"Accounts"}
                                    data={data}
                                    params={{ module: 'Deals', action: 'listview' }}
                                    history={history}
                                    location={location} />
                            </Tab>
                            <Tab eventKey="emails" title="Emails">
                                Emails
                            </Tab>
                            <Tab eventKey="notes" title="Notes">
                                Notes
                            </Tab>
                            <Tab className={"client-body-task"} eventKey="tasks" title="Tasks">
                                <TaskListWithDeal data={data} />
                              
                            </Tab>
                            <Tab eventKey="activities" title="Activities">
                                Activities
                            </Tab>
                            <Tab eventKey="accounting" title="Accounting">
                                Accounting
                            </Tab>
                            <Tab eventKey="workdrive" title="Workdrive">
                                <Tab_Workdrive data={data} />
                            </Tab> */}
                        </Tabs>
                        <AntdPopover placement="bottom" trigger={'click'} content={<>

                            
                                {remaingTab.map((tab, index) => {
                                    return <div className="row task-filter-hovr"><div onClick={() => {
                                        const tabs = [...tabArr];
                                        const remTab = [...remaingTab];
                                        let remainTab = tabs[tabs.length - 1]
                                        tabs[tabs.length - 1] = tab;
                                        remTab[index] = remainTab;
                                        setRemaingTab(remTab);
                                        setTabArr(tabs);
                                        setKey(tab.toLowerCase());
                                    }} className="col-sm-9">
                                        <a>
                                            {tab}
                                        </a>
                                    </div></div>
                                })}

                            
                        </>}>
                            <FontAwesomeIcon className="more-tab-body-icon" icon={faEllipsisV} />
                        </AntdPopover>
                    </div>
                </div>

                <div className={rightState ? "display-none" : "widthper-21"}>
                    <div className="col-3-border outer-border-3">
                        <div className="job-rightbox-overflow">

                            <div className="deal-details-right-topbox">

                                <div
                                    class="head-list-style font-14 ml-3 collapse-body-tiles collapse-body-tiles-right-links"
                                    onClick={() => {
                                        setSettingToggle(!settingToggle);
                                    }}
                                >
                                    {settingToggle ? (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleDown}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleRight}
                                        />
                                    )}
                                    Account Setting
                                </div>
                                <p style={{ width: '100%' }} className="collapse-body-tiles-border collapse-body-tiles-right-links"></p>
                                <Collapse isOpen={settingToggle}>
                                    Account Setting
                                </Collapse>



                            </div>
                            <div className="deal-details-right-midbox">
                                <div
                                    class="head-list-style font-14 collapse-body-tiles collapse-body-tiles-right-links"
                                    onClick={() => {
                                        setServiceToggle(!serviceToggle);
                                    }}
                                >
                                    {serviceToggle ? (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleDown}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleRight}
                                        />
                                    )}
                                    Service Setting
                                </div>
                                <div style={{ width: '100%' }} className="collapse-body-tiles-border collapse-body-tiles-right-links"></div>
                                <Collapse isOpen={serviceToggle}>

                                    Service Setting

                                </Collapse>

                            </div>

                            <div className="deal-details-right-midbox pr-2 mt-2">
                                <div
                                    class="head-list-style font-14  align-items-center justify-content-between "
                                    onClick={() => {
                                        setContactToggle(!contactToggle);
                                    }}
                                >
                                    <div className="collapse-body-tiles collapse-body-tiles-right-links">
                                        {contactToggle ? (
                                            <FontAwesomeIcon
                                                className="mar-right-5"
                                                icon={faChevronCircleDown}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                className="mar-right-5"
                                                icon={faChevronCircleRight}
                                            />
                                        )}
                                        Contact Roles
                                    </div>
                                    <div style={{ width: '100%' }} className="collapse-body-tiles-border collapse-body-tiles-right-links"></div>

                                </div>

                                <Collapse className="contact-roles" isOpen={contactToggle}>
                                    <p>
                                        <span> Contact Roles</span>
                                    </p>
                                    <p>
                                        <span> Contact Roles</span>
                                    </p>
                                    <p>
                                        <span> Contact Roles</span>
                                    </p>

                                </Collapse>

                            </div>


                            <div className="deal-details-bottumbox p-0 mt-2">
                                <div
                                    class="head-list-style font-14 collapse-body-tiles collapse-body-tiles-right-links"
                                    onClick={() => {
                                        setDocumentToggle(!documentToggle);
                                    }}
                                >
                                    {documentToggle ? (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleDown}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleRight}
                                        />
                                    )}
                                    Documents
                                </div>
                                <div style={{ width: '100%' }} className="collapse-body-tiles-border collapse-body-tiles-right-links"></div>
                                <Collapse isOpen={documentToggle}>

                                    <p>
                                        <span>Document</span>
                                    </p>
                                </Collapse>

                            </div>

                            <div className="deal-details-bottumbox p-0 mt-2">
                                <div
                                    class="head-list-style font-14 collapse-body-tiles collapse-body-tiles-right-links"
                                    onClick={() => {
                                        setAccountingToggle(!accountingToggle);
                                    }}
                                >
                                    {accountingToggle ? (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleDown}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faChevronCircleRight}
                                        />
                                    )}
                                    Accounting Status
                                </div>
                                <div style={{ width: '100%' }} className="collapse-body-tiles-border collapse-body-tiles-right-links"></div>
                                <Collapse isOpen={accountingToggle}>

                                    <p>
                                        <span>Account Status Completed</span>
                                    </p>
                                </Collapse>

                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
};
export default ClientBody;
