import React, { useRef,forwardRef,useImperativeHandle,useState } from "react";

import Preliminary from "./preliminary/preliminary";
import NycProject from "./nycproject/nycproject";
import Homeowner from "./homeowner/homeowner";
import Permitting from "./permitting/permitting";
import PVSystem from "./pvsystem/pvsystem";
const Layout = forwardRef((props,ref) => {
  const [addressValChange, setAddressValChange] = useState(false);

  //Hrushikesh shelke new code
  const saveCancelButtonRef = useRef();
  useImperativeHandle(ref, () => ({
    saveButtonClick() {
      let currentsidebar=localStorage.getItem("currentsidebar");
            if(props.sidebarCardsStatus.Preliminary && props.sidebarCardsStatus.NavActivetab!='Preliminary'){
                if((props.sidebarCardsStatus.NycProject && props.sidebarCardsStatus.NavActivetab!='NycProject') || props.sidebarCardsStatus.SkipNycFlag){
                    if(props.sidebarCardsStatus.Homeowner && props.sidebarCardsStatus.NavActivetab!='Homeowner'){
                        if(props.sidebarCardsStatus.Permitting && props.sidebarCardsStatus.NavActivetab!='Permitting'){
                            if(props.sidebarCardsStatus.PVSystem && props.sidebarCardsStatus.NavActivetab!='PVSystem'){
                              saveCancelButtonRef.current.savePVSystem();
                            }else{
                              saveCancelButtonRef.current.savePVSystem();
                            }
                        }else{
                          saveCancelButtonRef.current.savePermitting();
                        }
                    }else{
                      saveCancelButtonRef.current.saveHomeowner();
                    }
                }else{
                  saveCancelButtonRef.current.saveNycProject();
                }
            }else{
              saveCancelButtonRef.current.savePreliminary();
            }
    },
    toggelActiveBar(d) {
      saveCancelButtonRef.current.toggelActiveBar(d);
    }
  }));
  //hrushikesh shelke new code

  return (
    <div className="color-white">
      {
        props.layoutstate == "preliminary" ? (
          <Preliminary
            ref={saveCancelButtonRef}
            sidebarCardsStatus={props.sidebarCardsStatus}
            updateTheSidebarState={props.updateTheSidebarState}
            allServicesName={props.allServicesName}
            setAllServicesName={props.setAllServicesName}
            allDeals={props.allDeals}
            setData={props.setData}
            setEntityId={props.setEntityId}
            buttonPosition={props.buttonPosition}
            setButtonPosition={props.setButtonPosition}
            isAccess={props.isAccess}
            selectedRole={props.selectedRole}
            addressValChange={addressValChange}
            setAddressValChange={setAddressValChange}
            func={props.func}
            getProjectNumber={props.getProjectNumber}
            handleOnChangeDeal={props.handleOnChangeDeal}
            refreshDeal={props.refreshDeal}
            data={props.data}
            listState={props.listState}
            layoutstate={props.layoutstate}
            setLayoutState={props.setLayoutState}
            setListState={props.setListState}
            setNycVisible={props.setNycVisible}
            isNycVisible={props.isNycVisible}
          />
        ) : props.layoutstate == "nycproject" ? (
          <NycProject
            ref={saveCancelButtonRef}
            sidebarCardsStatus={props.sidebarCardsStatus}
            setSidebarCardsStatus={props.setSidebarCardsStatus} 
            updateTheSidebarState={props.updateTheSidebarState}
            setData={props.setData}
            location={props.location}
            getDealData={props.getDealData}
           isAccess={props.isAccess}
            selectedRole={props.selectedRole}
            addressValChange={addressValChange}
            setAddressValChange={setAddressValChange}
            handleOnChangeDeal={props.handleOnChangeDeal}
            data={props.data}
            refreshDeal={props.refreshDeal}
            listState={props.listState}
            layoutstate={props.layoutstate}
            setLayoutState={props.setLayoutState}
            setListState={props.setListState}
            setNycFilingDisable={props.setNycFilingDisable}
            nycFilingDisable={props.nycFilingDisable}
            setownerType={props.setownerType}
            setLoader={props.setLoader}
          />
        ) : props.layoutstate == "homeowner" ? (
          <Homeowner
            ref={saveCancelButtonRef}
            sidebarCardsStatus={props.sidebarCardsStatus}
            updateTheSidebarState={props.updateTheSidebarState}
            isAccess={props.isAccess}
            selectedRole={props.selectedRole}
            handleOnChangeDeal={props.handleOnChangeDeal}
            refreshDeal={props.refreshDeal}
            setMailAddressDisable={props.setMailAddressDisable}
            mailAddressDisable={props.mailAddressDisable}
            data={props.data}
            listState={props.listState}
            layoutstate={props.layoutstate}
            setLayoutState={props.setLayoutState}
            setListState={props.setListState}
            setownerType={props.setownerType}
          />
        ) : props.layoutstate == "permitting" ? (
          <Permitting
            ref={saveCancelButtonRef}
            sidebarCardsStatus={props.sidebarCardsStatus}
            updateTheSidebarState={props.updateTheSidebarState}
            isAccess={props.isAccess}
            selectedRole={props.selectedRole}
            handleOnChangeDeal={props.handleOnChangeDeal}
            refreshDeal={props.refreshDeal}
            data={props.data}
            listState={props.listState}
            layoutstate={props.layoutstate}
            setLayoutState={props.setLayoutState}
            setListState={props.setListState}
          />
        ) :
          <PVSystem
            ref={saveCancelButtonRef}
            sidebarCardsStatus={props.sidebarCardsStatus}
            updateTheSidebarState={props.updateTheSidebarState}
            entityId={props.entityId}
            buttonPosition={props.buttonPosition}
            isAccess={props.isAccess}
            location={props.location}
            selectedRole={props.selectedRole}
            ClickSaveSave={props.ClickSaveSave}
            handleOnChangeDeal={props.handleOnChangeDeal}
            refreshDeal={props.refreshDeal}
            data={props.data}
            listState={props.listState}
            layoutstate={props.layoutstate}
            setLayoutState={props.setLayoutState}
            setListState={props.setListState}
          />

      }

    </div>
  );
});

export default Layout;