import React, { useContext, useEffect, useState } from 'react'
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper';
import { CCollapse } from '@coreui/react';
import AppContext from '../../Context/AppContext';

const MentionUserNotes = ({showDropdown, allusers, editorValue, handleChange, charToCompare, setShowDropdown}) => {
    const [visible, setVisible] = useState(false);
    const [sortedUsers,setSortedUsers]=useState([]);
    const [allDealsUsers,setallDealsUsers]=useState([])
    const [allDealsSortedUsers,setallDealsSortedUsers]=useState([])
    const AppData = useContext(AppContext);
    const { state, dispatch } = AppData || {};
    const allDealsAndUsers = state.AllDeals || [];
    const allAccounts=state.AllAccounts || [];


    useEffect(e=>{
        if(allDealsAndUsers){
          let arr=[];
          allDealsAndUsers?.forEach(e=>{
            let account=allAccounts?.find(k=>k.Account_Name==e.Account_Name?.name)
            let obj={
              id:e.id,
              Deal_Name:e.Deal_Name,
              Account_Name:e.Account_Name?.name,
              AccountColor:account?account.Color:"#5b6c97",
              DealColor:"#5b6c97",
            }
            arr.push(obj);
          })
          setallDealsUsers(arr);
          setallDealsSortedUsers(arr)
        }
      },[])
      // useEffect(e=>{
      //   console.log("Modified Accounts And Deals",allDealsUsers)
      //   console.log("charToComparecharToCompare", charToCompare);
      // },[allDealsUsers, charToCompare])

    useEffect(e=>{
    //   console.log("Users",allusers)
      if(allusers){
          setSortedUsers(allusers);
      }
    },[allusers])
    useEffect(e=>{
      if(showDropdown){
          setVisible(true)
      }else{
          setVisible(false)
      }
    },[showDropdown])

    useEffect(e=>{ 
      if(editorValue.toString("html")){
        editorValue = editorValue.toString("html").replaceAll("<p>", "")
        editorValue = editorValue.toString("html").replaceAll("</p>", "")
          let typedName=editorValue.split('@')
          typedName=typedName[typedName.length-1]?.split(' ');

          if(editorValue && charToCompare=="@"){
            let typedName=editorValue.split(charToCompare)
            typedName=typedName[typedName.length-1]?.split(' ');
            if(typedName){
                let filteredArray=allusers.filter(e=>e.name?.includes(typedName[0]));
                if(filteredArray?.length > 0){
                  setSortedUsers(filteredArray);
                }else{
                  setShowDropdown(false)
                }
            }else{
                setSortedUsers(allusers);
            }
        }else if(editorValue && charToCompare=="#"){
            let typedName=editorValue.split(charToCompare)
            typedName=typedName[typedName.length-1]?.split(' ');
            if(typedName){
                let filteredArray=allDealsUsers.filter(e=>e.Account_Name?.includes(typedName[0]) || e.Deal_Name?.includes(typedName[0]));
                if(filteredArray?.length > 0){
                  setallDealsSortedUsers(filteredArray);
                }else{
                  setShowDropdown(false)
                }
            }else{
              setallDealsSortedUsers(allDealsUsers);
            }
        }else{
          setSortedUsers(allusers);
          setallDealsSortedUsers(allDealsUsers)
        }

        //   if(typedName){
        //       let filteredArray=allusers.filter(e=>e.name.includes(typedName[0]));
        //       setSortedUsers(filteredArray);
        //   }else{
        //       setSortedUsers(allusers);
        //   }
      }else{
          setSortedUsers(allusers);
      }
    },[editorValue.toString("html")])

    // console.log("noteToSubmit", noteToSubmit);

    const selectUser=(e)=>{
        if(e){
            let newInpute="";
            let name=e.currentTarget?.attributes?.userName?.value;
            if(name){
                editorValue = editorValue.toString("html")
                editorValue = editorValue.toString("html").replaceAll("<p>", "")
                editorValue = editorValue.toString("html").replaceAll("</p>", "")
                let arr=editorValue.split(charToCompare);
                arr.forEach((element,i) => {
                    if(i==arr.length-1){
                        newInpute=newInpute+(i==0?name:charToCompare+name)
                    }else{
                        newInpute=newInpute+(i==0?element:charToCompare+element)
                    }
                });
                console.log(newInpute.toString("html"))
                let module="";
                if(e?.currentTarget?.classList?.contains("Accounts")){
                  handleChange(newInpute.toString("html"),"Accounts")
                }else if(e?.currentTarget?.classList?.contains("Deals")){
                  handleChange(newInpute.toString("html"),"Deals")
                }else{
                  handleChange(newInpute.toString("html"))
                }
            }
        }
      }

    // const selectUser1=(e)=>{
    //     if(e){
    //         let newInpute="";
    //         let newInputeHidden="";
    //         let name=e.currentTarget?.attributes?.userName?.value;
    //         if(name){
    //             editorValue = editorValue.toString("html")
    //             editorValue = editorValue.toString("html").replaceAll("<p>", "")
    //             editorValue = editorValue.toString("html").replaceAll("</p>", "")
    //             let arr=editorValue.split('@');
    //             arr.forEach((element,i) => {
    //                 if(i==arr.length-1){
    //                     newInpute=newInpute+(i==0?name:'@'+name)
    //                     newInputeHidden=newInputeHidden+(i==0?name:'{@'+name+'}')
    //                 }else{
    //                     newInpute=newInpute+(i==0?element:'@'+element)
    //                     newInputeHidden=newInputeHidden+(i==0?element:'{@'+element+'}')
    //                 }
    //             });
    //                 console.log(newInpute.toString("html"))
    //                 // setchatMessageInpute(newInpute)
    //                 handleChange(newInpute.toString("html"))
    //                 setNoteToSubmit(newInputeHidden)
    //         }
    //     }
    //   }

    // return (
    //   <OtsideClickClosingWrapper className={`Mentions-Note-Collapse ${visible && "Bordered-Box-Notes"}`} close={(e) => {
    //     if (visible) {
    //       setVisible(false)
    //     }
    //   }}>
    //      <CCollapse
    //       id="collapseWidthExample" verticle visible={visible}>
    //           {sortedUsers && sortedUsers.map(user=>{
    //               return (
    //                   <div userName={user.name} className='user-card' onClick={selectUser}>
    //                       <span>{user.name}</span>
    //                   </div>
    //               )
    //           })
    //          }
    //       </CCollapse>
         
    //   </OtsideClickClosingWrapper>
    // )
    if(charToCompare=='@'){
        return (
          <OtsideClickClosingWrapper className={`Mentions-Note-Collapse ${visible && "Bordered-Box-Notes"}`} close={(e) => {
            if (visible) {
              setVisible(false)
            }
          }}>
             <CCollapse
              id="collapseWidthExample" verticle visible={visible}>
                  <div className='User-Mention-Header'>Mention Users</div>
                  {sortedUsers && sortedUsers.map(user=>{
                      return (
                          <div userName={user.name} className='user-card' onClick={selectUser}>
                              <span>{user.name}</span>
                          </div>
                      )
                  })
                 }
              </CCollapse>
             
          </OtsideClickClosingWrapper>
        )
      }else if(charToCompare=='#'){
        return (
          <OtsideClickClosingWrapper className={`Mentions-Note-Collapse ${visible && "Bordered-Box-Notes"}`} close={(e) => {
            if (visible) {
              setVisible(false)
            }
          }}>
             <CCollapse
              id="collapseWidthExample" verticle visible={visible}>
                  <div className='User-Mention-Header'>Accounts And Deals</div>
                  {allDealsSortedUsers && allDealsSortedUsers.map(user=>{
                      return (
                          <div className='Deal-Account-Wrapper'>
                            <div userName={user.Account_Name} style={{backgroundColor:user.AccountColor,borderColor:user.AccountColor}} className='user-card Accounts' onClick={selectUser}>
                                <span>{user.Account_Name}</span>
                            </div>
                            
                            <div userName={user.Deal_Name} style={{backgroundColor:user.DealColor,borderColor:user.DealColor}} className='user-card Deals' onClick={selectUser}>
                                <span>{user.Deal_Name}</span>
                            </div>
                          </div>
                      )
                  })
                 }
              </CCollapse>
             
          </OtsideClickClosingWrapper>
        )
      }
}

export default MentionUserNotes