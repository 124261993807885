import React, { useContext, useEffect, useState } from 'react'
import './RenderAllPipelineTemplates.css';
import { Button, Collapse } from 'antd';
import { Col, Row } from 'reactstrap';
import { AllDeals } from '../../Context/AppActions';
import AppContext from '../../Context/AppContext';
import { CaretDownOutlined } from '@ant-design/icons';
import AwsServerService from '../../common/util/AwsServerService';
import { TaskGroupCard } from './TaskGroupCard';
import { TaskGroupCardForTemplate } from './TaskGroupCardForTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AddInlineData from './AddInlineData';
import swal from "sweetalert";

const { Panel } = Collapse;
export const HeaderContent = ({ data, key  }) => {
  console.log(data[key]);

  return <Row sm={12}>
    <Col sm={6}>{data[key]}</Col>
    <Col sm={6}></Col>
  </Row>
}
export const RenderAllPipelineTemplates = ({allPipelineData,viewTaskFunction,refreshData, setPageRefreshData,
  group,open,setOpen,viewPipelineData,setViewPipelineData,selectedTable,isSubTaskView, setIsSubTaskView
}) => {
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [deals, setDeals] = useState([]);
  const [group1Data,setGrpoup1Data] = useState({});
  const [group2Data,setGrpoup2Data] = useState([]);
  const [groupFilter,setGroupFilter] = useState({
    group1 : {name:"Pipeline", value:"Pipeline" },
    group2 : {name:"Pipeline", value:"PipelineROWID" },
  })
  
  useEffect(e=>{
    setGroupFilter(group)
  },[group])
  useEffect(e=>{
    if(allPipelineData){
        let obj={};
        allPipelineData?.forEach(data=>{
          if(obj[data.PipelineROWID]){
            // let allTasks = allPipelineData.filter(p=>p.DealName==data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[data.PipelineROWID]=[...obj[data.PipelineROWID],...data.Tasks];
          }else{
            // let allTasks = allPipelineData.filter(p=>p.DealName==data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[data.PipelineROWID]=[...data.Tasks];
          }
        });
        // console.log("---------- new template11111 ",obj);
        setGrpoup1Data(obj);
    }
    
  },[groupFilter,allPipelineData])

  useEffect(e=>{
    handleViewPipeline(e);
  },[refreshData])

  const handleViewPipeline = (e,id) => {
    e?.preventDefault();
    let pipeline = allPipelineData.find(e=>e.PipelineROWID==id);
    if(pipeline){
      console.log("res pipeline: ", pipeline);
      setViewPipelineData(pipeline);
      setOpen(true);
    }
    // AwsServerService.getAllPipelineTemplateData(id).then((res) => {
    //   console.log("res: ", res);
    //   setViewPipelineData(res)
    //   setOpen(true)
    // }).catch((e)=>{
    //   // swal("Alert!", "Something went wrong", "warning");
    // })
  }
  useEffect(e => {
    setDeals(state.AllDeals)
    // console.log(state.AllDeals);

  }, [state.AllDeals])
  const getPipelineName=(id)=>{
    let pipeline = allPipelineData.find(e=>e.PipelineROWID==id);
    if(pipeline){
      return pipeline.PipelineName;
    }else{
      return "N/A"
    }
  }
  return (
    <div className='PiplinesList-Wrapper'>
      <Collapse defaultActiveKey={['1']} expandIconPosition="left" >
        {group1Data && Object.keys(group1Data)?.length>0 && Object.keys(group1Data)?.map((key, index) => {       
          return <Panel className='Pipline-Panel-hover' header={<Row sm={12}>
            <Col style={{display:"flex",flexDirection:"row"}} sm={6}><span style={{backgroundColor: "rgb(91, 108, 151)",color: "white"}} className='Group1-Card'>{getPipelineName(key)}</span><Button className='Pipeline-View-Btn' type='primary' icon={<FontAwesomeIcon icon={faEye}/>} ghost 
            onClick={(e)=>handleViewPipeline(e,key)}
            >View</Button></Col>
            <Col sm={5}></Col>
            <Col sm={1} className='Pipeline-Delete-Btn-Wrapper'><FontAwesomeIcon className='Pipeline-Delete-Btn' onClick={(e)=>{
              // console.log("key", key);              
                swal({
                  title: "Warning!",
                  text: "Are you sure you want to delete the Task?",
                  icon: "warning",
                  buttons: {
                    cancel: "Cancel",
                    confirm: "Proceed",
                  },
                }).then(async(isDelete)=>{
                  if(isDelete){
                    AwsServerService.deletePipelineFromTemplate(key).then((res)=>{
                      console.log("Deleted Id");  
                      setPageRefreshData(!refreshData)                
                    }).catch((e)=>{
                      console.log("error while deleting pipeline");                  
                    })
                  }})
            }} icon={faTrashAlt} style={{color:"red"}}/></Col>
          </Row>}
            key={key} style={{ height: 'auto' }}>
              {/* <AddInlineData
                onAdd={(e)=>{handleAddRow(e, taskData)}}
                onDelete={(e)=>{handleDeleteRow()}}
                onSave={(e)=>{handleSave()}}
              /> */}
               <TaskGroupCardForTemplate viewTaskFunction={viewTaskFunction} groupFilter={groupFilter} allPipelineData={allPipelineData} data={group1Data[key]} 
               header={key} selectedTable={selectedTable} isSubTaskView={isSubTaskView} setIsSubTaskView={setIsSubTaskView} editData={[]} refreshData={refreshData}
               setPageRefreshData={setPageRefreshData} />
          </Panel>
        })}

      </Collapse>
    </div>
  )
}
