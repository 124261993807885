import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";
import AppContext from "../../../Context/AppContext";
import { HexColorPicker } from "react-colorful";
import { Dropdown as AntDropDown } from "antd";
import Select from "react-select";
import Geocode from "react-geocode";
import axios from "axios";
import ReactInputMask from "react-input-mask";
import { OtsideClickClosingWrapper } from "../../../common/EmojiReactionPicker/OtsideClickClosingWrapper";
import GlobalTable from "../../../common/DealTableChart/GlobalTable";
import {
  ClientFullServices,
  ClientServices,
  ServicesForClient,
  billingTerms,
  isServiceAddersClickable
} from "../../../Context/AppActions";
import Config from "../../../config.json";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faEdit,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import GoogleMapReact from "google-map-react";
import Marker from "../../Deal/createdeal/layout/preliminary/propertyinformation/Marker";
import { v4 as uuid } from "uuid";
import CurrencyInput from "react-currency-input-field";

export default function ClientLayout(props) {
  const [serviceSelected, setServiceSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const placeInputRef = useRef(null);
  const placeInputRef1 = useRef(null);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [isAddService, setIsAddService] = useState(false);
  const allAccounts = state.AllAccounts || [];
  const [idToDelete, setIdToDelete] = useState("");
  const [serviceIdToDelete, setServiceIdToDelete] = useState("");
  // const [props.clientFullServices, setprops.clientFullServices] = useState([])
  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey("AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw");
  // set response language. Defaults to english.
  Geocode.setLanguage("en");
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("us");
  Geocode.setLocationType("ROOFTOP");
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();
  const [allServicess, setAllServices] = useState([]);
  const [allServiceOption, setAllServiceOption] = useState([]);
  const [allServiceOptionCache, setAllServiceOptionCache] = useState([]);
  const [totalCost, setTotalCost] = useState();
  const [serviceFields, setServiceFields] = useState({
    Name: null,
    Service_Cost: "",
    Service_Location: "",
  });
  const [billingLat, setBillingLat] = useState(40.705121);
  const [billingLong, setBillingLong] = useState(-73.82738499999999);
  const [zoom, setZoom] = useState(17);
  const [shippingLat, setShippingLat] = useState(40.705121);
  const [shippingLong, setShippingLong] = useState(-73.82738499999999);
  const [nestedModal1, setNestedModal1] = useState(false);
  const [modal, setShowModal] = useState(false)
  const [backdrop, setBackdrop] = useState("static");
  const [tierNo, setTierNo] = useState();
  const [itemName, setItemName] = useState();
  const [cost, setCost] = useState();
  const [billingStageTask, setBillingStageTask] = useState();
  const [billingStageStatus, setBillingStageStatus] = useState();
  const [editState,setEditState] = useState("");
  const [editServiceState,setEditServiceState] = useState("");
  const [deleteAdminModal, setDeleteAdminModal] = useState(false);
  const [deleteServiceAdderModal, setDeleteServiceAdderAdminModal] = useState(false);
  const [billingDetailstogglestate, setBillingDetailstogglestate] = useState(true);
  const [shippingDetailstogglestate, setShippingDetailstogglestate] = useState(false);
  const [billingTermstogglestate, setBillingTermstogglestate] = useState(true);
  const [serviceAdderstogglestate, setServiceAdderstogglestate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteFlag, setDeleteFlag] = useState(false)

  const [serviceName, setServiceName] = useState();
  const [serviceCost, setServiceCost] = useState();
  const [adderBillingStageTask, setAdderBillingStageTask] = useState();
  const [adderBillingStageStatus, setAdderBillingStageStatus] = useState();

  let optionsForPriority = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  let optionsForProjectType = [
    { label: "Resedential", value: "Resedential" },
    { label: "Commercial", value: "Commercial" },
    { label: "Resedential/Commercial", value: "Resedential/Commercial" },
  ];

  const billingTermsCache = [
    {value : "Retainer", label : "Retainer" },
    {value : "Approval", label : "Approval" },
    {value : "Permitting", label : "Permitting" },
    {value : "Prior to Sign-Off", label : "Prior to Sign-Off" },
    {value : "After Sign-Off", label : "After Sign-Off" },
  ]

  const [billingTermsArr, setBillingTermsArr] = useState([
    {value : "Retainer", label : "Retainer" },
    {value : "Approval", label : "Approval" },
    {value : "Permitting", label : "Permitting" },
    {value : "Prior to Sign-Off", label : "Prior to Sign-Off" },
    {value : "After Sign-Off", label : "After Sign-Off" },
  ]);

  const isSaveClicked = state.SaveClickedForClient;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
  });  

  // New changes //

  const resetBillingModalFields = (e) => {
    setItemName(null);
    setCost("");
    setBillingStageTask("");
    setBillingStageStatus("");
  };
  const resetAdderModalFields = (e) => {
    setServiceName(null);
    setServiceCost("");
    setAdderBillingStageTask("");
    setAdderBillingStageStatus("");
  };

  const [pvIndex, setPvIndex] = useState(-1)
  const editpvSystemInfo = async (info, i) => {
    setValidation2({
      modelItem:'1',
      approvalCost:'1',
      billingStageTask:'1',
      billingStageStatus:'1'
    })
    setPvIndex(i)
    setItemName({label : info?.item, value : info?.item});
    // setTierNo(info?.tierNo)
    setCost(info?.cost);
    setBillingStageTask(info?.billingStageTask);
    setBillingStageStatus(info?.billingStageStatus);
    setNestedModal1(!nestedModal1);
    setEditState("test");
  };
  const editServiceAdderInfo = async (info, i) => {
    setValidation3({
      modelItems:'1',
      approvalCosts:'1',
      adderBillingStageTask:'1',
      adderBillingStageStatus:'1'
    })
    setPvIndex(i)
    setServiceName({label : info?.item, value : info?.item});
    setServiceCost(info?.cost);
    setAdderBillingStageTask(info?.billingStageTask);
    setAdderBillingStageStatus(info?.billingStageStatus);
    setShowModal(!modal);
    setEditServiceState("test");
  };

  const deletepvSystemInfo = () => {
    var copyArray = props?.formInputsValue?.billingTermsInfo?.filter(
      (info, i) => i != idToDelete
    );
    // calculateTotalValues(copyArray)
    // props.setPVSystemInfo(copyArray);
    // props.handleOnChangeDeal({currentTarget: { name: 'pvSystemInfo', value: copyArray }})
    setDeleteFlag(!deleteFlag)
    props.setFormInputsValue({
      ...props.formInputsValue,
      billingTermsInfo: copyArray,
    });
  };
  const deleteServiceAdderInfo = () => {
    var copyArray = props?.formInputsValue?.serviceAddersInfo?.filter(
      (info, i) => i != serviceIdToDelete
    );
    // calculateTotalValues(copyArray)
    // props.setPVSystemInfo(copyArray);
    // props.handleOnChangeDeal({currentTarget: { name: 'pvSystemInfo', value: copyArray }})
    props.setFormInputsValue({
      ...props.formInputsValue,
      serviceAddersInfo: copyArray,
    });
  };
  // New chnages Ends //
  const initPlaceAPI = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current
      );
    }
  };
  const initPlaceAPIShipping = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef1.current
      );
    }
  };
  useEffect(
    (e) => {
      if (props.services) {
        setServiceSelected(props.services);
      }
    },
    [props.services]
  );
  useEffect(
    (e) => {
      dispatch(ServicesForClient(serviceFields));
    },
    [serviceFields]
  );
  useEffect(
    (e) => {
      dispatch(ClientServices(serviceSelected));
      // dispatch(ClientFullServices(serviceSelected));
    },
    [serviceSelected]
  );
  useEffect(
    (e) => {
      if (allServicess) {
        let arr = [
          // { label: "Create New Service", value: "Create New Service" },
        ];
        allServicess?.forEach((one) => {
          if (!state?.ClientFullServices?.find((e) => e?.id == one?.id)) {
            arr.push({ label: one?.Name, value: one?.Name });
          }
        });
        let filteredserivces = serviceSelected?.filter(
          (b) => !state?.ClientFullServices?.find((e) => e?.id == b?.id)
        );
        setAllServiceOption(arr);
        setAllServiceOptionCache(arr)
        setServiceSelected(filteredserivces);
      }
    },
    [allServicess, state?.ClientFullServices]
  );

  useEffect(e=>{
    setTotalCost(props.formInputsValue.contractPrice)
  },[props.formInputsValue.contractPrice])

  useEffect(e=>{
    setTierNo(props.formInputsValue.tierNumber)
  },[props.formInputsValue.tierNumber])

  const handleResetClick = () => {
    props.setClientFullServices([]);
  };

  const handleFullClick = () => {
    let allService = [...allServicess];
    let arr = [];
    allService.forEach((one) => {
      let obj = { name: one.Name, id: one.id };
      arr.push(obj);
    });
    props.setClientFullServices(arr);
  };

  useEffect(
    (e) => {
      let amount = 0;
      if (props.clientFullServices) {
        props.clientFullServices?.forEach((e) => {
          amount += Number(e.amount);
        });
      }
      // setTotalCost(amount);
      dispatch(ClientFullServices(props.clientFullServices));
    },
    [props.clientFullServices]
  );

  // useEffect(
  //   (e) => {
  //     const totalAmount = Object.values(props.billingTermsCost).reduce(
  //       (total, cost) => {
  //         // Convert the cost to a number, and if it's NaN, treat it as 0
  //         const costNumber = parseFloat(cost) || 0;
  //         return total + costNumber;
  //       },
  //       0
  //     );
  //     dispatch(billingTerms(props.billingTermsCost));
  //   },
  //   [props.billingTermsCost, totalCost]
  // );

  useEffect(e=>{
    if(props?.formInputsValue?.billingTermsInfo?.length > 0){
      let arr = billingTermsCache?.filter(c => 
        !props?.formInputsValue?.billingTermsInfo?.some(k => k.item === c.value)
          );    
      setBillingTermsArr(arr)
    }else{
      setBillingTermsArr(billingTermsCache)
    }
  }, [props?.formInputsValue?.billingTermsInfo?.length, editState])

  useEffect(e=>{ 
    if(props?.formInputsValue?.serviceAddersInfo?.length > 0){
      let arr = allServiceOptionCache?.filter(c => 
        !props?.formInputsValue?.serviceAddersInfo?.some(k => k.item === c.value)
          );
      setAllServiceOption(arr)
    }else{
      setAllServiceOption(allServiceOptionCache)
    }
  }, [props?.formInputsValue?.serviceAddersInfo?.length, editServiceState])

  function remainingInterconnections(){
    let dataLength = props?.formInputsValue?.billingTermsInfo?.length;
     let count = Number(tierNo) - dataLength;
     return count
  }

  useEffect(()=>{ 
    if(tierNo==""||tierNo==undefined || props.formInputsValue?.contractPrice > getTotalCost() || props.formInputsValue?.contractPrice < getTotalCost()){
      dispatch(isServiceAddersClickable(1));
    }else{
      dispatch(isServiceAddersClickable(remainingInterconnections()));
    }
},[tierNo,props?.formInputsValue?.billingTermsInfo.length, props.formInputsValue?.contractPrice])

  const getTotalCost=()=>{
    let num=0;
    if(props?.formInputsValue?.billingTermsInfo){
      props?.formInputsValue?.billingTermsInfo.forEach((one)=>{
       num=Number(num)+Number(one.cost)
     })
    }
    return num
  }

  const fetchAllServicess = async () => {
    let config = {
      Type: "Get",
      method: "Get",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      url: "https://www.zohoapis.com/crm/v2/Requested_Service",
    };
    await axios
      .post(Config.backend_url + "clientservice", { config: config })
      .then((res) => {
        if (res.data.data) {
          setAllServices(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addServiceToList = (data) => {
    if (data && !serviceSelected?.find((e) => e.id == data.id)) {
      setServiceSelected([data, ...serviceSelected]);
      setServiceFields(null);
    } else if (editFlag == true && data) {
      let newData = serviceSelected.map((s) => {
        if (s.id == data?.id) {
          return data;
        } else {
          return s;
        }
      });
      setServiceSelected(newData);
      setEditFlag(false);
      setServiceFields(null);
    } else {
      alert("Service Already Selected");
    }
  };

  useEffect(
    (e) => {
      initPlaceAPI();
      initPlaceAPIShipping();
    },
    [props.formInputsValue]
  );

  useEffect((e) => {
    fetchAllServicess();
  }, []);

  useEffect(
    (e) => {
      let state=localStorage.getItem("currentsidenavbar");
      if(state=="BillingDetails"){
        setBillingDetailstogglestate(true)
        setShippingDetailstogglestate(false)
      }else if(state=="ShippingDetails"){
        setShippingDetailstogglestate(true)
        setBillingDetailstogglestate(false)
      }else if(state=="BillingTerms"){
        setBillingTermstogglestate(true)
        setServiceAdderstogglestate(false)
      }else if(state=="ServiceAdders"){
        setServiceAdderstogglestate(true)
        setBillingTermstogglestate(false)
      }
    },
    [localStorage.getItem("currentsidenavbar")]
  );

  const [validation, setValidation] = useState({
    clientName: "0",
    // clientNickname: "0",
    parentClient: "0",
    clientStatus: "0",
    priority: "0",
    projectType: "0",
    projectVolume: "0",
    phone: "0",
    // website: "0",
    billingaddress: "0",
    billingStreet: "0",
    billingCity: "0",
    billingState: "0",
    billingCode: "0",
    // billingCountry: "0",
    shippingAddress: "0",
    shippingStreet: "0",
    shippingCity: "0",
    shippingState: "0",
    shippingCode: "0",
    // shippingCountry: "0",
    contractPrice : "0",
    interconnNo: "0",
    // services: "0",
    serviceCost: "0",
    // serviceLocation:"0"
  });

  const [validation2, setValidation2] = useState({
    modelItem:'0',
    approvalCost:'0',
    billingStageTask:'0',
    billingStageStatus:'0'
  })
  const [validation3, setValidation3] = useState({
    modelItems:'0',
    approvalCosts:'0',
    adderBillingStageTask:'0',
    adderBillingStageStatus:'0'
  })

  const validationCss = {
    transition: "width 0.4s ease-in-out",
    "box-shadow": "rgb(255 98 98) 0px 0px 7px 1px",
    "border-color": "#ff0000",
  };

  const sections = [
    {
      ClientInfo: {
        clientName: props.formInputsValue.Account_Name
          ? props.formInputsValue.Account_Name
          : props.formInputsValue.clientName,
        // clientNickname: props.formInputsValue.clientNickname
        //   ? props.formInputsValue.clientNickname
        //   : props.formInputsValue.clientNickname,
        parentClient: props.formInputsValue.parentClient
          ? props.formInputsValue.parentClient
          : props.formInputsValue.parentClient,
        priority: props.formInputsValue.priority
          ? props.formInputsValue.priority
          : props.formInputsValue.priority,
        projectType: props.formInputsValue.projectType
          ? props.formInputsValue.projectType
          : props.formInputsValue.projectType,
        projectVolume: props.formInputsValue.projectVolume
          ? props.formInputsValue.projectVolume
          : props.formInputsValue.projectVolume,
        phone: props.formInputsValue.Phone
          ? props.formInputsValue.Phone
          : props.formInputsValue.phone,
        // website: props.formInputsValue.Website
        //   ? props.formInputsValue.Website
        //   : props.formInputsValue.website,
      },
    },
    {
      BillingDetails: {
        billingaddress: props.formInputsValue.Billing_Address
          ? props.formInputsValue.Billing_Address
          : props.formInputsValue.billingaddress,
        billingStreet: props.formInputsValue.Billing_Street
          ? props.formInputsValue.Billing_Street
          : props.formInputsValue.billingStreet,
        billingCity: props.formInputsValue.Billing_City
          ? props.formInputsValue.Billing_City
          : props.formInputsValue.billingCity,
        billingState: props.formInputsValue.Billing_State
          ? props.formInputsValue.Billing_State
          : props.formInputsValue.billingState,
        billingCode: props.formInputsValue.Billing_Code
          ? props.formInputsValue.Billing_Code
          : props.formInputsValue.billingCode,
        // billingCountry: props.formInputsValue.Billing_Country
        //   ? props.formInputsValue.Billing_Country
        //   : props.formInputsValue.billingCountry,
      },
    },
    {
      ShippingDetails: {
        shippingAddress: props.formInputsValue.Shipping_Address
          ? props.formInputsValue.Shipping_Address
          : props.formInputsValue.shippingAddress,
        shippingStreet: props.formInputsValue.Shipping_Street
          ? props.formInputsValue.Shipping_Street
          : props.formInputsValue.shippingStreet,
        shippingCity: props.formInputsValue.Shipping_City
          ? props.formInputsValue.Shipping_City
          : props.formInputsValue.shippingCity,
        shippingState: props.formInputsValue.Shipping_State
          ? props.formInputsValue.Shipping_State
          : props.formInputsValue.shippingState,
        shippingCode: props.formInputsValue.Shipping_Code
          ? props.formInputsValue.Shipping_Code
          : props.formInputsValue.shippingCode,
        // shippingCountry: props.Shipping_Country
        //   ? props.formInputsValue.Shipping_Country
        //   : props.formInputsValue.shippingCountry,
      },
    },
    {
      FullServices: {
        // services: serviceFields?.Name,
        // serviceCost: serviceFields?.Service_Cost,
        // serviceLocation: serviceFields?.Service_Location
      },
    },
    {
      BillingTerms: {
        contractPrice: totalCost,
        interconnNo: tierNo,
      },
    },
    {
      // Services: {
      //   services: serviceFields?.Name,
      //   serviceCost: serviceFields?.Service_Cost,
      //   serviceLocation: serviceFields?.Service_Location,
      // },
    },
  ];

  useEffect(
    (e) => { 
      let obj = {};
      let flag = true;
      let CurrntState = props.layoutstate;
      let index =
        CurrntState == "ClientInfo"
          ? 0
          : CurrntState == "BillingDetails"
          ? 1
          : CurrntState == "ShippingDetails"
          ? 2
          : CurrntState == "FullServices"
          ? 3
          : CurrntState == "BillingTerms"
          ? 4
          : 5;
      if (
        sections[index][CurrntState] != null ||
        sections[index][CurrntState] != undefined
      ) {
        Object.keys(sections[index][CurrntState])?.forEach((one) => {
           ;
          if (
           ( sections[index][CurrntState][one] == "" ||
            sections[index][CurrntState][one] == undefined)
          ) {
            flag = false;
            obj[one] = "-1";
          } else {
            obj[one] = validation[one];
          }
        });
        setValidation(obj);
      }
      if (flag) {
        if (props.layoutstate == "ClientInfo") {
          let data=props.formInputsValue.Phone
          ? props.formInputsValue.Phone
          : props.formInputsValue.phone;
          var value1 = data.replace(/[-#*$+/?=%() ]/g, "");
          let onlyNo= value1.replace(/\D/g, '');
          if(onlyNo.length==10){
            props.setLayoutState("BillingDetails"); 
            setBillingDetailstogglestate(true)
            setShippingDetailstogglestate(false)
            if(!props.updatedSections.includes("BillingDetails")){
            props.setUpdateSectionState([...props.updatedSections,"BillingDetails"])
            }
          }
        } else if (props.layoutstate == "BillingDetails") {
          let data=props.formInputsValue.Billing_Code
          ? props.formInputsValue.Billing_Code
          : props.formInputsValue.billingCode
          if(data.length==5){
            if(props.formInputsValue.ShippnigAddDifferent){
              props.setLayoutState("ShippingDetails") 
              setShippingDetailstogglestate(true)
              setBillingDetailstogglestate(false)
              if(!props.updatedSections.includes("ShippingDetails")){
              props.setUpdateSectionState([...props.updatedSections,"ShippingDetails"])
            }
          }else{
              props.setLayoutState("FullServices");
              if(!props.updatedSections.includes("FullServices")){
              props.setUpdateSectionState([...props.updatedSections,"FullServices"])
              }
            }
          }
        }else if (props.layoutstate == "ShippingDetails") {
          let data=props.formInputsValue.Billing_Code
          ? props.formInputsValue.Shipping_Code
          : props.formInputsValue.shippingCode
          if(data.length==5){
            props.setLayoutState("FullServices");
            if(!props.updatedSections.includes("FullServices")){
            props.setUpdateSectionState([...props.updatedSections,"FullServices"])
            }
          }
        }else if (props.layoutstate == "FullServices") { 
          if(props.clientFullServices?.length > 0){
            props.setLayoutState("BillingTerms");
            setBillingTermstogglestate(true)
            setServiceAdderstogglestate(false)
            if(!props.updatedSections.includes("BillingTerms")){
            props.setUpdateSectionState([...props.updatedSections,"BillingTerms"])
            }
          }
        }else if (props.layoutstate == "BillingTerms"){
          if(remainingInterconnections() > 0){
            swal("Alert!",   "Please fill all unassigned payment tiers.", "warning");
            return
          }
          if(props.formInputsValue?.contractPrice > getTotalCost()){
            swal("Alert!",   "Total Cost is below Contract Price.", "warning");
            return
          }else if(props.formInputsValue?.contractPrice < getTotalCost()){
            swal("Alert!",   "Total Cost is above Contract Price.", "warning");
            return
          }
          props.setLayoutState("ServiceAdders");
          setServiceAdderstogglestate(true)
          setBillingTermstogglestate(false)
          if(!props.updatedSections.includes("ServiceAdders")){
          props.setUpdateSectionState([...props.updatedSections,"ServiceAdders"])
          }
        }
        //  else {
        //   let data=props.formInputsValue.Shipping_Code
        //   ? props.formInputsValue.Shipping_Code
        //   : props.formInputsValue.shippingCode
        //   if(props.clientFullServices?.length > 0){
        //     props.setLayoutState("Services");
        //     if(!props.updatedSections.includes("Services")){
        //     props.setUpdateSectionState([...props.updatedSections,"Services"])
        //     }
        //   }
        // }
      }
    },
    [isSaveClicked]
  );
  const billingDetailsFilled=function(){
   let flag=false;
   if(props.formInputsValue.billingCode==''||props.formInputsValue.billingCity==""
   ||props.formInputsValue.billingStreet==''||props.formInputsValue.billingState==""
   ||props.formInputsValue.billingAddress==""){
    flag=true;
   }
   return flag;
  }

  console.log(
    "props?.formInputsValue?.billingTermsInfo",
    props?.formInputsValue?.billingTermsInfo
  );


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleRemoveLogo = (e) => {
    let doc=document.getElementById("upload-photo");
    doc.value='';
    setSelectedFile(null);
  };

  const handleSelectProjectType = (values) => {
    if (values?.value === props?.formInputsValue?.projectType?.value) {
      setValidation({...validation,projectType:'-1'})
      props.handleChange({ target: { name: "projectType", value: "" } });
    } else {
      setValidation({...validation,projectType:'1'})
      props.handleChange({ target: { name: "projectType", value: values } });
    }
  };

  useEffect(() => {
    let ob = {};
    Object.keys(props.formInputsValue).forEach((one, i) => {
      if (props.formInputsValue[one] != "") {
        ob[one] = "1";
      } else {
        ob[one] = validation[one];
      }
    });
    setValidation(ob);
  }, [
    props?.formInputsValue?.billingaddress,
    props?.formInputsValue?.shippingAddress,
  ]);

  const onBlurProjectAdd = async (value) => {
     ;
    await Geocode.fromAddress(value).then(
      async (response) => {
        const address = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;
        setBillingLat(lat);
        setBillingLong(lng);
        var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "",
          borough = "",
          neighbourhood = "";
        let Country = "";
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                houseNO = response.results[0].address_components[i].long_name;
                break;
              case "route":
                street =
                  houseNO +
                  " " +
                  response.results[0].address_components[i].long_name;
                break;
              case "neighborhood":
                neighbourhood =
                  response.results[0].address_components[i].long_name;
                break;
              case "sublocality_level_1":
                borough = response.results[0].address_components[i].long_name;
                if (
                  borough == "Manhattan" ||
                  borough == "Bronx" ||
                  borough == "The Bronx" ||
                  borough == "Brooklyn" ||
                  borough == "Queens" ||
                  borough == "Staten Island"
                ) {
                  if (borough == "Manhattan") {
                    city = "New York";
                  } else if (borough == "Bronx" || borough == "The Bronx") {
                    city = borough;
                  } else if (borough == "Brooklyn") {
                    city = borough;
                  } else if (borough == "Queens") {
                    city = neighbourhood;
                  } else if (borough == "Staten Island") {
                    city = borough;
                  } else {
                    localStorage.setItem("borough", "0");
                  }
                } else {
                  city = response.results[0].address_components[i].long_name;
                }
                break;
              case "locality":
                if (
                  borough != "Manhattan" &&
                  borough != "Bronx" &&
                  borough != "Brooklyn" &&
                  borough != "Queens" &&
                  borough != "Staten Island"
                ) {
                  city = response.results[0].address_components[i].long_name;
                }

                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                zipcode = response.results[0].address_components[i].long_name;
                break;
              case "country":
                Country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        let obj = { ...props.formInputsValue };
        obj.billingStreet = street;
        obj.billingCity = city;
        obj.billingState = state;
        obj.billingCode = zipcode;
        obj.billingCountry = Country;
        obj.billingaddress = address;
        props.setFormInputsValue(obj);
      },
      (error) => {
        if (props.formInputsValue.billingaddress == "") {
          let obj = { ...props.formInputsValue };
          obj.billingStreet = "";
          obj.billingCity = "";
          obj.billingState = "";
          obj.billingCode = "";
          obj.billingCountry = "";
          obj.billingaddress = "";
          props.setFormInputsValue(obj);
        }
      }
    );
  };

  const onBlurShippingAddress = async (value) => {
    if(value=="Clear"){
      let obj = { ...props.formInputsValue };
      obj.shippingStreet = "";
      obj.shippingCity = "";
      obj.shippingState = "";
      obj.shippingCode = "";
      // obj.shippingCountry = "";
      obj.shippingAddress = "";
      obj.ShippnigAddDifferent =false;
      props.setFormInputsValue(obj);
    }else{ 
    Geocode.fromAddress(value).then(
      async (response) => {
        const address = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;
        setShippingLong(lng);
        setShippingLat(lat);
        var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "",
          borough = "",
          neighbourhood = "";
        let Country = "";
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                houseNO = response.results[0].address_components[i].long_name;
                break;
              case "route":
                street =
                  houseNO +
                  " " +
                  response.results[0].address_components[i].long_name;
                break;
              case "neighborhood":
                neighbourhood =
                  response.results[0].address_components[i].long_name;
                break;
              case "sublocality_level_1":
                borough = response.results[0].address_components[i].long_name;
                if (
                  borough == "Manhattan" ||
                  borough == "Bronx" ||
                  borough == "The Bronx" ||
                  borough == "Brooklyn" ||
                  borough == "Queens" ||
                  borough == "Staten Island"
                ) {
                  if (borough == "Manhattan") {
                    city = "New York";
                  } else if (borough == "Bronx" || borough == "The Bronx") {
                    city = borough;
                  } else if (borough == "Brooklyn") {
                    city = borough;
                  } else if (borough == "Queens") {
                    city = neighbourhood;
                  } else if (borough == "Staten Island") {
                    city = borough;
                  } else {
                    localStorage.setItem("borough", "0");
                  }
                } else {
                  city = response.results[0].address_components[i].long_name;
                }
                break;
              case "locality":
                if (
                  borough != "Manhattan" &&
                  borough != "Bronx" &&
                  borough != "Brooklyn" &&
                  borough != "Queens" &&
                  borough != "Staten Island"
                ) {
                  city = response.results[0].address_components[i].long_name;
                }

                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                zipcode = response.results[0].address_components[i].long_name;
                break;
              case "country":
                Country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        let obj = { ...props.formInputsValue };
        obj.shippingStreet = street;
        obj.shippingCity = city;
        obj.shippingState = state;
        obj.shippingCode = zipcode;
        // obj.shippingCountry = Country;
        obj.shippingAddress = address;
        props.setFormInputsValue(obj);
      },
      (error) => {
        if (props.formInputsValue.shippingAddress == "") {
          let obj = { ...props.formInputsValue };
          obj.shippingStreet = "";
          obj.shippingCity = "";
          obj.shippingState = "";
          obj.shippingCode = "";
          // obj.shippingCountry = "";
          obj.shippingAddress = "";
          props.setFormInputsValue(obj);
        }
      }
    );
  }
  };

  console.log("props.formInputsValue", props.formInputsValue);
 
  let optionsForParentClient = allAccounts
    ?.filter((one) => one.Account_Name != props.formInputsValue.clientName)
    ?.map((item, i) => {
      return { value: item.Account_Name, label: item.Account_Name };
    });
  optionsForParentClient.unshift({ value: "None", label: "None" });
  const [editFlag, setEditFlag] = useState(false);
  const getServiceSeletedTable = () => {
    let items = [
      {
        label: (
          <GlobalTable
            onEdit={(info, index) => {
              setServiceFields(info);
              setEditFlag(true);
            }}
            onDelete={(info, index) => {
              let newData = serviceSelected.filter((s) => s.id != info.id);
              setServiceSelected(newData);
            }}
            data={serviceSelected}
            headers={[
              { label: "Service Name", field: "Name" },
              { label: "Service Cost", field: "Service_Cost" },
              { label: "Service Location", field: "Service_Location" },
            ]}
          />
        ),
        key: 0,
      },
    ];

    return (
      <AntDropDown
        overlayClassName="Client-Service-Popover"
        menu={{ items }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["hover"]}
      >
        <span>{"Service List (" + serviceSelected?.length + ")"}</span>
      </AntDropDown>
    );
  };

  const setChangePhone = (value) => {
    props.setFormInputsValue({
      ...props.formInputsValue,
      phone: value,
    });
  };

  const toggle = () => {
    resetBillingModalFields();
    setValidation2({
      modelItem:'0',
      approvalCost:'0',
      billingStageTask:'0',
      billingStageStatus:'0'
    })
    setEditState("");
    setNestedModal1(!nestedModal1);
  };
  const toggleServiceModal = () => {
    resetAdderModalFields();
    setValidation3({
      modelItems:'0',
      approvalCosts:'0',
      adderBillingStageTask:'0',
      adderBillingStageStatus:'0'
    });
    setEditServiceState("");
    setShowModal(!modal);
  };

  const highliteEmptys=(data)=>{
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
    if(data.name=="valid2"){
      setValidation2(ob);
    }else{
      setValidation3(ob);
    }
  }

  const saveBillingTermsInfo = () => {
    let vKeys=Object.keys(validation2);
    for(let i=0;i<vKeys.length;i++){
      if(validation2[vKeys[i]]==='-1' || validation2[vKeys[i]]==='0'){
        alert("Please fill all required fields!")
        highliteEmptys({arr:validation2,name:"valid2"})
        return
      }
    }
    let billingInfo = props?.formInputsValue?.billingTermsInfo;
    if (editState === "") {
    let unique = uuid();
    billingInfo.push({
      sr_id: unique,
      tierNo: tierNo,
      item: itemName.value,
      cost: cost,
      billingStageTask: billingStageTask,
      billingStageStatus: billingStageStatus,
    });
    }
     else {
      props?.formInputsValue?.billingTermsInfo?.forEach((info, i) => {
        if (pvIndex !== -1 && pvIndex == i) {
          billingInfo[i] = {
            item: itemName?.value,
            cost: cost,
            tierNo: tierNo,
            billingStageTask: billingStageTask,
            billingStageStatus: billingStageStatus,
          };
        }
      });
    }
    // resetPvSystemInfoModal({arr:validations,obType:'validations'});
    // calculateTotalValues(pvSystem)
    props.setFormInputsValue({
      ...props.formInputsValue,
      billingTermsInfo: billingInfo,
    });
    // props.setPVSystemInfo(pvSystem);
    setEditState("");
    toggle();
  };

  const saveServiceAddersInfo = () => { 
    let vKeys=Object.keys(validation3);
    for(let i=0;i<vKeys.length;i++){
      if(validation3[vKeys[i]]==='-1' || validation3[vKeys[i]]==='0'){
        alert("Please fill all required fields!")
        highliteEmptys({arr:validation3,name:"validation3"})
        return
      }
    }
    let adderInfo = props?.formInputsValue?.serviceAddersInfo;
    if (editServiceState === "") {
    let unique = uuid();
    adderInfo.push({
      sr_id: unique,
      item: serviceName?.value,
      cost: serviceCost,
      billingStageTask: adderBillingStageTask,
      billingStageStatus: adderBillingStageStatus,
    });
    }
     else {
      props?.formInputsValue?.serviceAddersInfo?.forEach((info, i) => {
        if (pvIndex !== -1 && pvIndex == i) {
          adderInfo[i] = {
            item: serviceName?.value,
            cost: serviceCost,
            billingStageTask: adderBillingStageTask,
            billingStageStatus: adderBillingStageStatus,
          };
        }
      });
    }
    // resetPvSystemInfoModal({arr:validations,obType:'validations'});
    // calculateTotalValues(pvSystem)
    props.setFormInputsValue({
      ...props.formInputsValue,
      serviceAddersInfo: adderInfo,
    });
    // props.setPVSystemInfo(pvSystem);
    setEditServiceState("");
    toggleServiceModal();
  };

  const changeStatus = (e) => {
    props.setFormInputsValue({
      ...props.formInputsValue,
      [e.target.name]: !props.formInputsValue.clientStatus,
    });
  };

  const onChangePhoneNumber = (value) => {
    var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
    let onlyNo = value1.replace(/\D/g, "");
    if (onlyNo.length < 10) {
      setChangePhone(value);
    }
    if (onlyNo.length == 12) {
      setChangePhone("");
    }
    if (onlyNo.length == 10) {
      setChangePhone(
        "(" +
          value1.substring(0, 3) +
          ") " +
          value1.substring(3, 6) +
          "-" +
          value1.substring(6, 10)
      );
      setValidation({ ...validation, phone: "1" });
    }
    if (onlyNo.length == 0) {
      setValidation({ ...validation, phone: "1" });
    }
  };

  const onBlurPhoneNumber = (value) => {
    var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
    let onlyNo = value1.replace(/\D/g, "");
    if (onlyNo.length < 10 && onlyNo.length != 0) {
      setValidation({ ...validation, phone: "-1" });
    } else {
      setValidation({ ...validation, phone: "1" });
    }
  };

  const handleSelectItemName = (values) => {
    if (values?.value === props?.formInputsValue?.parentClient?.value) {
      setValidation2({...validation2,modelItem:'-1'})
    } else {
      setItemName(values);
      setValidation2({...validation2,modelItem:'1'})
    }
  };

  const handleSelectServiceName = (values) => {
      setServiceName(values);
  };

  const handleSelectAccounts = (values) => {
    if (values?.value === props?.formInputsValue?.parentClient?.value) {
      props.handleChange({ target: { name: "parentClient", value: "" } });
    } else {
      props.handleChange({ target: { name: "parentClient", value: values } });
    }
  };
  const handleSelectPriority = (values) => {
    if (values?.value === props?.formInputsValue?.priority?.value) {
      setValidation({ ...validation, priority: "-1" });
      props.handleChange({ target: { name: "priority", value: "" } });
    } else {
      setValidation({ ...validation, priority: "1" });
      props.handleChange({ target: { name: "priority", value: values } });
    }
  };
  return (
    <div className="color-white">
      <>
        <div className="boxplate">
          <div className="boxplate-heading" style={{ display: "flex" }}>
          <div className="sectitle" style={{width: "max-content"}}>{props.layoutstate == "ClientInfo" ? "Client Information" : 
            (props.layoutstate=="BillingDetails"||props.layoutstate=="ShippingDetails")?"Address Information":
            props.layoutstate=="FullServices"?"Full Services":
            (props.layoutstate=="BillingTerms"||props.layoutstate=="ServiceAdders")?"Billing Terms":
            props.layoutstate}
          </div>
          </div>
          <div className="pad-15 createdeal-custom-scroll">
            <Row className="mar-15 create-user-box">
              <div
                className="mainWrap"
                style={{ height: "auto", paddingBottom: "0.5rem" }}
              >
                {props.layoutstate == "ClientInfo" ? (
                  <>
                    <Col sm={2}></Col>
                    <Col
                      sm={10}
                      className="createuser-border"
                      style={{ overflowY: "auto" }}
                    >
                      <div className="user-form-border">
                        <>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label className="text-bold" for="clientName">
                                  Client Name<span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <Input
                                  type="text"
                                  name="clientName"
                                  className="create-user-input"
                                  style={
                                    validation.clientName == "-1"
                                      ? { ...validationCss }
                                      : {}
                                  }
                                  value={
                                    props.formInputsValue.Account_Name
                                      ? props.formInputsValue.Account_Name
                                      : props.formInputsValue.clientName
                                  }
                                  onBlur={(e) => {
                                    if (
                                      e.target.value.trimStart().length == 0
                                    ) {
                                      setValidation({
                                        ...validation,
                                        clientName: "-1",
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    e.target.value = e.target.value
                                      .trimStart()
                                      .replace(
                                        /[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                        ""
                                      );
                                    e.target.value =
                                      e.target.value
                                        .replace(/[^\D]/g, "")
                                        .charAt(0)
                                        .toUpperCase() +
                                      e.target.value
                                        .replace(/[^\D]/g, "")
                                        .slice(1);
                                    if (e.target.value.length != 0) {
                                      setValidation({
                                        ...validation,
                                        clientName: "1",
                                      });
                                    }
                                    props.handleChange(e, "ClientName");
                                  }}
                                />
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label
                                  className="text-bold"
                                  for="clientNickname"
                                >
                                  Client Nickname
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <Input
                                  type="text"
                                  name="clientNickname"
                                  className="create-user-input"
                                  value={
                                    props.formInputsValue.clientNickname
                                      ? props.formInputsValue.clientNickname
                                      : props.formInputsValue.clientNickname
                                  }
                                  onChange={(e) => {
                                    e.target.value = e.target.value
                                      .trimStart()
                                      .replace(
                                        /[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                        ""
                                      );
                                    e.target.value =
                                      e.target.value
                                        .replace(/[^\D]/g, "")
                                        .charAt(0)
                                        .toUpperCase() +
                                      e.target.value
                                        .replace(/[^\D]/g, "")
                                        .slice(1);
                                    props.handleChange(e, "NickName");
                                  }}
                                />
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label className="text-bold" for="parentClient">
                                  Parent Client
                                  <span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <Select
                                  style={{ width: "100%", padding: "1.3px" }}
                                  className={
                                    validation.parentClient == "-1"
                                      ? "addclss"
                                      : ""
                                  }
                                  options={optionsForParentClient}
                                  closeMenuOnSelect={true}
                                  onChange={(values) => {
                                    if (values != "" || values != undefined) {
                                      setValidation({
                                        ...validation,
                                        parentClient: "1",
                                      });
                                    }
                                    handleSelectAccounts(values);
                                  }}
                                  value={
                                    props.formInputsValue.parentClient
                                      ? props.formInputsValue.parentClient
                                      : props.formInputsValue.parentClient
                                  }
                                  menuPlacement="bottom"
                                />
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label className="text-bold" for="clientStatus">
                                  Client Status
                                  <span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <Label className="padr-15 activelabel" style={{marginTop : "0.5rem"}}>
                                  Active
                                </Label>
                                <Input
                                  type="checkbox"
                                  name="clientStatus"
                                  style={{marginTop : "0.7rem"}}
                                  defaultChecked={
                                    props.clientModalType
                                      ? props.formInputsValue.clientStatus
                                      : props.formInputsValue.clientStatus
                                  }
                                  checked={
                                    props.formInputsValue.clientStatus
                                      ? props.formInputsValue.clientStatus
                                      : props.formInputsValue.clientStatus
                                  }
                                  onClick={(e) => {
                                    changeStatus(e);
                                  }}
                                  className="create-user-checkbox"
                                />
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label className="text-bold" for="priority">
                                  Priority<span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <Select
                                  style={{ width: "100%", padding: "1.3px" }}
                                  className={
                                    validation.priority == "-1" ? "addclss" : ""
                                  }
                                  options={optionsForPriority}
                                  closeMenuOnSelect={true}
                                  onChange={(values) =>
                                    handleSelectPriority(values)
                                  }
                                  value={
                                    props.formInputsValue.priority
                                      ? props.formInputsValue.priority
                                      : props.formInputsValue.priority
                                  }
                                  menuPlacement="bottom"
                                />
                              </Col>
                               <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label className="text-bold" for="projectType">
                                  Project Type
                                  <span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={5}>
                              <Select
                                name="projectType"
                                style={{ width: "100%", padding: "1.3px" }}
                                className={validation.projectType=='-1'
                                ? 'addclss':''}
                                options={optionsForProjectType}
                                closeMenuOnSelect={true}
                                onChange={(values) =>
                                  handleSelectProjectType(values)
                                }
                                value={ props.formInputsValue.projectType }
                                menuPlacement="bottom"
                              />
                                {/* <Input
                                  type="text"
                                  name="projectType"
                                  style={
                                    validation.projectType == "-1"
                                      ? { ...validationCss }
                                      : {}
                                  }
                                  className="create-user-input"
                                  value={
                                    props.formInputsValue.projectType
                                      ? props.formInputsValue.projectType
                                      : props.formInputsValue.projectType
                                  }
                                  onBlur={(e) => {
                                    if (
                                      e.target.value.trimStart().length == 0
                                    ) {
                                      setValidation({
                                        ...validation,
                                        projectType: "-1",
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    e.target.value = e.target.value
                                      .trimStart()
                                      .replace(
                                        /[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                        ""
                                      );
                                    if (e.target.value.length != 0) {
                                      setValidation({
                                        ...validation,
                                        projectType: "1",
                                      });
                                    }
                                    props.handleChange(e, "ProjectType");
                                  }}
                                /> */}
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label
                                  className="text-bold"
                                  for="projectVolume"
                                >
                                  Project Volume (per month)
                                  <span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <Input
                                  type="number"
                                  name="projectVolume"
                                  style={
                                    validation.projectVolume == "-1"
                                      ? { ...validationCss }
                                      : {}
                                  }
                                  className="create-user-input"
                                  value={
                                    props.formInputsValue.projectVolume
                                      ? props.formInputsValue.projectVolume
                                      : props.formInputsValue.projectVolume
                                  }
                                  onBlur={(e) => {
                                    if (
                                      e.target.value == ""
                                    ) {
                                      setValidation({
                                        ...validation,
                                        projectVolume: "-1",
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.trimStart();
                                    if (e.target.value < 0) {
                                      return 
                                    }
                                      setValidation({
                                        ...validation,
                                        projectVolume: "1",
                                      });
                                    props.handleChange(e, "ProjectVolume");
                                  }}
                                />
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label className="text-bold" for="phone">
                                  Phone<span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <ReactInputMask
                                  type="text"
                                  mask="(999) 999-9999"
                                  style={
                                    validation.phone == "-1"
                                      ? { ...validationCss }
                                      : {}
                                  }
                                  maskChar="_"
                                  className="form-control create-user-input"
                                  name="phone"
                                  id="phoneno"
                                  placeholder="(###) ###-####"
                                  minLength={3}
                                  value={
                                    props.formInputsValue.Phone
                                      ? props.formInputsValue.Phone
                                      : props.formInputsValue.phone
                                  }
                                  onKeyDown={(evt) =>
                                    ((evt.keyCode > 64 && evt.keyCode < 91) ||
                                      evt.key === ".") &&
                                    evt.preventDefault()
                                  }
                                  onChange={(e) => {
                                    e.target.value = e.target.value.trimStart();
                                    onChangePhoneNumber(e.target.value);
                                  }}
                                  onBlur={(e) => {
                                    onBlurPhoneNumber(e.target.value);
                                  }}
                                />
                              </Col>
                              {validation.phone == "-1" ? (
                                <Row>
                                  <Col sm={3}></Col>
                                  <Col sm={5} className="ml-3">
                                    <span
                                      className={
                                        validation.phone == "-1"
                                          ? "red-text font12"
                                          : "error-display"
                                      }
                                    >
                                      {" "}
                                      Enter 10 digit Phone Number.
                                    </span>
                                  </Col>
                                </Row>
                              ) : (
                                <></>
                              )}

                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col sm={3}>
                                <Label className="text-bold" for="website">
                                  Website
                                </Label>
                              </Col>
                              <Col sm={5}>
                                <Input
                                  type="text"
                                  name="website"
                                   className="create-user-input"
                                  value={
                                    props.formInputsValue.Website
                                      ? props.formInputsValue.Website
                                      : props.formInputsValue.website
                                  }
                                  onChange={(e) => {
                                    e.target.value = e.target.value
                                      .trimStart()
                                      .replace(
                                        /[!#$\s%^*`\[\]\+\=\"\/();,<>?|}{~:]/i,
                                        ""
                                      );
                                    props.handleChange(e, "Website");
                                  }}
                                />
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row style={{ position: "relative" }}>
                              <Col sm={3}>
                                <Label
                                  className="text-bold"
                                  for="shippingCountry"
                                >
                                  Color<span className="red-text">*</span>
                                </Label>
                              </Col>
                              <Col sm={1}>
                                <Input
                                  readOnly
                                  style={
                                    props.color
                                      ? {
                                          backgroundColor:
                                            props.formInputsValue.Color,
                                          cursor: "pointer",
                                        }
                                      : {}
                                  }
                                  type="text"
                                  name="color"
                                  className="create-user-input"
                                  onClick={() => {
                                    setOpen(!open);
                                  }}
                                />
                                {open && (
                                  <OtsideClickClosingWrapper
                                    close={(e) => {
                                      setOpen(false);
                                    }}
                                  >
                                    <HexColorPicker
                                      className="hexpicker"
                                      color={
                                        props.formInputsValue.Color
                                          ? props.formInputsValue.Color
                                          : props.Color
                                      }
                                      onChange={(value) => {
                                        props.handleChange({
                                          target: {
                                            name: "Color",
                                            value: value,
                                          },
                                        });
                                      }}
                                    />
                                  </OtsideClickClosingWrapper>
                                )}
                              </Col>
                              <Col sm={4}>
                                <Input
                                  type="text"
                                  name="color"
                                  className="create-user-input"
                                  value={
                                    props.formInputsValue.Color
                                      ? props.formInputsValue.Color
                                      : props.Color
                                  }
                                  onChange={(e) => {
                                    props.handleChange({
                                      target: {
                                        name: "Color",
                                        value: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </Col>
                              <Col></Col>
                            </Row>
                          </FormGroup>
                          {/* <FormGroup>
                          <Row style={{ position: "relative" }}>
                          <Col sm={2}>
                              <Label
                                className="text-bold"
                                for="shippingCountry"
                              >
                                Logo
                              </Label>
                            </Col>
                          <label htmlFor="upload-photo" className="logoLabel">
                            {selectedFile ? (
                              <img src={URL.createObjectURL(selectedFile)} alt="Uploaded Logo" 
                              style={{ maxWidth: "100%", maxHeight: "100%", display: "block"}} />
                            ) : (
                              <span>Upload Logo</span>
                            )}
                          </label>
                          <input type="file" id="upload-photo" accept="image/png, image/jpeg" style={{display:"none"}} onChange={handleFileChange} />
                          {selectedFile!=null && (
                        <Button color="danger" size="sm" onClick={handleRemoveLogo} className="removeLogo ml-1" style={{position: "static",height: "min-content"}}>
                         Remove Logo
                        </Button>
                        )}
                          </Row>
                          </FormGroup> */}
                        </>
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {(props.layoutstate == "BillingDetails"||props.layoutstate == "ShippingDetails") ? (
                  <>
                    <Col sm={12}>
                      <Button
                        onClick={()=>{
                          if(shippingDetailstogglestate){
                            setShippingDetailstogglestate(!shippingDetailstogglestate)
                          }
                          setBillingDetailstogglestate(!billingDetailstogglestate)
                          props.setLayoutState("BillingDetails")
                          localStorage.setItem('currentsidenavbar',"BillingDetails");
                        }}
                          className={props.layoutstate=="BillingDetails" ? "btn-100-active" : "btn-100"}
                      >
                        Billing Details
                        <div className="float-right">
                          {billingDetailstogglestate ? (
                            <FontAwesomeIcon
                              className="font-centered"
                              icon={faAngleDown}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="font-centered"
                              icon={faAngleRight}
                            />
                          )}
                        </div>
                      </Button>
                      <Collapse isOpen={billingDetailstogglestate}>
                        <Card className="createdeal-card">
                          <CardBody>
                            <div>
                              <Row>
                                <Col sm={5}>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="billingaddress"
                                        >
                                          Billing Address
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <input
                                          type="text"
                                          name="billingaddress"
                                          ref={placeInputRef}
                                          style={
                                            validation.billingaddress == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input form-control"
                                          value={
                                            props.formInputsValue
                                              .Billing_Address
                                              ? props.formInputsValue
                                                  .Billing_Address
                                              : props.formInputsValue
                                                  .billingaddress
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                billingaddress: "-1",
                                              });
                                            }
                                            onBlurProjectAdd(e.target.value);
                                          }}
                                          onChange={(e) => {
                                            e.target.value =
                                              e.target.value.trimStart();
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                billingaddress: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "billingaddress"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="billingStreet"
                                        >
                                          Billing Street Address
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="billingStreet"
                                          style={
                                            validation.billingStreet == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue.Billing_Street
                                              ? props.formInputsValue
                                                  .Billing_Street
                                              : props.formInputsValue
                                                  .billingStreet
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                billingStreet: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(
                                                /[_!#@$%^&*()<>?|}{~:]/,
                                                ""
                                              );
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                billingStreet: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "BillingStreet"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="billingHouseNo"
                                        >
                                          Apt, Suite, Bldg, Unit, etc.
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="billingHouseNo"
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue
                                              .Billing_House_Number
                                              ? props.formInputsValue
                                                  .Billing_House_Number
                                              : props.formInputsValue
                                                  .billingHouseNo
                                          }
                                          onChange={(e) => {
                                            // e.target.value = e.target.value
                                            //   .trimStart()
                                            //   .replace(
                                            //     /[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                            //     ""
                                            //   );
                                            // e.target.value = e.target.value
                                            //   .trimStart()
                                            //   .replace(/[0-9]/, "");
                                            // e.target.value = e.target.value.replace(
                                            //   /(^|\s)\S/g,
                                            //   (match) => match.toUpperCase()
                                            // );
                                            // if (e.target.value.length != 0) {
                                            //   setValidation({
                                            //     ...validation,
                                            //     billingCity: "1",
                                            //   });
                                            // }
                                            props.handleChange(
                                              e,
                                              "BillingHouseNo"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="billingCity"
                                        >
                                          Billing City
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="billingCity"
                                          style={
                                            validation.billingCity == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue.Billing_City
                                              ? props.formInputsValue
                                                  .Billing_City
                                              : props.formInputsValue
                                                  .billingCity
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                billingCity: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(
                                                /[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                                ""
                                              );
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(/[0-9]/, "");
                                            e.target.value =
                                              e.target.value.replace(
                                                /(^|\s)\S/g,
                                                (match) => match.toUpperCase()
                                              );
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                billingCity: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "BillingCity"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="billingState"
                                        >
                                          Billing State
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="billingState"
                                          style={
                                            validation.billingState == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue.Billing_State
                                              ? props.formInputsValue
                                                  .Billing_State
                                              : props.formInputsValue
                                                  .billingState
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                billingState: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(
                                                /[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                                ""
                                              );
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(/[0-9]/, "");
                                            e.target.value =
                                              e.target.value.replace(
                                                /(^|\s)\S/g,
                                                (match) => match.toUpperCase()
                                              );
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                billingState: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "BillingState"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="billingCode"
                                        >
                                          Billing Code
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="billingCode"
                                          style={
                                            validation.billingCode == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue.Billing_Code
                                              ? props.formInputsValue
                                                  .Billing_Code
                                              : props.formInputsValue
                                                  .billingCode
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length < 5
                                            ) {
                                              setValidation({
                                                ...validation,
                                                billingCode: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value =
                                              e.target.value.trimStart();
                                            e.target.value =
                                              e.target.value.replace(/\D/g, "");
                                            if (e.target.value.length == 5) {
                                              setValidation({
                                                ...validation,
                                                billingCode: "1",
                                              });
                                            }
                                            if (e.target.value.length <= 5) {
                                              props.handleChange(
                                                e,
                                                "BillingCode"
                                              );
                                            }
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup row className="marb-16  padtop-15">
                                    <Row>
                                      <Col sm={8}>
                                        <Label
                                          className="text-bold"
                                          for="ownername"
                                        >
                                          Shipping Address different than
                                          Billing address
                                        </Label>
                                      </Col>
                                      <Col sm={1} className="radioStyle">
                                        <Input
                                          name="ShippnigAddDifferent"
                                          type="checkbox"
                                          value={
                                            props.formInputsValue
                                              .Shipping_Address_Different_Than_Billing_Adderss
                                              ? props.formInputsValue
                                                  .Shipping_Address_Different_Than_Billing_Adderss
                                              : props.formInputsValue
                                                  .ShippnigAddDifferent
                                          }
                                          onChange={(e)=>{
                                            // props.setMailAddDifferent(e.target.checked)
                                            // props.setMailAddressDisable(e.target.checked)
                                            if(e.target.checked){
                                              if(billingDetailsFilled()){
                                                swal("Alert!",   "Please fill all required fields first.", "warning");
                                              }else{
                                                props.handleChange(e, "ShippnigAddDifferent");
                                              }
                                            }else{
                                              // props.handleChange(e, "ShippnigAddDifferent");
                                              onBlurShippingAddress("Clear")
                                            }
                                        }}
                                          checked={
                                            props.formInputsValue
                                              .ShippnigAddDifferent
                                              ? true
                                              : false
                                          }
                                        />
                                      </Col>
                                      <Col sm={6}></Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                                <Col sm={7}>
                                  {billingLong && billingLat ? (
                                    <GoogleMapReact
                                      defaultCenter={{
                                        lng: parseFloat(billingLong),
                                        lat: parseFloat(billingLat),
                                      }}
                                      center={{
                                        lng: parseFloat(billingLong),
                                        lat: parseFloat(billingLat),
                                      }}
                                      defaultZoom={zoom}
                                    >
                                      <Marker
                                        lat={parseFloat(billingLat)}
                                        lng={parseFloat(billingLong)}
                                        name="My Marker"
                                        color="blue"
                                      />
                                    </GoogleMapReact>
                                  ) : (
                                    <p>Map Not Available</p>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>

                      <Button
                        onClick={()=>{
                          if(billingDetailstogglestate){
                            setBillingDetailstogglestate(!billingDetailstogglestate)
                          }
                          setShippingDetailstogglestate(!shippingDetailstogglestate)
                          props.setLayoutState("ShippingDetails")
                          localStorage.setItem('currentsidenavbar',"ShippingDetails");
                        }}
                        className={props.layoutstate=="ShippingDetails" ? "btn-100-active" : "btn-100"}
                        disabled={!props.formInputsValue.ShippnigAddDifferent}
                      >
                        Shipping Details
                        <div className="float-right">
                          {shippingDetailstogglestate ? (
                            <FontAwesomeIcon
                              className="font-centered"
                              icon={faAngleDown}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="font-centered"
                              icon={faAngleRight}
                            />
                          )}
                        </div>
                      </Button>
                      <Collapse isOpen={shippingDetailstogglestate}>
                        <Card className="createdeal-card">
                          <CardBody>
                            <div>
                              <Row>
                                <Col sm={5}>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="shippingAddress"
                                        >
                                          Shipping Address
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <input
                                          type="text"
                                          name="shippingAddress"
                                          style={
                                            validation.shippingAddress == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          ref={placeInputRef1}
                                          className="create-user-input form-control"
                                          value={
                                            props.formInputsValue
                                              .Shipping_Address
                                              ? props.formInputsValue
                                                  .Shipping_Address
                                              : props.formInputsValue
                                                  .shippingAddress
                                          }
                                          onBlur={(e) => {
                                            onBlurShippingAddress(
                                              e.target.value
                                            );
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                shippingAddress: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value =
                                              e.target.value.trimStart();
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                shippingAddress: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "shippingAddress"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="shippingStreet"
                                        >
                                          Shipping Street Address
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="shippingStreet"
                                          style={
                                            validation.shippingStreet == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue
                                              .Shipping_Street
                                              ? props.formInputsValue
                                                  .Shipping_Street
                                              : props.formInputsValue
                                                  .shippingStreet
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                shippingStreet: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(
                                                /[_!#@$%^&*()<>?|}{~:]/,
                                                ""
                                              );
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                shippingStreet: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "ShippingStreet"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="shippingHouseNo"
                                        >
                                          Apt, Suite, Bldg, Unit, etc.
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="shippingHouseNo"
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue
                                              .Shipping_House_Number
                                              ? props.formInputsValue
                                                  .Shipping_House_Number
                                              : props.formInputsValue
                                                  .shippingHouseNo
                                          }
                                          onChange={(e) => {
                                            // e.target.value = e.target.value
                                            //   .trimStart()
                                            //   .replace(
                                            //     /[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                            //     ""
                                            //   );
                                            // e.target.value = e.target.value
                                            //   .trimStart()
                                            //   .replace(/[0-9]/, "");
                                            // e.target.value = e.target.value.replace(
                                            //   /(^|\s)\S/g,
                                            //   (match) => match.toUpperCase()
                                            // );
                                            // if (e.target.value.length != 0) {
                                            //   setValidation({
                                            //     ...validation,
                                            //     billingCity: "1",
                                            //   });
                                            // }
                                            props.handleChange(
                                              e,
                                              "ShippingHouseNo"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="shippingCity"
                                        >
                                          Shipping City
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="shippingCity"
                                          style={
                                            validation.shippingCity == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue.Shipping_City
                                              ? props.formInputsValue
                                                  .Shipping_City
                                              : props.formInputsValue
                                                  .shippingCity
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                shippingCity: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(
                                                /[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                                ""
                                              );
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(/[0-9]/, "");
                                            e.target.value =
                                              e.target.value.replace(
                                                /(^|\s)\S/g,
                                                (match) => match.toUpperCase()
                                              );
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                shippingCity: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "ShippingCity"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="shippingState"
                                        >
                                          Shipping State
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="shippingState"
                                          style={
                                            validation.shippingState == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue.Shipping_State
                                              ? props.formInputsValue
                                                  .Shipping_State
                                              : props.formInputsValue
                                                  .shippingState
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length == 0
                                            ) {
                                              setValidation({
                                                ...validation,
                                                shippingState: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(
                                                /[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,
                                                ""
                                              );
                                            e.target.value = e.target.value
                                              .trimStart()
                                              .replace(/[0-9]/, "");
                                            e.target.value =
                                              e.target.value.replace(
                                                /(^|\s)\S/g,
                                                (match) => match.toUpperCase()
                                              );
                                            if (e.target.value.length != 0) {
                                              setValidation({
                                                ...validation,
                                                shippingState: "1",
                                              });
                                            }
                                            props.handleChange(
                                              e,
                                              "ShippingState"
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                  <FormGroup className="marb-16 padtop-15">
                                    <Row>
                                      <Col sm={5}>
                                        <Label
                                          className="text-bold"
                                          for="shippingCode"
                                        >
                                          Shipping Code
                                          <span className="red-text">*</span>
                                        </Label>
                                      </Col>
                                      <Col sm={7}>
                                        <Input
                                          type="text"
                                          name="shippingCode"
                                          style={
                                            validation.shippingCode == "-1"
                                              ? { ...validationCss }
                                              : {}
                                          }
                                          className="create-user-input"
                                          value={
                                            props.formInputsValue.Shipping_Code
                                              ? props.formInputsValue
                                                  .Shipping_Code
                                              : props.formInputsValue
                                                  .shippingCode
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value.trimStart()
                                                .length < 5
                                            ) {
                                              setValidation({
                                                ...validation,
                                                shippingCode: "-1",
                                              });
                                            }
                                          }}
                                          onChange={(e) => {
                                            e.target.value =
                                              e.target.value.trimStart();
                                            e.target.value =
                                              e.target.value.replace(/\D/g, "");
                                            if (e.target.value.length == 5) {
                                              setValidation({
                                                ...validation,
                                                shippingCode: "1",
                                              });
                                            }
                                            if (e.target.value.length <= 5) {
                                              props.handleChange(
                                                e,
                                                "ShippingCode"
                                              );
                                            }
                                          }}
                                        />
                                      </Col>
                                      <Col></Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                                <Col sm={7}>
                                  {shippingLong && shippingLat ? (
                                    <GoogleMapReact
                                      defaultCenter={{
                                        lng: parseFloat(shippingLong),
                                        lat: parseFloat(shippingLat),
                                      }}
                                      center={{
                                        lng: parseFloat(shippingLong),
                                        lat: parseFloat(shippingLat),
                                      }}
                                      defaultZoom={zoom}
                                    >
                                      <Marker
                                        lat={parseFloat(shippingLat)}
                                        lng={parseFloat(shippingLong)}
                                        name="My Marker"
                                        color="blue"
                                      />
                                    </GoogleMapReact>
                                  ) : (
                                    <p>Map Not Available</p>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {props.layoutstate == "FullServices" ? (
                  <Col sm={12} style={{ padding: "2rem" }}>
                    <FormGroup className="marb-16">
                      <Row>
                        <Col sm={12}>
                          <FormGroup row className="marb-16">
                            <Row>
                            <Col sm={1} style={{ margin: "0px 1.8rem" }}>
                                <Button
                                  className="continue-button"
                                  onClick={handleFullClick}
                                  style={{ width: "7rem" }}
                                >
                                  Select All
                                </Button>
                              </Col>
                              <Col sm={2}>
                                <Button
                                  className="continue-button"
                                  onClick={handleResetClick}
                                  style={{ width: "7rem" }}
                                >
                                  Deselect All
                                </Button>
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup row className="marb-16 padtop-15">
                            {allServicess.map((one, index) => {
                              return (
                                <Col
                                  sm={6}
                                  key={index}
                                  style={{ marginBottom: "0.7rem" }}
                                >
                                  <Row>
                                    <Col sm={4}>
                                      <Label
                                        className="text-bold"
                                        for="preliminaryresearch"
                                      >
                                        {one.Name}
                                        <span className="red-text">*</span>
                                      </Label>
                                    </Col>
                                    <Col sm={2} className="radioStyle">
                                      <Label className="text-bold2">Yes</Label>
                                      <Input
                                        type="radio"
                                        name={one.Name}
                                        id={one.id}
                                        value={one.Name}
                                        checked={
                                          props.clientFullServices &&
                                          props.clientFullServices.filter(
                                            (ser) => ser.id == one.id
                                          ).length > 0
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          let arr = [
                                            ...props.clientFullServices,
                                          ];
                                          if (arr) {
                                            let service = [...arr];
                                            service.push({
                                              id: one.id,
                                              name: one.Name,
                                              amount: one.Service_Cost,
                                            });
                                            arr = service;
                                          } else {
                                            let service = [];
                                            service.push({
                                              id: one.id,
                                              name: one.Name,
                                            });
                                            arr = service;
                                          }
                                          props.setClientFullServices(arr);
                                        }}
                                      />
                                    </Col>

                                    <Col sm={2} className="radioStyle">
                                      <Label className="text-bold2">No</Label>
                                      <Input
                                        type="radio"
                                        name={one.key}
                                        id={one.key}
                                        value="No"
                                        checked={
                                          props.clientFullServices &&
                                          !(
                                            props.clientFullServices.filter(
                                              (ser) => ser.id == one.id
                                            ).length > 0
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          let arr = [
                                            ...props.clientFullServices,
                                          ];
                                          if (arr) {
                                            let service = [...arr];
                                            service = service.filter(
                                              (ser) => ser.id !== one.id
                                            );
                                            arr = service;
                                          }
                                          props.setClientFullServices(arr);
                                        }}
                                      />
                                    </Col>
                                    <Col sm={4}></Col>
                                  </Row>
                                </Col>
                              );
                            })}
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                ) : (
                  <></>
                )}

                {(props.layoutstate == "BillingTerms"||props.layoutstate=="ServiceAdders") ? (
                  <Col sm={12}>
                    <Button
                      onClick={()=>{
                        if(serviceAdderstogglestate){
                          setServiceAdderstogglestate(!serviceAdderstogglestate)
                        }
                        setBillingTermstogglestate(!billingTermstogglestate)
                        props.setLayoutState("BillingTerms")
                        localStorage.setItem('currentsidenavbar',"BillingTerms");
                      }}
                      className={props.layoutstate == "BillingTerms" ? "btn-100-active" : "btn-100"}
                    >
                      Main Quote
                      <div className="float-right">
                        {billingTermstogglestate ? (
                          <FontAwesomeIcon
                            className="font-centered"
                            icon={faAngleDown}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="font-centered"
                            icon={faAngleRight}
                          />
                        )}
                      </div>
                    </Button>
                    <Collapse isOpen={billingTermstogglestate}>
                      <Card className="createdeal-card">
                        <CardBody>
                          <div>
                            <FormGroup>
                             <FormGroup row className="marb-16  padtop-15">
                              <Row className="custom-margin">
                                <Col sm={2}>
                                  <Label
                                    className="text-bold"
                                    for="contractPrice"
                                  >
                                    Contract Price
                                    <span className="red-text">*</span>
                                  </Label>
                                </Col>
                                <Col sm={2}>
                                  <div>
                                    <span className="dollar-sign">$</span>
                                  <Input
                                      className="createdeal-textfield currency-input"
                                      type="number"
                                      name="contractPrice"
                                      id="contractPrice"
                                      placeholder="0.00"
                                      style={
                                        validation2.contractPrice == "-1"
                                          ? { ...validationCss}
                                          : {}
                                      }
                                      value={props.formInputsValue.contractPrice}
                                      onChange={(e) => {
                                        e.target.value = e.target.value < 0 || e.target.value == undefined ? "" : e.target.value;
                                        e.target.value = e.target.value.trimStart();
                                            if (e.target.value < 0) {
                                              return 
                                            }
                                              setValidation2({
                                                ...validation2,
                                                contractPrice: "1",
                                              });
                                              setTotalCost(e.target.value);
                                              props.setFormInputsValue({...props.formInputsValue, contractPrice : e.target.value});
                                      }}
                                      onBlur={(e) => {
                                        if (
                                          e.target.value == ""
                                        ) {
                                          setValidation2({
                                            ...validation2,
                                            contractPrice: "-1",
                                          });
                                        }
                                        props.setFormInputsValue({...props.formInputsValue, contractPrice : Number(props.formInputsValue.contractPrice).toFixed(2)});

                                        // setTotalCost(Number(props.formInputsValue.contractPrice).toFixed(2))
                                      }}
                                      onFocus={(e)=>{
                                        props.setFormInputsValue({...props.formInputsValue, contractPrice : (props.formInputsValue.contractPrice != "0.00" && props.formInputsValue.contractPrice != "0" && props.formInputsValue.contractPrice != "") ? Number(props.formInputsValue.contractPrice).toFixed(0): ""});
                                        // setTotalCost(props.formInputsValue.contractPrice)
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                                <Row>
                                  <Col sm={2}>
                                    <Label
                                      className="text-bold"
                                      for="interconnNo"
                                    >
                                      Number of Payment Tiers
                                      <span className="red-text">*</span>
                                    </Label>
                                  </Col>
                                  <Col sm={2}>
                                  <Input
                                      className="createdeal-textfield"
                                      type="number"
                                      name="interconnNo"
                                      id="interconnNo"
                                      style={validation.interconnNo=='-1'?{...validationCss}:{}}
                                      value={tierNo}
                                      onChange={(e) => {
                                        if(e.target.value > billingTermsCache?.length){
                                          return
                                         }
                                        e.target.value =
                                          e.target.value < 0
                                            ? 0
                                            : e.target.value;
                                        if (
                                          e.target.value != 0 ||
                                          e.target.value != undefined
                                        ) {
                                          setValidation({...validation,interconnNo:'1'})
                                        }else{
                                          setValidation({...validation,interconnNo:'-1'})
                                        }
                                        if(e.target.value > 0 && e.target.value >= props?.formInputsValue?.billingTermsInfo?.length){
                                          setTierNo(e.target.value);
                                          props.setFormInputsValue({...props.formInputsValue, tierNumber : e.target.value});
                                        }else{
                                          swal("Alert!", "Cannot reduce the number of tiers as previous tiers are already in use", "warning");
                                        }
                                      }}
                                      onBlur={(e) => {
                                        if (
                                          e.target.value == undefined ||
                                          e.target.value == 0
                                        ) {
                                          setValidation({...validation,interconnNo:'-1'})
                                        } else {
                                          setValidation({...validation,interconnNo:'1'})
                                        }
                                      }}
                                    />
                                  </Col>

                                  <Col sm={7}></Col>
                                </Row>
                              </FormGroup>

                              <Table
                                striped
                                bordered
                                className="table-pad margin-top-18"
                              >
                                <thead className="bg-header">
                                  <tr>
                                    <th className="width-64 left-th">
                                      Actions
                                    </th>
                                    <th className=" width-75 middle-th">
                                      Tier Number
                                    </th>
                                    <th className=" width-75 middle-th">
                                      Item
                                    </th>
                                    <th className="middle-th width-75">Cost</th>
                                    <th className="middle-th width-75">
                                      Billing Stage Task
                                    </th>
                                    <th className="middle-th width-75">
                                      Billing Stage Task Status
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="font12">
                                  {props?.formInputsValue?.billingTermsInfo ? (
                                    props?.formInputsValue?.billingTermsInfo.map(
                                      (info, i) => {
                                        return (
                                          <tr>
                                            <td className="pad0" scope="row">
                                              <Button
                                                className="btn btn-info btn-info2"
                                                onClick={() => editpvSystemInfo(info,i)}
                                              >
                                                <FontAwesomeIcon
                                                  className="float-right"
                                                  icon={faEdit}
                                                />
                                              </Button>
                                              <Button
                                                className="btn btn-danger btn-info2"
                                                onClick={
                                                  () => {
                                                    setIdToDelete(i)
                                                    setDeleteAdminModal(!deleteAdminModal)
                                                  }
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  className="float-right"
                                                  icon={faTrashAlt}
                                                />
                                              </Button>
                                              <Modal
                                                className="exportmodal-width"
                                                isOpen={deleteAdminModal}
                                                toggle={deleteAdminModal}
                                              >
                                                <div className="modal-header-class">
                                                  Confirmation Message
                                                  <div className="note-header-icon">
                                                    {" "}
                                                    <FontAwesomeIcon
                                                      icon={faTimes}
                                                      className="icon-time-color"
                                                      onClick={() => setDeleteAdminModal(!deleteAdminModal)}
                                                    ></FontAwesomeIcon>
                                                  </div>
                                                </div>
                                                <ModalBody
                                                  style={{
                                                    height: "25vh",
                                                    borderRadius: "10px",
                                                    border: "none",
                                                  }}
                                                >
                                                  <FormGroup className="mar-bottom0 sureMessage">
                                                    <Row>
                                                      <Col sm={1}></Col>
                                                      <Col sm={10}>
                                                        <span
                                                          className="text-bold"
                                                          for="l2code"
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          Are you sure you want
                                                          to Delete?
                                                        </span>
                                                      </Col>

                                                      <Col sm={1}></Col>
                                                    </Row>
                                                  </FormGroup>

                                                  <FormGroup className="marb-16 padtop-15 messageButton">
                                                    <Row>
                                                      <Col sm={2}></Col>
                                                      <Col sm={4}>
                                                        <Button
                                                          className="continue-button"
                                                          onClick={() => setDeleteAdminModal(!deleteAdminModal)}
                                                          style={{
                                                            padding: "5px 12px",
                                                          }}
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </Col>
                                                      <Col sm={4}>
                                                        <Button
                                                          className="continue-button"
                                                          onClick={() => {
                                                            deletepvSystemInfo()
                                                            setDeleteAdminModal(!deleteAdminModal)
                                                          }}
                                                          style={{
                                                            background: "#fff",
                                                            color: "#0069d9",
                                                            border: "1px solid",
                                                            padding: "5px 12px",
                                                          }}
                                                        >
                                                          Yes
                                                        </Button>
                                                      </Col>
                                                      <Col sm={2}></Col>
                                                    </Row>
                                                  </FormGroup>
                                                </ModalBody>
                                              </Modal>
                                            </td>
                                            <td>{i + 1}</td>
                                            <td>{info?.item}</td>
                                            <td>{formatter.format(info?.cost)}</td>
                                            <td>{info?.billingStageTask}</td>
                                            <td>{info?.billingStageStatus}</td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  <tr>
                                    <td className="pad0" scope="row">
                                      <Button
                                        className="btn-wwp-plus"
                                        onClick={toggle}
                                        disabled={
                                          tierNo <= 0 || tierNo == undefined || props?.formInputsValue?.billingTermsInfo?.length == tierNo
                                            ? true
                                            : false
                                        }
                                      >
                                        +
                                      </Button>
                                    </td>

                                    <td className="opacity0"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  <tr className="fw-bold">
                                    <td>
                                      <strong>Total</strong>
                                    </td>
                                    <td>
                                      <strong>{props?.formInputsValue?.billingTermsInfo ? props?.formInputsValue?.billingTermsInfo?.length:0}</strong>
                                    </td>
                                    <td>
                                      <strong></strong>
                                    </td>
                                    <td>
                                      <strong style={{color : props.formInputsValue?.contractPrice != getTotalCost() ? "red" : "" }}>{formatter.format(getTotalCost())}</strong>
                                    </td>
                                    <td>
                                      <strong></strong>
                                    </td>
                                    <td>
                                      <strong></strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <span className="notify-interconnection-text">
                                {remainingInterconnections() > 0 ? "Note:-  " + remainingInterconnections() + " unassigned Tiers(s)!" : (props.formInputsValue?.contractPrice < getTotalCost() ? "Total Cost is above Contract Price." : props.formInputsValue?.contractPrice > getTotalCost() ? "Total Cost is below Contract Price." : "")}
                              </span>
                            </FormGroup>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>

                    <Modal
                      className="modalwidth-450"
                      isOpen={nestedModal1}
                      backdrop={backdrop}
                    >
                      <div className="modal-header-class">
                        Billing Terms
                        <div className="note-header-icon">
                          {" "}
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="icon-time-color"
                            onClick={toggle}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <ModalBody>
                        <FormGroup>
                          <Row className="custom-margin">
                            {/* <Col sm={2}></Col> */}
                            <Col sm={5}>
                              <Label className="text-bold" for="retainerCost">
                                Item
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                            <Select
                                name="modelItem"
                                style={{ width: "100%", padding: "1.3px" }}
                                className={validation2.modelItem=='-1'
                                ? 'addclss':''}
                                options={billingTermsArr}
                                closeMenuOnSelect={true}
                                onChange={(values) => {
                                  handleSelectItemName(values);
                                }}
                                value={itemName}
                                menuPlacement="bottom"
                              />
                            </Col>
                          </Row>
                          <Row className="custom-margin">
                            {/* <Col sm={2}></Col> */}
                            <Col sm={5}>
                              <Label className="text-bold" for="approvalCost">
                                Cost
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                              {/* <CurrencyInput
                                className="currency_input h-100 createdeal-textfield"
                                prefix="$"
                                intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                                allowDecimals={false}
                                decimalsLimit="2"
                                name='approvalCost'
                                style={
                                  validation2.approvalCost == "-1"
                                    ? { ...validationCss , padding: "0.7rem"}
                                    : { padding: "0.7rem"}
                                }
                                onValueChange={(value) => {
                                  value = value < 0 || value == undefined ? "" : value;
                                  value = value.trimStart();
                                      if (value < 0) {
                                        return 
                                      }
                                        setValidation2({
                                          ...validation2,
                                          approvalCost: "1",
                                        });
                                      setCost(value);
                                    }}
                                    onBlur={(e) => {
                                      if (
                                        e.target.value == ""
                                      ) {
                                        setValidation2({
                                          ...validation2,
                                          approvalCost: "-1",
                                        });
                                      }
                                    }}
                                    value={cost}
                                  /> */}
                                  <div>
                                    <span className="dollar-sign">$</span>
                                  <Input
                                      type="number"
                                      className="createdeal-textfield currency-input"
                                      name="approvalCost"
                                      id="approvalCost"
                                      style={
                                        validation2.approvalCost == "-1"
                                          ? { ...validationCss}
                                          : {}
                                      }
                                      placeholder="0.00"
                                      value={cost}
                                      onChange={(e) => {
                                        e.target.value = e.target.value < 0 || e.target.value == undefined ? "" : e.target.value;
                                        e.target.value = e.target.value.trimStart();
                                            if (e.target.value < 0) {
                                              return 
                                            }
                                            setValidation2({
                                              ...validation2,
                                              approvalCost: "1",
                                            });
                                              setCost(e.target.value);
                                      }}
                                      onBlur={(e) => {
                                        if (
                                          e.target.value == ""
                                        ) {
                                          setValidation2({
                                            ...validation2,
                                            approvalCost: "-1",
                                          });
                                        }
                                        setCost(Number(cost).toFixed(2))
                                      }}
                                      onFocus={(e)=>{
                                        setCost(cost != "0.00" && cost != "0" && cost != "" ? Number(cost).toFixed(0): "")
                                      }}
                                    />
                                  </div>
                            </Col>
                          </Row>
                          <Row className="custom-margin">
                            <Col sm={5}>
                              <Label className="text-bold" for="permittingCost">
                                Billing Stage Task
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                            <Input
                                type="text"
                                name="billingStageTask"
                                style={validation2.billingStageTask=="-1"?{...validationCss}:{}}
                                className="create-user-input"
                                value={billingStageTask}
                                onBlur={(e) => {
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation2({...validation2,billingStageTask:'-1'})
                                  }
                                }}
                                onChange={(e) => {
                                  e.target.value=e.target.value.trimStart();
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation2({...validation2,billingStageTask:'-1'})
                                  }else{
                                    setValidation2({...validation2,billingStageTask:'1'})
                                  }
                                  setBillingStageTask(e.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="custom-margin">
                            <Col sm={5}>
                              <Label
                                className="text-bold"
                                for="priorToSignOffCost"
                              >
                                Billing Stage Task Status
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                            <Input
                                style={validation2.billingStageStatus=="-1"?{...validationCss}:{}}
                                type="text"
                                name="billingStageStatus"
                                className="create-user-input"
                                value={billingStageStatus}
                                onChange={(e) => {
                                  e.target.value=e.target.value.trimStart();
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation2({...validation2,billingStageStatus:'-1'})
                                  }else{
                                    setValidation2({...validation2,billingStageStatus:'1'})
                                  }
                                  setBillingStageStatus(e.target.value);
                                }}
                                onBlur={(e) => {
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation2({...validation2,billingStageStatus:'-1'})
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup className="marb-16 padtop-15 mt-2">
                          <Row>
                            <Col sm={6}>
                              <Button
                                className="continue-button"
                                onClick={toggle}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col sm={6}>
                              <Button
                                className="continue-button"
                                onClick={saveBillingTermsInfo}
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </ModalBody>
                    </Modal>


                    <Button
                     onClick={()=>{
                      if(remainingInterconnections()!=0||tierNo==""||tierNo==undefined){
                        return
                      }else if(props.formInputsValue?.contractPrice > getTotalCost() || props.formInputsValue?.contractPrice < getTotalCost()){
                        return
                      }
                      if(billingTermstogglestate){
                        setBillingTermstogglestate(!billingTermstogglestate)
                      }
                      setServiceAdderstogglestate(!serviceAdderstogglestate)
                      props.setLayoutState("ServiceAdders")
                      localStorage.setItem('currentsidenavbar',"ServiceAdders");
                    }}
                    className={props.layoutstate=="ServiceAdders" ? "btn-100-active" : "btn-100"}
                    >
                      Service Adders
                      <div className="float-right">
                        {serviceAdderstogglestate ? (
                          <FontAwesomeIcon
                            className="font-centered"
                            icon={faAngleDown}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="font-centered"
                            icon={faAngleRight}
                          />
                        )}
                      </div>
                    </Button>
                    <Collapse isOpen={serviceAdderstogglestate}>
                      <Card className="createdeal-card">
                        <CardBody>
                        <div>
                            <FormGroup>
                            <Table
                                striped
                                bordered
                                className="table-pad margin-top-18"
                              >
                                <thead className="bg-header">
                                  <tr>
                                    <th className="width-64 left-th">
                                      Actions
                                    </th>
                                    <th className=" width-75 middle-th">
                                      Services
                                    </th>
                                    <th className="middle-th width-75">Cost</th>
                                    <th className="middle-th width-75">
                                      Billing Stage Task
                                    </th>
                                    <th className="middle-th width-75">
                                      Billing Stage Task Status
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="font12">
                                  {props?.formInputsValue?.serviceAddersInfo ? (
                                    props?.formInputsValue?.serviceAddersInfo.map(
                                      (info, i) => {
                                        return (
                                          <tr>
                                            <td className="pad0" scope="row">
                                              <Button
                                                className="btn btn-info btn-info2"
                                                onClick={() => editServiceAdderInfo(info,i)}
                                              >
                                                <FontAwesomeIcon
                                                  className="float-right"
                                                  icon={faEdit}
                                                />
                                              </Button>
                                              <Button
                                                className="btn btn-danger btn-info2"
                                                onClick={
                                                  () => {
                                                    setServiceIdToDelete(i)
                                                    setDeleteServiceAdderAdminModal(!deleteServiceAdderModal)
                                                  }
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  className="float-right"
                                                  icon={faTrashAlt}
                                                />
                                              </Button>
                                              <Modal
                                                className="exportmodal-width"
                                                isOpen={deleteServiceAdderModal}
                                                toggle={deleteServiceAdderModal}
                                              >
                                                <div className="modal-header-class">
                                                  Confirmation Message
                                                  <div className="note-header-icon">
                                                    {" "}
                                                    <FontAwesomeIcon
                                                      icon={faTimes}
                                                      className="icon-time-color"
                                                      onClick={() => setDeleteServiceAdderAdminModal(!deleteServiceAdderModal)}
                                                    ></FontAwesomeIcon>
                                                  </div>
                                                </div>
                                                <ModalBody
                                                  style={{
                                                    height: "25vh",
                                                    borderRadius: "10px",
                                                    border: "none",
                                                  }}
                                                >
                                                  <FormGroup className="mar-bottom0 sureMessage">
                                                    <Row>
                                                      <Col sm={1}></Col>
                                                      <Col sm={10}>
                                                        <span
                                                          className="text-bold"
                                                          for="l2code"
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          Are you sure you want
                                                          to Delete?
                                                        </span>
                                                      </Col>

                                                      <Col sm={1}></Col>
                                                    </Row>
                                                  </FormGroup>

                                                  <FormGroup className="marb-16 padtop-15 messageButton">
                                                    <Row>
                                                      <Col sm={2}></Col>
                                                      <Col sm={4}>
                                                        <Button
                                                          className="continue-button"
                                                          onClick={() => setDeleteServiceAdderAdminModal(!deleteServiceAdderModal)}
                                                          style={{
                                                            padding: "5px 12px",
                                                          }}
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </Col>
                                                      <Col sm={4}>
                                                        <Button
                                                          className="continue-button"
                                                          onClick={() => {
                                                            deleteServiceAdderInfo()
                                                            setDeleteServiceAdderAdminModal(!deleteServiceAdderModal)
                                                          }}
                                                          style={{
                                                            background: "#fff",
                                                            color: "#0069d9",
                                                            border: "1px solid",
                                                            padding: "5px 12px",
                                                          }}
                                                        >
                                                          Yes
                                                        </Button>
                                                      </Col>
                                                      <Col sm={2}></Col>
                                                    </Row>
                                                  </FormGroup>
                                                </ModalBody>
                                              </Modal>
                                            </td>
                                            <td>{info?.item}</td>
                                            <td>{formatter.format(info?.cost)}</td>
                                            <td>{info?.billingStageTask}</td>
                                            <td>{info?.billingStageStatus}</td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}

                                  <tr>
                                    <td className="pad0" scope="row">
                                      <Button
                                        className="btn-wwp-plus"
                                        onClick={toggleServiceModal}
                                      >
                                        +
                                      </Button>
                                    </td>

                                    <td className="opacity0"></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </Table>
                            </FormGroup>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                    <Modal
                      className="modalwidth-450"
                      isOpen={modal}
                      backdrop={backdrop}
                    >
                      <div className="modal-header-class">
                        Service Adders Info
                        <div className="note-header-icon">
                          {" "}
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="icon-time-color"
                            onClick={toggleServiceModal}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                      <ModalBody>
                        <FormGroup>
                          <Row className="custom-margin">
                            <Col sm={5}>
                              <Label className="text-bold" for="retainerCost">
                                Item
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                            <Select
                                name="modelItems"
                                style={{ width: "100%", padding: "1.3px" }}
                                className={
                                  validation3.modelItems == "-1"
                                    ? "addclss"
                                    : ""
                                }
                                options={allServiceOption}
                                closeMenuOnSelect={true}
                                onChange={(values) => {
                                  if (values != "" || values != undefined) {
                                    setValidation3({
                                      ...validation3,
                                      modelItems: "1",
                                    });
                                  }
                                  handleSelectServiceName(values);
                                }}
                                value={serviceName}
                                menuPlacement="bottom"
                              />
                            </Col>
                          </Row>
                          <Row className="custom-margin">
                            <Col sm={5}>
                              <Label className="text-bold" for="approvalCost">
                                Cost
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                              {/* <CurrencyInput
                                className="currency_input h-100 createdeal-textfield"
                                prefix="$"
                                intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                                allowDecimals={false}
                                decimalsLimit="2"
                                name='approvalCosts'
                                style={validation3.approvalCosts=="-1"?{...validationCss, padding: "0.7rem"}:{ padding: "0.7rem"}}
                                onValueChange={(value) => {
                                  value = value < 0 || value == undefined ? "" : value;
                                  value = value.trimStart();
                                      if (value < 0) {
                                        return 
                                      }
                                      setValidation3({
                                        ...validation3,
                                        approvalCosts: "1",
                                      });
                                    setServiceCost(value);
                                    }}
                                    onBlur={(e) => {
                                      if (
                                        e.target.value == ""
                                      ) {
                                        setValidation3({
                                          ...validation3,
                                          approvalCosts: "-1",
                                        });
                                      }
                                    }}
                                    value={serviceCost}
                                  /> */}
                                  <div>
                                    <span className="dollar-sign">$</span>
                                  <Input
                                      type="number"
                                      className="createdeal-textfield currency-input"
                                      name="approvalCosts"
                                      id="approvalCosts"
                                      style={validation3.approvalCosts=="-1"?{...validationCss}:{}}
                                      placeholder="0.00"
                                      value={serviceCost}
                                      onChange={(e) => {
                                        e.target.value = e.target.value < 0 || e.target.value == undefined ? "" : e.target.value;
                                        e.target.value = e.target.value.trimStart();
                                            if (e.target.value < 0) {
                                              return 
                                            }
                                            setValidation3({
                                              ...validation3,
                                              approvalCosts: "1",
                                            });
                                            setServiceCost(e.target.value);
                                      }}
                                      onBlur={(e) => {
                                        if (
                                          e.target.value == ""
                                        ) {
                                          setValidation3({
                                            ...validation3,
                                            approvalCosts: "-1",
                                          });
                                        }
                                        setServiceCost(Number(serviceCost).toFixed(2))
                                      }}
                                      onFocus={(e)=>{
                                        setServiceCost(serviceCost != "0.00" && serviceCost != "0" && serviceCost != "" ? Number(serviceCost).toFixed(0) : "")
                                      }}
                                    />
                                  </div>
                            </Col>
                          </Row>
                          <Row className="custom-margin">
                            {/* <Col sm={2}></Col> */}
                            <Col sm={5}>
                              <Label className="text-bold" for="permittingCost">
                                Billing Stage Task
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                            <Input
                                style={validation3.adderBillingStageTask=="-1"?{...validationCss}:{}}
                                type="text"
                                name="adderBillingStageTask"
                                className="create-user-input"
                                value={adderBillingStageTask}
                                onBlur={(e) => {
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation3({...validation3,adderBillingStageTask:'-1'})
                                  }
                                }}
                                onChange={(e) => {
                                  e.target.value=e.target.value.trimStart();
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation3({...validation3,adderBillingStageTask:'-1'})
                                  }else{
                                    setValidation3({...validation3,adderBillingStageTask:'1'})
                                  }
                                  setAdderBillingStageTask(e.target.value);
                                }}
                                
                              />
                            </Col>
                          </Row>
                          <Row className="custom-margin">
                            {/* <Col sm={1}></Col> */}
                            <Col sm={5}>
                              <Label
                                className="text-bold"
                                for="priorToSignOffCost"
                              >
                                Billing Stage Task Status
                                <span className="red-text">*</span>
                              </Label>
                            </Col>
                            <Col sm={7}>
                            <Input
                                style={validation3.adderBillingStageStatus=="-1"?{...validationCss}:{}}
                                type="text"
                                name="adderBillingStageStatus"
                                className="create-user-input"
                                value={adderBillingStageStatus}
                                onBlur={(e) => {
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation3({...validation3,adderBillingStageStatus:'-1'})
                                  }
                                }}
                                onChange={(e) => {
                                  e.target.value=e.target.value.trimStart();
                                  if(e.target.value==''||e.target.value==undefined){
                                    setValidation3({...validation3,adderBillingStageStatus:'-1'})
                                  }else{
                                    setValidation3({...validation3,adderBillingStageStatus:'1'})
                                  }
                                  setAdderBillingStageStatus(e.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup className="marb-16 padtop-15 mt-2">
                          <Row>
                            <Col sm={6}>
                              <Button
                                className="continue-button"
                                onClick={toggleServiceModal}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col sm={6}>
                              <Button
                                className="continue-button"
                                onClick={saveServiceAddersInfo}
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </ModalBody>
                    </Modal>
                  </Col>
                ) : (
                  <></>
                )}
              </div>
            </Row>
          </div>
        </div>
      </>
    </div>
  );
}
