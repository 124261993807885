import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./contractorModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import handleCreate from '../../../../../../common/util/createRecord';
import ReactInputMask from 'react-input-mask';
import dayjs from 'dayjs';

const formInputs =
{
    companyName: "",
    firstName: "",
    middleInitial: "",
    lastName: "",
    phoneNumber: "",
    faxNumber: "",
    mobileNumber: "",
    streetAdd: "",
    city: "",
    state: "",
    zipCode: "",
    emailAdd: "",
    tackingNumber: "",
    homeImproveLicenseNo: "",
    taxpayerID: "",
    expirationDate: "",
}


export default function ContractModal(props) {

    const [formInputsValue, setFormInputsValue] = useState(formInputs);
    const [phoneError, setPhoneError] = useState(false);
    const [faxError, setFaxError] = useState(false);
    const [mobileError, setMobileError] = useState(false);

    let valid=(emailtext)=>{
        for(let i=1;i<emailtext.length;i++){
          if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
            return false;
          }
        }
        return true;
      }

    const handleChange = (e) => {
        if(e.currentTarget.name=='emailAdd'){
            // Email validation
          let validMail=false;
          let str=e.target.value.toString()
          const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
          const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
          if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){              if(!regforSpec.test(str)){
                  if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
                    validMail=true;
                  }
              }
          }
          // use validMail(true/false) for valoidation
            
            if ( validMail ) {
              setValidation({...validation,emailAdd:"1"})
              setMailInvalid(false);
            }
          }

        setFormInputsValue({
            ...formInputsValue,
            [e.target.name]: e.target.value
        })
    }
    const changeDate = (date, name) => {
        if(date==null
          ||date==undefined
          ||date==''){
          setValidation({ ...validation, expirationDate: "-1" });
        } else {
          setValidation({ ...validation, expirationDate: "1" });
        }
        const formattedDate = dayjs(date).format('mm-dd-yyyy')
        setFormInputsValue({
          ...formInputsValue,
          [name] : date
      })
    
      }

    const isAllMandatoriesValid = () => {
        let flag = true;
         
        let vKeys=Object.keys(validation);
        let ob={};
        for(let i=0;i<vKeys.length;i++){
            if(formInputsValue[vKeys[i]]=='' || formInputsValue[vKeys[i]]==undefined || mailInvalid || mobileError || phoneError || zipValid==false){
            flag=false;
            break
          }
        }

        return flag;
    }

    const saveContractor = async () => {
         
        let isvalidData = isAllMandatoriesValid();
        if (isvalidData) {
            await createContractorZoho();
        }
        else {
            highliteEmptys()
            alert("Mandatory fields are missing");
        }
    }

    const createContractorZoho = async () => {
        let obj = {
            "Name": formInputsValue.GeneralContractorName,
            "Company_Name": formInputsValue.companyName,
            "Street_Name": formInputsValue.streetAdd,
            "Fax_Number": formInputsValue.faxNumber,
            "Email_Address": formInputsValue.emailAdd,
            "Phone_Number": formInputsValue.phoneNumber,
            "State": formInputsValue.state,
            "Taxpayer_s_ID": formInputsValue.taxpayerID,
            "Tracking_Number": formInputsValue.tackingNumber,
            "Expiration_Date": formInputsValue.expirationDate,
        }
        await handleCreate("General_Contractor",obj)
            .then(function (data) {
                data.data=data.data.data;
                props.handleOnChangeDeal({ currentTarget: { name: 'Contractor', value:{name:formInputsValue.GeneralContractorName,id:data.data[0].details.id,module:"General_Contractors"} } })
                props.setgeneralContracterModal(false);
                props.getGeneralContractors();
                resetValidations();
            })
            .catch((err)=>{
                console.log(err);
               alert(err);
            });
    }

    const checkNumbers = (e) => {
    if(e.target.name=='phoneNumber' || e.target.name=='mobileNumber'){ 
        let value= e.target.value=e.target.value.trimStart();    
        var value1= value.replace(/[-#*$+/?=%() ]/g,'')
        let onlyNo= value1.replace(/\D/g, '');
        if(onlyNo.length>10){
            return
        }
        setFormInputsValue({
             ...formInputsValue,
             [e.target.name]: value
        })

    }else{
        setFormInputsValue({
            ...formInputsValue,
            [e.target.name]: e.target.value
     })
    }        
    }


    const validatePhoneNmuber = (e) => {
        var value1= formInputsValue.phoneNumber?.replace(/[-#*$+/?=%() ]/g,'')
        let onlyNo= value1.replace(/\D/g, '');
        if (onlyNo?.length < 10) {
            setValidation({...validation,phoneNumber:'-1'})
            setPhoneError(true);
        } else {
            setValidation({...validation,phoneNumber:'1'})
            setPhoneError(false);
        }
    }

    const validateFaxNumber = (e) => {
        if (formInputsValue.faxNumber.length < 9 &&
            formInputsValue.faxNumber.length != 0) {
            setFaxError(true);
        } else {
            setFaxError(false);
        }
    }


    const validateMobileNmuber = (e) => {
        var value1= formInputsValue.mobileNumber?.replace(/[-#*$+/?=%() ]/g,'')
        let onlyNo= value1.replace(/\D/g, '');
        if (onlyNo.length < 10 ) {
            setValidation({...validation,mobileNumber:'-1'})
            setMobileError(true);
        } else {
            setValidation({...validation,mobileNumber:'1'})
            setMobileError(false);
        }
    }

    // hrushikesh shelke new code
    const [validation,setValidation] = useState({
        GeneralContractorName:"0",
        companyName:'0',
        firstName:'0',
        // middleInitial:'0',
        lastName:'0',
        phoneNumber:'0',
        mobileNumber:'0',
        streetAdd:'0',
        zipCode:'0',
        city:'0',
        state:'0',
        tackingNumber:'0',
        emailAdd:'0',
        homeImproveLicenseNo:'0',
        taxpayerID:'0',
        expirationDate:'0'
    })
    const resetValidations=(data)=>{
     
        let vKeys=Object.keys(validation);
        let ob={};
        for(let i=0;i<vKeys.length;i++){
          ob[vKeys[i]]='0'
        }
       setFormInputsValue(formInputs);
       setValidation(ob)
      }
      const highliteEmptys=()=>{
         
        let vKeys=Object.keys(validation);
        let ob={};
        for(let i=0;i<vKeys.length;i++){
            if(formInputsValue[vKeys[i]]=='' || formInputsValue[vKeys[i]]==undefined){
                ob[vKeys[i]]='-1'
            }else{
                ob[vKeys[i]]='1'
            }
          if(vKeys[i]=='zipCode'&&zipValid==false){
            ob={...ob,zipCode:'-1'}
          }
        }
        setValidation(ob)
      }
    const [mailInvalid, setMailInvalid] = useState(false);
    let handlemailid = (email) => {
         
       // Email validation
    let validMail=false;
    let str=email.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
        if (validMail) {
          setMailInvalid(false);
          setValidation({...validation,emailAdd:'1'})
          // this is a valid email address
          // call setState({email: email}) to update the email
          // or update the data in redux store.
        } else {
            setValidation({...validation,emailAdd:'-1'})
          if (email.length == 0) {
            setMailInvalid(false);
          } else {
            setMailInvalid(true);
          }
        }
      };
    const[zipValid,setZipValid]=useState(true);
    const validationCss={
        "transition": "width 0.4s ease-in-out",
        "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
      }

    //end
    return (
        <div>
            <Modal
                isOpen={props.generalContractermodal}
                //toggle={props.generalContractorToggle}
                backdrop={props.backdrop}
                keyboard={props.keyboard}
            >
                <div className="modal-header-class">
                    General Contractor Information
                    <div className="note-header-icon">
                        {" "}
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="icon-time-color"
                            onClick={() => {
                                setMailInvalid(false)
                                setPhoneError(false)
                                setMobileError(false)
                                resetValidations()
                                props.setgeneralContracterModal(false)
                            }}
                        ></FontAwesomeIcon>
                    </div>
                </div>
                <ModalBody className="h500-overflow">
                    <FormGroup row className="marb-16  padtop-15">
                        <Row>
                            <Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    General Contractor Name<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="GeneralContractorName"
                                    id="ownerlastname"
                                    className="createdeal-textfield"
                                    value={formInputsValue.GeneralContractorName}
                                    onChange={(e) => {
                                        e.target.value=e.target.value.trimStart()
                                        handleChange(e)}}
                                    style={validation.GeneralContractorName=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.GeneralContractorName?.trim().length==0 || formInputsValue.GeneralContractorName==undefined){
                                            setValidation({...validation,GeneralContractorName :'-1'});
                                        }else{
                                            setValidation({...validation,GeneralContractorName :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>

                    <FormGroup row className="marb-16  padtop-15">
                        <Row>
                            <Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Company Name<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="companyName"
                                    id="ownerlastname"
                                    className="createdeal-textfield"
                                    value={formInputsValue.companyName}
                                    onChange={(e) =>{
                                        e.target.value = e.target.value.trimStart();
                                        handleChange(e)
                                    }}
                                    style={validation.companyName=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.companyName?.trim().length==0 || formInputsValue.companyName==undefined){
                                            setValidation({...validation,companyName :'-1'});
                                        }else{
                                            setValidation({...validation,companyName :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row>
                            <Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    First Name<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="firstName"
                                    id="ownerlastname"
                                    className="createdeal-textfield"
                                    value={formInputsValue.firstName}
                                    onChange={(e) =>{ 
                                        e.target.value=e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                                        e.target.value=e.target.value.replace(/[^\D]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^\D]/g,'').slice(1)
                                        handleChange(e)}}
                                    style={validation.firstName=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.firstName?.trim().length==0 
                                        || formInputsValue.firstName==undefined){
                                            setValidation({...validation,firstName :'-1'});
                                        }else{
                                            setValidation({...validation,firstName :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row>
                            <Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Middle Initial
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="middleInitial"
                                    id="ownerlastname"
                                    className="createdeal-textfield"
                                    value={formInputsValue.middleInitial}
                                    maxLength={1}
                                    onChange={(e) =>{
                                        e.target.value=e.target.value.trimStart()
                                        e.target.value=e.target.value.replace(/[^a-zA-Z]/g,'').toUpperCase()
                                        handleChange(e)
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Last Name<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="lastName"
                                    id="ownerlastname"
                                    className="createdeal-textfield"
                                    value={formInputsValue.lastName}
                                    onChange={(e) =>{ 
                                        e.target.value=e.target.value.trimStart().replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                                        e.target.value=e.target.value.replace(/[^\D]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^\D]/g,'').slice(1)
                                        handleChange(e)}}
                                    style={validation.lastName=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.lastName?.trim().length==0 
                                        || formInputsValue.lastName==undefined){
                                            setValidation({...validation,lastName :'-1'});
                                        }else{
                                            setValidation({...validation,lastName :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Phone Number<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                            <ReactInputMask
                                    type="text"
                                    mask="(999) 999-9999"
                                    maskChar='_'
                                    className="form-control createdeal-textfield"
                                    name="phoneNumber"
                                    id="ownerlastname"
                                    value={formInputsValue.phoneNumber}
                                    style={phoneError || validation.phoneNumber=='-1'?{...validationCss}:{}}
                                    onChange={(e) =>{ 
                                        checkNumbers(e)
                                    }}
                                    onBlur={(e) => validatePhoneNmuber(e)}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                                <span
                                    className={phoneError == true || validation.phoneNumber=='-1'? "red-text font12" : "error-display"}>
                                    Enter 10 digit Phone Number
                                </span>
                            </Col>

                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Fax Number
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="faxNumber"
                                    className="createdeal-textfield"
                                    value={formInputsValue.faxNumber}
                                    style={faxError?{...validationCss}:{}}
                                    onChange={(e) => {
                                        e.target.value=e.target.value.replace(/\D/g, '');
                                        if(e.target.value.length>9){
                                            e.preventDefault()
                                            return
                                        }
                                        checkNumbers(e);
                                    }}
                                    onBlur={(e) => validateFaxNumber(e)}
                                    maxLength="9"
                                />
                            </Col>

                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                                <span
                                    className={faxError == true ? "red-text font12" : "error-display"}>
                                    Enter 9 digit fax Number
                                </span>
                            </Col>

                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Mobile Number<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                            <ReactInputMask
                                    type="text"
                                    mask="(999) 999-9999"
                                    maskChar='_'
                                    className="form-control createdeal-textfield"
                                    name="mobileNumber"
                                    value={formInputsValue.mobileNumber}
                                    style={mobileError || validation.mobileNumber=='-1'?{...validationCss}:{}}
                                    onChange={(e) => checkNumbers(e)}
                                    onBlur={(e) => validateMobileNmuber(e)}
                                    
                                />
                            </Col>

                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                                <span
                                    className={mobileError == true || validation.mobileNumber=='-1'? "red-text font12" : "error-display"}>
                                    Enter 10 digit Mobile Number
                                </span>
                            </Col>

                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Street Address<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="streetAdd"
                                    className="createdeal-textfield"
                                    value={formInputsValue.streetAdd}
                                    onChange={(e) =>{
                                        e.target.value=e.target.value.trimStart()
                                        handleChange(e)}}
                                    style={validation.streetAdd=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.streetAdd?.trim().length==0 
                                        || formInputsValue.streetAdd==undefined){
                                            setValidation({...validation,streetAdd :'-1'});
                                        }else{
                                            setValidation({...validation,streetAdd :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    City<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="city"
                                    className="createdeal-textfield"
                                    value={formInputsValue.city}
                                    onChange={(e) =>{
                                        e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                                        e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                                        handleChange(e)}}
                                    style={validation.city=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.city?.trim().length==0 
                                        || formInputsValue.city==undefined){
                                            setValidation({...validation,city :'-1'});
                                        }else{
                                            setValidation({...validation,city :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    State<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="state"
                                    className="createdeal-textfield"
                                    value={formInputsValue.state}
                                    onChange={(e) => {
                                        e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                                        handleChange(e)}}
                                    style={validation.state=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.state?.trim().length==0 
                                        || formInputsValue.state==undefined){
                                            setValidation({...validation,state :'-1'});
                                        }else{
                                            setValidation({...validation,state :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Zip Code<span className="red-text">*</span></Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    className="createdeal-textfield"
                                    value={formInputsValue.zipCode}
                                    style={validation.zipCode=='-1'?{...validationCss}:{}}
                                    onChange={(e) => {
                                      e.target.value=e.target.value.replace(/\D/g, '');
                                      if(e.target.value.length>5){
                                        e.preventDefault()
                                        return
                                      }
                                      if(e.target.value<5){
                                        setValidation({...validation,zipCode:'-1'})
                                      }
                                      if(e.target.value.length==5){
                                        setValidation({...validation,zipCode:'1'})
                                        setZipValid(true);
                                      }
                                      handleChange(e)
                                    }}
                                    onBlur={(e)=>{
                                        if(e.target.value.length<5||e.target.value==undefined){
                                            setValidation({...validation,zipCode:'-1'})
                                        }
                                      if(formInputsValue.zipCode.length<5 && formInputsValue.zipCode!=undefined  && formInputsValue.zipCode?.length!=0){
                                        setZipValid(false);
                                      }
                                    }}
                                />

                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                                <span
                                    className={validation.zipCode=='-1'? "red-text font12" : "error-display"}>
                                    5 Digit Zip code required.
                                </span>
                            </Col>

                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Email Address<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="emailAdd"
                                    className="createdeal-textfield"
                                    value={formInputsValue.emailAdd}
                                    onChange={(e) =>{
                                        e.target.value=e.target.value.trimStart()
                                        handleChange(e)}}
                                    style={mailInvalid || validation.emailAdd=='-1'?{...validationCss}:{}}
                                    onBlur={(e) => handlemailid(e.target.value)}
                                />
                            </Col>

                            <Col sm={1}></Col>

                        </Row>
                        <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}>
                                <span
                                    className={mailInvalid == true || validation.emailAdd=='-1' ? "red-text font12" : "error-display"}>
                                     Incorrect E-mail Address format.
                                </span>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={6}></Col>
                            <Col sm={6}></Col>

                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Tracking Number<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="tackingNumber"
                                    className="createdeal-textfield"
                                    value={formInputsValue.tackingNumber}
                                    onChange={(e) =>{
                                        e.target.value=e.target.value.trimStart()
                                        handleChange(e)}}
                                    style={validation.tackingNumber=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.tackingNumber?.trim().length==0 
                                        || formInputsValue.tackingNumber==undefined){
                                            setValidation({...validation,tackingNumber :'-1'});
                                        }else{
                                            setValidation({...validation,tackingNumber :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Home Improvement License Number<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="homeImproveLicenseNo"
                                    className="createdeal-textfield"
                                    value={formInputsValue.homeImproveLicenseNo}
                                    onChange={(e) =>{
                                        e.target.value=e.target.value.trimStart()
                                        handleChange(e)}}
                                    style={validation.homeImproveLicenseNo=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.homeImproveLicenseNo?.trim().length==0 
                                        || formInputsValue.homeImproveLicenseNo==undefined){
                                            setValidation({...validation,homeImproveLicenseNo :'-1'});
                                        }else{
                                            setValidation({...validation,homeImproveLicenseNo :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Taxpayer's ID<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    name="taxpayerID"
                                    className="createdeal-textfield"
                                    value={formInputsValue.taxpayerID}
                                    onChange={(e) => {
                                        e.target.value=e.target.value.trimStart()
                                        handleChange(e)}}
                                    style={validation.taxpayerID=='-1'?{...validationCss}:{}}
                                    onBlur={()=>{
                                         
                                        if(formInputsValue.taxpayerID?.trim().length==0 
                                        || formInputsValue.taxpayerID==undefined){
                                            setValidation({...validation,taxpayerID :'-1'});
                                        }else{
                                            setValidation({...validation,taxpayerID :'1'});
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup row className="marb-16  padtop-15">
                        <Row><Col sm={1}></Col>
                            <Col sm={5}>
                                <Label className="text-bold" for="ownerlastname">
                                    Expiration Date<span className="red-text">*</span>
                                </Label>
                            </Col>
                            <Col sm={5}>

                            <DatePicker
                                className={(validation.expirationDate == '0' || validation.expirationDate == '1') ? "valid-date" : "invalid-date"}
                                name="expirationDate"
                                placeholderText='mm/dd/yyyy'
                                onKeyDown={(e) => {
                                e.preventDefault();
                                }}
                                selected={
                                formInputsValue.expirationDate ? formInputsValue.expirationDate : ''
                                }
                                onChange={(e) => changeDate(e, "expirationDate")}
                                dateFormat="MM/dd/yyyy"
                                onBlur={(e) => {
                                if(e.currentTarget.value==null
                                    ||e.currentTarget.value==undefined
                                    ||e.currentTarget.value==''){
                                    setValidation({ ...validation, expirationDate: "-1" });
                                } else {
                                    setValidation({ ...validation, expirationDate: "1" });
                                }
                                }}
                            />
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className="marb-16 padtop-15 mt-3">
                        <Row>

                            <Col sm={6} className="text-right">
                                <Button
                                    className="continue-button contractor-btn"
                                    onClick={() => {
                                        setMailInvalid(false)
                                        setPhoneError(false)
                                        setMobileError(false)
                                        resetValidations();
                                        props.setgeneralContracterModal(false)
                                    }}
                                >
                                    Cancel
                                </Button></Col>
                            <Col sm={6} className="text-left">
                                <Button
                                    className="continue-button contractor-btn"
                                    onClick={() => saveContractor()}
                                >
                                    Save
                                </Button></Col>
                        </Row>
                    </FormGroup>
                </ModalBody>

            </Modal>

        </div>
    )
}