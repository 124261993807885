import React, { useEffect, useRef, useState } from "react";
import { EmojiReactionPicker } from "../EmojiReactionPicker/EmojiReactionPicker";
import "./chat.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { HorizontalCollapseOptions } from "./CollapseOptions/HorizontalCollapseOptions";
import ChatService from "../util/ChatService";
import PinChatIcon from "./PinChatIcon.png";
import { AllPinnedMessageCollapse } from "./CollapseOptions/AllPinedMessageCollapse";
import UserService from "../util/UserService";
import defaultUser from "../../assets/images/defaultUser.png";

export const ChatBodyComponent = ({
  newMessagesBuffer,
  setSelectedChatsInChats,
  clearSelections,
  externalSearchTrigger,
  setChatMessages,
  chatMessages,
  convertZohoClickMessageToCRM1,
  getTime,
  allUsers,
  openImagePreview,
  setChatContainerRef,
  selectUser,
  tempState,
  appUser,
  setpinnedMassageForReply,
  searchInchats,
  scrollToEnd,
  upclicked,
  downclicked,
}) => {
  const [showComponent, setShowComponent] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [myProfile, setmyProfile] = useState({});
  const [pinnedMessageInChat, setpinnedMessageInChat] = useState(null);
  const [isPinnedExpandOpen, setIsPinnedExpandOpen] = useState(false);
  const [allPinnedMessageInChat, setAllPinnedMessageInChat] = useState([]);
  const [triggerImojiSelector, setTriggerEmojiSelector] = useState(1);
  const [selectedChats, setselectedChats] = useState([]);
  const [isHeld, setIsHeld] = useState();
  const [newMessageSectionIndex, setnewMessageSectionIndex] = useState();
  const [section, setSection] = useState("Chat");
  const countRef = useRef(0);
  const [userColors, setUserColors] = useState({});
  const [pinnedMessageInChatOld, setpinnedMessageInChatOld] = useState(null);
  const chatContainerRef = useRef(null);

  useEffect(
    (e) => {
    //   console.log("Timer Set", isHeld);
      if (isHeld) {
        setselectedChats([...selectedChats, isHeld]);
      }
    },
    [isHeld]
  );
  useEffect(
    (e) => {
      setselectedChats([]);
      document.querySelectorAll(".Selected-Chat")?.forEach((e) => {
        e.classList.remove("Selected-Chat");
      });
    },
    [clearSelections]
  );
  useEffect(
    (e) => {
      setSelectedChatsInChats(selectedChats);
    },
    [selectedChats]
  );
  useEffect(
    (e) => {
      reverseChatData(chatMessages);
    },
    [chatMessages]
  );

  useEffect(
    (e) => {
      setShowComponent(false);
      setTimeout((e) => setShowComponent(true), 2000);
    },
    [tempState]
  );
  useEffect(
    (e) => {
      if (appUser) {
        appUser["name"] = appUser.first_name + " " + appUser.last_name;
        setmyProfile(appUser);
        setselectedChats([]);
      }
    },
    [appUser]
  );

  function focuseThePinnedChat(id) {
    if (id) {
      let classId = `Highlight${id?.replaceAll("_", "")}`;
      if (classId) {
        let dom = document.querySelector(`#${classId} .highlite-child-pointer`);
        if (dom) {
          dom.classList.add("Pinned-Chat-Class");
        }
      }
    }
  }

  useEffect(
    (e) => {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    },
    [scrollToEnd]
  );

  useEffect(
    (e) => {
      let filteredMessages = [];
      let searchKeyWord = searchInchats?.trim().toLowerCase();
      if (searchKeyWord != "") {
        chatHistory.forEach((one) => {
          let text = one?.content?.text?.toLowerCase().split(" ");
          let classId = `Highlight${one?.id?.replaceAll("_", "")}`;
          let dom = document.getElementById(classId);
          if (one?.content?.text?.toLowerCase()?.includes(searchKeyWord)) {
            if (dom) {
              dom.style.boxShadow =
                "30px 1px 21px 43px inset rgba(174,210,255,0.47)";
            }
            filteredMessages.push({ text: one.content.text, id: one.id });
          } else {
            if (dom) {
              dom.style.boxShadow = "";
            }
          }
        });
        if (filteredMessages.length > 0) {
          if (downclicked?.state && countRef.current > 0) {
            countRef.current -= 1;
          } else if (
            upclicked?.state &&
            filteredMessages.length > countRef.current
          ) {
            countRef.current += 1;
          } else if (filteredMessages.length == countRef.current) {
            countRef.current = 0;
          } else if (downclicked?.state && countRef.current == 0) {
            countRef.current = filteredMessages.length - 1;
          } else if (countRef.current > filteredMessages.length) {
            countRef.current = 0;
          } else {
            countRef.current += 1;
          }
          FocusIntoView(filteredMessages[countRef.current]?.id, "search");
        }
      } else {
        if (chatHistory.length > 0) {
          chatHistory.forEach((one) => {
            let classId = `Highlight${one?.id?.replaceAll("_", "")}`;
            let dom = document.getElementById(classId);
            if (dom) {
              dom.style.boxShadow = "";
              dom.style.backgroundColor = "";
            }
          });
        }
      }
    },
    [externalSearchTrigger, upclicked, searchInchats]
  );

  const getRandomColor = () => {
    const contrastThreshold = 0.7; // Adjust this threshold as needed
    let color = "#";

    do {
      color = "#";
      // Generate random R, G, and B values
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);

      // Calculate the luminance using the formula for relative luminance
      const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

      // Calculate the contrast ratio between the generated color and white background
      const contrast =
        luminance > 0.5 ? (luminance + 0.05) / 1.05 : 1.05 / (luminance + 0.05);

      // If contrast ratio meets the threshold, set the color
      if (contrast >= contrastThreshold) {
        color += (r < 16 ? "0" : "") + r.toString(16);
        color += (g < 16 ? "0" : "") + g.toString(16);
        color += (b < 16 ? "0" : "") + b.toString(16);
      }
    } while (color.length !== 7);

    return color;
  };

  const getColorForUser = (userName) => {
    if (userColors[userName]) {
      return userColors[userName];
    } else {
      const color = getRandomColor();
      setUserColors({ ...userColors, [userName]: color });
      return color;
    }
  };

  useEffect(
    (e) => {
      // document.querySelector(".Pinned-Chat-Class")?.classList.remove("Pinned-Chat-Class");
      allPinnedMessageInChat?.forEach((e) => {
        focuseThePinnedChat(e?.id);
      });
    },
    [pinnedMessageInChat]
  );
  useEffect(
    (e) => {
      if (allPinnedMessageInChat) {
        setpinnedMessageInChat(
          allPinnedMessageInChat.find((e) => e?.primary == true)
        );
      } else {
        setpinnedMessageInChat(null);
      }
    },
    [allPinnedMessageInChat]
  );
  useEffect(
    (e) => {
      if (selectUser && selectUser.chat_id) {
        ChatService.GetAllPinnedChatsFromZoho(selectUser.chat_id, myProfile)
          .then((res) => {
            if (res?.data?.data) {
              setAllPinnedMessageInChat(res?.data?.data);
            } else {
              setAllPinnedMessageInChat(null);
            }
          })
          .catch((err) => {
            setAllPinnedMessageInChat(null);
            console.log(err);
          });
      } else {
        setAllPinnedMessageInChat([]);
      }
      setIsPinnedExpandOpen(false);
    },
    [selectUser]
  );

  const [timer, setTimer] = useState();
  let tempEvent;
  const getListParent = (event) => {
    if (event && event.target.classList.contains("replies")) {
      return event;
    } else {
      let e = event.target.closest(".replies");
      if (e) {
        event.target = e;
      }
    }
    return event;
  };
  const handleMouseDown = (message, event) => {
    event = getListParent(event);
    setTimer(
      setTimeout(() => {
        if (selectedChats?.length == 0) {
          event.target.classList.add("Selected-Chat");
          event.target.classList.add("first-selection");
        }
        setIsHeld(message);
      }, 1000)
    );
  };

  const handleMouseUp = (message, event) => {
    clearTimeout(timer);
    setIsHeld(null);
  };
  const onClickChat = (message, event) => {
    if (selectedChats && selectedChats.length > 0) {
      let isPresent = selectedChats.find((e) => e.id == message.id);
      if (isPresent) {
        if (
          event.currentTarget.classList.contains("Selected-Chat") &&
          !event.currentTarget.classList.contains("first-selection")
        ) {
          setselectedChats(selectedChats.filter((e) => e.id != message.id));
          event.currentTarget.classList.remove("Selected-Chat");
        } else if (event.currentTarget.classList.contains("first-selection")) {
          event.currentTarget.classList.remove("first-selection");
        } else {
          setselectedChats([...selectedChats, message]);
          event.currentTarget.classList.remove("Selected-Chat");
          event.currentTarget.classList.add("Selected-Chat");
        }
      } else {
        setselectedChats([...selectedChats, message]);
        event.currentTarget.classList.add("Selected-Chat");
      }
    }
  };

  useEffect(
    (e) => {
      if (newMessagesBuffer?.length == 0) {
        setnewMessageSectionIndex("");
        document.querySelectorAll(".New-Message-Band")?.forEach((el) => {
          el.style.display = "none";
        });
      }
    },
    [newMessagesBuffer]
  );

  function isLettestMessage(message, i) {
    let messageId = message.id;
    messageId = messageId?.replaceAll("_", "%20");
    if (newMessagesBuffer) {
      let isMessageHighlite = newMessagesBuffer.find((e) =>
        e.Notifications.Text.includes(messageId)
      );
      if (isMessageHighlite) {
        return "New-Message-Highlite H" + i;
      }
    }
    return "";
  }

  function FocusIntoView(id, flag) {
    if (flag !== "search") {
      focuseThePinnedChat(id);
    }
    let classId = `Highlight${id?.replaceAll("_", "")}`;
    if (classId) {
      let textContent = document.querySelector(`#${classId} .textContent`);
      let dom = document.getElementById(classId);
      if (dom) {
        let originalText = textContent.innerHTML;
        let searchTerm = searchInchats?.trim().toLowerCase();
        if (flag == "search") {
          let highlightedText = originalText.replace(
            new RegExp(searchTerm, "gi"),
            (match) => `<span class="highlight">${match}</span>`
          );
          textContent.innerHTML = highlightedText;
        }
        dom.scrollIntoView({
          behavior: "smooth",
        });
        dom.style.transition = "background-color 0.5s ease-in-out";
        dom.style.borderRadius = "5px";
        dom.style.backgroundColor = "#aed2ff78";
        dom.style.animation = "glowEffect 1s infinite alternate";
        setTimeout(function () {
          dom.style.backgroundColor = "";
          dom.style.animation = "none";
          textContent.innerHTML = originalText;
        }, 1000);
      }
    }
  }

  async function pinAChat(massage, replacePinned) {
    await ChatService.PinMessagesInZoho(
      selectUser.chat_id,
      massage.id,
      replacePinned
    )
      .then(async (e) => {
        ChatService.GetAllPinnedChatsFromZoho(selectUser.chat_id, myProfile)
          .then((e) => {
            if (e?.data?.data) {
              setAllPinnedMessageInChat(e?.data?.data);
            } else {
              setAllPinnedMessageInChat([]);
            }
          })
          .catch((e) => {
            setAllPinnedMessageInChat([]);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const getDateString = (date) => {
    let strTime = "";
    /// date
    if (
      new Date().toLocaleDateString() !== new Date(date).toLocaleDateString()
    ) {
      let dateTime = new Date(date);
      let str = "";
      str = str + dateTime.toLocaleString("en-us", { month: "short" });
      str = str + " " + dateTime.getDate();
      /// year
      if (new Date().getFullYear() !== dateTime.getFullYear()) {
        strTime = str + " , " + dateTime.getFullYear();
      }
    } else {
      strTime = "Today";
    }
    return strTime;
  };

  function reverseChatData(data) {
    let groupDate;
    let arr = [];
    if (selectUser && selectUser.chat_type != "chat") {
      data = data?.filter(
        (message) =>
          message.sender?.id == selectUser.zuid ||
          message.sender?.id == localStorage.getItem("ZUID")
      );
    }
    data = data?.sort((a, b) => {
      return new Date(b.time) - new Date(a.time);
    });
    for (let k = 0; k < data.length; k++) {
      let thisDate = getDateString(new Date(data[k].time));
      if (k == 0) {
        groupDate = thisDate;
      } else if (groupDate != thisDate) {
        arr.push({ heading_obj: groupDate });
        groupDate = thisDate;
      }
      arr.push(data[k]);
    }
    if (groupDate) {
      arr.push({ heading_obj: groupDate });
    }

    if (arr && newMessagesBuffer) {
      let index = -2;
      let messageId = "";
      arr.forEach((e, i) => {
        if (i == 0 && isLettestMessage(e) != "") {
          index = i;
          messageId = e.id;
        } else if (index + 1 == i && isLettestMessage(e) != "") {
          index = i;
          messageId = e.id;
        }
      });
      if (index != -2 && messageId) {
        setnewMessageSectionIndex(messageId);
      }
    }
    setChatHistory(arr);
    return arr;
  }
  if (showComponent) {
    return (
      <>
        {pinnedMessageInChat ? (
          <div className="Pinned-Message-Wrapper">
            <div
              className="Pinned-Message-Body"
              onClick={(e) => {
                FocusIntoView(pinnedMessageInChat.id);
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: convertZohoClickMessageToCRM1(
                    pinnedMessageInChat.content?.text
                  ),
                }}
              ></span>
              <div className="time-wrapper">
                <span>{getTime(new Date(pinnedMessageInChat.time))}</span>
                <span className="All-Reaction-List-Icon">
                  <FontAwesomeIcon
                    color="#018cdc"
                    className="list-icon"
                    onClick={(e) => {
                      setIsPinnedExpandOpen(!isPinnedExpandOpen);
                    }}
                    icon={faListAlt}
                  ></FontAwesomeIcon>
                  <AllPinnedMessageCollapse
                    FocusIntoView={FocusIntoView}
                    allUsers={allUsers}
                    section={section}
                    selectUser={selectUser}
                    myProfile={myProfile}
                    convertZohoClickMessageToCRM1={
                      convertZohoClickMessageToCRM1
                    }
                    getTime={getTime}
                    isPinnedExpandOpen={isPinnedExpandOpen}
                    setIsPinnedExpandOpen={setIsPinnedExpandOpen}
                    allPinnedMessageInChat={allPinnedMessageInChat}
                    setAllPinnedMessageInChat={setAllPinnedMessageInChat}
                  />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <ul
          className="chat-container-body"
          ref={chatContainerRef}
          onScroll={(e) => {
            const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

            // Check if the scrollbar is at the bottom or close to it
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10; // You can adjust the threshold as needed
            if (scrollTop >= 0) {
            }
            if (isAtBottom || -10 >= scrollTop) {
              setChatContainerRef(false);
            } else {
              setChatContainerRef(true);
            }
          }}
        >
          {chatHistory?.map((message, i) => {
            let lastMessage = false;
            let otherReplyFlag = false;
            if (selectUser.chat_type != "chat") {
              if (message.sender?.id == selectUser.zuid) {
                otherReplyFlag = true;
              }
            } else {
              if (message.sender?.id != localStorage.getItem("ZUID")) {
                otherReplyFlag = true;
              }
            }
            if (chatMessages?.length - 1 == i) {
              lastMessage = true;
            }
            let isPinned = allPinnedMessageInChat?.find(
              (e) => e.id == message.id
            );

            if (message?.Attachements && message?.Attachements?.length > 0) {
              return <li className="replies"></li>;
            } else {
            //   console.log("msg----", message);
              if (message.heading_obj) {
                return (
                  <li className="Date-Band">
                    <span>{message.heading_obj}</span>
                  </li>
                );
              } else if (message.replied_to) {
                return (
                  <>
                    <li
                      id={`Highlight${message.replied_to.id?.replace("_", "")}`}
                      className={`replies ${
                        lastMessage ? "last-message" : ""
                      } ${
                        otherReplyFlag ? "reply-chat" : "main-chat"
                      } ${isLettestMessage(message, i)}`}
                      onMouseDownCapture={(e) => {
                        handleMouseDown(message, e);
                      }}
                      onMouseUpCapture={(e) => {
                        handleMouseUp(message, e);
                      }}
                      onClick={(e) => {
                        onClickChat(message, e);
                      }}
                    >
                      {selectUser.chat_type == "chat" && (
                        <div className="Group-Profile-Photo">
                          <img
                            src={
                              message?.sender?.id
                                ? UserService.GetUserPhotoUrl(
                                    message?.sender?.id
                                  )
                                : defaultUser
                            }
                          />
                        </div>
                      )}
                      <div
                        className={`Reply-Container highlite-child-pointer ${
                          isPinned && "Pinned-Chat-Class"
                        }`}
                      >
                        {selectUser.chat_type == "chat" ? (
                          message.sender?.name != myProfile.name ? (
                            <span
                              className="display-user-name"
                              style={{
                                color: getColorForUser(message.sender?.name),
                              }}
                            >
                              {message.sender?.name}
                            </span>
                          ) : (
                            <span
                              className="display-user-name"
                              style={{ color: getColorForUser(myProfile.name) }}
                            >
                              {myProfile.name}
                            </span>
                          )
                        ) : (
                          <></>
                        )}
                        {isPinned && (
                          <div className="Pin-Icon">
                            <img src={PinChatIcon}></img>
                          </div>
                        )}
                        <HorizontalCollapseOptions
                          showByDefault={true}
                          massageId={message.id}
                          onClickReaction={(id) => {
                            setTriggerEmojiSelector(id);
                          }}
                          onClickReply={(massage) => {
                            setpinnedMassageForReply(massage);
                          }}
                          onPinnedMessage={(massage) => {
                            if (pinnedMessageInChat) {
                              pinAChat(massage, true);
                            } else {
                              pinAChat(massage, false);
                            }
                            setpinnedMessageInChatOld(pinnedMessageInChat);
                            setpinnedMessageInChat(massage);
                          }}
                          currentMessage={message}
                        />
                        <div className="Reply-Text-Wrapper">
                          <p className="Reply-Paragraph">
                            <span className="user">
                              {message.replied_to.sender.id ==
                              localStorage.getItem("ZUID")
                                ? "You:"
                                : message.replied_to.sender.name + ":"}
                            </span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertZohoClickMessageToCRM1(
                                  message.replied_to.content
                                ),
                              }}
                            ></div>
                            <span className="m-time">
                              {getTime(
                                new Date(parseInt(message.replied_to.time))
                              )}
                            </span>
                          </p>
                        </div>
                        <p className="Main-Paragraph">
                          <div className="reaction-chat-wrapper">
                            <EmojiReactionPicker
                              type="CHAT"
                              externalTriggers={triggerImojiSelector}
                              setTriggerEmojiSelector={setTriggerEmojiSelector}
                              setNotesData={setChatMessages}
                              chatId={selectUser?.chat_id}
                              notesReactions={chatHistory}
                              messageId={message.id}
                            />
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: convertZohoClickMessageToCRM1(
                                message.content?.text
                              ),
                            }}
                          ></div>
                          <span className="m-time">
                            {getTime(new Date(parseInt(message.time)))}
                          </span>
                        </p>
                      </div>
                    </li>
                    {newMessageSectionIndex &&
                      newMessageSectionIndex == message.id && (
                        <li className="Date-Band New-Message-Band">
                          <span>-- New Messages --</span>
                        </li>
                      )}
                  </>
                );
              } else {
                return (
                  <>
                    {message.content?.text || message?.type == "file" ? (
                      <>
                        <li
                          id={`Highlight${message.id?.replace("_", "")}`}
                          className={`replies ${
                            lastMessage ? "last-message" : ""
                          } ${
                            otherReplyFlag ? "reply-chat" : "main-chat"
                          } ${isLettestMessage(message, i)}`}
                          onMouseDownCapture={(e) => {
                            handleMouseDown(message, e);
                          }}
                          onMouseUpCapture={(e) => {
                            handleMouseUp(message, e);
                          }}
                          onClick={(e) => {
                            onClickChat(message, e);
                          }}
                        >
                          {selectUser.chat_type == "chat" && (
                            <div className="Group-Profile-Photo">
                              <img
                                src={
                                  message?.sender?.id
                                    ? UserService.GetUserPhotoUrl(
                                        message?.sender?.id
                                      )
                                    : defaultUser
                                }
                              />
                            </div>
                          )}
                          {/* {showAttachedIcons(message, 'file-replie-div')} */}
                          <p
                            className={`massage-body-wrapper highlite-child-pointer ${
                              isPinned && "Pinned-Chat-Class"
                            }`}
                          >
                            {isPinned && (
                              <div className="Pin-Icon">
                                <img src={PinChatIcon}></img>
                              </div>
                            )}

                            {selectUser.chat_type == "chat" ? (
                              message.sender?.id !=
                              localStorage.getItem("ZUID") ? (
                                <span
                                  className="display-user-name"
                                  style={{
                                    color: getColorForUser(
                                      message.sender?.name
                                    ),
                                  }}
                                >
                                  {message.sender?.name}
                                </span>
                              ) : (
                                <span
                                  className="display-user-name"
                                  style={{ color: getColorForUser("You") }}
                                >
                                  {"You"}
                                </span>
                              )
                            ) : (
                              <></>
                            )}
                            {message?.content?.thumbnail?.blur_data &&
                              message?.content?.file?.type.includes(
                                "image/png"
                              ) && (
                                <div
                                  className="Attachment-Wrapper-Chats"
                                  onClick={(e) => {
                                    openImagePreview(message?.content?.file);
                                  }}
                                >
                                  <img
                                    src={`data:image/png;base64,${message?.content?.thumbnail?.blur_data}`}
                                  />
                                  <div className="overlay">Preview</div>
                                </div>
                              )}
                            {message?.content?.thumbnail?.blur_data &&
                              message?.content?.file?.type.includes("pdf") && (
                                <div
                                  className="Attachment-Wrapper-Chats"
                                  onClick={(e) => {
                                    openImagePreview(message?.content?.file);
                                  }}
                                >
                                  <img
                                    src={`data:image/png;base64,${message?.content?.thumbnail?.blur_data}`}
                                  />
                                  <div className="overlay">Preview</div>
                                </div>
                              )}
                            <div className="reaction-chat-wrapper">
                              <EmojiReactionPicker
                                type="CHAT"
                                externalTriggers={triggerImojiSelector}
                                setNotesData={setChatMessages}
                                setTriggerEmojiSelector={
                                  setTriggerEmojiSelector
                                }
                                chatId={selectUser?.chat_id}
                                notesReactions={chatHistory}
                                messageId={message.id}
                              />
                            </div>
                            <div
                              className="textContent"
                              dangerouslySetInnerHTML={{
                                __html: convertZohoClickMessageToCRM1(
                                  message.content?.text
                                ),
                              }}
                            ></div>
                            <HorizontalCollapseOptions
                              showByDefault={true}
                              massageId={message.id}
                              onClickReaction={(id) => {
                                setTriggerEmojiSelector(id);
                              }}
                              onClickReply={(massage) => {
                                setpinnedMassageForReply(massage);
                              }}
                              onPinnedMessage={(massage) => {
                                if (pinnedMessageInChat) {
                                  pinAChat(massage, true);
                                } else {
                                  pinAChat(massage, false);
                                }
                                setpinnedMessageInChatOld(pinnedMessageInChat);
                                setpinnedMessageInChat(massage);
                              }}
                              currentMessage={message}
                            />
                            <span className="m-time">
                              {getTime(new Date(parseInt(message.time)))}
                            </span>
                          </p>
                        </li>
                        {newMessageSectionIndex &&
                          newMessageSectionIndex == message.id && (
                            <li className="Date-Band New-Message-Band">
                              <span>-- New Messages --</span>
                            </li>
                          )}
                      </>
                    ) : (
                      <></>
                    )}{" "}
                  </>
                );
              }
            }
          })}
        </ul>
      </>
    );
  } else {
    return <></>;
  }
};
