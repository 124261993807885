import http from "../services/http.common";

class SRSolarServices {
  post(ApiName,config) {
    return http.post(ApiName,config);
  }
    getAll(ApiName) {
      return http.post(ApiName);
    }
  
    getById(ApiName,id) {
      return http.post(`${ApiName}/${id}`);
    }

    getByBoj(ApiName,data) {
      return http.post(ApiName, data);
    }
    getByBojPut(ApiName,data) {
      return http.put(ApiName, data);
    }
  
    CreateUpdate(ApiName,data) {
      return http.post(ApiName, data);
    }
  
    update(ApiName,id, data) {
      return http.put(`${ApiName}/${id}`, data);
    }
  
    deleteByID(ApiName,id) {
      return http.delete(`${ApiName}/${id}`);
    }

    deleteByIdPatch(ApiName,data) {
      return http.patch(ApiName,data);
    }
  }
  
  export default new SRSolarServices();