
import axios from "axios";
import httpService from "../../services/http.service";
import Config from '../../config.json'
// import TaskService from "./TaskService";
const instance = axios.create({
    timeout:60000,
    headers:{
        'Connection': 'keep-alive'
    }
});
class NotificationAPI{
    api=Config.backend_url.replace("apiservice","notification")
    Trigger(data){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Create',
                method: 'post',
                url: this.api+"create",
                headers:{},
                data:data
               
            }
            httpService.post('chat', {config:config})
                .then(async(result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }

    CreateReminder(data){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Create',
                method: 'post',
                url: this.api+"reminder/create",
                headers:{},
                data:data
               
            }
            httpService.post('chat', {config:config})
                .then(async(result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }

    GetTaskReminderNotification(taskid){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Get',
                method: 'Get',
                url: this.api+`task/${taskid}/reminder`,
                headers:{}
               
            }
            httpService.post('chat', {config:config})
                .then(async(result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }

    LiveNotificationLongPollApi(notificationCount){
        return new Promise((resolve,reject)=>{
            instance.get(Config.backend_url+"long-polling?count="+notificationCount)
                .then(async(result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }

    GetAllNotifications(){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Get',
                method: 'Get',
                url: this.api,
                headers:{}
               
            }
            httpService.post('chat', {config:config})
                .then(async(result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }

    UpdateNotification(data){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Update',
                method: 'put',
                url: this.api+"update/"+data.ROWID,
                headers:{},
                data:data
               
            }
            httpService.post('chat', {config:config})
                .then(async (result) => {
                    resolve(result)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    SaveAppliedNotificationFilters(filterObj){
        return new Promise((resolve,reject)=>{
            let config = {
                type: 'POST',
                data: filterObj,
                module: "Notification"
            }
            axios.post('', {config:config})
            .then(async (result) => {
                resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
        })
    }
}


export default new NotificationAPI();