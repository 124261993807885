import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import './reccuring.css';
import config from '../../config.json'
import dayjs from 'dayjs';
import CalendarAPI from '../../common/util/CalendarAPI';
import { PopoverBody, PopoverHeader, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import UserService from '../../common/util/UserService';
import moment from 'moment';
import Loader from '../../common/loader';


const Reccuring = ({setRepeatObj , repeatObj , start,tillDate, onClose, onSave, obj, action, id, users , refreshRecurringData}) => {
    const weekdays = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    //const [repeatObj, setRepeatObj] = useState({OnDay:new Date().getDate(),OnMonth:'OnDay',EventDate:new Date(Date.now()), MonthStep: '1', MonthDay: weekdays[new Date().getDay()], DailyIntervalCount: '1', WhichMonth: `${new Date().getMonth() + 1}`, EndBy: 'Never', Repeat: 'Daily', DailyInterval: 'Every', WeeklyDay: `${dayjs().format('dddd')}` })
   
    const [selectedUser, setSelectedUser] = useState([{ role: localStorage.getItem('Role'), photo: localStorage.getItem('ProfilePhoto'), email: userinfo?.email_id, name: userinfo?.first_name + " " + userinfo?.last_name, zuid: localStorage.getItem('ZUID') }])
    const [dueDatePopUp, setDueDatePopUp] = useState(false);
    const [notifyMePopUp, setNotifyMePopUp] = useState(false);
    const [closeAssigneePopup, setCloseAssigneePopup] = useState(false);
    const [loading, setLoading] = useState(false);


    console.log("start", start);
    console.log("obj", obj);
    
    useEffect(() => {
        if ((action == 'edit') || (action == 'editview')) {
            setLoading(true)
            GetAndSetEventDetal(obj.uid)
                .then((result) => {
                    let rObj = {...repeatObj}
                    result = result.filter(one => one.rrule);
                    result = result[0]
                    // if(result.rrule){
                    //     let rr = result.rrule.split(";")?.find(str => str.includes("UNTIL"))
                    //     rr = rr?.replace("UNTIL=",'');
                    //     if(rr){
                    //       let dateTime = moment(rr).format("YYYY-MM-DD");
                    //       result.EndByValue = dateTime;
                    //     }
                    //   }
                    result = convertEventToReaptObj(result?.rrule)
                    console.log(result);
                    setRepeatObj(result);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                })
        }
    }, [action, refreshRecurringData])

    useEffect(()=>{
        if(start){
            const obj={...repeatObj};
            let date=new Date(start);
            obj.WeeklyDay=weekdays[date.getDay()]
            obj.OnDay=date.getDate();
            setRepeatObj(obj);
        }
    },[start])

    const GetAndSetEventDetal = (uid) => {
        return new Promise(async (resolve, reject) => {
            let res = await CalendarAPI.GetAllCalendars()
            CalendarAPI.GetEventDetail(res.uid, uid)
                .then((result) => {
                    resolve(result)
                })
                .catch((err) => {
                    reject(err);
                })
        })

    }
    // const weekDays=['MO',"TU","WE","TH","FR","SA","SU"]

    const convertWeeklyDayTOShortDay = (days) => {
        weekdays.forEach((one) => {
            if (days.includes(one.slice(0, 2).toUpperCase())) {
                days=days.replaceAll(one.slice(0, 2).toUpperCase(), one);
            }
        })
        return days;
    }


    const convertEventToReaptObj = (rrule) => {
        let obj = {...repeatObj}
        if(start){
            let date=new Date(start);
            obj.OnDay=date.getDate();
        }

        if (rrule?.includes('DAILY')) {
            let rule = rrule.split(';')
            obj.Repeat = 'Daily'
            obj.DailyInterval = 'Every'
            rule.forEach((one, idx) => {
                if (one.includes('INTERVAL')) {
                    one = one.split('=')
                    obj.DailyIntervalCount = (one[1])
                }
                else if (one.includes('BYDAY')) {
                    one = one.split('=')
                    obj.DailyInterval = 'WeekDay'
                }
                else if (one.includes('UNTIL')) {
                    one = one.split('=')
                    obj.EndBy = 'ByDate'
                    obj.EndByValue = moment((one[1])).format("YYYY-MM-DD");
                }
                else if (one.includes('COUNT')) {
                    obj.EndBy = 'ByCount'
                    one = one.split('=')
                    obj.EndByValue = (one[1])
                } else {
                    if (idx == rule.length - 1) {
                        obj.EndBy = "Never"
                    }
                }
            })
        }
        if (rrule?.includes('WEEKLY')) {
            let rule = rrule.split(';')
            obj.Repeat = 'Weekly';
            rule.forEach((one, idx) => {
                if (one.includes('INTERVAL')) {
                    one = one.split('=')
                    obj.DailyIntervalCount = (one[1])
                }
                else if (one.includes('UNTIL')) {
                    one = one.split('=')
                    obj.EndBy = 'ByDate'
                    obj.EndByValue = moment((one[1])).format("YYYY-MM-DD");
                }
                else if (one.includes('BYDAY')) {
                    one = one.split('=')
                    obj.WeeklyDay = convertWeeklyDayTOShortDay(one[1])
                }
                else if (one.includes('COUNT')) {
                    obj.EndBy = 'ByCount'
                    one = one.split('=')
                    obj.EndByValue = (one[1])
                } else {
                    if (idx == rule.length - 1) {
                        obj.EndBy = "Never"
                    }
                }
            })
        }
        if (rrule?.includes('MONTHLY')) {
            let rule = rrule.split(';');
            obj.Repeat = 'Monthly';
            rule.forEach((one, idx) => {
                if (one.includes('INTERVAL')) {
                    one = one.split('=')

                    obj.DailyIntervalCount = (one[1])
                }
                else if (one.includes('BYMONTHDAY')) {
                    obj.OnMonth = 'OnDay'
                    one = one.split('=')
                    obj.OnDay = (one[1])
                }
                else if (one.includes('UNTIL')) {
                    one = one.split('=')
                    obj.EndBy = 'ByDate'
                    obj.EndByValue = moment((one[1])).format("YYYY-MM-DD");
                }
                else if (one.includes('BYDAY')) {
                    obj.OnMonth = 'OnThe'
                    one = one.split('=')
                    obj.MonthDay = convertWeeklyDayTOShortDay(one[1])
                }
                else if (one.includes('BYSETPOS')) {

                    one = one.split('=')
                    obj.MonthStep = one[1]
                }
                else if (one.includes('COUNT')) {
                    obj.EndBy = 'ByCount'
                    one = one.split('=')
                    obj.EndByValue = (one[1])
                } else {
                    if (idx == rule.length - 1) {
                        obj.EndBy = "Never"
                    }
                }
            })
        }
        if (rrule?.includes('YEARLY')) {
            let rule = rrule.split(';');
            obj.Repeat = 'Yearly';
            rule.forEach((one, idx) => {
                if (one.includes('INTERVAL')) {
                    one = one.split('=')

                    obj.DailyIntervalCount = (one[1])
                }
                else if (one.includes('BYMONTHDAY')) {
                    obj.OnMonth = 'OnDay'
                    one = one.split('=')
                    obj.OnDay = (one[1])
                }
                else if (one.includes('UNTIL')) {
                    one = one.split('=')
                    obj.EndBy = 'ByDate'
                    obj.EndByValue = moment((one[1])).format("YYYY-MM-DD");
                }
                else if (one.includes('BYDAY')) {
                    obj.OnMonth = 'OnThe'
                    one = one.split('=')
                    obj.MonthDay = convertWeeklyDayTOShortDay(one[1])
                }
                else if (one.includes('BYSETPOS')) {

                    one = one.split('=')
                    obj.MonthStep = one[1]
                }
                else if (one.includes('BYMONTH')) {
                    one = one.split('=')
                    obj.WhichMonth = one[1]
                }
                else if (one.includes('COUNT')) {
                    obj.EndBy = 'ByCount'
                    one = one.split('=')
                    obj.EndByValue = (one[1])
                } else {
                    if (idx == rule.length - 1) {
                        obj.EndBy = "Never"
                    }
                }
            })
        }

        if (obj.EndBy == 'ByDate') {
            let date = dayjs(obj.EndByValue).format('YYYY-MM-DD');
            obj.EndByValue = date;
        }

        return obj
    }

    const handleOnChange = (e) => {
        if(e.currentTarget.value < 1 && e.currentTarget.name != "MonthStep"){
            e.preventDefault();
            return
        }
        const obj = { ...repeatObj };
        obj[e.currentTarget.name] = e.currentTarget.value;
        setRepeatObj(obj);
    }

    const getDailyContent = () => {
        return <>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <input type='radio' disabled={action == "editview" ? true : false} checked={repeatObj.DailyInterval == 'Every'} value={'Every'} onChange={handleOnChange} name='DailyInterval' />&nbsp;&nbsp;<span>Every</span>
                            </div>
                            <div className="col-4 pl-0">
                                <input name='DailyIntervalCount' disabled={repeatObj.DailyInterval == 'Every' && action != "editview" ? false:true} value={repeatObj.DailyIntervalCount} onChange={handleOnChange} className="form-control  form-control-sm" type='text' />
                            </div>
                            <div className="col-4 p-0  d-flex align-items-center">
                                <span className='w-100'>day(s) once</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <input type='radio' checked={repeatObj.DailyInterval == 'WeekDay'}
                        disabled={action == "editview" ? true : false}
                        onChange={(e) => {

                            handleOnChange(e)
                        }} value={'WeekDay'} name='DailyInterval' />&nbsp;&nbsp;<span>Weekday only</span>
                    </div>

                </div>

            </div>
            {/* <div className='form-group'>
                <div className='row'>
                    <div className='col'>
                        <input type='radio' checked={repeatObj.DailyInterval == 'WeekDay'} onChange={(e) => {

                            handleOnChange(e)
                        }} value={'WeekDay'} name='DailyInterval' />&nbsp;&nbsp;<span>Weekday only</span>
                    </div>
                </div>

            </div> */}
        </>
    }

    const getWeeklyContent = () => {
        return <>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-6 '>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                {/* <input type='radio' checked={repeatObj.DailyInterval=='Every'} value={'Every'} onChange={handleOnChange} name='DailyInterval' />&nbsp;&nbsp; */}
                                <span>Every</span>
                            </div>
                            <div className="col-4 pl-0">
                                <input disabled={action == "editview" ? true : false} name='DailyIntervalCount' onChange={handleOnChange} className="form-control  form-control-sm" type='text' />
                            </div>
                            <div className="col-4 p-0 d-flex align-items-center">
                                <span className='w-100'>week(s) once</span>
                            </div>
                        </div>

                    </div>
                    <div className='col'>

                    </div>

                </div>

            </div>
            <div className='form-group'>
                <div className='row'>
                    <div className='col weekly_tabs'>
                        {weekdays.map(day => {
                            return <span onClick={(e) => {
                                if(action == "editview"){
                                    e.preventDefault();
                                    return
                                }
                                let obj = { ...repeatObj };
                                if (obj.WeeklyDay.includes(day)) {
                                    obj.WeeklyDay = obj.WeeklyDay.replaceAll(day+',', '');
                                    obj.WeeklyDay = obj.WeeklyDay.replaceAll(day, '');
                                } else {
                                    obj.WeeklyDay = obj.WeeklyDay + ',' + day;
                                }
                                setRepeatObj(obj);
                            }} style={repeatObj.WeeklyDay && repeatObj.WeeklyDay.includes(day) ? { borderColor: '#007bff', backgroundColor: '#007bff', color: 'white' } : {}}>{day.charAt(0)}</span>
                        })}
                    </div>
                </div>

            </div>
        </>
    }

    const getMonthlyContent = () => {
        return <>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-6 '>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <span>Every</span>
                            </div>
                            <div className="col-4 pl-0">
                                <input disabled={action == "editview" ? true : false} value={repeatObj.DailyIntervalCount?repeatObj.DailyIntervalCount:''} name='DailyIntervalCount' onChange={handleOnChange} className="form-control  form-control-sm" type='text' />
                            </div>
                            <div className="col-4 p-0 d-flex align-items-center">
                                <span className='w-100'>month(s) once</span>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                    </div>
                </div>
            </div>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-8'>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <input type='radio' checked={repeatObj.OnMonth == 'OnDay'} onChange={(e)=>{
                                    if(action == "editview"){
                                        return
                                    }
                                    handleOnChange(e)
                                    }} value={'OnDay'} name='OnMonth' />&nbsp;&nbsp;<span>On day</span>
                            </div>
                            <div className="col pl-1">
                                <input value={repeatObj.OnDay?repeatObj.OnDay:''} disabled={!(repeatObj.OnMonth == 'OnDay') || (action == "editview")} name='OnDay' onChange={handleOnChange} className="form-control  form-control-sm" type='text' />
                            </div>
                        </div>

                    </div>
                    <div className='col'>

                    </div>

                </div>

            </div>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-8'>
                        <div className="row">
                            <div className="col-4 d-flex align-items-center">
                                <input type='radio' checked={repeatObj.OnMonth == 'OnThe'} onChange={(e)=>{
                                    if(action == "editview"){
                                        return
                                    }
                                    handleOnChange(e)
                                    }} value={'OnThe'} name='OnMonth' />&nbsp;&nbsp;<span>On the</span>
                            </div>
                            <div className="col-4 pl-1">
                                <select value={repeatObj.MonthStep?repeatObj.MonthStep:''} name='MonthStep' disabled={!(repeatObj.OnMonth == 'OnThe') || (action == "editview")} onChange={handleOnChange} className='form-control form-control-sm'>
                                    <option value={'1'}>First</option>
                                    <option value={'2'}>Second</option>
                                    <option value={'3'}>Third</option>
                                    <option value={'4'}>Fourth</option>
                                    <option value={'-1'}>Last</option>
                                </select>
                            </div>
                            <div className="col-4 pl-1">
                                <select value={repeatObj.MonthDay?repeatObj.MonthDay:''} name='MonthDay' disabled={!(repeatObj.OnMonth == 'OnThe') || (action == "editview")} onChange={handleOnChange} className='form-control form-control-sm'>
                                    {weekdays.map((day) => {
                                        return <option value={day}>{day}</option>
                                    })}
                                </select>
                            </div>
                        </div>



                    </div>
                </div>

            </div>

        </>
    }

    const getYearlyContent = () => {
      return (
        <>
          <div className="form-group">
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-4 d-flex align-items-center">
                    <span>Every</span>
                  </div>
                  <div className="col pl-0">
                    <select
                      name="WhichMonth"
                      disabled={action == "editview"}
                      value={repeatObj.WhichMonth ? repeatObj.WhichMonth : ""}
                      onChange={handleOnChange}
                      className="form-control form-control-sm"
                    >
                      {config.month.map((month) => {
                        return (
                          <option value={month.number}>{month.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="col-4 d-flex align-items-center">
                    <input
                      type="radio"
                      checked={repeatObj.OnMonth == "OnDay"}
                      onChange={(e) => {
                        if (action == "editview") {
                          return;
                        }
                        handleOnChange(e);
                      }}
                      value={"OnDay"}
                      name="OnMonth"
                    />
                    &nbsp;&nbsp;<span>On day</span>
                  </div>
                  <div className="col pl-1">
                    <input
                      disabled={
                        !(repeatObj.OnMonth == "OnDay") || action == "editview"
                      }
                      value={repeatObj.OnDay ? repeatObj.OnDay : ""}
                      name="OnDay"
                      onChange={handleOnChange}
                      className="form-control  form-control-sm"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-8 ">
                <div className="row">
                  <div className="col-4 d-flex align-items-center">
                    <input
                      type="radio"
                      checked={repeatObj.OnMonth == "OnThe"}
                      onChange={(e) => {
                        if (action == "editview") {
                          return;
                        }
                        handleOnChange(e);
                      }}
                      value={"OnThe"}
                      name="OnMonth"
                    />
                    &nbsp;&nbsp;<span>On the</span>
                  </div>
                  <div className="col-4 pl-1">
                    <select
                      value={repeatObj.MonthStep ? repeatObj.MonthStep : ""}
                      name="MonthStep"
                      disabled={
                        !(repeatObj.OnMonth == "OnThe") || action == "editview"
                      }
                      onChange={handleOnChange}
                      className="form-control form-control-sm"
                    >
                      <option value={"1"}>First</option>
                      <option value={"2"}>Second</option>
                      <option value={"3"}>Third</option>
                      <option value={"4"}>Fourth</option>
                      <option value={"-1"}>Last</option>
                    </select>
                  </div>
                  <div className="col-4 pl-1">
                    <select
                      value={repeatObj.MonthDay ? repeatObj.MonthDay : ""}
                      name="MonthDay"
                      disabled={
                        !(repeatObj.OnMonth == "OnThe") || action == "editview"
                      }
                      onChange={handleOnChange}
                      className="form-control form-control-sm"
                    >
                      {weekdays.map((day) => {
                        return <option value={day}>{day}</option>;
                      })}
                      {/* <option>Monday</option>
                                    <option>Tuesday</option>
                                    <option>Wenesday</option>
                                    <option>Thursday</option>
                                    <option>Friday</option>
                                    <option>Saturday</option>
                                    <option>Sunday</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    const getContentByRpeat = (type) => {
        switch (type) {
            case 'Daily':
                return getDailyContent()
            case 'Weekly':
                return getWeeklyContent()
            case 'Monthly':
                return getMonthlyContent()
            case 'Yearly':
                return getYearlyContent()
            default:
                return <></>
        }
    }

    const steps = { 1: 'First', '2': 'Second', '3': 'Third', '4': 'Fourth', '-1': 'Last' }

    const getPlaceholderText = () => {
        let text = ''
        if (repeatObj.Repeat == 'Daily') {
            if (repeatObj.DailyInterval == 'Every') {
                text = `Every ${repeatObj.DailyIntervalCount} day `
            }
            if (repeatObj.DailyInterval == 'WeekDay') {
                text = `Every Weekday `
            }

        }
        if (repeatObj.Repeat == 'Weekly') {
            text = `Every ${repeatObj.DailyIntervalCount} Week on ${repeatObj.WeeklyDay} `
        }
        if (repeatObj.Repeat == 'Monthly') {
            if (repeatObj.OnMonth == 'OnDay') {
                text = `Every ${repeatObj.DailyIntervalCount} month on day ${repeatObj.OnDay} `
            }
            if (repeatObj.OnMonth == 'OnThe') {
                text = `Every ${repeatObj.DailyIntervalCount} month on  ${steps[repeatObj.MonthStep]}  ${repeatObj.MonthDay} `
            }

        }
        if (repeatObj.Repeat == 'Yearly' ) {
            if (repeatObj.OnMonth == 'OnDay') {
                let whichMonth=''
                let monthFilter=config.month.filter((one)=>one.number==repeatObj.WhichMonth);
                if(monthFilter.length>0){
                    whichMonth=monthFilter[0].abbreviation
                }
                text = `Every ${whichMonth} on day ${repeatObj.OnDay} `
            }
            if (repeatObj.OnMonth == 'OnThe') {
                text = `Every year on   ${steps[repeatObj.MonthStep]}  ${repeatObj.MonthDay}  of  ${config.month.some(one => one.number == repeatObj.WhichMonth) ? config.month.filter(one => one.number == repeatObj.WhichMonth)[0].name : " "} `
            }

        }
        if (repeatObj.EndBy == 'ByCount') {
            text = text + `For ${repeatObj.EndByValue} times `
        }
        if (repeatObj.EndBy == 'ByDate') {
            text = text + `Until ${dayjs(repeatObj.EndByValue).format('MMM DD YYYY')}`
        }

        return text;
    }

    const removeAssignee = (user) => {
        let selected = [...selectedUser]
        selected = selected.filter(obj => obj.zuid !== user.zuid);
        setSelectedUser(selected);
    }

    const fetchPhotoFromLocalstorage = (photo, name, user) => {
        if (photo === null || photo === "") {
            return (
                <div className='user-img-icon common_reminder_box'>
                    {selectedUser.length > 1 ? <FontAwesomeIcon icon={faTimes} onClick={() => removeAssignee(user)} className='remove-user' /> : <></>}
                    <div className='no-image-user'
                        type="button" id="reminder-pop-user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        onClick={() => {
                            setDueDatePopUp(false);
                            setNotifyMePopUp(false);
                            setCloseAssigneePopup(!closeAssigneePopup);
                        }}
                    >{name ? name.charAt(0) : ''}</div>
                </div>
            )
        }


        else {
            return (
                <div className='user-img-icon common_reminder_box'>
                    {selectedUser.length > 1 ? <FontAwesomeIcon icon={faTimes} onClick={() => removeAssignee(user)} className='remove-user' /> : <></>}
                    <img src={photo} class="user-img btn btn-secondary dropdown-toggle" type="button" id="reminder-pop-user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        onClick={() => {
                            setDueDatePopUp(false);
                            setNotifyMePopUp(false);
                            setCloseAssigneePopup(!closeAssigneePopup);
                        }}
                    >
                    </img>
                </div>


            )
        }
    }

    const showPhotoOfUser = (user) => {

        if (user.zuid === null || user.zuid === "") {
            return (
                <div
                    className='no-image-user'
                >{user.first_name ? user.first_name.charAt(0) : ''}</div>)
        }
        else {
            return (<img src={UserService.GetUserPhotoUrl(user.zuid)} className="user-ico" />)
        }
    }
   
    function disabledDates(current) {
        let date=dayjs(tillDate)
        return current && (current.isBefore(date) || current.isSame(date));
    }
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < dayjs().add(-1, 'day').endOf('day');
    }

    return (<>
        {loading ? <Loader /> : <></>}
        <div className='w-100 text-left'>
            <h6 style={{
                "margin-top": "0",
                "margin-bottom": "0.5em",
                "font-weight": "600",
                "color": "#767676",
            }}>Recurring Event</h6>
            <div className='form-group'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="row">
                            <div className="col-4">
                                <span>Repeat</span>
                            </div>
                            <div className="col pl-0">
                                <select disabled={action == "editview" ? true : false} value={repeatObj.Repeat} onChange={(e) => {
                                    let obj = { ...repeatObj }
                                    if (e.currentTarget.value == 'Weekly') {
                                        obj.WeeklyDay = `${dayjs().format('dddd')}`;
                                    }
                                    obj.Repeat = e.currentTarget.value;
                                    setRepeatObj(obj);
                                }} className='form-control form-control-sm'>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Yearly">Yearly</option>
                                </select>
                            </div>
                        </div>



                    </div>
                    <div className='col'>

                    </div>

                </div>

            </div>
            {getContentByRpeat(repeatObj.Repeat)}
            <div className='form-group'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="row">
                            <div className="col-4">
                                <span className='mr-2'>Ends</span>
                            </div>
                            <div className="col pl-1">

                                <select disabled={action == "editview" ? true : false} value={repeatObj.EndBy} onChange={(e) => {
                                    const obj = { ...repeatObj };
                                     
                                    obj.EndBy = e.currentTarget.value;
                                    if(e.currentTarget.value =="ByDate" ){ 
                                        obj.EndByValue = dayjs(tillDate).add(1,"months").format('YYYY-MM-DD');
                                    }else if(e.currentTarget.value =="ByCount" ){
                                        obj.EndByValue = 1;
                                    }
                                    setRepeatObj(obj);
                                }} className='form-control form-control-sm'>
                                    <option value={'Never'}>Never</option>
                                    <option value={'ByDate'}>By Date</option>
                                    <option value={"ByCount"}>By Count</option>
                                </select>
                            </div>

                        </div>

                    </div>
                    <div className='col-3 p-0'>
                        {repeatObj.EndBy == "ByCount" ? <input disabled={action == "editview" ? true : false} value={repeatObj.EndByValue} name='EndByValue' onChange={handleOnChange} type='number' className='form-control form-control-sm' /> :
                            repeatObj.EndBy == "ByDate" ? 
                            <DatePicker
                                name='EndByValue'
                                disabled={action == "editview" ? true : false}
                                format="MM-DD-YYYY"
                                onChange={(date, dateString) => {
                                    handleOnChange({currentTarget:{
                                        name:"EndByValue",
                                        value:dateString
                                    }})
                                }}
                                value={dayjs(repeatObj.EndByValue)}
                                disabledDate={disabledDates}
                            />
                            // <input disabled={action == "editview" ? true : false} value={repeatObj.EndByValue} type='date' name='EndByValue' onChange={handleOnChange} className='form-control form-control-sm' /> 
                            :
                                <></>
                        }
                    </div>
                </div>

            </div>
            <div className='form-group'>
                <div className='row'>
                    <div className='col d-flex align-items-center'>
                        <input style={{ backgroundColor: '#f1cfc5' }} type='text' placeholder={getPlaceholderText()} disabled className='form-control form-control-sm' />
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between'>
               {id=='Reminder' && <div style={id!=='Reminder'?{visibility:'hidden'}:{}} className='d-flex'>
                    <div className='reminder-footerIcon reminder-assignees'>
                        {selectedUser.map((user) => {
                            return fetchPhotoFromLocalstorage(user.photo, user.name, user)
                        })}
                        <UncontrolledPopover
                            isOpen={closeAssigneePopup}
                            // toggle={closeAssigneePopup}
                            trigger="legacy"
                            placement="bottom"
                            target="reminder-pop-user"
                            className='reminderUserDropdown'
                        >
                            <PopoverHeader>
                                <input type='text' name='SearchActivityUser' placeholder='search user...' className='form-control form-control-sm' />
                            </PopoverHeader>
                            <PopoverBody
                                className='activity-filters'
                            >
                                {
                                    users && users.map((user) => {
                                        return <div onClick={() => {
                                            const selected = [...selectedUser];
                                            if (selectedUser.filter(usr => usr.zuid == user.zuid).length == 0) {
                                                let obj = {}
                                                obj.photo = UserService.GetUserPhotoUrl(user.zuid);
                                                obj.name = user.full_name;
                                                obj.zuid = user.zuid;
                                                obj.role = user.Role;
                                                obj.email = user.email;
                                                selected.push(obj);
                                                setSelectedUser(selected);
                                            }

                                        }} className='row m-0 cursor-pointer'>
                                            <div
                                                className='row align-items-center my-1'
                                                onClick={() => setCloseAssigneePopup(!closeAssigneePopup)}
                                            >
                                                <div className='col-sm-4'>
                                                    {showPhotoOfUser(user)}
                                                </div>
                                                <div className='col-sm-8 filterName call-log-contacts-text'>
                                                    {user.full_name}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }

                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                    <div className='reminder-footerIcon d-flex align-items-center'>
                        <UncontrolledTooltip target={'datePicker-reminder-due-date'} placeholder='top'>
                            Due Date
                        </UncontrolledTooltip>
                        <div id='datePicker-reminder-due-date'>
                            <DatePicker
                                // dis
                                // renderExtraFooter={() => <><button onClick={() => setDueDatePopUp(false)} className='mt-2 btn btn-sm btn-primary float-right'>Close</button> </>}
                                placeholder='Event Date'
                                format="YYYY-MM-DD HH:mm"
                                onChange={(date, dateString) => {  
                                    const obj={...repeatObj};
                                    obj.EventDate=new Date(date.$d)
                                    setRepeatObj(obj); 
                                }}
                                 value={dayjs(repeatObj.EventDate)}
                                disabledDate={disabledDate}

                                // disabledTime={true}
                                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm'), use12Hours: true }}
                            />
                        </div>
                    </div>
                </div>}
                {/* <div>
                    <button onClick={() => {
                        onSave(repeatObj)
                        }} className='btn btn-sm btn-primary mr-2'>Save</button>
                    <button onClick={() => {
                        onClose()
                    }} className='btn btn-sm btn-secondary'>Cancel</button>
                </div> */}

            </div>
        </div>
        </>);

}

export default Reccuring;