import React, { useEffect, useRef } from 'react';

function RenderActionButtons({id,actionListCellRenderer,data,email,empty}) {
  const btnDivRef = useRef(null);

  useEffect(() => {
    const btnDiv = actionListCellRenderer(data,email,{isEmpty:"dayView"})
    btnDivRef?.current.appendChild(btnDiv);
  }, []);

  return (data && <div id={id} ref={btnDivRef}></div>);
}

export default RenderActionButtons;
