import React, { useState,useEffect } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const AddNYCFilling=(props)=> {
  const [buttonState, setbuttonState] = useState(false);
  const [skipNycFilling, setSkipNycFilling] = useState(false);
  const [validation,setValidation]=useState({
    appNo:'0'
  })

  const buttontoggle= () =>{
    props.settoggleState('addnyc');
    props.setListState('addnyc');
    setbuttonState(!buttonState);
  } 

  useEffect(() => {
    props.toggleState =='addnyc'?
    setbuttonState(true):setbuttonState(false);
  }, [props.toggleState]);
  
  useEffect(() => {
    getData()
  },[props.data]);


  const getData=()=>{
    if(props.data!=null){
       
       props.setConjunction(props.data.Conjunction);
       props.setStopWork(props.data.StopWork);
       props.setWithoutpermit(props.data.withoutpermit);
       props.setPartial(props.data.partial);
       props.setLblConjunction(props.data.lblConjunction);

      }
  }

  const[validApplicationNo,setValidApplicationNo]=useState(true);
  const validationCss ={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const handleNYCFillingCheck =() =>{
    if(skipNycFilling){
      props.setAddFillingComplete.additionalNyc=true;
      return true;
    }
    
   if(props.Conjunction==="Yes"){
     if(props.lblConjunction!=="" && props.lblConjunction!==null && props.StopWork!=="" && props.withoutpermit!==null){
        props.setAddFillingComplete.additionalNyc=true;
        return true;
     }else{
      return false;
     }
   }
   if(props.Conjunction==="No"){
      if(props.StopWork!=="" && props.withoutpermit!==null){
        props.setAddFillingComplete.additionalNyc=true;
        return true;
      }
   } 
   props.setAddFillingComplete.additionalNyc=false;
   return false;
  }

    return (
        <Col sm={12}>
        <Button
          onClick={buttontoggle}
          className={buttonState ? "btn-100-active":"btn-100"}
        >
           Additional NYC Filing Information 
           <div className="float-right">
           <Input type="checkbox"
           className={handleNYCFillingCheck() ? "rightbutton-checkbox-active" : "rightbutton-checkbox"} 
           checked={handleNYCFillingCheck()}
           onChange={()=>setSkipNycFilling(!skipNycFilling)}/>
          {buttonState
          ? <FontAwesomeIcon className="font-centered" icon={faAngleDown}/>
          :<FontAwesomeIcon className="font-centered" icon={faAngleRight}/>}
          </div>
        </Button>
        <Collapse isOpen={buttonState}>
          <Card className="createdeal-card">
            <CardBody>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" for="conjunction">
                  Must be Filed in Conjunction<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input type="radio" name="Conjunction"  value="Yes"
                     checked={props.data? props.data.Conjunction === "Yes":false}
                    onChange={(e) =>{props.handleOnChangeDeal(e); props.setConjunction(e.target.value)}}/>
                    </Col>

                    <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input type="radio" name="Conjunction"  value="No"
                    checked={props.data? props.data.Conjunction === "No":false}
                    onChange={(e) =>{props.handleOnChangeDeal(e); props.setConjunction(e.target.value)}}/>
                    </Col>
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
                <FormGroup row className={
                    props.Conjunction == "Yes"
                    ? "marb-16 padtop-15"
                    : "conjunction-none"
                  }>
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" >
                    Application Number
                    <span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={2}>
                  <Input
                  className="createdeal-textfield"
                    type="text"
                    name="lblConjunction"
                    id="projectnumber"
                    style={validation.appNo=='-1'?{...validationCss}:{}}
                    value={props.data?props.data.lblConjunction:''}
                    onChange={(e)=>{
                      e.target.value=e.target.value.replace(/\D/g, '');
                      if(e.target.value.length==9){
                        setValidation({...validation,appNo:'1'})
                      }
                      props.handleOnChangeDeal(e);
                      props.setLblConjunction(e.target.value)}
                    }
                    onBlur={(e)=>{
                      if(props.data?.lblConjunction?.length<9||e.target.value==undefined||e.target.value==''){
                        setValidation({...validation,appNo:'-1'})
                        setValidApplicationNo(false)
                      }else{
                        setValidation({...validation,appNo:'1'})
                        setValidApplicationNo(true)
                      }
                    }}
                    maxLength={9}
                  />
                </Col>
                <Col sm={4}>
                  <span
                    className={
                     validation.appNo=='-1' ? "red-text font12" : "error-display"
                    }
                  >
                    {" "}
                    Enter 9 digit Application number.
                  </span>
                </Col>
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
                <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" for="stopwork">
                  Stop Work Order Exists on Property<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input type="radio" name="StopWork"  value="Yes"
                    checked={props.data?props.data.StopWork === "Yes":false}
                    onChange={(e) => {props.handleOnChangeDeal(e);props.setStopWork(e.target.value)}}/>
                    </Col>

                    <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input type="radio" name="StopWork"  value="No"
                    checked={props.data?props.data.StopWork === "No":false}
                    onChange={(e) => {props.handleOnChangeDeal(e); props.setStopWork(e.target.value)}}/>
                    </Col>
                <Col sm={6}></Col>
                </Row>
                </FormGroup>
                <FormGroup row className={
                    props.StopWork == "Yes"
                    ? "marb-16 padtop-15"
                    : "partial-none"
                  }>
              <Row>
                  <Col sm={4}>
                  <Label className="text-bold2" style={{fontWeight : "bold"}} for="conjunction" >
                    Partial
                  </Label>
                  </Col>
                  <Col sm={1}><Input type="checkbox" name="partial"
                  style={{marginLeft : "0px"}}
                  value={props.data? props.data.partial:false}
                  checked={props.data? props.data.partial:false}
                  onChange={(e)=>{props.handleOnChangeDeal(e);props.setPartial(e.target.checked)}} /></Col>
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
                <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" for="withoutpermit">
                  Active Work Without Permit Violations<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input type="radio" name="withoutpermit" value="Yes"
                   checked={props.data?props.data.withoutpermit === "Yes":false}
                    onChange={(e) =>{props.handleOnChangeDeal(e); props.setWithoutpermit(e.target.value)}}/>
                    </Col>

                    <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input type="radio" name="withoutpermit" value="No"
                    checked={props.data?props.data.withoutpermit === "No":false}
                    disabled={props.sidebarCardsStatus.isViolationActive}
                    onChange={(e) =>{props.handleOnChangeDeal(e); 
                    props.setWithoutpermit(e.target.value)}}/>
                    </Col>
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
            </CardBody>
          </Card>
        </Collapse>
      </Col>
    );
}

export default AddNYCFilling;