import React, { useState , useEffect} from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import swal from "sweetalert";
import Config from '../../../../../../config.json'
import axios from "axios";

const Asbestos=(props)=> {

  const wrappNameCss={
    "display": "block",
    "white-space": "nowrap",
    "width": "11em",
    "overflow": "hidden",
    "text-overflow": "ellipsis",
    "cursor":"default"
  }
        
  const [buttonState, setbuttonState] = useState(false);
  const [skipAsbestos, setSkipAsbestos] = useState();

  const buttontoggle= () =>{
    props.settoggleState('asbestos');
    props.setListState('asbestos');
    setbuttonState(!buttonState);
  } 

  useEffect(() => {
    props.toggleState =='asbestos'?
    setbuttonState(true):setbuttonState(false);
  }, [props.toggleState])

  useEffect(() => {
    getData()
  },[props.data]);

  const[isAsbestosNo,setIsAsbestosNo]=useState(true);
  const[isAcp5No,setIsAcp5No]=useState(true);

  const[validation,setValidation]=useState({
    nbApplication:'0',
    existingACP5InspCertNumber:'0',
    existingACP5ControlNumber:'0'
  })
  const validationCss ={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const getData=()=>{
     
    if(props.data!=null){
       props.setAsbestosReq(props.data.asbestosReq);
       props.setACPcompleted(props.data.existingACP5);
       props.setCertificateoccupancy(props.data.certificateoccupancy);
       props.setNbApplication(props.data.nbApplication);
       props.setCertificateNum(props.data.existingACP5InspCertNumber);
       props.setAcpControl(props.data.existingACP5ControlNumber);
      }
  }

  const handleAsbestosCheck=()=>{
  if(skipAsbestos){
      props.setAsbestosComplete.asbestos = true;
      return true;
    }
    if(props.asbestosReq==""){
      return false;
    }
    if((props.nbApplication!=="" && props.nbApplication!== undefined && props.nbApplication!== null) && props.certificateoccupancy ==="No"  && props.asbestosReq==="No"){
      props.setAsbestosComplete.asbestos = true;
      return true;
    }

    if(props.asbestosReq==="No" && props.certificateoccupancy ==="Yes" && props.sidebarCardsStatus.AsbestosCertificate !== null){
      props.setAsbestosComplete.asbestos = true;
      return true;
    }
    if(props.asbestosReq==="Yes" && props.acpcompleted=="No"){
      props.setAsbestosComplete.asbestos = true;
      return true;
    }
    
    if(props.asbestosReq==="Yes" && props.acpcompleted=="Yes" && (props.asCertificateNum!=="" && props.asCertificateNum!==undefined && props.AcpControl!=="" && props.AcpControl!==undefined && props.sidebarCardsStatus.ACP5 !== null)) {
      props.setAsbestosComplete.asbestos = true;
      return true;
    }
    props.setAsbestosComplete.asbestos=false;
    return false;
  }
  const obj={
    nbApplication:props.data.nbApplication,
    existingACP5ControlNumber: props.data.existingACP5ControlNumber,
    existingACP5InspCertNumber:props.data.existingACP5InspCertNumber
  }
  // clear file code
  const clearFileFromZohoFiles=async(data)=>{
    if(data.fileId){
      let name=data.filename.replace(".PDF",'')+'ARCHIVE.PDF'
      await axios.post(Config.backend_url+'archiveFile',
      {
        file_id:data.fileId,
        file_name:name,
        accessToken:localStorage.getItem('token')
      })
    }
  }
  //end
    return (
        <Col sm={12}>
        <Button
          onClick={buttontoggle}
          className={buttonState ? "btn-100-active":"btn-100"}
        >
           Asbestos Investigation
           <div className="float-right">
           <Input type="checkbox" 
            className={handleAsbestosCheck() ? "rightbutton-checkbox-active" : "rightbutton-checkbox"}
           checked={handleAsbestosCheck()}
           onChange={(e)=>{
            let vKeys=Object.keys(obj);
            let ob = {};
            for (let i = 0; i < vKeys.length; i++) {
              if (obj[vKeys[i]] == '' || obj[vKeys[i]] == undefined) {
                ob[vKeys[i]] = "-1";
              } else {
                ob[vKeys[i]] = "1";
              }
            }
            setValidation(ob);
            setSkipAsbestos(!skipAsbestos)}}
           />
           
          {buttonState
          ? <FontAwesomeIcon className="font-centered" icon={faAngleDown}/>
          :<FontAwesomeIcon className="font-centered" icon={faAngleRight}/>}
          </div>
        </Button>
        <Collapse isOpen={buttonState}>
          <Card className="createdeal-card">
            <CardBody>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="asbestosReq">
                  Asbestos Required<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input type="radio" name="asbestosReq"  value="Yes"
                       checked={props.data?props.data.asbestosReq === "Yes":false}

                       onChange={(e) => { props.handleOnChangeDeal(e); props.setAsbestosReq(e.target.value)}}
                       
                   />
                    </Col>

                    <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input type="radio" name="asbestosReq"  value="No"
                       checked={props.data?props.data.asbestosReq === "No":false}
                       onChange={(e) =>{
                        props.handleOnChangeDeal(e); props.setAsbestosReq(e.target.value)
                      }}
                   />
                    </Col>
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
                {
                  props.data.asbestosReq=="Yes"?
                  <FormGroup>
                  <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="acpcompleted">
                  ACP5 Completed<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input type="radio" name="existingACP5"  value="Yes"
                       checked={props.data?props.data.existingACP5 === "Yes":false}
                       onChange={(e) =>{props.handleOnChangeDeal(e); props.setACPcompleted(e.target.value)}}
                   />
                    </Col>

                    <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input type="radio" name="existingACP5"  value="No"
                       checked={props.data?props.data.existingACP5 === "No":false}
                       onChange={(e) =>{
                        if(props.sidebarCardsStatus.ACP5!=null){
                          if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                            e.preventDefault();
                          }else{
                            if(e.target.value=='No'){
                              props.updateTheSidebarState({type:"ACP5Upload",status:null});
                              clearFileFromZohoFiles({
                                ...props.sidebarCardsStatus.ACP5
                              }) 
                            }
                            props.handleOnChangeDeal(e); props.setACPcompleted(e.target.value)
                          }
                        }else{
                          props.handleOnChangeDeal(e); props.setACPcompleted(e.target.value)
                        }
                      }}
                   />
                    </Col>
                    {
                  props.data.existingACP5=="Yes"?
                    <Col sm={2}>
                  <Input id="ACP5" type="file" name="ACP5"
                    onChange={(e)=>{
                      if(e.target.files[0].type!='application/pdf'){
                        alert('Please select a PDF file!')
                        e.preventDefault();
                        return
                      }
                      let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let arr=e.target.value.split('\\');
                      let name=arr[arr.length-1]
                      props.updateTheSidebarState({type:"ACP5Upload",
                      status:{
                       filename: name ,
                       file : e.target.files[0],
                      }})
                    }}
                    hidden
                   />
                   {props.sidebarCardsStatus.ACP5!=null?
                    <Button className="continue-button"
                    onClick={(e)=>{
                      if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                        e.preventDefault();
                      }else{
                        clearFileFromZohoFiles({
                          ...props.sidebarCardsStatus.ACP5
                        })
                        if(props.sidebarCardsStatus.ACP5.filename != '' && props.sidebarCardsStatus.ACP5.file==null){    
                          props.updateTheSidebarState({type:"RemoveFilesArray",status: props.sidebarCardsStatus.ACP5.filename})
                        }
                        document.getElementById('ACP5').value='';
                        props.updateTheSidebarState({type:"ACP5Upload", status:null})
                      }
                    }}
                    >Clear</Button>:
                    <Button className="continue-button"
                    onClick={(e)=>{
                      document.getElementById('ACP5').click();
                    }}
                    >Upload</Button>
                   }
                 {props.sidebarCardsStatus.ACP5!=null?<span title={props.sidebarCardsStatus.ACP5.filename} style={{fontSize:'13px',...wrappNameCss}}>{props.sidebarCardsStatus.ACP5.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
                </Col>
                :<Col sm={7}></Col> }
                    {/* <Col sm={2}>
                  <Button className="continue-button">Upload</Button>
                </Col> */}
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
                {props.data.existingACP5=="Yes"?
                <FormGroup>
                <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="asvestosInspector">
                  Asbestos Inspector's Certificate Number<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={2}>
                      <Input type="text" name="existingACP5InspCertNumber"  className="createdeal-textfield"
                      value={props.data?props.data.existingACP5InspCertNumber:''}
                      style={validation.existingACP5InspCertNumber=='-1'?{...validationCss}:{}}
                      onChange={(e)=>{
                        e.target.value=e.target.value.trimStart()
                            e.target.value=e.target.value.replace(/[\D]/,'')
                        e.target.value=e.target.value.replace(/\D/g, '');
                        if(e.target.value.length==6){
                          setValidation({...validation,existingACP5InspCertNumber:"1"})
                        }if(e.target.value.length<6){
                          setValidation({...validation,existingACP5InspCertNumber:"-1"})
                        }
                        props.handleOnChangeDeal(e);
                        props.setCertificateNum(e.target.value)}}
                        onBlur={(e)=>{
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,existingACP5InspCertNumber:"-1"})
                          }
                          if(props.data?.existingACP5InspCertNumber?.length<6){
                            setIsAsbestosNo(false)
                          }else{
                            setIsAsbestosNo(true)
                          }
                        }}
                      maxLength={6}
                   />
                    </Col>
                    <Col sm={4}>
                  <span
                    className={
                     validation.existingACP5InspCertNumber=='-1' ? "red-text font12" : "error-display"
                    }
                  >
                    {" "}
                    Enter 6 digit Certificate number.
                  </span>
                </Col>
                   
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
                <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="acpcontrolno">
                  ACP5 Control Number<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={2}>
                      <Input type="text" name="existingACP5ControlNumber"  className="createdeal-textfield"
                      value={props.data?props.data.existingACP5ControlNumber:''}
                      style={validation.existingACP5ControlNumber=='-1'?{...validationCss}:{}}
                      onChange={(e)=>{
                        e.target.value=e.target.value.trimStart()
                        e.target.value=e.target.value.replace(/[\D]/,'')
                        if(e.target.value.length==8){
                          setValidation({...validation,existingACP5ControlNumber:"1"})
                        }if(e.target.value.length<8){
                          setValidation({...validation,existingACP5ControlNumber:"-1"})
                        }
                         e.target.value=e.target.value.replace(/\D/g, '');
                          props.handleOnChangeDeal(e);props.setAcpControl(e.target.value)
                        }}
                        onBlur={(e)=>{
                          if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,existingACP5ControlNumber:"-1"})
                          }
                          if(props.data?.existingACP5ControlNumber?.length<8){
                            setIsAcp5No(false)
                          }else{
                            setIsAcp5No(true)
                          }
                        }}
                      maxLength={8}
                      />
                    </Col>
                    <Col sm={4}>
                  <span
                    className={
                     validation.existingACP5ControlNumber=='-1' ? "red-text font12" : "error-display"
                    }
                  >
                    {" "}
                    Enter 8 digit Control number.
                  </span>
                </Col>
                <Col sm={7}></Col>
                </Row>
                </FormGroup>
                    </FormGroup>
:<></>  

                } 
                </FormGroup>
                :props.data.asbestosReq=="No"?
                  
                  <FormGroup>
                    <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="certificateoccupancy">
                  Certificate of Occupancy Available<span className="red-text">*</span>
                  </Label>
                  </Col>
                  <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">Yes</Label>
                      <Input type="radio" name="certificateoccupancy"  value="Yes"
                       checked={props.data?props.data.certificateoccupancy == "Yes":false}
                       onChange={(e) =>{props.handleOnChangeDeal(e);props.setCertificateoccupancy(e.target.value)}}
                   />
                    </Col>

                    <Col sm={1} className="radioStyle">
                      <Label className="text-bold2">No</Label>
                      <Input type="radio" name="certificateoccupancy"  value="No"
                        checked={props.data?props.data.certificateoccupancy === "No":false}
                       onChange={(e) =>{
                        if(props.sidebarCardsStatus.AsbestosCertificate!=null){
                          if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                            e.preventDefault();
                          }else{
                            props.handleOnChangeDeal(e); 
                            props.setCertificateoccupancy(e.target.value)
                            if(e.target.value=='No'){
                              props.updateTheSidebarState({type:"AsbestosCertificateUpload",status:null})
                              clearFileFromZohoFiles({
                                ...props.sidebarCardsStatus.AsbestosCertificate
                              })  
                            }
                          }  
                        }else{
                          props.handleOnChangeDeal(e); 
                          props.setCertificateoccupancy(e.target.value)
                        }
                      }}
                   />
                    </Col>
                    {
                  props.data.certificateoccupancy=="Yes"?
                  <>
                    <Col sm={2}>
                   <Input id="asbestosCertificate" type="file" name="asbestosCertificate"
                    onChange={(e)=>{
                      if(e.target.files[0].type!='application/pdf'){
                        alert('Please select a PDF file!')
                        e.preventDefault();
                        return
                      }
                      let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let arr=e.target.value.split('\\');
                      let name=arr[arr.length-1]
                      props.updateTheSidebarState({type:"AsbestosCertificateUpload",
                      status:{
                       filename: name ,
                       file : e.target.files[0],
                      }})
                    }}
                    hidden
                   />
                   {props.sidebarCardsStatus.AsbestosCertificate!=null?
                    <Button className="continue-button"
                    onClick={(e)=>{
                      if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                        e.preventDefault();
                      }else{
                        clearFileFromZohoFiles({
                          ...props.sidebarCardsStatus.AsbestosCertificate
                        })
                        // if(props.sidebarCardsStatus.AsbestosCertificate.filename != '' && props.sidebarCardsStatus.AsbestosCertificate.file==null){    
                        //   props.updateTheSidebarState({type:"RemoveFilesArray",status: props.sidebarCardsStatus.AsbestosCertificate.filename})
                        // }
                        document.getElementById('asbestosCertificate').value='';
                        props.updateTheSidebarState({type:"AsbestosCertificateUpload",status:null}) 
                      }
                    }}
                    >Clear</Button>:
                    <Button className="continue-button"
                    onClick={(e)=>{
                      document.getElementById('asbestosCertificate').click();
                    }}
                    >Upload</Button>
                  }
                    {props.sidebarCardsStatus.AsbestosCertificate!=null?<span title={props.sidebarCardsStatus.AsbestosCertificate.filename} style={{fontSize:'13px',...wrappNameCss}}>{props.sidebarCardsStatus.AsbestosCertificate.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
                </Col>
                
          
                </>
                :  <Col sm={7}></Col>}
                 
            
                </Row>
                </FormGroup>
                {
                  props.data.certificateoccupancy=="No"?
                   <FormGroup row className="marb-16 padtop-15">
                  <Row>
                    <Col sm={3}>
                      <Label className="text-bold" for="nbApplication">
                      NB Application Number<span className="red-text">*</span>
                      </Label>
                      </Col>
                      <Col sm={2}>
                          <Input type="text" name="nbApplication"  className="createdeal-textfield"
                          value={props.data?props.data.nbApplication:''}
                          style={validation.nbApplication=='-1'?{...validationCss}:{}}
                          onChange={(e)=>{
                            e.target.value=e.target.value.trimStart()
                            e.target.value=e.target.value.replace(/[\D]/,'')
                            if(e.target.value.length<9){
                              setValidation({...validation,nbApplication:"-1"})
                            }if(e.target.value.length==9){
                              setValidation({...validation,nbApplication:"1"})
                            }
                            props.handleOnChangeDeal(e); props.setNbApplication(e.target.value)}}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,nbApplication:'-1'})
                            }
                          }}
                          maxLength={9}
                          />
                        </Col>
                        <Col sm={4}>
                  <span
                    className={
                     validation.nbApplication=='-1' ? "red-text font12" : "error-display"
                    }
                  >
                    {" "}
                    Enter 9 digit Application number.
                  </span>
                </Col>
                    <Col sm={5}></Col>
                    </Row>
                    </FormGroup>:<></>
                }
                  </FormGroup> :<></>

                }
                
            </CardBody>
          </Card>
        </Collapse>
      </Col>
    );
}

export default Asbestos;