import React, { useState, useEffect } from "react";
// import request from 'request';
// import jsPDF from 'jspdf';
// import pdfMake from 'pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// import htmlToPdfmake from 'html-to-pdfmake';
import ReactDOMServer from 'react-dom/server';
// import Api2Pdf from 'api2pdf';
import Config from '../../../../../../config.json'
import { DownloadOutlined, PrinterOutlined , EyeFilled} from "@ant-design/icons";
import './previewModal.css';

import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Tooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faTrashAlt,
  faEdit,
  faPaperclip,
  faDownload, faPrint, faEye
} from "@fortawesome/free-solid-svg-icons";

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuid } from 'uuid';
// import { data } from "jquery";
import axios from "axios";
import { result, upperCase } from "lodash";
import httpService from "../../../../../../services/http.service";
import ModuleService from "../../../../../../common/util/ModuleService";
import WorkdriveService from "../../../../../../common/util/WorkdriveService";
import PdfViewer from "../../../../../PdfPreview/PdfViewer";

// import PdfViewer from "../../../../../PdfPreview/PdfViewer";
// import { useHistory, withRouter, useLocation, Prompt } from 'react-router-dom';
// import swal from "sweetalert";

// var a2pClient = new Api2Pdf('d0791fc8-874e-48d0-865f-a0369364a721');
const Violation = (props) => {
  // const location = useLocation();
  const [buttonState, setbuttonState] = useState(false);
  const [penalty, setpenalty] = useState(false);
  const [modal, setModal] = useState(false);
  const [backdrop, setBackdrop] = useState("static");
  const [keyboard, setKeyboard] = useState(true);
  const [skipViolation, setSkipViolation] = useState(false);
  const [deleteAdminModal, setDeleteAdminModal] = useState(false);
  const [editState, setEditState] = useState("");
  const [idToDelete, setIdToDelete] = useState("");
  const [prevObj, setPrevObj] = useState({});
  const [action, setAction] = useState('add')
  const [editIdx, setEditIdx] = useState(0);
  const [deleteId, setDeleteId] = useState([])
  const[violationEditIndex,setVilationEditIndex]=useState("");
  const[oldVilation,setOldViolation]=useState(null);
  const wwwViolationHeaders = [
    { value: "Name", label: "Violation Index", oldKey: "violationIndex" },
    { value: "Violation_Number", label: "Violation Number", oldKey: "violationNum" },
    { value: "Violation_Type", label: "Violation Type", oldKey: "violationType" },
    { value: "L2_Code", label: "L2 Code", oldKey: "ltwocode" },
    { value: "Civil_Penalty_Payment_Required", label: "Civil Penalty Payment Required", oldKey: "Civilpenalty" },
    { value: "Paid_Under_Job_Number", label: "Paid Under Job Number", oldKey: "jobNo" },
    { value: "Invoice_Number", label: "Invoice Number", oldKey: "invoiceNo" },
    // { value: "ISN_Number", label: "ISN Number" },
  ]

  const wrappNameCss={
    "display": "block",
    "white-space": "nowrap",
    "width": "11em",
    "overflow": "hidden",
    "text-overflow": "ellipsis",
    "cursor":"default"
  }

  const buttontoggle = () => {
    props.settoggleState("violation");
    props.setListState("violation");
    setbuttonState(!buttonState);
  };

  useEffect(() => {
    props.toggleState == "violation"
      ? setbuttonState(true)
      : setbuttonState(false);

  }, [props.toggleState]);

  useEffect(() => {
    getData();
  }, [props.data]);

  const getData = () => {
    if (props.data != null) {
      props.setWWPViolation(props.data.wwpViolation);
    }
  };

  const toggle = () => {
    props.setViolationType("")
    props.setViolationNum("")
    props.setltwocode("NRV")
    props.setCivilpenalty("")
    props.setJobNumber("")
    props.setInvoiceNumber("");
    props.setDOBViolationNum("")
    resetValidations({obType:'validations',arr:validations})
    setViolation(null);
    setInvoice(null);
    setPaidunderJob(null);
    setVilationEditIndex("")
    setViolationSummary(null);
    setpenalty(false)
    setEditState("")
    setModal(!modal);
  }

  //Hrushikesh shelke new code start
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
    "margin-bottom" : "12px"
  }
  const[validations,setValidations]=useState({
    Violation_Number:'0',
    Violation_Type:'0',
    L2_Code:'0',
    Civil_Penalty_Payment_Required:'0',
    Paid_Under_Job_Number:'0',
    Invoice_Number:'0'
  });
  const resetValidations=(data)=>{
     
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      ob[vKeys[i]]='0'
    }
    if(data.obType=='validations'){
      setValidations(ob)
    }
  }
  const highliteEmptys=(data)=>{
     
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
    if(data.obType=='validations'){
      setValidations(ob)
    }
  }
  // end

  const saveViolationInfo = () => {
    var wwpViolation = props.data.wwpViolation;
    var jobNo = props.Civilpenalty === "Yes" ? "" : props.jobNumber;
    var invoiceNo = props.Civilpenalty === "Yes" ? "" : props.invoiceNumber;
    if (editState === "") {
      var unique = uuid();
      wwpViolation.push({
        violationId: unique,
        violationType: props.violationType,
        violationNum: props.violationNum,
        ltwocode: props.ltwocode,
        Civilpenalty: props.Civilpenalty,
        jobNumber: jobNo,
        invoiceNumber: invoiceNo,
        dobViolationNum: props.dobViolationNum
      });
    } else {
      props.data.wwpViolation.forEach((info, i) => {
        if (info.violationId === editState) {
          wwpViolation[i] = {
            violationId: editState,
            violationNum: props.violationNum,
            violationType: props.violationType,
            ltwocode: props.ltwocode,
            Civilpenalty: props.Civilpenalty,
            jobNumber: jobNo,
            invoiceNumber: invoiceNo,
            dobViolationNum: props.dobViolationNum
          }
        }
      })
    }
    props.handleOnChangeDeal({ currentTarget: { name: 'wwpViolation', value: wwpViolation } })
    props.setWWPViolation(wwpViolation);
    setEditState("");
    toggle();
  };

  const deleteviolationInfo = () => {
    let dealObj = { ...props.data }
    var copyArray = props.wwpViolation.filter((info, i) => info.violationId != idToDelete);
    props.setWWPViolation(copyArray);
   
  }
  
  const editviolationInfo = (info,i) => {
    let obj = {};
    if (info.id) {
      obj = { id: info.id };
    }
    wwwViolationHeaders.forEach((one) => {
      obj[one.value] = info[one.oldKey];
    });
    setPrevObj(obj);
    setEditIdx(i);
    setAction("edit");
    toggle();
    props.setViolationNum(info.violationNum)
    props.setltwocode(info.ltwocode)
    props.setCivilpenalty(info.Civilpenalty)
    props.setJobNumber(info.jobNo)
    props.setInvoiceNumber(info.invoiceNo)
    setOldViolation(info);
    let vKeys=Object.keys(validations);
     
    if(info.Civilpenalty!='No'){
      let ob={
        Violation_Number:'1',
        Violation_Type:'1',
        L2_Code:'1',
        Civil_Penalty_Payment_Required:'1',
        Paid_Under_Job_Number:'0',
        Invoice_Number:'0'
      }
      setValidations(ob)
    }else{
      let ob={
        Violation_Number:'1',
        Violation_Type:'1',
        L2_Code:'1',
        Civil_Penalty_Payment_Required:'1',
        Paid_Under_Job_Number:'1',
        Invoice_Number:'1'
      }
      setValidations(ob)
    }
     
     
    if(props.sidebarCardsStatus.EditDocuments){
      if(props.sidebarCardsStatus.EditDocuments.violationDocs?.length>0){
        let thisV=props.sidebarCardsStatus.EditDocuments.violationDocs?.find(e=>e.id?.toUpperCase()==info.violationNum?.toUpperCase());
        if(thisV){
          setViolation({
            ...thisV,
            filename:thisV.filename,
            edit:true
          })
        }
      }
       
      if(props.sidebarCardsStatus.EditDocuments.violationSummaryDocs?.length>0){
        let thisV=props.sidebarCardsStatus.EditDocuments.violationSummaryDocs?.find(e=>e.id?.toUpperCase()==info.violationNum?.toUpperCase());
        if(thisV){
          setViolationSummary({
            ...thisV,
            filename:thisV.filename,
            edit:true
          })
        }
      }
      if(props.sidebarCardsStatus.EditDocuments.paidUnderJobDocs?.length>0){
        let thisV=props.sidebarCardsStatus.EditDocuments.paidUnderJobDocs?.find(e=>e.id?.toUpperCase()==info.jobNo?.toUpperCase());
        if(thisV){
          setPaidunderJob({
            ...thisV,
            filename:thisV.filename,
            edit:true
          })
        }
      }
      if(props.sidebarCardsStatus.EditDocuments.invoiceDocs?.length>0){
        let thisV=props.sidebarCardsStatus.EditDocuments.invoiceDocs?.find(e=>e.id?.toUpperCase()==info.invoiceNo?.toUpperCase());
        if(thisV){
          setInvoice({
            ...thisV,
            filename:thisV.filename,
            edit:true
          })
        }
      }
    }
    if (info.ltwocode == "CPP" || info.ltwocode == "DUPW") {
      setpenalty(true)
    }
    setModal(true);
    setEditState(info.violationId);
  }

  const handleChange = (e) => {
    const obj = { ...prevObj };
    if(e.currentTarget.name == 'Violation_Number'){
      setViolation({
        ...violation,
        violationNo:e.currentTarget.value,
      })
      setViolationSummary({
        ...violationSummary,
        violationNo:e.currentTarget.value,
      })
    }
    if(e.currentTarget.name=='Violation_Type'){
      setViolation({
        ...violation,
        violationType:e.currentTarget.value,
      })
      setViolationSummary({
        ...violationSummary,
        violationType:e.currentTarget.value,
      })
    }
    obj[e.currentTarget.name] = e.currentTarget.value;
    if (e.currentTarget.name == 'L2_Code') {
      obj['Civil_Penalty_Payment_Required']="";
      if((prevObj.L2_Code == "CPP" || prevObj.L2_Code == "DUPW") && (e.currentTarget.value == "CPP" || e.currentTarget.value == "DUPW")){
        if(prevObj.L2_Code != e.currentTarget.value){
          obj["Paid_Under_Job_Number"] = "";
          obj["Invoice_Number"] = "";
          obj['Civil_Penalty_Payment_Required']="";
          setValidations({...validations,Civil_Penalty_Payment_Required:'-1'})
          setValidations({...validations,Paid_Under_Job_Number:'-1'})
          setValidations({...validations,Invoice_Number:'-1'})
          // props.updateTheSidebarState({type:"InvoiceUpload", 
          //  status:invoice, 
          //  action:'clear'})
           setInvoice({...invoice,file:null,filename:null});
           setPaidunderJob({...paidunderJob,file:null,filename:null});
        }
      }else{
        if (e.currentTarget.value == "CPP" || e.currentTarget.value == "DUPW") {
          setpenalty(true)
        }else{
          obj["Paid_Under_Job_Number"] = "";
          obj["Invoice_Number"] = "";
          setpenalty(false)
        }
      }
    }
    if(e.currentTarget.name == 'Civil_Penalty_Payment_Required'){
      if(e.currentTarget.value == "Yes"){
        obj["Paid_Under_Job_Number"] = "";
        setPaidunderJob(null)
        setInvoice(null)
        obj["Invoice_Number"] = "";
      }
    }
    setPrevObj(obj);
  }

  let handleL2CodeChange = (e) => {

    if (e.target.value == "CPP" || e.target.value == "DUPW") {
      setpenalty(true)
      props.setCivilpenalty("Yes")
    } else {
      setpenalty(false)
      props.setCivilpenalty("")
    }
    props.setltwocode(e.target.value);
  };
  const getTenDigitNumberWithPrefixZero = (number) => {
    const max = 10;
    const remain = max - number.length;
    let strPrefix = ''
    for (let i = 0; i < remain; i++) {
      strPrefix += '0';
    }
    return strPrefix + number;
  }
  const handleClickPrintDOBViolation = (info) => {
    let ecb_violation_url = "https://a810-bisweb.nyc.gov/bisweb/ActionViolationDisplayServlet?requestid=2&allbin=" + props.data.binNo + "&allinquirytype=BXS3OCV4&allboroughname=&allstrt=&allnumbhous=&allisn=" + info.isnNumber + "&ppremise60=" + info.violationNum;//config.domainUrl + info.docPath_original;
    let print = window.open(ecb_violation_url);
    return print.print();

  }

  let returnViolationDObLink = (isnNumber, number) => {
    if (isnNumber)
      isnNumber = getTenDigitNumberWithPrefixZero(isnNumber);
    return `https://a810-bisweb.nyc.gov/bisweb/ActionViolationDisplayServlet?requestid=2&allbin=${props.bin}&allinquirytype=BXS3OCV4&allboroughname=&allstrt=&allnumbhous=&allisn=${isnNumber}&ppremise60=${number}`
  }
  let returnViolationLink = (value) => {
    return `https://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?requestid=2&allbin=${props.bin}&ecbin=${value}`;
  };

  let returnJobNumLink = (value) => {
    return "https://a810-bisweb.nyc.gov/bisweb/JobsQueryByNumberServlet?passjobnumber=" + value;
  };



  const handleViolationCheck = () => {
    if (skipViolation) {
      props.setViolationComplete.wwpViolation = true;
      return true;
    }
    if(props.data.wwpViolation.length != 0){
      props.setViolationComplete.wwpViolation = true;
      return true;
    }
    props.setViolationComplete.wwpViolation = false;
    return false;
  };

  const l2code = (
    <Input
      type="select"
      className="dropdown-text  dropdown-textL2"
      name="L2_Code"
      id="l2code"
      value={prevObj ? prevObj.L2_Code : ''}
      onChange={handleChange}
      style={validations.L2_Code=='-1'?{...validationCss}:{}}
      onBlur={(e)=>{
         
        if(e.target.value!=''){
          setValidations({...validations,L2_Code:'1'})
        }else{
          setValidations({...validations,L2_Code:'-1'})
        }
      }}
    >
      <option value="">Select L2 Code</option>
      <option value="NRV">NRV</option>
      <option value="REDT">REDT</option>
      <option value="LEG">LEG</option>
      <option value="BFP">BFP</option>
      <option value="CPP">CPP</option>
      <option value="DUPW">DUPW</option>
      <option value="ECB">ECB</option>
      <option value="EWG">EWG</option>
      <option value="EWP">EWP</option>
      <option value="EXP1">EXP1</option>
      <option value="EXP2">EXP2</option>
      <option value="GOV">GOV</option>
      <option value="TPT">TPT</option>
      <option value="SAPW">SAPW</option>
      <option value="SWBC">SWBC</option>
      <option value="SWOE">SWOE</option>
    </Input>
  );
  // preview code end
  const [loader,setLoader]=useState(false);
  useEffect(e=>{
    props.updateTheSidebarState({type:"LoadingModalStatus",status:loader});
  },[loader])
  const [authenticatedUrl, setAuthenticatedUrl] = useState(null);
  const fetchAuthenticatedUrl = async (info,type) => {
    let uri="";
    let isEditDeal=localStorage.getItem('isUpdateDeal');
    let fileId="";
    let shouldDownload=false;
    props.setLoader(true);
    try{
      if(type=="vs"){
        if(props.sidebarCardsStatus.EditDocuments.violationSummaryDocs){
          let current=props.sidebarCardsStatus.EditDocuments.violationSummaryDocs?.find(e=>e.id?.toUpperCase()==info.violationNum?.toUpperCase());
          if(current){
            setShowFileName(current.filename)
          }
          if(current?.violationSummaryDocs){
            setAuthenticatedUrl(current.violationSummaryDocs);
          }else{
            if(current?.fileId){
              fileId=current?.fileId
            }
            shouldDownload=true;
          }
        }
      }else if(type=="vc"){
        if(props.sidebarCardsStatus.EditDocuments.violationDocs){
          let current=props.sidebarCardsStatus.EditDocuments.violationDocs?.find(e=>e.id?.toUpperCase()==info.violationNum?.toUpperCase());
          if(current){
            setShowFileName(current.filename)
          }
          if(current?.violationDoc){
            setAuthenticatedUrl(current.violationDoc);
          }else{
            if(current?.fileId){
              fileId=current?.fileId
            }
            shouldDownload=true;
          }
        }
      }
      else if(type=="in"){
        if(props.sidebarCardsStatus.EditDocuments.invoiceDocs){
          let current=props.sidebarCardsStatus.EditDocuments.invoiceDocs?.find(e=>e.id?.toUpperCase()==info.invoiceNo?.toUpperCase());
          if(current){
            setShowFileName(current.filename)
          }
          if(current?.invoiceDoc){
            setAuthenticatedUrl(current.invoiceDoc);
          }else{
            if(current?.fileId){
              fileId=current?.fileId
            }
            shouldDownload=true;
          }
        }
      }else if(type=="job"){
        if(props.sidebarCardsStatus.EditDocuments.paidUnderJobDocs){
          let current=props.sidebarCardsStatus.EditDocuments.paidUnderJobDocs?.find(e=>e.id?.toUpperCase()==info.jobNo?.toUpperCase());
          if(current){
            setShowFileName(current.filename)
          }
          if(current?.JobDoc){
            setAuthenticatedUrl(current.JobDoc);
          }else{
            if(current?.fileId){
              fileId=current?.fileId
            }
            shouldDownload=true;
          }
        }
      }
      if(shouldDownload){
        let data;
        if(fileId!="" && fileId!= undefined){
          data= await handleClickDownloadViolationFromWorkdrive(fileId);
        }else{
          if(type=="vs"){
            data= await handleClickDownloadViolationSummary(info);
          }else{
            data= await handleClickDownloadViolation(info);
          }
        }
        setAuthenticatedUrl(data);
      }
      props.setLoader(false);
    }catch(e){
      props.setLoader(false);
    }
    //setAuthenticatedUrl("https://firstprojectbook.herokuapp.com/static/pdf/Mediatek_2016_CSRreport.pdf")
    setShowPriview(true);
  };
  const[showPriview,setShowPriview]=useState(false);
  const[showFileName,setShowFileName]=useState("");
  const closePreview=()=>{
    setShowPriview(!showPriview);
  }

 // preview code end
 
 const handleClickDownloadViolationFromWorkdrive = async (fileData) => {
   let baseUrl=`${Config.backend_url}getPriviewFileById`
   let payLoad={
      "fileId":fileData,
      "accessToken":localStorage.getItem('token')
  }
  let str=""
  await axios.post(baseUrl,payLoad).then(r=>{
    str=r.data
   
  });
  return str;
 }
 const handleClickDownloadViolationSummary = async (info) => {
    let baseUrl=`https://catly-795086611.development.catalystserverless.com/server/apiservice/generatepdf`
    let url = info.violationType == "ECB" ? returnViolationLink(info.violationNum)
      : returnViolationDObLink(info.isnNumber,info.violationNum)
    let payLoad={
      "url": url
    }
    let str=""
    await axios.post(baseUrl,payLoad).then(r=>{
      str=r.data
    });
  return str;
}

  const handleClickDownloadViolation = async (info) => {
    let url = "";//"https://pdfmyurl.com/api?license=yourlicensekey&url="
    let result
    if (info.violationType == "ECB") {
      result = await WorkdriveService.DownloadECBViolationFile(info.violationNum)
      url = url + "http://a820-ecbticketfinder.nyc.gov/GetViolationImage?violationNumber=" + info.violationNum;
    } else {
      result = await WorkdriveService.DownloadECBViolationFile(info.violationNum)
      url = url + "https://a810-bisweb.nyc.gov/bisweb/ActionViolationDisplayServlet?requestid=2&allbin=" + props.data.binNo + "&allinquirytype=BXS3OCV4&allboroughname=&allstrt=&allnumbhous=&allisn=" + info.isnNumber + "&ppremise60=" + info.violationNum;
    }
    return result;
  }

  function displayMessage(evt) {
    alert("I got " + evt.data + " from " + evt.origin);
  }

  const [downloadTooltip, setDownloadTooltip] = useState(false);
  const [printTooltip, setPrintTooltip] = useState(false);

  const downloadTooltipToggle = () => {
    setDownloadTooltip(!downloadTooltip);
  }

  const printTooltipToggle = () => {
    setPrintTooltip(!printTooltip);
  }

  const handleClickPrintViolation = async (info) => {
    let ecb_violation_url = "https://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?ecbin=" + info.violationNum//config.domainUrl + info.docPath_original;
    let node = window.open(ecb_violation_url);
    if (node) {
      node.focus();
      node.print();

    }
    // //   let url = 'http://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?ecbin=34580047M'//"http://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?ecbin="+value
    //   httpService.CreateUpdate('GetPropertyViolationECB',{})
    //   .then((result)=>{
    //  const doc =new jsPDF()
    // //     doc.setFontSize(22);
    // //  // doc.text(result.data, 15, 15);
    // //   doc.fromHTML(result.data, 15, 20, {'width': 180});

    // //   window.open(doc.output('bloburl'), '_blank');
    // //  const pdfTable = document.getElementById('frame');
    // //   //html to pdf format
    // //   var html = htmlToPdfmake(pdfTable.contentWindow.document.body.innerHTML);
    // //   const documentDefinition = { content: html };
    // //   pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // //   pdfMake.createPdf(documentDefinition).open();

    //   let stringHtml = `<div>sandeep</div>`;

    //   doc.setFontSize(22);
    //   doc.text('Title', 15, 15);
    //   doc.fromHTML(result.data, 15, 20, {'width': 180});
    //   window.open(doc.output('bloburl'), '_blank');
    //   })
    //   .catch((err)=>{
    //     alert(`${err}`);
    //   })

  }

  //Hrushikesh shelke new code start
  const[violation,setViolation]=useState(null);
  const[violationSummary,setViolationSummary]=useState(null);
  const[paidunderJob,setPaidunderJob]=useState(null);
  const[invoice,setInvoice]=useState(null);
  //Hrushikesh shelke new code end

  const getBase64=async (file) =>{
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  }
  const[clearedDocs,setClearedDocs]=useState([])
  const handleSave =async () => {
    let vKeys=Object.keys(validations);
    if((prevObj.L2_Code== "CPP" || prevObj.L2_Code== "DUPW") && (prevObj.Civil_Penalty_Payment_Required == 'No' || prevObj.Civil_Penalty_Payment_Required == '')){
      for(let i=0;i<vKeys.length;i++){
          if(validations[vKeys[i]]=='-1' || validations[vKeys[i]]=='0'){
          alert("Please fill all required fields!")
          highliteEmptys({arr:validations,obType:'validations'})
          return
        }
      }
      if(!violation?.filename){
        alert("Please attach all the required documents")
        return
      }
      if(!violationSummary?.filename){
        alert("Please attach all the required documents")
        return
      }
      if(!paidunderJob?.filename){
        alert("Please attach all the required documents")
        return
      }
      if(!invoice?.filename){
        alert("Please attach all the required documents")
        return
      }
    }else{
      for(let i=0;i<vKeys.length;i++){
        if((validations[vKeys[i]]=='-1' || validations[vKeys[i]]=='0') 
        && vKeys[i]!='Invoice_Number' && vKeys[i]!='Paid_Under_Job_Number' && vKeys[i]!='Civil_Penalty_Payment_Required'){
          alert("Please fill all required fields!")
          highliteEmptys({arr:validations,obType:'validations'})
          return
        }
      }
      if(!violation?.filename){
        alert("Please attach all the required documents.")
        return
      }
      if(!violationSummary?.filename){
        alert("Please attach all the required documents")
        return
      }
    }
    let oldObDocs={}
    let isUpdateDeal=false;
    if(localStorage.getItem('isUpdateDeal')){
      isUpdateDeal=localStorage.getItem('isUpdateDeal');
    }
    if(violation==null){
      alert("Please Fill Voilation Details!");
      return;
    }
    let obj = { ...prevObj }
    let data = { ...props.data };
    if(action=="add"){
      let tempObj=data.wwpViolation?.find(e=>e.violationNum==prevObj.Violation_Number);
      if(tempObj){
        alert("Violation Number Already Present. Please Enter unique Violation Number.");
        return;
      }
    }else if(action=="edit"){
      let tempObj=data.wwpViolation?.find((e,i)=>e.violationNum==prevObj.Violation_Number && i!=editIdx);
      if(tempObj){
        alert("Violation Number Already Present. Please Enter unique Violation Number.");
        return;
      }
    }
    let insertObj={}
    if (action == 'edit') {
      insertObj = { id: obj.id };
      wwwViolationHeaders.forEach((one) => {
        insertObj[one.oldKey] = obj[one.value]
      })
      data.wwpViolation[editIdx] = insertObj;
    } else {
      insertObj = {};
      wwwViolationHeaders.forEach((one) => {
        insertObj[one.oldKey] = obj[one.value]
      })
    }
     
    if(violation?.violationNo){
      oldObDocs["violationDoc"]={
        filename:oldVilation?.violationNum?violation.filename.replace("VIOLATION COPY "+oldVilation.violationNum?.toUpperCase(),"VIOLATION COPY "+insertObj.violationNum?.toUpperCase()).replace(oldVilation.violationType?.toUpperCase(),insertObj.violationType?.toUpperCase()):violation.filename,
        'violationDoc': violation.file? await getBase64(violation.file).then(r=>r):null,
        id:violation.violationNo,
        fileId:violation.fileId,
      }
      insertObj={
        ...insertObj,
        'editflag': isUpdateDeal
      }
    }
    if(violationSummary?.violationNo){
      oldObDocs["violationSummaryDocs"]={
        filename:oldVilation?.violationNum?violationSummary.filename.replace("VIOLATION SUMMARY "+oldVilation.violationNum?.toUpperCase(),"VIOLATION SUMMARY "+insertObj.violationNum?.toUpperCase()).replace(oldVilation.violationType?.toUpperCase(),insertObj.violationType?.toUpperCase()):violationSummary.filename,
        'violationSummaryDocs': violation.file? await getBase64(violationSummary.file).then(r=>r):null,
        id:violationSummary.violationNo,
        fileId:violationSummary.fileId,
      }
      insertObj={
        ...insertObj,
        'editflag': isUpdateDeal
      }
    }
    if(paidunderJob?.PaidunderJobNo){
      oldObDocs["paidUnderJobDoc"]={
        filename: oldVilation?.jobNo? paidunderJob.filename.replace("VIOLATION PAID APPLICATION "+oldVilation.jobNo?.toUpperCase(),"VIOLATION PAID APPLICATION "+insertObj.jobNo?.toUpperCase()):paidunderJob.filename,
        'JobDoc': paidunderJob.file?await getBase64(paidunderJob.file).then(r=>r):null,
        id:paidunderJob.PaidunderJobNo,
        fileId:paidunderJob.fileId,
      }
      insertObj={
        ...insertObj,
        'editflag': isUpdateDeal
      }
    }
    if(invoice?.invoiceNo){
      oldObDocs["invoiceDoc"]={
        filename:oldVilation?.invoiceNo?invoice.filename.replace("VIOLATION PAID INVOICE "+oldVilation.invoiceNo?.toUpperCase(),"VIOLATION PAID INVOICE "+insertObj.invoiceNo?.toUpperCase()):invoice.filename,
        'invoiceDoc': invoice.file?await getBase64(invoice.file).then(r=>r):null,
        fileId:invoice.fileId,
        id:invoice.invoiceNo
      }
      insertObj={
        ...insertObj,
        'editflag': isUpdateDeal
      }
    }
     
    if (data.wwpViolation) {
      let temp=data.wwpViolation.filter(e=>e.violationNum==obj.Violation_Number);
      
      if(temp.length>0){
        if(!window.confirm(isUpdateDeal?'The exisitng violation detail will be updated, do you still want to continue?':'Violation No Already Present, Do you want to update?')){
          return;
        }else{
          let index=-1;
          for(let t=0;t<data.wwpViolation.length;t++){
              if(data.wwpViolation[t].violationNum==obj.Violation_Number){
                index=t;
                break
              }
          }
          //code to renameTheFilesInZoho
          let renameFilesArr=[...props.sidebarCardsStatus.RenameFilesArray];
          if(oldVilation.violationNum!=insertObj.violationNum || oldVilation.violationType!=insertObj.violationType){
            let tempOb= props.sidebarCardsStatus.EditDocuments.violationDocs?.find(e=>e.id?.toUpperCase()==oldVilation.violationNum?.toUpperCase());
            let tempOb1= props.sidebarCardsStatus.EditDocuments.violationSummaryDocs?.find(e=>e.id?.toUpperCase()==oldVilation.violationNum?.toUpperCase());
            if(tempOb?.fileId){
              renameFilesArr=renameFilesArr.filter(o=>o.fileId!=tempOb.fileId)
              let Vname=tempOb.filename.replace("VIOLATION COPY "+oldVilation.violationNum?.toUpperCase(),"VIOLATION COPY "+insertObj.violationNum?.toUpperCase()).replace(oldVilation.violationType?.toUpperCase(),insertObj.violationType?.toUpperCase());
              renameFilesArr.push({...tempOb,id:insertObj.violationNum,newName:Vname?.toUpperCase()});
             
            }
            if(tempOb1?.fileId){
              renameFilesArr=renameFilesArr.filter(o=>o.fileId!=tempOb1.fileId)
              let Vsname=tempOb1.filename.replace("VIOLATION SUMMARY "+oldVilation.violationNum?.toUpperCase(),"VIOLATION SUMMARY "+insertObj.violationNum?.toUpperCase()).replace(oldVilation.violationType?.toUpperCase(),insertObj.violationType?.toUpperCase());
              renameFilesArr.push({...tempOb1,id:insertObj.violationNum,newName:Vsname?.toUpperCase()});
              
            }
            //renameFilesArr=renameFilesArr.filter(e=>e.id?.toUpperCase()!=insertObj.violationNum?.toUpperCase() && !(tempOb.filename.includes('VIOLATION COPY') || (tempOb.filename.includes('VIOLATION SUMMARY'))));
            
          }
          if(oldVilation.invoiceNo!=insertObj.invoiceNo){
            let tempOb= props.sidebarCardsStatus.EditDocuments.invoiceDocs?.find(e=>e.id?.toUpperCase()==oldVilation.invoiceNo?.toUpperCase());
            if(tempOb?.fileId){
              renameFilesArr=renameFilesArr.filter(o=>o.fileId!=tempOb.fileId)
              //renameFilesArr=renameFilesArr.filter(e=>e.id?.toUpperCase()!=insertObj.invoiceNo?.toUpperCase() && !tempOb.filename.includes('VIOLATION PAID INVOICE'))
              let Vname=tempOb.filename.replace("VIOLATION PAID INVOICE "+oldVilation.invoiceNo?.toUpperCase(),"VIOLATION PAID INVOICE "+insertObj.invoiceNo?.toUpperCase());
              renameFilesArr.push({...tempOb,id:insertObj.invoiceNo,newName:Vname?.toUpperCase()});
             
            }
          }
          if(oldVilation.jobNo!=insertObj.jobNo){
            let tempOb= props.sidebarCardsStatus.EditDocuments.paidUnderJobDocs?.find(e=>e.id?.toUpperCase()==oldVilation.jobNo?.toUpperCase());
            if(tempOb?.fileId){
              renameFilesArr=renameFilesArr.filter(o=>o.fileId!=tempOb.fileId)
              // renameFilesArr=renameFilesArr.filter(e=>e.id?.toUpperCase()!=insertObj.jobNo?.toUpperCase() && !tempOb.filename.includes('VIOLATION PAID APPLICATION'))
              let Vname=tempOb.filename.replace("VIOLATION PAID APPLICATION "+oldVilation.jobNo?.toUpperCase(),"VIOLATION PAID APPLICATION "+insertObj.jobNo?.toUpperCase());
              renameFilesArr.push({...tempOb,id:insertObj.jobNo,newName:Vname?.toUpperCase()});
             
            }
          }
          //
          data.wwpViolation[index] = insertObj;
          // data.wwpViolation.push(insertObj)
          props.updateTheSidebarState({type:"VoilationUpload",
          status:{
            ...violation,
            "violationNo":insertObj.violationNum,
            "JobNo":insertObj.jobNo,
            'invoiceNo': insertObj.invoiceNo,
            'JobDoc': insertObj.JobDoc,
            'invoiceDoc':insertObj.invoiceDoc,
            'violationSummaryDocs':insertObj.violationSummaryDocs,
            'editflag': isUpdateDeal,
          },action:"update","docs":oldObDocs,"oldObj":oldVilation,renameFileArr:renameFilesArr,
          Archive:clearedDocs
        })
        setClearedDocs([])
          setViolation(null);
        }
      }else{
        data.wwpViolation.push(insertObj)
        props.updateTheSidebarState({type:"VoilationUpload",
        status:{
          ...violation,
          "JobNo":insertObj.jobNo,
          'invoiceNo': insertObj.invoiceNo,
          'JobDoc': insertObj.JobDoc,
          'invoiceDoc':insertObj.invoiceDoc,
          'violationSummaryDocs':insertObj.violationSummaryDocs,
        },action:"add","docs":oldObDocs,})
        setViolation(null);
      }
    } else {
      data.wwpViolation = [insertObj]
      props.updateTheSidebarState({type:"VoilationUpload",
      status:{
        ...violation,
        "JobNo":insertObj.jobNo,
        'invoiceNo': insertObj.invoiceNo,
        'JobDoc': insertObj.JobDoc,
        'invoiceDoc':insertObj.invoiceDoc,
      },action:"add","docs":oldObDocs,})
      setViolation(null);
    }
    setPaidunderJob(null)
    setInvoice(null)
    resetValidations({obType:'validations',arr:validations})
    props.setData(data);
    toggle()

  }

  const handleDelete = (info, idx) => {
    let data = { ...props.data };
    let localIdes=localStorage.getItem('deletedIds');
    if(localIdes){
      localIdes=JSON.parse(localIdes);
    }else{
      localIdes=[];
    }
    const ids = [...localIdes];

    let tempClearArr=[];
    if (data.wwpViolation) {
      data.wwpViolation = data.wwpViolation.filter((one, i) => idx !== i);
      if (info.id) {
        ids.push(info.id)
      }
      if(info.violationNum){
        let t1=props.sidebarCardsStatus.EditDocuments.violationDocs?.find(e=>e.id?.toUpperCase()==info.violationNum?.toUpperCase());
        tempClearArr.push(t1)
        let t2= props.sidebarCardsStatus.EditDocuments.violationSummaryDocs?.find(e=>e.id?.toUpperCase()==info.violationNum?.toUpperCase())
        tempClearArr.push(t2)
      }
      if(info.jobNo){
        let t1=props.sidebarCardsStatus.EditDocuments.paidUnderJobDocs?.find(e=>e.id?.toUpperCase()==info.jobNo?.toUpperCase());
        tempClearArr.push(t1)
      }
      if(info.invoiceNo){
        let t1=props.sidebarCardsStatus.EditDocuments.invoiceDocs?.find(e=>e.id?.toUpperCase()==info.invoiceNo?.toUpperCase());
        tempClearArr.push(t1)
      }
      props.updateTheSidebarState({type:"VoilationUpload",
      status:{
        fileName:'',
        file:null,
        violationNo: info.violationNum
      },action:"clear",Archive:tempClearArr})
    }
    props.setData(data);
    localStorage.setItem('deletedIds', JSON.stringify(ids))

  }

  const handleDownload = () => {
    const url = 'http://a820-ecbticketfinder.nyc.gov/GetViolationImage?violationNumber=039002338J'; // Replace with the actual URL to download the file
    const headers = {
      responseType: 'blob'
    }
    axios.get(url, { headers }) // Set responseType to 'blob' to handle binary data
      .then(handleFileDownload)
      .catch(handleDownloadError);
  };
  const handleFileDownload = (response) => {
    alert("shbdksabd");
    const fileName = 'file-name.extension'; // Replace with the desired file name and its extension
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleDownloadError = (error) => {
    alert("error");
    console.error('Error downloading file:', error);
    // Handle any error, such as showing an error message to the user
  };

  // function to getNameOfFile()
  const getFileName=(d)=>{
    if(d.type=='vs'){
      let ob=props.sidebarCardsStatus.EditDocuments?.violationSummaryDocs?.find(e=>e.id?.toUpperCase()==d.data?.violationNum?.toUpperCase())
      if(ob){
        return ob.filename;
      }
    }else if(d.type=='vc'){
      let ob=props.sidebarCardsStatus.EditDocuments?.violationDocs?.find(e=>e.id?.toUpperCase()==d.data?.violationNum?.toUpperCase())
      if(ob){
        return ob.filename;
      }
    }
    return '';
  }

  const clearFileFromZohoFiles=async(data,rename)=>{
    if(rename){
      if(data.fileId){
        let name=rename
        await axios.post(Config.backend_url+'archiveFile',
        {
          file_id:data.fileId,
          file_name:name,
          accessToken:localStorage.getItem('token')
        })
      }
    }else if(data.fileId){
      let name=data.filename.replace(".PDF",'')+'ARCHIVE.PDF'
      await axios.post(Config.backend_url+'archiveFile',
      {
        file_id:data.fileId,
        file_name:name,
        accessToken:localStorage.getItem('token')
      })
    }
  }

  return (
    <Col sm={12}>
      <Button
        onClick={buttontoggle}
        className={buttonState ? "btn-100-active" : "btn-100"}
        disabled={props.data.withoutpermit == "No" ? true : false}
      >
        Work Without Permit Violation Information
        <div className="float-right">
          <Input
            type="checkbox"
            className={
              handleViolationCheck()
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={props.data.withoutpermit == "Yes" ? handleViolationCheck() : true}
            onChange={() => setSkipViolation(!skipViolation)}
            disabled={props.data.withoutpermit == "No" ? true : false}
          />
          {buttonState ? (
            <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
          )}
        </div>
      </Button>
      <Collapse isOpen={buttonState}>
        <Card className="createdeal-card">
          <CardBody>
            <div style={{"overflow-x" : 'auto'}}>
            <Table striped bordered className="table-pad" style={{width : '1150px'}}>
              <thead className="bg-header">
                <tr>
                  <th className="width-64 left-th" style={{position : 'sticky', left : '0',background:'#018ee0'}}>Action</th>
                  <th className="middle-th" style={{position : 'sticky', left : '53px',background:'#018ee0'}}>
                    Violation Number
                  </th>
                  {/* <th className="middle-th">
                  </th>
                  <th className="middle-th">
                  </th>
                  <th className="middle-th">
                  </th> */}
                  <th className="middle-th">
                    Violation Type
                  </th>
                  <th className="middle-th" colSpan="2">
                    Violation Summary
                  </th>
                  <th className="middle-th" colSpan="2">
                    Violation Copy
                  </th>

                  <th className="middle-th">L2 Code</th>
                  <th className="middle-th">Civil Penalty Payment Required</th>
                  <th className="middle-th" colSpan="2">
                    Paid Under Job Number
                  </th>
                  <th className="right-th" colSpan="2">
                    Invoice Number
                  </th>
                </tr>
              </thead>
              <tbody className="font12">
                {props.data.wwpViolation && props.data.wwpViolation?.length > 0 ? (
                  props.data.wwpViolation?.map((info, i) => {
                    return (
                      <tr>
                        <td className="pad0" scope="row" style={{position : 'sticky', left : '0', background: "#dbeef9"}}>
                          <Button
                            className="btn btn-info btn-info2 cursor-pointer"
                            onClick={() => editviolationInfo(info,i)}
                          >
                            <FontAwesomeIcon
                              className="float-right"
                              icon={faEdit}
                            />
                          </Button>
                          <Button
                            className="btn btn-danger btn-info2 cursor-pointer"
                            onClick={() => {
                              handleDelete(info, i);
                              // setDeleteAdminModal(!deleteAdminModal)
                              // setIdToDelete(info.violationId)
                            }}
                          >
                            <FontAwesomeIcon
                              className="float-right"
                              icon={faTrashAlt}
                            />
                          </Button>
                          <Modal
                            className="exportmodal-width"
                            isOpen={deleteAdminModal}
                            toggle={deleteAdminModal}
                          >
                            <div className="modal-header-class">
                              Confirmation Message
                              <div className="note-header-icon">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="icon-time-color"
                                  onClick={() =>
                                    setDeleteAdminModal(!deleteAdminModal)
                                  }
                                ></FontAwesomeIcon>
                              </div>
                            </div>
                            <ModalBody>
                              <FormGroup className="mar-bottom0">
                                <Row>
                                  <Col sm={1}></Col>
                                  <Col sm={10}>
                                    <span className="text-bold" for="l2code">
                                      Are you sure Want to Delete?
                                    </span>
                                  </Col>

                                  <Col sm={1}></Col>
                                </Row>
                              </FormGroup>

                              <FormGroup className="marb-16 padtop-15">
                                <Row>
                                  <Col sm={2}></Col>
                                  <Col sm={4}>
                                    <Button
                                      className="continue-button"
                                      onClick={() =>
                                        setDeleteAdminModal(!deleteAdminModal)
                                      }
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                  <Col sm={4}>
                                    <Button
                                      className="continue-button"
                                      onClick={() => {
                                        deleteviolationInfo();
                                        setDeleteAdminModal(!deleteAdminModal);
                                      }}
                                    >
                                      Yes
                                    </Button>
                                  </Col>
                                  <Col sm={2}></Col>
                                </Row>
                              </FormGroup>
                            </ModalBody>
                          </Modal>
                        </td>

                        <td style={{position : 'sticky', left : '53px', background: "#dbeef9", width: "107px"}}>
                          <a
                            href={
                              info.violationType == "ECB"
                                ? returnViolationLink(info.violationNum)
                                : returnViolationDObLink(
                                    info.isnNumber,
                                    info.violationNum
                                  )
                            }
                            target="_blank"
                            style={{cursor :"pointer"}}
                          >
                            {info.violationNum}
                          </a>
                        </td>

                        <td>{info.violationType}</td>
                        <td>{getFileName({type:'vs',data:info})}</td>
                        <td className="width-36">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  fetchAuthenticatedUrl(info,"vs")
                                }
                                download
                              >
                              <FontAwesomeIcon icon={faEye} />
                              </a>
                            </td>
                        <td>{getFileName({type:'vc',data:info})}</td>
                        <td className="width-36">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  fetchAuthenticatedUrl(info,"vc")
                                }
                                download
                              >
                               <FontAwesomeIcon icon={faEye} />
                              </a>
                            </td>
                        <td>{info.ltwocode}</td>
                        <td
                          className={info.Civilpenalty != "" && info.Civilpenalty != null && info.Civilpenalty != undefined  ? "" : "backcolor"}
                        >
                          {info.Civilpenalty}
                        </td>
                        <td className={(info.jobNo != "" && info.jobNo != undefined && info.jobNo != null) ? "" : "backcolor"}>
                          <a
                            href={returnJobNumLink(info.jobNo)}
                            target="_blank"
                          >
                            {info.jobNo}
                          </a>
                        </td>
                        <td
                          className={
                            (info.jobNo != "" && info.jobNo != undefined && info.jobNo != null)
                              ? "width-36"
                              : "backcolor width-36"
                          }
                        >
                          <FontAwesomeIcon
                           onClick={(e)=>{
                            if(info.jobNo != "" && info.jobNo != undefined && info.jobNo != null){
                              fetchAuthenticatedUrl(info,"job")
                            }else{
                              e.preventDefault();
                            }
                          }}
                          icon={faEye} />
                        </td>
                        <td className={info.invoiceNo != "" && info.invoiceNo != undefined && info.invoiceNo != null ? "" : "backcolor"}>
                          {" "}
                          {info.invoiceNo}
                        </td>
                        <td
                          className={
                            info.invoiceNo != "" && info.invoiceNo != undefined && info.invoiceNo != null
                              ? "width-36"
                              : "backcolor width-36"
                          }
                        >
                          <FontAwesomeIcon
                            onClick={(e)=>{
                              if(info.invoiceNo != "" && info.invoiceNo != undefined && info.invoiceNo != null){
                                fetchAuthenticatedUrl(info,"in")
                              }else{
                                e.preventDefault();
                              }
                            }} 
                           icon={faEye} />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
                <tr>
                  <td className="pad0" scope="row" style={{position : 'sticky', left : '0',background:'#dbeef9'}}>
                    <Button className="btn-wwp-plus" onClick={() => { toggle(); setPrevObj({}); setAction('add') }}>
                      +
                    </Button>
                  </td>
                  {/* <td></td> */}
                  <td style={{position : 'sticky', left : '53px',background :'#dbeef9', display : "none"}}>dasdsa</td>
                  <td style={{position : 'sticky', left : '160px',background :'#dbeef9', display : "none"}}></td>
                  <td style={{display : "none"}}></td>
                  <td style={{display : "none"}}></td>
                  <td style={{display : "none"}}></td>
                  <td style={{display : "none"}}></td>
                  <td style={{display : "none"}}></td>
                  <td style={{display : "none"}}></td>
                  <td style={{display : "none"}}></td>
                  {/* <td></td> */}
                </tr>
              </tbody>
            </Table>
            </div>
          </CardBody>
        </Card>
      </Collapse>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <div className="modal-header-class">Violation Information
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={(e)=>{
                let isEdit=localStorage.getItem('isUpdateDeal')
                if(violation!=null && isEdit!=undefined && !isEdit){
                  if(!window.confirm("All selected files will be removed. Do you want to continue?")){
                    e.preventDefault();
                    return
                  }
                }
                toggle()
              }}
            ></FontAwesomeIcon>
          </div>
        </div>
        <ModalBody>
          <FormGroup className="marb-16">
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="workwithoutpermt">
                  Violation Number<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
                <Input
                  className="createdeal-textfield"
                  type="text"
                  name="Violation_Number"
                  id="workwithoutpermt"
                  value={prevObj ? prevObj.Violation_Number : ""}
                  onChange={(e) =>{
                    e.target.value=e.target.value.replace(' ','')
                    handleChange(e)}}
                  style={validations.Violation_Number=='-1'?{...validationCss}:{}}
                  onBlur={(e)=>{
                    if(e.target.value.trim()==''){
                      setValidations({...validations,Violation_Number:'-1'})
                    }else{
                      setValidations({...validations,Violation_Number:'1'})
                    }
                  }}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="marb-16">
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="workwithoutpermt">
                  Violation Type<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
                <select name="Violation_Type" id="violationType" onChange={(e) => {
                  handleChange(e)
                }} value={prevObj ? prevObj.Violation_Type : ''} className="createdeal-textfield form-control"
                style={validations.Violation_Type=='-1'?{...validationCss}:{}}
                onBlur={(e)=>{
                   
                  if(e.target.value!='' && e.target.value!='--violation type--'){
                    setValidations({...validations,Violation_Type:'1'})
                  }else{
                    setValidations({...validations,Violation_Type:'-1'})
                  }
                }}
                >
                  <option>--violation type--</option>
                  <option>ECB</option>
                  <option>DOB</option>
                </select>
              </Col>
            </Row>
          </FormGroup>

          {/* docs */}
          <FormGroup className="marb-16">
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="workwithoutpermt">
                  Violation Summary<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
              <Input id="voilationSummary" type="file" name="voilationSummary"
                    onChange={(e)=>{
                      if(e.target.files[0].type!='application/pdf'){
                        alert('Please select a PDF file!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let arr=e.target.value.split('\\');
                      let name=arr[arr.length-1]
                      setViolationSummary({
                        filename: name ,
                        violationNo:prevObj.Violation_Number,
                        violationType:prevObj.Violation_Type,
                        file : e.target.files[0],
                      })
                    }}
                    hidden
                   />
                {(violationSummary!=null && violationSummary.filename) ?<Button className="continue-button"
                    onClick={(e)=>{
                      if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                        e.preventDefault();
                      }else{
                        if(violationSummary.filename != '' && violationSummary.file==null){    
                          props.updateTheSidebarState({type:"RemoveFilesArray",status: violationSummary.filename})
                        }
             
                        let oldDocs=clearedDocs
                        oldDocs.push(violationSummary);
                        setClearedDocs(oldDocs)
                        document.getElementById('voilationSummary').value='';
                        props.updateTheSidebarState({type:"violationSummaryUpload",
                        status:violationSummary, 
                        action:'clear',
                      })
                        setViolationSummary({...violationSummary,file:null,filename:null});
                      }
                    }}
                    >Clear</Button>:
                    <Button className="continue-button"
                    onClick={(e)=>{
                      let missingVal={...validations}
                      let isValid=true;
                      if(prevObj.Violation_Number==undefined || prevObj.Violation_Number==''){
                        missingVal['Violation_Number']='-1';
                        isValid=false;
                      }
                      if(prevObj.Violation_Type==undefined || prevObj.Violation_Type==''){        
                        missingVal['Violation_Type']='-1';
                        isValid=false;
                      }
                      if(!isValid){
                        alert('Please fill required fields!')
                        setValidations(missingVal)
                        e.preventDefault()
                        return
                      }
                      document.getElementById('voilationSummary').click();
                    }}
                    >Upload</Button>
                }
              </Col>
              <Col sm={4}>
              {(violationSummary!=null && violationSummary.filename)?<span title={violationSummary.filename} style={{fontSize:'13px',...wrappNameCss }}>{violationSummary.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16">
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="workwithoutpermt">
                  Violation Copy<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>
              <Input id="voilation" type="file" name="voilation"
                    onChange={(e)=>{
                      if(e.target.files[0].type!='application/pdf'){
                        alert('Please select a PDF file!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let arr=e.target.value.split('\\');
                      let name=arr[arr.length-1]
                      setViolation({
                        filename: name ,
                        violationNo:prevObj.Violation_Number,
                        violationType:prevObj.Violation_Type,
                        file : e.target.files[0],
                      })
                    }}
                    hidden
                   />
                {(violation!=null && violation.filename) ?<Button className="continue-button"
                    onClick={(e)=>{
                      if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                        e.preventDefault();
                      }else{
                        document.getElementById('voilation').value='';
                  
                        let oldDocs=clearedDocs
                        oldDocs.push(violation);
                        setClearedDocs(oldDocs)
                        props.updateTheSidebarState({type:"VoilationUpload", status:{
                          ...violation,
                        },action:"clear",
                       
                      })
                        setViolation(null);
                      }
                    }}
                    >Clear</Button>:
                    <Button className="continue-button"
                    onClick={(e)=>{
                      if(prevObj.Violation_Number==undefined || prevObj.Violation_Number==''){
                        alert('Please enter Violation Number!') 
                        e.preventDefault();
                        return
                      }
                      if(prevObj.Violation_Type==undefined || prevObj.Violation_Type==''){
                        alert('Please enter Violation Type!')
                        e.preventDefault();
                        return
                      }
                      document.getElementById('voilation').click();
                    }}
                    >Upload</Button>
                }
              </Col>
              <Col sm={4}>
              {(violation!=null && violation.filename)?<span title={violation.filename} style={{fontSize:'13px',...wrappNameCss }}>{violation.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
              </Col>
            </Row>
          </FormGroup>
          {/* docs */}
          
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={4}>
                <Label className="text-bold" for="l2code">
                  L2 Code<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={4}>{l2code}</Col>
              <Col sm={4}></Col>
            </Row>
          </FormGroup>
          <FormGroup className={penalty == true ? "" : "l2code-none"}>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" for="civilpenalty">
                    Civil Penalty Payment Required
                    <span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={2}>
                  <Label className="text-bold2">Yes</Label>
                  <Input
                    type="radio"
                    name="Civil_Penalty_Payment_Required"
                    value="Yes"
                    checked={prevObj.Civil_Penalty_Payment_Required === "Yes" ? true : false}
                    onChange={(e) =>{
                      if(e.target.value=='Yes'){
                        setValidations({...validations,Civil_Penalty_Payment_Required:'1'})
                      }
                      handleChange(e)}}
                  />
                </Col>

                <Col sm={2}>
                  <Label className="text-bold2">No</Label>
                  <Input
                    type="radio"
                    name="Civil_Penalty_Payment_Required"
                    value="No"
                    checked={prevObj.Civil_Penalty_Payment_Required === "No" ? true : false}
                    onChange={(e) =>{ 
                      if(e.target.value=='No'){
                        setValidations({...validations,Civil_Penalty_Payment_Required:'1'})
                      }
                      handleChange(e)
                    }}
                  />
                </Col>
                <Col sm={4}></Col>
              </Row>
            </FormGroup>
            <FormGroup
              className={
                prevObj.Civil_Penalty_Payment_Required == "No"
                  ? "marb-16 padtop-15"
                  : "civilpenalty-none"
              }
            >
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" for="workwithoutpermt">
                    Paid under Job Number<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                  <Input
                    //className="arrow-hide"
                    type="text"
                    name="Paid_Under_Job_Number"
                    className="createdeal-textfield arrow-hide"
                    id="PaidUnderJobNumber"
                    value={prevObj ? prevObj.Paid_Under_Job_Number : ''}
                    onChange={(e) => { 
                      if(paidunderJob!=null){
                        if(paidunderJob.file!=null && e.target.value==''){
                          alert('File is already selected! Paid Under Job Number cannot be empty!')
                          e.preventDefault()
                          return;
                        }
                        setPaidunderJob({
                          ...paidunderJob,
                          PaidunderJobNo: e.target.value,
                        })
                      }else{
                        setPaidunderJob({
                          PaidunderJobNo: e.target.value,
                          file:null,
                        })
                      }
                      handleChange(e)
                     }}
                     style={validations.Paid_Under_Job_Number=='-1'?{...validationCss}:{}}
                      onBlur={(e)=>{
                         
                        if(e.target.value.trim()!=''){
                          setValidations({...validations,Paid_Under_Job_Number:'1'})
                        }else{
                          setValidations({...validations,Paid_Under_Job_Number:'-1'})
                        }
                      }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" for="workwithoutpermt">
                  Paid Under Application Summary<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                <Input id="PaidunderJob" type="file" name="voilation"
                    onChange={(e)=>{
                      if(e.target.files[0].type!='application/pdf'){
                        alert('Please select a PDF file!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                      let arr=e.target.value.split('\\');
                      let name=arr[arr.length-1]
                      setPaidunderJob({
                       PaidunderJobNo: prevObj.Paid_Under_Job_Number,
                       filename: name ,
                       file : e.target.files[0],
                      })
                    }}
                    hidden
                   />
                  {paidunderJob!=null && paidunderJob.filename?<Button className="continue-button"
                    onClick={(e)=>{
                      if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                        e.preventDefault();
                      }else{
                        if(paidunderJob.filename != '' && paidunderJob.file==null){    
                          //props.updateTheSidebarState({type:"RemoveFilesArray",status: paidunderJob.filename})
                        }
                        document.getElementById('PaidunderJob').value='';
                    
                        let oldDocs=clearedDocs
                        oldDocs.push(paidunderJob);
                        setClearedDocs(oldDocs)
                        props.updateTheSidebarState({type:"PaidunderJobUpload",
                        status:paidunderJob, 
                        action:'clear',
                      })
                        setPaidunderJob({...paidunderJob,file:null,filename:null});
                      }
                    }}
                    >Clear</Button>:
                    <Button className="continue-button"
                    onClick={(e)=>{
                      if(document.getElementById("PaidUnderJobNumber").value.trim()==''){
                        alert("Paid under Job Number should not be empty.")
                        return
                      }
                      document.getElementById('PaidunderJob').click();
                    }}
                    >Upload</Button>
                  }
                </Col>
                <Col sm={4}>
                {(paidunderJob!=null && paidunderJob.filename)?<span title={paidunderJob.filename} style={{fontSize:'13px',...wrappNameCss }}>{paidunderJob.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
                </Col>
              </Row>
            </FormGroup>
            <FormGroup
              className={
                prevObj.Civil_Penalty_Payment_Required == "No"
                  ? "marb-16 padtop-15"
                  : "civilpenalty-none"
              }
            >
              <Row>
                <Col sm={4}>
                  <Label className="text-bold" for="workwithoutpermt">
                    Invoice Number<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                  <Input
                    type="text"
                    name="Invoice_Number"
                    id="Invoice_Number"
                    className="createdeal-textfield"
                    value={prevObj ? prevObj.Invoice_Number : ''}
                    onChange={(e) => {
                      if(invoice!=null){
                        if(invoice.file!=null && e.target.value==''){
                          alert('File is already selected! Invoice Number cannot be empty!')
                          e.preventDefault()
                          return;
                        }
                        setInvoice({
                          ...invoice,
                          invoiceNo: e.target.value,
                        })
                      }else{
                        setInvoice({
                          invoiceNo: e.target.value,
                          file:null,
                        })
                      }
                      handleChange(e) }}
                    style={validations.Invoice_Number=='-1'?{...validationCss}:{}}
                    onBlur={(e)=>{
                       
                      if(e.target.value.trim()!=''){
                        setValidations({...validations,Invoice_Number:'1'})
                      }else{
                        setValidations({...validations,Invoice_Number:'-1'})
                      }
                    }}
                  />
                </Col>
              </Row>
              <FormGroup
              className="marb-16 padtop-15"
              style={{marginTop :"10px"}}
              >
                <Row>
                  <Col sm={4}>
                    <Label className="text-bold" for="workwithoutpermt">
                      Invoice Summary<span className="red-text">*</span>
                    </Label>
                  </Col>
                  <Col sm={4}>
                  <Input id="Invoice" type="file" name="voilation"
                      onChange={(e)=>{
                        if(e.target.files[0].type!='application/pdf'){
                          alert('Please select a PDF file!')
                          e.target.value='';
                          e.preventDefault();
                          return
                        }
                        let s=Math.round((e.target.files[0].size / 1024))
                      if(s>10240){
                        alert('File size should not be greater than 10MB!')
                        e.target.value='';
                        e.preventDefault();
                        return
                      }
                        let arr=e.target.value.split('\\');
                        let name=arr[arr.length-1]
                        setInvoice({
                        invoiceNo: prevObj.Invoice_Number,
                        filename: name ,
                        file : e.target.files[0],
                        })
                      }}
                      hidden
                    />
                    {invoice!=null && invoice.filename?<Button className="continue-button"
                      onClick={(e)=>{
                        if(!window.confirm("Selected file will be removed. Do you want to continue?")){
                          e.preventDefault();
                        }else{
                          if(invoice.filename != '' && invoice.file==null){    
                            props.updateTheSidebarState({type:"RemoveFilesArray",status: invoice.filename})
                          }
                          document.getElementById('Invoice').value='';
                        
                          let oldDocs=clearedDocs
                          oldDocs.push(invoice);
                          setClearedDocs(oldDocs)
                          props.updateTheSidebarState({type:"InvoiceUpload",
                          status:invoice, 
                          action:'clear'})
                          setInvoice({...invoice,file:null,filename:null,
                          });
                        }
                      }}
                      >Clear</Button>:
                      <Button className="continue-button"
                      onClick={(e)=>{
                        if(document.getElementById("Invoice_Number").value.trim()==''){
                          alert("Invoice number should not be empty.")
                          return
                        }
                        document.getElementById('Invoice').click();
                      }}
                      >Upload</Button>
                    }
                  </Col>
                  <Col sm={4}>
                    {(invoice!=null && invoice.filename)?<span title={invoice.filename} style={{fontSize:'13px',...wrappNameCss }}>{invoice.filename}</span>:<span style={{color:"red",fontSize:'13px'}}>No file choosen</span>}
                  </Col>
                </Row>
              </FormGroup>
              
            </FormGroup>
           
          </FormGroup>

          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={3}>
                <Button className="continue-button" onClick={toggle}>
                  Cancel
                </Button>
              </Col>
              <Col sm={3}>
                <Button className="continue-button" onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col sm={6}></Col>
            </Row>
          </FormGroup>
        </ModalBody>
      </Modal>
      <Modal
      isOpen={showPriview}
      backdrop={'static'}
      toggle={closePreview}
      className="taskComplete-warning width-of-modal"
    >
      <div className="modal-header-class">{showFileName}
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={(e)=>{
                closePreview()
              }}
            ></FontAwesomeIcon>
          </div>
        </div>
      <ModalBody>
        
            <PdfViewer fileData={authenticatedUrl}></PdfViewer>
            {/* <embed title="PDF Preview" 
            type="application/pdf"
            width="100%" height="500px" src={authenticatedUrl} /> */}
        
      </ModalBody>
    </Modal>
    </Col>
  );
};

export default Violation; 