import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import { AgGridColumn, AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import {
    faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faCircle,
    faSearch, faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, ModalBody } from "react-bootstrap";
import { useHistory,useLocation, useParams } from "react-router-dom";
import {
    Button, Col, FormGroup, Input, Label, ListGroupItem,
    Modal, Row, ModalHeader, Table
} from "reactstrap";
import swal from "sweetalert";
import doticon from "../../assets/images/doticon.png";
import leftexpandgray from "../../assets/images/svg/left-gray.svg";
import leftexpand from "../../assets/images/svg/left.svg";
import leftexpandactivegray from "../../assets/images/svg/leftactive-gray.svg";
import leftexpandactive from "../../assets/images/svg/leftactive.svg";
import "../Deal/deal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import TabView from "../../common/tabs/tabs";
import Filter from "../../common/filter/filter";
import Config from '../../config.json'
import FieldService from "../../common/util/FieldService";
import { GlobalAgGridTable } from "../Deal/AgGridReactComponent/GlobalAgGridTable";
import _ from "lodash";



const VendorList = (props) => {
    const history = useHistory();
    const location=props.location;
    const params=useParams();
    useEffect(()=>{
        if(props.getParams){
            props.getParams(params);
        }
    },[location])
    const [cacheHeaderData, setCacheHeaderData] = useState([]);
    const [headerData, setheaderData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [cacheData, setcacheData] = useState([]);
    const [from, setFromRow] = useState(1);
    const [to, setToRow] = useState(5);
    const [totalRow, setTotalRow] = useState(5);
    const [backActive, setbackActive] = useState(false);
    const [frontActive, setfrontActive] = useState(true);
    const [dotActive, setdotActive] = useState(false);
    const [filterText, setFilterText] = useState(null);
    const [filterState, setFilterState] = useState(false);
    const [firstActive, setFirstActive] = useState(false);
    const [columnsEdit, setColumnsEdit] = useState(false);
    const [editColumnSelectAllFlag, SetEditColumnSelectAllFlag] = useState(false);
    const [showFilter, setShowFilter] = useState('')
    const [searchValue, setSearchValue] = useState("");
    const [preAppliedFiltersForView, setPreAppliedFiltersForView] = useState([]);
    const [activeFiltersOnColumnLevel, setActiveFiltersOnColumnLevel] = useState(
        {}
      );
    const [appliedFiltersForView, setappliedFiltersForView] = useState([]);

    let selectedIndx = -1;
    let selectedValue = null;
    let dropedIndx = -1;
    let dropedValue = null;


    const columnHeaders = [
        {
          field: "Vendor_Name",
          name: "Vendor Name",
          path: "Vendor_Name",
          type:"text",
          label: "Vendor Name",
          id : 0,
          isChecked : false,
          isDragOver : false,
          isSearch : true,
        //   content: (obj) => (
        //     <label hidden={selectedTbl === "dayView"}>{obj.userName}</label>
        //   ),
        },
        {
        //   key:"Phone",
          name: "Phone",
          field: "Phone",
          type:"text",
          path: "Phone",
          label: "Phone",
          id : 1,
          isChecked : false,
          isDragOver : false,
          isSearch : true,
        //   content: function(obj,key){return GetFormattedDate(new Date(obj[key]))}
        },
        { 
          path: "Email",
          label: "Email",
          field: "Email",
          type:"text",
          name: "Email",
          id : 2,
          isChecked : false,
          isDragOver : false,
          isSearch : true,  
        },
        { 
          path: "Company_Name",
          label: "Company Name",
          field: "Company_Name",
          type:"text",
          name: "Company Name",
          id : 3,
          isChecked : false,
          isDragOver : false,
          isSearch : true,  
        },
        { 
          path: "Company_Address",
          label: "Company Address",
          field: "Company_Address",
          type:"text",
          name: "Company Address",
          id : 4,
          isChecked : false,
          isDragOver : false,
          isSearch : true,  
        },
        { 
          path: "City",
          label: "City",
          field: "City",
          type:"text",
          name: "City",
          id : 5,
          isChecked : false,
          isDragOver : false,
          isSearch : true,  
        },
        { 
          path: "State",
          label: "State",
          field: "State",
          type:"text",
          name: "State",
          id : 6,
          isChecked : false,
          isDragOver : false,
          isSearch : true,  
        },
        { 
          path: "Zip_Code",
          label: "Zip Code",
          field: "Zip_Code",
          type:"text",
          name: "Zip Code",
          id : 7,
          isChecked : false,
          isDragOver : false,
          isSearch : true,  
        },
        // { 
        //   field: "Owner", 
        //   name: "Vendor Owner" ,
        //   path: "Owner", 
        //   type:"text",
        //   label: "Vendor Owner",
        //   id : 8,
        //   isChecked : false,
        //   isDragOver : false,
        //   isSearch : true, 
        // },
        // { path: "CheckInLocation", label: "Check In Location" },
        // { path: "CheckOutLocation", label: "Check Out Location" },
        { 
          path: "IsActive", 
          label: "IsActive" ,
          field: "IsActive", 
          type: "text",
          name: "IsActive",
          id : 8,
          isChecked : false,
          isDragOver : false,
          isSearch : true, 
        },
        // { path: "WorkingHours", label: "Working Hours" },
        {
        //   key: "GL_Account",
          name: "GL_Account",
          type:"text",
          label: "GL_Account",
          field: "GL_Account",
          id : 9,
          isChecked : false,
          isDragOver : false,
          isSearch : true,
        //   content: function(obj,key){return actionListCellRenderer(obj, obj[key])},
        },
      ];

      console.log("rowData        ", rowData);
      const setActiveFilterAndGetActiveFilters = async (
        filters,
        action,
        mainFilters
      ) => {
        let api = gridApi;
        if (action == "SAVE") {
          if (api) {
            let filtersModal = await api.getFilterModel();
            return filtersModal;
          }
        } else if (action == "SET") {
          let filterMain = mainFilters;
          if (!_.isArray(mainFilters) && !_.isEmpty(mainFilters)) {
            do {
              filterMain = JSON.parse(filterMain);
            } while (!_.isArray(filterMain));
          }
          let filter = filters;
          if (!_.isObject(filters) && !_.isEmpty(filters)) {
            do {
              filter = JSON.parse(filter);
            } while (!_.isObject(filter));
          }
          setPreAppliedFiltersForView(filterMain);
          setTimeout(() => {
            setActiveFiltersOnColumnLevel(filter);
          }, 1000);
        }
      };


    useEffect(() => {
        getFieldHeaders()
    }, [])


    const getFieldHeaders = () => {
        FieldService.GetFields('all', 'Vendors')
            .then((result) => {
                let arr = []
                result.forEach(one => {
                    let obj = {}
                    obj.field = one.api_name
                    obj.id = one.id
                    obj.isChecked = false
                    obj.isDragOver = false
                    obj.isSearch = true
                    obj.name = one.field_label
                    obj.type = one.data_type
                    arr.push(obj)
                })
                setheaderData(arr);
                setCacheHeaderData(arr);
            })
            .catch((err) => {
                console.log(err);
            })

    }


    const handleColumns = (e, index) => {
        if (!e.currentTarget.checked) {
            SetEditColumnSelectAllFlag(false);
        }
        let newClient = [...headerData];
        newClient.forEach(obj => {
            if (obj.id === e.currentTarget.id) {
                obj.isChecked = !e.currentTarget.checked
            };
        })
        setColumnsEdit(true);
        setheaderData(newClient);
    }



    const getVendorsList = () => {
        const backendUrl = Config.backend_url+"workdrive";

        var config = {
            Type: "Get",

            url: "https://www.zohoapis.com/crm/v2/Vendors",
            method: "GET",
            headers: {
                'Authorization': localStorage.getItem('token')
            }
        };
        axios.post(backendUrl, { config: config })
            .then(function (res) {
                res.data.data = res.data.data.map((one) => {
                    one.Created_By = one.Created_By.name;
                    one.Owner = one.Owner.name;
                    // one.Account_Name = one.Account_Name.name;
                    return one;
                })
                let obj = res.data.data[0];
                // let arr = [{ type: 'text', name: 'Vendor Name', field: "Vendor_Name", 
                // id: "Vendor_Name", isDragOver: false, isSearch: false, isChecked: false }]

                // Object.keys(obj).forEach(key => {
                //     if (!key.includes('$') && key !== 'Vendor_Name') {
                //         let name = key.replaceAll('sandeepext', '');
                //         name = name.replaceAll('_', ' ');
                //         name = name.replaceAll('__', ' ');
                //         let obj = {}
                //         obj.field = key
                //         obj.id = key
                //         obj.isChecked = false
                //         obj.isDragOver = false
                //         obj.isSearch = true
                //         obj.name = name
                //         obj.type = 'text'
                //         arr.push(obj)
                //     }
                // })
                // setCacheHeaderData(arr);
                // setheaderData(arr);
                setRowData(res.data.data);
                setcacheData(res.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onDragColumns = (e, value, list) => {
        e.preventDefault();
        selectedIndx = list.findIndex((x) => x.name === value.name);
        selectedValue = value;
    };

    const onDropColumns = (e, value, list) => {
        e.preventDefault();
        let newClient = [...headerData];
        if (selectedIndx !== 0 || dropedIndx === 0) {
            dropedIndx = newClient.findIndex((x) => x.name === value.name);
            dropedValue = value;
            newClient.splice(selectedIndx, 1);
            newClient.splice(dropedIndx, 0, selectedValue);
        }

        newClient.forEach((data) => {
            data.isDragOver = false;
        });

        setheaderData(newClient);
    };

    const onDragOverColumns = (e, value, list) => {
        // e.preventDefault();
        // if (isDragOver !== value.name) {
        //   isDragOver = value.name;
        //   let newList = [...headerData];
        //   newList.forEach((data) => {
        //     if (data.name.toUpperCase().includes(value.name.toUpperCase())) {
        //       data.isDragOver = true;
        //     } else {
        //       data.isDragOver = false;
        //     }
        //   });
        //   setheaderData(newList)
        // }
    };


    const updatedHeaderData = () => {
        let headList = headerData;
        if (gridColumnApi !== null) {
            let orderedList = headList.map((data) => data.field);
            gridColumnApi.moveColumns(orderedList, 1); //moveColumn(colKey, toIndex)
        }
        return headList;
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        getVendorsList();

    };

    useEffect(e=>{
        // setGridApi(params.api);
        // setGridColumnApi(params.columnApi);
        getVendorsList();
    },[])


    const getPagingValues = () => {
        if (gridApi != null) {
            const currentPage = gridApi.paginationGetCurrentPage();
            const totalPage = gridApi.paginationGetTotalPages();
            const totalRows = gridApi.paginationGetRowCount();
            const pageSize = gridApi.paginationGetPageSize();
            const fromRow = currentPage * pageSize + 1;
            const toRow = Math.min(fromRow + pageSize - 1, totalRows);

            setFromRow(fromRow);
            setToRow(toRow);
            setTotalRow(totalRows);
            fromRow != 1 ? setbackActive(true) : setbackActive(false);
            if (toRow === totalRows) {
                setfrontActive(false);
                setdotActive(true);
            } else {
                setfrontActive(true);
                setdotActive(false);
            }
        }
    };

    const onPageSizeChanged = (newPageSize) => {
        var value = document.getElementById("page-size").value;
        gridApi.paginationSetPageSize(Number(value));
        getPagingValues();
    };



    const onBtNext = () => {
        gridApi.paginationGoToNextPage();
        getPagingValues();
    };

    const onBtPrevious = () => {
        gridApi.paginationGoToPreviousPage();
        getPagingValues();
    };

    const onBtDoubleNext = () => {
        gridApi.paginationGoToNextPage();
        gridApi.paginationGoToNextPage();
        getPagingValues();
    };

    const onBtDoublePrevious = () => {
        gridApi.paginationGoToPreviousPage();
        gridApi.paginationGoToPreviousPage();
        getPagingValues();
    };

    const onSearchFilterTexts = (value) => {
        gridApi.setQuickFilter(value);
    };

    const sortByAthleteAsc = () => {
        gridColumnApi.applyColumnState({
            state: [
                {
                    colId: "jobNumber",
                    sort: "asc",
                },
            ],
            defaultState: { sort: null },
        });
    };

    const sortByAthleteDesc = () => {
        gridColumnApi.applyColumnState({
            state: [
                {
                    colId: "jobNumber",
                    sort: "desc",
                },
            ],
            defaultState: { sort: null },
        });
    };

    const onChange = (event) => {
        setFilterText(event.target.value);
    };

    const ColumnFilter = () => {
        return (
            <div style={{ padding: 10, width: 230, backgroundColor: "white" }}>
                <div style={{ marginTop: 10 }} onClick={() => sortByAthleteAsc()}>
                    Sort A to Z
                </div>
                <div style={{ marginTop: 10 }} onClick={() => sortByAthleteDesc()}>
                    Sort Z to A
                </div>
                <div>
                    <input
                        style={{ margin: "4 0 4 0" }}
                        type="text"
                        value={filterText}
                        onChange={onChange}
                        placeholder="Search"
                    />
                </div>
                <div
                    style={{
                        width: 200,
                        height: 150,
                        textAlign: "center",
                        padding: 10,
                        margin: 10,
                        border: "1px solid lightgrey",
                        backgroundColor: "white",
                    }}
                ></div>
            </div>
        );
    };

    const applyFilters = (
        column,
        filterName,
        filterType,
        filterText,
        filterText2,
        duration
    ) => {

        var filtervalue = {
            column: column,
            filterName: filterName,
            filterType: filterType,
            filterText: filterText,
            filterText2: filterText2,
            duration: duration,
        };
        setClearFilterFlag(false);
        gridApi.setFilterModel(null);
        filterchanged(filtervalue);
    };

    const filterchanged = (value) => {
        console.log(value);

        if (
            value.filterType === "text" &&
            value.filterName !== "clear" &&
            value.filterName !== "" &&
            value.filterName !== undefined
        ) {
            var tableData = cacheData;
            var filtered = [];
            switch (value.filterName) {
                case "contains":
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableData[i][value.column] &&
                            tableData[i][value.column]
                                .toLowerCase()
                                .indexOf(value.filterText.toLowerCase()) >= 0
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "doesn't contain":
                    for (let i = 0; i < tableData.length; i++) {
                        if (tableData[i][value.column] &&
                            tableData[i][value.column]
                                .toLowerCase()
                                .indexOf(value.filterText.toLowerCase()) === -1
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "is":
                    for (let i = 0; i < tableData.length; i++) {
                        if (tableData[i][value.column] &&
                            tableData[i][value.column].toLowerCase() ===
                            value.filterText.toLowerCase()
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "isn't":
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableData[i][value.column] &&
                            tableData[i][value.column].toLowerCase() !==
                            value.filterText.toLowerCase()
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "starts with":
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableData[i][value.column] &&
                            tableData[i][value.column]
                                .toLowerCase()
                                .indexOf(value.filterText.toLowerCase()) === 0
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "ends with":
                    for (var i = 0; i < tableData.length; i++) {
                        var index = tableData[i][value.column]
                            .toLowerCase()
                            .lastIndexOf(value.filterText.toLowerCase());
                        if (
                            index >= 0 &&
                            index ===
                            tableData[i][value.column].length - value.filterText.length
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "is empty":
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableData[i][value.column] && tableData[i][value.column] === "") {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "is not empty":
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableData[i][value.column] && tableData[i][value.column] !== "") {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                default:
                    // should never happen
                    console.warn("invalid filter name " + value.filterName);
                    return false;
            }
        } else if (
            value.filterType === "number" &&
            value.filterName !== "clear" &&
            value.filterName !== "" &&
            value.filterName !== undefined
        ) {
            tableData = cacheData;
            let newData = filterNumberData(
                value.filterName,
                tableData,
                value.column,
                value.filterText,
                value.filterText2
            );
            setRowData(newData);
            return true;
        } else if (
            value.filterType === "radio" &&
            value.filterName !== "clear" &&
            value.filterName !== "" &&
            value.filterName !== undefined
        ) {

            tableData = cacheData;
            var newData = [];

            switch (value.filterName) {
                case "Selected":
                    newData = tableData.filter((e) => (e[value.column] ? e[value.column].toLowerCase() : e[value.column]) === "yes");
                    setRowData(newData);
                    return true;
                case "Unselected":
                    newData = tableData.filter((e) => (e[value.column] ? e[value.column].toLowerCase() : e[value.column]) === "no");
                    setRowData(newData);
                    return true;
            }

        } else if (
            value.filterType === "date" &&
            value.filterName !== "clear" &&
            value.filterName !== "" &&
            value.filterName !== undefined
        ) {
            tableData = cacheData;
            var filtered = [];
            var todayDate = new Date();
            switch (value.filterName) {
                case "in the last":
                    if (value.duration === "days") {
                        todayDate.setDate(todayDate.getDate() - value.filterText);
                        console.log(todayDate);
                    } else if (value.duration === "weeks") {
                        var days = value.filterText * 7;
                        todayDate.setDate(todayDate.getDate() - days);
                        console.log(todayDate);
                    } else if (value.duration === "months") {
                        todayDate.setMonth(todayDate.getMonth() - value.filterText);
                    }
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        if (tableDate > todayDate) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "due in":
                    return true;
                case "On":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        var filterDate = new Date(value.filterText);
                        if (
                            tableDate.getDate() === filterDate.getDate() &&
                            tableDate.getMonth() === filterDate.getMonth() &&
                            tableDate.getYear() === filterDate.getYear()
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "before":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        var filterDate = new Date(value.filterText);
                        if (tableDate < filterDate) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "after":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        var filterDate = new Date(value.filterText);
                        if (tableDate > filterDate) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "between":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        var filterDate = new Date(value.filterText);
                        var filterDate2 = new Date(value.filterText2);
                        if (tableDate > filterDate && tableDate < filterDate2) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "Today":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        if (
                            tableDate.getDate() === todayDate.getDate() &&
                            tableDate.getMonth() === todayDate.getMonth() &&
                            tableDate.getYear() === todayDate.getYear()
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "Yesterday":
                    todayDate.setDate(todayDate.getDate() - 1);
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        if (
                            tableDate.getDate() === todayDate.getDate() &&
                            tableDate.getMonth() === todayDate.getMonth() &&
                            tableDate.getYear() === todayDate.getYear()
                        ) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "This Week":
                    todayDate.setDate(todayDate.getDate() - 7);
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);

                        if (tableDate > todayDate) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "This Month":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        if (tableDate.getMonth() === todayDate.getMonth()) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "This Month":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        if (tableDate.getYear() === todayDate.getYear()) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "Last Week":
                    return true;
                case "Last Month":
                    for (var i = 0; i < tableData.length; i++) {
                        var tableDate = new Date(tableData[i][value.column]);
                        if (tableDate.getMonth() === todayDate.getMonth() - 1) {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "is empty":
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableDate === "") {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                case "is not empty":
                    for (var i = 0; i < tableData.length; i++) {
                        if (tableDate !== "") {
                            filtered.push(tableData[i]);
                        }
                    }
                    setRowData(filtered);
                    return true;
                default:
                    console.warn("invalid filter name " + value.filterName);
                    return false;
            }
        } else if ((value.filterName = "clear")) {
            setRowData(cacheData);
        }
    };

    const filterNumberData = (
        operation,
        data,
        colText,
        filterText,
        filterText2
    ) => {
        switch (operation) {
            case ">=":
                return data.filter((e) => parseInt(e[colText]) >= parseInt(filterText));
            case "=":
                return data.filter((e) => parseInt(e[colText]) == parseInt(filterText));
            case ">":
                return data.filter((e) => parseInt(e[colText]) > parseInt(filterText));
            case "<":
                return data.filter((e) => parseInt(e[colText]) < parseInt(filterText));
            case "<=":
                return data.filter((e) => parseInt(e[colText]) <= parseInt(filterText));
            case "!=":
                return data.filter(
                    (e) => parseInt(e[colText]) !== parseInt(filterText)
                );
            case "between":
                return data.filter(
                    (e) =>
                        parseInt(e[colText]) > parseInt(filterText) &&
                        parseInt(e[colText]) < parseInt(filterText2)
                );
            case "not between":
                console.log("not between" + filterText + "" + filterText2);
                let lessArr = data.filter(
                    (e) => parseInt(e[colText]) < parseInt(filterText)
                );
                let greatrArr = data.filter(
                    (e) => parseInt(e[colText]) > parseInt(filterText2)
                );
                let final = lessArr.concat(greatrArr);
                return final;

            case "is empty":
                let emptyArr = [];
                return emptyArr;
            case "is not empty":
                return data;
        }
    };

    const onSearchColumns = (value) => {
        let newList = [...headerData];
        if (value == '') {
            newList.forEach((header) => {
                if (header.name) {
                    header.isSearch = true;
                }
            })
        } else {
            newList.forEach((header) => {
                if (header.name && header.name.toLowerCase().includes(value.toLowerCase())) {
                    header.isSearch = true;
                } else {
                    header.isSearch = false;
                }
            })
        }
        setSearchValue(value);
        setheaderData(newList)

    };

    const showTilesOfList = (params) => {
        let list = params.data.Services;
        let str = ''
        if (list.length > 0) {
            str = list.map(function (elem) {
                return '<span>' + elem.label + '</span>'
                // list.map(function (elem) {
                // return elem.PipelineStatus;
            })
                .join(" ")
        }
        const eDiv = document.createElement('div');
        eDiv.classList.add("tilesWrapper");
        // eDiv.addClass('tilesWrapper');
        eDiv.innerHTML = str;
        return eDiv;
    }

    const showTilesOfZoningList = (params) => {
        let list = params.data.zoningList;
        let str = ''
        if (list.length > 0) {
            str = list.map(function (elem) {
                return '<span>' + elem.zoningdistrict + '</span>'
                // list.map(function (elem) {
                // return elem.PipelineStatus;
            })
                .join(" ")
        }
        const eDiv = document.createElement('div');
        eDiv.classList.add("tilesWrapper");
        // eDiv.addClass('tilesWrapper');
        eDiv.innerHTML = str;
        return eDiv;
    }
    const showTilesOfCommercialList = (params) => {
        let list = params.data.commercialList;
        let str = ''
        if (list.length > 0) {
            str = list.map(function (elem) {
                return '<span>' + elem.commercialoverlay + '</span>'
                // list.map(function (elem) {
                // return elem.PipelineStatus;
            })
                .join(" ")
        }


        const eDiv = document.createElement('div');
        eDiv.classList.add("tilesWrapper");
        // eDiv.addClass('tilesWrapper');
        eDiv.innerHTML = str;
        return eDiv;
    }

    const changeFilterState = () => {
        setFilterState(!filterState);
    };


    const [clearFilterFlag, setClearFilterFlag] = useState(false);
    const clearFilters = () => {
        gridApi.setFilterModel(null);
        setClearFilterFlag(true);
        setShowFilter('')
    }

    const onColumnPinned = (event) => {
        var allCols = event.columnApi.getAllGridColumns();
        var allFixedCols = allCols.filter(function (col) {
            return col.getColDef().lockPosition;
        });
        var allNonFixedCols = allCols.filter(function (col) {
            return !col.getColDef().lockPosition;
        });
        var pinnedCount = allNonFixedCols.filter(function (col) {
            return col.getPinned() === 'left';
        }).length;
        var pinFixed = pinnedCount > 0;
        var columnStates = [];
        allFixedCols.forEach(function (col) {
            if (pinFixed !== col.isPinned()) {
                columnStates.push({
                    colId: col.getId(),
                    pinned: pinFixed ? 'left' : null,
                });
            }
        });
        if (columnStates.length > 0) {
            event.columnApi.applyColumnState({ state: columnStates });
        }
    };
    //-------------------------------------------------------------------------------------------

    const FormatDateData = (params) => {
        getPagingValues();
        let keyData = params.value;
        return keyData ? (new Date(keyData)).toLocaleDateString() : '';
    }

    const setGridApiRef = (val) =>{
        setGridApi(val)
    }

    const getAppliedFiltersforView = (data) =>{
        console.log("dataaaaaaaaaaaaaaa", data);
        // setGridApi()
    }

    const navigateToProject = (params) => {
        let keyData = params.data.Vendor_Name;
        const eDiv = document.createElement('div');
        eDiv.innerHTML = '<a class="underline-text">' + keyData + '</a>';
        const eButton = eDiv.querySelectorAll('.underline-text')[0];
        eButton.addEventListener('click', () => {
            history.push({
                pathname: '/vendorupdate/Vendors/Update/' + params.data.id,
                state: { data: params.data, action: 'Update', module: 'Vendors' }
            });
        });
        return eDiv;
    }

    const excelFilterParams = {
        // can be 'windows' or 'mac'
        excelMode: 'windows',
    };

    const NumberfilterParams = {
        filters: [
            {
                filter: 'agNumberColumnFilter',
            },
            {
                filter: 'agSetColumnFilter',
            },
        ],
    }


    const dateFilterParams = {
        filters: [
            {
                filter: 'agDateColumnFilter',
                filterParams: {
                    comparator: function (filterDate, cellValue) {
                        if (cellValue == null) return -1;

                        return getDate(cellValue) - filterDate;
                    },
                },
            },
            {
                filter: 'agSetColumnFilter',
                filterParams: {
                    comparator: function (a, b) {
                        return getDate(a) - getDate(b);
                    },
                },
            },
        ],
    };
    function getDate(value) {
        var dateParts = value.split('/');
        return new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );
    }

    const getViewColumns = (viewData) => { 
        // let viewNames =  viewData.map(obj=> {
        //     return obj.fields
        // });
       if(viewData=='All'){
        let columnsData = [...cacheHeaderData];
        columnsData.forEach(e=>e.isChecked=false)
        setheaderData(columnsData);
       }else{
        let viewApiNames = viewData.map(one => {
            return one.id
        })
        let filterViewData = [];
        let columnsData = [...cacheHeaderData];
     
        for (let i = 0; i < columnsData.length; i++) {
            if (viewApiNames.includes(columnsData[i].id)) {
                // filterViewData.push(columnsData[i]);
                columnsData[i].isChecked = false
            }
            else {
                columnsData[i].isChecked = true;
            }
        }
        setheaderData(columnsData);
       }
    }

    return (
        <div className="vendor-list-wrap">
            <div className="pad-col">
                <Row className="row-mar-0">
                    {/* <div className="col-sm-2 pr-0 pad-r0">
                        {filterState == true ? <Filter setShowFilter={setShowFilter} clearFilterFlag={clearFilterFlag} applyFilters={applyFilters} headerData={headerData} /> : <></>}
                    </div> */}
                    <div className="col-sm-12 vendors-module" style={{padding : "0px"}}>
                        <Col
                            className={
                                filterState == true
                                    ? "col-8-border outer pos-relative"
                                    : "col-8-border active-deal col-sm-12 outer pos-relative"
                            }
                        >
                            {/* {filterState ? firstActive ? (
                                <img
                                    className="filter-expand-lefticon"
                                    onClick={changeFilterState}
                                    src={leftexpandgray}
                                />
                            ) : (
                                <img
                                    className="filter-expand-lefticon"
                                    onClick={changeFilterState}
                                    src={leftexpand}
                                />
                            ) : firstActive ? (
                                <img
                                    className="filter-expand-lefticonactive"
                                    onClick={changeFilterState}
                                    src={leftexpandactivegray}
                                />
                            ) : (
                                <img
                                    className="filter-expand-lefticonactive"
                                    onClick={changeFilterState}
                                    src={leftexpandactive}
                                />
                            )} */}

                            <TabView
                                location={location}
                                getViewColumns={getViewColumns} 
                                headerData={headerData}
                                setPreAppliedFiltersForView={setPreAppliedFiltersForView}
                                setActiveFiltersOnColumnLevel={setActiveFiltersOnColumnLevel}
                                setActiveFilterAndGetActiveFilters={
                                  setActiveFilterAndGetActiveFilters
                                }
                                activeFiltersOnColumnLevel={activeFiltersOnColumnLevel}
                                preAppliedFiltersForView={preAppliedFiltersForView}
                                appliedFiltersForView={appliedFiltersForView}
                                setappliedFiltersForView={setappliedFiltersForView}
                            />
                            <div className="ag-theme-alpine">
                                <div className="example-wrapper">
                                    {/* <div className="example-header"> */}
                                        {/* <Row>
                                            <Col
                                                sm={filterState ? 4 : 3}
                                                className={
                                                    filterState
                                                        ? "dealtablesearchdiv-active"
                                                        : "dealtablesearchdiv"
                                                }
                                            >
                                                <div className="pos-relative">
                                                    <Input
                                                        type="text"
                                                        onChange={(e) =>
                                                            onSearchFilterTexts(e.target.value)
                                                        }
                                                        placeholder="Search"
                                                        className="mr-sm-2 pad-fix search-filter"
                                                    />

                                                    <FontAwesomeIcon
                                                        className="mr-sm-2 search-ico"
                                                        icon={faSearch}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={filterState ? 1 : 2}></Col>
                                            <Col sm={7}>
                                                <div className="float-right">
                                                    <select
                                                        className="dealtable-dropdown"
                                                        onChange={() => onPageSizeChanged()}
                                                        id="page-size"
                                                    >
                                                        <option value="5">
                                                            5 Records Per Page
                                                        </option>
                                                        <option value="10">10 Records Per Page</option>
                                                        <option value="15">15 Records Per Page</option>
                                                        <option value="25">25 Records Per Page</option>
                                                        <option value="50" selected={true}>50 Records Per Page</option>
                                                        <option value="75">75 Records Per Page</option>
                                                        <option value="100">100 Records Per Page</option>
                                                    </select>
                                                    <div className="dot-fix">
                                                        <FontAwesomeIcon
                                                            className={
                                                                dotActive
                                                                    ? "pad-icon-circle-active"
                                                                    : "pad-icon-circle"
                                                            }
                                                            icon={faCircle}
                                                        />
                                                    </div>
                                                    <div className="rownum-fix">
                                                        {" "}
                                                        {from} to {to} of {totalRow}
                                                    </div>
                                                    <div className="dot-fix">
                                                        <FontAwesomeIcon
                                                            className={
                                                                backActive ? "pad-icon-active" : "pad-icon"
                                                            }
                                                            onClick={() => onBtDoublePrevious()}
                                                            icon={faAngleDoubleLeft}
                                                        />
                                                        <FontAwesomeIcon
                                                            className={
                                                                backActive ? "pad-icon-active" : "pad-icon"
                                                            }
                                                            onClick={() => onBtPrevious()}
                                                            icon={faAngleLeft}
                                                        />
                                                        <FontAwesomeIcon
                                                            className={
                                                                frontActive ? "pad-icon-active" : "pad-icon"
                                                            }
                                                            onClick={() => onBtNext()}
                                                            icon={faAngleRight}
                                                        />
                                                        <FontAwesomeIcon
                                                            className={
                                                                frontActive ? "pad-icon-active" : "pad-icon"
                                                            }
                                                            onClick={() => onBtDoubleNext()}
                                                            icon={faAngleDoubleRight}
                                                        />
                                                    </div>
                                                </div>
                                                <button className='clear-filter btn btn-sm btn-primary' style={{display : showFilter != "" ? "block" : "none"}} onClick={clearFilters}>Clear Filter</button>
                                            </Col>
                                        </Row> */}
                                    {/* </div> */}
                                    <div
                                        id="myGrid"
                                        // style={
                                        //     filterState == true
                                        //         ? {
                                        //             height: 'calc(100vh - 277px)',
                                        //             width: "100%",
                                        //             padding: 15,
                                        //             paddingTop: 0,
                                        //         }
                                        //         : {
                                        //             height: 'calc(100vh - 248px)',
                                        //             width: "100%",
                                        //             padding: 15,
                                        //             paddingTop: 0,
                                        //         }
                                        // }
                                        className="ag-theme-alpine"
                                    >
                                        {/* <AgGridReact
                                            modules={(AllCommunityModules, AllModules)}
                                            paginationNumberFormatter={function (params) {
                                                return params.value.toLocaleString();
                                            }}
                                            defaultColDef={{
                                                sortable: true,
                                                resizable: true,
                                                flex: 1,
                                                minWidth: 140,
                                            }}
                                            frameworkComponents={{
                                                columnFilter: ColumnFilter,
                                            }}
                                            cacheQuickFilter={true}
                                            suppressDragLeaveHidesColumns={true}
                                            suppressPaginationPanel={true}
                                            onColumnPinned={onColumnPinned}
                                            // debug={true}
                                            rowSelection={"multiple"}
                                            groupSelectsChildren={true}
                                            suppressRowClickSelection={true}
                                            enableRangeSelection={true}
                                            pagination={true}
                                            paginationPageSize={50}
                                            onGridReady={onGridReady}
                                            onPaginationChanged={getPagingValues}
                                            rowData={rowData}
                                        >
                                            <AgGridColumn
                                                field=""
                                                maxWidth={55}
                                                lockPosition={true}
                                                // pinned='left'
                                                cellClass="locked-col"
                                                checkboxSelection={checkboxSelection}
                                                headerCheckboxSelection={headerCheckboxSelection}
                                                cellStyle={staticCellStyle}
                                                suppressNavigable={true}
                                            ></AgGridColumn>
                                            {updatedHeaderData().map((e) => (
                                                console.log(e.field),
                                                <AgGridColumn
                                                    //  hide={e.name === "Job Number" ? false : e.isChecked}
                                                    hide={e.isChecked}

                                                    headerName={e.name}
                                                    filter={'agMultiColumnFilter'}
                                                    //filterParams={e.type=='text'?excelFilterParams:e.type=='number'?NumberfilterParams:dateFilterParams}
                                                    filterParams={
                                                        e.type === "text" ? excelFilterParams :
                                                            e.type === "number" ? NumberfilterParams :
                                                                dateFilterParams
                                                    }
                                                    // filter={
                                                    //   e.type === "text" ? "agTextColumnFilter" :
                                                    //     e.type === "number" ? "agNumberColumnFilter" :
                                                    //       "agDateColumnFilter"
                                                    // }
                                                    // filter={'agMultiColumnFilter'}
                                                    // filterParams={excelFilterParams}
                                                    // lockPosition={false}
                                                    field={e.field}
                                                    // pinned={e.name === "Job Number" ? "left" : null}
                                                    minWidth={150}
                                                    // cellClass={
                                                    //   e.name === "Job Number" ? "locked-col" : null
                                                    // }
                                                    suppressNavigable={e.name === "Project Number" ? true : false}
                                                    cellStyle={staticCellStyle}
                                                    // lockPosition={
                                                    //   e.name === "Job Number" ? true : false
                                                    // }
                                                    headerTooltip={e.name}
                                                    cellRenderer={e.field === "Vendor_Name" ? navigateToProject : e.name}


                                                />
                                            ))}
                                        </AgGridReact> */}
                                        <GlobalAgGridTable
                                            headers={columnHeaders}
                                            setRowData={setRowData}
                                            cacheRowData={cacheData}
                                            setCacheRowData={setcacheData}
                                            module="Vendors"
                                            // mainUrl="Vendors"
                                            DropDownFields={["Vendor Name", "Company Name", "City", "State", "Email", "Phone"]}
                                            rowData={rowData}
                                            setGridApiRef= {setGridApiRef}
                                            getAppliedFiltersforView={getAppliedFiltersforView}
                                            // renderExtraComponent={extraComponentForTable}
                                            // renderExtraComponentToRight={extraComponentToRight}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className={filterState == true ? "non-tab-div-active" : "non-tab-div"}>
                                <div>
                                    {" "}
                                    <Dropdown className="editColumn">
                                        <Dropdown.Toggle
                                            id="dropdown-editcolumn"
                                            className="float-right edit-colum-button"
                                        >
                                            Edit Columns
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <FormGroup className="pos-relative editcol-searchpad">
                                                <Input
                                                    type="text"
                                                    placeholder="Search"
                                                    className="mr-sm-2 pad-fix search-filter"
                                                    value={searchValue}
                                                    onChange={(e) => onSearchColumns(e.target.value)}
                                                />

                                                <FontAwesomeIcon
                                                    className="mr-sm-2 search-ico"
                                                    icon={faSearch}
                                                />

                                                <div className="select-all-editColumn">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const headers = [...headerData];
                                                            headers.forEach(obj => obj.isChecked = (!e.currentTarget.checked));
                                                            setheaderData(headers);
                                                        }}
                                                    ></input>
                                                    {"  "}
                                                    <span className={"editColumnlist-label "}
                                                    >Select All</span>
                                                </div>


                                                <hr className="hr-line" />
                                            </FormGroup>
                                            <ul className='editColummn-list'>


                                                {headerData.map((e, index) =>
                                                    (e.name && e.isSearch) ? (
                                                        <ListGroupItem
                                                            className="editColumnlist-text"
                                                            draggable
                                                            onDrag={(ev) => onDragColumns(ev, e, headerData)}
                                                            onDragOver={(ev) => onDragOverColumns(ev, e, headerData)}
                                                            onDrop={(ev) => onDropColumns(ev, e, headerData)}
                                                        >
                                                            <img className="icon-grip-vertical" src={doticon} />
                                                            <input
                                                                type="checkbox"
                                                                checked={!e.isChecked}
                                                                onChange={(e) => handleColumns(e, index)}
                                                            ></input>
                                                            {"  "}
                                                            <span className={e.isDragOver ? "editColumnlist-label add-line" : "editColumnlist-label"}
                                                            >{e.name}</span>
                                                            {e.isDragOver ? <hr className="hr-line" /> : null}
                                                        </ListGroupItem>
                                                    ) : null
                                                )}
                                            </ul>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="clear"></div>
                                </div>
                            </div> */}
                        </Col>
                    </div>
                </Row>
            </div>

        </div>
    );
};

var checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
};
var headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
};



export default VendorList;
