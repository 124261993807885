import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useHistory, withRouter, useLocation, Prompt } from 'react-router-dom';
import FontAwesome from "react-fontawesome";
import { Button, Row, Col, FormGroup, Label, Input, Table } from "reactstrap";
import HttpService from "../../services/http.service";
import Select from "react-dropdown-select";
import "./UserForm.css";
import MultiSelect from "react-select";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import UserService from "../../common/util/UserService";

const UserForm = (props) => {
    const location = useLocation();
    const animatedComponents = makeAnimated();
    const [options, setOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);



    const [fileName, setFileName] = useState("");
    const [UsermailInvalid, setUserMailInvalid] = useState(false);
    const [PasswordInvalid, setPasswordInvalid] = useState(false);
    const [NumInvalid, setNumInvalid] = useState(false);
    const [frstNameInvalid, setFrstNameInvalid] = useState(false);
    const [lastNameInvalid, setLastNameInvaild] = useState(false);
    const [addressInvalid, setAddressInvalid] = useState(false);
    const [cityInvalid, setCityInvalid] = useState(false);
    const [stateInvalid, setStateInValid] = useState(false);
    const [zipInvalid, setZipInvalid] = useState(false);
    const [ProfilePhoto, setProfilePhoto] = useState("");
    const [UseremailAdd, setUserEmailAdd] = useState("");
    const [Password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [ActiveChecked, setActiveChecked] = useState(false);
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [superviser, setSuperviser] = useState(false);
  
    const [countryName, setCountryName] = useState('');
  
    const [selectedClientId, setSelectedClientId] = useState("");
    const [selectedClient, setSelectedClient] = useState([]);
    const [frstName, setFrstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [file, setFile] = useState(null);
    const [lunchStartTime, setLunchStartTime] = useState('');
    const [lunchEndTime, setLunchEndTime] = useState('');
    const [inTime, setInTime] = useState('');
    const [outTime, setOutTime] = useState('');
    const [saturdayWorking, setSaturdayWorking] = useState(false);
    const [saturdayInTime, setSaturdayInTime] = useState("");
    const [saturdayOutTime, setSaturdayOutTime] = useState("");
  
    const [sundayWorking, setSundayWorking] = useState(false);
    const [sundayInTime, setSundayInTime] = useState("");
    const [sundayOutTime, setSundayOutTime] = useState("");
    const [userData, setUserData] = useState(null);

    const [allRoles,setAllRoles]=useState([])

    useEffect(()=>{
        UserService.GetAllUserRoles()
        .then((result)=>{
            result.data.roles.forEach((one,index,self)=>{
                let obj={ label: one.name, value: one.id, obj: one }
                self[index]=obj
            })
            setAllRoles(result.data.roles);
            setOptions(result.data.roles)
        })
        .catch((err)=>{
            console.log(err);
        })
    },[])
  
    const resetAllFields = () => {
        setUserEmailAdd("");
        setPhoneNumber("");
        setActiveChecked(false);
        setSelectedRole("");
        setFrstName("");
        setLastName("");
        setState("");
        setAddress("");
        setPassword("");
        setCity("");
        setZip("");
        setSelectedCategory('');
        setSuperviser(false);
        setSelectedClient([]);
        setProfilePhoto("");
        setCountryName("");
      }

    let handleUsermailid = (email) => {
        const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
        setUserEmailAdd(email);
        if (reg.test(email)) {
            console.log("true");
            setUserMailInvalid(false);
        } else {
            /// setContractorEmailAdd("");
            if (email.length == 0) {
                setUserMailInvalid(false);
            } else {
                setUserMailInvalid(true);
            }
        }
    };

    let onChangeUserEmailAdd = (email) => {
        if (email.length == 0) {
            setUserMailInvalid(false);
        }
        setUserEmailAdd(email);
    };

    const onChangePhoneNumber = (value) => {
        var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
        if (value1.length < 10) {
            setPhoneNumber(value);
        }
        if (value1.length == 12) {
            setPhoneNumber("");
        }
        if (value1.length == 10) {
            setPhoneNumber(
                "(" +
                value1.substring(0, 3) +
                ") " +
                value1.substring(3, 6) +
                "-" +
                value1.substring(6, 10)
            );
            setNumInvalid(false);
        }
        if (value.length == 0) {
            setNumInvalid(false);
        }
    };

    const onBlurPhoneNumber = (value) => {
        var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
        if (value1.length < 10 && value.length != 0) {
           setNumInvalid(true);
        } else {
       setNumInvalid(false);
        }
    };

    function handleErrors(type) {
        console.log("click event");
        if (type === "FirstName") {
            if (frstName.length === 0) {
                console.log("change ");
               setFrstNameInvalid(true);
            } else {
               setFrstNameInvalid(false);
            }
        }
        if (type === "LastName") {
            if (lastName.length === 0) {
                console.log("change ");
              setLastNameInvaild(true);
            } else {
            setLastNameInvaild(false);
            }
        }
        // if (type === "Password") {
        //   if (props.Password.length === 0) {
        //     props.setPasswordInvalid(true);
        //   } else {
        //     props.setPasswordInvalid(false);
        //   }
        // }
        if (type === "Address") {
            if (address.length === 0) {
              setAddressInvalid(true);
            } else {
                setAddressInvalid(false);
            }
        }
        if (type === "City") {
            if (city.length === 0) {
               setCityInvalid(true);
            } else {
               setCityInvalid(false);
            }
        }
        if (type === "State") {
            if (state.length === 0) {
              setStateInValid(true);
            } else {
               setStateInValid(false);
            }
        }
        if (type === "Zip") {
            if (zip && zip.length === 0) {
                setZipInvalid(true);
            } else {
                setZipInvalid(false);
            }
        }
    }


    const Option = props => {
        return (
            <div>
                <components.Option {...props}>
                    {props.value !== 'all' ? <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    /> : <></>}{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );


    const handleSaveUser=()=>{
        let data = {
  
            "FirstName": frstName,
            "LastName": lastName,
            "Email": UseremailAdd,
            "Password": Password,
            "Address": address,
            "City": city,
            "State": state,
            "Zip": zip,
            "Phone": phoneNumber,
            "Role": selectedRole,
            "Category": selectedCategory,
            "Superviser": superviser,
            "ClientUnderSupervision": selectedClient,
            "Country":countryName,
            "Active": ActiveChecked,
            "ProfilePhoto": fileName
          }

          let obj={
            "role": selectedRole[0].value,
            "first_name": frstName,
            "email": UseremailAdd,
            "profile": "4695950000000026014",
            "last_name": lastName
          }

          UserService.CreateUser(obj)
          .then((result)=>{
            console.log(result.data)
            props.closeModal()
          })
          .catch((err)=>{
            console.log(err);
          })
    }


    return (
        <>
            <FormGroup className="createdeal-headpadding">
                <Row className="row-mar-0" style={{display : "flex", alignItems : "center"}}>
                    <Col sm={5} className="heading-text-padding">
                        <div className="jobhead-box">

                            <span className={props.from == 'popup' ? "jobnumber-heding-text pl-0" : "jobnumber-heding-text"}> <strong>Create User</strong></span>

                        </div>
                    </Col>
                    <Col sm={3}></Col>
                    <Col sm={4}>

                        <div className="save-cancelbox">
                            <div className="create-dealbox2">
                                <Button className=" top-botton-pad blue-background3D"
                                    onClick={() => {
                                        props.closeModal()
                                    }}
                                >Cancel</Button>
                            </div>
                            <div className="create-dealbox3 ">
                                <Button
                                    className=" top-botton-pad blue-background3D"
                                    onClick={() => {
                                        handleSaveUser()
                                    }}
                                >Save</Button>
                            </div>


                        </div>

                    </Col>

                </Row>

            </FormGroup>
            <Row className="mar-15 create-user-box">
                <Col sm={12} className="createuser-border">
                    {/* <div className="create-form-header">Create User</div> */}
                    <div className="user-form-border">
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        First Name<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        className={frstNameInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={frstName}
                                        onChange={(e) => setFrstName(e.target.value)}
                                        onBlur={(e) => handleErrors("FirstName")}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                            frstNameInvalid == true
                                                ? "show-error font12"
                                                : "error-display"
                                        }
                                    >
                                        {" "}
                                        Field should not be empty.
                                    </span>
                                </Col> */}
                                {/* <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        Last Name<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        className={lastNameInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onBlur={(e) => handleErrors("LastName")}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                            lastNameInvalid == true
                                                ? "red-text font12"
                                                : "error-display"
                                        }
                                    >
                                        {" "}
                                        field should not be empty.
                                    </span>
                                </Col>
                                <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        Email<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        className={UsermailInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={UseremailAdd}
                                        placeholder="abc@mail.com"
                                        onChange={(e) => onChangeUserEmailAdd(e.target.value)}
                                        onBlur={(e) => handleUsermailid(e.target.value)}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                        UsermailInvalid == true
                                                ? "red-text font12"
                                                : "error-display"
                                        }
                                    >
                                        {" "}
                                        Incorrect E-mail Address format.
                                    </span>
                                </Col>
                                <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        Address<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        className={addressInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        onBlur={(e) => handleErrors("Address")}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                            addressInvalid == true
                                                ? "red-text font12"
                                                : "error-display"
                                        }
                                    >
                                        Field should not be empty.
                                    </span>
                                </Col>
                                <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        City<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        className={cityInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        onBlur={(e) => handleErrors("City")}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                            cityInvalid == true
                                                ? "red-text font12"
                                                : "error-display"
                                        }
                                    >
                                        {" "}
                                        Fields should not be empty.
                                    </span>
                                </Col>
                                <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        State<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        className={stateInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        onBlur={(e) => handleErrors("State")}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                            stateInvalid == true
                                                ? "red-text font12"
                                                : "error-display"
                                        }
                                    >
                                        {" "}
                                        Fields should not be empty.
                                    </span>
                                </Col>
                                <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        Zip<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        className={zipInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                        onBlur={(e) => handleErrors("Zip")}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                            zipInvalid == true
                                                ? "red-text font12"
                                                : "error-display"
                                        }
                                    >
                                        {" "}
                                        Fields should not be empty.
                                    </span>
                                </Col>
                                <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        Countries/Region
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <MultiSelect

                                        options={[]}
                                        // isMulti
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        //components={{Option, MultiValue, animatedComponents}}
                                        onChange={(values) => {
                                            props.setCountryName(values.value)
                                        }}
                                        allowSelectAll={true}
                                        value={[{ label: props.countryName, value: props.countryName }]}
                                        menuPlacement="auto"
                                    />
                                </Col>

                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        Phone<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Input
                                        type="text"
                                        name="text"
                                        placeholder="(###)### ####"
                                        className={NumInvalid ? "show-error create-user-input" : "create-user-input"}
                                        value={phoneNumber}
                                        onKeyDown={(evt) =>
                                            ((evt.keyCode > 64 && evt.keyCode < 91) ||
                                                evt.key === ".") &&
                                            evt.preventDefault()
                                        }
                                        onChange={(e) => onChangePhoneNumber(e.target.value)}
                                        onBlur={(e) => onBlurPhoneNumber(e.target.value)}
                                    />
                                </Col>
                                {/* <Col sm={4}>
                                    <span
                                        className={
                                            NumInvalid == true
                                                ? "red-text font12"
                                                : "error-display"
                                        }
                                    >
                                        {" "}
                                        Enter 10 digit Phone Number.
                                    </span>
                                </Col>
                                <Col sm={3}></Col> */}
                            </Row>
                        </FormGroup>
                        {/* <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  In Time<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={3}>
              <Input
                  type="time"
                  name="OutTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.inTime} 
                  onChange={(e) => props.setInTime(e.target.value)}
                />
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
                        {/* <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Out Time<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={3}>
              <Input
                  type="time"
                  name="outTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.outTime}
                  onChange={(e) => props.setOutTime(e.target.value)}
                  
                />
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
                        {/* <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Lunch Start Time<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={3}>
              <Input
                  type="time"
                  name="lunchStartTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.lunchStartTime}
                  onChange={(e) => props.setLunchStartTime(e.target.value)}
                  
                />
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
                        {/* <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Lunch End Time<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={3}>
              <Input
                  type="time"
                  name="lunchEndTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.lunchEndTime}
                  onChange={(e) => props.setLunchEndTime(e.target.value)}
                  
                />
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <Label className="text-bold" for="projectnumber">
                                        Roles<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={8}>
                                    <Select
                                        options={options}
                                        placeholder="Select Roles"
                                        values={selectedRole}
                                        onChange={(e) => {
                                            setSelectedRole(e);
                                        }}
                                        className="create-user-input"
                                        dropdownPosition="auto"
                                    />
                                </Col>
                                {/* <Col sm={7}></Col> */}
                            </Row>
                        </FormGroup>
                        {/* <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <Label className="text-bold" for="projectnumber">
                                        Category<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={3}>
                                    <Select
                                        options={categoryOptions}
                                        placeholder="Select Category"
                                        values={props.selectedCategory}
                                        onChange={(e) => {
                                            props.setSelectedCategory(e);
                                        }}
                                        className="create-user-input"
                                        dropdownPosition="auto"
                                    />
                                </Col>
                                <Col sm={7}></Col>
                            </Row>
                        </FormGroup> */}
                        {/* <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <Label className="text-bold" for="projectnumber">
                                        Superviser
                                    </Label>
                                </Col>
                                <Col sm={2}>
                                    <Input
                                        name="Supervisor"
                                        type="checkbox"
                                        checked={props.superviser}
                                        onChange={(e) => {
                                            props.setSuperviser(e.currentTarget.checked)
                                        }}
                                        className="create-user-checkbox"
                                    />
                                </Col>
                                <Col sm={7}></Col>
                            </Row>
                        </FormGroup> */}
                        {/* {props.superviser ?
                            <FormGroup>
                                <Row>
                                    <Col sm={2}>
                                        <Label className="text-bold" for="projectnumber">
                                            Clients Under Supervision<span className="red-text">*</span>
                                        </Label>
                                    </Col>
                                    <Col sm={3}>
                                        <MultiSelect
                                            options={clientOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{ Option, MultiValue, animatedComponents }}
                                            onChange={(values) => {
                                                let all = [...clientOptions];
                                                if (values.filter(one => one.value == 'all').length > 0) {
                                                    let filterServices = all.filter(value => value.value !== 'all');
                                                    props.setSelectedClient(filterServices)
                                                } else {
                                                    values = values.filter(value => value.value !== 'all');
                                                    props.setSelectedClient(values)
                                                }

                                            }}
                                            // onChange={(values) =>props.pipelineModalType?props.handleOnChangeValue(values): handleChangeSelect(values, 'Deal')}
                                            allowSelectAll={true}
                                            value={props.selectedClient}
                                            menuPlacement="auto"
                                        // onChange={handleServiceChange}
                                        // value={getValues()}
                                        // isMulti={true}
                                        // options={options}
                                        // isClearable={false}
                                        />
                                    </Col>
                                    <Col sm={7}></Col>
                                </Row>
                            </FormGroup>
                            : <></>}
                        <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <Label className="text-bold" for="projectnumber">
                                        Is Active
                                    </Label>
                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="checkbox"
                                        checked={props.ActiveChecked}
                                        onChange={() => props.setActiveChecked(!props.ActiveChecked)}
                                        className="create-user-checkbox"
                                    />
                                </Col>
                                <Col sm={7}></Col>
                            </Row>
                        </FormGroup> */}
                        {/* <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <Label className="text-bold">Working Days</Label>
                                </Col>
                                <Col sm={10}>
                                    <Table className="Table-workingDays">
                                        <thead>
                                            <th className="text-bold"></th>
                                            {props.weekWorkingDays && props.weekWorkingDays.map(one => (
                                                <th className="text-bold">
                                                    {one.Day}
                                                    <input
                                                        className="isWorking-checkbox"
                                                        type="checkbox"
                                                        checked={one.isWorking}
                                                    //   onChange={(e)=> handleChangeIsWorking(e.target.checked, one.Day)}
                                                    />
                                                </th>
                                            ))}
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    In Time
                                                </td>
                                                {
                                                    props.weekWorkingDays && props.weekWorkingDays.map(obj => (
                                                        <td>
                                                            <input
                                                                type="time"
                                                                name="InTime"
                                                                value={obj.InTime}
                                                                disabled={!obj.isWorking}
                                                            // onChange={(e)=>handleChangeWorkingDays(e.target.value, obj.Day,
                                                            //    e.target.name )}
                                                            /></td>
                                                    ))
                                                }
                                            </tr>
                                            <tr>
                                                <td>
                                                    Lunch Start Time
                                                </td>
                                                {
                                                    props.weekWorkingDays && props.weekWorkingDays.map(obj => (
                                                        <td>
                                                            <input
                                                                type="time"
                                                                name="LunchStartTime"
                                                                value={obj.LunchStartTime}
                                                                disabled={!obj.isWorking}
                                                            // onChange={(e)=>handleChangeWorkingDays(e.target.value, obj.Day,
                                                            //    e.target.name )}
                                                            />
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                            <tr>
                                                <td>
                                                    Lunch End  Time
                                                </td>

                                                {
                                                    props.weekWorkingDays && props.weekWorkingDays.map(obj => (
                                                        <td>
                                                            <input
                                                                type="time"
                                                                name="LunchEndTime"
                                                                value={obj.LunchEndTime}
                                                                disabled={!obj.isWorking}
                                                            //  onChange={(e)=>handleChangeWorkingDays(e.target.value, obj.Day,
                                                            //    e.target.name )}
                                                            />
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                            <tr>
                                                <td>
                                                    Out Time
                                                </td>
                                                {
                                                    props.weekWorkingDays && props.weekWorkingDays.map(obj => (
                                                        <td>
                                                            <input
                                                                type="time"
                                                                name="OutTime"
                                                                value={obj.OutTime}
                                                                disabled={!obj.isWorking}
                                                            //   onChange={(e)=>handleChangeWorkingDays(e.target.value, obj.Day,
                                                            //    e.target.name )}
                                                            />
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>

                        </FormGroup> */}

                        {/* <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Is Saturday Working<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
                <Input
                  type="checkbox"
                  checked={props.saturdayWorking}
                  onChange={() => props.setSaturdayWorking(!props.saturdayWorking)}
                  className="create-user-checkbox"
                />
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
                        {/* { props.saturdayWorking ? (
             <>
             <FormGroup>
             <Row>
               <Col sm={2}>
                 <Label className="text-bold" for="projectnumber">
                   Saturday In Time<span className="red-text">*</span>
                 </Label>
               </Col>
               <Col sm={2}>
               <Input
                  type="time"
                  name="saturdayInTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.saturdayInTime} 
                  onChange={(e) => props.setSaturdayInTime(e.target.value)}
                />
                
               </Col>
               <Col sm={7}></Col>
             </Row>
           </FormGroup>
            <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Saturday Out Time<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
              <Input
                  type="time"
                  name="saturdayOutTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.saturdayOutTime} 
                  onChange={(e) => props.setSaturdayOutTime(e.target.value)}
                />
                
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup>
          </>
          )
            : (<></>)
           
          } */}
                        {/* <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Is Sunday Working<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
                <Input
                  type="checkbox"
                  checked={props.sundayWorking}
                  onChange={() => props.setSundayWorking(!props.sundayWorking)}
                  className="create-user-checkbox"
                />
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
                        {/* { props.sundayWorking ? (
             <>
             <FormGroup>
             <Row>
               <Col sm={2}>
                 <Label className="text-bold" for="projectnumber">
                   Sunday In Time<span className="red-text">*</span>
                 </Label>
               </Col>
               <Col sm={2}>
               <Input
                  type="time"
                  name="sundayInTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.sundayInTime} 
                  onChange={(e) => props.setSundayInTime(e.target.value)}
                />
                 
               </Col>
               <Col sm={7}></Col>
             </Row>
           </FormGroup>
            <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Sunday Out Time<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
              <Input
                  type="time"
                  name="sundayOutTime"
                  placeholder="(###)### ####"
                  className="create-user-input"
                  value={props.sundayOutTime} 
                  onChange={(e) => props.setSundayOutTime(e.target.value)}
                />
               
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup>
          </>
          )
            : (<></>)
           
          } */}
                        {/* <FormGroup>
                            <Row>
                                <Col sm={2}>
                                    <Label className="text-bold" for="projectnumber">
                                        Add Profile Photo<span className="red-text">*</span>
                                    </Label>
                                </Col>
                                <Col sm={2}>
                                    <img
                                        key={Date.now()}
                                        cache={false}
                                        src={props.fileName}
                                        style={{ width: "45%" }}
                                    />
                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        id="photo"
                                        name={props.fileName}
                                        onChange={(e) => {
                                           
                                        }}
                                    />
                                   
                                </Col>
                                <Col sm={3}></Col>
                            </Row>
                        </FormGroup> */}
                        {/* <FormGroup>
            <Row>
              <Col sm={3}></Col>
              <Col sm={2}>
                <Button className="next-button next-btn3D float-right" onClick={props.saveUserData}>
                  {" "}
                  Save{" "}
                </Button>
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
                    </div>
                </Col>
            </Row>
        </>

    );
};
export default UserForm;
