import httpService from "../../services/http.service";
import axios from 'axios';
import Config from '../../config.json'

class ModuleService {
    GetModuleList(moduleName) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/crm/v2/" + moduleName,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetModuleListData(module) {
        return new Promise((resolve, reject) => {
            axios.post(Config.backend_url+'getAllPagesOfModule', {
                token:  localStorage.getItem('token'),
                module:module
            })
                .then((result) => {
                    if (result.data.error) {
                        resolve({});
                    } else {
                        if(result.data){
                            resolve(result.data.data);
                        }else{
                            resolve([]);
                        }
                       
                    }

                })
                .catch((err) => {
                    reject(err);
                })
        })

    }

    CreateModuleData(module,obj){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://www.zohoapis.com/crm/v2/" + module + "/upsert",
                headers: { "Authorization": localStorage.getItem('token') },
                data: [obj]
            }
            httpService.post('workdrive', config)
                .then(async(result) => {
                    resolve(result.data)
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }

    DeleteRecords(module,ids){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://www.zohoapis.com/crm/v2/"+module+"?ids="+ids+"&wf_trigger=true",
                headers: { "Authorization": localStorage.getItem('token') },
            }
            httpService.post('task', config)
                .then(async(result) => {
                    resolve(result.data)
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }


  


    DeleteModuleData(module,id){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://www.zohoapis.com/crm/v3/"+module+"/"+id,
                headers: { "Authorization": localStorage.getItem('token') },
                // data: [obj]
            }
            httpService.post('task', config)
                .then(async(result) => {
                    resolve(result.data)
                })
                .catch((err) => {
                    reject(`${err}`);
                })
        })
    }

   


   



}

export default new ModuleService();