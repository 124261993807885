import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import DealTableChart from "../../../../../../common/DealTableChart/dealTableChart";
import ModuleService from "../../../../../../common/util/ModuleService";
import dayjs from "dayjs";


function PreviouSolar(props) {
  const [prevsolarstate, setPrevsolartoggle] = useState(false);

  const [mailInvalid, setMailInvalid] = useState(false);
  const [prevObj, setPrevObj] = useState({})
  const [skipSolar, setSkipSolar] = useState(false);
  const [action,setAction]=useState('add');
  const [editIdx,setEditIdx]=useState(0);
  // new code
  const [validation,setValidation]=useState({
    Application_Number: "0",
    Current_Application_Status: "0",
    Applicant_Supersede_Required: "0",
    Current_Applicant: "0",
    GC_Company_Name: "0",
    GC_Name: "0",
    GC_License_Number: "0",
    GC_Supersede_Required: "0",
    Reinstatement_Required: "0",
    Withdrawal_Required: "0",
    Inspection_Passed_Date:"0",
  })

  
  const highliteEmptys=(data)=>{
      let vKeys=Object.keys(data.arr);
      let ob={};
      for(let i=0;i<vKeys.length;i++){
        if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
          ob[vKeys[i]]='-1'
        }else{
          ob[vKeys[i]]='1'
        }
      }
        setValidation(ob)
    }
    const resetValidation=()=>{
      setValidation({
          Application_Number: "0",
          Current_Application_Status: "0",
          Applicant_Supersede_Required: "0",
          GC_Supersede_Required: "0",
          Current_Applicant:"0",
          GC_Company_Name:"0",
          GC_Name:"0",
          GC_License_Number:"0",
          Reinstatement_Required: "0",
          Withdrawal_Required: "0",
          Inspection_Passed_Date:"0",
        });
  }
   // new code
  const [addInfoModal, setAddInfoModal] = useState(false);
  const [solarApplicationNumber,setSolarApplicationNumber]=useState("");
  let valid=(emailtext)=>{
    for(let i=1;i<emailtext.length;i++){
      if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
        return false;
      }
    }
    return true;
  }

  const handleChange = (e) => {
    if(e.currentTarget.name=='Inspection_Ready_Email_Address'){
      // Email validation
    let validMail=false;
    let str=e.target.value.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
      props.setEmailAdd(e.target.value);
      if ( validMail ) {
        setValidation({...validation,Inspection_Ready_Email_Address:"1"})
        setMailInvalid(false);
      }
    }
    const obj = { ...prevObj };
    if(e.currentTarget.name == 'Applicant_Supersede_Required'){
      if(e.currentTarget.value == "No"){
        obj["Current_Applicant"] = ""
      }
    }
    if(e.currentTarget.name == 'GC_Supersede_Required'){
      if(e.currentTarget.value == "No"){
        obj["GC_Company_Name"] = "";
        obj["GC_License_Number"] = "";
        obj["GC_Name"] = "";
        obj["Withdrawal_Required"] = "No";
      }
    }

    if(e.currentTarget.name == 'Reinstatement_Required'){
      if(e.currentTarget.value == "No"){
        obj["Inspection_Passed"] = "";
        obj["Inspection_Passed_Date"] = "";
      }
    }

    if(e.currentTarget.name == 'Inspection_Passed'){
      if(e.currentTarget.value == "No"){
        obj["Inspection_Passed_Date"] = "";
      }
    }
    if(e.currentTarget.name == 'Withdrawal_Required'){
      if(e.currentTarget.value == "No"){
        obj["Installation_Complete"] = "";
        obj["Inspection_Ready_Email_Address"] = "";
      }else if(e.currentTarget.value == "Yes"){
        obj["GC_Supersede_Required"] = "";
        obj["GC_Company_Name"] = "";
        obj["GC_License_Number"] = "";
        obj["GC_Name"] = "";
      }
    } 
    if(e.currentTarget.name == 'Installation_Complete'){
      if(e.currentTarget.value == "No"){
        obj["Inspection_Ready_Email_Address"] = "";
      }
    }

    if(e.currentTarget.name == 'Inspection_Passed_Date'){
      if(e.currentTarget.value == null || e.currentTarget.value == ''){
        obj["Inspection_Passed_Date"] = "";
      }
    }
    obj[e.currentTarget.name] = e.currentTarget.value;
    setPrevObj(obj);
  }
  let dataToDelete = [];
  useEffect(()=>{
    if(props.data.PreviousSolar == "No"){
      let data = {...props.data};
      if(data.Previous_Solar_Info){
        dataToDelete = data.Previous_Solar_Info.map((item)=>{ return item.id})
        handleDeleteIds("Previous_Solar_Info")
        data.Previous_Solar_Info = [];
        props.setData(data);
      }
      
    }
  },[props.data.PreviousSolar])


  const handleDeleteIds = (Module) => {
    let ids = dataToDelete;
    if (ids) {
        let doArr = [];
        ids.forEach((id) => {
            doArr.push(ModuleService.DeleteModuleData(Module, id))
        })
        Promise.all(doArr)
            .then((result) => {
                // localStorage.removeItem('deletedIds');
            })
            .catch((err) => {
                // localStorage.removeItem('deletedIds');
                console.log(err)
            })

    }
}

  const handleDelete=(info,idx)=>{
    if(info.id){
      let data = { ...props.data };
      let localIdes=localStorage.getItem('deletedIds');
      if(localIdes){
        localIdes=JSON.parse(localIdes);
      }else{
        localIdes=[];
      }
      const ids=[...localIdes];
      if(data.Previous_Solar_Info){
        data.Previous_Solar_Info=data.Previous_Solar_Info.filter((one,i)=>idx!==i);
          if(info.id){
            ids.push(info.id)
          }
      }
      props.setData(data);
      localStorage.setItem('deletedIds',JSON.stringify(ids))
    }else{
      let data = { ...props.data };
    if(data.Previous_Solar_Info){
      data.Previous_Solar_Info=data.Previous_Solar_Info.filter((one,i)=>one.Application_Number!==info.Application_Number);
    }
    props.setData(data);
    }
    
    setAddInfoModal(false)
  }
  const[dataAdded,setDataAdded]=useState(0)

  const handleSave = () => {

    let data = { ...props.data };

    const ob={... prevObj}
    let filled=true;
    if(ob['Application_Number']==undefined||ob['Application_Number']==''||ob['Application_Number'].length<9){filled=false}
    if(ob['Current_Application_Status']==undefined||ob['Current_Application_Status']==''){filled=false}
    if(ob['Applicant_Supersede_Required']==undefined||ob['Applicant_Supersede_Required']==''){ filled=false}
    if((ob['GC_Supersede_Required']==undefined || ob['GC_Supersede_Required']=='') && ob['Withdrawal_Required'] !='Yes'){filled=false}
    if(ob['Reinstatement_Required']==undefined||ob['Reinstatement_Required']==''){ filled=false}
    if(ob.Applicant_Supersede_Required=='No'){
      if(ob['Withdrawal_Required']==undefined||ob['Withdrawal_Required']==''){filled=false}
    }
    if(ob.Applicant_Supersede_Required=='Yes'&&(ob.Current_Applicant==undefined||ob.Current_Applicant=='')){
      filled=false;
    }
    if(ob.GC_Supersede_Required=='Yes'&&((ob.GC_Company_Name==undefined||ob.GC_Company_Name=='')||(ob.GC_Name==undefined||ob.GC_Name=='')||(ob.GC_License_Number==undefined||ob.GC_License_Number==''||ob['GC_License_Number'].length<6))){
      filled=false;
    }
    if(ob.Reinstatement_Required=='Yes'&&(ob.Inspection_Passed==undefined||ob.Inspection_Passed=='')){
      filled=false;
    }
    else if(ob.Reinstatement_Required=='Yes'&&ob.Inspection_Passed=='Yes'&&(ob.Inspection_Passed_Date==undefined||ob.Inspection_Passed_Date=='')){
       filled=false;
    }
    if(ob.Withdrawal_Required=='Yes' &&( ob.Installation_Complete==undefined || ob.Installation_Complete=='')){filled=false;}
    if(ob.Withdrawal_Required=='Yes' && ob.Installation_Complete=='Yes' && mailInvalid==true) {
      alert("Incorrect E-mail Address format.")
      highliteEmptys({ arr: validation });
      filled=false;
      return;
    }
      if (filled) {
        if(action=="add"){
          let tempObj=data.Previous_Solar_Info?.find(e=>e.Application_Number==prevObj.Application_Number);
          if(tempObj){
            alert("Application Number Already Present. Please Enter unique Application Number.");
            return;
          }
        }else if(action=="edit"){
          let tempObj=data.Previous_Solar_Info?.find((e,i)=>e.Application_Number==prevObj.Application_Number && i!=editIdx);
          if(tempObj){
            alert("Application Number Already Present. Please Enter unique Application Number.");
            return;
          }
        }
        if(data.Previous_Solar_Info?.length>0){
          let appNumber
          if(solarApplicationNumber!=""){
            appNumber = props.data.Previous_Solar_Info?.filter((item,i)=>item.Application_Number==solarApplicationNumber);
          }else{
            appNumber = props.data.Previous_Solar_Info?.filter((item,i)=>item.Application_Number==ob["Application_Number"]);
          }
          if(appNumber.length>0){
              if(!window.confirm(action=='edit'?"The exisitng Previous Solar details will be updated, do you still want to continue?":"Application Number Already Present, Do you want to update?")){
                return;
              }else{
                // arr.push(ob);
                data.Previous_Solar_Info[editIdx]=ob;
              }
          }else{
            data.Previous_Solar_Info.push(ob);
          }
        }else{
          data.Previous_Solar_Info = [ob]
        }
        setDataAdded(dataAdded+1);
        props.setData(data);
        setAddInfoModal(false)
        setSolarApplicationNumber("");
        resetValidation();
      } else {  
            alert("Please fill all required fields!");
            highliteEmptys({ arr: validation });
            return;
      }

  }

  const previousSolarAppHeaders = [
    { value: "Application_Number", label: "Application Number" },
    { value: "Current_Application_Status", label: "Current Application Status" },
    { value: "Applicant_Supersede_Required", label: "Applicant Supersede Required" },
    { value: "Current_Applicant", label: "Current Applicant" },
    { value: "GC_Supersede_Required", label: "GC Supersede Required" },
    { value: "GC_Company_Name", label: "GC Company Name" },
    { value: "GC_Name", label: "GC Name" },
    { value: "GC_License_Number", label: "GC License Number" },
    { value: "Reinstatement_Required", label: "Reinstatement Required" },
    { value: "Inspection_Passed", label: "Inspection Passed" },
    { value: "Inspection_Passed_Date", label: "Inspection Passed Date" },
    { value: "Withdrawal_Required", label: "Withdrawal Required" },
    { value: "Installation_Complete", label: "Installation Complete" },
    { value: "Inspection_Ready_Email_Address", label: "Inspection Ready Email Address" , flag : "Installation_Complete" },

  ]

  const prevsolartoggle = () => {
    props.settoggleState("prevsolar");
    props.setListState('prevsolar');
    setPrevsolartoggle(!prevsolarstate);
  };


  const handlePreviousSolar = () => {
    if (skipSolar) {
      props.setPreviousComplete.previousSolar = true;
      return true;
    }
    if (props.PreviousSolar === "0" || (props.appnumber !== "" && props.currentStatus !== "" &&
      props.AppSupersede !== "" && props.GeneralContractor !== "" && props.Reinstatement !== "" && props.Withdrawal !== "")) {
      props.setPreviousComplete.previousSolar = true;
      return true;
    }
    props.setPreviousComplete.previousSolar = false;
    return false;
  }

  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }

  let handlemailid = ( email ) => {
    // Email validation
    let validMail=false;
    let str=email.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
   
    props.setEmailAdd(email);
    if ( validMail ) {   
       //setValidation({...validation,Inspection_Ready_Email_Address:"1"})
      setMailInvalid(false);
    }
    else {
      //setValidation({...validation,Inspection_Ready_Email_Address:"-1"})
      if(email.length==0){
        setMailInvalid(false);
      }else{
        setMailInvalid(true);
      }
    }
  }

  useEffect(() => {
    props.toggleState == "prevsolar"
      ? setPrevsolartoggle(true)
      : setPrevsolartoggle(false);
  }, [props.toggleState]);

  useEffect(() => {
    getData()
  }, [props.data]);


  const getData = () => {
    if (props.data != null) {
      props.setPreviousSOlar(props.data.PreviousSolar);
      props.setAppSupersede(props.data.AppSupersede);
      props.setinstallation(props.data.installation);
      props.setGeneralContractor(props.data.GeneralContractor);
      props.setInspectionPass(props.data.InspectionPass);
      props.setReinstatement(props.data.Reinstatement);
      props.setWithdrawal(props.data.Withdrawal);
      props.setStartDate(Date.parse(props.data.startDate));
      props.setEmailAdd(props.data.inspectionEmailAdd);
      props.setAppNumber(props.data.appnumber);
      props.setCurrentStatus(props.data.currentStatus);

    }
  }


  const addPreviousInfoModal = () => {
    return <Modal
      className="modalwidth-500"
      isOpen={addInfoModal}
      backdrop={'static'}
    // toggle={toggle}
    >
      <div className="modal-header-class">Previous Solar Number
        <div className="note-header-icon">
          {" "}
          <FontAwesomeIcon
            icon={faTimes}
            className="icon-time-color"
            onClick={() => {setAddInfoModal(false)
              resetValidation()
            }}
          ></FontAwesomeIcon>
        </div>
      </div>
      <ModalBody className="h500-overflow">
        <FormGroup row className={
          props.data.PreviousSolar == "Yes"
            ? "ml-2"
            : "application-none"
        }>

          <FormGroup row className="marb-16 padtop-15">
            <Row>
              <Col sm={5}>
                <Label className="text-bold" for="appnumber">
                  Application Number<span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={7}>
                <Input type="text" name="Application_Number" id="block" className="createdeal-textfield"
                  value={prevObj ? prevObj.Application_Number : ''}
                  style={validation.Application_Number==='-1'?{...validationCss}:{}}
                  maxLength={9}
                  onBlur={(e) => {
                    if(e.target.value==undefined || e.target.value=='' || e.target.value.length<9){
                      setValidation({... validation, Application_Number: '-1'});
                    }else{
                      setValidation({... validation, Application_Number: '1'});
                    }
                  }
                  }
                  onChange={(e) => {
                    e.target.value=e.target.value.replace(/[\D]/,'')
                    handleChange(e);
                  }}
                  />
              </Col>

            </Row>
          </FormGroup>

          <FormGroup row className="marb-16 padtop-15">
            <Row >
              <Col sm={5}>
                <Label className="text-bold" for="appstatus">
                  Current Application's Status<span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={7}>
                <Input type="text" name="Current_Application_Status" id="block" className="createdeal-textfield"
                  value={prevObj ? prevObj.Current_Application_Status : ''}
                  onChange={(e) => {
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)
                  }}
                  style={validation.Current_Application_Status==='-1'?{...validationCss}:{}}
                  onBlur={(e) => {
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({... validation, Current_Application_Status: '-1'});
                    }else{
                      setValidation({... validation, Current_Application_Status: '1'});
                    }
                  }
                  }
                  />
              </Col>


            </Row>
          </FormGroup>
            <FormGroup row className="marb-16 padtop-15">
              <Row>
                <Col sm={6}>
                  <Label className="text-bold" for="withdrawal">
                    Withdrawal Required<span className="red-text">*</span>
                  </Label>
                </Col>

                <Col sm={3}>
                  <Label className="text-bold2">Yes</Label>
                  <Input type="radio" name="Withdrawal_Required" value="Yes"
                    checked={prevObj ? prevObj.Withdrawal_Required === "Yes" : false}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                  />
                </Col>
                <Col sm={3}>
                  <Label className="text-bold2">No</Label>
                  <Input type="radio" name="Withdrawal_Required" value="No"
                    checked={prevObj ? prevObj.Withdrawal_Required === "No" : false}
                    onChange={(e) => {
                      handleChange(e)
                    }} />
                </Col>
              </Row>
            </FormGroup>
              <FormGroup row className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="applicantsupersede">
                  Applicant Supersede Required<span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={3}>
                <Label className="text-bold2">Yes</Label>
                <Input
                  type="radio"
                  name="Applicant_Supersede_Required"
                  value="Yes"
                  checked={prevObj ? prevObj.Applicant_Supersede_Required === "Yes" : false}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </Col>
              <Col sm={3}>
                <Label className="text-bold2">No</Label>
                <Input
                  type="radio"
                  name="Applicant_Supersede_Required"
                  value="No"
                  checked={prevObj ? prevObj.Applicant_Supersede_Required === "No" : false}
                  onChange={(e) => {
                    setValidation({...validation,Current_Applicant:"0"})
                    handleChange(e)
                  }}
                />
              </Col>

            </Row>
          </FormGroup>           

          <FormGroup row className={
            (prevObj ? prevObj.Applicant_Supersede_Required == "Yes" : false)
              ? "marb-16 padtop-15"
              : "appSupersede-none"
          }>
            <Row >
              <Col sm={5}>
                <Label className="text-bold" for="currentapplicant">
                  Current Applicant
                  <span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={7}>
                <Input type="text"
                  value={prevObj ? prevObj.Current_Applicant : ''}
                  style={validation.Current_Applicant=='-1'?{...validationCss}:{}}
                  onBlur={(e)=>{
                   if(e.target.value==undefined||e.target.value==''){
                    setValidation({...validation,Current_Applicant:'-1'})
                   }else{
                    setValidation({...validation,Current_Applicant:'1'})
                   }
                  }}
                  onChange={(e) => {
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)
                  }}
                  name="Current_Applicant" id="block" className="createdeal-textfield" />
              </Col>

              <Col sm={7}></Col>
            </Row>
          </FormGroup>

        {
          prevObj.Withdrawal_Required != "Yes" ? 
          <FormGroup row className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="contractorrequired">
                  GC Supersede Required<span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={3}>
                <Label className="text-bold2">Yes</Label>
                <Input
                  type="radio"
                  name="GC_Supersede_Required"
                  value="Yes"
                  checked={prevObj ? prevObj.GC_Supersede_Required === "Yes" : false}
                  onChange={(e) => {
                    
                    handleChange(e)
                  }}
                />
              </Col>
              <Col sm={3}>
                <Label className="text-bold2">No</Label>
                <Input
                  type="radio"
                  name="GC_Supersede_Required"
                  value="No"
                  checked={prevObj ? prevObj.GC_Supersede_Required === "No" : false}
                  onChange={(e) => {
                    setValidation({...validation,GC_Company_Name:"0",GC_Name:"0",GC_License_Number:"0"})
                    handleChange(e)
                  }}
                />
              </Col>
            </Row>
          </FormGroup> : <></>
        }
          

          <FormGroup row className={
            prevObj.GC_Supersede_Required == "Yes"
              ? "marb-16 padtop-15"
              : "generalContractor-none"
          } >
            <Row>
              <Col sm={5}>
                <Label className="text-bold" for="contractorcompany">
                  GC Company Name
                  <span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={7}>
                <Input
                  type="text"
                  name="GC_Company_Name"
                  id="contractorcompany"
                  className="createdeal-textfield"
                  value={prevObj ? prevObj.GC_Company_Name : ''}
                  style={validation.GC_Company_Name=="-1"?{...validationCss}:{}}
                  onBlur={(e)=>{
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,GC_Company_Name:"-1"})
                    }else{
                      setValidation({...validation,GC_Company_Name:"1"})
                    }
                  }}
                  onChange={(e) => {
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)
                  }}
                />
              </Col>


            </Row>
          </FormGroup>

          <FormGroup row className={
            prevObj.GC_Supersede_Required == "Yes"
              ? "marb-16 padtop-15"
              : "generalContractor-none"
          } >
            <Row>
              <Col sm={5}>
                <Label className="text-bold" for="contractorname">
                  GC Name
                  <span className="red-text">*</span>
                </Label>

              </Col>

              <Col sm={7}>
                <Input
                  type="text"
                  name="GC_Name"
                  id="contractorname"
                  className="createdeal-textfield"
                  style={validation.GC_Name=="-1"?{...validationCss}:{}}
                  value={prevObj ? prevObj.GC_Name : ''}
                  onBlur={(e)=>{
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,GC_Name:"-1"})
                    }else{
                      setValidation({...validation,GC_Name:"1"})
                    }
                  }}
                  onChange={(e) => {
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)
                  }}
                />
              </Col>


            </Row>
          </FormGroup>

          <FormGroup row className={
            prevObj.GC_Supersede_Required == "Yes"
              ? "marb-16 padtop-15"
              : "generalContractor-none"
          }>
            <Row>
              <Col sm={5}>
                <Label className="text-bold" for="contractorlicence">
                  GC License Number
                  <span className="red-text">*</span>
                </Label>

              </Col>

              <Col sm={7}>
                <Input
                  value={prevObj ? prevObj.GC_License_Number : ''}
                  type="text"
                  name="GC_License_Number"
                  id="contractorlicence"
                  style={validation.GC_License_Number=="-1"?{...validationCss}:{}}
                  className="createdeal-textfield"
                  onBlur={(e)=>{
                    if(e.target.value==undefined||e.target.value==''||e.target.value.length<6){
                      setValidation({...validation,GC_License_Number:"-1"})
                    }else{
                      setValidation({...validation,GC_License_Number:"1"})
                    }
                  }}
                  onChange={(e) => {
                    e.target.value=e.target.value.replace(/[\D]/,'')
                    handleChange(e)
                  }}
                  maxLength={6}
                />

              </Col>
            </Row>
          </FormGroup>

          <FormGroup row className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="reinstatement">
                  Reinstatement Required<span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={3}>
                <Label className="text-bold2">Yes</Label>
                <Input type="radio" name="Reinstatement_Required" value="Yes"
                  checked={prevObj.Reinstatement_Required ? prevObj.Reinstatement_Required === "Yes" : false}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </Col>
              <Col sm={3}>
                <Label className="text-bold2">No</Label>
                <Input type="radio" name="Reinstatement_Required" value="No"
                  checked={prevObj.Reinstatement_Required ? prevObj.Reinstatement_Required === "No" : false}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </Col>
            </Row>
          </FormGroup>




          <FormGroup row className={
            prevObj.Reinstatement_Required == "Yes"
              ? "marb-16 padtop-15"
              : "appSupersede-none"
          }>
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="inspectionpassed">
                  Inspection Passed
                  <span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={3}>
                <Label className="text-bold2">Yes</Label>
                <Input type="radio" name="Inspection_Passed" value="Yes"
                  checked={prevObj ? prevObj.Inspection_Passed === "Yes" : false}
                  onChange={(e) => { handleChange(e) }} />
              </Col>
              <Col sm={3}>
                <Label className="text-bold2">No</Label>
                <Input type="radio" name="Inspection_Passed" value="No"
                  checked={prevObj ? prevObj.Inspection_Passed === "No" : false}
                  onChange={(e) => { handleChange(e) }} />
              </Col>
            </Row>
          </FormGroup>


          <FormGroup row className={
            ((prevObj.Inspection_Passed == "Yes") && (prevObj.Reinstatement_Required == "Yes"))
              ? "marb-16 padtop-15"
              : "appSupersede-none"
          }>
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="inspectiondate">
                  Inspection Passed Date
                  <span className="red-text">*</span>
                </Label>

              </Col>

              <Col sm={6}>

              <DatePicker
                selected={prevObj.Inspection_Passed_Date ? new Date(prevObj.Inspection_Passed_Date) : ''} 
                name='Inspection_Passed_Date' 

                className={validation.Inspection_Passed_Date=='1' ||validation.Inspection_Passed_Date=='0'  ? "valid-date" : "invalid-date"}
                onChange={
                  date => {
                    if(date==null
                      ||date==undefined
                      ||date==''){
                       setValidation({...validation,Inspection_Passed_Date:'-1'})
                    }else{
                      setValidation({...validation,Inspection_Passed_Date:'1'})
                    }
                     handleChange({ currentTarget: { name: 'Inspection_Passed_Date', value: date == null ? "" : dayjs(new Date(date)).format('YYYY-MM-DD') } });
                 }} 
                 onBlur={(e)=>{
                 if(e.target.value==null
                   ||e.target.value==undefined
                   ||e.target.value==''){
                   setValidation({...validation,Inspection_Passed_Date:'-1'})
                 }else{
                   setValidation({...validation,Inspection_Passed_Date:'1'})
                 }
               }}
               onKeyDown={(e) => {
                e.preventDefault();
             }}
                />

              </Col>
            </Row>
          </FormGroup>


         <FormGroup row className={
            prevObj.Withdrawal_Required == "Yes"
              ? "marb-16 padtop-15"
              : "appSupersede-none"
          }>
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="installation">
                  Installation Complete
                  <span className="red-text">*</span>
                </Label>
              </Col>

              <Col sm={3}>
                <Label className="text-bold2">Yes</Label>
                <Input type="radio" name="Installation_Complete" value="Yes"
                  checked={prevObj ? prevObj.Installation_Complete === "Yes" : false}
                  onChange={(e) => { handleChange(e) }} />
              </Col>
              <Col sm={3}>
                <Label className="text-bold2">No</Label>
                <Input type="radio" name="Installation_Complete" value="No"
                  checked={prevObj ? prevObj.Installation_Complete === "No" : false}
                  onChange={(e) => { handleChange(e) }} />
              </Col>
            </Row>
          </FormGroup>


          <FormGroup row className={
            prevObj.Installation_Complete == "Yes"
              ? "marb-16 padtop-15"
              : "appSupersede-none"
          }>
            <Row>
              <Col sm={5}>
                <Label className="text-bold" for="inspectionemail" >
                  Inspection Ready Email Address
                  {/* <span className="red-text">*</span> */}
                </Label>
              </Col>

              <Col sm={7}>
                <Input type="email"
                  className="createdeal-textfield"
                  placeholder="abc@mail.com"
                  value={prevObj ? prevObj.Inspection_Ready_Email_Address : ''}
                  // style={validation.Inspection_Ready_Email_Address =='-1'?{...validationCss}:{}}
                  name="Inspection_Ready_Email_Address" id="ownername" 
                  onChange={(evt) => { 
                    evt.target.value=evt.target.value.trimStart()
                    handleChange(evt) }}
                  onBlur={(e) => {
                    handlemailid(e.target.value) }}/>
              </Col>
          
            </Row>
            <Row>
            <Col sm={5}></Col>
            <Col sm={7}><span className={mailInvalid?"red-text font12":"error-display"}> Incorrect E-mail Address format.</span></Col>
            </Row>
          </FormGroup>

        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <div className="row float-right w-100">
          <div className="col-sm-6">
          <span onClick={() =>{ setAddInfoModal(false)
            resetValidation();
            }} className="btn w-100 btn-sm btn-secondary">Cancel</span>
          </div>
          <div className="col-6 w-100">
            <span onClick={() => {
              handleSave()
            }} className="btn btn-sm w-100 btn-primary">Save</span>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  }


  return (
    <Col sm={12}>

      <Button onClick={prevsolartoggle} className={prevsolarstate ? "btn-100-active" : "btn-100"}>
        Previous Solar Application Information
        <div className="float-right">
          <Input type="checkbox"
            className={handlePreviousSolar() ? "rightbutton-checkbox-active" : "rightbutton-checkbox"}
            checked={handlePreviousSolar()}
            onChange={() => setSkipSolar(!skipSolar)} />
          {prevsolarstate ? (
            <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
          )}
        </div>
      </Button>
      <Collapse isOpen={prevsolarstate}>
        <Card className="createdeal-card">
          <CardBody>
            <FormGroup row className="marb-16">
              <div className="col-12">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="solarapp">
                    Previous Solar Application
                  </Label>
                </Col>

                <Col sm={1} className="radioStyle">
                  <Label className="text-bold2">Yes</Label>
                  <Input
                    type="radio"
                    name="PreviousSolar"
                    value="Yes"
                    checked={props.data ? props.data.PreviousSolar === "Yes" : false}
                    onChange={(e) => {
                      props.handleOnChangeDeal(e)
                      props.setPreviousSOlar(e.target.value)
                    }}
                  />
                </Col>
                <Col sm={1} className="radioStyle">
                  <Label className="text-bold2">No</Label>
                  <Input
                    type="radio"
                    name="PreviousSolar"
                    value="No"
                    checked={props.data ? props.data.PreviousSolar === "No" : false}
                    onChange={(e) => { props.handleOnChangeDeal(e); props.setPreviousSOlar(e.target.value) }}
                  />
                </Col>
                <Col sm={7}></Col>


              </Row>
              </div>
             
            </FormGroup>
            {addPreviousInfoModal()}
            <div className="row" style={{margin : '0px'}}>
              {props.data.PreviousSolar == "Yes" ? 
              <DealTableChart 
              dataAdded={dataAdded} 
              onDelete={(data,idx)=>{handleDelete(data,idx)}} 
              editOnClick={(data,index)=>{
                setPrevObj(data);
                setSolarApplicationNumber(data.Application_Number)
                setAddInfoModal(true);
                setAction('edit');
                setEditIdx(index);
                let vKeys = Object.keys(validation);
                let ob={}
                for (let i = 0; i < vKeys.length; i++) {
                  if(data[vKeys[i]]!=undefined && data[vKeys[i]]?.length>0){
                    ob[vKeys[i]]='1';
                  }else{
                    ob[vKeys[i]]='0';
                  }
                }
                setValidation(ob)
                
              }} addOnClick={() => {
                setAddInfoModal(true);
                setPrevObj({});
                setAction('add');

              }} headers={previousSolarAppHeaders} data={props.data ? props.data.Previous_Solar_Info : []} /> : <></>}
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
}

export default PreviouSolar;