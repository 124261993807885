import React, { useEffect, useRef, useState } from 'react'
import './compactview.css';
import { faAngleDoubleRight,faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from 'antd';
import { AgGridReact } from '@ag-grid-community/react';
import AgGridReactComponent from '../AgGridReactComponent/AgGridReactComponent';
import { AgGridColumn } from 'ag-grid-react';
import DealFileIcon from './dealFileIcon.svg'
import { set } from 'lodash';
import { param } from 'jquery';
export default function CompactView ({
    activeHeaderData,
    onExpandOrCollapse, AllCommunityModules, AllModules, ColumnFilter, CustomDateComponent, CustomEditorComponent,
    CustomHeaderComponent, onFirstDataRendered, onSelectionChanged, onColumnPinned, onGridReady,setSouldRender,
    getPagingValues, rowData, staticCellStyle, groupStatus, getColumnsBySection,viewType,ref,setGroupStatus,
    onChangeColumnFilters,sectionsState,setComactViewGridApis,updateCompactViewFileters,compactViewGridApis,unExpandedRowData,
    triggerGridApiEffect,settriggerGridApiEffect,
}) {
    const[sections,setSections]=useState([...sectionsState])  
    const ExpandStatusObject={
        "Basic_Information":{
            Basic_Information: false,
            Property_Location_Info: false,
            Services_Requested: false,
        },
        "NYC_Information":{
            NYC_Property_Info: false,
            Previous_Solar_Application_Info: false,
            Additional_Nyc_Filling_Info:false,
            WWP_Violation_Info:false,
            Asbestos_Investigation:false,
            Links:false
        },
        "Home_Owner":{
            Property_Owner_Info:false,
            Home_Owner_Info:false,
            Homeowner_s_Mailing_Address_Info:false,
            Other_Owner_Information:false,
            Condo_Second_Officer:false,
        },
        "Permitting":{
            Permitting_Info:true,
        },
        "Pv_System":{
            Pv_System_Info:false,
            PV_Interconection:false,
            Project_Cost:false,
        },
        "Actions_Details":{
            Created_Details:false,
            Modified_Details:false,
        }
    }
    const expandSection=(data)=>{
        let d=sections?.map(temp=>{
            if(temp.fieldName==data.fieldName){
                temp.expanded=!data.expanded
                setGroupStatus({...groupStatus,...ExpandStatusObject[data.field]})
            }
            return temp
        })
        setSections(d);
    }
    const[compactViewRowData,setCompactViewRowData]=useState([]);
    useEffect(e=>{
        settriggerGridApiEffect(!triggerGridApiEffect);
    },[compactViewRowData])
    useEffect(e=>{
        if(groupStatus['Previous_Solar_Application_Info'] && !groupStatus['WWP_Violation_Info'] && !groupStatus['PV_Interconection']){
            let filteredData=rowData.filter(d=>d.Solar_Columns || !d.Row_Span_Count)
            setSouldRender(false);
            setCompactViewRowData(filteredData);
        }else if(!groupStatus['Previous_Solar_Application_Info'] && groupStatus['WWP_Violation_Info'] && !groupStatus['PV_Interconection']){
            let filteredData=rowData.filter(d=>d.Violation_Columns || !d.Row_Span_Count)
            setSouldRender(false);
            setCompactViewRowData(filteredData);
        }else if(!groupStatus['Previous_Solar_Application_Info'] && !groupStatus['WWP_Violation_Info'] && groupStatus['PV_Interconection']){
            let filteredData=rowData.filter(d=>d.Pv_Columns || !d.Row_Span_Count)
            setSouldRender(false);
            setCompactViewRowData(filteredData);
        }else if(groupStatus['Previous_Solar_Application_Info'] && groupStatus['WWP_Violation_Info'] && !groupStatus['PV_Interconection']){
            let filteredData=rowData.filter(d=>(d.Violation_Columns || d.Solar_Columns) || !d.Row_Span_Count)
            setSouldRender(false);
            setCompactViewRowData(filteredData);
        }else if(groupStatus['Previous_Solar_Application_Info'] && !groupStatus['WWP_Violation_Info'] && groupStatus['PV_Interconection']){
            let filteredData=rowData.filter(d=>(d.Pv_Columns || d.Solar_Columns) || !d.Row_Span_Count)
            setSouldRender(false);
            setCompactViewRowData(filteredData);
        }else if(!groupStatus['Previous_Solar_Application_Info'] && groupStatus['WWP_Violation_Info'] && groupStatus['PV_Interconection']){
            let filteredData=rowData.filter(d=>(d.Pv_Columns || d.Violation_Columns) || !d.Row_Span_Count)
            setSouldRender(false);
            setCompactViewRowData(filteredData);
        }else if(groupStatus['Previous_Solar_Application_Info'] && groupStatus['WWP_Violation_Info'] && groupStatus['PV_Interconection']){
            let filteredData=rowData.filter(d=>(d.Pv_Columns || d.Violation_Columns || d.Solar_Columns) || !d.Row_Span_Count)
            setSouldRender(false);
            setCompactViewRowData(filteredData);
        }else{
            setCompactViewRowData(unExpandedRowData);
        }
    },[rowData,groupStatus,unExpandedRowData])
    
    const dealTableRef=useRef();
    const [gridApis,setGridApis]=useState();
    const initialWidths={
        Basic_Information:"600px",
        NYC_Information:"1200px",
            Home_Owner:"1000px",
            Permitting:"600px",
            Pv_System:"600px",
            Actions_Details:"400px"
        }
    const [widthOfTables,setWidthOfTables]=useState({
        Basic_Information:"600px",
        NYC_Information:"1200px",
        Home_Owner:"1000px",
        Permitting:"600px",
        Pv_System:"600px",
        Actions_Details:"400px"
    });
    const onGridsReadyForVompactView=(params,id)=>{
         
        let ob={}
        let model;
        if(gridApis){
            ob={...gridApis};
            model=gridApis["Deal_Name"].getFilterModel();
        }
        if(params){
            params.api.setFilterModel(model);
            ob[id]=params.api;
        }
        setGridApis(ob);
    }

    const updateWidthsOfTables=(columnGroup,flag)=>{
         
        if(!columnGroup){
            setWidthOfTables(initialWidths);
        }else{
            let a=columnGroup;
            columnGroup=columnGroup.columnGroup
            let headerName=columnGroup.colGroupDef.headerName.trim().replaceAll(" ",'_')
            let thisTab=sections.find(s=>{
                let isPresent=s.subSections.find(sub=>{
                    if(sub.field==headerName){
                        return sub;
                    }
                })
                if(isPresent){
                    return s;
                }
            })
            let ob={...widthOfTables}
            if(a.columnGroup.expanded){
                ob[thisTab.field]=a.api.columnController.bodyWidth+"px";
            }else{
                let wid=(a.columnGroup.children?.length-1)*200;
                let oldWid=ob[thisTab.field].replaceAll('px','');
                ob[thisTab.field]=(parseInt(oldWid)-wid)+"px";
            }
            ob["Permitting"]="600px";
            setWidthOfTables(ob);
        }
    }
    useEffect(e=>{
         
        // updateWidthsOfTables(null,"All")
        // console.log(compactViewGridApis)
    },[activeHeaderData])
    const hoverAllTableRow=(params)=>{
         
        let rowIndex=params?.node?.id;
        let allRowsPresent=document.querySelectorAll(".Compact-View-Wrapper .ag-row");
        if(rowIndex){
            if(allRowsPresent){
                allRowsPresent.forEach(r=>{
                    if(r.getAttribute("row-index")==rowIndex){
                        r.classList.add("ag-row-hover")
                    }else{
                        r.classList.remove("ag-row-hover")
                    }
                })
            }
        }else{
            if(allRowsPresent){
                allRowsPresent.forEach(r=>{
                    r.classList.remove("ag-row-hover")
                })
            }
        }
    }
    useEffect(e=>{
         
        setComactViewGridApis(gridApis);
        updateWidthsOfTables();
    },[gridApis])
    const onSrollTable=(params,id)=>{
         
        if(gridApis && dealTableRef){
            let allTables= document.querySelectorAll('.ag-body-viewport');
            allTables.forEach(t=>{
                 
                t.scroll(0,params.top)
            })
        }
    }

    const getRowStyle = params => {
        // Access the data for the current row
        const rowData = params.data;
    
        // Create an object to hold style attributes
        const style = {};
    
        // Add a custom attribute based on the name (e.g., data-name)
        if (rowData.Deal_Name) {
          style['data-deal'] = rowData.Deal_Name; // Customize the attribute name as needed
        }
    
        // Return the style object
        return style;
      };

    const renderNestedTableFunction=(header,index)=>{
        return (
            <AgGridReact
            sectionName={header.field}
            suppressHorizontalScroll={false}
            containerStyle={{width:"100%"}}
            onColumnGroupOpened={(params)=>{
                 
                updateWidthsOfTables(params)
                onExpandOrCollapse(params)
                //onGridsReadyForVompactView(params,header.field)
            }}
            
            onCellMouseOver={(params)=>{
                hoverAllTableRow(params)
            }}
            onCellMouseOut={()=>{
                let allRowsPresent=document.querySelectorAll(".Compact-View-Wrapper .ag-row-hover");
                if(allRowsPresent){
                    allRowsPresent.forEach(r=>{
                        r.classList.remove("ag-row-hover")
                    })
                }
            }}
            //onColumnGr={onExpandOrCollapse}
            modules={(AllCommunityModules, AllModules)}
            paginationNumberFormatter={function (params) {
                return params.value.toLocaleString();
            }}
            defaultColDef={{
                sortable: true,
                resizable: true,
                flex: 1,
                minWidth: 140,
            }}
            frameworkComponents={{
                columnFilter: ColumnFilter,
                agDateInput: CustomDateComponent,
                customEditor: CustomEditorComponent,
                CustomHeaderComponent: CustomHeaderComponent
            }}
            onFirstDataRendered={onFirstDataRendered}
            onSelectionChanged={onSelectionChanged}
            cacheQuickFilter={true}
            suppressDragLeaveHidesColumns={true}
            suppressPaginationPanel={true}
            onColumnPinned={onColumnPinned}
            getRowClass={(params)=>{
                return params.data.Deal_Name
            }}
            getRowStyle={getRowStyle}
            rowSelection={"multiple"}
            groupSelectsChildren={true}
            suppressRowClickSelection={true}
            enableRangeSelection={true}
            pagination={true}
            paginationPageSize={50}
            onGridReady={(params)=>{
                onGridsReadyForVompactView(params,header.field)
            }}
            onBodyScroll={(params)=>{
                onSrollTable(params,header.field)
            }}  
            rowData={compactViewRowData}
            onFilterChanged={(params)=>{
                  
                updateCompactViewFileters({params:params,field:header.field})
            }}
        >
           {
            header.subSections.map((sub,i)=>{
                    
                return (
                    <AgGridColumn headerName={sub.fieldName} 
                        openByDefault={sub.field!="Permitting_Info"? groupStatus[sub.field]:true}
                    >
                        {getColumnsBySection(sub.field, sub.pinnedColumn,sub)}
                    </AgGridColumn>
                )
            })
           }
        </AgGridReact>
        )
    }

  const calculateWidthDynamically=(header,api)=>{
     
    if(gridApis){
        //let api=gridApis[header.field];
        if(api){
            try {
                let allGroups=api.columnController.columnDefs;
                let width=0;
                if(allGroups){
                    // allGroups.forEach(c=>{
                    //     let temp=c.children;
                    //     width=width+(temp.length*200);
                    // })
                    width=width+(allGroups.length*200)
                }
                return width+"px";
            } catch (error) {
            }
        }
    }
  }
  return (
    <div className='Compact-View-Wrapper'>
       <div className='Deal-wrapper-div col'>
        {/* <img src={DealFileIcon} /> */}
        <AgGridReact
                ref={dealTableRef}
                id="deal"
                onColumnGroupOpened={onExpandOrCollapse}
                //onColumnGr={onExpandOrCollapse}
                modules={(AllCommunityModules, AllModules)}
                paginationNumberFormatter={function (params) {
                    return params.value.toLocaleString();
                }}
                getRowClass={(params)=>{
                    return params.data.Deal_Name
                }}
                getRowStyle={getRowStyle}
                onCellMouseOver={(params)=>{
                    hoverAllTableRow(params)
                }}
                rowClassRules={(params)=>{
                    return params.data.Deal_Name
                }}
                onCellMouseOut={()=>{
                    let allRowsPresent=document.querySelectorAll(".Compact-View-Wrapper .ag-row-hover");
                    if(allRowsPresent){
                        allRowsPresent.forEach(r=>{
                            r.classList.remove("ag-row-hover")
                        })
                    }
                }}
                defaultColDef={{
                    sortable: true,
                    resizable: true,
                    flex: 1,
                    minWidth: 140,
                }}
                frameworkComponents={{
                    columnFilter: ColumnFilter,
                    agDateInput: CustomDateComponent,
                    customEditor: CustomEditorComponent,
                    CustomHeaderComponent: CustomHeaderComponent
                }}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
                cacheQuickFilter={true}
                suppressDragLeaveHidesColumns={true}
                suppressPaginationPanel={true}
                onColumnPinned={onColumnPinned}
                // debug={true}
                rowSelection={"multiple"}
                groupSelectsChildren={true}
                suppressRowClickSelection={true}
                enableRangeSelection={true}
                pagination={true}
                paginationPageSize={50}
                onGridReady={(params)=>{
                    onGridsReadyForVompactView(params,"Deal_Name")
                }}
                onBodyScroll={(params)=>{
                    onSrollTable(params,"Deal_Name")
                }}
                onFilterChanged={(params)=>{
                      
                    updateCompactViewFileters({params:params,field:"Deal_Name"})
                }}
                rowData={compactViewRowData}
            >
                <AgGridColumn
                    pinned="left"
                    field=""
                    maxWidth={55}
                    lockPosition={true}
                    // pinned='left'
                    cellClass="locked-col"
                    checkboxSelection={true}
                    headerCheckboxSelection={true}
                    cellStyle={(params)=>{
                        if(params?.data?.Row_Span_Count && viewType!='List'){
                            return {...staticCellStyle,display:"none"};
                        }else{
                            return staticCellStyle;
                        }
                    }}
                    suppressNavigable={true}
                ></AgGridColumn>
                {getColumnsBySection("Pined_Column", "Deal_Name")}
        </AgGridReact>
       </div>
       <div className='Compact-body-wrapper'>
        {sections && sections.length>0?
        (sections.map((header,index)=>{
            return <div className='Verticle-Bar'
                    style={header.expanded?{minWidth:widthOfTables[header.field]}:{minWidth:"50px"}}
                >
                    <div className='Expand-Icon-Wrapp'
                    style={header.expanded?{borderColor:header.headerColor.open,
                    }
                    :{borderColor:header.headerColor.closed,
                        backgroundColor:"#f8f8f8",
                        color: "#014F7C"
                    }}
                    onClick={()=>expandSection(header)}
                    >
                    {header.expanded?<span className='m-2'>{header.fieldName}</span>:<></>}
                    <FontAwesomeIcon
                        icon={header.expanded?faAngleDoubleLeft:faAngleDoubleRight}
                    />
                </div>
                <div className='Expand-Icon-Body'
                 style={header.expanded?{borderColor:header.headerColor.open}:{borderColor:header.headerColor.closed}}
                >
                    {header.expanded?
                        renderNestedTableFunction(header,index)
                    :<span className='Verticle-Header-Name'>{header.fieldName}</span>}
                </div>
            </div>
        })):<></>
        }
        
       </div>
    </div>
  )
}
