import React, { useContext, useEffect, useState } from "react";
import {
  Modal as AntModal,
  Button as AntButton,
  Dropdown as AntDropdown,
  Select as AntSelect,
  Input,
  DatePicker as AntdDatepicker,
  Popover,
  Collapse,
  Tabs,
  TimePicker,
  Button,
  Badge,
  theme,
  Checkbox,
  Select,
} from "antd";
import "./CreateTaskModal.css";
import StatusDropdown from "../../common/StatusDropdown/StatusDropdown";
import { Row } from "reactstrap";
import { Col } from "react-bootstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faUser,
  faClock,
  faDotCircle,
  faMinusCircle,
  faFolder,
  faFlag,
  faCircle,
  faTrash,
  faCaretUp,
  faCaretDown,
  faFile,
  faPaperclip,
  faCodeBranch,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle , faStopCircle} from "@fortawesome/free-regular-svg-icons";
import { DateRangePicker } from "react-date-range";
import {
  CaretDownFilled,
  CloseOutlined,
  FlagFilled,
  MailOutlined,
  MinusCircleFilled,
  PhoneOutlined,
  SendOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { UserPicker } from "../../common/UserPicker/UserPicker";
import { AppDataRequired } from "../../App";
import SubTaskTab from "./AddTaskTabs/SubTaskTab";
import { Checklist } from "./AddTaskTabs/Checklist";
import { ActivitySection } from "./AddTaskTabs/ActivitySection";
import TextArea from "antd/es/input/TextArea";
import { CustomeCollapse } from "./AddTaskTabs/CustomeCollapse";
import AppContext from "../../Context/AppContext";
import AwsServerService from "../../common/util/AwsServerService";
import CustomSelectComponent from "../../common/Select";
import { FaTablet, FaTasks, FaUser } from "react-icons/fa";
import _ from 'lodash';
import swal from "sweetalert";
import {
  convertToUSTime,
  getPriorityValue,
} from "../../components/HR/TimeClock/Util";
import { Option } from "antd/es/mentions";
import { number } from "mathjs";
import CustomTimeSelect from "../../common/CustomTimeSelect/CustomTimeSelect";
import TimeTracker from "../../common/TimeTracker/TimeTracker";
import {getNextStepOfFlow} from '../../components/ProjectTasks/Util'
import AttachmentModal from "./attachmentModal";
import axios from "axios";

const getItems = (panelStyle, text) => [
  {
    key: "1",
    label: "Description",
    children: <TextArea value={text} placeholder="Description.." />,
    style: panelStyle,
  },
];
const CustomeTaskField = ({ name, icon, children }) => {
  return (
    <Row className="Custom-Field-Row" style={{ opacity: "0.85" }} sm={12}>
      <Col style={{ padding: "0px" }} sm={4}>
        <Row sm={12}>
          <Col style={{ padding: "0px" }} sm={2}>
            {icon}
          </Col>
          <Col style={{ padding: "0px 3px", fontWeight: "500" }} sm={10}>
            {name}
          </Col>
        </Row>
      </Col>
      <Col sm={8}>{children}</Col>
    </Row>
  );
};

const CreateTaskModal = ({
  open,
  setOpen,
  viewTaskData,
  taskModalState,
  viewPipelineData,
  setViewPipelineData,
  refreshData,
  setPageRefreshData,
  createPiplineModalState,
  selectedTable,
  isSubTaskView,
  setIsSubTaskView,
  allPipelineData,
  viewTaskFunction,
  dropdownTasksDataCache,
  allTasksAndSubtasks,
  showDependencies,
  setShowDependencies,
  allBlueprintsData
}) => {
  const backendUrl = "https://catly-795086611.catalystserverless.com/server/apiservice/";
  const { token } = theme.useToken();
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  // const [isSubTaskView,setIsSubTaskView] = useState(false);
  const [accountSelected, setAccountSelected] = useState();
  const [dealSelected, setDealSelected] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [blueprintData, setBlueprintData] = useState({});
  const [taskHistory, setTaskHistory] = useState([]); 
  const [statusOptions, setStatusOptions] = useState([]);
  const [currentKey, setCurrentKey] = useState();
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [relativePathId, setRelativePathId] = useState(null)


    ////// Attachment Functions /////

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleAttachementRemove = (one) => {
      let tempFileCollection = [...fileNames];
      tempFileCollection = tempFileCollection.filter((file) => file.fileName !== one.fileName);
      setFileNames(tempFileCollection);
    }
      
    const uploadFiles = (arr) => {
      let doArr = []
      let fileUploadArr = []
      let names = []
      let tempFileCollection = [...fileNames];
      tempFileCollection.forEach((obj) => {
        obj.Status = "Pending"
      })
      arr.forEach((one) => {
        names.push(one.fileName);
        // doArr.push(addZohoTaskAttachements(taskData.project_id, taskData.id_string, one))
        fileUploadArr.push(handleUploadFileWorkDrive(one))
      })
  
      // setProgress({ active: true, percent: 1 })
      // let startInterval = setInterval(() => {
      //   setProgress((prev) => {
      //     if (prev && prev.percent < 100) {
      //       prev.percent = prev.percent + 1;
      //       setProgress(prev);
      //     }
      //   })
      // }, 1)
      Promise.all(doArr)
        .then((result) => {
  
          console.log(result);
  
          return Promise.all(fileUploadArr)
        })
        .then((result) => {
          arr.forEach((one) => {
            one.Status = "Success"
          })
  
          // setProgress({ active: true, percent: 100 });
          let filterTemp = tempFileCollection.filter(e => e.Status != "Success")
          // setLoader(false);
          setFileNames(filterTemp);
          // clearInterval(startInterval);
          // setProgress({ active: false, percent: 0 });
          alert("file upload successfully");
          // setAttachmentModal(false)
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        })
  
      // let text = `You upload ${tempFileCollection.length} files `
      // let text = `You upload one file `
      // let filename = names.join(' , ')
      // swal({
      //   title: "Are you sure?",
      //   text: text + filename,
      //   icon: "warning",
      //   buttons: [
      //     'No',
      //     'Yes'
      //   ],
      //   dangerMode: true,
      // }).then(function (isConfirm) {
      //   if (isConfirm) {
  
      //   }
      // })
  
    }

    const handleUploadFiles = () => {
      let link = document.createElement('input');
      link.setAttribute('type', 'file');
      link.setAttribute('name', 'uploadFiles');
      link.setAttribute('multiple', "multiple");
      document.body.appendChild(link);
      link.click();
      link.onchange = function (e) {
        // let formData = new FormData();
        // formData.append('uploaddoc', e.currentTarget.files[0])
        // updateZohoTask(formData)
        handleFileAttachments(e.currentTarget.files);
      }
  
      document.body.removeChild(link);
    }
   
  const handleUploadFileWorkDrive = (fileObj) => {
    return new Promise((resolve, reject) => {
      if (relativePathId) {
        var config = {
          Type: "Upload",
          data: {
            filename: fileObj.fileName,
            content: fileObj.file,
            parent_id: relativePathId,
          },
          url: "https://www.zohoapis.com/workdrive/api/v1/upload",
          method: "POST",
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        };
        axios.post(backendUrl + "workdrive", { config: config })
          .then(async function (response) {
            // ActivitieService.AddActivityLocal('Workdrive', { DealId: props.dealObj.Project_ID }, { action_type: 'Upload', data_type: 'File', data: { name: fileObj.fileName, path: relativeFolderId, pathId: relativePathId } })
            // resolve(response)
            // console.log("-------------------------files data-----------------------------------")

          })
          .catch(function (error) {
            reject(error);
          });
      }


    })

  }

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...fileNames];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      let base64 = await convertToBase64(fileList[index]);
      tempFileCollection.push({
        fileName: fileName,
        file: base64,
      });
      index = index + 1;
    }
    setFileNames(tempFileCollection);
    // let doArr = []
    // let fileUploadArr = []
    // let names = []
    // tempFileCollection.forEach((one) => {
    //   names.push(one.fileName);
    //   doArr.push(addZohoTaskAttachements(props.dealObj.Project_ID, taskData.id_string, one))
    //   fileUploadArr.push(handleUploadFileWorkDrive(one))
    // })

    // let text = `You upload ${tempFileCollection.length} files `
    // let filename = names.join(' , ')
    // swal({
    //   title: "Are you sure?",
    //   text: text + filename,
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {
    //     setLoader(true);
    //     Promise.all(doArr)
    //       .then((result) => {
    //         console.log(result);
    //         return Promise.all(fileUploadArr)
    //       })
    //       .then((result) => {
    //         setLoader(false);
    //         alert("file upload successfully");
    //         setAttachmentModal(false)
    //         console.log(result);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //   }
    // })


  };
    ////// Attachment Functions /////

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const taskEmptyTemplate = {
    TaskName: "",
    TaskDescription: "",
    TaskStartDate: "",
    TaskEndDate: "",
    Priority: "Not Set",
    Assignee: "",
    Supervisor: "",
    PipelineROWID: "",
    DueDate: "",
    Account: "",
    Project: "",
    TaskTemplateId: "",
    TaskStatus:"Not Started",
    TaskFlowId:'1',
    SubTasks: [],
    CheckList: [],
  };
  
  const [taskData, setTaskData] = useState({ ...taskEmptyTemplate });
  const { AllUsers } = useContext(AppDataRequired);
  const [userOptions, setUserOptions] = useState([]);
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [allDealsData, setAllDealsData] = useState([]);
  const [allPipelinesData, setAllPipelinesData] = useState([]);
  const [isDependencyModalOpen, setIsDependencyModalOpen] = useState(false);
  const [subTaskData, setSubTaskData] = useState([]);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  let allDealsAndUsers = state.AllDeals || [];
  let allAccounts = state.AllAccounts || [];
  const [isHovered, setIsHovered] = useState(false);
  const [statusColor, setStatusColor] = useState('#c9c2c2'); 
  const [resultFromGetDependency,setResultFromGetDependency]=useState([]);
  const [dropdownTasksData,setDropdownTasksData]=useState([]);
  // const [dropdownTasksDataCache,setDropdownTasksDataCache]=useState([]);
  const [dropdowncount,setDropdownCount]=useState({WaitingOn:0,Blocking:0}); 
  let userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [selectedDependenciesCache,setSelectedDependenciesCache]=useState({WaitingOn:[], Blocking:[]})
  const [selectedDependencies,setSelectedDependencies]=useState({
    WaitingOn:[],
    Blocking:[]
  }); 
  console.log("taskData", taskData);

  async function handleSaveTask(data) {
    let user = JSON.parse(localStorage.getItem("userinfo"));
    const {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
    } = data;
    const payload = {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
    };
    payload["PipelineROWID"] = data.PipelineROWID;
    payload["CREATEDBY"] = user.email_id;
    console.log(payload);
    if (taskModalState.action == "create") {
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.saveTask(payload)
            .then(async (res) => {
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              setOpen(false);
              setPageRefreshData(!refreshData);
            })
            .catch((err) => {});
        } else {
          await AwsServerService.createSubtask(payload)
            .then(async (res) => {
              setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {});
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.saveTaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              setOpen(false);
              setPageRefreshData(!refreshData);
            })
            .catch((err) => {});
        } else {
          await AwsServerService.createSubtaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              setPageRefreshData(!refreshData);
              // await Promise.all(subTaskData.map(item=> {
              //   if(item.SubTaskROWID){
              //       return
              //     }
              //   delete item["id"]
              //   delete item["flag"]
              //   item["TaskROWID"] = res[0]?.insertId;
              //  return AwsServerService.createSubtaskInTemplate(item)
              // })).then(allRes=>{
              //   console.log("allRes", allRes);
              // })
              setOpen(false);
            })
            .catch((err) => {});
        }
      }
    } else if (taskModalState.action == "edit") {
      payload["ROWID"] = isSubTaskView
        ? taskData?.SubTaskROWID
        : taskData?.TaskROWID;
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.updateTask(payload)
            .then(async (res) => {
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {});
        } else {
          await AwsServerService.updateSubtask(payload)
            .then(async (res) => {
              setPageRefreshData(!refreshData);
              // await Promise.all(subTaskData.map(item=> {
              //   if(item.SubTaskROWID){
              //       return
              //     }
              //   delete item["id"]
              //   delete item["flag"]
              //   item["TaskROWID"] = taskData?.TaskROWID;
              // return AwsServerService.createSubtask(item)
              // })).then(allRes=>{
              //   console.log("allRes", allRes);
              // })
              setOpen(false);
            })
            .catch((err) => {});
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.updateTaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {});
        } else {
          await AwsServerService.updateSubtaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              setPageRefreshData(!refreshData);
              // await Promise.all(subTaskData.map(item=> {
              //   if(item.SubTaskROWID){
              //       return
              //     }
              //   delete item["id"]
              //   delete item["flag"]
              //   item["TaskROWID"] = taskData?.TaskROWID;
              // return AwsServerService.createSubtaskInTemplate(item)
              // })).then(allRes=>{
              //   console.log("allRes", allRes);
              // })
              setOpen(false);
            })
            .catch((err) => {});
        }
      }
    }
    setTaskHistory([]);
    setIsSubTaskView(false);
  }
  
  useEffect(
    (e) => {
      if (isSubTaskView) {
        setActiveTab("2");
      } else {
        setActiveTab(activeTab);
      }
    },
    [isSubTaskView]
  );
  const openLookupFields = () => {
    setShowMoreFields(!showMoreFields);
  };
  useEffect(
    (e) => {
      if (viewTaskData) {
        getAllDependenciesForTask(viewTaskData?.hasOwnProperty('SubTaskROWID'),viewTaskData?.hasOwnProperty('SubTaskROWID')?viewTaskData?.SubTaskROWID:viewTaskData?.TaskROWID);
        AwsServerService.getTaskFlowById(viewTaskData.TaskFlowId).then(r=>{
          let data=r.data[0];
          setBlueprintData(data[0])
          getBlueprintDataOptions(data[0],viewTaskData?.TaskStatus);
        })
          let pipeline = allPipelineData.find(e=>e.PipelineROWID==viewTaskData?.PipelineROWID);
          let account = allDealsAndUsers?.filter(e=>e.Deal_Name==pipeline?.DealName ? e?.Account_Name:'')
          setDealSelected(pipeline?.DealName);
          setAccountSelected(account[0]?.Account_Name?.name)
          console.log(":-----------:>", pipeline);
          
          let array = [];
          array.push({
            value: pipeline?.PipelineROWID,
            label: pipeline?.PipelineName,
            key: pipeline?.PipelineROWID,
          });
          setAllPipelinesData(array);
        setTaskData({ ...viewTaskData });
      } else {
        setTaskData({ ...taskEmptyTemplate });
        setAllPipelinesData([]);
        setSelectedDependenciesCache({WaitingOn:[], Blocking:[]})
      }
    },
    [viewTaskData]
  );

  useEffect(
    (e) => {
      if (viewPipelineData && viewPipelineData?.length > 0) {
        // console.log("viewPipelineData", viewPipelineData);
        let temp = { ...taskData };
        temp["PipelineROWID"] = viewPipelineData[0]?.PipelineROWID || viewPipelineData[0]?.ROWID;
        setTaskData(temp);
      }
    },
    [viewPipelineData]
  );
  // const [allTasksAndSubtasks,setAllTasksAndSubtasks]=useState({Tasks:[],SubTasks:[]});

  // const getTasksAndSubtasks = (data) => {
  //   const result = []; 
  //   const taskMap = new Map();
  //   const subtaskMap = new Map(); 
  //   data?.forEach(pipeline => {
  //     pipeline.Tasks?.forEach(task => { 
  //       taskMap.set(task.TaskROWID, task);
  //       result.push({
  //         label: task.TaskName,
  //         value: `Tasks_${task.TaskROWID}`
  //       }); 
  //       if (task.SubTasks) {
  //         task.SubTasks?.forEach(subtask => {
  //           subtaskMap.set(subtask.SubTaskROWID,subtask)
  //           result.push({
  //             label: subtask.TaskName,
  //             value: `SubTasks_${subtask.SubTaskROWID}`
  //           });
  //         });
  //       }
  //     });
  //   }); 
  //   setAllTasksAndSubtasks({Tasks:taskMap,SubTasks:subtaskMap})
  //   return result; 
  // };
  // useEffect(()=>{
  //   if(allPipelineData.length>0){
  //     let data=getTasksAndSubtasks(allPipelineData) 
  //     // setDropdownTasksData(data);
  //     setDropdownTasksDataCache(data);
  //   }
  // },[allPipelineData]) 
  function getOptionsForDropdown(data,selected){
    let options=[];
    let selectedData={...selected}
    let type=viewTaskData?.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let id=viewTaskData?.hasOwnProperty('SubTaskROWID')?viewTaskData?.SubTaskROWID:viewTaskData?.TaskROWID;
    const valuesToExclude = new Set([
      ...selectedData.Blocking.map(item => item.value),
      ...selectedData.WaitingOn.map(item => item.value),
      `${type}_${id}`
    ]) 
    data.filter(e=>!valuesToExclude.has(e.value)?options.push(e):'')
    return options;
  }
  const getBlueprintDataOptions = async (data,status)=>{
    if(data){
      let currentStatus=status;
      let key=null;
      if(status!='start'){
        key=statusOptions?.filter(e=>e.value==status)[0]?.key;
      }
      if(status=='Reject'){  
        let next=getNextStepOfFlow(data.Nodes,data.Edges,currentKey,false);
        let statusToSet=data.Nodes.filter(e=>e.id==next)[0];
        if(statusToSet){ 
          currentStatus=statusToSet?.data.label;
          let temp = { ...taskData };
          temp["TaskStatus"] = statusToSet.data.label;
          setTaskData(temp);
        }
        
      }
    let EdgeIds=data.Edges.filter(e=>e.source==data.Nodes.filter(e=>e.data.label==currentStatus)[0]?.id)?.map(e=>e.target);
    let nodes=data.Nodes.filter(e=>EdgeIds.includes(e.id));
    let rejectId=data.Edges.filter(e=>e.source==key && e.sourceHandle=='reject')[0]?.target;
    let options = [];
    nodes?.map((item) => {
      if(rejectId && item.id==rejectId){
        options.push({
          value: 'Reject',
          label: "Reject",
          key: item?.id,
        });
      }else{
        options.push({
          value: item?.data?.label,
          label: item?.data?.label,
          key: item?.id,
        });
      }
      })
      setCurrentKey(key);
      setStatusOptions(options)
    // let next=getNextStepOfFlow(data[0].Nodes,data[0].Edges,data[0].Nodes.id,true);
    // let statusToSet=data[0].Nodes.filter(e=>e.id==next)[0];
    // if(statusToSet){ 
    //  let temp = { ...taskData };
    //  temp["TaskStatus"] = statusToSet.data.label;
    //  setTaskData(temp);
    // }
  } 
    }
  const getAllDependenciesForTask = (subtask,ROWID) => { 
    if(ROWID==undefined||ROWID==null){
      return
    }
    let dropdowndata = [...dropdownTasksDataCache]; 
    let sourceTaskType=subtask?'SubTasks':'Tasks';
    AwsServerService.getDependecyByTaskId(ROWID).then(r => {
      const result = r.data[0];
      // setResultFromGetDependency(result);
      if (result.length > 0) { 
        let obj = {
          WaitingOn: new Set(),
          Blocking: new Set()
        }; 
        const dropdownMap = new Map(dropdowndata.map(item => [item.value, item]));
        allPipelineData?.forEach(pipeline => {
          pipeline.Tasks?.forEach(task => {
            const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==task.TaskROWID && e.STaskType == 'Tasks'));
            let tData=undefined;
            if(tDataValidate){
            tData=tDataValidate;
            }else{
              tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
            }
            let flag=true;
            if(tData?.SourceTaskId==ROWID){
              flag=tData.STaskType==sourceTaskType
            }
            if (tData && flag) {
              const type = (tData.TargetTaskId == ROWID && tData.DependancyType == 'WaitingOn') ? 'Blocking' :
                           (tData.TargetTaskId == ROWID && tData.DependancyType == 'Blocking') ? 'WaitingOn' :
                           tData.DependancyType; 
              if (tData.TargetTaskId == ROWID) {
                const tmp = dropdownMap.get(`${tData.STaskType}_${tData.SourceTaskId}`);
                if (tmp && sourceTaskType==tData.TaskType) {
                  obj[type].add(JSON.stringify({...tmp}));
                }
              } else{
                obj[type].add(JSON.stringify({ label: task.TaskName, value: `Tasks_${task.TaskROWID}` }));
              }
            } 
            task.SubTasks?.forEach(subtask => {
              const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==subtask.SubTaskROWID && e.STaskType == 'SubTasks'));
            let tData2=undefined;
            if(tDataValidate){
              tData2=tDataValidate;
            }else{
              tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
            } 
              if (tData2) {
                const type2 = (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'WaitingOn') ? 'Blocking' :
                              (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'Blocking') ? 'WaitingOn' :
                              tData2.DependancyType; 
                if (tData2.TargetTaskId == ROWID) {
                  const tmp2 = dropdownMap.get(`${tData2.STaskType}_${tData2.SourceTaskId}`);
                  if (tmp2 && sourceTaskType==tData2.TaskType) {
                    obj[type2].add(JSON.stringify({...tmp2}));
                  }
                } else {
                  obj[type2].add(JSON.stringify({ label: subtask.TaskName, value: `SubTasks_${subtask.SubTaskROWID}` }));
                }
              }
            });
          });
        }); 
        setSelectedDependenciesCache({
          WaitingOn: Array.from(obj.WaitingOn).map(item => JSON.parse(item)),
          Blocking: Array.from(obj.Blocking).map(item => JSON.parse(item))
        });
      } else {
        setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] });
      }
    });
  };
  
  useEffect(()=>{
    if(!_.isEmpty(showDependencies)){
      setIsDependencyModalOpen(true);
      setSelectedDependencies(showDependencies);
    }else{
      let data={...selectedDependenciesCache}
      setSelectedDependencies(data);
    }
  },[selectedDependenciesCache,showDependencies])

  useEffect(
    (e) => {
      let array = [];
      allAccounts = allAccounts?.filter((item) => item?.Account_Name != "All");
      allAccounts?.map((item) => {
        array.push({
          value: item?.Account_Name,
          label: item?.Account_Name,
          key: item?.id,
        });
      });
      array.unshift({
        value: "Miscellaneous",
        label: "Miscellaneous",
        key: "Miscellaneous",
      });
      setAllAccountsData(array);
    },
    []
  );

  useEffect(
    (e) => {
      let array = [];
      allDealsAndUsers = allDealsAndUsers?.filter(
        (item) =>
          item.hasOwnProperty("Created_Time") &&
          item?.Account_Name?.name == accountSelected
      );
      allDealsAndUsers?.map((item) => {
        array.push({
          value: item?.Deal_Name,
          label: item?.Deal_Name,
          key: item?.id,
        });
      });
      setAllDealsData(array);
    },
    [accountSelected]
  );

  useEffect(() => {
    if (dealSelected) {
      AwsServerService.getPipelineDataByDeal(dealSelected)
        .then((res) => {
          let array = [];
          console.log("data ---- ", res);
          res[0]?.map((item) => {
            array.push({
              value: item?.ROWID,
              label: item?.PipelineName,
              key: item?.ROWID,
            });
          });
          setAllPipelinesData(array);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setAllPipelinesData([]);
    }
  }, [dealSelected]);

  useEffect(() => {
    if (viewPipelineData && viewPipelineData?.length > 0) {
      let array = [];
      array.push({
        value: viewPipelineData[0]?.PipelineROWID,
        label: viewPipelineData[0]?.PipelineName,
        key: viewPipelineData[0]?.PipelineROWID,
      });
      setAllPipelinesData(array);
    } else {
      setAllPipelinesData([]);
    }
  }, [viewPipelineData]);

  const activitySections = [
    {
      key: "Activity",
      open: true,
      icon: <FontAwesomeIcon icon={faCommentAlt} />,
      component: (taskId, flag) => {
        return (
          <ActivitySection taskId={taskId} module={flag ? "SUBTASK" : "TASK"} />
        );
      },
    },
    {
      key: "Blocking",
      open: false,
      icon: <FontAwesomeIcon icon={faMinusCircle} />,
      component: () => <></>,
    },
  ];
  const [sidebarOptions, setSidebarOptions] = useState([...activitySections]);
  const [activitySection, setActivitySection] = useState({
    key: "Activity",
    open: true,
    icon: <FontAwesomeIcon icon={faCommentAlt} />,
    component: (taskId, flag) => {
      return (
        <ActivitySection taskId={taskId} module={flag ? "SUBTASK" : "TASK"} />
      );
    },
  });
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  // console.log("selectionRange", selectionRange);  
  useEffect(
    (e) => {
      // console.log(selectionRange);

      if (selectionRange[0]?.startDate && selectionRange[0]?.endDate) {
        // console.log(selectionRange);
        let temp = { ...taskData };
        temp["StartDate"] = convertToUSTime(selectionRange[0]?.startDate);
        temp["DueDate"] = convertToUSTime(selectionRange[0]?.endDate);
        setTaskData(temp);
      }
    },
    [selectionRange]
  );

  const [taskLayout, setTaskLayout] = useState({
    status_details: [
      {
        is_start: false,
        color: "#a7bcee",
        name: "Not Started",
        id: "1716273000002029045",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#fbc11e",
        name: "To Do",
        id: "1716273000002029041",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#08aeea",
        name: "In Progress",
        id: "1716273000000031001",
        is_default: true,
        type: "open",
      },
      {
        is_start: false,
        color: "#98ca3c",
        name: "Completed",
        id: "1716273000002029047",
        is_default: false,
        type: "closed",
      },
      {
        is_start: false,
        color: "#a9b2c0",
        name: "Not Required",
        id: "1716273000002029043",
        is_default: false,
        type: "closed",
      },
    ],
  });
  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        setUserOptions(arr);
      }
    },
    [AllUsers]
  );
  const changeDateRange = (item) => {
    console.log("Selected Range:", item);
  
    // Extract and format the dates
    const startDate = dayjs(item.selection.startDate).format("MM-DD-YYYY");
    const endDate = dayjs(item.selection.endDate).format("MM-DD-YYYY");
  
    // Update the selection range
    setSelectionRange([item.selection]);
  };

  const handleDropDownChange = (e, data) => {
    // console.log("e", e);
    // console.log("data", data);
    if (data == "Account") {
      setAccountSelected(e);
    } else if (data == "Project") {
      setDealSelected(e);
    } else {
      let temp = { ...taskData };
      temp[data] = e;
      setTaskData(temp);
    }
  };
  
  const getAntSelectDropDown = (data) => {
    let options = data == "Project" ? allDealsData : allAccountsData;
    return (
      <AntSelect
        disabled={
          (data == "Project" && accountSelected == "Miscellaneous") ||
          ((data == "Project" || data == "Account") && createPiplineModalState)
        }
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={
          data == "Priority"
            ? "dropdown-create-task"
            : "dropdown-create-task header-dropdowns"
        }
        placeholder={data == "Account" ? "Select Account" : "Select Project"}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={
          data == "Account"
            ? accountSelected
            : data == "Project"
            ? dealSelected
            : taskData[data]
        }
        options={options}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };

  const getBlueprintsDropDown = (data) => {
    let options = [];
    allBlueprintsData?.map((item) => {
      options.push({
        value: item?.ROWID,
        label: item?.Name,
        // key: item?.Name,
      });
    })
    return (
      <AntSelect
        // disabled={
        //   (data == "Project" && accountSelected == "Miscellaneous") ||
        //   ((data == "Project" || data == "Account") && createPiplineModalState)
        // }
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={"dropdown-create-task header-dropdowns" }
        placeholder={"Select blueprint"}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={taskData[data]}
        options={options}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };
  const getBlueprintsStatuses = (data) => {
    // let options = [];
    // allBlueprintsData?.map((item) => {
    //   options.push({
    //     value: item?.ROWID,
    //     label: item?.Name,
    //     // key: item?.Name,
    //   });
    // })
    return (
      <AntSelect
        // disabled={
        //   (data == "Project" && accountSelected == "Miscellaneous") ||
        //   ((data == "Project" || data == "Account") && createPiplineModalState)
        // }
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={"dropdown-create-task header-dropdowns" }
        // placeholder={"Select blueprint"}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
          getBlueprintDataOptions(blueprintData,e);
        }}
        value={taskData[data]}
        options={statusOptions}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };

  const DueDateRangePopover = () => {
    return (
      <Popover
        overlayClassName="create-task-datepicker"
        content={
          <div className="due-date-range">
            <DateRangePicker
              onChange={changeDateRange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={selectionRange}
              direction="vertical"
              scroll={{ enabled: true }}
            />
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <AntButton
          id="setDueDate"
          className="SupervisorBtn"
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
          style={{ width: "100%" , color: "#5a6c97"}}
        >
          {`${dayjs(taskData?.StartDate).format("MM/DD/YYYY")} - ${dayjs(taskData?.DueDate).format("MM/DD/YYYY")}`}
        </AntButton>
      </Popover>
    );
  };
  const getDependencyFieldDropdowns = (data) => {
    let options =
      data == "Current_Status"
        ? [
            {
              value: "Not Started",
              label: "Not Started",
            },
            {
              value: "To Do",
              label: "To Do",
            },
            {
              value: "Pending",
              label: "Pending",
            },
            {
              value: "Completed",
              label: "Completed",
            },
          ]
        : data == "Related_Task"
        ? [
            {
              value: "1",
              label: "Task 1",
            },
            {
              value: "2",
              label: "Task 2",
            },
            {
              value: "3",
              label: "Task 3",
            },
          ]
        : data == "Related_Status"
        ? [
            {
              value: "1",
              label: "High",
            },
            {
              value: "2",
              label: "Medium",
            },
            {
              value: "3",
              label: "Low",
            },
          ]
        : data == "Dependency_Type"
        ? [
            {
              value: "blocking",
              label: "Blocking",
            },
            {
              value: "waiting",
              label: "Waiting On",
            },
          ]
        : [];
    return (
      <AntSelect
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={"dependncy-list-dropdowns"}
        placeholder={
          data == "Current_Status"
            ? "Current Task Status"
            : data == "Related_Task"
            ? "Related Task"
            : data == "Related_Status"
            ? "Related Task Status"
            : ""
        }
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        // value={data == "Account" ? selectedAccount : data == "Project" ? selectedProject : null}
        options={options}
      />
    );
  };
  const handleHistoryAdd=()=>{
    let history=[...taskHistory]
    history.push(viewTaskData)
    setTaskHistory(history);
  }
  const handleBack = () => {
    const previousHistory = [...taskHistory]; 
    const lastTask = previousHistory.pop();
    let type=lastTask?.hasOwnProperty('SubTaskROWID')?'SUBTASK':'TASK';
    if(lastTask){
      viewTaskFunction(lastTask,type); 
    }
    setTaskHistory(previousHistory);
  };
  function openTaskFromDependencies(data){ 
    const [type,id]=data?.value?.split('_');  
    let tasksData={...allTasksAndSubtasks};
     if(type=='Tasks'){
      const newData=tasksData.Tasks.get(number(id));
      if(newData){
        handleHistoryAdd();
        viewTaskFunction(newData,type);
      }
     }else{
      const newData=tasksData.SubTasks.get(number(id));
      if(newData){
        handleHistoryAdd();
        viewTaskFunction(newData,type);
      }
     }
     setIsDependencyModalOpen(false);
  }
  const handleSaveDependencies=async (data)=>{ 
    try { 
      let sourceTaskType=viewTaskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      let sourceId=viewTaskData.hasOwnProperty('SubTaskROWID')?viewTaskData.SubTaskROWID:viewTaskData.TaskROWID;
      if(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0){ 
        let d={...selectedDependenciesCache};
        let blocking = new Set(d.Blocking.filter(e=>e.value).map(e => e.value));
        let waiting = new Set(d.WaitingOn.filter(e=>e.value).map(e => e.value));
        let deps={
          Blocking:data.Blocking.filter(e=> !blocking.has(e.value)),
          WaitingOn:data.WaitingOn.filter(e=> !waiting.has(e.value))
        }
      const promises = Object.keys(deps).map(async (e) => { 
        return Promise.all(deps[e].map(async (a) => { 
          const [type,id]=a.value.split('_');
        const payload={
          TargetTaskId:id,
          STaskType:sourceTaskType,
          SourceTaskId:sourceId,
          TaskType:type,
          DependancyType:e,
          CREATEDBY:userinfo.email_id
        } 
        return AwsServerService.createDependency(payload);
      }));
    });
    await Promise.all(promises).then(r=>{
      let data={...selectedDependencies}
      setSelectedDependenciesCache(data)
      setIsDependencyModalOpen(false);
    })
      }else{
       const promises = Object.keys(data).map(async (e) => { 
          return Promise.all(data[e].map(async (a) => { 
            const [type,id]=a.value.split('_');
          const payload={
            TargetTaskId:id,
            STaskType:sourceTaskType,
            SourceTaskId:sourceId,
            TaskType:type,
            DependancyType:e,
            CREATEDBY:userinfo.email_id
          } 
          return AwsServerService.createDependency(payload);
        }));
      }); 
      await Promise.all(promises).then(r=>{
        let data={...selectedDependencies}
        setSelectedDependenciesCache(data)
        setIsDependencyModalOpen(false);
        // swal('Success','All dependencies saved successfully','success');
      });
    }
    } catch (error) {
      // swal('Failed','Error saving dependencies','Alert');
    }
  } 
  function handleDeleteDependency(e,isWaiting){
    let [type,id]=e.value.split('_');
    let sourceType=viewTaskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let sourceId=viewTaskData.hasOwnProperty('SubTaskROWID')?viewTaskData.SubTaskROWID:viewTaskData.TaskROWID;
    AwsServerService.deleteDependency(sourceId,sourceType,id,type).then(r=>{
      if(isWaiting=='waiting'){
        let selections=[...selectedDependencies.WaitingOn];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'WaitingOn':0});
        }
        setSelectedDependenciesCache({...selectedDependencies,'WaitingOn':data}) 
        setSelectedDependencies({...selectedDependencies,'WaitingOn':data})
      }else{
        let selections=[...selectedDependencies.Blocking];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'Blocking':0});;
        } 
        setSelectedDependenciesCache({...selectedDependencies,'Blocking':data}) 
        setSelectedDependencies({...selectedDependencies,'Blocking':data}) 
      }
      })
    }
  const DependecyModal = () => {
    return (
      <>
        <AntButton
          onClick={() => {
            setIsDependencyModalOpen(true);
          }}
          color="primary"
          type="primary"
        >
          {(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0) ?`Dependencies`:`Add Dependency`}
        </AntButton>
        <AntModal
          open={isDependencyModalOpen}
          // open={true}
          wrapClassName={"Add-dependecny-Wrapper"}
          rootClassName="Add-dependecny-Root-Wrapper"
          draggable
          title={(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0)?"Edit Dependency":"Add Dependency"}
          maskClosable={false}
          width={300}
          className=""
          style={{ bottom: '2rem',right: '9rem', zIndex: "1000" }}
          onCancel={() => {
            setIsDependencyModalOpen(false); 
            setShowDependencies({});
            let data={...selectedDependenciesCache};
            setSelectedDependencies(data); 
            setDropdownCount({WaitingOn:0,Blocking:0})
          }}
          footer={[<>
          <Button type="primary" onClick={()=>{
            handleSaveDependencies(selectedDependencies) 
          }}>{_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}</Button>
          </>]}
        >
          <div className="add-dependency-wrapper-div">
            <div className="dependency-header"></div>
            <div className="dependency-modal-body-wrapper">
              {/* <Row sm={12}>
                <Col sm={12}> */}
                  {/* <CustomeTaskField name={"Current Task Status"} icon={""}>
                    {getDependencyFieldDropdowns("Current_Status")}
                  </CustomeTaskField> */}
                  {/* <Row>
                  <Col sm={3}>
                   <label htmlFor="status"><strong>Status :</strong></label>
                  </Col>
                  <Col sm={9}>
                  <AntSelect
                  showSearch
                  size={"small"}
                  className="form-control"
                  popupClassName={"dependncy-list-dropdowns"}
                  placeholder={'Select Status' }
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => {
                    // handleDropDownChange(e, data);
                  }}
                  onSelect={()=>{

                  }}
                  // value={data == "Account" ? selectedAccount : data == "Project" ? selectedProject : null}
                  options={getDependencyFieldDropdowns('Current_Status')}
                />
                </Col>
                </Row> */}
                  {/* <CustomeTaskField name={"Dependency Type"} icon={""}>
                    {getDependencyFieldDropdowns("Dependency_Type")}
                  </CustomeTaskField> */}
                  <Row>
                  <Col sm={3}>
                   <label htmlFor="status"><strong>Waiting on:</strong></label>
                  </Col>
                    <Col sm={9}>
                    <div className="waitingOndiv">
                     <div className="renderTasksDiv">
                      {
                        selectedDependencies.WaitingOn.map((e,index)=>
                          <div key={index} className="innerRendertaskdiv">
                        <Row>
                          <Col sm={10}>
                            <div style={{cursor: 'pointer'}} onClick={()=>{
                              openTaskFromDependencies(e);
                            }}>
                            <span>
                            <FontAwesomeIcon icon={faCircle} style={{opacity:'0.5', width: '0.7em',marginRight:'6px'}} />
                            </span>
                            {e.label}
                          </div>
                            </Col>
                            <Col sm={2}> 
                            <span style={{cursor:'pointer'}} onClick={()=>{
                              let [type,id]=e.value.split('_');
                              console.log(type,id);
                            }}>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{ 
                            // let selections=[...selectedDependencies.WaitingOn];
                            // let data=selections?.filter(t=>t.label!==e.label);
                            // if(data.length==0){
                            //   setDropdownCount({...dropdowncount,'WaitingOn':0});
                            // } 
                            // setSelectedDependencies({...selectedDependencies,'WaitingOn':data}) 
                            handleDeleteDependency(e,'waiting');
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>
                         )
                      }
                     </div>
                     {dropdowncount.WaitingOn==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'WaitingOn':1})} ghost> Add Task + </Button>}
                    { dropdowncount.WaitingOn==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Waiting On'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.WaitingOn];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'WaitingOn':selections}) 
                    }} 
                    value={null}
                    options={getOptionsForDropdown(dropdownTasksDataCache,selectedDependencies)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
                    </Row>
                  {/* <CustomeTaskField
                  name={"Pipeline"}
                  icon={<FontAwesomeIcon icon={faDotCircle} />}
                  >
                    <span>Dropdown</span>
                  </CustomeTaskField> */}
                  {/* <CustomeTaskField name={"Related Task"} icon={""}>
                    {getDependencyFieldDropdowns("Related_Task")}
                  </CustomeTaskField> */}
                  <Row style={{ marginTop: '20px' }}>
                  <Col sm={3}>
                   <label htmlFor="status"><strong>Blocking :</strong></label>
                  </Col>
                    <Col sm={9}>
                    <div className="waitingOndiv">
                     <div className="renderTasksDiv">
                      {
                        selectedDependencies.Blocking.map((e,index)=>
                          <div key={index} className="innerRendertaskdiv">
                        <Row>
                          <Col sm={10}>
                          <div style={{cursor: 'pointer'}} onClick={()=>{
                          openTaskFromDependencies(e);
                         }}>
                         <span>
                         <FontAwesomeIcon icon={faCircle} style={{opacity:'0.5', width: '0.7em',marginRight:'6px'}} />
                         </span>
                            {e.label}
                        </div>
                            </Col>
                            <Col sm={2}> 
                            <span>
                           <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
                           onClick={()=>{ 
                            // let selections=[...selectedDependencies.Blocking];
                            // let data=selections?.filter(t=>t.label!==e.label);
                            // if(data.length==0){
                            //   setDropdownCount({...dropdowncount,'Blocking':0});;
                            // } 
                            // setSelectedDependencies({...selectedDependencies,'Blocking':data})
                            handleDeleteDependency(e); 
                           }}
                           />
                            </span> 
                            </Col>
                            </Row>
                          </div>
                         )
                      }
                     </div>
                     {dropdowncount.Blocking==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'Blocking':1})} ghost> Add Task + </Button>}
                    { dropdowncount.Blocking==1 && 
                    <div className="dropdownForWaiting">
                    <AntSelect
                    showSearch
                    size={"small"}
                    className="form-control"
                    popupClassName={"dependncy-list-dropdowns"}
                    placeholder={'Select Blocking'}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    onSelect={(e)=>{
                      let data=[...dropdownTasksDataCache]
                      let selections=[...selectedDependencies.Blocking];
                      selections.push(data?.filter(t=>t.value==e)[0]);
                      setSelectedDependencies({...selectedDependencies,'Blocking':selections})  
                      // setDropdownTasksData(data?.filter(t=>t.value != e))
                    }} 
                    value={null}
                    options={getOptionsForDropdown(dropdownTasksDataCache,selectedDependencies)}
                    />
                    </div>
                  }
                  </div>
                    </Col>
                    </Row>
                  {/* <CustomeTaskField name={"Related Task Status"} icon={""}>
                    {getDependencyFieldDropdowns("Related_Status")}
                  </CustomeTaskField> */}
                {/* </Col>
              </Row> */}
            </div>
          </div>
        </AntModal>
      </>
    );
  };
  // console.log("issubstsakaview", isSubTaskView);

  const getTableForLookupFields = () => {
    return (
      <div className="newField-box">
        {/* <table class="table table-sm table-hover newField-table">
          <tbody>
            {taskCustumeFieldList &&
              taskCustumeFieldList.map((one, i) => {
                if (showMoreFields) {
                  return getInputHtmlForAddField(one);
                } else {
                  return;
                }
              })}
          </tbody>
        </table> */}
        <div className="newField-tableOptions">
          {/* {!showMore ? getTotalCustomeTaskEmphtyField() : 0} empty */}
          <p onClick={openLookupFields}>
            <FontAwesomeIcon
              className="mr-2"
              icon={showMoreFields ? faCaretUp : faCaretDown}
            />
            {showMoreFields ? "Show less Lookup" : "Show more Lookup"} fields (
            {/* {countLookupFields(taskCustumeFieldList)} total fields */}0
            )
          </p>
          {/* {!showMore ? taskCustumeFieldList.length  : taskCustumeFieldList.length-1} */}
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              // onClickAddOrEditCustomeFields("LookupFields");
            }}
          >
            + Add fields
          </a>
        </div>
      </div>
    );
  };

  const getStatusByFlow=async(viewTaskData,accepted)=>{
    let id=viewTaskData?.TaskFlowId; 
    let currentStatus= viewTaskData?.TaskStatus;
    if(id==undefined){
      swal('Alert','Task Not Saved Yet!','info')
      return
    }
    swal({
      title: "Warning!",
      text: `Are you sure you want to ${accepted?'Approve':'Reject'}?`,
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: "Proceed",
      },
    }).then(async(isTrue)=>{
      if(isTrue){
        //  debugger
         await AwsServerService.getTaskFlowById(id).then(r=>{
           let data=r.data[0];
           if(data){
       let status=data[0].Nodes.filter(e=>e.data.label==currentStatus)[0];
       let next=getNextStepOfFlow(data[0].Nodes,data[0].Edges,status.id,accepted);
       let statusToSet=data[0].Nodes.filter(e=>e.id==next)[0];
       if(statusToSet){ 
        let temp = { ...taskData };
        temp["TaskStatus"] = statusToSet.data.label;
        setTaskData(temp);
      }
    }
  }) 
  }
});
}

  return (
    <div>
      {/* <AntButton
        onClick={() => {
          setIsCreateModalOpen(true);
        }}
        color="primary"
        type="primary"
      >
        Add Task
      </AntButton> */}
      <AntModal
        open={open}
        // open={true}
        wrapClassName={"Add-task-Wrapper"}
        rootClassName="Add-task-Root-Wrapper"
        draggable
        title=""
        maskClosable={false}
        width={300}
        className=""
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setOpen(false);
          setIsSubTaskView(false);
          setStatusOptions({})
        }}
        footer={[<></>]}
      >
        <div className="Task-Modal-Wrapper">
          <div className="Task-Header">
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {/* <span><FontAwesomeIcon icon="fa-solid fa-list-check" /></span> */}
              {/* <span style={{fontSize:"1.5rem",fontWeight:"600"}}>Create Task</span> */}
              { taskModalState.action == "edit" && <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon
                onClick={() => {
                  handleBack();
                }}
                style={taskHistory.length == 0 ? { cursor: 'not-allowed', color: 'lightgray' } : { cursor: 'pointer' }}
                icon={faArrowLeft}
              />
              </span>}
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faUser} />
                <div>{getAntSelectDropDown("Account")}</div>
              </span>
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faFolder} />
                <div>{getAntSelectDropDown("Project")}</div>
              </span>
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faCodeBranch} />
                <div>{getBlueprintsDropDown()}</div>
              </span>
            </div>
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  type="primary"
                  // icon={<CloseOutlined />}
                  onClick={() => {
                    handleSaveTask(taskData);
                  }}
                  style={{ height: "28px" }}
                >
                  {taskModalState.action == "edit"
                    ? "Update Task"
                    : "Save Task"}
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setOpen(false);
                    setIsSubTaskView(false);
                    setTaskHistory([])
                  }}
                  style={{ height: "28px" }}
                ></Button>
              </div>
            </div>
          </div>
          <div className="Task-Header-Two">
            {[
              {
                label: "STATUS",
                component: (
                  // <StatusDropdown
                  //   module="CreateTask"
                  //   toggelButton={
                  //     <span
                  //       className="project_status_custom"
                  //       style={{
                  //         backgroundColor:
                  //           taskLayout.status_details[0]?.color || "",
                  //         maxWidth: "8rem",
                  //       }}
                  //     >
                  //       {taskLayout.status_details[0]?.name}
                  //     </span>
                  //   }
                  //   items={taskLayout.status_details || []}
                  //   onSelect={async (value) => {}}
                  // />
              //     <div
              //   className="taskStatusCustom"
              //   style={{background:statusColor}}
              //   onMouseEnter={() => setIsHovered(true)}
              //   onMouseLeave={() => setIsHovered(false)}
              // >
              //   {
              //   isHovered ? (
              //     <div className="button-group">
              //       <button className="statusButton" style={{borderRight:'1px solid gray',background:'#01e401'}}
              //       onClick={()=>{
              //         getStatusByFlow(viewTaskData,true);
              //       }}
              //       >Approve</button>
              //       <button className="statusButton" style={{background:'#f34545'}}
              //       onClick={()=>{
              //         getStatusByFlow(viewTaskData,false);
              //       }}
              //       >Reject</button>
              //     </div>
              //   ) : (
              //     <button className="statusButtonView" style={{background:statusColor}} 
              //     >{taskData.TaskStatus}</button>
              //   )}
              // </div>
              <div className="statusDropdownBlueprint">
              {getBlueprintsStatuses('TaskStatus')}
              </div>
                ),
              },
              {
                label: "SUPERVISOR",
                component: (
                  <UserPicker
                    values={taskData["Supervisor"]}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Supervisor: val })
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                  />
                ),
              },
              {
                label: "ASSIGNEE",
                component: (
                  <UserPicker
                    mode="multiple"
                    values={taskData["Assignee"]}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Assignee: val })
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                  />
                ),
              },
              {
                label: "PRIORITY",
                component: (
                  <Select
                    className="priority-icon"
                    name="Priority"
                    placeholder="Select an option"
                    style={{ width: 200 }}
                    value={taskData["Priority"]}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Priority: val })
                    }
                    optionLabelProp="icon"
                    title={taskData["Priority"]}
                    rootClassName="table-dropdown"
                  >
                    {["Not Set", "Low", "Medium", "High"].map((priority) => (
                      <Option
                        key={priority}
                        value={priority}
                        icon={
                          <FlagFilled
                            style={{ color: getPriorityValue(priority) }}
                            className="Icon"
                          />
                        }
                      >
                        <FlagFilled
                          style={{ color: getPriorityValue(priority) }}
                          className="Icon"
                        />{" "}
                        {priority}
                      </Option>
                    ))}
                  </Select>
                ),
              },
              {
                label: "TIME TRACKED",
                component: (<>
                  {/* <FontAwesomeIcon icon={faStopCircle} className="play-stop-button" style={{ color: 'rgb(204, 45, 45)' }}/>
                  <FontAwesomeIcon icon={faPlayCircle} className="play-stop-button" style={{ color: 'rgb(45, 204, 110)' }}/> */}
                  <TimeTracker viewTaskData={viewTaskData} open={open} users={userOptions} allTasksAndSubtasks={allTasksAndSubtasks}/>
                  </>),
              },
              {
                label: "TIME ESTIMATE",
                component: (
                  // <TimePicker
                  //   className="TimePicker-Estimate"
                  //   defaultValue={dayjs("12:08", "HH:mm")}
                  //   name="TimeEstimate"
                  //   format="HH:mm"
                  //   value={
                  //     taskData["TimeEstimate"]
                  //       ? dayjs(taskData["TimeEstimate"], "HH:mm")
                  //       : null
                  //   }
                  //   onChange={(time, timestr) => {
                  //     setTaskData({ ...taskData, TimeEstimate: timestr });
                  //   }}
                  // />
                  <CustomTimeSelect
                    rootClassName={"Create-Task-TimePicker"}
                    className={"Create-Task-TimePicker-Class"}
                    placement={"top"}
                    taskData={taskData}
                    setTaskData={setTaskData}
                  />
                ),
              },
              {
                label: "START/DUE DATE",
                component: <DueDateRangePopover />,
              },
            ].map(({ label, component }) => (
              <div key={label}>
                <p className="Text-Center" style={label == "TIME ESTIMATE" ? {marginBottom : "2px"} : {}}>{label}</p>
                {component}
              </div>
            ))}
          </div>
          <div className="Task-Modal-Body-Wrapper">
            <div className="Task-Modal-Body-Child1">
              <Row className="Task-Modal-Body" sm={12}>
                <Col
                  className="Task-Info-Wrapper"
                  sm={activitySection && activitySection.open ? 7 : 12}
                >
                  <Row className="TaskNameWrapper margin-0">
                    <Input
                      className="Task-Name"
                      size="large"
                      placeholder="Task Name"
                      name="TaskName"
                      variant="borderless"
                      value={taskData["TaskName"]}
                      onChange={(e) => {
                        let temp = { ...taskData };
                        temp["TaskName"] = e.currentTarget.value;
                        setTaskData(temp);
                      }}
                    />
                  </Row>
                  <Row className="TaskDescriptionWrapper margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          label: "Description",
                          children: (
                            <TextArea
                              name="TaskDescription"
                              style={{ border: "none", color:"#5b6c97" }}
                              placeholder="Write description.."
                              value={taskData["TaskDescription"]}
                              onChange={(e) => {
                                let temp = { ...taskData };
                                temp["TaskDescription"] = e.currentTarget.value;
                                setTaskData(temp);
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </Row>
                  <Row
                    sm={12}
                    className="margin-0"
                    style={{ borderBottom: "1px solid #e4e4e4" }}
                  >
                    <Col sm={6}>
                      <CustomeTaskField
                        name={"PIPELINE:"}
                        icon={<FontAwesomeIcon icon={faUser} />}
                      >
                        <Select
                          mode={""}
                          style={{ width: 200 }}
                          value={taskData["PipelineROWID"]}
                          placeholder="Select Pipeline"
                          onChange={(val) => {
                            let temp = { ...taskData };
                            temp["PipelineROWID"] = val;
                            setTaskData(temp);
                          }}
                          options={allPipelinesData}
                        />
                      </CustomeTaskField>
                      <CustomeTaskField
                        name={"CREATED AT:"}
                        icon={<FontAwesomeIcon icon={faClock} />}
                      >
                        <span
                          style={{
                            fontWeight: "500",
                            opacity: "0.85",
                            padding: "12px",
                          }}
                        >
                          {taskData?.CREATEDTIME
                            ? dayjs(taskData?.CREATEDTIME).format("MM-DD-YYYY")
                            : ""}
                        </span>
                      </CustomeTaskField>
                      {/* <CustomeTaskField
                        name={"Start/Due date"}
                        icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                      >
                        {DueDateRangePopover()}
                      </CustomeTaskField> */}
                      {/* <CustomeTaskField
                        name={"Time Estimate:"}
                        icon={<FontAwesomeIcon icon={faClock} />}
                      >
                        <TimePicker
                          className="TimePicker-Estimate"
                          defaultValue={dayjs("12:08", "HH:mm")}
                          name="TimeEstimate"
                          format={"HH:mm"}
                          value={
                            taskData["TimeEstimate"]
                              ? dayjs(taskData["TimeEstimate"], "HH:mm")
                              : null
                          }
                          onChange={(time, timestr) => {
                            console.log("timestr", timestr);
                            let temp = { ...taskData };
                            temp["TimeEstimate"] = timestr;
                            setTaskData(temp);
                          }}
                        />
                      </CustomeTaskField> */}
                    </Col>
                    <Col sm={6}>
                      {/* <CustomeTaskField
                        name={"Status"}
                        icon={<FontAwesomeIcon icon={faDotCircle} />}
                      >
                        <StatusDropdown
                          module="CreateTask"
                          toggelButton={
                            <span
                              className="project_status_custom"
                              style={{
                                backgroundColor: taskLayout.status_details[0]
                                  .color
                                  ? taskLayout.status_details[0].color
                                  : "",
                                maxWidth: "8rem",
                              }}
                            >
                              {taskLayout.status_details[0].name}
                            </span>
                          }
                          items={
                            taskLayout.status_details
                              ? taskLayout.status_details
                              : []
                          }
                          onSelect={async (value) => {}}
                        />
                      </CustomeTaskField> */}
                      {/* <CustomeTaskField
                        name={"Supervisor:"}
                        icon={<FontAwesomeIcon icon={faUser} />}
                      >
                        <UserPicker
                          mode={""}
                          values={taskData["Supervisor"]}
                          onChange={(val) => {
                            let temp = { ...taskData };
                            temp["Supervisor"] = val;
                            setTaskData(temp);
                          }}
                          options={userOptions}
                        />
                      </CustomeTaskField> */}
                      {/* <CustomeTaskField
                        name={"Assignee:"}
                        icon={<FontAwesomeIcon icon={faUser} />}
                      >
                        <UserPicker
                          mode={"multiple"}
                          values={taskData["Assignee"]}
                          onChange={(val) => {
                            let temp = { ...taskData };
                            temp["Assignee"] = val;
                            console.log(temp);
                            setTaskData(temp);
                          }}
                          options={userOptions}
                        />
                      </CustomeTaskField> */}
                      {/* <CustomeTaskField
                        name={"Priority:"}
                        icon={<FontAwesomeIcon icon={faFlag} />}
                      >
                        <Select
                          className="priority-icon"
                          name="Priority"
                          placeholder="Select an option"
                          style={{ width: 200 }}
                          value={taskData["Priority"]}
                          onChange={(val) => {
                            let temp = { ...taskData };
                            temp["Priority"] = val;
                            console.log(temp);
                            setTaskData(temp);
                          }}
                          optionLabelProp="icon"
                          title={taskData["Priority"]}
                        >
                          <Option
                            value="Not Set"
                            icon={
                              <FlagFilled
                                style={{ color: getPriorityValue("Not Set") }}
                              />
                            }
                          >
                            <FlagFilled
                              style={{ color: getPriorityValue("Not Set") }}
                            />{" "}
                            Not Set
                          </Option>
                          <Option
                            value="Low"
                            icon={
                              <FlagFilled
                                style={{ color: getPriorityValue("Low") }}
                              />
                            }
                          >
                            <FlagFilled
                              style={{ color: getPriorityValue("Low") }}
                            />{" "}
                            Low
                          </Option>
                          <Option
                            value="Medium"
                            icon={
                              <FlagFilled
                                style={{ color: getPriorityValue("Medium") }}
                              />
                            }
                          >
                            <FlagFilled
                              style={{ color: getPriorityValue("Medium") }}
                            />{" "}
                            Medium
                          </Option>
                          <Option
                            value="High"
                            icon={
                              <FlagFilled
                                style={{ color: getPriorityValue("High") }}
                              />
                            }
                          >
                            <FlagFilled
                              style={{ color: getPriorityValue("High") }}
                            />{" "}
                            High
                          </Option>
                        </Select>
                      </CustomeTaskField> */}
                      <CustomeTaskField
                        name={"TASK RELATION:"}
                        icon={<MinusCircleFilled />}
                      >
                        {DependecyModal()}
                      </CustomeTaskField>
                    </Col>
                  </Row>
                  {/* <Row className="margin-15">{getTableForLookupFields()}</Row> */}
                  <Row
                    sm={12}
                    className="margin-0"
                    style={{ borderBottom: "1px solid #e4e4e4" }}
                  >
                    <Col sm={6}>
                      <CustomeTaskField name={"INPUT FIELDS:"} icon={""}>
                        <Button style={{ border: "none" }}>Add Fields</Button>
                      </CustomeTaskField>
                    </Col>
                    {/* <Col sm={6}>
                      <CustomeTaskField name={"LOOK UP FIELDS:"} icon={""}>
                        <Button style={{ border: "none" }}>Add Fields</Button>
                      </CustomeTaskField>
                    </Col> */}
                  </Row>
                  <Row
                    sm={12}
                    className="margin-0"
                    style={{ borderBottom: "1px solid #e4e4e4" }}
                  >
                    {/* <Col sm={6}>
                      <CustomeTaskField name={"INPUT FIELDS:"} icon={""}>
                        <Button style={{ border: "none" }}>Add Fields</Button>
                      </CustomeTaskField>
                    </Col> */}
                    <Col sm={6}>
                      <CustomeTaskField name={"LOOK UP FIELDS:"} icon={""}>
                        <Button style={{ border: "none" }}>Add Fields</Button>
                        {/* {DependecyModal()} */}
                      </CustomeTaskField>
                    </Col>
                  </Row>
                  <Row
                    sm={12}
                    className="margin-15"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #e4e4e4",
                    }}
                  >
                    <Col sm={6}>
                      <CustomeTaskField
                        name={"ATTACHMENTS:"}
                        icon={<FontAwesomeIcon icon={faPaperclip} />}
                      >
                        <Button
                          title="Upload attachments"
                          onClick={() => {
                              setAttachmentModal(!attachmentModal)
                          }}
                          // className="compose-btns"
                          style={{ position: "relative" }}
                        >
                          Upload files
                        </Button>
                        <AttachmentModal
                          handleAttachementRemove={handleAttachementRemove}
                          setAttachmentModal={setAttachmentModal}
                          attachmentModal={attachmentModal}
                          handleUploadFiles={handleUploadFiles}
                          // data={props.dealObj}
                          fileNames={fileNames}
                          setFileNames={setFileNames}
                          uploadFiles={uploadFiles}
                        />
                      </CustomeTaskField>
                    </Col>
                    <Col sm={6}>
                      {/* <CustomeTaskField
                        name={"Look Up Fields:"}
                        icon={""}
                      >
                       <Button style={{border : "none"}}>Add Fields</Button>
                      </CustomeTaskField> */}
                    </Col>
                  </Row>
                  <Row className="margin-15">
                    <Tabs
                      activeKey={activeTab}
                      onTabClick={(key) => {
                        setActiveTab(key);
                      }}
                      defaultActiveKey={isSubTaskView ? "2" : "1"}
                      items={[
                        // {
                        //   label: (
                        //     <Badge color="blue" count={1} offset={[10, 0]}>
                        //       <span>Details</span>
                        //     </Badge>
                        //   ),
                        //   key: "1",
                        //   children: "Tab 1",
                        // },
                        {
                          label: (
                            <Badge
                              color="blue"
                              count={taskData?.SubTasks?.length}
                              offset={[10, 0]}
                            >
                              <span>Sub Tasks</span>
                            </Badge>
                          ),
                          key: "1",
                          disabled: isSubTaskView,
                          children: (
                            <SubTaskTab
                              allPipelineData={allPipelinesData}
                              groupFilter={""}
                              module={"View Task"}
                              subtasks={
                                taskData?.SubTasks ? taskData.SubTasks : []
                              }
                              viewTaskFunction={(data) => {
                                setIsSubTaskView(true);
                                setTaskData(data);
                                setActiveTab("2");
                              }}
                              taskData={taskData}
                              key={"Task Name"}
                              selectedTable={selectedTable}
                              subTaskData={subTaskData}
                              setSubTaskData={setSubTaskData}
                              isSubTaskView={isSubTaskView}
                              setIsSubTaskView={setIsSubTaskView}
                            />
                          ),
                        },
                        {
                          label: (
                            <Badge color="blue" count={4} offset={[10, 0]}>
                              <span>Check List</span>
                            </Badge>
                          ),
                          key: "2",
                          children: <Checklist />,
                        },
                      ]}
                    />
                  </Row>
                </Col>
                {activitySection && activitySection.open && (
                  <Col className="Task-Activity-Wrapper" sm={5}>
                    <div className="Activity-Header">
                      <span className="header">{activitySection.key}</span>
                    </div>
                    <div className="Activity-Body">
                      {taskModalState.action == "edit" &&
                        activitySection.component(
                          taskData?.TaskROWID,
                          isSubTaskView
                        )}
                    </div>
                    <div className="Activity-Comment-Body">
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: "Comments",
                            children: (
                              <TextArea
                                name="Comment"
                                style={{ border: "none" }}
                                placeholder="Write Comment.."
                                value={taskData["Comment"]}
                                onChange={(e) => {
                                  let temp = { ...taskData };
                                  temp["Comment"] = e.currentTarget.value;
                                  setTaskData(temp);
                                }}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="Task-Modal-Body-Child2">
              <div className="Task-Sidebar" sm={2}>
                {sidebarOptions &&
                  sidebarOptions.map((item, i) => {
                    return (
                      <div
                        className="mb-3 SidebarBtns"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          let arr = [...sidebarOptions];
                          arr = arr.map((a, k) => {
                            if (i != k) {
                              a.open = false;
                            } else {
                              a.open = !a.open;
                            }
                            return a;
                          });
                          setSidebarOptions(arr);
                          setActivitySection(arr[i]);
                        }}
                      >
                        <Badge
                          showZero={false}
                          count={1}
                          color="#ec4242d1"
                          offset={[0, 0]}
                        >
                          <Button
                            style={{ border: "none" }}
                            icon={item.icon}
                          ></Button>
                        </Badge>
                        <span>{item.key}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </AntModal>
    </div>
  );
};

export default CreateTaskModal;
