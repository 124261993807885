import "gantt-task-react/dist/index.css"; 
import './GanttView.css';
import React, { useEffect, useState } from 'react';
import {Gantt, ViewMode} from 'gantt-task-react'; 
import AwsServerService from '../../common/util/AwsServerService'; 
import { ViewSwitcher } from "./viewSwitcher"; 
import swal from 'sweetalert';
import { random } from "mathjs";
import moment from 'moment';  
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
export default function GanttView({view, isChecked,currentMonth,setCurrentMonth}) { 
  // const [view, setView] = useState(ViewMode.Day); 
  // const [isChecked, setIsChecked] = useState(true);
  const [taskOriginalData,setTaskOriginalData]=useState([]);
  const [cacheTaskData,setCacheTaskData]=useState([]);
  const [columnWidth, setColumnWidth] = useState(70)
  // const [currentMonth, setCurrentMonth] = useState(moment().startOf('month'));
  const demoTask=[{
    id: '1',
    name: 'No Data',
    start: new Date(currentMonth?._d), 
    type:'task',
    end: new Date(currentMonth.clone().endOf('month')?._d),
    styles: {
      backgroundSelectedColor: 'rgba(255, 255, 255, 0)',  
      backgroundColor: 'rgba(255, 255, 255, 0)', 
      progressColor: 'rgba(32, 168, 248, 0)',  
      progressSelectedColor: 'rgba(255, 255, 255, 0)' 
    },
    isDisabled:true
  }];
  const [taskData,setTaskData]=useState(demoTask); 
  useEffect(()=>{
   AwsServerService.getOnlyTasks().then(r=>{
    let data=r[0];
    setTaskOriginalData(data);
    console.log('original',data); 
    data=data?.map((e,index)=>{ 
      let obj={
        id: e.ROWID,
        name: e.TaskName,
        start:new Date(e.StartDate), 
        end: new Date(e.DueDate),
        // progress: random(10,50),
        type: "task",
        project: "ProjectSample",
        displayOrder: index,
        styles: { backgroundSelectedColor: '#018EE0',  
          backgroundColor: '#018EE0', }
      }
      return obj;
    })
    if(data.length>0){
      setCacheTaskData(data);
      setTaskData(data);
    }else{
      setCacheTaskData(demoTask);
      setTaskData(demoTask);
    }
   })
  },[]) 
useEffect(()=>{
  if (view === ViewMode.Month) {
    setColumnWidth(300)
  } else if (view === ViewMode.Week) {
    setColumnWidth(250)
  }else {
    setColumnWidth(70)
  } 
},[view])
  
const handleTaskChange = (task) => { 
  let data=[...taskData];
  let newTasks = data?.map((t) =>{
    if(t.id==task.id){
      return {
      ...t,
      end:task.end,
      start:task.start
     } 
    }
    return t;
  })
  if(newTasks){  
    setTaskData(newTasks);
  }
}

const customTooltip = ({ task, fontSize, fontFamily }) => {
  if(task?.name=='No Data'){
    return
  }
  const { name, start, end, type, progress } = task;
  return (
    <div className="task-details" 
    style={{backgroundColor: 'aliceblue',padding:'1rem',borderRadius:'1em'}}
    >
    <h6>Task Details</h6>
    <div className="detail">
      <strong>Name:</strong> <span style={{marginRight: '1em'}}>{name}</span> 
      <strong>Type:</strong> <span>{type}</span>
    </div>
    <div className="detail">
      <strong>Start:</strong> <span>{moment(start).format('MM/DD/YYYY')}</span>
    </div>
    <div className="detail">
      <strong>End:</strong> <span style={{marginRight:'.5em'}}>{moment(end).format('MM/DD/YYYY')}</span>
      <strong>{`( `}</strong>{moment(end).diff(moment(start),'Day')}<span> Days</span><strong>{` )`}</strong>
    </div>
    {/* <div className="detail">
      <strong>Progress:</strong> <span>{progress }%</span>
    </div> */}
  </div>
  );
};
const handleProgressChange = async (task) => {debugger
  setTaskData(taskData.map((t) => (t.id === task.id ? {...t, progress:task.progress} : t))) 
} 
 
const handleSelect = (task, isSelected) => {
  console.log(task.name + " has " + (isSelected ? "selected" : "unselected"))
}

const handleExpanderClick = (task) => {
  setTaskData(taskData.map((t) => (t.id === task.id ? task : t)))
  console.log("On expander click Id:" + task.id)
}
useEffect((e)=>{
  let data=[...cacheTaskData]
  console.log(data); 
  // debugger
  const startOfMonth = currentMonth.clone().startOf('month');
    const endOfMonth = currentMonth.clone().endOf('month'); 
    const filteredTasks = data?.filter(task => {
      const taskStart = moment(task.start); 
      const taskEnd = moment(task.end);  
      return taskStart.isBefore(endOfMonth, 'day') && taskEnd.isAfter(startOfMonth, 'day');
    });
    if(filteredTasks.length>0){ 
      setTaskData(filteredTasks);
    }else{ 
      setTaskData(demoTask);
    } 
},[currentMonth])
const goToNextMonth = () => { 
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
};

const goToPreviousMonth = () => { 
  setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
};
return (
  <div>
    {/* <ViewSwitcher
      onViewModeChange={(viewMode) => setView(viewMode)}
      viewMode={view}
      onViewListChange={setIsChecked}
      isChecked={isChecked}
    />  */}
    {/* <div className="buttonsForDateChange">
      <Button className={'btnclass'} onClick={goToPreviousMonth} type="primary" icon={<LeftOutlined />} ghost> Prev </Button>
      <Button className={'btnclass'} onClick={goToNextMonth} type="primary" icon={<RightOutlined />} ghost>Next</Button>
      </div> */}
     <Gantt
      tasks={taskData}
      viewMode={view}
      viewDate={currentMonth.toDate()}
      todayColor={'#f8720d'}
      TooltipContent={customTooltip}
      onDateChange={handleTaskChange} 
      onProgressChange={handleProgressChange} 
      onSelect={handleSelect}
      onExpanderClick={handleExpanderClick}
      listCellWidth={isChecked ? "155px" : ""}
      ganttHeight={480}
      columnWidth={columnWidth}
    />
  </div>
)
}