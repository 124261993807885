import { CCollapse } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react'
import './collapse.css'
import { OtsideClickClosingWrapper } from '../../EmojiReactionPicker/OtsideClickClosingWrapper';
import UserService from '../../util/UserService';
import GroupImg from "../../../assets/images/groupImg.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSignOutAlt, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ChatService from '../../util/ChatService';
import { GlobalSearchComponent } from '../GlobalSearchComponent';

export const GroupInfoCollapse = ({ setchatMembersDetails,chatMembersDetails,showGroupCollapse, setSelectUser, reloadState, loader, setLoader, getAllUsers, setShowGroupCollapse, setallAvailableUsers, selectUser, setChatMessages, chatMessages, allAvailableUsers }) => {
  const [visible, setVisible] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [externalSearchTrigger, setexternalSearchTrigger] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [testToSearch, settestToSearch] = useState("");
  const [filteredAllUsers, setFilteredAllUsers] = useState([]);
  const [addGroupToggle, setAddGroupToggle] = useState(false)
  console.log("chatMessages", chatMessages);
  useEffect(e => {
    if (allAvailableUsers) {
      let arr = allAvailableUsers.filter(main => {
        if (selectUser.recipients_summary.find(e => e.user_id == main.zuid)) {
          main["action"] = "REMOVE"
        } else {
          main["action"] = "ADD"
        }
        if (main.chat_type == "dm") {
          return main
        }
      })
      console.log("All Users List", arr)
      setAllUsers(arr)
    } else {
      setAllUsers([])
    }
  }, [allAvailableUsers, reloadState])
  useEffect(e => {
    if (!addGroupToggle && selectUser.recipients_summary) {
      setFilteredAllUsers(selectUser.recipients_summary)
    } else if (addGroupToggle && allAvailableUsers) {
      setFilteredAllUsers(allAvailableUsers.filter(k => k.chat_type == "dm"))
    }
  }, [addGroupToggle, selectUser])
  useEffect(e => {
    if (showGroupCollapse) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [showGroupCollapse])
  const handleGroupAction = async (user) => {
    if (user.action == "ADD") {
      setLoader(true);
      await ChatService.AddMemberInGroup(user, selectUser.chat_id).then(e => {
        ;
        let currentUser;
        let newUsers = allAvailableUsers.map(e => {
          if (e.chat_id == selectUser.chat_id) {
            if (e.recipients_summary) {
              e.recipients_summary.push({
                "user_id": user.zuid,
                "name": user.name
              })
              currentUser = e;
            }
          }
          return e;
        })
        if (currentUser) {
          setSelectUser(currentUser)
        }
        setallAvailableUsers(newUsers);
        setLoader(false);
      }).catch(e => {
        console.log(e);
        setLoader(false);
      })

    } else if (user.action == "REMOVE") {
      setLoader(true);
      await ChatService.RemoveMemberInGroup(user, selectUser.chat_id).then(e => {
        ;
        let currentUser;
        let newUsers = allAvailableUsers.map(e => {
          if (e.chat_id == selectUser.chat_id) {
            if (e.recipients_summary) {
              e.recipients_summary = e.recipients_summary.filter(u => u.user_id != user.zuid)
              currentUser = e;
            }
          }
          return e;
        })
        if (currentUser) {
          setSelectUser(currentUser)
        }
        setallAvailableUsers(newUsers);
        setLoader(false);
      }).catch(e => {
        console.log(e);
        setLoader(false);
      })

    }
  }
  useEffect(e => {
     
    if (addGroupToggle) {
      if (testToSearch) {
        if (allAvailableUsers) {
          let sortedData = allUsers.filter(e => e.name.toLowerCase().includes(testToSearch.toLowerCase()));
          setFilteredAllUsers(sortedData)
        }
      } else {
        setFilteredAllUsers(allAvailableUsers.filter(k => k.chat_type == "dm"))
      }
    } else {
      if (testToSearch) {
        if (selectUser?.recipients_summary) {
          let sortedData = selectUser?.recipients_summary?.filter(e => e.name.toLowerCase().includes(testToSearch.toLowerCase()));
          setFilteredAllUsers(sortedData)
        }
      } else {
        setFilteredAllUsers(selectUser?.recipients_summary)
      }
    }
  }, [testToSearch])

  const isAdminUser=(user)=>{
     
    if(chatMembersDetails){
      let currentUserAdmin = chatMembersDetails.find(e=>e.zuid==localStorage.getItem("ZUID") && e?.isadmin==true);
      if(currentUserAdmin){
        return true;
      }
    }
    return false;
  }

  if (selectUser?.chat_type !== "chat") {
    return (
      <OtsideClickClosingWrapper className={`Mentions-group-Collapse`} close={(e) => {
        if (visible) {
          setVisible(false);
        }
      }}>
        <CCollapse
          id="collapseWidthExample" verticle visible={visible} className={`group-collapse ${visible && "Bordered-Box"}`}>
          <div className='group-section-wrapper main-group-wrapper' style={{ height: "25%" }}>
            <div className='Group-profile-photo'>
              <img src={UserService.GetUserPhotoUrl(selectUser?.zuid)} alt="Group-img" />
            </div>
            <div className='Group-info'>
              <span className='group-name'>{selectUser?.name}</span>
              {/* <div className='add-member'>
                          <FontAwesomeIcon icon={faUserPlus} style={{color: "#018ee0"}}/>
                          <span>Add member</span>
                      </div>  */}
              {/* <div className='group-search'>
                <FontAwesomeIcon icon={faSignOutAlt} style={{ color: "#018ee0" }} />
                <span>Leave Group</span>
              </div> */}
            </div>
          </div>
          <div
            className="group-section-wrapper img-wrapper-div Attachments-Wrapper"
            style={{ height: "30%" }}>
            <div className='doc-label'>All Attachments</div>
            <div className='main-div-wrapper'>

              {chatMessages.map((item) => {
                if (item.type == "file" && item?.content?.thumbnail?.blur_data != undefined) {
                  return (
                    <div className='docs-wrapper'>
                      <img src={`data:image/png;base64,${item?.content?.thumbnail?.blur_data}`} alt="" />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </CCollapse>

      </OtsideClickClosingWrapper>
    )
  } else {
    return (
      <OtsideClickClosingWrapper className={`Mentions-group-Collapse`} close={(e) => {
        if (visible) {
          setShowGroupCollapse(false);
          setVisible(false);
        }
      }}>
        <CCollapse
          id="collapseWidthExample" verticle visible={visible} className={`group-collapse ${visible && "Bordered-Box"}`}>
          <div className='group-section-wrapper main-group-wrapper' style={{ height: "25%" }}>
            <div className='Group-profile-photo'>
              <img src={GroupImg} alt="Group-img" />
            </div>
            <div className='Group-info'>
              <span className='group-name'>{selectUser.name}</span>
              {/* <div className='add-member'>
                        <FontAwesomeIcon icon={faUserPlus} style={{color: "#018ee0"}}/>
                        <span>Add member</span>
                    </div>  */}
              {!selectUser?.removed && <div className='group-search' 
              onClick={e=>{
                if(window.confirm("Are you sure want to leave the group?")){
                    ChatService.LeaveChatGroup(selectUser.chat_id).then(res=>{
                      let oldUser={...selectUser}
                      oldUser["removed"]=true;
                      setSelectUser(oldUser)
                      getAllUsers()
                    }).catch(err=>{
                      console.log(err)
                    })
                  }
                  }}>
                <FontAwesomeIcon icon={faSignOutAlt} style={{ color: "red" }} 
                />
                  <span style={{color:"red"}}>Leave</span>
              </div>}
            </div>
          </div>

          <div
            className="group-section-wrapper img-wrapper-div Attachments-Wrapper"
            style={{ height: "30%" }}>
            <div className='doc-label'>All Attachments</div>
            <div className='main-div-wrapper'>

              {chatMessages.map((item) => {
                if (item.type == "file" && item?.content?.thumbnail?.blur_data != undefined) {
                  return (
                    <div className='docs-wrapper'>
                      <img src={`data:image/png;base64,${item?.content?.thumbnail?.blur_data}`} alt="" />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className='group-section-wrapper' style={{ height: "45%" }}>
            <div className='group-member-wrapper'>
              <div className='member-list-wrapper top-header'>
                <span>All Members</span>
                <div className='add-member'>
                  <GlobalSearchComponent
                    setexternalSearchTrigger={setexternalSearchTrigger}
                    externalSearchTrigger={externalSearchTrigger}
                    settextToSearch={settestToSearch}
                    action={"live"}
                  />
                  {isAdminUser()? !addGroupToggle ? <FontAwesomeIcon icon={faUserPlus}
                    onClick={e => {
                      setAddGroupToggle(!addGroupToggle);
                    }}
                  /> :
                    <FontAwesomeIcon icon={faUser}
                      onClick={e => {
                        setAddGroupToggle(!addGroupToggle);
                      }}
                    />:<></>
                  }
                </div>
              </div>
              {
                !addGroupToggle && filteredAllUsers ? filteredAllUsers.map((item) => {
                  return <div className='member-list-wrapper'>
                    <img src={UserService.GetUserPhotoUrl(item.user_id)} alt="" />
                    <span>{item.name}</span>
                  </div>
                }) :
                  filteredAllUsers && filteredAllUsers?.map((item) => {
                    return <div className='member-list-wrapper'>
                      <img src={UserService.GetUserPhotoUrl(item.zuid)} alt="" />
                      <span>{item.name}</span>
                      <span className={`${item.action}`} onClick={e => {
                        handleGroupAction(item);
                      }}>{item.action}</span>
                    </div>
                  })
              }
            </div>
          </div>
        </CCollapse>

      </OtsideClickClosingWrapper>
    )
  }
}
