import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import AppContext from "../../../../Context/AppContext";
import { HexColorPicker } from "react-colorful";
import { Dropdown as AntDropDown } from "antd";
import Select from "react-select";
import Geocode from "react-geocode";
import CustumSelect from "../../../../common/SelectWithSearch";
import axios from "axios";
import ReactInputMask from "react-input-mask";
import { OtsideClickClosingWrapper } from "../../../../common/EmojiReactionPicker/OtsideClickClosingWrapper";
import GlobalTable from "../../../../common/DealTableChart/GlobalTable";
import { ClientFullServices, ClientServices,ServicesForClient, billingTerms } from "../../../../Context/AppActions";
import Config from "../../../../config.json";
import swal from "sweetalert";

export default function VendorLayout(props) {
    
    const AppData = useContext(AppContext);
    const { state, dispatch } = AppData || {};
    const [mailisValid,setMailisValid]=useState(false);
    const [zipValid,setZipValid]=useState(false);
    
      const options = [
        "Sales Software",
        "Sales Hardware",
        "Rental Income",
        "Interest Income",
        "Sales Software Support",
        "Sales Other",
        "Interest Sales",
        "Labor Hardware Service",
      ];
    
        const [validation, setValidation] = useState({
          Vendor_Name: "0",
          Email: "0", 
          Company_Name: "0",
          Company_Address: "0",
          City: "0",
          State: "0",
          Zip_Code: "0", 
          GL_Account:"0"
        });
        const validationCss = {
          "transition": "width 0.4s ease-in-out",
          "box-shadow": "rgb(255 98 98) 0px 0px 7px 1px",
          "border-color": "#ff0000",
        };    

        const isSaveClicked = state.SaveClickedForClient;
        useEffect(
          (e) => { 
            let obj={}; 
            Object.keys(props.data)?.forEach((one) => { 
             if(one!="Active"){ 
              if ( ( props.data[one] == "" || props.data[one] == undefined) ) { 
               obj[one] = "-1";
             } else {
               obj[one] = validation[one];
             }}
           }); 
           setValidation(obj);
          },
          [isSaveClicked]
        );
        useEffect(
          (e) => {   
           setValidation({
            Vendor_Name: "0",
            Email: "0", 
            Company_Name: "0",
            Company_Address: "0",
            City: "0",
            State: "0",
            Zip_Code: "0", 
            GL_Account:"0"
          });
          },
          [props.layoutstate]
        );
    const onChangeCompanyAdd = (value) => {
      props.handleChange({currentTarget:{name:'ProjectAddress',value:value}});
      props.setData(value);
    };
    let valid=(emailtext)=>{
      for(let i=1;i<emailtext.length;i++){
        if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
          return false;
        }
      }
      return true;
    }
    const onChangeEmail = (value) => { 
      let validMail=false;
      let str=value.target.value.toString()
      const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
      const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
      if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
          if(!regforSpec.test(str)){
              if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
                validMail=true;
                setValidation({...validation,Email:'1'})
              }
          }
      }
        setMailisValid(validMail);
    }
    const onBlurEmail = (value) => { 
      let validMail=false;
      let str=value.target.value.toString()
      const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
      const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
      if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
          if(!regforSpec.test(str)){
              if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
                validMail=true;
                setValidation({...validation,Email:'1'})
              }
          }
      }
        if(validMail){
          setValidation({...validation,Email:"1"})
        }else{
          if(str.length==0){
            setValidation({...validation,Email:'1'})
          }else{
            setValidation({...validation,Email:'-1'})
          }
        }
    }
  
    const onChangePhoneNumber = (value) => {
      var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
      if (value1.length < 10) {
        props.setPhoneNumber(value);
      }
      if (value1.length == 12) {
        props.setPhoneNumber("");
      }
      if (value1.length == 10) {
        props.setPhoneNumber(
          "(" +
            value1.substring(0, 3) +
            ") " +
            value1.substring(3, 6) +
            "-" +
            value1.substring(6, 10)
        );
        props.setNumInvalid(false);
      }
      if (value.length == 0) {
        props.setNumInvalid(false);
      }
    };
  
    const onBlurPhoneNumber = (value) => {
      var value1 = value.replace(/[-#*$+/?=%() ]/g, "");
      if (value1.length < 10 && value.length != 0) {
        props.setNumInvalid(true);
      } else {
        props.setNumInvalid(false);
      }
    };
  
    function handleErrors(type) {
      console.log("click event");
      if (type === "FirstName") {
        if (props.frstName.length === 0) {
          console.log("change ");
          props.setFrstNameInvalid(true);
        } else {
          props.setFrstNameInvalid(false);
        }
      }
      if (type === "LastName") {
        if (props.lastName.length === 0) {
          console.log("change ");
          props.setLastNameInvaild(true);
        } else {
          props.setLastNameInvaild(false);
        }
      }
      if (type === "Password") {
        if (props.Password.length === 0) {
          props.setPasswordInvalid(true);
        } else {
          props.setPasswordInvalid(false);
        }
      }
      if (type === "Address") {
        if (props.address.length === 0) {
          props.setAddressInvalid(true);
        } else {
          props.setAddressInvalid(false);
        }
      }
      if (type === "City") {
        if (props.city.length === 0) {
          props.setCityInvalid(true);
        } else {
          props.setCityInvalid(false);
        }
      }
      if (type === "State") {
        if (props.state.length === 0) {
          props.setStateInValid(true);
        } else {
          props.setStateInValid(false);
        }
      }
      if (type === "Zip") {
        if (props.zip.length === 0) {
          props.setZipInvalid(true);
        } else {
          props.setZipInvalid(false);
        }
      }
    }
  
    //   const uploadProfilePhoto = async (file) => {
  
    //     // props.setProfilePhoto(file);
  
    //       let returnData = await HttpService.CreateUpdate('uploadprofilephoto', file).then(res =>  {
    //       //   console.log(res.data);
    //         if(res.data!=null){
    //           alert(res.data);
  
    //         }else if(res.data==null || res.data==undefined){
    //           alert("error");
    //         }
  
    //       });
  
    // }
    const [file, setFile] = useState(null);
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
    const handleFileUpload = async (e) => {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);
      props.setFileName(base64);
    };
  
    const placeInputRef1 = useRef(null);
  useEffect(()=>{
      initPlaceAPI()
  })
  
  const initPlaceAPI = () => {
      
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef1.current
      );
  }
  
  const setFilterStreet = (street) => {
    var str = street.replace(/(\d+)(st|nd|rd|th)/g, "$1");
    return str;
  };
  
  Geocode.setApiKey("AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw");
  Geocode.setLanguage("en");
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("us");
  Geocode.setLocationType("ROOFTOP");
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();
  
  
    const onBlurProjectAdd = (value) => {
  
      Geocode.fromAddress(value).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const { lat, lng } = response.results[0].geometry.location;
          let obj={}
          var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "",
          borough = "",
          neighbourhood = "";
            
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "street_number":
                  houseNO = response.results[0].address_components[i].long_name;
                  break;
                case "route":
                  street = response.results[0].address_components[i].long_name;
                  break;
               
                  break;
                case "sublocality_level_1":
                  borough = response.results[0].address_components[i].long_name;
                  if (
                    borough == "Manhattan" ||
                    borough == "Bronx" ||
                    borough == "Brooklyn" ||
                    borough == "Queens" ||
                    borough == "Staten Island"
                  ) {
                    if (borough == "Manhattan") {
                      localStorage.setItem("borough", "1");
                      city = "New York";
                    } else if (borough == "Bronx") {
                      localStorage.setItem("borough", "2");
                      city = borough;
                    } else if (borough == "Brooklyn") {
                      localStorage.setItem("borough", "3");
                      city = borough;
                    } else if (borough == "Queens") {
                      localStorage.setItem("borough", "4");
                      city = neighbourhood;
                    } else if (borough == "Staten Island") {
                      localStorage.setItem("borough", "5");
                      city = borough;
                    } else {
                      localStorage.setItem("borough", "0");
                    }
                  } else {
                    localStorage.setItem("nycOption", "0");
                    localStorage.setItem("borough", "");
                    city = response.results[0].address_components[i].long_name;
                  }
                  break;
                case "locality":
                  if (
                    borough != "Manhattan" &&
                    borough != "Bronx" &&
                    borough != "Brooklyn" &&
                    borough != "Queens" &&
                    borough != "Staten Island"
                  ) {
                    city = response.results[0].address_components[i].long_name;
                   
                  
                    localStorage.setItem("nycOption", "No");
                    localStorage.setItem("borough", "");
                  }
  
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].short_name;
                  break;
                case "postal_code":
                  zipcode = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          if(street!==""){
            setValidation({...validation,Company_Address:'1',City:'1',State:'1',
            Zip_Code:'1'})
          }
          var str = value;
          var lastIndex = str.lastIndexOf(",");
          var res = str.substring(lastIndex);
          if(lastIndex>0){
            if(res.includes("USA")){
              str = str.substring(0, lastIndex);
              str=str+" "+zipcode;
            }
          }
        
          obj.Company_Address = str;
         
  
          var filterStreet= setFilterStreet(street)
         
          localStorage.setItem("houseNo", houseNO);
         
         
          localStorage.setItem("street", filterStreet);
        
         
          localStorage.setItem("city", city);
          obj.City=city;
         
         
          localStorage.setItem("state", state);
          obj.State=state;
          
          
          obj.Zip_Code=zipcode;
         
          localStorage.setItem("zipcode", zipcode);
        
         
         
          localStorage.setItem("lat", lat);
         
          localStorage.setItem("lng", lng);
          console.log(lat, lng);
         
        props.handleChange({currentTarget:{name:'Company_Address',value:obj}})
        },
        (error) => {
          console.error("Error Finding Place:" + error);
        }
      );
    };
  
  //   let handleUsermailid = (e) => {
  return (
    <div className="color-white">
      <>
        <div className="boxplate">
          <div className="boxplate-heading" style={{ display: "flex" }}>
            <div className="sectitle" style={{width:"13%"}}>{props.layoutstate}</div>
          </div>
          <div className="pad-15 createdeal-custom-scroll">
            <Row className="mar-15 create-user-box">
              <div className="mainWrap">
                {props.layoutstate == "Vendor Information" ? (
                  <>
                  <Col sm={3}></Col>
                  <Col sm={9} className="createuser-border2" style={{overflowY: "auto"}}>
                  <div className="user-form-border2">
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Vendor Name<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <input
                  type="text"
                  name="Vendor_Name"
                  className="form-control create-user-input"
                  value={props.data ? props.data.Vendor_Name : ""}
                  style={
                    validation["Vendor_Name"] == "-1"
                      ? { ...validationCss }
                      : {}
                  }
                  onChange={(e) => {
                    e.target.value = e.target.value
                      .trimStart()
                      .replace(/[0-9_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i, "");
                    e.target.value =
                      e.target.value
                        .replace(/[^\D]/g, "")
                        .charAt(0)
                        .toUpperCase() +
                      e.target.value.replace(/[^\D]/g, "").slice(1);
                    if (e.target.value != "") {
                      setValidation({ ...validation, Vendor_Name: "1" });
                    }
                    props.handleChange(e);
                  }}
                  onBlur={(e) => {
                    if (e.target.value == "" || e.target.value == undefined) {
                      setValidation({ ...validation, Vendor_Name: "-1" });
                    } else {
                      setValidation({ ...validation, Vendor_Name: "1" });
                    }
                  }}
                />
              </Col>
              <Row>
                <Col sm={2}></Col>
                <Col sm={4} className="ml-3">
                  <span
                    className={
                      validation["Vendor_Name"] == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    Field should not be empty.
                  </span>
                </Col>
              </Row>
              <Col></Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Email<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <Input
                  type="text"
                  name="Email"
                  className="create-user-input"
                  style={
                    validation["Email"] == "-1" ? { ...validationCss } : {}
                  }
                  value={props.data ? props.data.Email : ""}
                  placeholder="abc@mail.com"
                  onChange={(e) => {
                    e.target.value = e.target.value.trim();
                    onChangeEmail(e);
                    props.handleChange(e);
                  }}
                  onBlur={(e) => onBlurEmail(e)}
                />
              </Col>
              <Row>
                <Col sm={2}></Col>
                <Col sm={4} className="ml-3">
                  <span
                    className={
                      validation["Email"] == "-1"
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    Please Enter A Valid E-mail Address.
                  </span>
                </Col>
              </Row>
              <Col></Col>
              <Col sm={3}></Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Company Name<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <input
                  type="text"
                  name="Company_Name"
                  className="form-control create-user-input"
                  style={validation["Company_Name"]=="-1"?{...validationCss}:{}}
                  value={props.data ? props.data.Company_Name : ""}
                  onChange={(e) => {
                    e.target.value = e.target.value.trimStart();
                    if (e.target.value == "" || e.target.value == undefined) {
                      setValidation({ ...validation, Company_Name: "-1" });
                    } else {
                      setValidation({ ...validation, Company_Name: "1" });
                    }
                    props.handleChange(e);
                  }}
                  onBlur={(e) => {
                    if (e.target.value == "" || e.target.value == undefined) {
                      setValidation({ ...validation, Company_Name: "-1" });
                    }
                  }}
                />
              </Col>
              <Row>
                <Col sm={2}></Col>
                <Col sm={4} className="ml-3">
                  <span
                    className={validation["Company_Name"] == "-1" ? "red-text font12" : "error-display"}
                  >
                    {" "}
                    Field should not be empty.
                  </span>
                </Col>
              </Row>
              <Col></Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Company Address<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <input
                  type="text"
                  ref={placeInputRef1}
                  name="Company_Address"
                  style={validation["Company_Address"]== "-1"?{...validationCss}:{}}
                  className="form-control create-user-input"
                  value={props.data ? props.data.Company_Address : ""}
                  // onChange={(e)=>onChangeCompanyAdd(e)}
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart().replace(/[_!#$%^&*()<>?|}{~:]/,'');
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,Company_Address:'-1'})
                    }else{
                      setValidation({...validation,Company_Address:'1'})
                    }
                    props.handleChange(e)}}
                  onBlur={(e) => {
                    if(e.target.value==undefined||e.target.value==''){
                            setValidation({...validation,Company_Address:'-1'})
                          }else{
                            setValidation({...validation,Company_Address:'1'})
                          }
                    onBlurProjectAdd(e.target.value)}}
                  //   onBlur={(e) => handleErrors("Address")}
                />
              </Col>
              <Row>
                <Col sm={2}></Col>
                <Col sm={4} className="ml-3">
                  <span
                    className={validation["Company_Address"]== "-1" ? "red-text font12" : "error-display"}
                  >
                    {" "}
                    Field should not be empty.
                  </span>
                </Col>
              </Row>
              <Col></Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  City<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <input
                  type="text"
                  name="City"
                  style={validation["City"]== "-1"?{...validationCss}:{}}
                  className="form-control create-user-input"
                  value={props.data ? props.data.City : ""}
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.trimStart().replace(/[0-9]/,'');
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,City:'-1'})
                            }else{
                              setValidation({...validation,City:'1'})
                            }
                    props.handleChange(e)}}
                    onBlur={(e) =>{
                      if(e.target.value==undefined||e.target.value==''){
                        setValidation({...validation,City:'-1'})
                      }else{
                        setValidation({...validation,City:'1'})
                      }
                      // handleErrors("City")
                    }}
                />
              </Col>
              <Row>
                <Col sm={2}></Col>
                <Col sm={4} className="ml-3">
                  <span
                    className={validation["City"]== "-1" ? "red-text font12" : "error-display"}
                  >
                    {" "}
                    Field should not be empty.
                  </span>
                </Col>
              </Row>
              <Col></Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  State<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <input
                  type="text"
                  name="State"
                  style={validation["State"]== "-1"?{...validationCss}:{}}
                  className="form-control create-user-input"
                  value={props.data ? props.data.State : ""}
                  onChange={(e) =>{
                    e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,State:'-1'})
                            }else{
                              setValidation({...validation,State:'1'})
                            }
                    props.handleChange(e)}}
                    onBlur={(e) => {
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,State:'-1'})
                            }else{
                              setValidation({...validation,State:'1'})
                            }
                      // handleErrors("State")
                    }}
                />
              </Col>
              <Row>
                <Col sm={2}></Col>
                <Col sm={4} className="ml-3">
                  <span
                    className={validation["State"]== "-1" ? "red-text font12" : "error-display"}
                  >
                    {" "}
                    Field should not be empty.
                  </span>
                </Col>
              </Row>
              <Col></Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Zip<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>
                <input
                  type="text"
                  name="Zip_Code"
                  style={validation["Zip_Code"]== "-1"?{...validationCss}:{}}
                  className="form-control create-user-input"
                  value={props.data ? props.data.Zip_Code : ""}
                  onChange={(e) => {
                    e.target.value=e.target.value.trimStart();
                    e.target.value=e.target.value.replace(/\D/g, '');
                    if(e.target.value.length>5){
                      e.preventDefault()
                      return
                    }
                    if(e.target.value.length==5){
                      setValidation({...validation,Zip_Code:'1'})
                      setZipValid(true);
                    }
                    props.handleChange(e)
                  }}
                  onBlur={(e) => {
                    if(props.data.Zip_Code?.length<5){
                      setValidation({...validation,Zip_Code:'-1'})
                      setZipValid(false);
                    }else{
                      setValidation({...validation,Zip_Code:'1'})
                      setZipValid(true);
                    }
                    // handleErrors("Zip")
                  }}
                />
              </Col>
              <Row>
                <Col sm={2}></Col>
                <Col sm={4} className="ml-3">
                  <span
                    className={validation["Zip_Code"]== "-1" ? "red-text font12" : "error-display"}
                  >
                    {" "}
                    Please Enter A Valid Zip Code.
                  </span>
                </Col>
              </Row>
              <Col></Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  Is Active<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={2}>
                <Input
                  type="checkbox"
                  name="Active"
                  checked={props.data.Active}
                  onChange={props.handleChange}
                  className="create-user-checkbox"
                />
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label className="text-bold" for="projectnumber">
                  GL Account<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={5}>{
                validation &&
                <CustumSelect
                  addFlag={true}
                  placeholder={"Select "}
                  value={props.data ? props.data.GL_Account : ""}
                  Color={validation.GL_Account=="-1"?-1:1}
                  onChange={(value) => {
                    if(value!=undefined||value!=""){
                      setValidation({...validation,"GL_Account":"1"})
                    }
                    props.handleChange({
                      currentTarget: { name: "GL_Account", value: value },
                    });
                  }} 
                  addItemLabel={" Add Client"}
                  //   addItemAction={clientModalToggle}
                  items={options}
                />}
                {/* <Select
                    options={options}
                    // isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    // components={{ Option, MultiValue, animatedComponents }}
                    onChange={(e) =>props.handleChange({ currentTarget: {name: "GL_Account", 
                    value: e  } })}
                    allowSelectAll={true}
                    value={props.data ? props.data.GL_Account : ""}
                    menuPlacement="auto"
            
             /> */}
              </Col>
              <Col sm={2}></Col>
            </Row>
          </FormGroup>

          {/* <FormGroup>
            <Row>
              <Col sm={3}></Col>
              <Col sm={2}>
                <Button className="next-button next-btn3D float-right" onClick={props.saveUserData}>
                  {" "}
                  Save{" "}
                </Button>
              </Col>
              <Col sm={7}></Col>
            </Row>
          </FormGroup> */}
        </div>
                  </Col>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Row>
            
          </div>
        </div>
      </>
    </div>
  );
}