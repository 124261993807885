import React, { useContext, useEffect, useMemo, useState } from 'react'
import './RenderAllTasks.css';
import { Button, Collapse } from 'antd';
import { Col, Row } from 'reactstrap';
import { AllDeals } from '../../Context/AppActions';
import AppContext from '../../Context/AppContext';
import { CaretDownOutlined } from '@ant-design/icons';
import AwsServerService from '../../common/util/AwsServerService';
import { TaskGroupCard } from './TaskGroupCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { getValueByPath, invertColor } from '../HR/TimeClock/Util';
import { useHistory, useLocation } from "react-router-dom";
import _ from 'lodash';

const { Panel } = Collapse;
export const HeaderContent = ({ data, key }) => {
  console.log(data[key]);

  return <Row sm={12}>
    <Col sm={6}>{data[key]}</Col>
    <Col sm={6}></Col>
  </Row>
}
export const RenderAllTasks = ({
  allPipelineData, viewTaskFunction,searchText,searchSettings,
  group, DealStatus, taskFilterSetting ,selectedTable,isSubTaskView, setIsSubTaskView, refreshData,setPageRefreshData
  ,allDependeniesData, dropdownTasksDataCache, allTasksAndSubtasks, setShowDependencies
}) => {
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [arrayOfGroupKeys, setArrayOfGroupKeys] = useState([])
  const [activeKeys, setActiveKeys] = useState([])
  const [deals, setDeals] = useState([]);
  const [group1Data, setGrpoup1Data] = useState({});
  const [cacheGroup1Data, setCacheGroup1Data] = useState({});
  const [groupFilter, setGroupFilter] = useState({
    group1: { name: "Status", value: "Stage", extraKey: "Account_Name" },
    group2: { name: "Pipeline", value: "PipelineROWID" },
  })
  const [taskHeadersData, setTaskHeadersData] = useState([]);
  const history = useHistory();
  useEffect(e => {
    // console.log("deal status---", DealStatus);
  }, [DealStatus])
  useEffect(e => {
    setGroupFilter(group)
  }, [group])
  const getColorOfDealStatus = (dealId) => {
    let st = {
      backgroundColor: "#5b6c97",
      color: "white"
    }
    if (groupFilter?.group1?.value == "Deal_Name") {
      let deal = deals?.find(d => d.Deal_Name == dealId);
      if (deal) {
        let temp = DealStatus?.find(ds => ds.display_value == deal.Stage);
        if (temp?.colour_code) {
          st["backgroundColor"] = temp.colour_code;
          st["color"] = invertColor(temp.colour_code, true)
        }
      }
    } else if (groupFilter?.group1?.value == "Stage") {
      let temp = DealStatus?.find(ds => ds.display_value == dealId);
      if (temp?.colour_code) {
        st["backgroundColor"] = temp.colour_code;
        st["color"] = invertColor(temp.colour_code, true)
      }
    } else if (groupFilter?.group1?.value == "Client") {
      let temp = state?.AllAccounts?.find(ds => ds.id == dealId);
      if (temp?.Color) {
        st["backgroundColor"] = temp?.Color;
        st["color"] = invertColor(temp?.Color, true)
      }
    }
    return st;
  }
  // const getPanelKeys = () => {
  //   const keys = [];
  //   Object.keys(group1Data).forEach(key => {
  //     keys.push(key);
  //     // Add nested keys if any
  //     Object.keys(group1Data[key]).forEach(subKey => {
  //       keys.push(`${key}-${subKey}`);
  //     });
  //   });
  //   setArrayOfGroupKeys(keys);
  //   return keys;
  // };
  useEffect(e => {
    const keys = [];
    if (groupFilter && groupFilter.group1 && groupFilter.group2) {
      if (groupFilter.group1 && groupFilter.group1.value) {
        let obj = {};
        deals?.forEach(data => {
          let val;
          if (groupFilter.group1.isNested) {
            val = groupFilter.group1.getValue(data);
          } else {
            val = data[groupFilter.group1.value];
          }
          if (obj[val]) {
            let allTasks = allPipelineData.filter(p => p.DealName == data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[val] = [...obj[val], ...allTasks]
          } else {
            let allTasks = allPipelineData.filter(p => p.DealName == data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[val] = [...allTasks];
            keys.push(`${val}`);
          }
        });
        if (groupFilter.group2 && groupFilter.group2.value) {
          Object.keys(obj).forEach(key => {
            let objtemp = {};
            obj[key].forEach(task => {
              if (objtemp[task[groupFilter.group2.value]]) {
                objtemp[task[groupFilter.group2.value]].push(task)
              } else {
                objtemp[task[groupFilter.group2.value]] = [task];
              }
            })
            obj[key] = objtemp;
          })
        }
        // console.log("---------- new ",obj);
        setGrpoup1Data(obj);
        setCacheGroup1Data(obj);
      }
    }
    setArrayOfGroupKeys(keys);
  }, [groupFilter, deals, allPipelineData])

  useEffect(e=>{
    if(cacheGroup1Data){
      if(searchText){
        let obj=_.cloneDeep(cacheGroup1Data);
        Object.keys(obj).forEach(group1 => {
          Object.keys(obj[group1]).forEach(group2=>{
            let temp=[...obj[group1][group2]];
            if(temp && temp.length>0 && searchSettings.TaskName){
              temp = temp.filter(task=>task.TaskName && _.capitalize(task.TaskName)?.includes(_.capitalize(searchText)));
            }
            if(temp && temp.length>0 && searchSettings.Description){
              temp = temp.filter(task=>task.TaskDescription && _.capitalize(task.TaskDescription)?.includes(_.capitalize(searchText)));
            }
            obj[group1][group2]=temp && temp.length>0 ?temp:[];
          })
        })
        setGrpoup1Data(obj);
      }else{
        setGrpoup1Data({...cacheGroup1Data});
      }
    }
  },[searchText,searchSettings,cacheGroup1Data])

  useEffect(e => {
    setDeals(state.AllDeals)
    // console.log(state.AllDeals);

  }, [state.AllDeals])
  const getGroup1HeaderValue = (key, group) => {
    if (group == "Client") {
      let accountDeal = deals?.find(d => d?.Client?.id == key);
      if (accountDeal?.Client?.name) {
        return accountDeal.Client.name;
      }
    }
    return key
  }


  return (
    <div className='PiplinesList-Wrapper'>
      <Collapse onChange={(e) => {
        // console.log("change---", e);
        setActiveKeys(e);
      }} activeKey={taskFilterSetting?.ExpandDeals ? arrayOfGroupKeys : activeKeys} defaultActiveKey={['1']} expandIconPosition="left" >
        {group1Data && Object.keys(group1Data)?.length > 0 && Object.keys(group1Data)?.map((key, index) => {
          return <Panel className='Group-Panel-hover'
            header={<Row sm={12} >
              <Col style={{ display: "flex", flexDirection: "row" }} sm={6}><span style={getColorOfDealStatus(key)} className='Group1-Card' onClick={() => {
                let data = state.AllDeals?.find(e => e.Deal_Name == key);
                if (data) {
                  history.push({
                    pathname: "/d/Deals/DetailView/" + data.id,
                    state: {
                      data: data,
                      action: "DetailView",
                      module: "Deals",
                    },
                  })
                }
              }}>{getGroup1HeaderValue(key, groupFilter.group1.value)}</span><Button className='Group-View-Btn' type='primary' icon={<FontAwesomeIcon icon={faEye} />} onClick={e => { }} ghost>View</Button></Col>
              <Col sm={6}></Col>
            </Row>}
            key={key} style={{ height: 'auto' }} >
            {(taskHeadersData.length > 0 || taskHeadersData.length == 0) && Object.keys(group1Data[key]).map(key2 => {
              return <TaskGroupCard
                selectedTable={selectedTable}
                isSubTaskView={isSubTaskView}
                setIsSubTaskView={setIsSubTaskView}
                taskFilterSetting={taskFilterSetting}
                viewTaskFunction={viewTaskFunction}
                groupFilter={groupFilter}
                allPipelineData={allPipelineData}
                data={group1Data[key][key2]}
                header={key2}
                taskHeadersData={taskHeadersData}
                setTaskHeadersData={setTaskHeadersData}
                refreshData={refreshData}
                setPageRefreshData={setPageRefreshData}
                setShowDependencies={setShowDependencies}
                dropdownTasksDataCache={dropdownTasksDataCache}
                allTasksAndSubtasks={allTasksAndSubtasks}
                allDependeniesData={allDependeniesData}
              />
            })}

          </Panel>
        })}

      </Collapse>
    </div>
  )
}
