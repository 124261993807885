// CustomNode.js
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Handle } from 'reactflow';

const CustomNode = ({ data }) => {
  return (
    <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', background: '#fff' }}>
      <div style={{ fontWeight: 'bold' }}><FontAwesomeIcon className='mr-1' color={data.color?data.color:"#fff5e0"} icon={faCircle}/>{data.label}</div>
      <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <Handle type="target" position="top" id="target" style={{ background: '#555' }} />
        <Handle type="source" position="bottom" id="destination" style={{ background: '#555' }} />
        <Handle type="source" position="right" id="reject" style={{ background: '#f00' }} />
      </div>
    </div>
  );
};

export default CustomNode;
