import { faPlayCircle, faStopCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'  
import { Popover, Button, Tabs, DatePicker as AntdDatepicker, Collapse } from 'antd';
import {Row, Col} from 'reactstrap'
import TimePicker from 'react-time-picker';  
import { faSlidersH, faPen, faEye, faTrashAlt, faAlignLeft, } from '@fortawesome/free-solid-svg-icons';
import { CustomeCollapse } from '../../tabs/Task/AddTaskTabs/CustomeCollapse';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';  
import './TimeTracker.css'
import { convertToUSTime, formateNoInDecimalString, getWorkingHoursAndMinutes, modifyTimezoneString } from '../../components/HR/TimeClock/Util'
import AwsServerService from '../util/AwsServerService'
import moment from 'moment';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { CCollapse } from '@coreui/react';
import swal from 'sweetalert'
import { number } from 'mathjs';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const TimeTracker = ({viewTaskData, users,allTasksAndSubtasks}) => {

  const [startTimer, setStartTimer] = useState(false)
  const [timer, setTimer] = useState(0); 
  const [timer2, setTimer2] = useState(0); 
  const [lastEntry, setLastEntry] = useState(null); 
  const [userList, setUserList] = useState([]);
  const [FromRange, setFromRange] = useState(null); 
  const [key, setKey] = useState('timer');  
  const [timeLogDate, setTimeLogDate] = useState('');
  const [ToRange, setToRange] = useState(null); 
  const [timetrackedPopover, settimetrackedPopover] = useState(false);
  const clockInterval=useRef(null);
  const availableRecord=useRef(null);
  const [typeOfTask, setTypeOfTask] = useState({ROWID:'',Type:''});
  const [description, setDescription] = useState('');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [anotherSessionActiveFlag, setAnotherSessionActiveFlag] = useState({Active:false,Record:{}});
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));  

  function calculateTimeDifference(startTime, stopTime) { 
    const start = moment(startTime);
    const stop = moment(stopTime); 
    const duration = moment.duration(stop.diff(start)); 
    return duration.asSeconds();
  }
  
  const checkForActiveTrackingSessions=()=>{
    AwsServerService.getAllTimeRecords().then(r=>{
      let data=r.data[0];
      let flag=false;
      let record={};
      for (let i = 0; i < data.length; i++) {
       if(data[i].StopTime=='-'){
        flag=true;
        record=data[i];
       }
    }
    let tasksData={...allTasksAndSubtasks};
    if(record && record.TaskType=='Tasks'){ 
        const newData=tasksData.Tasks.get(number(record.TaskROWID));
        record['TaskName']=newData.TaskName;
    }else if(record && record.TaskType=='SubTasks'){
      const newData=tasksData.SubTasks.get(number(record.TaskROWID));
      record['TaskName']=newData.TaskName;
    }     
    setAnotherSessionActiveFlag({Active:flag,Record:record});
    })
  }
  
  const checkRecordIfAvailable=(taskId,taskType)=>{
     AwsServerService.getTimeRecordsByTask(taskId,taskType).then(r=>{
       let data=r.data[0]; 
       let obj={};
       data.forEach(entry => { 
         if (obj[entry.CreatedBy]) { 
          obj[entry.CreatedBy].push(entry);
        } else {
          obj[entry.CreatedBy]=[entry];
        }
      });
      let totalSeconds=0;
      let taskRecord={};
      let flag=true;
      let userLists=[];
      Object.keys(obj).forEach(e=>{
        let info= users.find(el=>el.value==e);
        let totalSec=0;
        obj[e].forEach(record=>{
          if(record.StopTime=='-' && flag==true){
            const now = new Date();
            const StopTime=convertToUSTime(now);
            const { StartTime } = record;
            totalSeconds += calculateTimeDifference(StartTime, StopTime);
            let currentSessionTime= calculateTimeDifference(StartTime, StopTime);
            setTimer2(currentSessionTime);
            setStartTimer(true);
            taskRecord=record; 
            flag=false;
            }else{ 
                const { StartTime, StopTime } = record;
                totalSeconds += calculateTimeDifference(StartTime, StopTime);
                totalSec += calculateTimeDifference(StartTime, StopTime);
              }
        })
        let object={
          userName:info?.label,
          userId:info?.value,
          Entrys:obj[e],
          TotalTime:formatTime(totalSec)
        }
        userLists.push(object);
      })
      setTimer(totalSeconds); 
       if(!flag){
        setLastEntry(taskRecord);
       }
       setUserList(userLists); 
  })
  } 

  useEffect(()=>{
    if(viewTaskData){
      clearInterval(clockInterval.current);
      setTimer(0);
      setStartTimer(false);
      setTimer2(0);
      checkForActiveTrackingSessions();
      let type=viewTaskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
      if(type=='Tasks'){
        checkRecordIfAvailable(viewTaskData.TaskROWID,type)
        setTypeOfTask({ROWID:viewTaskData.TaskROWID,Type:type});
      }else{
        checkRecordIfAvailable(viewTaskData.SubTaskROWID,type)
        setTypeOfTask({ROWID:viewTaskData.SubTaskROWID,Type:type});
      }
    }else{
      clearInterval(clockInterval.current);
      setTimer(0);
      setStartTimer(false);
      setTimer2(0);
      setAnotherSessionActiveFlag({Active:false,Record:{}})
      setUserList([]);
      setDescription('');
      setTypeOfTask({ROWID:'',Type:''});
    }
  },[viewTaskData]) 

  const startStopClock = async (isActive) => {
    if (!isActive) {
      availableRecord.current=null;
      let stopTime =  convertToUSTime(new Date()); 
      let startTime= convertToUSTime(new Date(lastEntry.StartTime));
      let timeObj=getWorkingHoursAndMinutes(new Date(startTime), new Date(stopTime));
      let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
      let payload={ 
        StopTime:stopTime,
        TotalTime:TotalHours,
        ROWID:lastEntry.ROWID,
        Description:description
      } 
      await AwsServerService.updateTimeEntry(payload).then((result) => {
          payload['CreatedBy']=lastEntry.CreatedBy;
          payload['StartTime']=lastEntry.StartTime;  
          setLastEntry(null); 
          let arr=[...userList];
           arr.forEach(element=>{
            if(element.userId==lastEntry.CreatedBy){
              element['Entrys'].map(el=>{
                if(el.ROWID==payload.ROWID){
                  el.StopTime=payload.StopTime;
                  el.TotalTime=payload.TotalTime;
                }
              }) 
            }
          })
          setUserList(arr);
          setDescription('');
          setIsSaveClicked(false);
        })
        .catch((err) => { 
          console.log(err);
        });
    } else {
      let startTime =  convertToUSTime(new Date());
      let payload={
        StartTime:startTime,
        StopTime:'-',
        TaskROWID:typeOfTask.ROWID,
        TaskType:typeOfTask.Type, 
        CreatedBy:viewTaskData.Assignee,
        TotalTime:'-',
        Description:description
      } 
      await AwsServerService.createTimeEntry(payload).then(async (result) => {  
        if(result.data[0].insertId){
            let RowId=result.data[0].insertId;
            await AwsServerService.getTimeEntryById(RowId,typeOfTask.ROWID,typeOfTask.Type).then(res=>{
              let data=res.data[0];
              let obj = {
                StartTime:  modifyTimezoneString(data[0].StartTime,"MM/DD/YYYY HH:mm:ss"),
                StopTime:  modifyTimezoneString(data[0].StopTime,"MM/DD/YYYY HH:mm:ss"),
                TaskType:data[0].TaskType,
                TaskROWID:data[0].TaskROWID,
                CreatedBy:data[0].CreatedBy,
                ROWID:data[0].ROWID
              }; 
              setLastEntry(obj);
              setIsSaveClicked(true)
            })
          } else { 
            setLastEntry(null);
          }  
        })
        .catch((err) => { 
          console.log(err);
        });
    }
  }; 
  useEffect(() => { 
    if (lastEntry ) { 
      // if(availableRecord.current=='paused'){
      //   return;
      // }
      let seconds = 1; 
      seconds = Math.round(seconds / 1000);
      setTimer((timer) => timer + seconds); 
      setTimer2((timer2) => timer2 + seconds); 
      clockInterval.current = setInterval(() => {
        setTimer((timer) => timer + 1);
        setTimer2((timer2)=> timer2 +1);
      }, 1000);
    } else {
      clearInterval(clockInterval.current);
      setTimer2(0);
      // setTimer(0); 
    }
  }, [lastEntry]);
 
  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds} Hrs`;
  };
  const getDatePickerValue = (date) => {
    try {
      let d = date
      if (d) {
        return dayjs(new Date(d));
      } else {
        return dayjs(new Date()).subtract(1,"day");
      }
    } catch (error) {}
  };
  const [expandedEntry, setExpandedEntry] = useState(null); 
  function handleDeleteEntry(data){
    AwsServerService.deleteTimeTrackRecord(data.ROWID).then(result=>{
      let arr=[...userList];
      arr.forEach((element,index)=>{debugger
       if(element.userId==data.CreatedBy){
        let entrys= element['Entrys'].filter(el=>el.ROWID!=data.ROWID); 
        arr[index]['Entrys']=entrys;
       }
     })
      setUserList(arr);
      swal('Success','Record deleted successfully','info')
    })
  }
  const getTimeTrackPopUp = () => {
    const popoverContent = (
      <div>
        {/* <div id="timetracklist">
          <div>
            <img src={localStorage.getItem('ProfilePhoto')} alt="Profile" />
            {localStorage.getItem('UserName')}
          </div>
          {formatTime(timer)}
        </div> */}
        <Collapse rootClassName='Time-Track-Collapse'>
        {userList.map(user => (
          <Panel
            header={
              <div className="timetracklist">
                <div className="timetracklist2">
                <img src={ "https://contacts.zoho.com/file?ID=" + user.userId + "&fs=thumb"} alt={'s Profile'} style={{height: '2rem', borderRadius: '1rem'}}/>
                <div>{user.userName}</div>
                <div>{user.TotalTime}</div>
                </div>
              </div>
            }
            key={user.userId}
            showArrow={false}
          >
            <div className="time_track_scroll">
              {user['Entrys'].map((el, i) => (
                <Row className="no-gutters user-time-track" key={i}>
                  <Col>
                    <ul className="list-unstyled">
                      <li>
                        {el.StartTime ? (
                          <FontAwesomeIcon icon={faPlayCircle} />
                        ) : (
                          <FontAwesomeIcon icon={faSlidersH} />
                        )}
                      </li>
                      <li>{el.TotalTime}</li>
                      <li>{moment(el.StartTime).format('MM-DD-YYYY')}</li>
                    </ul>
                  </Col>
                  <Col className="text-right">
                    <ul className="list-unstyled">
                      <li>
                        <EyeOutlined className="view" onClick={() => {
                          setExpandedEntry(expandedEntry && expandedEntry?.ROWID === el?.ROWID ? null : el);
                        }} />
                      </li>
                      <li>
                        <DeleteOutlined className="delete" onClick={() => { 
                          swal({
                            title: "Are you sure?",
                            text: `You want to delete the time track record?`,
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          })
                            .then(async (willDelete) => {
                              if (willDelete) { 
                                handleDeleteEntry(el);
                              }
                            });
                        }} />
                      </li>
                    </ul>
                  </Col>
                  {expandedEntry && expandedEntry?.ROWID === el.ROWID && (
                    <CCollapse verticle visible={expandedEntry?.ROWID === el.ROWID} className='View-Time-Collapse'>
                       <div className='View-Time-Ettry'>
                          <p><span>From:</span>  {moment(expandedEntry.StartTime).format('MM-DD-YYYY HH:mm')}</p>
                          <p><span>To:</span> {expandedEntry.StopTime=='-'?'-':moment(expandedEntry.StopTime).format('MM-DD-YYYY HH:mm')}</p>
                          <span style={{fontWeight:'700'}}>Description:</span> 
                          <p style={{maxHeight: '3rem',overflow: 'auto', width: '113%'}}>{expandedEntry.Description || 'No description'}</p>
                      </div>
                    </CCollapse>
                  )}
                </Row>
              ))}
            </div> 
          </Panel>
        ))}
      </Collapse>
        <div className="p-2 border-top">
          <Tabs activeKey={key} onChange={(k) => setKey(k)} className="mb-2 time_tracked_tabs">
            <TabPane tab="Timer" key="timer">
              <Row style={{ marginTop: '20px' }}>
                <Col sm={9}>
                  <div className="d-flex align-items-center" style={{gap:"10px", marginBottom:"0.4rem"}}>
                  {startTimer ? <FontAwesomeIcon icon={faStopCircle} 
                   onClick={()=>{ 
                    setStartTimer(!startTimer)
                    startStopClock(false)
                   }} 
                  className="play-stop-button" 
                  style={{ color: 'rgb(204, 45, 45)' }}/>
                  : <FontAwesomeIcon icon={faPlayCircle} 
                  onClick={()=>{ 
                    if(anotherSessionActiveFlag.Active){
                      swal({
                        title: "Alert!",
                        text: `Time tracking is active for the task ${anotherSessionActiveFlag.Record?.TaskName}. Want to stop that Tracking Session?`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      })
                        .then(async (okay) => {
                          if (okay) {
                            let stopTime =  convertToUSTime(new Date()); 
                            let startTime= convertToUSTime(new Date(anotherSessionActiveFlag.Record?.StartTime));
                            let timeObj=getWorkingHoursAndMinutes(new Date(startTime), new Date(stopTime));
                            let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
                            let payload={ 
                              StopTime:stopTime,
                              TotalTime:TotalHours,
                              ROWID:anotherSessionActiveFlag.Record?.ROWID
                            } 
                            await AwsServerService.updateTimeEntry(payload).then((result) => { 
                                startStopClock(true);
                                setStartTimer(!startTimer);
                                console.log(result);
                              })
                              .catch((err) => { 
                                console.log(err);
                              });
                          } 
                        }); 
                    }else{
                      startStopClock(true);
                      setStartTimer(!startTimer);
                    } 
                   }} 
                   className="play-stop-button" 
                   style={{ color: 'rgb(45, 204, 110)' }}/>
                   }
                    <div>
                      {formatTime(timer2)}
                    </div>
                  </div>
                </Col>
                <Col sm={3}>
                  {/* <span className="desc_icon">
                    <FontAwesomeIcon icon={faAlignLeft} id="description" />
                  </span> */}
                </Col>
              </Row>
              <Row className="margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          label: <FontAwesomeIcon icon={faAlignLeft} id="description" />,
                          children: (
                            <TextArea
                            onClick={()=>setIsSaveClicked(false)}
                            name="TaskDescription"
                            style={isSaveClicked?{background:"#edf0f4"}:{ border: "none", color:"#5b6c97" }}
                            placeholder="Write description.."
                            value={description}
                            onChange={(e) => {
                               setDescription(e.currentTarget.value);
                            }}
                          />
                          ),
                        },
                      ]}
                    />
                  </Row>
            </TabPane>
            <TabPane tab="Range" key="range">
              <div className="time_tracked_fields mb-2">
              <AntdDatepicker
                  // disabledDate={disabledDateLocal}
                  allowClear
                  portalId="root"
                  format="MM/DD/YYYY"
                  value={getDatePickerValue(timeLogDate)}
                  placeholderText="Select a date"
                  popperClassName="ag-custom-component-popup"
                  className="form-control form-control-sm"
                  onChange={(date) => {
                    setTimeLogDate(dayjs(new Date(date)).format("MM/DD/YYYY"));
                  }}
                  // open={showDatePicker}
                  // onOpenChange={open => setShowDatePicker(open)}
                />
              </div>
              <div className="time_tracked_fields mb-2">
                <TimePicker
                  format="hh:mm a"
                  clearIcon={null}
                  disableClock={true}
                  onChange={(e) => setFromRange(e)}
                  value={FromRange}
                />
                -
                <TimePicker
                  format="hh:mm a"
                  clearIcon={null}
                  disableClock={true}
                  onChange={(e) => setToRange(e)}
                  value={ToRange}
                />
              </div>
              <Row>
                <Col sm={7}>
                  <p style={{ marginLeft: '14px' }}>When: now</p>
                </Col>
                <Col sm={5}>
                </Col>
              </Row>
              <Row className="margin-0">
                    <CustomeCollapse
                      defaultOpen={[]}
                      items={[
                        {
                          key: "1",
                          label: <FontAwesomeIcon icon={faAlignLeft} id="description" />,
                          children: (
                            <TextArea
                              name="TaskDescription"
                              style={{ border: "none", color:"#5b6c97" }}
                              placeholder="Write description.."
                              value={''}
                              onChange={(e) => {
                                // let temp = { ...taskData };
                                // temp["TaskDescription"] = e.currentTarget.value;
                                // setTaskData(temp);
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </Row>
            </TabPane>
          </Tabs>
          <div className="text-right mt-2" style={{gap:"10px"}}>
            <Button
              type="default"
              size="middle"
              onClick={() => {
                // setStartTime('');
                // setStopTime('');
                settimetrackedPopover(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                setIsSaveClicked(true);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  
    return (
      <Popover
        content={popoverContent}
        trigger="click"
        open={timetrackedPopover}
        overlayClassName='Time-Track-Popover'
        onOpenChange={(visible) => settimetrackedPopover(visible)}
        placement="bottom"
      >
      <div onClick={() => settimetrackedPopover(true)} style={{cursor:"pointer", marginLeft:'5px'}}>{timer==0?"00:00:00":formatTime(timer)}</div>

        {/* <span style={{cursor:"pointer"}} >{formatTime(timer)}</span> */}
      </Popover>
    );
  };

  return (
    <div className='Time-Track-Wrapper'>
      {startTimer ? <FontAwesomeIcon icon={faStopCircle} 
      onClick={()=>{ 
        setStartTimer(!startTimer)
        startStopClock(false)
      }} 
      className="play-stop-button" 
      style={{ color: 'rgb(204, 45, 45)' }}/>
     : <FontAwesomeIcon icon={faPlayCircle} 
     onClick={()=>{ 
      if(anotherSessionActiveFlag.Active){
        swal({
          title: "Alert!",
          text: `Time tracking is active for the task " ${anotherSessionActiveFlag.Record?.TaskName}. " Want to stop that Tracking Session?`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (okay) => {
            if (okay) {
              let stopTime =  convertToUSTime(new Date()); 
              let startTime= convertToUSTime(new Date(anotherSessionActiveFlag.Record?.StartTime));
              let timeObj=getWorkingHoursAndMinutes(new Date(startTime), new Date(stopTime));
              let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
              let payload={ 
                StopTime:stopTime,
                TotalTime:TotalHours,
                ROWID:anotherSessionActiveFlag.Record?.ROWID
              } 
              await AwsServerService.updateTimeEntry(payload).then((result) => { 
                  startStopClock(true);
                  setStartTimer(!startTimer);
                  console.log(result);
                })
                .catch((err) => { 
                  console.log(err);
                });
            } 
          }); 
        } else{
        startStopClock(true);
        setStartTimer(!startTimer);
        }
       }} 
       className="play-stop-button" 
       style={{ color: 'rgb(45, 204, 110)' }}/>}
       {getTimeTrackPopUp()}
    </div>
  )
}

export default TimeTracker