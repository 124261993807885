import httpService from "../../services/http.service";
import axios from "axios";
import Config from '../../config.json'
import dayjs from "dayjs";
import { buildFolderHierarchy } from "../../components/EmailSection/emailUtil";
import { typeOf } from "mathjs";
class EmailService{

    GetAllFolder(accountId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/folders",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    // result.data.data=result.data.data.filter(one=>one.folderName=="INBOX");
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

    

    UploadFileAttachement(accountId,file) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'FormData',
                method: 'post',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/messages/attachments",
                headers: { "Authorization": localStorage.getItem('token'),'Content-Type': 'application/octet-stream' },
                data: {fileName:"Text",file:file}
            }
            httpService.post('task', {config:config})
                .then((result) => {
                     
                    // result.data.data=result.data.data.filter(one=>one.folderName=="INBOX");
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    UploadEmailAttachement(accountId,file) {
        const formData = new FormData();
        // file.forEach((e) => {
            formData.append("files", file);
        // });
        return new Promise((resolve, reject) => {
            axios.post(Config.backend_url+`uploadMultipartFile?accountId=${accountId}`,formData,
            {headers: { 
                'content-type': 'multipart/form-data',
                "token": localStorage.getItem('token') 
            }})
            .then((result) => {
                 
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    GetEmailAttachement(accountId,folderId,messageId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'post',
                url: `https://mail.zoho.com/api/accounts/${accountId}/folders/${folderId}/messages/${messageId}/attachmentinfo`,
                headers: { "Authorization": localStorage.getItem('token'),'Content-Type': 'application/octet-stream' },
            }
            httpService.post('task', {config:config})
                .then((result) => {
                     
                    // result.data.data=result.data.data.filter(one=>one.folderName=="INBOX");
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    GetAllEmails(accountId,folderId,query) { 
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://mail.zoho.com/api/accounts/"+accountId+`/messages/view?limit=1000&${folderId && !query?"folderId="+folderId:''}${query?query:""}`,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    result.data.data.forEach((one)=>{
                        one.accountId=accountId
                    })
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetAllEmailMessages(accountId,folderId,query) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://mail.zoho.com/api/accounts/"+accountId+`/messages/view?limit=1000&${folderId ?"folderId="+folderId+"&" : ""}${query?query:""}`,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    result.data.data.forEach((one)=>{
                        one.accountId=accountId
                    })
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


    GetEmailMetaData(accountId,folderId,messageId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/folders/"+folderId+"/messages/"+messageId+"/details",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

    GetEmailContent(accountId,folderId,messageId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/folders/"+folderId+"/messages/"+messageId+"/content",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }


    GetCurrentEmailAccount(email) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://mail.zoho.com/api/accounts",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then(async (result) => {
                    result.data.data=result.data.data.filter(one=>one.incomingUserName==email)
                    if(result?.data?.data[0]?.accountId){
                        localStorage.setItem("AccountID",result?.data?.data[0]?.accountId)
                    }
                    for(let i=0;i<result.data.data.length;i++){

                        let res=await this.GetAllFolder(result.data.data[i].accountId);
                        result.data.data[i].Folders=buildFolderHierarchy(res.data);
                    }
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }


    DeleteEmail(accountId,folderId,messageId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/folders/"+folderId+"/messages/"+messageId,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

    HardDeleteEmail(accountId,folderId,messageId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/folders/"+folderId+"/messages/"+messageId+"?expunge=true",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    RestoreDeletedMail(accountId,folderId,messageId, mode) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'PUT',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/updatemessage",
                headers: { "Authorization": localStorage.getItem('token') },
                data:{
                        "mode": mode,
                        "destfolderId":folderId,
                        "messageId": messageId,
                    }
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    ArchiveEmails(accountId,messageId,mode) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'PUT',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/updatemessage",
                headers: { "Authorization": localStorage.getItem('token') },
                data:{
                        "mode": mode,
                        "messageId": messageId,
                    }
            }
            httpService.post('task', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    getSignature() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'Get',
                url: "https://mail.zoho.com/api/accounts/signature",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    SendEmail(accountId,data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/messages",
                headers: { "Authorization": localStorage.getItem('token') },
                data: data
            }
            httpService.post('chat', {config:config})
            .then((result) => {
                resolve(result.data);
            }).catch((err) => {
                reject(err);
            })
        })
    
    
    }

    ReplyToEmail(accountId,messageId,data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/messages/"+messageId,
                headers: { "Authorization": localStorage.getItem('token') },
                data: data
            }
            httpService.post('chat', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }


    GetAllAccounts() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "http://mail.zoho.com/api/accounts",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    CreateSubFolders(accountId,payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: `https://mail.zoho.com/api/accounts/${accountId}/folders`,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {...payload}
            }
            httpService.post('chat', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    DeleteSubFolder(accountId,folderId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: `https://mail.zoho.com/api/accounts/${accountId}/folders/${folderId}`,
                headers: { "Authorization": localStorage.getItem('token') },
                data:{}
            }
            httpService.post('chat', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    MoveToFolder(accountId,folderId,messageId) {
        let arr=[];
        if(typeOf(messageId) == typeOf([])){
            arr=[...messageId];
        }else{
            arr=[messageId]
        }
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'PUT',
                url: "https://mail.zoho.com/api/accounts/"+accountId+"/updatemessage",
                headers: { "Authorization": localStorage.getItem('token') },
                data:{
                        "mode": "moveMessage",
                        "destfolderId":folderId,
                        "messageId": arr,
                    }
            }
            httpService.post('chat', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    EmptyFolder(accountId,folderId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'PUT',
                url: `https://mail.zoho.com/api/accounts/${accountId}/folders/${folderId}`,
                headers: { "Authorization": localStorage.getItem('token') },
                data:{mode:"emptyFolder"}
            }
            httpService.post('chat', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    GetAttachmentsInfo(accountId,folderId,messageId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: `https://mail.zoho.com/api/accounts/${accountId}/folders/${folderId}/messages/${messageId}/attachmentinfo`,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    GetAttachmentsContent(accountId,folderId,messageId,attachmentId) {
        return new Promise((resolve, reject) => {
            let config = {
                url: `https://mail.zoho.com/api/accounts/${accountId}/folders/${folderId}/messages/${messageId}/attachments/${attachmentId}`,
                headers: { 
                    "Authorization": localStorage.getItem('token'),
                    "Content-Type": "application/json" 
                },
                fileType:""
            }
            httpService.post('downloadPdfByURL', config)
                .then((result) => {
                    resolve(result.data?.split("base64,")[1]);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    GetAllTags() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                headers: { "Authorization": localStorage.getItem('token') },
            }
            httpService.post('tags', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    AddTags(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Post',
                method: 'Post',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('tags', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    UpdateTags(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'Update',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('tags', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    DeleteTags(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Delete',
                method: 'Delete',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('tags', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

    AddTrashRecord(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Post',
                method: 'Post',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('trashMail', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

    GetTrashDataById(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'GetById',
                method: 'Post',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('trashMail', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    DeleteTrashRecord(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Delete',
                method: 'Delete',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('trashMail', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    TagEmailWithRecords(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Post',
                method: 'Post',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('emailassociation', {config:config})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    getRandomString(length = 8) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    AssociateEmail(payload,deals,sendflag) {
        const to = payload.toAddress?.split(",").map(add => ({ email: add.trim() }));
        const cc = payload.ccAddress?.split(",").map(add => ({ email: add.trim() }));
        const bcc = payload.bccAddress?.split(",").map(add => ({ email: add.trim() }));
        let emailObj={
            from:payload.fromAddress,
            subject:payload.subject,
            content:payload.content,
            mail_format: payload.mailFormat,
            date_time: dayjs(new Date()).toISOString,
            sent: sendflag,
            original_message_id: this.getRandomString(8),
        }
        if(to?.length>0){
            emailObj["to"]=to
        }
        if(cc?.length>0){
            emailObj["cc"]=cc
        }
        if(bcc?.length>0){
            emailObj["bcc"]=bcc
        }
        let ModifyData = {
            "Emails": [
               {...emailObj}
            ]
        }
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Post',
                method: 'Post',
                headers: { "Authorization": localStorage.getItem('token') },
                data:payload,
            }
            httpService.post('tags', {config:ModifyData})
            .then((result) => {
                resolve(result.data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }
    AssociateEmailWithRecords(Payload,type) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: type,
                url: Config.backend_url.replace("apiservice","notification")+"emailassociation",
                data: {...Payload}
            }
            httpService.post('call', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    GetAllAssociateEmailWithRecords() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                url: Config.backend_url.replace("apiservice","notification")+"emailassociation",
            }
            httpService.post('call', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


}

export default new EmailService();