import React from 'react';
import './Marker.css';

const Marker = (props) => {
    const { color, name, id } = props;
    var googleLink= "https://www.google.com/maps/search/?api=1&query="+(props.lat)+","+(props.lng)
    return (
      <div>
        <a href={googleLink}
      target="_blank">
        <div
          className="pin bounce"
          style={{ backgroundColor: color, cursor: 'pointer' }}
          title={name}
        />
        <div className="pulse" />
        </a>
      </div>
    );
  };

  export default Marker;