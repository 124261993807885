import React, { useContext, useEffect, useState } from "react";
import { Button, CardBody, Collapse, Card } from "reactstrap";
import AppContext from "../../../Context/AppContext";

function Billing(props) { 
  const [shouldClick,setShouldClick]=useState(false);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  useEffect(()=>{
 let data=state.isServiceAddersClickable;
  setShouldClick(data==0);
  },[state.isServiceAddersClickable])
  return (
    <div>
      <Button
        className={
          ((props.layoutstate == "BillingTerms"||props.layoutstate=="ServiceAdders")
            ? "navbarbtn-active"
            : "navbarbtn-100")
        }
        style={(props.layoutstate == "BillingTerms"||props.layoutstate=="ServiceAdders") ? 
        {color : "aliceblue", marginTop: "0px"} : {color : "#018ee0", marginTop: "0px"}}
        onClick={(e) => {
          if(props.location.state.action=="create" && !props.updatedSections.includes("BillingTerms")){
            e.preventDefault()
            return
          }else{
          localStorage.setItem('currentsidenavbar',"BillingTerms");
          props.setLayoutState("BillingTerms");
          }
        }}
      >
         Billing Terms
      </Button>
      <Collapse isOpen={(props.layoutstate == "BillingTerms"||props.layoutstate=="ServiceAdders")}>
        <Card className="sidenav-card createdeal-card">
          <CardBody className="navbar-sublist-card">
            <div
              className={
                props.layoutstate == "BillingTerms"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              onClick={(e) => {
                props.setLayoutState("BillingTerms")
                localStorage.setItem('currentsidenavbar',"BillingTerms");
              }}
            >
              Main Quote
            </div>
            <div
              className={
                 props.layoutstate == "ServiceAdders"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              onClick={(e) =>{
                if(shouldClick){
                  props.setLayoutState("ServiceAdders")
                  localStorage.setItem('currentsidenavbar',"ServiceAdders");
                }
            }}
            >
              Service Adders
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default Billing;
