import React, { useState } from 'react'
import './DayView.css';
import {
    Col,
    Row
  } from "reactstrap";
import { addTimeStrings, isBeforeEight } from '../Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import RenderActionButtons from './RenderActionButtons';
import dayjs from 'dayjs';
export const DayRender = ({Day,dayData,colourBand,incompleteColourFlag,isThisWeekDay,actionListCellRenderer,date,email, currentDate, totalHoursByDay, disableDates}) => {
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const colculateDayHours=(week)=>{
      
    let temp="00:00";
    dayData?.forEach(element => {
      temp=addTimeStrings(temp,element.TotalHours)
    });
    //return isBeforeEight("08:00",temp);
    return false;
  }
  const renderDayData=()=>{
    colculateDayHours();
     
    return (<>
        <Col className={`custome-col Day ${isThisWeekDay ?"Show-This-Day-Band Highlite":""} ${colculateDayHours() && incompleteColourFlag && !isThisWeekDay?"Less-Time-Than-Expected":""}`} sm={2}>
            {Day}
            {/* {colculateDayHours() && incompleteColourFlag?<FontAwesomeIcon icon={faExclamationCircle}/>:<></>} */}
        </Col>
        <Col className={`Day-Row-Wrapper ${isThisWeekDay ?"Show-This-Day-Band":""}`} sm={10}>
            {dayData && dayData.length>0 && dayData.map((one,i)=>{
                return (<Row className='custome-row' sm={12}>
                    <Col className='custome-col' sm={2}>{one.TimesheetDate+(!incompleteColourFlag?(" ("+one.UserName+")"):"")}</Col>
                    <Col className='custome-col' sm={2}>{one.checkIn}</Col>
                    <Col className='custome-col' sm={2}>{one.checkOut}</Col>
                    <Col className='custome-col' sm={2}>{one.TotalHours}</Col>
                    <Col className='custome-col' sm={2}>{(dayData.length-1==i)?totalHoursByDay:''}</Col>
                    <Col className='custome-col' sm={2}>
                      {dayjs(date).isBefore(dayjs().format("MM/DD/YYYY")) && <RenderActionButtons  id={currentDate} actionListCellRenderer={(data,email,obj)=>actionListCellRenderer(data,email,obj)} data={one} email={one.email}/>} 
                    </Col>
                    {/* <Col className='custome-col' sm={3}>Action</Col> */}
                </Row>)
            })}
            {dayData && dayData.length==0 && <Row className='custome-row' sm={12}>
                    <Col className='custome-col' sm={2}>{currentDate}</Col>
                    <Col className='custome-col' sm={2}></Col>
                    <Col className='custome-col' sm={2}></Col>
                    <Col className='custome-col' sm={2}></Col>
                    <Col className='custome-col' sm={2}></Col>
                    <Col className='custome-col' sm={2}>
                    {(dayjs(currentDate).isBefore(dayjs().format("MM/DD/YYYY")) && !disableDates?.includes(currentDate)) &&  <RenderActionButtons id={currentDate} empty={true} actionListCellRenderer={(data,email,obj)=>actionListCellRenderer(data,email,obj)} data={{TimesheetDate:currentDate,email:email}} email={email}/>}
                    </Col>
                    {/* <Col className='custome-col' sm={3}>Action</Col> */}
                </Row>
            }
        </Col>
    </>)
  }
  return (
    <div className={`Day-Wrapper ${colourBand}`}>
        {renderDayData(dayData)}
    </div>
  )
}
