import {
    faAngleDown,
  faAngleUp,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./GlobalSearch.css";

export const GlobalSearchComponent = ({
  setUpclicked,
  setDownclicked,
  settextToSearch,
  action,
  externalSearchTrigger,
  setexternalSearchTrigger,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  return (
    <div className="Global-Search-Wrapper">
      <div className="Search-Inpute-Wrapper">
        {isSearchVisible ? (
          <div className="Search-Inpute-Wrapper classforicons">
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faAngleDown}
              onClick={(e) => {
                setDownclicked({ state: true, time: e.timeStamp });
                setUpclicked({ state: false, time: e.timeStamp });
              }}
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faAngleUp}
              onClick={(e) => {
                setDownclicked({ state: false, time: e.timeStamp });
                setUpclicked({ state: true, time: e.timeStamp });
              }}
            ></FontAwesomeIcon>
          </div>
        ) : (
          <></>
        )}
        {action && action == "live" ? (
          <input
            type="text"
            value={searchText}
            id="searchInput"
            className={`text-search-field ${
              !isSearchVisible ? "close-search" : "open-search"
            }`}
            placeholder="Search..."
            onChange={(e) => {
              settextToSearch(e.currentTarget.value);
              setSearchText(e.currentTarget.value);
            }}
          />
        ) : (
          <input
            type="text"
            id="searchInput"
            value={searchText}
            className={`text-search-field ${
              !isSearchVisible ? "close-search" : "open-search"
            }`}
            placeholder="Search..."
            onKeyUpCapture={(e) => {
              if (e.key == "Enter") {
                setexternalSearchTrigger(!externalSearchTrigger);
              }
            }}
            onChange={(e) => {
              settextToSearch(e.currentTarget.value);
              setSearchText(e.currentTarget.value);
            }}
          />
        )}
      </div>
      <div className="Icons-Wrapper">
        {!isSearchVisible ? (
          <FontAwesomeIcon
            id="searchChat"
            icon={faSearch}
            onClick={() => {
              setIsSearchVisible(true);
            }}
          ></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              setIsSearchVisible(false);
              setSearchText("");
              document.getElementById("searchInput").value = "";
              settextToSearch("")
            }}
          />
        )}
      </div>
    </div>
  );
};
