
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import React, { useRef, useState } from 'react'
import './emojipicker.css';
import AddReactionIcon from './smile-plus.png'
import { useEffect } from 'react';
import { OtsideClickClosingWrapper } from './OtsideClickClosingWrapper';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Dropdown as AntDropDown, Collapse, Select } from "antd";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import Config from '../../config.json'
import addImojiIcon from './happy.png'
import zIndex from '@material-ui/core/styles/zIndex';
export const EmojiReactionPicker = ({ messageId, notesReactions, setTriggerEmojiSelector,setNotesData, chatId, type , externalTriggers}) => {
  const [thisChatReactions, setThisChatReactions] = useState();
  const [yourReaction, setYourReaction] = useState([]);
  const [isOpen, setIsOpen] = useState(false)
  const AddReactionToMessage = async (messageId, reaction) => {

    let payLoad = {
      Type: 'Reaction',
      method: 'POST',
      url: "https://cliq.zoho.com/api/v2/chats/" + chatId + "/messages/" + messageId + "/reactions",
      headers: { "Authorization": localStorage.getItem('token') },
      data: { "emoji_code": reaction }
    }
    await axios.post(Config.backend_url + "chat", { config: payLoad })
      .then(res => {
        let newArr = notesReactions.map(e => {
          if (e.id && e.id == messageId) {
            if (reaction != '👍') {
              e["my_reactions"]?.push({ emoji: reaction, user: localStorage.getItem("ZUID") })
            } else {
              e["my_like"] = { emoji: reaction, user: "" }
            }
          }
          return e
        });
        setNotesData(newArr);
      }).catch(err => {
        console.log(err);
      })
  }
  // useEffect(e=>{
  //   window.
  // },[])
  useEffect(e=>{
         
    if(externalTriggers && messageId==externalTriggers){
      setIsOpen(true)
    }
  },[externalTriggers])
  useEffect(e=>{
    console.log("is open",isOpen)
    if(!isOpen && setTriggerEmojiSelector){
      setTriggerEmojiSelector(null)
    }
  },[isOpen])
  const RemoveReactionToMessage = async (messageId, reaction) => {
     
    let payLoad = {
      Type: 'Reaction',
      method: 'DELETE',
      url: "https://cliq.zoho.com/api/v2/chats/" + chatId + "/messages/" + messageId + "/reactions",
      headers: { "Authorization": localStorage.getItem('token') },
      data: { "emoji_code": reaction }
    }
    axios.post(Config.backend_url + "chat", { config: payLoad })
      .then(res => {
        let newArr = notesReactions.map(e => {
          if (e.id && e.id == messageId) {
            if (reaction != '👍') {
              e["my_reactions"] = e["my_reactions"]?.map(k => k.emoji != e.emoji)
            } else {
              e["my_like"] = null
            }
          }
          return e
        });
        setNotesData(newArr);
      }).catch(err => {
        console.log(err);
      })
  }
  const emojiChanged = (e) => {
    AddReactionToMessage(messageId, e?.native)
    setIsOpen(false);
  }

  useEffect(e => {
    let arr = notesReactions.find(note => note.id == messageId);
    if (arr) {
      setThisChatReactions(arr.others_reactions);
      setYourReaction(arr.my_reactions);
    }
  }, [notesReactions])


  const getReactionsForChat = () => {
         
   
  }
  const getAntDropDown = (type) => {
    if (type == "others") {
      let items = []
      thisChatReactions?.map((d, key) => {
        let user;
        if (d.user) {
          user = d.user.split(":");
          user = user[user.length - 1]
        }
        items.push({
          label: <div className='others-reaction-container'>
            <span>{d.emoji}</span>
            <span>{user}</span>
          </div>,
          key: key
        })
      })

      return (<AntDropDown
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["hover"]}
      >
        <div>
          <span>Others :</span>
          <span style={thisChatReactions && thisChatReactions[thisChatReactions?.length - 2] ? {} : { display: "none" }}>{thisChatReactions && thisChatReactions[thisChatReactions?.length - 2]?.emoji}</span>
          <span style={thisChatReactions && thisChatReactions[thisChatReactions?.length - 1] ? {} : { display: "none" }}>{thisChatReactions && thisChatReactions[thisChatReactions?.length - 1]?.emoji}</span>
          {/* {yourReaction?.map(r => {
              return r.emoji + " "
          })} */}
        </div>
      </AntDropDown>)
    } else if (type == "my") {
      let items = []
      yourReaction?.map((d, key) => {
        let user;
        if (d.user) {
          user = d.user.split(":");
          user = user[user.length - 1]
        }
        items.push({
          label: <div className='user-reaction-container'>
            <span>{d.emoji}</span>
            <span>You</span>
            <span>
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={e => {
                  RemoveReactionToMessage(messageId,yourReaction.splice(key, 1)[0]?.emoji)
                }}
              >
              </FontAwesomeIcon>
            </span>
          </div>,
          key: key
        })
      })

      return (<AntDropDown
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["hover"]}
      >
        <div>
          <span>You :</span>
          <span style={yourReaction && yourReaction[yourReaction?.length - 2] ? {} : { display: "none" }}>{yourReaction && yourReaction[yourReaction?.length - 2]?.emoji}</span>
          <span style={yourReaction && yourReaction[yourReaction?.length - 1] ? {} : { display: "none" }}>{yourReaction && yourReaction[yourReaction?.length - 1]?.emoji}</span>
          {/* {yourReaction?.map(r => {
              return r.emoji + " "
          })} */}
        </div>
      </AntDropDown>)
    }else if(type=="chat"){
      let items = []
      yourReaction?.map((d, key) => {
        let user;
        if (d.user) {
          user = d.user.split(":");
          user = user[user.length - 1]
        }
        items.push({
          label: <div chat={"chat"} className='user-reaction-container'>
            <span>{d.emoji}</span>
            <span>You</span>
            <span>
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={e => {
                  RemoveReactionToMessage(messageId,yourReaction.splice(key, 1)[0]?.emoji)
                }}
              >
              </FontAwesomeIcon>
            </span>
          </div>,
          key: key
        })
      })
      thisChatReactions?.map((d, key) => {
        let user;
        if (d.user) {
          user = d.user.split(":");
          user = user[user.length - 1]
        }
        items.push({
          label: <div className='others-reaction-container'>
            <span>{d.emoji}</span>
            <span>{user}</span>
          </div>,
          key: key
        })
      })

      let arr = notesReactions.find(note => note.id == messageId);
      if (arr) {
        let allReactions=[]
        if(arr.my_reactions?.length>0){
          allReactions=[...allReactions,...arr.my_reactions]
        }
        if(arr.others_reactions?.length>0){
          allReactions=[...allReactions,...arr.others_reactions]
        }
      return (<AntDropDown
        overlayStyle={{zIndex:10000}}
        overlayClassName='chat-overlay'
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["hover"]}
      >
         <div className='inner-div'>
          <span style={allReactions && allReactions[allReactions?.length - 2] ? {} : { display: "none" }}>{allReactions && allReactions[allReactions?.length - 2]?.emoji}</span>
          <span style={allReactions && allReactions[allReactions?.length - 1] ? {} : { display: "none" }}>{allReactions && allReactions[allReactions?.length - 1]?.emoji}</span>
          {/* {yourReaction?.map(r => {
              return r.emoji + " "
          })} */}
          {/* <span className='add-reaction-icon'><img title='Add Reaction' src={addImojiIcon} /></span> */}
          <span style={allReactions && allReactions?.length>0 ? { marginLeft:"5px" } : { display: "none" }}>{allReactions?.length}</span>
        </div>
      </AntDropDown>)
    }
    }
  }


  if (type == "CHAT") {
    return (<div className='Emoji-wrapper-chat'>
      <div className='All-Reactions Selected-Reactions' style={thisChatReactions?.length > 0 || yourReaction?.length > 0? {} : { display:"none" }}>
         {getAntDropDown("chat")}
      </div>
      {isOpen ?
        <OtsideClickClosingWrapper className={type == "CHAT" ? 'Emoji-Picker-Wrapper Emoji-Picker-Wrapper-Chats' : 'Emoji-Picker-Wrapper'} close={(e) => {
          if (isOpen) {
            setIsOpen(false)
          }
        }}>
          <Picker data={data} onEmojiSelect={emojiChanged}
          />
        </OtsideClickClosingWrapper> :
        <></>
      }
    </div>)
  }else{
    return (<div className='Emoji-Wrapper'>
    <div className='Selected-Reaction-Wrapper'>
      <div className='Selected-Reaction-Header'>
        <div className='All-Reactions Selected-Reactions' style={thisChatReactions?.length > 0 ? {} : { display: "none" }}>
          {getAntDropDown("others")}
        </div>
        <div className='Selected-Reactions' style={yourReaction?.length > 0 ? {} : { display: "none" }}>
          {getAntDropDown("my")}
        </div>
        <div className='Add-Emoji-Icon'>
          <img onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen)
          }} src={AddReactionIcon} />
        </div>
      </div>

      {isOpen ?
        <OtsideClickClosingWrapper className={type == "CHAT" ? 'Emoji-Picker-Wrapper Emoji-Picker-Wrapper-Chats' : 'Emoji-Picker-Wrapper'} close={(e) => {
          if (isOpen) {
            setIsOpen(false)
          }
        }}>
          <Picker data={data} onEmojiSelect={emojiChanged}
          />
        </OtsideClickClosingWrapper> :
        <></>
      }
    </div>

  </div>
  )
  }

}
