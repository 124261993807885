import React, { useEffect, useState } from "react";
import "./Pipelines.css";
import CreatePipeline from "./CreatePipeline";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPen,
  faSearch,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FormGroup, Col, Input, Row } from "reactstrap";
import AwsServerService from "../../../common/util/AwsServerService";
import {  Collapse , Button } from "antd";

const { Panel } = Collapse;

export default function Pipelines({open,setOpen,viewPipelineData,setViewPipelineData}) {
  const [rowData, setRowData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("create");
  const [dataToEdit, setDataToEdit] = useState({});
  const [cacheData, setCacheData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    setRowData(cacheData);
    console.log("cache", cacheData);
  }, [cacheData]);
  useEffect(() => {
    if (searchValue != "") {
      let data = cacheData.filter((e) =>
        e.PipelineName.toLowerCase().includes(searchValue.toLowerCase())
      );
      setRowData(data);
    } else {
      setRowData(cacheData);
    }
  }, [searchValue]);
  useEffect(() => {
    AwsServerService.getAllPipeline().then((res) => {
      setCacheData(res?.data[0]);
    });
  }, []);
  const handleDelete = (rowId) => {
    swal({
      title: "Are you sure you want to delete this pipeline?",
      text: "",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        AwsServerService.DeletePipeline(rowId).then((e) => {
          let data = cacheData.filter((e) => e.ROWID != rowId);
          setRowData(data);
          swal("Deleted", "Pipeline deleted Successfully!", "success");
        }).catch((e)=>{
          swal("Alert!", "Something went wrong", "warning");
        })
      }
    });
  };

  const handleViewPipeline = (id) =>{
    AwsServerService.getAllPipelineTemplateData(id).then((res) => {
      console.log("res getAllPipelineTemplateData: ", res);
      setViewPipelineData(res)
      setOpen(true)
    }).catch((e)=>{
      swal("Alert!", "Something went wrong", "warning");
    })
  }
  

  return (
    <div className="PiplinesList-Wrapper">
      <Collapse defaultActiveKey={["1"]} expandIconPosition="left">
        {rowData &&
          rowData?.length > 0 &&
          rowData?.map((key, index) => {
            return (
              <Panel
                header={
                  <Row sm={12} className="pipeline-card">
                    <Col sm={6}>
                      <span className="Group1-Card">{key.PipelineName}</span>
                    </Col>
                    <Col sm={6} style={{display : "flex", justifyContent : "flex-end"}}>
                      <Button onClick={()=>handleViewPipeline(key?.ROWID)} className="view-pipeline-btn" style={{height: "20px"}} type="text"><FontAwesomeIcon icon={faEye} style={{opacity : "0.75"}} title="View Pipeline"/>View</Button>
                    </Col>
                  </Row>
                }
                key={key?.ROWID}
              >
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
}
