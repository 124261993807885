import { Button, Divider, Popover, Segmented } from "antd";
import { useEffect, useMemo, useState } from "react";
import "./StatusDropdown.css";

const StatusDropdown = ({
  selectedValue,
  onSelect,
  items,
  toggelButton,
  isScroll,
  module
}) => {
  const [showArrow, setShowArrow] = useState(true);
  const [arrowAtCenter, setArrowAtCenter] = useState(false);
  const [selected, setSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  function invertColor(hex, bw) {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  }
  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  }

  useEffect(
    (e) => {
      setIsOpen(false);
    },
    [isScroll, selected]
  );

  // const mergedArrow = useMemo(() => {
  //   if (arrowAtCenter)
  //     return {
  //       pointAtCenter: true,
  //     };
  //   return showArrow;
  // }, [showArrow, arrowAtCenter]);

  // const text = <span>Title</span>;
  // const buttonWidth = 70;

  const content = (
    <div className={module && module == "CreateTask" ? "custum-dropdown-status-popover-createtask" : "custum-dropdown-status-popover"}>
      {items?.map((one) => {
        return (
          <p
            onClick={() => {
              onSelect(one);
              setSelected(!selected);
            }}
            style={{ backgroundColor: one.color, color: one.color ? invertColor(one.color, true) : "#fff", fontWeight:"600" }}
          >
            {one.name}
          </p>
        );
      })}
    </div>
  );
  return (
    <div>
      <Popover
        style={{ zIndex: 9999 }}
        open={isOpen}
        onOpenChange={(e) => {
          setIsOpen(!isOpen);
        }}
        trigger="click"
        overlayClassName={module && module == "CreateTask" || module == "Deal" ? "Prject-Status-Container" : "status-container"}
        placement="bottomLeft"
        content={content}
        arrow={false}
      >
        {toggelButton}
      </Popover>
    </div>
  );
};

export default StatusDropdown;
