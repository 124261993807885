import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from "reactstrap";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";

const Projectcost = (props) => {
  const [projcoststate, setProjCostState] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [skipPvSystem, setSkipPvSystem] = useState(false);
  const [validation,setValidation] = useState('0');
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  useEffect(() => {
    props.toggleState == "projectcost"
      ? setProjCostState(true)
      : setProjCostState(false);
  },[props.toggleState]);

  const buttontoggle = () => {
    setProjCostState(!projcoststate);
    props.settoggleState("projectcost");
    props.setListState("projectcost");
    props.setActiveTab("projectcost")
  };

  useEffect(()=>{
    getData()
  },[props.data])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });  

  const getData = () => {
    if (props.data != null) {
      props.setprojectCost(props.data.projectCost)
    }
  }
  const calculateRightCost = (value) => {
      var cost = props.data.projectCost;
      var minLimit = props.totalSysSize * 1000 * 3;
      var maxLimit = (props.totalSysSize * 1000 * 8) + 4200;
      if (cost < minLimit) {
        if(cost==0){
          props.setlowerCostInvalid(false)
        }
        else{
        props.setlowerCostInvalid(true)
        }
        seterrorMessage("Project Cost is below to lower limit (" + formatter.format(minLimit) + ").")
        props.setprojectCost("")
        props.handleOnChangeDeal({ currentTarget: { name: 'projectCost', value: props.data.projectCost } });

      } else if (cost > maxLimit) {
        props.setupperCostInvalid(true)
        seterrorMessage(props.totalSysSize > 0 ? "Project Cost is above to upper limit (" + formatter.format(maxLimit) + ")." : '')
        props.setprojectCost("")
        props.handleOnChangeDeal({ currentTarget: { name: 'projectCost', value: props.data.projectCost } });
      } else {
          props.setprojectCost(props.data.projectCost)
          props.handleOnChangeDeal({ currentTarget: { name: 'projectCost', value: props.data.projectCost } })
          setValidation('1')
          props.setlowerCostInvalid(false)
          props.setupperCostInvalid(false)
      }
  }

  useEffect(()=>{
    calculateRightCost()
  },[props.data.projectCost, props.totalSysSize])

  const handlePVSystemCheck = () => {
    if (skipPvSystem) {
      props.setprojectcostcomplete.projectcost = true;
      return true;
    }
    if (props.data.projectCost && props.data.projectCost > 0 //props.projectCost !== "" props.projectCost !== ""// 
    ) {
      props.setprojectcostcomplete.projectcost = true;
        return true
    }
    props.setprojectcostcomplete.projectcost = false;
    return false;
  };

  return (
    <Col sm={12}>
      <Button
        onClick={buttontoggle}
        className={projcoststate ? "btn-100-active" : "btn-100"}
      >
        Project Cost
        <div className="float-right">
          <Input
            type="checkbox"
            className={
              (handlePVSystemCheck() )
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={(handlePVSystemCheck())}
            onChange={(e) =>{ 
              if(props.data.projectCost==''|| props.data.projectCost==undefined){
                setValidation('-1')
              }else{
                setValidation('1')
              }
              if(validation=='0'){
                setValidation('-1')
              }
              setSkipPvSystem(!skipPvSystem)
              props.setModuleCheckStatus({...props.moduleCheckStatus,Projectcost:e.target.checked})
            }}
          />
          {projcoststate ? (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleDown}
            />
          ) : (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleRight}
            />
          )}
        </div>
      </Button>
      <Collapse isOpen={props.activeTab == "projectcost" && projcoststate ? true : false}>
        <Card className="createdeal-card">
          <CardBody>
          <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="projectCost">
                    Project Cost<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={2}>
                  <CurrencyInput
                    className="currency_input h-100"
                    prefix="$"
                    intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                    allowDecimals={false}
                    decimalsLimit="2"
                    name='projectCost'
                    style={validation=='-1'?{...validationCss}:{}}
                    onValueChange={(value) => {
                      value = value < 0 ? "" : value;
                      props.handleOnChangeDeal({ currentTarget: { name: 'projectCost', value: value } });
                      props.setprojectCost(value)
                        if(props.lowerCostInvalid == false && props.upperCostInvalid == false){
                        props.setModuleCheckStatus({...props.moduleCheckStatus,Projectcost:value != undefined && value != 0 ? true : false})
                        setValidation('1')
                      }else{
                        setValidation('-1')
                      }
                    }}
                    value={props.data ? props.data.projectCost : ''}
                  />
                </Col>
                <Col sm={7}>
                  <span
                    className={
                      (props.lowerCostInvalid == true || props.upperCostInvalid == true)
                        ? "red-text font12"
                        : "error-display"
                    }
                  >
                    {" "}
                    {errorMessage}
                  </span>
                </Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default Projectcost;