import axios from "axios";
import httpService from "../../services/http.service";
import Config from '../../config.json'

class ChatService{
    SendMessageToUser(chatId,message,replyObj) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://cliq.zoho.com/api/v2/chats/"+chatId+"/message",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {
                    text:message,sync_message:true
                }
            }
            if(replyObj){
                config.data['reply_to']=replyObj.id;
            }
            httpService.post('chat', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    SendAttachmentToUser(chatId,formData) {
         
        return new Promise((resolve, reject) => {
            axios.post(Config.backend_url+`addAttachment?chatId=${chatId}`,formData,
            {headers: { 
                'content-type': 'multipart/form-data',
                "token": localStorage.getItem('token') 
            }})
            .then((result) => {
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

    ReplyToUser(chatId , msgId, message) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://cliq.zoho.com/api/v2/chats/"+chatId+"/message",
                headers: { "Authorization": localStorage.getItem('token') },
                
                data: {reply_to: msgId, text : message,sync_message:true}
            }
            httpService.post('chat', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }    

    DeleteUserChat(chatId , msgId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Remove',
                method: 'DELETE',
                url: "https://cliq.zoho.com/api/v2/chats/"+chatId+"/messages/"+msgId,
                headers: { "Authorization": localStorage.getItem('token') },
            }
            httpService.post('chat', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }  

    UpdateUserChat(chatId , msgId,message) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'PUT',
                url: "https://cliq.zoho.com/api/v2/chats/"+chatId+"/messages/"+msgId,
                headers: { "Authorization": localStorage.getItem('token') },
                data: { text : message,"notify_edit":true}
            }
            httpService.post('chat', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    } 

    GetChatMessage(chatId) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://cliq.zoho.com/api/v2/chats/"+chatId+"/messages",
                headers: { "Authorization": localStorage.getItem('token') },
                chatId:chatId,
                data: {}
            }
            httpService.post('chat', {config:{...config}})
                .then((result) => {
                    if(result?.data?.data){
                        result.data.data=result?.data?.data.filter(e=>e.type!="deleted");
                    }
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }
    GetNoteREactionById(chatId,messageId){
        return new Promise(async (resolve, reject) => {
            let config = {
                Type: 'Reaction',
                method: 'GET',
                url: "https://cliq.zoho.com/api/v2/chats/"+chatId+"/messages/"+messageId+"/reactions",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            await httpService.post('chat', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetAllChats(){
        return new Promise((resolve, reject)=>{
            let config={
                Type:"Get",
                method:"GET",
                url:"https://cliq.zoho.com/api/v2/chats",
                headers:{"Authorization":localStorage.getItem('token')}
            }
            httpService.post('workdrive',{config:{...config}})
            .then((result)=>{
                resolve(result.data.chats);
            })
            .catch((err)=>{
                reject(err);
                console.log(err);
            }) 
    
        })
    }


    GetAllChannels(){
        return new Promise((resolve, reject)=>{
            let config={
                Type:"Get",
                method:"GET",
                url:"https://cliq.zoho.com/api/v2/channels",
                headers:{"Authorization":localStorage.getItem('token')}
            }
            httpService.post('chat',{config:{...config}})
            .then(async (result)=>{
                let channels=result.data.channels;
                while(result.data.has_more){
                    result=await this.GetAllChannelsNext(result.data.next_token);
                    channels=[...channels,...result.data.channels];
                }
                resolve(channels);
            })
            .catch((err)=>{
                reject(err);
                console.log(err);
            }) 
    
        })
    }

    GetAllChannelsNext(token){
        return new Promise((resolve, reject)=>{
            let config={
                Type:"Get",
                method:"GET",
                url:"https://cliq.zoho.com/api/v2/channels?next_token="+token,
                headers:{"Authorization":localStorage.getItem('token')}
            }
            httpService.post('chat',{config:{...config}})
            .then((result)=>{
                resolve(result);
            })
            .catch((err)=>{
                reject(err);
                console.log(err);
            }) 
    
        })
    }

    DeleteChats(CHAT_ID, msgId , isArray){
        return new Promise((resolve, reject)=>{
            let config={
                Type:"Remove",
                method:"DELETE",
                url:`https://cliq.zoho.com/api/v2/chats/${CHAT_ID}/messages${isArray?"":"/"+msgId}`,
                headers:{"Authorization":localStorage.getItem('token')}
            }
            if(isArray){
                config["data"]={
                    message_ids:[...isArray]
                }
            }
            httpService.post('chat',{config:{...config}})
            .then((result)=>{
                resolve(result);
            })
            .catch((err)=>{
                reject(err);
                console.log(err);
            }) 
    
        })
    }

    UpdateMessages(CHAT_ID, msgId, msgVal){
        return new Promise((resolve, reject)=>{
            let config={
                Type:"Update",
                method:"PUT",
                url:"https://cliq.zoho.com/api/v2/chats/"+CHAT_ID+"/messages/"+msgId+"",
                headers:{"Authorization":localStorage.getItem('token')},
                data : {text:msgVal, notify_edit:true}
            }
            httpService.post('chat',{config:{...config}})
            .then((result)=>{
                resolve(result);
            })
            .catch((err)=>{
                reject(err);
                console.log(err);
            }) 
    
        })

    }

    PinAMessageInZoho(chatId,massageId,replacePinned,expTime){
        let config={
            Type:"Create",
            method:"POST",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/stickymessage`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {
                "id": massageId ,
                "expiry_time": -1,
                "notify": false
            }
        }
        return new Promise(async (resolve, reject)=>{
            if(replacePinned){
                await this.UnPinAMassageInZoho(chatId).then(e=>{
                    console.log("removed..")
                }).catch(e=>{
                    console.log(e)
                })
            }
            httpService.post('chat',{config:{...config}})
            .then(e=>{
                resolve(e)
            }).catch(e=>{
                reject(e)
            })
        })
       
    }
    PinMessagesInZoho(chatId,massageId,replacePinned,expTime){
        let config={
            Type:"Create",
            method:"POST",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/pinmessages`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {
                "message_id": massageId ,
                "expiry_time": -1,
                "notify": false
            }
        }
        return new Promise(async (resolve, reject)=>{
            // if(replacePinned){
            //     await this.UnPinAMassageInZoho(chatId).then(e=>{
            //         console.log("removed..")
            //     }).catch(e=>{
            //         console.log(e)
            //     })
            // }
            httpService.post('chat',{config:{...config}})
            .then(e=>{
                resolve(e)
            }).catch(e=>{
                reject(e)
            })
        })
       
    }
    UnPinAMassageInZoho(chatId){
        let config={
            Type:"Remove",
            method:"DELETE",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/stickymessage`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('chat',{config:{...config}})
            .then(e=>{
                resolve(e)
            }).catch(e=>{
                reject(e)
            })
        })
    } 
    UnPinMassagesInZoho(chatId,messageIds){
        let config={
            Type:"Remove",
            method:"DELETE",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/pinmessages`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {
                message_ids:messageIds,
            }
        }
        return new Promise((resolve, reject)=>{
            httpService.post('chat',{config:{...config}})
            .then(e=>{
                resolve(e)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    GetPinnedChatsFromZoho(chatId,appUser){
        let config={
            Type:"Get",
            method:"GET",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/stickymessage`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('chat',{config:{...config}})
            .then(e=>{
                if(e?.data?.data){
                    let obj={
                        content:{
                            text:e?.data?.data?.message?.msg
                        },
                        time:parseInt(e?.data?.data?.message?.time),
                        id:e?.data?.data?.message?.id,
                        chat_id:e?.data?.data?.chat_id,
                        name:e?.data?.data?.message?.dname,
                        creator_name:appUser?.name==e?.data?.data?.creator?.name?"You":e?.data?.data?.creator?.name
                    };
                    e.data.data=obj;
                }
                resolve(e)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    GetAllPinnedChatsFromZoho(chatId,appUser){
        let config={
            Type:"Get",
            method:"GET",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/pinmessages`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('chat',{config:{...config}})
            .then(o=>{
                let arr=[]
                if(o?.data?.data){
                    o?.data?.data.forEach(e=>{
                        let obj={
                            primary: e.primary?true:false,
                            content:{
                                text:e.message?.msg
                            },
                            time:parseInt(e.message?.time),
                            id:e.message?.id,
                            chat_id:e.chat_id,
                            dname:e.message?.dname,
                            name:appUser?.name==e.creator?.name?"You":e.creator?.name,
                            creator_name:appUser?.name==e.creator?.name?"You":e.creator?.name
                        };
                        arr.push(obj);
                    })
                    o.data.data=arr;
                }
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    GetAttachmentById(attachmentId,type){
        let config={
            Type:"Get",
            method:"GET",
            docType:type,
            url:`https://cliq.zoho.com/api/v2/files/${attachmentId}`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('attachments',{config:{...config}})
            .then(o=>{
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    AddMemberInGroup(user,groupId){
         
        let config={
            Type:"Member",
            method:"Post",
            url:`https://cliq.zoho.com/company/730401191/addmember.do`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {chid:groupId,ulist:user.zuid}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('groupactions',{config:{...config}})
            .then(o=>{
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    RemoveMemberInGroup(user,groupId){
         
        let config={
            Type:"Member",
            method:"Post",
            url:`https://cliq.zoho.com/company/730401191/deletemember.do`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {chid:groupId,ulist:user.zuid}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('groupactions',{config:{...config}})
            .then(o=>{
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    GetMembersInChat(groupId){
        let config={
            Type:"Get Participents",
            method:"Get",
            url:`https://cliq.zoho.com/company/730401191/v1/chats/${groupId}/participants`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('groupactions',{config:{...config}})
            .then(o=>{
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    CloseChatConversation(chatId){
        let config={
            Type:"Close Chat",
            method:"Post",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/close`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {id:[chatId]}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('groupactions',{config:{...config}})
            .then(o=>{
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    LeaveChatGroup(chatId){
        let config={
            Type:"Leave Chat",
            method:"Post",
            url:`https://cliq.zoho.com/api/v2/chats/${chatId}/leave`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {}
        }
        return new Promise((resolve, reject)=>{
            httpService.post('groupactions',{config:{...config}})
            .then(o=>{
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
    GetUserActiveStatus(userId){
        let config={
            Type:"Status",
            method:"Get",
            url:`https://cliq.zoho.com/api/v2/users/${userId}?fields=chat_status`,
            headers:{"Authorization":localStorage.getItem('token')},
            data : {}
        }
        return new Promise((resolve, reject)=>{
             httpService.post('groupactions',{config:{...config}})
            .then(o=>{
                resolve(o)
            }).catch(e=>{
                reject(e)
            })
        })
    }
}

export default new ChatService();