import React, { useState, useEffect } from "react";
import loader from '../assets/images/loader.gif';
import './loader.css';

const Loader = () => {

    return (
        <div className="loader-contentV1">
            <div className="loader">
                {/* <div className="loading"></div> */}
                {/* <h5>Loading...</h5> */}
                <img src={loader}/>
            </div>
        </div>

    );
    // return (
    //     <div class="spinner-border text-dark" role="status">
    //         <span class="visually-hidden">Loading...</span>
    //     </div>
    // )
}


export default Loader;