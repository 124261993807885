import React, { useContext, useEffect, useState } from 'react'
import { Button, Row } from 'react-bootstrap';
import './ReminderPopUp.css'
import { Modal as AntModal } from 'antd';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faCalendarAlt, faDotCircle, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Popover as AntdPopover } from "antd";
import { AllPaths, convertToUSTime, modifyTimezoneString } from '../HR/TimeClock/Util';
import AwsServerService from '../../common/util/AwsServerService';
import swal from 'sweetalert';
import ReminderSvg from '../../../src/assets/images/svg/AlarmReminderBlack.svg';
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper';
import { faCalendar, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { CustomeTabs } from '../NewTabs/CustomeTabs';
import AppContext from '../../Context/AppContext';
import { forwardObject } from '../../Context/AppActions';
import moment from 'moment';
export const ReminderListPopUp = ({ history,open, onClose, allReminderData, userReminderObj ,setTriggeredReminders,triggeredReminders}) => {
    // const history = useHistory();
    const AppData = useContext(AppContext);
    const { dispatch, state } = AppData || {};
    const [collapse, setcollapse] = useState({
        Active: true,
        Inactive: false,
    })
    const [openSnooze,setOpenSnooze]=useState({id:null,open:false});
    const [openNewEventPopUp,setOpenNewEventPopUp]=useState({data:null,open:false});
    const [ActiveTab,setActiveTab]=useState({
        label:"Active",
        id:1,
        module:"All",
        icon: <FontAwesomeIcon icon={faDotCircle}/>,
        count:()=>{
            return {count:triggeredReminders?.length,color:"#ff64642e"};
        }
    });
    const Tabs=[
        {
            label:"Active",
            id:1,
            module:"All",
            icon: <FontAwesomeIcon icon={faDotCircle}/>,
            count:()=>{
                return {count:triggeredReminders?.filter(d=>(d.status == "false" && d.triggered == "true" && !moment(d.reminderTime).isBefore(moment().startOf("day"))))?.sort((a,b)=>moment(b.reminderTime)?._d-moment(a.reminderTime)?._d)?.length,color:"#ff64642e"};
            }
        },
        // {
        //     label:"Email",
        //     id:2,
        //     module:"Email",
        //     icon: <FontAwesomeIcon icon={faEnvelope}/>,
        //     count:()=>{
        //         return {count:triggeredReminders?.filter(d=>d.module=="Email")?.length};
        //     }
        // },
        // {
        //     label:"Calendar",
        //     id:3,
        //     module:"Calendar",
        //     icon: <FontAwesomeIcon icon={faCalendar}/>,
        //     count:()=>{
        //         return {count:triggeredReminders?.filter(d=>d.module=="Calendar")?.length};
        //     }
        // },
    ]
    const [snoozeTime,setSnoozeTime]=useState(null);
    useEffect(e=>{
        setOpenSnooze({id:null,open:false})
    },open)
    const handleSnooze=(data,time,custome)=>{
         
        let payLoad={
            ROWID:data.ROWID,
            triggered:"false",
        };
        if(!custome){
            payLoad.reminderTime=convertToUSTime(dayjs(modifyTimezoneString(data.reminderTime,"MM/DD/YYYY HH:mm")).add(time,"minutes"));
        }else{
            if(!time){
                time=dayjs().add(10, "minutes").format("HH:mm");
            }
            let tempDateStr=modifyTimezoneString(data.reminderTime,"MM/DD/YYYY")+" "+time;
            payLoad.reminderTime=convertToUSTime(tempDateStr);
        }
        AwsServerService.UpdateReminders(payLoad).then(res=>{
             
            console.log(res);
            setSnoozeTime(null);
        }).catch(e=>{
            console.log(e);
        })
        setOpenSnooze({id:null,open:false});
    }
    const handleCompleted=(data)=>{
         
        let payLoad={
            ROWID:data.ROWID,
            triggered:"false",
        };
        payLoad.status="true";
        payLoad.triggered="false";
        AwsServerService.UpdateReminders(payLoad).then(res=>{
             
            console.log(res);
        }).catch(e=>{
            console.log(e);
        })
    }
    const handleDelete=(data)=>{
        swal({
            title: "Are you sure?",
            text: "You want to delete the reminder!",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
                AwsServerService.DeleteReminders([data.ROWID],"ROWID").then(res=>{
                     
                    console.log(res);
                }).catch(e=>{
                    console.log(e);
                })
            }
          });
    }
    const content=(data)=>{
        return ( <div className="Snooze-Content">
            <Row sm={12}>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                    handleSnooze(data,10)
                }} >10 min</Col>
                <Col className='Preset-Cards' sm={3}onClick={e=>{
                    handleSnooze(data,20)
                }}>20 min</Col>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                    handleSnooze(data,30)
                }}>30 min</Col>
            </Row>
            <div className='Custome-Snooze-Time'><input
                type="time"
                className="form-control form-control-sm"
                format="HH:mm A"
                // style={validation.out=='-1'?{...validationCss}:{}}
                style={{ height: '2.2rem' }}
                onChange={(e) => {
                    setSnoozeTime(e.currentTarget.value)
                }}
                value={snoozeTime ? snoozeTime : dayjs().add(10, "minutes").format("HH:mm")}
            />
                <Button style={{"line-height":"0.3rem"}} className='btn btn-primary' onClick={e=>{
                    handleSnooze(data,snoozeTime,true)
                }}>Set</Button>
            </div>
        </div>)
    }
    const getHeaderStatement = (data) => {
        if (data.reminderType == "At specific time") {
            return { text: `You have set reminder on an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 1 }
        } else if (data.reminderType == "On every reply") {
            return { text: `You have set reminder on every reply for an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""}`, status: 2 }
        } else if (data.reminderType == "If no replies until") {
            return { text: `You have set reminder on an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} for reply till - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 3 }
        } else {
            return { text: `You have set reminder on an ${data.module}: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 1 }
        }
    }

    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    function getEmailTab(tab){
        return <Col className='col' sm={12} style={{
            "max-height": "25rem",
            "overflow": "scroll",
            "min-height": "10rem",
        }}>
        {triggeredReminders && triggeredReminders.length > 0 &&
                triggeredReminders?.sort((a,b)=>moment(b.reminderTime)?._d-moment(a.reminderTime)?._d)?.map((r, k) => {
                    if (r.module == tab) {
                        return (
                            <Row className='Triggered-Alerts-Wrapper'>
                                <Col className='Icon-Wrapper' sm={1}>
                                    <img src={ReminderSvg} alt="" />
                                </Col>
                                <Col sm={11}>
                                    <Row className='Card'>
                                        <Row style={{
                                            "padding": "0px 8px",
                                            "font-weight": "500",
                                        }}
                                        onClick={e=>{
                                             
                                             onClose();
                                            dispatch(forwardObject({module:r.module,data:r,state:"view"}))
                                            history.push(AllPaths[r.module]);
                                        }}
                                        >{getHeaderStatement(r)?.text}
                                        </Row>
                                        <Row>
                                            {/* <Col sm={1}>{i + 1}</Col> */}
                                            <Col sm={8}>{r?.reminderNote && r?.reminderNote != null ? r?.reminderNote : ""}</Col>
                                            <Col sm={4} className='Option-Wrapper'>
                                                <OtsideClickClosingWrapper className='Options'
                                                    close={e => {
                                                        setOpenSnooze({ id: null, open: false })
                                                    }}
                                                >
                                                    {getHeaderStatement(r)?.status != 2 &&
                                                        <AntdPopover
                                                            open={openSnooze.open && r?.ROWID + "TT" == openSnooze.id}
                                                            overlayClassName={`Snooze-Popover Do-Not-Close ROWID-${r?.ROWID}`}
                                                            style={{ zIndex: 9999, "width": "max-content" }}
                                                            trigger="click"
                                                            placement="bottomLeft"
                                                            content={() => content(r)}
                                                            onOpenChange={e => {

                                                                setOpenSnooze({ id: r?.ROWID + "TT", open: true });
                                                            }}
                                                            arrow={true}
                                                        >
                                                            <span>Snooze</span>
                                                        </AntdPopover>
                                                    }
                                                    <span onClick={e => {
                                                        handleCompleted(r)
                                                    }}>Complete</span>
                                                    <span onClick={e => {
                                                        handleDelete(r)
                                                    }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                                </OtsideClickClosingWrapper>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                })
        }
        
        <Row className='Active-Section' sm={12}>
            <Row className='Header'>
                <span>{`Upcoming (${allReminderData?.filter(r => r.module == tab && r.userEmail == userInfo.email_id && r.status != "true" && r.triggered!="true")?.length})`}</span>
                <span className='Expand-Icon' onClick={e => {
                    setcollapse({ ...collapse, Active: !collapse.Active })
                }}>
                    {!collapse.Active ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
                </span>
            </Row>
            {collapse.Active && <div>
                {allReminderData?.filter(r => r.module == tab && r.attendees?.includes(userInfo?.email_id) && r.status != "true" && r.triggered!="true")?.sort((a,b)=>moment(b.reminderTime)?._d-moment(a.reminderTime)?._d)?.map((r, i) => {
                    return <>
                        <Row className='Card'>
                            <Row style={{
                                "padding": "0px 8px",
                                "font-weight": "500",
                            }}
                            onClick={e=>{
                                 
                                 onClose();
                                dispatch(forwardObject({module:r.module,data:r,state:"view"}))
                                history.push(AllPaths[r.module]);
                            }}
                            >{getHeaderStatement(r)?.text}
                            </Row>
                            <Row>
                                {/* <Col sm={1}>{i + 1}</Col> */}
                                <Col sm={8}>{r.reminderNote && r.reminderNote!=null? r.reminderNote : ""}</Col>
                                <Col sm={4} className='Option-Wrapper'>
                                    <OtsideClickClosingWrapper className={"Options"}
                                        close={e=>{
                                            setOpenSnooze({id:null,open:false})
                                        }}
                                    >                                             
                                        {getHeaderStatement(r)?.status != 2 &&
                                            <AntdPopover
                                                open={openSnooze.open && r.ROWID+"AA"==openSnooze.id}
                                                overlayClassName={`Snooze-Popover Do-Not-Close ROWID ROWID-${r.ROWID}`}
                                                style={{ zIndex: 9999 ,"width":"max-content"}}
                                                trigger="click"
                                                placement="bottomLeft"
                                                content={()=>content(r)}
                                                onOpenChange={e=>{
                                                     
                                                    setOpenSnooze({id:r.ROWID+"AA",open:true});
                                                }}
                                                arrow={true}
                                            >
                                                <span>Snooze</span>
                                            </AntdPopover>
                                        }
                                        <span onClick={e=>{
                                            handleCompleted(r)
                                        }}>Complete</span>
                                        <span onClick={e=>{
                                            handleDelete(r)
                                        }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                   
                                    </OtsideClickClosingWrapper>
                                    
                                </Col>
                            </Row>
                        </Row>
                    </>
                })}
            </div>}
        </Row>
        <Row className='Inactive-Section' sm={12}>
            <Row className='Header'>
                <span>{`Completed (${allReminderData?.filter(r => r.module == tab && r.userEmail == userInfo.email_id && r.status == "true" && r.triggered!="true")?.length})`}</span>
                <span className='Expand-Icon' onClick={e => {
                    setcollapse({ ...collapse, Inactive: !collapse.Inactive })
                }}>
                    {!collapse.Inactive ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />}
                </span>
            </Row>
            {collapse.Inactive && <div>
                {allReminderData?.filter(r => r.module == tab && r.attendees?.includes(userInfo?.email_id) && r.status == "true" && r.triggered!="true")?.sort((a,b)=>moment(b.reminderTime)?._d-moment(a.reminderTime)?._d)?.map((r, i) => {
                    return <>
                        <Row className='Card'>
                        <Row style={{
                                "padding": "0px 8px",
                                "font-weight": "500",
                            }}
                            onClick={e=>{
                                 
                                 onClose();
                                dispatch(forwardObject({module:r.module,data:r,state:"view"}))
                                history.push(AllPaths[r.module]);
                            }}
                            >{getHeaderStatement(r)?.text}
                            </Row>
                            <Row>
                                {/* <Col sm={1}>{i + 1}</Col> */}
                                <Col sm={8}>{r.reminderNote && r.reminderNote!=null? r.reminderNote : ""}</Col>
                                <Col sm={4} className='Option-Wrapper'>
                                    <OtsideClickClosingWrapper className={"Options"}
                                        close={e=>{
                                            setOpenSnooze({id:null,open:false})
                                        }}
                                    >                                             
                                        {/* {getHeaderStatement(r)?.status == 1 &&
                                            <AntdPopover
                                                open={openSnooze.open && r.ROWID+"AA"==openSnooze.id}
                                                overlayClassName={`Snooze-Popover Do-Not-Close ROWID ROWID-${r.ROWID}`}
                                                style={{ zIndex: 9999 ,"width":"max-content"}}
                                                trigger="click"
                                                placement="bottomLeft"
                                                content={()=>content(r)}
                                                onOpenChange={e=>{
                                                     
                                                    setOpenSnooze({id:r.ROWID+"AA",open:true});
                                                }}
                                                arrow={true}
                                            >
                                                <span>Snooze</span>
                                            </AntdPopover>
                                        } */}
                                        {/* <span onClick={e=>{
                                            handleCompleted(r)
                                        }}>Complete</span> */}
                                        <span onClick={e=>{
                                            handleDelete(r)
                                        }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                   
                                    </OtsideClickClosingWrapper>
                                    
                                </Col>
                            </Row>
                        </Row>
                    </>
                })}
            </div>}
        </Row>
    </Col>
    }
    const iconStyle={
        "font-size": "1rem",
        "color": "#007bff",
    }
    const getIconByModule = (module) => {
        if (module == "Calendar") {
            return <FontAwesomeIcon style={{...iconStyle}} icon={faCalendarAlt} />
        } else if (module == "Email") {
            return <FontAwesomeIcon style={{...iconStyle}} icon={faEnvelope} />
        }
    }
    function getActiveReminders(){
        return <Col className='col' sm={12} style={{
            "max-height": "25rem",
            "overflow": "scroll",
            "min-height": "10rem",
        }}>
            {triggeredReminders && triggeredReminders.length > 0 &&
            triggeredReminders?.filter(d=>(d.status == "false" && d.triggered == "true" && !moment(d.reminderTime).isBefore(moment().startOf("day"))))?.sort((a,b)=>moment(b.reminderTime)?._d-moment(a.reminderTime)?._d)?.map((r,k) => (
                <Row className='Triggered-Alerts-Wrapper'>
                    <Col className='Icon-Wrapper' sm={1}>
                        { getIconByModule(r.module)}
                    </Col>
                    <Col sm={11}>
                        <Row className='Card' onClick={e=>{
                            
                        }}>
                            <Row style={{
                                "padding": "0px 8px",
                                "font-weight": "500",
                            }}
                            onClick={e=>{
                                 
                                 onClose();
                                dispatch(forwardObject({module:r.module,data:r,state:"view"}))
                                history.push(AllPaths[r.module]);
                            }}
                            >{getHeaderStatement(r)?.text}
                            </Row>
                            <Row>
                                {/* <Col sm={1}>{i + 1}</Col> */}
                                <Col sm={8}>{r?.reminderNote && r?.reminderNote != null ? r?.reminderNote : ""}</Col>
                                <Col sm={4} className='Option-Wrapper'>
                                    <OtsideClickClosingWrapper className='Options' 
                                        close={e=>{
                                            setOpenSnooze({id:null,open:false})
                                        }}
                                    >
                                        {getHeaderStatement(r)?.status != 2 &&
                                            <AntdPopover
                                                open={openSnooze.open && r?.ROWID+"TT" == openSnooze.id}
                                                overlayClassName={`Snooze-Popover Do-Not-Close ROWID-${r?.ROWID}`}
                                                style={{ zIndex: 9999, "width": "max-content" }}
                                                trigger="click"
                                                placement="bottomLeft"
                                                content={() => content(r)}
                                                onOpenChange={e => {
                                                     
                                                    setOpenSnooze({ id: r?.ROWID+"TT", open: true });
                                                }}
                                                arrow={true}
                                            >
                                                <span>Snooze</span>
                                            </AntdPopover>
                                        }
                                        <span onClick={e => {
                                            handleCompleted(r)
                                        }}>Complete</span>
                                        <span onClick={e => {
                                            handleDelete(r)
                                        }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                    </OtsideClickClosingWrapper>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Row>
            ))
        }
        </Col>
    }
    function getAddReminderPopUp(data){
        return <>
            hii
        </>
    }
    function getTabContent(){
        if(ActiveTab?.label!="Active"){
            return getEmailTab(ActiveTab?.label);
        }else if(ActiveTab?.label=="Active" && triggeredReminders.length > 0){
            return getActiveReminders();
        }else{
            return <Col style={{height:"5rem",display:"flex",alignItems:"center",justifyContent:"center"}}>
                No Content
            </Col>
        }
    }
    return (
        <div>
            <AntModal
                open={open.status}
                draggable
                title={<div style={{
                    "position": "relative",
                    "display": "flex",
                    "width": "100%",
                }}>
                        <span>All reminders</span>
                        <span style={{
                                "position": "absolute",
                                "right": "-29px",
                        }}><FontAwesomeIcon icon={faPlus} onClick={() =>{
                            onClose();
                            dispatch(forwardObject({module:"Calendar",data:null,state:"create"}))
                            history.push(AllPaths["Calendar"]);
                          }} /></span>
                    </div>
                }
                maskClosable={false}
                width={300}
                className="All-Reminder-Modal My-Ant-Modal"
                style={{ top: 170, borderRadius: 50, right: 50 }}
                onCancel={() => {
                    onClose();
                }}
                
                footer={null} 
            >
                <div className='Reminder-List-Wrapper'>
                    {/* <Row>
                        <div className='Tabs-Wrapper'>
                            {Tabs.map(r=>{
                                return <div className={`Tab-Card ${ActiveTab==r.tabid && "Active-Tab"}`}
                                    onClick={e=>{

                                    }}
                                    >
                                    <span>{r.icon}</span>
                                    <span>{r.label}</span>
                                </div>
                            })}
                        </div>
                    </Row> */}
                    <CustomeTabs onSelectTab={tab=>setActiveTab(tab)} value={ActiveTab} tabs={Tabs}/>
                    {getTabContent()}
                    
                </div>
            </AntModal>
        </div>
    )
}
