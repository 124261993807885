import {  faMinusCircle,faChevronCircleRight,faChevronCircleDown,faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import './NestedComponent.css'
import { Collapse } from 'reactstrap';


export const NestedSection = ({ sectionName , getNestedFeildsBySection}) => {
    const [expand, setExpand] = useState(false);
    return (
        <div className='section-wrapper' style={getNestedFeildsBySection(sectionName)?.length>0?{}:{display:"none"}}>
            <div className='section-header-wrapper'>
                <span className={expand || sectionName=="Pined_Column"?"active":"inActive"}>{sectionName=="Pined_Column"?"Deal Name" :sectionName?.replaceAll('_'," ")}</span>
                <div className='expand-icon-wrapper' style={sectionName=="Pined_Column"?{display:"none"}:{}}>
                    {expand ?
                        <FontAwesomeIcon
                            icon={faChevronCircleDown}
                            onClick={e=>setExpand(!expand)}
                        >
                        </FontAwesomeIcon> :
                        <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            onClick={e=>setExpand(!expand)}
                        >
                        </FontAwesomeIcon>
                    }
                </div>
            </div>
            <Collapse
                isOpen={expand || sectionName=="Pined_Column"}
            >
                {getNestedFeildsBySection(sectionName)}
            </Collapse>

        </div>
    )
}
