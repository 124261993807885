import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper';

export default function MultiselectCheckbox(props) {
    console.log("props.eventData?.action", props.eventData?.action);
const [opendDropdowns,setOpendDropDowns]=useState(false);
    const getOptionByIcon =(idx,NotificationBy,flag)=>{
        return  props.eventDataOptions?.map((one, index) => {
         return (
           <label >
             <input
               checked={NotificationBy.includes(one.NotificationBy)}
               onChange={(e) => {
                 if(NotificationBy.length==1 && !e.target.checked){
                  return
                  }
                 let arr=props.eventData?.action[idx]?.NotificationBy;
                 if(e.target.checked){
                   arr.push(one.NotificationBy)
                 }else{
                  arr=arr.filter(item => item !== one.NotificationBy); 
                 }
                 let data={...props.eventData}
                 data.action[idx].NotificationBy=arr;
                 console.log(data,"data");
                 props.setEventData(data)
                // obj[index].isChecked=e.target.checked;
                // props.setEventDataOptions(obj);
               }}
               type="checkbox"
               id={index}
             />
             {/* <span className="cust-drop-icon"> */}
               {/* <span style={{ backgroundColor: "#889757" }}></span> */}
             {/* </span> */}
             <span className="cust-drop-text">
               {one?.NotificationBy?.charAt(0)?.toUpperCase() + one?.NotificationBy?.slice(1)}
             </span>
           </label>
         );
       })}
  return ( 
    // 
    <OtsideClickClosingWrapper
    className={"multiselect"} 
    forceClose={true}
    close={()=>setOpendDropDowns(false)}
    id={"custom_dropdown"}
    > 
    <div
      onClick={(e) => {
        // openDeopDown(e);
        setOpendDropDowns(!opendDropdowns);
      }}
      className="custom-tiles text-truncate overSelect"
    >
      {/* {values && values.length > 0 ? <></> : "Select Option"} */}
      {props.eventData?.action &&
        props.eventData?.action[props.idx]?.NotificationBy?.map((one, i) => {
          return (
            <span
              className="tile-span"
              key={i}
              style={
                 {background: "rgb(91, 108, 151)"} 
              }
              id={i}
            >
              {one?.charAt(0)?.toUpperCase() + one?.slice(1)}
              {/* <FontAwesomeIcon
                onClick={(e) =>{}
                  // removeTile(one.id, filterIndex, GroupIndex, e)
                }
                className="remove-tile"
                icon={faTimesCircle}
              /> */}
            </span>
          );
        })} 
    </div>
    <div
      class="selectBox"
      onClick={(e) => {
        // openDeopDown(e);
        setOpendDropDowns(!opendDropdowns);
      }}
    >
      <select>
        <option>
          <span style={{ padding: "5px", backgroundColor: "yellow" }}>
            ksafgjadsgfdsgjas
          </span>
        </option>
      </select>
      <div class="overSelect"></div>
    </div>
    <div
      className="custom-dropdown-box checkboxes"
      style={ opendDropdowns ? { display: "block" } : { display: "none" } }
      id={"three"}
    >
      <div className="filter-drop-list">
        { opendDropdowns?getOptionByIcon(props.idx,props.eventData?.action[props.idx].NotificationBy,props.flag):<></>} 
      </div>
    </div>
  </OtsideClickClosingWrapper>
  )
}