import React, { useEffect, useState, useRef } from "react";
import {
    Col,
    Modal,
    ModalBody,
    FormGroup,
    Button,
    Input,
    Row,
    ModalHeader,
    ModalFooter

} from "reactstrap";
import { faPlus, faEye, faEnvelope, faPen, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HttpService from "../../services/http.service";
import Geocode from "react-geocode";
import Table from '../../common/table/table';
import { useHistory, withRouter } from "react-router-dom";
import swal from "sweetalert";
import UserForm from "./UserForm";


const UsersTable = (props) => {

    const history = useHistory();
    const regexMatch = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
    const [selectedTable, setSelectedTable] = useState("MySettings");
    const [key, setKey] = useState("MyProfile");

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loginData, setLoginData] = useState({});
    const [newObj, setNewObj] = useState({});
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const [error, setError] = useState(null);
    const [passwordView, setPasswordView] = useState({ New: 'password', Confirm: 'password' });
    const [selectedUser, setSelectedUser] = useState(null)

    const [userModalPopUp, setUserModalPopUp] = useState(false);
    const [action, setAction] = useState('');

    const [allUsers, setAllUsers] = useState([]);
    const [allCacheUsers, setAllCacheUsers] = useState([]);
    const [searchVal, setSearchValue] = useState("");
    const [userstatus, setUserStatus] = useState("All")

    useEffect(() => {
        let existUsers = props.users.filter(one=> one.status!="deleted")
        setAllUsers(existUsers);
        setAllCacheUsers(props.users);
        setSelectedUser(props.selectedUser);
    }, [props.users, props.selectedUser])

    const setFilterStreet = (street) => {
        var str = street.replace(/(\d+)(st|nd|rd|th)/g, "$1");
        return str;
    };

    const searchOnBasisOfUsers = (e) =>{
        setSearchValue(e.currentTarget.value);
        let all = [...allCacheUsers];
        if (userstatus=='All') {
            if(e.currentTarget.value!==""){
                all = all.filter(one => {
           
                    if (one.full_name.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                        return one;
                    }
                    setAllUsers(all)
                })
            }
            else{
                setAllUsers(all); 
            }
            
            setAllUsers(all);
            }
       
        if (userstatus == 'active' ) {
            if(e.currentTarget.value!==""){
                all = all.filter(one => {
                    if( one.status==="active" && one.full_name.toLowerCase().includes(e.currentTarget.value.toLowerCase())){
                        return one;
                    }
                    setAllUsers(all) 
                })
            }
            else{
                all = all.filter(one => {
                    if(one.status==="active" ){
                        return one;
                    }
                })
            }
            
            setAllUsers(all);
          
        }
        if (userstatus == 'inactive' ) {
            if(e.currentTarget.value!==""){
                all = all.filter(one => {
                    if( one.status==="disabled" && one.full_name.toLowerCase().includes(e.currentTarget.value.toLowerCase())){
                        return one;
                    }
                    setAllUsers(all)
                })
            }
            else{
                all = all.filter(one => {
                    if(one.status==="disabled" ){
                        return one;
                    }
                })
              
            }
            setAllUsers(all);
          
        }
        // if (e.currentTarget.value !== '') {
        //     all = all.filter(one => {
           
        //         if (one.full_name.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
        //             return one;
        //         }
        //     })
        //     setAllUsers(all);
        // }
    }

    const onBlurProjectAdd = (value) => {
        Geocode.fromAddress(value).then(
            (response) => {
                const address = response.results[0].formatted_address;
                const { lat, lng } = response.results[0].geometry.location;
                let obj = {}
                var houseNO = "",
                    street = "",
                    city = "",
                    state = "",
                    zipcode = "",
                    borough = "",
                    neighbourhood = "";

                for (
                    let i = 0;
                    i < response.results[0].address_components.length;
                    i++
                ) {
                    for (
                        let j = 0;
                        j < response.results[0].address_components[i].types.length;
                        j++
                    ) {
                        switch (response.results[0].address_components[i].types[j]) {
                            case "street_number":
                                houseNO = response.results[0].address_components[i].long_name;
                                break;
                            case "route":
                                street = response.results[0].address_components[i].long_name;
                                break;

                                break;
                            case "sublocality_level_1":
                                borough = response.results[0].address_components[i].long_name;
                                if (
                                    borough == "Manhattan" ||
                                    borough == "Bronx" ||
                                    borough == "Brooklyn" ||
                                    borough == "Queens" ||
                                    borough == "Staten Island"
                                ) {


                                    if (borough == "Manhattan") {


                                        localStorage.setItem("borough", "1");
                                        city = "New York";
                                    } else if (borough == "Bronx") {


                                        localStorage.setItem("borough", "2");
                                        city = borough;
                                    } else if (borough == "Brooklyn") {


                                        localStorage.setItem("borough", "3");
                                        city = borough;
                                    } else if (borough == "Queens") {


                                        localStorage.setItem("borough", "4");
                                        city = neighbourhood;
                                    } else if (borough == "Staten Island") {


                                        localStorage.setItem("borough", "5");
                                        city = borough;
                                    } else {

                                        localStorage.setItem("borough", "0");
                                    }
                                } else {


                                    localStorage.setItem("nycOption", "0");
                                    localStorage.setItem("borough", "");
                                    city = response.results[0].address_components[i].long_name;
                                }
                                break;
                            case "locality":
                                if (
                                    borough != "Manhattan" &&
                                    borough != "Bronx" &&
                                    borough != "Brooklyn" &&
                                    borough != "Queens" &&
                                    borough != "Staten Island"
                                ) {
                                    city = response.results[0].address_components[i].long_name;


                                    localStorage.setItem("nycOption", "No");
                                    localStorage.setItem("borough", "");
                                }

                                break;
                            case "administrative_area_level_1":
                                state = response.results[0].address_components[i].short_name;
                                break;
                            case "postal_code":
                                zipcode = response.results[0].address_components[i].long_name;
                                break;
                        }
                    }
                }

                var str = value;
                var lastIndex = str.lastIndexOf(",");
                var res = str.substring(lastIndex);
                if (lastIndex > 0) {
                    if (res.includes("USA")) {
                        str = str.substring(0, lastIndex);
                        str = str + " " + zipcode;
                    }
                }

                obj.Address = str;

                var filterStreet = setFilterStreet(street)

                localStorage.setItem("houseNo", houseNO);

                localStorage.setItem("street", filterStreet);

                localStorage.setItem("city", city);
                obj.City = city;

                localStorage.setItem("state", state);
                obj.State = state;

                obj.Zip = zipcode;

                localStorage.setItem("zipcode", zipcode);

                localStorage.setItem("lat", lat);

                localStorage.setItem("lng", lng);
                console.log(lat, lng);

                updateUserData({ currentTarget: { name: 'Address', value: obj } })
            },
            (error) => {
                console.error("Error Finding Place:" + error);
            }
        );
    };

    const [warningModal, setWarningModal] = useState(false);

    const updateData = async (data) => {
        data.Active = !data.Active;
        let formData = new FormData();
        //formData.append("file", file ); 
        formData.append("data", JSON.stringify(data));
        await HttpService.CreateUpdate('updateuser', formData).then(res => {
            props.getAllUsers();
            alert("updated");
        });
    }

    const updateUserData = async (e) => {
        let profileData = { ...loginData };
        if (e.currentTarget.name == 'Address') {
            let mergeObj = { ...profileData, ...e.currentTarget.value };
            setLoginData(mergeObj);
        } else {
            profileData[e.currentTarget.name] = e.currentTarget.value;
            setLoginData(profileData);
        }
    }

    const userModalForm = () => {
        return <Modal
            size="xl"
            style={{width : "700px"}}
            backdrop='static'
            isOpen={userModalPopUp}
            modalClassName="Add-new-users"
            contentClassName="Add-new-users-content"
        >
            <ModalBody>
                <UserForm
                   closeModal={()=>setUserModalPopUp(false)}
                    selectedUser={selectedUser}
                    from='popup'
                    action={action}
                    getAllUsers={props.getAllUsers}
                    setUserModalPopUp={setUserModalPopUp}
                    isAccess={props.isAccess}
                    selectedRole={props.selectedRole}
                />
            </ModalBody>
        </Modal>
    }




    const placeInputRef1 = useRef(null);
    // useEffect(() => {
    //     initPlaceAPI()
    // })




    const showPhotoOfUser = (user) => {
        let photo = user.ProfilePhoto;
        let name = user.FirstName + " " + user.LastName;
        if (photo === null || photo === "") {
            return (
                <div
                    className='no-image-user'
                >{name ? name.charAt(0) : ''}</div>)
        }
        else {
            return (<img src={photo} className="user-ico" />)
        }
    }
    return (
        <div className='row'>
            {userModalForm()}
            <div className='col-sm-5 border-right '>
                <div className='row mt-3'>
                    <div className='col-sm-5 form-group'>
                        <select onChange={(e) => {
                            let all = [...allCacheUsers];
                            if (e.currentTarget.value == 'All') {
                                setUserStatus("All")
                                setAllUsers(all);
                             
                            }
                            if (e.currentTarget.value == 'active') {
                                all = all.filter(one => one.status==="active")
                                setUserStatus("active")
                                setAllUsers(all);
                              
                            }
                            if (e.currentTarget.value == 'inactive') {
                                all = all.filter(one => one.status==="disabled")
                                setUserStatus("inactive")
                                setAllUsers(all);
                               
                            }
                        }} className='form form-control form-control-sm'>
                            <option value={'All'}>All</option>
                            <option value={'active'}>Active Users</option>
                            <option value={'inactive'}>Inactive Users</option>
                        </select>
                    </div>
                    <div className='col-sm-2'>
                    </div>
                    <div className='col-sm-5'>
                        <button
                            onClick={() => {
                                setAction('create');
                               // setSelectedUser(null);
                                setUserModalPopUp(true);
                            }}
                            className='btn btn-sm btn-primary'
                        >
                            <FontAwesomeIcon icon={faPlus} />New Users
                        </button>
                    </div>

                </div>
                <div className='row mt-1'>
                    <div className="cursor-pointer mb-2 col-sm-12 d-flex ">
                        <div className="d-flex align-items-center px-2">
                            <input type="checkbox" />
                        </div>
                        <div className="mx-3">
                            <input
                                onChange={(e) => {
                                   
                                    searchOnBasisOfUsers(e)
                                    // let all = [...allUsers];
                                    // if (e.currentTarget.value !== '') {
                                    //     all = all.filter(one => {
                                       
                                    //         if (one.full_name.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                                    //             return one;
                                    //         }
                                    //     })
                                    //     setAllUsers(all);
                                    // }
                                    // else{
                                    //     setAllUsers(all);
                                    // }
                                   
                                }} 
                                value={searchVal}
                                type="text" className="form-control form-control-sm" name='UserSearch' placeholder="search.."
                            />
                        </div>
                    </div>
                    <div className="user-profile-left">
                        {allUsers.map((user) => {
                            return <div onClick={() => setSelectedUser(user)} className="cursor-pointer mb-2 col-sm-12 d-flex align-items-center user-profile"
                                style={selectedUser&&(selectedUser.id === user.id) ? { backgroundColor: "#f1f5f9" } : { backgroundColor: "white" }}
                            >
                                <div className="d-flex align-items-center px-2">
                                    <input type="checkbox" />
                                </div>
                                <div className="px-1">
                                    <div
                                        className='no-image-user'
                                    >{user.full_name ? user.full_name.charAt(0) : ''}</div>
                                </div>
                                <div className="user-profile-content">
                                    <h5>{user.full_name}</h5>
                                    <h6>
                                        <span
                                            onClick={() => {
                                                //     let filterRoles = userRoleData.filter(e=>e.UserRole==user.Role[0].label)
                                                //     history.push({
                                                //     pathname: "/usersroles/form",
                                                //     state: { detail: filterRoles[0] }
                                                // }
                                                // )
                                            }
                                            }
                                        >{user.role  ? user.role.name : 'N/A'}</span></h6>
                                    <h6>{user.email}</h6>
                                </div>
                            </div>
                        })}
                    </div>
                </div>

            </div>
            {selectedUser ?

                <div className='col-sm-7 user-profile-right'>
                    <div className="text-right my-3">
                        {selectedUser && selectedUser.confirm  ? <></> : <button
                            className="btn btn-outline-primary reinvite-btn text-primary mx-2"
                            // onClick={() => handleResendEmail(selectedUser.UserId)}
                        >Reinvite</button>
                        }
                        <button
                            className={true ? "btn btn-outline-danger deactivate-btn text-danger" : "btn btn-outline-success deactivate-btn text-success"}
                            onClick={() => {
                                setWarningModal(!warningModal)
                            }}
                        >
                            {selectedUser.status=='active'? 'Deactivate' : 'Activate'}
                        </button>
                    </div>
                    <div className="row users-pd">
                        <Col sm={1} className="p-0">
                        <div
                    className='no-image-user'
                >{selectedUser ? selectedUser.full_name.charAt(0) : ''}</div>
                            {/* {showPhotoOfUser(selectedUser)} */}
                            {/* <img 
                            src={localStorage.getItem("ProfilePhoto")} class="userprofile-img " 
                        /> */}
                        </Col>
                        <Col sm={6}>
                            <span className="usersname">{selectedUser.full_name} {" "}</span>
                            <span className="companyneme">
                                <span
                                    className="admintype"
                                    onClick={() => {
                                        //     let filterRoles = userRoleData.filter(e=>e.UserRole==selectedUser.Role[0].label)
                                        //     history.push({
                                        //     pathname: "/usersroles/form",
                                        //     state: { detail: filterRoles[0] }
                                        //   }
                                        //   )
                                    }
                                    }
                                >{selectedUser.role  ? selectedUser.role.name : ''}</span> at SRS Solar Design
                            </span>
                            <span className="emailid"><FontAwesomeIcon icon={faEnvelope} className="mr-2" />{selectedUser.Email}</span>
                        </Col>
                        <Col sm={5}>
                            {/* {selectedUser && selectedUser.Password && (selectedUser.Password !== '') ? <></> : <a onClick={() => handleResendEmail(selectedUser.UserId)} className="resend-email">Resend Email</a>} */}

                            <FontAwesomeIcon
                                icon={faPen}
                                onClick={() => {
                                    setAction('edit')
                                    setUserModalPopUp(true)
                                }}
                                className="float-right"
                            />
                        </Col>

                        <div className="row"></div>
                        <Col sm={12}>
                            <span className="user-info">User Information</span>
                        </Col>
                        <Col sm={4}>
                            <label>First Name</label>
                        </Col>
                        <Col sm={8}>
                            <span>{selectedUser.first_name}</span>
                        </Col>
                        <Col sm={4}>
                            <label>Last Name</label>
                        </Col>
                        <Col sm={8}>
                            <span>{selectedUser.last_name}</span>
                        </Col>
                        <Col sm={4}>
                            <label>Email</label>
                        </Col>
                        <Col sm={8}>
                            <span>{selectedUser.email}</span>
                        </Col>
                        <Col sm={4}>
                            <label>State</label>
                        </Col>
                        <Col sm={8}>
                            <span>{selectedUser.state}</span>
                        </Col>
                        <Col sm={4}>
                            <label>City</label>
                        </Col>
                        <Col sm={8}>
                            <span>{selectedUser.city}</span>
                        </Col>
                        <Col sm={4}>
                            <label>Zip</label>
                        </Col>
                        <Col sm={8}>
                            <span>{selectedUser.zip}</span>
                        </Col>
                        <Col sm={4}>
                            <label>Phone</label>
                        </Col>
                        <Col sm={8}>
                            <span>{selectedUser.phone}</span>
                        </Col>
                        <Col sm={12} style={{ textAlign: "center" }}>
                            <button
                                className="btn btn-outline-danger deactivate-btn text-danger"
                                style={{ textAlign: "center" }}
                                // onClick={() => removeUser()}
                            >Delete this User
                            </button>
                        </Col>
                    </div>
                    {/* <div className="mt-2">
                    <div className="row">
                        <Col sm={1}>
                            {showPhotoOfUser(selectedUser)}
                          
                        </Col>
                        <Col sm={11}>
                            <div>
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        className="form-control"
                                        name="FirstName"
                                        type="text"
                                        value={selectedUser.FirstName}
                                        onChange={(e) => updateUserData(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        className="form-control"
                                        name="LastName"
                                        type="text"
                                        value={selectedUser.LastName}
                                        onChange={(e) => updateUserData(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        name="Email"
                                        type="email"
                                        className="form-control"
                                        value={selectedUser.Email}
                                        onChange={(e) => updateUserData(e)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Address</label>
                                    

                                    <input
                                        name='Address'
                                        type="text"
                                        className="form-control"
                                        ref={placeInputRef1}
                                        defaultValue={selectedUser.Address}
                                        //onChange={(e) => updateUserData(e)}
                                        onBlur={(e) => onBlurProjectAdd(e.target.value)}
                                    />

                                </div>
                                <div className="form-group">
                                    <label>City</label>
                                   
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="City"
                                        value={selectedUser.City}
                                        onChange={(e) => updateUserData(e)}
                                    />

                                </div>
                                <div className="form-group">
                                    <label>State</label>
                                   
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="State"
                                        value={selectedUser.State}
                                        onChange={(e) => updateUserData(e)}
                                    />

                                </div>
                                <div className="form-group">
                                    <label>Zip</label>
                                   
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="Zip"
                                        value={selectedUser.Zip}
                                        onChange={(e) => updateUserData(e)}
                                    />

                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={selectedUser.Phone}
                                        name="Phone"
                                        onChange={(e) => updateUserData(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Role</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="Role"
                                        value={selectedUser.Role ? selectedUser.Role[0].label : ""}
                                        onChange={(e) => updateUserData(e)}
                                    />
                                </div>
                                <div>
                                    <button className="btn btn-primary" onClick={() => {

                                        setResetPasswordOpen(!resetPasswordOpen)
                                    }}
                                    >Reset Password</button>
                                </div>
                               
                            </div>
                        </Col>
                    </div>
                </div> */}



                    <Modal
                        className="centered-modal"
                        isOpen={resetPasswordOpen}
                        toggle={resetPasswordOpen}
                    >
                        <ModalHeader>Reset Password</ModalHeader>
                        <ModalBody>
                            <Row className="mb-2">
                                <Col sm={4}>
                                    <label>
                                        New Password
                                    </label>
                                </Col>
                                <Col sm={6}>
                                    {/* <InputGroup>
                            <Input placeholder="username" />
                            <InputGroupAddon addonType="prepend"></InputGroupAddon>
                          </InputGroup> */}
                                    <div className="pos-relative">
                                        <input
                                            placeholder="New Password"
                                            type={passwordView.New}
                                            className="form-control pr-25"
                                            name="NewPassword"
                                            value={newPassword}
                                            onChange={(e) => {
                                                if ((newPassword !== '') && (!e.target.value.match(regexMatch))) {
                                                    let obj = { ...error };
                                                    obj.New = 'Password must be 8 character one special character and one number'
                                                    setError(obj)

                                                } else {
                                                    setError(null)
                                                }
                                                setNewPassword(e.target.value)
                                            }}
                                        />
                                        {error && error.New ? <p className="small text-danger">{error.New}</p> : ""}

                                        <FontAwesomeIcon
                                            className="view-icons"
                                            onClick={() => {
                                                const obj = { ...passwordView }
                                                if (obj.New == 'text') {
                                                    obj.New = 'password'
                                                } else {
                                                    obj.New = 'text'
                                                }
                                                setPasswordView(obj);
                                            }} icon={faEye} color='gray' />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <label>
                                        Confirm Password
                                    </label>
                                </Col>
                                <Col sm={6}>
                                    <div className="pos-relative">
                                        <input
                                            placeholder="Confirm Password"
                                            type={passwordView.Confirm}
                                            className="form-control"
                                            name="ConfirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => {
                                                if ((confirmPassword !== '') && (!e.target.value.match(regexMatch))) {
                                                    let obj = { ...error };
                                                    obj.Confirm = 'Password must be 8 character one special character and one number'
                                                    setError(obj)

                                                } else {
                                                    setError(null)
                                                }
                                                setConfirmPassword(e.target.value)

                                            }}
                                        />
                                        {error && error.Confirm ? <p className="small text-danger">{error.Confirm}</p> : ""}
                                        <FontAwesomeIcon
                                            className="view-icons"
                                            onClick={() => {
                                                const obj = { ...passwordView }
                                                if (obj.Confirm == 'text') {
                                                    obj.Confirm = 'password'
                                                } else {
                                                    obj.Confirm = 'text'
                                                }
                                                setPasswordView(obj);
                                            }


                                            } icon={faEye} color='gray' />
                                    </div>


                                </Col>


                            </Row>



                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setResetPasswordOpen(!resetPasswordOpen)
                                }}
                            >
                                {" "}
                                Cancel
                            </Button>
                            <Button

                                color="primary"
                                onClick={() => {
                                    // ResetNewPassword()
                                }}
                            >
                                {" "}
                                Update
                            </Button>
                            {/* <Button color="secondary" onClick={() => setDelPopup(!delPopup)}>
                      {" "}
                                  Cancel
                                   </Button> */}
                        </ModalFooter>
                    </Modal>

                </div> : <></>}

        </div>
    );
}

export default UsersTable;