import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import { DateRangePicker, Calendar } from "react-date-range";
import { addDays, lastDayOfMonth } from "date-fns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-all";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import TimePicker from "react-time-picker/dist/entry.nostyle";
import attachIcon from "../../assets/images/task-attachment-icon.png";
import Vector from "../../assets/images/vector.png";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DatePicker, notification, Space } from "antd";
import { components } from "react-select";
import createUID from "create-unique-id";
import "./reminder.css";
// import "./TimePicker.css";
// import '../jobbody.css';
import { Popover as AntdPopover } from "antd";
import Config from "../../config.json";
import { evaluate, string } from "mathjs";
import "antd/dist/reset.css";
// import dateTime from '../../../../../common/utill/dateTime';
// import dealFields from '../../../../../common/dealFields';
import excelFormulaUtilities from "excel-formula";
import ChatService from "../../common/util/ChatService";
import Comment from "../../common/Comment/comment";
import AttachmentModal from "./attachmentModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ModuleService from "../../common/util/ModuleService";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Tooltip,
  Card,
  CardTitle,
  CardText,
  Collapse,
  FormGroup,
  CardBody,
} from "reactstrap";
import { Tabs, Tab, Nav } from "react-bootstrap";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faHourglass,
  faClock,
  faStopCircle,
  faPlayCircle,
  faEdit,
  faTrashAlt,
  faEye,
  faCalendar,
  faCalendarCheck,
  faCaretSquareDown,
  faCheckSquare,
  faFile,
  faDotCircle,
  faCircle,
  faPauseCircle,
  faFilePdf,
} from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationTriangle,
  faFlag as faFlagSolid,
  faUpload,
  faSquare,
  faFunnelDollar,
  faAngleRight,
  faExpandAlt,
  faReply,
  faEnvelope,
  faImage,
  faSortNumericDown,
  faPercentage,
  faMoneyBillAlt,
  faFun,
  faPhone,
  faSearchPlus,
  faPlus,
  faFileDownload,
  faBars,
  faEllipsisV,
  faForward,
  faBackward,
  faArrowLeft,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import assigntask from "../../assets/images/svg/assigntask.svg";
import avtar from "../../assets/images/avtar.jpg";
import {
  faSearch,
  faCaretDown,
  faCaretRight,
  faEllipsisH,
  faPen,
  faCog,
  faChevronDown,
  faChevronRight,
  faFlag as solidFlag,
  faTimes,
  faStop,
  faAlignLeft,
  faTrash,
  faSlidersH,
  faMinusCircle,
  faPlusCircle,
  faHashtag,
  faTags,
  faItalic,
  faTextHeight,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import HttpService from "../../services/http.service";
import "./task.css";
import { v4 as uuidv4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import _, { result } from "lodash";
import defaultUser from "../../assets/images/defaultUser.png";
import { Hidden } from "@material-ui/core";
import httpService from "../../services/http.service";
import TaskService from "../../common/util/TaskService";
import InputFieldsModal from "../../common/Fields/InputFields";
import LookUpFields from "../../common/Fields/LookUpFields";
import dayjs from "dayjs";
import UserService from "../../common/util/UserService";
import axios from "axios";
import Workdrive from "../../components/Workdrive/workdrive";
import ActivitieService from "../../common/util/ActivitieService";
import Loader from "../../common/loader";
// import Progress from "../../common/util/progress_custom";
import ProgressBarCustom from "../../common/util/progress_custom";
import StatusDropdown from "../../common/StatusDropdown/StatusDropdown";
import validation from "../../common/util/validation";
import ProjectService from "../../common/util/ProjectService";
import CustomMultiSelect from "../../common/custom_multiselect/custom_multiselect";
import CustomDropdown from "../../common/custom_dropdown/CustomDropdown";
import NotificationAPI from "../../common/util/NotificationAPI";
import Reminder from "../../components/Reminder/Reminder";
import CalendarAPI from "../../common/util/CalendarAPI";
import remIcoCutom from "../../assets/images/reminder_icon_custom.svg";
import remIcoClickup from "../../assets/images/reminder_icon_clickup.svg";

import reminderFillColor from "../../assets/images/reminder_icon_solid_y.svg";
import reminderGrayColor from "../../assets/images/reminder_icon_solid_g.svg";

// import EmployeeCalender from "../../../../hr/employeeCalender/employeeCalender";
// import { isTaskAuthorize } from "../../../../../common/utill/taskAuthorize";

let usersDetail = [];
let selectedTask = {};
let selectedSubTask = {};
let selectedMeataTask = {};
let timeTrackInitialTime = new Date(Date.now());
let NOT_DEFINED_VALUE = null;

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

const TaskModal = (props) => {
  const backendUrl = Config.backend_url;
  const history = useHistory();
  const dropdownRef = useRef();
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();

  // const onDrop = React.useCallback(acceptedFiles => {
  //   handleFileAttachments(acceptedFiles);
  // }, []);

  const taskSchema = {
    status: {
      color_code: "#a7bcee",
      name: "Not Started",
      id: "1716273000002029045",
    },
  };

  const taskFields = {};
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [taskData, setTaskData] = useState(taskSchema);
  const [reminders, setReminder] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [openFormulaPopup, setFormulaPopUp] = useState(false);
  const [subTaskData, setSubTaskData] = useState(taskSchema);
  const [metaTaskData, setMetaTaskData] = useState(taskSchema);
  const [openTimeTrackedPopUp, setOpenTimeTrackedPopUp] = useState(false);
  const [allSuperviser, setAllSuperviser] = useState([]);
  const [deleteWarnObj, setDeleteWarnObj] = useState({
    id: "assignee",
    header: "Assignee Delete Warning",
    text: "Are you sure? You want to remove Assignee.",
  });
  const [userMailInvalid, setUserMailInvalid] = useState(false);
  const [userPhoneInvalid, setUserPhoneInvalid] = useState(false);
  const [fieldsModal, setFieldsModal] = useState(false);
  const [lookUpModal, setLookUpModal] = useState(false);
  const [openTaskArray, setOpenTaskArr] = useState([]);
  const [taskFieldAction, setTaskFieldAction] = useState("");

  const [taskCustumeFieldList, setTaskCustumFieldList] = useState([]);

  const [relativePathId, setRelativePathId] = useState(null);

  const [relativeFolderId, setRelativeFolder] = useState(null);

  const [subTaskList, setSubtaskList] = useState([]);
  const [timeTrackLogs, setTimeTrackLogs] = useState([]);
  const [timerObj, setTimerObj] = useState(null);
  const [reminderModal, setReminderModal] = useState(false);
  const [relativeModal, setRelativeModal] = useState(false);
  const [moveTaskObj, setMoveTaskObj] = useState({});

  const [taskAttachements, setTaskAttachements] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState(false);

  const [attachementViewType, setAttachementViewType] = useState("list");

  const [dependencyTaskList, setDependencyTaskList] = useState([]);

  const [dealList, setDealList] = useState([]);

  const [myTasksList, setMyTaskList] = useState([]);

  const [activityFlag, setActivityFlag] = useState(false);

  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState({ active: false, percent: 0 });

  const [projectPipeline, setProjectPipeline] = useState([]);
  const activityView = [
    "All",
    "Comments",
    "Status",
    "Priority",
    "Assignee",
    "Start/Due Date",
    "Category",
  ];
  const [viewActivity, setViewActivity] = useState(activityView);

  const [actionOnCommentData, setActionOnCommentData] = useState({});

  //// Reminder State
  const [notifyMePopUp, setNotifyMePopUp] = useState(false);
  const [closeAssigneePopup, setCloseAssigneePopup] = useState(false);

  const [selectedUser, setSelectedUser] = useState([
    {
      email: userinfo?.email_id,
      role: userinfo?.role_details.role_name,
      name: userinfo?.first_name + " " + userinfo?.last_name,
      zuid: localStorage.getItem("ZUID"),
    },
  ]);

  //////////////////////////////   startDate endDate///////////////////////////////////////////////////

  const [startDateTime, setStartDateTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Comment State

  const [commentMessages, setCommentMessages] = useState(null);
  const [allData, setAllData] = useState([]);

  const [checkStatusToComplete, setCheckStatusToComplete] = useState(false);
  const [openCommentEditor, setOpenCommentEditor] = useState(false);
  const [Comms, setComms] = useState("");
  const [CommentsBody, setCommentsBody] = useState([]);
  const [replyData, setReplyData] = useState("");
  const [allInputFields, setAllInputFields] = useState([]);
  const [activityFilterBy, setActivityFilterBy] = useState("All");

  useEffect(() => {
    if (!fieldsModal && taskData.id) {
      getTaskFieldsForThisTask(taskData.id);
    }
  }, [fieldsModal, taskData]);
  useEffect(() => {
    if (
      (props.taskCreateLocation == "Account" ||
        props.taskCreateLocation == "Tasks") &&
      props.dealObj.Project_ID &&
      props.allProject.length > 0
    ) {
      let ids = props.dealObj.Project_ID;
      ids = ids.split(",");
      ids = ids.filter((id) => id);
      let projects = [...props.allProject];
      projects = projects.filter((one) => ids.includes(one.id_string));
      let doarr = [];
      // doarr.push(getTaskList(taskData.project_id))
      GetTaskList(projects)
        .then((tasklist) => {
          tasklist = validation.RemoveNestedArray(tasklist);
          tasklist = tasklist.filter((tlist) => tlist.name !== "General");
          setProjectPipeline(projects);
          setTaskList(tasklist);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // setProjectPipeline([]);
      // setTaskList([]);
    }
  }, [props.dealObj, props.id, props.allProject, props.taskCreateLocation]);

  useEffect(() => {
    if (props.taskCreateLocation == "Deals") {
      if (props.dealObj.Project_ID && props.allProject.length > 0) {
        let ids = props.dealObj.Project_ID;
        ids = ids.split(",");
        ids = ids.filter((id) => id);
        let projects = [...props.allProject];
        projects = projects.filter((one) => ids.includes(one.id_string));
        let doarr = [];
        // doarr.push(getTaskList(taskData.project_id))
        // projects.forEach(one => {
        //   doarr.push(getTaskList(one.id_string))
        // })
        GetTaskList(projects)
          .then((tasklist) => {
            tasklist = validation.RemoveNestedArray(tasklist);
            tasklist = tasklist.filter((tlist) => tlist.name !== "General");
            setProjectPipeline(projects);
            setTaskList(tasklist);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setProjectPipeline(props.allProject);
      }
    }
  }, [props.dealObj, props.id, props.allProject, props.taskCreateLocation]);

  // useEffect(() => {
  //   // if(props.job){
  //   let dealName = props.dealObj ? "#" + props.dealObj.Deal_Name + "_Notes" : "";
  //   ChatService.GetAllChannels().then(channel => {
  //     let filterChannels = channel.filter(one => one.name === "#Task_Notes")
  //     console.log(filterChannels);
  //     setCommentMessages(filterChannels[0]);
  //   })
  //     .catch((error) => {
  //       alert(error)
  //     })
  //   // }

  // }, [props.dealObj])

  // useEffect(() => {
  //   if (commentMessages) {
  //     getAllTaskMessage()
  //   }

  // }, [commentMessages])

  const getIcon = (ext) => {
    if (ext == "txt") {
      return <FontAwesomeIcon size="5x" className="file-icon" icon={faFile} />;
    } else if (ext == "png" || ext == "jpg" || ext == "svg" || ext == "jpeg") {
      return <FontAwesomeIcon size="5x" className="file-icon" icon={faImage} />;
    } else if (ext == "pdf") {
      return (
        <FontAwesomeIcon
          size="5x"
          style={{ color: "red" }}
          className="file-icon"
          icon={faFilePdf}
        />
      );
    } else {
      return <img className="file-icon" src={attachIcon} />;
    }
  };

  const getMergeFieldsAll = () => {
    // const deals = { ...dealFields };
    const task = { ...taskFields };
    const merge = { ...task };
    return merge;
  };

  const getTagName = (keyField) => {
    // const deals = { ...dealFields };
    const task = { ...taskFields };
    // if (deals[keyField]) {
    //   return 'Deal'
    // }
    if (task[keyField]) {
      return "Task";
    } else {
      return "";
    }
  };

  const [allOperators, setAllOperators] = useState([]);
  const [mathFunctions, setAllMathFunctions] = useState([]);
  const [allFields, setAllFields] = useState(getMergeFieldsAll());
  const [taskList, setTaskList] = useState([]);

  const [taskLayout, setTaskLayout] = useState({
    status_details: [
      {
        is_start: false,
        color: "#a7bcee",
        name: "Not Started",
        id: "1716273000002029045",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#fbc11e",
        name: "To Do",
        id: "1716273000002029041",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#08aeea",
        name: "In Progress",
        id: "1716273000000031001",
        is_default: true,
        type: "open",
      },
      {
        is_start: false,
        color: "#98ca3c",
        name: "Completed",
        id: "1716273000002029047",
        is_default: false,
        type: "closed",
      },
      {
        is_start: false,
        color: "#a9b2c0",
        name: "Not Required",
        id: "1716273000002029043",
        is_default: false,
        type: "closed",
      },
    ],
  });
  // const [taskStatus, setTaskStatus] = useState([])

  const [serviceRequested, setServicesRequested] = useState([]);
  const [selectedServiceReq, setSelectedServiceReq] = useState([]);

  const removeAssignee = (user) => {
    let selected = [...selectedUser];
    selected = selected.filter((obj) => obj.zuid !== user.zuid);
    setSelectedUser(selected);
  };

  const customeFields = {
    UDF_MULTI1: "Task Template Fields",
    UDF_CHAR9: "Milestone Name",
    UDF_CHAR8: "Milestone Id",
    UDF_CHAR6: "Category",
    UDF_BOOLEAN1: "IsTemplate",
    UDF_CHAR4: "Template Name",
    UDF_BOOLEAN2: "IsMilestone",
    UDF_CHAR1: "Pipeline",
    UDF_CHAR10: "Time Estimate",
  };

  useEffect(() => {
    if (
      props.dependencyList &&
      props.dependencyList.length > 0 &&
      taskData.id
    ) {
      let list = props.dependencyList.filter(
        (one) => one.Current_Task_ID == taskData.id_string
      );
      // let succesee=props.dependencyList.filter((one) => &&(one.Related_Task_ID == taskData.id_string));
      setTaskDependency(list);
    } else {
      setTaskDependency([]);
    }
  }, [props.dependencyList, taskData]);

  useEffect(() => {
    if (taskData && taskData.id) {
      if (
        taskData.custom_fields &&
        taskData.custom_fields.filter(
          (one) => one.label_name == "Time Estimate"
        ).length > 0
      ) {
        let filterValue = taskData.custom_fields.filter(
          (one) => one.label_name == "Time Estimate"
        )[0].value;
        let obj = getTimeEstimateValue(filterValue);

        setTimeEstimateValue(obj.hrs);
        setTimeMinuteValue(obj.mins);
      }
      getAllActivityAsPerTask(taskData.id_string);
      console.log("taskDatapid", taskData.project_id);
      getAllTimeTrackLogs(taskData.JobId);
      // getCurrentlyRunningTimer();
      getCalendarEventList(taskData.id_string);
      getSubtaskList(taskData.project_id, taskData.id_string);
      getTaskAttachements(taskData.project_id, taskData.id_string);
      getTaskLayout(taskData.project_id);
      getAllTaskMessage(taskData.project_id, taskData.id_string);
      if (taskData.start_date_format && taskData.end_date_format) {
        setStartAndDueDateLabel(
          new Date(taskData.start_date_format),
          new Date(taskData.end_date_format)
        );
      }

      setRelativePath(taskData);
    } else {
      getTaskLayout(taskData.status.id);

      subtaskCollapseOpen();
    }

    // if (props.dealObj.Project_ID) {
    //   let pid = props.dealObj.Project_ID;
    //   pid=pid.split(',');
    //   pid=pid.filter(id=>id);
    //   let doarr = []
    //   pid.forEach(id=>{
    //     doarr.push(getTaskList(id))
    //   })

    //   Promise.all(doarr)
    //     .then((result) => {
    //       result = validation.RemoveNestedArray(result);
    //       setTaskList(result);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    // }
    getDealList();
    // getAllMyTask()
  }, [taskData]);

  useEffect(() => {
    if (!props.timeTrackFlag) {
      getAllTimeTrackLogs(taskData.JobId);
    }
  }, [props.timeTrackFlag]);

  const convertDateTime = (dateTime) => {
    if (dateTime) {
      // dateTime=dateTime.split('-')
      // dateTime=dateTime.slice(0,dateTime.length-1);
      // dateTime=dateTime.join('')+'Z'
      dateTime = dayjs(dateTime);
      return dateTime.$d;
    }
  };

  const getCalendarEventList = (taskid) => {
    CalendarAPI.GetAllCalendars()
      .then((result) => {
        return CalendarAPI.GetAllEvents(result.uid);
      })
      .then((result) => {
        result = validation.RemoveNestedArray(result);
        let now = new Date();
        now.setMinutes(now.getMinutes() + 5);
        result = result.filter(
          (one) =>
            one.description.includes(`taskid:${taskid}`) &&
            new Date(now) <= new Date(convertDateTime(one.dateandtime.start))
        );
        setReminder(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetTaskList = async (projectIds) => {
    try {
      let result = [];
      for (let i = 0; i < projectIds.length; i++) {
        let res = await TaskService.GetTaskList(projectIds[i].id_string);
        result.push(res);
      }
      return result;
    } catch (err) {
      console.log(err);
    }
  };

  const getTaskList = (projectId) => {
    return new Promise((resolve, reject) => {
      TaskService.GetTaskList(projectId)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const setRelativePath = (data) => {
    let ids = data.custom_fields.filter(
      (one) => one.column_name == "UDF_CHAR3"
    );
    if (ids.length > 0) {
      let names = ids[0].value.split("/");
      names = names.filter((one) => one);
      setRelativePathId(ids[0].value);
      setSearchFileId(
        props.dealObj.zohoworkdriveforcrm__Workdrive_Folder_ID,
        names[names.length - 1]
      );
    }
  };

  const setSearchFileId = (parentId, folderName) => {
    let teamId = "lvelh71c5a951437d4db18a23bc8628fec5be";
    var config = {
      Type: "Search",

      url:
        "https://www.zohoapis.com/workdrive/api/v1/teams/" +
        teamId +
        "/records?search%5Ball%5D=" +
        folderName +
        "&filter%5BparentId%5D=" +
        parentId +
        "&page%5Blimit%5D=50&page%5Boffset%5D=0",
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(backendUrl + "workdrive", { config: config })
      .then(function (response) {
        if (response.data.data.length > 0) {
          response.data.data = response.data.data.filter(
            (one) => one.attributes.name == folderName
          );

          setRelativePathId(response.data.data[0].id);
          setRelativeFolder(response.data.data[0].attributes.name);
          // setSearchState(true);

          // setSearchFiles(response.data.data);
          // setOpenFolderDir([])
          // setFolderId(null)
        }

        console.log(
          "-------------------------files data-----------------------------------"
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAllMyTask = () => {
    TaskService.GetMyTasks()
      .then((result) => {
        setMyTaskList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTaskLayout = (projectId) => {
    TaskService.GetTaskLayout(projectId)
      .then((result) => {
        setDefaultDependencyStatus(result.status_details);
        if (result.error) {
          setTaskLayout({ status_details: [] });
        } else {
          setTaskLayout(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealList = () => {
    const backendUrl = Config.backend_url + "getAllDealPages";
    let config = {
      token: localStorage.getItem("token"),
    };
    axios
      .post(backendUrl, { ...config })
      .then(function (res) {
        res.data.data = res.data.data.map((one) => {
          one.Created_By = one.Created_By.name;
          one.Owner = one.Owner.name;
          one.Account_Name = one.Account_Name ? one.Account_Name.name : "";
          return one;
        });
        setDealList(res.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTaskAttachements = (dealId, taskId) => {
    TaskService.GetTaskAttachements(dealId, taskId)
      .then((result) => {
        console.log(result);
        if (result.error) {
          setTaskAttachements([]);
        } else {
          setTaskAttachements(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubtaskList = async (pid, taskId) => {
    let subtaskRelation = await TaskService.GetAllSubTaskOfTask(pid, taskId);
    if (subtaskRelation && subtaskRelation.length > 0) {
      setSubtaskCollapse(true);
    } else {
      setSubtaskCollapse(false);
    }
    setSubtaskList(subtaskRelation);
  };

  const getAllTaskMessage = (pid, tid) => {
    TaskService.GetTaskComment(pid, tid)
      .then((notes) => {
        if (notes) {
          setCommentsBody(notes);
          setAllData(notes);
        } else {
          setCommentsBody([]);
          setAllData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentlyRunningTimer = () => {
    TaskService.GetCurrentlyRunningTimer()
      .then((result) => {
        if (result && taskData.JobId == result.jobId) {
          setInterv(
            setInterval(() => {
              let nowTime = new Date(Date.now());
              let diff = nowTime.getTime() - timeTrackInitialTime.getTime();
              diff = diff / 1000;
              timeTrackInitialTime = new Date(nowTime);
              setTimer((prev) => prev + diff);
            }, 1000)
          );
          let startTime = new Date(Date.now());
          startTime.setTime(startTime.getTime() + result.diff * 1000);
          timeTrackInitialTime = new Date(startTime);
          setTimer((prev) => prev + result.diff);
          setTimerObj(result);
          setClockIsActive(true);
        } else {
          setTimerObj(null);
          setClockIsActive(false);
          // setTimer(0)
          clearInterval(interv);
        }
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertZohoMentionToCRM = (message) => {
    const users = [...allUsers];
    users.forEach((one) => {
      message = message.toString("html");
      message = message.replaceAll(
        `zp[@zpuser#${one.zuid}#${one.full_name}]zp`,
        `<span class="mymention" data-mention-id=${one.zuid} id=User-${one.zuid} name="User" >${one.full_name}</span>`
      );
      message = message.replaceAll(
        `zp[@zpuser#${one.zuid}#${one.first_name}]zp`,
        `<span class="mymention" data-mention-id=${one.zuid} id=User-${one.zuid} name="User" >${one.first_name}</span>`
      );
    });
    return message;
    console.log(message);
  };

  const getAllTimeTrackLogs = (taskId) => {
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    let startDate = new Date();
    startDate.setDate(1);
    startDate = dayjs(startDate).format("YYYY-MM-DD");
    let endDate = new Date();
    endDate.setDate(31);
    endDate = dayjs(endDate).format("YYYY-MM-DD");
    //userinfo.email_id
    TaskService.GetAllTimeTrack(userInfo?.email_id, startDate, endDate, taskId)
      .then((result) => {
        // console.log(result)
        let totalTime = 0;
        if (result) {
          result.forEach((one) => {
            totalTime = totalTime + one.totaltime;
          });
        }

        setTimer(totalTime);
        setTimeTrackLogs(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (props.taskAction == "Edit") {
      let cloneTask = { ...props.task };
      const arr = [...openTaskArray];
      arr.push(cloneTask);
      setOpenTaskArr(arr);
    }
  }, [props.taskAction]);

  useEffect(() => {
    if (props.taskAction == "Edit") {
      if (props.task) {
        let cloneTask = { ...props.task };
        // cloneTask.description = cloneTask.description.replace('<div>', '');
        // cloneTask.description = cloneTask.description.replace('</div>', '');
        // cloneTask.description = cloneTask.description.replace('<br />', '')
        setMoveTaskObj({
          project_id: cloneTask.project_id,
          taskId: cloneTask.id_string,
          formTaskListId: cloneTask.tasklist.id_string,
        });
        setTaskData(cloneTask);
      }
      subtaskCollapseOpen();
      subtaskCollapseOpen();
      setDependencyDropdownData(props.DependencyDropdownData);
    } else {
      // setMoveTaskObj({ project_id: cloneTask.project_id})
      const obj = { ...taskSchema };
      if (props.taskType == "subtask") {
        obj.project_name = props.parentTaskObj.project_name;
        obj.tasklist = props.parentTaskObj.tasklist;
      }
      setTaskData(obj);
      subtaskCollapseOpen();
      setCheckList([]);
      setActivityList([]);
      setMergeActivity([]);
      setSubtaskList([]);
      setTaskCustumFieldList([]);
      setDependencyDropdownData(props.DependencyDropdownData);
      setStartAndDueDateLabel(taskSchema.StartDate, taskSchema.DueDate);
      setTaskCustumFieldList([]);
      setCommentsBody([]);
    }
    GetServiceList();
  }, [props.task, props.subtask, props.taskAction, props.taskType]);

  useEffect(() => {
    //getServiceRequestedData(props.dealObj);
    //  if(acceptedFiles!==null){
    if (acceptedFiles.length > 0) {
      handleFileAttachments(acceptedFiles);
    }

    //  }
  }, [acceptedFiles]);

  const GetServiceList = () => {
    const backendUrl = Config.backend_url + "workdrive";
    var config = {
      Type: "Get",
      //url: "https://www.zohoapis.com/crm/v2/Services_Requested",
      url: "https://www.zohoapis.com/crm/v2/Requested_Service",
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(backendUrl, { config: config })
      .then(function (res) {
        let arr = [];
        res.data.data.forEach((one) => {
          let obj = { label: one.Name, value: one.id };
          arr.push(obj);
        });
        setServicesRequested(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const optionsService = [
    { value: "PreliminaryResearch", label: "Preliminary Research" },
    { value: "PreliminaryLayout", label: "Preliminary Layout" },
    { value: "SiteAssessment", label: "Site Assessment" },
    { value: "Design", label: "Design" },
    { value: "Asbestos", label: "Asbestos" },
    { value: "Expediting", label: "Expediting" },
    { value: "Permitting", label: "Permitting" },
    { value: "Certification", label: "Certification" },
    { value: "ElectricalDiagram", label: "Electrica lDiagram" },
    { value: "SpecialInspection", label: "Sspecial Inspection" },
    { value: "ProgressInspection", label: "Progress Inspection" },
    { value: "EnergyInspection", label: "Energy Inspection" },
    { value: "Interconnection", label: "Interconnection" },
    { value: "StructuralLetter", label: "Structural Letter" },
    { value: "PropertySurvey", label: "Property Survey" },
    { value: "NYSERDAFiling", label: "NYSERDA Filing" },
    { value: "PropertyTaxAbatement", label: "Property Tax Abatement" },
  ];
  const statusForWarn = ["IN PROGRESS", "COMPLETED"];
  const getEstimateDuration = (startDate, timeEstimate) => {
    let obj = { start: new Date(startDate), end: new Date(startDate) };
    //obj.start.setMilliseconds(obj.start.getMilliseconds()+1);
    timeEstimate = timeEstimate.split(":");
    obj.end.setHours(obj.end.getHours() + parseInt(timeEstimate[0]));
    obj.end.setMinutes(obj.end.getMinutes() + parseInt(timeEstimate[1]));
    return obj;
  };
  const addTimeTrackForStatusUpdate = (data, value, estimate1) => {
    if (value.name == "COMPLETED") {
      if (data.TimeTrack.length == 0) {
        const timeTrackObj = {
          ID: uuidv4(),
          UserID: localStorage.getItem("UserId"),
          InputBy: "Timer",
          current: false,
          StartTime: estimate1.start,
          StopTime: new Date(Date.now()),
        };
        data.TimeTrack.push(timeTrackObj);
        data.Status = value;
      } else if (data.TimeTrack.filter((one) => one.current).length > 0) {
        let obj = data.TimeTrack.filter((one) => one.current)[0];
        obj.current = false;
        obj.StopTime = new Date(Date.now());
        data.TimeTrack.filter((one) => one.current)[0] = obj;
      } else {
        const timeTrackObj = {
          ID: uuidv4(),
          UserID: localStorage.getItem("UserId"),
          InputBy: "Timer",
          current: false,
          StartTime: estimate1.start,
          StopTime: new Date(Date.now()),
        };
        data.TimeTrack.push(timeTrackObj);
        data.Status = value;
      }
    } else {
      const timeTrackObj = {
        ID: uuidv4(),
        UserID: localStorage.getItem("UserId"),
        InputBy: "Timer",
        current: true,
        StartTime: estimate1.start,
        StopTime: new Date(Date.now()),
      };
      data.TimeTrack.push(timeTrackObj);
      data.Status = value;
    }
    return data;
  };
  const getValues = () => {
    let serviceVar = [...serviceReq];
    let values = [];
    serviceVar.forEach((one) => {
      let objKey = Object.keys(one);
      if (one[objKey[0]] == "Yes") {
        let obj = {};
        let filterLable = optionsService.filter(
          (one) => one.value == objKey[0]
        );
        obj.label = filterLable[0].label;
        obj.value = objKey[0];
        values.push(obj);
      }
    });
    return values;
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  const [categoryList, setCategoryList] = useState([]);
  const getAllCategory = async () => {
    const backendUrl = Config.backend_url;
    var clients = [];
    let returnData = await axios
      .get(backendUrl + "getAllCategories")
      .then((res) => {
        if (res.data.length > 0) {
          setCategoryList(res.data);
        }
      });
  };
  const serviceReqVariable = [
    { PreliminaryResearch: "No" },
    { PreliminaryLayout: "No" },
    { SiteAssessment: "No" },
    { Design: "No" },
    { Asbestos: "No" },
    { Expediting: "No" },
    { Permitting: "No" },
    { Certification: "No" },
    { ElectricalDiagram: "No" },
    { SpecialInspection: "No" },
    { ProgressInspection: "No" },
    { EnergyInspection: "No" },
    { Interconnection: "No" },
    { StructuralLetter: "No" },
    { PropertySurvey: "No" },
    { NYSERDAFiling: "No" },
    { PropertyTaxAbatement: "No" },
  ];

  const [serviceReq, setServiceRequested] = useState(serviceReqVariable);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [AttachedFiles, setAttachedFiles] = useState([]);

  const handleUploadFileWorkDrive = (fileObj) => {
    return new Promise((resolve, reject) => {
      if (relativePathId) {
        var config = {
          Type: "Upload",
          data: {
            filename: fileObj.fileName,
            content: fileObj.file,
            parent_id: relativePathId,
          },
          url: "https://www.zohoapis.com/workdrive/api/v1/upload",
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
        axios
          .post(backendUrl + "workdrive", { config: config })
          .then(async function (response) {
            ActivitieService.AddActivityLocal(
              "Workdrive",
              { DealId: props.dealObj.Project_ID },
              {
                action_type: "Upload",
                data_type: "File",
                data: {
                  name: fileObj.fileName,
                  path: relativeFolderId,
                  pathId: relativePathId,
                },
              }
            );
            resolve(response);
            console.log(
              "-------------------------files data-----------------------------------"
            );
          })
          .catch(function (error) {
            reject(error);
          });
      }
    });
  };

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...fileNames];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      let base64 = await convertToBase64(fileList[index]);
      tempFileCollection.push({
        fileName: fileName,
        file: base64,
      });
      index = index + 1;
    }
    setFileNames(tempFileCollection);
    // let doArr = []
    // let fileUploadArr = []
    // let names = []
    // tempFileCollection.forEach((one) => {
    //   names.push(one.fileName);
    //   doArr.push(addZohoTaskAttachements(props.dealObj.Project_ID, taskData.id_string, one))
    //   fileUploadArr.push(handleUploadFileWorkDrive(one))
    // })

    // let text = `You upload ${tempFileCollection.length} files `
    // let filename = names.join(' , ')
    // swal({
    //   title: "Are you sure?",
    //   text: text + filename,
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {
    //     setLoader(true);
    //     Promise.all(doArr)
    //       .then((result) => {
    //         console.log(result);
    //         return Promise.all(fileUploadArr)
    //       })
    //       .then((result) => {
    //         setLoader(false);
    //         alert("file upload successfully");
    //         setAttachmentModal(false)
    //         console.log(result);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //   }
    // })
  };

  const [DealsId, setDealsId] = useState("");
  useEffect(() => {
    if (props.dealsId) {
      setDealsId(props.dealsId);
    }
  }, [props.dealsId]);

  useEffect(() => {
    //  getallTasks();
    getAllUsers();
  }, [props.dealsId]);

  useEffect(() => {
    // getallTasks();
    getAllUsers();
    getallclients();
  }, [props.task]);

  const [clientData, setClientData] = useState([]);

  const getallclients = async () => {
    // let returnData = await HttpService.getByBoj('getallclients', {}).then(res => {
    //   console.log(res.data);
    //   setClientData(res.data)
    // });

    const backendUrl = Config.backend_url + "workdrive";

    var config = {
      Type: "Get",

      url: "https://www.zohoapis.com/crm/v2/Accounts",
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    httpService
      .post("workdrive", { config: { ...config } })
      .then((res) => {
        setClientData(res.data.data);
      })
      .catch(function (error) {
        console.log("error in accounts", error);
      });
  };

  const [dependencySuccesseeDrop, setDependencySuccesseeDrop] = useState(false);
  const dependencySuccesseToggle = () =>
    setDependencySuccesseeDrop((prevState) => !prevState);

  const [dependencyDrop, setDependencyDrop] = useState(false);
  const dependencyToggle = () => setDependencyDrop((prevState) => !prevState);

  const [dependencyEditDrop, setDependencyEditDrop] = useState(false);
  const dependencyEditToggle = () =>
    setDependencyEditDrop((prevState) => !prevState);

  const [allTaskList, setallTaskList] = useState([]);
  const dependencyType = ["Waiting-On", "Blocking"];
  const [DependencyDropdownData, setDependencyDropdownData] = useState([]);
  const [taskDependency, setTaskDependency] = useState([]);

  const dependencyTypeZoho = [
    { name: "Waiting-On", value: "Waiting-On" },
    { name: "Blocking", value: "Blocking" },
  ];

  const [DependencyType, setDependencyType] = useState("");

  const opentasktoggle = () => setOpenTaskPopup(!openTaskPopup);
  const [openTaskPopup, setOpenTaskPopup] = useState(false);
  const addTasktoggle = () => setAddTaskPopup(!addTaskPopup);
  const [addTaskPopup, setAddTaskPopup] = useState(false);
  const filtertasktoggle = () => setFilterTaskPopup(!filterTaskPopup);
  const [filterTaskPopup, setFilterTaskPopup] = useState(false);
  const secondPlustoggle = () => setsecondPlusPopup(!secondPlusPopup);
  const [secondPlusPopup, setsecondPlusPopup] = useState(false);
  const firstPlustoggle = () => setfirstPlusPopup(!firstPlusPopup);
  const [firstPlusPopup, setfirstPlusPopup] = useState(false);
  const settingtasktoggle = () => setsettingtaskPopup(!settingTaskPopup);
  const [settingTaskPopup, setsettingtaskPopup] = useState(false);
  const [nestedModal1, setNestedModal1] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [taskArrow, setTaskArrow] = useState("");
  const [backdrop, setBackdrop] = useState("static");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [subtaskStatusDrop, setSubTaskStatusDrop] = useState(false);
  const [categoryDrop, setCategoryDrop] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [timeEstimateValue, setTimeEstimateValue] = useState("");
  const [timeMinuteValue, setTimeMinuteValue] = useState("");
  const [subtaskName, setSubtaskName] = useState("");
  const [description, setDescription] = useState("");
  const [datePickerIsOpen, setdatePickerIsOpen] = useState(false);
  const [startDate, setstartDate] = useState(new Date());
  const [defaultDependencyStatus, setDefaultDependencyStatus] = useState([
    { value: "PENDING", label: "PENDING", color: "#e50000" },
    { value: "NOT STARTED", label: "NOT STARTED", color: "#f8d800" },
    { value: "IN PROGRESS", label: "IN PROGRESS", color: "#2ecd6f" },
    { value: "COMPLETED", label: "COMPLETED", color: "#f900ea" },
    { value: "NOT REQUIRED", label: "NOT REQUIRED", color: "#0231e8" },
  ]);

  const [
    selectedDependencyStatusBlocking,
    setSelectedDependencyStatusBlocking,
  ] = useState([
    { value: "IN PROGRESS", label: "IN PROGRESS", color: "#2ecd6f" },
    { value: "COMPLETED", label: "COMPLETED", color: "#f900ea" },
  ]);

  const [selectedDependencyStatusWaiting, setSelectedDependencyStatusWaiting] =
    useState([
      { value: "IN PROGRESS", label: "IN PROGRESS", color: "#2ecd6f" },
      { value: "COMPLETED", label: "COMPLETED", color: "#f900ea" },
    ]);

  const [dependencyStatusBlocking, setDependencyStatusBlocking] = useState([
    { value: "PENDING", label: "PENDING", color: "#e50000" },
    { value: "NOT STARTED", label: "NOT STARTED", color: "#e50000" },
    { value: "NOT REQUIRED", label: "NOT REQUIRED", color: "#e50000" },
  ]);
  const [dependencyStatusWaiting, setDependencyStatusWaiting] = useState([
    { value: "IN PROGRESS", label: "IN PROGRESS", color: "#2ecd6f" },
    { value: "COMPLETED", label: "COMPLETED", color: "#f900ea" },
  ]);
  const [groupBy, setGroupBy] = useState("Category");
  const [projectDate, setProjectDate] = useState(
    Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date())
  );
  const [taskStatus, setTaskStatus] = useState({
    name: "PENDING",
    color: "#e50000",
  });
  const [subtaskStatus, setSubtaskStatus] = useState({
    name: "PENDING",
    color: "#e50000",
  });
  const [taskCategory, setTaskCategory] = useState("DESIGN");
  const categoryValues = ["DESIGN", "FILING", "MISCELLANEOUS"];
  const statusValues = [
    { name: "PENDING", color: "#e50000" },
    { name: "NOT STARTED", color: "#f8d800" },
    { name: "IN PROGRESS", color: "#2ecd6f" },
    { name: "COMPLETED", color: "#f900ea" },
    { name: "NOT REQUIRED", color: "#0231e8" },
  ];
  const priorityValues = [
    { name: "None", color: "lightgray" },
    { name: "High", color: "#e50000" },
    { name: "Medium", color: "#f7c700" },
    { name: "Low", color: "blue" },
  ];

  const taskExtraItems = [
    { name: "Duplicate", id: "duplicate", label1: "Duplicate" },
    { name: "Template", id: "template", label1: "Save As Template" },
    { name: "Copy Link", id: "copylink", label1: "Copy Link" },
    { name: "Log a Call", id: "logCall", label1: "Log a Call" },
    { name: "Log an Email", id: "logEmail", label1: "Log an Email" },
    { name: "Log a Meeting", id: "logMeeting", label1: "Log a Meeting" },
    // { name: 'Reminder', id: 'reminder', label1: 'Add Reminder' },
    { name: "Relative", id: "relative", label1: "Add Relative Path" },
    // {name: 'Log an Event', id: 'logEvent', label1: 'Log an Event'}
  ];
  const [allTasks, setAllTasks] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [cacheUsers, setCacheUsers] = useState([]);
  const [groupByField, setGroupByField] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState({});
  const [selectedSubTaskId, setselectedSubTaskId] = useState({});
  const [newTask, seNewTaskState] = useState(true);
  const [assigneDropState, setAssigneDropState] = useState([]);
  const [subtaskAssignee, setSubtaskAssignee] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [subTaskTimeHr, setSubTaskTimeHr] = useState("");

  const [popoverOpen, setPopoverOpen] = useState(false);
  const addviewtoggle = () => setPopoverOpen(!popoverOpen);

  const updateViewToggle = () => {
    setSelectedTaskId("");
    if (openTimeEstimatePopUp) {
      setOpenTimeEstimatePopUp(false);
    }
  };

  const updateSubViewToggle = () => {
    setSelectedTaskId("");
    if (openTimeEstimatePopUp) {
      setOpenTimeEstimatePopUp(false);
    }
  };

  const updateMetaToggle = () => {
    setSelectedTaskId("");
    if (openTimeEstimatePopUp) {
      setOpenTimeEstimatePopUp(false);
    }
  };

  const isVisibleTableColumn = (name) => {
    let filterColumn = addColumns.filter((one) => one.headerName == name);
    if (filterColumn.length > 0) {
      return filterColumn[0].isVisible;
    }
    return true;
  };

  const [addColumns, setAddColumns] = useState([
    { headerName: "ASSIGNEE", isVisible: true },
    { headerName: "DUE DATE", isVisible: true },
    { headerName: "PRIORITY", isVisible: true },
    { headerName: "TIME ESTIMATE", isVisible: true },
    { headerName: "PROGRESS", isVisible: true },
    { headerName: "STATUS", isVisible: true },
    { headerName: "CATEGORY", isVisible: true },
  ]);

  const handleOnChangeAddColumn = (name, checked) => {
    const columns = [...addColumns];
    columns.filter((one) => one.headerName == name)[0].isVisible = checked;
    setAddColumns(columns);
  };

  const [timetrackedPopover, settimetrackedPopover] = useState(false);
  const timetrackedtoggle = () => settimetrackedPopover(!timetrackedPopover);
  const [key, setKey] = useState("timer");

  const [priorityValue, setPriorityValue] = useState({
    name: "",
    color: "#000",
  });
  const [subtaskPriority, setSubtaskPriority] = useState({
    name: "",
    color: "#000",
  });
  const [assigneDropToggle, setAssigneDropToggle] = useState(false);
  const [subtaskAssigneDrop, setSubtaskAssigneDrop] = useState(false);
  const [priorityDropToggle, setPriorityDropToggle] = useState(false);
  const [taskExtraOpsToggle, setTaskExtraOpsToggle] = useState(false);
  const [subtaskPriorityDrop, setSubtaskPriorityDrop] = useState(false);
  const [subtaskContainer, toggleSubtaskContainer] = useState(false);
  const [updateSubtaskId, setUpdateSubtaskId] = useState("");
  const [openmodalType, setOpenModalType] = useState("task");
  const [selectdTimeEstimate, setSelectdTimeEstimate] = useState("");
  const [seelectedMinEstimate, setSelectedMinEstimate] = useState("");
  const [subTaskArrow, setSubTaskArrow] = useState("");

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const setStartAndDueDateLabel = (StartDate, DueDate) => {
    let obj = {
      startDate: new Date(StartDate),
      endDate: new Date(DueDate),
      key: "selection",
    };
    setSelectionRange([obj]);
    setDateDisplay(obj);
  };

  const [DisplayStartDueDate, setDisplayStartDueDate] = useState("");

  const setDateDisplay = (selection) => {
    let mm = selection.startDate.getMonth() + 1; //months from 1-12
    let dd = selection.startDate.getDate();
    let yy = selection.startDate.getFullYear();

    dd = dd < 10 ? "0" + dd : dd;
    mm = mm < 10 ? "0" + mm : mm;

    let display = mm + "/" + dd + "/" + yy;
    display = display + " " + getTime(selection.startDate);

    mm = selection.endDate.getMonth() + 1; //months from 1-12
    dd = selection.endDate.getDate();
    yy = selection.endDate.getFullYear();

    dd = dd < 10 ? "0" + dd : dd;
    mm = mm < 10 ? "0" + mm : mm;

    display =
      display +
      " - " +
      mm +
      "/" +
      dd +
      "/" +
      yy +
      " " +
      getTime(selection.endDate);
    setDisplayStartDueDate(display);
    return display;
  };
  const changeDateRange = async (item) => {
    let taskObj = { ...taskData };
    let startDate = new Date(item.selection.startDate);
    let endDate = new Date(item.selection.endDate);
    startDate = dayjs(startDate).format("MM-DD-YYYY");
    endDate = dayjs(endDate).format("MM-DD-YYYY");
    if (props.taskAction == "Create") {
      let obj = { ...taskData };
      obj["start_date_format"] = startDate;
      obj["start_date"] = startDate;
      obj["end_date_format"] = endDate;
      obj["end_date"] = endDate;
      setTaskData(obj);
    } else {
      await updateTask(taskObj.id_string, "start_date", startDate);
      await updateTask(taskObj.id_string, "end_date", endDate);
    }

    setSelectionRange([item.selection]);
    setDateDisplay(item.selection);
  };

  const changeTimeRange = (e) => {
    let taskObj = { ...taskData };
    setStartDateTime(e.currentTarget.value);
    // let startTimeVal = getTime(new Date(taskObj.start_date_long));
    // console.log(startTimeVal)
    // let time = e.currentTarget.value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [e.currentTarget.value];

    // if (time.length > 1) { // If time format correct
    //   time = time.slice(1);  // Remove full string match value
    //   time[5] = + time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
    //   // h < 10 ? "0" + h : h
    //   time[0] = + time[0] % 12 || 12; // Adjust hours
    // }
    // time[0] = time[0] < 10 ? "0" + time[0] : time[0];
    // time = time.join('');

    // let time = fomartTimeShow(e.currentTarget.value)
    // let startTime = getTime(new Date(e.currentTarget.value));
    updateTask(taskObj.id_string, "start_time", e.currentTarget.value);
    // updateTask(taskObj.id_string, 'end_time', endDateTime);
  };

  const changeEndTimeRange = (e) => {
    let taskObj = { ...taskData };
    setEndTime(e.currentTarget.value);
    let startTimeVal = getTime(new Date(taskObj.start_date_long));
    console.log(startTimeVal);
    let time = e.currentTarget.value
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
      e.currentTarget.value,
    ];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
      // h < 10 ? "0" + h : h
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    time[0] = time[0] < 10 ? "0" + time[0] : time[0];
    time = time.join("");

    // let endTimeVal = getTime(new Date(taskObj.end_date_long));
    // console.log(endTimeVal)
    // let endDateTime = e.currentTarget.value
    // updateTask(taskObj.id_string, 'start_time', startTime);
    updateTask(taskObj.id_string, "end_time", time);
  };
  const [newTaskData, setNewTaskData] = useState(null);
  const [newSubTask, setNewSubTask] = useState(false);
  const [newMetaTask, setNewMetaTask] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [logData, setLogData] = useState([]);

  const [mergeActivity, setMergeActivity] = useState([]);

  const getFilterActivity = (list) => {
    list = [...list];
    if (activityFilterBy !== "All") {
      list = list.filter((one) => {
        if (one.ActivityType == "Comment" && activityFilterBy == "Comments") {
          return one;
        }
        if (
          activityFilterBy == "Status" &&
          one.change_info &&
          one.change_info.status
        ) {
          return one;
        }
        if (
          activityFilterBy == "Priority" &&
          one.change_info &&
          one.change_info.priority
        ) {
          return one;
        }
        if (
          activityFilterBy == "Category" &&
          one.change_info &&
          one.change_info.customfield
        ) {
          return one;
        }
        if (
          activityFilterBy == "Start/Due Date" &&
          one.change_info &&
          one.change_info.stdate
        ) {
          return one;
        }
        if (
          activityFilterBy == "Assignee" &&
          one.change_info &&
          one.change_info.owner
        ) {
          return one;
        }
      });
    }

    return list;
  };

  const getAllActivityAsPerTask = (TaskId) => {
    TaskService.GetTaskActivity(taskData.project_id, TaskId)
      .then((result) => {
        result = result.filter((one) => one && one.state !== "Comment add");
        setActivityList(result);
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // getLogs();
    getAllDescription(taskData);
  }, []);

  useEffect(() => {
    if (
      (activityList && activityList.length > 0) ||
      (CommentsBody && CommentsBody.length > 0)
    ) {
      activityList.forEach((one) => {
        one.ActivityType = "Logs";
      });
      CommentsBody.forEach((one) => {
        one.ActivityType = "Comment";
        one.time_long = one.created_time_long;
      });
      let list = [...activityList, ...CommentsBody];
      // list = list.filter((one) => one.ActivityType == 'Comment')
      list = list.filter((one) => {
        if (one && one.state == "moveto") {
          return one;
        }
        if (
          (one && one.state == "custom_function_execution") ||
          (one.old_value && one.old_value.includes("#")) ||
          one.state == "businessrule" ||
          one.state == "Comment update" ||
          one.state == "Comment delete"
        ) {
        } else {
          return one;
        }
      });
      list = list.sort((a, b) => new Date(b.time_long) - new Date(a.time_long));
      setMergeActivity(list);
    }
  }, [activityList, CommentsBody, activityFlag]);

  // const getLogs = async () => {
  //   const backendUrl = "https://crm.srsolardesign.com:3006/api/v1/";
  //   let returnData = await HttpService.getByBoj('getAllLogs', {}).then(res => {
  //     console.log(res.data);
  //     setLogData(res.data)

  //   });
  // };

  const filterTaskLogs = () => {
    let taskLogs = logData.filter(
      (e) => e.TaskId !== null && e.TaskName !== null
    );
    return taskLogs;
  };

  const getStatementForLog = (one) => {
    return;
    if (one.DocumentType === "Call") {
      return <span>You Created Call Log</span>;
    }
    if (one.DocumentType === "Email") {
      return <span>You Created Email Log</span>;
    }
    if (one.DocumentType === "Meeting") {
      return <span>You Created Meeting Log</span>;
    }
  };

  const GetPriorityColor = (value) => {
    let values = [...priorityValues];
    values = values.filter((one) => one.name == value);
    if (values.length > 0) {
      return values[0].color;
    } else {
      return "gray";
    }
  };

  const getStatusContent = (dependent) => {
    if (dependent.Data_For == "Dependency") {
      let currentStatus = dependent.Current_Task_Status
        ? dependent.Current_Task_Status
        : [];
      let relatedStatus = dependent.Related_Task_Status
        ? dependent.Related_Task_Status
        : [];
      return (
        <div className="row">
          <div className="col-sm-12">
            <strong>Current Task Dependency Status : </strong>
            {currentStatus &&
              currentStatus.map((one) => {
                return <p>{one}</p>;
              })}
            <br></br>
            <strong>Related Task Dependency Status : </strong>
            {relatedStatus &&
              relatedStatus.map((one) => {
                return <p>{one}</p>;
              })}
          </div>
        </div>
      );
    } else {
      if (dependent.Type == "Predecessor") {
        let currentStatus = dependent.Current_Task_Status
          ? dependent.Current_Task_Status
          : [];
        let relatedStatus = taskLayout.status_details.filter(
          (one) => dependent.Dependent_Task_Status == one.id
        );
        return (
          <div className="row">
            <div className="col-sm-12">
              <strong>{taskData.name}</strong> automatically change in{" "}
              <strong>
                {relatedStatus.length > 0 ? relatedStatus[0].name : ""}
              </strong>
              <br /> when
              <strong> {dependent.Related_Task_Name}</strong> change status{" "}
              <strong>{dependent.Related_Task_Status.join(",")}</strong>
            </div>
          </div>
        );
      } else {
        let currentStatus = dependent.Current_Task_Status
          ? dependent.Current_Task_Status
          : [];
        let relatedStatus = taskLayout.status_details.filter(
          (one) => dependent.Dependent_Task_Status == one.id
        );
        relatedStatus = relatedStatus.length > 0 ? relatedStatus[0].name : "";
        return (
          <div className="row">
            <div className="col-sm-12">
              when <strong>{dependent.Current_Task_Name}</strong> change status
              to <strong>{dependent.Current_Task_Status.join(",")}</strong>
              <br /> then
              <strong> {dependent.Related_Task_Name}</strong> automatically
              change status <strong>{relatedStatus}</strong>
            </div>
          </div>
        );

        // <div className="row">
        //   <div className="col-sm-12">
        //     <strong>Current Task Status : </strong>
        //     {currentStatus && currentStatus.map((one) => {
        //       return <p >{one}</p>
        //     })}
        //     <br></br><strong>Change Related Task Status : </strong>
        //     {relatedStatus && relatedStatus.map((one) => {
        //       return <p >{one.name}</p>;
        //     })}
        //   </div>
        // </div>
      }
    }
  };

  const GetStatusColor = (value) => {
    if (taskLayout && taskLayout.status_details) {
      let values = [...taskLayout.status_details];
      values = values.filter((one) => one.name == value);
      if (values.length > 0) {
        return values[0].color;
      } else {
        return "gray";
      }
    }
  };

  const getStatementForTask = (one) => {
    //  return <span>You change {one.state } from {one.new_value}  to  {one.old_value}</span>
    let users = [...allUsers];
    users = users.filter((user) => user.zuid == one.zuid);
    let userId = "4695950000000296001";
    if (users.length > 0) {
      users = users[0];
      userId = users.id;
    }
    let returnValue = <></>;
    if (one.state === "moveto") {
      returnValue = (
        <span>
          <a
            onClick={() =>
              history.push({
                pathname: `/setting/users/${userId}/Users`,
                state: { tab: "Users" },
              })
            }
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {one.activity_by}
          </a>{" "}
          updated tasklist from {one.old_value.tasklist.name}&nbsp;to&nbsp;
          {one.new_value.tasklist.name} <p></p>
        </span>
      );
    }
    // if (one.state === "Comment add") {
    //   return returnValue;
    //   returnValue = <span><a onClick={() => history.push({ pathname: '/setting/users/4695950000000296001/Users', state: { tab: "Users" } })} style={{ cursor: 'pointer', color: '#1890ff' }}>{one.activity_by}</a> added Comment {one.name} </span>
    // }
    else if (one.state === "Description updated") {
      returnValue = (
        <span>
          <a
            onClick={() =>
              history.push({
                pathname: `/setting/users/${userId}/Users`,
                state: { tab: "Users" },
              })
            }
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {one.activity_by}
          </a>{" "}
          {one.state} from {one.old_value}&nbsp;to&nbsp;{one.new_value} <p></p>
        </span>
      );
    } else if (one.state === "Priority") {
      returnValue = (
        <span>
          <a
            onClick={() =>
              history.push({
                pathname: `/setting/users/${userId}/Users`,
                state: { tab: "Users" },
              })
            }
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {one.activity_by}
          </a>{" "}
          updated {one.state} from {one.old_value}{" "}
          <FontAwesomeIcon
            color={GetPriorityColor(one.old_value)}
            icon={faFlagSolid}
          />
          &nbsp;to&nbsp;{one.new_value}{" "}
          <FontAwesomeIcon
            color={GetPriorityColor(one.new_value)}
            icon={faFlagSolid}
          />{" "}
          <p></p>
        </span>
      );
    } else if (one.state === "Task Title") {
      returnValue = (
        <span>
          <a
            onClick={() =>
              history.push({
                pathname: `/setting/users/${userId}/Users`,
                state: { tab: "Users" },
              })
            }
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {one.activity_by}
          </a>{" "}
          updated {one.state} from {one.old_value}&nbsp;to&nbsp;{one.new_value}{" "}
          <p></p>
        </span>
      );
    } else if (one.state === "new") {
      returnValue = (
        <span>
          <a
            onClick={() =>
              history.push({
                pathname: `/setting/users/${userId}/Users`,
                state: { tab: "Users" },
              })
            }
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {one.activity_by}
          </a>{" "}
          added {one.name} <p>{one.affected_msg}</p>
        </span>
      );
    } else if (
      one.old_value &&
      one.old_value.tasklist &&
      one.old_value.tasklist.name
    ) {
      returnValue = (
        <span>
          <a
            onClick={() =>
              history.push({
                pathname: `/setting/users/${userId}/Users`,
                state: { tab: "Users" },
              })
            }
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {one.activity_by}
          </a>{" "}
          updated tasklist from {one.old_value.tasklist.name}&nbsp;to&nbsp;
          {one.new_value.tasklist.name} <p></p>
        </span>
      );
    } else if (one.change_info) {
      if (one.change_info.priority) {
        returnValue = (
          <span>
            <a
              onClick={() =>
                history.push({
                  pathname: `/setting/users/${userId}/Users`,
                  state: { tab: "Users" },
                })
              }
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              {one.activity_by}
            </a>{" "}
            updated Priority from {one.change_info.priority.old}{" "}
            <FontAwesomeIcon
              color={GetPriorityColor(one.change_info.priority.old)}
              icon={faFlagSolid}
            />
            &nbsp;to&nbsp;{one.change_info.priority.new}{" "}
            <FontAwesomeIcon
              color={GetPriorityColor(one.change_info.priority.new)}
              icon={faFlagSolid}
            />{" "}
            <p></p>
          </span>
        );
      } else if (one.change_info.status) {
        returnValue = (
          <span>
            <a
              onClick={() =>
                history.push({
                  pathname: `/setting/users/${userId}/Users`,
                  state: { tab: "Users" },
                })
              }
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              {one.activity_by}
            </a>{" "}
            updated Status from
            <span
              className="name-tasklist-label"
              style={{
                borderRadius: 5,
                padding: 3,
                backgroundColor: GetStatusColor(one.change_info.status.old),
                color: "white",
                marginLeft: 10,
              }}
            >
              {one.change_info.status.old}
            </span>
            &nbsp;to&nbsp;
            <div>
              <span
                className="name-tasklist-label"
                style={{
                  borderRadius: 5,
                  padding: 3,
                  backgroundColor: GetStatusColor(one.change_info.status.new),
                  color: "white",
                }}
              >
                {one.change_info.status.new}
              </span>
            </div>{" "}
            <p></p>
          </span>
        );
      } else if (one.change_info.customfield) {
        returnValue = one.change_info.customfield.map((obj) => {
          return (
            <span>
              <a
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${userId}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.activity_by}
              </a>{" "}
              updated {obj.fieldName} from {obj.old}&nbsp;to&nbsp;{obj.new}{" "}
              <p></p>
            </span>
          );
        });
      } else if (one.change_info.stdate) {
        return (
          <span>
            <a
              onClick={() =>
                history.push({
                  pathname: `/setting/users/${userId}/Users`,
                  state: { tab: "Users" },
                })
              }
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              {one.activity_by}
            </a>{" "}
            updated Start Date from {one.change_info.stdate.old}&nbsp;to&nbsp;
            {one.change_info.stdate.new}{" "}
          </span>
        );
      } else if (one.change_info.enddate) {
        return (
          <span>
            <a
              onClick={() =>
                history.push({
                  pathname: `/setting/users/${userId}/Users`,
                  state: { tab: "Users" },
                })
              }
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              {one.activity_by}
            </a>{" "}
            updated End Date from {one.change_info.enddate.old}&nbsp;to&nbsp;
            {one.change_info.enddate.new}{" "}
          </span>
        );
      } else if (one.change_info.owner) {
        let filterUserNew = allUsers.filter(
          (user) =>
            one.change_info &&
            one.change_info.owner &&
            user.full_name == one.change_info.owner.new
        );
        let filterUserOld = allUsers.filter(
          (user) =>
            one.change_info &&
            one.change_info.owner &&
            user.full_name == one.change_info.owner.old
        );
        if (filterUserNew.length > 0 && filterUserOld.length > 0) {
          return (
            <span>
              <a
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${userId}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.activity_by}
              </a>{" "}
              updated Assignee from{" "}
              <span
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${filterUserOld[0].id}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.change_info.owner.old}
              </span>
              &nbsp;to&nbsp;
              <span
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${filterUserNew[0].id}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.change_info.owner.new}
              </span>{" "}
            </span>
          );
        } else if (filterUserNew.length > 0) {
          return (
            <span>
              <a
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${userId}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.activity_by}
              </a>{" "}
              updated Assignee from {one.change_info.owner.old}&nbsp;to&nbsp;
              <span
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${filterUserNew[0].id}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.change_info.owner.new}
              </span>{" "}
            </span>
          );
        } else if (filterUserOld.length > 0) {
          return (
            <span>
              <a
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${userId}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.activity_by}
              </a>{" "}
              updated Assignee from{" "}
              <span
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${filterUserOld[0].id}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.change_info.owner.old}
              </span>
              &nbsp;to&nbsp;{one.change_info.owner.new}{" "}
            </span>
          );
        } else {
          return (
            <span>
              <a
                onClick={() =>
                  history.push({
                    pathname: `/setting/users/${userId}/Users`,
                    state: { tab: "Users" },
                  })
                }
                style={{ cursor: "pointer", color: "#1890ff" }}
              >
                {one.activity_by}
              </a>{" "}
              updated Assignee from {one.change_info.owner.old}&nbsp;to&nbsp;
              {one.change_info.owner.new}{" "}
            </span>
          );
        }
      } else {
        returnValue = (
          <span>
            <a
              onClick={() =>
                history.push({
                  pathname: `/setting/users/${userId}/Users`,
                  state: { tab: "Users" },
                })
              }
              style={{ cursor: "pointer", color: "#1890ff" }}
            >
              {one.activity_by}
            </a>{" "}
            added {one.name} <p></p>
          </span>
        );
      }
    } else {
      returnValue = (
        <span style={{ lineBreak: "anywhere" }}>
          <a
            onClick={() =>
              history.push({
                pathname: "/setting/users/4695950000000296001/Users",
                state: { tab: "Users" },
              })
            }
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {one.activity_by}
          </a>{" "}
          updated {one.state} from {one.old_value}&nbsp;to&nbsp;{one.new_value}{" "}
          <p></p>
        </span>
      );
    }
    return returnValue;
  };

  const saveUpdateSubTask = () => {
    var data = subTasks;
    const uniqueId = uuidv4();

    const subtask = {
      DealsId: DealsId,
      //SubtaskId: updateSubtaskId == "" ? uniqueId : updateSubtaskId,
      TaskName: subtaskName,
      Status: subtaskStatus,
      Priority: subtaskPriority,
      Assignee: subtaskAssignee,
      Description: "",
      StartDate: selectionRange[0].startDate,
      DueDate: selectionRange[0].endDate,
      TimeEstimateValue:
        updateSubtaskId == ""
          ? ":"
          : parseInt(timeEstimateValue) <= 0
          ? timeMinuteValue.toString()
          : timeEstimateValue + ":" + timeMinuteValue,
      TimeTrack: updateSubtaskId == "" ? [] : currentData,
      Checklist: updateSubtaskId == "" ? [] : currentCheckList,
      Subtask: [],
    };
    if (updateSubtaskId == "") {
      data.push(subtask);
    } else {
      data.forEach((e, i) => {
        if (e.SubtaskId == updateSubtaskId) {
          data[i] = subtask;
        }
      });
    }

    setNewSubTask(true);
    setNewTaskData(subtask);
    // addUpdateTaskApi(subtask);
    setOpenSubtaskPopup(false);
    saveUpdateTaskData(subtask);
    setSubTasks(data);
    resetSubtaskFields();
  };

  const getTymDetails = (estimateVal, taskEstHr, taskEstMin) => {
    if (estimateVal == ":") {
      return (
        <span className="hourglass-border">
          <FontAwesomeIcon icon={faHourglass} />
        </span>
      );
      //return "00h 00m";
    }
    if (
      (taskEstHr == "00" || taskEstHr == "0") &&
      (taskEstMin == "00" || taskEstMin == "0")
    ) {
      return (
        <span className="hourglass-border">
          <FontAwesomeIcon icon={faHourglass} />
        </span>
      );
    }
    if (taskEstHr == "00" || taskEstHr == "0") {
      return taskEstMin + "m";
    }
    if (taskEstMin == "00" || taskEstMin == "0") {
      return taskEstHr + "h";
    }

    // if (taskEstHr.length == 1) {
    //   taskEstHr = '0' + taskEstHr
    // }
    // if (taskEstMin.length == 1) {
    //   taskEstMin = '0' + taskEstMin
    // }

    return taskEstHr + "h " + taskEstMin + "m";
  };

  const getTimeEstimateValue = (timeEStimateVal) => {
    let obj = {};
    if (
      typeof timeEStimateVal !== "string" ||
      !timeEStimateVal instanceof String
    ) {
      timeEStimateVal = timeEStimateVal.toString();
    }
    if (timeEStimateVal.includes(":")) {
      let time = timeEStimateVal.split(":");
      obj.hrs = time[0];
      obj.mins = time[1];
    } else {
      obj.hrs = "00";
      obj.mins = timeEStimateVal;
    }

    return obj;
  };

  const setStateForUpdateSubtask = (e) => {
    setUpdateSubtaskId(e.SubtaskId);
    setSubtaskName(e.TaskName);
    setSubtaskStatus(e.Status);
    setSubtaskPriority(e.Priority);
    setSubtaskAssignee(e.Assignee);
    toggleSubtaskContainer(false);
  };
  const resetSubtaskFields = () => {
    setSubtaskName("");
    setSubtaskStatus({
      name: "PENDING",
      color: "#e50000",
    });
    setSubtaskPriority({
      name: "",
      color: "#000",
    });
    setSubtaskAssignee([]);
    setUpdateSubtaskId("");
  };

  const [taskDocs, setTaskDocs] = useState([]);

  const getTaskDocs = async (taskName, taskId) => {
    let duplicate = {
      TaskName: "",
      Everything: true,
      Attachements: true,
      Checklist: true,
      Comments: true,
      InputFields: true,
      LookUpFields: true,
      Dependencies: true,
      DueDate: true,
      Assignee: true,
      TimeEstimate: true,
      Priority: true,
      Status: true,
      Subtask: true,
    };
    try {
      let res = await HttpService.getByBoj("gettaskdocs", {
        DealsId: props.dealsId,
      });
      if (res.data.Data.length > 0) {
        let filterCopyTasks = res.data.Data.filter(
          (one) => one.TaskCopyOf == taskId
        );
        setCopyTasks(filterCopyTasks);
        setTaskDocs(res.data.Data);
        duplicate.TaskName =
          taskName + " - Copy" + (filterCopyTasks.length + 1);
        setDuplicateValueObj(duplicate);
      }
    } catch (err) {
      swal(`${err}`);
    }
  };

  const getallTasks = async () => {
    let returnData = await HttpService.getByBoj("gettask", {
      DealsId: props.dealsId,
    }).then(async (res) => {
      if (res.data.length > 0 && res.data != "unsuccesfull") {
        //res.data.forEach((one)=>one.Subtask=[])
        setAllTasks(res.data);
        if (parentObj) {
          let res = await HttpService.getByBoj("gettaskdocs", {
            DealsId: props.dealsId,
          });
          if (res.data.Data.length > 0) {
            let filterCopyTasks = taskDocs.filter(
              (one) => one.TaskCopyOf == parentObj._id
            );
            setCopyTasks(filterCopyTasks);
            setTaskDocs(res.data.Data);
            let obj = res.data.Data.filter((one) => one._id == parentObj._id);
            obj = { ...obj[0] };
            setParentObject(obj);
          }
        } else {
          let res = await HttpService.getByBoj("gettaskdocs", {
            DealsId: props.dealsId,
          });
          setTaskDocs(res.data.Data);
        }
        TaskIcons(res.data);
        getGroupByFields(res.data, groupBy);

        let t = [];
        res.data.forEach((task, i) => {
          t.push({
            id: task._id,
            name: task.TaskName,
            status: task.Status,
          });
          // if (task.Subtask.length > 0) {
          //   //foreach//push
          //   task.Subtask.forEach((subTask, subIndx) => {
          //     t.push({
          //       id: subTask.SubtaskId,
          //       name: subTask.TaskName,
          //       status: subTask.Status,
          //     });
          //     if (subTask.Subtask.length > 0) {
          //       subTask.Subtask.forEach((metaTask, metaIndx) => {
          //         t.push({
          //           id: metaTask.SubtaskId,
          //           name: metaTask.TaskName,
          //           status: metaTask.Status,
          //         });
          //       });
          //     }
          //   });
          // }
        });
        setallTaskList(t);
      }
    });
  };

  const getAllUsers = async () => {
    UserService.GetAllUsers()
      .then((res) => {
        if (res.data.users.length > 0 && res.data.users.length != null) {
          usersDetail = res.data.users;
          setAllUsers(res.data.users.filter((one) => one.status == "active"));
          setCacheUsers(res.data.users.filter((one) => one.status == "active"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onlyTypeNumber = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setTimeEstimateValue(value);
    }
  };

  const onlyAcceptNumbr = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setTimeMinuteValue(value);
    }
  };

  function min2hrConverstion(mins) {
    let num = parseInt(mins);
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    let obj = {
      hrs: rhours,
      mint: rminutes,
    };
    return obj;
  }

  const metaEnterTimeEstimate = (
    event,
    operationType,
    taskData,
    subTask,
    metaTask
  ) => {
    if (event.key === "Enter") {
      //
      let obj = {};
      let defaultHrEstimate = 0;
      if (timeMinuteValue !== "") {
        obj = min2hrConverstion(timeMinuteValue);
        if (timeEstimateValue === "") {
          obj.hrs = parseInt(obj.hrs) + parseInt(defaultHrEstimate);
          setTimeEstimateValue(obj.hrs);
        } else {
          obj.hrs = parseInt(obj.hrs) + parseInt(timeEstimateValue);
          setTimeEstimateValue(obj.hrs);
        }
        setTimeMinuteValue(obj.mint);
      } else {
        setTimeEstimateValue(timeEstimateValue);
        setTimeMinuteValue(timeMinuteValue);
      }
      if (timeMinuteValue === "") {
        setTimeMinuteValue("00");
      }

      if (operationType === "updateList") {
        taskData.Subtask.forEach((updtd, i) => {
          if (updtd.SubtaskId === subTask.SubtaskId) {
            updtd.Subtask.forEach((meta) => {
              if (meta.SubtaskId === metaTask.SubtaskId) {
                // meta.TimeEstimateValue = obj.hrs+":"+obj.mint;
                meta.TimeEstimateValue =
                  parseInt(obj.hrs) <= 0
                    ? obj.hrs + ":" + obj.mint
                    : obj.hrs + ":" + obj.mint;
              }
            });
          }
        });
        setOpenTimeEstimatePopUp(false);

        updateTask(metaTask);
      }
      setSelectedTaskId("");
      setPopoverOpen(!popoverOpen);
    }
  };

  const subTaskEnterTimeEstimate = (
    event,
    operationType,
    taskData,
    subTask
  ) => {
    if (event.key === "Enter") {
      let obj = {};
      let defaultHrEstimate = 0;
      if (timeMinuteValue !== "") {
        obj = min2hrConverstion(timeMinuteValue);
        if (timeEstimateValue === "") {
          obj.hrs = parseInt(obj.hrs) + parseInt(defaultHrEstimate);
          setTimeEstimateValue(obj.hrs);
        } else {
          obj.hrs = parseInt(obj.hrs) + parseInt(timeEstimateValue);
          setTimeEstimateValue(obj.hrs);
        }
        setTimeMinuteValue(obj.mint);
      } else {
        setTimeEstimateValue(timeEstimateValue);
        setTimeMinuteValue(timeMinuteValue);
      }
      if (timeMinuteValue === "") {
        setTimeMinuteValue("00");
      }

      if (operationType === "updateList") {
        taskData.Subtask.forEach((updtd) => {
          if (updtd.SubtaskId === subTask.SubtaskId) {
            updtd.TimeEstimateValue =
              parseInt(obj.hrs) <= 0
                ? obj.hrs + ":" + obj.mint
                : obj.hrs + ":" + obj.mint;
          }
        });

        updateTask(subTask);
        setOpenTimeEstimatePopUp(false);
      }

      setSelectedTaskId("");
      setPopoverOpen(!popoverOpen);
    }
  };

  function tymIsEmptyOrNull(timeEstimateValue, timeMinuteValue) {
    let flag = false;
    if (
      timeMinuteValue === "" &&
      (timeEstimateValue === "" || timeEstimateValue === ":")
    ) {
      flag = true;
    }
    // if( obj.mint !== undefined  || obj.hrs !== undefined){
    //   flag = true;
    // }
    return flag;
  }

  const enterTimeEstimate = (e, operationType) => {
    if (e.key === "Enter") {
      let taskObj = { ...taskData };
      let obj = {};
      let timeObj = {};
      timeObj.PrevState = taskObj.TimeEstimateValue;
      taskObj.TimeEstimateValue = timeEstimateValue + ":" + timeMinuteValue;
      timeObj.UpdatedState = timeEstimateValue + ":" + timeMinuteValue;

      let defaultHrEstimate = 0;
      if (timeMinuteValue !== "" || timeEstimateValue !== "") {
        if (timeMinuteValue == "") {
          obj = min2hrConverstion(0);
        } else {
          obj = min2hrConverstion(timeMinuteValue);
        }
        if (timeEstimateValue === "") {
          obj.hrs = parseInt(obj.hrs) + parseInt(defaultHrEstimate);
          setTimeEstimateValue(obj.hrs);
        } else {
          obj.hrs = parseInt(obj.hrs) + parseInt(timeEstimateValue);
          setTimeEstimateValue(obj.hrs);
        }
        setTimeMinuteValue(obj.mint);
      } else {
        setTimeEstimateValue(timeEstimateValue);
        setTimeMinuteValue(timeMinuteValue);
      }
      if (timeMinuteValue === "") {
        setTimeMinuteValue("00");
      }
      if (operationType === "popupView") {
        if (!tymIsEmptyOrNull(timeEstimateValue, timeMinuteValue)) {
          taskObj.TimeEstimateValue =
            parseInt(obj.hrs) <= 0
              ? obj.hrs + ":" + obj.mint
              : obj.hrs + ":" + obj.mint;

          setOpenTimeEstimatePopUp(false);
          updateTask(
            taskData.id_string,
            "custom_fields",
            JSON.stringify({ UDF_CHAR10: taskObj.TimeEstimateValue })
          );
        } else {
          taskObj.TimeEstimateValue = ":";
          setOpenTimeEstimatePopUp(false);
          updateTask(
            taskData.id_string,
            "custom_fields",
            JSON.stringify({ UDF_CHAR10: taskObj.TimeEstimateValue })
          );
        }
      }

      setSelectedTaskId("");
      setPopoverOpen(!popoverOpen);
    }
  };
  const [GroupIcons, setGroupIcons] = useState([]);
  const getGroupByFields = (tasks, groupBy) => {
    var unique = [];
    if (groupBy == "Priority" || groupBy == "Status") {
      unique = [...new Set(tasks.map((task) => task[groupBy].name))];
    } else {
      unique = [...new Set(tasks.map((task) => task[groupBy]))];
    }
    setGroupByField(unique);
    //set group icons first time
    let gicons = [];
    unique.forEach((e, i) => {
      if (e) {
        gicons.push({
          ID: e.replace(/ /g, "") + DealsId,
          isOpen: true,
        });
      }
    });
    setGroupIcons(gicons);
  };
  const getReplyData = (noteID) => {
    let filterComments = allData.filter((one) => {
      if (one.content.text && one.content.text.includes("reply_")) {
        return one;
      }
      // one.content.text.includes("comment_")
    });
    let commentsPerId = filterComments.filter((one) => {
      let id = one.replied_to.id;
      id = id.split("%");
      let firstId = id[0];
      let secondId = id[1];
      secondId = secondId.replace("20", "");
      noteID = noteID.replace("_", "");
      if (firstId + secondId == noteID) {
        return one;
      }
    });
    console.log(commentsPerId);
    return commentsPerId;
  };

  const toggleGroupIcon = (gID) => {
    let gicons = [...GroupIcons];
    GroupIcons.forEach((e, i) => {
      if (e.ID == gID) {
        gicons[i] = {
          ID: gID,
          isOpen: !e.isOpen,
        };
      }
    });
    setGroupIcons(gicons);
  };

  const getDynamicFieldIcon = (type) => {
    switch (type) {
      case "Dropdown":
        return (
          <FontAwesomeIcon className="field-icon" icon={faCaretSquareDown} />
        );
        break;
      case "Date":
        return <FontAwesomeIcon className="field-icon" icon={faCalendar} />;
        break;
      case "File":
        return <FontAwesomeIcon className="field-icon" icon={faFile} />;
        break;
      case "Textarea":
        return <FontAwesomeIcon className="field-icon" icon={faTextHeight} />;
        break;
      case "Text":
        return <FontAwesomeIcon className="field-icon" icon={faItalic} />;
        break;
      case "Radio":
        return <FontAwesomeIcon className="field-icon" icon={faDotCircle} />;
        break;
      case "Label":
        return <FontAwesomeIcon className="field-icon" icon={faTags} />;
        break;
      case "Number":
        return <FontAwesomeIcon className="field-icon" icon={faHashtag} />;
        break;
    }
  };

  const getGroupIcon = (gID) => {
    let obj = GroupIcons.find((e) => e.ID == gID);
    if (obj != undefined) {
      return obj.isOpen;
    } else {
      return true;
    }
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const subtaskStatusToggle = () =>
    setSubTaskStatusDrop((prevState) => !prevState);
  const categoryToggle = () => setCategoryDrop((prevState) => !prevState);

  const nextStatusHandle = () => {
    taskLayout &&
      taskLayout.status_details.map(async (value, i) => {
        if (value.name === (taskData ? taskData.status.name : "")) {
          let status = await checkCheckTaskStatus(
            taskData,
            statusValues[i + 1]
          );

          if (status) {
            setParentToSubtaskStatusCompleteWar(true);
          } else {
            setStartDateOnCompleted(statusValues[i + 1], prepareTaskData());
            setTaskStatus(statusValues[i + 1]);
            handleOnChangeTask({
              target: { name: "Status", value: statusValues[i + 1] },
            });
            addActivity(
              "Update",
              "Status",
              taskData._id,
              taskData.TaskName,
              0,
              { PrevStatus: taskStatus, UpdatedState: statusValues[i + 1] }
            );
          }
        }
      });
  };

  // const nextStatusHandle = async () => {

  //   for(let i=0;i<statusValues.length;i++){
  //     if (
  //       statusValues[i].name !==(taskData ? taskData.Status.name : '')
  //     ) {
  //       let status=await checkCheckTaskStatus(taskData,statusValues[i])
  //       if(status){
  //         setTaskCompleteWarning(true);
  //         break;
  //       }else{
  //        // setStartDateOnCompleted(statusValues[i + 1], prepareTaskData());
  //         setTaskStatus(statusValues[i]);
  //         handleOnChangeTask({ target: { name: 'Status', value: statusValues[i] } });
  //         addActivity("Update", "Status", taskData._id, taskData.TaskName, 0, { PrevStatus: taskStatus, UpdatedState: statusValues[i] });
  //         break;
  //       }

  //     }
  //   }

  //   statusValues.map((value, i) => {

  //   });
  // };

  const [TaskCollapseIcon, setTaskCollapseIcon] = useState([]);
  const [SubTaskCollapseIcon, setSubTaskCollapseIcon] = useState([]);
  const [Flag, setFlag] = useState(false);

  const [dropOpen, setDropOpen] = useState(false);

  const [depedencyDropToggle, setDepedencyDropToggle] = useState(false);
  const dependencyDropToggleFun = () => {
    setDepedencyDropToggle(!depedencyDropToggle);
  };

  const [AttachdropOpen, setAttachDropOpen] = useState(false);

  const dropToggle = () => {
    setDropOpen(!dropOpen);
  };

  const AttachdropToggle = () => {
    setAttachDropOpen(!AttachdropOpen);
  };

  const TaskIcons = (tasks) => {
    let t1 = [];
    let subt1 = [];
    tasks.forEach((e, i) => {
      t1.push({
        ID: e._id,
        taskArrow: faCaretDown,
      });
      if (e.Subtask.length > 0) {
        e.Subtask.forEach((sub, j) => {
          subt1.push({
            ID: sub.SubtaskId,
            subtaskArrow: faCaretDown,
          });
        });
      }
    });
    setTaskCollapseIcon(t1);
    setSubTaskCollapseIcon(subt1);
  };
  const subTaskIcon = (id) => {
    //
    let obj = SubTaskCollapseIcon.find((e) => e.ID == id);
    if (obj != undefined) {
      return obj.subtaskArrow;
    } else {
      let subT = SubTaskCollapseIcon;
      subT.push({
        ID: id,
        subtaskArrow: faCaretDown,
      });
      return faCaretDown;
    }
  };

  const toggleSubTaskIcons = (id) => {
    let t = SubTaskCollapseIcon;
    SubTaskCollapseIcon.forEach((e, i) => {
      if (e.ID == id) {
        setFlag(true);
        t[i] = {
          ID: e.ID,
          subtaskArrow:
            e.subtaskArrow == faCaretRight ? faCaretDown : faCaretRight,
        };
      }
    });
    setSubTaskCollapseIcon(t);
  };

  const openSubtaskPopupToggle = () => {
    setOpenSubtaskPopup(!openSubtaskPopup);
  };

  const toggleTaskIcons = (id) => {
    let t = TaskCollapseIcon;
    TaskCollapseIcon.forEach((e, i) => {
      if (e.ID == id) {
        setFlag(true);
        t[i] = {
          ID: e.ID,
          taskArrow: e.taskArrow == faCaretRight ? faCaretDown : faCaretRight,
        };
      }
    });
    setTaskCollapseIcon(t);
  };

  useEffect(() => {
    //alert("useefffecg");
    allTasks.map((tasks, index) => {});
    setFlag(false);
  }, [Flag]);

  const [DesignCollapseIcon, setDesignCollapseIcon] = useState(false);
  const [FilingCollapseIcon, setFilingCollapseIcon] = useState(false);
  const [MiscCollapseIcon, setMiscCollapseIcon] = useState(false);

  const [secondTable, setsecondTable] = useState("");

  const toggletable2 = () => {
    if (secondTable == "") {
      setsecondTable("show");
    } else {
      setsecondTable("");
    }
  };

  const [thirdTable, setthirdTable] = useState("");

  const toggletable3 = () => {
    if (thirdTable == "") {
      setthirdTable("show");
    } else {
      setthirdTable("");
    }
  };

  const toggleNested1 = () => {
    setNestedModal1(!nestedModal1);
  };

  const openDatePicker = () => {
    setdatePickerIsOpen(!datePickerIsOpen);
  };
  const handleChange = (date) => {
    setstartDate(date);
    setdatePickerIsOpen(!datePickerIsOpen);
  };

  const changeDate = (date) => {
    setstartDate(date);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
    setProjectDate(formattedDate);
  };

  const clearAllTblListAssgnee = (oprtnType) => {
    if (oprtnType === "TASK_DROPDOWN_MENU") {
      selectedTask.Assignee = [];
    } else if (oprtnType === "SUBTASK_DROPDOWN_MENU") {
      selectedSubTask.Assignee = [];
    } else if (oprtnType === "METATASK_DROPDOWN_MENU") {
      selectedMeataTask.Assignee = [];
    }
    updateTask(selectedTask);
  };

  const removeTblAssignee = (data, asgneeUserId) => {
    let flag = false;
    data.Assignee.forEach((asgn, i) => {
      if (asgn.userId === asgneeUserId) {
        data.Assignee.splice(i, 1);
        flag = true;
      }
    });

    return flag;
  };

  const addRemoveTblListAssignee = (userName, userId, oprtnType) => {
    if (oprtnType === "ADD_TASK_BY_LIST") {
      if (!removeTblAssignee(selectedTask, userId)) {
        selectedTask.Assignee.push({ userName: userName, userId: userId });
        updateTask(selectedTask);
        addActivity(
          "Update",
          "Assignee",
          selectedTask._id,
          selectedTask.TaskName,
          0,
          { userName: userName, userId: userId }
        );
        getAllActivityAsPerTask(selectedTask._id);
      }
    } else if (oprtnType === "ADD_SUBTASK_BY_LIST") {
      if (!removeTblAssignee(selectedSubTask, userId)) {
        selectedSubTask.Assignee.push({ userName: userName, userId: userId });
        updateTask(selectedSubTask);
        addActivity(
          "Update",
          "Assignee",
          selectedSubTask._id,
          selectedSubTask.TaskName,
          0,
          { userName: userName, userId: userId }
        );
        getAllActivityAsPerTask(selectedSubTask._id);
      }
    } else if (oprtnType === "ADD_METASUBTASK_BY_LIST") {
      if (!removeTblAssignee(selectedMeataTask, userId)) {
        selectedMeataTask.Assignee.push({ userName: userName, userId: userId });
        updateTask(selectedMeataTask);
        addActivity(
          "Update",
          "Assignee",
          selectedMeataTask._id,
          selectedMeataTask.TaskName,
          0,
          { userName: userName, userId: userId }
        );
        getAllActivityAsPerTask(selectedMeataTask._id);
      }
    }
  };

  const addActivity = async (
    actionType,
    dataType,
    taskId,
    taskName,
    taskType,
    data
  ) => {
    let obj = {
      UserId: localStorage.getItem("UserId"),
      UserName: localStorage.getItem("UserName"),
      DealId: DealsId,
      TaskId: taskId,
      TaskName: taskName,
      Action: { ActionType: actionType, DataType: dataType, Data: data },
    };
    HttpService.getByBoj("addActivityTime", obj)
      .then((result) => {
        if (taskData._id) getAllActivityAsPerTask(taskData._id);
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };
  const [formulaTypeObj, setFormulaTypeObj] = useState({});
  const [functionSelect, setFunctionSelect] = useState("");
  const [fieldSelect, setFieldSelect] = useState("");
  const [operatorSelect, setOperatorSelect] = useState("");
  const [formulaExpression, setFormulaExpression] = useState("");
  const [syntaxError, setSyntaxError] = useState(null);

  const returnFormulaObj = () => {
    let obj = [...mathFunctions];
    let returnObj = obj.filter((one) => one.label === "Abs")[0];
    return returnObj;
  };

  const [formulaDescObj, setFormulaDescObj] = useState(returnFormulaObj);

  const handleClick = (select, item) => {
    let obj = [...mathFunctions];
    if (select == "Function") {
      setFunctionSelect(item);
      let formulaObj = obj.filter((one) => one.value === item)[0];
      setFormulaDescObj(formulaObj);
    }
    if (select == "Field") {
      setFieldSelect(item);
    }
    if (select == "Operator") {
      setOperatorSelect(item);
    }
  };

  // const handleClick = (select, item) => {
  //   if (select == 'Function') {
  //     setFunctionSelect(item)
  //   }
  //   if (select == 'Field') {
  //     setFieldSelect(item)
  //   }
  //   if (select == 'Operator') {
  //     setOperatorSelect(item)
  //   }
  // }

  const handleOnChangeFormula = (e) => {
    const obj = { ...formulaTypeObj };
    obj[e.currentTarget.name] = e.currentTarget.value;
    setFormulaTypeObj(obj);
  };

  const handleCheckSytax = () => {
    let returnResult = false;
    let taskObj = { ...taskFieldObj };
    taskObj.Config = setEvaluateExpression(taskObj.Type);
    try {
      getFormulaCalVal(taskObj);
      setSyntaxError(null);
      returnResult = false;
    } catch (ex) {
      setSyntaxError("Syntax Error");
      returnResult = true;
    }
    return returnResult;
  };

  const formulaModelPopUp = () => {
    return (
      <Modal
        isOpen={openFormulaPopup}
        backdrop={"static"}
        size="lg"
        className="formula-modal taskComplete-warning"
      >
        <ModalHeader>
          <span className="float-right">
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => setFormulaPopUp(false)}
              icon={faTimes}
            />
          </span>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={4}>
                  <div className="form-group text-left">
                    <label>Field Label</label>
                    <input
                      name={elementType}
                      value={
                        taskFieldObj && taskFieldObj.Name
                          ? taskFieldObj.Name
                          : ""
                      }
                      onChange={handleOnChangeFieldName}
                      type="text"
                      className="form-control form-control-sm"
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group text-left">
                    <label>Formula Return Type</label>
                    <select
                      value={
                        taskFieldConfigObj && taskFieldConfigObj.ReturnType
                          ? taskFieldConfigObj.ReturnType
                          : ""
                      }
                      onChange={handleOnChangeConfig}
                      name="ReturnType"
                      type="text"
                      className="form-control form-control-sm"
                    >
                      <option>Select</option>
                      <option>Decimal</option>
                      <option>Date</option>
                      <option>String</option>
                      <option>DateTime</option>
                      <option>Currency</option>
                      <option>Boolean</option>
                    </select>
                  </div>
                </Col>
                {taskFieldConfigObj &&
                (taskFieldConfigObj.ReturnType == "Decimal" ||
                  taskFieldConfigObj.ReturnType == "Currency") ? (
                  <Col sm={4}>
                    <div className="form-group text-left">
                      <label>Formula Decimale Places</label>
                      <select
                        value={taskFieldConfigObj.DecimalPlaces}
                        onChange={handleOnChangeConfig}
                        name="DecimalPlaces"
                        type="text"
                        className="form-control form-control-sm"
                      >
                        <option>Select</option>
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group text-left">
                    <label>Select Funtion</label>
                    <input
                      onChange={(e) => {
                        let func = [];
                        if (e.currentTarget.value == "") {
                          setAllMathFunctions(func);
                        } else {
                          func = func.filter((one) => {
                            let rr = one.label.toLowerCase();
                            if (
                              rr.includes(e.currentTarget.value.toLowerCase())
                            ) {
                              return one;
                            }
                          });
                          setAllMathFunctions(func);
                        }
                      }}
                      type="text"
                      placeholder="search.."
                      className="form-control form-control-sm"
                    />
                    <ul class="formulaPopUp-listGroup list-group list-group-sm">
                      {mathFunctions.map((one) => {
                        return (
                          <li
                            onDoubleClick={() =>
                              handleInsertFunction(one.value)
                            }
                            onClick={() => handleClick("Function", one.value)}
                            class={
                              functionSelect == one.value
                                ? "list-font list-group-item list-group-item-sm active"
                                : " list-font list-group-item list-group-item-sm "
                            }
                          >
                            {one.label}
                          </li>
                        );
                      })}
                      {/* <li onDoubleClick={() => handleInsertFunction('Ceil')} onClick={() => handleClick('Function', 'Ceil')} class={functionSelect == 'Ceil' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>Ceil</li>
                    <li onDoubleClick={() => handleInsertFunction('Floor')} onClick={() => handleClick('Function', 'Floor')} class={functionSelect == 'Floor' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>Floor</li>
                    <li onDoubleClick={() => handleInsertFunction('Base')} onClick={() => handleClick('Function', 'Base')} class={functionSelect == 'Base' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>Base 10log</li>
                    <li onDoubleClick={() => handleInsertFunction('Max')} onClick={() => handleClick('Function', 'Max')} class={functionSelect == 'Max' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>Max</li>
                    <li onDoubleClick={() => handleInsertFunction('Min')} onClick={() => handleClick('Function', 'Min')} class={functionSelect == 'Min' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>Min</li>
                    <li onDoubleClick={() => handleInsertFunction('Round')} onClick={() => handleClick('Function', 'Round')} class={functionSelect == 'Round' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>Round Off</li> */}
                    </ul>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group text-left">
                    <label>Select Field</label>
                    <input
                      //     onChange={(e) => {
                      //     let fields = { ...getMergeFieldsAll() };
                      //     let obj = {}
                      //     if (e.currentTarget.value == '') {
                      //       setAllFields(fields)
                      //     } else {
                      //       Object.keys(fields).forEach((key) => {
                      //         let value = fields[key].toLowerCase()
                      //         if (value.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                      //           obj[key] = fields[key];
                      //         }
                      //       })
                      //       setAllFields(obj);
                      //     }

                      //   }}
                      type="text"
                      placeholder="search.."
                      className="form-control form-control-sm"
                    />
                    <ul class="formulaPopUp-listGroup list-group">
                      {Object.keys(allFields).map((key) => {
                        return (
                          <li
                            onDoubleClick={() =>
                              handleInsertField(allFields[key])
                            }
                            onClick={() => handleClick("Field", allFields[key])}
                            class={
                              fieldSelect == allFields[key]
                                ? "list-font list-group-item list-group-item-sm active"
                                : "list-font list-group-item list-group-item-sm "
                            }
                          >
                            {allFields[key]}
                            <span className="list-font ml-2 p-1 badge badge-pill badge-info border border-info">
                              {getTagName(key)}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group text-left">
                    <label>Select Operator</label>
                    <input
                      onChange={(e) => {
                        let ops = [];
                        if (e.currentTarget.value == "") {
                          setAllOperators(ops);
                        } else {
                          ops = ops.filter((one) => {
                            let rr = one.label.toLowerCase();
                            if (
                              rr.includes(e.currentTarget.value.toLowerCase())
                            ) {
                              return one;
                            }
                          });
                          setAllOperators(ops);
                        }
                      }}
                      type="text"
                      placeholder="search.."
                      className="form-control form-control-sm"
                    />
                    <ul class=" formulaPopUp-listGroup list-group">
                      {allOperators.map((one) => {
                        return (
                          <li
                            onDoubleClick={() =>
                              handleInsertOperator(one.value)
                            }
                            onClick={() => handleClick("Operator", one.value)}
                            class={
                              operatorSelect == one.value
                                ? "list-font list-group-item list-group-item-sm active"
                                : "list-font list-group-item list-group-item-sm "
                            }
                          >
                            {one.label}
                          </li>
                        );
                      })}
                      {/* <li onDoubleClick={() => handleInsertOperator('-')} onClick={() => handleClick('Operator', '-')} class={operatorSelect == '-' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>- Subtract</li>
                    <li onDoubleClick={() => handleInsertOperator('*')} onClick={() => handleClick('Operator', '*')} class={operatorSelect == '*' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>* Multiply</li>
                    <li onDoubleClick={() => handleInsertOperator('/')} onClick={() => handleClick('Operator', '/')} class={operatorSelect == '/' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>/ Divide</li>
                    <li onDoubleClick={() => handleInsertOperator('%')} onClick={() => handleClick('Operator', '%')} class={operatorSelect == '%' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>% Reminder</li>
                    <li onDoubleClick={() => handleInsertOperator('^')} onClick={() => handleClick('Operator', '^')} class={operatorSelect == '^' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>^ Exponentials</li>
                    <li onDoubleClick={() => handleInsertOperator('!')} onClick={() => handleClick('Operator', '!')} class={operatorSelect == '!' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>! NOT</li>
                    <li onDoubleClick={() => handleInsertOperator('||')} onClick={() => handleClick('Operator', '||')} class={operatorSelect == '||' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>|| OR</li>
                    <li onDoubleClick={() => handleInsertOperator('&&')} onClick={() => handleClick('Operator', '&&')} class={operatorSelect == '&&' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>&& AND</li>
                    <li onDoubleClick={() => handleInsertOperator('(')} onClick={() => handleClick('Operator', '(')} class={operatorSelect == '(' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>( Open paranthesis</li>
                    <li onDoubleClick={() => handleInsertOperator(')')} onClick={() => handleClick('Operator', ')')} class={operatorSelect == ')' ? "list-group-item list-group-item-sm active" : 'list-group-item list-group-item-sm '}>) Close paranthesis</li> */}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4} className="text-center">
                  <button
                    onClick={() => {
                      handleInsertFunction(functionSelect);
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    Insert
                  </button>
                </Col>
                <Col sm={4}>
                  <button
                    onClick={() => {
                      handleInsertField(fieldSelect);
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    Insert
                  </button>
                </Col>
                <Col sm={4}>
                  <button
                    onClick={() => {
                      handleInsertOperator(operatorSelect);
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    Insert
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Card className="mt-3 formula-usage-card">
                    <CardTitle className="col-sm-12 pt-3 mb-0 text-left formula-header">
                      Function Usage
                    </CardTitle>

                    <CardBody className="row pt-2 text-left formula-body ">
                      <Col sm={4}>
                        <label>Function:</label>
                      </Col>
                      <Col sm={6}>
                        <span>
                          {formulaDescObj ? formulaDescObj.Usage.Function : ""}
                        </span>
                      </Col>
                      <Col sm={4}>
                        <label>Description:</label>
                      </Col>
                      <Col sm={6}>
                        <span>
                          {formulaDescObj
                            ? formulaDescObj.Usage.Description
                            : ""}
                        </span>
                      </Col>
                      <Col sm={4}>
                        <label>Syntax:</label>
                      </Col>
                      <Col sm={6}>
                        <span>
                          {formulaDescObj ? formulaDescObj.Usage.Syntax : ""}
                        </span>
                      </Col>
                      <Col sm={4}>
                        <label>Example:</label>
                      </Col>
                      <Col sm={6}>
                        <span>
                          {formulaDescObj ? formulaDescObj.Usage.Example : ""}
                        </span>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={8}>
                  <div className="form-group text-left">
                    <label>Formula Expression : </label>
                    <textarea
                      value={
                        taskFieldConfigObj && taskFieldConfigObj.Expression
                          ? taskFieldConfigObj.Expression
                          : ""
                      }
                      onChange={(e) => {
                        const obj = { ...taskFieldConfigObj };
                        obj.Expression = e.currentTarget.value;
                        setTaskFieldConfigObj(obj);
                        setFormulaExpression(e.currentTarget.value);
                      }}
                      className="form-control form-control-sm"
                      type="text"
                      name="Expression"
                    />
                    {syntaxError ? (
                      <p className="small text-danger">{syntaxError}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
                <Col sm={3} className="mt-4">
                  <div
                    onClick={() => {
                      handleCheckSytax();
                    }}
                    className="btn btn-sm btn-primary"
                  >
                    Check Syntax
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="text-center">
            <Col sm={12} className="text-center">
              <button
                onClick={() => {
                  if (
                    taskFieldObj &&
                    taskFieldObj.Name &&
                    taskFieldConfigObj &&
                    taskFieldConfigObj.ReturnType
                  ) {
                    setFormulaPopUp(false);
                    handleCreateField("Formula");
                  } else {
                    swal(" Mandatory Fields Are Required", {
                      dangerMode: true,
                    });
                    return;
                  }
                }}
                className="btn btn-sm btn-primary  mt-2"
              >
                Done
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const handleInsertOperator = (opSelect) => {
    let expres = formulaExpression;
    expres = expres + opSelect;
    const obj = { ...taskFieldConfigObj };
    obj.Expression = expres;
    setTaskFieldConfigObj(obj);
    setFormulaExpression(expres);
    setOperatorSelect("");
  };

  const handleInsertField = (fieldSele) => {
    let expres = formulaExpression;
    if (expres == "") {
      expres = expres + "${" + fieldSele + "}";
    } else {
      expres = expres + "${" + fieldSele + "}";
    }

    setFormulaExpression(expres);
    const obj = { ...taskFieldConfigObj };
    obj.Expression = expres;
    setTaskFieldConfigObj(obj);
    setFieldSelect("");
  };

  const handleInsertFunction = (funcSelect) => {
    let expres = formulaExpression;
    if (expres == "") {
      expres = funcSelect;
    } else {
      expres = funcSelect + "(" + expres + ")";
    }
    setFormulaExpression(expres);
    const obj = { ...taskFieldConfigObj };
    obj.Expression = expres;
    setTaskFieldConfigObj(obj);
    setFunctionSelect("");
  };

  const handleOpenCreateField = (dataType) => {
    if (dataType == "Formula") {
      const obj = { ...taskFieldObj };
      obj.Type = dataType;
      setTaskFieldConfigObj({ DecimalPlaces: 2 });
      setTaskFieldObj(obj);
      setFunctionSelect("");
      setFieldSelect("");
      setTaskFieldAction("add");
      setOperatorSelect("");
      setElementType(dataType);
      setFormulaExpression("");
      setFormulaTypeObj({});
      setFormulaPopUp(true);
      setSyntaxError(null);
    } else {
      setCreateFieldPopUp(true);
      setElementType(dataType);
      setTaskFieldExist(false);
      if (taskFieldAction === "add") {
        setTaskFieldObj(null);
        setTaskFieldConfigObj(null);
      }
    }
  };

  const saveTaskCategory = (value) => {
    let categoryData = { ...prepareTaskData() };
    setTaskCategory(value);
    categoryData.Category = value;
    addUpdateTaskApi(categoryData);
  };

  const addPriorityActivity = (value) => {
    let priorityData = { ...taskData };
    let obj = {};
    obj.PrevState = priorityData["Priority"];
    setPriorityValue(value);
    priorityData.Priority = value;
    priorityData["Priority"] = value;
    obj.UpdatedState = value;
    addActivity(
      "ADD",
      "Priority",
      priorityData._id,
      priorityData.TaskName,
      0,
      obj
    );
    handleOnChangeTask({ target: { name: "Priority", value: value } });
    getAllActivityAsPerTask(priorityData._id);
  };

  const isMatchStatus = (name, statusArr) => {
    if (statusArr.filter((status) => status.value == name).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getDiffInHourAndMiniutes = (start, end) => {
    var diffMs = end - start; // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return { hours: diffHrs, minutes: diffMins };
  };

  const saveStatusOnClick = async (value) => {
    let statusData = { ...taskData };
    let status = false;
    let dependencyStatus = statusData.DependentOn;
    let obj = {};
    obj.PrevStatus = statusData["Status"];
    if (value.name == "COMPLETED") {
      let diff =
        new Date(statusData.AssignDatetime).getTime() - new Date().getTime();
      diff = diff / (1000 * 60);
      if (diff < 1) {
        swal(
          "you can not complete this task because working time is less than a minute",
          { dangerMode: true }
        );
      } else {
        status = await checkCheckTaskStatus(statusData, value);
        if (status) {
          setActivityObj(obj);
          setParentToSubtaskStatusCompleteWar(true);
          statusData.Status = value;
          setDataForUpdate(statusData);
          getallTasks();
        } else {
          if (statusForWarn.includes(value.name)) {
            setStartDateOnCompleted(value, statusData);
            setTaskStatus(value);
            statusData.Status = value;
            obj.UpdatedState = value;
            let estimate = getEstimateDuration(
              new Date(statusData.AssignDatetime),
              statusData.TimeEstimateValue
            );
            statusData = addTimeTrackForStatusUpdate(
              statusData,
              value,
              estimate
            );
            let estimateDur = getDiffInHourAndMiniutes(
              estimate.start,
              new Date(Date.now())
            );
            estimateDur = `${estimateDur.hours}:${estimateDur.minutes}`;
            addActivity(
              "ADD",
              "Status",
              statusData._id,
              statusData.TaskName,
              0,
              obj
            );
            getAllActivityAsPerTask(statusData._id);
            let objData = { ...statusData };
            objData.TimeEstimateValue = estimateDur;
            handleOnChangeTask({ target: { name: "Status", value: value } });
            await props.taskReAssign(statusData.Assignee[0], objData);
          } else {
            setStartDateOnCompleted(value, statusData);
            setTaskStatus(value);
            statusData.Status = value;
            obj.UpdatedState = value;
            addActivity(
              "ADD",
              "Status",
              statusData._id,
              statusData.TaskName,
              0,
              obj
            );
            getAllActivityAsPerTask(statusData._id);
            handleOnChangeTask({ target: { name: "Status", value: value } });
          }
        }
      }
    } else {
      status = await checkCheckTaskStatus(statusData, value);
      if (status) {
        setActivityObj(obj);
        setParentToSubtaskStatusCompleteWar(true);
        statusData.Status = value;
        setDataForUpdate(statusData);
        getallTasks();
      } else {
        if (statusForWarn.includes(value.name)) {
          setStartDateOnCompleted(value, statusData);
          setTaskStatus(value);
          statusData.Status = value;
          obj.UpdatedState = value;
          let estimate = getEstimateDuration(
            new Date(statusData.AssignDatetime),
            statusData.TimeEstimateValue
          );
          statusData = addTimeTrackForStatusUpdate(statusData, value, estimate);
          let estimateDur = getDiffInHourAndMiniutes(
            estimate.start,
            new Date(Date.now())
          );
          estimateDur = `${estimateDur.hours}:${estimateDur.minutes}`;
          addActivity(
            "ADD",
            "Status",
            statusData._id,
            statusData.TaskName,
            0,
            obj
          );
          getAllActivityAsPerTask(statusData._id);
          let objData = { ...statusData };
          objData.TimeEstimateValue = estimateDur;
          handleOnChangeTask({ target: { name: "Status", value: value } });
          await props.taskReAssign(statusData.Assignee[0], objData);
        } else {
          setStartDateOnCompleted(value, statusData);
          setTaskStatus(value);
          statusData.Status = value;
          obj.UpdatedState = value;
          addActivity(
            "ADD",
            "Status",
            statusData._id,
            statusData.TaskName,
            0,
            obj
          );
          getAllActivityAsPerTask(statusData._id);
          handleOnChangeTask({ target: { name: "Status", value: value } });
        }
      }
    }

    return status;
  };

  const [taskEstimatePopUpWarn, setTaskEstimatePopupWarn] = useState(false);

  const taskEstimateFillWarningModal = () => {
    return (
      <Modal
        isOpen={taskEstimatePopUpWarn}
        backdrop={"static"}
        size="md"
        toggle={taskEstimatePopUpWarn}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Assignee Add warning</h5>
              <p className="taskComplete-warning-subtitle">
                Please fill the time estimate first for adding assignee
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                onClick={() => {
                  setTaskEstimatePopupWarn(!taskEstimatePopUpWarn);
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                OK
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const addRemoveAssignee = (userName, userId, oprtnType) => {
    let activityData = { ...taskData };
    let assignee = activityData.Assignee;
    let found = false;
    if (assignee.length > 1) {
      assigneDropState.forEach((asgn, i) => {
        if (asgn.userId == userId) {
          assignee.splice(i, 1);
          found = true;
          // saveUpdateTaskData();
          addActivity(
            "DELETE",
            "Assignee",
            activityData._id,
            activityData.TaskName,
            0,
            {
              userName: userName,
              userId: userId,
            }
          );
          getAllActivityAsPerTask(activityData._id);
        }
      });
    }

    if (found == false) {
      if (
        activityData.TimeEstimateValue == ":" ||
        activityData.TimeEstimateValue == "0:0"
      ) {
        setTaskEstimatePopupWarn(true);
        return;
      }

      let taskAssignDate1 = props.checkValidateTimeEstimateForTask(
        { userName: userName, userId: userId },
        activityData
      );
      if (taskAssignDate1) {
        activityData.AssignDatetime = taskAssignDate1;
        setTaskData(activityData);
      } else {
        swal(
          "No available time slot till the task due date. If you want to assign increase the due date"
        );
        return;
      }
      assignee.push({
        userName: userName,
        userId: userId,
      });
      addActivity(
        "ADD",
        "Assignee",
        activityData._id,
        activityData.TaskName,
        0,
        {
          userName: userName,
          userId: userId,
        }
      );
      getAllActivityAsPerTask(activityData._id);
      // assignee = addAssgnee(assignee, userName, userId, oprtnType);
    }
    handleOnChangeTask({ target: { name: "Assignee", value: [...assignee] } });
    //setAssigneDropState([...assignee]);
  };

  const addSuperviser = (userName, userId, oprtnType) => {
    let activityData = { ...taskData };
    let assignee = activityData.Superviser;
    if (assignee.filter((a) => a.userId == userId).length == 0) {
      assignee.push({ userId: userId, userName: userName });
      handleOnChangeTask({
        target: { name: "Superviser", value: [...assignee] },
      });
    }

    //setAssigneDropState([...assignee]);
  };

  const addAssgnee = (assignee, userName, userId, oprtnType) => {
    assignee.push({
      userName: userName,
      userId: userId,
    });

    // if (oprtnType === "ADD_TASK_BY_LIST") {
    //   if(!removeTblAssignee(selectedTask, userId)) {
    //     selectedTask.Assignee.push({
    //       userName: userName,
    //       userId: userId,
    //     });
    //   }

    // } else if(oprtnType === "ADD_SUBTASK_BY_LIST") {
    //   if(!removeTblAssignee(selectedSubTask, userId)) {
    //     selectedSubTask.Assignee.push({
    //       userName: userName,
    //       userId: userId,
    //     });
    //   }
    // }else if(oprtnType === "ADD_METASUBTASK_BY_LIST"){
    //   if(!removeTblAssignee(selectedMeataTask, userId)) {
    //     selectedMeataTask.Assignee.push({
    //       userName: userName,
    //       userId: userId,
    //     });
    //   }

    // }
    // updateTask(selectedTask);

    return assignee;
  };

  const [dependencyCollapse, setDependencyCollapse] = useState(true);
  const [toDoCollapse, setToDoCollapse] = useState(true);
  const [attachementCollapse, setAttachementCollapse] = useState(true);

  //TIMETRACK
  const [interv, setInterv] = useState(0);
  const [timer, setTimer] = useState(0);
  const [clockisActive, setClockIsActive] = useState(false);
  const [StartTime, setStartTime] = useState("");
  const [StopTime, setStopTime] = useState("");
  const [TimeTrackedData, setTimeTrackedData] = useState([]);
  const [TotalTimeTracked, setTotalTimeTracked] = useState(0);
  const [timeTrackObj, setTimeTrackObj] = useState({});
  let d = new Date().getHours() + ":" + new Date().getMinutes();
  const [FromRange, setFromRange] = useState(null);
  const [ToRange, setToRange] = useState(null);
  const [timeLogDate, setTimeLogDate] = useState(null);
  const SetTime = (DateTime) => {
    let hrs = new Date(DateTime).getHours();
    hrs = hrs < 10 ? "0" + hrs : hrs;
    let mins = new Date().getMinutes();
    mins = mins < 10 ? "0" + mins : mins;
    return hrs + ":" + mins;
  };
  const getDateMonthOnly = (dateSplit) => {
    dateSplit = GetFormattedDate(dateSplit);
    dateSplit = dateSplit.split("/");
    let monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    //
    let Month = parseInt(dateSplit[1]);
    return monthShortNames[Month - 1] + " " + dateSplit[0];
  };
  function GetFormattedDate(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return day + "/" + month + "/" + year;
  }
  const formatRangeTimer = (Time, Date) => {
    let d2 = SetTime(Time);
    d2 = d2.split(":");
    Date = GetFormattedDate(Time);
    let yr = Date.split("/");
    if (d2[0] > 12) {
      d2[0] = d[0] % 12;
      d2[1] = d2[1] + " p.m.";
    } else {
      d2[1] = d2[1] + " a.m.";
    }
    return getDateMonthOnly(Time) + " " + yr[2] + " " + d2[0] + ":" + d2[1];
  };
  const formatStartStopTimer = (Time) => {
    if (Time) {
      let d1 = new Date(Time).toString();
      d1 = d1.split(" ");
      let d2 = d1[4].split(":");
      if (d2[0] > 12) {
        d2[0] = d[0] % 12;
        d2[2] = d2[2] + " p.m.";
      } else {
        d2[2] = d2[2] + " a.m.";
      }
      return (
        d1[1] +
        " " +
        d1[2] +
        " " +
        d1[3] +
        " " +
        d2[0] +
        ":" +
        d2[1] +
        ":" +
        d2[2]
      );
    } else {
      return "NA";
    }
  };

  const getDifferenceBetweenTimes = (time1, time2) => {
    let timeDiff = new Object();
    timeDiff.milliseconds = Math.abs(time1 - time2);
    timeDiff.seconds = Math.floor(timeDiff.milliseconds / 1000);
    timeDiff.minutes = Math.floor(timeDiff.seconds / 60);
    timeDiff.hours = Math.floor(timeDiff.minutes / 60);
    timeDiff.days = Math.floor(timeDiff.hours / 24);
    timeDiff.weeks = Math.floor(timeDiff.days / 7);

    return timeDiff;
  };

  const getTotalHrsBetweenTwoDate = (time1, time2) => {
    let dateTime = "";

    if (time1 && time2) {
      time1 = new Date(time1);
      time2 = new Date(time2);
      // let timeDiff=new Date(Math.abs((time1 - time2)));
      let timeDiff = new Object();
      timeDiff.milliseconds = Math.abs(time1 - time2);
      timeDiff.seconds = Math.ceil(timeDiff.milliseconds / 1000);
      timeDiff.minutes = Math.floor(timeDiff.seconds / 60);
      timeDiff.hours = Math.floor(timeDiff.minutes / 60);
      timeDiff.days = Math.floor(timeDiff.hours / 24);
      timeDiff.weeks = Math.floor(timeDiff.days / 7);
      if (timeDiff.seconds >= 60) {
        timeDiff.seconds = timeDiff.seconds % 60;
      }
      if (timeDiff.minutes >= 60) {
        timeDiff.minutes = timeDiff.minutes % 60;
      }

      dateTime = dateTime + (timeDiff.hours < 10 ? "0" : "") + timeDiff.hours;
      dateTime =
        dateTime + ":" + (timeDiff.minutes < 10 ? "0" : "") + timeDiff.minutes;
      dateTime =
        dateTime + ":" + (timeDiff.seconds < 10 ? "0" : "") + timeDiff.seconds;
      return dateTime + " Hrs";
    } else {
      return "00:00:00 Hrs";
    }
  };

  const formatTimeFormOld = (timerObj) => {
    let timer = getTotalHrsBetweenTwoDate(
      timerObj.StartTime,
      timerObj.StopTime
    );
    return timer;
  };

  const getTotalTimeDiff = () => {
    let data = { ...taskData };
    let totalTimeDiff = { hrs: 0, mins: 0, sec: 0 };
    let timeStr = "";
    if (data && data.TimeTrack) {
      data.TimeTrack.forEach((t) => {
        if (t.current) {
          let obj = getDifferenceBetweenTimes(
            new Date(t.StartTime),
            new Date(Date.now())
          );
          totalTimeDiff.sec = obj.seconds + totalTimeDiff.sec;
        } else {
          let obj = getDifferenceBetweenTimes(
            new Date(t.StartTime),
            new Date(t.StopTime)
          );
          totalTimeDiff.sec = obj.seconds + totalTimeDiff.sec;
        }
      });

      if (totalTimeDiff.sec >= 60) {
        totalTimeDiff.mins =
          totalTimeDiff.mins + Math.floor(totalTimeDiff.sec / 60);
        totalTimeDiff.sec = totalTimeDiff.sec % 60;
      }
      if (totalTimeDiff.mins >= 60) {
        totalTimeDiff.hrs =
          totalTimeDiff.hrs + Math.floor(totalTimeDiff.mins / 60);
        totalTimeDiff.mins = totalTimeDiff.mins % 60;
      }
      timeStr =
        timeStr + (totalTimeDiff.hrs < 10 ? "0" : "") + totalTimeDiff.hrs;
      timeStr =
        timeStr +
        ":" +
        (totalTimeDiff.mins < 10 ? "0" : "") +
        totalTimeDiff.mins;
      timeStr =
        timeStr + ":" + (totalTimeDiff.sec < 10 ? "0" : "") + totalTimeDiff.sec;
      return timeStr + " Hrs";
    } else {
      return `00:00:00 Hrs`;
    }
  };
  const formatTime = (timerObj) => {
    let timer = 0;
    if (timerObj.current) {
      timer = getTotalHrsBetweenTwoDate(timerObj.StartTime, timerObj.StopTime);
      // const getSeconds = `0${timer % 60}`.slice(-2);
      // const minutes = `${Math.floor(timer / 60)}`;
      // const getMinutes = `0${minutes % 60}`.slice(-2);
      // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
      return timer;
    } else if (!timerObj.current) {
      timer = getTotalHrsBetweenTwoDate(timerObj.StartTime, timerObj.StopTime);
      // const getSeconds = `0${timer % 60}`.slice(-2);
      // const minutes = `${Math.floor(timer / 60)}`;
      // const getMinutes = `0${minutes % 60}`.slice(-2);
      // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
      return timer;
    } else {
      return `00:00:00 Hrs`;
    }
  };
  const handleReset = () => {
    //
    clearInterval(interv);
    setClockIsActive(false);
    setInterv(0);
    setTimer(0);
  };

  const startstopClock = async () => {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    try {
      if (clockisActive) {
        let res = await TaskService.ResumeAndPauseTimeTrack(
          timerObj.timelogId,
          "stop"
        );
        setClockIsActive(false);
        clearInterval(interv);
        timeTrackInitialTime = new Date();
        // setTimer(0);
        setTimerObj(null);
        getAllTimeTrackLogs(taskData.JobId);
      } else {
        let date = new Date();
        // date.setDate(date.getDate() - 1)
        date = dayjs(new Date(date)).format("YYYY-MM-DD");
        let result = await TaskService.StartTimeTrack(
          userinfo?.email_id,
          date,
          taskData.JobId
        );
        if (result.response.errors) {
          alert(result.response.errors.message);
        } else {
          let res = await TaskService.GetCurrentlyRunningTimer();
          if (res) {
            setInterv(
              setInterval(() => {
                let isRunning = localStorage.getItem("IsTimeTrackRunning");
                if (isRunning == "true" || isRunning) {
                  let nowTime = new Date(Date.now());
                  let diff = nowTime.getTime() - timeTrackInitialTime.getTime();
                  diff = diff / 1000;
                  timeTrackInitialTime = new Date(nowTime);
                  setTimer((prev) => prev + diff);
                } else {
                  localStorage.setItem("IsTimeTrackRunning", false);
                  setClockIsActive(false);
                  clearInterval(interv);
                  timeTrackInitialTime = new Date();
                  // setTimer(0);
                  setTimerObj(null);
                  getAllTimeTrackLogs(taskData.JobId);
                }
              }, 1000)
            );
            let startTime = new Date(Date.now());
            startTime.setTime(startTime.getTime() + res.diff * 1000);
            timeTrackInitialTime = new Date(startTime);
            setTimer((prev) => prev + res.diff);
            setClockIsActive(true);
            localStorage.setItem("IsTimeTrackRunning", true);
            setTimerObj(res);
          }
        }
      }
      if (props.initializeTimeTrack) {
        props.initializeTimeTrack();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const currentData = TimeTrackedData ? TimeTrackedData : [];
  const checkAndStartClock = (data) => {
    if (data.TimeTrack.length > 0) {
      let filterTimeObj = data.TimeTrack.filter((one) => one.current);
      if (filterTimeObj.length > 0) {
        setTimeTrackObj({ ...filterTimeObj[0] });
        setClockIsActive(true);

        setInterv(
          setInterval(() => {
            let timeObj = { ...timeTrackObj };
            if (timeObj.current) {
              timeObj.StopTime = new Date(Date.now());
              setTimeTrackObj(timeObj);
            } else {
              if (clockisActive) {
                setInterv(0);
                clearInterval(interv);
                setClockIsActive(false);
                setTimeTrackObj({});
              }
            }
          }, 1000)
        );
      } else {
        setTimeTrackObj(null);
        setClockIsActive(false);
        setInterv(0);
        clearInterval(interv);
      }
    }
  };
  const removeTimeTrack = (itemID) => {
    const task = { ...taskData };
    task.TimeTrack = task.TimeTrack.filter((e) => e.ID != itemID);
    setTaskData(task);
  };

  const onSaveTimer = (start, stop) => {
    const temp = {
      ID: uuidv4(),
      UserID: localStorage.getItem("UserId"),
      Date: GetFormattedDate(),
      InputBy: "Timer",
      StartTime: start,
      StopTime: stop,
      TimeTracked: stop == "" ? 0 : Math.floor((stop - start) / 1000),
    };
    let sum = 0;
    if (stop == "") {
      currentData.push(temp);
    } else {
      currentData.forEach((e, i) => {
        if (e.StopTime == "") {
          currentData[i] = temp;
        }
      });
    }
    currentData.forEach((e, i) => {
      sum = sum + e.TimeTracked;
    });
    setTotalTimeTracked(sum);
    setTimeTrackedData(currentData);
    openmodalType == "task"
      ? saveUpdateTaskData()
      : openmodalType == "subtask"
      ? saveUpdateSubTaskData()
      : saveUpdateMetaSubTaskData();
  };

  const convert24to12 = (date) => {
    // let Time =  new Date(date);
    // Time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    if (date == "N/A" || date == "") {
      return date;
    } else {
      try {
        var hours = date.getHours();
        var minutes = date.getMinutes();

        // if(seconds>=30){
        //   minutes=minutes+1;
        // }
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
      } catch (ex) {}
    }
  };
  const onSaveTimeRange = (start, stop, date) => {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    const data = { ...taskData };
    start = start.split(":");
    stop = stop.split(":");
    let from = new Date(Date.now());
    from.setHours(parseInt(start[0]));
    from.setMinutes(parseInt(start[1]));
    let till = new Date(Date.now());
    till.setHours(parseInt(stop[0]));
    till.setMinutes(parseInt(stop[1]));
    start = convert24to12(from);
    stop = convert24to12(till);
    date = dayjs(new Date(date)).format("YYYY-MM-DD");
    TaskService.addTimeTrack(
      taskData.JobId,
      data.name,
      date,
      userinfo?.email_id,
      start,
      stop
    )
      .then((result) => {
        if (result.errors) {
          alert(result.errors.message);
        } else {
          alert(result.message);
          setTimeLogDate(null);
          setFromRange(null);
          setToRange(null);
        }
        console.log(result);
        return getAllTimeTrackLogs(taskData.JobId);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });

    // swal("Starting Time should be lesser");
  };
  /// CHECKLIST CONST
  const [CheckItemAssigneeDrop, setCheckItemAssigneeDrop] = useState(false);
  const [CheckItemAssignee, setCheckItemAssignee] = useState([]);
  const [CheckList, setCheckList] = useState([]);
  const [CheckItemContainer, toggleCheckItemContainer] = useState(false);
  const [CheckItemLabel, setCheckItemLabel] = useState("");
  const [CheckItemforUpdate, setCheckItemforUpdate] = useState("");
  const currentCheckList = CheckList;

  const getUserProfilePhotobyID = (userId) => {
    let selectedUser = allUsers.find((e) => e.UserId == userId);
    if (selectedUser != undefined) {
      return selectedUser.ProfilePhoto;
    } else {
      return assigntask;
    }
  };

  const resetCheckItemFields = () => {
    setCheckItemLabel("");
    setCheckItemforUpdate("");
    setCheckItemAssignee([]);
  };
  const saveUpdateCheckList = () => {
    const parentObj = { ...taskData };
    let newCheckItem = {
      ID: uuidv4(),
      UserID: localStorage.getItem("UserId"),
      Label: CheckItemLabel,
      Assignee: CheckItemAssignee[0] ? CheckItemAssignee[0] : [],
      IsChecked: false,
    };

    let obj = {};
    obj.PrevCheckList = prevCheckList;
    obj.UpdatedCheckList = newCheckItem;
    addActivity(
      "Update",
      "CheckList",
      parentObj._id,
      parentObj.TaskName,
      0,
      obj
    );
    // if (!newTask) {
    //   openmodalType == "task"
    //     ? addActivity('UPDATE', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
    //     : openmodalType == "subtask"
    //       ? addActivity('UPDATE', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
    //       : addActivity('UPDATE', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
    // }

    currentCheckList.push(newCheckItem);
    setCheckList(currentCheckList);
    handleOnChangeTask({
      target: { name: "Checklist", value: currentCheckList },
    });
    // if (!newTask) {
    //   props.taskType == "task"
    //     ? saveUpdateTaskData()
    //     : openmodalType == "subtask"
    //       ? saveUpdateSubTaskData()
    //       : saveUpdateMetaSubTaskData();
    // }
    resetCheckItemFields();
  };

  const [prevCheckList, setPrevCheckList] = useState(null);
  const changeCheckLabel = (e, Item, i) => {
    if (e.key == "Enter" && CheckItemLabel != "") {
      let filterCheckList = CheckList.filter((one) => one.ID == Item.ID);
      if (filterCheckList.length > 0) {
        setPrevCheckList(filterCheckList[0]);
      }

      let updatedCheckItem = {
        ID: Item.ID,
        UserID: Item.UserID,
        Assignee: Item.Assignee,
        Label: CheckItemLabel,
        IsChecked: Item.IsChecked,
      };
      let obj = {};
      obj.PrevCheckList =
        filterCheckList.length > 0 ? filterCheckList[0] : null;
      obj.UpdatedCheckList = updatedCheckItem;

      if (!newTask) {
        openmodalType == "task"
          ? addActivity(
              "Update",
              "CheckList",
              parentObj._id,
              parentObj.TaskName,
              0,
              obj
            )
          : openmodalType == "subtask"
          ? addActivity(
              "Update",
              "CheckList",
              parentObj._id,
              parentObj.TaskName,
              0,
              obj
            )
          : addActivity(
              "Update",
              "CheckList",
              parentObj._id,
              parentObj.TaskName,
              0,
              obj
            );
      }
      currentCheckList.forEach((e, i) => {
        if (e.ID == Item.ID) {
          currentCheckList[i] = updatedCheckItem;
        }
      });
      setCheckList(currentCheckList);
      if (!newTask) {
        openmodalType == "task"
          ? saveUpdateTaskData()
          : openmodalType == "subtask"
          ? saveUpdateSubTaskData()
          : saveUpdateMetaSubTaskData();
      }
      resetCheckItemFields();
    }
  };

  const changeCheck = (Item, idx) => {
    let updatedCheckItem = {
      ID: Item.ID,
      UserID: Item.UserID,
      Assignee: Item.Assignee,
      Label: Item.Label,
      IsChecked: !Item.IsChecked,
    };
    let filterCheckList = CheckList.filter((one) => one.ID == Item.ID);
    if (filterCheckList.length > 0) {
      setPrevCheckList(filterCheckList[0]);
    }
    currentCheckList.forEach((e, i) => {
      if (e.ID == Item.ID) {
        currentCheckList[i] = updatedCheckItem;
      }
    });
    setCheckList(currentCheckList);
    if (!newTask) {
      openmodalType == "task"
        ? saveUpdateTaskData()
        : openmodalType == "subtask"
        ? saveUpdateSubTaskData()
        : saveUpdateMetaSubTaskData();
    }
    resetCheckItemFields();
  };
  //modify checklist in 3 columns
  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  const [parentObj, setParentObject] = useState(null);
  const [editTask, setEditTask] = useState(false);

  const [action, setAction] = useState();
  // const [taskId,,setTaskId]=useState('')

  const openUpdateModal = async (data, type, parentdata, metaSubTask) => {
    // let duplicate = {
    //   TaskName: '',
    //   Everything: true, Attachements: true, Checklist: true, Comments: true, InputFields: true,
    //   LookUpFields: true, Dependencies: true, DueDate: true, Assignee: true
    // };
    // duplicate.TaskName=data.TaskName+' - Copy'+(copyTasks.length+1);
    // setDuplicateValueObj(duplicate);
    getallTasks();

    getTaskDocs(data.TaskName, data._id);
    GetAllTaskCustumeFieldList(data._id);
    setParentObject(data);
    // if (data.TimeEstimateValue != "") {
    //   let obj = getTimeEstimateValue(data.TimeEstimateValue);

    //   setTimeEstimateValue(obj.hrs);
    //   setTimeMinuteValue(obj.mins);
    // }

    if (type == "subtask") {
      if (data.Subtask.length > 0) {
        setSubtaskCollapse(true);
      } else {
        setSubtaskCollapse(!subtaskCollapse);
      }
      getAllActivityAsPerTask(data._id);
      setSelectedTaskId({ task: data });
      getComments(data._id);
      setTaskCategory(parentdata.Category);
      setselectedSubTaskId({ subtask: data, task: parentdata });
      setTaskName(data.TaskName);
      let TList = [...allTaskList];
      // TList = TList.filter((t) => t.id != data.SubtaskId);
      setDependencyDropdownData(TList);
      setTaskDependency([]);
      // setTaskDependency(data.DependentOn != null ? data.DependentOn : []);
      setDescription(data.Description); // setSelectedTaskId({subtask:data,task:parentdata});
      setSubTasks(data.Subtask);
      // setTaskId(data._id)
      setTaskStatus(data.Status);
      setPriorityValue(data.Priority);
      setAssigneDropState(data.Assignee);
      setSelectionRange([
        {
          startDate: data.StartDate ? data.StartDate : new Date(),
          endDate: data.DueDate ? data.DueDate : addDays(new Date(), 7),
          key: "selection",
        },
      ]);
      setstartDate(Date.parse(data.DueDate));
      setSubTasks(data.Subtask);
      //   if (data.TimeEstimateValue != "") {
      //     let obj = getTimeEstimateValue(data.TimeEstimateValue);

      //     setTimeEstimateValue(obj.hrs);
      //     setTimeMinuteValue(obj.mins);
      //   }
      // setTimeEstimateValue(data.TimeEstimateValue);

      setCheckList(data.Checklist);
      setTimeTrackedData(data.TimeTrack);

      let timeSum = 0;
      data.TimeTrack.forEach((e, i) => {
        if (e.UserID == localStorage.getItem("UserId")) {
          timeSum = timeSum + e.TimeTracked;
          if (e.StopTime == "") {
            setStartTime(e.StartTime);
            setClockIsActive(true);
            let startTimerFromHere = Math.floor(
              (Date.now() - e.StartTime) / 1000
            );
            setTimer(startTimerFromHere);
          }
          if (e.StopTime == "") {
            setInterv(
              setInterval(() => {
                setTimer((timer) => timer + 1);
              }, 1000)
            );
          }
        }
      });
      setTotalTimeTracked(timeSum);
    } else if (type == "metatask") {
      getAllActivityAsPerTask(data._id);
      setSelectedTaskId({ task: data });
      getComments(data._id);
      setselectedSubTaskId({
        metatask: data,
        task: parentdata,
        subtask: metaSubTask,
      });
      //setTaskId(data._id)
      //setSelectedTaskId({subtask:data,task:parentdata});
      setTaskName(data.TaskName);
      let TList = [...allTaskList];
      // TList = TList.filter((t) => t.id != data.SubtaskId);
      setDependencyDropdownData(TList);
      setTaskDependency([]);
      // setTaskDependency(data.DependentOn != null ? data.DependentOn : []);
      setDescription(data.Description);
      setTaskStatus(data.Status);
      setPriorityValue(data.Priority);
      setAssigneDropState(data.Assignee);
      setSelectionRange([
        {
          startDate: data.StartDate ? data.StartDate : new Date(),
          endDate: data.DueDate ? data.DueDate : addDays(new Date(), 7),
          key: "selection",
        },
      ]);
      setstartDate(Date.parse(data.DueDate));
      setSubTasks(data.Subtask);
      //   if (data.TimeEstimateValue != "") {
      //     let obj = getTimeEstimateValue(data.TimeEstimateValue);
      //     setTimeEstimateValue(obj.hrs);
      //     setTimeMinuteValue(obj.mins);
      //   }
      // setTimeEstimateValue(data.TimeEstimateValue);
      setCheckList(data.Checklist);
      setTimeTrackedData(data.TimeTrack);

      let timeSum = 0;
      data.TimeTrack.forEach((e, i) => {
        if (e.UserID == localStorage.getItem("UserId")) {
          timeSum = timeSum + e.TimeTracked;
          if (e.StopTime == "") {
            setStartTime(e.StartTime);
            setClockIsActive(true);
            let startTimerFromHere = Math.floor(
              (Date.now() - e.StartTime) / 1000
            );
            setTimer(startTimerFromHere);
          }
          if (e.StopTime == "") {
            setInterv(
              setInterval(() => {
                setTimer((timer) => timer + 1);
              }, 1000)
            );
          }
        }
      });
      setTotalTimeTracked(timeSum);
    } else {
      getAllActivityAsPerTask(data._id);
      setSelectedTaskId({ task: data });
      setTaskName(data.TaskName);
      let TList = [...allTaskList];
      TList = TList.filter((t) => t.id != data._id);
      setDependencyDropdownData(TList);
      setTaskDependency(data.DependentOn != null ? data.DependentOn : []);
      setDescription(data.Description);
      setTaskStatus(data.Status);
      setTaskCategory(data.Category);

      let selection = {
        startDate: data.StartDate ? new Date(data.StartDate) : new Date(),
        endDate: data.DueDate ? new Date(data.DueDate) : addDays(new Date(), 7),
        key: "selection",
      };
      setSelectionRange([selection]);
      setDateDisplay(selection);

      setstartDate(Date.parse(data.DueDate));
      setPriorityValue(data.Priority);
      setAssigneDropState(data.Assignee);
      setSubTasks(data.Subtask);
      // setTimeEstimateValue(data.TimeEstimateValue);
      getComments(data._id);

      setCheckList(data.Checklist);
      setTimeTrackedData(data.TimeTrack);

      let timeSum = 0;
      data.TimeTrack.forEach((e, i) => {
        if (e.UserID == localStorage.getItem("UserId")) {
          timeSum = timeSum + e.TimeTracked;
          if (e.StopTime == "") {
            setStartTime(e.StartTime);
            setClockIsActive(true);
            let startTimerFromHere = Math.floor(
              (Date.now() - e.StartTime) / 1000
            );
            setTimer(startTimerFromHere);
          }
          if (e.StopTime == "") {
            setInterv(
              setInterval(() => {
                setTimer((timer) => timer + 1);
              }, 1000)
            );
          }
        }
      });
      setTotalTimeTracked(timeSum);

      setAttachedFiles(data.AttachedFiles);
    }
    // if(type=='task'){
    //   setEditTask(true);
    //   setEditSubTask(false);
    //   setEditMetaTask(false);
    // }
    // if(type=='subtask'){
    //   setEditTask(false);
    //   setEditSubTask(true);
    //   setEditMetaTask(false);
    // }
    // if(type=='metatask'){
    //   setEditTask(false);
    //   setEditSubTask(false);
    //   setEditMetaTask(true);
    // }
    setEditTask(true);
    subtaskCollapseOpen();
    seNewTaskState(false);
    setNestedModal(true);
    toggleSubtaskContainer(false);
    setOpenModalType(type);
  };

  const isTaskCompleteteMandatory = () => {
    let result = false;
    let filterTaskMandat = taskCustumeFieldList.filter((one) => {
      if (one.Config) {
        if (one.Config.Mandatory && (!one.Value || one.Value.Value == "")) {
          return one;
        }
      }
    });
    if (filterTaskMandat.length > 0) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const [taskMandatoryStatus, setTaskMandatoryStatus] = useState(false);

  const [browseTemplatePopUp, setBrowseTemplatePopup] = useState(false);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const browseTemplatePopUpToggle = () =>
    setBrowseTemplatePopup(!browseTemplatePopUp);

  const openBrowseTemplatePopUp = () => {
    HttpService.getByBoj("gettaskdocs", { DealsId: props.dealsId })
      .then((result) => {
        setSelectedTemplate(null);
        setBrowseTemplatePopup(true);
        let filterTaskTemplate = result.data.Data.filter(
          (one) => one.isTemplate
        );
        setTaskTemplates(filterTaskTemplate);
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [useTemplatePopUp, setUseTemplatePopUp] = useState(false);
  const setUseTemplateToggle = () => setUseTemplatePopUp(!useTemplatePopUp);

  const setStartDateOnCompleted = (status, task) => {
    if (status.name == "COMPLETED") {
      let filterDependentTask = props.taskDocs.filter((one) => {
        if (
          one.DependentOn &&
          one.DependentOn.filter((dependency) => dependency.id == task._id)
            .length > 0
        ) {
          return one;
        }
      });
      if (filterDependentTask.length > 0) {
        let taskData = { ...filterDependentTask[0] };
        taskData.StartDate = new Date(Date.now());
        updateTask(taskData);
      }
    }
  };

  const getGroupVal = (groupValue) => {
    if (groupValue) {
      groupValue = groupValue.split("-");
      groupValue = groupValue[groupValue.length - 1];
      return groupValue;
    }
  };

  const getDealNameFormPipeline = (groupValue) => {
    if (groupValue) {
      groupValue = groupValue.split("-");
      groupValue = groupValue.slice(0, groupValue.length - 1);
      return groupValue.join("-");
    }
  };

  const getPipelineName = (groupValue) => {
    if (groupValue) {
      groupValue = groupValue.split("-");
      groupValue = groupValue[groupValue.length - 1];
      return groupValue;
    }
  };

  const updateStatus = async (value, task, subTask, metaTask, oprtnType) => {
    let data = { ...taskData };
    data.status = value;
    setTaskData(data);
    props.updateStatusTask(value, task, subTask, metaTask, oprtnType);
  };

  // const updateStatus = async (value, task, subTask, metaTask, oprtnType) => {
  //   let waitingOn = [];
  //   let data;
  //   let obj = {}
  //   if (oprtnType === "UPDATE_TASK_STATUS") {
  //     //Start Set Start Date Functionality

  //     //End Start Date Functionality
  //     task.DependentOn.forEach((x, i) => {
  //       let ta = allTaskList.find((t) => t.id == x.id);
  //       if (ta.status.name != "COMPLETED" && x.type == "Waiting-On") {
  //         waitingOn.push(ta.name);
  //       }
  //     });
  //     if (waitingOn.length > 0 && value.name == "COMPLETED") {
  //       swal("still waiting on" + JSON.stringify(waitingOn), { dangerMode: true });
  //       setTaskWaitingList(waitingOn);
  //       setTaskCompleteWarning(true);
  //     } else {
  //       obj.PrevStatus = task["Status"];
  //       task["Status"] = value;

  //       obj.UpdatedState = value;
  //     }
  //     data = { ...task };
  //   } else if (oprtnType === "UPDATE_SUBTASK_STATUS") {

  //     obj.PrevStatus = subTask["Status"];
  //     subTask["Status"] = value;
  //     data = { ...subTask };
  //     obj.UpdatedState = value;
  //     // subTask.DependentOn.forEach((x, i) => {
  //     //   let ta = allTaskList.find((t) => t.id == x.id);
  //     //   if (ta.status.name != "COMPLETED" && x.type == "Waiting-On") {
  //     //     waitingOn.push(ta.name);
  //     //   }
  //     // });
  //     // if (waitingOn.length > 0 && value.name == "COMPLETED") {
  //     //   alert("still waiting on" + JSON.stringify(waitingOn));
  //     // } else {
  //     //   subTask["Status"] = value;
  //     // }
  //   } else if (oprtnType === "UPDATE_METASUBTASK_STATUS") {

  //     obj.PrevStatus = metaTask["Status"];
  //     metaTask["Status"] = value;
  //     obj.UpdatedState = value;
  //     data = { ...metaTask };
  //     // metaTask.DependentOn.forEach((x, i) => {
  //     //   let ta = allTaskList.find((t) => t.id == x.id);
  //     //   if (ta.status.name != "COMPLETED" && x.type == "Waiting-On") {
  //     //     waitingOn.push(ta.name);
  //     //   }
  //     // });
  //     // if (waitingOn.length > 0 && value.name == "COMPLETED") {
  //     //   alert("still waiting on" + JSON.stringify(waitingOn));
  //     // } else {
  //     //   metaTask["Status"] = value;
  //     // }
  //   }
  //   setStartDateOnCompleted(value, data)
  //   if (value.name == 'COMPLETED') {
  //     let status = await checkCheckTaskStatus(data)
  //     if (status) {
  //       setActivityObj(obj);
  //       // setParentToSubtaskStatusCompleteWar(true);
  //       setTaskCompleteWarning(true);
  //       setDataForUpdate(data);
  //       getallTasks();
  //     } else {
  //       if (data.isMilestone) {
  //         if (data.Pipeline) {
  //           let res = await HttpService.CreateUpdate('getPipeline', { PipelineName: data.Pipeline })
  //           if (res.data.ServiceAssociated.length > 0) {
  //             data.Milestone.DateTime = new Date(Date.now())
  //           }
  //         }

  //       }
  //       updateTask(data);
  //       if (waitingOn.length == 0) {
  //         // addActivity('Update', 'Status', data._id, data.TaskName, 0, obj);
  //       }
  //       getallTasks();
  //     }
  //   } else {
  //     updateTask(data);
  //     if (waitingOn.length == 0) {
  //       addActivity('Update', 'Status', data._id, data.TaskName, 0, obj);
  //     }
  //     getallTasks();
  //   }
  //   // let status = await checkCheckTaskStatus(data)
  //   // if ((value.name !== 'COMPLETED') && (!status)) {
  //   //   updateTask(data);
  //   //   if (waitingOn.length == 0) {
  //   //     addActivity('Update', 'Status', data._id, data.TaskName, 0, obj);
  //   //   }
  //   //   getallTasks();
  //   // } else {
  //   //   setActivityObj(obj);
  //   //   setParentToSubtaskStatusCompleteWar(true);
  //   //   setDataForUpdate(data);
  //   //   getallTasks();
  //   // }

  // };
  const [dependencyObj, setDependencyObj] = useState({
    deal: props.dealObj.id,
    type: "FS",
    pipeline: taskData.project_id,
  });
  const [dependencyRemove, setDependencyRemove] = useState(false);
  const [deleteDependencyObj, setDeleteDependencyObj] = useState({});
  const [dependencyEditFlag, setDependencyEditFlag] = useState(false);
  const [editDependency, setEditDependency] = useState({});
  const setDependencyRemoveToggle = () =>
    setDependencyRemove(!dependencyRemove);
  const dependecyRemoveWarnig = () => {
    return (
      <Modal
        isOpen={dependencyRemove}
        backdrop={backdrop}
        size="md"
        toggle={setDependencyRemoveToggle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Task Relation Remove Warning</h5>
              <p className="taskComplete-warning-subtitle">
                Are you sure want to remove?
              </p>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <button
                className="btn btn-secondary w-100"
                onClick={() => {
                  setDependencyRemove(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleRemoveDependency(deleteDependencyObj);
                  setDependencyRemove(false);
                }}
                className="btn btn-primary w-100 mt-2"
              >
                Remove
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const [clockStartWarnPopUp, setClockStartWarnPopUp] = useState(false);
  const [activityObj, setActivityObj] = useState();
  const [dataForUpdate, setDataForUpdate] = useState(null);
  const [childTaskForWarinig, setChildTask] = useState([]);
  const [
    parentToSubtaskStatusCompleteWarinig,
    setParentToSubtaskStatusCompleteWar,
  ] = useState(false);
  const parentToSubtaskStatusCompleteWarinigToogle = () =>
    setParentToSubtaskStatusCompleteWar(!parentToSubtaskStatusCompleteWarinig);
  const warningTaskStatusCompleteModal = () => {
    return (
      <Modal
        isOpen={parentToSubtaskStatusCompleteWarinig}
        backdrop={backdrop}
        size="md"
        toggle={parentToSubtaskStatusCompleteWarinigToogle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Dependency Warning</h5>
              <p className="taskComplete-warning-subtitle">
                This task is waiting on required fields or tasks dependency for
                the following tasks of fields{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p>Field Name</p>
              <ul className="textField-list">
                {taskCustumeFieldList.map((one) => {
                  if (
                    one.Config &&
                    one.Config.Mandatory &&
                    (!one.Value || one.Value.Value === "")
                  ) {
                    return <li>{one.Name}</li>;
                  }
                })}
              </ul>
              <hr />
              <p>Task Name</p>
              <ul className="textField-list">
                {childTaskForWarinig.map((one) => {
                  return <li>{one.TaskName}</li>;
                })}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "forcely complete the status for all sub task for this",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (willDelete) => {
                    if (willDelete) {
                      let arr = [];
                      let arr1 = [];
                      arr.push(updateTask(dataForUpdate));
                      arr1.push(
                        addActivity(
                          "Update",
                          "Status",
                          dataForUpdate._id,
                          dataForUpdate.TaskName,
                          0,
                          activityObj
                        )
                      );
                      // childTaskForWarinig.forEach((one) => {
                      //   one = { ...one }
                      //   one.Status = { name: "COMPLETED", color: "#f900ea" }
                      //   arr.push(updateTask(one))
                      //   arr1.push(addActivity('Update', 'Status', one._id, one.TaskName, 0, activityObj))
                      // })
                      let res1 = await Promise.all(arr1);
                      let res = await Promise.all(arr);
                      setParentToSubtaskStatusCompleteWar(false);
                      setOpenTimeTrackedPopUp(false);
                      swal("Successfully complete this task and sub task", {
                        icon: "success",
                      });
                    } else {
                      swal("you cancel  for the update task");
                    }
                  });
                }}
              >
                Ignore
              </button>
              <button
                onClick={() => {
                  setOpenTimeTrackedPopUp(false);
                  setParentToSubtaskStatusCompleteWar(false);
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const clockStartWarningModal = () => {
    return (
      <Modal
        isOpen={clockStartWarnPopUp}
        backdrop={backdrop}
        size="md"
        toggle={clockStartWarnPopUp}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Warning</h5>
              <p className="taskComplete-warning-subtitle">
                Would you like to start clock
              </p>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  startstopClock(taskData);
                  setClockStartWarnPopUp(false);
                }}
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setClockStartWarnPopUp(false);
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                No
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const customStyles = {
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      fontWeight: "normal",
      display: "block",
      whiteSpace: "nowrap",
      minHeight: "1.2em",
      padding: "0px 2px 1px",
      color: "#808080",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "55px",
      overflow: "auto",
    }),
  };

  const getTaskData = (pId, pName, tId) => {
    TaskService.GetTaskDetail(pId, pName, tId)
      .then((result) => {
        if (result) {
          // const arr=[...openTaskArray];
          // arr.push(result);
          // setOpenTaskArr(arr);
          console.log("result of getall task", result);
          setTaskData(result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createDefaultDepedency = async (waitingTask, blockingTask) => {
    let doArr = [];
    let dependencyObj = {
      Data_For: "Dependency",
      // Dependent_Task_Status: obj.DependentStatus,
      Name:
        blockingTask.name +
        "-" +
        blockingTask.id_string +
        "-" +
        "Blocking-" +
        waitingTask.id_string +
        "-" +
        "Dependency",
      Type: "Blocking",
      Current_Project_ID: blockingTask.project_id,
      Current_Project_Name: blockingTask.project_name,
      Current_Task_ID: blockingTask.id_string,
      Current_Task_Name: blockingTask.name,
      Current_Task_Status: ["Completed"],
      Related_Project_ID: waitingTask.project_id,
      Related_Project_Name: waitingTask.project_name,
      Related_Task_ID: waitingTask.id_string,
      Related_Task_Name: waitingTask.name,
      Related_Task_Status: ["Completed"],
    };
    let waitingObj = {
      Data_For: "Dependency",
      Name:
        waitingTask.name +
        "-" +
        waitingTask.id_string +
        "-Waiting-On-" +
        blockingTask.id_string,
      Type: "Waiting-On",
      Current_Project_ID: waitingTask.project_id,
      Current_Project_Name: waitingTask.project_name,
      Current_Task_ID: waitingTask.id_string,
      Current_Task_Name: waitingTask.name,
      Current_Task_Status: ["Completed"],
      Related_Project_ID: blockingTask.project_id,
      Related_Project_Name: blockingTask.project_name,
      Related_Task_ID: blockingTask.id_string,
      Related_Task_Name: blockingTask.name,
      Related_Task_Status: ["Completed"],
    };
    doArr.push(ModuleService.CreateModuleData("Task_Dependency", waitingObj));
    doArr.push(
      ModuleService.CreateModuleData("Task_Dependency", dependencyObj)
    );
    try {
      let res = await Promise.all(doArr);
      if (props.getTaskDependencyData) {
        props.getTaskDependencyData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetDependency = (projectId, dataFor) => {
    const obj = { ...dependencyObj };

    let doArr = [];
    if (obj.Task) {
      obj.Task.forEach((task) => {
        let dependencyObj = {
          Data_For: dataFor,
          // Dependent_Task_Status: obj.DependentStatus,
          Name: task.label + "-" + task.value + "-" + obj.type + "-" + dataFor,
          Type: obj.type,
          Current_Project_ID: taskData.project_id,
          Current_Project_Name: taskData.project_name,
          Current_Task_ID: taskData.id_string,
          Current_Task_Name: taskData.name,
          Current_Task_Status: obj.current_status.map((one) => one.label),
          Related_Project_ID: task.project.id,
          Related_Project_Name: task.project.name,
          Related_Task_ID: task.value,
          Related_Task_Name: task.label,
          Related_Task_Status: obj.related_status.map((one) => one.label),
        };
        doArr.push(
          ModuleService.CreateModuleData("Task_Dependency", dependencyObj)
        );

        if (obj.type == "Blocking") {
          let waitingObj = {
            Data_For: dataFor,
            Name:
              task.label +
              "-" +
              task.value +
              "-Waiting-On-" +
              taskData.id_string,
            Type: "Waiting-On",
            Related_Project_ID: taskData.project_id,
            Related_Project_Name: taskData.project_name,
            Related_Task_ID: taskData.id_string,
            Related_Task_Name: taskData.name,
            Related_Task_Status: obj.current_status.map((one) => one.label),

            Current_Project_ID: task.project.id,
            Current_Project_Name: task.project.name,
            Current_Task_ID: task.value,
            Current_Task_Name: task.label,
            Current_Task_Status: obj.related_status.map((one) => one.label),
          };
          doArr.push(
            ModuleService.CreateModuleData("Task_Dependency", waitingObj)
          );
        } else {
          let blockingObj = {
            Data_For: dataFor,
            Name: task.label + "-" + task.value + "-Blocking",
            Type: "Blocking",
            Related_Project_ID: taskData.project_id,
            Related_Project_Name: taskData.project_name,
            Related_Task_ID: taskData.id_string,
            Related_Task_Name: taskData.name,
            Related_Task_Status: obj.current_status.map((one) => one.label),

            Current_Project_ID: task.project.id,
            Current_Project_Name: task.project.name,
            Current_Task_ID: task.value,
            Current_Task_Name: task.label,
            Current_Task_Status: obj.related_status.map((one) => one.label),
          };
          doArr.push(
            ModuleService.CreateModuleData("Task_Dependency", blockingObj)
          );
        }
      });
    }
    Promise.all(doArr)
      .then((result) => {
        if (props.getTaskDependencyData) {
          props.getTaskDependencyData();
        }

        getTaskData(projectId, taskData.project_name, taskData.id_string);
        setDependencyDrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSetSuccession = (projectId, dataFor) => {
    const obj = { ...dependencyObj };

    let doArr = [];
    if (obj.Task) {
      obj.Task.forEach((task) => {
        if (obj.type == "Predecessor") {
          let dependencyObj = {
            Data_For: dataFor,
            // Dependent_Task_Status: obj.DependentStatus,
            Name:
              task.label + "-" + task.value + "-" + obj.type + "-" + dataFor,
            Type: obj.type,
            Current_Project_ID: taskData.project_id,
            Current_Project_Name: taskData.project_name,
            Current_Task_ID: taskData.id_string,
            Current_Task_Name: taskData.name,
            Dependent_Task_Status:
              obj.current_status.length > 0 ? obj.current_status[0].value : "",
            // Current_Task_Status: obj.current_status.map((one) => one.label),
            Related_Project_ID: task.project.id,
            Related_Project_Name: task.project.name,
            Related_Task_ID: task.value,
            Related_Task_Name: task.label,
            Related_Task_Status: [
              taskLayout.status_details.filter(
                (one) => one.id == obj.DependentStatus
              )[0].name,
            ],
          };
          doArr.push(
            ModuleService.CreateModuleData("Task_Dependency", dependencyObj)
          );
          let waitingObj = {
            Data_For: dataFor,
            Name: task.label + "-" + task.value + "-Successor",
            Type: "Successor",
            Related_Project_ID: taskData.project_id,
            Related_Project_Name: taskData.project_name,
            Related_Task_ID: taskData.id_string,
            Related_Task_Name: taskData.name,
            // Related_Task_Status: obj.current_status.map((one) => one.label),
            Dependent_Task_Status:
              obj.current_status.length > 0 ? obj.current_status[0].value : "",

            Current_Project_ID: task.project.id,
            Current_Project_Name: task.project.name,
            Current_Task_ID: task.value,
            Current_Task_Name: task.label,
            Current_Task_Status: [
              taskLayout.status_details.filter(
                (one) => one.id == obj.DependentStatus
              )[0].name,
            ],
          };
          doArr.push(
            ModuleService.CreateModuleData("Task_Dependency", waitingObj)
          );
        } else {
          let dependencyObj = {
            Data_For: dataFor,
            // Dependent_Task_Status: obj.DependentStatus,
            Name:
              task.label + "-" + task.value + "-" + obj.type + "-" + dataFor,
            Type: obj.type,
            Current_Project_ID: taskData.project_id,
            Current_Project_Name: taskData.project_name,
            Current_Task_ID: taskData.id_string,
            Current_Task_Name: taskData.name,
            Dependent_Task_Status: obj.DependentStatus,
            Current_Task_Status: obj.current_status.map((one) => one.label),
            Related_Project_ID: task.project.id,
            Related_Project_Name: task.project.name,
            Related_Task_ID: task.value,
            Related_Task_Name: task.label,
            // Related_Task_Status: obj.related_status.map((one) => one.label)
          };
          doArr.push(
            ModuleService.CreateModuleData("Task_Dependency", dependencyObj)
          );
          let blockingObj = {
            Data_For: dataFor,
            Name: task.label + "-" + task.value + "-Predecessor",
            Type: "Predecessor",
            Related_Project_ID: taskData.project_id,
            Related_Project_Name: taskData.project_name,
            Related_Task_ID: taskData.id_string,
            Related_Task_Name: taskData.name,
            Related_Task_Status: obj.current_status.map((one) => one.label),
            Dependent_Task_Status: obj.DependentStatus,

            Current_Project_ID: task.project.id,
            Current_Project_Name: task.project.name,
            Current_Task_ID: task.value,
            Current_Task_Name: task.label,
            // Current_Task_Status: obj.related_status.map((one) => one.label)
          };
          doArr.push(
            ModuleService.CreateModuleData("Task_Dependency", blockingObj)
          );
        }
      });
    }
    Promise.all(doArr)
      .then((result) => {
        if (props.getTaskDependencyData) {
          props.getTaskDependencyData();
        }
        getTaskData(projectId, taskData.project_name, taskData.id_string);
        setDependencySuccesseeDrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSetSuccesse = (projectId, dataFor) => {
    const obj = { ...dependencyObj };
    let doArr = [];
    if (obj.Task) {
      obj.Task.forEach((task) => {
        let dependencyObj = {
          Data_For: dataFor,
          Type: obj.type,
          Name: task.label + "-" + task.value + "-" + obj.type + "-" + dataFor,
          Current_Project_ID: taskData.project_id,
          Current_Project_Name: taskData.project_name,
          Current_Task_ID: taskData.id_string,
          Dependent_Task_Status: obj.DependentStatus,
          Current_Task_Name: taskData.name,
          Current_Task_Status: obj.current_status.map((one) => one.label),
          Related_Project_ID: task.project.id,
          Related_Project_Name: task.project.name,
          Related_Task_ID: task.value,
          Related_Task_Name: task.label,
          // Related_Task_Status: obj.related_status.map((one) => one.label)
        };
        doArr.push(
          ModuleService.CreateModuleData("Task_Dependency", dependencyObj)
        );
      });
    }
    Promise.all(doArr)
      .then((result) => {
        if (props.getTaskDependencyData) {
          props.getTaskDependencyData();
        }
        getTaskData(projectId, taskData.id_string);
        setDependencySuccesseeDrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDependencyStatus = () => {
    let status = [...taskLayout.status_details];
    let arr = [];
    status.forEach((one) => {
      if (one.name !== "Pending") {
        arr.push({ label: one.name, value: one.id, color: one.color });
      }
    });
    return arr;
  };

  const addUpdateSucceseeDepdencyInTask = () => {
    return (
      <Modal
        isOpen={dependencySuccesseeDrop}
        backdrop={backdrop}
        size="md"
        toggle={dependencySuccesseeDrop}
        className="taskComplete-warning dependency-modal"
      >
        <ModalBody className="px-4 py-5">
          <Row>
            <Col sm={12}>
              <h5 className="mb-3">
                {dependencyEditFlag ? "Edit" : "Add"} Succession
              </h5>
            </Col>
          </Row>

          <Row>
            <Col sm={3} className="text-left small">
              Current Task Status
            </Col>
            <Col sm={9}>
              <Select
                isMulti
                options={getDependencyStatus()}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(value) => {
                  const obj = { ...dependencyObj };
                  obj.current_status = value;
                  setDependencyObj(obj);
                }}
                styles={customStyles}
                allowSelectAll={true}
                value={dependencyObj ? dependencyObj.current_status : []}
                menuPlacement="auto"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Deal
            </Col>
            <Col sm={9}>
              <select
                value={dependencyObj.deal}
                onChange={(e) => {
                  const obj = { ...dependencyObj };
                  obj.deal = e.target.value;
                  setDependencyObj(obj);
                }}
                name="Project"
                className="form-control form-control-sm"
              >
                {dealList.map((one) => {
                  return <option value={one.id}>{one.Deal_Name}</option>;
                })}
              </select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Pipeline
            </Col>
            <Col sm={9}>
              <select
                value={dependencyObj.pipeline}
                onChange={(e) => {
                  const obj = { ...dependencyObj };
                  obj.pipeline = e.target.value;
                  setDependencyObj(obj);
                  TaskService.GetProjectTask(e.target.value)
                    .then((tasks) => {
                      // const obj = { ...dependencyObj }
                      // obj.pipeline = e.target.value;
                      // setDependencyObj(obj);
                      tasks = tasks.filter(
                        (one) => one.id_string !== taskData.id_string
                      );
                      tasks = tasks.map((one) => {
                        return { label: one.name, value: one.id_string };
                      });
                      setDependencyTaskList(tasks);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                name="Project"
                className="form-control form-control-sm"
              >
                {props.allProject &&
                  props.allProject.map((one) => {
                    let pid = "";
                    let filterDeal = dealList.filter(
                      (one) => one.id == dependencyObj.deal
                    );
                    pid = filterDeal.length > 0 ? filterDeal[0].Project_ID : "";
                    let clientFilter = clientData.filter(
                      (client) =>
                        one.Account_Name && client.id == one.Account_Name.id
                    );
                    if (clientFilter.length > 0) {
                      pid = pid + "," + clientFilter[0].Project_ID;
                    }
                    if (pid) {
                      pid = pid.split(",");
                      pid = pid.filter((id) => id);
                      if (pid.includes(one.id_string)) {
                        return (
                          <option value={one.id_string}>
                            {getGroupVal(one.name)}
                          </option>
                        );
                      }
                    }
                  })}
              </select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Succession Type
            </Col>
            <Col sm={9}>
              <select
                value={dependencyObj ? dependencyObj.type : ""}
                onChange={(e) => {
                  const obj = { ...dependencyObj };
                  obj.type = e.target.value;
                  setDependencyObj(obj);
                  setDependencyType(e.target.value);
                }}
                className="form-control form-control-sm dependency-input"
              >
                <option>-- select type --</option>
                <option value={"Successor"}>Successor</option>
                <option value={"Predecessor"}>Predecessor</option>
              </select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Related Task
            </Col>
            <Col sm={9}>
              <Select
                isMulti
                options={dependencyTaskList}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(value) => {
                  const obj = { ...dependencyObj };
                  obj.Task = value;
                  setDependencyObj(obj);
                }}
                styles={customStyles}
                allowSelectAll={true}
                value={dependencyObj ? dependencyObj.Task : []}
                menuPlacement="auto"
              />
            </Col>
          </Row>

          {/* <Row className='mt-2'>
          <Col sm={3} className='text-left small'>
            Destination Task Status
          </Col>
          <Col sm={9} >
            <Select
              isMulti
              options={getDependencyStatus()}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(value) => {
                const obj = { ...dependencyObj }
                obj.related_status = value;
                setDependencyObj(obj);
              }}

              styles={customStyles}
              allowSelectAll={true}
              value={dependencyObj ? dependencyObj.related_status : []}
              menuPlacement="auto"
            />
          </Col>
        </Row> */}

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Related Task Status
            </Col>
            <Col sm={9}>
              <select
                value={dependencyObj ? dependencyObj.DependentStatus : ""}
                onChange={(e) => {
                  const obj = { ...dependencyObj };
                  obj.DependentStatus = e.target.value;
                  setDependencyObj(obj);
                  setDependencyType(e.target.value);
                }}
                className="form-control form-control-sm dependency-input"
              >
                <option>-- select status --</option>
                {taskLayout.status_details.map((type) => (
                  <option value={type.id}>{type.name}</option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={6}>
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  handleSetSuccession(taskData.project_id, "Succession");
                }}
              >
                {dependencyEditFlag ? "Update" : "Save"}
              </button>
            </Col>
            <Col sm={6}>
              <button
                onClick={() => {
                  setDependencySuccesseeDrop(false);
                  setDependencyEditFlag(false);
                }}
                className="btn btn-secondary w-100"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const addUpdateDependencyInTask = () => {
    return (
      <Modal
        isOpen={dependencyDrop}
        backdrop={backdrop}
        size="md"
        toggle={dependencyDrop}
        className="taskComplete-warning dependency-modal"
      >
        <ModalBody className="px-4 py-5">
          <Row>
            <Col sm={12}>
              <h5 className="mb-3">
                {dependencyEditFlag ? "Edit" : "Add"} Dependency
              </h5>
            </Col>
          </Row>

          <Row>
            <Col sm={3} className="text-left small">
              Current Task Status
            </Col>
            <Col sm={9}>
              {/* <CustomMultiSelect
              options={[{name:'T1'}]}
              values={[]}
              id="dependency-task-list"

            /> */}
              <Select
                isMulti
                options={getDependencyStatus()}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                // components={{ Option, MultiValue, animatedComponents }}
                onChange={(value) => {
                  const obj = { ...dependencyObj };
                  obj.current_status = value;
                  setDependencyObj(obj);
                }}
                // onInputChange={(text) => {
                //   TaskService.SaerchInProject(props.dealObj.Project_ID, 'tasks', text, 200, 0)
                //     .then((result) => {
                //       let taskArr = []
                //       result.forEach((one) => {
                //         taskArr.push({ label: one.name, value: one.id_string })
                //       })
                //       setMyTaskList(taskArr)
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //     })
                // }}
                styles={customStyles}
                allowSelectAll={true}
                value={dependencyObj ? dependencyObj.current_status : []}
                menuPlacement="auto"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Deal
            </Col>
            <Col sm={9}>
              <select
                value={dependencyObj.deal}
                onChange={(e) => {
                  const obj = { ...dependencyObj };
                  obj.deal = e.target.value;
                  setDependencyObj(obj);
                }}
                name="Project"
                className="form-control form-control-sm"
              >
                {dealList.map((one) => {
                  return <option value={one.id}>{one.Deal_Name}</option>;
                })}
              </select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Pipeline
            </Col>
            <Col sm={9}>
              <select
                value={dependencyObj.pipeline}
                onChange={(e) => {
                  const obj = { ...dependencyObj };
                  obj.pipeline = e.target.value;
                  setDependencyObj(obj);
                  TaskService.GetProjectTask(e.target.value)
                    .then((tasks) => {
                      // const obj = { ...dependencyObj }
                      // obj.pipeline = e.target.value;
                      // setDependencyObj(obj);
                      tasks = tasks.filter(
                        (one) => one.id_string !== taskData.id_string
                      );
                      tasks = tasks.map((one) => {
                        return { label: one.name, value: one.id_string };
                      });
                      setDependencyTaskList(tasks);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                name="Project"
                className="form-control form-control-sm"
              >
                {props.allProject &&
                  props.allProject.map((one) => {
                    let pid = "";
                    let filterDeal = dealList.filter(
                      (one) => one.id == dependencyObj.deal
                    );
                    pid = filterDeal.length > 0 ? filterDeal[0].Project_ID : "";
                    let clientFilter = clientData.filter(
                      (client) =>
                        one.Account_Name && client.id == one.Account_Name.id
                    );
                    if (clientFilter.length > 0) {
                      pid = pid + "," + clientFilter[0].Project_ID;
                    }
                    if (pid) {
                      pid = pid.split(",");
                      pid = pid.filter((id) => id);
                      if (pid.includes(one.id_string)) {
                        return (
                          <option value={one.id_string}>
                            {getGroupVal(one.name)}
                          </option>
                        );
                      }
                    }
                  })}
              </select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Dependency Type
            </Col>
            <Col sm={9}>
              <select
                value={dependencyObj ? dependencyObj.type : ""}
                onChange={(e) => {
                  const obj = { ...dependencyObj };
                  obj.type = e.target.value;
                  setDependencyObj(obj);
                  setDependencyType(e.target.value);
                }}
                className="form-control form-control-sm dependency-input"
              >
                <option>-- select type --</option>
                {dependencyTypeZoho.map((type) => (
                  <option value={type.value}>{type.name}</option>
                ))}
              </select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Related Task
            </Col>
            <Col sm={9}>
              {/* <CustomMultiSelect
              options={[{name:'T1'}]}
              values={[]}
              id="dependency-task-list"

            /> */}
              <Select
                isMulti
                options={dependencyTaskList}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                // components={{ Option, MultiValue, animatedComponents }}
                onChange={(value) => {
                  const obj = { ...dependencyObj };
                  obj.Task = value;
                  setDependencyObj(obj);
                }}
                // onInputChange={(text) => {
                //   TaskService.SaerchInProject(props.dealObj.Project_ID, 'tasks', text, 200, 0)
                //     .then((result) => {
                //       let taskArr = []
                //       result.forEach((one) => {
                //         taskArr.push({ label: one.name, value: one.id_string })
                //       })
                //       setMyTaskList(taskArr)
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //     })
                // }}
                styles={customStyles}
                allowSelectAll={true}
                value={dependencyObj ? dependencyObj.Task : []}
                menuPlacement="auto"
              />
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={3} className="text-left small">
              Related Task Status
            </Col>
            <Col sm={9}>
              {/* <CustomMultiSelect
              options={[{name:'T1'}]}
              values={[]}
              id="dependency-task-list"

            /> */}
              <Select
                isMulti
                options={getDependencyStatus()}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                // components={{ Option, MultiValue, animatedComponents }}
                onChange={(value) => {
                  const obj = { ...dependencyObj };
                  obj.related_status = value;
                  setDependencyObj(obj);
                }}
                styles={customStyles}
                allowSelectAll={true}
                value={dependencyObj ? dependencyObj.related_status : []}
                menuPlacement="auto"
              />
            </Col>
          </Row>

          {/* <Row className='mt-2'>
          <Col sm={3} className='text-left small'>
            Dependent Task Status
          </Col>
          <Col sm={9} >

            <select value={dependencyObj ? dependencyObj.DependentStatus : ''} onChange={(e) => {
              const obj = { ...dependencyObj }
              obj.DependentStatus = e.target.value;
              setDependencyObj(obj);
              setDependencyType(e.target.value)
            }} className="form-control form-control-sm dependency-input">
              <option>-- select status --</option>
              {taskLayout.status_details.map((type) => (
                <option value={type.id}>{type.name}</option>
              ))}
            </select>
          </Col>
        </Row> */}

          <Row className="mt-3">
            <Col sm={6}>
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  if (dependencyObj.Task && dependencyObj.Task.length > 0) {
                    handleSetDependency(taskData.project_id, "Dependency");
                  } else {
                    swal("please select dependency task", { dangerMode: true });
                  }
                }}
              >
                {dependencyEditFlag ? "Update" : "Save"}
              </button>
            </Col>
            <Col sm={6}>
              <button
                onClick={() => {
                  setDependencyDrop(false);
                  setDependencyEditFlag(false);
                }}
                className="btn btn-secondary w-100"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const checkCheckTaskStatus = async (task, status) => {
    let childTask = [];
    let blockingTask = [];
    props.taskDocs.forEach((one) => {
      one.DependentOn.forEach((d) => {
        if (d.id == task._id && d.type == "Blocking") {
          if (
            isMatchStatus(status.name, d.Status) &&
            isMatchStatus(one.Status.name, d.DependencyStatus)
          ) {
            blockingTask.push(one);
          }
        }
      });
    });
    let waitingTask = [];
    task.DependentOn.forEach((d) => {
      props.taskDocs.forEach((one) => {
        if (d.id == one._id && d.type == "Waiting-On") {
          if (!isMatchStatus(one.Status.name, d.Status)) {
            if (isMatchStatus(status.name, d.DependencyStatus)) {
              waitingTask.push(one);
            }
          }
        }
      });
    });
    //  taskDocs.forEach((one)=>{
    //   one.DependentOn.forEach(d=>{
    //      if((d.id==task._id)&&(d.type=='Waiting-On')){
    //        if(!isMatchStatus(status.name,d.Status)&&!isMatchStatus(one.Status.name,d.DependencyStatus)){
    //         waitingTask.push( one);
    //        }
    //      }
    //    })
    //  })
    childTask = [...blockingTask, ...waitingTask];
    childTask = _.unionBy(childTask, "_id");
    if (childTask.length > 0) {
      setChildTask(childTask);
      return true;
      // let filterStatus = childTask.filter(one => one.Status.name !== 'COMPLETED')
      // if (filterStatus.length > 0) {
      //   setChildTask(filterStatus);
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return false;
    }
  };

  const updateMilestone = async (projectId, taskId, fromListId, toListId) => {
    TaskService.MoveTask(projectId, taskId, fromListId)
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTask = async (taskId, key, data) => {
    if (props.taskAction == "Create") {
      if (key == "custom_fields") {
        let obj = { ...taskData };
        let parse = JSON.parse(data);
        Object.keys(parse).forEach((key1) => {
          let obj1 = {
            column_name: key1,
            label_name: customeFields[key1],
            value: parse[key1],
          };
          if (obj[key]) {
            obj[key].push(obj1);
          } else {
            obj[key] = [obj1];
          }
        });
        setTaskData(obj);
      } else {
        let obj = { ...taskData };
        obj[key] = data;
        setTaskData(obj);
      }
    } else {
      TaskService.UpdateTask(taskData.project_id, taskId, key, data)
        .then((result) => {
          if (result) {
            console.log("resulttttttttttttttttt", result);
            result.custom_fields = taskData.custom_fields;
            result.project_id = taskData.project_id;
            setTaskData(result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // const addAssigneeImgTblImg = (assignee) => {
  //   if(getAssigneeImg(assignee.userId)!=""){
  //     return <img
  //     src={getAssigneeImg(assignee.userId)}
  //     className="rounded-circle selected-avtar"
  //   />
  //   }
  //   else{
  //     return <div className='no-image-user'>{assignee.userName.charAt(0)}</div>
  //   }
  //   // return (
  //   //   <img
  //   //     src={getAssigneeImg(assignee.userId)}
  //   //     className="rounded-circle selected-avtar"
  //   //   />
  //   // );
  // };

  const addAssigneeImgTblImg = (assignee) => {
    return (
      <img
        src={getAssigneeImg(assignee.userId)}
        className="rounded-circle selected-avtar"
      />
    );
  };

  const addTaskDropdownMenuItem = (user, tasks) => {
    return addDropdownMenuItem(
      "ADD_TASK_BY_LIST",
      user,
      tasks,
      NOT_DEFINED_VALUE,
      NOT_DEFINED_VALUE
    );
  };

  const addSubTaskDropdownMenuItem = (user, tasks, subTask) => {
    return addDropdownMenuItem(
      "ADD_SUBTASK_BY_LIST",
      user,
      tasks,
      subTask,
      NOT_DEFINED_VALUE
    );
  };

  const addMetaTaskDropdownMenuItem = (user, tasks, subTask, metaTask) => {
    return addDropdownMenuItem(
      "ADD_METASUBTASK_BY_LIST",
      user,
      tasks,
      subTask,
      metaTask
    );
  };

  const addDropdownMenuItem = (oprtnType, user, tasks, subtask, metaTask) => {
    let userName = user.first_name + " " + user.last_name;
    return (
      <DropdownItem
        value={userName}
        onClick={(e) => {
          selectedTask = tasks;
          selectedSubTask = subtask;
          selectedMeataTask = metaTask;
          setSelectedTaskId({ task: tasks });
          addRemoveTblListAssignee(userName, user.user_id, oprtnType);
        }}
      >
        {user.ProfilePhoto && user.ProfilePhoto !== "" ? (
          <>
            <img
              src={user.ProfilePhoto}
              //alt={avtar}
              className="mr-1 rounded-circle width25"
            />
          </>
        ) : (
          <>
            <div className="no-image-user">{userName.charAt(0)}</div>
          </>
        )}
        {userName}
      </DropdownItem>
    );
  };

  const getDropdownMenu = (
    tasks,
    fnName,
    assignee,
    oprationType,
    subTask,
    metaTask
  ) => {
    return (
      <UncontrolledDropdown className="transparent-dropdown assignee-dropdown">
        <DropdownToggle>
          {assignee === null ? fnName() : fnName(assignee)}
        </DropdownToggle>

        <DropdownMenu>
          {allUsers.map((user, i) =>
            oprationType === "TASK_DROPDOWN_MENU"
              ? addTaskDropdownMenuItem(user, tasks)
              : oprationType === "SUBTASK_DROPDOWN_MENU"
              ? addSubTaskDropdownMenuItem(user, tasks, subTask)
              : addMetaTaskDropdownMenuItem(user, tasks, subTask, metaTask)
          )}
          {clearAssigneeTag(oprationType, tasks, subTask, metaTask)}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const clearAssigneeTag = (oprationType, tasks, subTask, metaTask) => {
    return (
      <DropdownItem
        value=""
        onClick={() => {
          selectedTask = tasks;
          selectedSubTask = subTask;
          selectedMeataTask = metaTask;
          clearAllTblListAssgnee(oprationType);
        }}
      >
        {" "}
        <FontAwesomeIcon
          icon={faTimes}
          style={{
            color: "red",
            marginRight: "10px",
          }}
        />
        CLEAR
      </DropdownItem>
    );
  };

  const changeGroupBy = (e) => {
    setGroupBy(e.target.textContent);
    getGroupByFields(allTasks, e.target.textContent);
    filtertasktoggle();
    // e.preventDefault();
  };

  const generateAssignee = () => {
    assigneDropState.forEach((asgnData) => {
      if (asgnData.userId === "") {
        asgnData.userId = localStorage.getItem("UserId");
        asgnData.userName = localStorage.getItem("UserName");
      }
    });
    return assigneDropState;
  };

  const isAllMandatoriesValid = (operationType) => {
    let flag = true;
    if (operationType !== "updateList") {
      if (taskName.length === 0 || description.length === 0) {
        flag = false;
      }
    }
    return flag;
  };

  const prepareTaskData = () => {
    var id = "";
    if (selectedTaskId.task != undefined) {
      id = selectedTaskId.task._id;
    }
    let taskData = {
      _id: id,
      DealsId: DealsId,
      Status: taskStatus,
      TaskName: taskName,
      DependentOn: taskDependency,
      Description: description,
      //Assignee: generateAssignee(),
      Assignee: assigneDropState,
      Category: taskCategory,
      StartDate: selectionRange[0].startDate,
      DueDate: selectionRange[0].endDate,
      Priority: priorityValue,
      Subtask: subTasks,
      TimeEstimateValue:
        parseInt(timeEstimateValue) <= 0
          ? timeEstimateValue + ":" + timeMinuteValue.toString()
          : timeEstimateValue + ":" + timeMinuteValue,
      TimeTrack: currentData,
      Checklist: newTask ? CheckList : currentCheckList,
      AttachedFiles: AttachedFiles,
    };
    return taskData;
  };

  const [taskUpdateWhere, setTaskUpdateWhere] = useState("");

  const saveUpdateTaskData = async (operationType) => {
    // const taskData = {
    //   _id: id,
    //   DealsId: DealsId,
    //   Status: taskStatus,
    //   TaskName: taskName,
    //   Description: description,
    //   //Assignee: generateAssignee(),
    //   Assignee: assigneDropState,
    //   Category: taskCategory,
    //   StartDate: selectionRange[0].startDate,
    //   DueDate: selectionRange[0].endDate,
    //   Priority: priorityValue,
    //   Subtask: subTasks,
    //   TimeEstimateValue:
    //     parseInt(timeEstimateValue) <= 0
    //       ? timeMinuteValue.toString()
    //       : timeEstimateValue + ":" + timeMinuteValue,
    //   TimeTrack: currentData,
    //   Checklist: newTask ? CheckList : currentCheckList,
    //   AttachedFiles: AttachedFiles,
    // };
    let isvalidData = isAllMandatoriesValid(operationType);
    if (isvalidData && openmodalType === "task") {
      let data = prepareTaskData();
      setUpdateTaskData(data);
      addUpdateTaskApi(data, data);
      // if(taskActionType=='subtask'){

      // }else{
      //   addUpdateTaskApi(data1?data1:data, data);
      // }
    } else if (openmodalType == "subtask") {
      let data = prepareTaskData();
      setUpdateTaskData(data);

      addUpdateTaskApi(data, data);
      // alert("Mandatory fields missing ");
    } else if (openmodalType == "metatask") {
      let data = prepareTaskData();
      setUpdateTaskData(data);

      addUpdateTaskApi(data, data);
    } else {
      swal("Mandatory fields missing ", { dangerMode: true });
    }
    // addUpdateTaskApi(taskData);
  };

  const addDefaultDependency = async (parentTask, subtask) => {
    const parent = { ...parentTask };
    const sub = { ...subtask };
    let dependency1 = {
      DependencyStatus: dependencyStatusWaiting,
      id: sub._id,
      type: "Waiting-On",
      TaskName: sub.TaskName,
      Status: selectedDependencyStatusWaiting,
    };
    let dependency2 = {
      DependencyStatus: dependencyStatusBlocking,
      id: parent._id,
      type: "Blocking",
      TaskName: parent.TaskName,
      Status: selectedDependencyStatusBlocking,
    };
    parent.DependentOn.push(dependency1);
    sub.DependentOn.push(dependency2);
    let doArr = [];
    doArr.push(updateTask(parent));
    doArr.push(updateTask(sub));
    await Promise.all(doArr);
  };

  const addUpdateTaskApi = async (taskData, taskAction) => {
    if (props.taskAction == "Create" && !taskData._id) {
      if (taskData.TaskName == "") {
        props.handleCloseTaskModel(props.taskType);
        //swal('please fill the mandatory taskname and description fields', { dangerMode: true });
        return;
      }
      // if (isTaskAlreadyExist(taskData.TaskName)) {
      //   swal('task name already exist in this deal ', { dangerMode: true });
      //   return
      // }
      let formData = new FormData();
      // if (props.taskType == 'task' || props.taskType == 'metatask') {
      //   taskData.Category = props.task.Category;
      // }
      if (props.taskType == "subtask") {
        //taskData.Category = props.task.Category;
        taskData.Pipeline = props.task.Pipeline;
        taskData.GrpID = props.task.GrpID;
      }
      if (props.taskType == "metatask") {
        //taskData.Category = props.subtask.Category;
        taskData.Pipeline = props.subtask.Pipeline;
        taskData.GrpID = props.subtask.GrpID;
      }

      if (!taskData.Pipeline && taskData.Pipeline !== "") {
        taskData.Pipeline = "NO PIPELINE";
      }

      formData.append("data", JSON.stringify(taskData));
      try {
        let status = await HttpService.CreateUpdate("addtask", formData, {})
          .then(async (res) => {
            if (res.data.Ack == 1) {
              let obj = {};
              obj.TaskId = res.data.Data._id;
              obj.TaskName = res.data.Data.TaskName;
              obj.DealId = res.data.Data.DealsId;
              obj.TaskType =
                props.taskType == "task"
                  ? 0
                  : props.taskType == "subtask"
                  ? 1
                  : 2;
              obj.ParentTaskId =
                props.taskType == "task"
                  ? null
                  : props.taskType == "subtask"
                  ? props.task._id
                  : props.subtask._id;
              if (props.taskType == "task") {
                props.setParentObject(res.data.Data);
              } else if (props.taskType == "subtask") {
                props.setSubtaskData(res.data.Data);
              } else {
                props.setMetaTaskData(res.data.Data);
              }

              if (taskAction) {
                props.setTaskAction(taskAction);
              }

              if (props.taskType == "task") {
                props.setMainTaskId(res.data.Data._id);
              }

              if (props.taskType == "subtask") {
                addDefaultDependency(props.task, res.data.Data);
              }
              if (props.taskType == "metatask") {
                addDefaultDependency(props.subtask, res.data.Data);
              }

              await addActivity(
                "ADD",
                "TASK",
                res.data.Data._id,
                res.data.Data.TaskName,
                obj.TaskType
              );
              return HttpService.CreateUpdate("addTaskRelation", obj);
            }
          })
          .then((result) => {
            return props.getallTasks();
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      updateSubTask(taskData)
        .then((result) => {})
        .catch((err) => {
          swal(`${err}`);
        });
    }
  };

  const updateSubTask = async (data) => {
    data = { ...data };
    data.Subtask = [];
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));

    HttpService.CreateUpdate("updatetask", formData)
      .then((res) => {
        if (res.data.Ack == 1) {
          setTaskData(data);
          props.getallTasks();
        }
      })
      // .then((result)=>{

      // })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      });
  };

  const getTime = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  // const getDate = (date) => {
  //   date = new Date(date);
  //   let str = '';
  //   str = str + date.toLocaleString('en-us', { month: 'short' });
  //   str = str + " " + date.getDate();

  //   return str;
  // }

  // const getFullDate = (date) => {
  //   let date1 = new Date(date);
  //   return (
  //     (date1.getMonth() + 1 < 10 ? "0" : "") +
  //     (date1.getMonth() + 1) +
  //     "/" +
  //     (date1.getDate() < 10 ? "0" : "") +
  //     date1.getDate() +
  //     "/" +
  //     date1.getFullYear()
  //   );
  // };

  const getFullDate = (date) => {
    let date1 = new Date(date);
    // if(new Date().getFullYear()==date1.getFullYear()){
    //   return ''
    // }
    return date1.getFullYear();
  };

  function getDate(date) {
    let dateObj = new Date(date);
    let d = dateObj.toString().split(" ");
    let month = d[1]; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    //return day + "/" + month + "/" + year;
    // if (year === now.years()) {
    //   return month + " " + day;
    // } else {

    return month + " " + day;
    // }
  }

  const addSubTask = async (data, taskType, id) => {
    data = { ...data };
    delete data.SubtaskId;
    // if(isTaskAlreadyExist(data.TaskName)){
    //   swal('task name already exist in this deal');
    //   return
    // }
    if (!isTaskAlreadyExist(data.TaskName)) {
      let formData = new FormData();

      formData.append("data", JSON.stringify(data));
      HttpService.CreateUpdate("addtask", formData)
        .then(async (res) => {
          if (res.data.Ack == 1) {
            let obj = {};
            obj.TaskId = res.data.Data._id;
            obj.TaskName = res.data.Data.TaskName;
            obj.DealId = res.data.Data.DealsId;
            obj.TaskType = taskType;
            obj.ParentTaskId = id ? id : parentObj._id;
            setSubTasks([res.data.Data]);
            await addActivity(
              "ADD",
              "TASK",
              res.data.Data._id,
              res.data.Data.TaskName,
              taskType
            );
            return HttpService.CreateUpdate("addTaskRelation", obj);
          }
        })
        .then((result) => {
          getallTasks();
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    } else {
      swal("task name already exist in this deal ", { dangerMode: true });
    }
  };

  const saveUpdateSubTaskData = async () => {
    // var subtask = selectedTaskId.subtask;
    var subtask = selectedSubTaskId.subtask;
    subtask.Status = taskStatus;
    subtask.TaskName = taskName;
    subtask.DependentOn = taskDependency;
    subtask.Description = description;
    subtask.Assignee = assigneDropState;
    // subtask.Category: taskCategory,
    subtask.StartDate = selectionRange[0].startDate;
    subtask.DueDate = selectionRange[0].endDate;
    subtask.Priority = priorityValue;
    subtask.Subtask = subTasks;
    subtask.TimeEstimateValue =
      parseInt(timeEstimateValue) <= 0
        ? timeMinuteValue.toString()
        : timeEstimateValue + ":" + timeMinuteValue;
    subtask.TimeTrack = currentData;
    subtask.Checklist = currentCheckList;
    var task = selectedSubTaskId.task;
    selectedSubTaskId.task.Subtask.map((st, i) => {
      if (st.SubtaskId == subtask.SubtaskId) {
        task.Subtask[i] = subtask;
      }
    });

    setUpdateTaskData(subtask);
    addUpdateTaskApi(task, subtask);
  };

  // const [updateTask,setUpdateTask]=useState(null);
  const [updateTaskData, setUpdateTaskData] = useState(null);

  const saveUpdateMetaSubTaskData = async () => {
    var task = selectedSubTaskId.task;
    var subtask = selectedSubTaskId.subtask;
    var metatask = selectedSubTaskId.metatask;
    metatask.Status = taskStatus;
    metatask.TaskName = taskName;
    metatask.DependentOn = taskDependency;
    metatask.Description = description;
    metatask.Assignee = assigneDropState;
    metatask.StartDate = selectionRange[0].startDate;
    metatask.DueDate = selectionRange[0].endDate;
    metatask.Priority = priorityValue;
    metatask.TimeEstimateValue =
      parseInt(timeEstimateValue) <= 0
        ? timeMinuteValue.toString()
        : timeEstimateValue + ":" + timeMinuteValue;
    metatask.TimeTrack = currentData;
    metatask.Checklist = currentCheckList;
    task.Subtask.forEach((sub, i) => {
      if (sub.SubtaskId === subtask.SubtaskId) {
        sub.Subtask.forEach((meta) => {
          if (meta.SubtaskId === metatask.SubtaskId) {
            meta = metatask;
          }
        });
      }
    });

    //   selectedSubTaskId.task.Subtask.map((st,i)=>{
    //  if(st.SubtaskId==subtask.SubtaskId){
    //   st.Subtask.map((mt,j)=>{
    //     if(mt.SubtaskId==metatask.SubtaskId){
    //       task.Subtask[i].Subtask[j]=metatask;
    //     }
    //   })a
    //  }
    //   })
    setUpdateTaskData(metatask);
    addUpdateTaskApi(task, metatask);
  };

  const getDropDownStatusForNewFiels = (className, status) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle className={"badge " + className}>
          {status}
        </DropdownToggle>
        <DropdownMenu>
          {statusValues.map((value, i) => {
            return (
              <DropdownItem
                style={{
                  backgroundColor: value.color,
                  color: "#fff",
                }}
                // onClick={(e) =>
                //   updateStatus(
                //     value,
                //     tasks,
                //     null,
                //     null,
                //     "UPDATE_TASK_STATUS"
                //   )
                // }
              >
                {value.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const getSpaceBetweenCamelString = (string) => {
    string = string.replace(/([A-Z])/g, " $1").trim();
    return string;
  };

  const [addEditField, setAddEditFiels] = useState(false);

  const addEditFieldToggle = () => setAddEditFiels(!addEditField);
  const [openExistModal, setOpenExistModal] = useState(false);

  const existModalToggle = () => {
    setOpenExistModal(!openExistModal);
  };

  const getTotalCustomeTaskEmphtyField = () => {
    let i = 0;
    taskCustumeFieldList.forEach((one) => {
      if (!one.Value) {
        i = i + 1;
      }
    });
    return i;
  };

  const GetAndCheckTaskFieldMandtoryToComplete = async (taskId) => {
    const backendUrl = Config.backend_url;
    // let res = await HttpService.CreateUpdate('getTaskFields', { TaskId: taskId });
    let res = await axios.post(backendUrl + "getTaskFields", {
      TaskId: taskId,
    });
    let result = false;
    let filterTaskMandat = res.data.Data.filter((one) => {
      if (one.Config) {
        if (one.Config.Mandatory && (!one.Value || one.Value.Value == "")) {
          return one;
        }
      }
    });
    if (filterTaskMandat.length > 0) {
      result = true;
      setTaskCustumFieldList(res.data.Data);
    } else {
      result = false;
    }
    return result;
  };

  const getTaskFieldsForThisTask = (taskId) => {
    const backendUrl = Config.backend_url;
    // HttpService.CreateUpdate('getTaskFields', { TaskId: taskId })
    axios
      .post(backendUrl + "getTaskFields", { TaskId: taskId })
      .then((result) => {
        if (result.data.Data) {
          setTaskCustumFieldList(result.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetAllTaskCustumeFieldList = (taskId) => {
    const backendUrl = Config.backend_url;
    // HttpService.CreateUpdate('getTaskFields', { TaskId: taskId })
    axios
      .post(backendUrl + "getTaskFields", { TaskId: taskId })
      .then((result) => {
        setTaskCustumFieldList(result.data.Data);
        let columns = [...addColumns];
        result.data.Data.forEach((one) => {
          let filterColumn = columns.filter(
            (obj) => obj.headerName == one.Name
          );
          if (filterColumn.length == 0) {
            let obj = { headerName: one.Name, isVisible: false };
            columns.push(obj);
          }
        });
        setAddColumns(columns);
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      });
  };

  const [openCustomFields, setOpenCustomFields] = useState(false);

  const customFieldsToggle = () => {
    setOpenCustomFields(!openCustomFields);
    setTaskFieldExist(false);
  };
  // const [addEditField,setAddEditFiels]=useState(false);

  // const addEditFieldToggle=()=>setAddEditFiels(!addEditField);
  const [delTooltipOpen, setDelTooltipOpen] = useState(false);

  const delToggle = () => setDelTooltipOpen(!delTooltipOpen);

  const [editTooltipOpen, setEditTooltipOpen] = useState(false);

  const editToggle = () => setEditTooltipOpen(!editTooltipOpen);

  const handleTaskFieldDelete = (taskFieldObj) => {
    HttpService.CreateUpdate("deleteTaskField", {
      _id: taskFieldObj._id,
      TaskId: taskFieldObj.TaskId,
    })
      .then((result) => {
        GetAllTaskCustumeFieldList(taskFieldObj.TaskId);
        getAllTaskInputFields();
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      });
    // if (!taskFieldObj.Assign && taskFieldObj.Assign.length == 0) {

    // } else {
    //   notification.warn({
    //     duration: 20,
    //     message: 'Task Fields Delete Warning ',
    //     description:
    //       taskFieldObj.Name + ' already in use in this task  ' + taskFieldObj.TaskName,
    //   });
    // }
  };
  const [lookUpSearch, setLookUpSearch] = useState(null);

  const [openTimeEstimatePopUp, setOpenTimeEstimatePopUp] = useState(false);
  const [addLookFieldsPopUp, setAddLookupFieldsPopUp] = useState(false);
  const addLookUpFieldsToggle = () =>
    setAddLookupFieldsPopUp(!addLookFieldsPopUp);
  const handleClickAddLookUpFields = () => {
    setLookUpModal(true);
    setLookUpSearch(null);
  };

  const handleOnChangeSearchLookUpFields = (e) => {
    if (e.target.value == "") {
      setLookUpSearch(null);
    } else {
      setLookUpSearch(e.target.value.toLowerCase());
    }
  };

  // const addLookUpFields = (name, value) => {
  //   const parentObj = { ...taskData }
  //   const obj = { ...taskFieldObj };
  //   obj.Type = 'LookUp';
  //   obj.Name = name;
  //   obj.Label = name;
  //   obj.Value = { Value: value };
  //   obj.TaskId = parentObj._id;
  //   obj.TaskName = parentObj.TaskName;
  //   obj.DealId = parentObj.DealsId;
  //   obj.UserName = localStorage.getItem('UserName');
  //   obj.UserId = localStorage.getItem('UserId');
  //   obj.Config = taskFieldConfigObj;
  //   HttpService.CreateUpdate('createTaskField', obj)
  //     .then((result) => {

  //       GetAllTaskCustumeFieldList(parentObj._id)
  //       setTaskFieldConfigObj(null);
  //       setTaskFieldObj(null)
  //       // addLookUpFieldsToggle();
  //       setAddLookupFieldsPopUp(true);
  //       //swal('Create Success');
  //     })
  //     .catch((err) => {
  //       swal(`${err}`, { dangerMode: true });
  //     })
  // }

  const addLookUpFields = (label, name, value) => {
    const parentObj = { ...taskData };
    const obj = { ...taskFieldObj };
    obj.Type = "LookUp";
    obj.Name = name;
    obj.Label = label;
    obj.Value = { Value: value };
    obj.TaskId = parentObj._id;
    obj.TaskName = parentObj.TaskName;
    obj.DealId = parentObj.DealsId;
    obj.UserName = localStorage.getItem("UserName");
    obj.UserId = localStorage.getItem("UserId");
    obj.Config = taskFieldConfigObj;
    obj.Assign = [
      {
        DealId: parentObj.DealsId,
        TaskName: parentObj.TaskName,
        TaskId: parentObj._id,
      },
    ];
    HttpService.CreateUpdate("createTaskField", obj)
      .then((result) => {
        GetAllTaskCustumeFieldList(parentObj._id);
        setTaskFieldConfigObj(null);
        setTaskFieldObj(null);
        // addLookUpFieldsToggle();
        setAddLookupFieldsPopUp(true);
        //swal('Create Success');
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      });
  };

  const handleAttachementRemove = (one) => {
    let tempFileCollection = [...fileNames];
    tempFileCollection = tempFileCollection.filter(
      (file) => file.fileName !== one.fileName
    );
    setFileNames(tempFileCollection);
  };

  const uploadFiles = (arr) => {
    let doArr = [];
    let fileUploadArr = [];
    let names = [];
    let tempFileCollection = [...fileNames];
    tempFileCollection.forEach((obj) => {
      obj.Status = "Pending";
    });
    arr.forEach((one) => {
      names.push(one.fileName);
      doArr.push(
        addZohoTaskAttachements(taskData.project_id, taskData.id_string, one)
      );
      fileUploadArr.push(handleUploadFileWorkDrive(one));
    });

    setProgress({ active: true, percent: 1 });
    let startInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev && prev.percent < 100) {
          prev.percent = prev.percent + 1;
          setProgress(prev);
        }
      });
    }, 1);
    Promise.all(doArr)
      .then((result) => {
        console.log(result);

        return Promise.all(fileUploadArr);
      })
      .then((result) => {
        arr.forEach((one) => {
          one.Status = "Success";
        });

        setProgress({ active: true, percent: 100 });
        let filterTemp = tempFileCollection.filter(
          (e) => e.Status != "Success"
        );
        setLoader(false);
        setFileNames(filterTemp);
        clearInterval(startInterval);
        setProgress({ active: false, percent: 0 });
        alert("file upload successfully");
        // setAttachmentModal(false)
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });

    // let text = `You upload ${tempFileCollection.length} files `
    // let text = `You upload one file `
    // let filename = names.join(' , ')
    // swal({
    //   title: "Are you sure?",
    //   text: text + filename,
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {

    //   }
    // })
  };

  const renderAddLookUpFieldsModal = () => {
    return (
      <Modal
        isOpen={addLookFieldsPopUp}
        backdrop={backdrop}
        toggle={addLookUpFieldsToggle}
        size="sm"
        className="customFieldModal deal-table"
        id="manageFieldModal"
      >
        <ModalHeader className="text-center" toggle={addLookUpFieldsToggle}>
          <Row>
            <Col sm={12}>LOOKUP FIELDS</Col>
          </Row>
          <Row>
            <Col sm={12}>
              {/* <button onClick={() => handleOpenCreateField('Formula')} className="btn btn-sm btn-primary">Fx Formula</button> */}
            </Col>
          </Row>
        </ModalHeader>

        <div className="fieldSearch w-100">
          <input
            name="LookUpSearch"
            onChange={handleOnChangeSearchLookUpFields}
            className="form-control"
            type="text"
            placeholder="Search..."
          />
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <ModalBody style={{ overflowY: "auto", maxHeight: "465px" }}>
          {/* <h6 className="addCustomField-title">LOOKUP FIELDS</h6> */}

          <table className="table table-sm table-hover addCustomField-table addLookupFieldTbl">
            {/* {Object.keys(dealFields).map(key => {
            if (key == 'Services' || key == 'Positions' || key == 'Attachments' || key == 'pvSystemInfo' || key == 'wwpViolation' || key == 'zoningList' || key == 'commercialList' || key == 'specialDistList' || key == 'ContactRoles' || key == '_id' || key == '__v' || key == 'createdAt' || key == 'updatedAt' || key == 'ClientId' || taskCustumeFieldList.filter((one) => one.Name == key).length > 0) {
              return;
            }
            if (lookUpSearch) {
              if (key.toLowerCase().includes(lookUpSearch)) {
                return <tr>
                  <td>
                    <FontAwesomeIcon icon={faEye} className="field-icon" />
                    {dealFields[key]}
                  </td>
                  <td>
                    {key == 'ProjectDate' ? getFullDate(new Date(props.dealObj[key])) : props.dealObj[key]}
                  </td>
                  <td 
                //   onClick={() => 
                //   addLookUpFields(dealFields[key], key, props.dealObj[key])} 
                  style={{ cursor: 'pointer' }} className="labels">
                    <span  ><FontAwesomeIcon icon={faPlusCircle} color='red' /> Add</span>
                  </td>
                </tr>
              }
            }
            if (!lookUpSearch) {
              return <tr>
                <td>
                  <FontAwesomeIcon icon={faEye} className="field-icon" />
                  {/* {dealFields[key]} */}
            {/* {getSpaceBetweenCamelString(key)} */}
            {/* </td>
                <td>
                  {key == 'ProjectDate' ? getFullDate(new Date(props.dealObj[key])) : props.dealObj[key]}
                </td>
                <td style={{ cursor: 'pointer' }}
                //  onClick={() =>
                //   addLookUpFields(dealFields[key], key, props.dealObj[key])
                //   } 
                  className="labels">
                  <span><FontAwesomeIcon icon={faPlusCircle} color='red' /> Add</span>
                </td>
              </tr>
            }

          })} */}
          </table>
        </ModalBody>
      </Modal>
    );
  };

  const handleEditInputFields = (obj) => {
    if (obj.Type !== "LookUp") {
      if (obj.Type == "Formula") {
        setTaskFieldConfigObj(obj.Config);
        setTaskFieldAction("edit");
        setElementType(obj.Type);
        setTaskFieldObj(obj);
        setFormulaPopUp(true);
      } else {
        const optConfig = [...options];
        optConfig.splice(0, optConfig.length);
        obj = { ...obj };
        let keys = [];
        if (obj.Config) {
          keys = Object.keys(obj.Config);
        }

        let index = 0;
        keys.forEach((key) => {
          if (
            !key.includes("Mandatory") &&
            !key.includes("Color") &&
            !key.includes("IsLooKUpField") &&
            !key.includes("IsInputField")
          ) {
            index++;
            optConfig.push(index);
          }
        });
        setTaskFieldAction("edit");
        setOptions(optConfig);
        setElementType(obj.Type);
        createFieldToggle();
        setTaskFieldObj(obj);
        setTaskFieldConfigObj(obj.Config);
      }
    }
  };

  const handleOnClickRequired = (obj) => {
    obj = { ...obj };
    let config = { ...obj.Config };
    config.Mandatory = true;
    obj.Config = config;
    HttpService.CreateUpdate("updateTaskField", obj)
      .then((result) => {
        // customFieldsToggle();
        if (taskData._id) {
          GetAllTaskCustumeFieldList(taskData._id);
        }

        // swal('Create Success');
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      });
  };

  const handleClickNotrequired = (obj) => {
    obj = { ...obj };
    let config = { ...obj.Config };
    config.Mandatory = false;
    obj.Config = config;
    HttpService.CreateUpdate("updateTaskField", obj)
      .then((result) => {
        // customFieldsToggle();
        if (taskData._id) {
          GetAllTaskCustumeFieldList(taskData._id);
        }

        // swal('Create Success');
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      });
  };
  const [selectedInputFields, setSelectedInputFields] = useState([]);
  const handleSelectInputFieldsFromAvailable = (values) => {
    values = { ...values };
    if (values) {
      let obj = { ...values.obj };
      delete obj._id;
      delete obj.DealId;
      delete obj.TaskId;
      delete obj.TaskName;
      obj.TaskId = taskData._id;
      obj.TaskName = taskData.TaskName;
      obj.DealId = props.dealObj._id;
      HttpService.CreateUpdate("createTaskField", obj)
        .then((result) => {
          GetAllTaskCustumeFieldList(taskData._id);
          setTaskFieldConfigObj(null);
          setTaskFieldObj(null);
          setTaskFieldAction("");
          getAllTaskInputFields();
        })
        .catch((err) => {
          swal(`${err}`);
        });
    }
  };

  const handleUnassign = (e, obj) => {
    const backendUrl = "https://crm.srsolardesign.com:3006/api/v1/";
    const taskObj = { ...taskData };
    if (e.target.name == "Unassign") {
      obj = { ...obj };
      if (obj.Assign) {
        obj.Assign = obj.Assign.filter((one) => one.TaskId !== taskObj._id);
      } else {
        obj.Assign = null;
      }
    } else {
      obj = { ...obj };
      obj.Assign = obj.Assign ? obj.Assign : [];
      obj.Assign.push({
        DealId: props.dealObj._id,
        TaskId: taskObj._id,
        TaskName: taskObj.TaskName,
      });
    }
    // HttpService.CreateUpdate('updateTaskField', obj)
    axios
      .post(backendUrl + "updateTaskField", obj)
      .then((result) => {
        GetAllTaskCustumeFieldList(obj.TaskId);
        setTaskFieldConfigObj(null);
        setTaskFieldObj(null);
        setTaskFieldAction("");
        getAllTaskInputFields();
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };

  const handleAddLookUpFields = (obj) => {
    const taskObj = { ...taskData };
    obj = { ...obj };
    obj.Assign = obj.Assign ? obj.Assign : [];
    obj.Assign.push({
      DealId: props.dealObj._id,
      TaskId: taskObj._id,
      TaskName: taskObj.TaskName,
    });
    HttpService.CreateUpdate("updateTaskField", obj)
      .then((result) => {
        GetAllTaskCustumeFieldList(obj.TaskId);
        setTaskFieldConfigObj(null);
        setTaskFieldObj(null);
        setTaskFieldAction("");
        getAllTaskInputFields();
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };
  const AddOrEditFields = () => {
    return (
      <Modal
        isOpen={addEditField}
        backdrop={backdrop}
        toggle={addEditFieldToggle}
        size="lg"
        className="customFieldModal"
        id="manageFieldModal"
      >
        <ModalHeader
          className="centered-modal-heading"
          toggle={addEditFieldToggle}
        >
          <Row>
            <Col sm={12}>
              Manage {showLookupItems ? "Lookup" : "Input"} Fields For{" "}
              {taskData ? taskData.name : ""}
            </Col>
          </Row>
          <Row className="mr-4">
            {showLookupItems ? (
              <Col sm={12}>
                <button
                  onClick={() => handleOpenCreateField("Formula")}
                  className="btn btn-sm btn-primary"
                >
                  Fx Formula
                </button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </ModalHeader>
        <ModalBody>
          {showLookupItems ? (
            <>
              <h6 className="addCustomField-title">LOOKUP FIELDS</h6>
              <table className="table table-sm table-hover addCustomField-table">
                {taskCustumeFieldList &&
                  taskCustumeFieldList.map((one) => {
                    if (
                      one.Type == "LookUp" ||
                      one.Type == "Formula" ||
                      (one.Config && one.Config.IsLooKUpField)
                    ) {
                      return (
                        <tr>
                          <td>
                            {one.Type == "Formula" ? (
                              <span className="field-icon">Fx</span>
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="field-icon"
                              />
                            )}
                            {getSpaceBetweenCamelString(one.Label)}
                          </td>
                          <td className="actionBtns">
                            {one.Type == "Formula" ? (
                              <>
                                <FontAwesomeIcon
                                  onClick={() => handleEditInputFields(one)}
                                  icon={faPen}
                                  id="editoolTip2"
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="editoolTip2"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              <></>
                            )}
                            <FontAwesomeIcon
                              onClick={() => handleTaskFieldDelete(one)}
                              icon={faTrashAlt}
                              id="trashTip2"
                              className="lookupFieldDel"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="trashTip2"
                            >
                              Remove from this task
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </table>
              <h6 className="addCustomField-title"> AVAILABLE LOOKUP FIELDS</h6>
              <table className="table table-sm table-hover addCustomField-table">
                {allInputFields &&
                  allInputFields.map((one) => {
                    if (one.Type == "Formula" && one.Assign.length == 0) {
                      return (
                        <tr>
                          <td>
                            {one.Type == "Formula" ? (
                              <span className="field-icon">Fx</span>
                            ) : (
                              <FontAwesomeIcon
                                icon={faEye}
                                className="field-icon"
                              />
                            )}
                            {getSpaceBetweenCamelString(one.Label)}
                          </td>
                          <td className="actionBtns">
                            {one.Type == "Formula" ? (
                              <>
                                <FontAwesomeIcon
                                  onClick={() => handleEditInputFields(one)}
                                  icon={faPen}
                                  id="editTip"
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="editTip"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              <></>
                            )}
                            <FontAwesomeIcon
                              onClick={() => handleAddLookUpFields(one)}
                              color={"blue"}
                              icon={faPlusCircle}
                              id="addTip"
                              className="lookupFieldDel"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="addTip"
                            >
                              Add
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </table>

              <div>
                <button
                  onClick={handleClickAddLookUpFields}
                  className="btn btn-sm btn-primary"
                >
                  Add Lookup Fields
                </button>
              </div>
            </>
          ) : (
            <>
              <h6 className="addCustomField-title">SELECTED INPUT FIELDS</h6>
              <table className="table table-sm table-hover addCustomField-table">
                {taskCustumeFieldList ? (
                  taskCustumeFieldList.map((one) => {
                    if (one.Type == "LookUp" || one.Type == "Formula") {
                      return;
                    }
                    return (
                      <tr>
                        <td style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            class="align-middle"
                            onChange={(e) => handleUnassign(e, one)}
                            name="Unassign"
                            checked={
                              one.Assign &&
                              taskData &&
                              one.Assign.filter(
                                (obj) => obj.TaskId == taskData._id
                              ).length > 0
                            }
                            id={"unassign" + one._id}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={"unassign" + one._id}
                          >
                            Unassign
                          </UncontrolledTooltip>
                        </td>
                        <td>
                          {getDynamicFieldIcon(one.Type)}
                          {one.Label}
                        </td>
                        <td className="actionBtns">
                          <FontAwesomeIcon
                            onClick={() => handleEditInputFields(one)}
                            icon={faPen}
                            id="editoolTip1"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="editoolTip1"
                          >
                            Edit
                          </UncontrolledTooltip>
                          <FontAwesomeIcon
                            onClick={() => handleTaskFieldDelete(one)}
                            icon={faTrashAlt}
                            color="red"
                            id="trashTip1"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="trashTip1"
                          >
                            Delete
                          </UncontrolledTooltip>
                        </td>
                        {one.Config && one.Config.Mandatory ? (
                          <td className="labels label-required">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClickNotrequired(one)}
                            >
                              {one.Config.Mandatory ? "Required" : "Required"}
                            </span>
                          </td>
                        ) : (
                          <td className="labels">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleOnClickRequired(one)}
                            >
                              Required
                            </span>
                          </td>
                        )}

                        {/* <td className="labels">
                {taskFieldConfigObj &&taskFieldConfigObj.Mandatory ? (<span style={{color:"red"}}>Required</span>) : (<span>Required</span>)}
                
              </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </table>
              <h6 className="addCustomField-title">AVAILABLE INPUT FIELDS</h6>
              <table className="table table-sm table-hover addCustomField-table">
                {allInputFields ? (
                  allInputFields.map((one) => {
                    if (one.Type == "LookUp" || one.Type == "Formula") {
                      return;
                    }
                    return (
                      <tr>
                        <td style={{ width: "20px" }}>
                          <input
                            id={"Assign" + one._id}
                            class="align-middle"
                            onChange={(e) => handleUnassign(e, one)}
                            type="checkbox"
                            name="Assign"
                          />
                          <UncontrolledTooltip target={"Assign" + one._id}>
                            Assign
                          </UncontrolledTooltip>
                        </td>
                        <td>{one.Label}</td>

                        {/* <td className="labels">
                {taskFieldConfigObj &&taskFieldConfigObj.Mandatory ? (<span style={{color:"red"}}>Required</span>) : (<span>Required</span>)}
                
              </td> */}
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </table>
              {/* <select className="form-control form-control-sm">
              <option>--select from available input fields--</option>
              {allInputFields.map((one)=>{
                return <option value={one.Label}>{one.Label}  ({one.Type})</option>
              })}
            </select> */}
              <div className="manageField-btns mt-2">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setFieldsModal(!fieldsModal);

                    setTaskFieldAction("add");
                  }}
                >
                  New Input Field
                </button>
                {/* <p className="mb-0 mx-2">or</p>
          <span onClick={() => setOpenExistModal(true)}>Use an existing field</span> */}
              </div>
            </>
          )}
        </ModalBody>
        {/* <ModalFooter>
      <Button color="primary" onClick={addEditFieldToggle}>Save</Button>{' '}
      <Button color="secondary" onClick={addEditFieldToggle}>Cancel</Button>
    </ModalFooter> */}
      </Modal>
    );
  };

  const [showInputItems, setShowInputItems] = useState(false);
  const [showLookupItems, setShowLookupItems] = useState(false);

  const onClickAddOrEditCustomeFields = (type) => {
    setAddEditFiels(true);
    if (type === "inputFields") {
      setShowInputItems(true);
      setShowLookupItems(false);
    } else {
      setShowLookupItems(true);
      setShowInputItems(false);
    }
    // setTaskCustumFieldList([]);
  };

  const [createFieldPopUp, setCreateFieldPopUp] = useState(false);

  const createFieldToggle = () => {
    if (taskFieldAction == "add") {
      setOptions([1]);
    }
    setCreateFieldPopUp(!createFieldPopUp);
  };

  const [inputType, setInputType] = useState("");
  const [inputName, setInputName] = useState("");
  const [elementType, setElementType] = useState("");
  const [options, setOptions] = useState([1]);
  const handleAddOption = () => {
    const opt = [...options];
    let value = opt[opt.length - 1];
    value = parseInt(value) + 1;
    opt.push(value);
    setOptions(opt);
  };
  const [taskFieldObj, setTaskFieldObj] = useState(null);

  const handleOnChangeFieldName = (e) => {
    const { name, value } = e.currentTarget;
    const obj = { ...taskFieldObj };
    obj["Label"] = value;
    obj["Type"] = name;
    obj["Name"] = value;
    setTaskFieldObj(obj);
  };

  const [taskFieldConfigObj, setTaskFieldConfigObj] = useState(null);

  const randomColors = ["red", "blue", "green", "yellow", "#f900ea"];
  const getRandomColorHex = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    // let objKeys=Object.keys(api.JobColour[Math.floor(Math.random() * (max - min + 1)) + min])
    return randomColors[Math.floor(Math.random() * (max - min + 1)) + min];
  };

  const handleOnChangeConfig = (e) => {
    if ((e.target.id = "Dropdown")) {
      if (e.target.name == "Mandatory") {
        let obj = { ...taskFieldConfigObj };
        obj[e.target.name] = e.target.value;
        setTaskFieldConfigObj(obj);
      } else {
        if (e.target.name.includes("Option")) {
          let obj = { ...taskFieldConfigObj };
          obj[e.target.name] = e.target.value;
          // obj[e.target.name + 'Color'] = getRandomColorHex(1, 5);
          setTaskFieldConfigObj(obj);
        } else {
          let obj = { ...taskFieldConfigObj };
          obj[e.target.name] = e.target.value;
          setTaskFieldConfigObj(obj);
        }
      }
    } else {
      let obj = { ...taskFieldConfigObj };
      obj[e.target.id] = e.currentTarget.value;
      setTaskFieldConfigObj(obj);
    }
  };
  const getTitleForPopup = () => {
    if (elementType == "Dropdown") {
      if (taskFieldAction === "edit") {
        return "Update Dropdown Field";
      } else {
        return "Create Dropdown Field";
      }
    }
    if (elementType == "Number") {
      if (taskFieldAction === "edit") {
        return "Update Number Fields";
      } else {
        return "Create Number Fields";
      }
    }
    if (elementType == "Label") {
      if (taskFieldAction === "edit") {
        return "Update Label Fields";
      } else {
        return "Create Label Fields";
      }
    }
    if (elementType == "Text") {
      if (taskFieldAction === "edit") {
        return "Update Text Fields";
      } else {
        return "Create Text Fields";
      }
    }
    if (elementType == "Date") {
      if (taskFieldAction === "edit") {
        return "Update Date Fields";
      } else {
        return "Create Date Fields";
      }
    }
    if (elementType == "Checkbox") {
      if (taskFieldAction === "edit") {
        return "Update Radio Fields";
      } else {
        return "Create Radio Fields";
      }
    }
    if (elementType == "Files") {
      if (taskFieldAction === "edit") {
        return "Update Files Fields";
      } else {
        return "Create Files Fields";
      }
    }
    if (elementType == "Textarea") {
      if (taskFieldAction === "edit") {
        return "Update TextArea Fields";
      } else {
        return "Create TextArea Fields";
      }
    }
    if (elementType == "Decimal") {
      if (taskFieldAction === "edit") {
        return "Update Decimal Fields";
      } else {
        return "Create Decimal Fields";
      }
    }
    if (elementType == "Percent") {
      if (taskFieldAction === "edit") {
        return "Update Percent Fields";
      } else {
        return "Create Percent Fields";
      }
    }
    if (elementType == "Currency") {
      if (taskFieldAction === "edit") {
        return "Update Currency Fields";
      } else {
        return "Create Currency Fields";
      }
    }
    if (elementType == "LookUpField") {
      if (taskFieldAction === "edit") {
        return "Update Lookup Fields";
      } else {
        return "Create Lookup Fields";
      }
    }
    if (elementType == "AutoNumber") {
      if (taskFieldAction === "edit") {
        return "Update AutoNumber Fields";
      } else {
        return "Create AutoNumber Fields";
      }
    }
    if (elementType == "ImgUpload") {
      if (taskFieldAction == "edit") {
        return "Update Image Upload Field";
      } else {
        return "Create Image Upload Fields";
      }
    }
    if (elementType == "PhoneNumber") {
      if (taskFieldAction == "edit") {
        return "Update Phone Number Fields";
      } else {
        return "Create Phone Number Fields";
      }
    }
    if (elementType == "Email") {
      if (taskFieldAction === "edit") {
        return "Update Email Fields";
      } else {
        return "Create Email Fields";
      }
    }
  };
  const getInputFields = () => {
    if (elementType == "LookUpField") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control "
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label className="labelBold">LookUp Module</label>
            <select
              defaultValue={taskFieldConfigObj ? taskFieldConfigObj.Module : ""}
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter starting number"
              id={elementType}
              name={"Module"}
            >
              <option>Deal</option>
              <option>Task</option>
              <option>Lead</option>
              <option>Photovoltaic</option>
              <option>Inverter</option>
              <option>Electrician</option>
              <option>User</option>
            </select>
          </div>
          <div className="form-group">
            <label className="labelBold">Related List Title</label>
            <input
              type="text"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.ListTitle : ""
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter title"
              id={elementType}
              name={"ListTitle"}
            />
          </div>

          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
        </div>
      );
    } else if (elementType == "AutoNumber") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control "
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label className="labelBold">Starting Number</label>
            <input
              type="number"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.StartNumber : ""
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter starting number"
              id={elementType}
              name={"StartNumber"}
            />
          </div>
          <div className="form-group">
            <label className="labelBold">Prefix</label>
            <input
              type="text"
              defaultValue={taskFieldConfigObj ? taskFieldConfigObj.Prefix : ""}
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter prefix"
              id={elementType}
              name={"Prefix"}
            />
          </div>

          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
          {/* <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsInputField : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}
        </div>
      );
    } else if (elementType == "Number") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control "
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label className="labelBold">Max Numer Of Digit</label>
            <input
              type="number"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.MaxDigit : ""
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter max digit"
              id={elementType}
              name={"MaxDigit"}
            />
          </div>
          <div className="form-group">
            <label className="labelBold">Prefix</label>
            <input
              type="text"
              defaultValue={taskFieldConfigObj ? taskFieldConfigObj.Prefix : ""}
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter prefix"
              id={elementType}
              name={"Prefix"}
            />
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Seprator : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Seprator", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Seprator"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Display Number Seprator By (,)</label>
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
          {/* <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsInputField : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}
        </div>
      );
    } else if (elementType == "Currency") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control "
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label className="labelBold">Maximum digits allowed</label>
            <input
              type="number"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.MaxDigit : false
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter maximum digits"
              id={elementType}
              name={"MaxDigit"}
            />
          </div>
          <div className="form-group">
            <label className="labelBold">Number of decimal places</label>
            <input
              type="number"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.DecimalPlaces : false
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter decimal places"
              id={elementType}
              name={"DecimalPlaces"}
            />
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
        </div>
      );
    } else if (elementType == "Dropdown") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          {options.map((option) => {
            return (
              <div className="form-group position-relative">
                <label className="labelBold">Option {option}</label>
                <input
                  type="text"
                  value={
                    taskFieldConfigObj
                      ? taskFieldConfigObj["Option" + option]
                      : ""
                  }
                  onChange={handleOnChangeConfig}
                  id={elementType}
                  className="form-control"
                  placeholder="Enter options"
                  name={"Option" + option}
                />
                {/* <input type='color' onChange={handleOnChangeConfig} id={elementType} value={taskFieldConfigObj ? taskFieldConfigObj['Option' + option + 'Color'] : ''} name={'Option' + option + 'Color'} className="newFieldDropColor" /> */}
                {/* <select className="newFieldDropColor">
              {colors.map((color, i) => {
               return  <option value={color} style={{backgroundColor:color}}>{color}</option>
              })}

            </select> */}
                {/* <div  className="newFieldDropColor">
            <CirclePicker  />
            </div> */}
              </div>
            );
          })}
          <div style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faPlusCircle} color="blue" />
            <span
              onClick={handleAddOption}
              style={{ color: "blue", padding: 5 }}
            >
              Add new option
            </span>
          </div>
          <div>
            <input
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              type="checkbox"
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
          {/* <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldObj.IsInputField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}
        </div>
      );
    } else if (elementType == "Radio") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              placeholder="Enter radio group name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          {options.map((option) => {
            return (
              <div className="form-group position-relative">
                <label className="labelBold">Radio {option}</label>
                <input
                  type="text"
                  value={
                    taskFieldConfigObj
                      ? taskFieldConfigObj["Option" + option]
                      : ""
                  }
                  onChange={handleOnChangeConfig}
                  id={elementType}
                  className="form-control"
                  placeholder="Enter radio name..."
                  name={"Option" + option}
                />
                {/* <input type='color' onChange={handleOnChangeConfig} id={elementType} value={taskFieldConfigObj ? taskFieldConfigObj['Option' + option + 'Color'] : ''} name={'Option' + option + 'Color'} className="newFieldDropColor" /> */}
                {/* <select className="newFieldDropColor">
              {colors.map((color, i) => {
               return  <option value={color} style={{backgroundColor:color}}>{color}</option>
              })}

            </select> */}
                {/* <div  className="newFieldDropColor">
            <CirclePicker  />
            </div> */}
              </div>
            );
          })}
          <div style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faPlusCircle} color="blue" />
            <span
              onClick={handleAddOption}
              style={{ color: "blue", padding: 5 }}
            >
              Add new radio
            </span>
          </div>
          <div>
            <input
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              type="checkbox"
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
          {/* <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldObj.IsInputField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}
        </div>
      );
    } else if (elementType == "Decimal") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control "
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label className="labelBold">Maximum digits allowed</label>
            <input
              type="number"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.MaxDigit : false
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter maximum digits"
              id={elementType}
              name={"MaxDigit"}
            />
          </div>
          <div className="form-group">
            <label className="labelBold">Number of decimal places</label>
            <input
              type="number"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.DecimalPlaces : false
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter decimal places"
              id={elementType}
              name={"DecimalPlaces"}
            />
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Leading : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Leading", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Leading"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Show leading with zeroes</label>
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Trailing : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Trailing", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Trailing"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Show Trailing with zeroes</label>
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Seprator : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Seprator", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Seprator"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Display Number Seprator By (,)</label>
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
        </div>
      );
    } else if (elementType == "PhoneNumber") {
      return (
        <div>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              type="text"
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              onChange={handleOnChangeFieldName}
              className="form-control "
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label className="labelBold">Maximum digits</label>
            <input
              type="number"
              defaultValue={
                taskFieldConfigObj ? taskFieldConfigObj.MaximumDigits : false
              }
              onChange={handleOnChangeConfig}
              className="form-control"
              placeholder="Enter maximum digits"
              id={elementType}
              name={"MaximumDigitsPhn"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="form-group">
            <label className="labelBold">Field Name</label>
            <input
              style={isTaskFieldExist ? { borderColor: "red" } : {}}
              defaultValue={taskFieldObj ? taskFieldObj.Name : ""}
              type="text"
              onChange={handleOnChangeFieldName}
              className="form-control"
              placeholder="Enter name.."
              name={elementType}
            />
            {isTaskFieldExist ? (
              <p className="small text-danger">field name already exist</p>
            ) : (
              <></>
            )}
          </div>
          <div>
            <input
              type="checkbox"
              defaultChecked={
                taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false
              }
              onChange={(e) =>
                handleOnChangeConfig({
                  target: { name: "Mandatory", value: e.target.checked },
                })
              }
              placeholder="Enter name.."
              id="Mandatory"
              name={elementType}
            />
            &nbsp;&nbsp;&nbsp;<label>Required</label>
          </div>
          {/* <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsInputField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}
        </>
      );
    }
  };

  const colors = ["red", "green", "yellow", "blue", "#f900ea"];

  const getAllTaskInputFields = () => {
    const backendUrl = Config.backend_url;
    axios
      .get(backendUrl + "getAllTaskFields")
      .then((result) => {
        result.data.Data = result.data.Data.filter(
          (obj) => obj.Type !== "LookUp"
        );
        //let arr=[];
        // result.data.Data.forEach((one)=>{
        //   let obj={label:one.Label+` (${one.Type})`,value:one._id,obj:one};
        //   arr.push(obj);
        // })
        result.data.Data = result.data.Data.filter((obj) => {
          if (
            !obj.Assign ||
            obj.Assign.filter((one) => one.TaskId == taskData._id).length == 0
          ) {
            return obj;
          }
          return obj;
        });
        setAllInputFields(result.data.Data);
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };

  useEffect(() => {
    getAllTaskInputFields();
  }, []);

  const isTaskCustomeFieldExist = async (fieldName, id) => {
    let result = false;
    let keys = Object.keys(props.dealObj);
    result = keys.includes(fieldName);
    if (!result) {
      // let taskFieldList = await HttpService.CreateUpdate('getTaskFields', { DealId: id })
      let taskFieldList = await axios.post(backendUrl + "getTaskFields", {
        DealId: id,
      });
      taskFieldList = taskFieldList.data.Data;
      if (
        taskFieldList.filter(
          (one) => one.Name.toLowerCase() == fieldName.toLowerCase()
        ).length > 0
      ) {
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  };

  const [isTaskFieldExist, setTaskFieldExist] = useState(false);

  const getValueNumericExp = (exp) => {
    let evalEx = "";
    if (exp.startsWith("Min")) {
      exp = exp.replaceAll("Min", "min");
      exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
      evalEx = exp;
    } else if (exp.startsWith("Max")) {
      exp = exp.replaceAll("Max", "max");
      exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
      evalEx = exp;
    } else if (exp.startsWith("Abs")) {
      exp = exp.replaceAll("Abs", "abs");
      exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
      evalEx = exp;
    } else if (exp.startsWith("Ceil")) {
      exp = exp.replaceAll("Ceil", "ceil");
      exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
      evalEx = exp;
    } else if (exp.startsWith("Floor")) {
      exp = exp.replaceAll("Floor", "floor");
      exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
      evalEx = exp;
    } else if (exp.startsWith("Floor")) {
      exp = exp.replaceAll("Floor", "floor");
      exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
      evalEx = exp;
    } else if (exp.startsWith("Sqrt")) {
      exp = exp.replaceAll("Sqrt", "sqrt");
      exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
      evalEx = exp;
    }
    return exp;
  };

  const setEvaluateExpression = (type) => {
    let obj = { ...taskFieldConfigObj };
    if (type == "Formula") {
      let exp = obj.Expression;
      let evalEx = "";
      if (exp.startsWith("Contains")) {
        exp = exp.replaceAll("Contains", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        evalEx = exp[0] + `.includes(${exp[1]})`;
      } else if (exp.startsWith("Startswith")) {
        exp = exp.replaceAll("Startswith", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        evalEx = exp[0] + `.startsWith(${exp[1]})`;
      } else if (exp.startsWith("Endswith")) {
        exp = exp.replaceAll("Endswith", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        evalEx = exp[0] + `.endsWith(${exp[1]})`;
      } else if (exp.startsWith("Lower")) {
        exp = exp.replaceAll("Lower", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        evalEx = exp[0] + `.toLowerCase()`;
      } else if (exp.startsWith("Upper")) {
        exp = exp.replaceAll("Upper", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        evalEx = exp[0] + `.toUpperCase()`;
      } else if (exp.startsWith("Trim")) {
        exp = exp.replaceAll("Trim", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        evalEx = exp[0] + `.trim()`;
      } else if (exp.startsWith("Substring")) {
        exp = exp.replaceAll("Substring", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        let str = exp.slice(1, exp.length);
        str = str.join(",");
        evalEx = exp[0] + `.substring(${str})`;
      } else if (exp.startsWith("Tostring")) {
        exp = exp.replaceAll("Tostring", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.join("");
        evalEx = exp + `.toString()`;
      } else if (exp.startsWith("Len")) {
        exp = exp.replaceAll("Len", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        exp = exp.join("");
        evalEx = exp + `.length`;
      } else if (exp.startsWith("Concat")) {
        exp = exp.replaceAll("Concat", "");
        exp = exp.replaceAll("(", ",");
        exp = exp.replaceAll(")", ",");
        exp = exp.split(",");
        exp = exp.filter((e) => e);
        let str = exp.slice(1, exp.length);
        str = str.join(",");
        evalEx = exp[0] + `.concat(${str})`;
      } else if (exp.startsWith("Min")) {
        exp = exp.replaceAll("Min", "min");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      } else if (exp.startsWith("Max")) {
        exp = exp.replaceAll("Max", "max");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      } else if (exp.startsWith("Abs")) {
        exp = exp.replaceAll("Abs", "abs");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      } else if (exp.startsWith("Ceil")) {
        exp = exp.replaceAll("Ceil", "ceil");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      } else if (exp.startsWith("Floor")) {
        exp = exp.replaceAll("Floor", "floor");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      } else if (exp.startsWith("Floor")) {
        exp = exp.replaceAll("Floor", "floor");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      } else if (exp.startsWith("Sqrt")) {
        exp = exp.replaceAll("Sqrt", "sqrt");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      } else if (exp.startsWith("Base10Log")) {
        exp = exp.replaceAll("Base10Log", "log10");
        exp = "Math." + exp; //excelFormulaUtilities.toJavaScript(exp)
        evalEx = exp;
      }

      obj.EvalExp = evalEx;
    }
    return obj;
  };

  const handleCreateField = async (type) => {
    const parentObj = { ...taskData };
    if (taskFieldAction == "add") {
      if (!taskFieldObj || Object.keys(taskFieldObj).length == 0) {
        swal(" Field Name Required", { dangerMode: true });
        return;
      }

      const obj = { ...taskFieldObj };

      if (await isTaskCustomeFieldExist(obj.Name, parentObj.DealsId)) {
        setTaskFieldExist(true);
        return;
      } else {
        setTaskFieldExist(false);
      }

      // if (type == 'Formula') {
      //   let obj={...taskFieldObj}
      //   obj.Config=taskFieldConfigObj;
      //   let res = handleCheckSytax()
      //   if (res) {
      //     setSyntaxError("Syntax Error");
      //     return;
      //   }else{
      //     setSyntaxError(null);
      //   }
      // }
      obj.TaskId = parentObj._id;
      obj.TaskName = parentObj.TaskName;
      obj.DealId = parentObj.DealsId;
      obj.Assign = [
        {
          DealId: parentObj.DealsId,
          TaskName: parentObj.TaskName,
          TaskId: parentObj._id,
        },
      ];
      obj.UserName = localStorage.getItem("UserName");
      obj.UserId = localStorage.getItem("UserId");
      obj.Config = setEvaluateExpression(type);

      HttpService.CreateUpdate("createTaskField", obj)
        .then((result) => {
          if (type !== "Formula") {
            createFieldToggle();
            customFieldsToggle();
          }
          GetAllTaskCustumeFieldList(parentObj._id);
          setTaskFieldConfigObj(null);
          setTaskFieldObj(null);
          setTaskFieldAction("");
          getAllTaskInputFields();
          setSyntaxError(null);

          addActivity("ADD", "TaskField", obj.TaskId, obj.TaskName, 0, {
            PrevState: { Value: null, Name: "" },
            UpdatedState: { Value: null, Name: obj.Name },
          });
          // swal('Create Success');
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    }
    if (taskFieldAction == "edit") {
      // if (!taskFieldObj || Object.keys(taskFieldObj).length == 0) {
      //   swal('Task Field Name Required');
      //   return
      // }
      const obj = { ...taskFieldObj };
      obj.Config = setEvaluateExpression(type);
      HttpService.CreateUpdate("updateTaskField", obj)
        .then((result) => {
          if (type !== "Formula") {
            createFieldToggle();
          }
          // customFieldsToggle();
          GetAllTaskCustumeFieldList(parentObj._id);
          setTaskFieldConfigObj(null);
          setTaskFieldObj(null);
          setTaskFieldAction("");
          setSyntaxError(null);
          addActivity(
            "Update",
            "InputFields",
            obj.TaskId,
            obj.TaskName,
            0,
            obj
          );
          // swal('Create Success');
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    }
  };

  const createFieldModal = () => {
    return (
      <Modal
        isOpen={createFieldPopUp}
        toggle={createFieldToggle}
        size="lg"
        backdrop="static"
        className="createFieldModal"
      >
        <div>
          <h5
            className="text-center mt-3"
            // toggle={addEditFieldToggle}
          >
            {getTitleForPopup()}
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => createFieldToggle()}
              className="close"
            />
          </h5>
        </div>

        <ModalBody>
          {getInputFields()}

          <div style={{ float: "right" }}>
            <Button color="primary" onClick={() => handleCreateField("")}>
              {taskFieldAction == "edit" ? "Update" : "Create"}
            </Button>
            {"      "}
            <Button onClick={createFieldToggle} color="secondary">
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const [showMore, setShowMore] = useState(false);
  const [showMoreFields, setShowMoreFields] = useState(false);
  //const [noOfItemsToShowMoreOrLess, setNoOfItemsToShowMoreOrLess] = useState(false)

  const handleClickShowMoreFields = () => {
    setShowMore(!showMore);
  };

  const openLookupFields = () => {
    setShowMoreFields(!showMoreFields);
  };

  let phnRegex = "(d{3})s*d{3}-d{4}";

  // const handleOnChangeTaskCustomField = (event, data) => {
  //   let oldValue = data.Value;
  //   if (data.Type == 'File') {
  //     event.preventDefault();
  //     let self = this;
  //     let fr = new FileReader();

  //     //if (event.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
  //     fr.readAsDataURL(event.currentTarget.files[0]);
  //     fr.fileName = event.currentTarget.files[0].name
  //     fr.onloadend = function (e) {
  //       let obj = { Value: e.target.result, FileName: e.target.fileName };
  //       data = { ...data };
  //       data["Value"] = obj;
  //       HttpService.CreateUpdate('updateTaskField', data)
  //         .then((result) => {
  //           if (result.data.Ack == 1) {
  //             let list = _.cloneDeep(taskCustumeFieldList);
  //             let arr = [...list];
  //             arr.forEach((one, i) => {
  //               if (one._id == data._id) {
  //                 arr[i].Value = event.target.value
  //               }
  //             })
  //             setTaskCustumFieldList(arr);
  //             console.log('Success');
  //           } else {
  //             console.log('Unsuccess')
  //           }
  //         })
  //         .catch((err) => {
  //           swal(`${err}`, { dangerMode: true });
  //         })
  //     };

  //     // }
  //     // else {
  //     //     swal('File Size Should Be Between 2KB to 50KB');
  //     // }

  //   } else {
  //     if (data.Type == 'Number') {

  //       data = { ...data };
  //       data['Value'] = event.target.value

  //     }
  //     else if (data.Type == "PhoneNumber") {
  //       let Value = {};
  //       let value1 = event.target.value.Value.replace(/[-#*$+/?=%() ]/g, "");
  //       data = { ...data };

  //       if (value1.length === 10) {
  //         let phnVal = "(" + value1.substring(0, 3) + ") " + value1.substring(3, 6) +
  //           "-" + value1.substring(6, 10)
  //         data["Value"] = Value.phnVal
  //       }
  //     }
  //     else {
  //       data = { ...data };
  //       data['Value'] = event.target.value
  //     }
  //     if (!oldValue) {
  //       addActivity('Update', 'TaskField', data.TaskId, data.TaskName, 0, { PrevState: { Value: null, Name: data.Name }, UpdatedState: { Name: data.Name, Value: data.Value } });
  //     }
  //     else {
  //       addActivity('Update', 'TaskField', data.TaskId, data.TaskName, 0, { PrevState: { Value: oldValue.Value, Name: data.Name }, UpdatedState: { Name: data.Name, Value: data.Value } });
  //     }
  //     HttpService.CreateUpdate('updateTaskField', data)
  //       .then((result) => {
  //         if (result.data.Ack == 1) {
  //           let list = _.cloneDeep(taskCustumeFieldList);
  //           let arr = [...list];
  //           arr.forEach((one, i) => {
  //             if (one._id == data._id) {
  //               arr[i].Value = event.target.value
  //             }
  //           })
  //           // addActivity("UPDATE", "InputFields", prepareTaskData()._id, prepareTaskData().TaskName, obj);
  //           setTaskCustumFieldList(arr);

  //           console.log('Success');
  //         } else {
  //           console.log('Unsuccess')
  //         }
  //       })
  //       .catch((err) => {
  //         swal(`${err}`, { dangerMode: true });
  //       })
  //   }

  // }

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  const getNumberValue = (value, data) => {
    if (data.Type == "Number") {
      if (data.Config) {
        value = value.replaceAll(",", "");
        let str;
        if (data.Config.Prefix) {
          str = String(value).padStart(
            parseInt(data.Config.MaxDigit),
            data.Config.Prefix
          );
        } else {
          str = value;
        }
        if (parseInt(data.Config.MaxDigit) < value.length) {
          let rem = value.length - parseInt(data.Config.MaxDigit);
          str = str.substring(rem, str.length);
        }
        if (data.Config.Seprator) {
          str = numberWithCommas(str);
        }
        return str;
      } else {
        if (data.Config.Seprator) {
          value = numberWithCommas(value);
        }
        return value;
      }
    }
    if (data.Type == "Decimal") {
      if (data.Config) {
        let values = value.split(".");
        let decimal = values[1];
        value = values[0];
        value = value.replaceAll(",", "");
        let str1;
        if (data.Config.Leading) {
          str1 = String(value).padStart(parseInt(data.Config.MaxDigit), "0");
        } else {
          str1 = value;
        }

        if (parseInt(data.Config.MaxDigit) < value.length) {
          let rem = value.length - parseInt(data.Config.MaxDigit);
          str1 = str1.substring(rem, str1.length);
        }
        if (data.Config.Seprator) {
          str1 = numberWithCommas(str1);
        }
        if (decimal != undefined) {
          decimal = String(decimal).padStart(
            parseInt(data.Config.DecimalPlaces),
            "0"
          );
          if (parseInt(data.Config.DecimalPlaces) < decimal.length) {
            let rem = decimal.length - parseInt(data.Config.DecimalPlaces);
            decimal = decimal.substring(rem, decimal.length);
          }
          str1 =
            str1 +
            "." +
            decimal.substring(0, parseInt(data.Config.DecimalPlaces));
        } else {
          decimal = "00";
          str1 = str1 + "." + decimal;
        }
        return str1;
      } else {
        if (data.Config.Seprator) {
          value = numberWithCommas(value);
        }
        return value;
      }
    }
    if (data.Type == "Currency") {
      let values = value.split(".");
      let decimal = values[1];
      value = values[0];
      value = value.replaceAll(",", "");
      let str1 = value;
      if (parseInt(data.Config.MaxDigit) < value.length) {
        let rem = value.length - parseInt(data.Config.MaxDigit);
        str1 = str1.substring(rem, str1.length);
      }
      if (data.Config.Seprator) {
        str1 = numberWithCommas(str1);
      }
      if (decimal != undefined) {
        str1 =
          str1 +
          "." +
          decimal.substring(0, parseInt(data.Config.DecimalPlaces));
      }
      return str1;
    }
    if (data.Type == "AutoNumber") {
      let str1 = "";
      if (data.Config) {
        if (data.Config.Prefix) {
          str1 = data.Config.Prefix;
        }
        str1 = str1 + data.Config.StartNumber;
      }
      return str1;
    }
  };

  const padZero = (num, lead, trail) => {
    // (A1) CONVERT NUMBER TO STRING
    var cString = num.toString();

    // (A2) GET NUMBER OF DIGITS
    var cWhole,
      cDec,
      cCheck = cString.indexOf(".");

    // NO DECIMAL PLACES, A WHOLE NUMBER
    if (cCheck == -1) {
      cWhole = cString.length;
      cDec = 0;
      cString += ".";
    }
    // IS A DECIMAL NUMBER
    else {
      cWhole = cCheck;
      cDec = cString.substr(cCheck + 1).length;
    }

    // (A3) PAD WITH LEADING ZEROES
    if (cWhole < lead) {
      for (let i = cWhole; i < lead; i++) {
        cString = "0" + cString;
      }
    }

    // (A4) PAD WITH TRAILING ZEROES
    if (cDec < trail) {
      for (let i = cDec; i < trail; i++) {
        cString += "0";
      }
    }

    return cString;
  };

  const handleOnChangeTaskCustomField = (event, data) => {
    let oldValue = data.Value;
    if (data.Type == "File" || data.Type == "ImgUpload") {
      event.preventDefault();
      let self = this;
      let fr = new FileReader();

      //if (event.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsDataURL(event.currentTarget.files[0]);
      fr.fileName = event.currentTarget.files[0].name;
      fr.onloadend = function (e) {
        let obj = { Value: e.target.result, FileName: e.target.fileName };
        data = { ...data };
        data["Value"] = obj;
        HttpService.CreateUpdate("updateTaskField", data)
          .then((result) => {
            if (result.data.Ack == 1) {
              let list = _.cloneDeep(taskCustumeFieldList);
              let arr = [...list];
              arr.forEach((one, i) => {
                if (one._id == data._id) {
                  arr[i].Value = obj;
                }
              });
              setTaskCustumFieldList(arr);
              console.log("Success");
            } else {
              console.log("Unsuccess");
            }
          })
          .catch((err) => {
            swal(`${err}`, { dangerMode: true });
          });
      };

      // }
      // else {
      //     swal('File Size Should Be Between 2KB to 50KB');
      // }
    } else if (data.Type === "PhoneNumber") {
      let phnVal = "";
      phnVal = event.target.value.Value.replace(/[-#*$+/?=%() ]/g, "");
      data = { ...data };

      if (phnVal.length === 10) {
        phnVal =
          "(" +
          phnVal.substring(0, 3) +
          ") " +
          phnVal.substring(3, 6) +
          "-" +
          phnVal.substring(6, 10);
        data["Value"] = { Value: phnVal };
        setUserPhoneInvalid(false);
      } else {
        data["Value"] = { Value: phnVal };
        setUserPhoneInvalid(true);
      }
      HttpService.CreateUpdate("updateTaskField", data)
        .then((result) => {
          if (result.data.Ack == 1) {
            let list = _.cloneDeep(taskCustumeFieldList);
            let arr = [...list];
            arr.forEach((one, i) => {
              if (one._id == data._id) {
                arr[i].Value = { Value: phnVal };
              }
            });
            // addActivity("UPDATE", "InputFields", prepareTaskData()._id, prepareTaskData().TaskName, obj);
            setTaskCustumFieldList(arr);

            console.log("Success");
          } else {
            console.log("Unsuccess");
          }
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    } else {
      if (data.Type == "Number") {
        data = { ...data };
        data["Value"] = event.target.value;
      } else if (data.Type == "Email") {
        const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
        // props.setUserEmailAdd(email);
        if (reg.test(event.target.value.Value)) {
          console.log("true");
          data = { ...data };
          data["Value"] = event.target.value;
          setUserMailInvalid(false);
        } else {
          setUserMailInvalid(true);
          data["Value"] = "";
        }
      } else {
        data = { ...data };
        data["Value"] = event.target.value;
      }
      if (!oldValue) {
        addActivity("Update", "TaskField", data.TaskId, data.TaskName, 0, {
          PrevState: { Value: null, Name: data.Name },
          UpdatedState: { Name: data.Name, Value: data.Value },
        });
      } else {
        addActivity("Update", "TaskField", data.TaskId, data.TaskName, 0, {
          PrevState: { Value: oldValue.Value, Name: data.Name },
          UpdatedState: { Name: data.Name, Value: data.Value },
        });
      }
      HttpService.CreateUpdate("updateTaskField", data)
        .then((result) => {
          if (result.data.Ack == 1) {
            let list = _.cloneDeep(taskCustumeFieldList);
            let arr = [...list];
            arr.forEach((one, i) => {
              if (one._id == data._id) {
                arr[i].Value = event.target.value;
              }
            });
            // addActivity("UPDATE", "InputFields", prepareTaskData()._id, prepareTaskData().TaskName, obj);
            setTaskCustumFieldList(arr);

            console.log("Success");
          } else {
            console.log("Unsuccess");
          }
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    }
  };

  const GetSignificantValue = (value, signDigitObj) => {
    let num;
    if (signDigitObj) {
      num = parseFloat(value).toFixed(parseInt(signDigitObj.Significant));
    } else {
      num = parseFloat(value);
    }
    return num;
  };

  const getNumber = (number, signDigitObj) => {
    if (number !== "" && signDigitObj) {
      let arrNumber = number.split(".");
      if (
        arrNumber.length == 2 &&
        arrNumber[1].length <= parseInt(signDigitObj.Significant)
      ) {
        return number;
      } else {
        return GetSignificantValue(number, signDigitObj);
      }
    } else {
      return number;
    }
  };

  const getBorderStyles = (obj) => {
    if (taskMandatoryStatus && (!obj.Value || obj.Value.Value == "")) {
      return { borderColor: "red" };
    } else {
      return {};
    }
  };
  const myRef = useRef(null);

  const [checklistCollapse, setChecklistCollapse] = useState(false);
  const [subtaskCollapse, setSubtaskCollapse] = useState(false);

  const [collapseOpen, setCollapseOpen] = useState(false);

  const [openSubtaskPopup, setOpenSubtaskPopup] = useState(false);
  const checklistCollapseOpen = () => {
    setChecklistCollapse(!checklistCollapse);
  };

  const subtaskCollapseOpen = () => {
    setSubtaskCollapse(true);

    if (taskData && taskData.Checklist && taskData.Checklist.length > 0) {
      setChecklistCollapse(true);
    } else {
      setChecklistCollapse(false);
    }
  };
  const testFunction = () => {
    swal("shdfb\bh");
  };

  const getKeyByValue = (object, value) => {
    let key = Object.keys(object).find((key) => object[key] === value);
    if (key) {
      return props.dealObj[key];
    } else {
      return value;
    }
  };

  const handleUploadImageType = (name, data) => {
    let link = document.createElement("input");
    link.setAttribute("type", "file");
    link.setAttribute("name", name);
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      handleOnChangeTaskCustomField(e, data);
    };
    document.body.removeChild(link);
  };

  const getFormulaCalVal = (obj) => {
    let dataObj = { ...props.dealObj, ...taskData };
    if (obj.Config) {
      let result;
      if (obj.Config.Expression.startsWith("if")) {
        result = obj.Config.Expression;
      } else {
        if (obj.Config.EvalExp) {
          result = obj.Config.EvalExp;
        } else {
          result = obj.Config.Expression;
        }
      }
      if (
        obj.Config.ReturnType == "Decimal" ||
        obj.Config.ReturnType == "Currency"
      ) {
        result = obj.Config.EvalExp;
        result = result.replaceAll("$", "");
        result = result.replaceAll("{", "");
        result = result.replaceAll("}", "");
        Object.keys(allFields).forEach((key, index) => {
          if (result.includes(allFields[key])) {
            let replaceItem = allFields[key];
            let replaceWith = dataObj[key];
            if (key == "lotNo" || key == "binNo") {
              replaceWith = parseInt(replaceWith);
              result = result.replaceAll(replaceItem, replaceWith);
            } else {
              result = result.replaceAll(replaceItem, replaceWith);
            }
          }
        });

        result = eval(result);
        if (isNaN(result)) {
          result = 0;
          // throw "Syntax Error"
        }

        result = parseFloat(result).toFixed(parseInt(obj.Config.DecimalPlaces));
      } else {
        result = result.replaceAll("$", "");
        result = result.replaceAll("{", "");
        result = result.replaceAll("}", "");
        Object.keys(allFields).forEach((key, index) => {
          if (result.includes(allFields[key])) {
            let replaceItem = allFields[key];

            if (
              key == "ProjectDate" ||
              key == "StartDate" ||
              key == "DueDate"
            ) {
              let replaceWith = `${dataObj[key]}`;
              let date = new Date(replaceWith).toLocaleDateString();
              date = `"${date}"`;
              result = result.replaceAll(replaceItem, date);
            } else {
              let replaceWith = `"${dataObj[key]}"`;
              result = result.replaceAll(replaceItem, replaceWith);
            }
          }
        });
        if (obj.Config.Expression.startsWith("if")) {
          result = result.replaceAll(")", "}");
          result = result.split(",");
          result.forEach((one, index) => {
            if (index == 0) {
              result[index] = result[index] + "){";
            }
            if (index == 1) {
              result[index] = result[index] + "}else{";
            }
          });
          result = result.join("");
        }
        result = eval(result);
        // if(isNaN(result)){
        //   throw "Syntax Error"
        // }
        result = result.toString();
        // result = result.replaceAll(/[+,&&,\-\,*,[\/\],!,||,^,()]/ig, " ")
      }
      return result;
    } else {
      return "";
    }
  };
  const GetInputHtmlFromTaskFields = (obj) => {
    if (obj.Type == "Number") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <input
              type="text"
              ref={myRef}
              value={obj.Value ? obj.Value.Value : ""}
              onChange={(e) => {
                // if(e.target.value.length<=parseInt(obj.Config.MaxDigit)){
                handleOnChangeTaskCustomField(
                  {
                    target: {
                      value: {
                        Value: getNumberValue(e.currentTarget.value, obj),
                      },
                    },
                  },
                  obj
                );
                // }
              }}
              placeholder="...."
              className="form-control"
              name={obj.Name}
            />
            {/* {getDropDownStatusForNewFiels('badge-info', 'Complete')} */}
            {/* <FontAwesomeIcon className="removeField-icon" style={{ backgroundColor: 'blue', color: 'white' }} icon={faTimes} /> */}
          </td>
        </tr>
      );
    }

    if (obj.Type == "Dropdown") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <select
              className="form-control"
              value={obj.Value ? obj.Value.Value : obj.Type}
              onChange={(e) => {
                console.log(e.target.value);
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                );
              }}
            >
              <option>Please Select</option>
              {Object.keys(obj.Config)
                .filter((one) => {
                  if (
                    // !one.includes('Color') &&
                    !one.includes("Mandatory") &&
                    !one.includes("IsLooKUpField") &&
                    !one.includes("IsInputField")
                  ) {
                    return one;
                  }
                })
                .map((key, i) => {
                  return <option>{obj.Config[key]}</option>;
                })}
            </select>
          </td>
        </tr>
      );
    }
    if (obj.Type == "Radio") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          //  className="hoverDelete"
          >
            {Object.keys(obj.Config)
              .filter((one) => {
                if (
                  // !one.includes('Color') &&
                  !one.includes("Mandatory") &&
                  !one.includes("IsLooKUpField") &&
                  !one.includes("IsInputField")
                ) {
                  return one;
                }
              })
              .map((keyr, i) => {
                return (
                  <>
                    <input
                      type="radio"
                      defaultChecked={
                        obj.Value
                          ? obj.Value.Value == obj.Config[keyr]
                            ? true
                            : false
                          : false
                      }
                      onChange={(e) =>
                        handleOnChangeTaskCustomField(
                          { target: { value: { Value: obj.Config[keyr] } } },
                          obj
                        )
                      }
                      name={obj.Label}
                    />
                    &nbsp;&nbsp;&nbsp;<span>{obj.Config[keyr]}</span>
                    &nbsp;&nbsp;
                  </>
                );
              })}

            {/* <input type='radio' defaultChecked={obj.Value ? obj.Value.Value == false : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: false } } }, obj)} name={obj.Label} />&nbsp;&nbsp;&nbsp;No */}
          </td>
        </tr>
      );
    }
    if (obj.Type == "File" || obj.Type == "ImgUpload") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <div class="custom-file">
              <input
                type="file"
                id={obj.Label}
                onChange={(e) => handleOnChangeTaskCustomField(e, obj)}
                placeholder={obj.Type}
                className="form-control custom-file-input"
                name={obj.Label}
              />
              <label class="custom-file-label" for={obj.Label}>
                {obj.Value ? obj.Value.FileName : "Choose file"}
              </label>
            </div>
            {/* <label>{obj.Value ? obj.Value.FileName : ''}</label> */}
          </td>
        </tr>
      );
    }
    // if (obj.Type == 'ImgUpload') {
    //   return <tr>
    //     <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
    //     <td
    //     // className="hoverDelete"
    //     >
    //       <span onClick={()=>handleUploadImageType(obj.Label,obj)} className="small btn btn-sm btn-primary"><FontAwesomeIcon icon={faPlus}/>Add Image</span>
    //       <label>{obj.Value ? obj.Value.FileName : ''}</label>
    //     </td>
    //   </tr>
    // }

    if (obj.Type == "Date") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          //  className="hoverDelete"
          >
            <input
              type="date"
              placeholder="mm/dd/yy"
              defaultValue={obj.Value ? obj.Value.Value : ""}
              onChange={(e) =>
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                )
              }
              className="form-control"
              name={obj.Label}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "Textarea") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <textarea
              type="text"
              placeholder="..."
              rows={3}
              cols={10}
              defaultValue={obj.Value ? obj.Value.Value : ""}
              onChange={(e) =>
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                )
              }
              className="form-control"
              name={obj.Label}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "Label") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <input
              type="text"
              defaultValue={obj.Value ? obj.Value.Value : ""}
              onChange={(e) =>
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                )
              }
              placeholder="...."
              className="form-control"
              name={obj.Label}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "Text") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td className="hoverDelete">
            <input
              type="text"
              defaultValue={obj.Value ? obj.Value.Value : ""}
              onChange={(e) =>
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                )
              }
              placeholder="...."
              className="form-control"
              name={obj.Label}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "Decimal") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <input
              type="text"
              ref={myRef}
              value={obj.Value ? obj.Value.Value : ""}
              onChange={(e) => {
                // if(e.target.value.length<=parseInt(obj.Config.MaxDigit)){
                handleOnChangeTaskCustomField(
                  {
                    target: {
                      value: {
                        Value: getNumberValue(e.currentTarget.value, obj),
                      },
                    },
                  },
                  obj
                );
                // }
              }}
              placeholder="...."
              className="form-control"
              name={obj.Name}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "AutoNumber") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <input
              disabled={true}
              type="text"
              value={obj.Config ? getNumberValue("", obj) : ""}
              onChange={(e) => {
                // if(e.target.value.length<=parseInt(obj.Config.MaxDigit)){
                handleOnChangeTaskCustomField(
                  {
                    target: {
                      value: {
                        Value: getNumberValue(e.currentTarget.value, obj),
                      },
                    },
                  },
                  obj
                );
                // }
              }}
              placeholder="...."
              className="form-control"
              name={obj.Name}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "Percent") {
      return (
        <tr>
          <td>
            {obj.Label}%
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <input
              type="number"
              defaultValue={obj.Value ? obj.Value.Value : ""}
              onChange={(e) =>
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                )
              }
              placeholder="...."
              className="form-control"
              name={obj.Name}
            />
            {/* {getDropDownStatusForNewFiels('badge-info', 'Complete')} */}
            {/* <FontAwesomeIcon className="removeField-icon" style={{ backgroundColor: 'blue', color: 'white' }} icon={faTimes} /> */}
          </td>
        </tr>
      );
    }
    if (obj.Type == "Currency") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          // className="hoverDelete"
          >
            <input
              type="text"
              value={obj.Value ? obj.Value.Value : ""}
              onChange={(e) => {
                // if(e.target.value.length<=parseInt(obj.Config.MaxDigit)){
                handleOnChangeTaskCustomField(
                  {
                    target: {
                      value: {
                        Value: getNumberValue(e.currentTarget.value, obj),
                      },
                    },
                  },
                  obj
                );
                // }
              }}
              placeholder="...."
              className="form-control"
              name={obj.Name}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "PhoneNumber") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td>
            <input
              type="text"
              value={obj.Value ? obj.Value.Value : ""}
              onChange={(e) =>
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                )
              }
              placeholder="...."
              className="form-control"
              name={obj.Name}
              maxLength="10"
            />{" "}
            {userPhoneInvalid ? (
              <span className="small text-danger">Invalid Phone Number</span>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    }
    if (obj.Type == "Email") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td>
            <input
              type="text"
              defaultValue={obj.Value ? obj.Value.Value : ""}
              onChange={(e) =>
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                )
              }
              placeholder="...."
              className="form-control"
              name={obj.Name}
            />{" "}
            {userMailInvalid ? (
              <span className="small text-danger">Invalid Email Format</span>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    }
    if (obj.Type == "LookUpField") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td>
            <select
              className="form-control"
              value={obj.Value ? obj.Value.Value : obj.Type}
              onChange={(e) => {
                console.log(e.target.value);
                handleOnChangeTaskCustomField(
                  { target: { value: { Value: e.target.value } } },
                  obj
                );
              }}
            >
              <option>Please Select</option>
              {/* {Object.keys(obj.Config).filter(one => {
              if (
                // !one.includes('Color') &&
                !one.includes('Mandatory') && !one.includes('IsLooKUpField') && !one.includes('IsInputField')) {
                return one;
              }
            }).map((key, i) => {
              return (
                <option >{obj.Config[key]}</option>
              );
            })} */}
            </select>
          </td>
        </tr>
      );
    }
    // if (obj.Type == 'LookUp') {
    //   return <tr>
    //     <td>{getSpaceBetweenCamelString(obj.Label)}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
    //     <td className="hoverDelete">
    //       <input type='text' className='form-control' disabled defaultValue={obj.Value ? obj.Value.Value : ''} name={obj.Label} />
    //     </td>
    //   </tr>
    // }
  };

  const getInputHtmlForAddField = (obj) => {
    if (obj.Type == "LookUp") {
      return (
        <tr>
          <td>
            {getSpaceBetweenCamelString(obj.Label)}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td
          //  className="hoverDelete"
          >
            <input
              type="text"
              className="form-control"
              disabled
              defaultValue={
                props.dealObj[obj.Name] ? props.dealObj[obj.Name] : ""
              }
              name={obj.Name}
            />
          </td>
        </tr>
      );
    }
    if (obj.Type == "Formula") {
      return (
        <tr>
          <td>
            {obj.Label}
            {obj.Config ? (
              obj.Config.Mandatory ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </td>
          <td className="hoverDelete">{getFormulaCalVal(obj)}</td>
        </tr>
      );
    }
  };

  const countLookupFields = (obj) => {
    let totalCount = 0;
    let lookupObj = obj.filter(
      (e) => e.Type == "LookUp" || e.Type == "Formula"
    );
    totalCount = lookupObj.length;
    return totalCount;
  };

  const emptyFieldsLength = (obj) => {
    obj = obj.filter((one) => one.Type == "InputField");
    let emptyCount = 0;
    let valueObj = obj.filter((e) => !e.Value);
    emptyCount = valueObj.length;

    return emptyCount;
  };

  const countInputFields = (obj) => {
    let totalCount = 0;
    let inputObj = obj.filter(
      (e) => e.Type !== "LookUp" && e.Type !== "Formula"
    );
    totalCount = inputObj.length;
    return totalCount;
  };

  // const getTableForAddNewField = () => {
  //   return <div className="newField-box">

  //     <table class="table table-sm table-hover newField-table">
  //       <tbody>
  //         {taskCustumeFieldList && taskCustumeFieldList.map((one, i) => {
  //           if (showMore) {
  //             return GetInputHtmlFromTaskFields(one)
  //           } else {
  //             return;
  //           }
  //           // if (one.Value) {
  //           //   return GetInputHtmlFromTaskFields(one)
  //           // } else {
  //           //   if (showMore) {
  //           //     return GetInputHtmlFromTaskFields(one)
  //           //   }
  //           // }
  //           // if (showMore) {

  //           // } else {
  //           //   if (i == 0) {
  //           //     return GetInputHtmlFromTaskFields(one)
  //           //   }
  //           // }

  //         })}
  //         {/* <tr>
  //           <td>Mark</td>
  //           <td className="hoverDelete">
  //             {getDropDownStatusForNewFiels('badge-warning', 'Pending')}
  //             <FontAwesomeIcon className="removeField-icon" style={{ backgroundColor: 'blue', color: 'white' }} icon={faTimes} />
  //           </td>
  //         </tr>
  //         <tr>
  //           <td>Mark</td>
  //           <td className="hoverDelete">
  //             {getDropDownStatusForNewFiels('badge-danger', 'In Progress')}
  //             <FontAwesomeIcon className="removeField-icon" style={{ backgroundColor: 'blue', color: 'white' }} icon={faTimes} />
  //           </td>
  //         </tr> */}
  //       </tbody>
  //     </table>
  //     <div className="newField-tableOptions">
  //       {/* {!showMore ? getTotalCustomeTaskEmphtyField() : 0} empty */}
  //       {/* {taskCustumeFieldList.map(one=> ( */}

  //       <p onClick={handleClickShowMoreFields}><FontAwesomeIcon className="mr-2" icon={showMore ? faCaretUp : faCaretDown} />{showMore ? "Show less Input" : "Show more Input"}   fields
  //         {" "}
  //         ( {countInputFields(taskCustumeFieldList)} total fields {" "} {" "}
  //         {emptyFieldsLength(taskCustumeFieldList)} empty fields )
  //       </p>
  //       {/* ) )} */}

  //       {/* {!showMore ? taskCustumeFieldList.length  : taskCustumeFieldList.length-1} */}
  //       <a style={{ cursor: 'pointer' }} onClick={() => {
  //         onClickAddOrEditCustomeFields("inputFields")
  //       }
  //       }>
  //         + Add or edit fields
  //       </a>
  //     </div>

  //   </div>
  // }

  const getTableForLookupFields = () => {
    return (
      <div className="newField-box">
        <table class="table table-sm table-hover newField-table">
          <tbody>
            {taskCustumeFieldList &&
              taskCustumeFieldList.map((one, i) => {
                if (showMoreFields) {
                  return getInputHtmlForAddField(one);
                } else {
                  return;
                }
              })}
          </tbody>
        </table>
        <div className="newField-tableOptions">
          {/* {!showMore ? getTotalCustomeTaskEmphtyField() : 0} empty */}
          <p onClick={openLookupFields}>
            <FontAwesomeIcon
              className="mr-2"
              icon={showMoreFields ? faCaretUp : faCaretDown}
            />
            {showMoreFields ? "Show less Lookup" : "Show more Lookup"} fields (
            {countLookupFields(taskCustumeFieldList)} total fields)
          </p>
          {/* {!showMore ? taskCustumeFieldList.length  : taskCustumeFieldList.length-1} */}
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              onClickAddOrEditCustomeFields("LookupFields");
            }}
          >
            + Add fields
          </a>
        </div>
      </div>
    );
  };

  const [warningTaskCompletePopup, setTaskCompleteWarning] = useState(false);

  const warningTaskCompleteAlertToogle = () =>
    setTaskCompleteWarning(!warningTaskCompletePopup);

  const [taskWaitingOnList, setTaskWaitingList] = useState([]);

  const warningTaskCompleteAlertModal = () => {
    return (
      <Modal
        isOpen={warningTaskCompletePopup}
        backdrop={backdrop}
        size="md"
        toggle={warningTaskCompleteAlertToogle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Dependency Warning</h5>
              <p className="taskComplete-warning-subtitle">
                This task is waiting on required fields or tasks dependency for
                the following tasks of fields{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p>Field Name</p>
              <ul className="textField-list">
                {taskCustumeFieldList.map((one) => {
                  if (
                    one.Config &&
                    one.Config.Mandatory &&
                    (!one.Value || one.Value.Value === "")
                  ) {
                    return <li>{one.Name}</li>;
                  }
                })}
              </ul>
              <hr />
              <p>Task Name</p>
              <ul className="textField-list">
                {taskWaitingOnList.map((one) => {
                  return <li>{one}</li>;
                })}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  if (taskStatusUpdateWhere == "table" && taskStatusUpdateObj) {
                    updateStatus(
                      taskStatusUpdateObj.first,
                      taskStatusUpdateObj.second,
                      taskStatusUpdateObj.third,
                      taskStatusUpdateObj.forth,
                      taskStatusUpdateObj.fifth
                    );
                    setTaskCompleteWarning(false);
                    setTaskStatusUpdateWhere("");
                    setTaskStatusUpdateObj(null);
                    setTaskWaitingList([]);
                  } else {
                    setTaskStatus({ name: "COMPLETED", color: "#f900ea" });
                    setTaskCompleteWarning(false);
                    setTaskWaitingList([]);
                  }
                }}
              >
                Ignore
              </button>
              <button
                onClick={() => setTaskCompleteWarning(false)}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const [taskStatusUpdateWhere, setTaskStatusUpdateWhere] = useState("");
  const [taskStatusUpdateObj, setTaskStatusUpdateObj] = useState(null);
  const [addDescPopup, setAddDescPopup] = useState(false);
  const [askMilestonePopup, setAskMileStonePopup] = useState(false);
  const [taskMilestoneList, setTaskMilestoneList] = useState([]);
  const [askMilestoneId, setAskMileStoneId] = useState(null);
  const [askMilestoneObj, setAskMileStoneObj] = useState(null);
  const askMilestonePopupToogle = () =>
    setAskMileStonePopup(!askMilestonePopup);

  const addDescPopupToggle = () => setAddDescPopup(!addDescPopup);

  const handleOnChangeAskMilestone = (e) => {
    const objArr = { ...askMilestoneObj };
    objArr[e.currentTarget.name] = e.currentTarget.value;
    objArr["Id"] = e.currentTarget.options[e.currentTarget.selectedIndex].id;
    objArr["DateTime"] = new Date();
    // let obj={}
    // obj[e.currentTarget.name] = e.currentTarget.value;
    // obj['DateTime'] = new Date();
    // objArr.push(Obj);
    setAskMileStoneObj(objArr);
  };
  const [mileStoneReplaceStatusWithTask, setMilestoneReplaceStatusWithTask] =
    useState([]);
  const handleOnChangeStatusMultiple = async (e, task) => {
    const arr = [...mileStoneReplaceStatusWithTask];
    let obj = {};
    obj[e.target.name] = e.target.value;
    obj["Task"] = task;
    obj["TaskId"] = task._id;
    if (arr.length == 0) {
      setMilestoneReplaceStatusWithTask((current) => [...current, obj]);
    } else {
      let filterData = arr.filter((obj1) => obj1.TaskId == task._id);
      if (filterData.length > 0) {
        arr.filter((obj1) => obj1.TaskId == task._id)[0].Status =
          e.target.value;
        setMilestoneReplaceStatusWithTask(arr);
      } else {
        setMilestoneReplaceStatusWithTask((current) => [...current, obj]);
      }
    }

    // if (await checkMilestoneForPipeline(selectedPiplineString, e.target.value)) {
    //   setMilestoneReplaceWQarning(true);
    // } else {
    //   obj.Pipeline=selectedPiplineString;
    //   obj.Milestone.Status=e.target.value;
    //   obj.Milestone.DateTime=new Date(Date.now())
    //   updateTask(obj);
    // }
  };
  const [allReadyUseStatus, setAllReadyUseStatus] = useState("");
  const handleReplaceMilestoneForTask = async (pipelineString) => {
    const statusObj = [...mileStoneReplaceStatusWithTask];
    let breakFlag = false;
    if (statusObj.length > 0) {
      for (let i = 0; i < statusObj.length > 0; i++) {
        if (statusObj[i].Status && statusObj[i].Status !== "None") {
          if (
            await checkMilestoneForPipeline(pipelineString, statusObj[i].Status)
          ) {
            setAllReadyUseStatus(statusObj[i].Status);
            setMilestoneReplaceWQarning(true);
            breakFlag = true;
            break;
          } else {
            statusObj[i].Task.Pipeline = pipelineString;
            statusObj[i].Task.Milestone.Status = statusObj[i].Status;
            statusObj[i].Task.Milestone.DateTime = new Date(Date.now());
            await updateTask(statusObj[i].Task);
          }
        } else {
          statusObj[i].Task.Pipeline = pipelineString;
          statusObj[i].Task.isMilestone = null;
          statusObj[i].Task.Milestone = null;
          await updateTask(statusObj[i].Task);
        }
      }
    } else {
      for (let i = 0; i < milestoneTaskForGrpId.length > 0; i++) {
        milestoneTaskForGrpId[i].Pipeline = pipelineString;
        milestoneTaskForGrpId[i].isMilestone = null;
        milestoneTaskForGrpId[i].Milestone = null;
        await updateTask(milestoneTaskForGrpId[i]);
      }
    }
    if (!breakFlag) {
      setPipelineReplaceForTaskPopUp(false);
      setPipelineWarning(false);
      setMilestoneReplaceStatusWithTask([]);
      handleReplacePipelineForThisTaskGroup(props.task.GrpID, pipelineString);
      // setMilestoneTaskForGrpId(null);
      // setMilestoneTaskForPipline(null)
    }
  };

  const [descData, setDescData] = useState(null);
  const handleOnChangeDesc = (e) => {
    const data = { ...descData };
    data[e.currentTarget.name] = e.currentTarget.value;
    setDescData(data);
  };

  const handleClickMention = async (e, id) => {
    let elem = document.getElementById(id);
    if (e.target.id.startsWith("User") || e.target.id.startsWith("Task")) {
      let span = e.target;
      if (span) {
        let text = span.id;
        if (text.startsWith("User")) {
          let all = [...allUsers];
          let id = span.id.replace("User-", "");
          all = all.filter((one) => one.zuid == id);
          if (all.length > 0) {
            all = all[0];
            history.push({
              pathname: `/setting/users/${all.id}/Users`,
              state: { tab: "Users" },
            });
          }
        } else if (text.startsWith("Task")) {
          let id = span.id.replace("Task-", "");
          id = id.split("-");
          id = id.filter((one) => one);
          let res = await ProjectService.GetProjectDetail(id[1]);
          TaskService.GetTaskDetail(res.id_string, res.name, id[0])
            .then((result) => {
              const arr = [...openTaskArray];
              arr.push(result);
              setOpenTaskArr(arr);
              setTaskData(result);
              history.push({
                search: `task=${result.id_string}`,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };

  const saveDescData = (data) => {
    if (data) {
      HttpService.CreateUpdate("addDescription", data)
        .then((result) => {
          getAllDescription();
          setAddDescPopup(false);
        })
        .catch((err) => {
          swal(`${err}`);
        });
    } else {
      swal("Please enter description");
    }
  };
  const [descriptionList, setDescList] = useState([]);
  const [selectPipeline, setSelectedPipeline] = useState([]);
  const getAllDescription = async (pipelineName) => {
    if (pipelineName) {
      // HttpService.CreateUpdate('getPipeline', { PipelineName: pipelineName })
      //   .then((result) => {
      //     if (result.data) {
      //       setSelectedPipeline(result.data)
      //       setDescList(result.data.Status);
      //     } else {
      //       setDescList([]);
      //     }
      //   })
      //   .catch((err) => {
      //     swal(`${err}`);
      //   })
    } else {
      setDescList([]);
    }
  };

  const addDescriptionModal = () => {
    return (
      <Modal
        isOpen={addDescPopup}
        backdrop={backdrop}
        size="md"
        toggle={addDescPopupToggle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              {/* <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" /> */}
              <h5 className="my-2">Description</h5>
            </Col>
          </Row>
          <Row>
            {/* <Col sm={12}>
          <div className='form-group'>
            <label>Statuses associated with</label>
            <select name='Category' onChange={handleOnChangeAskMilestone} className='form-control form-control-sm'>
              <option>--Select Category--</option>
              <option>Design</option>
              <option>Filing</option>
              <option>Miscellaneous</option>
            </select>
          </div>
        </Col> */}
            <Col sm={12}>
              <div className="form-group">
                <label>Statuses description</label>
                <input
                  placeholder="description..."
                  onChange={handleOnChangeDesc}
                  name="Description"
                  type="text"
                  className="form-control form-control-sm"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-success w-100"
                onClick={() => saveDescData(descData)}
              >
                Save{" "}
              </button>
              <button
                onClick={() => setAddDescPopup(false)}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const askModalToMakeMilestone = () => {
    return (
      <Modal
        isOpen={askMilestonePopup}
        backdrop={backdrop}
        size="md"
        toggle={askMilestonePopupToogle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              {/* <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" /> */}
              <h5 className="my-2">Milestone</h5>
              <p className="taskComplete-warning-subtitle">
                Associate milestone to this task
              </p>
            </Col>
          </Row>
          <Row>
            {/* <Col sm={12}>
            <div className='form-group'>
              <label>Statuses associated with</label>
              <select name='Category' onChange={handleOnChangeAskMilestone} className='form-control form-control-sm'>
                <option>--Select Category--</option>
                <option>Design</option>
                <option>Filing</option>
                <option>Miscellaneous</option>
              </select>
            </div>
          </Col> */}
            <Col sm={12}>
              <div className="form-group">
                <label>Status description</label>
                <select
                  name="Name"
                  onChange={handleOnChangeAskMilestone}
                  className="form-control form-control-sm"
                >
                  <option>--Select Milestone--</option>
                  {taskMilestoneList.map((obj) => {
                    return <option id={obj.id_string}>{obj.name}</option>;
                  })}
                </select>
                {/* <input placeholder='description...' onChange={handleOnChangeAskMilestone} name='Status' type='text' className='form-control form-control-sm' /> */}
              </div>
            </Col>
            {/* <Col sm={ 4}>
            <button onClick={()=>setAddDescPopup(true)} className="btn btn-sm btn-primary mt-4">Add</button>
          </Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-success w-100"
                onClick={async () => {
                  // if (milestoneReplaceWarning && milestoneTask) {
                  //   handleTaskExtraOps(askMilestoneId, milestoneTask)
                  // }
                  if (askMilestoneObj) {
                    updateTask(
                      taskData.id_string,
                      "custom_fields",
                      JSON.stringify({
                        UDF_CHAR7: askMilestoneObj.Status,
                        UDF_CHAR8: askMilestoneObj.Id,
                        UDF_CHAR9: askMilestoneObj.Name,
                        UDF_BOOLEAN2: true,
                      })
                    );
                    setAskMileStonePopup(false);
                    // if (await checkMilestoneForPipeline(taskData.Pipeline, askMilestoneObj.Status)) {
                    //   setMilestoneReplaceWQarning(true);
                    // } else {
                    //   handleTaskExtraOps(askMilestoneId)
                    // }
                  } else {
                    notification.error({
                      duration: 10,
                      message: "Milestone Status Error ",
                      description: "Please select a milestone status",
                    });
                  }
                }}
              >
                Yes{" "}
              </button>
              <button
                onClick={() => setAskMileStonePopup(false)}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const handleReplaceMilestoneStatus = () => {
    let obj = { ...taskData };
    obj.Milestone = askMilestoneObj;
    updateSubTask(obj);
    // addActivity('Update', 'Milestone', obj._id, obj.TaskName, 0, { PrevState: (obj && obj.isMilestone) ? true : false, UpdatedState: obj.isMilestone });
    setAskMileStonePopup(false);
    setMilestoneReplaceWQarning(false);
    //setMileStoneTask(null)
  };

  const handleTaskExtraOps = (value, taskObj) => {
    if (value && value.id == "milestone") {
      let obj = { ...taskData };
      if (taskObj) {
        obj = { ...taskObj };
      } else {
        obj = { ...taskData };
      }
      obj.isMilestone = !obj.isMilestone;
      if (obj.isMilestone) {
        obj.Milestone = askMilestoneObj;
      } else {
        obj.Milestone = null;
      }
      updateSubTask(obj);
      addActivity("Update", "Milestone", obj._id, obj.TaskName, 0, {
        PrevState: obj && obj.isMilestone ? true : false,
        UpdatedState: obj.isMilestone,
      });
      setAskMileStonePopup(false);
      setMilestoneReplaceWQarning(false);
      //setMileStoneTask(null)
    }
  };

  const handleUploadFiles = () => {
    let link = document.createElement("input");
    link.setAttribute("type", "file");
    link.setAttribute("name", "uploadFiles");
    link.setAttribute("multiple", "multiple");
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      // let formData = new FormData();
      // formData.append('uploaddoc', e.currentTarget.files[0])
      // updateZohoTask(formData)
      handleFileAttachments(e.currentTarget.files);
    };

    document.body.removeChild(link);
  };

  const addZohoTaskAttachements = (pid, tid, fileObj) => {
    return new Promise((resolve, reject) => {
      TaskService.AddTaskAttachements(pid, tid, fileObj)
        .then((result) => {
          console.log(result);
          return TaskService.GetTaskAttachements(
            taskData.project_id,
            taskData.id_string
          );
        })
        .then((result) => {
          console.log(result);
          setTaskAttachements(result);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  };

  const [logType, setLogType] = useState("");
  const [logModalPopUp, setLogModalPopUp] = useState(false);

  const [milestoneTaskForGrpId, setMilestoneTaskForGrpId] = useState(null);
  const [milestoneTaskForPipeline, setMilestoneTaskForPipline] = useState(null);
  const checkMilestoneForGrpId = async (grpID) => {
    try {
      let res = await HttpService.CreateUpdate("getMilestoneTaskForGrpID", {
        GrpID: grpID,
        DealId: props.dealObj._id,
      });
      if (res.data.Data.length > 0) {
        setMilestoneTaskForGrpId(res.data.Data);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      swal(`${err}`);
    }
  };

  const checkMilestoneForPipeline = async (pipelineName, milestoneStatus) => {
    try {
      let res = await HttpService.CreateUpdate("getMilestoneTaskForPipeline", {
        Pipeline: pipelineName,
        Status: milestoneStatus,
        DealId: props.dealObj._id,
      });
      if (res.data.Data.length > 0) {
        setMilestoneTaskForPipline(res.data.Data);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      swal(`${err}`);
    }
  };
  const [milestoneReplaceWarning, setMilestoneReplaceWQarning] =
    useState(false);
  const milestoneReplaceWarningToogle = () =>
    setMilestoneReplaceWQarning(!milestoneReplaceWarning);
  const milestoneReplaceWarningModal = () => {
    return (
      <Modal
        isOpen={milestoneReplaceWarning}
        backdrop={backdrop}
        size="md"
        toggle={milestoneReplaceWarningToogle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Milestone Already Use Warning</h5>
              <p className="taskComplete-warning-subtitle">
                <strong>{allReadyUseStatus}</strong> , this status for milestone
                already used in this task{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p>Task Name</p>
              <ul className="textField-list">
                {milestoneTaskForPipeline &&
                  milestoneTaskForPipeline.map((one) => {
                    return <li>{one.TaskName}</li>;
                  })}
              </ul>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {/* <button
              className='btn btn-success w-100'
              onClick={async () => {
                if (milestoneTask) {
                  await getAllDescription(milestoneTask)
                  setAskMileStonePopup(true);
                }
              }}
            >Replace</button> */}
              <button
                onClick={() => setMilestoneReplaceWQarning(false)}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const showPhotoOfUser = (user) => {
    if (user.zuid === null || user.zuid === "") {
      return (
        <div className="no-image-user">
          {user.first_name ? user.first_name.charAt(0) : ""}
        </div>
      );
    } else {
      return (
        <img
          src={UserService.GetUserPhotoUrl(user.zuid)}
          className="user-ico"
        />
      );
    }
  };

  const handleSearchUser = (e) => {
    let user = [...[]];
    if (e.target.value !== "") {
      user = user.filter((obj) => {
        let name = obj.FirstName + " " + obj.LastName;
        if (name.toLowerCase().startsWith(e.target.value.toLowerCase())) {
          return obj;
        }
      });
    }
    setAllUsers(user);
  };

  const fetchPhotoFromLocalstorage = (photo, name, user) => {
    if (!user.zuid) {
      return (
        <div className="user-img-icon">
          {selectedUser.length > 1 ? (
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => removeAssignee(user)}
              className="remove-user"
            />
          ) : (
            <></>
          )}
          <div
            className="no-image-user"
            type="button"
            id="reminder-pop-user"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => {
              // setDueDatePopUp(false);
              setNotifyMePopUp(false);
              setCloseAssigneePopup(!closeAssigneePopup);
            }}
          >
            {name ? name.charAt(0) : ""}
          </div>
        </div>
      );
    } else {
      return (
        <div className="user-img-icon">
          {selectedUser.length > 1 ? (
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => removeAssignee(user)}
              className="remove-user"
            />
          ) : (
            <></>
          )}
          <img
            src={UserService.GetUserPhotoUrl(user.zuid)}
            class="user-img btn btn-secondary dropdown-toggle"
            type="button"
            id="reminder-pop-user"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={() => {
              // setDueDatePopUp(false);
              setNotifyMePopUp(false);
              setCloseAssigneePopup(!closeAssigneePopup);
            }}
          ></img>
        </div>
      );
    }
  };
  const [remObj, setRemObj] = useState({
    BasedOn: "DueDate",
    ReminderType: "daily",
    Time: "00:00",
    NumberOfDays: 0,
  });
  const handleOnChangeReminder = (e) => {
    const obj = { ...remObj };
    obj[e.currentTarget.name] = e.currentTarget.value;
    setRemObj(obj);
  };

  const taskReminderModal = () => {
    if (reminderModal) {
      // return <Modal
      //   isOpen={reminderModal}
      //   backdrop={backdrop}
      //   size='md'
      //   className="taskComplete-warning"
      // >

      //   <ModalBody>
      //     <Row >
      //       <Col sm={12}>
      //         <FontAwesomeIcon icon={faClock} size='3x' className="text-warning" />
      //         <h5 className="my-2">Reminder</h5>
      //       </Col>

      //     </Row>
      //     <Row >
      //       <Col sm={12}>
      //         <Row>
      //           <Col className="text-center" sm={12}>
      //             Based On Due Date
      //           </Col>
      //           {/* <Col sm={6}>
      //             <input name="BasedOn" checked={remObj.BasedOn == "SpecificDate"} value={"SpecificDate"} onChange={handleOnChangeReminder} type='radio' />&nbsp;&nbsp;&nbsp;&nbsp;Specific Date
      //           </Col> */}
      //         </Row>

      //       </Col>
      //       {/* remObj.BasedOn == 'DueDate' */}
      //       {true ? <>
      //         <Col sm={12}>
      //           <div className="form-group form-group-sm text-left">
      //             <label>Reminder Type</label>
      //             <select value={remObj.ReminderType} onChange={handleOnChangeReminder} name='ReminderType' className="form-control form-control-sm" >
      //               <option value={'daily'}>Daily</option>
      //               <option value={'on same day'}>On Due Date</option>
      //               <option value={"before due date"}>Day before due date</option>
      //             </select>
      //           </div>
      //         </Col>
      //         <Col sm={12}>
      //           {remObj.ReminderType == "before due date" ? <div className="form-group form-group-sm text-left">
      //             <label>Number Of Days</label>
      //             <input onChange={handleOnChangeReminder} value={remObj.NumberOfDays} name='NumberOfDays' type="number" className="form-control form-control-sm" />
      //           </div> : <div className="form-group form-group-sm text-left">
      //             <label>Time</label>
      //             <input onChange={handleOnChangeReminder} value={remObj.Time} name='Time' type="time" className="form-control form-control-sm" />
      //           </div>}

      //         </Col></> : <>
      //         <Col sm={12}>
      //           <div className="form-group form-group-sm text-left">
      //             <label>Date</label>
      //             <input onChange={handleOnChangeReminder} name='Date' type="date" className="form-control form-control-sm" />
      //           </div>
      //         </Col>
      //       </>}
      //       <Col sm={12}>
      //         <div className="form-group form-group-sm text-left">
      //           <label>Notify Users</label>
      //           <div className='reminder-footerIcon reminder-assignees'>
      //             {selectedUser.map((user) => {
      //               return fetchPhotoFromLocalstorage(user.photo, user.name, user)
      //             })}
      //             <UncontrolledPopover
      //               isOpen={closeAssigneePopup}
      //               // toggle={closeAssigneePopup}
      //               trigger="legacy"
      //               placement="bottom"
      //               target="reminder-pop-user"
      //               className='reminderUserDropdown'
      //             >
      //               <PopoverHeader>
      //                 <input type='text' name='SearchActivityUser' onChange={handleSearchUser} placeholder='search user...' className='form-control form-control-sm' />
      //               </PopoverHeader>
      //               <PopoverBody
      //                 className='activity-filters'
      //               >
      //                 {
      //                   allUsers.map((user) => {
      //                     return <div onClick={() => {
      //                       const selected = [...selectedUser];
      //                       if(selected.filter(one=>one.zuid==user.zuid).length==0){
      //                         let obj = {}
      //                         obj.photo = user.ProfilePhoto;
      //                         obj.name = user.full_name;
      //                         obj.zuid = user.zuid;
      //                         obj.role = "";
      //                         obj.email = user.email;
      //                         selected.push(obj);
      //                         setSelectedUser(selected);
      //                       }

      //                     }} className='row m-0 cursor-pointer'>
      //                       <div
      //                         className='row align-items-center my-1'
      //                         onClick={() => setCloseAssigneePopup(!closeAssigneePopup)}
      //                       >
      //                         <div className='col-sm-4'>
      //                           {showPhotoOfUser(user)}
      //                         </div>
      //                         <div className='col-sm-8 filterName call-log-contacts-text'>
      //                           {user.first_name + " " + user.last_name}
      //                         </div>
      //                       </div>
      //                       {/* {user._id == 'all' ? <hr style={{ width: '88%' }}></hr> : <></>} */}
      //                     </div>
      //                   })
      //                 }

      //               </PopoverBody>
      //             </UncontrolledPopover>
      //           </div>
      //         </div>

      //       </Col>
      //     </Row>
      //     <Row >
      //       <Col sm={12}>
      //         <button
      //           className='btn btn-success w-100'
      //           onClick={async () => {
      //             // const task = { ...taskData };
      //             // let obj = { ...remObj };
      //             // let reminderObj = {}

      //             // // reminder_string   : { "reminder" : [ { "reminder_criteria" :  "customdate" , "day_before" : "5" , "custom_date" : "12-10-2019" , "reminder_time" : "21:30"  , "reminder_notify_users" : "owner,follower" } ] }
      //             // if (obj.ReminderType == 'before due date') {
      //             //   reminderObj = { reminder_criteria: obj.ReminderType, 'day_before': obj.day_before, reminder_time: obj.Time, reminder_notify_users: selectedUser.map(one => one.name).join(',') }
      //             // } else {
      //             //   reminderObj = { reminder_criteria: obj.ReminderType, reminder_time: obj.Time, reminder_notify_users: selectedUser.map(one => one.name).join(',') }
      //             // }
      //             // reminderObj = { reminder: [reminderObj] }
      //             // // task.reminder_string = JSON.stringify(reminderObj)
      //             // updateTask(taskData.id_string, "reminder_string", task, JSON.stringify(reminderObj))
      //             //   .then((result) => {
      //             //     console.log(result.data);
      //             //   })
      //             //   .catch((err) => {
      //             //     console.log(err);
      //             //   })
      //             if(taskData.end_date){
      //               let dueDate=new Date(taskData.end_date);
      //               if(remObj.ReminderType=="daily"){
      //                 obj.Type="Daily";
      //                 let time=remObj.ReminderType.split(':')
      //                 dueDate.setHours(parseInt(time[0]),parseInt(time[1]),0,0);
      //               }
      //               if(remObj.ReminderType=="before due date"){
      //                 dueDate.setDate(dueDate.getDate()-parseInt(remObj.NumberOfDays));
      //               }
      //               let obj = { DateTime: new Date(dueDate), Is_Notify_Me: false, Is_Sent: false }
      //               obj.Is_Notify_Me_DateTime = "";
      //               obj.Creator_Id = localStorage.getItem('ZUID');
      //               obj.Creator_Email = userinfo.email_id;
      //               obj.Text = `<a style="color:#40a9ff" id="Task-${taskData.id_string}">${taskData.name}</a>`;
      //               obj.Creator_Name = userinfo.first_name + " " + userinfo.last_name;

      //               let arr=[]
      //               selectedUser.forEach((one)=>{
      //                 let oneObj={...obj};
      //                 oneObj.Delegate_Email=one.email;
      //                 oneObj.Delegate_Name=one.name;
      //                 arr.push(NotificationAPI.CreateReminder(oneObj))
      //               })
      //               Promise.all(arr)
      //                 .then((result) => {
      //                   console.log(result);
      //                   setReminderModal(false);
      //                 })
      //                 .catch((err) => {
      //                   console.log(err);
      //                 })
      //             }else{
      //               swal('Please first assigned due date',{dangerMode:true});
      //             }

      //           }}
      //         >Set Reminder</button>
      //         <button onClick={() => {
      //           setRemObj({});
      //           setReminderModal(false)
      //         }
      //         } className='btn btn-secondary w-100 mt-2'>Cancel</button>
      //       </Col>

      //     </Row>
      //   </ModalBody>
      // </Modal>
      return (
        <Reminder
          id={"Task"}
          task={taskData}
          users={allUsers}
          show={reminderModal}
          onClose={() => setReminderModal(false)}
        />
      );
    } else {
      return <></>;
    }
  };

  const getRelativePath = (id, name) => {
    if (name !== "none") {
      setSearchFileId(
        props.dealObj.zohoworkdriveforcrm__Workdrive_Folder_ID,
        name
      );
    }
    setRelativePathId(id);
    setRelativeFolder(id);
  };
  const taskRelativeModal = () => {
    return (
      <Modal
        isOpen={relativeModal}
        backdrop={backdrop}
        size="lg"
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              {/* <FontAwesomeIcon icon={faClock} size='3x' className="text-warning" /> */}
              <h5 className="my-2">Relative Path</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Workdrive
                data={props.dealObj}
                getRelativePath={getRelativePath}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-success "
                onClick={async () => {
                  updateTask(
                    taskData.id_string,
                    "custom_fields",
                    JSON.stringify({ UDF_CHAR3: relativeFolderId })
                  );
                  setRelativeModal(false);
                }}
              >
                Save Path
              </button>
              <button
                onClick={() => {
                  setRelativeModal(false);
                }}
                className="btn btn-secondary  ml-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const handleTaskExtraOpsDropdownClick = async (value) => {
    const parent = { ...taskData };
    const duplicate = { ...duplicateValueObj };
    if (value && value.id == "milestone") {
      let pipeline = parent.custom_fields.filter(
        (one) => one.label_name == "Pipeline"
      );
      if (pipeline && pipeline.length > 0) {
        if (pipeline[0].value !== "NO PIPELINE") {
          let filterMilestone = props.allMilestones.filter(
            (one) => one.project.id == parent.project_id
          );
          setTaskMilestoneList(filterMilestone);
          setAskMileStonePopup(true);
          setAskMileStoneId(value);
        } else {
          notification.error({
            duration: 10,
            message: "Pipeline Error",
            description:
              "Please change Pipeline first from default to another pipeline for making task as a miletone",
          });
        }
      } else {
        notification.error({
          duration: 10,
          message: "Pipeline Error",
          description:
            "Please add Pipeline in task for making task as a miletone",
        });
      }
    }
    if (value && value.id == "duplicate") {
      let duplicate = {
        TaskName: "",
        Everything: true,
        Attachements: true,
        Checklist: true,
        Comments: true,
        InputFields: true,
        LookUpFields: true,
        Dependencies: true,
        StartDate: true,
        Category: true,
        Milestone: true,
        DueDate: true,
        Assignee: true,
        TimeEstimate: true,
        Priority: true,
        Status: true,
        Subtask: true,
      };
      duplicate.TaskName = parent.name + " - Copy";
      setDuplicateValueObj(duplicate);
      setDuplicatePopUp(true);
    }
    if (value && value.id == "template") {
      setTemplatePopUp(true);
      setServiceRequested(serviceReqVariable);
      duplicate.TaskName = parent.name + " - Template";
      setDuplicateValueObj(duplicate);
    }
    if (value && value.id == "copylink") {
      getLinkOfTaskModal(parent);
    }
    if (value && value.id == "logCall") {
      if (!props.isRights("Log")) {
        swal("you don't have sufficient privillages to perform this task!", {
          dangerMode: true,
        });
        return;
      }
      setLogType("call");
      setLogModalPopUp(!logModalPopUp);
    }
    if (value && value.id == "logEmail") {
      if (!props.isRights("Log")) {
        swal("you don't have sufficient privillages to perform this task!", {
          dangerMode: true,
        });
        return;
      }
      setLogType("email");
      setLogModalPopUp(!logModalPopUp);
    }
    if (value && value.id == "logMeeting") {
      if (!props.isRights("Log")) {
        swal("you don't have sufficient privillages to perform this task!", {
          dangerMode: true,
        });
        return;
      }
      setLogType("meeting");
      setLogModalPopUp(!logModalPopUp);
    }
    if (value && value.id == "logEvent") {
      if (!props.isRights("Log")) {
        swal("you don't have sufficient privillages to perform this task!", {
          dangerMode: true,
        });
        return;
      }
      setLogType("event");
      setLogModalPopUp(!logModalPopUp);
    }

    if (value && value.id == "reminder") {
      setReminderModal(true);
    }
    if (value && value.id == "relative") {
      setRelativeModal(true);
    }
  };

  const [timeVal, setTimeVal] = useState();
  const [DateVal, setDateVal] = useState("");
  const [emailDateVal, setEmailDateVal] = useState("");
  const [emailTimeVal, setEmailTimeVal] = useState("");
  const [descVal, setDescVal] = useState("");
  const [emailDescVal, setEmailDescVal] = useState("");
  const [meetingDescVal, setMeetingDescVal] = useState("");
  const [eventDescValue, setEventDescValue] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");

  const [SearchedItem, setSearchedItem] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [meetingSearch, setMeetingSearch] = useState("");
  const [eventSearch, setEventSearch] = useState("");
  const [SearchResult, setSearchResult] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [ContactPopUser, setContactPopuser] = useState(false);
  const [meetingOutcome, setMeetingOutcome] = useState("");
  const [callOutcome, setCallOutCome] = useState("");
  const [allUsersWithContact, setAllUsersWithContact] = useState([]);
  const [LogUsers, setLogUsers] = useState([]);

  const outcomeMessage = [
    "Busy",
    "Connected",
    "Left live message",
    "Left voicemail",
    "No answer",
    "Wrong number",
  ];

  const toggleContactUser = () => {
    setContactPopuser(!ContactPopUser);
  };

  const searchContacts = (name) => {
    name = name.trim();
    if (logType === "call") {
      setSearchedItem(name);
    }
    if (logType === "meeting") {
      setMeetingSearch(name);
    }
    if (logType === "event") {
      setEventSearch(name);
    }
    if (logType === "email") {
      setEmailSearch(name);
    }
    let searchItemState = [];

    contactData.forEach((e) => {
      if (e.label.toUpperCase().startsWith(name.toUpperCase())) {
        searchItemState.push(e);
      }
    });
    // setJobKey(searchItemState);
    setSearchResult(searchItemState);
  };

  useEffect(() => {
    setDefaultContactValues(props.dealObj);
  }, [props.dealObj, logType]);

  const setDefaultContactValues = (obj) => {
    let dealObj = { ...obj };
    let arr = [];
    if (dealObj.ContactRoles) {
      dealObj.ContactRoles.forEach((one) => {
        let obj = { value: one.id, label: one[one.type], isCheck: false };
        arr.push(obj);
      });
    }
    setContactData(arr);
  };

  const saveLogData = () => {
    if (logType === "call") {
      saveCallLogData();
    }
    if (logType === "email") {
      saveEmailData();
    }
    if (logType === "meeting") {
      saveMeetingData();
    }
    if (logType === "event") {
      saveEventData();
    }
  };

  const saveCallLogData = () => {
    const dataa = {
      UserName: localStorage.getItem("UserName"),
      UserId: localStorage.getItem("UserId"),
      DealId: props.dealObj._id,
      TaskId: taskData._id,
      TaskName: taskData.TaskName,
      DocumentType: "Call",
      Description: descVal,
      Date: DateVal,
      Time: timeVal,
      Contacts: contactData,
      CallOutcome: callOutcome,
    };
    updateData(dataa);
  };

  const saveEventData = () => {
    const data = {
      Username: localStorage.getItem("UserName"),
      UserId: localStorage.getItem("UserId"),
      DealId: props.dealObj._id,
      TaskId: taskData._id,
      TaskName: taskData.TaskName,
      DocumentType: "Event",
      Description: eventDescValue,
      Date: eventDate,
      Time: eventTime,
      Contacts: contactData,
    };
    updateData(data);
  };

  const saveEmailData = () => {
    const data = {
      Username: localStorage.getItem("UserName"),
      UserId: localStorage.getItem("UserId"),
      DealId: props.dealObj._id,
      TaskId: taskData._id,
      TaskName: taskData.TaskName,
      DocumentType: "Email",
      Description: emailDescVal,
      Date: emailDateVal,
      Time: emailTimeVal,
      Contacts: contactData,
    };
    updateData(data);
  };

  const saveMeetingData = () => {
    const data = {
      Username: localStorage.getItem("UserName"),
      UserId: localStorage.getItem("UserId"),
      DealId: props.dealObj._id,
      TaskId: taskData._id,
      TaskName: taskData.TaskName,
      DocumentType: "Meeting",
      Description: meetingDescVal,
      Date: meetingDate,
      Time: meetingTime,
      Contacts: contactData,
      CallOutcome: meetingOutcome,
    };
    updateData(data);
  };

  const updateData = async (data) => {
    let returnData = await HttpService.CreateUpdate("createLog", data).then(
      (res) => {
        if (res.data.ack === 1 || res.data.Ack === 1) {
          setLogModalPopUp(false);
        } else {
          alert("Netrork error");
        }
      }
    );
  };

  // useEffect(() => {
  //   getAllLogUsers()
  // }, [])

  const getAllLogUsers = async () => {
    let returnData = await HttpService.getByBoj("getallusers", {}).then(
      async (res) => {
        if (res.data.length > 0 && res.data != null) {
          let contacts = [];
          res.data.map((contact, i) => {
            contacts.push(contact);
          });
          let resContact = await HttpService.getByBoj("getAllContact", {});
          resContact.data.map((contact, i) => {
            contacts.push(contact);
          });
          setAllUsersWithContact(contacts);
          setLogUsers(res.data);
        }
      }
    );
  };

  const getCkEditorFeed = (users) => {
    const feeds = [];
    users.forEach((one) => {
      let obj = {};
      obj.id = "@" + one.FirstName + " " + one.LastName;
      if (one.ProfilePhoto) {
        obj.avatar = one.ProfilePhoto;
      } else {
        obj.avatar = "None";
      }
      obj.name = one.FirstName + " " + one.LastName;
      feeds.push(obj);
    });
    return feeds;
  };

  const customItemRenderer = (item) => {
    const itemElement = document.createElement("span");
    const avatar = document.createElement("img");
    const userNameElement = document.createElement("span");
    const fullNameElement = document.createElement("span");

    itemElement.classList.add("mention__item");
    if (item.avatar == "None") {
      avatar.src = `${defaultUser}`;
    } else {
      avatar.src = `${item.avatar}`;
    }

    userNameElement.classList.add("mention__item__user-name");
    userNameElement.textContent = item.id;

    fullNameElement.classList.add("mention__item__full-name");
    fullNameElement.textContent = item.name;

    itemElement.appendChild(avatar);
    itemElement.appendChild(userNameElement);
    itemElement.appendChild(fullNameElement);

    return itemElement;
  };

  const handleOnChangeComment = (e, editor) => {
    const data = editor.getData();
    const mentions = [...userMentions];
    if (data.includes("mention")) {
      var doc = new DOMParser().parseFromString(data, "text/html");
      let elems = doc.getElementsByClassName("mention");
      for (let i = 0; i < elems.length; i++) {
        if (!mentions.includes(elems[i].innerHTML)) {
          mentions.push(elems[i].innerHTML);
        }
      }

      setUserMentions(mentions);
    }
    setComms(data);
  };

  const handleOnChange = (e, editor) => {
    const data = editor.getData();
    if (logType === "call") {
      setDescVal(data);
    }
    if (logType === "email") {
      setEmailDescVal(data);
    }
    if (logType === "meeting") {
      setMeetingDescVal(data);
    }
    if (logType === "event") {
      setEventDescValue(data);
    }
  };

  const handlechangeContact = (e) => {
    let contactRoles = [...contactData];
    // contactRoles.isCheck = e.target.checked;
    contactRoles.filter((obj) => obj.value == e.currentTarget.id)[0].isCheck =
      e.target.checked;
    setContactData(contactRoles);
  };

  const handleOnChangeData = (e, name) => {
    if (name === "Date") {
      setDateVal(e);
    }
    if (name === "Time") {
      setTimeVal(e);
    }
    if (name === "EmailDate") {
      setEmailDateVal(e);
    }
    if (name === "EmailTime") {
      setEmailTimeVal(e);
    }
    if (name === "MeetingDate") {
      setMeetingDate(e);
    }
    if (name === "MeetingTime") {
      setMeetingTime(e);
    }
    if (name === "EventTime") {
      setEventTime(e);
    }
    if (name === "EventDate") {
      setEventDate(e);
    }
  };

  const getCallLogBodyHeader = () => {
    return (
      <div className="callLog">
        <Row>
          <Col sm={5}>
            <h6 className="muted-heading">Contacted</h6>
            <Row>
              <Col>
                <h6>
                  Contacted
                  {/* {getLengthOfContacts("call")}   */}
                  <FontAwesomeIcon
                    color="#018EE0"
                    icon={faCaretDown}
                    id="log-contacted-call"
                  />
                </h6>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  toggle={toggleContactUser}
                  isOpen={ContactPopUser}
                  target="log-contacted-call"
                >
                  <PopoverHeader>
                    <input
                      type="text"
                      name="SearchActivityUser"
                      placeholder="search user..."
                      className="form-control form-control-sm"
                      value={SearchedItem}
                      onChange={(e) => searchContacts(e.target.value)}
                    />
                  </PopoverHeader>
                  <PopoverBody className="activity-filters">
                    {SearchResult.length > 0
                      ? SearchResult.map((user) => (
                          <div className="row m-0">
                            <div>
                              <div>
                                <input
                                  id={user.value}
                                  name={user.label}
                                  type="checkbox"
                                  checked={user.isCheck}
                                  onChange={(e) => handlechangeContact(e)}
                                />
                                <span className="call-log-contacts-text">
                                  {user.label}
                                </span>
                              </div>

                              {/* <div className='col-sm-9 filterName'>
                
              </div> */}
                            </div>
                          </div>
                        ))
                      : contactData.map((user) => (
                          <div className="row m-0">
                            <div>
                              <div>
                                <input
                                  id={user.value}
                                  name={user.label}
                                  type="checkbox"
                                  checked={user.isCheck}
                                  onChange={(e) => handlechangeContact(e)}
                                />
                                <span className="call-log-contacts-text">
                                  {user.label}
                                </span>
                              </div>

                              {/* <div className='col-sm-9 filterName'>

                                                  </div> */}
                            </div>
                          </div>
                        ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row className="form-group">
              <Col>
                <label className="muted-heading">Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="Date"
                  value={DateVal}
                  onChange={(e) =>
                    handleOnChangeData(e.currentTarget.value, "Date")
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm={5}>
            <h6 className="muted-heading">Call Outcome</h6>
            <Row>
              <Col>
                <h6>
                  {callOutcome === "" ? "Select Outcome" : callOutcome}
                  <FontAwesomeIcon
                    color="#018EE0"
                    icon={faCaretDown}
                    id="log-outcome-call"
                  />
                </h6>
                <UncontrolledPopover
                  // isOpen={addLogPopup}
                  // toggle={addLogtoggle}
                  trigger="legacy"
                  placement="bottom"
                  target="log-outcome-call"
                >
                  <PopoverBody>
                    <ul type="none" className="taskopen-popup">
                      {outcomeMessage.map((message, idx) => {
                        return (
                          <li
                            onClick={() => {
                              setCallOutCome(message);
                            }}
                          >
                            {message}
                          </li>
                        );
                      })}
                    </ul>
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row className="form-group">
              <Col>
                <label className="muted-heading">Time</label>
                <input
                  className="form-control"
                  type="time"
                  name="Time"
                  value={timeVal}
                  onChange={(e) =>
                    handleOnChangeData(e.currentTarget.value, "Time")
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const getEmailLogBodyHeader = () => {
    return (
      <div className="callLog">
        <Row>
          <Col sm={4} className="mt-2">
            <h6 className="muted-heading">Contacted</h6>
            <Row>
              <Col>
                <h6>
                  {/* {getLengthOfContacts("email")} */}
                  Contacted{" "}
                  <FontAwesomeIcon
                    color="#018EE0"
                    icon={faCaretDown}
                    id="log-contacted-email"
                  />
                </h6>
                <UncontrolledPopover
                  placement="bottom"
                  toggle={toggleContactUser}
                  isOpen={ContactPopUser}
                  trigger="legacy"
                  target="log-contacted-email"
                >
                  <PopoverHeader>
                    <input
                      type="text"
                      name="SearchActivityUser"
                      placeholder="search user..."
                      value={emailSearch}
                      onChange={(e) => searchContacts(e.target.value)}
                      className="form-control form-control-sm"
                    />
                  </PopoverHeader>
                  <PopoverBody className="activity-filters">
                    {SearchResult.length > 0
                      ? SearchResult.map((user) => (
                          <div className="row m-0">
                            <div>
                              <div>
                                <input
                                  id={user.value}
                                  name={user.label}
                                  type="checkbox"
                                  checked={user.isCheck}
                                  onChange={(e) => handlechangeContact(e)}
                                />
                                <span className="call-log-contacts-text">
                                  {user.label}
                                </span>
                              </div>

                              {/* <div className='col-sm-9 filterName'>
                  
                </div> */}
                            </div>
                          </div>
                        ))
                      : contactData.map((user) => (
                          <div className="row m-0">
                            <div>
                              <div>
                                <input
                                  id={user.value}
                                  name={user.label}
                                  type="checkbox"
                                  checked={user.isCheck}
                                  onChange={(e) => handlechangeContact(e)}
                                />
                                <span className="call-log-contacts-text">
                                  {user.label}
                                </span>
                              </div>

                              {/* <div className='col-sm-9 filterName'>
  
</div> */}
                            </div>
                          </div>
                        ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
            </Row>
          </Col>
          <Col sm={4} className="p-0">
            <Row className="form-group">
              <Col>
                <label className="muted-heading">Date</label>
                <input
                  type="date"
                  value={emailDateVal}
                  onChange={(e) =>
                    handleOnChangeData(e.currentTarget.value, "EmailDate")
                  }
                  className="form-control"
                />
              </Col>
            </Row>
          </Col>
          <Col sm={4}>
            <Row className="form-group">
              <Col>
                <label className="muted-heading">Time</label>
                <input
                  className="form-control"
                  type="time"
                  value={emailTimeVal}
                  onChange={(e) =>
                    handleOnChangeData(e.currentTarget.value, "EmailTime")
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const getMeetingLogBodyHeader = () => {
    return (
      <div className="callLog">
        <Row>
          <Col sm={5}>
            <h6 className="muted-heading">Attandees</h6>
            <Row>
              <Col>
                <h6>
                  {/* {getLengthOfContacts("meeting")} */}
                  attandees{" "}
                  <FontAwesomeIcon
                    color="#018EE0"
                    icon={faCaretDown}
                    id="log-contacted-meet"
                  />
                </h6>
                <UncontrolledPopover
                  placement="bottom"
                  trigger="legacy"
                  toggle={toggleContactUser}
                  isOpen={ContactPopUser}
                  target="log-contacted-meet"
                >
                  <PopoverHeader>
                    <input
                      type="text"
                      name="SearchActivityUser"
                      placeholder="search user..."
                      className="form-control form-control-sm"
                      value={meetingSearch}
                      onChange={(e) => searchContacts(e.target.value)}
                    />
                  </PopoverHeader>
                  <PopoverBody className="activity-filters">
                    {SearchResult.length > 0
                      ? SearchResult.map((user) => (
                          <div className="row m-0">
                            <div>
                              <div>
                                <input
                                  id={user.value}
                                  name={user.label}
                                  type="checkbox"
                                  checked={user.isCheck}
                                  onChange={(e) => handlechangeContact(e)}
                                />
                                <span className="call-log-contacts-text">
                                  {user.label}
                                </span>
                              </div>

                              {/* <div className='col-sm-9 filterName'>
                
              </div> */}
                            </div>
                          </div>
                        ))
                      : contactData.map((user) => (
                          <div className="row m-0">
                            <div>
                              <div>
                                <input
                                  id={user.value}
                                  name={user.label}
                                  type="checkbox"
                                  checked={user.isCheck}
                                  onChange={(e) => handlechangeContact(e)}
                                />
                                <span className="call-log-contacts-text">
                                  {user.label}
                                </span>
                              </div>

                              {/* <div className='col-sm-9 filterName'>

</div> */}
                            </div>
                          </div>
                        ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row className="form-group">
              <Col>
                <label className="muted-heading">Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={meetingDate}
                  onChange={(e) =>
                    handleOnChangeData(e.currentTarget.value, "MeetingDate")
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm={5}>
            <h6 className="muted-heading">Outcome</h6>
            <Row>
              <Col>
                <h6>
                  {meetingOutcome === "" ? "meeting Outcome" : meetingOutcome}
                  <FontAwesomeIcon
                    color="#018EE0"
                    icon={faCaretDown}
                    id="log-contacted-email-outcome"
                  />
                </h6>
                <UncontrolledPopover
                  // isOpen={addLogPopup}
                  // toggle={addLogtoggle}
                  trigger="legacy"
                  placement="bottom"
                  target="log-contacted-email-outcome"
                >
                  <PopoverBody>
                    <ul type="none" className="taskopen-popup">
                      {outcomeMessage.map((message, idx) => {
                        return (
                          <li onClick={() => setMeetingOutcome(message)}>
                            {message}
                          </li>
                        );
                      })}
                    </ul>
                  </PopoverBody>
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row className="form-group">
              <Col>
                <label className="muted-heading">Time</label>
                <input
                  className="form-control"
                  type="time"
                  value={meetingTime}
                  onChange={(e) =>
                    handleOnChangeData(e.currentTarget.value, "MeetingTime")
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const openLogModals = () => {
    return (
      <Modal
        // className={sizeOfScreen == true ? "fullNote-width note-modalwidth" : "note-modalwidth shadow"}
        className="note-modalwidth shadow"
        isOpen={logModalPopUp}
        backdrop={"static"}
      >
        <div className="modal-header-class">
          {logType} Log
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={() => {
                setLogModalPopUp(false);
                // setSizeOfScreen(false)
              }}
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              icon={faExpandAlt}
              className="icon-time-color mr-3"
              // className={sizeOfScreen == true ? "" : ""}
              // onClick={() => setSizeOfScreen(!sizeOfScreen)}
            ></FontAwesomeIcon>
          </div>
        </div>

        <ModalBody>
          {logType === "email"
            ? getEmailLogBodyHeader()
            : logType === "meeting"
            ? getMeetingLogBodyHeader()
            : logType === "call"
            ? getCallLogBodyHeader()
            : ""}
          <FormGroup className="marb-16 padtop-15">
            <div className="note-ckeditor">
              <CKEditor
                className="ck-editor__editable"
                editor={ClassicEditor}
                onChange={(e, editor) => {
                  handleOnChange(e, editor);
                }}
                name="Description"
                data={
                  logType === "call"
                    ? descVal
                    : ""
                    ? logType === "email"
                      ? emailDescVal
                      : meetingDescVal
                    : ""
                }
                config={{
                  mention: {
                    feeds: [
                      {
                        marker: "@",
                        feed: getCkEditorFeed(allUsersWithContact),
                        itemRenderer: customItemRenderer,
                        minimumCharacters: 1,
                      },
                    ],
                  },
                  placeholder: "Start typing comment ...",
                  toolbar: [
                    "bold",
                    "italic",
                    "heading",
                    "|",

                    "undo",
                    "redo",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "insertTable",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "link",
                    "uploadImage",
                  ],
                }}
              />
            </div>
          </FormGroup>

          <FormGroup className="m-0">
            <Row className="notes-btns">
              <Col sm={3}>
                <Button
                  className="continue-button small-3d-button font-13"
                  onClick={() => saveLogData()}
                >
                  Log Activity
                </Button>
              </Col>
              <Col sm={3}>
                <Button
                  className="continue-button small-3d-button font-13"
                  // onClick={() => {
                  //     closeLogModalActvity()
                  // setSizeOfScreen(false)
                  // }}
                >
                  Cancel
                </Button>
              </Col>
              {/* <Col sm={6}>
                        <input type='checkbox' onChange={(e)=>{
                            setLogFollowStatus(e.currentTarget.checked);
                        }} name='Follow' />
                        <spna>Create a task to follow up</spna>
                        {logFollowStatus?<a>select date</a>:<></>}
                    </Col> */}
            </Row>
          </FormGroup>
        </ModalBody>
      </Modal>
    );
  };

  const getLinkOfTaskModal = (parent) => {
    if (parent._id) {
      let locationUrl = window.location.hostname;
      let pathName = window.location.pathname;
      let link =
        locationUrl + history.location.pathname + "?taskId=" + parent.UniqueId;
      navigator.clipboard.writeText(link);
    }
  };

  const [copyTasks, setCopyTasks] = useState([]);

  const [popUpTemplate, setTemplatePopUp] = useState(false);
  const popUpTemplateToogle = () => setTemplatePopUp(!popUpTemplate);

  const [popUpDuplicate, setDuplicatePopUp] = useState(false);
  const popUpDuplicateToogle = () => setDuplicatePopUp(!popUpDuplicate);

  const [duplicateValueObj, setDuplicateValueObj] = useState({
    TaskName: "",

    Everything: true,
    Attachements: true,
    Comments: true,
    InputFields: true,
    LookUpFields: true,
    Dependencies: true,
    Category: true,
    Milestone: true,
    StartDate: true,
    DueDate: true,
    Assignee: true,
    TimeEstimate: true,
    Priority: true,
    Status: true,
    Subtask: true,
  });
  const valuesLableTemplate = {
    DueDate: "Due Date",
    StartDate: "Start Date",
    Attachements: "Attachments",
    LookUpFields: "Look Up Fields",
    Comments: "Comments",
    Status: "Status",
    Priority: "Priority",
    Subtask: "Subtask",
    Assignee: "Assignee",
    InputFields: "Input Fields",
  };

  const handleOnChangeDupicateCheckBox = (e) => {
    const obj = { ...duplicateValueObj };
    if (e.currentTarget.name == "TaskName") {
      obj[e.currentTarget.name] = e.currentTarget.value;
    } else {
      if (e.currentTarget.checked) {
        if (e.currentTarget.name == "Everything") {
          let keys = Object.keys(obj);
          keys.forEach((key) => {
            if (key !== "TaskName") {
              obj[key] = true;
            }
          });
        } else {
          obj[e.currentTarget.name] = e.currentTarget.checked;
          obj["Everything"] = false;
        }
      } else {
        if (e.currentTarget.name == "Everything") {
          let keys = Object.keys(obj);
          keys.forEach((key) => {
            if (key !== "TaskName") {
              obj[key] = false;
            }
          });
        } else {
          obj[e.currentTarget.name] = e.currentTarget.checked;
          obj["Everything"] = false;
        }
      }
    }
    setDuplicateValueObj(obj);
  };

  const addDuplicateTaskComm_InputFields_LookUpFields = (
    oldTaskId,
    newTaskId,
    newTaskName
  ) => {
    const backendUrl = Config.backend_url;
    const duplicateValue = { ...duplicateValueObj };
    if (duplicateValue.Comments) {
      HttpService.CreateUpdate("gettaskcomms", { TaskID: oldTaskId })
        .then((result) => {
          let arr = [];
          result.data.forEach((one) => {
            let obj = { ...one };
            delete obj._id;
            obj.TaskID = newTaskId;
            arr.push(HttpService.CreateUpdate("addtaskcomms", obj));
          });
          return Promise.all(arr);
        })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    }
    if (duplicateValue.InputFields) {
      // HttpService.CreateUpdate('getTaskFields', { TaskId: oldTaskId })
      axios
        .post(backendUrl + "getTaskFields", { TaskId: oldTaskId })
        .then((result) => {
          let arr = [];
          if (result.data.Data) {
            result.data.Data.forEach((one) => {
              if (one.Type !== "LookUp") {
                let obj = { ...one };
                delete obj._id;
                obj.TaskId = newTaskId;
                obj.TaskName = newTaskName;
                arr.push(HttpService.CreateUpdate("createTaskField", obj));
              }
            });
          }
          return Promise.all(arr);
        })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    }
    if (duplicateValue.LookUpFields) {
      // HttpService.CreateUpdate('getTaskFields', { TaskId: oldTaskId })
      axios
        .post(backendUrl + "getTaskFields", { TaskId: oldTaskId })
        .then((result) => {
          let arr = [];
          if (result.data.Data) {
            result.data.Data.forEach((one) => {
              if (one.Type == "LookUp") {
                let obj = { ...one };
                delete obj._id;
                obj.TaskId = newTaskId;
                obj.TaskName = newTaskName;
                arr.push(HttpService.CreateUpdate("createTaskField", obj));
              }
            });
          }
          return Promise.all(arr);
        })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    }
  };

  const handleGetDuplicateTaskObj = () => {
    const taskObj = { ...taskData };
    const duplicateObj = { ...duplicateValueObj };

    if (!duplicateObj.Assignee) {
      taskObj.Assignee.splice(0, taskObj.Assignee.length);
    }
    // if (!duplicateObj.Milestone) {
    //   delete taskObj.isMilestone;
    //   delete taskObj.Milestone
    // }
    if (!duplicateObj.Checklist) {
      taskObj.Checklist.splice(0, taskObj.Checklist.length);
    }
    if (!duplicateObj.Attachements) {
      taskObj.AttachedFiles.splice(0, taskObj.AttachedFiles.length);
    }
    if (!duplicateObj.Dependencies) {
      taskObj.DependentOn.splice(0, taskObj.DependentOn.length);
    }
    if (!duplicateObj.DueDate) {
      delete taskObj.DueDate;
    }
    if (!duplicateObj.TimeEstimate) {
      delete taskObj.TimeEstimateValue;
    }
    if (!duplicateObj.Status) {
      delete taskObj.Status;
    }
    if (!duplicateObj.Priority) {
      delete taskObj.Priority;
    }
    return taskObj;
  };

  const handleServiceChange = (values) => {
    setSelectedServiceReq(values);
  };

  const renderTemplateTaskModal = () => {
    return (
      <Modal
        isOpen={popUpTemplate}
        backdrop={backdrop}
        size="md"
        toggle={popUpTemplateToogle}
        className="taskComplete-warning duplicate-task-modal"
      >
        <ModalBody>
          <Row>
            <Col sm={6}>
              <span className="float-left">
                <h4>Task Template</h4>
              </span>
            </Col>
            <Col sm={6}>
              <span className="float-right">
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={popUpTemplateToogle}
                  icon={faTimes}
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="small text-left">Template Name</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="form-group">
                <input
                  onChange={handleOnChangeDupicateCheckBox}
                  defaultValue={duplicateValueObj.TaskName}
                  name="TaskName"
                  type="text"
                  placeholder="template name"
                  className="form-control form-control-sm duplicate-taskname"
                />
              </div>
            </Col>
          </Row>
          {/* <Row >
          <Col sm={12}>
            <p className="mr-2 px-1">
              <p className='small text-left'>Services Associated With :</p>
              <Select
                options={serviceRequested}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={handleServiceChange}
                allowSelectAll={true}
                value={selectedServiceReq}
                menuPlacement="auto"
              // onChange={handleServiceChange}
              // value={getValues()}
              // isMulti={true}
              // options={options}
              // isClearable={false}
              />
            </p>
          </Col>
        </Row> */}
          <Row className="duplicate-task-box">
            <Col sm={6}>
              <Row>
                <p style={{ fontSize: 16 }} className="small text-left">
                  What do you want to copy?
                </p>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Everything}
                      name="Everything"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Everything</p>
                </Col>
              </Row>

              <hr className="m-0 mb-2" />

              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Attachements}
                      name="Attachements"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Attachements</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Comments}
                      name="Comments"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Comments</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.InputFields}
                      name="InputFields"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Input Fields</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.LookUpFields}
                      name="LookUpFields"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> LookUp Fields</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Dependencies}
                      name="Dependencies"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Dependencies</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="StartDate"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.StartDate}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Start Date</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="DueDate"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.DueDate}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Due Date</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="Category"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Category}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Category</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="Milestone"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Milestone}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small">Milestone</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Assignee"
                      checked={duplicateValueObj.Assignee}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className=" text-left small"> Assignee</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="TimeEstimate"
                      checked={duplicateValueObj.TimeEstimate}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Time Estimate</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Status"
                      checked={duplicateValueObj.Status}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Status</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Priority"
                      checked={duplicateValueObj.Priority}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Priority</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Subtask"
                      checked={duplicateValueObj.Subtask}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Subtask</p>
                </Col>
              </Row>
            </Col>
            {/* <Col sm={6}>
            <Row>
              <Col sm={1}>
                <div >
                  <input name='CopyAssignee' checked={duplicateValueObj.CopyAssignee} type='checkbox' />
                </div>
              </Col>
              <Col sm={8}>
                <p className='text-left small'> Copy Assignee</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {getDropdownMenu(
                  parentObj,
                  addAssigneeTblImgIcon,
                  NOT_DEFINED_VALUE,
                  "TASK_DROPDOWN_MENU",
                  NOT_DEFINED_VALUE,
                  NOT_DEFINED_VALUE
                )}
                {parentObj&&parentObj.Assignee.length > 0
                  ? parentObj.Assignee.map((assignee, i) =>
                    getDropdownMenu(
                      parentObj,
                      addAssigneeImgTblImg,
                      assignee,
                      "TASK_DROPDOWN_MENU",
                      NOT_DEFINED_VALUE,
                      NOT_DEFINED_VALUE
                    )
                  )
                  : null}
              </Col>

            </Row>
          </Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-primary w-50 float-right"
                onClick={() => handleCreateDuplicateTask(true)}
              >
                Save Template{" "}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const renderUseTemplateModal = () => {
    return (
      <Modal
        isOpen={useTemplatePopUp}
        backdrop={backdrop}
        size="md"
        toggle={setUseTemplateToggle}
        className="taskComplete-warning duplicate-task-modal"
      >
        <ModalBody>
          <Row>
            <Col sm={6}>
              <span className="float-left">
                <h4>Use Template</h4>
              </span>
            </Col>
            <Col sm={6}>
              <span className="float-right">
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={setUseTemplateToggle}
                  icon={faTimes}
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="small text-left">New Task Name</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="form-group">
                <input
                  onChange={handleOnChangeDupicateCheckBox}
                  name="TaskName"
                  type="text"
                  placeholder="task name"
                  className="form-control form-control-sm duplicate-taskname"
                />
              </div>
            </Col>
          </Row>
          <Row className="duplicate-task-box">
            <Col sm={6}>
              <Row>
                <p style={{ fontSize: 16 }} className="small text-left">
                  What do you want to copy?
                </p>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Everything}
                      name="Everything"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Import Everything</p>
                </Col>
              </Row>

              <hr className="m-0 mb-2" />

              {
                <>
                  {duplicateValueObj.Attachements ? (
                    <Row>
                      <Col sm={1}>
                        <div className="float-left">
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            checked={duplicateValueObj.Attachements}
                            name="Attachements"
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Attachements</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.Checklist ? (
                    <Row>
                      <Col sm={1}>
                        <div className="float-left">
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            checked={duplicateValueObj.Checklist}
                            name="Checklist"
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Checklist</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.Comments ? (
                    <Row>
                      <Col sm={1}>
                        <div className="float-left">
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            checked={duplicateValueObj.Comments}
                            name="Comments"
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Comments</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.InputFields ? (
                    <Row>
                      <Col sm={1}>
                        <div className="float-left">
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            checked={duplicateValueObj.InputFields}
                            name="InputFields"
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Input Fields</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.LookUpFields ? (
                    <Row>
                      <Col sm={1}>
                        <div className="float-left">
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            checked={duplicateValueObj.LookUpFields}
                            name="LookUpFields"
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> LookUp Fields</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.Dependencies ? (
                    <Row>
                      <Col sm={1}>
                        <div>
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            checked={duplicateValueObj.Dependencies}
                            name="Dependencies"
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Dependencies</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.DueDate ? (
                    <Row>
                      <Col sm={1}>
                        <div>
                          <input
                            name="DueDate"
                            onChange={handleOnChangeDupicateCheckBox}
                            checked={duplicateValueObj.DueDate}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Due Date</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.Assignee ? (
                    <Row>
                      <Col sm={1}>
                        <div>
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            name="Assignee"
                            checked={duplicateValueObj.Assignee}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className=" text-left small"> Assignee</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.TimeEstimate ? (
                    <Row>
                      <Col sm={1}>
                        <div>
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            name="TimeEstimate"
                            checked={duplicateValueObj.TimeEstimate}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Time Estimate</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.Priority ? (
                    <Row>
                      <Col sm={1}>
                        <div>
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            name="Priority"
                            checked={duplicateValueObj.Priority}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Priority</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.Status ? (
                    <Row>
                      <Col sm={1}>
                        <div>
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            name="Status"
                            checked={duplicateValueObj.Status}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Status</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {duplicateValueObj.Subtask ? (
                    <Row>
                      <Col sm={1}>
                        <div>
                          <input
                            onChange={handleOnChangeDupicateCheckBox}
                            name="Subtask"
                            checked={duplicateValueObj.Subtask}
                            type="checkbox"
                          />
                        </div>
                      </Col>
                      <Col sm={8}>
                        <p className="text-left small"> Subtask</p>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </>
              }
            </Col>
            {/* <Col sm={6}>
            <Row>
              <Col sm={1}>
                <div >
                  <input name='Customize' onChange={(e) => {
                    let obj = { ...duplicateValueObj }
                    let keys = Object.keys(obj);
                    keys.forEach(key => {
                      if (key !== 'TaskName') {
                        obj[key] = !e.currentTarget.checked;
                      }
                    })

                    setDuplicateValueObj(obj);

                  }} type='checkbox' />
                </div>
              </Col>
              <Col sm={8}>
                <p className='text-left small'> Customize</p>
              </Col>
            </Row>
          </Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <button
                onClick={() => {
                  handleCreateDuplicateTask(false);
                }}
                className="btn btn-primary w-50 float-right"
              >
                Use Template{" "}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const showTaskComment = (comText, oneActivity) => {
    if (
      actionOnCommentData.action == "Edit" &&
      actionOnCommentData.comment &&
      actionOnCommentData.comment.id_string == oneActivity.id_string
    ) {
      return (
        <Comment
          alltasks={props.taskDocs}
          commentId={oneActivity.id_string}
          value={comText}
          action="Edit"
          setTaskData={setTaskData}
          // handleClickSubtaskEdit={props.handleClickSubtaskEdit}
          // handleOpenTaskModal={props.handleOpenTaskModel}
          // taskType={props.taskType}
          onClose={() => {
            setActionOnCommentData({});
          }}
          closeModal={props.handleCloseTaskModel}
          history={history}
          id="task"
          projectName={taskData.project_name}
          taskname={taskData.name}
          allusers={allUsers}
          taskId={taskData.id_string}
          projectId={taskData.project_id}
          commentMessages={commentMessages}
          setCommentMessages={setCommentMessages}
          getAllTaskMessage={getAllTaskMessage}
        />
      );
    } else {
      return (
        <div
          id={"Comment" + oneActivity.id_string}
          className="name-comment-box"
        >
          <div className="p-3">
            <div className="commentBox-header">
              <p>
                <strong
                  onClick={() =>
                    history.push({
                      pathname: "/setting/users/4695950000000296001/Users",
                      state: { tab: "Users" },
                    })
                  }
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {oneActivity.added_person}
                </strong>{" "}
                Commented
              </p>
              <p>{dayjs(oneActivity.created_time_long).format("LLL")}</p>
              {/* {/ <p>{getDate(comments.createdAt)} at {getTime(comments.createdAt)}</p > /} */}
            </div>
            <div className="task-modal-comment">
              {/* {/ <p dangerouslySetInnerHTML={{ __html: comments.Comments }}></p > /} */}

              <div
                onClick={(e) =>
                  handleClickMention(e, "task-modal-comment" + oneActivity.id)
                }
                id={"task-modal-comment" + oneActivity.id}
                dangerouslySetInnerHTML={{
                  __html: convertZohoMentionToCRM(comText),
                }}
              ></div>
              <div className="task-modal-comment-action">
                <FontAwesomeIcon
                  onClick={() => {
                    setActionOnCommentData({
                      action: "Edit",
                      comment: oneActivity,
                    });
                  }}
                  className="cursor-pointer"
                  icon={faPen}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    swal({
                      title: "Are you sure? want to delete this comment",
                      text: "",
                      icon: "warning",
                      buttons: ["No", "Yes"],
                      dangerMode: true,
                    }).then(function (isConfirm) {
                      if (isConfirm) {
                        TaskService.DeleteTaskComment(
                          taskData.project_id,
                          taskData.id_string,
                          oneActivity.id_string
                        )
                          .then((result) => {
                            getAllTaskMessage(
                              taskData.project_id,
                              taskData.id_string
                            );
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }
                    });
                  }}
                  className="ml-2 cursor-pointer"
                  icon={faTrashAlt}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderDuplicateModal = () => {
    return (
      <Modal
        isOpen={popUpDuplicate}
        backdrop={backdrop}
        size="md"
        toggle={popUpDuplicateToogle}
        className="taskComplete-warning duplicate-task-modal"
      >
        <ModalBody>
          <Row>
            <Col sm={6}>
              <span className="float-left">
                <h4>Duplicate Task</h4>
              </span>
            </Col>
            <Col sm={6}>
              <span className="float-right">
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={popUpDuplicateToogle}
                  icon={faTimes}
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className="small text-left">New Task Name</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="form-group">
                <input
                  onChange={handleOnChangeDupicateCheckBox}
                  defaultValue={duplicateValueObj.TaskName}
                  name="TaskName"
                  type="text"
                  placeholder="new task name"
                  className="form-control form-control-sm duplicate-taskname"
                />
              </div>
            </Col>
          </Row>
          <Row className="duplicate-task-box">
            <Col sm={6}>
              <Row>
                <p style={{ fontSize: 16 }} className="small text-left">
                  What do you want to copy?
                </p>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Everything}
                      name="Everything"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Everything</p>
                </Col>
              </Row>

              <hr className="m-0 mb-2" />

              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Attachements}
                      name="Attachements"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Attachements</p>
                </Col>
              </Row>

              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Comments}
                      name="Comments"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Comments</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.InputFields}
                      name="InputFields"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Input Fields</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className="float-left">
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.LookUpFields}
                      name="LookUpFields"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> LookUp Fields</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Dependencies}
                      name="Dependencies"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Dependencies</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="DueDate"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.DueDate}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Due Date</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="StartDate"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.StartDate}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Start Date</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="Category"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Category}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Category</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      name="Milestone"
                      onChange={handleOnChangeDupicateCheckBox}
                      checked={duplicateValueObj.Milestone}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small">Milestone</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Assignee"
                      checked={duplicateValueObj.Assignee}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className=" text-left small"> Assignee</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="TimeEstimate"
                      checked={duplicateValueObj.TimeEstimate}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small"> Time Estimate</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Priority"
                      checked={duplicateValueObj.Priority}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small">Priority</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Status"
                      checked={duplicateValueObj.Status}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small">Status</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div>
                    <input
                      onChange={handleOnChangeDupicateCheckBox}
                      name="Subtask"
                      checked={duplicateValueObj.Subtask}
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className="text-left small">Subtask</p>
                </Col>
              </Row>
            </Col>
            {/* <Col sm={6}>
            <Row>
              <Col sm={1}>
                <div >
                  <input name='CopyAssignee' checked={duplicateValueObj.CopyAssignee} type='checkbox' />
                </div>
              </Col>
              <Col sm={8}>
                <p className='text-left small'> Copy Assignee</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {getDropdownMenu(
                  parentObj,
                  addAssigneeTblImgIcon,
                  NOT_DEFINED_VALUE,
                  "TASK_DROPDOWN_MENU",
                  NOT_DEFINED_VALUE,
                  NOT_DEFINED_VALUE
                )}
                {parentObj&&parentObj.Assignee.length > 0
                  ? parentObj.Assignee.map((assignee, i) =>
                    getDropdownMenu(
                      parentObj,
                      addAssigneeImgTblImg,
                      assignee,
                      "TASK_DROPDOWN_MENU",
                      NOT_DEFINED_VALUE,
                      NOT_DEFINED_VALUE
                    )
                  )
                  : null}
              </Col>

            </Row>
          </Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-primary w-50 float-right"
                onClick={() => {
                  if (
                    taskData &&
                    taskData.custom_fields.filter(
                      (one) => one.label_name == "Pipeline"
                    ).length > 0
                  ) {
                    let obj = { ...duplicateValueObj };
                    let pipe = taskData.custom_fields.filter(
                      (one) => one.label_name == "Pipeline"
                    )[0].value;
                    obj.Pipeline = [{ label: getGroupVal(pipe), value: pipe }];
                    props.handleCreateDuplicateTask(obj, taskData);
                    setDuplicatePopUp(false);
                  }
                }}
              >
                Duplicate Task{" "}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const handleCreateDuplicateSubTask = async (
    oldTaskId,
    newTaskId,
    isTemplate,
    services
  ) => {
    let subtaskRelation = await HttpService.CreateUpdate(
      "getTaskRelationDocByParentTaskId",
      { ParentTaskId: oldTaskId }
    );
    if (subtaskRelation.data.length > 0) {
      const duplicateObj = { ...duplicateValueObj };
      subtaskRelation = subtaskRelation.data[0];
      let subtask = props.taskDocs.filter(
        (one) => one._id == subtaskRelation.TaskId
      );
      subtask = { ...subtask[0] };
      let oldTaskId1 = subtask._id;
      delete subtask._id;
      subtask.isTemplate = isTemplate ? true : false;
      if (isTemplate) {
        subtask.Template = duplicateObj;
      } else {
        subtask.TaskCopyOf = oldTaskId1;
      }
      let formData = new FormData();
      formData.append("data", JSON.stringify(subtask));
      HttpService.CreateUpdate("addtask", formData, {})
        .then(async (res) => {
          let obj = subtaskRelation;
          delete obj._id;
          obj.ParentTaskId = newTaskId;
          obj.TaskId = res.data.Data._id;
          obj.TaskName = res.data.Data.TaskName;
          addDuplicateTaskComm_InputFields_LookUpFields(
            oldTaskId1,
            res.data.Data._id,
            res.data.Data.TaskName
          );
          await addActivity(
            "ADD",
            "TASK",
            res.data.Data._id,
            res.data.Data.TaskName,
            obj.TaskType
          );
          return HttpService.CreateUpdate("addTaskRelation", obj);
        })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        });
    }
  };

  const isTaskAlreadyExist = (taskName) => {
    if (
      props.taskDocs.filter(
        (one) =>
          one.TaskName.toLowerCase() == taskName.toLowerCase() &&
          !one.isTemplate
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  const isTemplateAlreadyExist = (taskName) => {
    if (
      props.taskDocs.filter(
        (one) =>
          one.TaskName.toLowerCase() == taskName.toLowerCase() && one.isTemplate
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  const getDropDownForEditAction = (dependency) => {
    if (
      !dependencyObj &&
      !(dependencyObj.id == dependency.id) &&
      !dependencyEditFlag
    ) {
      return <></>;
    }
    return (
      <UncontrolledPopover
        placement="bottom"
        isOpen={dependencyEditDrop}
        target="dependencypopoveredit"
        //  toggle={dependencyEditToggle}
        trigger="legacy"
        className="dependency-popver"
      >
        <PopoverHeader>ADD LINK</PopoverHeader>
        <PopoverBody>
          {dependencyType.map((type) => (
            <>
              <UncontrolledDropdown
              // isOpen={dependencyDrop}
              // toggle={dependencyToggle}
              // className="d-inline-block"
              >
                <DropdownToggle
                  caret
                  // className="category-dropBtn"
                >
                  <Row id={type}>
                    <span>{type}</span>
                  </Row>
                </DropdownToggle>
                <DropdownMenu>
                  {DependencyDropdownData.length > 0 ? (
                    DependencyDropdownData.map((t, tIndx) =>
                      (taskData ? taskData.DependentOn : []).find(
                        (droptItem) => droptItem.id == t.id
                      ) == undefined ? (
                        <DropdownItem
                          value={t.id}
                          onClick={(e) => {
                            let dependencyData = { ...taskData };
                            dependencyToggle();
                            setDependencyType(type);
                            let dependency =
                              (taskData ? taskData.DependentOn : []) != null
                                ? [...taskData.DependentOn]
                                : [];
                            if (
                              dependency.find(
                                (depen) => depen.id == e.target.value
                              ) == undefined
                            ) {
                              if (dependencyEditFlag && dependencyObj) {
                                let prevState = {};
                                dependency.forEach((one, i) => {
                                  if (one.id == dependencyObj.id) {
                                    prevState = dependency[i];
                                    dependency[i] = {
                                      id: e.target.value,
                                      type: type,
                                      TaskName: t.name,
                                    };
                                  }
                                });
                                handleRemoveDependency(dependencyObj);
                                setDependencyEditFlag(false);
                                addActivity(
                                  "Update",
                                  "Dependency",
                                  dependencyData._id,
                                  dependencyData.TaskName,
                                  0,
                                  {
                                    PrevState: prevState,
                                    UpdatedState: {
                                      id: e.target.value,
                                      type: type,
                                      TaskName: t.name,
                                    },
                                  }
                                );
                                // let filterTask=props.taskDocs.filter(one=>one._id==dependencyObj.id)
                                // if(filterTask.length>0){
                                //   filterTask=filterTask[0].DependentOn.filter(d=>d.id==dependencyData._id)
                                // }
                                // addActivity('UPDATE','Dependency',e.target.value,t.name,0,{PrevState:filterTask[0],UpdatedState:{
                                //   id: dependencyData._id,
                                //   type: (type=='Waiting-On'?'Blocking':'Waiting-On'),
                                //   TaskName: dependencyData.TaskName,
                                // }})
                              } else {
                                dependency.push({
                                  id: e.target.value,
                                  type: type,
                                  TaskName: t.name,
                                });
                              }
                              //setTaskDependency(dependency);
                              handleOnChangeTask({
                                target: {
                                  name: "DependentOn",
                                  value: dependency,
                                },
                              });
                              dependencyData.DependentOn = dependency;
                            }
                          }}
                        >
                          {t.name}
                        </DropdownItem>
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          ))}
        </PopoverBody>
      </UncontrolledPopover>
    );
  };

  const handleCreateDuplicateTask = (isTemplate) => {
    // const copyObj = { ...handleGetDuplicateTaskObj() };
    const duplicateObj = { ...duplicateValueObj };
    let obj = { UDF_MULTI1: "", UDF_BOOLEAN1: "", UDF_CHAR4: "" };
    const serviceReqVar = [...serviceReq];
    let services = [];
    serviceReqVar.forEach((one) => {
      let keys = Object.keys(one);
      if (one[keys[0]] == "Yes") {
        services.push(keys[0]);
      }
    });

    if (isTemplate) {
      let keys = Object.keys(duplicateObj);
      let multiSelect = "";
      keys.forEach((key) => {
        if (duplicateObj[key]) {
          if (key == "TaskName") {
            obj.UDF_CHAR4 = duplicateObj[key];
          } else {
            if (multiSelect) {
              if (valuesLableTemplate[key]) {
                multiSelect = multiSelect + "," + valuesLableTemplate[key];
              }
            } else {
              if (valuesLableTemplate[key]) {
                multiSelect = valuesLableTemplate[key];
              }
            }
          }
        }
      });
      obj.UDF_BOOLEAN1 = "true";
      obj.UDF_MULTI1 = multiSelect;
      updateTask(taskData.id_string, "custom_fields", JSON.stringify(obj));
      setDuplicatePopUp(false);
      setTemplatePopUp(false);
      setUseTemplatePopUp(false);
      setBrowseTemplatePopup(false);
    } else {
      let obj = { ...taskData };
      let data = {};
      data.person_responsible = "";
      data.name = duplicateObj.TaskName;
      data.priority = taskData.priority;
      // data.status=taskData.status
      taskData.details.owners.forEach((one) => {
        data.person_responsible = data.person_responsible + one.id + ",";
      });
      TaskService.CreateTask(taskData.project_id, data)
        .then((result) => {
          if (result) {
            setTaskData(result);
            setDuplicatePopUp(false);
            setTemplatePopUp(false);
            setUseTemplatePopUp(false);
            setBrowseTemplatePopup(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getLabel = (value) => {
    if (value && value.id == "milestone") {
      if (taskData.custom_fields) {
        let milestone = taskData.custom_fields.filter(
          (one) => one.label_name == "IsMilestone"
        );
        if (milestone && milestone.length > 0 && milestone[0].value) {
          return value.label2;
        } else {
          return value.label1;
        }
      } else {
        return value.label1;
      }
    }
    if (value && value.id == "duplicate") {
      return value.label1;
    }
    if (value && value.id == "template") {
      return value.label1;
    }
    if (value && value.id == "copylink") {
      return value.label1;
    }
    if (value && value.id == "logCall") {
      return value.label1;
    }
    if (value && value.id == "logEmail") {
      return value.label1;
    }
    if (value && value.id == "logMeeting") {
      return value.label1;
    }
    if (value && value.id == "logEvent") {
      return value.label1;
    }
    if (value && value.id == "reminder") {
      return value.label1;
    }
    if (value && value.id == "relative") {
      return value.label1;
    }
  };
  const [priorityTooltipOpen, setPriorityTooltipOpen] = useState(false);

  const priorityToggle = () => {
    setPriorityTooltipOpen(!priorityTooltipOpen);
  };

  const handleRemoveDependency = async (dependency) => {
    if (dependency.Data_For == "Successor") {
      let list = [...props.dependencyList];
      list = list.filter(
        (one) => one.Current_Task_ID == dependency.Related_Task_ID
      );
      list.push(dependency);
      let ids = list.map((depe) => depe.id).join(",");
      ModuleService.DeleteRecords("Task_Dependency", ids)
        .then((result) => {
          props.getTaskDependencyData();
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ModuleService.DeleteRecords("Task_Dependency", dependency.id)
        .then((result) => {
          props.getTaskDependencyData();
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleReplacePipelineForThisTaskGroup = (taskGroupId, Pipeline) => {
    HttpService.CreateUpdate("updatePipelineForGrpID", {
      GrpID: taskGroupId,
      Pipeline: Pipeline,
      UserId: localStorage.getItem("UserId"),
      UserName: localStorage.getItem("UserName"),
    })
      .then((result) => {
        if (result.data.Ack == 1) {
          let taskObj = { ...taskData };
          taskObj["Pipeline"] = Pipeline;
          setTaskData(taskObj);
          setSelectedPipelineString("");
          setPipelineWarning(false);
          setMilestoneTaskForGrpId(null);
          setMilestoneTaskForPipline(null);
          //props.getallTasks()
        }
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };

  const handleReplaceCategoryForThisTaskGroup = (taskGroupId, Category) => {
    HttpService.CreateUpdate("updateCategoryForGrpID", {
      GrpID: taskGroupId,
      Category: Category,
      UserId: localStorage.getItem("UserId"),
      UserName: localStorage.getItem("UserName"),
    })
      .then((result) => {
        if (result.data.Ack == 1) {
          let taskObj = { ...taskData };
          taskObj["Category"] = Category;
          setTaskData(taskObj);
          setSelectedPipelineString("");
          setPipelineWarning(false);
          setMilestoneTaskForGrpId(null);
          setMilestoneTaskForPipline(null);
          //props.getallTasks()
        }
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };

  const [selectedPiplineString, setSelectedPipelineString] = useState("");
  const [taskPipelineWarning, setPipelineWarning] = useState(false);
  const [replaceStausWarnPopUp, setReplaceStatusWarnPopUp] = useState(false);
  const setReplaceStatusWarnPopUpToogle = () =>
    setReplaceStatusWarnPopUp(!replaceStausWarnPopUp);
  const replaceStatusModalAlert = () => {
    return (
      <Modal
        isOpen={replaceStausWarnPopUp}
        backdrop={backdrop}
        size="md"
        toggle={setReplaceStatusWarnPopUpToogle}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              {/* <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" /> */}
              <h5 className="my-2">Replace Milestone Status</h5>
              <p className="taskComplete-warning-subtitle">
                you want replace the status of this milestone
              </p>
            </Col>
          </Row>
          <Row>
            {/* <Col sm={12}>
            <div className='form-group'>
              <label>Statuses associated with</label>
              <select name='Category' onChange={handleOnChangeAskMilestone} className='form-control form-control-sm'>
                <option>--Select Category--</option>
                <option>Design</option>
                <option>Filing</option>
                <option>Miscellaneous</option>
              </select>
            </div>
          </Col> */}
            <Col sm={12}>
              <div className="form-group">
                <label>Status description</label>
                <select
                  name="Status"
                  onChange={handleOnChangeAskMilestone}
                  className="form-control form-control-sm"
                >
                  <option>--Select description--</option>
                  {descriptionList.map((obj) => {
                    return <option>{obj.PipelineStatus}</option>;
                  })}
                </select>
                {/* <input placeholder='description...' onChange={handleOnChangeAskMilestone} name='Status' type='text' className='form-control form-control-sm' /> */}
              </div>
            </Col>
            {/* <Col sm={ 4}>
            <button onClick={()=>setAddDescPopup(true)} className="btn btn-sm btn-primary mt-4">Add</button>
          </Col> */}
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-success w-100"
                onClick={() => {
                  handleReplaceMilestoneStatus();
                  handleReplacePipelineForThisTaskGroup(
                    props.task.GrpID,
                    selectedPiplineString
                  );
                  setReplaceStatusWarnPopUp(false);
                }}
              >
                Replace{" "}
              </button>
              <button
                onClick={() => setReplaceStatusWarnPopUp(false)}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };
  const setPipelineAlertToogle = () => setPipelineWarning(!taskPipelineWarning);

  // const taskPipelineAlertModal = () => {
  //   return <Modal
  //     isOpen={taskPipelineWarning}
  //     backdrop={backdrop}
  //     size='md'
  //     toggle={setPipelineAlertToogle}
  //     className="taskComplete-warning"
  //   >

  //     <ModalBody>
  //       <Row >
  //         <Col sm={12}>
  //           <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
  //           <h5 className="my-2">Pipeline Replace Warning</h5>
  //           <p className="taskComplete-warning-subtitle">would you like to change the pipeline to the entire task group?</p>
  //         </Col>

  //       </Row>
  //       {/* <Row >
  //         <Col sm={12}>
  //           <p>Task Name</p>
  //           <ul className="textField-list">
  //             {milestoneTaskForGrpId && milestoneTaskForGrpId.map((one) => {
  //               return <>
  //                 <li>{one.TaskName}

  //                   <div className="form-group form-group-sm">
  //                     <select name='Status' onChange={(e)=>handleOnChangeStatusMultiple(e,one)} className='form-control form-control-sm'>
  //                       <option>--Select description--</option>
  //                       {descriptionList.map((obj) => {
  //                         return <option>{obj.PipelineStatus}</option>
  //                       })}
  //                     </select>
  //                   </div>
  //                 </li>

  //               </>
  //             })}
  //           </ul>
  //           <hr />

  //         </Col>

  //       </Row> */}
  //       <Row >
  //         <Col sm={12}>
  //           <button
  //             className='btn btn-success w-100'

  //             onClick={async () => {
  //               //  await getAllDescription({ Pipeline: selectedPiplineString })
  //               setPipelineReplaceForTaskPopUp(true)
  //               // handleReplacePipelineForThisTaskGroup(props.task.GrpID, selectedPiplineString);
  //             }}
  //           // onClick={async () => {
  //           //   if (!taskData.isMilestone) {
  //           //     handleReplacePipelineForThisTaskGroup(props.task.GrpID, selectedPiplineString);
  //           //   } else {
  //           //     await getAllDescription({ Pipeline: selectedPiplineString })
  //           //     setReplaceStatusWarnPopUp(true);
  //           //   }

  //           // }}
  //           >Yes</button>
  //           <button onClick={() => setPipelineWarning(false)} className='btn btn-secondary w-100 mt-2'>No</button>
  //         </Col>

  //       </Row>
  //     </ModalBody>
  //   </Modal>
  // }

  const [pipelineReplaceForTaskPopUp, setPipelineReplaceForTaskPopUp] =
    useState(false);
  const pipelineReplaceForTaskAlertToogle = () =>
    setPipelineReplaceForTaskPopUp(!pipelineReplaceForTaskPopUp);
  // const pipelineReplaceForTasktModal = () => {
  //   return <Modal
  //     isOpen={pipelineReplaceForTaskPopUp}
  //     backdrop={backdrop}
  //     size='md'
  //     toggle={pipelineReplaceForTaskAlertToogle}
  //     className="taskComplete-warning"
  //   >

  //     <ModalBody>
  //       <Row >
  //         <Col sm={12}>
  //           <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
  //           <h5 className="my-2">Milestone Status Replace</h5>
  //           <p className="taskComplete-warning-subtitle">you want change the status of the milestone for this task</p>
  //         </Col>

  //       </Row>
  //       <Row >
  //         <Col sm={12}>
  //           <p>Task Name</p>
  //           <ul className="textField-list">
  //             {milestoneTaskForGrpId && milestoneTaskForGrpId.map((one) => {
  //               return <>
  //                 <li>{one.TaskName}

  //                   <div className="form-group form-group-sm">
  //                     <select name='Status' onChange={(e) => handleOnChangeStatusMultiple(e, one)} className='form-control form-control-sm'>
  //                       <option value={'None'}>--Select description--</option>
  //                       {descriptionList.map((obj) => {
  //                         return <option value={obj.PipelineStatus}>{obj.PipelineStatus}</option>
  //                       })}
  //                     </select>
  //                   </div>
  //                 </li>

  //               </>
  //             })}
  //           </ul>
  //           <hr />

  //         </Col>

  //       </Row>
  //       <Row >
  //         <Col sm={12}>
  //           <button
  //             className='btn btn-success w-100'

  //             onClick={async () => {
  //               handleReplaceMilestoneForTask(selectedPiplineString);

  //             }}
  //           >Replace</button>
  //           <button onClick={() => setPipelineReplaceForTaskPopUp(false)} className='btn btn-secondary w-100 mt-2'>Cancel</button>
  //         </Col>

  //       </Row>
  //     </ModalBody>
  //   </Modal>
  // }

  const handleOnChangeTask = (e) => {
    const data = { ...taskData };
    data[e.currentTarget.name] = e.currentTarget.value;
    setTaskData(data);
  };

  // const handleOnChangeTask = async (e) => {
  //   let taskObj = { ...taskData };
  //   if (e.target.name == 'Merge') {
  //     taskObj = { ...taskObj, ...e.target.value };
  //   }
  //   else if (e.target.name == 'Pipeline') {
  //     if (taskObj.Pipeline) {
  //       if (await checkMilestoneForGrpId(taskObj.GrpID)) {

  //         await getAllDescription({ Pipeline: e.target.value });
  //         setPipelineWarning(true);
  //         setSelectedPipelineString(e.target.value)
  //       } else {
  //         if (taskObj.Pipeline == 'NO PIPELINE') {
  //           if (props.task) {
  //             handleReplacePipelineForThisTaskGroup(props.task.GrpID, e.target.value);
  //           }

  //         }
  //         taskObj[e.target.name] = e.target.value;
  //       }
  //     } else {
  //       taskObj[e.target.name] = e.target.value;
  //       if (props.taskType == 'subtask') {
  //         if (!props.task.Pipeline) {
  //           if (props.task.GrpID) {
  //             handleReplacePipelineForThisTaskGroup(props.task.GrpID, e.target.value);
  //           }

  //           //props.updateTaskWhenPipelineAdd(props.taskType, e.target.value)
  //         }
  //       }
  //       if (props.taskType == 'metatask') {
  //         if (!props.subtask.Pipeline) {
  //           if (props.subtask.GrpID) {
  //             handleReplacePipelineForThisTaskGroup(props.subtask.GrpID, e.target.value);
  //           }
  //           // props.updateTaskWhenPipelineAdd(props.taskType, e.target.value)
  //         }
  //       }
  //     }

  //   }
  //   // else if ((e.target.name == 'Category') && props.task && (props.task.GrpID)) {
  //   //   handleReplaceCategoryForThisTaskGroup(props.task.GrpID, e.target.value);
  //   // }
  //   else {
  //     if (e.target.name == 'Category') {
  //       if (e.target.value == '') {
  //         taskObj.Superviser = []
  //       } else {
  //         let superViser = getSupervisor(e.target.value);
  //         //  setAllSuperviser(superViser)
  //         taskObj.Superviser = superViser;
  //       }
  //     }
  //     taskObj[e.target.name] = e.target.value;

  //   }

  //   // if ((e.target.name == 'Assignee') && (taskObj.Status.name == 'PENDING') && (e.target.value.length > 0)) {
  //   //   taskObj.Status = { name: "NOT STARTED", color: "#f8d800" }
  //   // }
  //   setTaskData(taskObj);
  // }

  useEffect(() => {
    // getAllPipelines();
  }, []);

  const [pipelineNames, setPipelineNames] = useState([]);

  const getAllPipelines = async () => {
    let pipelines = [];
    let returnData = await HttpService.getByBoj("getAllPipeline", {}).then(
      (res) => {
        if (res.data.length > 0) {
          res.data.map((one, i) => {
            pipelines.push({
              name: one.PipelineName,
              _id: one._id,
              category: one.Category,
            });
          });
          setPipelineNames(pipelines);
        }
      }
    );
  };

  const getTimeTrackPopUp = () => {
    if (false) {
      return <></>;
    } else {
      return (
        <UncontrolledPopover
          className="time_tracked_popover"
          isOpen={timetrackedPopover}
          toggle={timetrackedPopover}
          trigger="click"
          placement="bottom"
          target={"timeTracked" + taskData.id_string}
        >
          <PopoverBody>
            <div id="timetracklist">
              <div>
                <img src={localStorage.getItem("ProfilePhoto")} />
                {localStorage.getItem("UserName")}
              </div>
              {props.timeTrackFlag &&
              props.timerObj &&
              props.timerObj.jobId == taskData.JobId
                ? `${props.timerTimeTrack}`.toHHMMSS()
                : `${timer}`.toHHMMSS()}
            </div>
            <UncontrolledCollapse toggler="timetracklist">
              <div className="time_track_scroll">
                {timeTrackLogs &&
                  timeTrackLogs.map((e, i) => {
                    return (
                      <>
                        <Row className="no-gutters user-time-track">
                          <Col>
                            <ul className="list-unstyled">
                              <li>
                                {e.timerLog ? (
                                  <FontAwesomeIcon icon={faPlayCircle} />
                                ) : (
                                  <>
                                    <FontAwesomeIcon icon={faSlidersH} />
                                  </>
                                )}
                              </li>
                              <li>{e.hours}</li>
                              <li>{getDateMonthOnly(new Date(e.workDate))}</li>
                            </ul>
                          </Col>
                          <Col className="text-right">
                            <ul className="list-unstyled">
                              {!e.timerLog ? (
                                <li>
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    id={"edit" + e.timelogId}
                                    className="edit"
                                    onClick={() => {
                                      setFromRange(SetTime(e.StartTime));
                                      setToRange(SetTime(e.StopTime));
                                    }}
                                  />
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    target={"edit" + e.timelogId}
                                    placement="bottom"
                                    className="time-log-popover-outer"
                                  >
                                    <PopoverBody className="time-log-popover">
                                      <div className="text-center">
                                        <TimePicker
                                          format="hh:mm a"
                                          clearIcon={null}
                                          disableClock={true}
                                          onChange={(e) => {
                                            setFromRange(e);
                                          }}
                                          value={FromRange}
                                        />
                                        <TimePicker
                                          format="hh:mm a"
                                          clearIcon={null}
                                          disableClock={true}
                                          onChange={setToRange}
                                          value={ToRange}
                                        />
                                      </div>
                                      <div className="text-center">
                                        <Button
                                          className="btn btn-primary btn-small ml-2"
                                          onClick={() => {}}
                                        >
                                          Save
                                        </Button>
                                        <Button
                                          className="btn btn-secondary btn-small"
                                          onClick={() => {
                                            setStartTime("");
                                            setStopTime("");
                                            settimetrackedPopover(false);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </div>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </li>
                              ) : (
                                <></>
                              )}

                              <li id={"View" + e.timelogId}>
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="view"
                                />
                              </li>

                              <li>
                                <FontAwesomeIcon
                                  id={"Del" + e.timelogId}
                                  icon={faTrashAlt}
                                  className="delete"
                                  onClick={() => {
                                    TaskService.DeleteTimeTrack(e.timelogId)
                                      .then((result) => {
                                        getAllTimeTrackLogs(taskData.JobId);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }}
                                />
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        <UncontrolledCollapse toggler={"View" + e.timelogId}>
                          {e.timerLog ? (
                            <>
                              {e.timearr &&
                                e.timearr.map((one) => {
                                  return (
                                    <div className="time-tracked-duration">
                                      <p>
                                        <span>From :</span>{" "}
                                        {one.fromTimeInTimeFormat}
                                      </p>
                                      <p>
                                        <span>To :</span>{" "}
                                        {one.toTimeInTimeFormat}
                                      </p>
                                    </div>
                                  );
                                })}
                            </>
                          ) : (
                            <>
                              <div className="time-tracked-duration">
                                <p>
                                  <span>From :</span> {e.fromTimeInTimeFormat}
                                </p>
                                <p>
                                  <span>To :</span> {e.toTimeInTimeFormat}
                                </p>
                              </div>
                            </>
                          )}
                        </UncontrolledCollapse>
                      </>
                    );
                  })}
              </div>
            </UncontrolledCollapse>
            <div className="p-2 border-top">
              <Tabs
                className="mb-2 time_tracked_tabs"
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                //className="mb-3"
              >
                <Tab
                  eventKey="timer"
                  title="Timer" ///StopWatchTimerDisplay
                >
                  <Row style={{ marginTop: "20px" }}>
                    <Col sm={9}>
                      {/* <div>{localStorage.getItem("UserName")}:    {formatTime(TotalTimeTracked)}  </div> */}
                      <div className="d-flex align-items-center">
                        {/* <Input placeholder="time" onChange={(e)=>setTimer(parseInt(e.target.value,10))}/> */}
                        <FontAwesomeIcon
                          className={
                            props.timeTrackFlag &&
                            props.timerObj &&
                            props.timerObj.jobId == taskData.JobId
                              ? "mr-2 timeTrackedIconPause"
                              : "mr-2 timeTrackedIconPlay"
                          }
                          icon={
                            props.timeTrackFlag &&
                            props.timerObj &&
                            props.timerObj.jobId == taskData.JobId
                              ? faStopCircle
                              : faPlayCircle
                          }
                          onClick={async () => {
                            if (
                              props.startstopClockTimeTrack &&
                              props.timeTrackFlag
                            ) {
                              await props.startstopClockTimeTrack(
                                props.timerObj
                              );
                            } else {
                              props.setTimerPrevTimeTrack(timer);
                              props.setTimerTimeTrack(timer);
                              props.startstopClockTimeTrack({
                                jobId: taskData.JobId,
                                jobName: taskData.name,
                              });
                            }
                            // startstopClock("")
                          }}
                        />
                        <div>
                          {props.timeTrackFlag &&
                          props.timerObj &&
                          props.timerObj.jobId == taskData.JobId
                            ? `${props.timerTimeTrack}`.toHHMMSS()
                            : `${timer}`.toHHMMSS()}
                        </div>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <span className="desc_icon">
                        <FontAwesomeIcon icon={faAlignLeft} id="description" />
                      </span>
                    </Col>
                  </Row>

                  <UncontrolledCollapse toggler="description">
                    <textarea
                      class="form-control taskmodal-description-area"
                      rows="5"
                      placeholder="Type Description below"
                    ></textarea>
                  </UncontrolledCollapse>
                </Tab>
                <Tab eventKey="range" title="Range">
                  <div className="time_tracked_fields mb-2">
                    <input
                      onChange={(e) => setTimeLogDate(e.currentTarget.value)}
                      value={timeLogDate ? timeLogDate : ""}
                      type="date"
                      className="form-control form-control-sm"
                    />
                  </div>
                  <div className="time_tracked_fields mb-2">
                    <TimePicker
                      format="hh:mm a"
                      clearIcon={null}
                      disableClock={true}
                      onChange={(e) => {
                        setFromRange(e);
                      }}
                      value={FromRange}
                    />
                    -
                    <TimePicker
                      format="hh:mm a"
                      clearIcon={null}
                      disableClock={true}
                      onChange={(e) => {
                        setToRange(e);
                      }}
                      value={ToRange}
                    />
                  </div>
                  <Row>
                    <Col sm={7}>
                      <p style={{ marginLeft: "14px" }}>When: now</p>
                    </Col>
                    <Col sm={5}>
                      <span className="desc_icon">
                        <FontAwesomeIcon
                          icon={faAlignLeft}
                          id="RangeTimerdescription"
                        />
                      </span>
                    </Col>
                  </Row>
                  <UncontrolledCollapse toggler="RangeTimerdescription">
                    <textarea
                      class="form-control taskmodal-description-area"
                      rows="5"
                      placeholder="Type Description below"
                    ></textarea>
                  </UncontrolledCollapse>
                </Tab>
              </Tabs>
              <div className="text-right mt-2">
                <button
                  className="btn btn-secondary btn-small"
                  onClick={() => {
                    setStartTime("");
                    setStopTime("");
                    settimetrackedPopover(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary btn-small ml-2"
                  onClick={() => {
                    if (FromRange && ToRange && timeLogDate) {
                      onSaveTimeRange(FromRange, ToRange, timeLogDate);
                    } else {
                      swal("Please Set Valid Time", { dangerMode: true });
                    }
                    // setToRange(() => {
                    //   let hrs = new Date().getHours();
                    //   hrs = hrs < 10 ? "0" + hrs : hrs;
                    //   let mins = new Date().getMinutes();
                    //   mins = mins < 10 ? "0" + mins : mins;
                    //   return hrs + ":" + mins;
                    // });
                    // setFromRange(() => {
                    //   let hrs = new Date().getHours();
                    //   hrs = hrs < 10 ? "0" + hrs : hrs;
                    //   let mins = new Date().getMinutes();
                    //   mins = mins < 10 ? "0" + mins : mins;
                    //   return hrs + ":" + mins;
                    // });
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      );
    }
  };

  const convertEventDateTime = (dateTime) => {
    if (dateTime) {
      // dateTime=dateTime.split('-')
      // dateTime=dateTime.slice(0,dateTime.length-1);
      // dateTime=dateTime.join('')+'Z'
      dateTime = dayjs(dateTime);
      return dateTime.$d;
    }
  };

  const showReminderTitle = (title) => {
    const type = ["email", "popup", "notification"];
    title = title.replaceAll(`#$taskid:${taskData.id_string}$#`, "");
    type.forEach((notifyType) => {
      title = title.replaceAll(`#$type:${notifyType}$#`, "");
    });
    return title;
  };

  const getReminderPopUp = () => {
    return (
      <UncontrolledPopover
        className="reminder_popup time_tracked_popover"
        // isOpen={timetrackedPopover}
        // toggle={timetrackedPopover}
        trigger="hover"
        placement="bottom"
        target={"ReminderPopUp" + taskData.id_string}
      >
        <PopoverBody>
          <div className="time_track_scroll">
            {reminders &&
              reminders.map((e, i) => {
                let title = showReminderTitle(e.title);
                return (
                  <>
                    <Row
                      style={
                        i == 0
                          ? { borderTop: "0px solid #ccc" }
                          : { borderTop: "1px solid #ccc" }
                      }
                      className="no-gutters user-time-track"
                    >
                      <Col>
                        <ul className="list-unstyled d-flex">
                          <li>
                            <img
                              className="reminder-icon-cust"
                              style={{
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#ffc107",
                              }}
                              src={remIcoCutom}
                            />

                            {/* <FontAwesomeIcon
                            color="#ffc107"
                            icon={faBell}

                          /> */}
                          </li>
                          {/* <UncontrolledTooltip target={"title"+e.calid}>
                        {title}
                        </UncontrolledTooltip> */}
                          <li
                            id={"title" + e.calid}
                            className="text-truncate"
                            style={{ width: "105px" }}
                          >
                            {title}
                          </li>
                          <li>
                            {getDate(convertEventDateTime(e.dateandtime.start))}
                          </li>
                          <li>
                            {getTime(convertEventDateTime(e.dateandtime.start))}
                          </li>
                          <li>
                            {getTime(convertEventDateTime(e.dateandtime.end))}
                          </li>
                          {/* <li>
                          {getDateMonthOnly(new Date(convertEventDateTime(e.dateandtime.start)))}
                        </li> */}
                        </ul>
                      </Col>
                      {/* <Col className="text-right">
                      <ul className="list-unstyled">
                        {!e.timerLog ? (
                          <li>
                            <FontAwesomeIcon
                              icon={faPen}
                              id={"edit" + e.timelogId}
                              className="edit"
                              onClick={() => {
                                setFromRange(SetTime(e.StartTime));
                                setToRange(SetTime(e.StopTime));
                              }}
                            />
                            <UncontrolledPopover
                              trigger="legacy"
                              target={"edit" + e.timelogId}
                              placement="bottom"
                              className="time-log-popover-outer"
                            >
                              <PopoverBody className="time-log-popover">
                                <div className="text-center">
                                  <TimePicker
                                    format="hh:mm a"
                                    clearIcon={null}
                                    disableClock={true}
                                    onChange={(e) => {
                                      setFromRange(e)
                                    }

                                    }
                                    value={FromRange}
                                  />
                                  <TimePicker
                                    format="hh:mm a"
                                    clearIcon={null}
                                    disableClock={true}
                                    onChange={setToRange}
                                    value={ToRange}
                                  />
                                </div>
                                <div className="text-center">
                                  <Button
                                    className="btn btn-primary btn-small ml-2"
                                    onClick={() => {


                                    }}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    className="btn btn-secondary btn-small"
                                    onClick={() => {
                                      setStartTime("");
                                      setStopTime("");
                                      settimetrackedPopover(
                                        false
                                      );
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </PopoverBody>
                            </UncontrolledPopover>
                          </li>
                        ) : (
                          <></>
                        )}

                        <li id={"View" + e.timelogId}>
                          <FontAwesomeIcon
                            icon={faEye}
                            className="view"
                          />
                        </li>

                        <li>
                          <FontAwesomeIcon
                            id={"Del" + e.timelogId}
                            icon={faTrashAlt}
                            className="delete"
                            onClick={() => {
                              TaskService.DeleteTimeTrack(e.timelogId)
                                .then((result) => {
                                  getAllTimeTrackLogs(taskData.JobId);
                                })
                                .catch((err) => {
                                  console.log(err);
                                })

                            }}
                          />
                        </li>
                      </ul>
                    </Col> */}
                    </Row>
                    {/* <UncontrolledCollapse
                    toggler={"View" + e.timelogId}
                  >
                    {e.timerLog ? (
                      <>
                        {e.timearr && e.timearr.map((one) => {
                          return <div className="time-tracked-duration">
                            <p>
                              <span>From :</span>{" "}
                              {one.fromTimeInTimeFormat}
                            </p>
                            <p>
                              <span>To :</span>{" "}
                              {one.toTimeInTimeFormat}
                            </p>
                          </div>
                        })
                        }
                      </>
                    ) : (
                      <>
                        <div className="time-tracked-duration">
                          <p>
                            <span>From :</span>{" "}
                            {e.fromTimeInTimeFormat}
                          </p>
                          <p>
                            <span>To :</span>{" "}
                            {e.toTimeInTimeFormat}
                          </p>
                        </div>

                      </>
                    )}
                  </UncontrolledCollapse> */}
                  </>
                );
              })}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    );
  };

  const getSupervisor = (category, fetchType) => {
    const users = [...allUsers];
    let data = { ...taskData };
    let clientid = props.dealObj.ClientId;
    let filterUser = users.filter((one) => {
      if (
        one.Category.length > 0 &&
        one.Category[0].label == category &&
        one.ClientUnderSupervision.filter((client) => client.id == clientid)
          .length > 0
      ) {
        return one;
      }
    });
    if (fetchType !== "full") {
      filterUser.forEach((one, index, self) => {
        self[index] = {
          userId: one.UserId,
          userName: one.FirstName + " " + one.LastName,
        };
      });
    }

    return filterUser;
  };

  const handleRemoveFile = (file) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete file " + file.FILENAME,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        TaskService.RemoveAttachement(
          taskData.project_id,
          taskData.id_string,
          file.RESOURCE_ID
        )
          .then((result) => {
            let files = [...taskAttachements];
            files = files.filter((one) => one.RESOURCE_ID !== file.RESOURCE_ID);
            setTaskAttachements(files);
            console.log(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const getDependencyRow = (depe, relatedTask) => {
    if (depe.Data_For == "Dependency") {
      return (
        <li className="dependency-list">
          <span
            className="dependency-status-icon"
            style={
              relatedTask.status
                ? { backgroundColor: relatedTask.status.color_code }
                : {}
            }
          ></span>
          <FontAwesomeIcon
            icon={faMinusCircle}
            color={depe.Type == "Blocking" ? "#f40600" : "#FFA500"}
          />
          {/* <span style={{
  border:1,
  borderRadius:10,
  padding: 1,
}}>-</span> */}{" "}
          <span
            style={{
              fontSize: 10,
              color: depe.Type == "Blocking" ? "#f40600" : "#FFA500",
            }}
          >
            {depe.Type}
          </span>
          <span
            onClick={() => {
              const arr = [...openTaskArray];
              arr.push(relatedTask);
              setOpenTaskArr(arr);
              setTaskData(relatedTask);
              history.push({
                search: `task=${relatedTask.id_string}`,
              });
            }}
            style={{
              fontSize: 13,
              textTransform: "uppercase",
              marginLeft: 10,
              cursor: "pointer",
            }}
          >
            {depe.Related_Task_Name}
          </span>
          <FontAwesomeIcon
            onClick={() => {
              setDeleteDependencyObj(depe);
              setDependencyRemove(true);
            }}
            className="delete-dependency-icon"
            icon={faTrash}
          />
          {!dependencyEditFlag ? (
            <FontAwesomeIcon
              onClick={() => {
                let obj = {
                  id: depe.id,
                  DependentStatus: depe.Dependent_Task_Status,
                  deal: props.dealObj.id,
                  type: depe.Type,
                  pipeline: taskData.project_id,
                  Task: [
                    {
                      label: depe.Related_Task_Name,
                      value: depe.Related_Task_ID,
                    },
                  ],
                };
                let current_status = [];
                let related_status = [];
                depe.Current_Task_Status.forEach((status) => {
                  let filterStatus = taskLayout.status_details.filter(
                    (one) => one.name == status
                  );
                  if (filterStatus.length > 0) {
                    current_status.push({
                      label: filterStatus[0].name,
                      value: filterStatus[0].id,
                      color: filterStatus[0].color,
                    });
                  }
                });

                depe.Related_Task_Status.forEach((status) => {
                  let filterStatus = taskLayout.status_details.filter(
                    (one) => one.name == status
                  );
                  if (filterStatus.length > 0) {
                    related_status.push({
                      label: filterStatus[0].name,
                      value: filterStatus[0].id,
                      color: filterStatus[0].color,
                    });
                  }
                });
                obj.current_status = current_status;
                obj.related_status = related_status;
                setDependencyDrop(true);
                setDependencyEditFlag(true);
                setDependencyObj(obj);
                setDependencyEditDrop(false);
              }}
              id="dependencypopoveredit"
              className="edit-dependency-icon"
              icon={faEdit}
            />
          ) : (
            <></>
          )}
          <AntdPopover placement="top" content={getStatusContent(depe)}>
            <FontAwesomeIcon
              id="dependencypopoveredit"
              className="edit-dependency-icon"
              icon={faEye}
            />
          </AntdPopover>
          {dependencyObj && dependencyObj.id == depe.id && false ? (
            <>
              <span
                id="dependencypopoveredit"
                className="edit-dependency-icon-edit"
                // onClick={() => setDependencyEditDrop(true)}
              >
                Edit Dependency
                <FontAwesomeIcon
                  className="cross-dependency-icon"
                  onClick={() => {
                    // setDependencyEditFlag(false)
                  }}
                  icon={faTimes}
                />
              </span>
              {/* {getDropDownForEditAction(dependency)} */}
            </>
          ) : (
            <></>
          )}
        </li>
      );
    } else {
      let relatedTaskColor = taskLayout.status_details.filter(
        (one) => one.id == depe.Dependent_Task_Status
      );
      relatedTaskColor =
        relatedTaskColor.length > 0 ? relatedTaskColor[0].color : "";
      return (
        <li className="dependency-list">
          <span
            className="dependency-status-icon"
            style={
              relatedTask.status
                ? { backgroundColor: relatedTask.status.color_code }
                : {}
            }
          ></span>
          {/* {
          depe.Current_Task_Status.map((status) => {
            let color = taskLayout.status_details.filter((one) => one.name == status);
            color = color.length ? color[0].color : "gray"
            return <span
              className="successe-status"
              style={{ backgroundColor: color }}
          })
        } */}
          <FontAwesomeIcon
            style={{ marginTop: "4px" }}
            color={relatedTaskColor}
            icon={depe.Type == "Predecessor" ? faBackward : faForward}
          />
          <span
            style={{ fontSize: 12, color: relatedTaskColor, marginLeft: 5 }}
          >
            {depe.Type}
          </span>
          {/* <span
          className=" successe-status"
          style={{ backgroundColor: relatedTaskColor }}
        ></span> */}

          <span
            onClick={() => {
              const arr = [...openTaskArray];
              arr.push(relatedTask);
              setOpenTaskArr(arr);
              setTaskData(relatedTask);
              history.push({
                search: `task=${relatedTask.id_string}`,
              });
            }}
            style={{
              fontSize: 13,
              marginRight: "5px",
              textTransform: "uppercase",
              marginLeft: 10,
              cursor: "pointer",
            }}
          >
            {depe.Related_Task_Name}
          </span>

          <FontAwesomeIcon
            onClick={() => {
              setDeleteDependencyObj(depe);
              setDependencyRemove(true);
            }}
            className="delete-dependency-icon"
            icon={faTrash}
          />
          {!dependencyEditFlag ? (
            <FontAwesomeIcon
              onClick={() => {
                let obj = {
                  id: depe.id,
                  DependentStatus: depe.Dependent_Task_Status,
                  deal: props.dealObj.id,
                  type: depe.Type,
                  pipeline: taskData.project_id,
                  Task: [
                    {
                      label: depe.Related_Task_Name,
                      value: depe.Related_Task_ID,
                    },
                  ],
                };
                let current_status = [];
                let related_status = [];
                depe.Current_Task_Status.forEach((status) => {
                  let filterStatus = taskLayout.status_details.filter(
                    (one) => one.name == status
                  );
                  if (filterStatus.length > 0) {
                    current_status.push({
                      label: filterStatus[0].name,
                      value: filterStatus[0].id,
                      color: filterStatus[0].color,
                    });
                  }
                });

                depe.Related_Task_Status.forEach((status) => {
                  let filterStatus = taskLayout.status_details.filter(
                    (one) => one.name == status
                  );
                  if (filterStatus.length > 0) {
                    related_status.push({
                      label: filterStatus[0].name,
                      value: filterStatus[0].id,
                      color: filterStatus[0].color,
                    });
                  }
                });
                obj.current_status = current_status;
                obj.related_status = related_status;
                setDependencyDrop(true);
                setDependencyEditFlag(true);
                setDependencyObj(obj);
                setDependencyEditDrop(false);
              }}
              id="dependencypopoveredit"
              className="edit-dependency-icon"
              icon={faEdit}
            />
          ) : (
            <></>
          )}
          <AntdPopover placement="top" content={getStatusContent(depe)}>
            <FontAwesomeIcon
              id="dependencypopoveredit"
              className="edit-dependency-icon"
              icon={faEye}
            />
          </AntdPopover>

          {dependencyObj && dependencyObj.id == depe.id && false ? (
            <>
              <span
                id="dependencypopoveredit"
                className="edit-dependency-icon-edit"
                // onClick={() => setDependencyEditDrop(true)}
              >
                Edit Dependency
                <FontAwesomeIcon
                  className="cross-dependency-icon"
                  onClick={() => {
                    // setDependencyEditFlag(false)
                  }}
                  icon={faTimes}
                />
              </span>
              {/* {getDropDownForEditAction(dependency)} */}
            </>
          ) : (
            <></>
          )}
        </li>
      );
    }
  };

  const getTaskNameInput = () => {
    if (taskData.name) {
      return (
        <Input
          type="text"
          className="taskmodal-title-wrapper-input"
          value={taskData ? taskData.name : ""}
          name="name"
          id="taskNameId"
          onChange={(e) => {
            // let taskNameData = {...prepareTaskData()};
            //   setTaskName(e.target.value);
            handleOnChangeTask(e);
            //  taskNameData.TaskName = newTaskName;
            //  addUpdateTaskApi(taskNameData);
          }}
          placeholder="Task Name"
        />
      );
    } else {
      return (
        <Input
          type="text"
          className="taskmodal-title-wrapper-input"
          value={""}
          name="name"
          id="taskNameId"
          onChange={(e) => {
            // let taskNameData = {...prepareTaskData()};
            //   setTaskName(e.target.value);
            handleOnChangeTask(e);
            //  taskNameData.TaskName = newTaskName;
            //  addUpdateTaskApi(taskNameData);
          }}
          placeholder="Task Name"
        />
      );
    }
  };
  let backTaskArr = [];
  const recurseBackTrackTask = (task) => {
    let filterTask = props.taskDocs.filter(
      (one) => task.parenttask_id && one.id_string == task.parenttask_id
    );
    if (filterTask.length > 0) {
      backTaskArr.unshift(filterTask[0]);
      recurseBackTrackTask(filterTask[0]);
    } else {
      return backTaskArr;
    }
  };

  const showBackParentTaskInHeader = () => {
    backTaskArr = [];
    recurseBackTrackTask(taskData);
    let navigateArr = [...backTaskArr];
    if (navigateArr && navigateArr.length > 0) {
      navigateArr = navigateArr.map((one) => one.name);
      navigateArr = navigateArr.join(" > ");
      return (
        <>
          <AntdPopover trigger={"hover"} content={<div>{navigateArr}</div>}>
            <FontAwesomeIcon className="ml-3 mr-3" icon={faEllipsisH} />
          </AntdPopover>
          <FontAwesomeIcon icon={faAngleRight} />
        </>
      );
    } else {
      return <></>;
    }
  };

  const getTaskSupervisor = (task) => {
    let users = [];
    let obj = { ...task };
    let all = [...allUsers];
    let superViser = obj.custom_fields.filter(
      (one) => one.label_name == "Supervisor"
    );
    if (superViser.length > 0) {
      users = superViser[0].users;
      if (users.length == 0 && superViser[0].value) {
        let filteruser = all.filter((one) => superViser[0].value == one.zuid);
        if (filteruser.length > 0) {
          filteruser = filteruser[0];
          users.push({
            email: filteruser?.email_id,
            id: filteruser.zuid,
            name: filteruser.first_name + " " + filteruser.last_name,
          });
        }
      }
    }
    return users;
  };

  const renderModal = () => {
    return (
      <Modal
        className={props.style}
        isOpen={props.nestedModal}
        backdrop={backdrop}
      >
        <div className="taskdetail-modal-header">
          <div className="task-breadcrumbs">
            {props.taskAction == "Edit" ? (
              <FontAwesomeIcon
                onClick={() => {
                  const arr = [...openTaskArray];
                  if (arr.length > 1) {
                    let obj = arr[arr.length - 2];
                    arr.splice(arr.length - 1, 1);
                    setOpenTaskArr(arr);
                    setTaskData(obj);
                    history.push({
                      search: `task=${obj.id_string}`,
                    });
                  }
                }}
                style={
                  openTaskArray.length <= 1
                    ? { cursor: "default", color: "lightgray" }
                    : { cursor: "pointer" }
                }
                icon={faArrowLeft}
              />
            ) : (
              <></>
            )}

            <UncontrolledDropdown className="d-inline-block col pl-g-0">
              <DropdownToggle
                onClick={() => {
                  if (props.taskAction == "Edit") {
                    history.push({
                      pathname:
                        "/c/Accounts/DetailView/" +
                        props.dealObj.Account_Name.id,
                      state: { data: {}, action: "Update", module: "Accounts" },
                    });
                    props.handleCloseTaskModel(props.taskType, taskData);
                  }
                }}
                caret
                className="category-dropBtn category-toggle"
              >
                {props.dealObj && props.dealObj.Account_Name
                  ? props.dealObj.Account_Name.name
                  : "NA"}

                {/* {taskData && taskData.custom_fields && taskData.custom_fields.filter(one => one.label_name == 'Pipeline').length > 0 ? getGroupVal(taskData.custom_fields.filter(one => one.label_name == 'Pipeline')[0].value) : 'N/A'} */}
              </DropdownToggle>
              <DropdownMenu>
                {}
                {props.taskCreateLocation !== "Deals" &&
                  props.clients &&
                  props.clients.map((one) => (
                    <DropdownItem
                      value={one}
                      onClick={(e) => {
                        if (props.taskCreateLocation == "Tasks") {
                          props.handleChangeOnClient(one);
                        }

                        // const obj = { ...moveTaskObj };
                        // const data = { ...taskData };
                        // obj.project_id = one.id_string;
                        // data.project_name = one.name;
                        // setMoveTaskObj(obj)
                        // setTaskData(data);
                      }}
                    >
                      {one.Account_Name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <FontAwesomeIcon icon={faAngleRight} />
            <UncontrolledDropdown className="d-inline-block col pl-g-0">
              <DropdownToggle
                onClick={() => {
                  if (props.taskAction == "Edit") {
                    history.push({
                      pathname: "/d/Deals/DetailView/" + props.dealObj.id,
                      state: {
                        data: props.dealObj,
                        action: "DetailView",
                        module: "Deals",
                      },
                    });
                    props.handleCloseTaskModel(props.taskType, taskData);
                  }
                }}
                caret
                className="category-dropBtn category-toggle"
              >
                {props.dealObj ? props.dealObj.Deal_Name : "N/A"}

                {/* {taskData && taskData.custom_fields && taskData.custom_fields.filter(one => one.label_name == 'Pipeline').length > 0 ? getGroupVal(taskData.custom_fields.filter(one => one.label_name == 'Pipeline')[0].value) : 'N/A'} */}
              </DropdownToggle>
              <DropdownMenu>
                {}
                {props.taskCreateLocation !== "Deals" &&
                  props.dealObj.Account_Name &&
                  props.dealObj.Account_Name.name !== "Miscellaneous" &&
                  props.deals &&
                  props.deals.map((one) => (
                    <DropdownItem
                      value={one}
                      onClick={(e) => {
                        if (props.taskCreateLocation !== "Deals") {
                          let pid = one.Project_ID;
                          let lastProjectID = one.Project_ID;
                          // if(props.taskCreateLocation=='Tasks'){
                          //   let pid=one.Project_ID;
                          //   let filterClient=props.clients.filter((one)=>props.dealObj.Account_Name&&(one.id==props.dealObj.Account_Name.id));
                          //   if(filterClient.length>0){
                          //     pid=pid+","+filterClient[0].Project_ID;
                          //   }
                          // }
                          let filterProject = props.allProject.filter(
                            (project) =>
                              project.name == one.Deal_Name + "-Miscellaneous"
                          );
                          if (filterProject.length > 0) {
                            pid =
                              one.Project_ID + "," + filterProject[0].id_string;
                            lastProjectID = filterProject[0].id_string;
                          } else {
                            pid = undefined;
                          }
                          props.handleOnChangeDeal({
                            currentTarget: {
                              name: "Deal_Name",
                              value: one.Deal_Name,
                              id: one.id,
                              Project_ID: pid,
                              LastProjectID: lastProjectID,
                            },
                          });
                        }

                        // const obj = { ...moveTaskObj };
                        // const data = { ...taskData };
                        // obj.project_id = one.id_string;
                        // data.project_name = one.name;
                        // setMoveTaskObj(obj)
                        // setTaskData(data);
                      }}
                    >
                      {one.Deal_Name}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledDropdown>

            {/* <CustomDropdown toggelButton={<Nav.Link
              className="taskmodal-dealname-top"
              id=''
              style={{ cursor: "pointer", color: 'white', backgroundColor: "#5b6c97" }}
              onClick={() => {
                // history.push({
                //   pathname: '/app/project/job/' + props.dealObj.id,
                //   state: { data: props.dealObj, action: 'DetailView', module: 'Deals' }
                // });
              }}
            >
              <span>
                Test
               
              </span>
              <FontAwesomeIcon icon={faCaretDown} />
            </Nav.Link>} items={[{name:'test1',id:'test1'}]} onSelect={(value) => {

            }} /> */}

            {props.taskType == "subtask" ? (
              <>
                {" "}
                <FontAwesomeIcon icon={faAngleRight} />
                <Nav.Link onClick={() => {}}>
                  {props.parentTaskObj ? props.parentTaskObj.name : ""}
                </Nav.Link>
              </>
            ) : (
              <></>
            )}

            <FontAwesomeIcon icon={faAngleRight} />
            {props.taskAction == "Edit" ? showBackParentTaskInHeader() : <></>}

            <Nav.Link onClick={() => {}}>
              {taskData ? taskData.name : ""}
            </Nav.Link>

            {/* {props.taskType === "subtask" ?
              (<Nav.Link
                onClick={() => {

                }}
              >
                {taskData ? taskData.name : ""}
              </Nav.Link>) : (<></>) ? props.taskType === "metatask" ?
                (<Nav.Link
                  onClick={() => {

                  }}

                >
                  {taskData ? taskData.name : ""}
                </Nav.Link>) : (<></>) : (<></>)} */}
          </div>
          {/* {props.taskAction == 'Create' ? <span className="float-left cursor-pointer btn btn-sm btn-primary"
            onClick={() => {
              if (taskData.name) {
                TaskService.CreateTask(taskData.project_id, taskData)
                  .then((result) => {
                    setDependencyEditFlag(false);
                    settimetrackedPopover(false)
                    props.handleCloseTaskModel(props.taskType);
                    props.getallTasks();
                    console.log(result);
                  })
                  .catch((err) => {
                    console.log(err);
                  })
              } else {
                alert('task name is mandatory');
              }




            }}>
            {" "}
            Save Task
          </span> : <></>} */}

          <span
            className="cursor-pointer"
            onClick={() => {
              setDependencyEditFlag(false);
              settimetrackedPopover(false);

              props.handleCloseTaskModel(props.taskType, taskData);
              if (props.taskAction == "Create" && !taskData.id) {
                if (
                  props.taskCreateLocation == "Account" ||
                  props.taskCreateLocation == "Tasks"
                ) {
                  if (taskData.name) {
                    let obj = {
                      // tasklist_id: taskData.tasklist.id,
                      name: taskData.name,
                      start_date: taskData.start_date,
                      end_date: taskData.end_date,
                      priority: taskData.priority,
                      description: taskData.Description,
                    };
                    if (taskData.tasklist) {
                      obj.tasklist_id = taskData.tasklist.id;
                    }

                    if (
                      props.dealObj.Account_Name &&
                      props.dealObj.Account_Name.id == "Miscellaneous" &&
                      props.dealObj.Deal_Name == "Miscellaneous"
                    ) {
                      props.handleCreateTaskWithProject(
                        "1716273000002687005",
                        obj
                      );
                      //props.handleCreateTaskWithProject("1716273000002687005", obj);
                    } else {
                      if (props.dealObj.LastProjectID) {
                        props.handleCreateTaskWithProject(
                          props.dealObj.LastProjectID,
                          obj
                        );
                      } else if (props.dealObj.id == "Miscellaneous") {
                        props.handleCreateTaskWithProject(
                          props.dealObj.LastProjectID,
                          obj,
                          props.dealObj.Account_Name.name,
                          "Accounts"
                        );
                      } else if (
                        props.dealObj.Account_Name &&
                        props.dealObj.Account_Name.id == "Miscellaneous"
                      ) {
                        props.handleCreateTaskWithProject(
                          props.dealObj.LastProjectID,
                          obj,
                          props.dealObj.Deal_Name,
                          "Deals"
                        );
                      } else if (
                        props.dealObj.Account_Name &&
                        props.dealObj.Account_Name.id &&
                        props.dealObj.id
                      ) {
                        props.handleCreateTaskWithProject(
                          props.dealObj.LastProjectID,
                          obj,
                          props.dealObj.Deal_Name,
                          "Deals"
                        );
                      } else {
                        props.handleCreateTaskWithProject(
                          props.dealObj.LastProjectID,
                          obj
                        );
                      }
                    }
                  }
                } else {
                  if (taskData.name) {
                    let obj = {
                      // tasklist_id: taskData.tasklist.id,
                      name: taskData.name,
                      start_date: taskData.start_date,
                      end_date: taskData.end_date,
                      priority: taskData.priority,
                      description: taskData.Description,
                    };
                    if (taskData.tasklist) {
                      obj.tasklist_id = taskData.tasklist.id;
                    }
                    let projectId =
                      projectPipeline.filter((one) =>
                        one.name.includes("Miscellaneous")
                      ).length > 0
                        ? projectPipeline.filter((one) =>
                            one.name.includes("Miscellaneous")
                          )[0].id_string
                        : "1716273000002687005";
                    if (taskData.project_name) {
                      let filterProjects = props.allProject.filter(
                        (one) => one.name == taskData.project_name
                      );
                      if (filterProjects.length > 0) {
                        projectId = filterProjects[0].id_string;
                      }
                    }

                    if (props.taskType == "subtask") {
                      let taskId = props.parentTaskObj
                        ? props.parentTaskObj.id_string
                        : "";
                      TaskService.CreateSubtask(projectId, taskId, obj)
                        .then(async (result) => {
                          if (result) {
                            result.project_id = props.parentTaskObj.project_id;
                            result.project_name =
                              props.parentTaskObj.project_name;
                            await createDefaultDepedency(
                              props.parentTaskObj,
                              result
                            );
                            if (
                              props.taskLocation == "Accounts" ||
                              props.taskLocation == "Tasks"
                            ) {
                              props.getAllTasks();
                            } else {
                              await props.getallTasks();
                            }

                            // if (taskData.custom_fields && taskData.custom_fields.filter((one) => one.label_name == 'Category').length > 0) {
                            //   let cat = taskData.custom_fields.filter((one) => one.label_name == 'Category')
                            //   await TaskService.UpdateTask(props.dealObj.Project_ID, result.id_string, "custom_fields", JSON.stringify({ [cat[0].column_name]: cat[0].value }));
                            //   let res = await TaskService.UpdateTask(props.dealObj.Project_ID, result.id_string, "custom_status", taskData.status.id);
                            //   setTaskData(res);
                            // }
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } else {
                      TaskService.CreateTask(projectId, obj)
                        .then(async (result) => {
                          if (result) {
                            if (
                              taskData.custom_fields &&
                              taskData.custom_fields.filter(
                                (one) => one.label_name == "Category"
                              ).length > 0
                            ) {
                              let cat = taskData.custom_fields.filter(
                                (one) => one.label_name == "Category"
                              );
                              await TaskService.UpdateTask(
                                props.dealObj.Project_ID,
                                result.id_string,
                                "custom_fields",
                                JSON.stringify({
                                  [cat[0].column_name]: cat[0].value,
                                })
                              );
                              let res = await TaskService.UpdateTask(
                                props.dealObj.Project_ID,
                                result.id_string,
                                "custom_status",
                                taskData.status.id
                              );
                              setTaskData(res);
                            }
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }
                }
              }
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>

        <ModalBody className="taskdetail-modal-height pb-0">
          <Row className="taskstatus-heading-wrapper">
            <Col sm={6} className="taskstatus-heading">
              <div className="text-center">
                <p className="fontsize-13 pad-bottom10">STATUS</p>

                <div className="d-flex">
                  <StatusDropdown
                    toggelButton={
                      <DropdownToggle
                        style={{
                          backgroundColor:
                            taskData && taskData.status
                              ? taskData.status.color_code
                              : "",
                        }}
                        className="task-modal-status-btn"
                      >
                        {taskData && taskData.status
                          ? taskData.status.name
                          : ""}
                      </DropdownToggle>
                    }
                    items={
                      taskLayout.status_details ? taskLayout.status_details : []
                    }
                    invertColor={props.invertColor}
                    onSelect={async (value) => {
                      if (props.taskAction !== "Create") {
                        // updateTask(taskData.id_string, "custom_status", value.id)
                        await props.evaluateDepedncyAndSuccession(
                          taskData,
                          value
                        );
                      } else {
                        updateTask(taskData.id_string, "status", {
                          color_code: value.color,
                          id: value.id,
                          name: value.name,
                        });
                      }
                    }}
                  />
                  
                  <div
                    className="task-modal-status-arrow cursor-pointer"
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      style={{
                        backgroundColor:
                          taskData && taskData.status
                            ? taskData.status.color_code
                            : "",
                        height: "35px",
                      }}
                      className="taskstatus-text-icon"
                    />
                  </div>
                </div>
              </div>

              <hr className="taskstatus-heading-seperator" />

              <div className="supervisor-div text-center">
                <p className="fontsize-13 pad-bottom10">SUPERVISOR</p>

                {taskData && taskData.custom_fields
                  ? getTaskSupervisor(taskData).length > 0
                    ? getTaskSupervisor(taskData).map((assignee, i) => {
                        var hasAssignee = assignee.name == "" ? false : true;
                        return getModalSupervisorDropdown(
                          assignee,
                          hasAssignee
                        );
                      })
                    : emptySupervisorImg()
                  : emptySupervisorImg()}
              </div>

              <div className="ml-4 text-center">
                <p className="fontsize-13 pad-bottom10">ASSIGNEE</p>
                <div className="assignee-div">
                  {/* {emptyAssigneeImg()} */}

                  {taskData && taskData.details
                    ? taskData.details.owners.length > 0
                      ? taskData.details.owners.map((assignee, i) => {
                          var hasAssignee = assignee.name == "" ? false : true;
                          return getModalAssigneeDropdown(
                            assignee,
                            hasAssignee
                          );
                        })
                      : emptyAssigneeImg()
                    : emptyAssigneeImg()}
                </div>
              </div>

              <div className="ml-4 text-center">
                <p
                  style={{ position: "relative", left: "9px" }}
                  className="fontsize-13 pad-bottom10"
                >
                  PRIORITY
                </p>
                <Dropdown
                  className="task-header-dropdown"
                  isOpen={priorityDropToggle}
                  toggle={() => setPriorityDropToggle(!priorityDropToggle)}
                >
                  <DropdownToggle
                    className="task-priority-btn"
                    caret
                    id="priorityValue"
                  >
                    {/* <img src={tag} className="taskstatus-text-tags" /> */}
                    {(taskData ? taskData.priority == "" : "") ? (
                      <>
                        <FontAwesomeIcon
                          style={{
                            color:
                              taskData && taskData.priority
                                ? priorityValues.filter(
                                    (one) => one.name == taskData.priority
                                  ).length > 0
                                  ? priorityValues.filter(
                                      (one) => one.name == taskData.priority
                                    )[0].color
                                  : ""
                                : "",
                          }}
                          icon={faFlag}
                          id="priorityTip"
                        />
                        <Tooltip
                          placement="top"
                          isOpen={priorityTooltipOpen}
                          target="priorityTip"
                          toggle={priorityToggle}
                        >
                          Priority
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          style={{
                            color:
                              taskData && taskData.priority
                                ? priorityValues.filter(
                                    (one) => one.name == taskData.priority
                                  ).length > 0
                                  ? priorityValues.filter(
                                      (one) => one.name == taskData.priority
                                    )[0].color
                                  : ""
                                : "",
                          }}
                          icon={solidFlag}
                        />
                        <UncontrolledTooltip
                          placement="right"
                          target="priorityValue"
                        >
                          {taskData ? taskData.priority : ""}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </DropdownToggle>
                  <DropdownMenu>
                    {priorityValues.map((value, i) => {
                      return (
                        <DropdownItem
                          onClick={() => {
                            updateTask(
                              taskData.id_string,
                              "priority",
                              value.name
                            );
                            // addPriorityActivity(value);
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: value.color,
                              marginRight: "5px",
                            }}
                            icon={solidFlag}
                          />{" "}
                          {value.name}
                        </DropdownItem>
                      );
                    })}
                    <DropdownItem
                      onClick={() => {
                        updateTask(taskData.id_string, "priority", "None");
                        //handleOnChangeTask({ target: { name: 'Priority', value: { name: "", color: "#000" } } });
                        // setPriorityValue({ name: "", color: "#000" })
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{
                          color: "red",
                          marginRight: "10px",
                        }}
                      />
                      CLEAR
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="ml-5 text-center">
                <p
                  style={{ position: "relative", left: "9px" }}
                  className="fontsize-13 pad-bottom10"
                >
                  REMINDER
                </p>
                <Dropdown
                  className="task-header-dropdown"
                  // isOpen={priorityDropToggle}
                  // toggle={() => setPriorityDropToggle(!priorityDropToggle)}
                >
                  <DropdownToggle
                    className="task-priority-btn"
                    style={reminders.length > 0 ? { color: "red" } : {}}
                    caret
                    id={"ReminderPopUp" + taskData.id_string}
                  >
                    <img
                      className="reminder-icon-cust"
                      onClick={() => setReminderModal(true)}
                      style={{ borderRadius: "50%" }}
                      src={
                        reminders.length > 0
                          ? reminderFillColor
                          : reminderGrayColor
                      }
                    />

                    {/* <FontAwesomeIcon onClick={() => setReminderModal(true)} style={reminders.length > 0?{ fontSize: '18px', color: '#ffc107' }:{ fontSize: '18px', color: 'lightgray' }} icon={faBell} /> */}
                    {reminders.length > 0 ? (
                      <span className="reminder-bell-num badge-danger badge-pill">
                        {reminders.length}
                      </span>
                    ) : (
                      <></>
                    )}
                  </DropdownToggle>
                </Dropdown>
                {getReminderPopUp()}
              </div>

              <Dropdown
                className="taskExtraOps"
                isOpen={taskExtraOpsToggle}
                toggle={() => setTaskExtraOpsToggle(!taskExtraOpsToggle)}
              >
                <DropdownToggle id="taskExtraOps">
                  <FontAwesomeIcon
                    style={{ color: "black" }}
                    icon={faEllipsisV}
                  />
                </DropdownToggle>
                <DropdownMenu>
                  {taskExtraItems.map((value, i) => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          handleTaskExtraOpsDropdownClick(value);
                        }}
                      >
                        {getLabel(value)}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col sm={6} className="taskstatus-heading-right">
              <Row className="font-s12">
                <Col sm={3}>
                  <div className="min-h-taskinfo">
                    <p className="fontsize-13 pad-bottom10">CREATED</p>
                    <p className="fontsize-12">
                      {taskData
                        ? // getDate(taskData.createdAt) + ' , ' + getTime(taskData.createdAt)
                          taskData.created_time
                        : "NA"}
                    </p>
                  </div>
                </Col>

                <Col sm={3}>
                  <div className="min-h-taskinfo">
                    <p className="fontsize-13 pad-bottom10">TIME TRACKED</p>
                    <p
                      className="fontsize-12 text-hover-blue"
                      id={"timeTracked" + taskData.id_string}
                    >
                      <FontAwesomeIcon
                        icon={
                          props.timeTrackFlag &&
                          props.timerObj &&
                          props.timerObj.jobId == taskData.JobId
                            ? faStopCircle
                            : faPlayCircle
                        }
                        className="timeTrack-playIcon"
                        style={
                          props.timeTrackFlag &&
                          props.timerObj &&
                          props.timerObj.jobId == taskData.JobId
                            ? { color: "rgb(204, 45, 45)" }
                            : { color: "rgb(45, 204, 110)" }
                        }
                        onClick={async () => {
                          if (
                            props.startstopClockTimeTrack &&
                            props.timeTrackFlag
                          ) {
                            await props.startstopClockTimeTrack(props.timerObj);
                          } else {
                            props.setTimerTimeTrack(timer);
                            props.startstopClockTimeTrack({
                              jobId: taskData.JobId,
                              jobName: taskData.name,
                            });
                          }
                        }}
                      />
                      <span
                        className="ml-1"
                        onClick={() => settimetrackedPopover(true)}
                      >
                        {props.timeTrackFlag &&
                        props.timerObj &&
                        props.timerObj.jobId == taskData.JobId
                          ? `${props.timerTimeTrack}`.toHHMMSS()
                          : `${timer}`.toHHMMSS()}
                      </span>
                    </p>
                    {getTimeTrackPopUp()}
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="min-h-taskinfo">
                    <p className="fontsize-13 pad-bottom10">TIME ESTIMATE</p>

                    <p
                      className="fontsize-12 text-hover-blue"
                      id={"timeEstimate"}
                    >
                      {" "}
                      {timeEstimateValue == "" && timeMinuteValue == "" ? (
                        <span className="hourglass-border">
                          <FontAwesomeIcon icon={faHourglass} />
                        </span>
                      ) : (
                        <span>
                          {getTymDetails(
                            taskData.custom_fields
                              ? taskData.custom_fields.filter(
                                  (one) => one.label_name == "Time Estimate"
                                ).length > 0
                                ? taskData.custom_fields.filter(
                                    (one) => one.label_name == "Time Estimate"
                                  )[0].value
                                : ":"
                              : ":",
                            `${timeEstimateValue}`,
                            `${timeMinuteValue}`
                          )}
                        </span>
                      )}
                      <UncontrolledPopover
                        className="time_est_popover"
                        isOpen={popoverOpen}
                        toggle={addviewtoggle}
                        trigger="legacy"
                        placement="bottom"
                        target="timeEstimate"
                      >
                        <PopoverBody className="m-2">
                          <b className="taskstatus-heading-right">
                            Time Estimate :
                          </b>
                          <div className="time_est_fields">
                            <div className="time_fields">
                              <Input
                                type="text"
                                placeholder="00"
                                value={
                                  timeEstimateValue.length == 1
                                    ? "0" + timeEstimateValue
                                    : timeEstimateValue
                                }
                                onChange={(e) => {
                                  if (e.target.value.length >= 2) {
                                    let str1 = e.target.value.replace(
                                      /^0+/,
                                      ""
                                    );
                                    setTimeEstimateValue(str1);
                                  } else {
                                    setTimeEstimateValue(e.target.value);
                                  }
                                  // onlyTypeNumber(e.target.value);
                                  // setTimeEstimateValue(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                  enterTimeEstimate(e, "popupView");
                                }}
                              />
                              <span className="time_unit">hrs</span>
                            </div>
                            <div className="time_fields ml-2">
                              <Input
                                type="text"
                                placeholder="00"
                                value={
                                  timeMinuteValue.length == 1
                                    ? "0" + timeMinuteValue
                                    : timeMinuteValue
                                }
                                onChange={(e) => {
                                  if (e.target.value.length >= 2) {
                                    let str2 = e.target.value.replace(
                                      /^0+/,
                                      ""
                                    );
                                    setTimeMinuteValue(str2);
                                  } else {
                                    setTimeMinuteValue(e.target.value);
                                  }
                                  // onlyAcceptNumbr(e.target.value);
                                  //setTimeMinuteValue(e.target.value)
                                }}
                                onKeyDown={(e) =>
                                  enterTimeEstimate(e, "popupView")
                                }
                              />
                              <span className="time_unit">min</span>
                            </div>
                          </div>
                          <div className="text-right mt-2">
                            {/* <button 
                           type="submit"
                           className="btn btn-primary btn-small"
                           onClick={}
                        >Save
                        </button> */}
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </p>
                  </div>
                </Col>
                <Col sm={3} className="text-center p-0">
                  <div className="min-h-taskinfo">
                    <p className="fontsize-13 pad-bottom10">START/DUE DATE</p>
                    <p className="text-hover-blue" id="setDueDate">
                      {taskData.start_date_format &&
                      taskData.start_date_format != "" ? (
                        // <span>{taskData.start_date_format} - {taskData.end_date_format}</span>
                        <span>
                          {dayjs(taskData.start_date_format).format(
                            "MM/DD/YYYY"
                          ) +
                            " / " +
                            dayjs(taskData.end_date_format).format(
                              "MM/DD/YYYY"
                            )}
                        </span>
                      ) : (
                        <span className="hourglass-border">
                          <FontAwesomeIcon
                            style={{ fontSize: "16px" }}
                            icon={faCalendarCheck}
                          />
                        </span>
                      )}
                    </p>
                    <p className="fontsize-12">
                      {/* <img src={duedate} className="dueDate-icon" /> */}
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        target="setDueDate"
                        className="due-date-range"
                      >
                        <PopoverBody>
                          <DateRangePicker
                            onChange={(item) => {
                              changeDateRange(item);
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={selectionRange}
                            direction="vertical"
                            scroll={{ enabled: true }}
                          />
                          {/* <div className="row">
                            <div className="col-sm">
                              Start Time : <input


                                // value={getTime(new Date(taskData.
                                //   start_date_long))}
                                className="form-control form-control-sm"

                                value={taskData.start_date_format?dayjs(taskData.start_date_format).format('HH:mm'):'00:00'}
                                type="time"
                                format="HH:mm a"
                                clearIcon={null}
                                disableClock={true}
                                onChange={(e) => {
                                  changeTimeRange(e)
                                }

                                }
                              />
                            </div>
                            <div className="col-sm">
                              End Time : <input
                                className="form-control form-control-sm"
                                type="time"
                                format="HH:mm a"
                                disableClock={true}
                                onChange={(e) => {
                                  changeEndTimeRange(e)
                                }
                                }
                                value={taskData.end_date_format?dayjs(taskData.end_date_format).format('HH:mm'):'00:00'}
                              // value={getTime(new Date(taskData.
                              //   end_date_long))}

                              />
                            </div>



                          </div> */}
                        </PopoverBody>
                      </UncontrolledPopover>
                      {/* <DatePicker
                      className="taskdetail-datepicker"
                      selected={startDate}
                      onChange={(e) => changeDate(e)}
                      dateFormat="MM/dd/yyyy"
                    /> */}
                      {/* Aug 10, 12 PM */}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col sm={6} className="right-box-popup p-0">
              <Form>
                {/* <div className="taskmodal-title-wrapper">Upload Initial Plans</div> */}
                {taskData && taskData.isMilestone ? (
                  <div className="taskmodal-title-wrapper">
                    <UncontrolledTooltip
                      placement="top"
                      target="milestone-icon"
                    >
                      Status : {taskData.Milestone.Status}
                    </UncontrolledTooltip>
                    <span id="milestone-icon" className="mileStone-icon">
                      <FontAwesomeIcon
                        icon={faSquare}
                        style={{ color: taskData ? taskData.Status.color : "" }}
                      />
                    </span>
                    {getTaskNameInput()}
                  </div>
                ) : (
                  getTaskNameInput()
                )}

                <div className="task-modal-scroll">
                  <div className="taskInfo-inputs">
                    <textarea
                      id="descriptionId"
                      class="form-control taskmodal-description-area"
                      value={taskData ? taskData.description : ""}
                      name="Description"
                      onChange={(e) => {
                        handleOnChangeTask(e);
                        // setDescription(e.target.value)
                      }}
                      // id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="Type Description below"
                    ></textarea>
                  </div>
                  <div className="task-category font-bold task-actions-left">
                    {/* {props.taskType == "subtask" ||
                      props.taskType == "metatask" ? (
                      <></>) : */}
                    <>
                      <p className="my-3 row">
                        <span className="d-inline-block mr-2  col-md-2 pr-lg-0  align-middle">
                          CATEGORY :{" "}
                        </span>
                        <Dropdown
                          isOpen={categoryDrop}
                          toggle={categoryToggle}
                          className="d-inline-block col pl-g-0"
                        >
                          <DropdownToggle
                            caret
                            className="category-dropBtn category-toggle"
                          >
                            {taskData &&
                            taskData.custom_fields &&
                            taskData.custom_fields.filter(
                              (one) => one.label_name == "Category"
                            ).length > 0
                              ? taskData.custom_fields.filter(
                                  (one) => one.label_name == "Category"
                                )[0].value
                              : "N/A"}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              value={""}
                              onClick={(e) =>
                                // setTaskCategory(e.target.value)
                                {
                                  // if (props.isRights('Task Category')) {
                                  //   handleOnChangeTask({ target: { name: 'Category', value: e.target.value } })
                                  // } else {
                                  //   swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
                                  // }
                                  // saveTaskCategory(e.target.value)
                                }
                              }
                            >
                              --Select Category--
                            </DropdownItem>
                            {categoryList.map((one) => {
                              return (
                                <DropdownItem
                                  value={one.CategoryName}
                                  onClick={(e) =>
                                    // setTaskCategory(e.target.value)
                                    {
                                      updateTask(
                                        taskData.id_string,
                                        "custom_fields",
                                        JSON.stringify({
                                          UDF_CHAR6: one.CategoryName,
                                        })
                                      );

                                      // saveTaskCategory(e.target.value)
                                    }
                                  }
                                >
                                  {one.CategoryName}
                                </DropdownItem>
                              );
                            })}

                            {/* <DropdownItem
                                value="FILING"
                                onClick={(e) =>
                                //  setTaskCategory(e.target.value)
                                {
                                  handleOnChangeTask({ target: { name: 'Category', value: 'FILING' } })
                                  //saveTaskCategory(e.target.value)
                                }
                                }
                              >
                                FILING
                              </DropdownItem>
                              <DropdownItem
                                value="MISCELLANEOUS"
                                onClick={(e) =>
                                //  setTaskCategory(e.target.value)
                                {
                                  handleOnChangeTask({ target: { name: 'Category', value: 'MISCELLANEOUS' } })
                                  //saveTaskCategory(e.target.value)
                                }
                                }
                              >
                                MISCELLANEOUS
                              </DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>
                      </p>
                    </>
                    {/* } */}
                  </div>
                  <div className="task-category font-bold task-actions-left">
                    <p className="my-3 row">
                      <span className="d-inline-block mr-2 col-md-2 pr-lg-0 align-middle">
                        PIPELINE :{" "}
                      </span>
                      <UncontrolledDropdown className="d-inline-block col pl-g-0">
                        <DropdownToggle
                          caret
                          className="category-dropBtn category-toggle"
                        >
                          {taskData.project_name
                            ? getGroupVal(taskData.project_name)
                            : "N/A"}

                          {/* {taskData && taskData.custom_fields && taskData.custom_fields.filter(one => one.label_name == 'Pipeline').length > 0 ? getGroupVal(taskData.custom_fields.filter(one => one.label_name == 'Pipeline')[0].value) : 'N/A'} */}
                        </DropdownToggle>
                        <DropdownMenu>
                          {}
                          {projectPipeline &&
                            projectPipeline.map((one) => (
                              <DropdownItem
                                value={one}
                                onClick={(e) => {
                                  const obj = { ...moveTaskObj };
                                  const data = { ...taskData };
                                  obj.project_id = one.id_string;
                                  data.project_name = one.name;
                                  setMoveTaskObj(obj);
                                  setTaskData(data);
                                  if (
                                    props.taskCreateLocation == "Tasks" ||
                                    props.taskCreateLocation == "Account"
                                  ) {
                                    props.handleOnChangeDeal({
                                      currentTarget: {
                                        name: "Project",
                                        value: one.name,
                                        id: one.id_string,
                                        Project_ID: one.id_string,
                                        LastProjectID: one.id_string,
                                      },
                                    });
                                  }
                                }}
                              >
                                {getGroupVal(one.name)}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </p>
                  </div>
                  <div className="task-category font-bold task-actions-left">
                    <p className="my-3 row">
                      <span className="d-inline-block mr-2 col-md-2 pr-lg-0 align-middle">
                        MILESTONE :{" "}
                      </span>
                      <UncontrolledDropdown className="d-inline-block col pl-g-0">
                        <DropdownToggle
                          caret
                          className="category-dropBtn category-toggle"
                        >
                          {taskData && taskData.tasklist
                            ? taskData.tasklist.name
                            : "N/A"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {taskList.map((one) => {
                            return one.milestone &&
                              one.milestone.project &&
                              one.milestone.project.id ==
                                moveTaskObj.project_id ? (
                              <DropdownItem
                                value={one.name}
                                onClick={(e) => {
                                  if (props.taskAction == "Edit") {
                                    TaskService.MoveTask(
                                      taskData.project_id,
                                      taskData.id_string,
                                      taskData.tasklist.id_string,
                                      one.id_string
                                    )
                                      .then((result) => {
                                        const obj = { ...taskData };
                                        obj.tasklist = {
                                          name: one.name,
                                          id: one.id_string,
                                          id_string: one.id_string,
                                        };
                                        setTaskData(obj);
                                        console.log(result);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  } else {
                                    let obj = { ...moveTaskObj };
                                    let data = { ...taskData };
                                    obj.toTaskListId = one.id_string;
                                    data.tasklist = {
                                      id: one.id_string,
                                      name: one.name,
                                    };
                                    setTaskData(data);
                                    setMoveTaskObj(obj);
                                  }
                                }}
                              >
                                {one.name}
                              </DropdownItem>
                            ) : (
                              <></>
                            );
                          })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <p className="float-right">
                        {taskData && taskData.isMilestone
                          ? "Milestone Status : " + taskData.Milestone.Status
                          : ""}
                      </p>
                    </p>
                  </div>

                  <div>{getTableForLookupFields()}</div>
                  {/* <div>
                    {getTableForAddNewField()}
                  </div> */}
                  {/* <div>Type Description below</div> */}
                  <div className="font-bold task-actions-left">
                    {openmodalType == "subtask" ||
                    openmodalType == "metatask" ? (
                      <></>
                    ) : (
                      <>
                        <p className="my-3 custom_dropdown_css addAttachment-dropdown">
                          <span
                            id="dependency-collapse"
                            className="d-inline-block mr-2 align-middle"
                            onClick={() =>
                              setDependencyCollapse(!dependencyCollapse)
                            }
                          >
                            {dependencyCollapse ? (
                              <FontAwesomeIcon icon={faCaretDown} />
                            ) : (
                              <FontAwesomeIcon icon={faCaretRight} />
                            )}
                          </span>
                          <span
                            id="dependency-collapse"
                            className="d-inline-block mr-2 align-middle"
                            // onClick={() => setDependencyCollapse(!dependencyCollapse)}
                          >
                            TASK RELATION :{" "}
                          </span>
                          <Dropdown
                            isOpen={depedencyDropToggle}
                            toggle={dependencyDropToggleFun}
                          >
                            <DropdownToggle>
                              Add <FontAwesomeIcon icon={faCaretDown} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => { 
                                  TaskService.GetProjectTask(
                                    taskData.project_id
                                  )
                                    .then((tasks) => {
                                      tasks = tasks.filter(
                                        (one) =>
                                          one.id_string !== taskData.id_string
                                      );
                                      tasks = tasks.map((one) => {
                                        return {
                                          label: one.name,
                                          value: one.id_string,
                                          project: {
                                            id: one.project_id,
                                            name: one.project_name,
                                          },
                                        };
                                      });
                                      setDependencyTaskList(tasks);
                                      setDependencyObj({
                                        DependentStatus: "1716273000002029041",
                                        deal: props.dealObj.id,
                                        type: "Blocking",
                                        pipeline: taskData.project_id,
                                      });
                                      setDependencyDrop(true);
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                                id="check-collapseTodo"
                              >
                                {/* <FontAwesomeIcon
                                  icon={faCircle}
                                  className="checklist-icon"
                                /> */}
                                Dependency
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={() => {
                                  TaskService.GetProjectTask(
                                    taskData.project_id
                                  )
                                    .then((tasks) => {
                                      tasks = tasks.filter(
                                        (one) =>
                                          one.id_string !== taskData.id_string
                                      );
                                      tasks = tasks.map((one) => {
                                        return {
                                          label: one.name,
                                          value: one.id_string,
                                          project: {
                                            id: one.project_id,
                                            name: one.project_name,
                                          },
                                        };
                                      });
                                      setDependencyTaskList(tasks);
                                      setDependencyObj({
                                        DependentStatus: "1716273000002029041",
                                        deal: props.dealObj.id,
                                        type: "Successor",
                                        pipeline: taskData.project_id,
                                      });
                                      setDependencySuccesseeDrop(true);
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                              >
                                {/* <img
                                  className="subtask-icon"
                                  src={Vector}
                                />  */}
                                Succession
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          {/* <span
                            id="dependencypopover"
                            className="d-inline-block mr-2 align-middle btn add-dependency-button"
                            onClick={() => {
                              TaskService.GetProjectTask(taskData.project_id)
                                .then((tasks) => {
                                  tasks = tasks.map((one) => { return { label: one.name, value: one.id_string, project: { id: one.project_id, name: one.project_name } } });
                                  setDependencyTaskList(tasks);
                                  setDependencyObj({ DependentStatus: "1716273000002029041", deal: props.dealObj.id, type: 'Blocking', pipeline: taskData.project_id })
                                  setDependencyDrop(true)
                                })
                                .catch((err) => {
                                  console.log(err);
                                })


                            }}
                          >
                            Add <FontAwesomeIcon icon={faCaretDown} />
                          </span> */}
                        </p>
                        <UncontrolledCollapse
                          isOpen={dependencyCollapse}
                          toggler="dependency-collapse"
                          className="dependencyCollapse mb-3"
                        >
                          {taskDependency.map((depe) => {
                            let relatedTask = props.taskDocs.filter(
                              (one) => one.id_string == depe.Related_Task_ID
                            );
                            relatedTask =
                              relatedTask.length > 0 ? relatedTask[0] : {};
                            return getDependencyRow(depe, relatedTask);
                          })}
                          {/* {taskData.dependency && taskData.dependency.successor && taskData.dependency.successor.map((dependency, ind) => {
                              let type = taskData.dependency.dependencyDetails[dependency].DEPENDENCY_TYPE;
                              if (type !== 'FS') {
                                return <></>
                              }
                              return <li className="dependency-list">
                                <span
                                  className="dependency-status-icon"
                                  color={
                                    "#f40600"
                                  }
                                ></span>
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  color={
                                    "#f40600"
                                  }
                                />
                               {" "}

                                <span
                                  style={
                                    { fontSize: 10, color: "#f40600" }
                                  }
                                >
                                  {"Blocking"}
                                </span>


                                <span onClick={() => getTaskData(props.dealObj.Project_ID, dependency)} style={{ fontSize: 13, textTransform: 'uppercase', marginLeft: 10, cursor: 'pointer' }}>
                                 
                                  {taskData.dependency.dependencyDetails[dependency].NAME}
                                </span>

                                <FontAwesomeIcon
                                  onClick={() => {
                                    
                                  }}
                                  className="delete-dependency-icon"
                                  icon={faTrash}
                                />
                                {!dependencyEditFlag ? <FontAwesomeIcon
                                  onClick={() => {
                                   
                                  }}
                                  id="dependencypopoveredit"
                                  className="edit-dependency-icon"
                                  icon={faEdit}
                                /> : <></>}
                                {dependencyObj && (dependencyObj.id == dependency.id) && false ? <><span
                                  id="dependencypopoveredit"
                                  className="edit-dependency-icon-edit"
                               
                                >
                                  Edit Dependency
                                  <FontAwesomeIcon className="cross-dependency-icon" onClick={() => {
                                  
                                  }} icon={faTimes} />
                                </span>
                                 

                                </>

                                  : <></>}

                               
                              </li>
                            })} */}
                        </UncontrolledCollapse>
                      </>
                    )}
                    <div className="addAttachment-dropdown mb-3">
                      <span
                        id="todo-collapse"
                        className="d-inline-block mr-2 align-middle"
                        onClick={() => setToDoCollapse(!toDoCollapse)}
                      >
                        {toDoCollapse ? (
                          <FontAwesomeIcon icon={faCaretDown} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretRight} />
                        )}
                      </span>
                      <p id="collapseDragger">TO DO : </p>
                      <Dropdown isOpen={dropOpen} toggle={dropToggle}>
                        <DropdownToggle>
                          Add <FontAwesomeIcon icon={faCaretDown} />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              checklistCollapseOpen();
                            }}
                            id="check-collapseTodo"
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              className="checklist-icon"
                            />
                            Checklist
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => {
                              toggleSubtaskContainer(!subtaskContainer);
                              subtaskCollapseOpen();
                              if (taskData.id_string) {
                                props.handleOpenTaskModel("subtask", taskData);
                              }
                            }}
                          >
                            <img className="subtask-icon" src={Vector} />{" "}
                            Subtask
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <UncontrolledCollapse
                      isOpen={toDoCollapse}
                      toggler="todo-collapse"
                      // className="dependencyCollapse"
                    >
                      <Collapse
                        toggler="collapseTodo"
                        className="todo-collapse"
                        isOpen={subtaskCollapse}
                      >
                        {props.taskType == "metatask" ? <></> : <></>}

                        {subTaskList &&
                          subTaskList.map((e) => {
                            return (
                              <div
                                className="subtask-list-item"
                                onClick={() =>
                                  props.handleClickSubtaskEdit(
                                    props.taskType,
                                    e
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  style={{ color: e.status.color_code }}
                                  icon={faStop}
                                />
                                {e.details.owners.length > 0 &&
                                e.details.owners.filter(
                                  (one) => one.name !== "Unassigned"
                                ).length > 0 ? (
                                  <>
                                    <div className="ml-2 mr-1 d-inline-block">
                                      {e.details.owners.map((assignee, i) => (
                                        <div className="transparent-dropdown assignee-dropdown dropdown">
                                          <img
                                            src={getAssigneeImgforMapRendering(
                                              assignee.zuid
                                            )}
                                            className="rounded-circle w-25px"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                ) : (
                                  <div className="ml-2 mr-1 d-inline-block">
                                    <img className="w-25px" src={assigntask} />
                                  </div>
                                )}
                                <span
                                  onClick={() => {
                                    const arr = [...openTaskArray];
                                    arr.push(e);
                                    setOpenTaskArr(arr);
                                    setTaskData(e);
                                    history.push({
                                      search: `task=${e.id_string}`,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {" "}
                                  {e.name}
                                </span>
                                {e.priority == "" ? (
                                  <FontAwesomeIcon
                                    icon={faFlag}
                                    className="ml-2"
                                  />
                                ) : (
                                  <>
                                    <FontAwesomeIcon
                                      className="ml-2"
                                      style={{
                                        color:
                                          e && e.priority
                                            ? priorityValues.filter(
                                                (one) => one.name == e.priority
                                              ).length > 0
                                              ? priorityValues.filter(
                                                  (one) =>
                                                    one.name == e.priority
                                                )[0].color
                                              : ""
                                            : "",
                                      }}
                                      icon={solidFlag}
                                    />
                                  </>
                                )}
                              </div>
                            );
                          })}
                        {subtaskContainer ? (
                          <div class="subtask-container">
                            <Input
                              type="text"
                              className="subtask-box-wrapper"
                              value={subtaskName}
                              onChange={(e) => setSubtaskName(e.target.value)}
                              placeholder="New Subtask"
                            />

                            <Dropdown
                              className="subtask-status-wrapper"
                              isOpen={subtaskStatusDrop}
                              toggle={subtaskStatusToggle}
                            >
                              <DropdownToggle
                                id="subtask-Status"
                                // className="h40"
                              >
                                {/* {taskStatus.name} */}
                                <FontAwesomeIcon
                                  style={{ color: subtaskStatus.color }}
                                  icon={faStop}
                                />
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="subtask-Status"
                                >
                                  {subtaskStatus.name}
                                </UncontrolledTooltip>
                              </DropdownToggle>
                              <DropdownMenu>
                                {statusValues.map((value, i) => {
                                  return (
                                    <DropdownItem
                                      style={{
                                        backgroundColor: value.color,
                                        color: "#fff",
                                      }}
                                      onClick={() => setSubtaskStatus(value)}
                                    >
                                      {value.name}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </Dropdown>

                            <Dropdown
                              className="subtask-priority-wrapper"
                              isOpen={subtaskPriorityDrop}
                              toggle={() =>
                                setSubtaskPriorityDrop(!subtaskPriorityDrop)
                              }
                            >
                              <DropdownToggle id="subtaskpriorityValue">
                                {/* <img src={tag} className="taskstatus-text-tags" /> */}
                                {subtaskPriority.name == "" ? (
                                  <FontAwesomeIcon icon={faFlag} />
                                ) : (
                                  <>
                                    <FontAwesomeIcon
                                      style={{
                                        color: subtaskPriority.color,
                                      }}
                                      icon={solidFlag}
                                    />
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="subtaskpriorityValue"
                                    >
                                      {subtaskPriority.name}
                                    </UncontrolledTooltip>
                                  </>
                                )}
                              </DropdownToggle>
                              <DropdownMenu>
                                {priorityValues.map((value, i) => {
                                  return (
                                    <DropdownItem
                                      onClick={() => setSubtaskPriority(value)}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          color: value.color,
                                          marginRight: "5px",
                                        }}
                                        icon={solidFlag}
                                      />{" "}
                                      {value.name}
                                    </DropdownItem>
                                  );
                                })}
                                <DropdownItem
                                  onClick={() =>
                                    setSubtaskPriority({
                                      name: "",
                                      color: "#000",
                                    })
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                      color: "red",
                                      marginRight: "10px",
                                    }}
                                  />
                                  CLEAR
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>

                            <Dropdown
                              className="subtask-assignee-wrapper"
                              isOpen={subtaskAssigneDrop}
                              toggle={() =>
                                setSubtaskAssigneDrop(!subtaskAssigneDrop)
                              }
                            >
                              <DropdownToggle id="subtaskAssignName">
                                {subtaskAssignee.length > 0 ? (
                                  <>
                                    {subtaskAssignee.map((assignee, i) => (
                                      <div className="transparent-dropdown assignee-dropdown dropdown">
                                        <img
                                          src={getAssigneeImgforMapRendering(
                                            assignee.userId
                                          )}
                                          className="rounded-circle w-25px"
                                        />
                                      </div>
                                    ))}{" "}
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="subtaskAssignName"
                                    >
                                      {subtaskAssignee[0].userName}
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  <img src={assigntask} />
                                )}
                              </DropdownToggle>
                              <DropdownMenu>
                                {allUsers.map((user, i) => {
                                  var userName =
                                    user.first_name + " " + user.last_name;
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        let temp = [...subtaskAssignee];
                                        if (
                                          temp.find(
                                            (one) => one.userId == user.id
                                          ) ||
                                          temp.length == 1
                                        ) {
                                          return;
                                        }
                                        temp.push({
                                          userName: userName,
                                          userId: user.id,
                                        });
                                        setSubtaskAssignee(temp);
                                      }}
                                    >
                                      {/* <img
                                        src={user.ProfilePhoto}
                                        alt={avtar}
                                        className="mr-1 rounded-circle width25"
                                      /> */}
                                      {userName}
                                    </DropdownItem>
                                  );
                                })}
                                <DropdownItem
                                  onClick={(e) => setSubtaskAssignee([])}
                                >
                                  {" "}
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                      color: "red",
                                      marginRight: "10px",
                                    }}
                                  />
                                  CLEAR
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>

                            {/* <img src={assigntask} class="image" /> */}

                            <Button
                              className="save-button"
                              onClick={
                                () => saveUpdateSubTask()
                                // toggleSubtaskContainer(!subtaskContainer)
                              }
                            >
                              Save
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Collapse>

                      <Collapse
                        isOpen={checklistCollapse}
                        toggler="check-collapseTodo"
                        className="todo-collapse"
                      >
                        <p className="todo-subtitle">
                          Checklist{" "}
                          <span
                            className="highlight-onHover cursor-pointer"
                            onClick={() => {
                              resetCheckItemFields();
                              toggleCheckItemContainer(!CheckItemContainer);
                            }}
                          >
                            +
                          </span>
                        </p>
                        {/* {arrayChunk((taskData ? taskData.Checklist : []), 2).map((r, j) => (
                        <Row>
                          {r.map((c, i) => {
                            if (c.ID == CheckItemforUpdate) {
                              return (
                                <Col sm={6}>
                                  <div className="task-checklist-update">
                                    <input
                                      type="text"
                                      id={c.ID}
                                      placeholder={c.Label}
                                      onChange={(e) => {
                                        setCheckItemLabel(e.target.value);
                                        //changeCheckLabel(e.target.value,c)
                                      }}
                                      onKeyUp={(e) => changeCheckLabel(e, c, i)}
                                    />
                                    <div>
                                      <Dropdown
                                        id={c.ID}
                                        className="subtask-assignee-wrapper"
                                        isOpen={CheckItemAssigneeDrop}
                                        toggle={() =>
                                          setCheckItemAssigneeDrop(
                                            !CheckItemAssigneeDrop
                                          )
                                        }
                                      >
                                        <DropdownToggle
                                          id={"CheckItemAssignName" + c.ID}
                                        >
                                          {CheckItemAssignee.length > 0 ? (
                                            <>
                                              <img
                                                src={getAssigneeImg(
                                                  CheckItemAssignee[0].UserId
                                                  , i)}
                                                className="rounded-circle"
                                              />{" "}
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={
                                                  "CheckItemAssignName" + c.ID
                                                }
                                              >
                                                {CheckItemAssignee[0].userName}
                                              </UncontrolledTooltip>
                                            </>
                                          ) : c.Assignee.UserId != undefined ? (
                                            <>
                                              <img
                                                src={getAssigneeImg(
                                                  c.Assignee.UserId
                                                  , i)}
                                                className="rounded-circle"
                                              />{" "}
                                              <UncontrolledTooltip
                                                placement="top"
                                                target={
                                                  "CheckItemAssignName" + c.ID
                                                }
                                              >
                                                {c.Assignee.userName}
                                              </UncontrolledTooltip>
                                            </>
                                          ) : (
                                            <img src={assigntask} />
                                          )}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {allUsers.map((user, i) => {
                                            var userName =
                                              user.FirstName +
                                              " " +
                                              user.LastName;
                                            return (
                                              <DropdownItem
                                                onClick={(e) =>
                                                  setCheckItemAssignee([
                                                    {
                                                      userName: userName,
                                                      userId: user._id,
                                                    },
                                                  ])
                                                }
                                              >
                                                <img
                                                  src={user.ProfilePhoto}
                                                  alt={avtar}
                                                  className="mr-1 rounded-circle width25"
                                                />
                                                {userName}
                                              </DropdownItem>
                                            );
                                          })}
                                          <DropdownItem
                                            onClick={(e) =>
                                              setCheckItemAssignee([])
                                            }
                                          >
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faTimes}
                                              style={{
                                                color: "red",
                                                marginRight: "10px",
                                              }}
                                            />
                                            CLEAR
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                      <Button
                                        className="btn btn-primary"
                                        onClick={() => resetCheckItemFields()}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                </Col>
                              );
                            } else {
                              return (
                                <Col sm={6}>
                                  <div className="custom-check">
                                    <input
                                      type="checkbox"
                                      id={c.ID}
                                      checked={c.IsChecked}
                                      onChange={() => {
                                        changeCheck(c, i);
                                      }}
                                    />
                                    <span class="checkmark"></span>
                                    <span
                                      className="checkbox-text"
                                      id={c.ID + "span"}
                                      onClick={() => {
                                        setCheckItemforUpdate(c.ID);
                                      }}
                                    >
                                      {c.Label}
                                    </span>
                                    <img
                                      id={"CheckItemAssignName" + c.ID}
                                      src={getAssigneeImg(c.Assignee.UserId, i)}
                                      className="mr-1 rounded-circle width25 checklist-assignee"
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={"CheckItemAssignName" + c.ID}
                                    >
                                      {c.Assignee.userName}
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                              );
                            }
                          })}
                        </Row>
                      ))} */}
                        {/* {CheckItemContainer ? (
                        <div
                          className="task-checklist-update"
                        //class="subtask-container"
                        >
                          <Input
                            type="text"
                            value={CheckItemLabel}
                            onChange={(e) => setCheckItemLabel(e.target.value)}
                            placeholder="New Check Item"
                            onKeyUp={(e) => {
                              if (e.key == "Enter") {
                                saveUpdateCheckList();
                                toggleCheckItemContainer(!CheckItemContainer);
                              }
                            }}
                          />
                          <Dropdown
                            className="subtask-assignee-wrapper"
                            isOpen={CheckItemAssigneeDrop}
                            toggle={() =>
                              setCheckItemAssigneeDrop(!CheckItemAssigneeDrop)
                            }
                          >
                            <DropdownToggle id="CheckItemAssignName">
                              {CheckItemAssignee.length > 0 ? (
                                <>
                                  <img
                                    id="CheckItemAssignName"
                                    src={getUserProfilePhotobyID(
                                      CheckItemAssignee[0].userId
                                    )}
                                    className="rounded-circle"
                                  />{" "}
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="CheckItemAssignName"
                                  >
                                    {CheckItemAssignee[0].userName}
                                  </UncontrolledTooltip>
                                </>
                              ) : (
                                <img src={assigntask} />
                              )}
                            </DropdownToggle>
                            <DropdownMenu>
                              {allUsers.map((user, i) => {
                                var userName =
                                  user.FirstName + " " + user.LastName;
                                return (
                                  <DropdownItem
                                    onClick={(e) =>
                                      setCheckItemAssignee([
                                        {
                                          userName: userName,
                                          UserId: user.UserId,
                                        },
                                      ])
                                    }
                                  >
                                    <img
                                      src={user.ProfilePhoto}
                                      alt={avtar}
                                      className="mr-1 rounded-circle width25"
                                    />
                                    {userName}
                                  </DropdownItem>
                                );
                              })}
                              <DropdownItem
                                onClick={(e) => setCheckItemAssignee([])}
                              >
                                {" "}
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  style={{
                                    color: "red",
                                    marginRight: "10px",
                                  }}
                                />
                                CLEAR
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          <Button
                            className="btn btn-primary"
                            onClick={() => {
                              resetCheckItemFields();
                              toggleCheckItemContainer(!CheckItemContainer);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )} */}
                      </Collapse>
                    </UncontrolledCollapse>

                    {openmodalType == "task" ? (
                      <>
                        <div className="addAttachment-dropdown mb-3">
                          <span
                            id="attachement-collapse"
                            className="d-inline-block mr-2 align-middle"
                            onClick={() =>
                              setAttachementCollapse(!attachementCollapse)
                            }
                          >
                            {attachementCollapse ? (
                              <FontAwesomeIcon icon={faCaretDown} />
                            ) : (
                              <FontAwesomeIcon icon={faCaretRight} />
                            )}
                          </span>
                          <p id="collapseDragger">ATTACHMENTS : </p>
                          <Dropdown
                            isOpen={AttachdropOpen}
                            toggle={AttachdropToggle}
                          >
                            <DropdownToggle>
                              Add <FontAwesomeIcon icon={faCaretDown} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                id="check-collapseTodo"
                                onClick={(e) => {
                                  setAttachmentModal(!attachmentModal);
                                  //handleUploadFiles(e)
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faUpload}
                                  className="checklist-icon"
                                />
                                Upload File
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          <span
                            style={
                              attachementViewType == "list"
                                ? { backgroundColor: "#007bff", color: "white" }
                                : {}
                            }
                            onClick={() => setAttachementViewType("list")}
                            className="list-grid-buttons list-grid-buttons_custom btn-sm"
                          >
                            <i class="fa fa-bars"></i> List
                          </span>
                          <span
                            style={
                              attachementViewType == "grid"
                                ? { backgroundColor: "#007bff", color: "white" }
                                : {}
                            }
                            onClick={() => setAttachementViewType("grid")}
                            className="list-grid-buttons btn-sm"
                          >
                            <i class="fa fa-th-large"></i> Grid
                          </span>
                        </div>
                        <AttachmentModal
                          handleAttachementRemove={handleAttachementRemove}
                          setAttachmentModal={setAttachmentModal}
                          attachmentModal={attachmentModal}
                          handleUploadFiles={handleUploadFiles}
                          data={props.dealObj}
                          fileNames={fileNames}
                          setFileNames={setFileNames}
                          uploadFiles={uploadFiles}
                        />
                        <UncontrolledCollapse
                          isOpen={attachementCollapse}
                          toggler="attachement-collapse"
                          // className="dependencyCollapse"
                        >
                          {attachementViewType == "grid" ? (
                            <Row>
                              {taskAttachements &&
                                taskAttachements.map((file, i) => {
                                  return (
                                    <Col sm={3}>
                                      <Card
                                        className="task-attachment-card position-relative mb-2"
                                        href={file.file}
                                        download={file.FILENAME}
                                      >
                                        <CardBody className="p-2">
                                          <div className="row">
                                            <div className="col">
                                              <figure>
                                                {getIcon(file.EXTENSION)}
                                                <p
                                                  onClick={() =>
                                                    handleRemoveFile(file)
                                                  }
                                                  className="mb-0 float-right"
                                                >
                                                  <FontAwesomeIcon
                                                    color="red"
                                                    icon={faTrashAlt}
                                                  />
                                                </p>
                                              </figure>
                                              <h6 className="attachmnt-file">
                                                {file.FILENAME}
                                              </h6>
                                            </div>
                                            <div className="col text-right">
                                              <div className="task-attachment-card-label">
                                                <p className="mb-0">
                                                  {getDate(
                                                    new Date(file.UPLOADED_TIME)
                                                  )}{" "}
                                                  &nbsp;,&nbsp;{" "}
                                                  {getFullDate(
                                                    file.UPLOADED_TIME
                                                  )}{" "}
                                                  {getTime(file.UPLOADED_TIME)}
                                                </p>
                                                <p className="mb-0">
                                                  Uploaded By {file.OWNER}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  );
                                })}
                            </Row>
                          ) : (
                            taskAttachements &&
                            taskAttachements.map((file, i) => {
                              return (
                                <Card
                                  className="task-attachment-card position-relative mb-2"
                                  href={file.file}
                                  download={file.FILENAME}
                                >
                                  <CardBody className="p-2">
                                    <div className="row">
                                      <div className="col">
                                        {/* <figure>
                                      {getIcon(file.EXTENSION)}
                                    </figure> */}
                                        <h6 className="attachmnt-file">
                                          {file.FILENAME}
                                        </h6>
                                      </div>
                                      <div className="col text-right">
                                        <div className="task-attachment-card-label">
                                          <p className="mb-0">
                                            Uploaded By {file.OWNER}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col text-right">
                                        <div className="task-attachment-card-label d-flex justify-content-between">
                                          <p className="mb-0">
                                            {getDate(
                                              new Date(file.UPLOADED_TIME)
                                            )}{" "}
                                            , {getFullDate(file.UPLOADED_TIME)}{" "}
                                            {getTime(file.UPLOADED_TIME)}
                                          </p>
                                          <p
                                            className="mb-0 float-right cursor-pointer"
                                            onClick={() =>
                                              handleRemoveFile(file)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              color="red"
                                              icon={faTrashAlt}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              );
                            })
                          )}
                        </UncontrolledCollapse>

                        {/* <button onClick={()=>{clickhandler(acceptedFiles)}}>click here</button> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <Button
                    className="task-modal-saveBtn"
                    onClick={() => {
                      if (openmodalType == "subtask") {
                        saveUpdateSubTaskData();
                      } else if (openmodalType == "metatask") {
                        saveUpdateMetaSubTaskData();
                      } else {
                        saveUpdateTaskData();
                      }
                      openSaveTaskModal();
                    }}
                  >
                    Save
                  </Button> */}
                </div>

                {/* <div {...getRootProps({ className: "dropzone" })}>
                 
                  <input {...getInputProps()}
                 
                  />
                  <p className="m-0">
                    Drag 'n' drop some files here, or click to select files
                  </p>
                </div> */}

                <div>
                  {isDragActive ? (
                    <div className="drop-file-task">
                      <FontAwesomeIcon size="2x" icon={faFile} />
                      &nbsp;&nbsp;&nbsp;drag files here
                    </div>
                  ) : (
                    <></>
                  )}

                  <div style={isDragActive ? { opacity: 0.3 } : {}}>
                    <div
                      {...getRootProps({
                        className: "dropzone",
                        onClick: (event) => event.stopPropagation(),
                      })}
                    >
                      {/* <div className="dots-3">
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </div> */}
                      <input
                        {...getInputProps()}
                        // onChange={()=>saveFileClickHandler()}
                      />
                      <p className="m-0">
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
            <Col sm={6} className="left-box-popup">
              <AntdPopover
                placement="bottom"
                trigger="click"
                // isOpen={employeeNotePopup}
                content={
                  <div>
                    <div className="row task-filter-hovr">
                      <div className="col-sm-12 p-0">
                        <input
                          onChange={(e) => {
                            let list = [...activityView];
                            if (e.currentTarget.value !== "") {
                              list = list.filter((activity) =>
                                activity
                                  .toLowerCase()
                                  .includes(e.currentTarget.value.toLowerCase())
                              );
                            }
                            setViewActivity(list);
                          }}
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="search.."
                        />
                      </div>
                    </div>
                    <hr className="hrcls"></hr>
                    {viewActivity.map((activity, idx) => {
                      return (
                        <>
                          <div className="row task-filter-hovr">
                            <div
                              onClick={() => {
                                setActivityFilterBy(activity);
                              }}
                              className="col-sm-9"
                            >
                              <a>{activity}</a>
                            </div>
                          </div>
                          {viewActivity.length - 1 == idx ? (
                            <></>
                          ) : (
                            <hr className="hrcls"></hr>
                          )}
                        </>
                      );
                    })}
                  </div>
                }
              >
                View Activity{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "rgb(24, 144, 255)",
                  }}
                >
                  {activityFilterBy}
                </span>
              </AntdPopover>
              {/* <BootstrapSwitchButton
                size="xs"
                width={110}
                checked={activityFlag}
                onlabel='Comments'
                offlabel='Activity'
                onstyle="primary"
                offstyle="info"
                onChange={(checked) => {
                  console.log(checked)
                  setActivityFlag(checked)
                  // this.setState({ isUserAdmin: checked })
                }}
              /> */}

              <div className="taskModal-contentRight mt-2">
                {getFilterActivity(mergeActivity).map((oneActivity) => {
                  let comText = "";
                  if (oneActivity.ActivityType == "Comment") {
                    comText = oneActivity.content;
                  }
                  return oneActivity.ActivityType == "Logs" ? (
                    <Row className="name-tasklist mb-2">
                      <Col sm={8}>
                        <div>
                          {/* <div>
                    <span><small>You</small> Created{e.TaskName}</span>
                    </div> */}

                          {getStatementForTask(oneActivity)}
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="text-right">
                          <span>
                            {getDate(new Date(oneActivity.time_long))},{" "}
                            {getFullDate(new Date(oneActivity.time_long))}
                          </span>{" "}
                          at{" "}
                          <span>
                            {getTime(new Date(oneActivity.time_long))}
                          </span>
                        </div>
                        {/* <div className="text-right">
                            <span>{getDate(new Date(oneActivity.time_long))}</span> at <span>{getTime(new Date(oneActivity.time_long))}</span>
                          </div> */}
                      </Col>
                    </Row>
                  ) : (
                    <div className="Comment-Section px-3">
                      <div>
                        <Row>
                          <div className="task-comment-img">
                            {oneActivity.added_by ? (
                              <img
                                src={UserService.GetUserPhotoUrl(
                                  oneActivity.added_by
                                )}
                              />
                            ) : (
                              <div className="no-image-user">
                                {oneActivity.added_person.charAt(0)}
                              </div>
                            )}
                            {/* {/ <img src={comments.ProfilePhoto} / > /} */}
                          </div>
                          <Col sm={10} className="pl-0">
                            {showTaskComment(comText, oneActivity)}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className="off-gray just-now">Just Now</div> */}
              <div className="bottom-comm-fix">
                <div className="dots-3">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </div>
                <Comment
                  dealObj={props.dealObj}
                  task={taskData}
                  alltasks={props.taskDocs}
                  setTaskData={setTaskData}
                  // handleClickSubtaskEdit={props.handleClickSubtaskEdit}
                  // handleOpenTaskModal={props.handleOpenTaskModel}
                  // taskType={props.taskType}
                  closeModal={props.handleCloseTaskModel}
                  history={history}
                  id="task"
                  taskname={taskData.name}
                  allusers={allUsers}
                  taskId={taskData.id_string}
                  projectId={taskData.project_id}
                  commentMessages={commentMessages}
                  setCommentMessages={setCommentMessages}
                  getAllTaskMessage={getAllTaskMessage}
                />
                {/* <div className="comment-here-box">
                  {
                    !openCommentEditor ? <Input
                      disabled={taskData ? taskData._id ? false : true : true}
                      type="text"
                      placeholder="Comment Here ..."
                      // value={Comms}
                      // onChange={(e) => setComms(e.target.value)}
                      // onKeyUp={(e) => handleKeyPress(e)}
                      onClick={() => {
                        if (props.isRights('Comment')) {
                          setOpenCommentEditor(!openCommentEditor)
                        } else {
                          swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
                        }

                      }}

                      id="opencommenteditor"
                    /> : <></>
                  }
                  <UncontrolledCollapse
                    isOpen={openCommentEditor}
                    toggler="opencommenteditor"
                  >
                    <div className='callLog-desc-editor'>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(e, editor) => {
                          handleOnChangeComment(e, editor);
                        }}
                        name="Description"
                        data={Comms}
                        config={
                          {
                            mention: {
                              feeds: [
                                {
                                  marker: '@',
                                  feed: getCkEditorFeed(allUsersWithContact),
                                  itemRenderer: customItemRenderer,
                                  minimumCharacters: 1
                                }
                              ]
                            },
                            placeholder: "Comment Here ...",
                            toolbar: [
                              "bold",
                              "italic",
                              "heading",
                              "|",

                              "undo",
                              "redo",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                              "insertTable",
                              "|",
                              "outdent",
                              "indent",
                              "|",
                              "link",
                              "uploadImage",
                            ]
                          }}
                        onKeyUp={(e) => {
                          setOpenCommentEditor(false)
                          handleKeyPress(e)
                        }}
                      />
                      <button
                        className="continue-button font-13 logactivity"
                        onClick={(e) => {
                          setOpenCommentEditor(false)
                          handleKeyPress(e)
                        }}
                      >Comment</button>
                      <button
                        onClick={() => {
                          setOpenCommentEditor(false)
                        }}
                        className="continue-button small-3d-button font-13 logactivity"
                      >Cancel</button>
                    </div>
                  </UncontrolledCollapse>

                </div> */}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  const getAssigneeImgforMapRendering = (assigneeId) => {
    let imgUrl = assigntask;
    let obj = usersDetail.find((user) => user.UserId == assigneeId);
    if (obj != undefined) {
      return obj.ProfilePhoto;
    } else {
      // if(usersDetail.length>0) {
      //   usersDetail.forEach(user=> {
      //     if(user.UserId == assigneeId){    //|| user._id == assigneeId
      //       imgUrl = user.ProfilePhoto;
      //     }
      //   })
      // }
      return imgUrl;
    }
  };
  const getAssigneeImg = (assigneeId, i) => {
    let imgUrl = assigntask;
    //  setPrevCheckList(CheckList[i])
    let obj = usersDetail.find((user) => user.zuid == assigneeId);
    if (obj != undefined) {
      return obj.ProfilePhoto;
    } else {
      // if(usersDetail.length>0) {
      //   usersDetail.forEach(user=> {
      //     if(user.UserId == assigneeId){    //|| user._id == assigneeId
      //       imgUrl = user.ProfilePhoto;
      //     }
      //   })
      // }
      return imgUrl;
    }
  };

  /**
   *
   * @param {*} clzName  - css class name
   * @returns addAssigneeImgIcon
   */
  const addAssigneeTblImgIcon = (clzName) => {
    return (
      <img
        src={getAssigneeImg("")}
        className="task-table-icon add-assignee-table cursor-pointer"
      />
    );
  };

  const [assigneeTooltipOpen, setAssigneeTooltipOpen] = useState(false);
  const [assigneeSearch, setAssigneeSearch] = useState("");

  const assigneeToggle = () => {
    setAssigneeTooltipOpen(!assigneeTooltipOpen);
  };

  const emptyAssigneeImg = () => {
    let assignee = {};
    assignee.name = "";
    return getModalAssigneeDropdown(assignee, false);
  };
  const emptySupervisorImg = () => {
    let assignee = {};
    assignee.username = "";
    return getModalSupervisorDropdown(assignee, false);
  };

  const handleAssigneeSearch = (name) => {
    let users = [...cacheUsers];
    setAssigneeSearch(name);
    let AssignUsers = users.filter((one) => {
      if (one?.full_name?.toLowerCase().includes(name?.toLowerCase())) {
        return one;
      }
    });
    setSearchResult(AssignUsers);
  };

  const [openremoveAssigneeModal, setOpenRemoveAssigneeModal] = useState(false);
  const [assigneeID, setAssigneeID] = useState("");
  const [saveTaskID, setSaveTaskID] = useState("");

  const handleRemoveAssigne = (userId, taskId) => {
    let allUsersName = [...allUsers];

    let taskData = [...props.taskDocs];
    let filterTasks = {};
    if (taskId) {
      filterTasks = taskData.filter((obj) => obj._id === taskId)[0];
    } else {
      filterTasks = taskData;
    }
    if (deleteWarnObj.id == "assignee") {
      let findAssignee = filterTasks.Assignee;

      let filterAssignee = findAssignee.filter((e) => e.userId != userId);

      filterTasks.Assignee = filterAssignee;
      if (filterTasks.Assignee.length == 0) {
        filterTasks.Status = { name: "PENDING", color: "#e50000" };
      }
      setTaskData(filterTasks);
    }
    if (deleteWarnObj.id == "superviser") {
      let findSuperviser = filterTasks.Superviser;

      let filterSuperviser = findSuperviser.filter((e) => e.userId != userId);

      filterTasks.Superviser = filterSuperviser;

      setTaskData(filterTasks);
    }

    // updateTask(filterTasks);
  };
  const openRemoveAssigneWarningModal = () => {
    return (
      <Modal
        isOpen={openremoveAssigneeModal}
        backdrop={"static"}
        size="md"
        toggle={openremoveAssigneeModal}
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">{deleteWarnObj.header}</h5>
              <p className="taskComplete-warning-subtitle">
                {deleteWarnObj.text}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  setOpenRemoveAssigneeModal(!openremoveAssigneeModal);
                  handleRemoveAssigne(assigneeID, saveTaskID);
                }}
              >
                Yes
              </button>

              <button
                onClick={() => {
                  setOpenRemoveAssigneeModal(!openremoveAssigneeModal);
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                No
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  //   const content = (
  //     <EmployeeCalender from='popover' selectedEmployee={selectedEmployee} />
  //   );

  const [selectedEmployeePopover, setSelectedEmployeePopover] = useState(false);
  const [employeeCalenderPopUp, setEmployeeCalenderPopUp] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTaskDropdown, setSelectedTaskDropdown] = useState(null);
  const [selectedAssigneeDropdown, setSelectedAssigneeDropdown] =
    useState(null);

  const handleChnageVisible = (event) => {
    setSelectedEmployeePopover(event);
  };

  const toggleDropdwon = (e) => {
    if (selectedTaskDropdown && !selectedEmployeePopover) {
      setSelectedTaskDropdown(null);
    }
  };

  const getModalAssigneeDropdown = (assignee, hasAssignee) => {
    //  //; //
    var a = uuidv4();
    var b = uuidv4();
    a = a + b;

    var uniqueID = a.replace(/[0-9]/g, "");
    uniqueID = uniqueID.replace(/-/g, "");
    return (
      <UncontrolledDropdown
        // className="task-header-dropdown modal-assignee-wrapper"
        className="transparent-dropdown assignee-dropdown"
        // ref={dropdownRef}
        // toggle={toggleDropdwon}
        // isOpen={selectedTaskDropdown &&
        //   ((selectedAssigneeDropdown == assignee) || (selectedAssigneeDropdown && selectedAssigneeDropdown.userId == assignee.userId))}
      >
        <DropdownToggle
          className={hasAssignee ? "has-assignee" : "no-assignee"}
          // id={uniqueID}
        >
          {!assignee.first_name ? (
            //task-table-icon add-assignee-table cursor-pointer
            <>
              <img
                src={getAssigneeImg("")}
                className="add-assignee"
                id="assignee-tooltip"
                // onClick={() => {
                //   setSelectedTaskDropdown(taskId)
                //   setSaveTaskID(taskId);
                //   setSelectedAssigneeDropdown(null);
                // }}
              />
              {/* <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      // onClick={()=>{setAssigneeID(assignee.userId)
                      //               setSaveTaskID(taskId)
                      //               setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
                      //   // handleRemoveAssigne(assignee.userId, taskId)
                      // }}
                  /> */}
              <Tooltip
                placement="top"
                isOpen={assigneeTooltipOpen}
                target="assignee-tooltip"
                toggle={assigneeToggle}
              >
                Assignee
              </Tooltip>
            </>
          ) : (
            <>
              {assignee.id ? (
                <>
                  <UncontrolledTooltip
                    placement="top"
                    target={"user-name-tooltip-" + assignee.id}
                  >
                    {assignee.first_name + " " + assignee.last_name}
                  </UncontrolledTooltip>
                  <div className="assignee-img">
                    <img
                      id={"user-name-tooltip-" + assignee.id}
                      src={UserService.GetUserPhotoUrl(assignee.id)}
                      className="rounded-circle selected-avtar"
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      onClick={async () => {
                        swal({
                          title: "Are you sure?",
                          text: "you want to remove assignee",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let ids = taskData.details.owners.map(
                              (one) => one.id
                            );
                            ids = ids.filter((id) => id !== assignee.id);
                            ids = ids.join(",");
                            if (!ids) {
                              ids = "0";
                            }
                            await updateTask(
                              taskData.id_string,
                              "person_responsible",
                              ids
                            );
                          }
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <UncontrolledTooltip
                    placement="top"
                    target={"user-name-tooltip-" + assignee.id}
                  >
                    {assignee.name}
                  </UncontrolledTooltip>
                  <div className="assignee-img">
                    <div
                      id={"user-name-tooltip-" + assignee.id}
                      className="no-image-user"
                    >
                      {assignee.name.charAt(0)}
                    </div>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      onClick={async () => {
                        swal({
                          title: "Are you sure?",
                          text: "you want to remove assignee",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let ids = taskData.details.owners.map(
                              (one) => one.id
                            );
                            ids = ids.filter((id) => id !== assignee.id);
                            ids = ids.join(",");
                            if (!ids) {
                              ids = "0";
                            }
                            await updateTask(
                              taskData.id_string,
                              "person_responsible",
                              ids
                            );
                          }
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </DropdownToggle>
        <DropdownMenu>
          <div className="dropdownHeader-search">
            <input
              type="text"
              placeholder="Search..."
              value={assigneeSearch}
              className="form-control form-control-sm"
              onChange={(e) => handleAssigneeSearch(e.target.value)}
            />
          </div>
          {assigneeSearch === ""
            ? allUsers.map((user, i) => {
                var userName = user.first_name + " " + user.last_name;
                // //;
                return (
                  <div className="empCalendar-popover">
                    <AntdPopover
                      ref={dropdownRef}
                      toggle={toggleDropdwon}
                      isOpen={
                        selectedTaskDropdown &&
                        (selectedAssigneeDropdown == assignee ||
                          (selectedAssigneeDropdown &&
                            selectedAssigneeDropdown.userId == assignee.userId))
                      }
                      onVisibleChange={(visible) =>
                        handleChnageVisible(visible)
                      }
                      // onMouseEnter={()=>setEmployeeCalenderPopUp(true)}
                      placement="right"
                      trigger="hover"
                      className="empCalendar-popover"
                      //   content={content}
                      title={
                        selectedEmployee
                          ? selectedEmployee.FirstName +
                            " " +
                            selectedEmployee.LastName +
                            " Calender"
                          : "Employee Calender"
                      }
                    ></AntdPopover>
                    <DropdownItem
                      // ref={dropdownRef}
                      // toggle={toggleDropdwon}
                      className="position-relative"
                      // onMouseEnter={() => {
                      //   setSelectedEmployee(user);
                      //   setEmployeeCalenderPopUp(true)
                      // }}
                      // onMouseLeave={() => {
                      //   setEmployeeCalenderPopUp(false)
                      // }}
                      // isOpen={selectedTaskDropdown && (selectedTaskDropdown == getTaskId(oprationType, tasks, subTask, metaTask)) &&
                      //   ((selectedAssigneeDropdown == assignee) || (selectedAssigneeDropdown && selectedAssigneeDropdown.userId == assignee.userId))}
                      onClick={async (e) => {
                        let obj = {
                          person_responsible: user.id,
                          owner_work: [
                            { user_id: user.id, working_hours: "1" },
                          ],
                        };
                        let ids = taskData.details.owners.map((one) => one.id);
                        ids.push(user.zuid);
                        ids = ids.join(",");
                        await updateTask(
                          taskData.id_string,
                          "person_responsible",
                          ids
                        );
                        await TaskService.SendAssignedNotification(
                          user.zuid,
                          [...allUsers],
                          taskData,
                          props.dealObj,
                          "Task"
                        );
                        //await updateTask(taskData.id_string, 'owner_work', obj);
                        if (props.getNotification) {
                          props.getNotification();
                        }

                        //  await TaskService.UpdateTaskMultipleFields(taskData.project_id, taskData.id_string,obj)
                      }}
                    >
                      {user.zuid && user.zuid !== "" ? (
                        <img
                          src={UserService.GetUserPhotoUrl(user.zuid)}
                          //alt={avtar}
                          className="mr-1 rounded-circle width25"
                        />
                      ) : (
                        <div className="no-image-user">
                          {userName.charAt(0)}
                        </div>
                      )}{" "}
                      {userName}
                      {/* <img
                  src={user.ProfilePhoto}
                  className="mr-1 rounded-circle width25"
                /> */}
                    </DropdownItem>
                  </div>
                );
              })
            : SearchResult.map((user, i) => {
                var userName = user.FirstName + " " + user.LastName;
                return (
                  <DropdownItem
                    onClick={(e) =>
                      addRemoveAssignee(userName, user.UserId, null)
                    }
                  >
                    {user.ProfilePhoto && user.ProfilePhoto !== "" ? (
                      <img
                        src={user.ProfilePhoto}
                        //alt={avtar}
                        className="mr-1 rounded-circle width25"
                      />
                    ) : (
                      <div className="no-image-user">{userName.charAt(0)}</div>
                    )}{" "}
                    {userName}
                  </DropdownItem>
                );
              })}
          {/* <DropdownItem onClick={() => { handleOnChangeTask({ target: { name: 'Assignee', value: [] } }); setAssigneDropState([]) }}>
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: "red",
                marginRight: "10px",
              }}
            />
            CLEAR
          </DropdownItem> */}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const getModalSupervisorDropdown = (assignee, hasAssignee) => {
    //  //; //
    var a = uuidv4();
    var b = uuidv4();
    a = a + b;

    var uniqueID = a.replace(/[0-9]/g, "");
    uniqueID = uniqueID.replace(/-/g, "");
    return (
      <UncontrolledDropdown
        // className="task-header-dropdown modal-assignee-wrapper"
        className="transparent-dropdown assignee-dropdown"
        // ref={dropdownRef}
        // toggle={toggleDropdwon}
        // isOpen={selectedTaskDropdown &&
        //   ((selectedAssigneeDropdown == assignee) || (selectedAssigneeDropdown && selectedAssigneeDropdown.userId == assignee.userId))}
      >
        <DropdownToggle
          className={hasAssignee ? "has-assignee" : "no-assignee"}
          // id={uniqueID}
        >
          {assignee.username == "" ? (
            //task-table-icon add-assignee-table cursor-pointer
            <>
              <img
                src={getAssigneeImg("")}
                className="add-assignee"
                id="supervisor-tooltip"
                // onClick={() => {
                //   setSelectedTaskDropdown(taskId)
                //   setSaveTaskID(taskId);
                //   setSelectedAssigneeDropdown(null);
                // }}
              />
              {/* <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      // onClick={()=>{setAssigneeID(assignee.userId)
                      //               setSaveTaskID(taskId)
                      //               setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
                      //   // handleRemoveAssigne(assignee.userId, taskId)
                      // }}
                  /> */}
              <UncontrolledTooltip
                placement="top"
                // isOpen={assigneeTooltipOpen}
                target="supervisor-tooltip"
                // toggle={assigneeToggle}
              >
                Supervisor
              </UncontrolledTooltip>
            </>
          ) : (
            <>
              {assignee.id !== "" ? (
                <>
                  <UncontrolledTooltip
                    placement="top"
                    target={"user-name-tooltip-" + assignee.id}
                  >
                    {assignee.name}
                  </UncontrolledTooltip>
                  <div className="assignee-img">
                    <img
                      id={"user-name-tooltip-" + assignee.id}
                      src={UserService.GetUserPhotoUrl(assignee.id)}
                      className="rounded-circle selected-avtar"
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      onClick={() => {
                        swal({
                          title: "Are you sure?",
                          text: "You want to remove supervisor",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let cloneTask = _.cloneDeep(taskData);
                            await TaskService.UpdateTask(
                              cloneTask.project_id,
                              cloneTask.id_string,
                              "custom_fields",
                              JSON.stringify({ UDF_MULTIUSER1: "" })
                            );
                            cloneTask.custom_fields =
                              cloneTask.custom_fields.filter(
                                (one) => one.column_name !== "UDF_MULTIUSER1"
                              );
                            setTaskData(cloneTask);
                          } else {
                            // swal("you cancel  for the update task");
                          }
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <UncontrolledTooltip
                    placement="top"
                    target={"user-name-tooltip-" + assignee.userId}
                  >
                    {assignee.name}
                  </UncontrolledTooltip>
                  <div>
                    <div
                      id={"user-name-tooltip-" + assignee.userId}
                      className="no-image-user"
                    >
                      {assignee.userName.charAt(0)}
                    </div>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      onClick={() => {
                        // setAssigneeID(assignee.userId)
                        // setSaveTaskID(taskData._id)
                        // setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
                        // handleRemoveAssigne(assignee.userId, taskId)
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </DropdownToggle>
        <DropdownMenu>
          <div className="dropdownHeader-search">
            <input
              type="text"
              placeholder="Search..."
              value={assigneeSearch}
              className="form-control form-control-sm"
              onChange={(e) => handleAssigneeSearch(e.target.value)}
            />
          </div>
          {assigneeSearch === ""
            ? allUsers.map((user, i) => {
                var userName = user.first_name + " " + user.last_name;
                // //;
                return (
                  <div className="empCalendar-popover">
                    <AntdPopover
                      ref={dropdownRef}
                      toggle={toggleDropdwon}
                      isOpen={
                        selectedTaskDropdown &&
                        (selectedAssigneeDropdown == assignee ||
                          (selectedAssigneeDropdown &&
                            selectedAssigneeDropdown.userId == assignee.userId))
                      }
                      onVisibleChange={(visible) =>
                        handleChnageVisible(visible)
                      }
                      // onMouseEnter={()=>setEmployeeCalenderPopUp(true)}
                      placement="right"
                      trigger="hover"
                      className="empCalendar-popover"
                      //   content={content}
                      title={
                        selectedEmployee
                          ? selectedEmployee.FirstName +
                            " " +
                            selectedEmployee.LastName +
                            " Calender"
                          : "Employee Calender"
                      }
                    ></AntdPopover>
                    <DropdownItem
                      // ref={dropdownRef}
                      // toggle={toggleDropdwon}
                      className="position-relative"
                      // onMouseEnter={() => {
                      //   setSelectedEmployee(user);
                      //   setEmployeeCalenderPopUp(true)
                      // }}
                      // onMouseLeave={() => {
                      //   setEmployeeCalenderPopUp(false)
                      // }}
                      // isOpen={selectedTaskDropdown && (selectedTaskDropdown == getTaskId(oprationType, tasks, subTask, metaTask)) &&
                      //   ((selectedAssigneeDropdown == assignee) || (selectedAssigneeDropdown && selectedAssigneeDropdown.userId == assignee.userId))}
                      onClick={async (e) => {
                        await TaskService.UpdateTask(
                          taskData.project_id,
                          taskData.id_string,
                          "custom_fields",
                          JSON.stringify({ UDF_MULTIUSER1: user.zuid })
                        );
                        await TaskService.SendAssignedNotification(
                          user.zuid,
                          allUsers,
                          taskData,
                          props.dealObj,
                          "Task",
                          "assigned you as supervisor in task "
                        );
                        if (props.getNotification) {
                          props.getNotification();
                        }
                        let data = { ...taskData };
                        let obj1 = {
                          users: [],
                          column_name: "UDF_MULTIUSER1",
                          label_name: "Supervisor",
                          value: user.zuid,
                        };
                        if (data.custom_fields) {
                          data.custom_fields.push(obj1);
                        } else {
                          data.custom_fields = [obj1];
                        }
                        setTaskData(data);
                      }}
                    >
                      {user.zuid && user.zuid !== "" ? (
                        <img
                          src={UserService.GetUserPhotoUrl(user.zuid)}
                          //alt={avtar}
                          className="mr-1 rounded-circle width25"
                        />
                      ) : (
                        <div className="no-image-user">
                          {user.first_name.charAt(0)}
                        </div>
                      )}{" "}
                      {user.first_name + " " + user.last_name}
                      {/* <img
                  src={user.ProfilePhoto}
                  className="mr-1 rounded-circle width25"
                /> */}
                    </DropdownItem>
                  </div>
                );
              })
            : SearchResult.map((user, i) => {
                var userName = user.first_name + " " + user.last_name;
                return (
                  <DropdownItem
                    onClick={(e) => addRemoveAssignee(userName, user.id, null)}
                  >
                    {user.id && user.id !== "" ? (
                      <img
                        src={user.ProfilePhoto}
                        //alt={avtar}
                        className="mr-1 rounded-circle width25"
                      />
                    ) : (
                      <div className="no-image-user">{userName.charAt(0)}</div>
                    )}{" "}
                    {userName}
                  </DropdownItem>
                );
              })}
          {/* <DropdownItem onClick={() => { handleOnChangeTask({ target: { name: 'Assignee', value: [] } }); setAssigneDropState([]) }}>
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: "red",
                marginRight: "10px",
              }}
            />
            CLEAR
          </DropdownItem> */}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const addReply = async (commentId) => {
    let content = replyData.replace("<p>", "");
    content = content.replace("</p>", "");
    let messageVal = "reply_" + content;

    ChatService.ReplyToUser(commentMessages.chat_id, commentId, messageVal)
      .then(function (data) {
        console.log(data);
        // props.setModalOpen(!props.modalOpen);
        // return NoteService.getNotesById(props.module, props.job.id)
        getAllTaskMessage();
      })
      .then((result) => {
        // props.setNotesData(result);
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const HandleReplyChange = (e, editor) => {
    const data = editor.getData();
    setReplyData(data);
  };

  const sendNotificationToUser = async (
    userId,
    userName,
    type,
    role,
    commentId,
    note
  ) => {
    const notificationObj = {
      DataType: "Comments",
      NotificationType: type,
      SenderId: localStorage.getItem("UserId"),
      SenderName: localStorage.getItem("UserName"),
      SenderRole: localStorage.getItem("Role"),
      TableId: commentId,
      ReceiverId: userId,
      ReceiverName: userName,
      ReceiverRole: role,
      RequestId: note,
      isSeen: false,
      isClick: false,
    };
    HttpService.getByBoj("addnotification", notificationObj)
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        swal(`${err}`);
      });
  };

  const [userMentions, setUserMentions] = useState([]);
  const sendNotification = (zuid) => {
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    let users = [...allUsers];
    users = users.filter((one) => zuid == one.zuid);
    let dataArr = [];
    let doArr = [];
    users.forEach((user) => {
      let obj = {
        IsRead: false,
        Sender_Email: userInfo?.email_id,
        Sender_Name: userInfo?.first_name + " " + userInfo?.last_name,
        Reciever_Email: user?.email,
        Reciever_Name: user.full_name,
        Type: "Assignee",
        Title: "Assignee",
        Text:
          " assigned you in task " +
          `<a style="color:#40a9ff" id="Client-${
            props.dealObj.Account_Name.id
          }">${
            props.dealObj.Account_Name ? props.dealObj.Account_Name.name : ""
          }</a> > <a style="color:#40a9ff" id="Deal-${
            props.dealObj.id
          }">${getDealNameFormPipeline(
            taskData.project_name
          )}</a> > <a style="color:#40a9ff" id="Task-${taskData.id_string}">${
            taskData.name
          }</a>`,
      };
      dataArr.push(obj);
    });
    dataArr.forEach((obj) => {
      doArr.push(NotificationAPI.Trigger(obj));
    });
    Promise.all(doArr)
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getComments = async (id) => {
    //fetch task comment
    const TaskID = { TaskID: id };
    let returnData = await HttpService.CreateUpdate(
      "gettaskcomms",
      TaskID
    ).then((res) => {
      setCommentsBody(res.data);
    });
  };

  return (
    <div>
      {progress && progress.active ? (
        <ProgressBarCustom percent={progress ? progress.percent : 0} />
      ) : (
        <></>
      )}
      {loader ? <Loader /> : <></>}
      {/* <InputFieldsModal taskFieldAction={taskFieldAction} dealId={props.dealObj.Project_ID} data={taskData} modal={fieldsModal} onClose={() => { setFieldsModal(false) }} /> */}
      <LookUpFields
        taskFieldAction={taskFieldAction}
        dealObj={props.dealObj}
        data={taskData}
        modal={lookUpModal}
        onClose={() => {
          setLookUpModal(false);
        }}
        taskFieldConfigObj={taskFieldConfigObj}
        setTaskFieldConfigObj={setTaskFieldConfigObj}
        GetAllTaskCustumeFieldList={GetAllTaskCustumeFieldList}
      />
      {renderModal()}
      {AddOrEditFields()}
      {taskReminderModal()}
      {addUpdateDependencyInTask()}
      {taskRelativeModal()}
      {renderTemplateTaskModal()}
      {renderDuplicateModal()}
      {askModalToMakeMilestone()}
      {dependecyRemoveWarnig()}
      {addUpdateSucceseeDepdencyInTask()}
    </div>
  );
};

export default TaskModal;
