import httpService from "../../services/http.service";

class ShiftService{
    GetShiftConfiguration(fromDate,toDate,emailid) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://people.zoho.com/people/api/attendance/getShiftConfiguration?emailId="+emailid+"&sdate="+fromDate+"&edate="+toDate,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    MapShiftToUser(fromDate,toDate,empId,ShiftName) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://people.zoho.com/people/api/attendance/updateUserShift?empId="+empId+"&shiftName="+ShiftName+"&fdate="+fromDate+"&tdate="+toDate,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
}

export default new ShiftService();