import React, { useEffect, useState } from 'react'
import CustumSelect from './SelectWithSearch'
import './DateRangePreDifinedFieldsComponent.css'
import { Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

export const DateRangePreDifinedFieldsComponent = ({panel ,focusBack, setRangeFromSelectBox ,onChangeDateRange,madalOpenState}) => {
    const [value, setValue] = useState("")
    const [rangeObject,setRangeObject]=useState({
        "initial":"This",
        "middel":"1",
        "last":"",
        "status":"phase1",
    })
   
    useEffect(e=>{
        if(rangeObject && rangeObject.status=="phase1"){
            clearFunction()
        }
    },[])
    const applyFilter=()=>{
           
        if(rangeObject && (rangeObject.status=="completed" 
        || rangeObject.initial=="Today"
        || rangeObject.initial=="Yesterday"
        || rangeObject.initial=="Tommorrow"
        || rangeObject.middel=="This"
        )){
            focusBack(false)
            setRangeFromSelectBox(rangeObject)
        }else if(rangeObject.status=="Clear"){
            setRangeFromSelectBox(rangeObject)
        }
    }

    const clearFunction=()=>{
         setRangeFromSelectBox({
            "initial":"This",
            "middel":"1",
            "last":"",
            "status":"Clear",
        })
    }
    return (
       panel && panel.length==2 && panel[0]==panel[1] ? (<div className='Date-Range-Custome-Fields-Wrapper' onMouseDown={(e) => { 
                focusBack(true)
                e.stopPropagation()}}
            >
            <div className='row header'>
                <span className='col col-sm-3'>Custom Range</span>
                <div className='col col-sm-3'>
                    <span className='apply-btn' onClick={e=>{
                        applyFilter();
                        focusBack(false)
                    }}>Apply Range</span>
                    <FontAwesomeIcon icon={faTrashAlt} onClick={(e)=>{
                        focusBack(false)
                        onChangeDateRange("Clear",[new Date(),new Date()])
                        setRangeObject({
                            "initial":"This",
                            "middel":"1",
                            "last":"",
                            "status":"Clear",
                        })
                    }}></FontAwesomeIcon>
                </div>
                
            </div>
            <div className='row'>
            <div className='col col-sm-4'>
                    <CustumSelect
                        
                        onClick={e => {
                            focusBack(true)
                        }}
                        onBlur={(e) => {
                            focusBack(false)
                        }}
                        placeholder={"Select Range Type"}
                       
                        value={rangeObject && rangeObject.initial? rangeObject.initial : ""}
                        onChange={(e) => {
                            focusBack(false) 
                            clearFunction();
                            focusBack(false)
                            setValue(e);   
                           
                            if (e) {
                                setRangeObject({
                                    ...rangeObject,
                                    initial:e,
                                    status:"completed"
                                })
                            }else{
                                setRangeObject({
                                    ...rangeObject,
                                    initial:"",
                                    status:"phase1"
                                })
                            }
                           
                        }}
                        items={[
                            'Last',
                            'This',
                            "Next",
                        ]}
                    />
                </div>
                <div className='col col-sm-4'>
                    <Input 
                    disabled={rangeObject && rangeObject.initial && rangeObject.initial!="This"?false:true}
                    value={rangeObject && rangeObject.middel? rangeObject.middel : ""} 
                     onClick={e => {
                          
                        focusBack(true)
                    }}
                    onBlur={(e) => {
                        focusBack(false)
                    }}
                    type='number' 
                    onChange={e=>{
                        focusBack(false)
                        setValue(e);

                        let val=e.currentTarget.value
                        if(val && val<1){
                            e.preventDefault()
                            return
                        }
                        if (val) {
                            setRangeObject({
                                ...rangeObject,
                                middel:val,  
                                status:"completed"
                            })
                        }else{
                            setRangeObject({
                                ...rangeObject,
                                middel:"",
                                status:"phase2"
                            })
                        }
                       
                    }} placeholder='Enter Number'/>
                </div>
                <div className='col col-sm-4'>
                    <CustumSelect
                    disabled={ !(rangeObject && rangeObject.initial)|| (rangeObject.initial 
                        && (rangeObject.initial=="Today" || rangeObject.initial=="Tommorrow" || rangeObject.initial=="Yesterday"))}
                        onClick={e => {
                              
                            focusBack(true)
                        }}
                        onBlur={(e) => {
                            focusBack(false)
                        }}
                        placeholder={"Select Range Type"}
                        value={rangeObject && rangeObject.last? rangeObject.last : ""}
                        onChange={(e) => {
                            
                            if (e) {
                                setRangeObject({
                                    ...rangeObject,
                                    last:e,
                                    status:"completed"
                                })
                                focusBack(false)
                            }else{
                                setRangeObject({
                                    ...rangeObject,
                                    last:"",
                                    status:"phase3"
                                })
                            }
                        }}
                        items={[
                            'Week',
                            'Month',
                            'Quarter',
                            'Year'
                        ]}
                    />
                </div>
                
               
            </div>

        </div>):<></>
    )
}
