import httpService from "../../services/http.service";
async function GetRecordById(module, id) {
    return new Promise(async (resolve, reject) => {
        let config = {
            Type: 'Get',
            method: 'GET',
            // https://www.zohoapis.com/crm/v3/Leads/id
            url: " https://www.zohoapis.com/crm/v3/"+module+"/"+id,
            headers: { "Authorization": localStorage.getItem('token') },
        }
        await httpService.post('workdrive', config)
            .then((result) => {
                resolve(result.data.data);
            })
            .catch((err) => {
                reject(err);
            })
    })


}

export default GetRecordById;