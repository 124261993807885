import React, { useState,useEffect } from "react";
import {
  Button,
  CardBody,
  Collapse,
  Card,
} from "reactstrap";


function PermittingButton(props) {
    const [permittingstate, setPermittingstate] = useState(false);
  const permittingtoggle = () =>{
    props.setLayoutState("permitting")
    props.setListState('permittinginfo')
    setPermittingstate(!permittingstate);
   } 

   useEffect(() => {
    props.layoutstate == "permitting"
      ? setPermittingstate(true)
      : setPermittingstate(false);
  });


    return (
        <div>
          <Button
        className={
          (props.layoutstate == "permitting"
            ? "navbarbtn-active"
            : "navbarbtn-100")
            +" "
            +(props.sidebarCardsStatus.Homeowner? "" : "pointer-class-deactive" ) 
        }
        onClick={() =>
          {
            props.updateTheSidebarState({type:'NavActivetab',status:'Permitting'})
            localStorage.setItem('currentsidebar',"Permitting");
            permittingtoggle() }}
      >
        Permitting
      </Button>
      <Collapse isOpen={permittingstate}>
        <Card className="sidenav-card createdeal-card">
          <CardBody className="navbar-sublist-card">
        <div className={
          props.listState == "permittinginfo"
            ? "sublist-text-active"
            : "sublist-text2"
          }  
        onClick={(e) => props.setListState('permittinginfo')}>Permitting Information</div>
          </CardBody>
          </Card>
          </Collapse>  
        </div>
    );
}

export default PermittingButton;