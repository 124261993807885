import React, { useState, useEffect } from "react";
import { Button, CardBody, Collapse, Card } from "reactstrap";

function HomeownerButton(props) {
  const [homeOwnerstate, setHomeOwnerstate] = useState(false);
  const homeownertoggle = () => {
    props.setLayoutState("homeowner");
    props.setListState("homeownerinfo");
    setHomeOwnerstate(!homeOwnerstate);
  };

  useEffect(() => {
    props.layoutstate == "homeowner"
      ? setHomeOwnerstate(true)
      : setHomeOwnerstate(false);
  });

  useEffect(() => {
    props.layoutstate == "homeowner"
      ?  props.setownerType('INDIVIDUAL')
      : console.log("")
  },[props.layoutstate]);

  
  return (
    <div>
      <Button
        className={
          (props.layoutstate == "homeowner"
            ? "navbarbtn-active"
            : "navbarbtn-100")
            +" "
            +(props.sidebarCardsStatus.NycProject ? "" : "pointer-class-deactive" ) 
        }
        onClick={() => {
          props.updateTheSidebarState({type:'NavActivetab',status:'Homeowner'})
          localStorage.setItem('currentsidebar',"Homeowner");
          homeownertoggle()
        }}
      >
        Property Owner
      </Button>
      <Collapse isOpen={homeOwnerstate}>
        <Card className="sidenav-card createdeal-card">
          <CardBody className="navbar-sublist-card">
            <div
              className={
                props.listState == "homeownerinfo"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              onClick={(e) => props.setListState("homeownerinfo")}
            >
              Property Owner's Information
            </div>
            <div
              className={
                props.data.ownerType != "INDIVIDUAL"
                  ? "disabled-subheader-div"
                  : props.listState == "individualowner"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              onClick={(e) =>
                props.data.ownerType != "INDIVIDUAL"
                  ? console.log("N0t Individual")
                  : props.setListState("individualowner")
              }
            >
              Homeowner's Information
            </div>
            <div
              className={
                props.data.ownerType != "INDIVIDUAL" || props.data.mailAddDifferent == false
                  ? "disabled-subheader-div"
                  : props.listState == "individualownermail"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              onClick={(e) =>
                props.data.ownerType != "INDIVIDUAL" || props.data.mailAddDifferent == false
                  ? console.log("N0t Individual")
                  : props.setListState("individualownermail")
              }
            >
              Homeowner's Mailing Address
            </div>
            <div
              className={
                props.data.ownerType == "INDIVIDUAL"
                  ? "disabled-subheader-div"
                  : props.listState == "otherownerinfo"
                  ? "sublist-text-active"
                  : "sublist-text"
              }
              onClick={(e) =>
                props.data.ownerType == "INDIVIDUAL"
                  ? console.log("Individual")
                  : props.setListState("otherownerinfo")
              }
            >
              Other Owner Information
            </div>
            <div
              className={
                props.data.ownerType != "CONDO/CO-OP"
                  ? "disabled-subheader-div"
                  : props.listState == "condosecondo"
                  ? "sublist-text-active"
                  : "sublist-text2"
              }
              onClick={(e) =>
                props.data.ownerType != "CONDO/CO-OP"
                  ? console.log("Not Condo")
                  : props.setListState("condosecondo")
              }
            >
              CONDO/CO-OP Second Officer
            </div>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default HomeownerButton;
