import React, { useState } from 'react';
import { Button, ListGroup } from 'reactstrap';
import "../CreateClient.css";
import BillingInfoButton from './BillingInfoButton';
import Billing from './Billing';

export default function ClientSideNavBar(props) {

  return (
    <ListGroup className="client-list-sticky">
     <div className='listdiv'>
      <Button
        className={"navbarbtn-top-100"}
        style={props.layoutstate == "ClientInfo"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={() =>{
        props.setLayoutState("ClientInfo");
        localStorage.setItem('currentsidenavbar',"ClientInfo");
        }}
      >
        Client Information
      </Button>
      </div>
      <div className='listdiv'>
      {/* <Button
        className={`navbarbtn-top-100 ${props.layoutstate == "BillingDetails"?"Active":""}`}
        style={props.layoutstate == "BillingDetails"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("BillingDetails")){
            e.preventDefault()
            return
          }else{
          localStorage.setItem('currentsidenavbar',"BillingDetails");
          props.setLayoutState("BillingDetails");
          }
        }}
      >
        Billing Details
      </Button> */}
      <BillingInfoButton 
        location={props.location}
        updatedSections={props.updatedSections}
        setUpdateSectionState={props.setUpdateSectionState}
        listState={props.listState}
        formInputsValue={props.formInputsValue}
        setListState={props.setListState}
        setLayoutState={props.setLayoutState}
        layoutstate={props.layoutstate}
        billinginfotogglestate={props.billinginfotogglestate}
        setBillinginfotogglestatee={props.setBillinginfotogglestatee}
        shippinginfotogglestate={props.shippinginfotogglestate}
        setShippinginfotogglestate={props.setShippinginfotogglestate}
        shippinginfotoggle={props.shippinginfotoggle}
        basicinfotoggle={props.basicinfotoggle}
      />
      </div>
      {/* <div className='listdiv'> 
      <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "ShippingDetails"?"Active":""}`}
         style={props.layoutstate == "ShippingDetails"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("ShippingDetails")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"ShippingDetails");
          props.setLayoutState("ShippingDetails");
        }}
      >
       Shipping Details
      </Button>
      </div> */}
      <div className='listdiv'>
      <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "FullServices"?"Active":""}`}
         style={props.layoutstate == "FullServices"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("FullServices")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"FullServices");
          props.setLayoutState("FullServices");
        }}
      >
        Full Services
      </Button>
      </div>
      <div className='listdiv'>
      {/* <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "BillingTerms"?"Active":""}`}
         style={props.layoutstate == "BillingTerms"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("BillingTerms")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"BillingTerms");
          props.setLayoutState("BillingTerms");
        }}
      >
        Billing Terms
      </Button> */}
      <Billing 
      location={props.location}
      updatedSections={props.updatedSections}
      setUpdateSectionState={props.setUpdateSectionState}
      listState={props.listState}
      setListState={props.setListState}
      setLayoutState={props.setLayoutState}
      layoutstate={props.layoutstate}
      ServiceAddersToggle={props.ServiceAddersToggle}
      BillingTermsToggle={props.BillingTermsToggle}
      billingTermstogglestate={props.billingTermstogglestate}
      setBillingTermstogglestate={props.setBillingTermstogglestate}
      serviceAdderstogglestate={props.serviceAdderstogglestate}
      setServiceAdderstogglestate={props.setServiceAdderstogglestate}
      />
      </div>
      {/* <div className='listdiv'>
      <Button
         className={`navbarbtn-top-100 ${props.layoutstate == "Services"?"Active":""}`}
         style={props.layoutstate == "Services"?{
          background: '#018ee0',
          color: 'aliceblue'}:
          {}}
        onClick={(e) =>{
          if(props.location.state.action=="create" && !props.updatedSections.includes("Services")){
            e.preventDefault()
            return
          }
          localStorage.setItem('currentsidenavbar',"Services");
          props.setLayoutState("Services");
        }}
      >
        Service Adders
      </Button>
      </div> */}
    </ListGroup>
  )
}