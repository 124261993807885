import React, { useState, useEffect } from "react";
import { Button, Modal, Select, Popover, Switch, Space } from "antd";
import CustumSelect from "../SelectWithSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import dayjs from "dayjs";
import { components } from "react-select";
import CustomMultiSelect from "../custom_multiselect/custom_multiselect";
import "./task_filter.css";
import AntdRangePicker from "../DateRangePicker";
import dateTimeObject from "../../common/util/date";
import _, { deburr, indexOf, isArray } from "lodash";
import DatePicker from "react-datepicker";
import { DatePicker as AntdDatepicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import calIcon from "./Calender.png";
// import './DatePCss.css'

let dealsResult = [];
const Task_Filters = ({
  appliedFiltersForView,
  setappliedFiltersForView,
  preAppliedFiltersForView,
  setPreAppliedFiltersForView,
  filtersmode,
  setfiltersmode,
  refresh,
  mode,
  location,
  dealData,
  taskData,
  setIsColFilDeleted,
  dealColumns,
  allServices,
  taskColumns,
  allClient,
  Deals,
  dealStatus,
  handleClose,
  activeFiltersOnColumnLevel,
  setActiveFiltersOnColumnLevel,
  status,
  AllDealsClicked,
  setAllDealsClicked,
  users,
  getFilters,
  clearFilter,
  pipelines,
  myTasks,
  categorys,
  getReturnData,
  invertColor,
  viewType,
  setActiveFilterAndGetActiveFilters
}) => {
  const [filters, setFilters] = useState([
    { phase: "initial", module: "Task" },
  ]);
  const [selectedCond, setSelectedCond] = useState([]);
  const [filterItemsDeal, setFilterItemsDeal] = useState([]);
  const [opendDropdowns, setOpendDropDowns] = useState("");
  const [date, setDate] = useState([]);
  const [ConjunctionApplicationNumbers, setConjunctionApplicationNumbers] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [rangeCalCloseFlag,setRangeCalCloseFlag]=useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isInitialStateOfFilter, setIsInitialStateOfFilter] = useState(true);
  const [filterItems, setFilterItems] = useState([
    { name: "Status", type: "string" },
    { name: "Task Name", type: "string" },
    { name: "Pipeline", type: "string" },
    { name: "Category", type: "string" },
    { name: "Due Date", type: "date" },
    { name: "Priority", type: "string" },
    { name: "Assignee", type: "string" },
    { name: "Supervisor", type: "string" },
    { name: "Milestone", type: "string" },
    { name: "Created By", type: "string" },
    { name: "Start Date", type: "date" },
  ]);

  let ConstructionClassfCode = [
    { name: "FIREPROOF STRUCTURES", id: 1 },
    { name: "I-A: 4 HOUR PROTECTED", id: 2 },
    { name: "I-B: 3 HOUR PROTECTED", id: 3 },
    { name: "I-C: 2 HOUR PROTECTED", id: 4 },
    { name: "I-D: 1 HOUR PROTECTED", id: 5 },
    { name: "I-E: UNPROTECTED", id: 6 },
    { name: "2: FIRE-PROTECTED STRUCTURES", id: 7 },
    { name: "II-A: HEAVY TIMBER", id: 8 },
    { name: "II-B: PROTECTED WOOD JOIST", id: 9 },
    { name: "II-C: UNPROTECTED WOOD JOIST", id: 10 },
    { name: "II-D: PROTECTED WOOD FRAME", id: 11 },
    { name: "II-E: UNPROTECTED WOOD FRAME", id: 12 },
    { name: "3: NON-FIREPROOF STRUCTURES", id: 13 },
    { name: "4: WOOD FRAME STRUCTURES", id: 14 },
    { name: "5: METAL STRUCTURES", id: 15 },
    { name: "6: HEAVY TIMBER STRUCTURES", id: 16 },
    { name: "I-A: 3 HOUR PROTECTED - NON-COMBUSTIBLE", id: 17 },
    { name: "I-B: 2 HOUR PROTECTED - NON-COMBUSTIBLE", id: 18 },
    { name: "II-A: 1 HOUR PROTECTED - NON-COMBUSTIBLE", id: 19 },
    { name: "II-B: UNPROTECTED - NON-COMBUSTIBLE", id: 20 },
    { name: "III-A: PROTECTED WOOD JOIST - COMBUSTIBLE", id: 21 },
    { name: "III-B: UNPROTECTED WOOD JOIST - COMBUSTIBLE", id: 22 },
    { name: "IV-HT: HEAVY TIMBER - COMBUSTIBLE", id: 23 },
    { name: "V-A: PROTECTED WOOD FRAME - COMBUSTIBLE", id: 24 },
    { name: "V-B: UNPROTECTED WOOD FRAME - COMBUSTIBLE", id: 25 },
  ];
  const [open, setOpen] = useState(false);

  const dropdownFields = [
    "Project Status",
    "Account Name",
    "Deal Name",
    "Owner Type",
    "Client",
    "Borough",
    "Client",
    "Task Name",
    "Category",
    "Pipeline",
    "Assignee",
    "Created By",
    "Supervisor",
    "Status",
    "Priority",
    "Milestone",
    "Service Requested Name",
    "City",
    "State",
    "Invertor Type",
    "Active Work Without Permit Violations",
    "PV Interconnection",
    "Occupancy Classification of Building",
    "Previous Solar Info",
    "Application Number",
    "Violation Type",
    "L2 Code"
    // "Violation Number",
    // "Inspection Passed Date"
  ];

  let optionsForOccupancyOfBuilding = [
    { name: "N/A", id: 46 },
    { name: "No", id: 47 },
    { name: "Yes", id: 48 },
    { name: "ASSEMBLY: THEATERS, CONCERT HALLS", id: 1 },
    { name: "ASSEMBLY: EATING & DRINKING", id: 2 },
    { name: "ASSEMBLY: OTHER", id: 3 },
    { name: "ASSEMBLY: INDOOR SPORTS", id: 4 },
    { name: "ASSEMBLY: OUTDOORS", id: 5 },
    { name: "BUSINESS", id: 6 },
    { name: "EDUCATIONAL", id: 7 },
    { name: "FACTORY/INDUSTRIAL: MODERATE HAZARD", id: 8 },
    { name: "FACTORY/INDUSTRIAL: LOW HAZARD", id: 9 },
    { name: "HIGH HAZARD: EXPLOSIVE DETONATION", id: 10 },
    { name: "HIGH HAZARD: ACCELERATED BURNING", id: 11 },
    { name: "HIGH HAZARD: SUPPORTS COMBUSTION", id: 12 },
    { name: "HIGH HAZARD: HEALTH", id: 13 },
    { name: "HIGH HAZARD: SEMICONDUCTORS", id: 14 },
    { name: "INSTITUTION: ASSISTED LIVING", id: 15 },
    { name: "INSTITUTION: INCAPACITATED", id: 16 },
    { name: "INSTITUTION: RESTRAINED", id: 17 },
    { name: "INSTITUTION: DAY CARE", id: 18 },
    { name: "MERCANTILE", id: 19 },
    { name: "RESIDENTIAL: HOTELS, DORMITORIES", id: 20 },
    { name: "RESIDENTIAL: APARTMENT HOUSES", id: 21 },
    { name: "ESIDENTIAL: 1 & 2 FAMILY HOUSES", id: 22 },
    { name: "STORAGE: MODERATE HAZARD", id: 23 },
    { name: "STORAGE: LOW HAZARD", id: 24 },
    { name: "UTILITY / MISCELLANEOUS", id: 25 },
    { name: "HIGH HAZARD", id: 26 },
    { name: "STORAGE (MODERATE HAZARD)", id: 27 },
    { name: "STORAGE (LOW HAZARD)", id: 28 },
    { name: "INDUSTRIAL (MODERATE HAZARD)", id: 29 },
    { name: "INDUSTRIAL (LOW HAZARD)", id: 30 },
    { name: "ASSEMBLY (THEATERS)", id: 31 },
    { name: "ASSEMBLY (CHURCHES, CONCERT HALLS)", id: 32 },
    { name: "ASSEMBLY (OUTDOORS)", id: 33 },
    { name: "ASSEMBLY (MUSEUMS)", id: 34 },
    { name: "ASSEMBLY (RESTAURANTS)", id: 35 },
    { name: "EDUCATION", id: 36 },
    { name: "INSTITUTIONAL (RESTRAINED)", id: 37 },
    { name: "INSTITUTIONAL (INCAPACITATED)", id: 38 },
    { name: "RESIDENTIAL (HOTELS)", id: 39 },
    { name: "RESIDENTIAL APT HOUSE", id: 40 },
    { name: "RESIDENTIAL 1-2 FAMILY HOUSES", id: 41 },
    { name: "MISCELLANEOUS", id: 42 },
    { name: "COMMERCIAL BUILDINGS - OLD CODE", id: 43 },
    { name: "PUBLIC BLDG - OLD CODE", id: 44 },
    { name: "RESID. BLDG - OLD CODE", id: 45 },
  ];
  const [addGroupVisibility, setAddGroupVisibility] = useState("none");
  useEffect(
    (k) => {
      let ConjunctionApplicationNumber = [];
      Deals?.forEach((e, index) => {
        if (
          !_.isNull(e.Conjunction_Application_Number) &&
          !ConjunctionApplicationNumber.includes(
            e.Conjunction_Application_Number
          )
        ) {
          ConjunctionApplicationNumber.push({
            name: e.Conjunction_Application_Number,
            id: e.Conjunction_Application_Number,
          });
        }
      });
      if (ConjunctionApplicationNumber.length > 0) {
        setConjunctionApplicationNumbers(ConjunctionApplicationNumber);
      }
    },
    [Deals]
  );
  useEffect(() => { 
    try {
      if(location.state.module == "Deals"){
        setappliedFiltersForView(filters)  
      }
    } catch (error) {
      
    }
    
    if (
      filters?.length > 0 && filters?.filter((one) => one?.phase == "completed" || one?.Group).length >
        0 ||
      refresh
    ) {
      // let deals = [...Deals]
      // let tasks = [...TaskSearchResult]
      // filterTasks = []
      // filterDeals = []
      dealsResult = [];
      let obj = {};
      if (mode == "Deal") {
        obj = getFilterResultDeal(filters);
        getReturnData({ deals: obj?.deals, tasks: obj?.tasks });
      } else {
        obj = getFilterResult(filters);
        getReturnData({ deals: dealsResult, tasks: obj.tasks });
      }

      // getGroupByFields(dealsResult, groupBy.Deal)
      // deals = filterResult.deals;
      // tasks = filterResult.tasks;
    } else {
      if (_.isEmpty(filtersmode)) {
        let deals = [...dealData];
        let tasks = [...taskData];
        getReturnData({ deals: deals, tasks: tasks });
      }
    }
   
  }, [filters, refresh]);

  useEffect(
    (e) => {
      if (preAppliedFiltersForView != undefined) {
        setfiltersmode(preAppliedFiltersForView);
        setFilters(preAppliedFiltersForView);
        setDate(preAppliedFiltersForView);
      }
      //  getFilterResultDeal(preAppliedFiltersForView);
    },
    [preAppliedFiltersForView]
  );

  useEffect(() => {
    if (mode == "Task") {
      // setFilterItems(taskColumns);
      setFilters([{ phase: "initial", module: "Task" }]);
    }
  }, [taskColumns]);

  useEffect(() => {

    if (mode == "Task") {
    } else {
      setFilterItemsDeal(dealColumns);
      setFilterItems(taskColumns);
      if (mode == "Deal") {
        if (AllDealsClicked && _.isEmpty(filtersmode)) {
          setFilters([{ phase: "initial", module: "Deal" }]);
        } else if (preAppliedFiltersForView?.length > 0) {
          setAllDealsClicked(false);
        } else {
          setFilters([{ phase: "initial", module: "Deal" }]);
        }
      }
      if (mode == "Task") {
        setFilters([{ phase: "initial", module: "Task" }]);
      }
    }
  }, [dealColumns]);
  const skippMsgForInitial = (allFilters, clickFlag) => {
    if (clickFlag) {
      setIsInitialStateOfFilter(false);
      return;
    }
    if (allFilters.slice(-1).column_name) {
      if (allFilters.slice(-1).Group) {
        if (allFilters.slice(-1).Group.slice(-1).column_name) {
          setIsInitialStateOfFilter(false);
        } else {
          setIsInitialStateOfFilter(true);
        }
      } else {
        setIsInitialStateOfFilter(false);
      }
    } else {
      if (allFilters.slice(-1).Group) {
        if (allFilters.slice(-1).Group.slice(-1).column_name) {
          setIsInitialStateOfFilter(false);
        } else {
          setIsInitialStateOfFilter(true);
        }
      } else {
        setIsInitialStateOfFilter(true);
      }
    }
  };

  const shouldWeAddFilters = (allFilters, clickFlag) => {
    if (allFilters && allFilters.length > 0) {
      skippMsgForInitial(allFilters, clickFlag);
      let isIncompleteFilter = allFilters.find((m) => {
        if (m?.phase != "completed") {
          if (m?.Group?.length > 0) {
            let temp = m?.Group?.find((g) => g?.phase != "completed");
            if (temp) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else if (m?.phase == "completed") {
          if (m?.Group?.length > 0) {
            let temp = m?.Group?.find((g) => g?.phase != "completed");
            if (temp) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      });
      if (isIncompleteFilter) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const compareDateFilterResult = (date, obj) => {
    if (date) {
      date = new Date(date);
      date = dayjs(new Date(date)).format("MM-DD-YYYY");
    }
    let retsult = false;
    if (
      obj.value == "Before Date" ||
      obj.value == "After Date" ||
      obj.value == "Select A Date" ||
      obj.value == "No Date"
    ) {
      if (obj.value == "Before Date") {
        if (date && new Date(date) < new Date(obj.date)) {
          retsult = true;
        }
      } else if (obj.value == "After Date") {
        if (date && new Date(date) > new Date(obj.date)) {
          retsult = true;
        }
      } else if (obj.value == "Select A Date") {
        let tempObjDate = dayjs(new Date(obj.date)).format("MM-DD-YYYY");
        if (date && date == tempObjDate) {
          retsult = true;
        }
      } else if (obj.value == "No Date") {
        if (!date) {
          return true;
        }
      }
    } else if (obj.value == "Date Range") {
      if (obj.date && obj.date.includes(date)) {
        retsult = true;
      }
    } else if (obj.value == "Today") {
      let today = dayjs(new Date()).format("MM-DD-YYYY");
      return today == date;
    } else if (obj.value == "Yestarday") {
      let today = dayjs(new Date()).format("MM-DD-YYYY");
      let temp = new Date(today);
      temp.setDate(temp.getDate() - 1);
      let yesterday = dayjs(temp).format("MM-DD-YYYY");
      return yesterday == date;
    } else if (obj.value == "Tomorrow") {
      let today = dayjs(new Date()).format("MM-DD-YYYY");
      let temp = new Date(today);
      temp.setDate(temp.getDate() + 1);
      let tomorrow = dayjs(temp).format("MM-DD-YYYY");
      return tomorrow == date;
    } else {
      let datesArr = dateTimeObject.GetDateArr(
        new Date(),
        new Date(),
        obj.value
      );
      if (new Date(date) >= datesArr[0] && new Date(date) <= datesArr[1]) {
        retsult = true;
      }
    }
    return retsult;
  };

  const priorityValues = [
    { name: "None", color: "lightgray" },
    { name: "High", color: "#e50000" },
    { name: "Medium", color: "#f7c700" },
    { name: "Low", color: "blue" },
  ];

  const dateFilterArr = [
    { label: "Before Date", value: "isbefore" },
    { label: "Select A Date", value: "selectadate" },
    { label: "After Date", value: "isafter" },
    { label: "Date Range", value: "daterange" },
    // { label: "Yesterday", value: "yesterday" },
    // { label: "Today", value: "today" },
    // { label: "Tomorrow", value: "tomorrow" },
    // { label: "Last Week", value: "lastweek" },
    // { label: "This Week", value: "currentweek" },
    // { label: "Next Week", value: "startingtomorrow" },
    // { label: "Last Month", value: "lastmonth" },
    // { label: "This Month", value: "currentmonth" },
    // { label: "Next Month", value: "nextmonth" },
    // { label: "Last Quarter ", value: "lastQuarter" },
    // { label: "This Quarter", value: "currentQuarter" },
    // { label: "Next Quarter", value: "nextQuarter" },
    // { label: "Last Year", value: "lastyear" },
    // { label: "This Year", value: "thisyear" },
    // { label: "Next Year", value: "nextyear" },
    // { label: "No Date", value: "nodate" },
  ];

  const conditions = {
    "<": (x, y) => (parseFloat(y) < parseFloat(x) ? true : false),
    "<=": (x, y) => (parseFloat(y) <= parseFloat(x) ? true : false),
    ">": (x, y) => (parseFloat(y) > parseFloat(x) ? true : false),
    ">=": (x, y) => (parseFloat(y) >= parseFloat(x) ? true : false),
    "=": (x, y) => (parseFloat(y) == parseFloat(x) ? true : false),
    "!=": (x, y) => (parseFloat(y) !== parseFloat(x) ? true : false),
    between: (x, y) =>
      parseFloat(y?.from) < parseFloat(x) && parseFloat(y?.to) > parseFloat(x)
        ? true
        : false,
    "not between": (x, y) =>
      parseFloat(y?.from) > parseFloat(x) || parseFloat(y?.to) < parseFloat(x)
        ? true
        : false,
    "is empty": (x, y) =>
      y === "" || y === undefined || y === null ? true : false,
    "is not empty": (x, y) =>
      y !== "" && y !== undefined && y !== null ? true : false,
    is: (arrX, y) => (arrX?.includes(y) ? true : false),
    "isn't": (arrX, y) => (!arrX?.includes(y) ? true : false),
    isnt: (arrX, y) => (!arrX.includes(y) ? true : false),
    contains: (arrX, y) =>
      y && arrX && y?.toLowerCase().includes(arrX.toLowerCase()) ? true : false,
    "doesn't contains": (arrX, y) =>
      !y.toLowerCase()?.includes(arrX.toLowerCase()) ? true : false,
    "starts with": (arrX, y) =>
      y.toLowerCase().startsWith(arrX.toLowerCase()) ? true : false,
    "ends with": (arrX, y) =>
      y.toLowerCase().endsWith(arrX.toLowerCase()) ? true : false,
  };
  const filterCondition = [
    {
      type: "number",
      conditions: [
        { label: "is", value: "is" },
        { label: "=", value: "=" },
        { label: "!=", value: "!=" },
        { label: "<", value: "<" },
        { label: "<=", value: "<=" },
        { label: ">", value: ">" },
        { label: ">=", value: ">=" },
        { label: "between", value: "between" },
        { label: "not between", value: "not between" },
        { label: "is empty", value: "is empty" },
        { label: "is not empty", value: "is not empty" },
      ],
    },

    {
      type: "string",
      conditions: [
        { label: "is", value: "is" },
        { label: "isn't", value: "isn't" },
        { label: "contains", value: "contains" },
        { label: "doesn't contains", value: "doesn't contains" },
        { label: "starts with", value: "starts with" },
        { label: "ends with", value: "ends with" },
        { label: "is empty", value: "is empty" },
        { label: "is not empty", value: "is not empty" },
        // { label: 'is set', value: 'is set' }, { label: "is not set", value: 'is not set' },
      ],
    },

    {
      type: "date",
      conditions: [
        { label: "is", value: "is" },
        { label: "isn't", value: "isnt" },
      ],
    },

    {
      type: "boolean",
      conditions: [
        { label: "is", value: "is" },
        { label: "isn't", value: "isnt" },
      ],
    },
  ];

  const pvSystemTableHeaders = [
    { value: "System_Number", label: "System Number" ,type : "number"},
    { value: "Interconnection_Number", label: "Interconnection Number" ,type : "number"},
    { value: "System_Size_KW1", label: "System Size KW" ,type : "number"},
    { value: "Number_Of_Modules", label: "Number Of Modules" ,type : "number"},
    { value: "PV_Module_Model", label: "PV Module Model" ,type : "string"},
    { value: "Invertor_Type", label: "Invertor Type" ,type : "string"},
    { value: "Number_of_Invertors", label: "Number of Invertors" ,type : "number"},
    { value: "Invertor_Model_Number", label: "Invertor Model Number" ,type : "string"},
  ];
  const wwwViolationHeaders = [
    { value: "Violation_Number", label: "Violation Number" ,type : "string" },
    {
      value: "Violation_Summary",
      label: "Violation Summary",
      extra: "Violation_Number",
      colSpan:2,type : "string" 
    },
    {
      value: "Violation_Copy",
      label: "Violation Copy",
      extra: "Violation_Number",
      colSpan:2,type : "string" 
    },
    { value: "Violation_Type", label: "Violation Type" ,type : "string" },
    { value: "L2_Code", label: "L2 Code" ,type : "string" },
    {
      value: "Civil_Penalty_Payment_Required",
      label: "Civil Penalty Payment Required",type : "string" 
    },
    { value: "Paid_Under_Job_Number", label: "Paid Under Job Number" ,type : "string" },
    { value: "Invoice_Number", label: "Invoice Number" ,type : "string" },
  ];

  const previousSolarAppHeaders = [
    { value: "Application_Number", label: "Application Number", type : "number" },
    {
      value: "Current_Application_Status",
      label: "Current Application Status", type : "string" 
    },
    {
      value: "Applicant_Supersede_Required",
      label: "Applicant Supersede Required",type : "string" 
    },
    { value: "Current_Applicant", label: "Current Applicant",type : "string"  },
    { value: "GC_Supersede_Required", label: "GC Supersede Required",type : "string"  },
    { value: "GC_Company_Name", label: "GC Company Name",type : "string"  },
    { value: "GC_Name", label: "GC Name",type : "string"  },
    { value: "GC_License_Number", label: "GC License Number", type : "number"  },
    { value: "Reinstatement_Required", label: "Reinstatement Required",type : "string"  },
    { value: "Inspection_Passed", label: "Inspection Passed",type : "string"  },
    { value: "Inspection_Passed_Date", label: "Inspection Passed Date" ,type : "date" },
    { value: "Withdrawal_Required", label: "Withdrawal Required" ,type : "string" },
    { value: "Installation_Complete", label: "Installation Complete" ,type : "string" },
    {
      value: "Inspection_Ready_Email_Address",
      label: "Inspection Ready Email Address",
      flag: "Installation_Complete",
      type : "string" 
    },
  ];


  const arrayFilterValidations = (datas, one, arr) => {
    if (_.isArray(datas)) {
      let isPresent = [];
      datas.forEach((t) => {
        let temp = conditions[one.condition](t, arr) ? true : false;
        isPresent.push(temp);
      });
      let trues = isPresent?.filter((t) => t == true);
      let falses = isPresent?.filter((t) => t == false);
      if (
        one.condition == "is" ||
        one.condition == "ends with" ||
        one.condition == "starts with" ||
        one.condition == "contains"
      ) {
        return trues.length > 0;
      } else if (
        one.condition == "isn't" ||
        one.condition == "doesn't contains"
      ) {
        return !(falses.length > 0);
      }
    } else {
      let na = "N/A";
      let d = datas;
      if (!na.includes(arr) && d.length == 0) {
        return false;
      } else if (na.includes(arr) && d.length == 0) {
        return true;
      } else {
        return conditions[one.condition](datas, arr) ? true : false;
      }
    }
  };
  const bettweenAndNormalChecking = (one, arr, data) => {
    let compareObject = {
      from: one.from,
      to: one.value,
    };
    if (one.condition == "between" || one.condition == "not between") {
      return conditions[one.condition](data[one.api_name], compareObject)
        ? true
        : false;
    } else {
      return conditions[one.condition](arr, data[one.api_name]) ? true : false;
    }
  };
  const getLogicResultDealForDealModule = (data, one) => { 
    try {
      let arr = [];
      if (
        one.value &&
        one.column_name &&
        one.column_name !== "Closing Date" &&
        one.column_name !== "Due Date" &&
        one.column_name !== "Start Date" &&
        one.column_name !== "Inspection Passed Date"
      ) {
        if (one.value && _.isArray(one.value)) {
          arr = one.value && one.value.map((one) => one.name);
        } else {
          arr = one.value;
        }
      } else if (
        !one?.Group &&
        one.condition != "is empty" &&
        one.condition != "is not empty"
      ) {
        if(one.column_name == "Inspection Passed Date" && data?.Solar_Columns){
          return compareDateFilterResult(data?.Solar_Columns[one.api_name], one);
        }
        return compareDateFilterResult(data[one.api_name], one);
      }

      if (
        one?.Group &&
        one?.Group?.filter((cond) => cond?.phase == "completed").length > 0
      ) {
        let conditionalArray = one?.Group;
        conditionalArray = one?.Group?.filter(
          (cond) => cond?.phase == "completed"
        );
        let groupValidationArray = [];
        conditionalArray?.forEach((el, i) => {
          let conditionResult = {
            status: false,
            condition: "",
          };
          conditionResult.status = getLogicResultDealForDealModule(data, el);
          conditionResult.condition = el?.logic;
          groupValidationArray.push(conditionResult);
        });
        let resultOfGroup = getGroupConditionValidation(groupValidationArray);
        return resultOfGroup;
      }
      if (one.api_name == "Project_Status") {
        one.api_name = "Stage";
        return conditions[one.condition](arr, data[one.api_name])
          ? true
          : false;
      }else if (one.api_name == "Application_Number") {
        return conditions[one.condition](arr, data?.Solar_Columns[one.api_name])
          ? true
          : false;
      }
       else if (one.api_name == "PV_Interconnection") {
        return conditions[one.condition](
          arr,
          _?.isArray(data.PV_Interconnection) ? "Provided" : "Required"
        )
          ? true
          : false;
      } else if (one.api_name == "Previous_Solar_Info") {
        if (one.condition == "is empty") {
          if (
            data.Previous_Solar_Application != "N/A" &&
            data.Previous_Solar_Application != "Yes" &&
            data.Previous_Solar_Application != "No"
          ) {
            return true;
          } else {
            return false;
          }
        } else if (one.condition == "is not empty") {
          if (
            data.Previous_Solar_Application != "N/A" &&
            data.Previous_Solar_Application != "Yes" &&
            data.Previous_Solar_Application != "No"
          ) {
            return false;
          } else {
            return true;
          }
        }
        return conditions[one.condition](
          arr,
          data.Previous_Solar_Application == "Yes" &&
            !_.isEmpty(data[one.api_name])
            ? "Provided"
            : data.Previous_Solar_Application == "Yes" &&
              _.isEmpty(data[one.api_name])
            ? "Required"
            : data.Previous_Solar_Application == "No"
            ? "No"
            : "N/A"
        )
          ? true
          : false;
      } else if (one.api_name == "Zoning_District") {
        return arrayFilterValidations(data[one.api_name], one, arr);
      } else if (one.api_name == "Commercial_Overlay") {
        return arrayFilterValidations(data[one.api_name], one, arr);
      } else if (one.api_name == "Specials_Districts") {
        return arrayFilterValidations(data[one.api_name], one, arr);
      }
      if (_.isArray(data[one.api_name])) {
        return conditions[one.condition](arr, data[one.api_name])
          ? true
          : false;
      } 
      // else if (
      //   pvSystemTableHeaders.some((obj) => obj.value == one.api_name)
      // ) {
      //   return data["PV_Interconnection"] &&
      //     data["PV_Interconnection"].some((obj) =>
      //       conditions[one.condition](arr, obj[one.api_name])
      //     )
      //     ? true
      //     : false;
      // } else if (wwwViolationHeaders.some((obj) => obj.value == one.api_name)) {
      //   return data["WWP_Violations"] &&
      //     data["WWP_Violations"].some((obj) =>
      //       conditions[one.condition](arr, obj[one.api_name])
      //     )
      //     ? true
      //     : false;
      // } else if (
      //   previousSolarAppHeaders.some((obj) => obj.value == one.api_name)
      // ) {
      //   return data["Previous_Solar_Info"] &&
      //     data["Previous_Solar_Info"].some((obj) =>
      //       conditions[one.condition](arr, obj[one.api_name])
      //     )
      //     ? true
      //     : false;
      // } 
      
      else if (_.isObject(data[one.api_name])) {
        return data[one.api_name] &&
          conditions[one.condition](arr, data[one.api_name].name)
          ? true
          : false;
      } else if (
        data[one.api_name] ||
        data[one.api_name] == 0 ||
        (data["Violation_Columns"] && Object.keys(data["Violation_Columns"])?.includes(one.api_name)) ||
        (data["Pv_Columns"] && Object.keys(data["Pv_Columns"])?.includes(one.api_name)) ||
        (data["Solar_Columns"] && Object.keys(data["Solar_Columns"])?.includes(one.api_name)) ||
        one.condition == "is empty" ||
        one.condition == "is not empty"
      ) {
        if (one.type == "date") {
          return compareDateFilterResult(data[one.api_name], one);
        } else if (one.api_name == "Stop_Work_Order_Exists_on_Property") {
          return conditions[one.condition](
            arr,
            data[one.api_name] == "Yes"
              ? data.Partial == true
                ? "Partial SWO Exists"
                : "Full SWO Exists"
              : data[one.api_name] == "N/A"
              ? "N/A"
              : "No"
          )
            ? true
            : false;
        } else if (one.api_name == "Service_Requested_Name") {
          let datas = data[one.api_name].split(",");
          let isPresent = [];
          datas.forEach((t) => {
            let temp = conditions[one.condition](arr, t) ? true : false;
            isPresent.push(temp);
          });
          let trues = isPresent?.filter((t) => t == true);
          let falses = isPresent?.filter((t) => t == false);
          if (
            one.condition == "is" ||
            one.condition == "ends with" ||
            one.condition == "starts with" ||
            one.condition == "contains"
          ) {
            return trues.length > 0;
          } else if (
            one.condition == "isn't" ||
            one.condition == "doesn't contains"
          ) {
            return !(falses.length > 0);
          }
        } else if (one.api_name == "Active_Work_Without_Permit_Violations") {
          if (one.condition == "is empty") {
            if (
              data[one.api_name] != "N/A" &&
              data[one.api_name] != "Yes" &&
              data[one.api_name] != "No"
            ) {
              return true;
            } else {
              return false;
            }
          } else if (one.condition == "is not empty") {
            if (
              data[one.api_name] != "N/A" &&
              data[one.api_name] != "Yes" &&
              data[one.api_name] != "No"
            ) {
              return false;
            } else {
              return true;
            }
          }
          return conditions[one.condition](
            arr,
            data[one.api_name] == "Yes" && !_.isEmpty(data.WWP_Violations)
              ? "Provided"
              : data[one.api_name] == "Yes" && _.isEmpty(data.WWP_Violations)
              ? "Required"
              : data[one.api_name] == "No"
              ? "No"
              : "N/A"
          )
            ? true
            : false;
        } else if (
          one.api_name == "Condo_Phone_Number" ||
          one.api_name == "Owner_Phone_Number" ||
          one.api_name == "Other_Owner_Phone_Number"
        ) {
          let no = data[one.api_name]
            ?.replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(" ", "")
            .replaceAll("-", "");
          return conditions[one.condition](no, arr) ? true : false;
        } else if (one.api_name == "Certificate_of_Occupancy_Available") {
          if (data["Asbestos_Required"] == "Yes" && !arr.includes("N/A")) {
            return false;
          }
          return conditions[one.condition](arr, data[one.api_name])
            ? true
            : false;
        } else if (one.api_name == "Bin_Number") {
          return bettweenAndNormalChecking(one, arr, data);
        } else if (one.api_name == "Commercial_Overlay") {
          let modifiedValue = data[one.api_name];
          if (modifiedValue?.trim().length == 0 && arr?.trim() == "N/A") {
            return true;
          } else if (modifiedValue?.trim().length > 0 && arr?.trim() != "N/A") {
            let newArr = arr.splice(",");
            return conditions[one.condition](newArr, data[one.api_name])
              ? true
              : false;
          }
        } else if (one.api_name == "Total_System_Size") {
          return bettweenAndNormalChecking(one, arr, data);
        } else if (one.api_name == "Number_of_Interconnection") {
          return bettweenAndNormalChecking(one, arr, data);
        } else if (one.api_name == "Total_Modules") {
          return bettweenAndNormalChecking(one, arr, data);
        } else if (one.api_name == "Project_Cost") {
          return bettweenAndNormalChecking(one, arr, data);
        } else if (one.api_name == "Conjunction") {
          if (
            data[one.api_name] == "Yes" &&
            (data.Conjunction_Application_Number == "" ||
              data.Conjunction_Application_Number == undefined ||
              data.Conjunction_Application_Number == null)
          ) {
            //data[one.api_name]="(Blank)";
            return conditions[one.condition](arr, "(Blank)") ? true : false;
          } else if (
            data[one.api_name] == "Yes" &&
            data.Conjunction_Application_Number != "" &&
            data.Conjunction_Application_Number != undefined &&
            data.Conjunction_Application_Number != null
          ) {
            return conditions[one.condition](
              arr,
              data["Conjunction_Application_Number"]
            )
              ? true
              : false;
          }

          return conditions[one.condition](arr, data[one.api_name])
            ? true
            : false;
        } else if (
          one.api_name == "Property_Owner_s_Name" &&
          (one.condition == "is empty" || one.condition == "is not empty")
        ) {
          return conditions[one.condition](arr, data[one.api_name])
            ? true
            : false;
        } else {
           
          try {
            if(data["Solar_Columns"] && Object.keys(data["Solar_Columns"])?.includes(one.api_name)){
              return conditions[one.condition](arr, data["Solar_Columns"][one.api_name])
              ? true
              : false;
            }else if(data["Pv_Columns"] && Object.keys(data["Pv_Columns"])?.includes(one.api_name)){
              return conditions[one.condition](arr, data["Pv_Columns"][one.api_name])
              ? true
              : false;
            }else if(data["Violation_Columns"] && Object.keys(data["Violation_Columns"])?.includes(one.api_name)){
              return conditions[one.condition](arr, data["Violation_Columns"][one.api_name])
              ? true
              : false;
            }
          } catch (error) {
            
          }
          return conditions[one.condition](arr, data[one.api_name])
            ? true
            : false;
        }
      }
      if (
        (arr.length == 0 ||
          _.isEmpty(one.condition) ||
          _.isNull(one.condition)) &&
        !one?.Group
      ) {
        return true;
      }
      // if (one.column_name == 'Account Name') {
      //     return data.Account_Name && conditions[one.condition](arr, data.Account_Name) ? true : false;
      // }
      // if (one.column_name == 'Created By') {
      //     return data.Created_By && conditions[one.condition](arr, data.Created_By.name) ? true : false;
      // }
      // if (one.column_name == 'Closing Date') {
      //     if (one.condition == 'is') {
      //         return compareDateFilterResult(data.Closing_Date, one);
      //     }
      //     if (one.condition == 'is not') {
      //         return !compareDateFilterResult(data.Closing_Date, one);
      //     }
      // }
      // if (one.column_name == 'Borough') {
      //     return conditions[one.condition](arr, data.Borough) ? true : false;
      // }
      // if (one.column_name == 'NYC Project') {
      //     return conditions[one.condition](arr, data.NYC_Project) ? true : false;
      // }
      // if (one.column_name == 'NYC Project') {
      //     return conditions[one.condition](arr, data.NYC_Project) ? true : false;
      // }

      // return { tasks: tasks, deals: deals, logic_result: logic_result };
    } catch (err) {}
  };

  const getLogicResultDeal = (data, one) => {
    let arr = [];
    if (
      one.value &&
      one.column_name !== "Closing Date" &&
      one.column_name !== "Due Date" &&
      one.column_name !== "Start Date"
    ) {
      if (one.value && _.isArray(one.value)) {
        arr = one.value && one.value.map((one) => one.name);
      } else {
        arr = one.value;
      }
    }
    if (
      one?.Group &&
      one?.Group?.filter((cond) => cond?.phase == "completed").length > 0
    ) {
      let conditionalArray = one?.Group;
      conditionalArray = one?.Group?.filter(
        (cond) => cond?.phase == "completed"
      );
      var i = 0;
      for (const el of conditionalArray) {
        let filterDeal = Deals?.filter(
          (one) => data.project && one.Project_ID == data.project.id_string
        );
        if (filterDeal.length > 0) {
          filterDeal = filterDeal[0];
        } else {
          filterDeal = {};
        }

        if (conditionalArray.length == 1) {
          if (conditionalArray[0].module == "Deal") {
            return getLogicResultDeal(filterDeal, conditionalArray[0]);
          } else {
            return getLogicResult(data, conditionalArray[0]);
          }
        }

        if (i == 0) {
          if (conditionalArray[i + 1].logic == "OR") {
            let res1;
            let res2;
            if (conditionalArray[i + 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            }
            if (!(res1 || res2)) {
              return false;
            }
          } else {
            let res1;
            let res2;
            if (conditionalArray[i + 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            }

            if (!(res1 && res2)) {
              return false;
            }
          }
        } else {
          if (el.logic == "OR") {
            let res1;
            let res2;
            if (conditionalArray[i - 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            }
            if (!(res1 || res2)) {
              return false;
            }
          } else {
            let res1;
            let res2;
            if (conditionalArray[i - 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            }

            if (!(res1 && res2)) {
              return false;
            }
          }
        }
        i++;
      }
      let final = Deals?.filter(
        (one) => data.project && one.Project_ID == data.project.id_string
      );
      dealsResult = [...final, ...dealsResult];
      dealsResult = _.uniqBy(dealsResult, function (e) {
        return e.id;
      });
      return true;
    }
    if (_.isArray(data[one.api_name])) {
      return conditions[one.condition](arr, data[one.api_name]) ? true : false;
    } 
    // else if (pvSystemTableHeaders.some((obj) => obj.value == one.api_name)) {
    //   return data["PV_Interconnection"] &&
    //     data["PV_Interconnection"].some((obj) =>
    //       conditions[one.condition](arr, obj[one.api_name])
    //     )
    //     ? true
    //     : false;
    // } else if (wwwViolationHeaders.some((obj) => obj.value == one.api_name)) {
    //   return data["WWP_Violations"] &&
    //     data["WWP_Violations"].some((obj) =>
    //       conditions[one.condition](arr, obj[one.api_name])
    //     )
    //     ? true
    //     : false;
    // } else if (
    //   previousSolarAppHeaders.some((obj) => obj.value == one.api_name)
    // ) {
    //   return data["Previous_Solar_Info"] &&
    //     data["Previous_Solar_Info"].some((obj) =>
    //       conditions[one.condition](arr, obj[one.api_name])
    //     )
    //     ? true
    //     : false;
    // } 
    
    else if (_.isObject(data[one.api_name])) {
      return data[one.api_name] &&
        conditions[one.condition](arr, data[one.api_name].name)
        ? true
        : false;
    } else {
      if (one.type == "date") {
        return compareDateFilterResult(data[one.api_name], one);
      } else {
        return conditions[one.condition](arr, data[one.api_name])
          ? true
          : false;
      }
    }
    // if (one.column_name == 'Account Name') {
    //     return data.Account_Name && conditions[one.condition](arr, data.Account_Name) ? true : false;
    // }
    // if (one.column_name == 'Created By') {
    //     return data.Created_By && conditions[one.condition](arr, data.Created_By.name) ? true : false;
    // }
    // if (one.column_name == 'Closing Date') {
    //     if (one.condition == 'is') {
    //         return compareDateFilterResult(data.Closing_Date, one);
    //     }
    //     if (one.condition == 'is not') {
    //         return !compareDateFilterResult(data.Closing_Date, one);
    //     }
    // }
    // if (one.column_name == 'Borough') {
    //     return conditions[one.condition](arr, data.Borough) ? true : false;
    // }
    // if (one.column_name == 'NYC Project') {
    //     return conditions[one.condition](arr, data.NYC_Project) ? true : false;
    // }
    // if (one.column_name == 'NYC Project') {
    //     return conditions[one.condition](arr, data.NYC_Project) ? true : false;
    // }

    // return { tasks: tasks, deals: deals, logic_result: logic_result };
  };

  const getLogicResult = (data, one) => {
    // if ((one.module == 'Deal')&&(data.module=='Deal')) {
    //     return getLogicResultDeal(data, one)
    // }else if((one.module == 'Task')&&(data.module=='Task')){
    let arr = [];
    if (
      one.value &&
      one.column_name !== "Due Date" &&
      one.column_name !== "Start Date"
    ) {
      if (one.column_name == "Assignee") {
        if (isArray(one.value)) {
          arr = one.value && one.value.map((one) => one.email);
        } else {
          arr = one.value;
        }
      } else {
        if (isArray(one.value)) {
          arr = one.value && one.value.map((one) => one.name);
        } else {
          arr = one.value;
        }
      }
    }
    if (
      one?.Group &&
      one?.Group?.filter((cond) => cond?.phase == "completed").length > 0
    ) {
      let conditionalArray = one?.Group;
      conditionalArray = one?.Group?.filter(
        (cond) => cond?.phase == "completed"
      );

      var i = 0;
      for (const el of conditionalArray) {
        let filterDeal = Deals?.filter(
          (one) => data.project && one.Project_ID == data.project.id_string
        );
        if (filterDeal.length > 0) {
          filterDeal = filterDeal[0];
        } else {
          filterDeal = {};
        }

        if (conditionalArray.length == 1) {
          if (conditionalArray[0].module == "Deal") {
            return getLogicResultDeal(filterDeal, conditionalArray[0]);
          } else {
            return getLogicResult(data, conditionalArray[0]);
          }
        }

        if (i == 0) {
          if (conditionalArray[i + 1].logic == "OR") {
            let res1;
            let res2;
            if (conditionalArray[i + 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            }
            if (!(res1 || res2)) {
              return false;
            }
          } else {
            let res1;
            let res2;
            if (conditionalArray[i + 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i + 1]);
            }

            if (!(res1 && res2)) {
              return false;
            }
          }
        } else {
          if (el.logic == "OR") {
            let res1;
            let res2;
            if (conditionalArray[i - 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            }
            if (!(res1 || res2)) {
              return false;
            }
          } else {
            let res1;
            let res2;
            if (conditionalArray[i - 1].module == "Deal") {
              res1 = getLogicResult(data, el);
              res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1]);
            } else if (el.module == "Deal") {
              res1 = getLogicResultDeal(filterDeal, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            } else {
              res1 = getLogicResult(data, el);
              res2 = getLogicResult(data, conditionalArray[i - 1]);
            }

            if (!(res1 && res2)) {
              return false;
            }
          }
        }
        i++;
      }
      let final = Deals?.filter(
        (one) => data.project && one.Project_ID == data.project.id_string
      );
      dealsResult = [...final, ...dealsResult];
      dealsResult = _.uniqBy(dealsResult, function (e) {
        return e.id;
      });
      return true;
    }

    if (one.column_name == "Pipeline") {
      return data.custom_fields &&
        data.custom_fields.length > 0 &&
        data.custom_fields?.filter((ow) => {
          if (
            ow.label_name == "Pipeline" &&
            conditions[one.condition](arr, ow.value)
          ) {
            return ow;
          }
        }).length > 0
        ? true
        : false;
    }
    if (one.column_name == "Category") {
      return data.custom_fields &&
        data.custom_fields.length > 0 &&
        data.custom_fields?.filter((ow) => {
          if (
            ow.label_name == "Category" &&
            conditions[one.condition](arr, ow.value)
          ) {
            return ow;
          }
        }).length > 0
        ? true
        : false;
    }
    if (one.column_name == "Task Name") {
      return data.name && conditions[one.condition](arr, data.name)
        ? true
        : false;
    }
    if (one.column_name == "Status") {
      let res =
        data.status && conditions[one.condition](arr, data.status.name)
          ? true
          : false;
      return res;
    }
    if (one.column_name == "Milestone") {
      let res =
        data.tasklist && conditions[one.condition](arr, data.tasklist.name)
          ? true
          : false;
      return res;
    }
    if (one.column_name == "Priority") {
      return data.priority && conditions[one.condition](arr, data.priority)
        ? true
        : false;
      // tasks = tasks?.filter(task => task.priority && one.value.includes(task.priority))
    }
    if (one.column_name == "Assignee") {
      return data.details &&
        data.details.owners &&
        data.details.owners.length > 0 &&
        data.details.owners?.filter((ow) => {
          if (conditions[one.condition](arr, ow.email)) {
            return ow;
          }
        }).length > 0
        ? true
        : false;
    }

    if (one.column_name == "Supervisor") {
      return data.custom_fields &&
        data.custom_fields.length > 0 &&
        data.custom_fields?.filter((ow) => {
          if (
            ow.label_name == "Supervisor" &&
            conditions[one.condition](arr, ow.users)
          ) {
            let filterUser = ow.users?.filter((user) =>
              conditions[one.condition](arr, user.email)
            );
            if (filterUser.length > 0) {
              return ow;
            }
          }
        }).length > 0
        ? true
        : false;
    }
    if (one.column_name == "Created By") {
      return conditions[one.condition](arr, data.created_person) ? true : false;
    }
    if (one.column_name == "Due Date") {
      if (one.condition == "is" || one.condition == "is set") {
        return compareDateFilterResult(data.end_date, one);
      }
      if (one.condition == "isnt" || one.condition == "isnt") {
        return !compareDateFilterResult(data.end_date, one);
      }
    }
    if (one.column_name == "Start Date") {
      if (one.condition == "is" || one.condition == "is set") {
        return compareDateFilterResult(data.start_date, one);
      }
      if (one.condition == "isnt" || one.condition == "is not set") {
        return !compareDateFilterResult(data.start_date, one);
      }
    }

    // }
    // else{
    //     return true;
    // }
  };

  //new method to get condition result
  const getGroupConditionValidation = (conditionResult) => {
    let result = conditionResult[0]?.status;
    conditionResult.forEach((c, i) => {
      if (i != 0) {
        if (c?.condition == "OR") {
          result = result || c?.status;
        } else if (c?.condition == "AND") {
          result = result && c?.status;
        }
      }
    });
    return result;
  };

  const getFilterResultDeal = (taskFilters) => {
    let conditionalArray = [...taskFilters];
    let tasks = [];
    let deals = [...dealData];
    let dataToFilter = deals;
    let result = [];
    conditionalArray = conditionalArray?.filter(
      (one) => one?.phase == "completed" || one?.Group
    );
    if (conditionalArray.length == 1) {
      let shouldAdd = shouldWeAddFilters(filters);
      if (!shouldAdd) {
        setErrorMsg("Please complete above filter.");
      } else {
        setErrorMsg(null);
      }
      result = deals?.filter((one) =>
        getLogicResultDealForDealModule(one, conditionalArray[0])
      );
    } else {
      let shouldAdd = shouldWeAddFilters(filters);
      if (!shouldAdd) {
        setErrorMsg("Please complete above filter.");
      } else {
        setErrorMsg(null);
      }
      result = dataToFilter?.filter((data) => {
        let groupValidationArray = [];
        conditionalArray?.forEach((el, i) => {
          let conditionResult = {
            status: false,
            condition: "",
          };
          conditionResult.status = getLogicResultDealForDealModule(data, el);
          conditionResult.condition = el?.logic;
          groupValidationArray.push(conditionResult);
        });
        let resultOfGroup = false;
        resultOfGroup = getGroupConditionValidation(groupValidationArray);
        return resultOfGroup;
      });
    }
    // getReturnData({ deals: result, tasks: [] });
    return { deals: result, tasks: [] };
  };

  const getFilterResult = (taskFilters) => {
    let conditionalArray = [...taskFilters];
    let tasks = [...taskData];
    let deals = [...dealData];
    // tasks.forEach((one) => one.module = 'Task');
    // deals.forEach((one) => one.module = 'Deal')
    let dataToFilter = tasks;
    // tasks = [...tasks];
    // deals = [...deals];
    let returnDeals = [];
    let result = [];
    conditionalArray = conditionalArray?.filter(
      (one) => one?.phase == "completed" || one?.Group
    );
    if (conditionalArray.length == 1) {
      if (conditionalArray[0].module == "Deal") {
        dealsResult = deals?.filter((one) =>
          getLogicResultDeal(one, conditionalArray[0])
        );
      } else {
        dealsResult = deals;
        result = tasks?.filter((one) =>
          getLogicResult(one, conditionalArray[0])
        );
      }
    } else {
      result = dataToFilter?.filter((data) => {
        var i = 0;

        for (const el of conditionalArray) {
          let filterDeal = deals?.filter(
            (one) => data.project && one.Project_ID == data.project.id_string
          );
          if (filterDeal.length > 0) {
            filterDeal = filterDeal[0];
          } else {
            filterDeal = {};
          }

          if (i == 0) {
            if (conditionalArray[i + 1].logic == "OR") {
              let res1;
              let res2;
              if (conditionalArray[i + 1].module == "Deal") {
                res1 = getLogicResult(data, el);
                res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1]);
              } else if (el.module == "Deal") {
                res1 = getLogicResultDeal(filterDeal, el);
                res2 = getLogicResult(data, conditionalArray[i + 1]);
              } else {
                res1 = getLogicResult(data, el);
                res2 = getLogicResult(data, conditionalArray[i + 1]);
              }
              if (!(res1 || res2)) {
                return false;
              }
            } else {
              let res1;
              let res2;
              if (conditionalArray[i + 1].module == "Deal") {
                res1 = getLogicResult(data, el);
                res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1]);
              } else if (el.module == "Deal") {
                res1 = getLogicResultDeal(filterDeal, el);
                res2 = getLogicResult(data, conditionalArray[i + 1]);
              } else {
                res1 = getLogicResult(data, el);
                res2 = getLogicResult(data, conditionalArray[i + 1]);
              }

              if (!(res1 && res2)) {
                return false;
              }
            }
          } else {
            if (el.logic == "OR") {
              let res1;
              let res2;
              if (conditionalArray[i - 1].module == "Deal") {
                res1 = getLogicResult(data, el);
                res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1]);
              } else if (el.module == "Deal") {
                res1 = getLogicResultDeal(filterDeal, el);
                res2 = getLogicResult(data, conditionalArray[i - 1]);
              } else {
                res1 = getLogicResult(data, el);
                res2 = getLogicResult(data, conditionalArray[i - 1]);
              }
              if (!(res1 || res2)) {
                return false;
              }
            } else {
              let res1;
              let res2;
              if (conditionalArray[i - 1].module == "Deal") {
                res1 = getLogicResult(data, el);
                res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1]);
              } else if (el.module == "Deal") {
                res1 = getLogicResultDeal(filterDeal, el);
                res2 = getLogicResult(data, conditionalArray[i - 1]);
              } else {
                res1 = getLogicResult(data, el);
                res2 = getLogicResult(data, conditionalArray[i - 1]);
              }

              if (!(res1 && res2)) {
                return false;
              }
            }
          }
          i++;
        }
        let final = deals?.filter(
          (one) => data.project && one.Project_ID == data.project.id_string
        );
        dealsResult = [...final, ...dealsResult];
        dealsResult = _.uniqBy(dealsResult, function (e) {
          return e.id;
        });
        return true;
      });
    }

    return { tasks: result };
  };

  const getMiddleLastInput = (obj) => {
     
    let returnArr = [];
    switch (obj.column_name) {
      case "Milestone":
        // if(status){
        //     returnArr= status
        // }
        let tasks1 = myTasks;
        returnArr = (() => {
          let arr = [];
          if (tasks1) {
            tasks1.forEach((one) =>
              arr.push({
                name: one.tasklist.name,
                color: one.color,
                id: one.milestone_id,
              })
            );
          }
          return _.uniqBy(arr, "name");
        })();
        break;
      case "Task Name":
        // if(status){
        //     returnArr= status
        // }
        let tasks = myTasks;
        returnArr = (() => {
          let arr = [];
          if (status) {
            tasks.forEach((one) =>
              arr.push({ name: one.name, color: one.color, id: one.id_string })
            );
          }
          return arr;
        })();
        break;
      case "Category":
        // if(status){
        //     returnArr= status
        // }
        let categroy = categorys;
        returnArr = (() => {
          let arr = [];
          if (status) {
            categroy.forEach((one) =>
              arr.push({
                name: one.CategoryName,
                color: one.color,
                id: one._id,
              })
            );
          }
          return arr;
        })();
        break;
      case "Pipeline":
        let pipes = pipelines;
        // pipes.data.map((one, i) => {
        //     pipelines.push({ 'name': one.PipelineName, '_id': one._id, 'category': one.Category });
        //   })
        returnArr = (() => {
          let arr = [];
          if (status) {
            pipes.forEach((one) =>
              arr.push({
                name: one.PipelineName,
                color: one.color,
                id: one._id,
              })
            );
          }
          return arr;
        })();
        break;
      case "Status":
        // if(status){
        //     returnArr= status
        // }
        returnArr = (() => {
          let arr = [];
          if (status) {
            status.forEach((one) =>
              arr.push({ name: one.name, color: one.color, id: one.id })
            );
          }
          return arr;
        })();
        break;
      case "Priority":
        // if(priorityValues){
        //     returnArr= priorityValues
        // }
        returnArr = (() => {
          let arr = [];
          if (priorityValues) {
            priorityValues.forEach((one) =>
              arr.push({ name: one.name, color: one.color })
            );
          }
          return arr;
        })();
        break;
      case "Assignee":
        // if(users){
        //     returnArr= users
        // }
        users = users?.filter((one) => one.status == "active");
        returnArr = (() => {
          let arr = [];
          if (users) {
            users.forEach((one) =>
              arr.push({
                name: one.first_name + " " + one.last_name,
                id: one.zuid,
                email: one.email,
              })
            );
          }
          return arr;
        })();
        break;
      case "Supervisor":
        // if(users){
        //     returnArr= users
        // }
        users = users?.filter((one) => one.status == "active");
        returnArr = (() => {
          let arr = [];
          if (users) {
            users.forEach((one) =>
              arr.push({
                name: one.first_name + " " + one.last_name,
                id: one.zuid,
                email: one.email,
              })
            );
          }
          return arr;
        })();
        break;
      case "Created By":
        // if(users){
        //     returnArr= users
        // }
        users = users?.filter((one) => one.status == "active");
        returnArr = (() => {
          let arr = [];
          if (users) {
            users.forEach((one) =>
              arr.push({
                name: one.first_name + " " + one.last_name,
                id: one.zuid,
                email: one.email,
              })
            );
          }
          return arr;
        })();

        break;
    }
    return returnArr;
  };

  const getListOfNumbersForTypeNumber = (one) => {
    const deals = [...dealData];
    const tasks = [...taskData];
     
    let parent;
    if( pvSystemTableHeaders.find(o=>o.value==one.api_name)){
      parent="Pv_Columns"
    }else if(wwwViolationHeaders.find(o=>o.value==one.api_name)){
      parent="Violation_Columns"
    }else if(previousSolarAppHeaders.find(o=>o.value==one.api_name)){
      parent="Solar_Columns"
    }
    let all = deals.map((data) => {
      if (_.isArray(data[one.api_name])) {
        return { name: data[one.api_name], id: data[one.api_name] };
      }
      // else if (pvSystemTableHeaders.some(obj => obj.value == one.api_name)) {
      //     return data["PV_Interconnection"] && data["PV_Interconnection"].some(obj => conditions[one.condition](arr, obj[one.api_name])) ? true : false
      // }
      // else if (wwwViolationHeaders.some(obj => obj.value == one.api_name)) {
      //     return data["WWP_Violation"] && data["WWP_Violation"].some(obj => conditions[one.condition](arr, obj[one.api_name])) ? true : false
      // }
      // else if (previousSolarAppHeaders.some(obj => obj.value == one.api_name)) {
      //     return data["Previous_Solar_App"] && data["Previous_Solar_App"].some(obj => conditions[one.condition](arr, obj[one.api_name])) ? true : false
      // }
      else if (_.isObject(data[one.api_name])) {
        if (data[one.api_name]) {
          return { name: data[one.api_name].name, id: data[one.api_name].name };
        }
      } else if (data[one.api_name]) {
        return { name: data[one.api_name], id: data[one.api_name] };
      }else if(parent && data[parent]){
        return { name: data[parent][one.api_name], id: data[parent][one.api_name] };
      }
    });
    all = all?.filter((one) => one != undefined);
    return all;
  };

  // let dummycitynames=[];
  //let dummystatenames=[];
  const getMiddleLastInputForDeal = (obj) => {
     
    let returnArr = [];
    switch (obj.column_name) {
      case "Deal Name":
        if (Deals) {
          returnArr = (() => {
            let arr = [];
            if (status) {
              Deals.forEach((one) =>
                arr.push({ name: one.Deal_Name, color: one.color, id: one.id })
              );
            }
            return arr;
          })();
        }
        break;
      case "Borough":
        let borough = [
          "Manhattan",
          "Bronx",
          "Brooklyn",
          "Queens",
          "Staten Island",
          "N/A",
        ];
        returnArr = (() => {
          let arr = [];
          if (status) {
            borough.forEach((one) => arr.push({ name: one, id: one }));
          }
          return arr;
        })();
        break;
      case "Account Name":
        let categroy = allClient?.filter((one) => one.Account_Name !== "All");
        returnArr = (() => {
          let arr = [];
          if (status) {
            categroy.forEach((one) => {
              let color = one.Color ? one.Color : "#5b6c97";
              arr.push({ name: one.Account_Name, color: color, id: one.id });
            });
          }
          return arr;
        })();
        break;
      case "Pipeline":
        let pipes = pipelines;
        // pipes.data.map((one, i) => {
        //     pipelines.push({ 'name': one.PipelineName, '_id': one._id, 'category': one.Category });
        //   })
        returnArr = (() => {
          let arr = [];
          if (status) {
            pipes.forEach((one) =>
              arr.push({
                name: one.PipelineName,
                color: one.color,
                id: one._id,
              })
            );
          }
          return arr;
        })();
        break;
      case "Project Status":
        // if(status){
        //     returnArr= status
        // }
        returnArr = (() => {
          let arr = [];
          if (dealStatus) {
            dealStatus.forEach((one) =>
              arr.push({
                name: one.display_value,
                color: one.colour_code,
                id: one.id,
              })
            );
          }
          return arr;
        })();
        break;
      case "Priority":
        // if(priorityValues){
        //     returnArr= priorityValues
        // }
        returnArr = (() => {
          let arr = [];
          if (priorityValues) {
            priorityValues.forEach((one) =>
              arr.push({ name: one.name, color: one.color })
            );
          }
          return arr;
        })();
        break;
      case "Assignee":
        // if(users){
        //     returnArr= users
        // }
        users = users?.filter((one) => one.status == "active");
        returnArr = (() => {
          let arr = [];
          if (users) {
            users.forEach((one) =>
              arr.push({
                name: one.first_name + " " + one.last_name,
                id: one.zuid,
              })
            );
          }
          return arr;
        })();
        break;
      case "Created By":
        returnArr = (() => {
          users = [];
          let userTemp = [];
          dealData.forEach((d, i) => {
            if (!userTemp.includes(d.Created_By)) {
              userTemp.push(d.Created_By);
              users.push({
                name: d.Created_By,
                id: i,
              });
            }
          });

          return users;
        })();

        break;
      case "Owner Type":
        returnArr = (() => {
          let arr = [
            { name: "INDIVIDUAL", id: "1" },
            { name: "CORPORATION", id: "2" },
            { name: "PARTNERSHIP", id: "3" },
            { name: "CONDO/CO-OP", id: "4" },
            { name: "OTHER GOVERNMENT AGENCY", id: "5" },
            { name: "NYC AGENCY", id: "6" },
            { name: "NYCHA/HHC", id: "7" },
          ];
          return arr;
        })();

        break;
      case "Service Requested Name":
        returnArr = (() => {
          let arr = [];
          allServices?.forEach((one) => {
            arr.push({ name: one.Name, id: one.id });
          });
          return arr;
        })();

        break;
      case "City":
        returnArr = (() => {
          let arr = [];
          let dummycitynames = [];
          Deals?.forEach((one, index) => {
            if (
              !dummycitynames.includes(one.City) &&
              !_.isNull(one.City) &&
              !_.isEmpty(one.City)
            ) {
              dummycitynames.push(one.City);
              arr.push({ name: one.City, id: index });
            }
          });
          return arr;
        })();

        break;
      case "State":
        returnArr = (() => {
          let arr = [];
          let dummystatenames = [];
          Deals?.forEach((one, index) => {
            if (
              !dummystatenames.includes(one.State) &&
              !_.isNull(one.State) &&
              !_.isEmpty(one.State)
            ) {
              dummystatenames.push(one.State);
              arr.push({ name: one.State, id: index });
            }
          });
          return arr;
        })();

        break;
      case "Invertor Type":
        returnArr = (() => {
          let arr = [
            { name: "Micro", id: "1" },
            { name: "String", id: "2" },
          ];
          return arr;
        })();

        break;
      case "Active Work Without Permit Violations":
        returnArr = (() => {
          let arr = [
            { name: "Provided", id: "1" },
            { name: "Required", id: "2" },
            { name: "No", id: "3" },
            { name: "N/A", id: "4" },
          ];
          return arr;
        })();

        break;
      case "Previous Solar Info":
        returnArr = (() => {
          let arr = [
            { name: "Provided", id: "1" },
            { name: "Required", id: "2" },
            { name: "No", id: "3" },
            { name: "N/A", id: "4" },
          ];
          return arr;
        })();

        break;
      case "PV Interconnection":
        returnArr = (() => {
          let arr = [
            { name: "Provided", id: "1" },
            { name: "Required", id: "2" },
          ];
          return arr;
        })();

        break;
      case "Occupancy Classification of Building":
        returnArr = (() => {
          let arr = [...optionsForOccupancyOfBuilding];
          return arr;
        })();

        break;
        case "Application Number":
        if (Deals) {
          returnArr = (() => {
            let arr = [];
            if (status) {
              Deals.forEach((one,ind) =>
                arr.push({ name: one?.Solar_Columns?.Application_Number, id: ind })
              );
            }
            return arr;
          })();
        }
        break;
        case "Violation Type":
        if (Deals) {
          returnArr = (() => {
            let arr = [];
            if (status) {
              Deals.forEach((one,ind) =>{
                if(one.Violation_Columns?.Violation_Type){
                  arr.push({ name: one?.Violation_Columns?.Violation_Type, id: ind })
                }
            });
            }
            arr = _.uniqBy(arr, "name");
            return arr;
          })();
        }
        break;
        case "L2 Code":
          if (Deals) {
            returnArr = (() => {
              let arr = [];
              if (status) {
                Deals.forEach((one,ind) =>{
                  if(one.Violation_Columns?.L2_Code){
                    arr.push({ name: one?.Violation_Columns?.L2_Code, id: ind })
                  }
              });
              }
              arr = _.uniqBy(arr, "name");
              return arr;
            })();
          }
          break;
    }
    return returnArr;
  };

  const getWitch = (index, obj, type) => {
    if (type == "module") {
      if (mode == "Task" || mode == "Deal") {
        return <></>;
      }
      return (
        <Space direction="vertical" className="switchBtn">
          <Switch
            style={{ backgroundColor: "rgb(1, 148, 247)" }}
            checked={obj.module == "Task"}
            checkedChildren={"Task"}
            unCheckedChildren={"Deal"}
            onChange={(checked, event) => {
              let list = [...filters];
              list[index].module = checked ? "Task" : "Deal";
              setFilters(list);
            }}
          />
        </Space>
      );
    } else {
      return (
        <Space direction="vertical">
          <Switch
            checked={obj.logic == "AND"}
            checkedChildren={"AND"}
            unCheckedChildren={"OR"}
            onChange={(checked, event) => {
              let list = [...filters];
              list[index].logic = checked ? "AND" : "OR";
              setFilters(list);
            }}
          />
        </Space>
      );
    }
  };

  const getGroupSwitch = (objIndex, index, obj, type) => {
    if (type == "module") {
      if (mode == "Task" || mode == "Deal") {
        return <></>;
      }
      return (
        <Space direction="vertical">
          <Switch
            style={{ backgroundColor: "rgb(1, 148, 247)" }}
            checked={obj.module == "Task"}
            checkedChildren={"Task"}
            unCheckedChildren={"Deal"}
            onChange={(checked, event) => {
              let list = [...filters];
              if (list[objIndex].Group) {
                list[objIndex].Group[index].module = checked ? "Task" : "Deal";
                setFilters(list);
              }
            }}
          />
        </Space>
      );
    } else {
      return (
        <Space direction="vertical" className="global">
          <Switch
            checked={obj.logic == "AND"}
            checkedChildren={"AND"}
            unCheckedChildren={"OR"}
            onChange={(checked, event) => {
              let list = [...filters];
              if (list[objIndex].Group) {
                list[objIndex].Group[index].logic = checked ? "AND" : "OR";
                setFilters(list);
              }
            }}
          />
        </Space>
      );
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          {props.value !== "all" ? (
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />
          ) : (
            <></>
          )}{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const getDatePickerValue = (index) => {
    try {
      let d = date.find((t, i) => i == index);
      if (d && d?.date[0] && d.type == "date") {
        return dayjs(new Date(d?.date[0]));
      } else {
        return null;
      }
    } catch (error) {}
  };

  const getDatePickerValueOfGroup = (index, groupIdx) => {
    let d = date.find((t, i) => i == index);
    if (d) {
      let group = d?.Group[groupIdx];
      if (group && group.type == "date" && group?.date && group?.date[0]) {
        return dayjs(new Date(group?.date[0]));
      } else {
        return null;
      }
    }
    return null;
  };

  const removeDatePickerValue = (d) => {
    setDate([...d]);
  };
  const setDatePickerValue = (list) => {
    setDate([...list]);
  };
  const getDateRangePickerValue = (index, k, groupIdx, isGroup) => {
    let d = dateRange?.find((t, i) => i == index);
    if (isGroup) {
      let dg = d?.Group?.find((t, i) => i == groupIdx);
      if (dg && dg.type == "date" && dg?.date) {
        let temp = dg.date?.slice(k);
        if (k == 0) {
          temp = dg.date[0];
        } else {
          temp = dg.date[dg.date.length - 1];
        }
        return new Date(temp);
      } else {
        return new Date();
      }
    } else {
      if (d && d.type == "date" && d?.date) {
        let temp = d.date?.slice(k);
        if (k == 0) {
          temp = d.date[0];
        } else {
          temp = d.date[d.date.length - 1];
        }
        return new Date(temp);
      } else {
        return new Date();
      }
    }
  };
  const setDateRangePickerValue = (list) => {
    setDateRange([...list]);
  };

  const removeTile = (id, filterIndex, groupIdx, e) => {
    e.stopPropagation();
    let list = [...filters];
    if (list[filterIndex] && list[filterIndex].Group) {
      list[filterIndex].Group[groupIdx].value = list[filterIndex].Group[
        groupIdx
      ].value?.filter((one) => one.id !== id);
      list[filterIndex].Group[groupIdx].phase = "completed";
      setFilters(list);
    } else {
      list[filterIndex].value = list[filterIndex].value?.filter(
        (one) => (one.id !== id && one.id!=0)
      );
      list[filterIndex].phase = "completed";
      setFilters(list);
    }
  };

  const updatedgetMiddleLastInputForDeal=(obj)=>{
     
    let newdata=getMiddleLastInputForDeal(obj);
    if(obj.api_name !=="Project_Status" && obj.api_name !== "Stage"){
      newdata = newdata.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      }
    newdata.unshift({ name: "Select All", color: "#fff", id: "0" })
    return newdata;
  }

  const getLastInputType = (obj, index) => { 
       
    if (obj.type == "string") {
      if (dropdownFields.includes(obj.column_name)) {
        if (obj.condition == "is" || obj.condition == "isn't") {
          return (
            <CustomMultiSelect
              setOpendDropDowns={setOpendDropDowns}
              invertColor={invertColor}
              opendDropdowns={opendDropdowns}
              id={"checkboxes" + index}
              filterIndex={index}
              removeTile={removeTile}
              values={obj.value}
              onChange={(checked, event, optionIdx) => {
                if (obj.value) {
                  if (checked) {
                    let list = [...filters];
                    let data= updatedgetMiddleLastInputForDeal(obj)
                    if(event.name=="Select All"){
                      list[index].value=[];
                      data.map((abc)=>{
                        list[index].value.push(abc);
                        list[index].phase = "completed";
                      })
                     }else{
                    list[index].value.push(event);
                    list[index].phase = "completed";
                    if(list[index].value.length==data.length-1){
                      list[index].value.push({ name: "Select All", color: "#fff", id: "0" })
                    }
                  }
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    if(event.name=="Select All"){        
                        list[index].value=[];
                        list[index].phase = "middle";                      
                     }else{
                       list[index].value = list[index].value?.filter(
                         (one) => one.name !== event.name && one.name!=="Select All"
                       );
                       list[index].phase = "completed";
                       if (list[index].value.length == 0) {
                         list[index].phase = "middle";
                    }
                  }
                    setFilters(list);
                  }
                } else {
                  if (checked) {
                    let list = [...filters];
                    if(event.name=="Select All"){
                      list[index].value=[];
                      let data= updatedgetMiddleLastInputForDeal(obj)
                      data.map((abc)=>{
                        list[index].value.push(abc);
                        list[index].phase = "completed";
                      })
                     }else{
                    list[index].value = [event];
                    list[index].phase = "completed";
                    }
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    list[index].value = null;
                    list[index].phase = "middle";
                    setFilters(list);
                  }
                }
              }}
              icon={obj.column_name}
              options={
                obj.module == "Deal"
                  ? updatedgetMiddleLastInputForDeal(obj)
                  : getMiddleLastInput(obj)
              }
            />
          );
        } else {
          return (
            <input
              style={{ height: "35px" }}
              className="form-control form-control-sm"
              type={
                obj.condition == "is empty" || obj.condition == "is not empty"
                  ? "hidden"
                  : "text"
              }
              id={"checkboxes" + index}
              value={obj.value}
              onChange={(e) => {
                if (e.currentTarget.value) {
                  let list = [...filters];
                  list[index].value = e.currentTarget.value;
                  list[index].phase = "completed";
                  setFilters(list);
                } else {
                  let list = [...filters];
                  list[index].phase = "middle";
                  list[index].value = e.currentTarget.value;
                  setFilters(list);
                }
              }}
            />
          );
        }
      } else {
        let listx = [...filters];
        return (
          <input
            style={{ height: "35px" }}
            className="form-control form-control-sm"
            type={
              obj.condition == "is empty" || obj.condition == "is not empty"
                ? "hidden"
                : "text"
            }
            id={"checkboxes" + index}
            value={listx[index].value}
            onChange={(e) => {
              if (e.currentTarget.value) {
                let list = [...filters];
                list[index].value = e.currentTarget.value;
                list[index].phase = "completed";
                setFilters(list);
              } else {
                let list = [...filters];
                list[index].phase = "middle";
                list[index].value = e.currentTarget.value;
                setFilters(list);
              }
            }}
          />
        );
      }
    } else if (obj.type == "date") {
      if (
        filters[index] &&
        (filters[index].value == "Before Date" ||
          filters[index].value == "Select A Date" ||
          filters[index].value == "After Date")
      ) {
        return (
          <>
            <div className="row">
              <div className="col-sm-5">
                <CustumSelect
                  addFlag={false}
                  placeholder={"Select Option"}
                  value={obj.value ? obj.value : ""}
                  onChange={(value) => {
                    let list = [...filters];
                    let newList = [];
                    list?.forEach((t) => {
                      if (!newList.includes(t)) {
                        newList.push(t);
                      }
                    });
                    list = newList;
                    list[index].value = value;
                    // list[index].phase = "completed";
                    setFilters(list);
                  }}
                  items={(() => {
                    let arr = [];
                    if (dateFilterArr) {
                      dateFilterArr.forEach((one) => arr.push(one.label));
                    }
                    return arr;
                  })()}
                />
              </div>
              <div className="col-sm-7">
                <AntdDatepicker
                  allowClear
                  portalId="root"
                  format="MM/DD/YYYY"
                  // onKeyDown={(e) => {
                  //   if(e.key!="Backspace"){
                  //     e.preventDefault();
                  //   }
                  // }}
                  placeholderText="Select a date"
                  popperClassName="ag-custom-component-popup"
                  className="form-control form-control-sm"
                  value={getDatePickerValue(index)}
                  onChange={(selected) => {
                    try {
                      if (
                        selected &&
                        selected.$d instanceof Date &&
                        isFinite(selected.$d.getTime())
                      ) {
                        let list = [...filters];
                        list[index].date = [
                          dayjs(selected.$d).format("MM/DD/YYYY"),
                        ];
                        list[index].phase = "completed";
                        setDatePickerValue(list);
                        setFilters(list);
                        // date is valid
                      } else {
                        let list = [...filters];
                        list[index].date = [selected];
                        list[index].phase = "middle";
                        setDatePickerValue(list);
                        setFilters(list);
                      }
                    } catch (error) {}
                  }}
                />
                {/* <label className="Date-Picker-Calender">
                  <img src={calIcon} alt="cal" />
                </label> */}
              </div>
            </div>
          </>
        );
      }
      if (filters[index] && filters[index].value == "Date Range") {
        return (
          <>
            <div className="row">
              <div className="col-sm-5">
                <CustumSelect
                  addFlag={false}
                  placeholder={"Select Option"}
                  value={obj.value ? obj.value : ""}
                  onChange={(value) => {
                    let list = [...filters];
                    let newList = [];
                    list?.forEach((t) => {
                      if (!newList.includes(t)) {
                        newList.push(t);
                      }
                    });
                    list = newList;
                    list[index].value = value;
                    // list[index].phase = "completed";
                    setFilters(list);
                  }}
                  items={(() => {
                    let arr = [];
                    if (dateFilterArr) {
                      dateFilterArr.forEach((one) => arr.push(one.label));
                    }
                    return arr;
                  })()}
                />
              </div>
              <div className="col-sm-7">
                <AntdRangePicker
                  inputReadOnly={false}
                  madalOpenState={()=>{return open}}
                  onChangeDateRange={(datesFlag, datesString) => {
                    try {
                      let start = new Date(new Date(datesString[0]));
                      let end = new Date(new Date(datesString[1]));
                      let arr = [];
                      let date = start;
                      while (date <= end) {
                        let temp = dayjs(new Date(date)).format("MM-DD-YYYY");
                        arr.push(temp);
                        date.setDate(date.getDate() + 1);
                      }
                      let list = [...filters];
                      list[index].date = arr;
                      list[index].phase = datesFlag && datesFlag=="Clear"? "middle" :"completed";
                      setDateRange(list);
                      setFilters(list);
                    } catch (error) {}
                  }}
                  defaultStartDate={getDateRangePickerValue(index, 0)}
                  defaultEndDate={getDateRangePickerValue(index, -1)}
                  allEnd={getDateRangePickerValue(index, -1)}
                  allStart={getDateRangePickerValue(index, 0)}
                />

                {/* <input style={{ height: '24px' }} type='date' onChange={(e) => {
                            let list = [...filters];
                            list[index].date = dayjs(new Date(e.currentTarget.value)).format('MM-DD-YYYY');
                            list[index].phase = "completed";
                            setFilters(list);
                        }} className='form-control form-control-sm' /> */}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <CustumSelect
            addFlag={false}
            placeholder={"Select Option"}
            value={obj.value ? obj.value : ""}
            onChange={(value) => {
              let list = [...filters];
              let newList = [];
              list?.forEach((t) => {
                if (!newList.includes(t)) {
                  newList.push(t);
                }
              });
              list = newList;
              list[index].value = value;
              if (
                list[index] &&
                list[index].value !== "Before Date" &&
                list[index].value !== "After Date" &&
                list[index].value !== "Date Range"
              ) {
                list[index].phase = "completed";
              }

              setFilters(list);
            }}
            items={(() => {
              let arr = [];
              if (dateFilterArr) {
                dateFilterArr.forEach((one) => arr.push(one.label));
              }
              return arr;
            })()}
          />
        );
      }
    } else if (obj.type == "number") {
      if (obj.condition == "between" || obj.condition == "not between") {
        return (
          <div className="row">
            <div className="col">
              <input
                placeholder="from..."
                style={{ height: "35px" }}
                className="form-control form-control-sm"
                type="number"
                id={"checkboxes" + index}
                value={obj.from}
                onChange={(e) => {
                  if (e.currentTarget.value) {
                    let list = [...filters];
                    list[index].from = e.currentTarget.value;
                    list[index].phase = "completed";
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    list[index].phase = "middle";
                    list[index].to = e.currentTarget.value;
                    list[index].from = e.currentTarget.value;
                    setFilters(list);
                  }
                }}
              />
            </div>
            <div className="col">
              <input
                placeholder="to.."
                style={{ height: "35px" }}
                className="form-control form-control-sm"
                type="number"
                id={"checkboxes" + index}
                value={obj.value}
                onChange={(e) => {
                  if (e.currentTarget.value) {
                    let list = [...filters];
                    list[index].value = e.currentTarget.value;
                    list[index].phase = "completed";
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    list[index].phase = "middle";
                    list[index].value = e.currentTarget.value;
                    setFilters(list);
                  }
                }}
              />
            </div>
          </div>
        );
      }
      if (obj.condition == "is") {
        return (
          <div className="row">
            <div className="col">
              <CustomMultiSelect
                setOpendDropDowns={setOpendDropDowns}
                invertColor={invertColor}
                filterIndex={index}
                removeTile={removeTile}
                opendDropdowns={opendDropdowns}
                id={"checkboxesForNumbers" + index}
                values={obj.value}
                onChange={(checked, event, optionIdx) => {
                  if (obj.value) {
                    if (checked) {
                      let list = [...filters];
                      list[index].value.push(event);
                      list[index].phase = "completed";
                      setFilters(list);
                    } else {
                      let list = [...filters];
                      list[index].value = list[index].value?.filter(
                        (one) => one.name !== event.name
                      );
                      list[index].phase = "completed";
                      if (list[index].value.length == 0) {
                        list[index].phase = "middle";
                      }
                      setFilters(list);
                    }
                  } else {
                    if (checked) {
                      let list = [...filters];
                      list[index].value = [event];
                      list[index].phase = "completed";
                      setFilters(list);
                    } else {
                      let list = [...filters];
                      list[index].value = null;
                      list[index].phase = "middle";
                      setFilters(list);
                    }
                  }
                }}
                icon={obj.column_name}
                options={
                  obj.module == "Deal" ? getListOfNumbersForTypeNumber(obj) : []
                }
              />
            </div>
          </div>
        );
      } else {
        return (
          <input
            placeholder="number.."
            style={{ height: "35px" }}
            className="form-control form-control-sm"
            type={
              obj.condition == "is empty" || obj.condition == "is not empty"
                ? "hidden"
                : "number"
            }
            id={"checkboxes" + index}
            value={obj.value}
            onChange={(e) => {
              if (e.currentTarget.value) {
                let list = [...filters];
                list[index].value = e.currentTarget.value;
                list[index].phase = "completed";
                setFilters(list);
              } else {
                let list = [...filters];
                list[index].phase = "middle";
                list[index].value = e.currentTarget.value;
                setFilters(list);
              }
            }}
          />
        );
      }
    } else if (obj.type == "boolean") {
      if (obj.condition == "is" || obj.condition == "isnt") {
        return (
          <CustomMultiSelect
            setOpendDropDowns={setOpendDropDowns}
            invertColor={invertColor}
            opendDropdowns={opendDropdowns}
            filterIndex={index}
            removeTile={removeTile}
            id={"checkboxes" + index}
            values={obj.value}
            onChange={(checked, event, optionIdx) => {
              if (obj.value) {
                if (checked) {
                  let list = [...filters];
                  list[index].value.push(event);
                  list[index].phase = "completed";
                  setFilters(list);
                } else {
                  let list = [...filters];
                  list[index].value = list[index].value?.filter(
                    (one) => one.name !== event.name
                  );
                  list[index].phase = "completed";
                  if (list[index].value.length == 0) {
                    list[index].phase = "middle";
                  }
                  setFilters(list);
                }
              } else {
                if (checked) {
                  let list = [...filters];
                  list[index].value = [event];
                  list[index].phase = "completed";
                  setFilters(list);
                } else {
                  let list = [...filters];
                  list[index].value = null;
                  list[index].phase = "middle";
                  setFilters(list);
                }
              }
            }}
            icon={obj.column_name}
            options={
              obj.api_name == "Stop_Work_Order_Exists_on_Property"
                ? [
                    { name: "No", id: "no" },
                    { name: "Partial SWO Exists", id: "Partial SWO Exists" },
                    { name: "Full SWO Exists", id: "Full SWO Exists" },
                    { name: "N/A", id: "N/A" },
                  ]
                : obj.api_name == "Conjunction"
                ? [
                    { name: "(Blank)", id: "blank" },
                    { name: "N/A", id: "N/A" },
                    { name: "No", id: "no" },
                    ...ConjunctionApplicationNumbers,
                  ]
                : obj.api_name == "Construction_Classification_Code"
                ? [{ name: "N/A", id: "N/A" }, ...ConstructionClassfCode]
                : [
                    { name: "N/A", id: "N/A" },
                    { name: "Yes", id: "yes" },
                    { name: "No", id: "no" },
                  ]
            }
          />
        );
      } else {
        return (
          <input
            style={{ height: "35px" }}
            className="form-control form-control-sm"
            type={
              obj.condition == "is empty" || obj.condition == "is not empty"
                ? "hidden"
                : "text"
            }
            id={"checkboxes" + index}
            value={obj.value}
            onChange={(e) => {
              if (e.currentTarget.value) {
                let list = [...filters];
                list[index].value = e.currentTarget.value;
                list[index].phase = "completed";
                setFilters(list);
              } else {
                let list = [...filters];
                list[index].phase = "middle";
                list[index].value = e.currentTarget.value;
                setFilters(list);
              }
            }}
          />
        );
      }
    } else {
      return <></>;
    }
  };

  const getLastInputTypeForGroup = (obj1, index, obj, groupIdx) => {
    if (obj.type == "string") {
      if (dropdownFields.includes(obj.column_name)) {
        if (obj.condition == "is" || obj.condition == "isn't") {
          return (
            <CustomMultiSelect
              setOpendDropDowns={setOpendDropDowns}
              invertColor={invertColor}
              opendDropdowns={opendDropdowns}
              id={`checkboxes-group${groupIdx}${index}`}
              values={obj.value}
              filterIndex={index}
              GroupIndex={groupIdx}
              removeTile={removeTile}
              onChange={(checked, event, optionIdx) => {
                if (obj.value) {
                  if (checked) {
                    let list = [...filters];
                    let data= updatedgetMiddleLastInputForDeal(obj)
                    if(event.name=="Select All"){
                      list[index].Group[groupIdx].value=[];
                      data.map((abc)=>{
                        list[index].Group[groupIdx].value.push(abc);
                        list[index].Group[groupIdx].phase = "completed";
                      })
                     }else{
                      list[index].Group[groupIdx].value.push(event);
                    list[index].Group[groupIdx].phase = "completed";
                    if(list[index].Group[groupIdx].value.length==data.length-1){
                      list[index].Group[groupIdx].value.push({ name: "Select All", color: "#fff", id: "0" })
                    }
                  }
                    // list[index].Group[groupIdx].value.push(event);
                    // list[index].Group[groupIdx].phase = "completed";
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    if(event.name=="Select All"){        
                      list[index].Group[groupIdx].value=[];
                      list[index].Group[groupIdx].phase = "middle";                      
                     }else{
                       list[index].Group[groupIdx].value = list[index].Group[groupIdx].value?.filter(
                         (one) => one.name !== event.name && one.name!=="Select All"
                       );
                       list[index].Group[groupIdx].phase = "completed";
                       if (list[index].Group[groupIdx].value.length == 0) {
                        list[index].Group[groupIdx].phase = "middle";
                    }
                  }
                    setFilters(list);
                  }
                } else {
                  if (checked) {
                    let list = [...filters];
                    if(event.name=="Select All"){
                      list[index].Group[groupIdx].value=[];
                      let data= updatedgetMiddleLastInputForDeal(obj)
                      data.map((abc)=>{
                        list[index].Group[groupIdx].value.push(abc);
                        list[index].Group[groupIdx].phase = "completed";
                      })
                     }else{
                    list[index].Group[groupIdx].value = [event];
                    list[index].Group[groupIdx].phase = "completed";
                    }
                    // list[index].Group[groupIdx].value = [event];
                    // list[index].Group[groupIdx].phase = "completed";
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    list[index].Group[groupIdx].value = null;
                    list[index].Group[groupIdx].phase = "middle";
                    setFilters(list);
                  }
                }
              }}
              icon={obj.column_name}
              options={
                obj.module == "Deal"
                  ? updatedgetMiddleLastInputForDeal(obj)
                  : getMiddleLastInput(obj)
              }
            />
          );
        } else {
          return (
            <input
              style={{ height: "35px" }}
              className="form-control form-control-sm"
              type={
                obj.condition == "is empty" || obj.condition == "is not empty"
                  ? "hidden"
                  : "text"
              }
              id={"checkboxes-group" + groupIdx}
              value={obj.value}
              onChange={(e) => {
                if (e.currentTarget.value) {
                  let list = [...filters];
                  list[index].Group[groupIdx].value = e.currentTarget.value;
                  list[index].Group[groupIdx].phase = "completed";
                  setFilters(list);
                } else {
                  let list = [...filters];
                  list[index].Group[groupIdx].phase = "middle";
                  list[index].Group[groupIdx].value = e.currentTarget.value;
                  setFilters(list);
                }
              }}
            />
          );
        }
      } else {
        return (
          <input
            style={{ height: "35px" }}
            className="form-control form-control-sm"
            type={
              obj.condition == "is empty" || obj.condition == "is not empty"
                ? "hidden"
                : "text"
            }
            id={"checkboxes-group" + groupIdx}
            value={obj.value}
            onChange={(e) => {
              if (e.currentTarget.value) {
                let list = [...filters];
                list[index].Group[groupIdx].value = e.currentTarget.value;
                list[index].Group[groupIdx].phase = "completed";
                setFilters(list);
              } else {
                let list = [...filters];
                list[index].Group[groupIdx].phase = "middle";
                list[index].Group[groupIdx].value = e.currentTarget.value;
                setFilters(list);
              }
            }}
          />
        );
      }
    } else if (obj.type == "date") {  
      if (
        filters[index] &&
        filters[index].Group &&
        filters[index].Group[groupIdx] &&
        (filters[index].Group[groupIdx].value == "Before Date" ||
          filters[index].Group[groupIdx].value == "Select A Date" ||
          filters[index].Group[groupIdx].value == "After Date")
      ) {
        return (
          <>
            <div className="row">
              <div className="col-sm-5">
                <CustumSelect
                  addFlag={false}
                  placeholder={"Select Option"}
                  value={obj.value ? obj.value : ""}
                  onChange={(value) => {
                    let list = [...filters];
                    let newList = [];
                    list?.forEach((t) => {
                      if (!newList.includes(t)) {
                        newList.push(t);
                      }
                    });
                    list = newList;
                    list[index].Group[groupIdx].value = value;
                    // list[index].phase = "completed";
                    setFilters(list);
                  }}
                  items={(() => {
                    let arr = [];
                    if (dateFilterArr) {
                      dateFilterArr.forEach((one) => arr.push(one.label));
                    }
                    return arr;
                  })()}
                />
              </div>
              <div className="col-sm-7">
                <AntdDatepicker
                  portalId="root"
                  allowClear
                  format="MM/DD/YYYY"
                  popperClassName="ag-custom-component-popup"
                  className="form-control form-control-sm"
                  value={getDatePickerValueOfGroup(index, groupIdx)}
                  onChange={(selected) => {
                    //setDate([...date,{date:selected,index:index}])
                    if (selected) {
                      let list = [...filters];
                      list[index].Group[groupIdx].date = [
                        dayjs(selected).format("MM/DD/YYYY"),
                      ];
                      list[index].Group[groupIdx].phase = "completed";
                      setDatePickerValue(list);
                      setFilters(list);
                    } else {
                      let list = [...filters];
                      list[index].Group[groupIdx].date = [selected];
                      list[index].Group[groupIdx].phase = "middle";
                      setDatePickerValue(list);
                      setFilters(list);
                    }
                  }}
                />
                {/* <label className="Date-Picker-Calender" >
                  <img src={calIcon} alt="cal" />
                </label> */}
              </div>
            </div>
          </>
        );
      }
      if (
        filters[index] &&
        filters[index].Group &&
        filters[index].Group[groupIdx].value == "Date Range"
      ) {
        return (
          <>
            <div className="row">
              <div className="col-sm-5">
                <CustumSelect
                  addFlag={false}
                  placeholder={"Select Option"}
                  value={obj.value ? obj.value : ""}
                  onChange={(value) => {
                    let list = [...filters];
                    let newList = [];
                    list?.forEach((t) => {
                      if (!newList.includes(t)) {
                        newList.push(t);
                      }
                    });
                    list = newList;
                    list[index].Group[groupIdx].value = value;
                    // list[index].phase = "completed";
                    setFilters(list);
                  }}
                  items={(() => {
                    let arr = [];
                    if (dateFilterArr) {
                      dateFilterArr.forEach((one) => arr.push(one.label));
                    }
                    return arr;
                  })()}
                />
              </div>
              <div className="col-sm-7">
                <AntdRangePicker
                  onChangeDateRange={(dates, datesString) => {
                    
                    let start = new Date(new Date(datesString[0]));
                    let end = new Date(new Date(datesString[1]));
                    let arr = [];
                    let date = start;
                    while (date <= end) {
                      let temp = dayjs(new Date(date)).format("MM-DD-YYYY");
                      arr.push(temp);
                      date.setDate(date.getDate() + 1);
                    }
                    let list = [...filters];
                    list[index].Group[groupIdx].date = arr;
                    list[index].Group[groupIdx].phase = "completed";
                    setDateRange(list);
                    setFilters(list);
                  }}
                  defaultStartDate={getDateRangePickerValue(
                    index,
                    0,
                    groupIdx,
                    true
                  )}
                  defaultEndDate={getDateRangePickerValue(
                    index,
                    -1,
                    groupIdx,
                    true
                  )}
                  allEnd={getDateRangePickerValue(index, -1, groupIdx, true)}
                  allStart={getDateRangePickerValue(index, 0, groupIdx, true)}
                />

                {/* <input style={{ height: '24px' }} type='date' onChange={(e) => {
                            let list = [...filters];
                            list[index].date = dayjs(new Date(e.currentTarget.value)).format('MM-DD-YYYY');
                            list[index].phase = "completed";
                            setFilters(list);
                        }} className='form-control form-control-sm' /> */}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <CustumSelect
            addFlag={false}
            placeholder={"Select Option"}
            value={obj.value ? obj.value : ""}
            onChange={(value) => {
              let list = [...filters];
              let newList = [];
              list?.forEach((t) => {
                if (!newList.includes(t)) {
                  newList.push(t);
                }
              });
              list = newList;
              list[index].value = value;
              list[index].Group[groupIdx].value = value;
              if (
                list[index] &&
                list[index].Group &&
                list[index].Group[groupIdx].value !== "Before Date" &&
                list[index].Group[groupIdx].value !== "After Date" &&
                list[index].Group[groupIdx].value !== "Date Range"
              ) {
                list[index].Group[groupIdx].phase = "completed";
              }

              setFilters(list);
            }}
            items={(() => {
              let arr = [];
              if (dateFilterArr) {
                dateFilterArr.forEach((one) => arr.push(one.label));
              }
              return arr;
            })()}
          />
        );
      }
    } else if (obj.type == "number") {
      if (obj.condition == "between" || obj.condition == "not between") {
        return (
          <div className="row">
            <div className="col">
              <input
                placeholder="from..."
                style={{ height: "35px" }}
                className="form-control form-control-sm"
                type="number"
                id={"checkboxes-group" + groupIdx}
                value={obj.from}
                onChange={(e) => {
                  if (e.currentTarget.value) {
                    let list = [...filters];
                    list[index].Group[groupIdx].from = e.currentTarget.value;
                    list[index].Group[groupIdx].phase = "completed";
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    list[index].Group[groupIdx].phase = "middle";
                    list[index].Group[groupIdx].to = e.currentTarget.value;
                    list[index].Group[groupIdx].from = e.currentTarget.value;
                    setFilters(list);
                  }
                }}
              />
            </div>
            <div className="col">
              <input
                placeholder="to.."
                style={{ height: "35px" }}
                className="form-control form-control-sm"
                type="number"
                id={"checkboxes-group" + groupIdx}
                value={obj.value}
                onChange={(e) => {
                  if (e.currentTarget.value) {
                    let list = [...filters];
                    list[index].Group[groupIdx].value = e.currentTarget.value;
                    list[index].Group[groupIdx].phase = "completed";
                    setFilters(list);
                  } else {
                    let list = [...filters];
                    list[index].Group[groupIdx].phase = "middle";
                    list[index].Group[groupIdx].value = e.currentTarget.value;
                    setFilters(list);
                  }
                }}
              />
            </div>
          </div>
        );
      }
      if (obj.condition == "is") {
        return (
          <div className="row">
            <div className="col">
              <CustomMultiSelect
                setOpendDropDowns={setOpendDropDowns}
                invertColor={invertColor}
                opendDropdowns={opendDropdowns}
                id={`checkboxes-group${groupIdx}${index}`}
                values={obj.value}
                filterIndex={index}
                GroupIndex={groupIdx}
                removeTile={removeTile}
                onChange={(checked, event, optionIdx) => {
                  if (obj.value) {
                    if (checked) {
                      let list = [...filters];
                      list[index].Group[groupIdx].value.push(event);
                      list[index].Group[groupIdx].phase = "completed";
                      setFilters(list);
                    } else {
                      let list = [...filters];
                      list[index].Group[groupIdx].value = list[index].Group[
                        groupIdx
                      ].value?.filter((one) => one.name !== event.name);
                      list[index].Group[groupIdx].phase = "completed";
                      if (list[index].Group[groupIdx].value.length == 0) {
                        list[index].Group[groupIdx].phase = "middle";
                      }
                      setFilters(list);
                    }
                  } else {
                    if (checked) {
                      let list = [...filters];
                      list[index].Group[groupIdx].value = [event];
                      list[index].Group[groupIdx].phase = "completed";
                      setFilters(list);
                    } else {
                      let list = [...filters];
                      list[index].Group[groupIdx].value = null;
                      list[index].Group[groupIdx].phase = "middle";
                      setFilters(list);
                    }
                  }
                }}
                icon={obj.column_name}
                options={
                  obj.module == "Deal" ? getListOfNumbersForTypeNumber(obj) : []
                }
              />
            </div>
          </div>
        );
      } else {
        return (
          <input
            placeholder="number.."
            style={{ height: "35px" }}
            className="form-control form-control-sm"
            type={
              obj.condition == "is empty" || obj.condition == "is not empty"
                ? "hidden"
                : "number"
            }
            id={"checkboxes-group" + groupIdx}
            value={obj.value}
            onChange={(e) => {
              if (e.currentTarget.value) {
                let list = [...filters];
                list[index].Group[groupIdx].value = e.currentTarget.value;
                list[index].Group[groupIdx].phase = "completed";
                setFilters(list);
              } else {
                let list = [...filters];
                list[index].Group[groupIdx].phase = "middle";
                list[index].Group[groupIdx].value = e.currentTarget.value;
                setFilters(list);
              }
            }}
          />
        );
      }
    } else if (obj.type == "boolean") {
      if (obj.condition == "is") {
        return (
          <CustomMultiSelect
            setOpendDropDowns={setOpendDropDowns}
            opendDropdowns={opendDropdowns}
            id={`checkboxes-group${groupIdx}${index}`}
            values={obj.value}
            filterIndex={index}
            GroupIndex={groupIdx}
            removeTile={removeTile}
            onChange={(checked, event, optionIdx) => {
              if (obj.value) {
                if (checked) {
                  let list = [...filters];
                  list[index].Group[groupIdx].value.push(event);
                  list[index].Group[groupIdx].phase = "completed";
                  setFilters(list);
                } else {
                  let list = [...filters];
                  list[index].Group[groupIdx].value = list[index].Group[
                    groupIdx
                  ].value?.filter((one) => one.name !== event.name);
                  list[index].Group[groupIdx].phase = "completed";
                  if (list[index].Group[groupIdx].value.length == 0) {
                    list[index].Group[groupIdx].phase = "middle";
                  }
                  setFilters(list);
                }
              } else {
                if (checked) {
                  let list = [...filters];
                  list[index].Group[groupIdx].value = [event];
                  list[index].Group[groupIdx].phase = "completed";
                  setFilters(list);
                } else {
                  let list = [...filters];
                  list[index].Group[groupIdx].value = null;
                  list[index].Group[groupIdx].phase = "middle";
                  setFilters(list);
                }
              }
            }}
            icon={obj.column_name}
            options={
              obj.api_name == "Stop_Work_Order_Exists_on_Property"
                ? [
                    { name: "No", id: "no" },
                    { name: "Partial SWO Exists", id: "Partial SWO Exists" },
                    { name: "Full SWO Exists", id: "Full SWO Exists" },
                    { name: "N/A", id: "N/A" },
                  ]
                : obj.api_name == "Conjunction"
                ? [
                    { name: "(Blank)", id: "blank" },
                    { name: "N/A", id: "N/A" },
                    { name: "No", id: "no" },
                    ...ConjunctionApplicationNumbers,
                  ]
                : obj.api_name == "Construction_Classification_Code"
                ? [{ name: "N/A", id: "N/A" }, ...ConstructionClassfCode]
                : [
                    { name: "N/A", id: "N/A" },
                    { name: "Yes", id: "yes" },
                    { name: "No", id: "no" },
                  ]
            }
          />
        );
      } else {
        return (
          <input
            style={{ height: "35px" }}
            className="form-control form-control-sm"
            type="text"
            id={"checkboxes-group" + groupIdx}
            value={obj.value}
            onChange={(e) => {
              if (e.currentTarget.value) {
                let list = [...filters];
                list[index].Group[groupIdx].value = e.currentTarget.value;
                list[index].Group[groupIdx].phase = "completed";
                setFilters(list);
              } else {
                let list = [...filters];
                list[index].Group[groupIdx].phase = "middle";
                list[index].Group[groupIdx].value = e.currentTarget.value;
                setFilters(list);
              }
            }}
          />
        );
      }
    } else {
      return <></>;
    }
  };

  const getGroupsForInitital = (one, index) => {
    if (one?.Group) {
      return (
        <>
          <div
            className="col-sm-6 ml-3 group-wrapper"
            //style={{ borderLeft: "1px solid lightgray" }}
          >
            {one?.Group.map((group, groupIdx) => {
              return (
                <div className="row mt-1">
                  <div className="col-sm-2" style={{ lineHeight: "30px" }}>
                    {groupIdx == 0
                      ? "Where"
                      : getGroupSwitch(index, groupIdx, group, "condition")}
                  </div>
                  {mode == "Both" ? (
                    <div className="col-sm-2">
                      {getGroupSwitch(index, groupIdx, group, "module")}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-sm-3">
                    <Select
                      showSearch
                      style={{ width: 190 }}
                      placeholder="Search to Select"
                      // optionFilterProp="children"
                      // filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      // filterSort={(optionA, optionB) =>
                      //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      // }
                      onSelect={(value, option) => {
                        let list = [...filters];
                        list[index].Group[groupIdx].phase = "middle";
                        list[index].Group[groupIdx].column_name = value;
                        setFilters(list);
                        let items = [...filterItems];
                        let conditions = [...filterCondition];
                        items = items?.filter((one) => one.name == value);
                        conditions = conditions?.filter(
                          (cond) => cond.type == items[0].type
                        );
                        list[index].Group[groupIdx].column_name = value;
                        list[index].Group[groupIdx].api_name = option.api_name;
                        list[index].Group[groupIdx].type = option.type;
                        list[index].Group[groupIdx].value = undefined;
                        list[index].Group[groupIdx].condition = undefined;
                        setSelectedCond(conditions[0].conditions);
                     
                      }}
                      options={getModuleItems(group)}
                    />
                  </div>
                </div>
              );
            })}
            <p
              onClick={() => addFilterInGroup(one, index)}
              style={{ cursor: "pointer", width: "90px" }}
              className="small mt-2 mb-0"
            >
              Add Filter +
            </p>
          </div>
        </>
      );
    }
  };

  const addFilterInGroup = (obj, index) => {
    const filterList = [...filters];
    if (filterList[index].Group) {
      if (mode == "Deal") {
        filterList[index].Group.push({
          phase: "initial",
          module: "Deal",
          logic: "AND",
        });
        setFilters(filterList);
      } else if (mode == "Task") {
        filterList[index].Group.push({
          phase: "initial",
          module: "Task",
          logic: "AND",
        });
        setFilters(filterList);
      } else {
        filterList[index].Group.push({
          phase: "initial",
          module: "Task",
          logic: "AND",
        });
        setFilters(filterList);
      }
    }
  };

  const middlePhaseGroupInput = (obj, index) => {
    if (obj.Group) {
      return (
        <>
          <div
            className="col-sm-11 group-wrapper"
            //style={{ borderLeft: "1px solid lightgray"}}
          >
            {obj.Group.map((group, groupIdx) => {
              return (
                <div className="row mt-1">
                  <div className="col-sm-1 insideSwitch">
                    {groupIdx == 0
                      ? "Where"
                      : getGroupSwitch(index, groupIdx, group, "condition")}
                  </div>
                  {mode == "Both" ? (
                    <div className="col-sm-1">
                      {getGroupSwitch(index, groupIdx, group, "module")}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-sm-3">
                    <CustumSelect
                      addFlag={false}
                      // placeholder={'Select Applicant'}
                      value={group.column_name ? group.column_name : ""}
                      onChange={(value) => {
                        let list = [...filters];
                        let newList = [];
                        list?.forEach((t) => {
                          if (!newList.includes(t)) {
                            newList.push(t);
                          }
                        });
                        list = newList;
                        let items =
                          group.module == "Deal"
                            ? [...filterItemsDeal]
                            : [...filterItems];
                        let conditions = [...filterCondition];
                        items = items?.filter((one) => one.name == value);
                        conditions = conditions?.filter(
                          (cond) => cond.type == items[0].type
                        );
                        list[index].Group[groupIdx].column_name = value;
                        list[index].Group[groupIdx].value = undefined;
                        list[index].Group[groupIdx].condition = undefined;
                        list[index].Group[groupIdx].type = items[0].type;
                        list[index].Group[groupIdx].api_name =
                          items[0].api_name;
                        list[index].Group[groupIdx].phase = "middle";

                        setSelectedCond(conditions[0].conditions);
                        setFilters(list);
                      }}
                      items={getModuleItems1(group)}
                    />
                  </div>
                  {obj.Group[groupIdx].type !== "date" ? (
                    <div className="col-sm-2">
                      <CustumSelect
                        addFlag={false}
                        placeholder={"Condition"}
                        value={group.condition ? group.condition : ""}
                        onChange={(value) => {
                          let list = [...filters];
                          let newList = [];
                          list?.forEach((t) => {
                            if (!newList.includes(t)) {
                              newList.push(t);
                            }
                          });
                          list = newList;
                          list[index].Group[groupIdx].condition = value;
                          list[index].Group[groupIdx].value = undefined;
                          setFilters(list);
                        }}
                        items={(() => {
                          let arr = [];
                          selectedCond.forEach((one) => arr.push(one.value));
                          return arr;
                        })()}
                      />
                    </div>
                  ) : (
                    // getLastInputTypeForGroup(obj, index, group, groupIdx)
                    <></>
                  )}
                  <div
                    className={
                      mode == "Both"
                        ? obj.Group[groupIdx].type == "date"
                          ? "col-sm-5"
                          : "col-sm-5"
                        : obj.Group[groupIdx].type == "date"
                        ? "col-sm-7"
                        : "col-sm-5"
                    }
                  >
                    {getLastInputTypeForGroup(obj, index, group, groupIdx)}
                  </div>
                  <div
                    className="col-sm-1"
                    style={{ lineHeight: "33px", paddingRight: "20px", textAlign: "right" }}
                  >
                    <FontAwesomeIcon
                      onClick={() => {
                        let list = [...filters];
                        if (list[index].Group) {
                          list[index].Group.splice(groupIdx, 1);
                          if (list[index].Group?.length == 0) {
                            list.splice(index, 1);
                          }
                          if (list?.length == 0) {
                            setFilters([{ phase: "initial", module: "Deal" }]);
                          } else {
                            setFilters(list);
                          }
                        }

                        setfiltersmode([]);
                       
                      }}
                      className="cursor-pointer trashIcon"
                      icon={faTrashAlt}
                    />
                  </div>
                </div>
              );
            })}
            <p
              onClick={() => addFilterInGroup(obj, index)}
              style={{ cursor: "pointer", width: "90px" }}
              className="small mt-2 mb-0"
            >
              Add Filter +
            </p>
          </div>
        </>
      );
    }
  };
  //helper css
  const filterCardCss = {
    "background-color": "lightgrey !important",
    "color": "white !important",
    "border-radius": "5px !important",
    "padding-top": "0.25rem !important",
    "padding-bottom": "0.1rem !important",
    "border-left": "2px solid black !important",
  };
  //helper css
  const middlePhaseInput = (index, obj) => {  
    return (
      <div className="row mt-2 filter-div-wrapper">
        <div className="col-sm-1 groupFilter">
          {index == 0 ? "Where" : getWitch(index, obj, "condition")}
        </div>
        {obj.Group && obj.Group.length > 0 ? (
          middlePhaseGroupInput(obj, index)
        ) : (
          <>
            {mode == "Both" ? (
              <div className="col-sm-1">{getWitch(index, obj, "module")}</div>
            ) : (
              <></>
            )}
            <div className="col-sm-3">
              <CustumSelect
                addFlag={false}
                // placeholder={'Select Applicant'}
                value={obj.column_name ? obj.column_name : ""}
                onChange={(value) => { 
                  let list = [...filters];
                  let newList = [];
                  list?.forEach((t) => {
                    if (!newList.includes(t)) {
                      newList.push(t);
                    }
                  });
                  list = newList;
                  let items =
                    obj.module == "Task"
                      ? [...filterItems]
                      : [...filterItemsDeal];
                  let conditions = [...filterCondition];
                  items = items?.filter((one) => one.name == value);
                  conditions = conditions?.filter(
                    (cond) => cond.type == items[0].type
                  );
                  let filterColumn = items?.filter((one) => one.name == value);
                  list[index].column_name = value;
                  list[index].api_name = filterColumn[0].api_name;
                  list[index].type = items[0].type;
                  list[index].value = undefined;
                  list[index].condition = undefined;
                  list[index].phase = "middle";
                  setSelectedCond(conditions[0].conditions);
                  setFilters(list);
                }}
                items={getModuleItems1(obj)}
              />
            </div>
            {obj.type !== "date" ? (
              <div className="col-sm-2">
                <CustumSelect
                  addFlag={false}
                  placeholder={"Condition"}
                  value={obj.condition ? obj.condition : ""}
                  onChange={(value) => {
                    let list = [...filters];
                    let newList = [];
                    list?.forEach((t) => {
                      if (!newList.includes(t)) {
                        newList.push(t);
                      }
                    });
                    list = newList;
                    list[index].condition = value;
                    if (value == "is empty" || value == "is not empty") {
                      list[index].value = value;
                      list[index].phase = "completed";
                    } else {
                      list[index].value = undefined;
                    }
                    list[index].value = undefined;
                    setFilters(list);
                  }}
                  items={(() => {
                    let arr = [];
                    selectedCond.forEach((one) => arr.push(one.value));
                    return arr;
                  })()}
                />
              </div>
            ) : (
              <></>
            )}
            <div
              className={
                mode == "Both"
                  ? obj.type == "date"
                    ? "col-sm-6"
                    : "col-sm-4"
                  : obj.type == "date"
                  ? "col-sm-7"
                  : "col-sm-5"
              }
            >
              {getLastInputType(obj, index)}
            </div>

            <div className="col-sm-1" style={{ lineHeight: "33px" }}>
              <FontAwesomeIcon
                onClick={() => {
                  let list = [...filters];
                  list = list?.filter((one, idx) => idx !== index);
                  if (list?.length == 0) {
                    setFilters([{ phase: "initial", module: "Deal" }]);
                  } else {
                    setFilters(list);
                  }
                
                  removeDatePickerValue(list);
                  setfiltersmode([]);
                  
                  //setFilters([{ phase: "initial", module: "Deal" }]);
                }}
                className="cursor-pointer trashIcon"
                icon={faTrashAlt}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const getModuleItems = (obj) => { 
    if (obj.module == "Task") {
      return (() => {
        let arr = [];
        filterItems.forEach((one) =>
          arr.push({ label: one.name, value: one.name, type: one.type })
        );
        return arr;
      })();
    } else {
      return (() => {
        let arr = [];
        filterItemsDeal.forEach((one) =>
          arr.push({
            label: one.name,
            value: one.name,
            api_name: one.api_name,
            type: one.type,
          })
        );
        return arr;
      })();
    }
  };

  const getModuleItems1 = (obj) => {  
    if (obj.module == "Task") {
      return (() => {
        let arr = [];
        filterItems.forEach((one) => arr.push(one.name));
        return arr;
      })();
    } else {
      return (() => {
        let arr = [];
        filterItemsDeal.forEach((one) => arr.push(one.name));
        return arr;
      })();
    }
  };

  const initialPhaseInput = (index, one) => { 
    return (
      <div className="row mt-2 filter-div-wrapper">
        <div
          className="col-sm-1"
          style={{  paddingLeft: "0px" }}
        >
          {index == 0 ? "Where" : getWitch(index, one, "condition")}
        </div>

        {one?.Group && one?.Group.length > 0 ? (
          getGroupsForInitital(one, index)
        ) : (
          <>
            {mode == "Both" ? (
              <div className="col-sm-1">
                <div className="row">
                  <div className="col">{getWitch(index, one, "module")}</div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="col-sm-3">
              <Select
                showSearch
                style={{ width: 190 }}
                placeholder="Search to Select"
                // optionFilterProp="children"
                // filterOption={(input, option) => (option?.label ?? '').includes(input)}
                // filterSort={(optionA, optionB) =>
                //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                onSelect={(value, option) => { 
                  let list = [...filters];
                  list[index].phase = "middle";
                  list[index].column_name = value;
                  setFilters(list);
                  let items =
                    one.module == "Task"
                      ? [...filterItems]
                      : [...filterItemsDeal];
                  let conditions = [...filterCondition];
                  items = items?.filter((one) => one.name == value);
                  conditions = conditions?.filter(
                    (cond) => cond.type == items[0].type
                  );
                  list[index].column_name = value;
                  list[index].api_name = option.api_name;
                  list[index].type = option.type;
                  list[index].value = undefined;
                  list[index].condition = undefined;
                  setSelectedCond(conditions[0].conditions);
                 
                }}
                options={getModuleItems(one)}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  function deletePerticularValueOfColumnFilter(key, v, i ,type) {
    
    let oldFilterModule = { ...activeFiltersOnColumnLevel };
    // if (oldFilterModule && oldFilterModule[key]) {
    //   let oldKey = oldFilterModule[key];
    //   if (oldKey.filterModels) {
    //     let arrIndex = oldKey.filterModels[i];
    //     if (arrIndex.values) {
    //       arrIndex["values"] = arrIndex.values?.filter((f) => f != v);
    //     }
    //     if (arrIndex.values && arrIndex.values.length == 0) {
    //       oldKey.filterModels[i] = null;
    //     }
    //   }
    //   if (oldKey.filterModels) {
    //     let arr = oldKey.filterModels?.filter((t) => t != null);
    //     if (arr && arr.length == 0) {
    //       delete oldFilterModule[key];
    //     } else {
    //       oldFilterModule[key] = oldKey;
    //     }
    //   }
    // }
    setActiveFiltersOnColumnLevel(oldFilterModule);
  }
  function renderInnerFilterContent(data, key) {
    let modals = data.filterModels;
    if (modals && modals.length > 0) {
      return (
        <div className="col Col-CustomeClass">
          {modals.map((d, i) => {
                        if(d?.filterType=="set"){
              if (d?.values?.length > 0) {
                return d.values.map((v, k) => {
                  return (
                    <span className="filter-span">
                      {v}
                      <FontAwesomeIcon
                        className="Delete-Icon-For-ColFilters"
                        style={{
                          marginLeft: "3px",
                          marginRight: "3px",
                          color: "#ff4545",
                        }}
                        icon={faTimes}
                        onClick={(e) => {
                          deletePerticularValueOfColumnFilter(key,v, i,d?.filterType);
                        }}
                      />
                    </span>
                  );
                });
              }
            }else if(d?.filterType=="date"){
              return (
                <span className="filter-span">
                  <span style={{
                    marginLeft:"2px",
                    marginRight :"2px"
                  }}>{d.type}</span>
                  <span style={{
                    marginLeft:"2px",
                    marginRight :"2px"
                  }}>{"From date :"+d.dateFrom}</span>
                  <span style={{
                    marginLeft:"2px",
                    marginRight :"2px"
                  }}>{"To date :"+d.dateTo}</span>
                  <FontAwesomeIcon
                    className="Delete-Icon-For-ColFilters"
                    style={{
                      marginLeft: "3px",
                      marginRight: "3px",
                      color: "#ff4545",
                    }}
                    icon={faTimes}
                    onClick={(e) => {
                      deletePerticularValueOfColumnFilter(key, undefined, i,d?.filterType);
                    }}
                  />
                </span>
              )
          }
          })}
        </div>
      );
    }
  }
  function DeleteColumnFilters(key) {
    
    let newFilter = { ...activeFiltersOnColumnLevel };
    delete newFilter[key];
    if (Object.keys(newFilter)?.length == 0) {
      setActiveFiltersOnColumnLevel({});
    } else {
      setActiveFiltersOnColumnLevel(newFilter);
    }
    setIsColFilDeleted(true)
    console.log("newFilter", newFilter);
  }

  function getColumnFilterIfExist(filters) {
    if (filters && Object.keys(filters).length > 0) {
      let keys = Object.keys(filters);
      return (
        <div className="col Column-Filter-Wrapper">
          <div className="row w-100" style={{ fontWeight: 500 }}>
            Column Filter Applied :
          </div>
          <div className="row w-100">
            {keys.map((k, i) => {
            let displayName = k;
              return (
                // <div className="row w-100 column-filter-row">
                //    <div className="col-3">{k.replaceAll("_", " ")}</div>
                //   <div className="col-8 Filter-Value-Wrapper">
                //     {renderInnerFilterContent(filters[k], k)}
                //   </div>
                //   <div className="col-1">
                //     <FontAwesomeIcon
                //       style={{ marginLeft: "4px" }}
                //       onClick={() => {
                //         DeleteColumnFilters(k);
                //       }}
                //       icon={faTrashAlt}
                //     />
                //   </div>
                // </div>
                <span className="filter-span" key={i}>
                      {displayName.replaceAll("_", " ")}
                      <FontAwesomeIcon
                       icon={faTrashAlt}
                        className="Delete-Icon-For-ColFilters"
                        style={{
                          marginLeft: "3px",
                          marginRight: "3px",
                          color: "#ff4545",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          DeleteColumnFilters(k);
                          //deletePerticularValueOfColumnFilter(key,v, i,d?.filterType);
                        }}
                      />
                  </span>
              );
            })}
          </div>
        </div>
      );
    }
  }

  // console.log("filters", filters);

  return (
    <>
      <span style={{ backgroundColor: "#0194f7", cursor: "pointer" }}>
        <FontAwesomeIcon
          onClick={() => {
            if (filters.length == 0) {
              setFilters([{ phase: "initial", module: "Deal" }]);
            }
            setRangeCalCloseFlag(true)
            setOpen(true);
          }}
          color="white"
          icon={faFilter}
        />
        {filters?.length > 0 && filters?.filter(
          (one) =>
            one?.phase == "completed" ||
            one?.Group?.filter((g) => g?.phase == "completed").length > 0
        ).length > 0 ||
        (activeFiltersOnColumnLevel
          ? Object.keys(activeFiltersOnColumnLevel).length > 0
          : false) ? (
          <>
            <span
              style={{ backgroundColor: "white" }}
              class="badge badge-light"
            >
              {" "}
              {(() => {
                let mainFilters = filters?.length > 0 && filters?.filter(
                  (one) =>
                    one?.phase == "completed" ||
                    one?.Group?.filter((g) => g?.phase == "completed").length >
                      0
                ).length;
                return (
                  mainFilters +
                  (activeFiltersOnColumnLevel
                    ? Object.keys(activeFiltersOnColumnLevel).length
                    : 0)
                );
              })()}
            </span>
            <FontAwesomeIcon
              onClick={() => {
                if(mode=='Both'){
                  let deals = [...dealData];
                  let tasks = [...taskData];
                  getReturnData({ deals: deals, tasks: tasks });
                }
                setFilters([{ phase: "initial" }]);
                setActiveFilterAndGetActiveFilters({}, "SET", []);
              }}
              color="white"
              icon={faTimes}
            />
          </>
        ) : (
          <></>
        )}
      </span>
      <Modal
        open={open}
        title="Filters"
        maskClosable={false}
        width={900}
        className="antModalBody taskFilterModal"
        style={{ top: 170, borderRadius: 50 }}
        onCancel={() => {
          setOpendDropDowns("");
          setRangeCalCloseFlag(!open)
          setOpen(false);
        }}
        footer={[
          <div
            onMouseLeave={() => setAddGroupVisibility("none")}
            onMouseEnter={() => setAddGroupVisibility("inline")}
            className="col-sm-12 task-filter-buttons"
          >
            <span className="Error-Msg-For-Filter">
              {errorMsg && !isInitialStateOfFilter ? errorMsg : <></>}
            </span>
            <Button
              style={{ display: "inline" }}
              onClick={() => {
                let shouldAdd = shouldWeAddFilters(filters, true);
                if (!shouldAdd) {
                  setErrorMsg("Please complete above filter.");
                  return;
                } else {
                  setErrorMsg(null);
                }
                const filterList = [...filters];
                if (mode == "Deal") {
                  filterList.push({
                    phase: "middle",
                    logic: "AND",
                    module: "Deal",
                    Group: [{ phase: "initial", logic: "AND", module: "Deal" }],
                  });
                } else if (mode == "Task") {
                  filterList.push({
                    phase: "middle",
                    logic: "AND",
                    module: "Task",
                    Group: [{ phase: "initial", logic: "AND", module: "Task" }],
                  });
                } else {
                  filterList.push({
                    phase: "middle",
                    logic: "AND",
                    module: "Task",
                    Group: [{ phase: "initial", logic: "AND", module: "Task" }],
                  });
                }
                setFilters(filterList);
              }}
              key="submit"
              type="primary"
            >
              Add Group +
            </Button>
            <Button
              onClick={() => {
                let shouldAdd = shouldWeAddFilters(filters, true);
                if (!shouldAdd) {
                  setErrorMsg("Please complete above filter.");
                  return;
                } else {
                  setErrorMsg(null);
                }
                const filterList = [...filters];
                if (mode == "Deal") {
                  filterList.push({
                    phase: "initial",
                    logic: "AND",
                    module: "Deal",
                  });
                } else if (mode == "Task") {
                  filterList.push({
                    phase: "initial",
                    logic: "AND",
                    module: "Task",
                  });
                } else {
                  filterList.push({
                    phase: "initial",
                    logic: "AND",
                    module: "Task",
                  });
                }

                setFilters(filterList);
              }}
              key="submit"
              type="primary"
            >
              Add Filter +
            </Button>
          </div>,
        ]}
      >
        {filters.map((one, index) => {
          if (one?.phase == "initial") {
            return initialPhaseInput(index, one);
          }
          if (one?.phase == "middle" || one?.phase == "completed") {
            return middlePhaseInput(index, one);
          }
        })}
        {getColumnFilterIfExist(activeFiltersOnColumnLevel)}
      </Modal>
    </>
  );
};

export default Task_Filters;
