import httpService from "../../services/http.service";

class UserService{
    GetUserPhotoUrl(userId){
        return "https://contacts.zoho.com/file?ID="+userId+"&fs=thumb"
    }
    GetAllUsers() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/crm/v3/users?type=AllUsers",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    result.data.users=result.data.users.filter((one) => (one.status == 'active'))
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

    CreateUser(data) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url: "https://www.zohoapis.com/crm/v3/users",
                headers: { "Authorization": localStorage.getItem('token') },
                data:{users :[data]}
            }
            httpService.post('chat', config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

    GetAllUserRoles() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/crm/v2/settings/roles",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', config)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

   
}

export default new UserService();