function getDate(value) {
    let dateParts = "";
    if (value?.includes("/")) {
        dateParts = value?.split("/");
    } else if (value?.includes("-")) {
        dateParts = value?.split("-");
    }
    return new Date(
        parseInt(dateParts[0]),
        parseInt(dateParts[1]) - 1,
        parseInt(dateParts[2])
    );
}
export const FilterParams = {
    "Application_Number": {
        filters: [
            {
                filter: "agNumberColumnFilter",
                filterParams: {
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                }
            },
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Application_Number;
                        }
                    },
                },
            }
        ],
    }, "GC_License_Number": {
        filters: [
            {
                filter: "agNumberColumnFilter",
                filterParams: {
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                }
            },
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.GC_License_Number;
                        }
                    },
                },
            }
        ],
    },
    "Current_Application_Status": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Current_Application_Status;
                        }
                    },

                },
            },
        ],
    },
    "Application_Supersede_Required": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Application_Supersede_Required;
                        }
                    },

                },
            },
        ],
    },
    "Current_Application": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Current_Application;
                        }
                    },

                },
            },
        ],
    },
    "GC_Supersede_Required": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.GC_Supersede_Required;
                        }
                    },

                },
            },
        ],
    },
    "GC_Company_Name": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.GC_Company_Name;
                        }
                    },

                },
            },
        ],
    },
    "GC_Name": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.GC_Name;
                        }
                    },

                },
            },
        ],
    },
    "Reinstatement_Required": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Reinstatement_Required;
                        }
                    },

                },
            },
        ],
    },
    "Inspection_Passed": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Inspection_Passed;
                        }
                    },

                },
            },
        ],
    },
    "Withdrawal_Required": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Withdrawal_Required;
                        }
                    },

                },
            },
        ],
    },
    "Installation_Completed": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Installation_Completed;
                        }
                    },

                },
            },
        ],
    },
    "Inspection_Ready_Email_Address": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            return params.data.Solar_Columns.Inspection_Ready_Email_Address;
                        }
                    },

                },
            },
        ],
    },
    //Date
    "Inspection_Passed_Date": {
        filters: [
            {
                filter: "agDateColumnFilter",
                filterParams: {
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    comparator: function (filterDate, cellValue) {
                        if (cellValue == null) return -1;
                        return getDate(cellValue) - filterDate;
                    },
                },
            },
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Solar_Columns) {
                            let d = params.data.Solar_Columns.Inspection_Passed_Date?.split('-');
                            if (d) {
                                return d[1] + '-' + d[2] + '-' + d[0];
                            }
                        }
                    },
                    comparator: function (a, b) {
                        return getDate(a) - getDate(b);
                    },
                },
            },
        ],
    },
    //violation filters -->
    "Violation_Number": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Violation_Columns) {
                            return params.data.Violation_Columns.Violation_Number;
                        }
                    },

                },
            },
        ],
    },
    "Violation_Type": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Violation_Columns) {
                            return params.data.Violation_Columns.Violation_Type;
                        }
                    },

                },
            },
        ],
    },
    "L2_Code": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Violation_Columns) {
                            return params.data.Violation_Columns.L2_Code;
                        }
                    },

                },
            },
        ],
    },
    "Civil_Penalty_Payment_Required": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Violation_Columns) {
                            return params.data.Violation_Columns.Civil_Penalty_Payment_Required;
                        }
                    },

                },
            },
        ],
    },
    "Paid_Under_Job_Number": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Violation_Columns) {
                            return params.data.Violation_Columns.Paid_Under_Job_Number;
                        }
                    },

                },
            },
        ],
    },
    "Invoice_Number": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Violation_Columns) {
                            return params.data.Violation_Columns.Invoice_Number;
                        }
                    },

                },
            },
        ],
    },
    //Pv Interconnection
    "Interconnection_Number": {
        filters: [
            {
                filter: "agNumberColumnFilter",
                filterParams: {
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                }
            },
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Pv_Columns) {
                            return params.data.Pv_Columns.Interconnection_Number;
                        }
                    },
                },
            }
        ],
    },
    "System_Size_KW1": {
        filters: [
            {
                filter: "agNumberColumnFilter",
                filterParams: {
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                }
            },
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Pv_Columns) {
                            return params.data.Pv_Columns.System_Size_KW1;
                        }
                    },
                },
            }
        ],
    },
    "Number_Of_Modules": {
        filters: [
            {
                filter: "agNumberColumnFilter",
                filterParams: {
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                }
            },
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Pv_Columns) {
                            return params.data.Pv_Columns.Number_Of_Modules;
                        }
                    },
                },
            }
        ],
    },
    "PV_Module_Model": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Pv_Columns) {
                            return params.data.Pv_Columns.PV_Module_Model;
                        }
                    },

                },
            },
        ],
    },
    "Invertor_Type": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Pv_Columns) {
                            return params.data.Pv_Columns.Invertor_Type;
                        }
                    },

                },
            },
        ],
    },
    "Number_of_Invertors": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Pv_Columns) {
                            return params.data.Pv_Columns.Number_of_Invertors;
                        }
                    },

                },
            },
        ],
    },
    "Invertor_Model_Number": {
        filters: [
            {
                filter: "agSetColumnFilter",
                filterParams: {
                    excelMode: "windows",
                    newRowsAction: 'keep',
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    valueGetter: (params) => {
                        if (params.data.Pv_Columns) {
                            return params.data.Pv_Columns.Invertor_Model_Number;
                        }
                    },

                },
            },
        ],
    },
}