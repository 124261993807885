import httpService from "../../services/http.service";
import TaskService from "./TaskService";

class ActivitieService{
    // GetAllProjectActivity(projectId) {
    //     return new Promise((resolve, reject) => {
    //         this.GetAllProjects(projectId)
    //         .then((result)=>{
    //             let doArr=[];
    //             result.data.projects=result.data.projects.filter(one=>one.id_string==projectId)
    //             result.data.projects.forEach((one)=>{
    //                 doArr.push(this.GetProjectActivity(one.link.activity.url))
    //             })
    //             return Promise.all(doArr);
    //         })
    //         .then((result)=>{
    //             resolve(result)
    //         })
    //         .catch((err)=>{
    //             reject(err);
    //         })
           
    //     })
    
    
    // }



    GetAllProjectActivity(projectId) {
        return new Promise((resolve, reject) => {
            let resData=[];
            this.GetAllProjects(projectId)
                .then((result) => {
                    let doArr = [];
                    result.data.projects = result.data.projects.filter(one => one.id_string == projectId)
                    result.data.projects.forEach((one) => {
                        doArr.push(this.GetProjectActivity(one.link.activity.url))
                    })

                    return Promise.all(doArr);
                })
                .then((result) => {
                    // resData = result

                    return TaskService.GetMyTasks()
                })
                .then((result) => {
                    result = result.filter(one => one.project.id_string == projectId);
                   let doArr = [];
                   result.forEach((one)=>{
                    doArr.push(TaskService.GetTaskActivity(projectId, one.id_string))
                   })
                   return Promise.all(doArr);
                })

                .then((result) => {
                    resData =  [...resData, ...result] 
                    let arr=[];
                        resData.forEach((firstarr) => {
                            if (firstarr != null || firstarr != undefined) {
                                if (firstarr.length == undefined) {
                                    arr.push(firstarr);
                                } else if (firstarr.length == 0) {
                    
                                } else {
                                    firstarr.forEach((secondarr) => {
                                        if (secondarr != null || secondarr != undefined) {
                                            if (secondarr.length == undefined) {
                                                arr.push(secondarr);
                                            } else if (secondarr.length == 0) {
                    
                                            } else {
                                                if(typeof secondarr===typeof []){
                                                    secondarr.forEach((thirdarr) => {
                                                        arr.push(thirdarr);
                                                    })
                                                }
                                                
                                            }
                                        }
                                    })
                                }
                            }
                        })
                  
                    resolve(arr);
                })
                .catch((err) => {
                    reject(err);
                })

        })


    }


    GetProjectActivity(link){
        return new Promise((resolve,reject)=>{
            let config = {
                Type: 'Get',
                method: 'GET',
                url: link,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config : config})
                .then((result) => {
                    resolve(result.data.activities);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }


    GetAllProjects() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://projectsapi.zoho.com/restapi/portal/730397895/projects/",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config:config})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }


    AddActivityLocal(activityFor,activityInfo,changeInfo){
        return new Promise((resolve, reject) => {
            let userInfo=JSON.parse(localStorage.getItem('userinfo'))
            let obj={user_id:userInfo?.user_id,user_name:userInfo?.first_name+" "+userInfo?.last_name,activity_for:activityFor,activity_info:activityInfo,change_info:changeInfo}
            httpService.CreateUpdate('addActivityTime', obj)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    

    GetActivityLocal(pid,tid){
        return new Promise((resolve, reject) => {
            httpService.CreateUpdate('getActivityTime', {DealId:pid,TaskId:tid})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetFolderActivity(folderId){
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://www.zohoapis.com/workdrive/files/"+folderId+"/timeline",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config : config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }




   

}

export default new ActivitieService();