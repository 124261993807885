import React, { useState, useEffect, useRef } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Tabs, Tab, Dropdown } from "react-bootstrap";
import Select from "react-select";
import { Coldefs } from "../../components/Deal/DealListColDef";  
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./body.css";
import {
  faCaretDown,
  faPen,
  faLock,
  faTimes,
  faSearchDollar,
  faChevronCircleRight,
  faChevronCircleDown,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import rightexpand from "../../assets/images/svg/right.svg";
import leftexpand from "../../assets/images/svg/left.svg";
import rightexpandactive from "../../assets/images/svg/rightactive.svg";
import leftexpandactive from "../../assets/images/svg/leftactive.svg";
import leftexpandactivegray from "../../assets/images/svg/left-active-grayunderline.svg";
import blurLeftPng from "../../assets/images/left-blur-1.png";
import GoogleMap from "../../assets/images/maps.jpg";
import Zola from "../../assets/images/Zola.jpg";
import Bisw from "../../assets/images/Bisw.jpg";
import Taxam from "../../assets/images/Taxam.jpg";
import { useParams } from "react-router-dom";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { typeOf } from "mathjs";
import handleUpdate from "../util/updateRecord";
import GetRecordById from "../util/getRecordById";
import Notes from "../../components/Notes/notes";
import Progress from "../../tabs/Progress/Progress";
import Tab_Workdrive from "../../tabs/Workdrive/tab_workdrive";
import TaskService from "../util/TaskService";
import { Popover as AntdPopover } from "antd";
import ProjectService from "../util/ProjectService";
import ModuleService from "../util/ModuleService";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import _ from "lodash";
import validation from "../util/validation";
import queryString from "query-string";
import dayjs from "dayjs";
import { object } from "react-dom-factories";
import { NestedSection } from "./NestedSection";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Email from "../../components/EmailSection/email";

const Body = (props) => {
  const backdrop = "static";
  let history = props.history;
  const location = props.location;
  const params = useParams();
  location.state = params;
  useEffect(() => {
    if(location.state.action=="DetailView"){
      props.setRendering(false);
    }
    if (props.getParams) {
      props.getParams(params);
    }
  }, [location]);
  const [jobData, setJobData] = useState({});
  const [searchData, setSearchData] = useState({});
  const [newObj, setNewObj] = useState({});
  const [dealTopBoxCollapse, setDealTopBoxCollapse] = useState(true);
  const [linksCollapse, setLinksCollapse] = useState(true);
  const [progressToggle, setProgressToggle] = useState(false);
  const [contactToggle, setContactToggle] = useState(false);
  const [documentToggle, setDocumentToggle] = useState(false);
  const [billingToggle, setBillingToggle] = useState(true);
  const [aboutToggle, setAboutToggle] = useState(true);
  const [rightState, setRightState] = useState(false);
  const [leftState, setLeftState] = useState(false);
  const [allClient, setAllClient] = useState([]);
  const [key, setKey] = useState("progress");
  const [jobkey, setJobKey] = useState([]);
  const [SearchedItem, setSearchedItem] = useState("");
  const [jobvalue, setJobValue] = useState([]);
  const [isProjectNameEdtbl, setProjectNameEdtbl] = useState(false);
  const [projectStatus, setProjectStatus] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [allMilstones, setAllMilestone] = useState([]);
  const [tabArr, setTabArr] = useState([
    "Progress",
    "Emails",
    "Notes",
    "Activities",
    "Accounting",
  ]);
  const [remaingTab, setRemaingTab] = useState(["Workdrive"]);
  const [taskLayout, setTaskLayout] = useState({ status_details: [] });
  const [isScroll, setIsScroll] = useState(false);

  const animatedComponents = makeAnimated();
  const status = [
    "On Hold",
    "Proposal",
    "In Progress",
    "Cancelled",
    "Completed",
  ];

  useEffect(() => {
    if (rightState && leftState) {
      setTabArr([
        "Progress",
        "Emails",
        "Notes",
        "Activities",
        "Accounting",
        "Workdrive",
      ]);
      setRemaingTab([]);
    } else {
      setTabArr(["Progress", "Emails", "Notes", "Activities", "Accounting"]);
      setRemaingTab(["Workdrive"]);
    }
  }, [rightState, leftState]);

  useEffect(e=>{
    // console.log("params", params);
    let arr = [];
    arr.push(params.id)
    props.setSelectedIds(arr)
  },[])

  useEffect(() => {
    // TaskService.GetProjectStatus()
    //     .then((result) => {
    //         let arr = []
    //         result.forEach((one) => {
    //             if (arr.length == 0) {
    //                 arr.push(one)
    //             } else {
    //                 if (arr.filter(obj => obj.status_name == one.status_name).length == 0) {
    //                     arr.push(one)
    //                 }
    //             }
    //         })
    //         arr.forEach((obj)=>{
    //             if(obj.status_name=="zp.projstatus.completed"){
    //                 obj.status_name="Completed"
    //             }
    //         })
    //         arr = arr.filter(one => status.includes(one.status_name))
    //         setProjectStatus(arr);
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    TaskService.GetDealStatus()
      .then((res) => {
        setProjectStatus(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getAllClients();
  }, []);

  const getAllClients = () => {
    ModuleService.GetModuleList("Accounts")
      .then((result) => {
        if (result) {
          setAllClient(result.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetTaskLayout = (projectID) => {
    let pid = "1716273000002205035";
    TaskService.GetTaskLayout(projectID)
      .then((result) => {
        setTaskLayout(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (jobData.Project_ID) {
      if (jobData.Project_ID) {
        let pids = jobData.Project_ID;
        pids = pids.split(",");
        pids = pids.filter((id) => id);
        
        GetTaskLayout(pids[0]);
      }

      // ProjectService.GetProjectDetail(jobData.Project_ID)
      //     .then((result) => {
      //         setProjectData(result)
      //     })
      //     .catch((err) => {
      //         console.log(err);
      //     })
      // getAllMilestone(jobData.Project_ID)
    }
  }, [jobData]);

  const getAllMilestone = (projectId) => {
    projectId = projectId.split(",");
    projectId = projectId.filter((id) => id);
    let doarr = [];
    projectId.forEach((id) => {
      doarr.push(ProjectService.GetProjectMilestone(id));
    });
    Promise.all(doarr)
      .then((result) => {
        result = validation.RemoveNestedArray(result);

        setAllMilestone(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let hideArray = [
    "Last_Activity_Time",
    "Tag",
    "Lead_Conversion_Time",
    "NYC_Project",
    "Sales_Cycle_Duration",
    "Overall_Sales_Duration",
    "Locked__s",
    "Certification",
    "Conjunction_Application_Number",
    "Stage",
    "Reason_For_Loss__s",
    "Amount",
    "Expected_Revenue",
    "Type",
    "Next_Step",
    "Probability",
    "Lead_Source",
    "Contact_Name",
    "Campaign_Source",
    "Client",
    "Project_Date",
    "Partial",
    "Change_Log_Time__s",
    "Description",
    "Record_Image",
    "Block",
    "Address",
    "Total_Interconnections",
    "Project_Status_ID",
    "Service_Requested",
    "Borough_Number",
    "Currency",
    "Exchange_Rate",
    "Previous_Solar_Application",
    "Violation_Information",
    "Tax_Abatement",
    "Electrical_Diagram",
    "Asbestos_Investigation",
    "Pipeline",
    "Asbestos",
    "zohoworkdriveforcrm__Workdrive_Folder_URL",
    "zohoworkdriveforcrm__Workdrive_Folder_ID",
    "Number",
    "Story_Number",
    "Notes_ID",
    "Project_Status_ID",
    "Deal_Status",
    "Project_ID",
    "Structural_Letter",
    "Service_Requested_ID",
  ];

  const doSearch = (name) => {
    const fields = { ...newObj };
    if (name !== "") {
      let obj = {};
      Object.keys(fields).forEach((key) => {
        let originalKey = key.replaceAll("sandeepext", "");
        originalKey = originalKey.replaceAll("_", " ");
        originalKey = originalKey.replaceAll("__", " ");
        if (originalKey.toLowerCase().includes(name.toLowerCase())) {
          obj[key] = fields[key];
        }
      });
      setSearchData(obj);
    } else {
      setSearchData(fields);
    }
    setSearchedItem(name);
  };

  useEffect(() => {
    if (props.deleteDealFlag == "Deleted") {
      getJobData("Deals", params.id)
    }
  }, [props.deleteDealFlag])

  const getJobData = (module, id) => {
    GetRecordById(module, id)
      .then((data) => {
        if(data){
          data = { ...data[0] };
          delete data.Subform_1;
          delete data.PV_Interconnection;
          delete data.Service_Requested;
          setJobData(data);
          setSearchData(data);
          setNewObj(data);
        }else{
          props?.setDeleteDealFlag('')
          history.push("/deallist/Deals/listview")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (params) {
      getJobData(params.module, params.id);
      let parsedSearch = queryString.parse(location.search);
      if (parsedSearch && parsedSearch.note) {
        setKey("notes");
      }
      //   setJobData(location.state.data);
      //   setNewObj(location.state.data);
    }
  }, [location]);

  // console.log("jobData", jobData);

  useEffect(() => {
    if (jobData) {
      getJobValues();
    }
  }, [jobData]);
  let borough;
  if (jobData.Borough == "Manhattan" || jobData.Borough == "Manhattann") {
    borough = "1";
  } else if (jobData.Borough == "Bronx") {
    borough = "2";
  } else if (jobData.Borough == "Brooklyn") {
    borough = "3";
  } else if (jobData.Borough == "Queens") {
    borough = "4";
  } else if (jobData.Borough == "Staten Island") {
    borough = "5";
  } else {
    borough = jobData.Borough;
  }

  let googleLink = `https://www.google.com/maps/search/?api=1&query=${jobData.Project_Address}`
  let zolaLink = `https://zola.planning.nyc.gov/l/lot/${borough}/${jobData.Block_Number}/${jobData.Lot_Number}`
  let bisLink =`http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?boro=${borough}&houseno=${jobData.House_Number}&street=${jobData.Street_Name}&go2=+GO+&requestid=2`
  let taxMapLink =`https://propertyinformationportal.nyc.gov/parcels/parcel/${borough}${jobData.Block_Number}${jobData.Lot_Number}`;
  let AcrisLink =`http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${borough}&block=${jobData.Block_Number}&lot=${jobData.Lot_Number}`;
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const changeRightExpand = () => {
    setRightState(!rightState);
  };

  const changeLeftExpand = () => {
    setLeftState(!leftState);
  };

  const getDateForDeal = (date) => {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return month + "/" + day + "/" + year;
  };

  const [textVisib, setTextVisible] = useState("");
  const [textKey, setTextKey] = useState("");

  const [popoverOpen, setPopoverOpen] = useState(false);

  const editValues = (evnt, e, key) => {
    key = key.split(" ").join("");
    let obj = { ...newObj };

    if (key !== "undefined" && key !== undefined) {
      obj[key] = evnt.target.value;
    }
    setNewObj(obj);
  };

  const handleOnChange = (e) => {
    const dealObj = { ...newObj };
    dealObj[e.currentTarget.name] = e.currentTarget.value;

    setNewObj(dealObj);
  };

  const spanClickHandler = (value, id, e) => {
    // if (id !== "Project Number") {
    // console.log("line 138 id ", id);
    setTextKey(id);
    setTextVisible(value);
    //   setProjectNameEdtbl(true);
    setPopoverOpen(true);
    //   initPlaceAPI()

    // }
  };

  const getProgressStatuses = (milestones) => {
    milestones = [...milestones];
    milestones = milestones.filter(
      (one) => one.status_det && one.status_det.name == "Completed"
    );
    milestones = _.groupBy(milestones, function (one) {
      return one.project.name;
    });
    let keys = Object.keys(milestones);
    let arr = [];
    keys.forEach((key) => {
      let obj = { name: key, data: milestones[key] };
      obj.data = obj.data.sort(
        (a, b) =>
          new Date(b.completed_date_long) - new Date(a.completed_date_long)
      );
      obj.data = [obj.data[0]];
      // obj.data = obj.data.sort((a, b) => new Date(a.created_time_long) - new Date(b.created_time_long))
      arr.push(obj);
    });
    return arr;
  };

  const handleSaveDeal = () => {
    // const services = [...serviceReq]
    let dealObj = { ...newObj };
    handleUpdate("Deals", dealObj);
    setJobData({ ...dealObj });
    setSearchData({ ...dealObj });
    setNewObj({ ...dealObj });
    setPopoverOpen(false);
    setTextKey("");
    // setProjectNameEdtbl(false)
    // setActivityDetails([]);
  };

  const getJobValues = () => {
    // if (!isArray(props.job)) {
    let keys = Object.keys(jobData);
    setJobKey(keys);
    let arrValue = [];
    keys.forEach((key) => {
      arrValue.push(jobData[key]);
    });
    // let val = Object.values(props.job)
    setJobValue(arrValue);
    // }
  };

  const getViewTabs = (tab) => {
    switch (tab) {
      case "Progress":
        return (
          <Tab eventKey="progress" title="Progress">
            <Progress
              loader={props.loader}
              setLoader={props.setLoader}
              invertColor={props.invertColor}
              dealColor={getStatusColor()}
              getNotification={props.getNotification}
              taskLayout={taskLayout}
              refreshEnable={props.refreshEnable}
              setRefreshEnable={props.setRefreshEnable}
              history={props.history}
              setTimerPrevTimeTrack={props.setTimerPrevTimeTrack}
              timerObj={props.timerObj}
              timerTimeTrack={props.timerTimeTrack}
              startstopClockTimeTrack={props.startstopClockTimeTrack}
              setTimerTimeTrack={props.setTimerTimeTrack}
              jobData={jobData}
              timeTrackFlag={props.timeTrackFlag}
              initializeTimeTrack={props.initializeTimeTrack}
              allMilstones={allMilstones}
              getAllMilestone={getAllMilestone}
              isView={props.isView}
              isAccess={props.isAccess}
              selectedRole={props.selectedRole}
              allDeals={props.allDeals}
              dealsId={""}
              dealObj={newObj}
              searchTask={props.searchTask}
            />
          </Tab>
        );
      case "Emails":
        return (
          <Tab eventKey="emails" title="Emails">
           <Email
            // warningFlag={props.warningFlag}
            // setWarningFlag={props.setWarningFlag}
            // {...props}
            clickedFromBody={true}
            location={props.location}
            handleNotificationHyperlink={props.handleNotificationHyperlink}
            allTask={props.allTask}
            allUsers={props.allUsers}
            cacheDeals={props.cacheDeals}
            refreshEnable={props.refreshEnable}
            setRefreshEnable={props.setRefreshEnable}
            getParams={props.getParams} 
            dealObj={newObj}
            />
          </Tab>
        );
      case "Notes":
        return (
          <Tab eventKey="notes" title="Notes">
            {key == "notes" ? (
              <Notes
                location={location}
                getNotification={props.getNotification}
                refreshEnable={props.refreshEnable}
                setRefreshEnable={props.setRefreshEnable}
                history={history}
                job={jobData}
                module={params.module}
              />
            ) : (
              <></>
            )}
          </Tab>
        );
      case "Activities":
        return (
          <Tab eventKey="activities" title="Activities">
            {/* <Activities data={jobData} /> */}
          </Tab>
        );
      case "Accounting":
        return (
          <Tab eventKey="accounting" title="Accounting">
            Accounting
          </Tab>
        );
      case "Workdrive":
        return (
          <Tab eventKey="workdrive" title="Workdrive">
            <Tab_Workdrive
              refreshEnable={props.refreshEnable}
              setRefreshEnable={props.setRefreshEnable}
              data={jobData}
            />
          </Tab>
        );
      default:
        return <></>;
    }
  };

  const resetData = () => {
    let latestObj = { ...jobvalue };
    setJobValue(latestObj);
  };

  const getAutoSaveDeal = (e) => {
    if (popoverOpen) {
      return (
        <div
          // className="dealInfo-btnGrp"
          className={textVisib == jobvalue[e] ? "dealInfo-btnGrp" : "d-none"}
          isOpen={popoverOpen}
        >
          <button className="btn btn-white" onClick={handleSaveDeal}>
            Save
          </button>
          <button
            className="btn btn-white btn-outline ml-2"
            onClick={() => {
              setPopoverOpen(false);
              setTextKey("");
              //   updateDeals(jobvalue);
              resetData();
              getJobValues();
              // setProjectNameEdtbl(true)
            }}
          >
            Cancel
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const firstArr = [
    "Deal_Name",
    "Closing_Date",
    "Account_Name",
    "Project_Address",
    "Project_Status",
    "House_Number",
    "Street_Name",
    "City",
    "State",
    "Zip_Code",
    "Latitude",
    "Longitude",
    "Is_NYC_Project",
    "Borough",
    "Service_Requested_Name",
    "Block_Number",
    "Lot_Number",
    "Bin_Number",
    "Community_Board_Number",
    "Occupancy_Classification_Building_2014_2008_Code",
    "Occupancy_Classification_of_Building",
    "Construction_Classification_Code_2014_2008_Code",
    "Construction_Classification_Code",
    "Multiple_Dwelling_Classification",
    "Number_of_Dwelling_Units",
    "Building_Height",
    "Number_of_Stories",
    "Zoning_District",
    "Commercial_Overlay",
    "Specials_Districts",
    "Zoning_Map_Number",
    "Tidal_Wetlands",
    "Fresh_Water_Wetland",
    "Flood_Hazard",
    "Coastal_Erosion_Hazard",
    "Special_Fire_District",
    "Landmark_Area",
    "Environmental_Restriction",
    "Conjunction",
    "Conjunction_Application_Number",
    "Stop_Work_Order_Exists_on_Property",
    "Active_Work_Without_Permit_Violations",
    "Asbestos_Required",
    "ACP5_Completed",
    "Asbestos_Inspector_Certificate_Number",
    "ACP5_Control_Number",
    "Certificate_of_Occupancy_Available",
    "NB_Application_Number",
    "Owner_Type",
    "Property_Owner_s_Name",
    "Owner_s_First_Name",
    "Owner_s_Middle_Initial",
    "Owner_s_Last_Name",
    "Owner_Phone_Number",
    "Owner_s_Email_Address",
    "Owner_s_Mailing_Address_Diff_Than_Property_Address",
    "Owner_s_Mailing_Address",
    "Owner_s_Street_Name",
    "Owner_s_House_Number",
    "Owner_s_City",
    "Owner_s_State",
    "Owner_s_Zip_Code",
    "Non_Profit",
    "DHCR",
    "Other_Owner_Company_Name",
    "Other_Owner_Mailing_Address",
    "Other_Owner_Street_Name",
    "Other_Owner_House_Number",
    "Other_Owner_City",
    "Other_Owner_State",
    "Other_Owner_Zip_Code",
    "Other_Owner_Officer_First_Name",
    "Other_Owner_Officer_Middle_Initial",
    "Other_Owner_Officer_Last_Name",
    "Other_Owner_Officer_Title",
    "Other_Owner_Phone_Number",
    "Other_Owner_Email_Address",
    "Condo_Company_Name",
    "Condo_Mailing_Address",
    "Condo_Street_Name",
    "Condo_House_Number",
    "Condo_City",
    "Condo_State_Name",
    "Condo_Zip_Code",
    "Condo_Officer_First_Name",
    "Condo_Officer_Middle_Initial",
    "Condo_Officer_Last_Name",
    "Condo_Officer_Title",
    "Condo_Phone_Number",
    "Condo_Email_Address",
    "Applicant",
    "General_Contractor",
    "Electrician",
    "Number_of_Interconnection",
    "Total_System_Size",
    "Total_Modules",
    "PV_Interconnection",
    "Project_Cost",
    "Owner",
    "Created_By",
    "Created_Time",
    "Modified_By",
    "Modified_Time",
  ];
  const getDealFields = () => {
    let keys = Object.keys(searchData);
    keys.sort();
    let secondArr = [];
    if (SearchedItem) {
    } else {
      for (let i = 0; i < keys.length; i++) {
        if (!firstArr.includes(keys[i])) {
          secondArr.push(keys[i]);
        }
      }
      keys = [...firstArr, ...secondArr];
    }
    keys = keys.filter((e) => !e.includes("Block_Number0"));
    return keys;
  };

  const getStyleExpandIco = () => {
    if (rightState) {
      if (leftState) {
        return { paddingLeft: "12px", paddingRight: "12px" };
      } else {
        return { paddingRight: "12px" };
      }
    } else if (leftState) {
      if (rightState) {
        return { paddingLeft: "12px", paddingRight: "12px" };
      } else {
        return { paddingLeft: "12px" };
      }
    } else if (leftState && rightState) {
      return { paddingLeft: "12px", paddingRight: "12px" };
    } else {
      return {};
    }
  };

  const updateProject = (key, value, name) => {
    ProjectService.UpdateProject(jobData.Project_ID, key, value)
      .then((result) => {
        setProjectData(result);
        return handleUpdate("Deals", {
          id: jobData.id,
          Project_Status: name,
          Project_Status_ID: value,
        });
      })
      .then((result) => {
      
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gotoClientPage = () => {
    let clients = [...allClient];
    clients = clients.filter((one) => one.id == newObj.Account_Name.id);
    if (clients.length > 0) {
      clients = clients[0];
      history.push({
        pathname: "/c/Accounts/DetailView/" + clients.id,
        state: { data: clients, action: "Update", module: "Accounts" },
      });
    }
  };

  const getDealStatus = () => {
    let arr = [];
    projectStatus.forEach((one) => {
      let obj = {
        name: one.display_value,
        color: one.colour_code,
        id: one.projectId,
      };
      arr.push(obj);
    });
    return arr;
  };

  const getGroupVal = (groupValue) => {
    if (groupValue) {
      groupValue = groupValue.replaceAll(newObj.Deal_Name, "");
      groupValue = groupValue.replace("-", "");
      return groupValue;
    }
  };

  const getClientColor = (clientId) => {
    let clients = [...allClient];
    clients = clients.filter((one) => one.id == clientId);
    if (clients.length > 0) {
      return {
        backgroundColor: clients[0].Color ? clients[0].Color : "#5b6c97", color: clients[0].Color ? props.invertColor(clients[0].Color, true) : "#fff"
      };
    } else {
      return { backgroundColor: "#5b6c97", color: props.invertColor('#5b6c97', true)};
    }
  };

  const tilesColumn = ["Deal_Name", "Account_Name", "Client", "Project_Status"];

  const getTilesStatusDropdown = (data, key, dataType) => {
    if (dataType == "String") {
      if (tilesColumn.includes(key)) {
        if (key == "Project_Status") {
          return (
            <StatusDropdown
              isScroll={isScroll}
              invertColor={props.invertColor}
              module="Deal"
              toggelButton={
                <span
                  style={
                    newObj.Stage
                      ? { backgroundColor: getStatusColor(), color : getStatusTextColor() }
                      : { backgroundColor: "lightgray" }
                  }
                  className="project_status_custom"
                >
                  {newObj.Stage ? newObj.Stage : "N/A"}
                  <FontAwesomeIcon
                    style={{ marginLeft: "30px" }}
                    icon={faCaretDown}
                  />
                </span>
              }
              items={getDealStatus()}
              onSelect={(value) => {
                handleUpdate("Deals", { id: newObj.id, Stage: value.name })
                  .then((result) => {
                    getJobData("Deals", newObj.id);
                    // setJobData(result);
                    // setNewObj(result);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                // updateProject("custom_status", value.id, value.name)
                // updateTask(data, "custom_status", value.id)
              }}
            />
          );
          // return <StatusDropdown toggelButton={
          //     <span style={projectData.custom_status_color ? { backgroundColor: projectData.custom_status_color } : { backgroundColor: 'lightgray' }} className="project_status_custom">{projectData.custom_status_name ? projectData.custom_status_name : "N/A"} <FontAwesomeIcon style={{ marginLeft: '30px' }} icon={faCaretDown} /></span>

          // } items={getDealStatus()} onSelect={(value) => {
          //     updateProject("custom_status", value.id, value.name)
          //     // updateTask(data, "custom_status", value.id)
          // }} />
        } else {
          return (
            <StatusDropdown
            invertColor={props.invertColor}
            module="Deal"
              toggelButton={
                <span
                  style={{ backgroundColor: "#5b6c97", color : props.invertColor('#5b6c97', true) }}
                  className="project_status_custom"
                >
                  {data[key] ? data[key] : "N/A"}
                </span>
              }
              items={[]}
              onSelect={(value) => {
                // updateProject("custom_status", value.id, value.name)
                // updateTask(data, "custom_status", value.id)
              }}
            />
          );
        }
      } else {
        return data[key];
      }
    }
    if (dataType == "Object") {
      if (tilesColumn.includes(key)) {
        return (
          <StatusDropdown
          module="Deal"
            toggelButton={
              <span
                style={getClientColor(
                  data.Account_Name ? data.Account_Name.id : ""
                )}
                className="project_status_custom"
              >
                {data[key] ? data[key].name : "N/A"}{" "}
              </span>
            }
            items={[]}
            onSelect={(value) => {
              // updateProject("custom_status", value.id, value.name)
              // updateTask(data, "custom_status", value.id)
            }}
            invertColor={props.invertColor}
          />
        );
      } else {
        return data[key].name;
      }
    }
  };

  const getStatusColor = () => {
    let filterColor = projectStatus.filter(
      (one) => one.display_value == newObj.Stage
    );
    if (filterColor.length > 0) {
      return filterColor[0].colour_code;
    } else {
      return "lightgray";
    }
  };

  const getStatusTextColor = () => {
    let filterColor = projectStatus.filter(
      (one) => one.display_value == newObj.Stage
    );
    if (filterColor.length > 0) {
      return props.invertColor(filterColor[0].colour_code, true)
    } else {
      return "#fff";
    }
  };


  //new code
  const getNestedFeildsBySection=(section)=>{
      let returnComp= getDealFields().map((key, e) => {
      let feildPresentInSection=Coldefs[section].find(e=>e.field==key)
      if (!hideArray.includes(key) && feildPresentInSection) {
        let originalKey = key.replaceAll("sandeepext", "");
        originalKey = originalKey.replaceAll("_", " ");
        originalKey = originalKey.replaceAll("__", " ");
        let value;
        if (key == "Closing_Date") {
          value = dayjs(jobData[key]).format("MM-DD-YYYY");
        } else {
          value = jobData[key];
        }

        let valType = typeOf(value);
        return (
          <>
            {originalKey.includes("$") ? (
              ""
            ) : (
              <>
                <p className="abt-deal-field">
                  <strong className="custom-label-job-head">
                    {originalKey}:
                  </strong>
                  {textKey === originalKey &&
                  jobData[key] != "Yes" &&
                  jobData[key] != "No" ? (
                    <input
                      type="text"
                      // type={getTagType(key)}
                      defaultValue={textKey==originalKey&& typeOf(jobData[key])=="Object"?jobData[key].name:jobData[key]}
                      onChange={(event) => {
                        editValues(event, jobData[key], key);
                      }}
                    />
                  ) : textKey == originalKey &&
                    (jobData[key] == "Yes" ||
                      jobData[key] == "No") ? (
                    <select
                      className="select-client"
                      name={key}
                      onChange={handleOnChange}
                      defaultValue={jobData[key]}
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  ) : (
                    <span>
                      {valType == "Object"
                        ? getTilesStatusDropdown(
                            jobData,
                            key,
                            "Object"
                          )
                        : getTilesStatusDropdown(
                            jobData,
                            key,
                            "String"
                          )}
                    </span>
                  )}
                  {/* {originalKey === "Owner" ||
                  originalKey == "Deal Name" ||
                  originalKey == "Client" ||
                  originalKey == "Account Name" ||
                  originalKey == "Project Address" ? (
                    <FontAwesomeIcon icon={faLock} />
                  ) : originalKey === "Project Status" ? (
                    <></>
                  ) : (
                    <FontAwesomeIcon
                      icon={faPen}
                      id={originalKey}
                      onClick={() => {
                        spanClickHandler(
                          jobData[key],
                          originalKey
                        );
                      }}
                    />
                  )} */}
                </p>
              </>
            )}

            {getAutoSaveDeal(e)}
          </>
        );
      }
    })
     
    returnComp=returnComp?.filter(e=>e!=undefined);
    return returnComp;
  }
  const getSections=()=>{
    return Object.keys(Coldefs).map((sectionKey,i)=>{
      if(sectionKey=="Hide" || sectionKey == "Pined_Column"){
        return
      }
      return (
        <NestedSection
          sectionName={sectionKey}
          getNestedFeildsBySection={getNestedFeildsBySection}
        />
      )
    })
   
  }
  

  return (
    <div className="pad-col ">
      {/* <input type='text' ref={placeInputRef1}/> */}
      <Row className="row-mar-0">
        <div className={leftState ? "display-none" : "widthper-21"}>
          <div className="col-3-border outer-border-3">
            <div
              className="collapse-body-tiles"
              onClick={() => setDealTopBoxCollapse(!dealTopBoxCollapse)}
            >
              {dealTopBoxCollapse ? (
                <FontAwesomeIcon
                  className="mar-right-5"
                  icon={faChevronCircleDown}
                />
              ) : (
                <FontAwesomeIcon
                  className="mar-right-5"
                  icon={faChevronCircleRight}
                />
              )}
              Information
            </div>
            <div className="collapse-body-tiles-border"></div>
            <Collapse className="contact-roles" isOpen={dealTopBoxCollapse}>
              <div className="deal-details-topbox">
                <p style={{ display: "flex" }}>
                  <strong className="custom-label-job-head">Client:</strong>
                  <StatusDropdown
                  module="Deal"
                    invertColor={props.invertColor}
                    toggelButton={
                      <span
                        onClick={() => gotoClientPage()}
                        style={getClientColor(
                          jobData.Account_Name ? jobData.Account_Name.id : ""
                        )}
                        className="project_status_custom"
                      >
                        {jobData && jobData.Account_Name
                          ? jobData.Account_Name.name
                          : "N/A"}{" "}
                      </span>
                    }
                    items={[]}
                    onSelect={(value) => {
                      // updateProject("custom_status", value.id, value.name)
                      // updateTask(data, "custom_status", value.id)
                    }}
                  />
                  {/* <span
                                        className="client-text-style cursor-point"
                                        onClick={() => gotoClientPage()}

                                    >{jobData && jobData.Account_Name ? jobData.Account_Name.name : ""}
                                    </span> */}
                </p>
                <p>
                  <strong className="custom-label-job-head">
                    Closing Date:
                  </strong>
                  <span>
                    {dayjs(jobData.Closing_Date).format("MM-DD-YYYY")}
                  </span>
                </p>
                <p>
                  <strong className="custom-label-job-head">
                    Property Owner's Name:
                  </strong>
                  <span>{"N/A"}</span>
                </p>
                <p>
                  <strong className="custom-label-job-head">
                    Project Address:
                  </strong>
                  <span style={{ width: 232 }} className="text-truncate">
                    {jobData.Project_Address
                      ? jobData.Project_Address.split(",")[0]
                      : "N/A"}
                  </span>{" "}
                  <br />
                  <span style={{ width: 232 }} className="text-truncate">
                    {jobData.Project_Address
                      ? jobData.Project_Address.split(",")[1]
                      : "N/A"}
                  </span>
                  <span style={{ width: 232 }} className="text-truncate">
                    {jobData.Project_Address
                      ? jobData.Project_Address.split(",")[2]
                      : "N/A"}
                  </span>
                </p>
                <p>
                  <strong className="custom-label-job-head">
                    Project Status:
                  </strong>
                  <StatusDropdown
                  module="Deal"
                    invertColor={props.invertColor}
                    toggelButton={
                      <span
                        style={
                          jobData.Stage
                            ? {
                                backgroundColor: getStatusColor(),
                                color: getStatusTextColor(),
                              }
                            : { backgroundColor: "lightgray" }
                        }
                        className="project_status_custom"
                      >
                        {newObj.Stage ? newObj.Stage : "N/A"}{" "}
                        <FontAwesomeIcon
                          style={{ marginLeft: "30px" }}
                          icon={faCaretDown}
                        />
                      </span>
                    }
                    items={getDealStatus()}
                    onSelect={(value) => {
                      handleUpdate("Deals", {
                        id: jobData.id,
                        Stage: value.name,
                      })
                        .then((result) => {
                          getJobData("Deals", jobData.id);
                          // setJobData(result);
                          // setNewObj(result);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                      // updateProject("custom_status", value.id, value.name)
                      // updateTask(data, "custom_status", value.id)
                    }}
                  />
                </p>
                {/* <p>
                <strong className="custom-label-job-head">Deal Status:</strong>
                <span className="padl-14 inprogress-text">In Progress:<FontAwesomeIcon
                  className="mar-Inprogess-icon"
                  icon={faCaretDown}
                /></span>
              </p>
              <p>
                <strong className="custom-label-job-head">Last Action:</strong>  <span className="padl-10">DOB submitted</span>
              </p> */}
              </div>
            </Collapse>
            <div
              className="collapse-body-tiles"
              onClick={() => {
                setAboutToggle(!aboutToggle);
              }}
            >
              {aboutToggle ? (
                <FontAwesomeIcon
                  className="mar-right-5"
                  icon={faChevronCircleDown}
                />
              ) : (
                <FontAwesomeIcon
                  className="mar-right-5"
                  icon={faChevronCircleRight}
                />
              )}
              About This Deal
            </div>
            <div className="collapse-body-tiles-border"></div>

            <div className=" ">
              <div className="deal-details-bottumbox">
                <div class="head-list-style about-deals-collapse font-14">
                  <Collapse isOpen={aboutToggle}>
                    <div className="position-relative about-deal-search">
                      <input
                        type="text"
                        placeholder="Search"
                        className="pad-fix search-filter-deals"
                        value={SearchedItem}
                        onChange={(e) => doSearch(e.target.value)}
                      />
                      {SearchedItem ? (
                        <FontAwesomeIcon
                          onClick={() => setSearchedItem("")}
                          className="search-ico2"
                          icon={faTimes}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="search-ico2"
                          icon={faSearchDollar}
                        />
                      )}
                    </div>
                    <div
                      style={
                        dealTopBoxCollapse
                          ? { height: "calc(100vh - 520px)" }
                          : { minHeight: "calc(100vh - 260px)" }
                      }
                      className="about-this-deal-content"
                      onScroll={(e) => setIsScroll(!isScroll)}
                    >
                      {getSections()}
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={getStyleExpandIco()}
          className={
            rightState && leftState
              ? "widthper-100 pos-relative"
              : rightState || leftState
              ? rightState
                ? "pad-l0 widthper-79 pos-relative"
                : "pad-r0 widthper-79 pos-relative"
              : "pad-l0 pad-r0 widthper-58 pos-relative"
          }
        >
          {rightState ? (
            <img
              src={rightexpandactive}
              className="mid-right-icon-active"
              onClick={changeRightExpand}
            />
          ) : (
            <img
              src={rightexpand}
              className="mid-right-icon"
              onClick={changeRightExpand}
            />
          )}
          {leftState ? (
            key != "progress" ? (
              <img
                src={leftexpandactivegray}
                className="mid-left-icon-active"
                onClick={changeLeftExpand}
              />
            ) : (
              <img
                src={leftexpandactive}
                className="mid-left-icon-active"
                onClick={changeLeftExpand}
              />
            )
          ) : key != "progress" ? (
            <img
              src={blurLeftPng}
              className="mid-left-icon"
              onClick={changeLeftExpand}
            />
          ) : (
            <img
              src={leftexpand}
              className="mid-left-icon"
              onClick={changeLeftExpand}
            />
          )}

          <div className="outer-border-6 task-group-tabs">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="job-parent-tabs"
            >
              {tabArr.map((tab) => getViewTabs(tab))}
            </Tabs>
            {remaingTab.length > 0 ? (
              <AntdPopover
                placement="bottom"
                trigger={"click"}
                content={
                  <>
                    <div className="row task-filter-hovr more-tab-icon">
                      {remaingTab.map((tab, index) => {
                        return (
                          <div
                            onClick={() => {
                              const tabs = [...tabArr];
                              const remTab = [...remaingTab];
                              let remainTab = tabs[tabs.length - 1];
                              tabs[tabs.length - 1] = tab;
                              remTab[index] = remainTab;
                              setRemaingTab(remTab);
                              setTabArr(tabs);
                              setKey(tab.toLowerCase());
                            }}
                            className="col-sm-9"
                          >
                            <a>{tab}</a>
                          </div>
                        );
                      })}
                    </div>
                    {/* <hr className="hrcls"></hr> */}
                  </>
                }
              >
                <FontAwesomeIcon
                  className="more-tab-body-icon"
                  icon={faEllipsisV}
                />
              </AntdPopover>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className={rightState ? "display-none" : "widthper-21 "}>
          <div className="col-3-border outer-border-3">
            <div className="job-rightbox-overflow">
              <div className="deal-details-right-topbox">
                <div
                  class="head-list-style font-14 ml-3 collapse-body-tiles collapse-body-tiles-right-links"
                  onClick={() => {
                    setLinksCollapse(!linksCollapse);
                  }}
                >
                  {linksCollapse ? (
                    <FontAwesomeIcon
                      className="mar-right-5"
                      icon={faChevronCircleDown}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="mar-right-5"
                      icon={faChevronCircleRight}
                    />
                  )}
                  Links
                </div>
                <p
                  style={{ width: "100%" }}
                  className="collapse-body-tiles-border collapse-body-tiles-right-links"
                ></p>
                <Collapse isOpen={linksCollapse}>
                  <div>
                    <ul className="deal-dash-link-wrapper">
                      <li>
                        <a
                          href={zolaLink}
                          target="_blank"
                          className={jobData.Is_NYC_Project == "Yes" ? "" : "deasble-link"}
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="deal-dash-link"
                            viewBox="0 0 512 512"
                          >
                            <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                          </svg>
                          <span>Zola</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={googleLink}
                          target="_blank"
                          className={jobData.Is_NYC_Project == "Yes" ? "" : "deasble-link"}
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="deal-dash-link"
                            viewBox="0 0 512 512"
                          >
                            <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                          </svg>
                          <span>Google Map</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={taxMapLink}
                          target="_blank"
                          className={jobData.Is_NYC_Project == "Yes" ? "" : "deasble-link"}
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="deal-dash-link"
                            viewBox="0 0 512 512"
                          >
                            <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                          </svg>
                          <span>Digital Tax Map</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={bisLink}
                          target="_blank"
                          className={jobData.Is_NYC_Project == "Yes" ? "" : "deasble-link"}
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="deal-dash-link"
                            viewBox="0 0 512 512"
                          >
                            <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                          </svg>
                          <span>Bis</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={AcrisLink}
                          className={jobData.Is_NYC_Project == "Yes" ? "" : "deasble-link"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="deal-dash-link"
                            viewBox="0 0 512 512"
                          >
                            <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                          </svg>
                          <span>ACRIS</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </div>
              <div className="deal-details-right-midbox">
                <div
                  class="head-list-style font-14 collapse-body-tiles collapse-body-tiles-right-links"
                  onClick={() => {
                    setProgressToggle(!progressToggle);
                  }}
                >
                  {progressToggle ? (
                    <FontAwesomeIcon
                      className="mar-right-5"
                      icon={faChevronCircleDown}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="mar-right-5"
                      icon={faChevronCircleRight}
                    />
                  )}
                  Progress Statuses
                </div>
                <div
                  style={{ width: "100%" }}
                  className="collapse-body-tiles-border collapse-body-tiles-right-links"
                ></div>
                <Collapse isOpen={progressToggle}>
                  {getProgressStatuses(allMilstones).map((one) => {
                    return one.data.map((milestone) => {
                      return (
                        <p className="mb-0 ">
                          <strong>{getGroupVal(one.name)} : </strong>
                          <strong>{milestone.name}</strong>
                        </p>
                      );
                    });
                  })}
                </Collapse>
              </div>

              <div className="deal-details-right-midbox pr-2 mt-2">
                <div
                  class="head-list-style font-14  align-items-center justify-content-between "
                  onClick={() => {
                    setContactToggle(!contactToggle);
                  }}
                >
                  <div className="collapse-body-tiles collapse-body-tiles-right-links">
                    {contactToggle ? (
                      <FontAwesomeIcon
                        className="mar-right-5"
                        icon={faChevronCircleDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="mar-right-5"
                        icon={faChevronCircleRight}
                      />
                    )}
                    Contact Roles
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="collapse-body-tiles-border collapse-body-tiles-right-links"
                  ></div>
                </div>

                <Collapse className="contact-roles" isOpen={contactToggle}>
                  <div className="client-contact-role">
                    <strong className="contact-role-label">Client </strong>
                    <button
                      onClick={() => {
                        // if (props.isAccess(props.selectedRole, "Deals", "Write")) {
                        //     handleAddPosition('Client');
                        // }
                        // else {
                        //     swal("You Don't have sufficient privileges to perform this task");
                        // }
                      }}
                      className="btn btn-sm btn-primary float-right add-contactRole-btn"
                    >
                      Add
                    </button>
                    <br />
                    {/* {clientPositionsValue.map((one) => {
                                            if (one.enable) {
                                                 return getClientPositionSelect(one, 'Client');
                                            }
                                        })} */}
                  </div>

                  <div className="vendor-contact-role mt-2">
                    <strong className="contact-role-label">Vendor </strong>
                    <button
                      onClick={() => {
                        // if (props.isAccess(props.selectedRole, "Deals", "Write")) {
                        //     handleAddPosition('Vendor')
                        // }
                        // else {
                        //     swal("You don't have sufficient privileges to perform this task", { dangerMode: true })
                        // }
                      }}
                      className="btn btn-sm btn-primary float-right add-contactRole-btn "
                    >
                      Add
                    </button>
                    {/* {vendorPositionsValue.map((one) => {
                                            if (one.enable) {
                                                return getClientPositionSelect(one, 'Vendor');
                                            }
                                        })} */}
                  </div>

                  <div className="employee-contact-role mt-2">
                    <strong className="contact-role-label">Employee </strong>
                    <Select
                      options={[]}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option, MultiValue, animatedComponents }}
                      onChange={(values) => {
                        // if (props.isAccess(props.selectedRole, "Deals", "Write")) {
                        //     handleContactRolesChange('Employee', values)
                        // }
                        // else {
                        //     swal("You don't have sufficient privileges to perform this task", { dangerMode: true });
                        // }
                      }}
                      allowSelectAll={true}
                      value={[]}
                      menuPlacement="auto"
                      className="form-group"
                      // onChange={handleServiceChange}
                      // value={getValues()}
                      // isMulti={true}
                      // options={options}
                      // isClearable={false}
                    />
                  </div>
                </Collapse>
              </div>

              {/* <div className="deal-details-right-midbox">
                                <div
                                    class="head-list-style font-14"

                                >
                                    {documentToggle ? (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faCaretDown}
                                            onClick={() => {
                                                setDocumentToggle(!documentToggle);
                                            }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className="mar-right-5"
                                            icon={faCaretRight}
                                            onClick={() => {
                                                setDocumentToggle(!documentToggle);
                                            }}
                                        />
                                    )}
                                    Documents
                                    <Dropdown
                                        className="mr-2 text-right float-right"
                                    >
                                        <Dropdown.Toggle
                                            id="dropdown-createnew"
                                            className="add-contactRole-btn"
                                        >
                                            Add
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                // if (props.isAccess(props.selectedRole, "Deals", "Write")) {
                                                //     handleUploadFiles()
                                                // }
                                                // else {
                                                //     swal("You don't have sufficient privileges to perform this task")
                                                // }
                                            }}>
                                                Upload Files
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div> */}

              <div className="deal-details-bottumbox p-0 mt-2">
                <div
                  class="head-list-style font-14 collapse-body-tiles collapse-body-tiles-right-links"
                  onClick={() => {
                    setBillingToggle(!billingToggle);
                  }}
                >
                  {billingToggle ? (
                    <FontAwesomeIcon
                      className="mar-right-5"
                      icon={faChevronCircleDown}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="mar-right-5"
                      icon={faChevronCircleRight}
                    />
                  )}
                  Biling Status
                </div>
                <div
                  style={{ width: "100%" }}
                  className="collapse-body-tiles-border collapse-body-tiles-right-links"
                ></div>
                <Collapse isOpen={billingToggle}>
                  <p>
                    <span>Biling Status Incomplete</span>
                  </p>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};
export default Body;
