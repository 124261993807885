import React, { useContext, useEffect, useState } from 'react'
import './Reminders.css'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faArrowCircleUp, faCaretUp, faCheck, faCircle, faDotCircle, faEnvelope, faFilter, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { OtsideClickClosingWrapper } from '../../common/EmojiReactionPicker/OtsideClickClosingWrapper'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { AllPaths, convertToUSTime, modifyTimezoneString } from '../HR/TimeClock/Util'
import ReminderSvg from '../../../src/assets/images/svg/AlarmReminder.svg';
import SettingIcon from '../../../src/assets/images/svg/SettingIcon.svg';
import {
    Tabs,
    Tab,
} from "react-bootstrap";
import AppContext from '../../Context/AppContext'
import { Button } from 'react-bootstrap';
import { Popover as AntdPopover ,Dropdown as AntDropDown,Switch as AntSwitch} from "antd";
import { forwardObject } from '../../Context/AppActions'
import dayjs from 'dayjs'
import swal from 'sweetalert';
import AwsServerService from '../../common/util/AwsServerService'
import AddColumn from '../../common/AddColumn/add_column'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
export const Reminders = ({ children, title, headerIcon, data, history }) => {
    const [open, setOpen] = useState(false);
    const [tabKey, setTabKey] = useState("Active");
    const AppData = useContext(AppContext);
    const [openSnooze,setOpenSnooze]=useState({id:null,open:false});
    const { dispatch, state } = AppData || {};
    const [cacheData, setCacheData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [notificationList, setNotificationList] = useState([]);
    const [notificationFilter, setNotifcationFilter] = useState([
        { name: "Calendar", isVisible: true, isSearch: true },
        { name: "Email", isVisible: true, isSearch: true },
      ]);
    const [snoozeTime,setSnoozeTime]=useState(null);
    useEffect(e=>{
        setOpenSnooze({id:null,open:false})
    },[open])
    useEffect(e=>{
        if(data){
            setCacheData(data);
        }else{
            setCacheData([]);
        }
    },[data])
    useEffect(e=>{
        getNotificationRender();
    },[cacheData,notificationFilter,searchText])
    const getIconByModule = (module) => {
        if (module == "Calendar") {
            return <FontAwesomeIcon icon={faCalendarAlt} />
        } else if (module == "Email") {
            return <FontAwesomeIcon icon={faEnvelope} />
        }
    }
    const handleDelete=(data)=>{
        swal({
            title: "Are you sure?",
            text: "You want to delete the reminder!",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
          }).then(function (isConfirm) {
            if (isConfirm) {
                AwsServerService.DeleteReminders([data.ROWID],"ROWID").then(res=>{
                     
                    console.log(res);
                }).catch(e=>{
                    console.log(e);
                })
            }
          });
    }
    const handleCompleted=(data)=>{
        let payLoad={
            ROWID:data.ROWID,
            triggered:"false",
        };
        payLoad.status="true";
        payLoad.triggered="false";
        AwsServerService.UpdateReminders(payLoad).then(res=>{
             
            console.log(res);
        }).catch(e=>{
            console.log(e);
        })
    }
    const handleSnooze=(data,time,custome)=>{
         
        let payLoad={
            ROWID:data.ROWID,
            triggered:"false",
        };
        if(!custome){
            payLoad.reminderTime=convertToUSTime(dayjs(modifyTimezoneString(data.reminderTime,"MM/DD/YYYY HH:mm")).add(time,"minutes"));
        }else{
            if(!time){
                time=dayjs().add(10, "minutes").format("HH:mm");
            }
            let tempDateStr=modifyTimezoneString(data.reminderTime,"MM/DD/YYYY")+" "+time;
            payLoad.reminderTime=convertToUSTime(tempDateStr);
        }
        AwsServerService.UpdateReminders(payLoad).then(res=>{
             
            console.log(res);
            setSnoozeTime(null);
        }).catch(e=>{
            console.log(e);
        })
        setOpenSnooze({id:null,open:false});
    }
    const content=(data)=>{
        return ( <div className="Snooze-Content">
            <Row sm={12}>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                    handleSnooze(data,10)
                }} >10 min</Col>
                <Col className='Preset-Cards' sm={3}onClick={e=>{
                    handleSnooze(data,20)
                }}>20 min</Col>
                <Col className='Preset-Cards' sm={3} onClick={e=>{
                    handleSnooze(data,30)
                }}>30 min</Col>
            </Row>
            <div className='Custome-Snooze-Time'><input
                type="time"
                className="form-control form-control-sm"
                format="HH:mm A"
                // style={validation.out=='-1'?{...validationCss}:{}}
                style={{ height: '2.2rem' }}
                onChange={(e) => {
                    setSnoozeTime(e.currentTarget.value)
                }}
                value={snoozeTime ? snoozeTime : dayjs().add(10, "minutes").format("HH:mm")}
            />
                <Button style={{"line-height":"0.3rem"}} className='btn btn-primary' onClick={e=>{
                    handleSnooze(data,snoozeTime,true)
                }}>Set</Button>
            </div>
        </div>)
    }
    const getHeaderStatement = (data) => {
        if (data.reminderType == "At specific time") {
            return { text: `You have set reminder on an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 1 }
        } else if (data.reminderType == "On every reply") {
            return { text: `You have set reminder on every reply for an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""}`, status: 2 }
        } else if (data.reminderType == "If no replies until") {
            return { text: `You have set reminder on an email: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} for reply till - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 3 }
        } else {
            return { text: `You have set reminder on an ${data.module}: ${data?.reminderHeading?data?.reminderHeading?.substring(0,70)+"..":""} - ${modifyTimezoneString(data.reminderTime,"MM/DD/YYYY hh:mm A")}.`, status: 1 }
        }
    }
    const displayNotification = (message) => {

        // console.log("message        : ityegdugeqdvcgavdcgdvc", message);
        return (
            <li
                style={{ padding: "15px 0px" }}
                className={message.IsRead ? "row" : "unreadNotification row pb-2"}
            >
                <div className="notificationIcon col-1">
                    <span>
                        {getIconByModule(message.module)}
                    </span>
                </div>
                <div className="col">
                    <div className="row" 
                         onClick={e => {
                            dispatch(forwardObject({ module: message.module, data: message, state: "view" }))
                            history.push(AllPaths[message.module]);
                        }}
                    >
                        <div
                            className="col-4 d-flex justify-content-end"
                            style={{ flexDirection: "column" }}
                        >
                            {message.triggered!="true" ? (
                                <h6 className="notifType">{message.module}</h6>
                            ) : (
                                <strong>
                                    <h6 className="notifType">{message.module}</h6>
                                </strong>
                            )}
                        </div>
                        <div className="col-8 d-flex  justify-content-Send" style={{"justify-content": "end"}}>
                            <div>
                                {message.triggered!="true" ? (
                                    <></>
                                ) : (
                                    <FontAwesomeIcon
                                        className="notify-isRead custom-dot-icon"
                                        icon={faCircle}
                                    />
                                )}
                            </div>
                            {message.triggered!="true" ? (
                                <>
                                    <span>{modifyTimezoneString(message.reminderTime, "MM/DD/YYYY")}</span>&nbsp;at&nbsp;
                                    <span>{modifyTimezoneString(message.reminderTime, "hh:mm A")}</span>
                                </>
                            ) : (
                                <strong>
                                    <span>{modifyTimezoneString(message.reminderTime, "MM/DD/YYYY")}</span> at{" "}
                                    <span>{modifyTimezoneString(message.reminderTime, "hh:mm A")}</span>
                                </strong>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingLeft: "15px" }}
                 onClick={e => {
                    dispatch(forwardObject({ module: message.module, data: message, state: "view" }))
                    history.push(AllPaths[message.module]);
                }}
                >
                    <div className="col-1"></div>

                    <div className="notificationText pl-4 col d-flex pr-0">

                        {message.triggered!="true" ? (
                            <div
                                className="notifications-hyperlinks my-1"
                                //   onClick={(e) => handleNotificationHyperlink(e)}
                                dangerouslySetInnerHTML={{ __html: message.reminderHeading }}
                            ></div>
                        ) : (
                            <strong>
                                <div
                                    className="notifications-hyperlinks my-1"
                                    // onClick={(e) => handleNotificationHyperlink(e)}
                                    dangerouslySetInnerHTML={{ __html: message.reminderHeading }}
                                ></div>
                            </strong>
                        )}
                    </div>
                </div>
                <div className="notificationTime">
                    <div className="notification-unread-button">

                    {getHeaderStatement(message)?.status != 2 && tabKey!='Completed' &&
                            <AntdPopover
                                open={openSnooze.open && message?.ROWID + "TT" == openSnooze.id}
                                overlayClassName={`Snooze-Popover Do-Not-Close ROWID-${message?.ROWID}`}
                                style={{ zIndex: 9999, "width": "max-content" }}
                                trigger="click"
                                placement="bottomLeft"
                                content={() => content(message)}
                                onOpenChange={e => {

                                    setOpenSnooze({ id: message?.ROWID + "TT", open: true });
                                }}
                                arrow={true}
                            >
                                <span>Snooze</span>
                            </AntdPopover>
                        }
                        {tabKey!="Completed" && <span onClick={e => {
                                handleCompleted(message)
                        }}>Complete</span>}
                        <span onClick={e => {
                            handleDelete(message)
                        }}><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </div>
                    {/* <div className="notification-unread-button ml-2">


                        {message.IsArchive ? (
                            <span
                                onClick={() => {
                                    let data = { ...message };
                                    data.IsArchive = false;
                                    // updateNotification(data);
                                }}
                            >
                                Activate
                            </span>
                        ) : (
                            <span
                                onClick={() => {
                                    let data = { ...message };
                                    data.IsArchive = true;
                                    // updateNotification(data);
                                }}
                            >
                                Archive
                            </span>
                        )}
                    </div> */}
                </div>
            </li>
        );

    };

      const getNotificationRender = () => {
        let list=[...cacheData];
        let obj = [...notificationFilter];
        let arr=obj.map(k=>{
            if(k.isVisible){
                return k.name;
            }
        })
        list = [...list];
        if (!obj.All) {
          list = list.filter((one) => {
            if(arr?.includes(one.module)){
                return one;
            }
          });
        }
        if (searchText) {
            let text = searchText?.toLowerCase();
            list = list.filter((one) => {
              if (one.module?.toLowerCase().includes(text) || one.reminderHeading?.toLowerCase().includes(text) ) {
                return one;
              }
            });
        }
        setFilteredData(list)
      };
      // async function handleSettingChange(e,module,index){
      //   let temp=[...notificationFilter];
      //   // setLoader(true);
         
      //   if(e){
      //     temp[index].settingObj.active=true;
      //     temp[index].settingObj.lastActiveTime=convertToUSTime(new Date());
      //   }else{
      //     temp[index].settingObj.active=false;
      //     temp[index].settingObj.lastDisableTime=convertToUSTime(new Date());
      //   }
      //   let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      //   setLoader(true);
      //   if(helperOpsData.find(d=>d.module=="ReminderFilter" && d.userId==userinfo.email_id)){
      //     await AwsServerService.DeleteHelperOps([userinfo.email_id],{module:"ReminderFilter"}).then(async res=>{
      //       await AwsServerService.SaveHelperOps(notificationFilter,"ReminderFilter",userinfo.email_id);
      //       setLoader(false)
      //     }).catch(e=>{
      //       console.log(e);
      //       setLoader(false)
      //     })
      //   }else{
      //     await AwsServerService.SaveHelperOps(notificationFilter,"ReminderFilter",userinfo.email_id).then(res=>{
      //       setLoader(false)
      //     }).catch(e=>{
      //       setLoader(false)
      //       console.log(e);
      //     })
      //   }
      //   // setNotifcationFilter(temp);
      // }
      function getCollapseOptions(){
        return <div className="Module-Selection-Wrapper" onClick={e=>{
          e.stopPropagation();
          // e.preventDefault();
        }}>
          <div className="Module-Selection-Header">Notification Control</div>
          {notificationFilter && notificationFilter.map((m,i)=>{
            return (<div className="Module-Wrapper">
            <span style={{"margin-right": "5px"}}>{m.name}</span>
            <div>
            <AntSwitch size="default" 
             checkedChildren={<CheckOutlined />}
             unCheckedChildren={<CloseOutlined />}
            defaultChecked checked={m.settingObj?.active?m.settingObj?.active:false} 
            // onClick={e=>handleSettingChange(e,"NotificationFilter",i)}
             />
              {/* <input checked={m.settingObj?.active?m.settingObj?.active:false} type="checkbox" 
              onClick={e=>handleSettingChange(e,"NotificationFilter",i)} /> */}
            </div>
          </div>)
          })}
        </div>
      }
      function getSettingPopOver(){
        const items = [
        //   {
        //     label: <a onClick={async (e) => {
        //       e.preventDefault()
        //       e.stopPropagation();
        //       setLoader(true);
        //       let list = [...notificationCacheList];
        //       // list = list.filter(one => !one.Notifications.IsArchive);
        //       let doArr = [];
        //       list.forEach((one) => {
        //         if (notifyTabKey == "Unarchive") {
        //           one.Notifications.IsArchive = true;
        //         }
        //         if (notifyTabKey == "Archive") {
        //           one.Notifications.IsArchive = false;
        //         }
        //         doArr.push(
        //           NotificationAPI.UpdateNotification(
        //             one.Notifications
        //           )
        //         );
        //       });
        //       await Promise.all(doArr)
        //         .then((result) => {
        //           getNotification();
        //           setLoader(false);
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //           setLoader(false);
        //         });
        //     }}>{notifyTabKey == "Unarchive"? " Archive All": "Active All"}</a>,
        //     key: "0",
        //   },
        //   {
        //     label: <a onClick={(e) => {
        //       e.preventDefault()
        //       e.stopPropagation();
        //       setLoader(true);
        //       let list = [...notificationCacheList];
        //       list = list.filter(
        //         (one) => !one.Notifications.IsRead
        //       );
        //       let doArr = [];
        //       list.forEach((one) => {
        //         one.Notifications.IsRead = true;
        //         doArr.push(
        //           NotificationAPI.UpdateNotification(
        //             one.Notifications
        //           )
        //         );
        //       });
        //       Promise.all(doArr)
        //         .then((result) => {
        //           getNotification();
        //           setLoader(false);
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //           setLoader(false);
        //         });
        //     }}>Mark all as read</a>,
        //     key: "1",
        //   },
          {
            label: getCollapseOptions(),
            key: "2",
          },
          // {
          //   label: <a onClick={async (e) => {
          //     e.preventDefault()
          //     e.stopPropagation();
          //     let userinfo = JSON.parse(localStorage.getItem("userinfo"));
          //     setLoader(true);
          //     if(helperOpsData.find(d=>d.module=="NotificationFilter" && d.userId==userinfo.email_id)){
          //       await AwsServerService.DeleteHelperOps([userinfo.email_id],{module:"NotificationFilter"}).then(async res=>{
          //         await AwsServerService.SaveHelperOps(notificationFilter,"NotificationFilter",userinfo.email_id);
          //         setLoader(false)
          //       }).catch(e=>{
          //         console.log(e);
          //         setLoader(false)
          //       })
          //     }else{
          //       await AwsServerService.SaveHelperOps(notificationFilter,"NotificationFilter",userinfo.email_id).then(res=>{
          //         setLoader(false)
          //         setLoader(false)
          //       }).catch(e=>{
          //         setLoader(false)
          //         console.log(e);
          //       })
          //     }
          //   }}>Save Filters</a>,
          //   key: "2",
          // },
        ];
        return <>
          <AntDropDown
            style={{ color: "none" }}
            className="Notification-Setting-Icon"
            overlayClassName="Notification-Setting-Popover"
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <img style={{ height: "1rem" }} src={SettingIcon} />
          </AntDropDown>
        </>
      }
    return (
        <Dropdown className="Reminder-DropDown"
            show={open}
            onToggle={e => {
                setOpen(true);
            }}
        >
            <Dropdown.Toggle
                id="Reminder-Toggle"
                className="Reminder-Toggle"
            >{children}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true} className="Reminder-Menu">
                <OtsideClickClosingWrapper
                    close={e => {
                        if (open) {
                            setOpen(!open);
                        }
                    }}
                >
                    <div className='Header'>
                        <img style={{ height: "1rem" }} src={ReminderSvg}></img>
                        <span>{title}</span>

                        <FontAwesomeIcon border="" className='Icon' icon={faCaretUp} />
                    </div>
                    <div className="notification-topMenu">
                        {notificationList.filter((one) => one.isCheck).length >
                        0 ? (
                          <div className="checkedList-actions">
                            <div className="d-flex">
                              <span
                                onClick={() => {
                                  // let msgs = messageList.filter((e) => e.isCheck);
                                  // changeReadStatusOfMessage(msgs, true)
                                  // setMessageData({});
                                  // setOpenPopupOnIconChange(false)
                                }}
                                className="text-blue"
                              >
                                <FontAwesomeIcon icon={faCheck} />
                                Mark as Read
                              </span>

                              <span
                                onClick={() => {
                                  // let msgs = messageList.filter((e) => e.isCheck);
                                  // changeReadStatusOfMessage(msgs, false)
                                  // setMessageData({});
                                  // setOpenPopupOnIconChange(false)
                                }}
                                className="text-blue"
                                style={{ marginLeft: "20px" }}
                              >
                                Mark as Unread
                              </span>
                            </div>

                            <span
                              onClick={() => {
                                // const list = [...messageList];
                                // list.forEach((e) => {
                                //   if (e.isCheck) {
                                //     delete e.isCheck;
                                //   }
                                // })
                                // setMessageList(list);
                                // setMessageData({});
                                // setOpenPopupOnIconChange(false)
                              }}
                              className="text-blue"
                            >
                              {" "}
                              Cancel
                            </span>
                          </div>
                        ) : (
                          <div>
                            <div className="notificationFilters">
                              {/* <UncontrolledDropdown>
                                <DropdownToggle className='notification-link filterBtn' caret>
                                  Filter
                                </DropdownToggle>
                                <DropdownMenu className='p-0'>
                                  <DropdownItem>
                                    <input
                                      type='checkbox'
                                      onChange={handleOnChangeFilterNotification}
                                      checked={filterObj['All']} name='All'
                                    />
                                    Select All
                                  </DropdownItem>
                                  <DropdownItem>
                                    <input
                                      type='checkbox'
                                      onChange={handleOnChangeFilterNotification}
                                      checked={filterObj['Notes']} name='Notes'
                                    />
                                    Notes
                                  </DropdownItem>
                                  <DropdownItem>
                                    <input
                                      type='checkbox'
                                      onChange={handleOnChangeFilterNotification}
                                      checked={filterObj['Mention']} name='Mention'
                                    />
                                    Mention
                                  </DropdownItem>
                                  <DropdownItem>
                                    <input
                                      type='checkbox'
                                      onChange={handleOnChangeFilterNotification}
                                      checked={filterObj['Task']} name='Task'
                                    />
                                    Tasks
                                  </DropdownItem>
                                  <DropdownItem>
                                    <input
                                      type='checkbox'

                                      onChange={handleOnChangeFilterNotification}
                                      checked={filterObj['Assignee']} name='Assignee'
                                    />
                                    Assignee
                                  </DropdownItem>


                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                              <AddColumn
                                Label={<FontAwesomeIcon 
                                    icon={faFilter} 
                                    // color='#007bff'
                                />}
                                columns={notificationFilter}
                                onChangeColumn={(columns) => {
                                  setNotifcationFilter(columns);
                                }}
                              />

<div className="position-relative about-deal-search notification-search-icon">
                              <input
                                type="text"
                                placeholder="Search"
                                className="pad-fix search-filter-deals"
                                // value={SearchedItem}
                                onChange={(e) => {
                                  let list = [...cacheData];
                                  setSearchText(e.currentTarget.value);
                                //   if (e.currentTarget.value) {
                                //     let text =
                                //       e.currentTarget.value.toLowerCase();
                                //     list = list.filter((one) => {
                                //       if (one.module?.toLowerCase().includes(text) || one.reminderHeading?.toLowerCase().includes(text) ) {
                                //         return one;
                                //       }
                                //     });
                                //   }
                                //   setFilteredData(list);
                                }}
                              />
                              <FontAwesomeIcon
                                className="search-ico"
                                icon={faSearch}
                              />
                            </div>
                            </div>

                           
                          </div>
                        )}
                      </div>
                    <Tabs
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                        className="job-middle-tab job-middle-tabs progress-inner-tabs"
                    >
                        <Tab eventKey="Active" title="Active">
                            <div className='Content'>
                                <ul
                                    className="list-unstyled notificationList col"
                                    style={{ overflowX: "hidden" }}
                                >
                                    {filteredData?.filter(d => (d.status == "false" && d.triggered == "true" && !moment(d.reminderTime).isBefore(moment().startOf("day"))))?.sort((a,b)=>moment(b.reminderTime)?._d-moment(a.reminderTime)?._d)?.map((r, i) => {
                                        return displayNotification(r);
                                    })}
                                </ul>
                            </div>
                        </Tab>
                        <Tab eventKey="Upcoming" title="Upcoming">
                            <div className='Content'>
                                <ul
                                    className="list-unstyled notificationList col"
                                    style={{ overflowX: "hidden" }}
                                >
                                    {filteredData?.filter(d => d.status == "false" && d.triggered == "false")?.sort((a,b)=>moment(a.reminderTime)?._d-moment(b.reminderTime)?._d)?.map((r, i) => {
                                        return displayNotification(r);
                                    })}
                                </ul>
                            </div>
                        </Tab>
                        <Tab eventKey="Completed" title="Completed">
                            <div className='Content'>

                                <ul
                                    className="list-unstyled notificationList col"
                                    style={{ overflowX: "hidden" }}
                                >
                                    {filteredData?.filter(d => d.status == "true")?.sort((a,b)=>moment(b.MODIFIEDTIME)?._d-moment(a.MODIFIEDTIME)?._d)?.map((r, i) => {
                                        return displayNotification(r);
                                    })}
                                </ul>
                            </div>
                        </Tab>
                        <Tab eventKey="Missed" title="Missed">
                            <div className='Content'>

                                <ul
                                    className="list-unstyled notificationList col"
                                    style={{ overflowX: "hidden" }}
                                >
                                    {filteredData?.filter(d => (d.status == "false" && d.triggered == "true" && moment(d.reminderTime).isBefore(moment().startOf("day"))))?.sort((a,b)=>moment(b.reminderTime)?._d-moment(a.reminderTime)?._d)?.map((r, i) => {
                                        return displayNotification(r);
                                    })}
                                </ul>
                            </div>
                        </Tab>
                    </Tabs>
            </OtsideClickClosingWrapper>
        </Dropdown.Menu>
        </Dropdown >
    )
}
