import "gantt-task-react/dist/index.css";
import './viewSwitcher.css'
import React from "react"
import { ViewMode } from "gantt-task-react";
import { Dropdown as AntDropDown } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked, viewMode }) => {
  const applyCss={
    'fontSize': 'medium',
    'fontWeight': '600'
  }
  const getActionOptions = () => {
    const items = [
      { 
        label: (
          <div className='Switch'>
            <label className='Switch_Toggle'>
              <input
                type='checkbox'
                checked={isChecked}
                onChange={(e) => onViewListChange(!isChecked)}
              /> 
            </label>
            <span className="checkboxLabel">Show With Tasks</span>
          </div>
        ),
        key: "0" 
      },
      {
        label: <a className='aTag'
        style={viewMode==ViewMode.Hour?{...applyCss}:{}}
          onClick={(e) => {
            onViewModeChange(ViewMode.Hour)
          }}
        >Hour</a>,
        key: "1",
      },
      {
        label: <a className='aTag'
        style={viewMode==ViewMode.HalfDay?{...applyCss}:{}}
          onClick={(e) => {
            onViewModeChange(ViewMode.HalfDay)
          }}
        >Half Of Day</a>,
        key: "2",
      },
      {
        label: <a className='aTag'
        style={viewMode==ViewMode.Day?{...applyCss}:{}}
        onClick={(e) => { onViewModeChange(ViewMode.Day)}}
        >Day</a>,
        key: "3",
      },
      {
        label: <a className='aTag'
        style={viewMode==ViewMode.Week?{...applyCss}:{}}
        onClick={(e) => { onViewModeChange(ViewMode.Week)}}
        >Week</a>,
        key: "4",
      },
      {
        label: <a className='aTag'
        style={viewMode==ViewMode.Month?{...applyCss}:{}}
        onClick={(e) => { onViewModeChange(ViewMode.Month)}}
        >Month</a>,
        key: "5",
      },
      // {
      //   label: <a className='aTag'
      //   style={viewMode==ViewMode.Year?{...applyCss}:{}}
      //   onClick={(e) => { onViewModeChange(ViewMode.Year)}}
      //   >Year</a>,
      //   key: "6",
      // },
    ];
    return (
      <AntDropDown
        style={{ color: "none" }}
        className="Actions-btn-subheader action"
        overlayClassName="Actions-btn-subhdr"
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["click"]}
      >
        <Button
          className="Action-Btn-Ant"
          variant="outline-primary"
          onClick={(e) => e.preventDefault()}
          style={{width: "max-content", padding: "5px", fontSize: "13px"}}
        >
          View Modes
          <FontAwesomeIcon
            className="Action-Btn-Ant-Dropdown"
            icon={faAngleDown}
          />
        </Button>
      </AntDropDown>
    )
  }
  return (
    <div className='ViewContainer'> 
      {getActionOptions()}
    </div>
  )
}