import React, { useContext, useEffect, useState } from "react";
import "./ActivityStreamTab.css"; // Import the CSS file
import { Col, Row } from "reactstrap";
import { Timeline } from "antd";
import ActivityTimeLine from "../../../common/ActivityStream/ActivityTimeLine";
import AwsServerService from "../../../common/util/AwsServerService";
import dayjs from "dayjs";
import { AppDataRequired } from "../../../App";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";

const ActivityStreamTab = ({pipelineId, module}) => {
  console.log("-----",pipelineId);
  const [activitiyData, setActivityData] = useState([]);
  const { AllUsers } = useContext(AppDataRequired);
  const history = useHistory();
  
  useEffect(e=>{
      // debugger
      AwsServerService.getAllActivityByModule(pipelineId, module).then(res=>{
          console.log("activity",res);
          res = res.sort((a, b) => new Date((b?.CREATEDTIME)) - new Date(a.CREATEDTIME))
          setActivityData(res)
      }).catch(err => {
        // swal("Error!", "Something went wrong!", "error");
      })
  }, [pipelineId]);

  const getMessage = (text, user) => {
    // Split the text around the "USER" placeholder
    const parts = text?.split("USER");
    return (
      <>
        {parts[0]}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            history.push({
              pathname: `/setting/users/${user.user_id}/Users`,
              state: { tab: "Users" },
            });
          }}
          className="user-link"
        >
          {user?.full_name}
        </a>
        {parts[1]}
      </>
    );
  };
  

  const getUserThumbnailUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
  };

  const RenderChildren = (data) => {   
    data = data?.data;
    let user = AllUsers.find(d=> d.email == data?.ActivityOwner);
    return (
      data?.Message && <div className="Activity-Data-Wrapper">
        <span style={{minWidth : "3rem"}}>
          <img
            className="User-Image"
            src={getUserThumbnailUrl(user?.zuid)}
          />
        </span>
        <div>
          <p className="activity-message"> {data?.Message ? getMessage(data?.Message, user) : <></>} </p>
          {/* <h6> Hello </h6> */}
          <span className="activity-date"> {dayjs(data?.CREATEDTIME).format("MMM DD YYYY hh:mm A")} </span>
        </div>
      </div>
    );
  };

  const items = activitiyData
  .filter(item => item?.Message) // Filter out items where Message is null or undefined
  .map((item, ind) => {
    return {
      children: <RenderChildren data={item}/>,
    };
  });

  return (
    <div className="Actvity-Items-Wrapper">
      <ActivityTimeLine items={items} />
    </div>
  );
};

export default ActivityStreamTab;
