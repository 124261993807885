import React, { useState } from 'react';
import { Card, Input } from 'antd';

const EditableCard = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('Click to edit me!');

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div style={{ minWidth: 100, maxWidth: "auto" }}>

      {isEditing ? (
        <Input
          autoFocus
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      ) : (
        <div className={"Editable-Card"} onClick={handleClick}>{text}</div>
      )}
    </div>
  );
};

export default EditableCard;
