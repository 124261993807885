import { CCollapse } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import './collapse.css'
import '../chat.css'
import defaultUser from "../../../assets/images/defaultUser.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faEllipsisV, faReply, faThumbtack, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { OtsideClickClosingWrapper } from '../../EmojiReactionPicker/OtsideClickClosingWrapper';
import ChatService from '../../util/ChatService';
import UserService from '../../util/UserService';

export const AllPinnedMessageCollapse = ({selectUser, FocusIntoView,allUsers, myProfile ,isPinnedExpandOpen, getTime, convertZohoClickMessageToCRM1, setIsPinnedExpandOpen, allPinnedMessageInChat, setAllPinnedMessageInChat, section }) => {
    const [visible, setVisible] = useState(false);
    const [pinnedMassages, setPinnedMassages] = useState([]);
    useEffect(e => {
        if (allPinnedMessageInChat) {
            setPinnedMassages(allPinnedMessageInChat)
        } else {
            setPinnedMassages([])
        }
    }, [allPinnedMessageInChat])
    useEffect(e => {
        if (isPinnedExpandOpen) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [isPinnedExpandOpen])


    const upPinChat=(message)=>{
             
        let id=message.id
       ChatService.UnPinMassagesInZoho(message.chat_id,[id]).then((e)=>{
       ChatService.GetAllPinnedChatsFromZoho(message.chat_id,myProfile).then(e=>{
                if(e?.data?.data){
                    setAllPinnedMessageInChat(e?.data?.data)
                }else{
                    setAllPinnedMessageInChat([])
                }
            }).catch(e=>{
                setAllPinnedMessageInChat([])
            })
        }).catch(e=>{
            console.log(e);
        })
    }
    return (
        <OtsideClickClosingWrapper className={`All-Pinned-Chats-Wrapper`}  close={(e) => {
            if (visible) {
                setIsPinnedExpandOpen(!isPinnedExpandOpen)
                setVisible(false)
            }
        }}>
            <CCollapse
                id="collapseWidthExample" className='Pinned-Expand-Wrapper' verticle visible={visible}>
                <div className='Heading'>{`Pinned Messages${pinnedMassages && pinnedMassages?.length>0?" ("+pinnedMassages?.length+")":""}`}</div>
                <div className='Pinned-Content-Body'>
                    {
                        pinnedMassages ? pinnedMassages.map(message => {
                                 
                            let p = allUsers.find(e => e.full_name == message.dname);
                            let userId;
                            if(message && section == "Notes"){
                                userId =  allUsers?.filter(e=> e.full_name == message?.dname)
                            }else{
                               let name =  message.creator_name == "You" ? myProfile.name : message.creator_name;
                                userId = selectUser.recipients_summary.filter(e=>(e.name == name));
                            }
                             
                            // console.log("userId", userId);
                            // console.log("pinnedMassages", pinnedMassages);
                            return (
                                <div className='Pinned-Massage-Card' onClick={e=>{
                                         
                                    document.querySelectorAll(".Pinned-Chat-Class")?.forEach(e=>{
                                        e.classList.remove("Pinned-Chat-Class")
                                    });
                                    FocusIntoView(message.id)
                                }}>
                                    <div className='Profile-Wrapper'>
                                        <div className='profile'>{
                                            userId[0] ?
                                                <img
                                                    key={Date.now()}
                                                    cache={false}
                                                    src={section == "Notes" ? UserService.GetUserPhotoUrl(userId[0].zuid) : UserService.GetUserPhotoUrl(userId[0]?.user_id)}
                                                // alt={avtar} 
                                                /> :
                                                <div
                                                    className='no-image-profile mr-10'
                                                    style={{ backgroundColor: `${p?.color}` }}
                                                >{p?.name?.charAt(0)}</div>

                                        }
                                        </div>
                                        <div className='header'>
                                            <span>{message.name}</span>
                                            <span>{`Pinned by `}</span>
                                            <span>{message.creator_name}</span>
                                        </div>
                                        <div className='Actions'>
                                            <FontAwesomeIcon
                                                color='red'
                                                icon={faTimes}
                                                onClick={()=>{
                                                    upPinChat(message)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='Message-Wrapper'>
                                        <div className='profile'></div>
                                        <div className='content'>
                                            <p className='massage-body-wrapper'><div dangerouslySetInnerHTML={{ __html: convertZohoClickMessageToCRM1(message.content?.text) }}></div>
                                                <span className='m-time'>{getTime(new Date(message.time))}</span>
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>)
                        }) : <></>
                    }
                </div>

            </CCollapse>

        </OtsideClickClosingWrapper>
    )
}
