import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import CurrencyInput from "react-currency-input-field";
import { v4 as uuid } from "uuid";
import MicroInverterModal from "../microInverter/microInverterModal";

import ManufacturerModal from "../manufacturerModal/manufacturerModal";
import StringInverterModal from "../stringInverter/StringInverterModal";
// import swal from 'sweetalert';
import CustumSelect from "../../../../../../common/SelectWithSearch";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../../../../../../common/loader";
import Config from '../../../../../../config.json'

const formInputs = {
  Name: "",
  modelNumber: "",
  manufacturer: "",
  ulNumber: "",
  maxDCwatt: "",
};

function PVSystemInformation(props) {
  const [formInputsValue, setFormInputsValue] = useState(formInputs);
  const [buttonState, setbuttonState] = useState(false);
  const [modal, setModal] = useState(false);
  const [nestedModal1, setNestedModal1] = useState(false);
  const [manufacturerModal, setManufacturerModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [backdrop, setBackdrop] = useState("static");
  const [pvmoduleList, setpvmoduleList] = useState([]);
  const [pvmoduleAllList, setpvmoduleAllList] = useState([]);
  const [loader,setLoader]=useState(false);
  const [inverterModelList, setInverterModelList] = useState([]);
  const [inverterModelAllList, setInverterModelAllList] = useState([]);
  const [manufacturerName, setManufacturerName] = useState([]);
  const [manufacturerAllList, setManufacturerAllList] = useState([]);
  const [stringInverterModal, setStringInverterModal] = useState(false);
  const [microInverterModal, setMicroInverterModal] = useState(false);
  const [deleteAdminModal, setDeleteAdminModal] = useState(false);
  const [skipPvSystem, setSkipPvSystem] = useState(false);
  const [editState, setEditState] = useState("");
  const [reloadManufacturerData, setReloadManufacturerData] = useState(true);
  const [reloadPvModuleNumbr, setReloadPvModuleNumbr] = useState(true);
  const [reloadInverterData, setReloadInverterData] = useState(true);
  const [idToDelete, setIdToDelete] = useState("");
  const [totalSysNum, setTotalSysNum] = useState(0);
  const [totalInterconnNo, setInterconnNo] = useState(0);
  const [totalSysSize, setTotalSysSize] = useState(0);
  const [totalModuleNum, setTotalModuleNum] = useState(0);
  const [remainInterConnection, setRemainInterConnection] = useState(0);
  const [inverterModelNoVisible, setInverterModelNoVisible] = useState(false);
  const[interc,setInterc]=useState('0');
  const changePositiveValue = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setFormInputsValue({
        ...formInputsValue,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetPvModuleFields = (e) => {
    props.setManufacturer("")
    setFormInputsValue({
      ...formInputsValue,
      Name : "",
      modelNumber: "",
      ulNumber: "",
      maxDCwatt: "",

    });
  };

  const savePVSystemInfo = () => {
    let vKeys=Object.keys(validations);
    for(let i=0;i<vKeys.length;i++){
      if(validations[vKeys[i]]==='-1' || validations[vKeys[i]]==='0'){
        alert("Please fill all required fields!")
        highliteEmptys({arr:validations,obType:'validations'})
        return
      }
    }
    var pvSystem = props.pvSystemInfo;
    if (editState === "") {
      var unique = uuid();
      pvSystem.push({
        pvSystemId: unique,
        interConnectionNum: props.interConnectionNum,
        systemSize: props.systemSize,
        noOfModules: props.noOfModules,
        pvmodulemodel: props.pvmodulemodel,
        numberOfInvertors : props.numberOfInvertors,
        invertertype: props.invertertype,
        inverterModelNumber: props.inverterModelNumber ? props.inverterModelNumber.name : "",
      });
    } else {
      props.pvSystemInfo.forEach((info, i) => {
        if (pvIndex !== -1 && pvIndex == i) {
          pvSystem[i] = {
            pvSystemId: info.pvSystemId,
            interConnectionNum: props.interConnectionNum,
            systemSize: props.systemSize,
            noOfModules: props.noOfModules,
            pvmodulemodel: props.pvmodulemodel,
            numberOfInvertors : props.numberOfInvertors,
            invertertype: props.invertertype,
            inverterModelNumber: props.inverterModelNumber ? props.inverterModelNumber.name : "",
          };
        }
      });
    }
    resetPvSystemInfoModal({arr:validations,obType:'validations'});
    calculateTotalValues(pvSystem)
    props.handleOnChangeDeal({currentTarget: { name: 'pvSystemInfo', value: pvSystem }})
    props.setPVSystemInfo(pvSystem);
    setEditState("");
    toggle();
  };

  const calculateTotalValues = (pvSystem) => {

    const distinctInterconnection = [...new Set(pvSystem?.map(x => x.interConnectionNum))]
    var remainInterConn = props.interconnNo - distinctInterconnection.length;

    var totalSytemSize = 0;
    var totalMOduleNum = 0;
    pvSystem.forEach((info, i) => {
      totalSytemSize += parseFloat(info.systemSize)
      totalMOduleNum += parseInt(info.noOfModules)
    })
    setInterconnNo(distinctInterconnection.length)
    setTotalSysNum(pvSystem.length)
    setTotalSysSize(totalSytemSize.toFixed(3))
    props.setTotalSysSize(totalSytemSize.toFixed(3))
    setTotalModuleNum(totalMOduleNum)
    setRemainInterConnection(remainInterConn)
  }



  const createPVModule = async () => {

    let vKeys=Object.keys(validationsForModule);
    for(let i=0;i<vKeys.length;i++){
      if(validationsForModule[vKeys[i]]=='-1' || validationsForModule[vKeys[i]]=='0'){
        alert("Please fill all required fields!")
        highliteEmptys({arr:validationsForModule,obType:'validationsForModule'})
        return
      }
    }

    setLoader(true)
    let ob={...formInputsValue,
      manufacturer:formInputsValue.manufacturer=='' && props.manufacturer!=''?props.manufacturer:formInputsValue.manufacturer
    }
    if(pvmoduleList.includes(ob.Name)){
      swal("Duplicate record")
    }else if(ob.Name != "" && ob.manufacturer != ""  && ob.maxDCwatt != "" && ob.modelNumber != "" && ob.ulNumber != ""){
      let bacUrl = Config.backend_url
      var config = {
        Type: "Create",
        data: [ob],
        url: "https://www.zohoapis.com/crm/v2/Photovoltaic_Module",
        method: "POST",
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    }
      let returnData = await axios.post(bacUrl + "workdrive", { config: config }).then((res) => {
        console.log(res.data);
        if (res.data) {
          setLoader(false)
          resetPvSystemInfoModal({arr:validationsForModule,obType:'validationsForModule'});
          resetPvModuleFields();
          toggleNested1();
          props.setpvmodulemodel(ob.Name)
          getPvModuleZoho();
          setReloadPvModuleNumbr(true);
        } else {
          setLoader(false)
          swal("Server Issue: Unable to add PV module.");
        }
      }).catch((err)=>{
        setLoader(false)
        console.log(err);
      })
    }else{
      setLoader(false)
      swal("Please Fill all the mandatory Details")
    }
  };

  const handleChange = (e) => {
    setFormInputsValue({
      ...formInputsValue,
      [e.target.name]: e.target.value,
    });
  };

  const toggle = () => {
    resetPvSystemInfoModal({arr:validations,obType:'validations'});
    setInverterModelNoVisible(false);
    props.setInterConnectionNum("1");
    props.setSystemSize("");
    props.setNoOfModules("");
    props.setpvmodulemodel("");
    props.setinvertertype("");
    props.setNumberOfInverters("")
    props.setinverterModelNumber("");
    setEditState("");
    setModal(!modal);
    setInverterModelList([]);
  };
  const [pvIndex, setPvIndex] = useState(-1)
  const editpvSystemInfo = async (info, i) => {
    setPvIndex(i)
    let vKeys=Object.keys(validations);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(info[vKeys[i]]!=='' || info[vKeys[i]]!==undefined){
        ob[vKeys[i]]=1;
      }else{
        ob[vKeys[i]]=0;
      }
    }
    setValidations(ob)
    setInverterModelNoVisible(true);
    props.setInterConnectionNum(info.interConnectionNum);
    props.setSystemSize(info.systemSize);
    props.setNoOfModules(info.noOfModules);
    let filterData = pvmoduleAllList.filter(one => one.Name == info.pvmodulemodel);
    props.setpvmodulemodel(info.pvmodulemodel);
    props.setNumberOfInverters(info.numberOfInvertors);
    props.setinvertertype(info.invertertype);
    if(info.invertertype == "Micro"){
      setIsMicroInverter(true);
    }else{
      setIsMicroInverter(false);
    }
    await getInvertersModelNo(info.invertertype);
    let module=""
    if(info.invertertype=='String'){
      module="String_Invertors"
    }else{
      module="Micro_Invertors"
    }
    let filterInvertor = inverterModelAllList.filter(one => one.Name == info.inverterModelNumber);
    props.setinverterModelNumber({ id: filterInvertor[0]?.id, module: module, name: info.inverterModelNumber });
    setModal(!modal);
    setEditState("test");
  };

  const deletepvSystemInfo = () => {
    var copyArray = props.pvSystemInfo.filter(
      (info, i) => info.pvSystemId != idToDelete
    );
    calculateTotalValues(copyArray)
    props.setPVSystemInfo(copyArray);
    props.handleOnChangeDeal({currentTarget: { name: 'pvSystemInfo', value: copyArray }})
  };

  const toggleNested1 = () => {
    resetPvSystemInfoModal({arr:validationsForModule,obType:'validationsForModule'});
    resetPvModuleFields();
    setNestedModal1(!nestedModal1);
    setCloseAll(false);
  };

  const openManufacturerModal = () => {
    setManufacturerModal(!manufacturerModal);
  }

  const changeToManufacturer = (value) => {
     
    if(value.trim()=='' || value==undefined){
        setValidationsForModule({...validationsForModule,manufacturer:'-1'})
    }else{
      setValidationsForModule({...validationsForModule,manufacturer:'1'})
    }
    if (value === "AddManufacturer") {
      if (props.isAccess && props.isAccess(props.selectedRole, 'Photovoltaic Manufacturer', 'Write')) {
        setManufacturerModal(true);
        props.setManufacturer("");
      } else {
        // swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
      }

    } else {
      let filterData = manufacturerAllList.filter(one => one.Name == value);
        props.setManufacturer(value); 
        setManufacturerModal(false);
        setFormInputsValue({...formInputsValue,
          "manufacturer" : value})
    }
  };

  const checkInterconnectionOnChange = (value) => {
    
    let arr = [];
    let val;
    if(props.pvSystemInfo?.length > 0){
      props.pvSystemInfo?.map((item,i) => {
        arr.push(item.interConnectionNum);
        val = arr.sort((a,b) => b - a)
      })
    }

    if(props.pvSystemInfo?.length > 0){
      if (value > 0 && value >= totalInterconnNo && value >= val[0]) {
        const distinctInterconnection = [...new Set(props.pvSystemInfo?.map(x => x.interConnectionNum))]
        var remainInterConn = value - distinctInterconnection.length;
        setRemainInterConnection(remainInterConn);
        props.handleOnChangeDeal({ currentTarget: { name: 'interconnNo', value: value } });
        props.setinterconnNo(value)
        props.setModuleCheckStatus({...props.moduleCheckStatus,PVSystemInformation:true })
      }
      else{
        swal("Alert!", "Cannot reduce the number of interconnections as previous interconnections are already in use", "warning");
        // swal("")
      }
    } else{  
    if (value >= 0 && value >= totalInterconnNo) {
      const distinctInterconnection = [...new Set(props.pvSystemInfo?.map(x => x.interConnectionNum))]
      var remainInterConn = value - distinctInterconnection.length;
      setRemainInterConnection(remainInterConn);
      props.handleOnChangeDeal({ currentTarget: { name: 'interconnNo', value: value } });
      props.setinterconnNo(value)
      props.setModuleCheckStatus({...props.moduleCheckStatus,PVSystemInformation:true })
    }
   }
  }

  const buttontoggle = () => {
    props.settoggleState("pvsysteminfo");
    props.setListState("pvsysteminfo");
    props.setActiveTab("pvsysteminfo")
    setbuttonState(!buttonState);
  };
  const changeInverterPage = (value) => {
    if (value === "CreateInverter") {
      if (props.invertertype === "String") {
        setStringInverterModal(true);
        props.setinverterModelNumber("");

      } else if (props.invertertype === "Micro") {
        setMicroInverterModal(true);
        props.setinverterModelNumber("");

      }
    }
  };

  useEffect(() => {
    if (reloadPvModuleNumbr || reloadInverterData) {
      getPvModuleZoho()
      getInvertersModelNo(props.invertertype);
    }
  }, [reloadPvModuleNumbr, reloadInverterData]);

  useEffect(() => {
    getData();
  }, [props.data]);
  const getData = () => {
    if (props.data != null) {
      props.setinterconnNo(props.data.interconnNo);
      if(props.data.pvSystemInfo?.length != 0){
        let totalSysSize = 0;
        for(let i=0; i<props.data.pvSystemInfo.length; i++){
          totalSysSize = totalSysSize + parseFloat(props.data.pvSystemInfo[i].systemSize)
        }
        props.setTotalSysSize(totalSysSize)
      }
    }
  };

  const getPvModuleZoho = () => {
    try {
      var pvmodules = [];
      const backendUrl = Config.backend_url+"workdrive";

      var config = {
          Type: "Get",

          url: "https://www.zohoapis.com/crm/v2/Photovoltaic_Module",
          method: "GET",
          headers: {
              'Authorization': localStorage.getItem('token')
          }
      };
      axios.post(backendUrl, { config: config })
        .then(function (data) {
          data.data=data.data.data;
          data.data?.forEach((one) => {
            pvmodules.push(one.Name);
          })
          setpvmoduleAllList(data.data);
          setpvmoduleList(pvmodules);
        })
        .catch((err) => {
          alert(err);
        })
    }
    catch (err) {

    }



  }

  function renderCells() {
    setbuttonState(true);
  }

  useEffect(() => {
    props.toggleState == "pvsysteminfo" ? renderCells() : setbuttonState(false);
  }, []);

  const handlePVSystemCheck = () => {
    if (skipPvSystem) {
      props.setpvsysteminfocomplete.pvsysteminfocomplete = true;
      return true;
    }
   if (props.data.interconnNo > 0) {
    props.setpvsysteminfocomplete.pvsysteminfocomplete = true;
      return true;
    }
    props.setpvsysteminfocomplete.pvsysteminfocomplete = false;
    return false;
  };

  const onlyTypeNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      props.setNoOfModules(e.target.value);
      if(isMicroInverter){
        props.setNumberOfInverters(e.target.value)
      }
    }
  };

  const testNumForSysSize = (val) => {
    var size = val.replace(/,/g, '')
    const re = /[+-]?([0-9]*[.])?[0-9]+/;
    if (size === "" || re.test(size)) {
      props.setSystemSize(size)
    }
    setTotalSysSize(size)
    props.setTotalSysSize(size)
  };

  const changePage = (value) => {
     
    // if(value=='' || value==undefined){
    //   setValidations({...validations,pvmodulemodel:'-1'})
    // }else{
    //   setValidations({...validations,pvmodulemodel:'1'})
    // }
    if (value === "CreatePVModule") {
      if (props.isAccess && props.isAccess(props.selectedRole, 'Photovoltaic Module', 'Write')) {
        toggleNested1();
        props.setpvmodulemodel(value);
      } else {
        // swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
      }

    } else {
      let filterData = pvmoduleAllList.filter(one => one.Name == value);
      props.setpvmodulemodel(value)
    }
  };

  const changeInverterType = (value) => {
    if (value != "") {
      setInverterModelNoVisible(true)
      getInvertersModelNo(value);
    } else {
      setInverterModelNoVisible(false)
      setInverterModelList([]);
    }
     
    if(value=='Micro'){
      setIsMicroInverter(true);
      setValidations({...validations,numberOfInvertors:'1'})
      props.setNumberOfInverters(props.noOfModules)
    }else{
      setValidations({...validations,numberOfInvertors:'0'})
      setIsMicroInverter(false);
      props.setNumberOfInverters('')
    }
    props.setinvertertype(value);
  };

  const getInvertersModelNo = async (value) => {
    var apikey = "";
    if (value == "String") {
      apikey = "String_Invertors";
    } else {
      apikey = "Micro_Invertors";
    }
    var invertersModel = [];
    const backendUrl = Config.backend_url+"workdrive";

      var config = {
          Type: "Get",

          url: "https://www.zohoapis.com/crm/v2/"+apikey,
          method: "GET",
          headers: {
              'Authorization': localStorage.getItem('token')
          }
      };

      axios.post(backendUrl, { config: config })
      .then((result) => {
        result.data=result.data.data;
        result.data?.map((module) => {
          invertersModel.push(module.Name);
        });
        setInverterModelList(invertersModel);
        setInverterModelAllList(result.data);
        setReloadInverterData(false);
      })
      .catch((err) => {
        alert(err);
      })

  };

  useEffect(() => {
    if (reloadManufacturerData) {
      getManufacturerZoho();
    }
  }, [reloadManufacturerData]);


  const getManufacturerZoho = () => {
    let manufacturers = [];
    try {
      const backendUrl = Config.backend_url+"workdrive";
      var config = {
          Type: "Get",

          url: "https://www.zohoapis.com/crm/v2/Photovoltaic_Manufacturer",
          method: "GET",
          headers: {
              'Authorization': localStorage.getItem('token')
          }
      };
      
      axios.post(backendUrl, { config: config })
        .then((result) => {
          result.data=result.data.data
          result.data.forEach((e) => {
            manufacturers.push(e.Name);
          });
          setManufacturerAllList(result.data);
          setManufacturerName(manufacturers);
          setReloadManufacturerData(false);
        })
        .catch((err) => {
          alert(err);
        })
    }
    catch (err) {

    }

  }

  //Hrushikesh Shelke new code start
  const[isMicroInverter,setIsMicroInverter]=useState(null);
  const[validations,setValidations]=useState({
    // interconn:'0',
    systSize:'0',
    noOfModules:'0',
    pvmodulemodel:'0',
    invertertype:'0',
    numberOfInvertors:'0'
  });
  const[validationsForModule,setValidationsForModule]=useState({
    Name:'0',
    modelNumber:'0',
    manufacturer:'0',
    maxDCwatt:'0',
    ulNumber:'0'
  });
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const resetPvSystemInfoModal=(data)=>{
     
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      ob[vKeys[i]]='0'
    }
    if(data.obType=='validations'){
      setValidations(ob)
    }else if(data.obType=='validationsForModule'){
      setValidationsForModule(ob);
    }
  }
  const highliteEmptys=(data)=>{
     
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
    if(data.obType=='validations'){
      setValidations(ob)
    }else if(data.obType=='validationsForModule'){
      setValidationsForModule(ob);
    }
  }

  //Hrushikesh Shelke new code start

  const test = (value) => {
    if(value.trim()==''){
      setValidations({...validations,systSize:'-1'})
    }else{
      setValidations({...validations,systSize:'1'})
    }
    if(value){
      var num = parseFloat(value.replace(/,/g, ''));
      num = num.toFixed(3);
      props.setSystemSize(num);
    }
   
  };

  function handleErrors(type) {
    console.log("click event");
    if (type === "ModuleNumber") {
      if (props.clientNumber.length === 0) {
        console.log("change ");
        props.setClientNumberInvalid(true);
      } else {
        props.setClientNumberInvalid(false);
      }
    }
  }

  const Connectionitems = [];

  for (var a = 0; a < props.interconnNo; a++) {
    Connectionitems.push(a + 1);
  }
const getTotalinterConnNo=()=>{
  let array=[];
  if(props.pvSystemInfo){
    props.pvSystemInfo.forEach((one,i)=>{
    if(!array.includes(parseInt(one.interConnectionNum))){
      array.push(parseInt(one.interConnectionNum))
    }
   })
  }
  return array
}
var totalSystemSize;
const getTotalSystemSize=()=>{
  let num=0;
  if(props.pvSystemInfo){
   props.pvSystemInfo.forEach((one)=>{
     num=Number(num)+Number(one.systemSize)
   })
  }
  totalSystemSize = num
  return num.toFixed(3)
}
const getTotalModule=()=>{
   let num=0;
   if(props.pvSystemInfo){
    props.pvSystemInfo.forEach((one)=>{
      num=num+parseInt(one.noOfModules)
    })
   }
   return num
}
const getTotalInverters=()=>{
   let num=0;
   if(props.pvSystemInfo){
    props.pvSystemInfo.forEach((one)=>{
      num=num+parseInt(one.numberOfInvertors)
    })
   }
   return num
}

function remainingInterconnections(){
  let totalInt=parseInt(props.data.interconnNo);
  let maxInt=0;
  let onlyInter=props.pvSystemInfo?.map(e=>{
    return parseInt(e.interConnectionNum)
  });
  let count = 0;
  for(let i= 1; i<= totalInt ;i++){
   if(!onlyInter.includes(i)){
    count++
   }
  }
  
  return count;
}

  useEffect(()=>{
    setTotalSysSize(totalSystemSize)
    props.setTotalSysSize(totalSystemSize)
  },[totalSystemSize])

  return (
    <>
    {loader?<Loader/>:<></>}
    <Col sm={12}>
      <Button
        onClick={buttontoggle}
        className={buttonState ? "btn-100-active" : "btn-100"}
      >
        PV System Information
        <div className="float-right">
        <Input
            type="checkbox"
            className={
              (handlePVSystemCheck() || props.moduleCheckStatus.PVSystemInformation)
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={(handlePVSystemCheck() || (props.moduleCheckStatus.PVSystemInformation && props.interconnNo > 0))}
            onChange={(e) => {
              if(props.data.interconnNo=='0'||props.data.interconnNo==undefined){
                setInterc('-1')
              }
              setSkipPvSystem(!skipPvSystem)
              props.setModuleCheckStatus({...props.moduleCheckStatus,PVSystemInformation:e.target.checked})
            }}
          />
          {buttonState ? (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleDown}
            />
          ) : (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleRight}
            />
          )}
        </div>
      </Button>
      <Collapse isOpen={props.activeTab == "pvsysteminfo" && buttonState ? true : false}>
        <Card className="createdeal-card">
          <CardBody>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="interconnNo">
                    Number of Interconnections
                    <span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={2}>
                  <Input
                    className="createdeal-textfield"
                    type="number"
                    name="interconnNo"
                    id="interconnNo"
                    style={interc=='-1'?{...validationCss}:{}}
                    value={props.data ? props.data.interconnNo : ''}
                    onChange={(e) => {
                      e.target.value = e.target.value < 0 ? '' : e.target.value
                      checkInterconnectionOnChange(e.target.value)
                      if(e.target.value!=''){
                        setInterc('1')
                       }
                    }}
                    onBlur={(e) => {
                      if(e.target.value==''||e.target.value==undefined||e.target.value==0){
                        setInterc('-1')
                      }else{
                        setInterc('1')    
                      }
                    }}
                  />
                </Col>

                <Col sm={7}></Col>
              </Row>
            </FormGroup>

            <Table striped bordered className="table-pad margin-top-18">
              <thead className="bg-header">
                <tr>
                  <th className="width-64 left-th">Actions</th>
                  <th className=" width-75 middle-th">System Number</th>
                  <th className="middle-th width-75">Interconnection Number</th>
                  <th className="middle-th width-75">System Size (kW)</th>
                  <th className="middle-th width-75">Number of Modules</th>
                  <th className="middle-th pad-12">PV Module Model</th>
                  <th className="middle-th pad-12">Inverter Type</th>
                  <th className="middle-th pad-12">Number of Invertors</th>
                  <th className="right-th pad-12">Inverter Model Number</th>
                </tr>
              </thead>
              <tbody className="font12">
                {props.pvSystemInfo ? (
                  props.pvSystemInfo?.map((info, i) => {
                    return (
                      <tr>
                        <td className="pad0" scope="row">
                          <Button className="btn btn-info btn-info2"
                            onClick={() => editpvSystemInfo(info,i)}
                          >
                            <FontAwesomeIcon
                              className="float-right"
                              icon={faEdit}

                            />
                          </Button>
                          <Button className="btn btn-danger btn-info2"
                            onClick={() => {
                              setIdToDelete(info.pvSystemId)
                              setDeleteAdminModal(!deleteAdminModal)
                            }
                              // deletepvSystemInfo(info.pvSystemId)
                            }>
                            <FontAwesomeIcon
                              className="float-right"
                              icon={faTrashAlt}

                            />
                          </Button>
                          <Modal
                            className="exportmodal-width"
                            isOpen={deleteAdminModal}
                            toggle={deleteAdminModal}
                          >
                            <div className="modal-header-class">Confirmation Message
                              <div className="note-header-icon">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="icon-time-color"
                                  onClick={() => setDeleteAdminModal(!deleteAdminModal)}
                                ></FontAwesomeIcon>
                              </div>
                            </div>
                            <ModalBody style={{height : "25vh" , borderRadius : "10px" , border: "none"}}>
                              <FormGroup className="mar-bottom0 sureMessage">
                                <Row>
                                  <Col sm={1}></Col>
                                  <Col sm={10}>
                                    <span className="text-bold" for="l2code" style={{fontSize : "15px"}}>
                                      Are you sure you want to Delete?
                                    </span>
                                  </Col>

                                  <Col sm={1}></Col>
                                </Row>
                              </FormGroup>

                              <FormGroup className="marb-16 padtop-15 messageButton">
                                <Row>
                                  <Col sm={2}></Col>
                                  <Col sm={4}>
                                    <Button
                                      className="continue-button"
                                      onClick={() => setDeleteAdminModal(!deleteAdminModal)}
                                      style={{padding: "5px 12px"}}
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                  <Col sm={4}>
                                    <Button
                                      className="continue-button"
                                      onClick={() => {
                                        deletepvSystemInfo()
                                        setDeleteAdminModal(!deleteAdminModal)
                                      }}
                                      style={{background: "#fff", color : "#0069d9", border : "1px solid", padding: "5px 12px"}}
                                    >
                                      Yes
                                    </Button>
                                  </Col>
                                  <Col sm={2}></Col>
                                </Row>
                              </FormGroup>
                            </ModalBody>
                          </Modal>
                        </td>
                        <td>{i + 1}</td>
                        <td>{info.interConnectionNum}</td>
                        <td>{info.systemSize}</td>
                        <td>{info.noOfModules}</td>
                        <td>{info.pvmodulemodel}</td>
                        <td>{info.invertertype}</td>
                        <td>{info.numberOfInvertors}</td>
                        <td>{info.inverterModelNumber}</td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}

                <tr>
                  <td className="pad0" scope="row">
                    <Button className="btn-wwp-plus" onClick={toggle} disabled={props.data.interconnNo <= 0 || props.data.interconnNo == undefined ? true : false}>
                      +
                    </Button>
                  </td>

                  <td className="opacity0"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr className="fw-bold">
                  <td><strong>Total</strong></td>
                  <td><strong>{props.pvSystemInfo?props.pvSystemInfo.length:0}</strong></td>
                  <td><strong>{getTotalinterConnNo().length}</strong></td>
                  <td><strong>{getTotalSystemSize()}</strong></td>
                  <td><strong>{getTotalModule()}</strong></td>
                  <td><strong></strong></td>
                  <td><strong></strong></td>
                  <td><strong>{getTotalInverters()}</strong></td>
                  <td><strong></strong></td>
                </tr>
              </tbody>
            </Table>
            <span className="notify-interconnection-text">  {remainingInterconnections() > 0 ? "Note:-  " + remainingInterconnections() + " unassigned Interconnection(s)!" : ""}</span>
          </CardBody>
        </Card>
      </Collapse>
      <Modal
        className="modalwidth-400"
        isOpen={modal}
        backdrop={backdrop}
        toggle={toggle}
      >
        <div className="modal-header-class">System Number {props.pvSystemInfo ? props.pvSystemInfo.length + 1 : 0}
          <div className="note-header-icon">
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-time-color"
              onClick={toggle}
            ></FontAwesomeIcon>
          </div>
        </div>
        <ModalBody className="h500-overflow">
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="workwithoutpermt">
                  Interconnection Number<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={6}>
                <Input
                  className="createdeal-textfield"
                  type="select"
                  name="interConnectionNum"
                  id="interConnectionNum"
                  value={props.interConnectionNum}
                  onChange={(e) => props.setInterConnectionNum(e.target.value)}
                >
                  {Connectionitems?.map((value, i) => {
                    return <option value={value}>{value}</option>;
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="workwithoutpermt">
                  System Size (kW)<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={6}>
                <CurrencyInput
                  className="createdeal-textfield form-control"
                  decimalsLimit="3"
                  decimalScale="3"
                  style={validations.systSize=='-1'?{...validationCss}:{}}
                  value={props.systemSize}
                  onBlur={(e) => test(e.target.value)}
                  onChange={(e) =>{
                    e.target.value=e.target.value.replace(/[^0-9.,]/g,"")
                      testNumForSysSize(e.target.value)
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="noofmodules">
                  Number of Modules<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={6}>
                <Input
                  className="createdeal-textfield"
                  type="text"
                  style={validations.noOfModules=='-1'?{...validationCss}:{}}
                  name="noofmodules"
                  id="noofmodules"
                  value={props.noOfModules}
                  onBlur={(e)=>{
                    if(e.target.value.trim()==''){
                      setValidations({...validations,noOfModules:'-1'})
                    }else{
                      setValidations({...validations,noOfModules:'1'})
                    }
                  }}
                  onChange={(e) => onlyTypeNumber(e)}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="workwithoutpermt">
                  PV Module Model Number<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={6}>
                <CustumSelect
                 addItemAction={toggleNested1}
                 addItemLabel={" Add PV Module"}
                  addFlag={true}
                  value={props.pvmodulemodel ? props.pvmodulemodel : undefined}
                  Color={validations.pvmodulemodel}
                  onChange={(value) => {
                    changePage(value);
                  }}
                  onBlur={(value)=>{
                     
                    if(props.pvmodulemodel.trim()==''){
                      setValidations({...validations,pvmodulemodel:'-1'})
                    }else{
                      setValidations({...validations,pvmodulemodel:'1'})
                    }
                  }}
                  items={pvmoduleList}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15 mt-2">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="invertertype">
                  Inverter Type<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={6}>



                <Input
                  className="createdeal-textfield"
                  type="select"
                  name="invertertype"
                  id="invertertype"
                  value={props.invertertype}
                  style={validations.invertertype=='-1'?{...validationCss}:{}}
                  onBlur={(e)=>{
                     
                    if(e.target.value.trim()=='' || e.target.value==undefined){
                      setValidations({...validations,invertertype:'-1'})
                    }else{
                      setValidations({...validations,invertertype:'1'})
                    }
                  }}
                  onChange={(e) =>{ 
                    props.setinverterModelNumber(undefined);
                      changeInverterType(e.target.value)
                    }}
                >
                  <option value="">Select Inverter Type</option>
                  <option value="String">String Inverter</option>
                  <option value="Micro">Micro Inverter</option>
                </Input>
              </Col>

            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={6}>
                <Label className="text-bold" for="invertertype">
                  Number Of Invertors<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={6}>
                <Input
                  className="createdeal-textfield"
                  type="number"
                  name="numberOfInvertors"
                  id="numberOfInvertors"
                  disabled={isMicroInverter}
                  value={props.numberOfInvertors}
                  style={validations.numberOfInvertors=='-1'?{...validationCss}:{}}
                  onBlur={(e)=>{
                     
                    if(e.target.value.trim()=='' || e.target.value==undefined){
                      setValidations({...validations,numberOfInvertors:'-1'})
                    }else{
                      setValidations({...validations,numberOfInvertors:'1'})
                    }
                  }}
                  onChange={(e) => {
                    e.target.value=e.target.value < 0 ? '' : e.target.value;
                    props.setNumberOfInverters(e.target.value)
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
          {/* <FormGroup className="marb-16 padtop-15">
            </FormGroup> */}
          {inverterModelNoVisible == true ?
            <FormGroup className="marb-16 padtop-15 ">
              <Row>
                <Col sm={6}>
                  <Label className="text-bold" for="inverterModelNumber">
                    Inverter Model Number
                  </Label>
                </Col>
                <Col sm={6}>
                  <CustumSelect
                    addFlag={true}
                    value={props.inverterModelNumber ? props.inverterModelNumber.name : undefined}
                    onChange={(value) => {
                      let module=""
                      if(props.invertertype=='String'){
                        module="String_Invertors"
                      }else{
                        module="Micro_Invertors"
                      }
                      let filterData = inverterModelAllList.filter(one => one.Name == value);
                      props.setinverterModelNumber({ id: filterData[0].id, module: module, name: value });
                     // changeInverterPage(value);
                    }}
                    addItemLabel={`+ Add ${props.invertertype} Inverter`}
                    addItemAction={() => changeInverterPage('CreateInverter')}
                    items={inverterModelList}
                  />
                </Col>
              </Row>
            </FormGroup>
            : <></>}
          <StringInverterModal
          invertertype={props.invertertype}
          setinverterModelNumber={props.setinverterModelNumber}
            getInvertersModelNo={getInvertersModelNo}
            setReloadInverterData={setReloadInverterData}
            stringInverterModal={stringInverterModal}
            setStringInverterModal={setStringInverterModal}
          />
          <MicroInverterModal
          invertertype={props.invertertype}
           setinverterModelNumber={props.setinverterModelNumber}
           getInvertersModelNo={getInvertersModelNo}
            microInverterModal={microInverterModal}
            setReloadInverterData={setReloadInverterData}
            setMicroInverterModal={setMicroInverterModal}
          />

          <FormGroup className="marb-16 padtop-15 mt-2">
            <Row>
              <Col sm={6}>
                <Button className="continue-button" onClick={toggle}>
                  Cancel
                </Button>
              </Col>
              <Col sm={6}>
                <Button className="continue-button" onClick={savePVSystemInfo}>
                  Save
                </Button>
              </Col>
            </Row>
          </FormGroup>

          <Modal
            className="modalwidth-400"
            isOpen={nestedModal1}
            backdrop={backdrop}
            toggle={toggleNested1}
            onClosed={closeAll ? toggle : undefined}
          >
            <div className="modal-header-class">Module Information
              <div className="note-header-icon">
                {" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  className="icon-time-color"
                  onClick={toggleNested1}
                ></FontAwesomeIcon>
              </div>
            </div>
            <ModalBody>
              <FormGroup row className="marb-16  padtop-15">
                <Row>
                  <Col sm={6}>
                    <Label className="text-bold" for="ownerlastname">
                      PV Module Name<span className="red-text">*</span>
                    </Label>
                  </Col>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="Name"
                      id="ownerlastname"
                      value={formInputsValue.Name}
                      className="createdeal-textfield"
                      onChange={(e) => handleChange(e)}
                      style={validationsForModule.Name=='-1'?{...validationCss}:{}}
                      onBlur={(e)=>{
                         
                        if(e.target.value.trim()=='' || e.target.value==undefined){
                          setValidationsForModule({...validationsForModule,Name:'-1'})
                        }else{
                          setValidationsForModule({...validationsForModule,Name:'1'})
                        }
                      }}
                    />
                  </Col>
    
                </Row>
              </FormGroup>
              <FormGroup row className="marb-16  padtop-15">
                <Row>
                  <Col sm={6}>
                    <Label className="text-bold" for="ownerlastname">
                      PV Module Type<span className="red-text">*</span>
                    </Label>
                  </Col>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="modelNumber"
                      id="ownerlastname"
                      value={formInputsValue.modelNumber}
                      className="createdeal-textfield"
                      onChange={(e) => handleChange(e)}
                      style={validationsForModule.modelNumber=='-1'?{...validationCss}:{}}
                      onBlur={(e)=>{
                         
                        if(e.target.value.trim()=='' || e.target.value==undefined){
                          setValidationsForModule({...validationsForModule,modelNumber:'-1'})
                        }else{
                          setValidationsForModule({...validationsForModule,modelNumber:'1'})
                        }
                      }}
                    />
                  </Col>
                  
                </Row>
              </FormGroup>
              <FormGroup row className="marb-16  padtop-15">
                <Row>
                  <Col sm={6}>
                    <Label className="text-bold" for="ownerlastname">
                      Manufacturer<span className="red-text">*</span>
                    </Label>
                  </Col>
                  <Col sm={6}>
                    <CustumSelect
                      addFlag={true}
                      placeholder={'Select Manufacturer'}
                      value={props.manufacturer ? props.manufacturer : undefined}
                      onChange={(value) => {
                        // props.handleOnChangeDeal({ currentTarget: { name: 'contractor', value: value } }) 
                        changeToManufacturer(value);
                      }}
                      Color={validationsForModule.manufacturer}
                      onBlur={(e)=>{
                         
                        if(props.manufacturer.trim()==''){
                          setValidationsForModule({...validationsForModule,manufacturer:'-1'})
                        }else{
                          setValidationsForModule({...validationsForModule,manufacturer:'1'})
                        }
                      }}
                      addItemLabel={" Add PV Manufacturer"}
                      addItemAction={openManufacturerModal}
                      items={manufacturerName}
                    />
                  </Col>
                 
                  
                </Row>
              </FormGroup>
              <FormGroup row className="marb-16  padtop-15 mt-2">
                <Row>
                  <Col sm={6}>
                    <Label className="text-bold" for="ownerlastname">
                      Module Size (W)<span className="red-text">*</span>
                    </Label>
                  </Col>
                  <Col sm={6}>
                    <Input
                      type="text"
                      name="maxDCwatt"
                      id="ownerlastname"
                      className="createdeal-textfield"
                      value={formInputsValue.maxDCwatt}
                      onChange={(e) => changePositiveValue(e)}
                      style={validationsForModule.maxDCwatt=='-1'?{...validationCss}:{}}
                      onBlur={(e)=>{
                         
                        if(e.target.value.trim()=='' || e.target.value==undefined){
                          setValidationsForModule({...validationsForModule,maxDCwatt:'-1'})
                        }else{
                          setValidationsForModule({...validationsForModule,maxDCwatt:'1'})
                        }
                      }}
                      // onBlur={(e) => changePositiveValue(e)}
                    />
                  </Col>
                 
                </Row>
              </FormGroup>
              <FormGroup row className="marb-16  padtop-15">
                <Row>
                  <Col sm={6}>
                    <Label className="text-bold" for="ownerlastname">
                      UL Number<span className="red-text">*</span>
                    </Label>
                  </Col>
                  <Col sm={6}>
                    <Input
                      className="createdeal-textfield"
                      type="text"
                      name="ulNumber"
                      value={formInputsValue.ulNumber}
                      id="ownerlastname"
                      style={validationsForModule.ulNumber=='-1'?{...validationCss}:{}}
                      onBlur={(e)=>{
                         
                        if(e.target.value.trim()=='' || e.target.value==undefined){
                          setValidationsForModule({...validationsForModule,ulNumber:'-1'})
                        }else{
                          setValidationsForModule({...validationsForModule,ulNumber:'1'})
                        }
                      }}
                      onChange={(e) => changePositiveValue(e)}
                    />
                  </Col>
                  
                </Row>
              </FormGroup>

              <FormGroup className="marb-16 padtop-15 mt-2">
                <Row>
                  <Col sm={6}>
                    <Button className="continue-button" onClick={toggleNested1}>
                      Cancel
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      className="continue-button"
                      onClick={() => createPVModule()}
                    >
                      Save
                    </Button>
                  </Col>
               
                </Row>
              </FormGroup>

              <ManufacturerModal
                setManufacturer={props.setManufacturer}
                setReloadManufacturerData={setReloadManufacturerData}
                getManufacturerName={getManufacturerZoho}
                manufacturerModal={manufacturerModal}
                setManufacturerModal={setManufacturerModal}
                manufacturer={props.manufacturer}
                manufacturerName={manufacturerName} 
              />
            </ModalBody>
          </Modal>
        </ModalBody>
      </Modal>
    </Col>
    </>
  );
}

export default PVSystemInformation;
