import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import './RenderAllTasks.css';
import { Button, Checkbox, Collapse, Input, Select, Table, TimePicker } from 'antd';
import { SelectedUserAvatar, UserPicker } from '../../common/UserPicker/UserPicker';
import { Option } from 'antd/es/mentions';
import { CaretDownFilled, CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CaretUpOutlined, EyeOutlined, FlagFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { AppDataRequired } from '../../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import swal from "sweetalert";
import { getPriorityValue } from '../HR/TimeClock/Util';
import AddInlineData from './AddInlineData';
import AwsServerService from '../../common/util/AwsServerService';

export const TaskGroupCardForTemplate = ({ taskData, header, data , allPipelineData ,groupFilter ,viewTaskFunction,module,editData,setEditData,
    selectedTable,isSubTaskView, setIsSubTaskView,refreshData, setPageRefreshData,setIdToDelete}) => {
    const [rowData, setRowData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // State for sorting
    const { AllUsers } = useContext(AppDataRequired);
    useEffect(e => {
        setRowData(data);
    }, [data])
    const toggleExpandIconOfSubtask=(e)=>{
        e.stopPropagation();
        let arr = e.currentTarget.childNodes;
        let taskId= e.currentTarget.id;
        let arrofsubtasks = document.querySelectorAll(`.${taskId}`);
        arr.forEach(element => {
            if(element.classList.contains("SHOW")){
                element.classList.remove("SHOW")
                element.classList.add("HIDE")
                arrofsubtasks.forEach(subtask=>{
                    // subtask.classList.remove("")
                    subtask.classList.add("HIDEROW")
                })
            }else if(element.classList.contains("HIDE")){
                element.classList.remove("HIDE")
                element.classList.add("SHOW")
                arrofsubtasks.forEach(subtask=>{
                    // subtask.classList.remove("")
                    subtask.classList.remove("HIDEROW")
                })
            }
        });
        console.log(arr);
        
    }
    
    function actionListCellRenderer(data1,module) {  
        // console.log("item",data1);
        //// Triggers are remaining for EDIT DELETE and CREATE //////
        return <>
            <div className='global-action-btn-for-tables'>
                <button value={data1?.TaskROWID}
                    onClick={e=>{
                        if(module == "task"){
                            let task=data?.find(f=>f.TaskROWID==data1?.TaskROWID);
                                viewTaskFunction(task,"TASK")
                        }else{
                            setIsSubTaskView(true)
                            let task=data?.find(f=>f.TaskROWID==data1?.TaskROWID);
                            let subTask=task?.SubTasks?.find(f=>f?.SubTaskROWID == data1?.SubTaskROWID);
                            viewTaskFunction(subTask,"SUBTASK")
                        }
                    }}
                >Edit</button>
                <button value={data1?.TaskROWID} style={{color:"red"}}
                    onClick={e=>{
                          swal({
                            title: "Warning!",
                            text: "Are you sure you want to delete the Task?",
                            icon: "warning",
                            buttons: {
                              cancel: "Cancel",
                              confirm: "Proceed",
                            },
                          }).then(async(isDelete)=>{
                            if(isDelete){
                              if (module == "task") {
                                if(data1?.TaskROWID){
                                    if (selectedTable == "tasks") {
                                      AwsServerService.deleteTask(data1?.TaskROWID)
                                        .then((res) => {
                                          setPageRefreshData(!refreshData);
                                        })
                                        .catch((e) => {
                                          console.log("error while deleting task");
                                        });
                                    } else {
                                      AwsServerService.deleteTaskInTemplate(data1?.TaskROWID)
                                        .then((res) => {
                                          console.log("res", res);
                                          setPageRefreshData(!refreshData);
                                        })
                                        .catch((e) => {
                                          console.log("error while deleting task");
                                        });
                                    }
                                }else{
                                    setIdToDelete(data1?.id)
                                }
                              } else if (module == "subtask") {
                                if(data1?.SubTaskROWID){
                                    if (selectedTable == "tasks") {
                                      AwsServerService.deleteSubtask(data1?.SubTaskROWID)
                                        .then((res) => {
                                          console.log("res", res);
                                          setPageRefreshData(!refreshData);
                                        })
                                        .catch((e) => {
                                          console.log("error while deleting task");
                                        });
                                    } else {
                                      AwsServerService.deleteSubtaskInTemplate(data1?.SubTaskROWID)
                                        .then((res) => {
                                          console.log("res", res);
                                          setPageRefreshData(!refreshData);
                                        })
                                        .catch((e) => {
                                          console.log("error while deleting task");
                                        });
                                    }
                                }else{
                                    setIdToDelete(data1?.id)
                                }
                              }
                            }
                          })
                    }}
                >Delete</button>
            </div>
        </>
      }
      const sortData = (field) => {
        let direction = 'ascending';
        if (sortConfig.key === field) {
            if (sortConfig.direction === 'ascending') {
                direction = 'descending';
            } else if (sortConfig.direction === 'descending') {
                direction = 'none';
            }
        }
    
        if (direction === 'none') {
            setRowData(data); // Reset to original data
            setSortConfig({ key: '', direction: '' });
        } else {
            const sortedData = [...rowData].sort((a, b) => {
                if (a[field] < b[field]) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a[field] > b[field]) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            setRowData(sortedData);
            setSortConfig({ key: field, direction });
        }
    };
    
    // Render sorting icons based on the current sort state
    const renderSortIcon = (field) => {
        if (sortConfig.key === field) {
            if (sortConfig.direction === 'ascending') {
                return <CaretUpOutlined />;
            } else if (sortConfig.direction === 'descending') {
                return <CaretDownOutlined />;
            }
        }
        return null; // No icon when not sorted
    };
    const taskHeaders = [
        {
            label: "Task Name",
            field: "TaskName",
            render: (data, field) => {
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? <Row style={{
                    display: "flex",
                    height: "41px",
                    alignItems:"center",
                    cursor: "pointer"
                }} sm={12}
                onClick={e=>{
                    viewTaskFunction(data,"TASK")
                }}
                ><Col id={`TaskId-${data.TaskId}`} className='Expand-Icon-Section' onClick={toggleExpandIconOfSubtask} sm={1}>
                    {data?.SubTasks?.length>0 && <><CaretRightOutlined className='LEFT SHOW'/><CaretDownOutlined className='RIGHT HIDE' /></>}
                    </Col>
                    <Col style={{display:"flex",flexDirection:"row","align-items": "center"}}>
                    <span title={data[field]} className='Elipsis-Class' style={{width:"150px"}}>{data[field]}</span>
                    {/* <span className='TaskIdSpan'>{data[module=="View Task"?"SubTaskId":"TaskId"]  && `(${data[module=="View Task"?"SubTaskId":"TaskId"]})`}</span> */}
                    {/* <Button className='Task-View-Btn' type='primary' icon={<FontAwesomeIcon icon={faEye}/>} onClick={e=>{
                        viewTaskFunction(data,"TASK")
                    }} ghost>View</Button> */}
                    </Col>
                </Row>
                :
                <Input variant='borderless' value={editData ? editData?.TaskName : ""} style={{maxWidth:"280px", background:"#fff", marginTop:"2px"}} onChange={(e)=>{setEditData({...editData, TaskName : e.target.value})}}/>
            );
            }
        },
        {
            label: "Assignee",
            field: "Assignee",
            render: (data, field) => {
                let assignee = data[field];
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? <UserPicker className={"No-Border"} values={assignee} mode="multiple" onChange={e => {
                    // let temp={...editData}
                    //     temp["Assignee"]=e;
                        console.log("Assignee");
                        // setEditData(temp);
                }} />
            :
            <UserPicker rootClassName={"table-dropdown"} className={"No-Border"} values={editData ? editData["Assignee"] : ""} mode="multiple" onChange={e => {
                let temp={...editData}
                    temp["Assignee"]=e;
                    // console.log(temp);
                    setEditData(temp);
            }} />
            )
            }
        },
        {
            label: "Supervisor",
            field: "Supervisor",
            render: (data, field) => {
                let Supervisor = data[field];
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? <UserPicker className={"No-Border"} values={Supervisor} mode="" onChange={(e, data) => {
                    // let temp={...editData}
                        // temp["Supervisor"]=e;
                        console.log("Supervisor");
                        // setEditData(temp);
                }} />
            :
            <UserPicker rootClassName={"table-dropdown"} className={"No-Border"} values={editData ? editData["Supervisor"] : ""} mode="" onChange={(e, data) => {
                let temp={...editData}
                    temp["Supervisor"]=e;
                    // console.log(temp);
                    setEditData(temp);
            }} />
            )
            }
        },
        // {
        //     label: "Description",
        //     field: "TaskDescription",
        //     render: (data, field) => {
        //         let description = data[field];
        //         return <span title={description} className='Elipsis-Class' style={{width:"400px"}}>{description}</span>;
        //     }
        // },
        {
            label: "Priority",
            field: "Priority",
            render: (data, field) => {
                // return data[field];
                return (editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? <Select className='No-Border priority-icon' name="Priority" placeholder="Select an option" style={{ width: 200 }}
                    value={data[field] ? data[field] : "Not Set"}
                    onChange={(val) => {
                        // let temp={...editData}
                        // temp["Priority"]=val;
                        console.log("Priority");
                        // setEditData(temp)
                    }}
                    // suffixIcon={<CaretDownFilled />}
                    rootClassName="table-dropdown"
                    dropdownRender={(menu) => (
                        <>
                          {menu}
                        </>
                      )}
                      optionLabelProp="icon"
                      title={data[field]}
                >
                    <Option value="Not Set" icon={<FlagFilled style={{ color: getPriorityValue("Not Set") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not Set
                    </Option>
                    <Option value="Low" icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
                    </Option>
                    <Option value="Medium" icon={<FlagFilled style={{ color: getPriorityValue("Medium") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Medium") }} /> Medium
                    </Option>
                    <Option value="High" icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("High") }} /> High
                    </Option>
                </Select>
                :
                <Select className='No-Border priority-icon' name="Priority" placeholder="Select an option" style={{ width: 200 }}
                    value={editData ? editData[field] : "Not Set"}
                    onChange={(val) => {
                        let temp={...editData}
                        temp["Priority"]=val;
                        // console.log(temp);
                        setEditData(temp)
                    }}
                    // suffixIcon={<CaretDownFilled />}
                    rootClassName="table-dropdown"
                    dropdownRender={(menu) => (
                        <>
                          {menu}
                        </>
                      )}
                      optionLabelProp="icon"
                      title={data[field]}
                >
                    <Option value="Not Set" icon={<FlagFilled style={{ color: getPriorityValue("Not Set") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not Set
                    </Option>
                    <Option value="Low" icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
                    </Option>
                    <Option value="Medium" icon={<FlagFilled style={{ color: getPriorityValue("Medium") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("Medium") }} /> Medium
                    </Option>
                    <Option value="High" icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}>
                        <FlagFilled style={{ color: getPriorityValue("High") }} /> High
                    </Option>
                </Select>
            )
            }
        },
        {
            label: "Time Estimate",
            field: "TimeEstimate",
            render: (data, field) => {
                return (
                    // editData?.length == 0 || data["TaskId"] || data["SubTaskId"] || data["SubTaskROWID"] || data["flag"] ? 
                <TimePicker
                className="TimePicker-Estimate No-Border"
                defaultValue={dayjs("12:08", "HH:mm")}
                name="TimeEstimate"
                format={"HH:mm"}
                value={data[field] ? dayjs(data[field], "HH:mm") : null}
                onChange={(time,timestr)=>{
                //   let temp={...taskData}
                //   temp["TimeEstimate"]=timestr;
                //   setTaskData(temp)
                }}
              /> 
            //   :
            //   <TimePicker
            //   className="TimePicker-Estimate No-Border"
            //   defaultValue={dayjs("12:08", "HH:mm")}
            //   name="TimeEstimate"
            //   format={"HH:mm"}
            //   value={data[field] ? dayjs(data[field], "HH:mm") : null}
            //   onChange={(time,timestr)=>{
            //     let temp={...editData}
            //     temp["TimeEstimate"]=timestr;
            //     setEditData(temp)
            //   }}
            // /> 
            );
            }
        },
        {
            label: "Status",
            field: "Status",
            render: (data, field) => {
                return data[field];
            }
        },
        {
            label: "Pipeline",
            field: "PipelineROWID",
            render: (data, field) => {
                let pipeline = allPipelineData.find(e=>e.PipelineROWID==data.PipelineROWID);
                return pipeline ? pipeline.PipelineName: "N/A";
            }
        },
        // {
        //     label: "Is Milestone",
        //     field: "IsMilestone",
        //     render: (data, field) => {
        //         return data[field]=="false"?"No":"Yes";
        //     }
        // },
        {
            label: "Action",
            field: "Action",
            render: (data, field) => {
                return actionListCellRenderer(data, "task")
            }
        },
    ]
    const subTaskHeaders = [
        {
            label: "Task Name",
            field: "TaskName",
            render: (data, field) => {
                return <Row style={{
                    display: "flex",
                    height: "41px",
                    alignItems:"center",
                    cursor:"pointer"
                }} sm={12}
                onClick={e=>{
                    viewTaskFunction(data,"SUBTASK")
                }}
                ><Col sm={1}></Col><Col sm={1}></Col><Col style={{display:"flex",flexDirection:"row","align-items": "center"}}>{data[field]}
                {/* <span className='TaskIdSpan'>{`(${data["SubTaskId"]})`}</span> */}
                {/* <Button className='Task-View-Btn' type='primary' icon={<FontAwesomeIcon icon={faEye}/>} onClick={e=>{
                    viewTaskFunction(data,"SUBTASK")
                }} ghost>View</Button> */}
                </Col></Row>;
            }
        },
        {
            label: "Assignee",
            field: "Assignee",
            render: (data, field) => {
                let assignee = data[field];
                return <UserPicker rootClassName={"table-dropdown"} className={"No-Border"} values={assignee} mode="multiple" onChange={e => {
                    console.log("sub assignee", e);
                }} />;
            }
        },
        {
            label: "Supervisor",
            field: "Supervisor",
            render: (data, field) => {                
                let Supervisor = data[field];
                return <UserPicker rootClassName={"table-dropdown"} className={"No-Border"} values={Supervisor} mode="" onChange={e => {
                    console.log("sub Supervisor", e);
                    
                }} />;
            }
        },
        // {
        //     label: "Description",
        //     field: "TaskDescription",
        //     render: (data, field) => {
        //         let description = data[field];
        //         return <span title={description} className='Elipsis-Class' style={{width:"400px"}}>{description}</span>;
        //     }
        // },
        {
            label: "Priority",
            field: "Priority",
            render: (data, field) => {
                // return data[field];
                return <Select className='No-Border priority-icon' name="Priority" placeholder="Select an option" style={{ width: 200 }}
                value={data[field] ? data[field] : "Not Set"}
                onChange={(val) => {
                }}
                // suffixIcon={<CaretDownFilled />}
                rootClassName="table-dropdown"
                optionLabelProp="icon"
                title={data[field]}
            >
                <Option value="Not Set" icon={<FlagFilled style={{ color: getPriorityValue("Not Set") , height:"100px"}} />}>
                    <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not Set
                </Option>
                <Option value="Low" icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}>
                    <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
                </Option>
                <Option value="Medium" icon={<FlagFilled style={{ color: getPriorityValue("Medium") }} />}>
                    <FlagFilled style={{ color: getPriorityValue("Medium") }} /> Medium
                </Option>
                <Option value="High" icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}>
                    <FlagFilled style={{ color: getPriorityValue("High") }} /> High
                </Option>
            </Select>;
            }
        },
        {
            label: "Time Estimate",
            field: "TimeEstimate",
            render: (data, field) => {
                return <TimePicker
                className="TimePicker-Estimate No-Border"
                defaultValue={dayjs("12:08", "HH:mm")}
                name="TimeEstimate"
                format={"HH:mm"}
                value={data[field] ? dayjs(data[field], "HH:mm") : null}
                onChange={(time,timestr)=>{
                //   let temp={...taskData}
                //   temp["TimeEstimate"]=timestr;
                //   setTaskData(temp)
                }}
              />;
            }
        },
        {
            label: "Status",
            field: "Status",
            render: (data, field) => {
                return data[field];
            }
        },
        {
            label: "Pipeline",
            field: "PipelineROWID",
            render: (data, field,task) => {
                let pipeline = allPipelineData.find(e=>e.PipelineROWID==task.PipelineROWID);
                return pipeline?.PipelineName? pipeline.PipelineName: "N/A";
            }
        },
        // {
        //     label: "Is Milestone",
        //     field: "IsMilestone",
        //     render: (data, field) => {
        //         return <></>;
        //     }
        // },
        {
            label: "Action",
            field: "Action",
            render: (data, field) => {
                // console.log("datttttttttta SubTaskROWID", data);
                return actionListCellRenderer(data , "subtask")
            }
        },
       
    ]
    const getTabCard = () => {
        // if(groupFilter.group2.name=="Pipeline"){
        //     let pipeline = allPipelineData.find(e=>e.PipelineROWID==header);
        //     return <div className='Group-Titel-Card'>
        //         <span>{pipeline?.PipelineName? pipeline.PipelineName: "N/A"}</span>
        //     </div>
        // }else if(groupFilter.group2.name=="Assignee" || groupFilter.group2.name=="Supervisor"){
        //     let user = AllUsers.find(e=>e.zuid==header);
        //     return <div className='Group-Titel-Card'>
        //         <span>{SelectedUserAvatar({label:user?.full_name,value:user?.zuid})}</span>
        //     </div>
        // }else{
        //     return <div className='Group-Titel-Card'>
        //         <span>{header}</span>
        //     </div>
        // }
        return "Task Name"
    }
    const getTaskHeaders = () => {
        return taskHeaders.map((col, index) => {
            return <th onClick={() => sortData(col.field)} className={`${col.label=="Action" && "custom-sticky"} ${index == 0 ? "min-width-250 task_table_header task-card-title justify-content-center" : ""}`}>{index == 0 ? getTabCard() : col.label}
            {renderSortIcon(col.field)}
            </th>
        })
    }
    const getTaskRow = (data) => {
        return taskHeaders.map((col, index) => {            
            return <td className={`${col.label=="Action" && "custom-sticky"} ${data?.SubTasks?.length>0 && index==0?"connecting-task-1":""}`}>{col.render(data, col.field)}</td>
        })
    }
    const getSubTaskRow = (data,task) => {
        return subTaskHeaders.map((col, index) => {
            return <td className={`${col.label=="Action" && "custom-sticky"} ${index==0?"connecting-task-2":""}`}>{col.render(data, col.field,task)}</td>
        })
    }
    const getSubtaskCollapse = (subtasks, task) => {
        return (subtasks.map((sub, i) => {
            return <tr className={`TaskId-${task.TaskId} HIDEROW`}>{getSubTaskRow(sub,task)}</tr>;
        }))
    }
    return (
        <Col sm={12} className='TaskGroup2Card'>
            <Row sm={12}>
                <table className='table task-table task-content-table table-hover'>
                    <thead className=''>
                        {getTaskHeaders(header)}
                    </thead>
                    <tbody>
                        {rowData?.map((row, index) => {                     
                            return <>
                                <tr key={index}>
                                    {getTaskRow(row)}
                                </tr>
                                {row?.SubTasks?.length > 0 && getSubtaskCollapse(row.SubTasks,row)}
                            </>
                        })}
                    </tbody>
                </table>
            </Row>
        </Col>
    )
}
