import React, { useEffect, useState } from 'react'
import './MultiSelectInput.css';
import { Col, Row } from 'reactstrap';
import { OtsideClickClosingWrapper } from '../../../common/EmojiReactionPicker/OtsideClickClosingWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
export const MultiSelectInpute = ({clear, preInputeValue,disabled,isMultiSelect,values, preValues ,onChange, lable , dropdownDisplayKey , dropdownValueKey, showCC, showBCC}) => {
  const [selectedValues,setSelectedValues]=useState([])
  const [filteredValues,setFilteredValues]=useState([]);
  const [allValues,setAllValues]=useState([]);
  const [inputValue,setInputValue]=useState();
  const [showSelect,setShowSelect]=useState(false);
  useEffect(e=>{
    setSelectedValues([])
    setInputValue("");
  },[clear])
  useEffect(e=>{
    if(values && values.length>0 && isMultiSelect){
      let arr=values.map(e=>{
        let obj={};
        obj[dropdownDisplayKey]=e[dropdownDisplayKey]
        obj[dropdownValueKey]=e[dropdownValueKey]
        obj["status"]=true;
        return obj
      })
      setAllValues(arr)
    }
  },[values])
  useEffect(e=>{
     
    if(preValues){
      setSelectedValues(preValues);
    }else{
      setSelectedValues([]);
    }
  },[preValues])
  useEffect(e=>{
      setInputValue(preInputeValue);
  },[preInputeValue])
  useEffect(()=>{
    if(selectedValues?.length>0 && isMultiSelect){
      onChange(selectedValues);
    }
  },[selectedValues])
  const removeValue=(val)=>{  
    if(val){
      let newArr=selectedValues.filter(e=>e[dropdownDisplayKey]!=val[dropdownDisplayKey]);
      onChange(newArr);
      setSelectedValues(newArr);
    }
  }
  const filterValues=(val)=>{
       
    if(val){
      setShowSelect(true)
      let newArr=allValues?.filter(e=>e[dropdownDisplayKey].includes(val));
      setFilteredValues(newArr);
    }else{
      setShowSelect(false)
      setFilteredValues(values);
    }
  }
  const selectValue=(val,entered)=>{
       
    if(!entered){
      if(val && selectedValues.find(e=>e[dropdownDisplayKey]==val[dropdownDisplayKey])){
      }else if(val){
        let tempArr=[...selectedValues,val];
        onChange(tempArr);
        setSelectedValues(tempArr);
        setInputValue("")
      }
    }else{
      let isPresent=allValues.find(e=>e[dropdownDisplayKey]==val.trim());
      if(val && selectedValues.find(e=>e[dropdownDisplayKey]==val.trim())){
      }else if(val && isPresent){
        let tempArr=[...selectedValues,isPresent];
        onChange(tempArr);
        setSelectedValues(tempArr);
        setInputValue("")
      }else if(val){
        let obj={};
        obj[dropdownDisplayKey]=val.trim();
        obj[dropdownValueKey]=val.trim();
        obj["status"]=false;
        let tempArr=[...selectedValues,obj];
        onChange(tempArr);
        setInputValue("")
        setSelectedValues(tempArr);
      }
    }
  }
  return (
    <div className='Custome-Inpute-Wrapper' style={lable == "Cc" ? showCC ? {display: ""} : {display: "none"} : lable == "Bcc" ? showBCC ? {display: ""} : {display: "none"} : {display: ""}}>
      <Row className='Custome-Row' sm={12}>
        <Col className='Titels' sm={1}>
          {lable}
        </Col>
        <Col className='inpute-col' sm={11}>
          <Row sm={10}>
            {selectedValues?.length>0 && isMultiSelect && selectedValues.map(s=>{
                return (<Col className='selected-value-card' sm={2} style={{overflow:"ellipse"}}>
                  <span>
                    {s.status &&  <FontAwesomeIcon color='green' icon={faCheckCircle} />}
                  </span>
                  <span>
                    {s[dropdownDisplayKey]}
                  </span>
                  <span>
                    <FontAwesomeIcon color='red' icon={faTimes} onClick={e=>{
                      removeValue(s)
                    }}/>
                  </span>
                </Col>)
              })} 
            <Col sm={isMultiSelect?4:10} className='Input-Wrapper'>
              <input readOnly={disabled?true:false} value={inputValue} class="form-control form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example" 
                onChange={e=>{
                  setInputValue(e.currentTarget.value);
                  if(isMultiSelect){
                    filterValues(e.currentTarget.value);
                  }else{
                    onChange(e.currentTarget.value);
                  }
                }}
                onKeyUp={k=>{
                     
                  if(k.code=="Enter"){
                    selectValue(inputValue,true)
                  }
                }}
              />
              {showSelect && isMultiSelect && filteredValues?.length>0 && <div className='Select-Wrapper'>
                <OtsideClickClosingWrapper className={"Values-Dropdown"} close={() => {
                  setShowSelect(false)
                }}>
                  {filteredValues && filteredValues.map(v=>{
                    return (
                      <div className='value-card' onClick={()=>{
                        selectValue(v);

                      }}>
                        <span>{v[dropdownDisplayKey]}</span>
                      </div>
                    )
                  })}
                </OtsideClickClosingWrapper>
              </div>}
            </Col>
          </Row>

        </Col>
      </Row>

    </div>
  )
}
