import React, { useEffect, useState } from 'react'
import { base64ToArrayBuffer } from '../../HR/TimeClock/Util'
import * as XLSX from 'xlsx';
import './DocumentPreview.css'

export const DocumentPreview = ({ base64String}) => {
    const [xlsxData, setXlsxData] = useState(null);
    const [dataStr,setDataStr] = useState("")
    const [mimeType,setMimeType] = useState("")
    useEffect(e=>{
        if(base64String){
            
            const temp = base64ToArrayBuffer(base64String);
            setMimeType(temp)
            if(temp === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                handlePreview();
            }
            const src = `data:${temp};base64,${base64String}`;
            setDataStr(src);
        }
    },[base64String])

    const renderExcel=()=>{
        return (
            <div className='Preview'>
              {xlsxData ? (
                <table>
                  <tbody>
                    {xlsxData.map((row, rowIndex) => (
                      <tr className='Preview-Tr' key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td className='Preview-Td' key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}
            </div>
          );
    }

    const handlePreview = () => {
        const workbook = XLSX.read(base64String, { type: 'base64' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setXlsxData(data);
      };
    
      return (
        <div>
        {mimeType.startsWith('image/') && (
          <img src={dataStr} alt="Document Preview" style={{ width: '100%', height: 'auto' }} />
        )}
        {mimeType === 'application/pdf' && (
          <iframe src={dataStr} title="PDF Preview" style={{ width: '100%', height: '500px' }}></iframe>
        )}
        {mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
          renderExcel()
        )}
        {!mimeType && <p>Unsupported file type.</p>}
      </div>
      );
}
