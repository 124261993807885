import { Row, Select, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./UserPicker.css";
import { AppDataRequired } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { Col } from "reactstrap";
import { Option } from "antd/es/mentions";
import {
  CaretDownFilled,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import assigntask from "../../assets/images/svg/assigntask.svg";

export const SelectedUserAvatar = (props) => {
  const getUserThumbnailUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
  };
  const { label, value, closable, onClose } = props;
  // console.log("------details---",props);

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      // color={value}
      className="CustomeUserTag"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      icon={value ? <img style={{ cursor: "default" }} title={label} className='Icon' src={getUserThumbnailUrl(value)} /> : <UserOutlined className='Icon' style={{border : "0px"}}/>}
      style={{marginInlineEnd: 4}}
    >{label}</Tag>
  );
};
export const UserPicker = ({
  options,
  values,
  onChange,
  mode,
  className,
  rootClassName,
}) => {
  const { AllUsers } = useContext(AppDataRequired);
  const [userOptions, setUserOptions] = useState([]);
  // const options = [
  //     {
  //       value: 'gold',
  //     },
  //     {
  //       value: 'lime',
  //     },
  //     {
  //       value: 'green',
  //     },
  //     {
  //       value: 'cyan',
  //     },
  //   ];
  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            icon: <FontAwesomeIcon icon={faTags} />,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        let emptyObj = {
          label: "None",
          value: "",
          icon: <FontAwesomeIcon icon={faTags} />,
          // subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`
        };
        arr.unshift(emptyObj);
        setUserOptions(arr);
      }
    },
    [AllUsers]
  );
  return (
    // modes can be : "multiselect" or default
    <Select
      className={`${className ? className : ""}`}
      mode={""}
      placeholder="Select option"
      // tagRender={SelectedUserAvatar}
      value={values}
      style={{
        width: "100%",
      }}
      onChange={onChange}
      rootClassName={rootClassName}
      // suffixIcon={<CaretDownFilled />}
      // options={userOptions}
      // dropdownRender={(menu) => (
      //   <>
      //     {menu}
      //   </>
      // )}
      optionLabelProp="icon"
    >
      {userOptions.map((o) => {
        return (
          <Option
            key={o.value}
            value={o.value}
            label={o.label}
            icon={
              values ? (
                <img
                  style={{
                    cursor: "pointer",
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                  title={o.label}
                  className="Icon"
                  src={
                    "https://contacts.zoho.com/file?ID=" + values + "&fs=thumb"
                  }
                />
              ) : (
                <img style={{ cursor: "default" }} src={assigntask} />
              )
            }
          >
            {" "}
            <Row>{SelectedUserAvatar(o)}</Row>
          </Option>
        );
      })}
    </Select>
  );
};
