import { io } from 'socket.io-client';
import config from './config.json'
let socket;

export const getSocket = () => {
  let user=JSON.parse(localStorage.getItem("userinfo"));
  if (!socket) {
    socket = io(config.backend_aws_server_url, {
      transports: ['websocket', 'polling'], // Ensure proper transport methods
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      query: {email : user?.email_id} 
    });

    socket.on('connect', (data) => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });
  }
  return socket;
};
