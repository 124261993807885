import React, { useState,useEffect } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import ReactInputMask from "react-input-mask";

const IndividualOwner=(props)=> {

  //Hrushikesh shelke new code 
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  // end
      
  const [buttonState, setbuttonState] = useState(false);
  const [NumInvalid, setNumInvalid]=useState(false);
  const [mailInvalid, setMailInvalid]=useState(false);
  const [skipOwner, setSkipOwner] = useState(false);
  const [validation,setValidation] = useState({
    OwnerFrstNme:"0",
    // ownerMiddle:"0",
    ownerlastname:"0",
    phoneNumber:"0",
    email:"0"

  })

  const highliteEmptys=(data)=>{
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
      setValidation(ob)
  }

  const onChangePhoneNumber = (value) => {
    var value1= value.replace(/[-#*$+/?=%() ]/g,'')
    let onlyNo= value1.replace(/\D/g, '');
    if(onlyNo.length>10){
      return
    }
    if(onlyNo.length==10){
      setValidation({...validation,phoneNumber:'1'})
    }
    props.setPhoneNumber(value);
    props.handleOnChangeDeal({currentTarget:{name:"phoneNumber",value:value}})
   
  };
  //Hrushikesh shelke contact no formating starts
  // const formatContactNo()
  //Hrushikesh shelke contact no formating ends
  
  const onBlurPhoneNumber = (value) => { 
    var v1 = value.replace(/[-#*$+/?=%() ]/g, "");
    let value1= v1.replace(/\D/g, '');
    if (value1.length < 10 ) {
      setValidation({...validation,phoneNumber:'-1'})
      setNumInvalid(true);
    }else{
      setValidation({...validation,phoneNumber:'1'})
      setNumInvalid(false);
    }
  };

  let handlemailid = ( email ) => {
    // Email validation
    let validMail=false;
    let str=email.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
     props.setEmailAdd(email);
     if ( validMail ) {
       setValidation({...validation,email:'1'})
       setMailInvalid(false);
         // this is a valid email address
         // call setState({email: email}) to update the email
         // or update the data in redux store.
     }
     else {
       if(email.length==0){
         setValidation({...validation,email:'-1'})
         setMailInvalid(false);
       }else{
         setValidation({...validation,email:'-1'})
         setMailInvalid(true);
       }
     }
 
 }

 const onChangeEmail= (value) => {  
  if(value.target.name=='emailAdd'){
    // Email validation
    let validMail=false;
    let str=value.target.value.toString()
    const regforSpec=/[_!#$%^&*()<>?|\s\\}{~:]/i;
    const reg = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{1,15})[ ]*$/i;
    if(!(str.charCodeAt(0)>=33 && str.charCodeAt(0)<=47) && !(str.charCodeAt(0)>=58 && str.charCodeAt(0)<=64)){
        if(!regforSpec.test(str)){
            if(reg.test(str)&& valid(str) && !str.includes("`") && !str.includes("/") && !str.includes("-")){
              validMail=true;
            }
        }
    }
    // use validMail(true/false) for valoidation
    if ( validMail ) {
      setValidation({...validation,email:"1"})
    }else{
      setValidation({...validation,email:"-1"})
    }
   }
  if(value.target.value.length==0){
    setMailInvalid(false);
  }
  props.setEmailAdd(value.target.value)
}
  const buttontoggle= () =>{
    props.settoggleState('individualowner');
    props.setListState('individualowner');
    setbuttonState(!buttonState);
  } 

  useEffect(() => {
    props.toggleState =='individualowner'?
    setbuttonState(true):setbuttonState(false);

  }, [props.toggleState]); 

  useEffect(() => {
    getData()
  },[props.data]);


  const getData=()=>{
    if(props.data!=null){
       props.setPhoneNumber(props.data.phoneNumber);
       props.setEmailAdd(props.data.emailAdd);
       props.setOwnerFrstNMe(props.data.OwnerFrstNme);
       props.setOwnerMiddle(props.data.ownerMiddle);
       props.setOwnerLastName(props.data.ownerlastname);
       props.setMailAddDifferent(props.data.mailAddDifferent);
      }
  }


  const handleIndividualOwner=()=>{
    if(skipOwner){
      props.setIndividualComplete.individualOwner=true;
      return true;
    }
    if(props.OwnerFrstNme!=="" && props.OwnerFrstNme!==null &&props.OwnerFrstNme!==undefined && props.phoneNumber!=="" && props.phoneNumber!==undefined && props.emailAdd!=="" && props.emailAdd!== undefined&& props.emailAdd!== null
    && props.ownerlastname!=="" && props.ownerlastname!==undefined && props.ownerlastname!==null){
      let allChecked=true;
      let vKeys=Object.keys(validation);
      for(let i=0;i<vKeys.length;i++){
        if(validation[vKeys[i]]=='-1'){
          allChecked=false ;
          break
        }
      }
      if(allChecked){
        props.setIndividualComplete.individualOwner=true;
        return true;
      }
    }
    props.setIndividualComplete.individualOwner=false;
    return false;
  }

  // const redirectACRIS=()=>{
  //   var link=`http://a836-acris.nyc.gov`
  //   window.open(link, '_blank')
  // }
  useEffect(e=>{
    if(props.ownerType!="INDIVIDUAL"){
      setValidation({
        OwnerFrstNme:"0",
        // ownerMiddle:"0",
        ownerlastname:"0",
        phoneNumber:"0",
        email:"0"
    
      })
    }
  },[props.ownerType])
  let valid=(emailtext)=>{
    for(let i=1;i<emailtext.length;i++){
      if((emailtext.charCodeAt(i)>=33 && emailtext.charCodeAt(i)<=45)&& (emailtext.charCodeAt(i)>=58 && emailtext.charCodeAt(i)<=63) ){
        return false;
      }
    }
    return true;
  }

  const obj={
    email:props.emailAdd,
    phoneNumber: props.phoneNumber,
    ownerlastname:props.data.ownerlastname,
    OwnerFrstNme:props.OwnerFrstNme
  }

    return (
        <Col sm={12}>
        <Button
          onClick={buttontoggle}
          className={buttonState ? "btn-100-active":"btn-100"}
          disabled= {props.ownerType!="INDIVIDUAL"?true:false}
        >
           Homeowner's Information
           <div className="float-right">
           <Input type="checkbox"
            className={handleIndividualOwner() ? "rightbutton-checkbox-active" : "rightbutton-checkbox"}
            checked={props.data.ownerType == "INDIVIDUAL" ? handleIndividualOwner() : true}
            onChange={()=>{setSkipOwner(!skipOwner)
              let vKeys = Object.keys(obj);
              let ob={};
              for (let i = 0; i < vKeys.length; i++) {
                // if (validation[vKeys[i]] == "-1" || validation[vKeys[i]] == "0" ) {
                //   highliteEmptys({ arr: validation });
                //   return;
                // }
                if (obj[vKeys[i]] == '' || obj[vKeys[i]] == undefined ) {
                  ob[vKeys[i]] = "-1";
                } else {
                  ob[vKeys[i]] = "1";
                }
                if(vKeys[i]=='phoneNumber'&& NumInvalid){
                  ob={...ob,phoneNumber:'-1'}
                }
                if(vKeys[i]=='email'&& mailInvalid){
                  ob={...ob,email:'-1'}
                }
              }
              setValidation(ob);
            }}
            disabled= {props.data.ownerType!="INDIVIDUAL"?true:false}
          />
          {buttonState
          ? <FontAwesomeIcon className="font-centered" icon={faAngleDown}/>
          :<FontAwesomeIcon className="font-centered" icon={faAngleRight}/>}
          </div>
        </Button>
        <Collapse isOpen={buttonState}>
          <Card className="createdeal-card">
            <CardBody>
              <Form>
            {/* <FormGroup row className="marb-16 ">
              <Row>
                <Col sm={2}>
                  <Button className="continue-button Acrisbtn" onClick={redirectACRIS}>
                     ACRIS
                     </Button>
                </Col>
              </Row>
            </FormGroup> */}
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="ownerfirstname">
                  Owner's First Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input type="text" name="OwnerFrstNme" id="ownerfirstname"  className="createdeal-textfield"
                  style={validation.OwnerFrstNme=='-1'?{...validationCss}:{}}
                  value={props.OwnerFrstNme}
                  onBlur={(e)=>{
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,OwnerFrstNme:"-1"})
                    }else{
                      setValidation({...validation,OwnerFrstNme:"1"})
                    }
                  }}
                  onChange={(e)=>{
                    e.target.value=e.target.value.trimStart();
                    e.target.value=e.target.value.replace(/[^a-zA-Z]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^a-zA-Z]/g,'').slice(1)
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,OwnerFrstNme:"-1"})
                    }else{
                      setValidation({...validation,OwnerFrstNme:"1"})
                    }
                    props.handleOnChangeDeal(e);
                     props.setOwnerFrstNMe(e.target.value)
                    }}/>
                </Col>
                <Col sm={6}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="ownermiddlename">
                  Owner's Middle Initial
                  {/* <span className="red-text">*</span> */}
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input type="text" name="ownerMiddle" id="ownermiddlename"  className="createdeal-textfield"
                  // style={validation.ownerMiddle=='-1'?{...validationCss}:{}}
                  value={props.data?props.data.ownerMiddle:""}
                  onBlur={(e)=>{
                    // if(e.target.value==undefined||e.target.value==''){
                    //   setValidation({...validation,ownerMiddle:"-1"})
                    // }else{
                    //   setValidation({...validation,ownerMiddle:"1"})
                    // }
                  }}
                  onChange={(e)=>{
                    e.target.value=e.target.value.trimStart();
                    e.target.value=e.target.value.replace(/[^a-zA-Z]/g,'').toUpperCase()
                    // if(e.target.value==undefined||e.target.value==''){
                    //   setValidation({...validation,OwnerFrstNme:"-1"})
                    // }else{
                    //   setValidation({...validation,OwnerFrstNme:"1"})
                    // }
                    props.handleOnChangeDeal(e);
                    props.setOwnerMiddle(e.target.value)
                    }}
                    maxLength={1}
                    />
                </Col>
                <Col sm={6}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="ownerlastname">
                  Owner's Last Name<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input type="text" name="ownerlastname" id="ownerlastname"  className="createdeal-textfield"
                  style={validation.ownerlastname=='-1'?{...validationCss}:{}}
                  value={props.data?props.data.ownerlastname:''}
                  onBlur={(e)=>{
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,ownerlastname:"-1"})
                    }else{
                      setValidation({...validation,ownerlastname:"1"})
                    }
                  }}
                  onChange={(e)=>{
                    e.target.value=e.target.value.trimStart();
                    e.target.value=e.target.value.replace(/[^a-zA-Z]/g,'').charAt(0).toUpperCase() + e.target.value.replace(/[^a-zA-Z]/g,'').slice(1)
                    if(e.target.value==undefined||e.target.value==''){
                      setValidation({...validation,ownerlastname:"-1"})
                    }else{
                      setValidation({...validation,ownerlastname:"1"})
                    }
                    props.handleOnChangeDeal(e);
                    props.setOwnerLastName(e.target.value)
                  }}/>
                </Col>
                <Col sm={6}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="phoneno">
                  Phone Number<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                <ReactInputMask
                  type="text"
                  mask="(999) 999-9999"
                  maskChar='_'
                  className="form-control arrow-hide createdeal-textfield" 
                  name="phoneNumber" id="phoneno" 
                  placeholder="(###) ###-####"
                 // maxLength={10}
                  minLength={3}
                  value={props.data?props.phoneNumber:''} 
                  style={validation.phoneNumber=='-1'?{...validationCss}:{}}
                  onKeyDown={(evt) =>
                    ((evt.keyCode >64 && evt.keyCode <91) || evt.key === "."  ) &&
                    evt.preventDefault()
                  }
                  onChange={(e) =>{
                     e.target.value=e.target.value.trimStart();
                     onChangePhoneNumber(e.target.value)
                    //  e.preventDefault()
                    }}
                    onBlur={(e) =>{
                      onBlurPhoneNumber(e.target.value)}}
                  />
                </Col>
                <Col sm={4}><span className={validation.phoneNumber=='-1'?"red-text font12":"error-display"}> Enter 10 digit Phone Number.</span></Col>
                <Col sm={2}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={3}>
                  <Label className="text-bold" for="owneremail">
                    Email Address<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input type="text"
                   placeholder="abc@mail.com"
                   className="createdeal-textfield"
                   style={validation.email=='-1'?{...validationCss}:{}}
                  value={props.data?props.emailAdd:''} 
                  onChange={(evt)=>{
                    evt.target.value=evt.target.value.trim();
                    props.handleOnChangeDeal(evt);
                     onChangeEmail(evt)
                    }}
                    onBlur={(e) =>{
                      if(e.target.value==undefined||e.target.value==''){
                        setValidation({...validation,email:'-1'})
                      }
                      handlemailid(e.target.value)}}
                   name="emailAdd" id="owneremail" />
                </Col>
                <Col sm={4}><span className={validation.email=='-1'?"red-text font12":"error-display"}> Incorrect E-mail Address format.</span></Col>
               
                <Col sm={2}></Col>
              </Row>
            </FormGroup>
            <FormGroup row className="marb-16  padtop-15">
              <Row>
                <Col sm={5}>
                  <Label className="text-bold" for="ownername">
                  Mailing Address different than property address
                  </Label>
                </Col>
                <Col sm={1} className="radioStyle"><Input name='mailAddDifferent' type="checkbox"
                value={props.data?props.data.mailAddDifferent:false}
                onChange={(e)=>{
                  props.setMailAddDifferent(e.target.checked)
                  props.setMailAddressDisable(e.target.checked)
                  props.handleOnChangeDeal(e);
              }}
                checked={props.data?props.data.mailAddDifferent:false}
                /></Col>
                <Col sm={6}></Col>
              </Row>
            </FormGroup>
</Form>
            </CardBody>
          </Card>
        </Collapse>
      </Col>
    );
}

export default IndividualOwner;