import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme } from 'antd';
import React, { useEffect, useState } from 'react'

export const CustomeCollapse = ({items,defaultOpen}) => {
    const { token } = theme.useToken();
    const [tabItems,setTabItems] = useState([]);
    const panelStyle = {
      marginBottom: 24,
      background: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      border: 'none',
    };
    useEffect(e=>{
        if(items && items.length > 0){
            let arr= items.map(d=>{ 
                d["style"]=panelStyle;
                return d;
            })
            setTabItems(arr);
        }
    },[items])
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={defaultOpen}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        style={{
          background: token.colorBgContainer,
        }}
        items={tabItems}
      />
    );
}
