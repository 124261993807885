import React, { useEffect, useState } from 'react';
import { DatePicker as AntdDatePicker, Select, Space } from 'antd';
import dayjs from 'dayjs'
import 'antd/dist/reset.css';
import './RangePicker.css'
// import dayjs from 'dayjs';
import dateTimeObject from "../common/util/date";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import date from './util/date';
import CustumSelect from './SelectWithSearch';
import { DateRangePreDifinedFieldsComponent } from './DateRangePreDifinedFieldsComponent';
const AntdRangePicker = ({ onChangeDateRange, defaultStartDate, defaultEndDate, allStart, allEnd ,madalOpenState, className}) => {
    const { RangePicker } = AntdDatePicker;
    const [panel,setPanel]=useState(["date","date"])
    const [rangeType, setRange] = useState('')
    // const setRangeType = (dates, datesString) => {
    //     if (datesString.length > 0) {
    //         let start = new Date(new Date(datesString[0]))
    //         let end = new Date(new Date(datesString[1]))
    //         let type=date.getRangeType(start,end,allStart,allEnd)
    //         setRange(type);
    //         onChangeDateRange(dates, datesString)
    //     }
    // }

    const defaultSelectCurrentMonth=function(){
        let startDateFormatted='';
        let endDateFormatted='';
        function getCurrentMonthDates() {
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        startDateFormatted = `${startOfMonth.getMonth() + 1}-${startOfMonth.getDate()}-${startOfMonth.getFullYear()}`;
        endDateFormatted = `${endOfMonth.getMonth() + 1}-${endOfMonth.getDate()}-${endOfMonth.getFullYear()}`;
        return [startDateFormatted, endDateFormatted];
        }  
        let dateString=getCurrentMonthDates();
        let dates=[
        dayjs(startDateFormatted),
        dayjs(endDateFormatted)
        ];
        // dates.push(dayjs(startDateFormatted))
        // dates.push(dayjs(endDateFormatted))
        setRangeType(dates,dateString);
    }
    useEffect(()=>{
        defaultSelectCurrentMonth();
    },[])


    const setRangeType = (dates, datesString) => { 
          
        if (datesString.length > 0) {
            let start = new Date(new Date(dates[0].$d))
            let end = new Date(new Date(dates[1].$d))
            let type = date.getRangeType(start, end, allStart, allEnd)
            setRange(type);
            let datesString = [start, end];
            onChangeDateRange(dates, datesString)
        }
    }



    const onClickLeft = () => {
         
        let dates = date.shiftDate(defaultStartDate, defaultEndDate, 'back', rangeType)
        onChangeDateRange(dates, dates)
    }
    const onClickRight = () => {
        let dates = date.shiftDate(defaultStartDate, defaultEndDate, 'next', rangeType)
        onChangeDateRange(dates, dates)
    }
    const QuartersLogic = (value, flag,number) => {
        let res = dateTimeObject.GetDateArr(
            new Date(),
            new Date(),
            value,
            number
        );
        return res[flag];
    }
    const [openRangePicker, setOpenRangePicker] = useState(false);
    const [avoidClosing, setAvoidClosing] = useState();
    const [rangeObject, setRangeObject] = useState({
        "initial": "",
        "middel": "",
        "last": "",
        "status": "phase1",
    })
    const focusBack = (flag) => {
        setAvoidClosing(flag);
    }

    const [periodNumber, setPeriodNumber] = useState(0);
    
    const ranges = {
        "Today": [dayjs(), dayjs()],
        'Yesterday': [dayjs().add(-1, 'days'), dayjs().add(-1, 'days')],
        'Tommorrow': [dayjs().add(1, 'days'), dayjs().add(1, 'days')],
        'This Week': [dayjs().startOf('week'), dayjs().endOf('week')],
        'Last Week': [dayjs().subtract(periodNumber, 'weeks').startOf('week'), dayjs().subtract(1, 'weeks').endOf('week')],
        'Next Week': [dayjs().add(1, 'weeks').startOf('week'), dayjs().add(periodNumber, 'weeks').endOf('week')],

        'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
        'Last Month': [dayjs().subtract(periodNumber, 'months').startOf('month'), dayjs().subtract(1, 'months').endOf('month')],
        'Next Month': [dayjs().add(1, 'months').startOf('month'), dayjs().add(periodNumber, 'months').endOf('month')],
        'This Quarter': [QuartersLogic("This Quarter", 0,periodNumber), QuartersLogic("This Quarter", 1,periodNumber)],
        'Last Quarter': [QuartersLogic("Last Quarter", 0,periodNumber), QuartersLogic("Last Quarter", 1,periodNumber)],
        'Next Quarter': [QuartersLogic("Next Quarter", 0,periodNumber), QuartersLogic("Next Quarter", 1,periodNumber)],
        'This Year': [dayjs().startOf('year'), dayjs().endOf('year')],
        'Last Year': [dayjs().subtract(periodNumber, 'years').startOf('year'), dayjs().subtract(1, 'years').endOf('year')],
        'Next Year': [dayjs().add(1, 'years').startOf('year'), dayjs().add(periodNumber, 'years').endOf('year')],
        'All': [dayjs(allStart), dayjs(allEnd)]
    }
    const rangePresets = [
        { label: 'Today', value: [dayjs(), dayjs()] },
        { label: 'Yesterday', value: [dayjs().add(-1, 'days'), dayjs().add(-1, 'days')] },
        { label: 'Tommorrow', value: [dayjs().add(1, 'days'), dayjs().add(1, 'days')] },
        { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
        { label: 'Last Week', value: [dayjs().subtract(1, 'weeks').startOf('week'), dayjs().subtract(1, 'weeks').endOf('week')] },
        { label: 'Last Two Week', value: [dayjs().subtract(2, 'weeks').startOf('week'), dayjs().subtract(1, 'weeks').endOf('week')] },
       // { label: 'Next Week', value: [dayjs().add(1, 'weeks').startOf('week'), dayjs().add(1, 'weeks').endOf('week')] },
      //  { label: 'Next Two Week', value: [dayjs().add(1, 'weeks').startOf('week'), dayjs().add(2, 'weeks').endOf('week')] },
        { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
        { label: 'Last Month', value: [dayjs().subtract(1, 'months').startOf('month'), dayjs().subtract(1, 'months').endOf('month')] },
        { label: 'Last Two Month', value: [dayjs().subtract(2, 'months').startOf('month'), dayjs().subtract(1, 'months').endOf('month')] },
        //{ label: 'Next Month', value: [dayjs().add(1, 'months').startOf('month'), dayjs().add(2, 'months').endOf('month')] },
        //{ label: 'Next Two Month', value: [dayjs().add(1, 'months').startOf('month'), dayjs().add(2, 'months').endOf('month')] },
        // { label: 'This Quarter', value: [QuartersLogic("This Quarter", 0,periodNumber), QuartersLogic("This Quarter", 1,periodNumber)] },
        // { label: 'Last Quarter', value: [QuartersLogic("Last Quarter", 0,periodNumber), QuartersLogic("Last Quarter", 1,periodNumber)] },
        // { label: 'Last Two Quarter', value: [QuartersLogic("Last Quarter", 0,1), QuartersLogic("Last Quarter", 1,2)] },
        //{ label: 'Next Quarter', value: [QuartersLogic("Next Quarter", 0,periodNumber), QuartersLogic("Next Quarter", 1,periodNumber)] },
        { label: 'This Year', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
        { label: 'Last Year', value: [dayjs().subtract(1, 'years').startOf('year'), dayjs().subtract(1, 'years').endOf('year')] },
        { label: 'Last Two Year', value: [dayjs().subtract(2, 'years').startOf('year'), dayjs().subtract(1, 'years').endOf('year')] },
        { label: 'All', value: [dayjs(allStart), dayjs(allEnd)]},
        //{ label: 'Next Year', value: [dayjs().add(1, 'years').startOf('year'), dayjs().add(periodNumber, 'years').endOf('year')] },
      ]
    useEffect(e => {
          
        let start
        let end
        let arr = [];
        if (rangeObject) {

            if(rangeObject.status=="Clear"){
                start = new Date();
                end = new Date();
                let datesString = [start, end];
                onChangeDateRange("Clear", datesString)
                setAvoidClosing(false);
                return;
            }

            if (rangeObject && rangeObject.initial == "Today") {
                arr = ranges[rangeObject.initial];

            } else if (rangeObject && rangeObject.initial == "Yesterday") {
                setPeriodNumber(-1)
                arr = ranges[rangeObject.initial];

            } else if (rangeObject && rangeObject.initial == "Tommorrow") {
                setPeriodNumber(1)
                arr = ranges[rangeObject.initial];

            } else {
                setPeriodNumber(parseInt(rangeObject.middel))
                arr = ranges[rangeObject.initial + " " + rangeObject.last];

            }
            setAvoidClosing(false);
        }
        if (arr) {
            start = new Date(arr[0])
            end = new Date(arr[1])
            if (start && end) {
                let datesString = [start, end];
                onChangeDateRange(null, datesString)
                setOpenRangePicker(!openRangePicker)
            }
        }
       
    }, [rangeObject])



    const setRangeFromSelectBox = (val) => {
         
        if(val.middel){
            setPeriodNumber(parseInt(val.middel))
        }
        let type=val.initial+" "+(val.middel=="2"?"Two"+" "+val.last:val.last);
        setRange(type);
        setRangeObject(val)
    }

    return <div className='d-flex align-items-center' style={{ height: '24px' }}>
        <Space className="antd-dateragne-txt-right" style={{ height: '24px' ,position:"relative"}} direction="horizontal" >
            <RangePicker
                presets={rangePresets}
                open={openRangePicker}
                onOpenChange={async e => {
                       
                    if (!avoidClosing) {
                        setOpenRangePicker(e)
                    }
                }}
                format={'MM-DD-YYYY'}
                allowClear={false}
                allowEmpty={false}
                value={[dayjs(defaultStartDate), dayjs(defaultEndDate)]}
                className={className}
                onPanelChange={(value,mode)=>{
                    setPanel(mode);
                }}
                renderExtraFooter={() => {
                    return (<DateRangePreDifinedFieldsComponent
                        focusBack={focusBack}
                        panel={panel}
                        madalOpenState={madalOpenState}
                        setRangeFromSelectBox={setRangeFromSelectBox}
                        onChangeDateRange={(dates,datestr)=>{
                            if(dates=="Clear"){
                                defaultSelectCurrentMonth();
                                // setRange("")
                            }
                            onChangeDateRange(dates,datestr)
                        }}
                    />)
                }}
                //disabledTime={onOpenChange}
                onChange={setRangeType}

            />

        <div className='hr-datepicker-btns'>
            <button  onClick={()=>rangeType && onClickLeft()} className='custom-btn-for-range btn btn-sm btn-primary'><FontAwesomeIcon icon={faAngleLeft} /></button>
            <button  onClick={()=>rangeType && onClickRight()} className='custom-btn-for-range btn btn-sm btn-primary'><FontAwesomeIcon icon={faAngleRight} /></button>
        </div>

        </Space>

    </div>


}

export default AntdRangePicker;