import React, { useContext, useEffect, useState } from 'react';
import './CreateBlueprint.css';
import { Col, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faProjectDiagram, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ArrowLeftOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { Flow } from './Flow';
import TextArea from 'antd/es/input/TextArea';
import { CompactPicker } from 'react-color';
import { HexColorRenges } from '../HR/TimeClock/Util';
import { CustomColorPicker } from '../CustomColorPicker/CustomColorPicker';
import AwsServerService from '../../common/util/AwsServerService';
import { Socket } from '../../Context/AppActions';
import AppContext from '../../Context/AppContext';
export const CreateBlueprint = ({ close, save ,editFlow,setEditFlow,view}) => {
    const AppData = useContext(AppContext);
    const { dispatch ,state} = AppData || {};
    const [addNodeState, setAddNodeState] = useState();
    let nodeTemplate = {
        data: { label: 'Step 1' },
        id: "",
        position: { x: 703.7616611847143, y: 167.5149118380199 },
        positionAbsolute: { x: 703.7616611847143, y: 167.5149118380199 },
        description: "",
        type: "custom",
    }
    const [selectedNode, onNodeSelect] = useState();
    const [selectedData, onSelectedData] = useState({
        type: "",
        data: null,
        action: "edit"
    });
    const [bluePrintData, setBluePrintData] = useState({
        name: "",
        description: ""
    });
    const [error,setError]=useState([])
    const validation=(data)=>{
        let temp=[]
        Object.keys(data).forEach(key=>{
          if(!data[key]){
            temp.push(key)
          }
        })
        setError(temp);
        return temp.length==0;
    }
    useEffect(e=>{
        if(editFlow){
            // debugger
            let {Name,Description} = {...editFlow};
            setBluePrintData({
                name:Name,
                description:Description
            })
            onSelectedData({
                type:"edit flow",
                action:"",
                data:{...editFlow}
            })

            // setEditFlow(null);
        }
    },[editFlow])
    return (
        <div className='Create-Blueprint-Wrapper'>
            <div className='Blueprint-Header'>
                <Row className=''>
                    <Col className='d-flex align-items-center' sm={4}>
                        {/* <FontAwesomeIcon className='logo' icon={faProjectDiagram} /> */}
                        <span style={{ fontSize: "1rem", fontWeight: "600", marginLeft: "10px", color: "#5b6c97" }}>{view=="create"?"Create Task flow":view=="edit"?"Edit Task Flow":""}</span>
                    </Col>
                    <Col sm={4}></Col>
                    <Col sm={3}></Col>
                    <Col sm={1}
                        style={{
                            "display": "flex",
                            "align-items": "center",
                            "justify-content": "end"
                        }}
                    ><Button
                        icon={<FontAwesomeIcon className='opacity-75' icon={faTimes} />}
                        onClick={close}
                    ></Button></Col>
                </Row>
            </div>
            <Row className='Create-Blueprint-Body m-0'>
                <Col style={{ "position": "relative" }} sm={8}>
                    <Button
                        type='primary'
                        className='Add-Node-Button'
                        icon={<FontAwesomeIcon className='opacity-75' icon={faPlus} />}
                        onClick={() => {
                            setAddNodeState("add");
                        }}
                    >Add Node</Button>
                    <Flow
                        view={view}
                        editFlow={editFlow}
                        addNodeButton={addNodeState}
                        resetAddNodeButton={setAddNodeState}
                        selectedData={selectedData}
                        onSelectedData={onSelectedData}
                        onNodeSelect={(node) => {
                            onSelectedData({
                                type: "node",
                                data: node,
                                action: "edit"
                            })
                        }}
                        onEdgeSelected={(edge) => {
                            onSelectedData({
                                type: "edge",
                                data: edge,
                                action: "edit"
                            })
                        }}
                        saveCallback={(res)=>{
                            // debugger
                            dispatch(Socket({trigger:"Refresh_Module",data:"Task Flow"}))
                            setBluePrintData({
                                name: "",
                                description: ""
                            });
                            close();
                        }}
                    />
                </Col>
                <Col className='right-section' sm={4}>
                    <Row style={{
                        // "padding": "10px 0px",
                        "height": "3rem",
                        "border": "1px solid #e8e8e8",
                        "margin": "0px",
                        "box-shadow": "0px 0px 5px -1px grey"
                    }} className='mt-2 mb-2 d-flex align-items-center' sm={12}>
                        <Col sm={10}>
                            <span style={{
                                "font-size": "1rem",
                                "font-weight": "600",
                                "margin-left": "10px",
                                "color": "rgb(91, 108, 151)"
                            }} className='fs-3'>{!selectedData?.type ? "Task Flow" : selectedData?.type == "node" ? "Edit Node" : selectedData?.type == "edge" ? "Edit Edge" : <></>}</span>
                        </Col>
                        <Col className='d-flex justify-content-end' sm={2}>
                            {
                               !selectedData?.type && <>
                                <Button
                                    className='mr-1'
                                    type='primary'
                                    // icon={<FontAwesomeIcon className='opacity-75' color='red' icon={faTrashAlt} />}
                                    onClick={() => {
                                        if(validation({...bluePrintData})){
                                            onSelectedData({
                                                type: "flow",
                                                data: {...bluePrintData},
                                                action: view
                                            })
                                        }
                                    }}
                                >{view=="edit"?"Update":"Save"}</Button>
                                {/* <Button
                                    className='mr-1'
                                    // type=''
                                    // icon={<FontAwesomeIcon className='opacity-75' color='red' icon={faRel} />}
                                    onClick={() => {
                                        
                                    }}
                                >Clear</Button> */}
                               </>
                            }
                            {selectedData?.type && <>
                                <Button
                                    className='mr-1'
                                    icon={<FontAwesomeIcon className='opacity-75' color='red' icon={faTrashAlt} />}
                                    onClick={() => {
                                        let temp = { ...selectedData }
                                        temp["action"] = "delete"
                                        onSelectedData(temp);

                                    }}
                                ></Button>
                                <Button
                                    icon={<FontAwesomeIcon className='opacity-75' icon={faArrowLeft} />}
                                    onClick={() => {
                                        onSelectedData({
                                            type: "",
                                            data: "",
                                            action: ""
                                        })
                                    }}
                                ></Button></>
                            }
                        </Col>
                    </Row>
                    {selectedData?.type == "node" ? <>
                        <Row className='Input-Group'>
                            <Col className='d-flex p-0 m-0 align-items-center' sm={1}>
                                <CustomColorPicker
                                    value={selectedData?.data?.data?.color}
                                    onChange={(color)=>{
                                        let obj = { ...selectedData };
                                        obj["data"]["data"]["color"]= color;
                                        onSelectedData(obj)
                                    }}
                                />
                            </Col>
                            <Col sm={11}>
                                <span className='labeltext'><Label>Name</Label></span>
                                <Input className='input' value={selectedData?.data?.data?.label} onChange={(e) => {
                                    let obj = { ...selectedData };
                                    obj["data"]["data"]["label"] = e.currentTarget.value;
                                    onSelectedData(obj)
                                }} />
                            </Col>
                        </Row>
                        <Row className='Input-Group'>
                            <Col>
                                <span className='labeltext'><Label>Description</Label></span>
                                <TextArea value={selectedData?.data?.description} style={{
                                    "padding": "5px 13px",
                                    "min-height": "5rem",
                                    "max-height": "5rem"
                                }} onChange={(e) => {
                                    let obj = { ...selectedData };
                                    obj["data"]["description"] = e.currentTarget.value;
                                    onSelectedData(obj)
                                }} />
                            </Col>
                        </Row>
                    </> : selectedData?.type == "edge" ? <>
                        <Row className='Input-Group'>
                            <Col>
                                <span className='labeltext'><Label>Label</Label></span>
                                <Input className='input' value={selectedData?.data?.label} onChange={(e) => {
                                    let obj = { ...selectedData };
                                    obj["data"]["label"] = e.currentTarget.value;
                                    onSelectedData(obj)
                                }} />
                            </Col>
                        </Row>
                    </> : <>
                        <Row className='Input-Group'>
                            <Col>
                                <span className='labeltext'><Label>Name</Label></span>
                                <Input className='input' value={bluePrintData?.name} onChange={(e) => {
                                    if(e.currentTarget.value){
                                        let err=error?.filter(e=>e!="name")
                                        setError(err);
                                    }
                                    let obj = { ...bluePrintData };
                                    obj["name"] = e.currentTarget.value;
                                    setBluePrintData(obj)
                                }} />
                                {error.includes("name") && <span style={{color:"red"}}>Please fill the required field</span>}
                            </Col>
                        </Row>
                        <Row className='Input-Group'>
                            <Col>
                                <span className='labeltext'><Label>Description</Label></span>
                                <TextArea value={bluePrintData?.description} style={{
                                    "padding": "5px 13px",
                                    "min-height": "5rem",
                                    "max-height": "5rem"
                                }} onChange={(e) => {
                                    if(e.currentTarget.value){
                                        let err=error?.filter(e=>e!="description")
                                        setError(err);
                                    }
                                    let obj = { ...bluePrintData };
                                    obj["description"] = e.currentTarget.value;
                                    setBluePrintData(obj)
                                }} />
                                {error.includes("description") && <span style={{color:"red"}}>Please fill the required field</span>}
                            </Col>
                        </Row>
                    </>}
                </Col>
            </Row>
        </div>
    )
}
