import { faCheckCircle, faFile, faFileAlt, faFolder, faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faHome, faSearchDollar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { Card, CardBody, CardFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Button } from 'reactstrap';
import 'antd/dist/reset.css';
import { Popover as AntdPopover } from "antd";
import './workdrive.css'
import swal from "sweetalert"
import Loading from '../../common/loader';
import icon from '../../assets/images/zoho-Workdrive-tr.png';
import Config from '../../config.json'
import ActivitieService from '../../common/util/ActivitieService';

//

const Workdrive = (props) => {
    const backendUrl = Config.backend_url;
    // axios.defaults.proxy.host = "https://www.zohoapis.com"
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loaderState, setLoaderState] = useState([]);

    const [uploadFiles, setUploadFiles] = useState([]);
    const [parent_id, setParentId] = useState("inifpd9a80f1f19df4d5e95e45375c41509f1");
    const [openFolderDir, setOpenFolderDir] = useState([]);
    const [createFolder, setCreateFolder] = useState(false);
    const [folderName, setFolderName] = useState(null);
    const [error, setError] = useState(null);
    const [folderId, setFolderId] = useState("inifpd9a80f1f19df4d5e95e45375c41509f1");
    const [hoverId, setHoverId] = useState("");
    const [token, setToken] = useState(localStorage.getItem('token'))// useState("Bearer 1000.61582af6a582dd94c8c493d846639e6f.17c793a7a5f75b8b0f972644d6399a1e");
    const [tokenTime, setTokenTime] = useState(new Date());

    const [copyState, setCopyState] = useState(false);
    const [moveState, setMoveState] = useState(false);
    const [moveFiles, setMoveFiles] = useState([]);
    const [fromMoveFolderId, setFromMoveFolderId] = useState("");
    const [searchState, setSearchState] = useState(false);
    const [searchFiles, setSearchFiles] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();




    let timer = null;

    useEffect(() => {
        if (props.data && props.data.zohoworkdriveforcrm__Workdrive_Folder_ID) {
            setFolderId(props.data.zohoworkdriveforcrm__Workdrive_Folder_ID);
            setParentId(props.data.zohoworkdriveforcrm__Workdrive_Folder_ID);
        }
    }, [props.data])
    // useEffect(() => {
    //     getToken()
    // }, [])
    useEffect(() => {

        if (acceptedFiles.length > 0) {
            handleFileAttachments(acceptedFiles);
        }
    }, [acceptedFiles])

    useEffect(() => {
        if (uploadFiles.length > 0) {
            handleAllFileUpload(uploadFiles);
        }
    }, [uploadFiles])





    // useEffect(() => {
    //     window.ZOHO.embeddedApp.on('PageLoad', function (data1) {

    //         window.ZOHO.CRM.API.getRecord({ Entity: data1.Entity, RecordID: data1.EntityId })
    //             .then((result) => {

    //                 let deal = result.data[0];
    //                 setFolderId(deal.zohoworkdriveforcrm__Workdrive_Folder_ID);
    //                 setParentId(deal.zohoworkdriveforcrm__Workdrive_Folder_ID);

    //                 // return  window.ZOHO.CRM.API.getRecord({ Entity: "Accounts", RecordID:  deal.Account_Name.id })
    //             })
    //             // .then((result)=>{
    //             //     console.log(result)
    //             //     let account = result.data[0];
    //             //     console.log(account)
    //             //     console.log(account.zohoworkdriveforcrm__Workdrive_Folder_ID);
    //             //     setFolderId(account.zohoworkdriveforcrm__Workdrive_Folder_ID);
    //             //      setParentId(account.zohoworkdriveforcrm__Workdrive_Folder_ID);
    //             // })
    //             .catch((Err) => {
    //                 console.log(Err);
    //             })
    //     })
    //     window.ZOHO.embeddedApp.init();
    // }, [])

    useEffect(() => {
        if (folderId && token) {
            getHomeData(folderId)
            // if (openFolderDir.length > 0 || props.getRelativePath) {
            //     let path = '/';
            //     openFolderDir.forEach((one) => {
            //         path = path + one.name
            //     }
            //     )

            //     props.getRelativePath(path+'/')
            // } else {
            //     if(props.getRelativePath){
            //         props.getRelativePath("/")
            //     }
            // }
        }
    }, [folderId, token])

    useEffect(() => {
        if (openFolderDir.length > 0 && props.getRelativePath) {
            let path = '/';
            openFolderDir.forEach((one) => {
                path = path + one.name + '/'
            }
            )

            props.getRelativePath(path, openFolderDir[openFolderDir.length - 1].name)
        } else {
            if (props.getRelativePath) {
                props.getRelativePath("/", 'none')
            }
        }
    }, [openFolderDir])


    const getHomeData = (id) => {
        var config = {
            Type: "Get",

            url: "https://www.zohoapis.com/workdrive/api/v1/files/" + id + "/files",
            method: "GET",
            headers: {
                'Authorization': token
            }
        };
        setLoaderState(true)
        axios.post(backendUrl + "workdrive", { config: config })
            .then(function (response) {
                setLoaderState(false);
                setSelectedFiles(response.data.data)
            })
            .catch(function (error) {
                setLoaderState(false);
                console.log(error);
            });
    }

    const handleFileAttachments = async (fileList) => {
        let tempFileCollection = [...uploadFiles];
        let v = fileList.length;
        let index = 0;
        while (index < v) {
            let fileName = fileList[index].name;
            let type = fileList[index].type;
            let base64 = await convertToBase64(fileList[index]);
            tempFileCollection.push({
                fileName: fileName,
                file: base64,
                type: type
            });
            index = index + 1;
        }
        setUploadFiles(tempFileCollection);

    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const createFolderZoho = () => {
        var config = {
            Type: "Create",
            data: {
                attributes: {
                    name: folderName,
                    parent_id: folderId
                },
                type: "files"
            },
            url: "https://www.zohoapis.com/workdrive/api/v1/files",
            method: "POST",
            headers: {
                'Authorization': token
            }
        };
        setLoaderState(true);
        axios.post(backendUrl + "workdrive", { config: config })
            .then(function (response) {
                if (openFolderDir.length > 0) {
                    getFolderAndFilesOfId(openFolderDir[openFolderDir.length - 1].id);
                } else {
                    getFolderAndFilesOfId(folderId);
                }

                setCreateFolder(false)
                setLoaderState(false);
                setError(null);
                setFolderName(null)
            })
            .catch(function (error) {
                setLoaderState(false);
                console.log(error);
            });
    }


    const handleCreateFolder = () => {
        if (folderName) {
            if (selectedFiles && selectedFiles.filter(one => one.attributes.name.toLowerCase() == folderName.trim().toLowerCase()).length > 0) {
                swal({
                    title: "Folder name already exist?",
                    text: "Do you want to create?",
                    icon: "warning",
                    buttons: ["No", "Yes"],
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            createFolderZoho();
                        } else {
                            // No
                        }
                    });
            } else {
                createFolderZoho();
            }


        } else {
            setError({ error: "please enter a valid folder name" })
            // swal("please enter folder name", { dangerMode: true });
        }

    }

    const getTokenEndTime = (start) => {
        let end = new Date(start);
        end.setTime(end.getTime() + (3600 * 1000))
        return new Date(end);
    }

    const getToken = () => {
        let obj = {
            "access_token": "1000.b363472952cbfa446395094862c69e44.ef963f387bd2837504ddbb8d65080690",
            "refresh_token": "1000.76b425e85e8d6f89a1840bd939283b8b.3e2b2418b377941c1f83e73a6856ec5f",
            "scope": "ZohoFiles.files.READ TeamDrive.files.READ TeamDrive.files.CREATE TeamDrive.files.UPDATE TeamDrive.files.DELETE ZohoCRM.users.READ ZohoCRM.users.CREATE ZohoCRM.users.UPDATE ZohoCRM.users.DELETE ZohoSearch.securesearch.READ TeamDrive.team.READ ZohoCRM.modules.READ ZohoCRM.modules.CREATE ZohoCRM.modules.UPDATE ZohoCRM.modules.DELETE",
            "api_domain": "https://www.zohoapis.com",
            "token_type": "Bearer",
            "expires_in": 3600
        }
        axios.get(backendUrl + "token/" + obj.refresh_token)
            .then((result) => {
                setToken("Bearer " + result.data.access_token);
                let start = new Date(result.data.tokenTime);
                setTokenTime(start)
                clearInterval(timer);
                timer = setInterval(() => {
                    let time = new Date(tokenTime);
                    var minutes = ((getTokenEndTime(start).getTime() - tokenTime.getTime()) / 1000) / 60;
                    if (minutes <= 15) {
                        getToken()
                    }
                    time.setMinutes(time.getMinutes() + 1);
                    setTokenTime(time);
                }, (60 * 1000))
            })
            .catch((err) => {
                getToken()
                //alert(JSON.stringify(err));
            })
    }

    const getFolderHerriarchi = (id) => {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Get",

                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + id + "/files",
                method: "GET",
                headers: {
                    'Authorization': token
                }
            };
            setLoaderState(true);
            axios.post(backendUrl + "workdrive", { config: config })
                .then((result) => {
                    setLoaderState(false);
                    result.data.data = result.data.data.filter(one => one.attributes.type == 'folder');
                    resolve(result.data.data);
                })
                .catch((err) => {
                    setLoaderState(false);
                    reject(err)
                })
        })
    }

    let arr = []
    const getFolderDirForSearch = async (id, selectedId) => {
        let res = await getFolderHerriarchi(id);
        for (let i = 0; i < res.length; i++) {
            let filter = res.filter(one => one.id == selectedId);
            if (filter.length > 0) {
                arr.push({ id: filter[0].id, name: filter[0].attributes.name });
                break;
            } else {
                arr.push({ id: res[i].id, name: res[i].attributes.name });
                await getFolderDirForSearch(res[i].id, selectedId);
            }
            res = await getFolderHerriarchi(res[i].id)
        }
        const arrayUniqueByKey = [...new Map(arr.map(item =>
            [item["id"], item])).values()];
        setSearchState(false);
        setSearchText(null);
        setFolderId(selectedId);
        setOpenFolderDir(arrayUniqueByKey);
    }


    const getFolderAndFilesOfId = (id, name, operation) => {
        var config = {
            Type: "Get",

            url: "https://www.zohoapis.com/workdrive/api/v1/files/" + id + "/files",
            method: "GET",
            headers: {
                'Authorization': token
            }
        };
        setLoaderState(true);
        axios.post(backendUrl + "workdrive", { config: config })
            .then(async function (response) {
                let dir = [...openFolderDir]
                if (operation == "next") {
                    dir.push({ name: name, id: id })
                    setOpenFolderDir(dir);
                } else if (operation == "back") {
                    let arr = []
                    for (let i = 0; i < dir.length; i++) {
                        arr.push(dir[i]);
                        if (id == dir[i].id) {
                            break;
                        }
                    }
                    setOpenFolderDir(arr);
                    dir.pop({ name: name, id: id });
                }
                else if (operation == "search") {
                    setOpenFolderDir([]);
                }
                setLoaderState(false);
                setSelectedFiles(response.data.data)
            })
            .catch(function (error) {
                setLoaderState(false);
                console.log(error);
            });
    }

    const handleCheckItem = (e, index) => {
        const files = [...selectedFiles];
        let obj = files[index];
        obj.checked = e.currentTarget.checked;
        files[index] = obj;
        setSelectedFiles(files)
    }

    const handleRenameAll = () => {
        let files = selectedFiles.filter(one => one.checked && one.rename && one.renameValue);
        let withoutRenameFiles = selectedFiles.filter(one => !one.checked);
        let doArr = []
        let fileNameExist = files.filter(one => {
            if (withoutRenameFiles.filter(f => f.attributes.name.toLowerCase() == one.renameValue.trim().toLowerCase()).length > 0) {
                return one;
            }
        });
        if (fileNameExist.length > 0) {
            let text = "";
            fileNameExist.forEach(file => {
                text = text + file.renameValue + ",";
            })
            text = text + " folder or file name already exist! Please use different name."
            swal(text, { dangerMode: true });
        } else {
            files.forEach((one) => {
                doArr.push(handleRenameFolder(one))
            })
            Promise.all(doArr)
                .then((result) => {
                    if (openFolderDir.length > 0) {
                        let obj = openFolderDir[openFolderDir.length - 1]
                        getFolderAndFilesOfId(obj.id, obj.name, "same");
                    } else {
                        getHomeData(parent_id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }


    }

    const handleRenameFolder = (data) => {
        return new Promise((resolve, reject) => {

            var config = {
                Type: "Rename",
                data: {
                    attributes: {
                        name: data.renameValue,
                    },
                    type: "files"
                },
                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + data.id,
                method: "PATCH",
                headers: {
                    'Authorization': token
                }
            };
            setLoaderState(true);
            axios.post(backendUrl + "workdrive", { config: config })
                .then(function (response) {
                    setLoaderState(false);
                    resolve(response)
                })
                .catch(function (error) {
                    setLoaderState(false);
                    reject(error);
                });
        })

    }


    const handleDeleteAll = () => {
        let files = selectedFiles.filter(one => one.checked);
        let doArr = []
        files.forEach((one) => {
            doArr.push(handleDeleteFolder(one))
        })
        setLoaderState(true);
        Promise.all(doArr)
            .then((result) => {
                if (openFolderDir.length > 0) {
                    let obj = openFolderDir[openFolderDir.length - 1]
                    getHomeData(obj.id);
                    // setOpenFolderDir([])
                } else {
                    getHomeData(parent_id);
                }
                setLoaderState(false);

                // setOpenFolderDir([]);
            })
            .catch((err) => {
                setLoaderState(false);
                console.log(err);
            })
    }

    const handleDeleteFolderPermanent = (data) => {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Delete",
                data: {
                    attributes: {
                        status: "61",
                    },
                    type: "files"
                },
                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + data.id,
                method: "PATCH",
                headers: {
                    'Authorization': token
                }
            };
            setLoaderState(true);
            axios.post(backendUrl + "workdrive", { config: config })
                .then(async function (response) {
                    setLoaderState(false);
                    resolve(response)
                })
                .catch(function (error) {
                    setLoaderState(false);
                    reject(error);
                });
        })

    }


    const handleDeleteFolder = (data) => {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Delete",
                data: {
                    attributes: {
                        status: "51",
                    },
                    type: "files"
                },
                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + data.id,
                method: "PATCH",
                headers: {
                    'Authorization': token
                }
            };
            setLoaderState(true);
            axios.post(backendUrl + "workdrive", { config: config })
                .then(async function (response) {
                    await handleDeleteFolderPermanent(data);
                    setLoaderState(false);
                    resolve(response)
                })
                .catch(function (error) {
                    setLoaderState(false);
                    reject(error);
                });
        })

    }

    const handleAllFileUpload = (uploadFile) => {
        let files = [...uploadFile];
        if (files.length > 0) {
            let doArr = []
            files.forEach((one) => {
                doArr.push(handleUploadFile(one))
            })
            setLoaderState(true);
            Promise.all(doArr)
                .then((result) => {
                    if (openFolderDir.length > 0) {
                        let obj = openFolderDir[openFolderDir.length - 1]
                        getHomeData(obj.id);
                        // setOpenFolderDir([])
                    } else {
                        getHomeData(parent_id);
                    }
                    setLoaderState(false);
                    // setOpenFolderDir([]);
                })
                .catch((err) => {
                    setLoaderState(false);
                    console.log(err);
                })
        }

    }


    const handleUploadFile = (fileObj) => {
        return new Promise((resolve, reject) => {


            var config = {
                Type: "Upload",
                data: {
                    filename: fileObj.fileName,
                    content: fileObj.file,
                    parent_id: folderId,
                },
                url: "https://www.zohoapis.com/workdrive/api/v1/upload",
                method: "POST",
                headers: {
                    'Authorization': token
                }
            };
            setLoaderState(true);
            let res = {}
            axios.post(backendUrl + "workdrive", { config: config })
                .then(function (response) {
                    res = response
                    return ActivitieService.AddActivityLocal('Workdrive', { DealId: props.data.Project_ID }, { action_type: 'Upload', data_type: 'File', data: { name: fileObj.fileName } })
                })
                .then((result) => {
                    setLoaderState(false);
                    resolve(res)
                })
                .catch(function (error) {
                    setLoaderState(false);
                    reject(error);
                });
        })

    }

    const handleCopyAll = () => {
        let files = [...moveFiles];
        let doArr = []
        files.forEach((one) => {
            doArr.push(handleCopyFile(one))
        })
        setLoaderState(true);
        Promise.all(doArr)
            .then((result) => {
                if (openFolderDir.length > 0) {
                    let obj = openFolderDir[openFolderDir.length - 1]
                    getHomeData(obj.id);
                    // setOpenFolderDir([])
                } else {
                    getHomeData(parent_id);
                }
                setLoaderState(false);
                setMoveFiles([]);
                setCopyState(false);
                // setOpenFolderDir([]);
            })
            .catch((err) => {
                setLoaderState(false);
                console.log(err);
            })
    }

    const handleCopyFile = (data) => {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Copy",
                data: {
                    attributes: {
                        resource_id: data.id
                    },
                    type: "files"
                },
                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + folderId + "/copy",
                method: "POST",
                headers: {
                    'Authorization': token
                }
            };
            setLoaderState(true);
            axios.post(backendUrl + "workdrive", { config: config })
                .then(function (response) {
                    setLoaderState(false);
                    resolve(response)
                    if (response.data.errors && response.data.errors.length > 0) {
                        swal(response.data.errors[0].title, { dangerMode: true })
                    }

                })
                .catch(function (error) {
                    setLoaderState(false);
                    reject(error);
                });
        })
    }


    const handleSearchFile = (e) => {
        if (e.currentTarget.value == "") {
            getFolderAndFilesOfId(parent_id);
            setSearchText(null);
            // if (openFolderDir.length > 0) {
            //     let obj = openFolderDir[openFolderDir.length - 1]
            //     getFolderAndFilesOfId(obj.id, obj.name, "search");
            //     // setOpenFolderDir([])
            // } else {
            //     getFolderAndFilesOfId(parent_id);
            // }
        } else {
            let teamId = "lvelh71c5a951437d4db18a23bc8628fec5be"
            var config = {
                Type: "Search",

                url: "https://www.zohoapis.com/workdrive/api/v1/teams/" + teamId + "/records?search%5Ball%5D=" + e.currentTarget.value + "&filter%5BparentId%5D=" + parent_id + "&page%5Blimit%5D=50&page%5Boffset%5D=0",
                method: "GET",
                headers: {
                    'Authorization': token
                }
            };
            // setLoaderState(true);
            setSearchText(e.currentTarget.value);
            axios.post(backendUrl + "workdrive", { config: config })
                .then(function (response) {
                    // resolve(response)
                    setLoaderState(false);
                    setSelectedFiles(response.data.data);
                    if ((response.data.data.length > 0)) {
                        setSearchState(true);

                        setSearchFiles(response.data.data);
                        setOpenFolderDir([])
                        setFolderId(null)
                    }

                })
                .catch(function (error) {
                    setLoaderState(false);
                    // reject(error);
                });
        }

    }

    const handleDownloadAllFile = () => {
        let files = selectedFiles.filter(one => one.checked);
        if (files.length > 0) {
            let doArr = []
            if (files.length == 1) {
                handleDownloadFileAndFolder(files[0]);
            } else {
                let ids = []
                files.forEach((one) => {
                    ids.push(one.id);
                })

                handleDownloadFileAndFolderOneZip(ids);

            }

        }
    }

    const handleDownloadFileAndFolderOneZip = (ids) => {
        var config = {}
        ids = ids.join(",")
        config = {
            Type: "Download",
            FileType: "Folder",
            data: {
                attributes: {
                    resource_id: ids
                },
                type: "files"
            },
            url: "https://www.zohoapis.com/workdrive/api/v1/multizip",
            method: "POST",
            headers: {
                'Authorization': token
            }
        };


        setLoaderState(true);
        axios.post(backendUrl + "workdrive", { config: config })
            .then(async function (response) {
                response = await axios.post(backendUrl + "workdrive", { config: config })
                let link = document.createElement('a');
                link.setAttribute('href', response.data.download_link);
                link.setAttribute('download', "test");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoaderState(false);

            })
            .catch(function (error) {
                setLoaderState(false);
                // reject(error);
            });

    }



    const handleDownloadFileAndFolder = (one) => {
        var config = {}
        if (one.attributes.type == 'folder') {
            config = {
                Type: "Download",
                FileType: "Folder",
                data: {
                    attributes: {
                        resource_id: one.id
                    },
                    type: "files"
                },
                url: "https://www.zohoapis.com/workdrive/api/v1/multizip",
                method: "POST",
                headers: {
                    'Authorization': token
                }
            };
        } else {
            config = {
                Type: "Download",
                FileType: "File",
                url: "https://download.zoho.com/v1/workdrive/download/" + one.id,
                method: "GET",
                data: {},
                headers: {
                    'Authorization': token
                }
            };
        }


        setLoaderState(true);
        axios.post(backendUrl + "workdrive", { config: config })
            .then(async function (response) {
                // resolve(response)
                // let url = window.URL.createObjectURL(response.data.download_link)
                if (one.attributes.type == 'folder') {
                    response = await axios.post(backendUrl + "workdrive", { config: config })
                    let link = document.createElement('a');
                    link.setAttribute('href', response.data.download_link);
                    link.setAttribute('download', "test");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    let url = "https://download.zoho.com/v1/workdrive/download/" + one.id;
                    let link = document.createElement('a');
                    link.setAttribute('href', url);
                    link.setAttribute('download', one.attributes.name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }

                // setSelectedFiles(response.data.data)
                setLoaderState(false);

            })
            .catch(function (error) {
                setLoaderState(false);
                // reject(error);
            });

    }

    const handleMoveAll = () => {
        let files = [...moveFiles];
        let doArr = []
        files.forEach((one) => {
            doArr.push(handleMoveFileOrFolder(one))
        })
        Promise.all(doArr)
            .then((result) => {
                if (openFolderDir.length > 0) {
                    let obj = openFolderDir[openFolderDir.length - 1]
                    getHomeData(obj.id);

                    // setOpenFolderDir([])
                } else {
                    getHomeData(parent_id);
                }
                setMoveState(false);
                setMoveFiles([])
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleMoveFileOrFolder = (data) => {
        return new Promise((resolve, reject) => {
            var config = {
                Type: "Move",
                data: {
                    attributes: {
                        parent_id: folderId
                    },
                    type: "files"

                },
                url: "https://www.zohoapis.com/workdrive/api/v1/files/" + data.id,
                method: "PATCH",
                headers: {
                    'Authorization': token
                }
            };
            axios.post(backendUrl + "workdrive", { config: config })
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error);
                });
        })
    }


    const getDisableState = () => {
        if (moveState) {
            return fromMoveFolderId == folderId || (moveFiles.filter(one => one.id == folderId).length > 0)
        } else if (copyState) {
            return (moveFiles.filter(one => one.id == folderId).length > 0)
        } else {
            return false;
        }
    }



    return (
        <div className={isDragActive ? "drag-overlay" : ""} >

            {isDragActive ?

                <div className='drop-file'><FontAwesomeIcon size='2x' icon={faFile} />&nbsp;&nbsp;&nbsp;darg files here</div> :

                <></>}

            <div style={isDragActive ? { opacity: .3 } : {  }} >
                <div  {...getRootProps({ className: "dropzone", onClick: event => event.stopPropagation() })} className='container1 mt-2 px-3 pt-2'>
                    {loaderState ? <Loading /> : <></>}
                    <div className='workdrive-heading-box'>


                        <div className='creat-box2 '>
                            <button onClick={() => setCreateFolder(true)} className='btn mr-3 btn-sm btn-primary'>Create Folder</button>

                            <div className='upload-box2'>
                                <div  {...getRootProps({ className: "dropzone position-static d-block p-0 h-0" })}>
                                    <input {...getInputProps()} />
                                    <p className="btn btn-sm btn-primary m-0 ">
                                        Upload File
                                    </p>

                                </div>
                                {/* <button className='btn btn-sm btn-primary'></button> */}
                            </div>


                        </div>


                        <div className='search-box2'>
                            {createFolder ? <><div className='creat-txt-box2'>
                                <div class="input-group input-group-sm">
                                    <input onChange={(e) => { if (e.currentTarget.value == "") { setFolderName(null); setError({ error: "please enter a valid folder name" }) } else { setFolderName(e.currentTarget.value); setError(null) } }} type="text" class="form-control form-control-sm" placeholder="enter name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <div class="input-group-append ">
                                        <button style={{ height: "31px" }} onClick={handleCreateFolder} class="btn-creat btn-outline-secondary" type="button"><FontAwesomeIcon color='blue' icon={faCheck} /></button>
                                        <button style={{ height: "31px" }} onClick={() => { setError(null); setCreateFolder(false); setFolderName(null) }} class="btn-creat-close btn-outline-secondary" type="button"><FontAwesomeIcon color='blue' icon={faTimes} /></button>
                                    </div>
                                </div>
                                {error ? <p className='small text-danger'>{error.error}</p> : <></>}
                            </div></> : <></>}

                            {/* <div class="form-group has-search">
                                <input type="text" value={searchText ? searchText : ""} onChange={handleSearchFile} class="form-control form-control-sm" placeholder="Search file and folder" />
                            </div> */}
                            <div class="form-group position-relative has-search">
                                <input
                                    type="text"
                                    placeholder="Search file and folder"
                                    // className="pad-fix search-filter-deals"
                                    className="form-control form-control-sm workdrive-searchbar"
                                    value={searchText ? searchText : ""}
                                    onChange={handleSearchFile}
                                />
                                <FontAwesomeIcon className="search-ico workdrive-searh-icon" icon={faSearchDollar} />
                            </div>
                            {searchText ? <button onClick={() => {
                                setSearchText(null);
                                setSearchState(false);
                                getHomeData(parent_id);

                            }} class="btn-reset btn btn-sm btn-outline-secondary" type="button">Clear</button> : <></>}
                        </div>
                        <div className='icons-box2'>
                            <img style={{ width: "50px", height: "51px", cursor: 'pointer', margin: '-57px 2px 2px 10px' }} onClick={() => window.open("https://workdrive.zoho.com/folder/" + folderId)} src={icon} />
                            {/* {/ <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={() => window.open("https://workdrive.zoho.com/folder/" + folderId)} color='#0d6efd' size='2x' icon={faFolderOpen} /> /} */}
                        </div>
                        <div className='clear'></div>
                    </div>
                    <div className='row  text-left '>
                        <div class="col">
                            <FontAwesomeIcon onClick={() => { getHomeData(parent_id); setOpenFolderDir([]); setFolderId(parent_id) }} icon={faHome} />
                            {openFolderDir.map(one => {
                                return <>&nbsp;{">"}&nbsp;&nbsp;<span style={{ cursor: 'pointer', color: "blue" }} onClick={() => getFolderAndFilesOfId(one.id, one.name, "back")}>{one.name}</span></>
                            })}
                        </div>

                    </div>
                </div>
                <div className={selectedFiles && selectedFiles.filter(one => one && one.checked).length > 0 ? 'container1  p-3' : 'd-none'}>
                    <div className='row '>
                        <div className=' col-sm-1'>
                            {selectedFiles && selectedFiles.filter(one => one && one.checked).length > 0 ?
                                <div class="btn-group">
                                    <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Manage
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" onClick={() => {
                                            const files = [...selectedFiles];
                                            files.forEach(one => {
                                                if (one.checked) {
                                                    one.rename = true;
                                                }
                                            })
                                            setSelectedFiles(files);
                                            setMoveState(false);
                                            setCopyState(false);

                                        }} style={{ cursor: 'pointer' }}>Rename</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item"
                                            onClick={() => handleDeleteAll()}

                                            style={{ cursor: 'pointer' }}>Delete</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item"
                                            onClick={() => {
                                                let files = [...selectedFiles];
                                                files = files.filter(one => one.checked);
                                                setCopyState(true);
                                                setMoveState(false);
                                                setMoveFiles(files);
                                            }}

                                            style={{ cursor: 'pointer' }}>Copy</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item"
                                            onClick={() => handleDownloadAllFile()}

                                            style={{ cursor: 'pointer' }}>Download</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item"
                                            onClick={() => {
                                                let files = [...selectedFiles];
                                                files = files.filter(one => one.checked);
                                                setMoveState(true);
                                                setCopyState(false);
                                                setMoveFiles(files);
                                                setFromMoveFolderId(folderId);
                                            }}


                                            style={{ cursor: 'pointer' }}>Move</a>
                                    </div>
                                </div>



                                : <></>}
                        </div>
                        <div className=' col-sm-2 '>
                            {selectedFiles && selectedFiles.filter(one => one && one.checked && one.rename).length > 0 ? <button onClick={handleRenameAll} class="btn btn-primary btn-sm " type="button" aria-expanded="false">
                                Apply
                            </button> : <></>}
                            {moveState || copyState ? <>


                                <div class="input-group input-group-sm">
                                    <button disabled={getDisableState()} onClick={() => {
                                        if (moveState) {
                                            handleMoveAll()
                                        } else if (copyState) {
                                            handleCopyAll()
                                        }
                                    }} className='btn btn-sm btn-primary'>Paste </button>
                                    <div class="input-group-append ">
                                        <button style={{ height: "31px" }} onClick={() => { setError(null); setCreateFolder(false); setFolderName(null) }} class="btn-creat-close btn-outline-secondary" type="button"><FontAwesomeIcon color='blue' icon={faTimes} onClick={() => { setMoveFiles([]); setMoveState(false); setCopyState(false); getHomeData(parent_id); setOpenFolderDir([]); }} /></button>
                                    </div>
                                </div>

                            </> : <></>}
                        </div>
                    </div>

                </div>
                <div style={{ maxHeight: "calc(100vh - 270px)" ,overflowY:'scroll'}} {...getRootProps({ className: "dropzone", onClick: event => event.stopPropagation() })} className='container1  px-3 pt-0'>
                    <table class="table workdrive-table table-sm ">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Created By</th>
                                <th scope="col">Created Time</th>
                                <th scope="col">Last Modified Time</th>
                                <th scope="col">Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedFiles && selectedFiles.map((one, index) => {
                                return <tr>
                                    <td >{one.attributes.type == "folder" ? <FontAwesomeIcon icon={faFolder} /> : <FontAwesomeIcon icon={faFileAlt} />}</td>
                                    <td ><input onChange={(e) => handleCheckItem(e, index)} checked={one.checked} type="checkbox" /></td>
                                    <td>
                                        {one.checked && one.rename ?
                                            <div class="input-group ">
                                                <input onChange={(e) => {
                                                    const files = [...selectedFiles];
                                                    files.forEach(obj => {
                                                        if (obj.id == one.id) {
                                                            one.renameValue = e.currentTarget.value;
                                                        }
                                                    })
                                                    setSelectedFiles(files);
                                                }} defaultValue={one.attributes.name} type="text" class="form-control" placeholder="enter name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                {/* <div class="input-group-append">
                                <button onClick={()=>handleRenameFolder(one,index)} class="btn btn-outline-secondary" type="button"><FontAwesomeIcon color='blue' icon={faCheck} /></button>
                            </div> */}
                                            </div>


                                            :
                                            <>

                                                {one.attributes.type == "folder" ?
                                                    <a onClick={() => {
                                                        if (searchState) {
                                                            arr = [];
                                                            setFolderId(one.id);
                                                            getFolderDirForSearch(parent_id, one.id)
                                                        } else {
                                                            getFolderAndFilesOfId(one.id, one.attributes.name, "next")
                                                            setFolderId(one.id);
                                                        }

                                                    }} style={{ cursor: 'pointer' }}>{one.attributes.name}</a> :
                                                    <AntdPopover
                                                        placement="right"
                                                        trigger="hover"
                                                        // isOpen={employeeNotePopup}
                                                        content={<div>
                                                            <img height={200} width={200} src={one.attributes.thumbnail_url} />

                                                        </div>}
                                                    >
                                                        <a onClick={() => {
                                                            window.open("https://workdrive.zoho.com/file/" + one.id)
                                                            // getFolderAndFilesOfId(one.id, one.attributes.name, "next")
                                                            // setFolderId(one.id);
                                                        }} style={{ cursor: 'pointer' }}>{one.attributes.name}</a>
                                                    </AntdPopover>


                                                }


                                            </>}

                                    </td>
                                    <td>{one.attributes.created_by}</td>
                                    <td>{one.attributes.created_time}</td>
                                    <td>{one.attributes.modified_time}</td>
                                    <td>{one.attributes.storage_info.size}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>


        </div>

    );
}

export default Workdrive;