import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "./RenderAllTasks.css";
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Popover,
  Select,
  Table,
  TimePicker,
} from "antd";
import {
  SelectedUserAvatar,
  UserPicker,
} from "../../common/UserPicker/UserPicker";
import { Option } from "antd/es/mentions";
import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  EyeOutlined,
  FlagFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { AppDataRequired } from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faCodeBranch,
  faEye,
  faGripVertical,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getPriorityValue, invertColor } from "../HR/TimeClock/Util";
import AwsServerService from "../../common/util/AwsServerService";
import swal from "sweetalert";
import minusCircle from "../../assets/images/svg/minus-circle.svg";
import AppContext from "../../Context/AppContext";
import Vector from "../../assets/images/vector.png";
import { rotate } from "mathjs";
import CustomTimeSelect from "../../common/CustomTimeSelect/CustomTimeSelect";
import { useHistory } from "react-router-dom";

export const TaskGroupCard = ({
  header,
  data,
  allPipelineData,
  groupFilter,
  viewTaskFunction,
  setTaskHeadersData,
  taskHeadersData,
  selectedTable,
  isSubTaskView,
  setIsSubTaskView,
  taskFilterSetting,
  refreshData,
  setPageRefreshData,
  allDependeniesData,
  dropdownTasksDataCache,
  allTasksAndSubtasks,
  setShowDependencies,
}) => {
  const [rowData, setRowData] = useState(data);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // State for sorting
  const { AllUsers } = useContext(AppDataRequired);
  const AppData = useContext(AppContext);
  const { dispatch, state } = AppData || {};
  const [dropdowndata, setdropdownTasksData] = useState(
    new Map(dropdownTasksDataCache.map((item) => [item.value, item]))
  );
  let history = useHistory();

  // console.log("All users-------",AllUsers);

  useEffect(
    (e) => {
      setRowData(data);
    },
    [data]
  );
  const toggleExpandIconOfSubtask = (e) => {
    e.stopPropagation();
    let arr = e.currentTarget.childNodes;
    let taskId = e.currentTarget.id;
    let arrofsubtasks = document.querySelectorAll(`.${taskId}`);
    arr.forEach((element) => {
      if (element.classList.contains("SHOW")) {
        element.classList.remove("SHOW");
        element.classList.add("HIDE");
        arrofsubtasks.forEach((subtask) => {
          // subtask.classList.remove("")
          subtask.classList.add("HIDEROW");
        });
      } else if (element.classList.contains("HIDE")) {
        element.classList.remove("HIDE");
        element.classList.add("SHOW");
        arrofsubtasks.forEach((subtask) => {
          // subtask.classList.remove("")
          subtask.classList.remove("HIDEROW");
        });
      }
    });
    console.log(arr);
  };

  const navigateToDeal = (deal) => {
    deal = { ...deal }
    deal.Created_By = deal[0].Created_By.name;
    deal.Owner = deal[0].Owner.name;
    deal.Account_Name = deal[0].Account_Name ? deal[0].Account_Name.name : "";
    history.push({
      pathname: '/d/Deals/DetailView/' + deal[0].id,
      state: { data: deal[0], action: 'DetailView', module: 'Deals' }
    });
  }

  // console.log("datatttttttatattatatatat", data);
  // console.log("allPipelineDataatttttttatattatatatat", allPipelineData);
  
  const showTaskLocationNames = (data) => {
    let allDeals = [...state?.AllDeals];
    let filteredDeal = allPipelineData?.filter(item => item?.PipelineROWID==data.PipelineROWID)[0];
    let accountName=allDeals?.filter(e=>e.Deal_Name==filteredDeal?.DealName ? e?.Account_Name:'');
    let filterDealData = allDeals?.filter(e=>e.Deal_Name==filteredDeal?.DealName);
    
    return (
      <>
        <span
          className="cont-box2"
        >
          <span
            onClick={() => {
              history.push({
                pathname: '/c/Accounts/DetailView/' + accountName[0].Account_Name.id,
                state: { data: accountName[0].Account_Name, action: 'Update', module: 'Accounts' }
              });
            }}
            // style={clientColorObj}
            className="cont-box3"
          >{accountName[0].Account_Name.name}

          </span>
          <span class="custom-slash">{"/"}</span>
          <span  onClick={() => { navigateToDeal(filterDealData)}

            } className="cont-box4 cursor-pointer">
            {filteredDeal?.DealName}
          </span>
          {/* <span className="cont-box5">
            {filterJob.Project_Address}
          </span> */}
        </span>


      </>
    )
  }

  const getDescriptionPopover = (data) => {
    const content = (
      <p style={{ overflowWrap: "break-word" }}>
        {data?.TaskDescription}
      </p>
    );
    return (
      <Popover content={content} title="" trigger="hover">
        <FontAwesomeIcon icon={faAlignLeft} />
      </Popover>
    );
  };

  function actionListCellRenderer(data1, module) {
    // console.log(module);
    // console.log(selectedTable);
    //// Triggers are remaining for EDIT DELETE and CREATE //////
    return (
      <>
        <div className="global-action-btn-for-tables">
          <button
            value={data1?.TaskROWID}
            onClick={(e) => {
              if (module == "task") {
                let task = data?.find((f) => f.TaskROWID == data1?.TaskROWID);
                viewTaskFunction(task, "TASK");
              } else {
                setIsSubTaskView(true);
                let task = data?.find((f) => f.TaskROWID == data1?.TaskROWID);
                let subTask = task?.SubTasks?.find(
                  (f) => f?.SubTaskROWID == data1?.SubTaskROWID
                );
                viewTaskFunction(subTask, "SUBTASK");
              }
            }}
          >
            Edit
          </button>
          <button
            value={data1?.TaskROWID}
            style={{ color: "red" }}
            onClick={(e) => {
              swal({
                title: "Warning!",
                text: "Are you sure you want to delete the Task?",
                icon: "warning",
                buttons: {
                  cancel: "Cancel",
                  confirm: "Proceed",
                },
              }).then(async (isDelete) => {
                if (isDelete) {
                  //
                  if (module == "task") {
                    if (selectedTable == "tasks") {
                      AwsServerService.deleteTask(data1?.TaskROWID)
                        .then((res) => {
                          console.log("res", res);
                          setPageRefreshData(!refreshData);
                        })
                        .catch((e) => {
                          console.log("error while deleting task");
                        });
                    } else {
                      AwsServerService.deleteTaskInTemplate(data1?.TaskROWID)
                        .then((res) => {
                          console.log("res", res);
                          setPageRefreshData(!refreshData);
                        })
                        .catch((e) => {
                          console.log("error while deleting task");
                        });
                    }
                  } else if (module == "subtask") {
                    if (selectedTable == "tasks") {
                      AwsServerService.deleteSubtask(data1?.SubTaskROWID)
                        .then((res) => {
                          console.log("res", res);
                          setPageRefreshData(!refreshData);
                        })
                        .catch((e) => {
                          console.log("error while deleting task");
                        });
                    } else {
                      AwsServerService.deleteSubtaskInTemplate(
                        data1?.SubTaskROWID
                      )
                        .then((res) => {
                          console.log("res", res);
                          setPageRefreshData(!refreshData);
                        })
                        .catch((e) => {
                          console.log("error while deleting task");
                        });
                    }
                  }
                }
              });
            }}
          >
            Delete
          </button>
        </div>
      </>
    );
  }

  const sortData = (field) => {
    let direction = "ascending";
    if (sortConfig.key === field) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        direction = "none";
      }
    }

    if (direction === "none") {
      setRowData(data); // Reset to original data
      setSortConfig({ key: "", direction: "" });
    } else {
      const sortedData = [...rowData].sort((a, b) => {
        if (a[field] < b[field]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
      setRowData(sortedData);
      setSortConfig({ key: field, direction });
    }
  };

  // Render sorting icons based on the current sort state
  const renderSortIcon = (field) => {
    if (sortConfig.key === field) {
      if (sortConfig.direction === "ascending") {
        return <CaretUpOutlined />;
      } else if (sortConfig.direction === "descending") {
        return <CaretDownOutlined />;
      }
    }
    return null; // No icon when not sorted
  };
const updateTaskData=(data)=>{
  let data2={}; 
  data2["ROWID"]=data.TaskROWID; 
  data2["TimeEstimate"]=data.TimeEstimate;
  AwsServerService.updateTask(data2).then(r=>{
    console.log('updated',r); 
    setPageRefreshData(!refreshData)
  }).catch(er=>{
    console.log("Er",er);
    
  })
}
  const taskHeadr = [
    {
      label: "Task Name",
      field: "TaskName",
      render: (data, field) => {
        return (
          <Row
            style={{
              display: "flex",
              height: "41px",
              alignItems: "center",
              cursor: "pointer",
            }}
            sm={12}
            onClick={(e) => {
              viewTaskFunction(data, "TASK");
            }}
          >
            <Col
              id={`TaskId-${data.TaskId}`}
              className="Expand-Icon-Section"
              onClick={(e) => toggleExpandIconOfSubtask(e)}
              sm={1}
            >
              {data?.SubTasks?.length > 0 && (
                <>
                  <CaretRightOutlined className="LEFT SHOW" />
                  <CaretDownOutlined className="RIGHT HIDE" />
                </>
              )}
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                "align-items": "center",
                gap: "10px",
              }}
            >
              <span
                title={data[field]}
                className="Elipsis-Class"
                style={{ width: "150px" }}
              >
                {data[field]}
              </span>
              <div
                className="dependencyicon"
                onClick={(e) => {
                  setShowDependencies(data.deps);
                  // e.stopPropagation()
                }}
              >
                {data?.deps.Blocking.length > 0 &&
                data?.deps.WaitingOn.length > 0 ? (
                  <img
                    className="minus-circle-v"
                    src={minusCircle}
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                  />
                ) : data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length == 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                    icon={faMinusCircle}
                    color={"#f40600"}
                  />
                ) : data?.deps.Blocking.length == 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                    icon={faMinusCircle}
                    color={"#FFA500"}
                  />
                ) : (
                  <></>
                )}
              </div>
              {data?.TaskDescription != "null" && data?.TaskDescription && getDescriptionPopover(data)}
              {data?.SubTasks?.length > 0 ? (
                <Badge
                  color="#7a7a7a"
                  count={data?.SubTasks?.length}
                  className="subtask-badge"
                  size="small"
                  offset={[2, 0]}
                >
                  <img src={Vector} alt="subtask-icon" className="subtask-icon"/>
                </Badge>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      label: "Assignee",
      field: "Assignee",
      render: (data, field) => {
        let assignee = data[field];
        return (
          <UserPicker
            rootClassName={"table-dropdown"}
            className={"No-Border"}
            values={assignee}
            mode="multiple"
            onChange={(e) => {
              // debugger;
            }}
          />
        );
      },
    },
    {
      label: "Supervisor",
      field: "Supervisor",
      render: (data, field) => {
        let Supervisor = data[field];
        return (
          <UserPicker
            rootClassName={"table-dropdown"}
            className={"No-Border"}
            values={Supervisor}
            mode=""
            onChange={(e) => {
              // debugger;
            }}
          />
        );
      },
    },
    {
      label: "Description",
      field: "TaskDescription",
      render: (data, field) => {
        let description = data[field];
        return (
          <span
            title={description}
            className="Elipsis-Class"
            style={{ width: "400px" }}
          >
            {description}
          </span>
        );
      },
    },
    {
      label: "Priority",
      field: "Priority",
      render: (data, field) => {
        // return data[field];
        return (
          <Select
            className="No-Border priority-icon"
            name="Priority"
            placeholder="Select an option"
            style={{ width: 200 }}
            value={data[field] ? data[field] : "Not Set"}
            onChange={(val) => {}}
            rootClassName="table-dropdown"
            // suffixIcon={<CaretDownFilled />}
            dropdownRender={(menu) => <>{menu}</>}
            optionLabelProp="icon"
            title={data[field]}
          >
            <Option
              value="Not Set"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Not Set") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not
              Set
            </Option>
            <Option
              value="Low"
              icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
            </Option>
            <Option
              value="Medium"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Medium") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Medium") }} />{" "}
              Medium
            </Option>
            <Option
              value="High"
              icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("High") }} /> High
            </Option>
          </Select>
        );
      },
    },
    {
      label: "Time Estimate",
      field: "TimeEstimate",
      render: (data, field) => {
        return (
          // <TimePicker
          //   className="TimePicker-Estimate No-Border"
          //   defaultValue={dayjs("12:08", "HH:mm")}
          //   name="TimeEstimate"
          //   // use12Hours
          //   format={"HH:mm"}
          //   value={data[field] ? dayjs(data[field], "HH:mm") : null}
          //   onChange={(time, timestr) => {
          //     //   let temp={...taskData}
          //     //   temp["TimeEstimate"]=timestr;
          //     //   setTaskData(temp)
          //   }}
          // />
          <CustomTimeSelect 
            rootClassName={"Task-Table-TimePicker"}
            className={"Task-Table-TimePicker-Class"}
            placement={"top"}
            taskData={data}
            setTaskData={(dt)=>{updateTaskData(dt)}}
          />
        );
      },
    },
    {
      label: "Status",
      field: "Status",
      render: (data, field) => {
        return data[field];
      },
    },
    {
      label: "Pipeline",
      field: "PipelineROWID",
      render: (data, field) => {
        let pipeline = allPipelineData.find(
          (e) => e.PipelineROWID == data.PipelineROWID
        );
        return pipeline?.PipelineName ? pipeline.PipelineName : "N/A";
      },
    },
    // {
    //     label: "Is Milestone",
    //     field: "IsMilestone",
    //     render: (data, field) => {
    //         return data[field]=="false"?"No":"Yes";
    //     }
    // },
    {
      label: "Action",
      field: "Action",
      render: (data, field) => {
        return actionListCellRenderer(data, "task");
      },
    },
  ];
  const subTaskHeadr = [
    {
      label: "Task Name",
      field: "TaskName",
      render: (data, field) => {
        return (
          <Row
            style={{
              display: "flex",
              height: "41px",
              alignItems: "center",
              cursor: "pointer",
            }}
            sm={12}
            onClick={(e) => {
              viewTaskFunction(data, "SUBTASK");
              setIsSubTaskView(true);
            }}
          >
            <Col sm={1}></Col>
            <Col sm={1}></Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                "align-items": "center",
              }}
            >
              <span>{data[field]}</span>
              {/* <span className='TaskIdSpan'>{`(${data["SubTaskId"]})`}</span> */}
              {/* <Button className='Task-View-Btn' type='primary' icon={<FontAwesomeIcon icon={faEye}/>} onClick={e=>{
                    viewTaskFunction(data,"SUBTASK");
                    setIsSubTaskView(true);
                }} ghost>View</Button> */}
              <div
                className="dependencyicon"
                onClick={(e) => {
                  setShowDependencies(data.deps);
                  // e.stopPropagation()
                }}
              >
                {data?.deps.Blocking.length > 0 &&
                data?.deps.WaitingOn.length > 0 ? (
                  <img
                    className="minus-circle-v"
                    src={minusCircle}
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                  />
                ) : data?.deps.Blocking.length > 0 &&
                  data?.deps.WaitingOn.length == 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                    icon={faMinusCircle}
                    color={"#f40600"}
                  />
                ) : data?.deps.Blocking.length == 0 &&
                  data?.deps.WaitingOn.length > 0 ? (
                  <FontAwesomeIcon
                    onClick={() => {
                      // setShowDependencyList(e.DependentOn)
                      // setShowDependencyPopUp(true)
                    }}
                    icon={faMinusCircle}
                    color={"#FFA500"}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        );
      },
    },
    {
      label: "Assignee",
      field: "Assignee",
      render: (data, field) => {
        let assignee = data[field];
        return (
          <UserPicker
            rootClassName={"table-dropdown"}
            className={"No-Border"}
            values={assignee}
            mode="multiple"
            onChange={(e) => {
              // debugger;
            }}
          />
        );
      },
    },
    {
      label: "Supervisor",
      field: "Supervisor",
      render: (data, field) => {
        let Supervisor = data[field];
        return (
          <UserPicker
            rootClassName={"table-dropdown"}
            className={"No-Border"}
            values={Supervisor}
            mode=""
            onChange={(e) => {
              // debugger;
            }}
          />
        );
      },
    },
    {
      label: "Description",
      field: "TaskDescription",
      render: (data, field) => {
        let description = data[field];
        return (
          <span
            title={description}
            className="Elipsis-Class"
            style={{ width: "400px" }}
          >
            {description}
          </span>
        );
      },
    },
    {
      label: "Priority",
      field: "Priority",
      render: (data, field) => {
        // return data[field];
        return (
          <Select
            className="No-Border priority-icon"
            name="Priority"
            placeholder="Select an option"
            style={{ width: 200 }}
            value={data[field] ? data[field] : "Not Set"}
            onChange={(val) => {}}
            // suffixIcon={<CaretDownFilled />}
            dropdownRender={(menu) => <>{menu}</>}
            optionLabelProp="icon"
            title={data[field]}
            rootClassName={"table-dropdown"}
          >
            <Option
              value="Not Set"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Not Set") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Not Set") }} /> Not
              Set
            </Option>
            <Option
              value="Low"
              icon={<FlagFilled style={{ color: getPriorityValue("Low") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("Low") }} /> Low
            </Option>
            <Option
              value="Medium"
              icon={
                <FlagFilled style={{ color: getPriorityValue("Medium") }} />
              }
            >
              <FlagFilled style={{ color: getPriorityValue("Medium") }} />{" "}
              Medium
            </Option>
            <Option
              value="High"
              icon={<FlagFilled style={{ color: getPriorityValue("High") }} />}
            >
              <FlagFilled style={{ color: getPriorityValue("High") }} /> High
            </Option>
          </Select>
        );
      },
    },
    {
      label: "Time Estimate",
      field: "TimeEstimate",
      render: (data, field) => {
        return (
          <TimePicker
            className="TimePicker-Estimate No-Border"
            defaultValue={dayjs("12:08", "HH:mm")}
            name="TimeEstimate"
            format={"HH:mm"}
            value={data[field] ? dayjs(data[field], "HH:mm") : null}
            onChange={(time, timestr) => {
              //   let temp={...taskData}
              //   temp["TimeEstimate"]=timestr;
              //   setTaskData(temp)
            }}
          />
        );
      },
    },
    {
      label: "Status",
      field: "Status",
      render: (data, field) => {
        return data[field];
      },
    },
    {
      label: "Pipeline",
      field: "PipelineROWID",
      render: (data, field, task) => {
        let pipeline = allPipelineData.find(
          (e) => e.PipelineROWID == task.PipelineROWID
        );
        return pipeline?.PipelineName ? pipeline.PipelineName : "N/A";
      },
    },
    // {
    //     label: "Is Milestone",
    //     field: "IsMilestone",
    //     render: (data, field) => {
    //         return <></>;
    //     }
    // },
    {
      label: "Action",
      field: "Action",
      render: (data, field) => {
        // console.log("data SubTaskROWID", data);
        return actionListCellRenderer(data, "subtask");
      },
    },
  ];
  const [taskHeaders, setTaskHeaders] = useState(taskHeadr);
  const [subTaskHeaders, setSubTaskHeaders] = useState(subTaskHeadr);
  useEffect(() => {
    if (taskHeadersData.length > 0) {
      setTaskHeaders(taskHeadersData);
      let d = taskHeadersData.filter((e, index) => index != 0);
      d.splice(0, 0, subTaskHeadr[0]);
      setSubTaskHeaders(d);
    }
  }, [taskHeadersData]);
  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    const newHeaders = [...taskHeaders];
    const [movedHeader] = newHeaders.splice(draggedIndex, 1);
    newHeaders.splice(dropIndex, 0, movedHeader);
    setTaskHeadersData(newHeaders);
  };
  const getTabCard = () => {
    if (groupFilter.group2.name == "Pipeline") {
      let pipeline = allPipelineData.find((e) => e.PipelineROWID == header);
      return (
        <div className="Group-Titel-Card">
          <span style={{ fontSize: "11px" }}>
            {pipeline?.PipelineName ? pipeline.PipelineName : "N/A"}
          </span>
        </div>
      );
    } else if (
      groupFilter.group2.name == "Assignee" ||
      groupFilter.group2.name == "Supervisor"
    ) {
      let user = AllUsers.find((e) => e.zuid == header);
      return (
        <div className="Group-Titel-Card">
          <span style={{ fontSize: "11px" }}>
            {SelectedUserAvatar({ label: user?.full_name, value: user?.zuid })}
          </span>
        </div>
      );
    } else if (groupFilter.group2.name == "Priority") {
      let color = getPriorityValue(header);
      return (
        header && (
          <div
            style={{ backgroundColor: color, color: invertColor(color, true) }}
            className="Group-Titel-Card"
          >
            <span style={{ fontSize: "11px" }}>{header}</span>
          </div>
        )
      );
    } else {
      return (
        <div className="Group-Titel-Card">
          <span style={{ fontSize: "11px" }}>{header}</span>
        </div>
      );
    }
  };
  // const getTaskHeaders = () => {
  //     return taskHeaders.map((col, index) => {
  //         return <th
  //             draggable
  //             onDragStart={(e) => handleDragStart(e, index)}
  //             onDragOver={(e) => e.preventDefault()}
  //             onDrop={(e) => handleDrop(e, index)}
  //         className={`${col.label=="Action" && "custom-sticky"} ${index == 0 ? "min-width-250 task_table_header task-card-title" : "column-header"}`}>{index == 0 ? getTabCard() : col.label}
  //         <FontAwesomeIcon icon={faGripVertical} className='dragOptionIcon' />
  //         </th>
  //     })
  // }
  const getTaskHeaders = () => {
    return taskHeaders.map((col, index) => {
      return (
        <th
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e, index)}
          className={`${col.label === "Action" && "custom-sticky"} ${
            index === 0
              ? "min-width-250 task_table_header task-card-title"
              : col.label === "Assignee" ||
                col.label === "Supervisor" ||
                col.label === "Priority"                 
              ? "column-header min-width-100" :
              col.label === "Time Estimate" ?
              "column-header min-width-120"
              : "column-header"
          }`}
          onClick={() => sortData(col.field)} // Add onClick for sorting
        >
          {index === 0 ? getTabCard() : col.label}
          {renderSortIcon(col.field)}
          <FontAwesomeIcon icon={faGripVertical} className="dragOptionIcon" />
        </th>
      );
    });
  };
  const getTaskRow = (data) => {
    return taskHeaders.map((col, index) => {
      return (
        <td
          className={`${col.label == "Action" && "custom-sticky"} ${
            data?.SubTasks?.length > 0 && index == 0 ? "connecting-task-1" : ""
          }`}
        >
          {col.render(data, col.field)}
        </td>
      );
    });
  };
  const getSubTaskRow = (data, task) => {
    return subTaskHeaders.map((col, index) => {
      return (
        <td
          className={`${col.label == "Action" && "custom-sticky"} ${
            index == 0 ? "connecting-task-2" : ""
          }`}
        >
          {col.render(data, col.field, task)}
        </td>
      );
    });
  };
  const getSubtaskCollapse = (subtasks, task) => {
    return subtasks.map((sub, i) => {
      let deps = getAllDependenciesForTask(true, sub);
      sub = { ...sub, deps: deps };
      return (
        <tr
          className={`TaskId-${task.TaskId} ${
            taskFilterSetting.ExpandTasks ? "" : "HIDEROW"
          }`}
        >
          {getSubTaskRow(sub, task)}
        </tr>
      );
    });
  };
  const getAllDependenciesForTask = (subtask, data) => {
    let sourceTaskType = subtask ? "SubTasks" : "Tasks";
    let ROWID = sourceTaskType == "Tasks" ? data.TaskROWID : data.SubTaskROWID;
    const result = allDependeniesData?.filter(
      (e) => e.SourceTaskId == ROWID || e.TargetTaskId == ROWID
    );
    // setResultFromGetDependency(result);
    if (result.length > 0) {
      // let dropdowndata = [...dropdownTasksDataCache];
      let obj = {
        WaitingOn: new Set(),
        Blocking: new Set(),
      };
      const dropdownMap = dropdowndata;

      allPipelineData?.forEach((pipeline) => {
        pipeline.Tasks?.forEach((task) => {
          const tDataValidate = result.find(
            (e) =>
              e.TargetTaskId == ROWID &&
              e.SourceTaskId == task.TaskROWID &&
              e.STaskType == "Tasks"
          );
          let tData = undefined;
          if (tDataValidate) {
            tData = tDataValidate;
          } else {
            tData = result.find(
              (e) => e.TargetTaskId == task.TaskROWID && e.TaskType == "Tasks"
            );
          }
          let flag = true;
          if (tData?.SourceTaskId == ROWID) {
            flag = tData.STaskType == sourceTaskType;
          }
          // const tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
          if (tData && flag) {
            const type =
              tData.TargetTaskId == ROWID && tData.DependancyType == "WaitingOn"
                ? "Blocking"
                : tData.TargetTaskId == ROWID &&
                  tData.DependancyType == "Blocking"
                ? "WaitingOn"
                : tData.DependancyType;
            if (tData.TargetTaskId == ROWID) {
              const tmp = dropdownMap.get(
                `${tData.STaskType}_${tData.SourceTaskId}`
              );
              if (tmp && sourceTaskType == tData.TaskType) {
                obj[type].add(JSON.stringify({ ...tmp }));
              }
            } else {
              obj[type].add(
                JSON.stringify({
                  label: task.TaskName,
                  value: `Tasks_${task.TaskROWID}`,
                })
              );
            }
          }
          task.SubTasks?.forEach((subtask) => {
            const tDataValidate = result.find(
              (e) =>
                e.TargetTaskId == ROWID &&
                e.SourceTaskId == subtask.SubTaskROWID &&
                e.STaskType == "SubTasks"
            );
            let tData2 = undefined;
            if (tDataValidate) {
              tData2 = tDataValidate;
            } else {
              tData2 = result.find(
                (e) =>
                  e.TargetTaskId == subtask.SubTaskROWID &&
                  e.TaskType == "SubTasks"
              );
            }
            //   const tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
            if (tData2) {
              const type2 =
                tData2.TargetTaskId == ROWID &&
                tData2.DependancyType == "WaitingOn"
                  ? "Blocking"
                  : tData2.TargetTaskId == ROWID &&
                    tData2.DependancyType == "Blocking"
                  ? "WaitingOn"
                  : tData2.DependancyType;
              if (tData2.TargetTaskId == ROWID) {
                const tmp2 = dropdownMap.get(
                  `${tData2.STaskType}_${tData2.SourceTaskId}`
                );
                if (tmp2 && sourceTaskType == tData2.TaskType) {
                  obj[type2].add(JSON.stringify({ ...tmp2 }));
                }
              } else {
                obj[type2].add(
                  JSON.stringify({
                    label: subtask.TaskName,
                    value: `SubTasks_${subtask.SubTaskROWID}`,
                  })
                );
              }
            }
          });
        });
      });
      let obj2 = {
        WaitingOn: Array.from(obj.WaitingOn).map((item) => JSON.parse(item)),
        Blocking: Array.from(obj.Blocking).map((item) => JSON.parse(item)),
      };
      return obj2;
    } else {
      return { WaitingOn: [], Blocking: [] };
    }
  };
  return (
    <Col sm={12} className="TaskGroup2Card">
      <Row sm={12}>
        <table className="table task-table task-content-table table-hover">
          <thead className="">{getTaskHeaders(header)}</thead>
          <tbody>
            {rowData?.map((row, index) => {
              let deps = getAllDependenciesForTask(
                row.hasOwnProperty("SubTaskROWID"),
                row
              );
              row = { ...row, deps: deps };
              return (
                <>
                  {taskFilterSetting &&
                  taskFilterSetting.TaskLocation
                    ? showTaskLocationNames(row)
                    : ""}
                  <tr>{getTaskRow(row)}</tr>
                  {row?.SubTasks?.length > 0 &&
                    getSubtaskCollapse(row.SubTasks, row)}
                </>
              );
            })}
          </tbody>
        </table>
      </Row>
    </Col>
  );
};
