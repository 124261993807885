import React, { useState, useEffect, useRef } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import GoogleMapReact from "google-map-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Marker from "../../preliminary/propertyinformation/Marker";
import Geocode from "react-geocode";
const IndividualOwnerMail = (props) => {
  const[zipValid,setZipValid]=useState(true);
  const [buttonState, setbuttonState] = useState(false);
  const placeInputRef1 = useRef(null);
  const [zoom, setZoom] = useState(16);
  const [ltitude, setltitude] = useState("");
  const [lngitude, setlngitude] = useState("");
  const [skipOwnerMail, setSkipOwnerMail] = useState(false);

  const[validation,setValidation] =useState({
    // MailingAdd:'0',
    streetName:'0',
    cityName:'0',
    stateName:'0',
    zipCode:'0'
  })
  
  const highliteEmptys=(data)=>{
    let vKeys=Object.keys(data.arr);
    let ob={};
    for(let i=0;i<vKeys.length;i++){
      if(data.arr[vKeys[i]]=='0' || data.arr[vKeys[i]]=='-1'){
        ob[vKeys[i]]='-1'
      }else{
        ob[vKeys[i]]='1'
      }
    }
      setValidation(ob)
  }

  Geocode.setApiKey("AIzaSyDw1Azkwh-3GjkOIig95Xu7aTaqwg8nTVw");
  Geocode.setLanguage("en");
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("us");
  Geocode.setLocationType("ROOFTOP");
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();

  const buttontoggle = () => {
    props.settoggleState("individualownermail");
    props.setListState("individualownermail");
    setbuttonState(!buttonState);
  };
  const validationCss={
    "transition": "width 0.4s ease-in-out",
    "box-shadow" : "rgb(255 98 98) 0px 0px 7px 1px","border-color":"#ff0000",
  }
  const onChangeMailingAdd = (value) => {
    props.setMailingAdd(value);
    props.handleOnChangeDeal({
      currentTarget: { name: "MailingAdd", value: value },
    });
  };

  const onChangeApt = (value) => {
    props.sethouseNo(value);
    localStorage.setItem("mailinghouseNo", value);
  };

  const onBlurMailingAdd = (value) => {
    props.setMailingAdd(value);
    localStorage.setItem("mailingAddress", value);

    Geocode.fromAddress(value).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const { lat, lng } = response.results[0].geometry.location;

        var houseNO = "",
          street = "",
          city = "",
          state = "",
          zipcode = "";
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                houseNO = response.results[0].address_components[i].long_name;
                break;
              case "route":
                street = houseNO + " " + response.results[0].address_components[i].long_name;
                break;
              case "sublocality_level_1":
                city = response.results[0].address_components[i].long_name;
                break;
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "postal_code":
                zipcode = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        if(street!==""){
          setValidation({...validation,MailingAdd:'1',
          streetName:'1',cityName:'1',zipCode:'1',stateName:'1'})
        }
        let obj = {};
        props.sethouseNo("");
        //   localStorage.setItem("mailinghouseNo", houseNO);
        props.setstreetName(houseNO + " " + street);
        localStorage.setItem("mailingstreet", houseNO + " " + street);
        props.setcityName(city);
        localStorage.setItem("mailingcity", city);
        props.setstateName(state);
        localStorage.setItem("mailingstate", state);
        props.setzipCode(zipcode);
        localStorage.setItem("mailingzipcode", zipcode);
        props.setCenter({ lat: lat, lng: lng });
        setltitude(lat);
        localStorage.setItem("mailinglat", lat);
        setlngitude(lng);
        localStorage.setItem("mailinglng", lng);
        obj.MailingAdd = value;
        obj.center = { lat: lat, lng: lng };
        obj.streetName = street;
        obj.cityName = city;
        obj.stateName = state;
        obj.zipCode = zipcode;
        obj.houseNO = houseNO;
        props.handleOnChangeDeal({
          currentTarget: { name: "Merge", value: obj },
        });
        //obj.streetAdd
      },
      (error) => {
        console.error("Error Finding Place:" + error);
      }
    );
  };

  const setAllStates = () => {
    setbuttonState(true);
    props.setCenter({
      lat: localStorage.getItem("mailinglat"),
      lng: localStorage.getItem("mailinglng"),
    });
    props.setMailingAdd(localStorage.getItem("mailingAddress"));
    props.sethouseNo(localStorage.getItem("mailinghouseNo"));
    props.setstreetName(localStorage.getItem("mailingstreet"));
    props.setcityName(localStorage.getItem("mailingcity"));
    props.setstateName(localStorage.getItem("mailingstate"));
    props.setzipCode(localStorage.getItem("mailingzipcode"));
    setltitude(localStorage.getItem("mailinglat"));
    setlngitude(localStorage.getItem("mailinglng"));
    initPlaceAPI();
  };

  useEffect(() => {
    props.toggleState == "individualownermail"
      ? setAllStates()
      : setbuttonState(false);
    //initPlaceAPI();
  }, [props.toggleState]);

  useEffect(() => {
    getData();
  }, [props.data]);

  const getData = () => {
    if (props.data != null) {
      props.setMailingAdd(props.data.MailingAdd);
      props.setstreetName(props.data.streetName);
      props.setcityName(props.data.cityName);
      props.setstateName(props.data.stateName);
      props.setzipCode(props.data.zipCode);
      props.sethouseNo(props.data.houseNo);
      props.setStreetAdd(props.data.streetAdd);
    }
  };

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef1.current
    );
    // new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
    //   let place = autocomplete.getPlace();
    //   setPlace({
    //     address: place.formatted_address,
    //     lat: place.geometry.location.lat(),
    //     lng: place.geometry.location.lng()
    //   });
    // });
  };

  const handleMailingAddCheck = () => {
    if (skipOwnerMail) {
      props.setOwnerMailComplete.individualOwnerMail = true;
      return true;
    }
    if (
      props.data.streetName !== "" && props.data.streetName !== undefined &&props.data.streetName !== null &&
      props.data.cityName !== "" && props.data.cityName !== undefined &&props.data.cityName !== null &&
      props.data.stateName !== "" && props.data.stateName !== undefined &&props.data.stateName !== null &&
      props.data.zipCode !== "" && props.data.zipCode !== undefined&&props.data.zipCode !== null
    ) {
      let allChecked=true;
      let vKeys=Object.keys(validation);
      for(let i=0;i<vKeys.length;i++){
        if(validation[vKeys[i]]=='-1'){
          allChecked=false ;
          break;
        }
      }
      if(allChecked){
        props.setOwnerMailComplete.individualOwnerMail = true;
        return true;
      }
    }
    props.setOwnerMailComplete.individualOwnerMail = false;
    return false;
  };
  
  useEffect(e=>{
    if(props.ownerType!="INDIVIDUAL" || props.data.mailAddDifferent==false){
      setValidation({
        // MailingAdd:'0',
        streetName:'0',
        cityName:'0',
        stateName:'0',
        zipCode:'0'
      })
    }
  },[props.ownerType, props.data.mailAddDifferent])

  const obj={
    streetName:props.data.streetName,
    cityName:props.data.cityName,
    stateName:props.data.stateName,
    zipCode:props.data.zipCode
  }

  return (
    <Col sm={12}>
      <Button
        onClick={buttontoggle}
        className={buttonState ? "btn-100-active" : "btn-100"}
        disabled={
          props.data.ownerType != "INDIVIDUAL" ||
          props.data.mailAddDifferent == false
            ? true
            : false
        }
      >
        Homeowner's Mailing Address
        <div className="float-right">
          <Input
            type="checkbox"
            className={
              handleMailingAddCheck()
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={(props.data.ownerType == "INDIVIDUAL" && props.data.mailAddDifferent == true) ? handleMailingAddCheck() : true}
            onChange={() =>
              {
                setSkipOwnerMail(!skipOwnerMail)
                let vKeys = Object.keys(obj);
                 let ob={}
              for (let i = 0; i < vKeys.length; i++) {
                if (obj[vKeys[i]]==''|| obj[vKeys[i]]==undefined) {
                  ob[vKeys[i]] = '-1';
                }else{
                  ob[vKeys[i]] = '1';
                }
              }
              setValidation(ob)
              }
            }
            disabled={
              props.data.ownerType != "INDIVIDUAL" ||
              props.data.mailAddDifferent == false
                ? true
                : false
            }
          />
          {buttonState ? (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleDown}
            />
          ) : (
            <FontAwesomeIcon
              className="float-right font-centered"
              icon={faAngleRight}
            />
          )}
        </div>
      </Button>
      <Collapse isOpen={buttonState}>
        <Card className="createdeal-card">
          <CardBody>
            <FormGroup row className="marb-16">
              {/* <Row>
          <FormGroup row className="marb-16 padtop-15">
          
            <Col sm={6}>
            <Row>
            <Col sm={5}>
            <Label className="text-bold " for="ownermailingadd">
            Owner's Mailing Address<span className="red-text">*</span>
          </Label>
            </Col>
            <Col sm={7}>
            <input type="text"
            className="address-text2"
             ref={placeInputRef} 
             value={props.MailingAdd} 
            onChange={(e) => onChangeMailingAdd(e.target.value)}
            onBlur={(e) => onBlurMailingAdd(e.target.value)}
            />
            </Col>
            </Row>
            </Col>
            <Col sm={6}></Col>
            
            </FormGroup>
            </Row> */}
              <>
                <Col sm={6}>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="ownermailingadd">
                          Owner's Mailing Address
                          {/* <span className="red-text">*</span> */}
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <input
                          type="text"
                          className="address-text2 form-control"
                          ref={placeInputRef1}
                          // style={validation.MailingAdd=='-1'?{...validationCss}:{}}
                          value={props.data ? props.data.MailingAdd : ""}
                          onBlur={(e)=>{
                            // if(e.target.value==undefined||e.target.value==''){
                            //   setValidation({...validation,MailingAdd:"-1"})
                            // }else{
                            //   setValidation({...validation,MailingAdd:"1"})
                            // }
                            onBlurMailingAdd(e.target.value)
                          }}
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart();
                            onChangeMailingAdd(e.target.value)
                            setZipValid(true)
                          }
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="housenumber">
                          Street Address<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data ? props.data.streetName : ""}
                          name="streetName"
                          className="createdeal-textfield"
                          style={validation.streetName=='-1'?{...validationCss}:{}}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,streetName:"-1"})
                            }else{
                              setValidation({...validation,streetName:"1"})
                            }
                          }}
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart().replace(/[_!#@$%^&*()<>?|}{~:]/,'');
                            props.handleOnChangeDeal(e);
                            props.setStreetAdd(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="apt">
                          Apt, Suite, Bldg, Unit, etc.
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.houseNum}
                          className="createdeal-textfield"
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart().replace(/[_!#@$%^&*()<>?|}{~:]/,'');
                            props.handleOnChangeDeal(e);
                            onChangeApt(e.target.value);
                          }}
                          name="houseNum"
                          id="apt"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="mailingCity">
                          City<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.cityName}
                          name="cityName"
                          id="mailingCity"
                          className="createdeal-textfield"
                          style={validation.cityName=='-1'?{...validationCss}:{}}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,cityName:"-1"})
                            }else{
                              setValidation({...validation,cityName:"1"})
                            }
                          }}
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                            props.handleOnChangeDeal(e);
                            props.setcityName(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="mailingState">
                          State<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.stateName}
                          name="stateName"
                          id="mailingState"
                          className="createdeal-textfield"
                          style={validation.stateName=='-1'?{...validationCss}:{}}
                          onBlur={(e)=>{
                            if(e.target.value==undefined||e.target.value==''){
                              setValidation({...validation,stateName:"-1"})
                            }else{
                              setValidation({...validation,stateName:"1"})
                            }
                          }}
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart().replace(/[_!#$@%^&*`\[\]\+\=\"\/().;,<>?|}{~:]/i,'');
                            e.target.value=e.target.value.trimStart().replace(/[0-9]/,'')
                            e.target.value=e.target.value.replace(/(^|\s)\S/g,(match)=>match.toUpperCase())
                            props.handleOnChangeDeal(e);
                            props.setstateName(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="marb-16 padtop-15">
                    <Row>
                      <Col sm={5}>
                        <Label className="text-bold" for="mailingZip">
                          Zip Code<span className="red-text">*</span>
                        </Label>
                      </Col>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={props.data.zipCode}
                          name="zipCode"
                          id="mailingZip"
                          className="createdeal-textfield"
                          style={validation.zipCode=='-1'?{...validationCss}:{}}
                          onChange={(e) => {
                            e.target.value=e.target.value.trimStart();
                            e.target.value=e.target.value.replace(/\D/g, '');
                            if(e.target.value.length>5){
                              e.preventDefault()
                              return
                            }
                            if(e.target.value.length==5){
                              setValidation({...validation,zipCode:'1'})
                              setZipValid(true);
                            }
                            props.handleOnChangeDeal(e);
                            props.setzipCode(e.target.value);
                          }}
                          onBlur={(e)=>{
                             
                            if(props.data.zipCode?.length<5){
                              setValidation({...validation,zipCode:'-1'})
                              setZipValid(false);
                            }
                          }}
                        />
                      </Col>
                      <Col sm={5}></Col>
                      <Col sm={7}><span className={zipValid==false?"red-text font12":"error-display"}> 5 Digit Zip code required.</span></Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col sm={6} className="map-padding">
                  <GoogleMapReact
                    defaultCenter={props.data.center}
                    center={props.data.center}
                    defaultZoom={zoom}
                  >
                    <Marker
                      lat={props.data.center ? props.data.center.lat : ""}
                      lng={props.data.center ? props.data.center.lng : ""}
                      name="My Marker"
                      color="blue"
                    />
                  </GoogleMapReact>
                </Col>
              </>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default IndividualOwnerMail;