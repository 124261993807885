import { faAngleDown, faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { ToolBox } from '../../../common/ShortcutsToolbox/ToolBox';

export const SideBarUl = ({addFolderSubFolder,selectedItem,collabseId,folderArr,onSelect,show,handleContextMenu,handleOnClicksForContext}) => {
    const [isCollapse,setIsCollapse] = useState(true);
    const [isShow,setIsShow] = useState(true);
    useEffect(e=>{
        setIsShow(show);
    },[show])
    function toggleSubFolderList(){
        setIsCollapse(!isCollapse)
    }
    function getFolderList() {
        if(!folderArr || folderArr?.length==0){
            return;
        }
        return <ul id={`${collabseId}`} class="List">
            {/* <li class="compose mb-3"><button onClick={() => setComposeEmailPopUp(true)} class="btn btn-primary btn-block">Compose</button></li> */}
            {folderArr && folderArr.map((folder) => {
                let listOfSubFilders = folder?.subFolders
                return (<><li
                    onClick={() => onSelect(folder)}
                    onDrop={e=>{
                        if(e.currentTarget.classList.contains("Dotted-Border")){
                            e.currentTarget.classList.remove("Dotted-Border");
                        }
                        let messageId = e.dataTransfer.getData("messageId");
                        handleOnClicksForContext({messageId:messageId,folderId:folder.folderId},"MOVEFOLDER");
                        
                    }}
                    onDragOver={e=>{
                        if(!e.currentTarget.classList.contains("Dotted-Border")){
                            e.currentTarget.classList.add("Dotted-Border");
                        }
                    }}
                    onDragLeave={e=>{
                        if(e.currentTarget.classList.contains("Dotted-Border")){
                            e.currentTarget.classList.remove("Dotted-Border");
                        }
                    }}
                    onContextMenu={(e) => {
                        
                        handleContextMenu(e, folder, "Sidebar");
                    }}
                    class={selectedItem == folder.folderName ? "active cursor-pointer sidebar-li" : 'sidebar-li cursor-pointer'}>
                    {listOfSubFilders && listOfSubFilders.length>0 ? <span style={{minWidth:"13px"}}>
                        {isCollapse ? <FontAwesomeIcon className='ShowCollapse' icon={faAngleDown}
                            onClick={toggleSubFolderList}
                        />:
                        <FontAwesomeIcon className='ShowCollapse' icon={faAngleRight}
                            onClick={toggleSubFolderList}
                        />}
                    </span>:<span style={{minWidth:"13px"}}></span>}
                    <a >
                        {/* <i class="mdi mdi-email-outline"></i> */}
                        {folder.folderName}</a>
                    {/* <span className='Add-Folder-Icon'>
                        <FontAwesomeIcon icon={faPlus}
                            onClick={e => {
                                e.stopPropagation();
                                addFolderSubFolder(e, folder);
                            }}
                        />
                    </span> */}
                </li>
                <SideBarUl
                    addFolderSubFolder={addFolderSubFolder}
                    selectedItem={selectedItem}
                    collabseId={""}
                    folderArr={listOfSubFilders}
                    onSelect={onSelect}
                    show={isCollapse}
                    handleContextMenu={handleContextMenu}
                    handleOnClicksForContext={handleOnClicksForContext}
                />
                </>)

            })}
        </ul>
    }
    
  return (
    <>{isShow && getFolderList()}</>
  )
}
