import React, { useState, useEffect,useRef,useImperativeHandle,forwardRef } from "react";
import BasicInformation from "./basicinformation/basicinformation";
import ServiceRequested from "./servicerequested/servicerequested";
import PropertyInformation from "./propertyinformation/propertyinformation";
import HttpService from '../../../../../services/http.service';
import axios from "axios";
import Config from '../../../../../config.json'
import { useLocation } from "react-router-dom";
// import swal from 'sweetalert';;
const backendUrl = Config.backend_url+"workdrive";
const Preliminary = forwardRef((props,ref) => {
  const formObj = { 
    BasicInformationComplete: false,
    propertInformationComplete: false,
    servicesComplete: false,
  };
  const [toggleState, settoggleState] = useState("basic");

  var strDate = new Date(); // By default Date empty constructor give you Date.now
  var shortYear = strDate.getFullYear();
  var twoDigitYear = shortYear.toString().substr(-2);
  const [jobnumber, setjobnumber] = useState("0100");
  const [twoDigit, settwoDigit] = useState("S" + twoDigitYear + "-" + jobnumber);
  const [startDate, setStartDate] = useState(new Date());
  const [projectDate, setProjectDate] = useState(Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date()));
  const [client, setClient] = useState("Select Client");
  const [clientId, setClientId] = useState("");
  const [clients, setClients] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [allDeals, setAllDeals] = useState([]);
  const[isEditDeal,setIsEditDeal] = useState(false);


const location = useLocation();


  // useEffect(() => {
  //   
  //   if(props.data!=null){
  //     settwoDigit(props.data.ProjectNumber)
  //     // setStartDate(props.data.ProjectDate)
  //     setClient(props.data.Client)
  //   }
  // }, [props.data]);

 
  const getClientsName = async () => {
    const backendUrl = Config.backend_url+"workdrive";

    var config = {
        Type: "Get",

        url: "https://www.zohoapis.com/crm/v2/Accounts",
        method: "GET",
        headers: {
            'Authorization': localStorage.getItem('token')
        }
    };
    axios.post(backendUrl, { config: {...config} })
      .then(function (data) {
        data.data=data.data.data;
        console.log("fetch client")
        let clientNames = []
        let clients = []
        //var newDiv = document.getElementById('Client');
        console.log("get client")
        var selectHTML = "<option>--Select Client--</option>";
        for (let i = 0; i < data.data.length; i++) {
          clients.push({ 'name': data.data[i].Account_Name, 'id': data.data[i].id });
          clientNames.push(data.data[i].Account_Name)
          // let obj = { id: data.data[i].id }
          // obj = JSON.stringify(obj)
          // selectHTML += `<option value=${obj}>` + data.data[i].Account_Name + "</option>";
        }
        setClients(clients);
        setClientNames(clientNames);

        // newDiv.innerHTML = selectHTML;

      })
      .catch((err) => {
        console.log(err);
        // alert(err);
      })

  }

  let basicProps = {
    getClientsName: getClientsName,
    clientNames: clientNames,
    selectedRole: props.selectedRole,
    isAccess: props.isAccess,
    twoDigit: twoDigit,
    settwoDigit: settwoDigit,
    startDate: startDate,
    setStartDate: setStartDate,
    projectDate: projectDate,
    setProjectDate: setProjectDate,
    client: client,
    clients: clients,
    setClient: setClient,
    clientId: clientId,
    setClientId: setClientId,
    toggleState: toggleState,
    settoggleState: settoggleState,
    setListState: props.setListState,
    setBasicInfoComplete: formObj,

  };

  const [NYCOption, setNYCOption] = useState("No");
  const [projectAdd, setProjectAdd] = useState("");
  const [ltitude, setltitude] = useState("");
  const [lngitude, setlngitude] = useState("");
  const [houseNo, sethouseNo] = useState("");
  const [streetName, setstreetName] = useState("");
  const [cityName, setcityName] = useState("");
  const [stateName, setstateName] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [borough, setBorough] = useState("");
  const [center, setCenter] = useState({
    lat: 40.705121,
    lng: -73.82738499999999,
  });

  let propertyProps = {
    NYCOption: NYCOption,
    setNYCOption: setNYCOption,
    projectAdd: projectAdd,
    setProjectAdd: setProjectAdd,
    ltitude: ltitude,
    setltitude: setltitude,
    lngitude: lngitude,
    setlngitude: setlngitude,
    houseNo: houseNo,
    sethouseNo: sethouseNo,
    streetName: streetName,
    setstreetName: setstreetName,
    cityName: cityName,
    setcityName: setcityName,
    stateName: stateName,
    setstateName: setstateName,
    zipCode: zipCode,
    setzipCode: setzipCode,
    borough: borough,
    setBorough: setBorough,
    center: center,
    setCenter: setCenter,
    toggleState: toggleState,
    settoggleState: settoggleState,
    setListState: props.setListState,
    setPropertyInfoComplete: formObj,
    location: location
  };


  //......................... Service Requested State ___________________________________________
  const [research, setResearchOption] = useState("No");
  const [layout, setLayoutOption] = useState("No");
  const [site, setSiteOption] = useState("No");
  const [design, setDesignOption] = useState("No");
  const [asbestos, setAsbestosOption] = useState("No");
  const [expediting, setExpeditingOption] = useState("No");
  const [permitting, setPermittingOption] = useState("No");
  const [certification, setCertificationOption] = useState("No");
  const [electrical, setElectricalOption] = useState("No");
  const [special, setSpecialOption] = useState("No");
  const [progress, setProgressOption] = useState("No");
  const [energy, setEnergyOption] = useState("No");
  const [interconnection, setInterconnectionOption] = useState("No");
  const [structural, setStructuralOption] = useState("No");
  const [property, setPropertyOption] = useState("No");
  const [NYSERDA, setNYSERDAOption] = useState("No");
  const [PropertyTax, setPropertyTaxOption] = useState("Yes");


  let serviceProps = {
    allServicesName: props.allServicesName,
    setAllServicesName: props.setAllServicesName,
    research: research, setResearchOption: setResearchOption,
    layout: layout, setLayoutOption: setLayoutOption,
    site: site, setSiteOption: setSiteOption,
    design: design, setDesignOption: setDesignOption,
    asbestos: asbestos, setAsbestosOption: setAsbestosOption,
    expediting: expediting, setExpeditingOption: setExpeditingOption,
    permitting: permitting, setPermittingOption: setPermittingOption,
    certification: certification, setCertificationOption: setCertificationOption,
    electrical: electrical, setElectricalOption: setElectricalOption,
    special: special, setSpecialOption: setSpecialOption,
    progress: progress, setProgressOption: setProgressOption,
    energy: energy, setEnergyOption: setEnergyOption,
    interconnection: interconnection, setInterconnectionOption: setInterconnectionOption,
    structural: structural, setStructuralOption: setStructuralOption,
    property: property, setPropertyOption: setPropertyOption,
    NYSERDA: NYSERDA, setNYSERDAOption: setNYSERDAOption,
    PropertyTax: PropertyTax, setPropertyTaxOption: setPropertyTaxOption,
    toggleState: toggleState,
    settoggleState: settoggleState,
    setListState: props.setListState,
    setServicesComplete: formObj
  }


  useEffect(() => {
  
 if(location.state.action=="Update" || location.pathname.includes('Deals/Update/')){
      getAccounts();
      setIsEditDeal(true)
    }
    else{
      setIsEditDeal(false)
      //getDealList();
      getAccounts();
 
    }
     
   

  }, [location])

  // const getDealList = () => {
   
  //   const backendUrl = "https://catly-795086611.development.catalystserverless.com/server/apiservice/getAllDealPages";
  //   var config = {
  //       'token': localStorage.getItem('token')
  //   };
  //   axios.post(backendUrl, {...config})
  //     .then(function (result) {
  //       result.data.data = result.data.data.sort((a, b) => new Date(b.Created_Time) - new Date(a.Created_Time))
  //       result=result.data.data[0];
  //       let dealNumber = "";
  //       let prefix = "S" + new Date().getFullYear().toString().substr(-2);
  //       if (result && result.Deal_Name) {

  //         dealNumber = result.Deal_Name
  //         dealNumber = dealNumber.split('-');
  //         if (dealNumber[0] == prefix) {
  //           let num = dealNumber[1];
  //           num = parseInt(num) + 1;
  //           dealNumber = prefix + "-0" + num;
  //         } else {
  //           dealNumber = prefix + "-0100";
  //         }

  //       } else {
  //         dealNumber = prefix + "-0100";
  //       }
  //       const obj = { ...props.data };
  //       obj.ProjectNumber = dealNumber;
  //       props.handleOnChangeDeal({ currentTarget: { name: "ProjectNumber", value: dealNumber } })

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  const getAccounts=()=>{
    var config = {
      Type: "Get",
      url: "https://www.zohoapis.com/crm/v2/Accounts",
      method: "GET",
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    };
    axios.post(backendUrl, { config: {...config} })
        .then(function (data) {
          data.data=data.data.data;
          console.log("fetch client")
          let clientNames = []
          let clients = []
          //var newDiv = document.getElementById('Client');
          console.log("get client")
          var selectHTML = "<option>--Select Client--</option>";
          for (let i = 0; i < data.data.length; i++) {
            clients.push({ 'name': data.data[i].Account_Name, 'id': data.data[i].id });
            clientNames.push(data.data[i].Account_Name)
            // let obj = { id: data.data[i].id }
            // obj = JSON.stringify(obj)
            // selectHTML += `<option value=${obj}>` + data.data[i].Account_Name + "</option>";
          }
          setClients(clients);
          setClientNames(clientNames);

          // newDiv.innerHTML = selectHTML;
          console.log('Record Data')
          console.log(data)

        })
        .catch((err) => {
          console.log(err);
          alert(err);
        })
  }

  useEffect(() => {
    //getClientsName();
    settoggleState(props.listState);
  }, [props.listState]);





  useEffect(() => {
    savePriliminaryData();
  }, [twoDigit, clientId]);


  useEffect(() => {
    if (window.location.pathname == "/projects/create") {
      // setLastJobNumber();
    }
  }, []);

  // const getClientsName = async () => {
  //   var clients = [];
  //   let returnData = await HttpService.getByBoj('getallclients', {}).then(res => {
  //     if (res.data.length > 0) {
  //       res.data.map((client, i) => {

  //         clients.push({ 'name': client.clientName, 'id': client._id, Services: client.Services });
  //       })
  //       setClients(clients);
  //     }
  //   });

  // }



  const setLastJobNumber = async () => {

    let returnData = await HttpService.getByBoj('getlastdeal', {})
      .then(res => {

        if (res.data.length > 0) {
          return res.data[0].ProjectNumber;
        } else {
          return null;
        }
      });
    var projectNum = '';

    if (returnData != null) {
      var lastyear = parseInt(returnData.slice(1, 3));
      if (twoDigitYear != lastyear) {
        projectNum = "S" + twoDigitYear + "-0100";

      } else {

        var number = parseInt(returnData.slice(4));
        number += 1;
        var prepended_number = String(number).padStart(4, '0')
        projectNum = "S" + twoDigitYear + "-" + prepended_number;

      }

    } else {
      projectNum = "S" + twoDigitYear + "-0100";
    }
    if (props.func != 'create') {
      settwoDigit(props.data.ProjectNumber)
    } else {
      props.getProjectNumber(projectNum)
      settwoDigit(projectNum)
    }

  }
  //hrushikesh shelke new code
  const [skipNycFlag,setSkipNycFlag]=useState(false);
  const saveCancelButtonRef = useRef();
  useImperativeHandle(ref, () => ({
    savePreliminary() {
      ableNextButton();
    }
  }));
  //hrushikesh shelke new code
  const ableNextButton = () => {
    if (
      formObj.BasicInformationComplete &&
      formObj.propertInformationComplete &&
      formObj.servicesComplete
    ) {
      savePriliminaryData();
      props.updateTheSidebarState(
        {type:"Preliminary",
         skipNycFlag: props.sidebarCardsStatus.SkipNycFlag,
         status:true
        })
      localStorage.setItem('currentsidebar',skipNycFlag?'Homeowner':'NycProject');
      // if(skipNycFlag){
      //   props.updateTheSidebarState({type:"NycProject",status:true})
      // }else{
      //   props.updateTheSidebarState({type:"NycProject",status:false})
      // }
      if (NYCOption == "Yes") {
        props.setLayoutState("nycproject");
        props.setListState("nycproperty");
      } else {
        props.setLayoutState("homeowner");
        props.setListState("homeownerinfo");
      }

      return true;
    } else {
      alert("Please check all checkboxes of current Section.(It should be checked for next form.)");
      return false;
    }

  };

  const savePriliminaryData = () => {
    const data = {

    }
    var preliminaryData = JSON.parse(localStorage.getItem("preliminayData"));
    var nycData = JSON.parse(localStorage.getItem("nycData"));
    var homeownerData = JSON.parse(localStorage.getItem("homeownerData"));
    var permittingData = JSON.parse(localStorage.getItem("permittingData"));
    var pvsystemData = JSON.parse(localStorage.getItem("pvSystemData"));

    const mergedObject = {
      ...data,
      ...nycData,
      ...homeownerData,
      ...permittingData,
      ...pvsystemData,
    };
    console.log(data)
    localStorage.setItem("preliminayData", JSON.stringify(data));
  }




  const updateData = async (data) => {
    let returnData = await HttpService.CreateUpdate('updatedealbyprojectno', data).then(res => {
      props.refreshDeal(data.ProjectNumber);
    })
      .catch((err) => {
        // alert("Unable to update deal. Check your Network.");
      });
  }

  return (
    <>
      <div className="boxplate">
        <div className="boxplate-heading" style={{display: "flex"}}>
          <div className='sectitle'>Preliminary</div>
           {/* <div className='zolabisbtn'> */}
            {/* <button className='nxtBtn' onClick={()=>ableNextButton()}>Next</button> */}
           {/* </div> */}
          </div>
        <div className="pad-15 createdeal-custom-scroll">
          <BasicInformation isEditDeal={isEditDeal}  {...basicProps} func={props.func} getProjectNumber={props.getProjectNumber} handleOnChangeDeal={props.handleOnChangeDeal} data={props.data} />
          <PropertyInformation 
            updateTheSidebarState={props.updateTheSidebarState}
            sidebarCardsStatus={props.sidebarCardsStatus}
            setSkipNycFlag={setSkipNycFlag}
            skipNycFlag={skipNycFlag}
            buttonPosition={props.buttonPosition} addressValChange={props.addressValChange}
            setData={props.setData}
            allDeals={allDeals}
            setAddressValChange={props.setAddressValChange} isNycVisible={props.isNycVisible} func={props.func} handleOnChangeDeal={props.handleOnChangeDeal} setNycVisible={props.setNycVisible} {...propertyProps} data={props.data} />
          <ServiceRequested {...serviceProps} setData={props.setData} data={props.data} func={props.func} handleOnChangeDeal={props.handleOnChangeDeal} />
          {/* <Row>
            <Col sm={10}></Col>
            <Col sm={2} className="nextbtn-box-pad">
              <Button
                className="next-button next-btn3D"
                // disabled={!formObj.BasicInformationComplete}
                onClick={() => {
                  ableNextButton();
                }}
              >
                {" "}
                Next
              </Button>
            </Col>
          </Row> */}
        </div>
      </div>
    </>
  );
});

export default Preliminary;
