import React,{useState, useEffect,useRef,forwardRef,useImperativeHandle} from 'react';
import HomeownerInfo from './homeownerinfo/homeownerinfo'
import IndividualOwner from './individualowner/individualowner'
import IndividualOwnerMail from './individualownermail/individualownermail'
import OtherOwnerInfo from './otherownerinfo/otherownerinfo'
import CondoSecondOfficer from './condosecondofficer/condosecondofficer'
import HttpService from '../../../../../services/http.service';
import { Button } from 'reactstrap';

const Homeowner= forwardRef((props,ref)=> {

  const homeObj ={
    homeOwnerInfo : false,
    individualOwner : false,
    individualOwnerMail : false,
    otherownerinfo : false,
    condoSecond : false
  }

    const [toggleState, settoggleState] = useState('homeownerinfo');
    
//-------------------------------------------homeownerinfo State-------------------------------------
const [homeOwnerName, setHomeOwnerName] = useState("");
const [ownerType, setOwnerType] = useState("INDIVIDUAL");


let homeOwnerInfoProps={
  homeOwnerName:homeOwnerName, setHomeOwnerName:setHomeOwnerName,
  ownerType:ownerType, setOwnerType:setOwnerType,
  toggleState:toggleState, settoggleState:settoggleState,
  setListState:props.setListState,setHomeComplete:homeObj,
  DisablesetownerType:props.setownerType
}

//----------------------------------individual Owner info State----------------------------------------
const [mailAddDifferent, setMailAddDifferent] = useState(false);
const [phoneNumber, setPhoneNumber] = useState("");
const [emailAdd, setEmailAdd]=useState("");
const [OwnerFrstNme, setOwnerFrstNMe] = useState("");
const [ownerMiddle, setOwnerMiddle] = useState("");
const [ownerlastname, setOwnerLastName] = useState("");

let individualOwnerInfoProps={
  setMailAddressDisable:props.setMailAddressDisable,mailAddressDisable:props.mailAddressDisable,
phoneNumber:phoneNumber, setPhoneNumber:setPhoneNumber,
emailAdd:emailAdd, setEmailAdd:setEmailAdd,
OwnerFrstNme:OwnerFrstNme, setOwnerFrstNMe:setOwnerFrstNMe,
ownerMiddle:ownerMiddle, setOwnerMiddle:setOwnerMiddle,
ownerlastname:ownerlastname, setOwnerLastName:setOwnerLastName,
  mailAddDifferent:mailAddDifferent, 
  setMailAddDifferent:setMailAddDifferent,
  toggleState:toggleState, settoggleState:settoggleState, 
  setListState:props.setListState, setIndividualComplete:homeObj,
  ownerType:ownerType
}

//----------------------------------individual Owner Mailing State----------------------------------------
const [MailingAdd, setMailingAdd] = useState("");
const [center, setCenter] = useState({lat: 36.1124573, lng: -115.1725102});
const [streetName, setstreetName] = useState("");
const [cityName, setcityName] = useState("");
const [stateName, setstateName] = useState("");
const [zipCode, setzipCode] = useState("");
const [houseNo, sethouseNo] = useState("");
const[streetAdd, setStreetAdd] = useState("");

let individualOwnerMailing ={
  setMailAddressDisable:props.setMailAddressDisable,mailAddressDisable:props.mailAddressDisable,
  MailingAdd:MailingAdd, setMailingAdd:setMailingAdd,
center:center, setCenter:setCenter,
streetName:streetName, setstreetName:setstreetName,
cityName:cityName, setcityName:setcityName,
stateName:stateName, setstateName:setstateName,
zipCode:zipCode, setzipCode:setzipCode,
houseNo:houseNo, sethouseNo:sethouseNo,
streetAdd:streetAdd, setStreetAdd:setStreetAdd,
toggleState:toggleState, settoggleState:settoggleState, 
setListState:props.setListState,setOwnerMailComplete:homeObj,
ownerType:ownerType
}

//----------------------------------Other Owner Info----------------------------------------

const [dhcr, setDHCR] = useState("No");
const [nonProfit, setNonProfit] = useState("No");
const [otherAdd, setOtherAdd] = useState("");
const [othercenter, setOtherCenter] = useState({lat: 36.1124573, lng: -115.1725102});
const [otherstreetName, setOtherstreetName] = useState("");
const [otherltitude, setOtherltitude] = useState("");
const [otherlngitude, setOtherlngitude] = useState("");
const [othercityName, setOthercityName] = useState("");
const [otherstateName, setOtherstateName] = useState("");
const [otherzipCode, setOtherzipCode] = useState("");
const [otherhouseNo, setOtherhouseNo] = useState("");
const [otherphoneNumber, setOtherPhoneNumber] = useState("");
const [othercompnyNme, setOtherCompnyNme]= useState("");
const [offcrFrstNme, setOffcrFrstNme]= useState("");
const [offcrLast, setOffcrLast] = useState("");
const [offcrMiddle, setOffcrMiddle] = useState("");
const [relatnOwner, setRelatnOwner] = useState("");
const [otheremailAdd, setOtherEmailAdd]=useState("");

let otherOwnerInfoProps={
  dhcr:dhcr, setDHCR:setDHCR,
  nonProfit:nonProfit, setNonProfit:setNonProfit,
  otherAdd:otherAdd, setOtherAdd:setOtherAdd,
  othercenter:othercenter, setOtherCenter:setOtherCenter,
  otherstreetName:otherstreetName, setOtherstreetName:setOtherstreetName,
  otherltitude:otherltitude, setOtherltitude:setOtherltitude,
  otherlngitudelngitude:otherlngitude, setOtherlngitude:setOtherlngitude,
  othercityName:othercityName, setOthercityName:setOthercityName,
  otherstateName:otherstateName, setOtherstateName:setOtherstateName,
  otherzipCode:otherzipCode, setOtherzipCode:setOtherzipCode,
  otherhouseNo:otherhouseNo, setOtherhouseNo:setOtherhouseNo,
  otherphoneNumber:otherphoneNumber, setOtherPhoneNumber:setOtherPhoneNumber,
  othercompnyNme:othercompnyNme, setOtherCompnyNme:setOtherCompnyNme,
  offcrFrstNme:offcrFrstNme, setOffcrFrstNme:setOffcrFrstNme,
  offcrLast:offcrLast, setOffcrLast:setOffcrLast,
  offcrMiddle:offcrMiddle, setOffcrMiddle:setOffcrMiddle,
  relatnOwner:relatnOwner, setRelatnOwner:setRelatnOwner,
  otheremailAdd:otheremailAdd, setOtherEmailAdd:setOtherEmailAdd,
  toggleState:toggleState, settoggleState:settoggleState,
  setListState:props.setListState,setOtherComplete:homeObj,
  ownerType:ownerType
}

//--------------------------------------CONDO officer state-------------------------------------

const [condoAdd, setCondoAdd] = useState("");
const [condocenter, setCondoCenter] = useState({lat: 36.1124573, lng: -115.1725102});
const [condostreetName, setCondostreetName] = useState("");
const [condoltitude, setCondoltitude] = useState("");
const [condolngitude, setCondolngitude] = useState("");
const [condocityName, setCondocityName] = useState("");
const [condostateName, setCondostateName] = useState("");
const [condozipCode, setCondozipCode] = useState("");
const [condohouseNo, setCondohouseNo] = useState("");
const [condophoneNumber, setCondoPhoneNumber] = useState("");
const [condoemailAdd, setCondoEmailAdd]=useState("");
const [condocompnyNme, setCondoCompnyNme] = useState("");
const [condooffcrFrstNme, setCondoOffcrFrstNme]= useState("");
const [condooffcrLast, setCondoOffcrLast] = useState("");
const [condooffcrMiddle, setCondoOffcrMiddle] = useState("");
const [condorelatnOwner, setCondoRelatnOwner] = useState("");

let condoofficerProps={
condoAdd:condoAdd, setCondoAdd:setCondoAdd,
condocenter:condocenter, setCondoCenter:setCondoCenter,
condostreetName:condostreetName, setCondostreetName:setCondostreetName,
condoltitude:condoltitude, setCondoltitude:setCondoltitude,
condolngitude:condolngitude, setCondolngitude:setCondolngitude,
condocityName:condocityName, setCondocityName:setCondocityName,
condostateName:condostateName, setCondostateName:setCondostateName,
condozipCode:condozipCode, setCondozipCode:setCondozipCode,
condohouseNo:condohouseNo, setCondohouseNo:setCondohouseNo,
condophoneNumber:condophoneNumber, setCondoPhoneNumber:setCondoPhoneNumber,
condoemailAdd:condoemailAdd, setCondoEmailAdd:setCondoEmailAdd,
condocompnyNme:condocompnyNme, setCondoCompnyNme:setCondoCompnyNme,
condooffcrFrstNme:condooffcrFrstNme, setCondoOffcrFrstNme:setCondoOffcrFrstNme,
condooffcrLast:condooffcrLast, setCondoOffcrLast:setCondoOffcrLast,
condooffcrMiddle:condooffcrMiddle, setCondoOffcrMiddle:setCondoOffcrMiddle,
condorelatnOwner:condorelatnOwner, setCondoRelatnOwner:setCondoRelatnOwner,
toggleState:toggleState, settoggleState:settoggleState,
   setListState:props.setListState,setCondoComplete:homeObj,
   ownerType:ownerType
}

    useEffect(() => {
        settoggleState(props.listState);
      },[props.listState]);

    //hrushikesh shelke new code
      const saveCancelButtonRef = useRef();
      useImperativeHandle(ref, () => ({
        saveHomeowner() {
          ableNextButton();
        }
      }));
   //hrushikesh shelke new code
      const ableNextButton=()=>{
        if(ownerType=='INDIVIDUAL'){
          homeObj.otherownerinfo=true;
          homeObj.condoSecond=true;
          homeObj.individualOwnerMail=true;
        }else if(ownerType=='CONDO/CO-OP'){
          homeObj.individualOwner=true;
          homeObj.individualOwnerMail=true;
        }else{
          homeObj.condoSecond=true;
          homeObj.individualOwner=true;
          homeObj.individualOwnerMail=true;
        }
        if(homeObj.homeOwnerInfo && homeObj.individualOwner && homeObj.individualOwnerMail && homeObj.otherownerinfo && 
          homeObj.condoSecond)
          {
            saveHomeownerData();
            props.updateTheSidebarState({type:"Homeowner",status:true})
            localStorage.setItem('currentsidebar',"Permitting");
          props.setLayoutState("permitting");
          props.setListState("permittinginfo");
          return true;
        }else{
          alert("Please check all checkboxes of current Section.(It should be checked for next form.)");
            return false;
         
        }
      }

      const updateData = async (data) => {
        let returnData = await HttpService.CreateUpdate('updatedealbyprojectno', data).then(res => {
          props.refreshDeal(data.ProjectNumber);
          //alert("Deal updated successfully.");
        })
          .catch((err) => {
           // alert("Unable to update deal. Check your Network.");
          });
      }
      
      const saveHomeownerData = () => {
         
        const data={
          "homeOwnerName":homeOwnerName,
          "ownerType":ownerType,
          "mailAddDifferent":mailAddDifferent,
          "phoneNumber":phoneNumber,
          "emailAdd":emailAdd,
          "OwnerFrstNme":OwnerFrstNme,
          "ownerMiddle":ownerMiddle,
          "ownerlastname":ownerlastname,
          "MailingAdd":MailingAdd,
          "center":center,
          "streetName":streetName,
          "cityName":cityName,
          "stateName":stateName,
          "zipCode":zipCode,
          "houseNo":houseNo,
          "streetAdd":streetAdd,
          "dhcr":dhcr,
          "nonProfit":nonProfit,
          "otherAdd":otherAdd,
          "othercenter":othercenter,
          "otherstreetName":otherstreetName,
          "otherltitude":otherltitude, 
          "otherlngitude":otherlngitude,
          "othercityName":othercityName,
          "otherstateName":otherstateName,
          "otherzipCode":otherzipCode,
          "otherhouseNo":otherhouseNo,
          "otherphoneNumber":otherphoneNumber,
          "othercompnyNme":othercompnyNme,
          "offcrFrstNme":offcrFrstNme,
          "offcrLast":offcrLast,
          "offcrMiddle":offcrMiddle,
          "relatnOwner":relatnOwner,
          "otheremailAdd":otheremailAdd,
          "condoAdd":condoAdd,
          "condocenter":condocenter,
          "condostreetName":condostreetName,
          "condoltitude":condoltitude,
          "condolngitude":condolngitude,
          "condocityName":condocityName,
          "condostateName":condostateName,
          "condozipCode":condozipCode,
          "condohouseNo":condohouseNo,
          "condophoneNumber":condophoneNumber,
          "condoemailAdd":condoemailAdd,
          "condocompnyNme":condocompnyNme,
          "condooffcrFrstNme":condooffcrFrstNme,
          "condooffcrLast":condooffcrLast,
          "condooffcrMiddle":condooffcrMiddle,
          "condorelatnOwner":condorelatnOwner
        }

        var preliminaryData = JSON.parse(localStorage.getItem("preliminayData"));
    var nycData = JSON.parse(localStorage.getItem("nycData"));
    var homeownerData = JSON.parse(localStorage.getItem("homeownerData"));
    var permittingData = JSON.parse(localStorage.getItem("permittingData"));
    var pvsystemData = JSON.parse(localStorage.getItem("pvSystemData"));

 const mergedObject = {
      ...preliminaryData,
      ...nycData,
      ...data,
      ...permittingData,
      ...pvsystemData,
    };
    localStorage.setItem("homeownerData", JSON.stringify(data));
      }

      let borough;
      if(props.data.Borough == "Manhattan" || props.data.Borough == "Bronx" || props.data.Borough == "Brooklyn"|| props.data.Borough == "Queens"|| props.data.Borough == "Staten Island"){
        if(props.data.Borough == "Manhattan"){
          borough = "1"
        }else if(props.data.Borough == "Bronx"){
          borough = "2"
        }else if(props.data.Borough == "Brooklyn"){
          borough = "3"
        }else if(props.data.Borough == "Queens"){
          borough = "4"
        }else{
          borough = "5"
        }
      }else{
        borough = props.data.Borough
      }

     // var acrisLink =`http://a836-acris.nyc.gov`;
     let acrisLink =`http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=${borough}&block=${props.data.blockNo}&lot=${props.data.lotNo}`;

    return (
      <div className="boxplate">
        <div className="boxplate-heading" style={{ display: "flex" }}>
          <div className='sectitle w-100'>Property Owner</div>
          <div className='zolabisbtn'>
            <a href={acrisLink} target="_blank" rel="noopener noreferrer" className='acris-link'><Button color='primary' className="zolaBtn">ACRIS</Button></a>
           {/* <button className='nxtBtn' onClick={()=>ableNextButton()}>Next</button> */}
          </div>
        </div>
        <div className="pad-15 createdeal-custom-scroll">
          <HomeownerInfo
            {...homeOwnerInfoProps}
            data={props.data}
            handleOnChangeDeal={props.handleOnChangeDeal}
          />
          <IndividualOwner
            {...individualOwnerInfoProps}
            data={props.data}
            handleOnChangeDeal={props.handleOnChangeDeal}
          />
          <IndividualOwnerMail
            {...individualOwnerMailing}
            data={props.data}
            handleOnChangeDeal={props.handleOnChangeDeal}
          />
          <OtherOwnerInfo
            {...otherOwnerInfoProps}
            data={props.data}
            sidebarCardsStatus={props.sidebarCardsStatus}
            updateTheSidebarState={props.updateTheSidebarState}
            handleOnChangeDeal={props.handleOnChangeDeal}
          />
          <CondoSecondOfficer
            {...condoofficerProps}
            data={props.data}
            handleOnChangeDeal={props.handleOnChangeDeal}
          />
          {/* <Row>
            <Col sm={10}></Col>
            <Col sm={2} className="nextbtn-box-pad">
              {" "}
              <Button
                className="next-button next-btn3D"
                onClick={() => ableNextButton()}
              >
                {" "}
                Next{" "}
              </Button>
            </Col>
          </Row> */}
        </div>
      </div>
    );
})

export default Homeowner;