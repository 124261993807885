import React, { useState, useEffect } from "react";
import {
  Collapse,
  Button,
  CardBody,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ClientModal from "../ClientModal/ClientModal";
import Config from '../../../../../../config.json'
import CustumSelect from "../../../../../../common/SelectWithSearch";
import axios from "axios";

const BasicInformation = (props) => {
  const [skipBasicinfo, setSkipBasicinfo] = useState(false);
  const [basicinfotogglestate, setBasicinfotoggle] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [backdrop, setBackdrop] = useState("static");
  const [keyboard, setKeyboard] = useState(true);
  const[clientSelected,setClientSelected]=useState('0');

  const clientToggle = () => {
    setClientModal(!clientModal)
  }
  const basicinfotoggle = () => {
    setBasicinfotoggle(!basicinfotogglestate);
    props.settoggleState("basic");
    props.setListState('basic');
  };

  useEffect(() => {
    props.toggleState == "basic"
      ? setBasicinfotoggle(true)
      : setBasicinfotoggle(false);
  },[props.toggleState]);

  const handleBasicInfoCheck = () => {
    if (skipBasicinfo) {
      props.setBasicInfoComplete.BasicInformationComplete = true;
      return true;
    }
    if (props?.twoDigit && props?.startDate && props?.data?.Client) {
      props.setBasicInfoComplete.BasicInformationComplete = true;
      return true;
    }
    return false;

  };

  const changeDate = (date, name) => {
    console.log("date", date);
    props.setStartDate(date)
    const formattedDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)
    props.setProjectDate(formattedDate)
    props.handleOnChangeDeal({ currentTarget: { name: name, value: date } });

  }

  const clientModalToggle = () => {
    setClientModal(!clientModal);
  }

  const changePage = (value) => {
    if (value === "AddClient") {
      if (props.isAccess && props.isAccess(props.selectedRole, 'Clients', 'Write')) {
        clientModalToggle();
        props.setClient("");
      } else {
        // swal("you don't have sufficient privileges to perform this task!",{dangerMode:true});
      }
    }
    else {
      props.setClient(value)
    }
  }

  const getClientById=async  (id)=>{
    const backendUrl = Config.backend_url+"workdrive";
    var config = {
      Type: "Get",
      url: "https://www.zohoapis.com/crm/v2/Accounts/"+id,
      method: "GET",
      headers: {
        'Authorization': localStorage.getItem('token')
      }
    };
    let res=await axios.post(backendUrl, { config: {...config} })
    return res.data
    
  }

  const changeValueOfClient = async (e) => {
     
    let filterClient = props.clients.filter((one) => one.name == e);
    let services=[];
    filterClient=await getClientById(filterClient[0].id);
    filterClient=filterClient.data;
    console.log(filterClient);
    console.log(filterClient[0].Subform_2);
    
    if(filterClient[0].Subform_2){
      filterClient[0].Subform_2.forEach(one=>{
        services.push(one.Service);
       })
    }
    props.handleOnChangeDeal({ currentTarget: { name: 'Client', value: {name:e,id:filterClient[0].id,module:"Accounts" },services:services} })
  }

  return (
    <Col sm={12}>
      <Button
        onClick={basicinfotoggle}
        className={basicinfotogglestate ? "btn-100-active" : "btn-100"}
      >
        Basic Information
        <div className="float-right">
          <Input
            type="checkbox"
            className={
              handleBasicInfoCheck()
                ? "rightbutton-checkbox-active"
                : "rightbutton-checkbox"
            }
            checked={handleBasicInfoCheck()}
            onChange={() => {
              if (
                props.data.Client == undefined ||
                props.data.Client.name == "" ||
                props.data.Client.name == undefined
              ) {
                setClientSelected("-1");
              }
              setSkipBasicinfo(!skipBasicinfo);
            }}
          />
          {basicinfotogglestate ? (
            <FontAwesomeIcon className="font-centered" icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon className="font-centered" icon={faAngleRight} />
          )}
        </div>
      </Button>
      <Collapse isOpen={basicinfotogglestate}>
        <Card className="createdeal-card">
          <CardBody>
            <FormGroup className="marb-16 ">
              <Row>
                <Col sm={2}>
                  <Label className="text-bold" for="projectdate">
                    Project Date<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <DatePicker
                    className="createdeal-textfield form-control"
                    name="ProjectDate"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    selected={
                      props.data ? new Date(props.data.ProjectDate) : new Date()
                    }
                    id="ProjectDate"
                    onChange={(e) => changeDate(e, "ProjectDate")}
                    dateFormat="MM/dd/yyyy"
                  />
                </Col>
                <Col sm={7}></Col>
              </Row>
            </FormGroup>
            <FormGroup className="marb-16 ">
              <Row>
                <Col sm={2}>
                  {" "}
                  <Label className="text-bold" for="client">
                    Client<span className="red-text">*</span>
                  </Label>
                </Col>
                <Col sm={3}>
                  <CustumSelect
                    addFlag={true}
                    placeholder={"Select Client"}
                    value={
                      props.data.Client ? props.data.Client.name : undefined
                    }
                    Color={clientSelected}
                    onChange={(val) => {
                      if (val != "") {
                        setClientSelected("1");
                      } else {
                        setClientSelected("-1");
                      }
                      changePage(val);
                      changeValueOfClient(val);
                    }}
                    onBlur={(e) => {
                      if (props.data.Client?.length == 0) {
                        setClientSelected("-1");
                      } else {
                        setClientSelected("1");
                      }
                    }}
                    addItemLabel={" Add Client"}
                    addItemAction={clientModalToggle}
                    items={props.clientNames}
                  />
                  <ClientModal
                    clients={props.clients}
                    clientNames={props.clientNames}
                    handleOnChangeDeal={props.handleOnChangeDeal}
                    setClientId={props.setClientId}
                    setClient={props.setClient}
                    getClientsName={props.getClientsName}
                    clientToggle={clientToggle}
                    clientModal={clientModal}
                    backdrop={backdrop}
                    keyboard={keyboard}
                  />
                </Col>
                <Col sm={7}></Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Collapse>
    </Col>
  );
};

export default BasicInformation;
