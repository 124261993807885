import dayjs from "dayjs";

let dateTimeObject = {}

dateTimeObject.sortDataAsc = function sortDataAsc(data) {
    if (data.length > 0) {
        data.sort((a, b) => new Date(a.date) - new Date(b.date))
        return data;
    }
    return data;
}

dateTimeObject.shiftDate = function shiftDate(start, end, shiftType, rangeType) {
    start = new Date(start);
    end = new Date(end);
    if (rangeType == 'Today' || rangeType == 'Yesterday') {
        if (shiftType == 'back') {
            start.setDate(start.getDate() - 1);
            end.setDate(end.getDate() - 1);
        }
        if (shiftType == 'next') {
            start.setDate(start.getDate() + 1);
            end.setDate(end.getDate() + 1);
        }
    }
    else if (rangeType == 'This Week' || rangeType == 'Last Week') {
        if (shiftType == 'back') {
            start = new Date(dayjs(start).subtract(1, 'weeks').startOf('week'))
            end = new Date(dayjs(end).subtract(1, 'weeks').endOf('week'))
        }
        if (shiftType == 'next') {
            start = new Date(dayjs(start).add(1, 'weeks').startOf('week'))
            end = new Date(dayjs(end).add(1, 'weeks').endOf('week'))
        }
    }
    else if (rangeType == 'Last Two Week') {
        if (shiftType == 'back') {
            start = new Date(dayjs(start).subtract(1, 'weeks').startOf('week'))
            end = new Date(dayjs(end).subtract(1, 'weeks').endOf('week'))
        }
        if (shiftType == 'next') {
            start = new Date(dayjs(start).add(1, 'weeks').startOf('week'))
            end = new Date(dayjs(end).add(1, 'weeks').endOf('week'))
        }
    }
    else if (rangeType == 'This Month' || rangeType == 'Last Month') {
        if (shiftType == 'back') {
            start = new Date(dayjs(start).subtract(1, 'months').startOf('month'))
            end = new Date(dayjs(end).subtract(1, 'months').endOf('month'))
        }
        if (shiftType == 'next') {
            start = new Date(dayjs(start).add(1, 'months').startOf('month'))
            end = new Date(dayjs(end).add(1, 'months').endOf('month'))
        }
    }
    else if (rangeType == 'Last Two Month') {
        if (shiftType == 'back') {
            start = new Date(dayjs(start).subtract(1, 'months').startOf('months'))
            end = new Date(dayjs(end).subtract(1, 'months').endOf('months'))
        }
        if (shiftType == 'next') {
            start = new Date(dayjs(start).add(1, 'months').startOf('months'))
            end = new Date(dayjs(end).add(1, 'months').endOf('months'))
        }
    }
    else if (rangeType == 'This Year' || rangeType == 'Last Year') {
        if (shiftType == 'back') {
            start = new Date(dayjs(start).subtract(1, 'years').startOf('year'))
            end = new Date(dayjs(end).subtract(1, 'years').endOf('year'))
        }
        if (shiftType == 'next') {
            start = new Date(dayjs(start).add(1, 'years').startOf('year'))
            end = new Date(dayjs(end).add(1, 'years').endOf('year'))
        }
    }
    else if (rangeType == 'Last Two Year') {
        if (shiftType == 'back') {
            start = new Date(dayjs(start).subtract(1, 'years').startOf('years'))
            end = new Date(dayjs(end).subtract(1, 'years').endOf('years'))
        }
        if (shiftType == 'next') {
            start = new Date(dayjs(start).add(1, 'years').startOf('years'))
            end = new Date(dayjs(end).add(1, 'years').endOf('years'))
        }
    }
   
    let arr = [];
    arr.push(start);
    arr.push(end);
    return arr;
}

function calculateQuatersAccordingToCurrentMonth(quater,number){
    let qStartMonth=1;
    let today=new Date()
    let month=today.getMonth()+1
    let qEndMonth=3;
    while(!(qStartMonth<=month && qEndMonth>=month )){
        qStartMonth+=3;
        qEndMonth+=3;
    }
    // if(quater=="Next Quarter"){
    //     qEndMonth=qEndMonth*number;
    // }else if(quater=="Last Quarter"){
    //     qStartMonth=qStartMonth*number;
    // }
    let startDate=qStartMonth+"/01/"+today.getFullYear();
    let endDate=qEndMonth+"/01/"+today.getFullYear();
    let finalStartDate=new Date(startDate)
    let finalEndDate=new Date(endDate)
    if(quater=="This Quarter"){
        let d={
            start:dayjs(finalStartDate).startOf('month'),
            end:dayjs(finalEndDate).endOf('month')
        }
        return d;
    }else if(quater=="Next Quarter"){
        let d={
            start:dayjs(finalStartDate).add(3,'month').startOf('month'),
            end:dayjs(finalEndDate).add(3*number,'month').endOf('month')
        }
        return d;
    }
    else if(quater=="Last Quarter"){
        let d= {
            start:dayjs(finalStartDate).subtract(3*number,'month').startOf('month'),
            end:dayjs(finalEndDate).subtract(3,'month').endOf('month')
        }
        return d;
    }
}

dateTimeObject.GetDateArr = function GetDateArr(start, end, rangeType,number) {
    rangeType=rangeType?.trim();
    start = new Date(start);
    end = new Date(end);
    start.setHours(0,0,0,0)
    end.setHours(0,0,0,0)
    if (rangeType == 'Today') {
        start.setDate(start.getDate());
        end.setDate(end.getDate());
    }
    else if (rangeType == 'Tommorrow') {
        start.setDate(start.getDate() + 1);
        end.setDate(end.getDate() + 1);
    }
    else if (rangeType == 'Yesterday') {
        start.setDate(start.getDate() - 1);
        end.setDate(end.getDate() - 1);
    }
    else if (rangeType == 'This Week') {
        start = new Date(dayjs(start).startOf('week'))
        end = new Date(dayjs(end).endOf('week'))
    }
    else if (rangeType == 'Last Week') {
        start = new Date(dayjs(start).subtract(1, 'weeks').startOf('week'))
        end = new Date(dayjs(end).subtract(1, 'weeks').endOf('week'))
    }
    else if (rangeType == 'Next Week') {
        start = new Date(dayjs(start).add(1, 'weeks').startOf('week'))
        end = new Date(dayjs(end).add(1, 'weeks').endOf('week'))
    }
    else if (rangeType == 'Last Two Week') {
        start = new Date(dayjs(start).subtract(2, 'weeks').startOf('week'))
        end = new Date(dayjs(end).subtract(2, 'weeks').endOf('week'))
    }
    else if (rangeType == 'This Month') {
        start = new Date(dayjs(start).startOf('month'))
        end = new Date(dayjs(end).endOf('month'))
    }
    else if (rangeType == 'Last Month') {
        start = new Date(dayjs(start).subtract(1, 'months').startOf('month'))
        end = new Date(dayjs(end).subtract(1, 'months').endOf('month'))
    }
    else if (rangeType == 'Next Month') {
        start = new Date(dayjs(start).add(1, 'months').startOf('month'))
        end = new Date(dayjs(end).add(1, 'months').endOf('month'))
    } else if (rangeType == 'This Month') {
        start = new Date(dayjs(start).startOf('month'))
        end = new Date(dayjs(end).endOf('month'))
    }
    else if (rangeType == 'Last Quarter') {
        
        let dateRange= calculateQuatersAccordingToCurrentMonth(rangeType,number?number:1)
        start = dateRange.start
        end = dateRange.end
    }
    else if (rangeType == 'Next Quarter') {
        
        let dateRange= calculateQuatersAccordingToCurrentMonth(rangeType,number?number:1)
        start = dateRange.start
        end = dateRange.end
    }
    else if (rangeType == 'This Quarter') {
        
        let dateRange= calculateQuatersAccordingToCurrentMonth(rangeType,number?number:1)
        start = dateRange.start
        end = dateRange.end
    }
    else if (rangeType == 'Last Year') {
        start = new Date(dayjs(start).subtract(1, 'years').startOf('year'))
        end = new Date(dayjs(end).subtract(1, 'years').endOf('year'))
    }
    else if (rangeType == 'Next Year') {
        start = new Date(dayjs(start).add(1, 'years').startOf('year'))
        end = new Date(dayjs(end).add(1, 'years').endOf('year'))
    }
    
   
    let arr = [];
    arr.push(start);
    arr.push(end);
    return arr;
}

dateTimeObject.getRangeType = function getRangeType(start, end, allStart, allEnd) { 
    if ((new Date(dayjs()).toDateString() == start.toDateString()) && (new Date(dayjs()).toDateString() == end.toDateString())) {
        return 'Today'
    }
    if ((new Date(dayjs().add(-1, 'days')).toDateString() == start.toDateString()) && (new Date(dayjs().add(-1, 'days')).toDateString() == end.toDateString())) {
        return 'Yesterday'
    }
    else if ((new Date(dayjs().startOf('week')).toDateString() == start.toDateString()) && (new Date(dayjs().endOf('week')).toDateString() == end.toDateString())) {
        return 'This Week'
    }
    else if ((new Date(dayjs().subtract(1, 'weeks').startOf('week')).toDateString() == start.toDateString()) && (new Date(dayjs().subtract(1, 'weeks').endOf('week')).toDateString() == end.toDateString())) {
        return 'Last Week'
    }
    else if ((new Date(dayjs().subtract(2, 'weeks').startOf('week')).toDateString() == start.toDateString()) && (new Date(dayjs().subtract(1, 'weeks').endOf('week')).toDateString() == end.toDateString())) {
        return 'Last Two Week'
    }
    else if ((new Date(dayjs().startOf('month')).toDateString() == start.toDateString()) && (new Date(dayjs().endOf('month')).toDateString() == end.toDateString())) {
        return 'This Month'
    }
    else if ((new Date(dayjs().subtract(1, 'months').startOf('month')).toDateString() == start.toDateString()) && (new Date(dayjs().subtract(1, 'months').endOf('month')).toDateString() == end.toDateString())) {
        return 'Last Month'
    }
    else if ((new Date(dayjs().subtract(2, 'months').startOf('month')).toDateString() == start.toDateString()) && (new Date(dayjs().subtract(1, 'months').endOf('month')).toDateString() == end.toDateString())) {
        return 'Last Two Month'
    }
    else if ((new Date(dayjs().startOf('year')).toDateString() == start.toDateString()) && (new Date(dayjs().endOf('year')).toDateString() == end.toDateString())) {
        return 'This Year'
    }
    else if ((new Date(dayjs().subtract(1, 'years').startOf('year')).toDateString() == start.toDateString()) && (new Date(dayjs().subtract(1, 'years').endOf('year')).toDateString() == end.toDateString())) {
        return 'Last Year'
    }
    else if ((new Date(dayjs().subtract(2, 'years').startOf('year')).toDateString() == start.toDateString()) && (new Date(dayjs().subtract(1, 'years').endOf('year')).toDateString() == end.toDateString())) {
        return 'Last Two Year'
    }
    else if ((new Date(allStart).toDateString() == start.toDateString()) && (new Date(allEnd).toDateString() == end.toDateString())) {
        return 'All'
    } else {
        return ''
    }
}

export default dateTimeObject;