import httpService from "../../services/http.service";
import Config from "../../config.json"
import axios from "axios";

class AttendenceService{
    CreateAttendence(checkIn,chekcOut,EmailId,payload) {   
        // return new Promise((resolve, reject) => {
        //     let config = {
        //         Type: 'Create',
        //         method: 'POST',
        //         url: "https://people.zoho.com/people/api/attendance?dateFormat=dd/MM/yyyy HH:mm:ss&checkIn="+checkIn+"&checkOut="+chekcOut+"&emailId="+EmailId,
        //         headers: { "Authorization": localStorage.getItem('token') },
        //         data: {}
        //     }
        //     httpService.post('workdrive', {config:{...config}})
        //     .then((result) => {
        //         resolve(result);
        //     })
        //     .catch((err) => {
        //         reject(err);
        //     })
        // })
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Post',
                method: 'POST',
                //url: "https://people.zoho.com/people/api/attendance?dateFormat=dd/MM/yyyy HH:mm:ss&checkIn="+checkIn+"&checkOut="+chekcOut+"&emailId="+EmailId,
                //headers: { "Authorization": localStorage.getItem('token') },
                data: payload
            }
            httpService.post('attendance', {config:{...config}})
            .then((result) => {  
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    
    }
    UpdateAttendence(ROWID,payload) {   
        if(ROWID==undefined){
            return
        }
        payload={ROWID:payload.ROWID, ...payload}
        // return new Promise((resolve, reject) => {
        //     let config = {
        //         Type: 'Create',
        //         method: 'POST',
        //         url: "https://people.zoho.com/people/api/attendance?dateFormat=dd/MM/yyyy HH:mm:ss&checkIn="+checkIn+"&checkOut="+chekcOut+"&emailId="+EmailId,
        //         headers: { "Authorization": localStorage.getItem('token') },
        //         data: {}
        //     }
        //     httpService.post('workdrive', {config:{...config}})
        //     .then((result) => {
        //         resolve(result);
        //     })
        //     .catch((err) => {
        //         reject(err);
        //     })
        // })
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                method: 'POST',
                //url: "https://people.zoho.com/people/api/attendance?dateFormat=dd/MM/yyyy HH:mm:ss&checkIn="+checkIn+"&checkOut="+chekcOut+"&emailId="+EmailId,
                //headers: { "Authorization": localStorage.getItem('token') },
                data: payload
            }
            httpService.post('attendance', {config:{...config}})
            .then((result) => {  
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    
    }
    DeleteAttendance(ROWIDS) {  
        if(ROWIDS && ROWIDS.length<=0 ){
            return
        }
        // return new Promise((resolve, reject) => {
        //     let config = {
        //         Type: 'Create',
        //         method: 'POST',
        //         url: "https://people.zoho.com/people/api/attendance?dateFormat=dd/MM/yyyy HH:mm:ss&checkIn="+checkIn+"&checkOut="+chekcOut+"&emailId="+EmailId,
        //         headers: { "Authorization": localStorage.getItem('token') },
        //         data: {}
        //     }
        //     httpService.post('workdrive', {config:{...config}})
        //     .then((result) => {
        //         resolve(result);
        //     })
        //     .catch((err) => {
        //         reject(err);
        //     })
        // })
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Delete',
                method: 'POST',
                //url: "https://people.zoho.com/people/api/attendance?dateFormat=dd/MM/yyyy HH:mm:ss&checkIn="+checkIn+"&checkOut="+chekcOut+"&emailId="+EmailId,
                //headers: { "Authorization": localStorage.getItem('token') },
                data: ROWIDS
            }
            httpService.post('attendance', {config:{...config}})
            .then((result) => {  
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    
    }

    GetLastAttendence(Duration) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: "https://people.zoho.com/api/attendance/fetchLatestAttEntries?duration="+Duration+"&dateTimeFormat=dd-MM-yyyy HH:mm:ss",
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('workdrive', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }

    DeleteRequest(ROWIDS) { 
        if(ROWIDS && ROWIDS.length<=0 ){
            return
        }
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Delete',
                //url: "https://people.zoho.com/people/api/attendance?dateFormat=dd/MM/yyyy HH:mm:ss&checkIn="+checkIn+"&checkOut="+chekcOut+"&emailId="+EmailId,
                //headers: { "Authorization": localStorage.getItem('token') },
                data: ROWIDS
            }
            httpService.post('requestTime', {config:{...config}})
            .then((result) => {  
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            })
        })
    
    }


    GetSplitAttendence(date,emailid) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                url:"https://people.zoho.com/people/api/attendance/getAttendanceEntries?date="+date+"&emailId="+emailid,
                headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('chat', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    
    }


    GetAttendenceAllUser(Start,End,startIndex) {
        // return new Promise((resolve, reject) => {
        //     let config = {
        //         Type: 'Get',
        //         method: 'GET',
        //         url:"https://people.zoho.com/people/api/attendance/getUserReport?sdate="+Start+"&edate="+End+"&dateFormat=dd/MM/yyyy&startIndex="+startIndex,
        //         headers: { "Authorization": localStorage.getItem('token') },
        //         data: {}
        //     }
        //     httpService.post('workdrive', {config:{...config}})
        //         .then((result) => {
        //             resolve(result);
        //         })
        //         .catch((err) => {
        //             reject(err);
        //         })
        // })
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                //url:"https://people.zoho.com/people/api/attendance/getUserReport?sdate="+Start+"&edate="+End+"&dateFormat=dd/MM/yyyy&startIndex="+startIndex,
                //headers: { "Authorization": localStorage.getItem('token') },
                data: {}
            }
            httpService.post('attendance', {config:{...config}})
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    
    }

    CreateLeave(inputData) {
        const encodedData = encodeURIComponent(JSON.stringify(inputData));
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Create',
                method: 'POST',
                // url : "https://people.zoho.com/people/api/forms/json/leave/insertRecord?inputData={'Employee_ID':'3000000020481','Leavetype':'3000000046003','From':07-Feb-2022,'To':08-Feb-2022,'days':{'08-Feb-2022':{'LeaveCount':0.5, 'Session':2}}}",
                url: `https://people.zoho.com/people/api/forms/json/leave/insertRecord?inputData=${encodedData}`,
                headers: { "Authorization": localStorage.getItem('token') },
                // data: {}
            }
            httpService.post('workdrive', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetLeaveRecords(obj) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
                method: 'GET',
                url: `https://people.zoho.com/api/v2/leavetracker/leaves/records?from=${obj.from}&to=${obj.to}`,
                headers: { "Authorization": localStorage.getItem('token') },
            }
            httpService.post('workdrive', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetDeviceData() {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Get',
            }
            httpService.post('device', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    UpdateDeviceId(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Update',
                data:payload
            }
            httpService.post('device', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    CreateDeviceId(payload) {
        return new Promise((resolve, reject) => {
            let config = {
                Type: 'Post',
                data:payload
            }
            httpService.post('device', {config:config})
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    GetApsenceRecords(){
        return new Promise((resolve, reject) => {
            axios.get(Config.backend_url+'getAbsentRecords')
                .then((result) => {
                    resolve(result.data);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
}
export default new AttendenceService();