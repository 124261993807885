import React, { useContext, useEffect, useState } from "react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import {
    Col,
    Modal,
    ModalBody,
    Button,
    Row,
    ModalHeader,
    ModalFooter,
} from "reactstrap";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
// import "../users/userlist/userlist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { DatePicker as AntdDatePicker } from 'antd';
import 'antd/dist/reset.css';
import AntdRangePicker from "../../../common/DateRangePicker";
import CustumSelect from "../../../common/SelectWithSearch";
import ShiftServices from "../../../common/util/ShiftServices";
import { GlobalAgGridTable } from "../../Deal/AgGridReactComponent/GlobalAgGridTable";
import {SelectedIdsForTimeClock,ActionsOfTimeClock as Actions, addTimeSheetClicked} from "../../../Context/AppActions";
import AppContext from "../../../Context/AppContext";

const ShiftMapping = ({users, refreshEnable}) => {

    const [assignShiftModal, setAssignShiftModal] = useState(false)
    const [rowData, setRowData] = useState([]);
    const [allStart, setAllStart] = useState(new Date());
    const [allEnd, setAllEnd] = useState(new Date());
    const [allUsers, setAllUsers] = useState([]);
    const [shiftList,setShiftList]=useState([]);
    const [shiftAssignObj,setShiftAssignObj]=useState({})
    const [data, setData] = useState([])
    const [gridApi, setGridApi] = useState(null);
    const AppData = useContext(AppContext);
    const { state, dispatch } = AppData || {};
    const [isEditColumnTrue, setIsEditColumnTrue] = useState();
    const [listSelectedRows, setlistSelectedRows] = useState([]);
    const [cacheRowData, setCacheRowData] = useState([])
    const columnsForRequestView = [

        {
            path: "employeeName",
            label: "Employee Name",
            field: "employeeName",
            name: "Employee Name",
            type:"text",
            id : 0,
            isChecked : false,
            isDragOver : false,
            isSearch : true,
        },
        {
            path: "emailId",
            label: "emailId",
            field: "emailId",
            name: "emailId",
            type:"text",
            id : 1,
            isChecked : false,
            isDragOver : false,
            isSearch : true,
        },
        {   path: "shiftName",
            label: "shift Name",
            field: "shiftName",
            name: "shift Name",
            type:"text",
            id : 2,
            isChecked : false,
            isDragOver : false,
            isSearch : true,
        },
        {   path: "date",
            label: "Date",
            field: "date",
            name: "Date",
            type:"datetime",
            id : 3,
            isChecked : false,
            isDragOver : false,
            isSearch : true,
        },
        {   path: "shiftStartTime",
            label: "Shift Start Time",
            field: "shiftStartTime",
            name: "Shift Start Time",
            type:"datetime",
            id : 4,
            isChecked : false,
            isDragOver : false,
            isSearch : true,
        },
        {   path: "shiftEndTime",
            label: "Shift End Time",
            field: "shiftEndTime",
            name: "Shift End Time",
            type:"datetime",
            id : 5,
            isChecked : false,
            isDragOver : false,
            isSearch : true,
        },
        
    ];
    const [activeHeaderData, setActiveHeaderData] = useState([...columnsForRequestView])

    useEffect(()=>{
        if(users&&users.length>0){
            let arr=[]
            users.forEach((one)=>{
                arr.push(one.full_name)
            })
            setAllUsers(arr)
            
        }
    },[users])

    useEffect(() => {
      if (refreshEnable) {
        getShiftList();
        setEndDate(getEndDate())
        setStartDate(getStartDate())
      }
    }, [refreshEnable]);

    useEffect(e=>{
        setRowData(data)
    //    setCacheRowData(data)
    },[data])

    useEffect(()=>{
        getShiftList();
    },[])

    let arr = [];
    const getShiftList=()=>{
        let fromDate = dayjs().format('YYYY-MM-DD');
        let toDate = dayjs().format('YYYY-MM-DD');
        let emailIds = ['aditya@srsolardesign.com'];
        let obj;
        emailIds.forEach(e=>{
            
            ShiftServices.GetShiftConfiguration(fromDate,toDate,e)
            .then((result)=>{
                console.log("---------------------------result====",result.data);
                let shiftData = result.data.userShiftDetails;
               shiftData.shiftList?.forEach(e=>{
                     obj = {
                        employeeName : shiftData.employeeName,
                        emailId : shiftData.emailId,
                        shiftStartTime : e.shiftStartTime,
                        shiftEndTime : e.shiftEndTime,
                        shiftName : e.shiftName,
                        date : e.date,
                    }
                    arr.push(obj)
                })
                console.log("obj", obj);
                console.log("aaaaaaaaaaaarrrr", arr);
                setData(arr)
                // setRowData(arr)
                // setRowData(...obj)
                // setRowData()
            })
            .catch((err)=>{
                console.log(err);
            })
        })
    }

    // const ActionsOfTimeClock=state.ActionsOfTimeClock;

    // useEffect(() => {
    // if(ActionsOfTimeClock=="Export"){
    //   exportListViewData("newExcelSheet.xlsx")
    // }else if(ActionsOfTimeClock=="EditColumns"){
    //   setIsEditColumnTrue(true)
    // }else if(ActionsOfTimeClock=="Delete" && gridApi){
    //   let array=[];
    //   gridApi?.forEachNode((node) => {
         
    //         if (node.isSelected()) {
    //           if(node?.data?.ROWID){
    //             array.push(node?.data?.ROWID)
    //           }
    //         }
    //   })
      
    // }
    // dispatch(Actions(""))
    // }, [ActionsOfTimeClock])

    const onSelectionChanged = (event) => {
        setGridApi(event.api)
        let listTypeRows = event.api.rowModel?.rowsToDisplay.filter(node => node.isSelected());
        if (listTypeRows.length == event.api.rowModel.rowsToDisplay.length && listSelectedRows.length < listTypeRows.length && listSelectedRows.length != 0) {
          event.api.deselectAll();
        } else {
          let array = [];
          event.api.forEachNode((node) => {
            if (node.isSelected()) {
              array.push(node?.data)
            } else {
            }
          });
          setlistSelectedRows(listTypeRows);
          dispatch(SelectedIdsForTimeClock(array))
        }
      }
      const onFirstDataRendered = (event) => {
        dispatch(SelectedIdsForTimeClock([]))
      }

      const exportListViewData=(fileName)=>{
        gridApi?.exportDataAsExcel({
          fileName: fileName,
          onlySelected: true,
          processCellCallback: function(params) {
            const rowData = params.node.data;
            const columnField = params.column?.getColDef().field;
            return params.value;
        }
        });
    }

    const setAllDate = (data) => {
        data.sort((a, b) => {
            return new Date(a.date) - new Date(b.date)
        })
        if (data.length > 0) {
            setAllStart(new Date(data[0].date))
            setAllEnd(new Date(data[data.length - 1].date))
        }
    }

    function lastDateOFMonth(year, month) {
        return new Date(year, month + 1, 0).getDate();
    }
    const getStartDate = () => {
        let startDate = new Date(Date.now());
        startDate.setDate(1);
        startDate.setHours(0, 0, 0);
        return startDate;
    }
    const getEndDate = () => {
        let endDate = new Date(Date.now());
        endDate.setDate(lastDateOFMonth(new Date().getFullYear(), new Date().getMonth()));
        endDate.setHours(0, 0, 0);
        return endDate;
    }


    const onChangeDateRange = (dates, datesString) => {
        if (datesString.length > 0) {
            let start = new Date(new Date(datesString[0]))
            let end = new Date(new Date(datesString[1]))
            // changeDateRange({ selection: { startDate: start, endDate: end } })
        }

    }
    const [startDate, setStartDate] = useState(getStartDate());
    const [endDate, setEndDate] = useState(getEndDate());
    const { RangePicker } = AntdDatePicker;

    const addClicked=state.addTimeSheetClicked;
  
    useEffect(e=>{
      if(addClicked && addClicked?.module == "shiftMapping"){
        setAssignShiftModal(true);
      }
    },[addClicked])


    const handleOnChange=(e)=>{
        const obj={...shiftAssignObj};
        obj[e.currentTarget.name]=e.currentTarget.value;
        setShiftAssignObj(obj);
    }

    const handleAssignShift=()=>{
        let obj={...shiftAssignObj};
        // obj.From=dayjs(new Date(obj.From)).format('DD/MM/YYYY');
        // obj.To=dayjs(new Date(obj.To)).format('DD/MM/YYYY');
        let empId = obj.Employee == "David Rastegar" ? "001" : "002"
      ShiftServices.MapShiftToUser(obj.From,obj.To,empId,obj.Shift)
      .then((result)=>{
        console.log(result);
        // getShiftList();
        setAssignShiftModal(false)
      })
      .catch((err)=>{
        console.log(err);
      })
    }

    const extraComponentForTable = (e) => {
      return (
          <AntdRangePicker
            onChangeDateRange={onChangeDateRange}
            defaultStartDate={startDate}
            defaultEndDate={endDate}
            allEnd={allEnd}
            allStart={allStart}
          />
      );
    };


    const assignShiftPopUp = () => {
        return <Modal
            size="md"
            isOpen={assignShiftModal}
        >
            <ModalHeader>Assign Shift</ModalHeader>
            <ModalBody>
                <div className="mt-2">
                    <Col>
                        <Row>
                            <Col sm={4}>
                                <label>Applicable For</label>
                            </Col>
                            <Col sm={8}>
                                <CustumSelect
                                    addFlag={false}
                                    placeholder={'Users'}
                                    onChange={(value) => {
                                        handleOnChange({currentTarget:{name:'Employee',value:value}})
                                        // props.handleOnChangeDeal({ currentTarget: { name: 'applicant', value: value } })
                                        // props.setApplicant(value)
                                    }}
                                    items={allUsers} />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col sm={4}>
                                <label>Shift Name</label>
                            </Col>
                            <Col sm={8}>
                                <CustumSelect
                                    mode={'tag'}
                                    addFlag={false}
                                    placeholder={'Shifts'}
                                    onChange={(value) => {
                                        handleOnChange({currentTarget:{name:'Shift',value:value}})
                                        // props.handleOnChangeDeal({ currentTarget: { name: 'applicant', value: value } })
                                        // props.setApplicant(value)
                                    }}
                                    items={["General","Test Shift"]} />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col sm={4}>
                                <label>From</label>
                            </Col>
                            <Col sm={8}>
                                <input onChange={handleOnChange} name="From" placeholder="From" type="date" className="form-control form-control-sm" />

                            </Col>
                        </Row>
                    </Col>

                    <Col className="mt-2">
                        <Row>
                            <Col sm={4}>
                                <label>To</label>
                            </Col>
                            <Col sm={8}>
                                <input placeholder="To" onChange={handleOnChange} name="To" type="date" className="form-control form-control-sm" />

                            </Col>
                        </Row>
                    </Col>
                    <Col className="mt-2">
                        <Row>
                            <Col sm={4}>
                                <label>Reason</label>
                            </Col>
                            <Col sm={8}>
                                <textarea rows={4} cols={5} type="text" className="form-control form-control-sm" />
                            </Col>
                        </Row>
                    </Col>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => {
                        setAssignShiftModal(false);
                        dispatch(addTimeSheetClicked({}))
                      }}
                >
                    Cancel
                </Button>

                <Button
                    color="primary"
                    onClick={() => {
                        handleAssignShift()
                    }}
                >
                    Submit
                </Button>

            </ModalFooter>
        </Modal>
    }

    const extraComponentToRight=(e)=>{
        return (
          <div className="extra-component-request-toRight">
            <div className="job-action-box">
              <Button
                className="ml-3 request-btns"
                onClick={() => setAssignShiftModal(true)}
              >
                Assign Shift <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
        );
      }
    

    return (
        <div>
            {assignShiftPopUp()}
            <div className="hrRequestBtns" style={{ display: "flex", justifyContent: "center", height: "56px", alignItems: "center", borderBottom: "1px solid #dee2e6" }}>                
            </div>
            <div className="hr-time-table mt-2" >
                <div className="ag-theme-alpine" style={{height : "100%"}}>
                    {/* <Table
                        className="request-data-table"
                        id="request"
                        // data={requestList}
                        sortColumn={{ path: "", order: "asc" }}
                        data={func.sortDataAsc(rowData)}
                        columns={columnsForRequestView}
                    /> */}
                    <GlobalAgGridTable
                        headers={activeHeaderData}
                        setRowData={setRowData}
                        cacheRowData={rowData}
                        onSelectionChanged={onSelectionChanged}
                        onFirstDataRendered={onFirstDataRendered}
                        module="ShiftMapping"
                        DropDownFields={["User Name"]}
                        rowData={rowData}
                        renderExtraComponent={extraComponentForTable}
                        renderExtraComponentToRight={extraComponentToRight}
                    />
                </div>
            </div>
        </div>
    );
};
export default ShiftMapping;
