function validate(data, schema, errors) {
      
    const error = { ...errors }
    if (data) {
        schema.forEach(obj => {
            let keys = Object.keys(obj);
            if ((data[keys[0]] !== '') && (data[keys[0]] !== undefined) && (data[keys[0]] !== null)) {
                delete error[keys[0]];
            } else {
                delete error[keys[0]];
                error[keys[0]] = obj[keys[0]];
            }
        })
        if (Object.keys(error).length > 0) {
            return error;
        } else {
            return null;
        }
    } else {
        schema.forEach(one => {
            let keys = Object.keys(one);
            error[keys[0]] = one[keys[0]];
        })
        return error;
    }

}

function validateProperty(name, value, errors) {
      
    const err = { ...errors }
    if ((value !== '') && (value !== undefined) && (value !== null)) {
        delete err[name]
    }
    if (Object.keys(err).length == 0) {
        return null
    }
    return err;
}

function RemoveNestedArray(array) {
    let final = [];
    array.forEach((firstarr) => {
        if (firstarr != null || firstarr != undefined) {
            if (firstarr.length == undefined || firstarr == "NONE") {
                final.push(firstarr);
            } else if (firstarr.length == 0) {

            } else {
                firstarr.forEach((secondarr) => {
                    if (secondarr != null || secondarr != undefined) {
                        if (secondarr.length == undefined) {
                            final.push(secondarr);
                        } else if (secondarr.length == 0) {

                        } else {
                            if(typeof secondarr===typeof []){
                                secondarr.forEach((thirdarr) => {


                                    final.push(thirdarr);
                                })
                            }
                            
                        }
                    }
                })
            }
        }
    })
    return final;
}

export default {
    RemoveNestedArray,
    validate,
    validateProperty
}