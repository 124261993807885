import React, { useState,useEffect } from "react";
// import { useHistory, withRouter, useLocation, Prompt } from 'react-router-dom';
import { Button, CardBody, Collapse, Card } from "reactstrap";

function PVSystemButton(props) {
  // const location = useLocation();

  // useEffect(()=>{
  //   // if(location.state.action != 'create'){
  //   //   if(location.state.open&&(location.state.open=='PVSystemTable')){
  //   //     pvSystemtoggle()
  //   //   }
  //   // }
  // },[location])

  const [pvSystemstate, setPVSystemstatestate] = useState(false);
  const pvSystemtoggle = () =>{
    props.setLayoutState("pvsystem")
    props.setListState('pvsysteminfo')
    setPVSystemstatestate(!pvSystemstate);
   } 

   useEffect(() => {
    props.layoutstate == "pvsystem"
      ? setPVSystemstatestate(true)
      : setPVSystemstatestate(false);
  });

   
    return (
        <div>
            <Button
        className={
          (props.layoutstate == "pvsystem" ? "navbarbtn-active" : "navbarbtn-100")
          +" "
          +(props.sidebarCardsStatus.Permitting? "" : "pointer-class-deactive" ) 
        }
        onClick={() =>{ 
          props.updateTheSidebarState({type:'NavActivetab',status:'PVSystem'})
          localStorage.setItem('currentsidebar',"PVSystem");
          pvSystemtoggle() }}
      >
        PV System
      </Button>
      <Collapse isOpen={pvSystemstate || props.sidebarCardsStatus.NavActivetab == "PVSystem"}>
        <Card className="sidenav-card createdeal-card">
          <CardBody className="navbar-sublist-card">
        <div className={
          props.listState == "pvsysteminfo"
            ? "sublist-text-active"
            : "sublist-text2"
        } onClick={(e) =>{ 
          props.setListState('pvsysteminfo')
          props.toggelActiveBar("pvsysteminfo")
          }}>PV System Information</div>
        <div 
        className={
          props.listState == "projectcost"
            ? "sublist-text-active"
            : "sublist-text2"
        }
        onClick={(e)=>{
          props.setListState('projectcost')
          props.toggelActiveBar("projectcost")
        }}>
          Project Cost
        </div>
        {/* <div className="sublist-text">Module Information</div>
        <div className="sublist-text">PV Manufacturer Information</div>
        <div className="sublist-text2">Inverter Information</div> */}
          </CardBody>
          </Card>
          </Collapse>
        </div>
    );
}

export default PVSystemButton;